import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../Common/Icon';

const ConstructionPlannerScaffoldingSelectionActions = ({ onDelete, onEdit }) => (
    <div className="constructionPlannerSection__actions">
        {
            onDelete &&
            <button
                className="btn btn--backward-action btn--icon"
                onClick={onDelete}
            >
                    <span className="btn__icon">
                        <Icon name="delete" />
                    </span>
            </button>
        }
        {
            onEdit &&
            <button
                className="btn btn--neutral-action btn--icon"
                onClick={onEdit}
            >
                    <span className="btn__icon">
                        <Icon name="edit" />
                    </span>
            </button>
        }
    </div>
);

export default ConstructionPlannerScaffoldingSelectionActions;

ConstructionPlannerScaffoldingSelectionActions.propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

ConstructionPlannerScaffoldingSelectionActions.defaultProps = {
    onEdit: undefined,
    onDelete: undefined
};
