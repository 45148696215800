import PropTypes from 'prop-types';
import React from 'react';
import { ModelType } from '../../../../properties';

export const ConstructionPlannerSectionType = {
    Site: ModelType.Site,
    Scaffolding: ModelType.Scaffolding,
    Section: ModelType.Section,
    Media: 'media'
};

const ConstructionPlanFormGroup = ({
                                       id, readOnly, title, headerIcon, actions, wrapperClass, showActions, children, dashedBorder
                                   }) => {

    const classNames = ['constructionPlannerSection'];

    if (wrapperClass) {
        classNames.push(wrapperClass);
    }

    if (dashedBorder) {
        classNames.push('constructionPlannerSection--dashed');
    }

    if (readOnly) {
        classNames.push('constructionPlannerSection--readonly');
    }

    return (
        <section className={classNames.join(' ')}>
            {id && <div className="constructionPlannerSection__anchor" id={id} />}
            <header className="constructionPlannerSection__hd">
                <h2 className="constructionPlannerSection__ttl">
                    {headerIcon &&
                    <span className="constructionPlannerSection__icon"> {headerIcon} </span>}
                    {title}
                </h2>
                {showActions && actions}
            </header>
            <div className="constructionPlannerSection__content">
                {children}
            </div>
        </section>
    );
};

ConstructionPlanFormGroup.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]).isRequired,
    id: PropTypes.string,
    headerIcon: PropTypes.element,
    actions: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.bool
    ]),
    showActions: PropTypes.bool,
    wrapperClass: PropTypes.string,
    dashedBorder: PropTypes.bool,
    readOnly: PropTypes.bool
};

ConstructionPlanFormGroup.defaultProps = {
    id: '',
    headerIcon: undefined,
    actions: undefined,
    showActions: true,
    wrapperClass: '',
    dashedBorder: false,
    readOnly: false
};

export default ConstructionPlanFormGroup;
