import PropTypes from 'prop-types';
import React from 'react';
import DeleteModal from '../../../Common/Modal/DeleteModal';

const ConstructionPlanFormScaffoldingDeleteModal = ({ onConfirm, onCancel }) => (
    <DeleteModal
        modalTitle="Gerüst entfernen"
        deleteEntity={onConfirm}
        closeModal={onCancel}
    >
        <p>
            Sie sind im Begriff, Ihr Gerüst aus der Baustellenplanung zu
            löschen. <strong> Alle darunter befindlichen Gerüstabschnitte werden damit
            ebenfalls gelöscht.</strong>
        </p>
        <p>Möchten Sie fortfahren?</p>
    </DeleteModal>
);

ConstructionPlanFormScaffoldingDeleteModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConstructionPlanFormScaffoldingDeleteModal;
