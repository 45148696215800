import PropTypes from 'prop-types';
import React from 'react';

const ChangeLogTableRow = ({ children }) => (
    <tr>
        <td colSpan={3}>
            {children}
        </td>
    </tr>
);

const ChangeLogEntryWrapper = ({ entryMetaInformation, children }) => (
    <ChangeLogTableRow>
        <div className="change-log-entry">
            <div className="change-log-entry__meta">
                {entryMetaInformation}
            </div>
            <div className="change-log-entry__diff">
                {children}
            </div>
        </div>
    </ChangeLogTableRow>
);

export default ChangeLogEntryWrapper;

ChangeLogEntryWrapper.propTypes = {
    entryMetaInformation: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
