import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getAccountType, isTrialActive } from '../../../redux/helper';
import { hasLegacyCreditCardAsBilling } from '../../../redux/modules/user/action';
import Modal from '../../Common/Modal/Modal';
import { AccountTypes } from '../subscriptionTypes';

const STORAGE_KEY = 'credit_card_billing_deprecated_hidden';

function isHidden() {
    const clickedAt = localStorage.getItem(STORAGE_KEY);
    if (!clickedAt) {
        return false;
    }
    return moment(clickedAt)
        .add(1, 'w')
        .isAfter(moment());
}

class CreditCardBillingDeprecatedWarningModal extends Component {

    constructor(props) {
        super(props);

        this.loadingGotCancelled = false;
        this.state = {
            isLegacyCard: false,
            isHidden: isHidden()
        };

        this.hideModal = this.hideModal.bind(this);
        this.hideModalAndGoToDetails = this.hideModalAndGoToDetails.bind(this);
    }

    componentDidMount() {
        this.props.actions.hasLegacyCreditCardAsBilling()
            .then((isLegacyCard) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isLegacyCard
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    hideModal(then) {
        this.setState({
            isHidden: true
        }, () => {
            localStorage.setItem(STORAGE_KEY, moment()
                .format());
            if (typeof then === 'function') {
                then();
            }
        });
    }

    hideModalAndGoToDetails() {
        this.hideModal(() => {
            this.props.history.push('/account/subscription/edit/billingdetails');
        });
    }

    render() {

        const { isHidden, isLegacyCard } = this.state;
        const { accountType, trialActive } = this.props;

        if (isHidden || !isLegacyCard || trialActive || accountType === AccountTypes.light) {
            return null;
        }

        return (
            <Modal
                id="modal-credit-card-billing-deprecated"
                title="Bitte aktualisieren Sie Ihre Zahlungsdaten"
                type="confirmation"
                footer={
                    <div className="btn-group">
                        <a
                            className="btn modal-link-btn"
                            onClick={this.hideModal}
                        >
                            Schließen
                        </a>
                        <a
                            className="btn btn--forward-action btn--save btn--primary-green"
                            onClick={this.hideModalAndGoToDetails}
                        >
                            Zahlungsdetails öffnen
                        </a>
                    </div>
                }
            >
                <h3>Neue EU-Richtlinie “PSD2” tritt zum 14.09.2019 in Kraft</h3><br />
                <p>
                    Sie haben aktuell die Zahlung per Kreditkarte als Zahlungsmethode ausgewählt.
                    Bitte nehmen Sie sich einen Moment Zeit, um Ihre Zahlungsdaten zu
                    aktualisieren.
                </p><br />
                <p>
                    Dies führt dazu, dass Sie zukünftig die neue Methode der EU-Richtlinie “PSD2”
                    zur Abwicklung Ihrer
                    Zahlung nutzen. So können wir sicherstellen, dass Sie Scaffeye® auch in Zukunft
                    wie gewohnt nutzen
                    können.
                </p>
            </Modal>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        hasLegacyCreditCardAsBilling
    }, dispatch)
});

const mapStateToProps = (state) => {
    const trialActive = isTrialActive(state);
    const accountType = getAccountType(state);
    return {
        accountType,
        trialActive
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreditCardBillingDeprecatedWarningModal));
