import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SortLink from '../../Job/SortLink';

class SortableTableHeader extends Component {

    constructor(props) {
        super(props);
        this.sortData = this.sortData.bind(this);
        this.getClasses = this.getClasses.bind(this);
    }

    getClasses(fieldValue) {
        const selectedClass = this.props.sort.field === fieldValue ? 'selected' : '';
        return `${selectedClass} sort__link`;
    }

    getInvertedDirection() {
        return this.props.sort.direction === '-' ? '+' : '-';
    }

    sortData(field) {
        let direction = '+';
        if (this.props.sort.field === field) {
            direction = this.getInvertedDirection();
        }
        this.props.onChange(field, direction);
    }

    renderTableColumns(columns) {

        const { field, direction } = this.props.sort;

        return columns.map((column, index) => {

            const { isSortable, label, wrapperClass } = column;

            if (isSortable) {
                const { fieldName } = column;
                return (
                    <th key={index} className={wrapperClass || ''}>
                        <SortLink
                            field={fieldName}
                            label={label}
                            getClasses={this.getClasses}
                            sortData={this.sortData}
                            fieldValue={field}
                            direction={direction}
                        />
                    </th>
                );
            }

            return (
                <th key={index} className={wrapperClass || ''}>
                    {label}
                </th>
            );
        });
    }

    render() {
        const { columns } = this.props;
        return (
            <thead>
            <tr>
                {this.renderTableColumns(columns)}
            </tr>
            </thead>
        );
    }
}

export default SortableTableHeader;

SortableTableHeader.propTypes = {
    sort: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired
};




