import _ from 'lodash';

export const isIdenticalValue = (modelValue, modelReferenceDataValue) => {
    if (!modelValue && !modelReferenceDataValue) {
        return true;
    }

    if ((_.isObject(modelValue) || _.isObject(modelReferenceDataValue)) && _.isEmpty(modelValue) && _.isEmpty(modelReferenceDataValue)) {
        return true;
    }

    return _.isEqual(modelValue, modelReferenceDataValue);
};

export const isIdenticalAddress = (modelValue, modelReferenceDataValue) => {
    const value = { ...modelValue };
    const referenceValue = { ...modelReferenceDataValue };

    // country must be deleted because user has no access to set country
    if (value.hasOwnProperty('country')) {
        delete value.country;
    }

    if (referenceValue.hasOwnProperty('country')) {
        delete referenceValue.country;
    }

    return isIdenticalValue(value, referenceValue);
};

export function isObjectIdenticalValue(modelValue, modelReferenceDataValue) {

    if (
        (
            modelValue === null
            || (_.isObject(modelValue) && _.isEmpty(modelValue))
        )
        &&
        (
            modelReferenceDataValue === null
            || (_.isObject(modelReferenceDataValue) && _.isEmpty(modelReferenceDataValue))
        )
    ) {
        return true;
    }

    return _.isEqual(modelValue, modelReferenceDataValue);
}
