import React from 'react';
import DiffItem from '../Diff/DiffItem';
import DiffTypeHeading from '../Diff/DiffTypeHeading';

export const ChangeLogEntryChangeGroup = ({diffType, items, renderDiffItemValue, getDiffTypeLabel, getDiffTypeIcon}) => {

    const diffTypeIconName = getDiffTypeIcon(diffType);
    const diffTypeLabel = getDiffTypeLabel(diffType);

    return (
        <div className="change-log__group" key={diffType}>
            <div
                className={`change-log__group-type change-log__group-type--${diffType}`}
            >
                <DiffTypeHeading
                    diffType={diffType}
                    icon={diffTypeIconName}
                >
                    {diffTypeLabel}
                </DiffTypeHeading>
            </div>
            <div className="change-log__group-diff">
                {items.map((diffItem) =>
                    <DiffItem
                        key={diffItem.property}
                        diffItem={diffItem}
                        renderValue={renderDiffItemValue}
                    />
                )}
            </div>
        </div>
    );
};
