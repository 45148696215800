import React from 'react';
import Icon from './Icon';

const IconButton = ({ onClick, title, icon }) => (
    <button
        onClick={onClick}
        className="btn btn--neutral-action btn--icon"
        title={title}
    >
        <span className="btn__icon"><Icon name={icon} /></span>
    </button>
);

export default IconButton;
