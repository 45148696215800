import PropTypes from 'prop-types';
import React from 'react';
import { AllowedFileFormats } from '../../../../properties';
import Icon from '../../../Common/Icon';

const EmptyMediaDropzone = (props) => {
    return (
        <div className="media-dropzone__empty">
            {
                props.multiple ?
                    <p> Dateien zum Hochladen per Drag-and-Drop hier
                        ablegen <br />
                        oder </p>
                    :
                    <p> Datei zum Hochladen per Drag-and-Drop hier
                        ablegen <br />
                        oder </p>
            }

            <button type="button" className="btn btn--neutral-action">
                <span className="btn__icon">
                    <Icon name="upload" />
                </span>
                <span>{props.multiple ? 'Dateien auswählen' : 'Datei auswählen'}</span>
            </button>
            <small>Maximale Dateigröße für Uploads: 100MB</small>
            <div className="allowed-file-formats-hint">
                Mögl. Dateiendungen: <br />
                {`${AllowedFileFormats.join(', ')}`}
            </div>
        </div>
    );

};

EmptyMediaDropzone.propTypes = {
    multiple: PropTypes.bool
};

EmptyMediaDropzone.defaultProps = {
    multiple: true
};

export default EmptyMediaDropzone;
