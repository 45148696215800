import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { autocompleteSection } from '../../redux/modules/section/action';
import Autocomplete from './Autocomplete';
import { setFilterOption } from '../../redux/modules/constructiondiary/action';
import { DiaryCategoryFilterOptions } from './ConstructionDiaryCategoryOptions';


class CategoryAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        const { initialValue } = this.props;

        if (initialValue) {

            const initialOption = DiaryCategoryFilterOptions.filter((option) => option.value === initialValue);

            this.props.actions.setFilterOption({
                key: 'category',
                value: { value: initialValue }
            });

            this.setState({
                initialOption,
                hasInitialOption: true
            });
        }
    }

    onSelectionChange(selection) {
        this.props.onSelectionChange(selection);
    }

    render() {
        const { disabled } = this.props;
        const { initialOption, hasInitialOption } = this.state;

        return (
            <Autocomplete
                label="Kategorie"
                placeholder="Kategorie wählen"
                onSelectionChange={selection => this.onSelectionChange(selection)}
                disabled={disabled}
                options={DiaryCategoryFilterOptions}
                initialOption={initialOption}
                hasInitialOption={hasInitialOption}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteSection,
        setFilterOption
    }, dispatch)
});
export default connect(null, mapDispatchToProps)(CategoryAutocomplete);


CategoryAutocomplete.propTypes = {
    onSelectionChange: PropTypes.func,
    disabled: PropTypes.bool,
};

CategoryAutocomplete.defaultProps = {
    onSelectionChange: () => false,
    disabled: false,
};
