import React, { Component } from 'react';
import Modal from './Modal';

const suggestedBrowsers = [
    {
        label: 'Microsoft® Edge',
        description: 'Windows® 10 (vorinstalliert), Android® & iOS®',
        url: 'https://www.microsoft.com/de-de/windows/microsoft-edge'
    },
    {
        label: 'Chrome',
        description: 'alle Betriebssysteme',
        url: 'https://www.google.com/intl/de/chrome/'
    },
    {
        label: 'Firefox',
        description: 'alle Betriebssysteme',
        url: 'https://www.mozilla.org/de/firefox/new/'
    },
    {
        label: 'Safari',
        description: 'macOS® (vorinstalliert) & iOS® (vorinstalliert)',
        url: 'https://www.apple.com/de/safari/'
    }
];


const renderFancyBrowserLinks = () => {

    return suggestedBrowsers.map((browser,index) =>
        (
            <li key={index} className="browser-recommendations_recommendation">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={browser.url}
                >
                    {browser.label}
                </a>
                {' '} - {' '}
                {browser.description}
            </li>
        )
    );
};


class LegacyBrowserModal extends Component {

    renderButtonText() {
        const { isSending } = this.state;
        return isSending ? 'Wird gesendet ...' : 'Akzeptieren';
    }

    render() {

        return (
            <Modal
                title="Wussten Sie, dass ihr Browser veraltet ist?"
                id="legacyBrowserInfoModal"
                type="micro-form"
                footer={
                    <div className="btn-group">
                        <a
                            className="btn btn--backward-action"
                            onClick={() => this.props.closeModal()}
                        >
                            Schließen
                        </a>
                    </div>
                }
            >
                <p>
                    Mit dem <strong>Internet Explorer</strong> nutzen Sie aktuell einen
                    veralteten Browser. <br />
                    Zur optimalen Benutzung von Scaffeye® empfehlen wir Ihnen, auf einen neueren
                    Browser zu wechseln.
                </p>
                <div className="browser-recommendations">
                <h3> Scaffeye® unterstützt derzeit die folgenden Browser: </h3>
                <ul>
                    {renderFancyBrowserLinks()}
                </ul>
                    </div>
            </Modal>
        );
    }
}

export default LegacyBrowserModal;

