import { applyMiddleware, createStore } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';

import refreshMiddleware from './middleware/refreshMiddleware';
import rootReducer from './reducer';

const config = {
    key: 'root',
    storage,
    whitelist: ['currentUser', 'serviceworker']
};

const mainReducer = persistCombineReducers(config, rootReducer);

const initializeStore = () => {

    return createStore(
        mainReducer,
        applyMiddleware(refreshMiddleware, thunk)
    );

};

export const Store = initializeStore();

export default function configureStore() {

    const persistor = persistStore(Store);

    /* To clear the store uncomment the following lines: */
    // persistor.purge();

    return {
        persistor,
        Store
    };
}
