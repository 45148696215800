import _ from 'lodash';
import React, { Fragment } from 'react';
import NotAvailablePlaceholder from '../../Components/Common/NotAvailablePlaceholder';
import DateService from '../../Services/DateService';
import Tooltip from '../Common/Tooltip';
import {
    getCladdingLabel,
    getConstructionTypeLabel,
    getEquippedSurfaceLabel,
    getManagedLabel,
    getPlannedWorkLabel,
    getSpecialFeatureLabel,
    getStabilityAndLoadSafetyLabel,
    getSupportTypeLabel
} from './labels';
import {
    Cladding,
    Bracket,
    EquippedSurface,
    LoadClass,
    PlannedWork,
    SpecialFeature,
    WallClearance,
    WidthClass, PurposeGroup
} from './properties';

export const SelectablePurposeGroups = [
    PurposeGroup.WorkScaffolding,
    PurposeGroup.ProtectiveScaffolding,
    PurposeGroup.LadderAscent,
    PurposeGroup.PlatformStairway,
    PurposeGroup.SiteStaircase,
    PurposeGroup.EmergencyStairway
];

export const showManagedLabel = (managed, fallback = <NotAvailablePlaceholder />) => {
    if (managed === null || managed === undefined) {
        return fallback;
    }

    return getManagedLabel(managed);
};

export const showEquippedSurfaces = (surfaces, fallback = <NotAvailablePlaceholder />) => {

    if (!surfaces || !Object.keys(surfaces).length) {
        return fallback;
    }

    const items = Object.keys(surfaces)
        .map((surfaceKey) => {
            if (surfaceKey === EquippedSurface.Other) {
                return surfaces[EquippedSurface.Other];
            }

            return getEquippedSurfaceLabel(surfaceKey);
        });

    return items.join(', ');
};

/* Gerüstverkleidungen */
export const showCladdings = (claddings, fallback = <NotAvailablePlaceholder />) => {

    if (!claddings || !Object.keys(claddings).length) {
        return fallback;
    }

    const items = Object.keys(claddings)
        .map((cladding) => {
            if (cladding === Cladding.Other) {
                return claddings[Cladding.Other];
            }

            return getCladdingLabel(cladding);
        });

    return items.join(', ');
};

/* Special Features */
export const showSpecialFeatures = (specialFeatures, fallback = <NotAvailablePlaceholder />) => {

    if (!specialFeatures || _.isEmpty(specialFeatures)) {
        return fallback;
    }

    const items = Object.keys(specialFeatures)
        .map((feature) => {
            if (feature === SpecialFeature.Bracket) {
                const consoleFeatures = [];
                if (Object.prototype.hasOwnProperty.call(specialFeatures[feature], Bracket.MM300)) {
                    consoleFeatures.push('0,3m');
                }
                if (Object.prototype.hasOwnProperty.call(specialFeatures[feature], Bracket.MM700)) {
                    consoleFeatures.push('0,7m');
                }
                if (Object.prototype.hasOwnProperty.call(specialFeatures[feature], Bracket.Other)) {
                    consoleFeatures.push(specialFeatures[feature][Bracket.Other]);
                }

                if (consoleFeatures.length) {
                    return `Konsole: ${consoleFeatures.join(', ')}`;
                }
            }

            return getSpecialFeatureLabel(feature);
        });

    return items.join(', ');
};

/* Wandabstand */
export const showWallClearance = (clearance, fallback = <NotAvailablePlaceholder />) => {

    if (!clearance) {
        return fallback;
    }

    if (Object.prototype.hasOwnProperty.call(clearance, WallClearance.Below300MM)) {
        return '< 30cm';
    } else if (Object.prototype.hasOwnProperty.call(clearance, WallClearance.Exactly300MM)) {
        return '30cm';
    } else if (Object.prototype.hasOwnProperty.call(clearance, WallClearance.Above300MM)) {
        return `> 30 cm: ${clearance[WallClearance.Above300MM]}`;
    }

    return fallback;
};

/* Lastklasse */
export const showLoadClass = (loadClass, fallback = <NotAvailablePlaceholder />) => {
    if (!loadClass) {
        return fallback;
    }

    if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.LC1)) {
        return '1';
    } else if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.LC2)) {
        return '2';
    } else if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.LC3)) {
        return '3';
    } else if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.LC4)) {
        return '4';
    } else if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.LC5)) {
        return '5';
    } else if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.LC6)) {
        return '6';
    } else if (Object.prototype.hasOwnProperty.call(loadClass, LoadClass.Other)) {
        return loadClass[LoadClass.Other];
    }

    return fallback;
};

/* Lastklasse */
export const showWidthClass = (widthClass, fallback = <NotAvailablePlaceholder />) => {
    if (!widthClass) {
        return fallback;
    }

    if (Object.prototype.hasOwnProperty.call(widthClass, WidthClass.W06)) {
        return 'W06';
    } else if (Object.prototype.hasOwnProperty.call(widthClass, WidthClass.W09)) {
        return 'W09';
    } else if (Object.prototype.hasOwnProperty.call(widthClass, WidthClass.Other)) {
        return widthClass[WidthClass.Other];
    }

    return fallback;
};

export const showPlannedWorks = (works, fallback = <NotAvailablePlaceholder />) => {
    if (!works || !Object.keys(works).length) {
        return fallback;
    }

    const items = Object.keys(works)
        .map((plannedWorkKey) => {
            if (plannedWorkKey === PlannedWork.Other) {
                return works[PlannedWork.Other];
            }

            return getPlannedWorkLabel(plannedWorkKey);
        });

    return items.join(', ');
};

/* Support Type */
export const showSupportType = (supportType, fallback = <NotAvailablePlaceholder />) => {
    return supportType ? getSupportTypeLabel(supportType) : fallback;
};

export const showConstructionType = (constructionType, fallback = <NotAvailablePlaceholder />) => {
    if (!constructionType) {
        return fallback;
    }

    return getConstructionTypeLabel(constructionType);
};

export const showStabilityAndLoadSafety = ({specialConstruction, staticsCertificateNecessary}, fallback =
    <NotAvailablePlaceholder />) => {
    if (!specialConstruction && !staticsCertificateNecessary) {
        return fallback;
    }

    return getStabilityAndLoadSafetyLabel(specialConstruction, staticsCertificateNecessary);
};

export const hasRightToSendCloseRequest = (section) => {
    if (!section || !section.isInvited) {
        return false;
    }

    const { scaffolding } = section;
    if (!scaffolding || !scaffolding.isInvited) {
        return false;
    }

    const { site } = scaffolding;
    if (!site || !site.isInvited) {
        return false;
    }

    const { invitations } = site;
    if (!invitations || invitations.length === 0) {
        return false;
    }

    return invitations.filter(invitation => invitation.allowCloseRequests).length > 0;
};

export function renderManagedApprovedByInformation(activeAcceptanceInspection) {

    let content = '-';

    if (activeAcceptanceInspection) {

        const { createdByName, completed } = activeAcceptanceInspection;

        content = `${createdByName} am ${DateService.getReadableDateTime(completed)}`;
    }

    return (
        <Fragment>
            <span style={{
                display: 'inline-block',
                marginRight: 8
            }}
            >  {content}</span>
            <Tooltip id="approved-by-information">
                Die Person, die eine Freigabeprüfung für diesen Gerüstabschnitt durchführt, wird ab
                sofort automatisch als “Befähigte Person” geführt. Die manuelle Angabe der
                Befähigten Person ist dadurch nicht mehr nötig.
            </Tooltip>
        </Fragment>
    );
}

export function formatShortId(shortId) {
    if (_.isEmpty(shortId) || !_.isString(shortId)) {
        return shortId;
    }

    return `${shortId.substring(0, 4)} ${shortId.substring(4, 8)}`;
}
