import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { fetchBillingAddress, updateBillingAddress } from '../../redux/modules/account/action';
import { showApiError, showRequestError } from '../../redux/modules/error/action';
import {
    addBillingType,
    addPaymentMethod,
    createPaymentMethodSecret,
    getBillingData,
    getPaymentMethod,
    updateDebit,
    updatePaymentMethodCard
} from '../../redux/modules/user/action';
import GraphQLError from '../../Services/GraphQLError';
import StripeElementsError from '../../Services/StripeElementsError';
import CardSelection from '../Common/CardSelection';
import SepaSelection from '../Common/SepaSelection';
import Condition from '../Forms/Condition';
import InputGroup from '../Forms/InputGroup';
import Select from '../Forms/Select';
import Section from '../Layout/Section';
import SectionFooter from '../Layout/SectionFooter';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';
import InvoiceBillingDeprecatedWarningBanner from './Billing/InvoiceBillingDeprecatedWarningBanner';

export const ViewModes = {
    Invoice: 'invoiceMode',
    CardNew: 'cardNewMode',
    CardEdit: 'cardEditMode',
    CardView: 'cardViewMode',
    SepaNew: 'sepaNewMode',
    SepaView: 'sepaViewMode',
    SepaEdit: 'sepaEditMode'
};

export const BillingTypes = {
    Card: 'card',
    Invoice: 'invoice',
    SepaDebit: 'sepa_debit'
};

const BillingChoices = [
    {
        label: 'Rechnung',
        value: BillingTypes.Invoice,
        disabled: true
    },
    {
        label: 'Kreditkarte',
        value: BillingTypes.Card
    },
    {
        label: 'Lastschrift (SEPA-Mandat)',
        value: BillingTypes.SepaDebit
    }
];

class BillingDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            isFetching: true,
            isLoading: false,
            isSending: false,
            formValues: {},
            billingData: null,
            submissionError: '',
            isLegacyPaymentInfo: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.loadPaymentInformation = this.loadPaymentInformation.bind(this);
        this.setViewMode = this.setViewMode.bind(this);
        this.onBillingTypeChange = this.onBillingTypeChange.bind(this);
        this.validate = this.validate.bind(this);
    }

    static getBillingErrorCodeMessage(errorCode) {
        switch (errorCode) {

            /*
             * Credit card
             */

            case 'card_declined':
                return 'Ihre Kreditkarte wurde abgelehnt.';

            case 'expired_card':
                return 'Ihre Kreditkarte ist abgelaufen.';

            case 'incorrect_address':
                return 'Ihre Adressdaten sind fehlerhaft.';

            case 'incorrect_cvc':
                return 'Die Prüfnummer ihrer Kreditkarte ist fehlerhaft.';

            case 'incorrect_number':
                return 'Die Kreditkartennummer ist fehlerhaft.';

            case 'incorrect_zip':
                return 'Die Postleitzahl ihrer Kreditkarte ist ungültig.';

            case 'invalid_cvc':
                return 'Die Prüfnummer ihrer Kreditkarte ist ungültig.';

            case 'invalid_expiry_month':
                return 'Der Ablaufmonat ihrer Kreditkarte ist ungültig.';

            case 'invalid_expiry_year':
                return 'Das Ablaufjahr ihrer Kreditkarte ist ungültig.';

            case 'invalid_number':
                return 'Die Kreditkartennummer ist fehlerhaft.';

            case 'postal_code_invalid':
                return 'Die in ihren Rechnungsdaten hinterlegte Postleitzahl ist ungültig.';

            case 'tax_id_invalid':
                return 'Ihre USt.-IdNr. ist ungültig.';

            case 'email_invalid':
                return 'Die in ihrem Konto hinterlegte E-Mail Adresse ist ungültig.';

            default:
                return false;
        }
    }

    validate(values) {
        const errors = {};

        const { viewMode } = this.state;

        if (!values.billingType) {
            errors.billingType = 'Bitte wählen Sie eine Zahlungsart aus.';
        }

        if (values.billingType === BillingTypes.SepaDebit) {
            if (!values.name) {
                errors.name = 'Bitte geben Sie einen Namen ein';
            }
        }

        if (viewMode === ViewModes.CardNew) {
            if (!values.creditCardOwner) {
                errors.creditCardOwner = 'Bitte geben Sie einen Karteninhaber ein';
            }
        }

        return errors;
    }

    componentWillMount() {
        this.loadPaymentInformation();
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    submissionSuccess() {
        this.setState({
            isSending: false,
            isLoading: true
        });
        toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });
        this.loadPaymentInformation();
    }

    submissionFailure(error, message) {
        this.setState({
            isSending: false,
            isLoading: false
        });

        if (!this.state.submissionError) {
            this.handleSubmitError(error, message);
        }
    }

    submitSepaDebit(values, isCreated = true) {
        const { actions: { addBillingType } } = this.props;
        const { name, currentDebitSourceId } = values;

        const addSepaDebit = this.addSepaDebit(name);

        if (isCreated) {
            return addSepaDebit
                .then(sourceId => addBillingType(sourceId)
                    .then(() => {
                        this.submissionSuccess();
                    }))
                .catch((error) => {
                    this.submissionFailure(error, 'Lastschriftdaten konnten nicht gespeichert werden');
                });
        }

        return addSepaDebit
            .then(newSourceId => this.updateSepaDebit(currentDebitSourceId, newSourceId)
                .then(() => {
                    this.submissionSuccess();
                }))
            .catch((error) => {
                this.submissionFailure(error, 'Lastschriftdaten konnten nicht gespeichert werden');
            });
    }

    /**
     * @param values
     * @param isCreated
     * @returns {Q.Promise<any> | Promise<T | never> | *}
     */
    submitCreditCard(values, isCreated = true) {
        const { actions: { addPaymentMethod } } = this.props;
        const { creditCardOwner, sourceId: paymentMethodId, expMonth, expYear } = values;

        if (isCreated) {
            return this.addCreditCard(creditCardOwner)
                .then(paymentMethodId => addPaymentMethod(paymentMethodId)
                    .then(() => {
                        this.submissionSuccess();
                    }))
                .catch((error) => {
                    this.submissionFailure(error, 'Kreditkartendaten konnten nicht gespeichert werden');
                });
        }

        return this.updateCreditCard(paymentMethodId, expMonth, expYear)
            .then(() => {
                this.submissionSuccess();
            })
            .catch((error) => {
                this.submissionFailure(error, 'Kreditkartendaten konnten nicht gespeichert werden');
            });
    }

    /* eslint-disable consistent-return */
    onSubmit(values) {
        const { viewMode } = this.state;

        if (this.loadingGotCancelled) {
            return;
        }

        this.resetSubmissionError();

        this.setState({
            isSending: true
        });

        if (viewMode === ViewModes.SepaNew || viewMode === ViewModes.SepaEdit) {
            const isCreated = viewMode === ViewModes.SepaNew;
            return this.submitSepaDebit(values, isCreated);
        }

        if (viewMode === ViewModes.CardNew || viewMode === ViewModes.CardEdit || viewMode === ViewModes.CardView) {
            const isCreated = viewMode === ViewModes.CardNew;
            return this.submitCreditCard(values, isCreated);
        }
    }

    /* eslint-enable consistent-return */
    onBillingTypeChange(billingType) {
        const { billingData } = this.state;
        const { type } = billingData;

        this.resetSubmissionError();

        if (billingType === BillingTypes.Card) {
            this.setViewMode(billingData && billingType === type ? ViewModes.CardView : ViewModes.CardNew);
        } else if (billingType === BillingTypes.SepaDebit) {
            this.setViewMode(billingData && billingType === type ? ViewModes.SepaView : ViewModes.SepaNew);
        } else {
            this.setViewMode(ViewModes.Invoice);
        }
    }

    setViewMode(viewMode) {
        const _viewMode = viewMode || this.getDefaultViewMode();
        this.setState({
            viewMode: _viewMode
        });
    }

    updateCreditCard(cardId, expMonth, expYear) {
        const { actions: { updatePaymentMethodCard } } = this.props;
        return updatePaymentMethodCard(cardId, expMonth, expYear);
    }

    updateSepaDebit(currentSourceId, newSourceId) {
        const { actions: { updateDebit } } = this.props;
        return updateDebit(currentSourceId, newSourceId);
    }

    setSubmissionError(errorMessage) {
        this.setState({
            submissionError: errorMessage
        });
    }

    resetSubmissionError() {
        this.setState({
            submissionError: ''
        });
    }

    handleStripeError(data) {
        if (data.error) {
            const { error } = data;

            if (error.type === 'invalid_request_error' || error.type === 'card_error') {
                if (error.code !== 'card_declined') {
                    return Promise.reject(new StripeElementsError(error));
                }
                this.setSubmissionError('Ihre Karte wurde vom System abgelehnt. Bitte versuchen Sie es erneut oder verwenden Sie eine andere Kreditkarte.');
            }
        }
    }

    createSecret() {
        const { actions: { createPaymentMethodSecret } } = this.props;
        return createPaymentMethodSecret()
            .catch(err =>
                Promise.reject(new Error('Es ist ein Fehler beim Speichern der Kreditkarte aufgetreten. Bitte versuchen Sie es erneut oder verwenden Sie eine andere Kreditkarte.'))
            );
    }

    addCreditCard(name) {
        const { stripe } = this.props;
        if (!stripe) {
            return Promise.reject(new Error('Kreditkarte kann zur Zeit nicht hinzugefügt werden (stripe not loaded)'));
        }

        const opts = {
            payment_method_data: {
                billing_details: {
                    name
                }
            }
        };

        return this.createSecret()
            .then(secret => stripe.handleCardSetup(secret, opts))
            .then(data => {
                const error = this.handleStripeError(data);

                if (error) {
                    return error;
                }

                const { setupIntent } = data;
                if (!setupIntent) {
                    return Promise.reject();
                }

                return setupIntent.payment_method;
            });
    }

    addSepaDebit(name) {
        const { stripe } = this.props;
        if (!stripe) {
            return Promise.reject(new Error('Lastschrift kann zur Zeit nicht hinzugefügt werden (stripe not loaded)'));
        }

        const opts = {
            type: 'sepa_debit',
            currency: 'eur',
            owner: {
                name
            }
        };

        return stripe
            .createSource(opts)
            .then((data) => {
                const error = this.handleStripeError(data);

                if (error) {
                    return error;
                }

                const { source } = data;
                if (!source) {
                    return Promise.reject();
                }
                return source.id;
            });
    }

    loadPaymentInformation() {
        const { actions: { getBillingData, getPaymentMethod } } = this.props;

        getBillingData()
            .then((paymentInformation) => {
                const isLegacyPaymentInfo = paymentInformation.type === BillingTypes.Card && !!paymentInformation.id;
                const shouldLoadNewInformation = paymentInformation.type === BillingTypes.Card && !paymentInformation.id;

                if (!shouldLoadNewInformation) {
                    this.setPaymentInformation(paymentInformation);
                }

                this.setState({
                    isLegacyPaymentInfo
                });

                return Promise.resolve(shouldLoadNewInformation);
            })
            .then(shouldLoadNewInformation => {
                if (shouldLoadNewInformation) {
                    return getPaymentMethod().then(paymentInformation => this.setPaymentInformation(paymentInformation));
                }
            })
            .catch((error) => {
                showApiError(error);
                this.setState({
                    isFetching: false,
                    isLoading: false
                });
            });
    }

    setPaymentInformation(paymentInformation) {
        const { type } = paymentInformation;

        const formData = {
            billingType: type
        };

        const viewMode = this.getDefaultViewMode(type);

        // Credit Card
        if (type === BillingTypes.Card) {
            const { card } = paymentInformation;
            formData.sourceId = paymentInformation.id;
            formData.expMonth = card.expMonth;
            formData.expYear = card.expYear;
        }
        // Lastschrift
        if (type === BillingTypes.SepaDebit) {
            const { id } = paymentInformation;
            formData.currentDebitSourceId = id;
        }

        this.setState({
            isFetching: false,
            isLoading: false,
            viewMode,
            billingData: paymentInformation,
            formValues: {
                ...formData
            }
        });
    }

    renderButtonText() {
        const { isSending } = this.state;
        return isSending ? 'Änderungen werden gespeichert ...' : 'Speichern';
    }

    render() {
        const {
            formValues,
            isFetching,
            isSending,
            billingData,
            viewMode,
            isLoading,
            submissionError,
            isLegacyPaymentInfo
        } = this.state;

        if (!isFetching && !formValues) {
            return <ResourceNotFoundErrorPage />;
        }

        if (isFetching) {
            return <PageLoadingSpinner />;
        }

        return (
            <div className="BillingDetailsForm">

                <InvoiceBillingDeprecatedWarningBanner billingInformation={billingData} />

                {isLoading ? <LoadingSpinner label="Aktualisiere Zahlungsdetails" block /> : null}

                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    initialValues={formValues}
                    mutators={{
                        setField: ([field, value], state, utils) => {
                            utils.changeValue(state, field, () => value);
                        }
                    }}
                    render={({
                                 handleSubmit, form: { mutators }, values, form: { reset }
                             }) => (
                        <form onSubmit={handleSubmit}>
                            <Section
                                title=""
                            >
                                <div className="section-hint" style={{ marginBottom: 30 }}>
                                    Um Ihr Abonnement über die Testphase hinaus nutzen zu können,
                                    benötigen Sie eine gültige Zahlungsmethode. <br />
                                    Diese können Sie hier hinterlegen.
                                </div>
                                {submissionError &&
                                <div className="form-error">{submissionError}</div>}
                                <InputGroup
                                    label="Ihre Zahlungsart*"
                                >
                                    <Select
                                        name="billingType"
                                        placeholder="Zahlungsart wählen"
                                        options={BillingChoices}
                                        disabled={isFetching || isLoading || isSending}
                                        onChange={this.onBillingTypeChange}
                                    />
                                </InputGroup>

                                <Condition when="billingType" is="card">
                                    <CardSelection
                                        billingData={billingData}
                                        disabled={isFetching || isLoading || isSending}
                                        viewMode={viewMode}
                                        reset={reset}
                                        setViewMode={this.setViewMode}
                                        isFirstCard={!billingData}
                                        onExpirationDateChange={mutators.setField}
                                        expMonth={values.expMonth}
                                        expYear={values.expYear}
                                        isLegacy={isLegacyPaymentInfo}
                                    />
                                </Condition>

                                <Condition when="billingType" is="sepa_debit">
                                    <SepaSelection
                                        onFieldChange={mutators.setField}
                                        viewMode={viewMode}
                                        billingData={billingData}
                                        setViewMode={this.setViewMode}
                                        reset={reset}
                                        disabled={isFetching || isLoading || isSending}
                                    />
                                </Condition>

                                <SectionFooter>
                                    <div className="section__ft-hints">
                                        Scaffeye® nutzt <a
                                        style={{
                                            color: '#93979F',
                                            textDecoration: 'underline'
                                        }}
                                        href="https://stripe.com/de"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >Stripe
                                    </a>, um Ihre Zahlungen zu verarbeiten und Ihre
                                        Rechnungen zu generieren.
                                        Stripe ist ein Unternehmen mit Sitz in den USA und gemäß
                                        EU-US Privacy Shield Framework zertifiziert. Weitere
                                        Informationen zur EU-US Privacy Shield Policy finden Sie
                                        hier: <a
                                        href="https://stripe.com/privacy-shield-policy"
                                        target="_blank"
                                        style={{
                                            color: '#93979F',
                                            textDecoration: 'underline'
                                        }}
                                        rel="noreferrer noopener"
                                    >https://stripe.com/privacy-shield-policy
                                    </a>.
                                        Allgemeine Informationen zum Datenschutz bei Stripe können
                                        Sie hier nachlesen: <a
                                        href="https://stripe.com/de/privacy"
                                        target="_blank"
                                        style={{
                                            color: '#93979F',
                                            textDecoration: 'underline'
                                        }}
                                        rel="noreferrer noopener"
                                    >https://stripe.com/de/privacy
                                    </a>.
                                    </div>
                                </SectionFooter>
                            </Section>

                            <div className="btn-group">
                                <span>

                                    <Link
                                        to="/account/subscription"
                                        className="btn btn--backward-action"
                                        style={{ marginRight: 20 }}
                                    >
                                        <span>Abbrechen</span>
                                    </Link>

                                    <span style={{ color: '#0E1E33' }}>* Pflichtfeld</span>
                                </span>

                                <button
                                    type="submit"
                                    className="btn btn--forward-action btn--save"
                                    disabled={isSending || isFetching || isLoading}
                                >
                                    {this.renderButtonText()}
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
        );
    }

    getDefaultViewMode(_type) {
        let type = _type;
        if (!type) {
            const { billingData } = this.state;
            if (billingData.type) {
                type = billingData.type;
            }
        }

        switch (type) {
            case BillingTypes.Card:
                return ViewModes.CardView;
            case BillingTypes.SepaDebit:
                return ViewModes.SepaView;
            default:
                return ViewModes.Invoice;
        }
    }

    handleSubmitError(error, defaultMessage) {

        if (error instanceof StripeElementsError) {
            this.setSubmissionError(error.errorMessage);
            return;
        }

        if (error instanceof GraphQLError) {
            const { errorCode } = error;
            const message = BillingDetailsForm.getBillingErrorCodeMessage(errorCode);
            if (message) {
                this.setSubmissionError(message);
                return;
            }
        }

        showRequestError(defaultMessage, error);
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateBillingAddress,
        fetchBillingAddress,
        addBillingType,
        getBillingData,
        updateDebit,
        createPaymentMethodSecret,
        getPaymentMethod,
        updatePaymentMethodCard,
        addPaymentMethod
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(injectStripe(BillingDetailsForm));
