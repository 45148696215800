import React from 'react';

const Changelog_1_12_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">

            <section>
                <p>
                    Changelog in Bearbeitung
                </p>
            </section>

        </div>
    </div>
);

export default Changelog_1_12_0;
