import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import Select from 'react-select';
import { ActionTypes } from '../../Common/AsyncAutocomplete';
import FieldError from '../FieldError';
import styles from './styles';

const getInitialValue = (input, options) => {
    if (!input) {
        return;
    }
    return options.find(option => option.value === input.value);
};

const ReactSelectAdapter = ({ input, meta, options, onChange, isClearable, components, selectStyles, placeholder, getInitialValue }) => {

    const internalOnChange = (selectedOption, action) => {

        if (action.action === ActionTypes.clear) {
            input.onChange(null);
        } else {
            input.onChange(selectedOption.value);
        }

        if (typeof onChange === 'function') {
            onChange(selectedOption, action);
        }
    };

    return (
        <Fragment>
            <Select
                className="react-select"
                classNamePrefix="react-select"
                name={input.name}
                value={getInitialValue(input, options)}
                options={options}
                components={components}
                isClearable={isClearable}
                styles={{ ...styles, ...selectStyles }}
                onChange={internalOnChange}
                isOptionDisabled={option => option.disabled === true || option.disabled === 'yes'}
                placeholder={placeholder}
            />
            <FieldError meta={meta} />
        </Fragment>
    );
};

const SelectField = ({ ...props }) => (
    <Field
        component={ReactSelectAdapter}
        {...props}
    />
);

SelectField.propTypes = {
    components: PropTypes.object,
    selectStyles: PropTypes.object,
    getInitialValue: PropTypes.func,
    isClearable: PropTypes.bool
};

SelectField.defaultProps = {
    components: null,
    selectStyles: {},
    getInitialValue
};

export default SelectField;
