import PropTypes from 'prop-types';
import React from 'react';
import UserManagementBreadcrumb from '../../../Components/Account/UserManagementBreadcrumb';
import UserForm from '../../../Components/Account/UserForm';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const AddUserPage = ({ history, match }) => (
    <PrivatePage requiredUserPermission={Permission.CreateUsers}>
        <Container>
            <TopNav />
            <UserManagementBreadcrumb />
            <PageTitle title="Nutzer hinzufügen" />
            <UserForm
                history={history}
                edit={match.params.id}
            />
        </Container>
    </PrivatePage>
);

export default AddUserPage;


AddUserPage.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

AddUserPage.defaultProps = {
    match: {},
    history: {}
};
