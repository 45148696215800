import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError } from '../../../redux/modules/error/action';
import { fetchScaffoldingForSectionFormData } from '../../../redux/modules/scaffoldings/action';
import {
    addNewSection,
    fetchContractorChoices,
    fetchSectionFormData,
    updateSection
} from '../../../redux/modules/section/action';
import { fetchSitesSelectOptions } from '../../../redux/modules/sites/action';
import { BadRequestError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { userCan } from '../../../Services/UserService';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import { FormMode, FormModePropType } from '../../Forms/properties';
import { SectionStatus } from '../properties';
import AddContractorModal from './AddContractorModal';
import { getFormValues, getSubmitPayload, isUnmanaged } from './functions';
import { getAllInputs } from './helpers';
import SectionFormContent from './SectionFormContent';
import { SectionManagedIndicator } from './SectionManagedIndicator';
import { validate } from './validate';

const focusOnError = createDecorator(getAllInputs);

const initialFormData = {};

class SectionFormModal extends Component {
    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            initialized: false,
            mode: props.mode,
            sectionId: props.id,
            section: null,
            managed: undefined,
            formData: initialFormData,
            scaffoldingId: props.scaffoldingId,
            scaffolding: undefined,
            siteId: undefined,
            contractorChoices: [],
            showAddContractorModal: false,
            isFetching: props.mode === FormMode.Edit,
            isFetchingSites: false,
            isFetchingScaffoldings: false,
            isSaving: false,
            isFetchingContractors: false,
            fetchedContractors: false,
        };

        this.onFetchingSites = this.onFetchingSites.bind(this);
        this.onFetchingScaffoldings = this.onFetchingScaffoldings.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);

        this.initializeForm = this.initializeForm.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);

        this.toggleAddContractorModal = this.toggleAddContractorModal.bind(this);
        this.onContractorSelectionChange = this.onContractorSelectionChange.bind(this);
        this.onContractorCreated = this.onContractorCreated.bind(this);

        this.onUploading = this.onUploading.bind(this);
    }

    componentWillMount() {
        if (this.state.mode === FormMode.Create) {
            if (this.state.scaffoldingId) {
                this.props.actions.fetchScaffoldingForSectionFormData(this.state.scaffoldingId)
                    .then((scaffolding) => {
                        this.prepareFormDataWithScaffolding(scaffolding);
                    });
            } else {
                const formData = {
                    ...initialFormData,
                    managed: userCan(['create-managed-sections'], this.props.userPermissions) ? undefined : false
                };

                this.loadContractorOptions();

                this.setState({
                    initialized: true,
                    formData
                });
            }
        } else {
            this.props.actions.fetchSectionFormData(this.state.sectionId)
                .then((section) => {
                    this.initializeForm(section);
                });
        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onSiteChanged(siteId, mutators) {
        this.setState({
            siteId,
            scaffolding: undefined
        }, () => {
            mutators.resetField('scaffoldingId');
            mutators.resetField('managed');
        })
    }

    onScaffoldingChanged(scaffoldingId, scaffolding, mutators) {
        const { managed } = scaffolding;
        this.setState({
            managed
        }, () => {
            mutators.setField('managed', managed);
            if (!managed) {
                this.loadContractorOptions();
            }
        });
    }

    onContractorCreated() {
        this.loadContractorOptions();
        this.toggleAddContractorModal();
    }

    onFetchingSites(isFetching) {
        this.setState({
            isFetchingSites: isFetching
        });
    }

    onFetchingScaffoldings(isFetching) {
        this.setState({
            isFetchingScaffoldings: isFetching
        });
    }

    onContractorSelectionChange(value) {
        if (value === '-1') {
            this.toggleAddContractorModal();
        }
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    isPostingOutdatedAfterSaving(formValues) {
        const { mode } = this.props;
        if (mode !== FormMode.Edit) {
            return false;
        }

        const { section } = this.state;
        if (!section) {
            return false;
        }

        if (isUnmanaged(formValues)) {
            return false;
        }

        return section.status === SectionStatus.Constructed && formValues.status === section.status;
    }

    submit(values) {
        const {
            sectionId
        } = this.state;

        const sectionData = getSubmitPayload(values);

        if (values.managed) {
            sectionData.contractorId = this.props.contractorId || null;
        }

        this.setState({ isSaving: true });

        if (this.props.mode === FormMode.Edit) {
            this.props.actions.updateSection(sectionId, sectionData)
                .then((section) => {
                    this.setState({
                        isSaving: false
                    });
                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });
                    return section.id;
                })
                .then((id) => {
                    if (id && typeof this.props.onUpdated === 'function') {
                        this.props.onUpdated(id);
                    }
                })
                .catch((error) => {
                    this.handleFormSubmissionError(error);
                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });

        } else {
            this.props.actions.addNewSection(sectionData)
                .then((section) => {
                    this.setState({
                        isSaving: false
                    });
                    toast.success('Gerüstabschnitt wurde angelegt', { autoClose: 2500 });
                    return section;
                })
                .then((section) => {
                    if (section && typeof this.props.onCreated === 'function') {
                        this.props.onCreated(section.id, section);
                    }
                })
                .catch((error) => {
                    this.handleFormSubmissionError(error);
                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });
        }
    }

    onSubmit(values, form) {
        const { mode } = this.props;
        if(mode === FormMode.Edit && !form.getState().dirty) {
            // Don't do anything if the user has not changed anything
            this.onClose(true);
            return;
        }

        if (this.isPostingOutdatedAfterSaving(values)) {
            this.setState({
                showPostingOutdatedAfterUpdateModal: true,
                submitValues: values
            })
        } else {
            this.submit(values);
        }
    }

    handleFormSubmissionError(error) {

        if (error instanceof BadRequestError) {
            return ErrorService.handleBadRequestError(error, 'section')
                .then((errorMessages) => {
                    toast.error(Object.values(errorMessages)[0], { autoClose: 2500 });
                });
        }

        showApiError(error);

    }

    loadContractorOptions() {

        // Already loading or loaded - ignore call
        if (this.state.isFetchingContractors || this.state.fetchedContractors) {
            return;
        }

        this.setState({
            isFetchingContractors: true
        });

        this.props.actions.fetchContractorChoices()
            .then((choices) => {
                const contractorChoices = [
                    {
                        label: '+ Neuen Gerüstersteller anlegen',
                        value: -1
                    }
                ];

                choices.forEach((choice) => {
                    contractorChoices.push({
                        label: choice.name,
                        value: choice.id
                    });
                });

                this.setState({
                    isFetchingContractors: false,
                    fetchedContractors: true,
                    contractorChoices
                });
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    prepareFormDataWithScaffolding(scaffolding) {
        if (!scaffolding || !scaffolding.site) {
            this.setState({
                initialized: true
            });
        }

        const formData = {
            siteId: scaffolding.site.id,
            scaffoldingId: scaffolding.id,
            managed: scaffolding.managed
        };

        if (!scaffolding.managed) {
            this.loadContractorOptions();
        }

        this.setState({
            initialized: true,
            formData,
            scaffolding
        });
    }

    initializeForm(section) {
        if (!section) {
            this.setState({
                initialized: true,
                isFetching: false,
                section: null
            });
            return;
        }

        const formData = getFormValues(section);

        if (!section.managed) {
            this.loadContractorOptions();
        }

        this.setState({
            initialized: true,
            isFetching: false,
            section,
            formData
        });
    }

    renderButtonText() {
        const { mode, isSaving } = this.state;

        if (mode === FormMode.Edit) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }

        return isSaving ? 'Gerüstabschnitt wird gespeichert ...' : 'Gerüstabschnitt speichern';
    }

    toggleAddContractorModal() {
        this.setState(prevState => ({
            showAddContractorModal: !prevState.showAddContractorModal
        }));
    }

    onUploading(isUploading) {
        this.setState({
            isUploading
        });
    }

    render() {
        const {
            initialized,
            mode,
            isSaving,
            formData,
            isFetching,
            isFetchingSites,
            isFetchingScaffoldings,
            showAddContractorModal,
            isFetchingContractors,
            contractorChoices,
            isUploading,
            showPostingOutdatedAfterUpdateModal
        } = this.state;

        const modalTitle = mode === FormMode.Edit ? 'Gerüstabschnitt bearbeiten' : 'Neuer Gerüstabschnitt';

        if (!initialized) {
            return (
                <Modal
                    id="section-modal"
                    classes="modal-section"
                    title={modalTitle}
                    loading
                    loadingLabel="Lade Gerüstabschnittsdaten"
                    temporary
                />
            )
        }

        return (
            <>
                <Form
                    onSubmit={this.onSubmit}
                    decorators={[focusOnError]}
                    validate={validate}
                    initialValues={formData}
                    mutators={{
                        resetField: ([name], state, utils) => {
                            utils.changeValue(state, name, () => '');
                        },
                        setField: ([name, value], state, utils) => {
                            utils.changeValue(state, name, () => value);
                        },
                        setAttachments: ([newAttachments], state, utils) => {
                            utils.changeValue(state, 'attachments', () => newAttachments);
                        },
                        setUtilizationPlans: ([newUtilizationPlan], state, utils) => {
                            utils.changeValue(state, 'utilizationPlans', () => newUtilizationPlan);
                        },
                        setAnchorProtocol: ([newAnchorProtocol], state, utils) => {
                            utils.changeValue(state, 'anchorProtocol', () => newAnchorProtocol);
                        },
                        setProofOfStability: ([newProofOfStability], state, utils) => {
                            utils.changeValue(state, 'proofOfStability', () => newProofOfStability);
                        }
                    }}
                    render={({
                                 handleSubmit, values, pristine, form: { mutators }
                             }) => (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                id="section-modal"
                                classes="modal-section"
                                title={modalTitle}
                                onBackdropClick={() => this.onClose}
                                loading={!initialized}
                                loadingLabel="Lade Gerüstabschnittsdaten"
                                headerRight={values.scaffoldingId ?
                                    <SectionManagedIndicator managed={values.managed} /> : null}
                                footer={
                                    <ModalFormFooter
                                        onSubmit={handleSubmit}
                                        isSubmitting={isSaving}
                                        onClose={() => this.onClose(pristine)}
                                        submitLabel={this.renderButtonText()}
                                        submitDisabled={isSaving || isFetching || isFetchingSites || isFetchingScaffoldings || isFetchingContractors || isUploading}
                                    />
                                }
                            >
                                <OnChange name="contractorId">
                                    {(newContractorId) => this.onContractorSelectionChange(newContractorId)}
                                </OnChange>

                                <SectionFormContent
                                    mutators={mutators}
                                    mode={mode}
                                    values={values}
                                    isFetchingContractors={isFetchingContractors}
                                    onFetchingSites={this.onFetchingSites}
                                    onFetchingScaffoldings={this.onFetchingScaffoldings}
                                    onChangeSite={siteId => this.onSiteChanged(siteId, mutators)}
                                    onChangeScaffolding={(scaffoldingId, scaffolding) => this.onScaffoldingChanged(scaffoldingId, scaffolding, mutators)}
                                    contractorChoices={contractorChoices}
                                    userPermissions={this.props.userPermissions}
                                    onUploading={this.onUploading}
                                    section={this.state.section}
                                />

                            </Modal>

                            {/*  For debugging purposes*/}
                            {/*<FormDebugger values={values} />*/}

                            {showAddContractorModal &&
                            <AddContractorModal
                                onClose={wasCancelled => {
                                    if (wasCancelled) {
                                        mutators.setField('contractorId', null);
                                    }
                                    this.toggleAddContractorModal();
                                }}
                                onCreated={(contractorId) => {
                                    mutators.setField('contractorId', contractorId);
                                    this.onContractorCreated();
                                }}
                            />
                            }
                        </form>
                    )}
                />
                {showPostingOutdatedAfterUpdateModal &&
                <ConfirmationModal
                    title="Änderungen am Gerüstabschnitt"
                    onConfirm={() => {
                        this.setState({
                            showPostingOutdatedAfterUpdateModal: false
                        }, () => {
                            this.submit(this.state.submitValues);
                        })
                    }}
                    buttonTitle="Fortfahren und Speichern"
                    closeModal={() => {
                        this.setState({
                            showPostingOutdatedAfterUpdateModal: false
                        });
                    }}
                >
                    <p>
                        Die Angaben Ihres Gerüstabschnittes haben sich geändert.
                    </p>
                    <p>
                        <strong>
                            Dadurch verliert Ihre bereits durchgeführte Freigabe Ihre
                            Gültigkeit.
                        </strong>
                    </p>
                    <p>
                        Möchten Sie fortfahren?
                    </p>
                </ConfirmationModal>
                }
            </>
        );
    }
}

SectionFormModal.propTypes = {
    mode: FormModePropType,
    id: PropTypes.string,
    scaffoldingId: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func
};

SectionFormModal.defaultProps = {
    mode: FormMode.Create,
    id: null,
    scaffoldingId: null,
    onCreated: null,
    onUpdated: null,
    onClose: () => false
};

const mapStateToProps = ({ currentUser }) => {
    const userAccount = currentUser.usermeta.account || [];
    const userPermissions = currentUser.usermeta.permissions ? currentUser.usermeta.permissions : [];
    return {
        contractorId: userAccount.company.id,
        userPermissions
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSectionFormData,
        fetchContractorChoices,
        addNewSection,
        updateSection,
        fetchSitesSelectOptions,
        fetchScaffoldingForSectionFormData
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionFormModal);
