import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';

const InvitedIndicator = ({ label, size }) => (
    <div className={['indicator indicator--isInvited', size === 'small' ? 'indicator--small' : ''].join(' ')}>
        <Icon name="isInvited" verticalAlign="baseline" /><span className="indicator__lbl">{label}</span>
    </div>
);

export default InvitedIndicator;

InvitedIndicator.propTypes = {
    label: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default'])
};

InvitedIndicator.defaultProps = {
    size: 'default',
    label: 'Eingeladen'
};
