import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ModelType } from '../../../properties';
import InputGroup from '../../Forms/InputGroup';
import MediaField from '../../Forms/Media/Field/MediaField';
import MediaUploadButton from '../../Forms/Media/MediaUploadButton';
import MediaUploadModal from '../../Forms/Media/Modal/MediaFormModal';
import { MediaFormDataPropType} from '../../Media/properties';

class SiteMediaFormPart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadModalIsOpen: false
        };

        this.openMediaUploadModal = this.openMediaUploadModal.bind(this);
        this.closeMediaUploadModal = this.closeMediaUploadModal.bind(this);

        this.onUploaded = this.onUploaded.bind(this);
    }

    openMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: true
        });
    }

    closeMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: false
        });
    }

    onUploaded(mediaList) {

        const { attachments } = this.props;

        let newAttachments;

        if (attachments) {
            newAttachments = [
                ...attachments,
                ...mediaList
            ];
        } else {
            newAttachments = [
                ...mediaList
            ];
        }

        this.props.attachmentsMutator(newAttachments);
    }

    render() {
        const { uploadModalIsOpen } = this.state;
        const { readOnly, isConstructionPlan } = this.props;

        return (
            <Fragment>
                <InputGroup
                    label="Anhänge"
                >
                    <MediaField
                        name="attachments"
                        emptyListLabel="Keine Anhänge vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup>
                    <MediaUploadButton
                        onClick={this.openMediaUploadModal}
                        disabled={readOnly}
                    />
                </InputGroup>

                {uploadModalIsOpen &&
                <MediaUploadModal
                    onUploading={this.props.onUploading}
                    onUploaded={this.onUploaded}
                    modelType={ModelType.Site}
                    onClose={this.closeMediaUploadModal}
                    isConstructionPlan={isConstructionPlan}
                />
                }
            </Fragment>
        );
    }
}

SiteMediaFormPart.propTypes = {
    attachments: PropTypes.arrayOf(MediaFormDataPropType).isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    onUploading: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isConstructionPlan: PropTypes.bool
};

SiteMediaFormPart.defaultProps = {
    readOnly: false,
    isConstructionPlan: false
};

export default SiteMediaFormPart;
