import React from 'react';

const Changelog_1_15_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">

            <section>
                <h4>Erweiterung der Bautagebucheinträge um zusätzliche Informationen</h4>
                <p>
                    Bautagebucheinträge in Scaffeye® können ab sofort um Informationen zu Wetter und Zeitaufwand (Dauer von An- und Abfahrt, Arbeitszeit) ergänzt werden. Darüber hinaus sind Angaben zur anwesenden Arbeitskolonne möglich: Mitarbeiter und deren Arbeitsfunktion sind wählbar und können einer Arbeitskolonne zugewiesen werden.
                </p>
            </section>

            <section>
                <h4>Transparentere Übersicht zu Laufzeit und Kosten von Scaffeye®-Abonnements</h4>
                <p>
                    Die Darstellung von aktuellen Kosten und Abonnement-Laufzeit und -Verlängerung ist ab sofort deutlicher hervorgehoben.
                </p>
            </section>


            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>Optimierung der Ansicht für Anfragen und Aufträge</li>
                    <li>Verbesserte Fehlerbehandlung bei technischen Störungen</li>
                    <li>Korrektur der Änderungsübersicht innerhalb der Bauplanung</li>
                    <li>Weitere kleinere Verbesserungen und Korrekturen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_15_0;
