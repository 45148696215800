import { refreshToken } from '../action';
import {
    refreshTokenFailure,
    refreshTokenRequesting,
    refreshTokenSuccess
} from '../modules/user/action';
import * as types from '../types';

import { isTokenExpired } from '../helper';

// store => next => action => result
const refreshMiddleware = ({ dispatch, getState }) => (
    (next) => (action) => {

        const { currentUser } = getState();
        /*
         * Only interested in thunk functions
         */
        if (typeof action !== 'function') {
            return next(action);
        }

        /*
         * Check if we are trying to logout
         */
        if (action.type === types.LOGOUT) {
            return next(action);
        }

        /*
         * Check if the user is logged in
         */
        if (!(currentUser && currentUser.auth_token)) {
            return next(action);
        }

        /*
         * Check if token is still valid
         */
        if (!isTokenExpired(getState)) {
            return next(action);
        }

        /*
         * Check if we are currently refreshing token
         */
        if (currentUser.refreshing_auth_token) {
            return false;
        }

        /*
         * Token not valid
         */
        dispatch(refreshTokenRequesting());

        /*
        * Request a new token
        */
        return refreshToken(
            (data) => {
                dispatch(refreshTokenSuccess(data));
                next(action);
            },
            (errorMessages) => {
                dispatch(refreshTokenFailure(errorMessages));
            }
        );
    }
);

export default refreshMiddleware;
