import { ModelType } from '../../../properties';
import { ConstructionPlanModelMode } from '../../ConstructionPlan/properties';
import { walkConstructionPlanSite } from '../../ConstructionPlan/utils';
import {
    MediaVisibility,
    ModelMediaType,
    ScaffoldingMedia,
    SectionMedia,
    SiteMedia
} from '../../Media/properties';

export const toMediaFormData = (media, modelType, modelMediaType, constructionPlanMode, modelId) => {
    const mediaFormData = {
        ...media,
        modelType,
        modelMediaType
    };

    if (constructionPlanMode && !media.mode) {
        mediaFormData.mode = constructionPlanMode;
    }

    if (constructionPlanMode && modelId) {
        mediaFormData.modelId = modelId;
    }

    return mediaFormData;
};

export const toMediaPayload = (mediaFormData, isPlan) => {
    if (!isPlan) {
        return mediaFormData.id;
    }

    const mediaDto = {
        id: mediaFormData.id,
        mode: mediaFormData.mode
    };

    if (mediaFormData.mode === ConstructionPlanModelMode.Edit) {
        mediaDto.name = mediaFormData.name;
    }

    return mediaDto;
};

export const getRestrictedVisibilityOptionsHint = (values) => {

    const { modelMediaType } = values;

    if (!modelMediaType) {
        return null;
    }

    switch (modelMediaType) {

        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_AnchorProtocol:
            return 'Ankerprotokolle sind immer öffentlich';

        case ModelMediaType.Section_ProofOfStability:
            return 'Statiknachweise sind immer öffentlich';

        case ModelMediaType.Section_UtilizationPlan:
        case ModelMediaType.Scaffolding_UtilizationPlan:
            return 'Nutzungspläne sind immer öffentlich';

        case ModelMediaType.ConstructionDiaryEntry_Attachment:
            return 'Anhänge zu Bautagebucheinträgen sind nur unternehmensintern sichtbar';

        case ModelMediaType.Job_Attachment:
            return 'Anhänge zu Anfragen und Aufträgen sind nur unternehmensintern sichtbar';

        case ModelMediaType.Job_Correspondence:
            return 'Korrespondenz zu Anfragen und Aufträgen sind nur unternehmensintern sichtbar';

        default:
            return null;
    }
};

export const getModelMediaTypes = (modelType) => {
    switch (modelType) {
        case ModelType.Job:
            return [
                ModelMediaType.Job_Attachment,
                ModelMediaType.Job_Correspondence
            ];
        case ModelType.Site:
            return [
                ModelMediaType.Site_Attachment
            ];
        case ModelType.Scaffolding:
            return [
                ModelMediaType.Scaffolding_Attachment,
                ModelMediaType.Scaffolding_UtilizationPlan,
                ModelMediaType.Scaffolding_AnchorProtocol
            ];
        case ModelType.Section:
            return [
                ModelMediaType.Section_Attachment,
                ModelMediaType.Section_UtilizationPlan,
                ModelMediaType.Section_AnchorProtocol,
                ModelMediaType.Section_ProofOfStability
            ];
        case ModelType.ConstructionDiaryEntry:
            return [
                ModelMediaType.ConstructionDiaryEntry_Attachment
            ];
        default:
            return [];
    }
};

export const getSiteMediaType = (collectionKey) => {
    switch (collectionKey) {
        case SiteMedia.Attachments:
            return ModelMediaType.Site_Attachment;
        default:
            return undefined;
    }
};

export const getScaffoldingMediaType = (collectionKey) => {
    switch (collectionKey) {
        case ScaffoldingMedia.Attachments:
            return ModelMediaType.Scaffolding_Attachment;
        case ScaffoldingMedia.UtilizationPlans:
            return ModelMediaType.Scaffolding_UtilizationPlan;
        case ScaffoldingMedia.AnchorProtocol:
            return ModelMediaType.Scaffolding_AnchorProtocol;
        default:
            return undefined;
    }
};

export const getSectionMediaType = (collectionKey) => {
    switch (collectionKey) {
        case SectionMedia.Attachments:
            return ModelMediaType.Section_Attachment;
        case SectionMedia.UtilizationPlans:
            return ModelMediaType.Section_UtilizationPlan;
        case SectionMedia.AnchorProtocol:
            return ModelMediaType.Section_AnchorProtocol;
        case SectionMedia.ProofOfStability:
            return ModelMediaType.Section_ProofOfStability;
        default:
            return undefined;
    }
};

export const getModelMediaType = (modelType, collectionKey) => {
    switch (modelType) {
        case ModelType.Site:
            return getSiteMediaType(collectionKey);
        case ModelType.Scaffolding:
            return getScaffoldingMediaType(collectionKey);
        case ModelType.Section:
            return getSectionMediaType(collectionKey);
        default:
            return undefined;
    }
};

export const getCollectionKey = (modelMediaType) => {
    switch (modelMediaType) {
        case ModelMediaType.Job_Attachment:
        case ModelMediaType.Site_Attachment:
        case ModelMediaType.Scaffolding_Attachment:
        case ModelMediaType.Section_Attachment:
        case ModelMediaType.ConstructionDiaryEntry_Attachment:
            return 'attachments';
        case ModelMediaType.Scaffolding_UtilizationPlan:
        case ModelMediaType.Section_UtilizationPlan:
            return 'utilizationPlans';
        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_AnchorProtocol:
            return 'anchorProtocol';
        case ModelMediaType.Section_ProofOfStability:
            return 'proofOfStability';
        case ModelMediaType.Job_Correspondence:
            return 'correspondence';
        default:
            return undefined;
    }
};

export const getModelMediaTypeLabel = (modelMediaType) => {
    switch (modelMediaType) {
        case ModelMediaType.Job_Attachment:
        case ModelMediaType.Site_Attachment:
        case ModelMediaType.Scaffolding_Attachment:
        case ModelMediaType.Section_Attachment:
        case ModelMediaType.ConstructionDiaryEntry_Attachment:
            return 'Anhang';
        case ModelMediaType.Scaffolding_UtilizationPlan:
        case ModelMediaType.Section_UtilizationPlan:
            return 'Nutzungsplan';
        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_AnchorProtocol:
            return 'Ankerprotokoll';
        case ModelMediaType.Section_ProofOfStability:
            return 'Statiknachweis';
        case ModelMediaType.Job_Correspondence:
            return 'Korrespondenz';
        default:
            return `Anhang (${modelMediaType})`;
    }
};

export const getModelTypeLabel = (modelType) => {
    switch (modelType) {
        case ModelType.Site:
            return 'Baustelle';
        case ModelType.Scaffolding:
            return 'Gerüst';
        case ModelType.Section:
            return 'Gerüstabschnitt';
        default:
            return `n.a. (${modelType})`;
    }
};

export const getModelMediaTypeVisibilities = (modelMediaType) => {
    switch (modelMediaType) {
        case ModelMediaType.Scaffolding_UtilizationPlan:
        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_UtilizationPlan:
        case ModelMediaType.Section_AnchorProtocol:
        case ModelMediaType.Section_ProofOfStability:
            return [
                MediaVisibility.Public
            ];
        case ModelMediaType.Job_Attachment:
        case ModelMediaType.ConstructionDiaryEntry_Attachment:
            return [
                MediaVisibility.AccountPrivate
            ];
        case ModelMediaType.Job_Correspondence:
            return [
                MediaVisibility.AccountPrivate
            ];
        default:
            return [
                MediaVisibility.AccountPrivate,
                MediaVisibility.Public
            ];
    }
};

export const getVisibilityLabel = (visibility) => {
    switch (visibility) {
        case MediaVisibility.Public:
            return 'Öffentliches Dokument';
        case MediaVisibility.AccountPrivate:
            return 'Internes Dokument';
        default:
            return `Andere (${visibility})`;
    }
};

export const getMediaUploadPath = (modelMediaType, isConstructionPlan = false) => {

    if (isConstructionPlan) {
        return 'v1/jobs/construction-plan-media';
    }

    switch (modelMediaType) {
        case ModelMediaType.Job_Attachment:
            return 'v1/jobs/attachments';
        case ModelMediaType.Job_Correspondence:
            return 'v1/jobs/correspondence';
        case ModelMediaType.Site_Attachment:
            return 'v1/sites/attachments';
        case ModelMediaType.Scaffolding_UtilizationPlan:
            return 'v1/scaffoldings/utilization-plans';
        case ModelMediaType.Scaffolding_AnchorProtocol:
            return 'v1/scaffoldings/anchor-protocol';
        case ModelMediaType.Scaffolding_Attachment:
            return 'v1/scaffoldings/attachments';
        case ModelMediaType.Section_Attachment:
            return 'v1/sections/attachments';
        case ModelMediaType.Section_UtilizationPlan:
            return 'v1/sections/utilization-plans';
        case ModelMediaType.Section_ProofOfStability:
            return 'v1/sections/proof-of-stability';
        case ModelMediaType.Section_AnchorProtocol:
            return 'v1/sections/anchor-protocol';
        case ModelMediaType.ConstructionDiaryEntry_Attachment:
            return 'v1/construction-diary-entries/attachments';
        default:
            return undefined;
    }
};

export const getMediaUploadFieldName = (modelMediaType, isConstructionPlan = false) => {

    if (isConstructionPlan) {
        return 'media';
    }

    switch (modelMediaType) {
        case ModelMediaType.Job_Attachment:
        case ModelMediaType.Site_Attachment:
        case ModelMediaType.Scaffolding_Attachment:
        case ModelMediaType.Section_Attachment:
        case ModelMediaType.ConstructionDiaryEntry_Attachment:
            return 'attachment';
        case ModelMediaType.Scaffolding_UtilizationPlan:
        case ModelMediaType.Section_UtilizationPlan:
            return 'utilization-plan';
        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_AnchorProtocol:
            return 'anchorProtocol';
        case ModelMediaType.Section_ProofOfStability:
            return 'proofOfStability';
        default:
            return 'file';
    }
};

export const isMultiUploadAllowed = (modelMediaType) => {
    switch (modelMediaType) {
        case ModelMediaType.Job_Attachment:
        case ModelMediaType.Job_Correspondence:
        case ModelMediaType.Site_Attachment:
        case ModelMediaType.Scaffolding_Attachment:
        case ModelMediaType.Section_Attachment:
        case ModelMediaType.ConstructionDiaryEntry_Attachment:
        case ModelMediaType.Scaffolding_UtilizationPlan:
        case ModelMediaType.Section_UtilizationPlan:
            return true;
        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_AnchorProtocol:
        case ModelMediaType.Section_ProofOfStability:
            return false;
        default:
            return undefined;
    }
};

const createModelReferenceOption = (constructionPlanModel, type, referenceModel = null) => {
    const { mode } = constructionPlanModel;

    let label = 'n.a.';
    if (mode === ConstructionPlanModelMode.New) {
        label = constructionPlanModel.data.name;
    } else if (mode === ConstructionPlanModelMode.Reference) {
        if (referenceModel && referenceModel.name) {
            label = referenceModel.name;
        }
    } else if (constructionPlanModel.mode === ConstructionPlanModelMode.Edit) {
        if (constructionPlanModel.data.name) {
            label = constructionPlanModel.data.name;
        } else if (referenceModel && referenceModel.name) {
            label = referenceModel.name;
        }
    }

    let disabled = false;
    if (mode === ConstructionPlanModelMode.Reference) {
        disabled = true;
    }

    return {
        value: constructionPlanModel.id,
        label,
        disabled,
        type
    }
};

const createModelReferenceGroup = (label, type, options = []) => {
    return {
        label,
        type,
        options
    };
};

export const getModelReferenceOptionsFromConstructionPlanSite = (constructionPlanSite, referenceData = {}) => {

    const options = [];
    if (!constructionPlanSite) {
        return options;
    }

    let sectionOptions = [];

    walkConstructionPlanSite(constructionPlanSite, referenceData, (modelType, model, referenceData) => {
        switch (modelType) {
            case ModelType.Site:
                const siteOption = createModelReferenceOption(model, modelType, referenceData);
                const siteOptionGroup = createModelReferenceGroup('Baustelle', modelType, [siteOption]);
                options.push(siteOptionGroup);
                break;
            case ModelType.Scaffolding:
                const scaffoldingOption = createModelReferenceOption(model, modelType, referenceData);
                const scaffoldingOptionGroup = createModelReferenceGroup('Gerüst', modelType, [scaffoldingOption]);
                options.push(scaffoldingOptionGroup);

                if (sectionOptions && sectionOptions.length > 0) {
                    options.push(createModelReferenceGroup('Gerüstabschnitte', ModelType.Section, sectionOptions));
                    sectionOptions = [];
                }

                break;
            case ModelType.Section:
                sectionOptions.push(createModelReferenceOption(model, ModelType.Section, referenceData));
                break;
            default:
                console.error(`Encountered unknown model type ${modelType}`);
        }
    });

    if (sectionOptions && sectionOptions.length > 0) {
        options.push(createModelReferenceGroup('Gerüstabschnitte', ModelType.Section, sectionOptions));
        sectionOptions = [];
    }

    return options;
};

export const collectMediaIds = (mediaCollection) => {
    if (!mediaCollection || mediaCollection.length === 0) {
        return [];
    }

    return mediaCollection.map(mediaCollectionItem => mediaCollectionItem.id);
};
