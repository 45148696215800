import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../Common/Icon';

const SyncChoiceOption = ({ value, renderValue, isSelected, isLiveRessource, onSelect, hint }) => {

    const classes = ['sync-option'];

    if (isSelected) {
        classes.push('is-selected');
    }

    let renderedValue = renderValue(value, isLiveRessource);
    if (!renderedValue) {
        renderedValue = '-';
    }

    if (!renderedValue || renderedValue === '-') {
        classes.push('is-empty');
    }

    const hintElement = typeof hint === 'function' ? hint(value) : hint;

    if(!!hintElement || isSelected) {
        classes.push('has-meta');
    }

    return (
        <div onClick={() => onSelect(value)} className={classes.join(' ')} role="button">
            <div className="sync-option__cnt js-sync-option-cnt">
                {renderedValue}
            </div>
            <div className="sync-option__meta">
                {hintElement &&
                <div className="sync-option__hint">
                    {hintElement}
                </div>
                }
                {isSelected &&
                <div className="sync-option__selected">
                    <span className="sync-option__selected-icon">
                        <Icon name="correct" />
                    </span>
                    <span className="sync-option__selected-label">
                        Ausgewählt
                    </span>
                </div>
                }
            </div>
        </div>
    )
};

SyncChoiceOption.propTypes = {
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    renderValue: PropTypes.func,
    renderedValue: PropTypes.string,
    isLiveRessource: PropTypes.bool,
    wasEdited: PropTypes.bool,
    hint: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

SyncChoiceOption.defaultProps = {
    renderValue: (value) => value,
    hint: undefined
};

export default SyncChoiceOption;

