import React from 'react';
import { isUsingInternetExplorer } from '../utils';
import ModalGate from './Account/ModalGate';
import LegacyBrowserModal from './Common/Modal/LegacyBrowserModal';

const STORAGE_KEY = 'legacy_browser_modal_hidden';

const LegacyBrowserInfoModal = () => (
    <ModalGate
        opensIf={isUsingInternetExplorer()}
        storageKey={STORAGE_KEY}
    >
        {
            closeModal => (
                <LegacyBrowserModal
                    closeModal={closeModal}
                />
            )
        }
    </ModalGate>
);


export default LegacyBrowserInfoModal;
