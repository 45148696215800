import React from 'react';
import { getStatusLabel } from '../../../Sections/labels';
import { DiffValue, DiffValueFallback } from './DiffValue';
import { JobDiffProperty } from '../properties';

export const JobDiffValue = ({ value, diff }) => {

    const { property } = diff;

    switch (property) {
        case JobDiffProperty.Status:
            return getStatusLabel(value, DiffValueFallback);

        default:
            return <DiffValue value={value} diff={diff} />;
    }
};
