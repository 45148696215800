import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';

const UnmanagedResourceIndicator = ({ label, size }) => (
    <div className={['indicator indicator--unmanaged', size === 'small' ? 'indicator--small' : ''].join(' ')}>
        <Icon name="unmanaged" verticalAlign="baseline" /><span className="indicator__lbl">{label}</span>
    </div>
);

export default UnmanagedResourceIndicator;

UnmanagedResourceIndicator.propTypes = {
    label: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'default'])
};

UnmanagedResourceIndicator.defaultProps = {
    size: 'default'
};
