import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Icon from '../../../Common/Icon';
import IfUser from '../../../Common/IfUser';
import { DataTable } from '../../../Common/Table/DataTable';
import { FormMode } from '../../../Forms/properties';
import Section from '../../../Layout/Section';
import CustomerContactFormModal, { MODAL_CONTEXT_STANDALONE } from '../../Form/CustomerContactFormModal';
import { sortCustomerContactsByMainContact } from '../../Form/utils';
import CustomerContactPersonTableRow from './CustomerContactPersonTableRow';
import { CustomerContactPersonsTableColumns } from './properties';

const getInitialState = (props) => ({
    addContactPersonModalIsOpen: false
});

class CustomerContactPersonOverview extends Component {

    constructor(props) {
        super(props);
        this.state = getInitialState(props);

        this.toggleAddContactPersonModal = this.toggleAddContactPersonModal.bind(this);
        this.onChangeCustomersContactPersons = this.onChangeCustomersContactPersons.bind(this);
    }

    onChangeCustomersContactPersons() {
        this.toggleAddContactPersonModal();

        const { onChange } = this.props;

        onChange();

    }

    setCustomerContactPersons(customerContacts) {

        const contacts = customerContacts.map(contactPerson => {

            const {
                id,
                firstName,
                lastName,
                position,
                isMainContact,
                email,
                phone,
                mobile
            } = contactPerson;

            return {
                id,
                firstName,
                lastName,
                position,
                isMainContact,
                email,
                phone,
                mobile
            };
        });

        return sortCustomerContactsByMainContact(contacts);

    }

    toggleAddContactPersonModal() {
        this.setState((prevState => {
            return {
                addContactPersonModalIsOpen: !prevState.addContactPersonModalIsOpen
            };
        }));
    }

    render() {

        const { addContactPersonModalIsOpen } = this.state;
        const { customerId, customerContacts, isLoading: isLoadingCustomerContactPersons } = this.props;

        const customerContactPersons = this.setCustomerContactPersons(customerContacts);

        return (
            <Fragment>
                <Section
                    title="Ansprechpartner"
                    type="list"
                    cta={
                        <IfUser
                            can={['update-customers']}
                        >
                            <button
                                className="btn btn--add-action"
                                onClick={this.toggleAddContactPersonModal}
                            >
                                <span className="btn__icon">
                                    <Icon name="add" />
                                </span>
                                <span>Ansprechpartner hinzufügen</span>
                            </button>
                        </IfUser>
                    }
                >
                    {customerContactPersons && <DataTable
                        entityLabel="Ansprechpartner"
                        isFetching={isLoadingCustomerContactPersons}
                        data={customerContactPersons}
                        columns={CustomerContactPersonsTableColumns}
                        RowComponent={CustomerContactPersonTableRow}
                        onItemChange={this.props.onChange}
                        onItemDelete={this.props.onChange}
                        collapsesAfter={5}
                    />
                    }
                </Section>

                {
                    addContactPersonModalIsOpen &&
                    <CustomerContactFormModal
                        onCreated={this.onChangeCustomersContactPersons}
                        customerId={customerId}
                        onClose={this.toggleAddContactPersonModal}
                        mode={FormMode.Create}
                        modalContext={MODAL_CONTEXT_STANDALONE}
                    />
                }
            </Fragment>
        );
    }
}


export default CustomerContactPersonOverview;

CustomerContactPersonOverview.propTypes = {
    customerId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customerContacts: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
};
