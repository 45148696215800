import * as types from '../../types';

const initialState = {
    isFetching: false,
    scaffoldingdata: {}
};

export default function scaffoldingReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_SCAFFOLDING_SUCCESS:
            return {
                ...state
            };
        case types.ADD_SCAFFOLDING_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case types.FETCH_SCAFFOLDING:
            return {
                ...state,
                isFetching: true
            };

        case types.FETCH_SCAFFOLDING_SUCCESS:
            return {
                ...state,
                isFetching: false,
                scaffoldingdata: action.payload
            };

        case types.FETCH_SCAFFOLDING_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: action.payload
            };

        default:
            return state;
    }
}
