import PropTypes from 'prop-types';
import React from 'react';
import NotAvailablePlaceholder from './NotAvailablePlaceholder';

const Address = ({ address, inline }) => {

    if (!address) {
        return <NotAvailablePlaceholder />;
    }

    const { line1, line2, zip, city } = address;

    if (inline) {
        return (
            <span className="address address--inline">
                <span className="address__l1">
                    {line1},
                </span>
                {line2 &&
                <span className="address__l2">
                    {line2},
                </span>
                }
                <span className="address__city">
                    {zip} {city}
                </span>
            </span>
        )
    }

    return (
        <div className="address">
            <div className="address__l1">
                {line1}<br />
            </div>
            {line2 &&
            <div className="address__l2">
                {line2}<br />
            </div>
            }
            <div className="address__city">
                {zip} {city}
            </div>
        </div>
    );
};

Address.propTypes = {
    address: PropTypes.object,
    inline: PropTypes.bool
};

Address.defaultProps = {
    inline: false
};

export default Address;
