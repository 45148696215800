import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { showApiError } from '../../redux/modules/error/action';
import Icon from './Icon';

export const ActionTypes = {
    clear: 'clear',
    createOption: 'create-option',
    deselectOption: 'deselect-option',
    popValue: 'pop-value',
    removeValue: 'remove-value',
    selectOption: 'select-option',
    setValue: ' set-value'
};

const DEBOUNCE_TIMEOUT = 500;

class AsyncAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            itemSelected: props.hasInitialOption,
            isInitialized: false
        };

        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.internalSelectionChange = this.internalSelectionChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.debouncedFetchOptions = _.debounce(this.fetchOptions, DEBOUNCE_TIMEOUT);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.updateKey !== undefined) {
            if (newProps.updateKey === '' || newProps.updateKey !== this.props.updateKey) {
                this.setState({
                    isInitialized: false,
                    itemSelected: false,
                    menuOpen: false
                });
            }
        }
    }

    onMenuOpen() {
        this.setState({
            menuOpen: true
        });
    }

    onMenuClose() {
        this.setState({
            menuOpen: false
        });
    }

    getOptions(input, callback) {
        return this.debouncedFetchOptions(input, callback);
    }

    toggleMenu() {
        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        this.setState(prevState => ({
            isInitialized: true,
            menuOpen: !prevState.menuOpen
        }));
    }

    fetchOptions(input, callback) {
        let querystring = '';

        if (input) {
            querystring = input;
        }

        // remove ' + " from input
        const preparedInput = querystring.replace(/['"]+/g, '');

        this.props.getValues(preparedInput)
            .then((options) => {
                const opts = options.map(option => ({
                    label: option.name,
                    value: option.name,
                    id: option.id
                }));
                callback(opts);
                this.setState({ isInitialized: true });
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    internalSelectionChange(selectedValue, actionType) {
        if (ActionTypes.selectOption === actionType.action) {
            this.setState({
                itemSelected: true
            });
        } else {
            this.setState({
                itemSelected: false,
                menuOpen: false
            });
        }

        this.props.onSelectionChange(selectedValue);
    }

    renderSelectComponent() {
        const { menuOpen, isInitialized } = this.state;

        if (!isInitialized && !this.props.hasInitialOption) {
            return null;
        }

        let initialOption;

        if (this.props.hasInitialOption) {
            initialOption = this.props.initialOption ? this.props.initialOption : {
                label: this.props.loadingMessagePreselected,
                value: 'placeholder_value'
            };
        }

        return (
            <AsyncSelect
                onChange={this.internalSelectionChange}
                loadOptions={this.getOptions}
                value={initialOption}
                isClearable
                isMulti={false}
                defaultOptions
                classNamePrefix="filterbar"
                loadingMessage={this.props.loadingMessage}
                noOptionsMessage={this.props.noOptionsMessage}
                onMenuOpen={this.onMenuOpen}
                onMenuClose={this.onMenuClose}
                menuIsOpen={menuOpen}
                backspaceRemovesValue={false}
                placeholder={this.props.placeholder}
            />
        );
    }

    render() {
        const { itemSelected, menuOpen, isInitialized } = this.state;
        const { disabled } = this.props;

        let wrapperClasses = ['filterbar__autocomplete'];

        if (menuOpen) {
            wrapperClasses.push('filterbar__is-expanded');
        } else {
            wrapperClasses = wrapperClasses.filter(item => item !== 'filterbar__is-expanded');
        }

        if (disabled) {
            wrapperClasses.push('filterbar__is-disabled');
        }

        if ((itemSelected && isInitialized) || this.props.hasInitialOption) {
            wrapperClasses.push('filterbar__item-selected');
        }

        return (
            <React.Fragment>
                <label className={wrapperClasses.join(' ')}>
                    <div
                        onClick={this.toggleMenu}
                        className="filterbar__autocomplete-label"
                    >
                        {this.props.label}
                        <span className="icon">
                            <Icon name="reactselectChevron" />
                        </span>
                    </div>
                    {this.renderSelectComponent()}
                </label>
            </React.Fragment>
        );
    }

}

export default AsyncAutocomplete;

AsyncAutocomplete.propTypes = {
    getValues: PropTypes.func,
    onSelectionChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    loadingMessage: PropTypes.func,
    noOptionsMessage: PropTypes.func
};

AsyncAutocomplete.defaultProps = {
    noOptionsMessage: () => 'Wir konnten keine Ergebnisse für Ihre Eingabe finden',
    loadingMessage: () => 'Lade Ergebnisse',
    getValues: () => false
};
