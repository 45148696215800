import PropTypes from 'prop-types';
import { Component, createElement } from 'react';

class ScrollSpyLink extends Component {

    static contextTypes = {
        scrollSpy: PropTypes.object
    };

    static propTypes = {
        sectionId: PropTypes.string.isRequired
    };

    state = {
        found: true
    };

    componentDidMount() {
        const el = document.getElementById(this.props.sectionId);
        if (el) {
            this.context.scrollSpy.registerLink(this.props.sectionId);
        } else {
            this.setState({
                found: false
            });
        }
    }

    handleClick() {
        const { sectionId } = this.props;
        this.context.scrollSpy.jumpToSection(sectionId);
    }

    render() {
        const { found } = this.state;
        if (!found) {
            return null;
        }

        const { sectionId, className, children, ...restProps } = this.props;
        const isCurrent = (sectionId === this.context.scrollSpy.currentSection);
        let classes = className || '';

        if (typeof children === 'function') {
            return children(isCurrent);
        }

        if (isCurrent) {
            classes += ' active';
        }

        return createElement('div', {
            className: classes,
            onClick: (ev) => this.handleClick(ev),
            ...restProps
        }, children);
    }
}

export default ScrollSpyLink;
