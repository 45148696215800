import createDecorator from 'final-form-focus';
import _ from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Form} from 'react-final-form';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {bindActionCreators} from 'redux';
import {ModelType} from '../../../properties';
import {showApiError, showRequestError} from '../../../redux/modules/error/action';
import {createJob, fetchJobFormData, updateJob} from '../../../redux/modules/job/actions';
import {BadRequestError} from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import ResourceNotFoundModal from '../../Common/Modal/ResourceNotFoundModal';
import Checkbox from '../../Forms/Checkbox';
import InputGroup from '../../Forms/InputGroup';
import {toMediaFormData} from '../../Forms/Media/utils';
import Section from '../../Layout/Section';
import {ModelMediaType} from '../../Media/properties';
import LoadingSpinner from '../../Page/LoadingSpinner';
import {JobType} from '../properties';
import JobBasicInfoFormPart from './JobBasicInfoFormPart';
import JobMediaFormPart from './JobMediaFormPart';

/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = input => !!(input && typeof input.focus === 'function');

/**
 * Gets all the inputs inside modal on the page
 */
const getAllInputs = () => {

    const inputFields = window.document.getElementById('job-modal')
                              .querySelectorAll('input, select');

    if (typeof inputFields === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(inputFields)
                .reduce((accumulator, input) => {
                    if (isFocusableInput(input)) {
                        return accumulator.concat(input);
                    }

                    return accumulator;
                }, []);
};

const focusOnError = createDecorator(getAllInputs);

function validate(isRequestForm) {
    return (values) => {
        const errors = {};

        if (!values.type && !values.status) {
            errors.status = `Bitte wählen Sie einen ${isRequestForm ? 'Anfragestatus' : 'Auftragsstatus'}`;
        }

        if (values.customerId && !values.customerContactPersonId) {
            errors.customerContactPersonId = 'Bitte wählen Sie einen Ansprechpartner';
        }

        return errors;
    };
}

export const MODE_CREATE = 'create';
export const MODE_EDIT = 'edit';

class JobFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: this.props.mode,
            type: props.isRequest ? JobType.Request : JobType.Job,
            jobId: this.props.id,
            job: null,
            formData: {
                name: '',
                description: '',
                attachments: [],
                createConstructionPlan: false,
            },
            uploadInProgress: false,
            isFetching: this.props.mode === MODE_EDIT,
            isFetchingCustomers: false,
            isSaving: false,
            initialized: false,
        };

        this.setUploadInProgress = this.setUploadInProgress.bind(this);
        this.onFetchingCustomers = this.onFetchingCustomers.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.getSubmitPayload = this.getSubmitPayload.bind(this);
    }

    componentWillMount() {
        const {isRequest, actions: {fetchJobFormData}} = this.props;
        const {jobId, mode} = this.state;

        if (mode === MODE_CREATE) {
            this.setState({
                initialized: true,
                isFetching: false,
            });
            return;
        }

        const typeLabel = isRequest ? 'Anfrage' : 'Auftrag';

        fetchJobFormData(jobId)
            .then((job) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.prepareFormData(job);
            })
            .catch((error) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                showRequestError(`${typeLabel} konnte nicht geladen werden`, error);

                this.setState({
                    isFetching: false,
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onFetchingCustomers(isFetching) {
        this.setState({
            isFetchingCustomers: isFetching,
        });
    }

    isRequestForm() {
        return this.state.type === JobType.Request;
    }

    getSubmitPayload(values) {
        if (!values) {
            return null;
        }

        const {
            name,
            status,
            description,
            offerDeadline,
            customerId,
            customerContactPersonId,
        } = values;

        const {type} = this.state;

        let payload = {
            name,
            type,
            status,
        };

        // Angebotsende bis
        payload.offerDeadline = offerDeadline ? moment(offerDeadline)
            .format() : null;

        // Anhänge
        if (values.attachments && _.isArray(values.attachments)) {
            payload.attachmentIds = values.attachments.map(attachment => attachment.id);
        }

        // Notizen
        payload.description = description || null;

        // Kunde
        payload.customerId = customerId || null;

        if (payload.customerId) {
            payload.customerContactId = customerContactPersonId;
        }

        return payload;
    }

    onSubmit(formData) {
        this.setState({isSaving: true});

        const payload = this.getSubmitPayload(formData);

        // Update Job
        if (this.props.mode === MODE_EDIT) {
            const {jobId} = this.state;

            const {actions: {updateJob}, onUpdated} = this.props;

            updateJob(jobId, payload)
                .then(job => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false,
                    });
                    toast.success('Änderungen wurden gespeichert', {autoClose: 2500});
                    const id = job.id;

                    if (id) {
                        onUpdated(id);
                    }
                })
                .catch((error) => {

                    if (error instanceof BadRequestError) {
                        ErrorService.handleBadRequestError(error, 'job');
                    }

                    showApiError(error);
                })
                .finally((id) => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false,
                    });
                });
        } else {

            const {actions: {createJob}, onCreated} = this.props;

            createJob(payload)
                .then(job => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false,
                    });

                    const jobLabel = this.isRequestForm() ? 'Neue Anfrage' : 'Neuer Auftrag';
                    toast.success(`${jobLabel} wurde gespeichert`, {autoClose: 2500});

                    const id = job.id;

                    if (id) {
                        onCreated(id, formData.createConstructionPlan);
                    }
                })
                .catch((error) => {

                    if (error instanceof BadRequestError) {
                        ErrorService.handleBadRequestError(error, 'job');
                    }
                    showApiError(error);
                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false,
                    });
                });
        }
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    setUploadInProgress(uploadInProgress) {
        this.setState({
            uploadInProgress,
        });
    }

    prepareFormData(job) {
        if (!job) {
            this.setState({
                job: null,
                formData: null,
                isFetching: false,
                initialized: true,
            });
            return;
        }

        const {
            name,
            status,
            type,
            customer,
            description,
            id,
            offerDeadline,
            customerContact,
        } = job;


        const attachments = job.attachments || [];
        const formData = {
            customerContactPersonId: customerContact && customerContact.id,
            status,
            name,
            customerId: customer && customer.id,
            attachments: !attachments ? [] : attachments.map(file => toMediaFormData(file, ModelType.Job, ModelMediaType.Job_Attachment)),
            description,
            offerDeadline: offerDeadline ? moment(offerDeadline) : null,
        };
        this.setState({
            job,
            type,
            jobId: id,
            formData,
            isFetching: false,
            initialized: true,
        });
    }

    renderButtonText() {
        const {mode, isSaving} = this.state;

        if (mode === MODE_EDIT) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }

        return isSaving ? 'Wird gespeichert ...' : 'Speichern';
    }

    render() {
        const {
            initialized,
            type,
            mode,
            job,
            formData,
            isSaving,
            isFetching,
            isFetchingCustomers,
            uploadInProgress,
            jobId,
        } = this.state;

        if (initialized && mode === MODE_EDIT && !job) {
            return (
                <ResourceNotFoundModal
                    title={`${this.isRequestForm() ? 'Anfrage' : 'Auftrag'} nicht gefunden`}
                    onClose={this.props.onClose}
                />
            );
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                validate={validate(this.isRequestForm())}
                initialValues={formData}
                decorators={[focusOnError]}
                mutators={{
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    },
                    resetField: ([name], state, utils) => {
                        utils.changeValue(state, name, () => '');
                    },
                    setField: ([name, value], state, utils) => {
                        utils.changeValue(state, name, () => value);
                    },
                }}
                render={({handleSubmit, pristine, values, form: {mutators}}) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            title={`${this.isRequestForm() ? 'Anfrage' : 'Auftrag'} ${mode === MODE_CREATE ? 'erstellen' : 'bearbeiten'}`}
                            id="job-modal"
                            footer={
                                <ModalFormFooter
                                    onSubmit={handleSubmit}
                                    isSubmitting={isSaving}
                                    onClose={() => this.onClose(pristine)}
                                    submitLabel={this.renderButtonText()}
                                    submitDisabled={isFetching || isFetchingCustomers || uploadInProgress}
                                />
                            }
                            loading={isFetching || isFetchingCustomers}
                            loadingLabel={`Lade ${this.isRequestForm() ? 'Anfragedaten' : 'Auftragsdaten'}`}
                        >
                            <Section
                                title="Allgemeine Angaben"
                            >
                                <JobBasicInfoFormPart
                                    onFetchingCustomers={this.onFetchingCustomers}
                                    mutators={mutators}
                                    type={type}
                                    values={values}
                                    mode={mode}
                                    jobId={jobId}
                                />
                            </Section>
                            {mode === MODE_CREATE &&
                            <Section
                                title="Baustelle & Gerüste"
                            >
                                <Checkbox
                                    name="createConstructionPlan"
                                    label="Baustelle & Gerüste verknüpfen oder planen"
                                />
                                <p className="input-hint">
                                    Verknüpfen Sie Ihren Auftrag mit bestehenden Gerüsten oder planen
                                    Sie ein neues Gerüst. Das Verknüpfungs-Tool startet, sobald Sie Ihren
                                    Auftrag erstellt haben.
                                </p>
                            </Section>
                            }
                            <Section
                                title="Dokumente"
                            >
                                <JobMediaFormPart
                                    attachments={values.attachments}
                                    attachmentsMutator={mutators.setAttachments}
                                />
                            </Section>
                        </Modal>
                    </form>
                )}
            />
        );
    }
}

JobFormModal.propTypes = {
    isRequest: PropTypes.bool,
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]),
    id: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

JobFormModal.defaultProps = {
    isRequest: false,
    mode: MODE_CREATE,
    id: null,
    onCreated: (id) => false,
    onUpdated: () => false,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        createJob,
        fetchJobFormData,
        updateJob,
    }, dispatch),
});

export default connect(null, mapDispatchToProps)(JobFormModal);
