import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class EqualHeight extends Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
        selector: PropTypes.string.isRequired,
        className: PropTypes.string,
        tagName: PropTypes.string,
    };

    static defaultProps = {
        tagName: 'div',
    };

    constructor({ tagName }) {
        super();
        this.RootTag = `${tagName}`;
    }

    componentDidMount() {
        window.addEventListener('resize', this.matchHeights);
        this.preloadAndRun();
    }

    componentDidUpdate() {
        this.preloadAndRun();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.matchHeights);
    }

    getContainerRef = node => {
        this.container = node;
    };

    getMaxHeight = els => Array.prototype.map.call(els, el => el.offsetHeight).reduce((pre, cur) => Math.max(pre, cur), -Infinity);

    preloadAndRun = () => {
        setTimeout(this.matchHeights, 0);
    };

    matchHeights = () => {

        // Container has not been rendered yet
        if(!this.container) {
            return;
        }

        const els = this.container.querySelectorAll(this.props.selector);
        els.forEach(el => {
            el.style.height = null;
        });

        const maxHeight = this.getMaxHeight(els);
        els.forEach(el => {
            el.style.height = `${maxHeight}px`;
        });
    };

    render() {
        return (
            <this.RootTag className={this.props.className} ref={this.getContainerRef}>
                {this.props.children}
            </this.RootTag>
        )
    }
}
