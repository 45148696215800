import React from 'react';
import Condition from '../../../Forms/Condition';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import Select from '../../../Forms/Select';
import { getConstructionTypeLabel, getPurposeGroupLabel } from '../../labels';
import { ConstructionType, PurposeGroup } from '../../properties';
import { isEmergencyStairway } from '../functions';
import { ConstructionTypeOptions } from '../options';
import { isForbidden, isRequired } from '../validate';

export const SectionConstructionTypeInput = ({ values }) => {
    const _isEmergencyStairway = isEmergencyStairway(values);
    const tooltip = _isEmergencyStairway ? '' : undefined;
    return (
        <InputGroup
            label="Ausführungsart"
            required={isRequired('constructionType', values)}
            tooltip={tooltip}
        >
            <Select
                name="constructionType"
                options={ConstructionTypeOptions}
                label="Ausführungsart*"
                disabled={_isEmergencyStairway || isForbidden('constructionType', values)}
            />

            <Condition when="constructionType" is={ConstructionType.SystemFree}>
                <InputHint>
                    Bitte laden Sie den Nachweis für Ihr verwendetes System
                    {' '}<a href="#section-form-upload-documents">im Dokumente-Bereich</a> hoch
                </InputHint>
            </Condition>

            <Condition when="purpose.groups" contains={PurposeGroup.EmergencyStairway}>
                <InputHint>
                    {getPurposeGroupLabel(PurposeGroup.EmergencyStairway)}n können nur mit der Ausführungsart "{getConstructionTypeLabel(ConstructionType.ModuleSystemScaffolding)}" erstellt werden
                </InputHint>
            </Condition>
        </InputGroup>
    );
}
