import 'moment/locale/de';
import moment from 'moment/moment';

export default class DateService {

    static getReadableDateWithMonthName(dateOrDateTime) {
        return moment(dateOrDateTime)
            .format('D. MMMM YYYY');
    }

    static getReadableDateTime(timeOrDate) {
        return moment(timeOrDate)
            .format('DD.MM.YYYY, HH:mm') + ' Uhr';
    }

    static getReadableDate(dateOrDateTime) {
        return moment(dateOrDateTime)
            .format('DD.MM.YYYY');
    }

    static isOlderThan(dateOrDateTime, days) {
        return moment()
            .startOf('day')
            .subtract(days, 'days')
            .isAfter(moment(dateOrDateTime)
                .startOf('day'));
    }

    static isWithinNext(dateOrDateTime, days) {
        return moment(dateOrDateTime)
            .isBetween(moment()
                .startOf('day'), moment()
                .add(days, 'days'), 'day', '[]');
    }
}


