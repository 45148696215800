import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from 'react-icons/lib/md/close';
import { autoFormatFileSize } from '../../../../utils';
import Icon from '../../../Common/Icon';
import UploadProgress from '../../../Common/UploadProgress';

// 30 MB
export const FILE_SIZE_WARNING_THRESHOLD = 30000000;

const MediaDropzoneFile = (props) => {


    const showFileSizeWarning = (fileSize) => {

        // File size in bytes
        if (fileSize <= FILE_SIZE_WARNING_THRESHOLD) {
            return null;
        }

        return (
            <div className="media-form__file-size-warning">
                <span className="media-form__file-size-warning-icn">
                    <Icon name="statealert" />
                </span>
                {`Große Dateien (> ${autoFormatFileSize(FILE_SIZE_WARNING_THRESHOLD)}) können zu langen Ladezeiten führen.`}
            </div>
        );
    };

    const { file, remove, fileUploadProgressList } = props;

    const progress = fileUploadProgressList && fileUploadProgressList[file.id];

    const isUploading = progress && progress < 100;

    const canBeRemoved = typeof remove === 'function';
    return (
        <div className="media-form__file">
            <span className="media-form__file-name">
                {file.name}
            </span>
            <span className="media-form__file-sep">–</span>
            <span className="media-form__file-size">
                {autoFormatFileSize(file.size)}
            </span>
            {showFileSizeWarning(file.size)}
            {
                canBeRemoved && !isUploading &&
                <span
                    className="media-form__file-remove"
                    onClick={(e) => {
                        e.stopPropagation();
                        remove(file);
                    }}
                >
                    <CloseIcon />
                </span>
            }
            <UploadProgress progress={progress} />
        </div>
    );
};

MediaDropzoneFile.propTypes = {
    file: PropTypes.object.isRequired,
    remove: PropTypes.func,
    uploadProgress: PropTypes.number,
    fileUploadProgressList: PropTypes.object
};

MediaDropzoneFile.defaultProps = {
    remove: undefined,
    fileUploadProgressList: {}
};

export default MediaDropzoneFile;
