import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import Icon from '../../../Components/Common/Icon';
import TopNav from '../../../Components/Page/TopNav';

const links = [
    {
        label: 'Übersicht',
        value: '/'
    },
    {
        label: 'Anfragen',
        value: '/requests'
    },
    {
        label: 'Aufträge',
        value: '/jobs'
    },
    {
        label: 'Kunden',
        value: '/customers'
    },
    {
        label: 'Baustellen',
        value: '/sites'
    },
    {
        label: 'Bautagebuch',
        value: '/diary'
    },
    {
        label: 'Kontoeinstellungen',
        value: '/account'
    },
    {
        label: 'Hilfe',
        value: '/help'
    }
];

class ResourceNotFoundErrorPage extends Component {

    handleClick(dest) {
        this.props.history.push(dest);
    }

    renderLinks(links) {
        return links.map((link, i) => (
            <span
                onClick={() => this.handleClick(link.value)}
                key={i}
            >
                {link.label}
            </span>
        ));
    }

    render() {
        const { showTopNav } = this.props;

        return (
            <Fragment>
                {showTopNav &&
                <TopNav />
                }
                <div className="app-error">
                    <div className="app-error__cnt">
                        <div className="app-error__body">
                            <div className="app-error__ttl">
                                <span>404</span>
                                <h2> Seite konnte nicht gefunden werden</h2>
                            </div>
                            <p>Entweder der Link ist fehlerhaft oder die Seite existiert nicht.
                                Versuchen Sie es stattdessen mal mit diesen Seiten:
                            </p>
                            <div className="app-error__recoverylinks">
                                {this.renderLinks(links)}
                            </div>
                        </div>
                        <div className="app-error__icon"><Icon name="question" /></div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

ResourceNotFoundErrorPage.propTypes = {
    showTopNav: PropTypes.bool
};

ResourceNotFoundErrorPage.defaultProps = {
    showTopNav: false
};

export default withRouter(ResourceNotFoundErrorPage);

