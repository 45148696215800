import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../../properties';
import { walkConstructionPlanSite } from '../../utils';
import ConstructionPlanSyncModalHeader from '../ConstructionPlanSyncModalHeader';
import ConstructionPlanSyncScaffoldingSummary from './ConstructionPlanSyncScaffoldingSummary';
import ConstructionPlanSyncSectionSummary from './ConstructionPlanSyncSectionSummary';
import ConstructionPlanSyncSiteSummary from './ConstructionPlanSyncSiteSummary';

const ConstructionPlanSyncSummary = ({ syncData, liveSite, resolvedMedia }) => {

    const renderSyncDiff = () => {
        const content = [];

        const { site } = syncData;

        walkConstructionPlanSite(site, liveSite, (modelType, model, referenceData) => {
            if (modelType === ModelType.Site) {
                content.push(
                    <ConstructionPlanSyncSiteSummary
                        key={model.id}
                        syncModel={model}
                        liveData={referenceData}
                        resolvedMedia={resolvedMedia}
                    />
                );
            } else if (modelType === ModelType.Scaffolding) {
                content.push(
                    <ConstructionPlanSyncScaffoldingSummary
                        key={model.id}
                        syncModel={model}
                        liveData={referenceData}
                        resolvedMedia={resolvedMedia}
                    />
                );
            } else if (modelType === ModelType.Section) {
                content.push(
                    <ConstructionPlanSyncSectionSummary
                        key={model.id}
                        syncModel={model}
                        liveData={referenceData}
                        resolvedMedia={resolvedMedia}
                    />
                );
            }
        });

        return content;
    };

    return (
        <Fragment>
            <ConstructionPlanSyncModalHeader
                left={() => (
                    <h2>Vorher</h2>
                )}
                right={() => (
                    <h2>Nachher</h2>
                )}
            />
            <div className="sync-summary__bd">
                {renderSyncDiff()}
            </div>
        </Fragment>

    );
};

ConstructionPlanSyncSummary.propTypes = {
    syncData: PropTypes.object.isRequired,
    liveSite: PropTypes.object
};

export default ConstructionPlanSyncSummary;
