import React from 'react';
import { Link } from 'react-router-dom';
import Permission from '../../../permissions';

export const SettingsKey = {
    UserSettings: 'userSettings',
    CompanySettings: 'companySettings',
    MiscSettings: 'miscSettings',
    Logout: 'logout'
};

export const sectionLinks = {
    [SettingsKey.UserSettings]: [
        {
            label: 'Kontodaten',
            target: '/me'
        }
    ],
    [SettingsKey.CompanySettings]: [
        {
            label: 'Stammdaten',
            target: '/account/profile',
            requiredPermission: Permission.UpdateBaseData
        },
        {
            label: 'Nutzerverwaltung',
            target: '/account/users',
            requiredPermission: Permission.UpdateUsers
        },
        {
            label: 'Abo & Rechnung',
            target: '/account/subscription',
            requiredPermission: Permission.UpdateSubscription
        }
    ],
    [SettingsKey.MiscSettings]: [
        {
            label: 'Hilfe & Anleitungen',
            target: '/help'
        },
        {
            label: 'Rechtliche Hinweise',
            target: '/account/misc'
        }
    ],
    [SettingsKey.Logout]: [
        {
            label: 'Abmelden',
            target: '/logout',
            secondaryContent: <Link className="settingsDropdown__changelog-link" to="/changelog">
                Version {process.env.REACT_APP_VERSION}
            </Link>,
            cssClass: 'settingsDropdown__logout-link'
        }
    ]
};
