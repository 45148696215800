import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    autocompleteScaffolding,
    fetchSelectedScaffolding,
    setFilterOption
} from '../../redux/modules/constructiondiary/action';
import AsyncAutocomplete from '../Common/AsyncAutocomplete';
import { showApiError } from '../../redux/modules/error/action';

class ScaffoldingAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.onSelectionChange = this.onSelectionChange.bind(this);

        this.state = {
            initialOption: undefined,
            hasInitialOption: false
        };
    }

    onSelectionChange(selectedValue) {
        this.setState({ hasInitialOption: false });

        this.props.onSelectionChange(selectedValue);
    }

    setInitialOption(value) {

        const initialOption = {
            label: value.name,
            value: value.id,
            id: value.id
        };

        this.props.actions.setFilterOption({
            key: 'scaffolding',
            value: initialOption
        });

        this.setState({
            initialOption
        });
    }

    componentWillMount() {
        const { initialValue, actions: { fetchSelectedScaffolding } } = this.props;

        if (!initialValue) {
            return;
        }

        if (initialValue) {
            this.setState({
                hasInitialOption: true
            });

            fetchSelectedScaffolding(initialValue)
                .then((value) => {
                    this.setInitialOption(value);
                })
                .catch((error) => {
                    showApiError(error);
                });
        }
    }

    render() {
        const {
            siteId, managed, disabled, actions: { autocompleteScaffolding }
        } = this.props;

        const { initialOption, hasInitialOption } = this.state;

        return (
            <AsyncAutocomplete
                getValues={() => autocompleteScaffolding(siteId, managed)}
                onSelectionChange={this.onSelectionChange}
                label="Gerüst"
                loadingMessagePreselected="Lade Gerüst ..."
                loadingMessage={() => 'Lade Gerüste ...'}
                placeholder="Gerüst wählen"
                disabled={disabled}
                hasInitialOption={hasInitialOption}
                initialOption={initialOption}
                // updateKey={this.props.updateKey}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteScaffolding,
        fetchSelectedScaffolding,
        setFilterOption
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ScaffoldingAutocomplete);

/*ScaffoldingAutocomplete.propTypes = {
    onSelectionChange: PropTypes.func,
    disabled: PropTypes.bool,
    siteId: PropTypes.string.isRequired,
    managed: PropTypes.bool
};

ScaffoldingAutocomplete.defaultProps = {
    onSelectionChange: () => false,
    disabled: true,
    managed: null
};*/
