import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { getPurposeGroupLabel } from '../../../../../Sections/labels';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceDiffOptionRows from '../../../Base/Input/SyncChoiceDiffOptionRows';
import SyncChoiceIdenticalRow from '../../../Base/Input/SyncChoiceIdenticalRow';
import SyncInputGroup from '../../../Base/Input/SyncInputGroup';
import { isIdenticalValue } from '../../../equals';
import { validateListChoiceComplete } from '../../../validation';
import { getDiffPurposeGroupOptionRows, getIdenticalPurposeGroupOptionRows } from '../functions';

const PurposeGroupsSyncChoice = ({ planValue, liveValue, value: currentFormValue, onChange, meta, showIdentical }) => {

    const onSelect = (selectedValue) => {
        onChange({
            ...currentFormValue,
            ...selectedValue
        });
    };

    const isSelected = (option) => {
        if (!currentFormValue || !option) {
            return false;
        }

        const optionKey = Object.keys(option)[0];

        if (typeof currentFormValue === 'string') {
            return optionKey === currentFormValue;
        }

        return optionKey in currentFormValue && option[optionKey] === currentFormValue[optionKey];
    };

    const renderPurposeGroupLabel = (groupValue) => {
        if (!groupValue) {
            return '-';
        }

        const purposeGroups = Object.keys(groupValue);
        if(purposeGroups.length > 1) {
            console.error(`Encountered multiple purpose groups on a single sync option entry: ${JSON.stringify(groupValue)}`);
        }

        const purposeGroup = purposeGroups[0];
        if(!groupValue[purposeGroup]) {
            return '-';
        }

        return getPurposeGroupLabel(purposeGroup);
    };

    const nothingSelected = (value) => {
        if(_.isEmpty(value)) {
            return true;
        }

        let isSelected = false;
        for (const prop in value) {
            if( value.hasOwnProperty( prop ) ) {
                isSelected |= value[prop];
            }
        }

        return !isSelected;
    };

    const renderIdenticalRows = (isIdentical) => {
        if(isIdentical && !showIdentical) {
            return null;
        }

        const identicalOptionRows = getIdenticalPurposeGroupOptionRows(planValue, liveValue);
        if (_.isEmpty(identicalOptionRows)) {
            return null;
        }

        return identicalOptionRows.map(optionRow => (
            <SyncChoiceIdenticalRow
                key={optionRow.key}
                leftValue={optionRow.left}
                rightValue={optionRow.right}
                renderValue={renderPurposeGroupLabel}
                showIdentical={!isIdentical || showIdentical}
            />
        ));
    };

    const isIdentical = isIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Verwendungszweck"
        >
            {renderIdenticalRows(isIdentical)}

            <SyncChoiceDiffOptionRows
                planValue={planValue}
                liveValue={liveValue}
                getOptionRows={getDiffPurposeGroupOptionRows}
                renderValue={renderPurposeGroupLabel}
                onSelect={onSelect}
                isSelected={isSelected}
            />
        </SyncInputGroup>
    );
};

PurposeGroupsSyncChoice.propTypes = {
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    showIdentical: PropTypes.bool
};

PurposeGroupsSyncChoice.defaultProps = {
    planValue: null,
    liveValue: null,
    value: null,
    showIdentical: true,
    onChange: () => false
};

export default asSyncChoiceField(PurposeGroupsSyncChoice, {
    allowNull: true,
    validate: validateListChoiceComplete()
});
