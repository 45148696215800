import React from 'react';

const SectionFooter = props => (
    <footer className="section__ft">
        <div className="section__ft-ct">
            {props.children}
        </div>
    </footer>
);

export default SectionFooter;
