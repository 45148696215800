import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {bindActionCreators} from 'redux';
import {ResourceViewMode} from '../../../Containers/Pages/Private/properties';
import Permission from '../../../permissions';
import {getAccountType} from '../../../redux/helper';
import {fetchSiteNotes} from '../../../redux/modules/site/action';
import DateService from '../../../Services/DateService';
import {autoFormatFileSize, getFileExtension, isImage} from '../../../utils';
import {AccountTypes} from '../../Account/subscriptionTypes';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import DataRow from '../../Common/DataRow';
import DeletedUserPlaceholder from '../../Common/DeletedUserPlaceholder';
import EmptyCardHint from '../../Common/EmptyCardHint';
import EmptyDataTableHint from '../../Common/EmptyDataTableHint';
import Icon from '../../Common/Icon';
import IfUser from '../../Common/IfUser';
import InvitedIndicator from '../../Common/InvitedIndicator';
import MultiLineText from '../../Common/MultiLineText';
import PrimaryColumn from '../../Common/PrimaryColumn';
import {ScaffoldingPageLink, SectionPageLink} from '../../Common/ResourcePageLink';
import ScaffoldingDropdown from '../../Common/ScaffoldingDropdown';
import Table from '../../Common/Table';
import ThirdPartyIndicator from '../../Common/ThirdPartyIndicator';
import Tooltip from '../../Common/Tooltip';
import UnmanagedResourceIndicator from '../../Common/UnmanagedIndicator';
import {JobType} from '../../Job/properties';
import ReferencedJobRequestsTable from '../../Job/Referenced/ReferencedJobRequestsTable';
import {ReferencedJobsTable} from '../../Job/Referenced/ReferencedJobsTable';
import Section from '../../Layout/Section';
import MediaList from '../../Media/MediaList';
import LoadingSpinner, {PageLoadingSpinner} from '../../Page/LoadingSpinner';
import SectionStatusBadge from '../../Sections/SectionStatusBadge';
import CommentForm from './CommentForm';
import SiteConstructionDiary from './SiteConstructionDiarySection';
import SiteInvitationsList from './SiteInvitation/SiteInvitationsList';

const getInitialState = props => ({
    siteComments: [],
    commentError: null,
    commentListIsExpanded: false,
    commentListLimit: true,
    originalCommentListLimit: 5,
    isFetchingSite: true,
    isFetchingSiteComments: false,
    isSendingComment: false,
});

class SiteDetail extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = getInitialState(props);
        this.renderCommentSection = this.renderCommentSection.bind(this);
        this.toggleCommentList = this.toggleCommentList.bind(this);
    }

    static renderImage(file) {
        return (
            <a href={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}>
                <img
                    alt="attachment-upload"
                    className="attachment-upload__file-image"
                    src={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}
                />
            </a>
        );
    }

    static renderStandzeit(scaffolding) {
        if (!scaffolding) {
            return '-';
        }

        if (!scaffolding.scheduledErection && !scaffolding.scheduledDismantling) {
            return 'n.a';
        }

        let start = 'n.a';
        let end = 'n.a.';
        if (scaffolding.scheduledErection) {
            start = moment(scaffolding.scheduledErection)
                .format('DD.MM.YYYY');
        }
        if (scaffolding.scheduledDismantling) {
            end = moment(scaffolding.scheduledDismantling)
                .format('DD.MM.YYYY');
        }

        return <React.Fragment>{start} -<br/>{end}</React.Fragment>;
    }

    static renderSectionList(scaffolding) {
        if (!scaffolding || !scaffolding.sections) {
            return '-';
        }

        const {sections} = scaffolding;
        if (sections.length === 0) {
            return '-';
        }

        return (
            <div className="section-links">
                {sections
                    .map(section => (
                        <SectionPageLink
                            key={section.id}
                            id={section.id}
                            className="section-status-link"
                        >
                            <span className="section-status-link__lbl">
                                {section.name}
                            </span>
                            <span className="section-status-link__badge">
                                <SectionStatusBadge
                                    section={section}
                                    small
                                />
                            </span>
                        </SectionPageLink>
                    ))}
            </div>
        )
    }

    static renderScaffoldingSub(scaffolding) {
        if (!scaffolding) {
            return null;
        }

        if (scaffolding.isThirdParty && !scaffolding.isInvited) {
            return <ThirdPartyIndicator size="small"/>;
        }

        if (scaffolding.isThirdParty && scaffolding.isInvited) {
            return <InvitedIndicator size="small"/>;
        }

        if (!scaffolding.managed) {
            return (<UnmanagedResourceIndicator
                label="Fremdes Gerüst"
                size="small"
            />);
        }

        return null;
    }

    static renderScaffoldings(scaffoldings) {
        if (!scaffoldings || scaffoldings.length === 0) {
            return 'n.a.';
        }

        return <ScaffoldingDropdown scaffoldings={scaffoldings}/>;
    }

    static renderPlaceholder(file) {
        return (
            <a
                className="attachment-upload__file-placeholder"
                href={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}
            >
                <span className="attachment-upload__file-icon"> <Icon name="document"/></span>
                <span
                    className="attachment-upload__file-extension"
                >{getFileExtension(file.fileName)}{', '} {autoFormatFileSize(file.size)} <span
                    className="attachment-upload__download-indicator"
                ><Icon name="download"/></span></span>
            </a>
        );
    }

    static renderNoteAttachments(noteAttachments) {
        const attachments = noteAttachments.map((file, index) =>
            (
                <div
                    className="attachment-upload__ctn"
                    key={index}
                >
                    {
                        isImage(file.mimeType) ?
                            SiteDetail.renderImage(file)
                            :
                            SiteDetail.renderPlaceholder(file)
                    }
                </div>
            ));

        return (
            <div className="comments__attachments">
                {attachments}
            </div>
        );
    }

    static renderSingleComment(note, key) {
        const wrapperClasses = ['comments__content'];
        const noteHasAttachments = note.attachments && note.attachments.length > 0;
        if (noteHasAttachments) {
            wrapperClasses.push('comments__content--has-attachments');
        }

        const commentLabel = (
            <div className="comments__label">
                <div>
                    {note.createdBy
                        ? `${note.createdBy.firstName} ${note.createdBy.lastName}`
                        : <DeletedUserPlaceholder/>
                    }
                </div>
                <small className="object-reference__details">
                    {DateService.getReadableDateTime(note.created)}
                </small>
            </div>
        );
        return (
            <DataRow
                label={commentLabel}
                key={key}
            >
                <div className={wrapperClasses.join(' ')}>
                    {noteHasAttachments && SiteDetail.renderNoteAttachments(note.attachments)}
                    {note.content && <MultiLineText text={note.content}/>}
                </div>
            </DataRow>
        );
    }

    componentDidMount() {
        const {site} = this.props;
        if (!site) {
            return;
        }

        this.fetchNotes();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        const {site} = this.props;

        if (prevProps.site !== site) {
            if (site && site.id) {
                this.fetchNotes();
            } else {
                this.setState({
                    siteComments: [],
                });
            }
        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    fetchNotes() {
        const {site} = this.props;
        if (!site || !site.id || site.isInvited || site.isThirdParty) {
            return;
        }

        this.setState({
            isFetchingSiteComments: true,
        });

        this.props.actions.fetchSiteNotes(site.id)
            .then((data) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    siteComments: data ? data.notes : [],
                    isFetchingSiteComments: false,
                });
            })
            .catch((error) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                toast.error(error, 2500);

                this.setState({
                    isFetchingSiteComments: false,
                });
            });
    }

    toggleCommentList() {
        this.setState(prevState => ({
            commentListLimit: !prevState.commentListLimit,
            commentListIsExpanded: !prevState.commentListIsExpanded,
        }));
    }

    renderCommentSection(notes) {
        const showToggleButton = notes && notes.length > this.state.originalCommentListLimit;

        const {viewMode} = this.props;


        const renderCommentList = (notes) => {
            if (!notes || notes.length < 1) {
                return null;
            }

            const orderedNotes = _.sortBy(notes, note => (moment(note.created)))
                                  .reverse();

            return orderedNotes.map((note, key) => {
                if (this.state.commentListLimit) {
                    return key < this.state.originalCommentListLimit && SiteDetail.renderSingleComment(note, key);
                }
                return SiteDetail.renderSingleComment(note, key);
            });
        };

        return (
            <div className="comments">
                {renderCommentList(notes)}

                {showToggleButton ?
                    <DataRow
                        containerClass="comments__list-toggle-wrapper"
                        label=""
                    >
                        <span
                            className="comments__list-toggle"
                            onClick={this.toggleCommentList}
                        >
                            {this.state.commentListIsExpanded ? 'Weniger Kommentare anzeigen' : 'Weitere Kommentare anzeigen'}
                        </span>
                    </DataRow>
                    :
                    null
                }
                {(notes && notes.length > 0) && <div className="separator"/>}

                {
                    viewMode === ResourceViewMode.Archived ?
                        <div className="comments__archived-hint">
                            Hinweis: Da diese Baustelle archiviert ist, steht Ihnen die
                            Kommentarfunktion nicht mehr zur Verfügung.
                        </div>
                        :
                        <CommentForm
                            siteId={this.props.site.id}
                            onSubmit={() => this.fetchNotes()}
                        />
                }
            </div>
        );
    }

    render() {
        const {site, accountType, viewMode, onJobChange} = this.props;
        const {isFetchingSiteComments} = this.state;

        const siteComments = this.state.siteComments || [];

        if (!site) {
            return null;
        }

        const {
            address,
            name,
            description,
        } = site;

        const scaffoldings = site.scaffoldings || [];
        const attachments = site.attachments || [];
        const jobs = site.jobs || [];

        return (
            <Fragment>
                <Section
                    title="Allgemeine Angaben"
                    id="generalInformation"
                >
                    <DataRow
                        label="Bezeichnung"
                    >
                        {name}
                        {(site.isThirdParty && !site.isInvited) ?
                            <ThirdPartyIndicator/> : null
                        }

                        {(site.isThirdParty && site.isInvited) ?
                            <InvitedIndicator/> : null
                        }

                        {viewMode === ResourceViewMode.Archived && <ArchivedBadge size="small"/>}

                    </DataRow>

                    <DataRow
                        label="Adresse"
                    >
                        {address &&
                        <div>
                            {address.line1}<br/>
                            {address.line2 ?
                                <Fragment>{address.line2}<br/></Fragment>
                                :
                                null
                            }
                            {address.zip} {address.city}
                        </div>
                        }
                    </DataRow>

                    <DataRow
                        label="Notizen"
                    >
                        <MultiLineText text={description || ''}/>
                    </DataRow>

                    {(!site.isThirdParty && accountType !== AccountTypes.basic) && (
                        <Fragment>
                            <div className="data-row--separator" />
                            <DataRow
                                label="Eingeladene Nutzer"
                            >
                                <SiteInvitationsList
                                    invitations={this.props.site && this.props.site.invitations}
                                    siteId={site.id}
                                    onUpdate={this.props.onUpdate}
                                    siteIsArchived={viewMode === ResourceViewMode.Archived}
                                />
                            </DataRow>
                        </Fragment>
                    )
                    }
                </Section>

                <Section
                    title="Gerüste"
                    type="list"
                    id="scaffoldings"
                    cta={
                        <IfUser
                            can={['create-scaffoldings']}
                            and={!site.isThirdParty && viewMode !== ResourceViewMode.Archived}
                        >
                            <button
                                onClick={this.props.toggleScaffoldingModal}
                                className="btn btn--add-action"
                            >
                                <span className="btn__icon"><Icon name="add"/></span>
                                <span>Neues Gerüst</span>
                            </button>
                        </IfUser>
                    }
                >
                    <Table
                        columns={['Bezeichnung', 'Geplante Standzeit', 'Gerüstabschnitte']}
                        rows={scaffoldings}
                        emptyComponent={
                            <EmptyDataTableHint
                                numColumns={3}
                                title="Es wurden noch keine Gerüste für diese Baustelle angelegt"
                            >
                                <p>Sie können ein neues Gerüst über den blauen &quot;Neues
                                   Gerüst&quot; Button am oberen rechten Rand dieser Box oder über
                                   den grünen &quot;Erstellen&quot; Button am oberen rechten Rand
                                   der Seite anlegen.
                                </p>

                                <p> Die technischen Spezifikationen Ihrer Gerüste können
                                    anschließend im Bereich &quot;Gerüstabschnitte&quot; auf der
                                    Gerüstdetailseite verwaltet werden.
                                </p>
                            </EmptyDataTableHint>
                        }
                    >
                        {
                            scaffolding => (
                                <tr key={scaffolding.id}>
                                    <PrimaryColumn
                                        link={
                                            <ScaffoldingPageLink
                                                id={scaffolding.id}
                                            >
                                                {scaffolding.name}
                                                {scaffolding.archived &&
                                                <ArchivedBadge size="small"/>}
                                            </ScaffoldingPageLink>
                                        }
                                        sub={SiteDetail.renderScaffoldingSub(scaffolding)}
                                    >
                                        <ScaffoldingPageLink
                                            id={scaffolding.id}
                                        >Details
                                        </ScaffoldingPageLink>
                                    </PrimaryColumn>
                                    <td>{SiteDetail.renderStandzeit(scaffolding)}</td>
                                    <td>{SiteDetail.renderSectionList(scaffolding)}</td>
                                </tr>
                            )
                        }
                    </Table>
                </Section>

                <IfUser
                    can={['read-jobs']}
                    and={!site.isThirdParty}
                >
                    <Section
                        title="Anfragen"
                        type="list"
                        classes="related-requests"
                        subline={
                            <Fragment>
                                Nur aktive Anfragen <Tooltip id="related-request-tt">Unter "Aktive
                                                                                     Anfragen" werden alle Anfragen
                                                                                     zusammengefasst, die in Scaffeye®
                                                                                     hinterlegt und nicht archiviert
                                                                                     sind.</Tooltip>
                            </Fragment>
                        }
                        id="related-requests"
                    >
                        <ReferencedJobRequestsTable
                            data={jobs ? jobs.filter((job) => job.type === JobType.Request && !job.archived) : []}
                            onItemChange={onJobChange}
                            emptyTableHint={<EmptyDataTableHint
                                numColumns={7}
                                title="Es wurden noch keine Anfragen mit dieser Baustelle verknüpft."
                            >
                                <p>
                                    Sie können Baustellen und Anfragen miteinander
                                    verknüpfen. Wählen Sie dazu die
                                    gewünschte Anfrage im Sidebar-Reiter
                                    {' '}<Link to="/requests">Anfragen</Link>{' '}
                                    aus und folgen Sie den Anweisungen
                                    der Sektion &quot;Baustellendaten&quot;.
                                </p>
                            </EmptyDataTableHint>}
                        />
                    </Section>

                    <Section
                        title="Aufträge"
                        type="list"
                        classes="related-jobs"
                        id="related-jobs"
                        subline={
                            <Fragment>
                                Nur aktive Aufträge <Tooltip id="related-jobs-tt">Unter "Aktive
                                                                                  Aufträge" werden alle Aufträge
                                                                                  zusammengefasst, die in Scaffeye®
                                                                                  hinterlegt und nicht archiviert
                                                                                  sind.</Tooltip>
                            </Fragment>
                        }
                    >
                        <ReferencedJobsTable
                            data={jobs ? jobs.filter((job) => job.type === JobType.Job && !job.archived) : []}
                            onItemChange={onJobChange}
                            emptyTableHint={<EmptyDataTableHint
                                numColumns={6}
                                title="Es wurden noch keine Aufträge mit dieser Baustelle verknüpft."
                            >
                                <p>
                                    Sie können Baustellen und Aufträge miteinander
                                    verknüpfen. Wählen Sie dazu den
                                    gewünschten Auftrag im Sidebar-Reiter
                                    {' '}<Link to="/jobs">Aufträge</Link>{' '}
                                    aus und folgen Sie den Anweisungen
                                    der Sektion &quot;Baustellendaten&quot;.
                                </p>
                            </EmptyDataTableHint>}
                        />
                    </Section>
                </IfUser>

                <IfUser
                    can={Permission.ReadConstructionDiaryEntries}
                >
                    <SiteConstructionDiary
                        site={site}
                        viewMode={viewMode}
                    />
                </IfUser>

                <Section
                    title="Dokumente"
                    id="documents"
                >
                    {attachments && attachments.length ?
                        <DataRow
                            label="Anhänge"
                            type="media"
                        >
                            <MediaList
                                media={attachments}
                                groupByVisibility={!site.isThirdParty}
                                emptyLabel="Keine Anhänge hinterlegt"
                            />
                        </DataRow>
                        :
                        <EmptyCardHint
                            title="Es wurden keine Dokumente für diese Baustelle hochgeladen."
                        >
                            <p>
                                Laden Sie zur Baustelle zugehörige Dokumente hoch, um diese hier
                                angezeigt zu bekommen.
                            </p>
                            <p>
                                Klicken Sie dazu auf &quot;Bearbeiten&quot; und wählen Sie Ihre
                                gewünschten Anhänge im Bereich &quot;Dokumente&quot; aus. Sie können
                                wählen, ob die Anhänge nur für Ihr Team oder für alle Nutzer von
                                Scaffeye® (öffentliches Dokument) sichtbar sein sollen.
                            </p>
                        </EmptyCardHint>
                    }
                </Section>

                {(!site.isThirdParty && accountType !== AccountTypes.basic) &&
                <Section
                    title="Kommentare"
                    id="comments"
                >
                    {isFetchingSiteComments ? <LoadingSpinner
                        label="Lade Kommentare"
                        block
                    /> : this.renderCommentSection(siteComments)}
                </Section>
                }

                <IfUser
                    can={['delete-sites']}
                    and={!site.isThirdParty}
                >
                    <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.props.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                            Baustelle löschen
                        </span>
                    </div>
                </IfUser>
            </Fragment>
        );

    }
}

SiteDetail.propTypes = {
    site: PropTypes.object,
    toggleEditModal: PropTypes.func.isRequired,
    toggleScaffoldingModal: PropTypes.func.isRequired,
    toggleConstructionDiaryEntryModal: PropTypes.func.isRequired,
    toggleDeleteModal: PropTypes.func.isRequired,
    onClickAddJob: PropTypes.func.isRequired,
    viewMode: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onJobChange: PropTypes.func,
    onUpdate: PropTypes.func,
};

SiteDetail.defaultProps = {
    site: null,
    loading: false,
    onJobChange: (job, changes) => false,
    onUpdate: () => false,
};

const mapStateToProps = (state) => {
    return {
        accountType: getAccountType(state),
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSiteNotes,
    }, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteDetail));
