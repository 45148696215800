import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Breadcrumb from '../../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../../Navigation/Breadcrumbs/BreadcrumbLink';

const ConstructionDiaryOverviewBreadcrumb = ({ collapse }) => {

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label="Baustellen"
                    path="/sites"
                    icon="scaffold"
                />
                <BreadcrumbLink
                    label="Bautagebuch-Einträge"
                    path="/diary"
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default ConstructionDiaryOverviewBreadcrumb;

ConstructionDiaryOverviewBreadcrumb.propTypes = {
    collapse: PropTypes.bool
};

ConstructionDiaryOverviewBreadcrumb.defaultProps = {
    collapse: false
};
