import PropTypes from 'prop-types';
import React from 'react';

const ConstructionPlanReferenceList = ({ data, children }) => {

    const listContent = (!data || data.length === 0) ? 'Keine' : data.map((item, index) => children(item, index));

    return (
        <div className="constructionplan-referencelist">
            {listContent}
        </div>
    )
};

ConstructionPlanReferenceList.propTypes = {
    data: PropTypes.array
};

ConstructionPlanReferenceList.defaultProps = {
    data: []
};

export default ConstructionPlanReferenceList;
