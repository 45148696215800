import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ModelType } from '../../../properties';
import InputGroup from '../../Forms/InputGroup';
import MediaField from '../../Forms/Media/Field/MediaField';
import MediaUploadButton from '../../Forms/Media/MediaUploadButton';
import MediaUploadModal from '../../Forms/Media/Modal/MediaFormModal';
import { ModelMediaType} from '../../Media/properties';
import SiteMediaFormPart from '../../Sites/Form/SiteMediaFormPart';
import { SectionStatus } from '../properties';

class SectionMediaFormPart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadModalIsOpen: false
        };

        this.openMediaUploadModal = this.openMediaUploadModal.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.closeMediaUploadModal = this.closeMediaUploadModal.bind(this);
    }

    openMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: true
        });
    }

    closeMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: false
        });
    }

    addAttachments(attachmentsToAdd) {

        if (!attachmentsToAdd || attachmentsToAdd.length === 0) {
            return;
        }

        const {
            values: { attachments },
            attachmentsMutator
        } = this.props;

        let newMediaList;

        if (attachments) {
            newMediaList = [
                ...attachments,
                ...attachmentsToAdd
            ];
        } else {
            newMediaList = [
                ...attachmentsToAdd
            ];
        }

        attachmentsMutator(newMediaList);
    }

    addUtilizationPlans(utilizationPlansToAdd) {

        if (!utilizationPlansToAdd || utilizationPlansToAdd.length === 0) {
            return;
        }

        const {
            values: { utilizationPlans },
            utilizationPlanMutator
        } = this.props;

        let newMediaList;

        if (utilizationPlans) {
            newMediaList = [
                ...utilizationPlans,
                ...utilizationPlansToAdd
            ];
        } else {
            newMediaList = [
                ...utilizationPlansToAdd
            ];
        }

        utilizationPlanMutator(newMediaList);
    }

    setAnchorProtocol(newAnchorProtocol) {
        const {
            anchorProtocolMutator
        } = this.props;

        anchorProtocolMutator([newAnchorProtocol]);
    }

    setProofOfStability(newProofOfStability) {
        const {
            proofOfStabilityMutator
        } = this.props;

        proofOfStabilityMutator([newProofOfStability]);
    }

    onUploaded(mediaList) {
        const addedAttachments = [];
        const addedUtilizationPlans = [];
        let anchorProtocol = null;
        let proofOfStability = null;

        mediaList.forEach((media) => {
            switch (media.modelMediaType) {
                case ModelMediaType.Section_UtilizationPlan:
                    addedUtilizationPlans.push(media);
                    break;

                case ModelMediaType.Section_AnchorProtocol:
                    anchorProtocol = media;
                    break;

                case ModelMediaType.Section_ProofOfStability:
                    proofOfStability = media;
                    break;

                case ModelMediaType.Scaffolding_Attachment:
                default:
                    addedAttachments.push(media);
                    break;
            }
        });

        this.addAttachments(addedAttachments);
        this.addUtilizationPlans(addedUtilizationPlans);

        if (anchorProtocol) {
            this.setAnchorProtocol(anchorProtocol);
        }

        if (proofOfStability) {
            this.setProofOfStability(proofOfStability);
        }
    }

    render() {
        const { uploadModalIsOpen } = this.state;
        const { isConstructionPlan, readOnly, values } = this.props;
        const isDraft = values?.status === SectionStatus.Draft;

        return (
            <Fragment>
                <InputGroup
                    label="Nutzungspläne"
                    type="media"
                >
                    <MediaField
                        name="utilizationPlans"
                        emptyListLabel="Kein Nutzungsplan vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup
                    label="Ankerprotokoll"
                    type="media"
                >
                    <MediaField
                        name="anchorProtocol"
                        emptyListLabel="Kein Ankerprotokoll vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup
                    label="Statiknachweis"
                    required={this.props.values.staticsCertificateNecessary && !(isConstructionPlan || isDraft)}
                    type="media"
                >
                    <MediaField
                        name="proofOfStability"
                        emptyListLabel="Kein Statiknachweis vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup
                    label="Anhänge"
                    type="media"
                >
                    <MediaField
                        name="attachments"
                        emptyListLabel="Keine Anhänge vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup>
                    <MediaUploadButton onClick={this.openMediaUploadModal}  disabled={readOnly} />
                </InputGroup>

                {uploadModalIsOpen &&
                    <MediaUploadModal
                        onUploading={this.props.onUploading}
                        onUploaded={this.onUploaded}
                        modelType={ModelType.Section}
                        onClose={this.closeMediaUploadModal}
                        isConstructionPlan={isConstructionPlan}
                    />
                }
            </Fragment>
        );
    }
}

SectionMediaFormPart.propTypes = {
    values: PropTypes.object.isRequired,
    onUploading: PropTypes.func.isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    anchorProtocolMutator: PropTypes.func.isRequired,
    utilizationPlanMutator: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

SiteMediaFormPart.defaultProps = {
    readOnly: false
};

export default SectionMediaFormPart;
