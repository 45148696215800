import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { bindActionCreators } from 'redux';
import Permission from '../../../permissions';
import { deleteSingleCustomer } from '../../../redux/modules/customer/action';
import { fetchCustomers } from '../../../redux/modules/customers/action';
import { showApiError, showRequestError } from '../../../redux/modules/error/action';
import AddressSearchDropdown from '../../Common/AddressSearchFilterDropdown';
import ContactPersonAutocomplete from '../../Common/Autocomplete/ContactPersonAutocomplete';
import CustomerAutocomplete from '../../Common/Autocomplete/CustomerAutocomplete';
import { AddButton } from '../../Common/Button/AddButton';
import DeleteModal from '../../Common/Modal/DeleteModal';
import EmptyTableHint from '../../Common/EmptyTableHint';
import IfUser from '../../Common/IfUser';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import Pagination from '../../Common/Pagination';
import PrimaryColumn from '../../Common/PrimaryColumn';
import { CustomerPageLink } from '../../Common/ResourcePageLink';
import LoadingSpinner from '../../Page/LoadingSpinner';
import PageTitle from '../../Page/PageTitle';
import CustomerBreadcrumb from '../Detail/CustomerBreadcrumb';
import CustomerFormModal from '../Form/CustomerFormModal';
import CustomersOverviewHeader from './CustomersTableHeader';
import PaginationTotal from '../../Common/PaginationTotal';

export const CUSTOMERS_QUERY_STRING_VARIABLE = 'customersQueryString';
const ITEMS_PER_PAGE_LIMIT = 15;

const urlPropsQueryConfig = {
    name: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'name'
    },
    sortBy: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'sortBy'
    },
    asc: {
        type: UrlQueryParamTypes.boolean,
        updateType: 'replaceIn',
        queryParam: 'sortAsc'
    },
    page: {
        type: UrlQueryParamTypes.number,
        updateType: 'replaceIn',
        queryParam: 'page'
    },
    contactPerson: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'contact'
    },
    address: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'address'
    }
};


class CustomersOverview extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            deleteModalIsOpen: false,
            showAddCustomerModal: false,
            isDeletingCustomer: false,
            entityToDelete: null,
            filters: {},
            sort: {
                field: 'name',
                direction: '+'
            },
            page: 1,
            loading: true,
            items: [],
            total: 0
        };

        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.setCustomerNameFilter = this.setCustomerNameFilter.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSetPage = this.onSetPage.bind(this);
        this.setContactPersonFilter = this.setContactPersonFilter.bind(this);
        this.setAddressFilter = this.setAddressFilter.bind(this);
        this.onAddCustomer = this.onAddCustomer.bind(this);
    }


    componentDidMount() {
        const {
            name, sortBy, asc, page, contactPerson, address
        } = this.props;

        const filters = {
            name: name || '',
            contactPerson: contactPerson || '',
            address: address || ''
        };

        let sortDirection;

        if (asc === undefined) {
            sortDirection = '+';
        } else {
            sortDirection = asc ? '+' : '-';
        }

        const sort = {
            field: sortBy || 'name',
            direction: sortDirection
        };

        this.setState({
            filters,
            sort,
            page: page || 1
        }, () => this.updateList());

    }

    deleteCustomer() {
        const { entityToDelete } = this.state;

        if (!entityToDelete) {
            return;
        }

        const {
            actions: { deleteSingleCustomer }
        } = this.props;

        this.setState({
            isDeletingCustomer: true
        });

        deleteSingleCustomer(entityToDelete.id)
            .then(() => {
                this.setState({
                    isDeletingCustomer: false
                });
                this.toggleDeleteModal();
                toast.success('Kunde wurde gelöscht', { autoClose: 2500 });
                this.updateList();
            })
            .catch((error) => {
                this.setState({
                    isDeletingCustomer: false
                });
                return showApiError(error);
            });
    }

    toggleDeleteModal(customer = null) {
        if (customer) {
            this.setState({
                deleteModalIsOpen: true,
                entityToDelete: customer
            });
            return;
        }

        this.setState({
            deleteModalIsOpen: false,
            entityToDelete: null
        });
    }

    setCustomerNameFilter(selection) {
        const value = selection ? selection.value : null;

        this.setState({
            filters: {
                ...this.state.filters,
                name: value || ''
            },
            page: 1
        }, () => {
            this.updateQueryAndList({
                name: value,
                page: null
            });
        });
    }

    setContactPersonFilter(selection) {
        const value = selection ? selection.value : null;

        this.setState({
            filters: {
                ...this.state.filters,
                contactPerson: value || ''
            },
            page: 1
        }, () => {
            this.updateQueryAndList({
                contactPerson: value,
                page: null
            });
        });
    }

    setAddressFilter(selection) {
        const value = selection || null;

        this.setState({
            filters: {
                ...this.state.filters,
                address: value || ''
            },
            page: 1
        }, () => {
            this.updateQueryAndList({
                address: value,
                page: null
            });
        });
    }

    updateQueryAndList(queryParamPatch) {

        this.props.onChangeUrlQueryParams({
            ...queryParamPatch
        });

        this.updateHistory();
        this.updateList();
    }

    updateHistory() {

        window.setTimeout(() => {
            const { history, location } = this.props;

            // Get current query string
            const currentQueryString = location.search || '';

            history.replace({
                ...location,
                state: {
                    [CUSTOMERS_QUERY_STRING_VARIABLE]: currentQueryString
                }
            });
        }, 100);
    }

    onSortChange(updatedSortByField, updatedSortDirection) {
        const { field, direction } = this.state.sort;

        if (field !== updatedSortByField) {
            this.props.onChangeSortBy(updatedSortByField);
            this.setState(prevState => ({
                sort: {
                    field: updatedSortByField,
                    direction: prevState.sort.direction
                }
            }), () => this.updateList());
        }

        if (direction !== updatedSortDirection) {
            this.props.onChangeAsc(updatedSortDirection !== '-');
            this.setState(prevState => ({
                sort: {
                    field: prevState.sort.field,
                    direction: updatedSortDirection
                }
            }), () => this.updateList());
        }
    }

    onAddCustomer() {
        this.setState({ showAddCustomerModal: false });
        this.updateList();
    }

    formatAddress(address) {

        if (!address || typeof address !== 'string') {
            return '';
        }

        return address.replace(',', '');
    }

    getFetchParams() {
        const {
            filters, sort, page
        } = this.state;

        return {
            name: filters.name,
            address: this.formatAddress(filters.address),
            contactPerson: filters.contactPerson,
            sortAsc: sort.direction !== '-',
            sortField: sort.field,
            page: page,
            limit: ITEMS_PER_PAGE_LIMIT
        };
    }

    updateList() {
        this.setState({
            loading: true
        });

        const fetchParams = this.getFetchParams();

        const { actions: { fetchCustomers } } = this.props;

        fetchCustomers(fetchParams)
            .then((result) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    items: result.data,
                    total: result.total,
                    loading: false
                });
            })
            .catch((error) => {
                showRequestError('Kunden konnten nicht geladen werden', error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    loading: false
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    renderEmptyTableHint() {

        if (!this.hasActiveFilters()) {
            return (
                <EmptyTableHint
                    numColumns={6}
                    title={`Keine Kunden vorhanden`}
                >
                    <p>
                        <strong>
                            Sie haben bisher noch
                            keine Kunden
                            erstellt.
                        </strong>
                    </p>
                    <p>
                        Beginnen Sie mit der Verwaltung
                        Ihrer Kunden, indem Sie diese
                        über den grünen &quot;Erstellen&quot; Button am oberen rechten Rand der
                        Seite anlegen.
                    </p>
                </EmptyTableHint>
            );
        }

        return (
            <EmptyTableHint
                numColumns={6}
                title={`Wir konnten keine passenden Kunden zu Ihrer Filterauswahl finden. Entfernen oder ändern Sie die Filtereinstellungen, um mehrere Ergebnisse zu erhalten.`}
            />
        );
    }

    renderBody() {
        const { loading, items } = this.state;

        if (loading) {
            return (
                <tr>
                    <td colSpan="6">
                        <LoadingSpinner
                            label={`Lade Kunden ...`}
                            block
                        />
                    </td>
                </tr>
            );
        }


        if (!items || !items.length) {
            return this.renderEmptyTableHint();
        }

        return items.map(item => this.renderRow(item));
    }

    renderRow(customer) {

        const activeJobsCount = customer.jobs.filter(job => job.type === 'job' && !job.archived).length;
        const activeRequestsCount = customer.jobs.filter(job => job.type === 'request' && !job.archived).length;

        return (
            <tr key={customer.id}>
                <PrimaryColumn
                    link={<CustomerPageLink id={customer.id}>{customer.name}</CustomerPageLink>}
                >
                    <CustomerPageLink id={customer.id}>Details</CustomerPageLink>
                    <IfUser
                        can={['delete-customers']}
                    >
                            <span
                                onClick={() => this.toggleDeleteModal({
                                    id: customer.id,
                                    name: customer.name
                                })}
                            >
                                Entfernen
                            </span>
                    </IfUser>
                </PrimaryColumn>
                <td>
                    {customer.customerId || <NotAvailablePlaceholder />}
                </td>
                <td>
                    <div>
                        {customer.line1}
                    </div>
                    {
                        customer.line2 &&
                        <div>
                            {customer.line2}
                        </div>
                    }
                    <div>{customer.zip} {customer.city}</div>
                </td>
                <td>
                    {this.renderContactPersonInformation(customer.mainContact)}
                </td>
                <td>{activeRequestsCount || <NotAvailablePlaceholder />} </td>
                <td>{activeJobsCount || <NotAvailablePlaceholder />} </td>
            </tr>
        );
    }

    renderContactPersonInformation(mainContact) {
        if (!mainContact) {
            return <NotAvailablePlaceholder />;
        }

        const { firstName, lastName, phone, email } = mainContact;

        return (
            <Fragment>
                <div>{firstName} {lastName}</div>
                <div>{email}</div>
                <div>{phone}</div>
            </Fragment>
        );

    }

    hasActiveFilters() {
        const { filters } = this.state;
        return filters.name;
    }

    onSetPage(page) {
        this.setState({
            page
        }, () => this.updateQueryAndList({ page }));
    }

    render() {
        const { name, contactPerson, address } = this.props;
        const { deleteModalIsOpen, sort, loading, total, page, isDeletingCustomer, entityToDelete, showAddCustomerModal } = this.state;

        return (
            <React.Fragment>
                <PageTitle title="Kunden">
                    <AddButton
                        label="Neuer Kunde"
                        neededPermissions={Permission.CreateCustomers}
                        onClick={() => this.setState({ showAddCustomerModal: true })}
                    />
                </PageTitle>

                <PaginationTotal total={total} standalone/>

                <div className="customers-table">
                    <div className="filterbar">
                        <div className="filterbar__heading"> Filter</div>

                        <CustomerAutocomplete
                            onChange={this.setCustomerNameFilter}
                            initialValue={name}
                            label="Name"
                        />
                        <ContactPersonAutocomplete
                            onChange={this.setContactPersonFilter}
                            initialValue={contactPerson}
                            label="Hauptkontakt"
                        />
                        <AddressSearchDropdown
                            onChange={this.setAddressFilter}
                            initialValue={address}
                            label="Adresse"
                        />
                    </div>

                    <div className="content-table standalone">
                        <table cellPadding="0" cellSpacing="0" className="hover stack">
                            <CustomersOverviewHeader
                                onChange={this.onSortChange}
                                sort={{
                                    field: sort.field,
                                    direction: sort.direction
                                }}
                            />
                            <tbody>
                                {this.renderBody()}
                            </tbody>
                        </table>
                        {loading ?
                            <div />
                            :
                            <Pagination
                                total={total}
                                amountPerPage={ITEMS_PER_PAGE_LIMIT}
                                setPage={this.onSetPage}
                                currentPage={page}
                            />
                        }
                    </div>
                </div>

                { showAddCustomerModal &&
                    <CustomerFormModal
                        onCreated={this.onAddCustomer}
                        onClose={() => this.setState({ showAddCustomerModal: false })}
                    />
                }

                {deleteModalIsOpen &&
                <DeleteModal
                    modalTitle="Kunde löschen"
                    deleteEntity={this.deleteCustomer}
                    closeModal={this.toggleDeleteModal}
                    isDeletingEntity={isDeletingCustomer}
                >
                    <p>Sie sind im Begriff, den Kunden
                        <Link
                            to={`/customers/${entityToDelete.id}`}
                        >
                            {' '} {entityToDelete.name}
                        </Link> zu
                        löschen.
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteModal>
                }
            </React.Fragment>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        deleteSingleCustomer,
        fetchCustomers
    }, dispatch)
});
export default withRouter(addUrlProps({ urlPropsQueryConfig })(connect(null, mapDispatchToProps)(CustomersOverview)));

