import React from 'react';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';
import OptionGroup from '../../../Forms/OptionGroup';
import { EquippedSurfacesOptions } from '../options';
import { isRequired } from '../validate';

export const SectionEquippedSurfacesInput =
    ({ readOnly, values }) => (
        <InputGroup
            label="Eingerüstete Flächen"
            required={isRequired('equippedSurfaces', values)}
        >
            <OptionGroup
                name="equippedSurfaces"
                options={EquippedSurfacesOptions}
                type="checkbox"
                readOnly={readOnly}
            />

            <Input
                type="text"
                name="equippedSurfacesOther"
                placeholder='z.B. "Vorne Erdgeschoss"'
                readOnly={readOnly}
            />
        </InputGroup>
    );
