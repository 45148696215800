import React from 'react';
import { Field } from 'react-final-form';
import FieldError from './FieldError';

const Error = ({ name }) => (
    <Field
        name={name}
        subscription={{
            touched: true,
            error: true,
            submitError: true
        }}
        render={({ meta }) => <FieldError meta={meta} />}
    />
);

export default Error;
