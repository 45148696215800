import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { generateConstructionDiaryExport } from '../../redux/modules/constructiondiary/action';
import { showApiError, showTempError } from '../../redux/modules/error/action';
import { getDatePeriodLabel } from '../../utils';
import Modal from '../Common/Modal/Modal';
import { DiaryCategories } from './properties';

class ConstructionDiaryExportModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            exportInProgress: false
        };
        this.prepareLogExport = this.prepareLogExport.bind(this);
    }

    showCategory(category) {
        switch (category) {
            case DiaryCategories.PsaDocumentation:
                return 'PSA-Dokumentation';

            case DiaryCategories.Construction:
                return 'Aufbau';

            case DiaryCategories.Dismantling:
                return 'Abbau';

            case DiaryCategories.ObjectDamaged:
                return 'Schaden am Objekt';

            case DiaryCategories.Repairs:
                return 'Instandsetzung / Reparatur';

            case DiaryCategories.WaitingPeriod:
                return 'Wartezeit';

            case DiaryCategories.Warehouse:
                return 'Lager';

            case DiaryCategories.Acceptance:
                return 'Freigabe';

            case DiaryCategories.Other:
                return 'Sonstiges';

            default:
                return 'Alle';
        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    prepareLogExport() {
        const { actions: { generateConstructionDiaryExport } } = this.props;

        this.setState({
            exportInProgress: true
        });

        generateConstructionDiaryExport()
            .then((response) => {

                if (response.status === 204) {
                    showTempError('Es wurden keine Bautagebucheinträge für die gesetzten Filter gefunden.');
                    return true;
                }

                const contentDisposition = response.headers.get('content-disposition');
                let fileName = 'unknown';
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2) {
                        fileName = fileNameMatch[1];
                    }
                }

                response.blob()
                    .then((blob) => {
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.props.closeModal();
                    });
            })
            .catch((error) => {
                showApiError(error);
            })
            .then(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    exportInProgress: false
                });
            });
    }

    renderBody() {
        const {
            siteName,
            scaffoldingName,
            sectionName,
            categoryName,
            date
        } = this.props;

        let time = 'Gesamt';

        if (date.dateFrom || date.dateTo) {
            time = getDatePeriodLabel(date.dateFrom, date.dateTo);
        }

        return (
            <React.Fragment>
                <div className="diary-export-modal__summary">
                    <h4>Aktive Filter</h4>
                    <div>Baustelle: {siteName}</div>
                    <div>Gerüst: {scaffoldingName}</div>
                    <div>Gerüstabschnitt: {sectionName}</div>
                    <div>Kategorie: {this.showCategory(categoryName)}</div>
                    <div>
                        Zeitraum: {time}
                    </div>
                </div>
                <div className="diary-export-modal__info">
                    <p>Möchten Sie die Tagebucheinträge, die den oben angegebenen Kriterien
                        entsprechen,
                        exportieren?
                        Sie können die Kriterien ändern, indem Sie die Filter auf der
                        Übersichtsseite
                        anpassen.
                    </p>

                    <p>Der Export kann einige Minuten in Anspruch nehmen. Bitte warten Sie einen
                        Augenblick und schließen Sie dieses Fenster während des Exportvorganges
                        nicht.
                    </p>

                    <p> Möchten Sie fortfahren?</p>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const {
            exportInProgress
        } = this.state;

        return (
            <Modal
                title="Einträge exportieren"
                type="micro-form"
                classes="diary-export-modal"
                onBackdropClick={() => this.props.closeModal()}
                footer={
                    <div className="btn-group">
                        <a
                            className="btn btn--backward-action"
                            onClick={this.props.closeModal}
                        >
                            Abbrechen
                        </a>
                        <button
                            className="btn btn--forward-action btn--save"
                            onClick={this.prepareLogExport}
                            disabled={exportInProgress}
                        >
                            {exportInProgress ? 'Wird exportiert ...' : ' Einträge exportieren'}
                        </button>
                    </div>
                }
            >
                {this.renderBody()}
            </Modal>
        );
    }

}

ConstructionDiaryExportModal.propTypes = {
    closeModal: PropTypes.func
};

ConstructionDiaryExportModal.defaultProps = {
    closeModal: () => {
    }
};

const mapStateToProps = ({ constructionDiary }) => {
    const {
        site, scaffolding, section, category, date
    } = constructionDiary.filter;

    return {
        siteName: site.label || 'Alle',
        scaffoldingName: scaffolding.label || 'Alle',
        sectionName: section.label || 'Alle',
        categoryName: category && category.value,
        date
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        generateConstructionDiaryExport
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionDiaryExportModal);
