import PropTypes from 'prop-types';
import React from 'react';
import {
    CustomerPageLink,
    ScaffoldingPageLink,
    SectionPageLink,
    SitePageLink
} from './ResourcePageLink';

const ResourceReference = ({ link, children, badge }) => (
    <div className="object-reference">
        <div className="object-reference__name">
            {link}
            {
                badge &&
                <span className="object-reference__badge">
                    {badge}
                </span>
            }
        </div>
        {children &&
        <div className="object-reference__details">
            {children}
        </div>
        }
    </div>
);

ResourceReference.propTypes = {
    link: PropTypes.element.isRequired,
    children: PropTypes.node,
    badge: PropTypes.element
};

ResourceReference.defaultProps = {
    badge: null,
    children: null
};


export const SiteReference = ({ id, label, children, badge }) => (
    <ResourceReference
        link={
            <SitePageLink id={id}>{label}</SitePageLink>
        }
        badge={badge}
    >
        {children}
    </ResourceReference>
);

export const ScaffoldingReference = ({ id, label, children, badge }) => (
    <ResourceReference
        link={
            <ScaffoldingPageLink id={id}>{label}</ScaffoldingPageLink>
        }
        badge={badge}
    >
        {children}
    </ResourceReference>
);

export const SectionReference = ({ id, label, children, badge }) => (
    <ResourceReference
        link={
            <SectionPageLink id={id}>{label}</SectionPageLink>
        }
        badge={badge}
    >
        {children}
    </ResourceReference>
);

export const CustomerReference = ({ id, label, children, badge }) => (
    <ResourceReference
        link={
            <CustomerPageLink id={id}>{label}</CustomerPageLink>
        }
        badge={badge}
    >
        {children}
    </ResourceReference>
);

