import _ from 'lodash';
import React from 'react';
import DateService from '../../Services/DateService';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import ScaffoldingDropdown from '../Common/ScaffoldingDropdown';
import Tooltip from '../Common/Tooltip';
import { ActiveRequestStatusOptions, JobType, NotifiableRequestStatusOptions } from './properties';

// collection of request status options considered as "active"
export const requestIsActive = requestStatus => (ActiveRequestStatusOptions.findIndex(status => status === requestStatus) !== -1);

// request status options that can indicate a warning hint reg. an expiring offer deadline
export const requestIsNotifiable = requestStatus => (NotifiableRequestStatusOptions.findIndex(status => status === requestStatus) !== -1);

export default function mapSectionsToScaffoldings(sections) {

    if (!sections || sections.length === 0) {
        return [];
    }

    return Object.values(sections.reduce((scaffoldings, section) => {

        if (!section.scaffolding) {
            return scaffoldings;
        }

        let scaffolding = {
            id: section.scaffolding.id,
            name: section.scaffolding.name,
            archived: section.scaffolding.archived
        };

        if (!scaffolding) {
            return scaffoldings;
        }

        if (!(section.scaffolding.name in scaffoldings)) {
            scaffoldings[section.scaffolding.name] = scaffolding;
        } else {
            scaffolding = scaffoldings[section.scaffolding.name];
        }

        if (!scaffolding.sections) {
            scaffolding.sections = [];
        }
        scaffolding.sections.push(section);

        return scaffoldings;
    }, {}));
}

export function isRequest(jobOrRequest) {
    return jobOrRequest && jobOrRequest.status && jobOrRequest.status.indexOf('request_') === 0;
}

export function countJobsAndRequests(jobsAndRequests) {
    let jobCount = 0;
    let requestCount = 0;

    if (jobsAndRequests && jobsAndRequests.length > 0) {
        _.each(jobsAndRequests, (jobOrRequest) => {
            if (isRequest(jobOrRequest)) {
                requestCount += 1;
            } else {
                jobCount += 1;
            }
        });
    }

    return {
        jobCount,
        requestCount
    };
}

export function getJobCountLabel(jobsAndRequests) {

    const { jobCount } = countJobsAndRequests(jobsAndRequests);

    if (jobCount === 0) {
        return 'Keine Aufträge';
    }

    let jobLabel = 'Aufträge';

    if (jobCount === 1) {
        jobLabel = 'Auftrag';
    }

    return `${jobCount} ${jobLabel}`;

}

export function getRequestCountLabel(jobsAndRequests) {

    const { requestCount } = countJobsAndRequests(jobsAndRequests);

    if (requestCount === 0) {
        return 'Keine Anfragen';
    }

    let reuestLabel = 'Anfragen';

    if (requestCount === 1) {
        reuestLabel = 'Anfrage';
    }

    return `${requestCount} ${reuestLabel}`;

}

export const renderScaffoldings = (scaffoldings) => {

    if (!scaffoldings || scaffoldings.length === 0) {
        return 'n.a.';
    }

    return (
        <ScaffoldingDropdown scaffoldings={scaffoldings} />
    );
};


export const renderOfferDeadLine = (offerDeadline, status) => {

    if (!offerDeadline) {
        return <NotAvailablePlaceholder />;
    }

    return <span className="offer-deadline">
        {
            DateService.getReadableDate(offerDeadline)
        }
        {
            DateService.isWithinNext(offerDeadline, 3) && requestIsNotifiable(status) &&
            <Tooltip icon="time" id={'offerDeadline'}>
                <span>Die Frist zur Abgabe eines Angebots für diese Anfrage läuft in 3 Tagen oder weniger ab.</span>
            </Tooltip>
        }
    </span>;

};

export function getJobTypeByJobStatusString(status) {

    if (status.includes('job_')) {
        return JobType.Job;
    }

    if (status.includes('request_')) {
        return JobType.Request;
    }

    return null;

}


