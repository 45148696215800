import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError } from '../../../redux/modules/error/action';
import {
    addNewScaffolding,
    fetchScaffoldingFormData,
    updateScaffolding
} from '../../../redux/modules/scaffolding/action';
import { BadRequestError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { userCan } from '../../../Services/UserService';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import ResourceNotFoundModal from '../../Common/Modal/ResourceNotFoundModal';
import { FormMode, FormModePropType } from '../../Forms/properties';
import ScaffoldingFormContent from './ScaffoldingFormContent';
import ScaffoldingManagedChoice from './ScaffoldingManagedChoice';
import { getAllInputs, getFormValues, getSubmitPayload, validate } from './utils';

const focusOnError = createDecorator(getAllInputs);

const initialFormValues = {
    attachments: [],
    utilizationPlans: [],
    anchorProtocol: []
};

class ScaffoldingFormModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        const formValues = {
            ...initialFormValues
        };

        if (this.props.mode === FormMode.Create) {
            formValues.siteId = this.props.siteId;
            formValues.managed = userCan(['create-managed-scaffoldings'], this.props.userPermissions);
        }

        this.state = {
            mode: props.mode,
            scaffoldingId: props.id,
            scaffolding: null,
            formValues,
            isFetchingFormData: false,
            isInitializing: props.mode === FormMode.Edit,
            isSaving: false
        };

        this.initializeForm = this.initializeForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.onUploading = this.onUploading.bind(this);
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    componentWillMount() {
        if (this.state.mode === FormMode.Create) {
            return;
        }

        this.setState({ isInitializing: true });
        const { scaffoldingId } = this.state;

        this.props.actions.fetchScaffoldingFormData(scaffoldingId)
            .then((scaffolding) => {
                this.initializeForm(scaffolding);
            });
    }

    handleFormSubmissionError(error) {

        if (error instanceof BadRequestError) {
            return ErrorService.handleBadRequestError(error, 'site')
                .then((errorMessages) => {
                    toast.error(Object.values(errorMessages)[0], { autoClose: 2500 });
                });
        }

        showApiError(error);

    }

    onSubmit(values) {
        const scaffoldingDTO = getSubmitPayload(values);

        this.setState({ isSaving: true });

        // EDIT mode
        if (this.props.mode === FormMode.Edit) {
            const { scaffoldingId } = this.state;
            this.props.actions.updateScaffolding(scaffoldingId, scaffoldingDTO)
                .then((scaffolding) => {
                    this.setState({
                        isSaving: false
                    });
                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });
                    return scaffolding.id;
                })
                .then((id) => {
                    if (id && typeof this.props.onUpdated === 'function') {
                        this.props.onUpdated(id);
                    }
                })
                .catch((error) => {
                    this.handleFormSubmissionError(error);
                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });


        } else {
            // CREATE mode
            this.props.actions.addNewScaffolding(scaffoldingDTO)
                .then((scaffolding) => {
                    this.setState({
                        isSaving: false
                    });
                    toast.success('Gerüst wurde angelegt', { autoClose: 2500 });
                    return scaffolding;
                })
                .then((scaffolding) => {
                    if (scaffolding.id && typeof this.props.onCreated === 'function') {
                        this.props.onCreated(scaffolding.id, values.createSection, scaffolding);
                    }
                })
                .catch((error) => {
                    this.handleFormSubmissionError(error);
                });
        }
    }

    initializeForm(scaffolding) {
        if (!scaffolding) {
            this.setState({
                scaffolding: null,
                formValues: {},
                isInitializing: false
            });
            return;
        }

        const formValues = getFormValues(scaffolding);

        this.setState({
            scaffolding,
            formValues,
            isInitializing: false
        });
    }

    renderButtonText() {
        const { mode, isSaving } = this.state;
        if (mode === FormMode.Edit) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }

        return isSaving ? 'Gerüst wird gespeichert ...' : 'Gerüst speichern';
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    onUploading(isUploading) {
        this.setState({
            isUploading
        });
    }

    render() {
        const {
            scaffolding,
            formValues,
            mode,
            isSaving,
            isInitializing,
            isFetchingFormData,
            isUploading
        } = this.state;

        const isEdit = mode === FormMode.Edit;

        if (isEdit && !isInitializing && !scaffolding) {
            return (
                <ResourceNotFoundModal
                    title="Gerüst nicht gefunden"
                    onClose={this.props.onClose}
                />
            );
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                validate={(values) => validate(values)}
                initialValues={formValues}
                decorators={[focusOnError]}
                mutators={{
                    setManaged: ([managed], state, utils) => {
                        utils.changeValue(state, 'managed', () => managed);
                    },
                    setUploadId: ([name, id], state, utils) => {
                        utils.changeValue(state, name, () => id);
                    },
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    },
                    setAnchorProtocol: ([newAnchorProtocol], state, utils) => {
                        utils.changeValue(state, 'anchorProtocol', () => newAnchorProtocol);
                    },
                    setUtilizationPlans: ([newUtilizationPlan], state, utils) => {
                        utils.changeValue(state, 'utilizationPlans', () => newUtilizationPlan);
                    }
                }}
                render={({
                             values, handleSubmit, pristine, form: { mutators }
                         }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            id="scaffolding-modal"
                            title={mode === FormMode.Create ? 'Neues Gerüst' : 'Gerüst bearbeiten'}
                            headerRight={<ScaffoldingManagedChoice onChange={mutators.setManaged} managed={values.managed} readonly={isEdit}/>}
                            footer={
                                <ModalFormFooter
                                    onSubmit={handleSubmit}
                                    isSubmitting={isSaving}
                                    onClose={() => this.onClose(pristine)}
                                    submitLabel={this.renderButtonText()}
                                    submitDisabled={isSaving || isInitializing || isFetchingFormData || isUploading}
                                />
                            }
                            loading={isInitializing}
                            loadingLabel="Lade Gerüstsdaten"
                        >
                            <ScaffoldingFormContent
                                mode={mode}
                                values={values}
                                attachmentsMutator={mutators.setAttachments}
                                anchorProtocolMutator={mutators.setAnchorProtocol}
                                utilizationPlanMutator={mutators.setUtilizationPlans}
                                onUploading={this.onUploading}
                            />
                        </Modal>
                    </form>
                )}
            />
        );
    }

}

ScaffoldingFormModal.propTypes = {
    mode: FormModePropType,
    id: PropTypes.string,
    siteId: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func
};

ScaffoldingFormModal.defaultProps = {
    mode: FormMode.Create,
    id: null,
    siteId: null,
    onCreated: null,
    onUpdated: null,
    onClose: () => false
};

const mapStateToProps = ({ currentUser }) => {
    const userPermissions = currentUser.usermeta.permissions ? currentUser.usermeta.permissions : [];

    return {
        userPermissions
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchScaffoldingFormData,
        addNewScaffolding,
        updateScaffolding
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ScaffoldingFormModal);
