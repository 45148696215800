import _ from 'lodash';
import { ConstructionPlanModelMode } from '../ConstructionPlan/properties';
import { getModelMediaType } from '../Forms/Media/utils';
import { MediaVisibility } from './properties';

const filterMediaByVisibility = (mediaList, visibility) => (
    _.filter(mediaList, media => media.visibility === visibility)
);

export const getPrivateMedia = mediaList => (
    filterMediaByVisibility(mediaList, MediaVisibility.AccountPrivate)
);

export const getPublicMedia = mediaList => (
    filterMediaByVisibility(mediaList, MediaVisibility.Public)
);

export const resolveMediaItem = (modelType, modelId, collectionKey, mediaItem, resolvedMedia, formMode = false) => {

    if(!mediaItem) {
        return null;
    }

    let resolvedMediaItem = {
        ...mediaItem
    };

    if (formMode) {
        resolvedMediaItem.modelType = modelType;
        resolvedMediaItem.modelId = modelId;
        resolvedMediaItem.modelMediaType = getModelMediaType(modelType, collectionKey);
    }

    if (!resolvedMedia) {
        return resolvedMediaItem;
    }

    if (!(mediaItem.id in resolvedMedia)) {
        return resolvedMediaItem;
    }

    const referenceData = resolvedMedia[mediaItem.id];
    resolvedMediaItem = {
        ...resolvedMediaItem,
        ...referenceData
    };

    if(mediaItem.mode === ConstructionPlanModelMode.Edit) {
        resolvedMediaItem.name = mediaItem.name;
    }

    return resolvedMediaItem;
};

export const resolveMediaCollection = (modelType, modelId, collectionKey, mediaCollection, resolvedMedia, formMode = false) => {
    if (!mediaCollection || mediaCollection.length === 0) {
        return [];
    }

    return mediaCollection.map(mediaItem => resolveMediaItem(modelType, modelId, collectionKey, mediaItem, resolvedMedia, formMode));
};

export const modelHasMedia = (obj, mediaKeys) => {
    if (!obj) {
        return false;

    }
    return mediaKeys.some(mediaKey => {
        return (obj[mediaKey] && !_.isEmpty(obj[mediaKey]));
    });
};

export const getMediaCollectionName = (collectionKey) => {
    switch (collectionKey) {
        case 'utilizationPlans':
            return 'Nutzungspläne';
        case 'proofOfStability':
            return 'Statiknachweis';
        case 'anchorProtocol':
            return 'Ankerprotokoll';
        default:
            return 'Anhänge';
    }
};
