import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

class Collapse extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: props.isCollapsed
        };
    }

    toggleCollapse() {
        this.setState(prevState => ({
            isCollapsed: !prevState.isCollapsed
        }));
    }

    getToggleTitle() {
        return `${this.props.title} ${this.state.isCollapsed ? 'aufklappen' : 'zuklappen'}`;
    }

    render() {

        const { isCollapsed } = this.state;
        const { title, children } = this.props;

        const collapseWrapperClasses = ['collapse'];

        if (isCollapsed) {
            collapseWrapperClasses.push('collapse--isCollapsed');
        }

        return (
            <div className={collapseWrapperClasses.join(' ')}>
                <div
                    className="collapse__hd"
                    onClick={() => this.toggleCollapse()}
                    role="button"
                    title={this.getToggleTitle()}
                >
                    <div className="collapse__hd-cnt" >
                        <div className="collapse__title">
                            {title}
                        </div>
                        <span className="collapse__icon">
                            {!isCollapsed
                                ?
                                <Icon name="chevronUp" />
                                :
                                <Icon name="chevronDown" />
                            }
                        </span>
                    </div>
                </div>
                <div
                    className="collapse-cnt"
                    aria-expanded={isCollapsed}
                >
                    {children}
                </div>
            </div>
        );
    }
}

Collapse.propTypes = {
    isCollapsed: PropTypes.bool,
    title: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired
};

Collapse.defaultProps = {
    isCollapsed: true
};

export default Collapse;
