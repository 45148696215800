import callApi, { query, uploadMedia } from '../../action';

export function fetchCompanyDocuments(sortOptions) {

    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        const args = {
            sortAsc: sortOptions.direction !== '-',
            sortField: sortOptions.field || 'name'
        };

        const q = `FetchDocuments($page:Int,$limit:Int){documents(limit:$limit,page:$page,sort:${args.sortField},sortAsc:${args.sortAsc},type:private){data {id,name,description,file{id,fileName,type,mimeType,size}},total,per_page}}`;

        return query(q, {
            token,
            params: {
                page: 1,
                limit: -1
            }
        })
            .then(({ documents }) => (documents.data));
    };
}

export function fetchPublicDocuments(sortOptions) {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        const args = {
            sortAsc: sortOptions.direction !== '-',
            sortField: sortOptions.field || 'name'
        };

        const q = `FetchDocuments($page:Int,$limit:Int){documents(limit:$limit,page:$page,sort:${args.sortField},sortAsc:${args.sortAsc},type:public){data {id,name,description,file{id,fileName,type,mimeType,size}},total,per_page}}`;

        return query(q, {
            token,
            params: {
                page: 1,
                limit: -1
            }
        })
            .then(({ documents }) => (documents.data));
    };
}

export const deleteSingleDocument = documentId => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    return callApi(`v1/documents/${documentId}`, {
        method: 'DELETE',
        token
    })
        .then(response => (response));
};

export const addDocument = (documentName, documentDescription, documentFile, progressFunction) => {

    const formData = new FormData();
    formData.append('document', documentFile);

    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        return uploadMedia('v1/documents/file', {
            token,
            body: formData,
            progressFunction
        })
            .then(({ data: { id } }) => {

                const documentData = {
                    name: documentName,
                    description: documentDescription,
                    fileId: id
                };
                return callApi('v1/documents', {
                    method: 'POST',
                    token,
                    body: documentData
                })
                    .then(res => res.json());
            });
    };
};


export const updateDocument = (documentName, documentDescription, documentId) => {

    const documentData = {
        name: documentName,
        description: documentDescription
    };

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        return callApi(`v1/documents/${documentId}`, {
            method: 'PUT',
            token,
            body: documentData
        })
            .then(response => response.json());
    };
};
