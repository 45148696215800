import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showApiError, showRequestError, showTempError } from '../../../redux/modules/error/action';
import { fetchConstructionPlanFormData, updateConstructionPlan } from '../../../redux/modules/job/actions';
import { BadRequestError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import { ConstructionPlanFormPropType } from './propTypes';
import { ConstructionPlanFormSiteChangeEvents } from './Site/ConstructionPlanFormSite';
import ConstructionPlanFormSiteField from './Site/ConstructionPlanFormSiteField';
import { toast } from 'react-toastify';

function validate(values) {
    return {};
}

class ConstructionPlanFormModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            isInitializing: true,
            isFetchingReferenceData: false,
            isSaving: false,
            initialFormValues: {
                site: null
            }
        };
        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onConstructionPlanFormSiteChange = this.onConstructionPlanFormSiteChange.bind(this);
    }

    componentWillMount() {

        const { jobId, constructionPlan, actions: { fetchConstructionPlanFormData } } = this.props;

        // Mostly for testing: a construction plan has been set
        if ( constructionPlan ) {
            this.initializeForm(constructionPlan);
            return;
        }

        fetchConstructionPlanFormData(jobId)
            .then((constructionPlan) => {
                if ( this.loadingGotCancelled ) {
                    return;
                }
                this.initializeForm(constructionPlan);
            })
            .catch(error => {
                showRequestError('Bauplan konnte nicht geladen werden', error);
                this.setState({
                    isInitializing: false
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onClose(pristine) {

        const { onClose } = this.props;

        if ( pristine ) {
            onClose();
            return;
        }

        if ( window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren') ) {
            onClose();
        }
    }

    handleFormSubmissionError(error) {

        this.setState({
            isSaving: false
        }, () => {
            if ( error instanceof BadRequestError ) {
                return ErrorService.handleBadRequestError(error, 'job')
                                   .then(errorMessages => (showTempError(Object.values(errorMessages)[0])));
            }
            return showApiError(error);
        });
    }

    initializeForm(constructionPlan) {

        if ( !constructionPlan ) {
            this.setState({
                initialFormValues: {
                    site: null
                },
                isInitializing: false
            });
            return;
        }

        const { site } = constructionPlan;

        const initialFormValues = {
            site
        };

        this.setState({
            initialFormValues,
            isInitializing: false
        });
    }

    getSuccessMessage() {
        const { initialFormValues: { site } } = this.state;

        const actionText = site ? 'aktualisiert' : 'erstellt';

        return `Die Planung der Baustelle wurde erfolgreich ${actionText}.`;
    }

    onSubmit(values) {

        const { site } = values;
        const { actions: { updateConstructionPlan }, jobId, onUpdate } = this.props;

        const constructionPlan = {
            site
        };

        this.setState({
            isSaving: true
        });


        return updateConstructionPlan(jobId, constructionPlan)
            .then(updatedJob => {
                if ( this.loadingGotCancelled ) {
                    return;
                }

                toast.success(this.getSuccessMessage(), { autoClose: 2500 });

                onUpdate(updatedJob);

                return updatedJob;
            })
            .catch((error) => {
                this.handleFormSubmissionError(error);
            })
            .finally(() => {

                if ( this.loadingGotCancelled ) {
                    return;
                }

                this.setState({
                    isSaving: false
                });
            });
    }

    onConstructionPlanFormSiteChange(constructionPlanFormSiteChangeEvent) {

        const { changeType, changeValue } = constructionPlanFormSiteChangeEvent;

        switch ( changeType ) {
            case ConstructionPlanFormSiteChangeEvents.InitializedArchivedSite:
                this.setState({
                    siteIsArchived: changeValue
                });
                break;
            case  ConstructionPlanFormSiteChangeEvents.IsFetchingReferenceData:
                this.setState({
                    isFetchingReferenceData: changeValue
                });
                break;
        }

    }

    render() {

        const { initialFormValues, isSaving, isInitializing, siteIsArchived, isFetchingReferenceData } = this.state;

        return (
            <Form
                onSubmit={this.onSubmit}
                initialValues={initialFormValues}
                validate={validate}
                render={({ handleSubmit, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            title={'Baustellenplanung'}
                            subtitle={'Bitte wählen Sie die Baustelle und zugehörige Gerüste zur Planung Ihrer Anfrage aus oder legen Sie neue Baustellen und Gerüste zu Planungszwecken an.'}
                            id="constructionplanner-formmodal"
                            loading={isInitializing}
                            loadingLabel="Lade Baustellenplanung"
                            collapseHeader
                            footer={
                                <ModalFormFooter
                                    onSubmit={handleSubmit}
                                    isSubmitting={isSaving}
                                    onClose={() => this.onClose(pristine)}
                                    submitLabel="Speichern"
                                    submitDisabled={isSaving || isInitializing || siteIsArchived || isFetchingReferenceData}
                                />
                            }
                        >
                            {
                                !isInitializing &&
                                <ConstructionPlanFormSiteField
                                    onConstructionPlanFormSiteChange={this.onConstructionPlanFormSiteChange}
                                />
                            }
                        </Modal>
                    </form>
                )}
            />
        );
    }
}

ConstructionPlanFormModal.propTypes = {
    jobId: PropTypes.string.isRequired,
    constructionPlan: ConstructionPlanFormPropType,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func
};

ConstructionPlanFormModal.defaultProps = {
    constructionPlan: undefined,
    onUpdate: () => false,
    onClose: () => false,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanFormData,
        updateConstructionPlan
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanFormModal);
