import PropTypes from 'prop-types';
import React from 'react';

const SepaInfo = ({
    sepaOwner, sepaLast4, ...componentProps
}) => (
    <div className="sepaInfo" {...componentProps}>
        {sepaOwner && <div className="sepaInfo__owner">{sepaOwner}</div>}
        <span className="sepaInfo__bankicon pf pf-sepa" />
        <span className="sepaInfo__number"> **** **** **** {sepaLast4}</span>
    </div>
);

export default SepaInfo;

SepaInfo.propTypes = {
    sepaOwner: PropTypes.string.isRequired,
    sepaLast4: PropTypes.string.isRequired
};

