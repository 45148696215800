import React, { Component, Fragment } from 'react';
import { Form } from 'react-final-form';
import Icon from '../../Common/Icon';
import Modal from '../../Common/Modal/Modal';
import InputGroup from '../../Forms/InputGroup';
import InputHint from '../../Forms/InputHint';
import TeamMemberRoleSelectField from './TeamMemberRoleSelectField';
import TeamMemberSelectField from './TeamMemberSelectField';
import { renderEmployeeRoleLabel } from './utils';

const VIEW_MODES = {
    add: 'add'
};

class ConstructionDiaryTeamMemberModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            teamMembers: props.value || [],
            newlyCreatedGroupUserOptions: [],
            currentTeamMemberToEdit: {}
        };

        this.renderTeamMemberList = this.renderTeamMemberList.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.addTeamMember = this.addTeamMember.bind(this);
        this.removeTeamMemberByName = this.removeTeamMemberByName.bind(this);
        this.handleInternalChange = this.handleInternalChange.bind(this);
        this.validate = this.validate.bind(this);
        this.openFreshModal = this.openFreshModal.bind(this);
    }

    static showTeamMemberRole(teamMember) {
        const { role } = teamMember;
        return renderEmployeeRoleLabel(role);
    }

    // Will be set in the render method
    handleSubmit = () => false;

    componentDidUpdate(prevProps) {
        if (this.props.value && prevProps.value === '') {
            this.setState({
                teamMembers: this.props.value
            });
        }
    }

    validate(values) {
        const errors = {};

        if (!values.user) {
            errors.user = 'Bitte geben Sie eine Mitarbeiterin / einen Mitarbeiter an';
        }

        if (!values.role) {
            errors.role = `Bitte geben Sie eine Rolle an`;
        }

        return errors;
    }

    handleInternalChange() {
        const { teamMembers } = this.state;
        this.props.onChange(teamMembers);
    }

    onSubmit(values) {

        const { user, role } = values;

        const teamMember = {};
        teamMember.name = user.name;
        teamMember.role = role;

        if (user.isNewlyCreated) {
            this.addToNewlyCreatedGroupUserOptions(user);
        }

        this.addTeamMember(teamMember);

    }

    addToNewlyCreatedGroupUserOptions(user) {

        const { name } = user;

        const newlyCreatedGroupUserOption = {
            label: name,
            value: name,
            name: name
        };

        this.setState((prevState) => {
            return {
                newlyCreatedGroupUserOptions: [...prevState.newlyCreatedGroupUserOptions, newlyCreatedGroupUserOption]
            };
        });

    }

    addTeamMember(invitee) {


        this.setState(prevState => ({
            teamMembers: [...prevState.teamMembers, invitee]
        }), () => {
            this.toggleModal();
            this.handleInternalChange();
        });
    }

    removeTeamMemberByName(name) {

        this.setState(prevState => ({
            teamMembers: prevState.teamMembers.filter(member => member.name !== name)
        }), () => {
            this.handleInternalChange();
        });
    }

    renderModal() {
        const { teamMembers } = this.state;

        let initialValues = {
            user: null,
            role: null
        };

        return (
            <Modal
                title="Mitarbeiter auswählen"
                onBackdropClick={this.toggleModal}
                type="upload"
                footer={(
                    <div className="btn-group">
                        <button
                            className="btn btn--backward-action"
                            onClick={this.toggleModal}
                        >
                            Abbrechen
                        </button>
                        <span className="btn btn--dummy">*Pflichtfeld</span>
                        <button
                            type="submit"
                            className="btn btn--forward-action btn--save"
                            onClick={(e) => this.handleSubmit(e)}
                        >
                            Mitarbeiter auswählen
                        </button>
                    </div>
                )}
            >
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={initialValues}
                    validate={this.validate}
                    render={({ handleSubmit }) => {
                        this.handleSubmit = handleSubmit;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="site-invitations">
                                    <InputGroup
                                        label="Mitarbeiter*"
                                    >
                                        <TeamMemberSelectField
                                            name="user"
                                            placeholder="Mitarbeiter angeben"
                                            selectedGroupUsers={teamMembers}
                                            newlyCreatedGroupUserOptions={this.state.newlyCreatedGroupUserOptions}
                                        />
                                        <InputHint showIcon>
                                            Durch das Anlegen neuer Mitarbeiter in diesem Bereich
                                            werden keine neuen Nutzerkonten erstellt.
                                        </InputHint>
                                    </InputGroup>
                                    <InputGroup
                                        label="Rolle*"
                                    >
                                        <TeamMemberRoleSelectField
                                            name="role"
                                            placeholder="Rolle angeben"

                                        />
                                    </InputGroup>
                                </div>
                            </form>
                        );
                    }}
                />
            </Modal>
        );
    }

    openFreshModal() {
        this.setState({
            currentTeamMemberToEdit: {},
            viewMode: VIEW_MODES.add
        }, () => this.toggleModal());
    }

    toggleModal() {
        this.setState(prevState => ({
            modalIsOpen: !prevState.modalIsOpen
        }));
    }

    renderTeamMemberList() {
        const { teamMembers } = this.state;

        if (!teamMembers || teamMembers.length < 1) {
            return (
                <div className="media-field__items-ct is-empty">
                    <div className="media-field__empty-lbl">
                        Keine Mitarbeiter ausgewählt
                    </div>
                </div>
            );
        }

        const listOfTeamMembers = teamMembers.map((member, index) => (
            <div className="construction-diary-team-members__list-item" key={index}>
                <div className="construction-diary-team-members__member-info">
                    <span
                        className="construction-diary-team-members__member-name"
                    >
                        {member.name}
                    </span>
                    <div className="construction-diary-team-members__member-role">
                        {ConstructionDiaryTeamMemberModal.showTeamMemberRole(member)}
                    </div>
                </div>
                <span
                    className="construction-diary-team-members__btn construction-diary-team-members__btn--delete"
                    onClick={() => this.removeTeamMemberByName(member.name)}
                >
                   <Icon name="removefile" />
                </span>
            </div>
        ));

        return (
            <div className="construction-diary-team-members__list">
                <span>{listOfTeamMembers}</span>
            </div>
        );
    }

    renderControls() {
        return (
            <button className="btn btn--upload" onClick={this.openFreshModal}>
                <span className="btn__icon">
                    <Icon name="add" />
                </span>
                <span className="btn__lbl">
                    Mitarbeiter hinzufügen
                </span>
            </button>
        );
    }

    render() {
        const { modalIsOpen } = this.state;

        if (modalIsOpen) {

            return (
                <Fragment>
                    {this.renderModal()}
                </Fragment>
            );
        }

        return (
            <div className="construction-diary-team-members">
                {this.renderTeamMemberList()}
                {this.renderControls()}
            </div>
        );
    }

}


export default ConstructionDiaryTeamMemberModal;
