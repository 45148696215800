import _ from 'lodash';
import 'moment/locale/de';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { autoFormatFileSize, getFileExtension, isImage } from '../../utils';
import ArchivedBadge from '../Common/Badge/ArchivedBadge';
import DataRow from '../Common/DataRow';
import DeletedUserPlaceholder from '../Common/DeletedUserPlaceholder';
import DeleteModal from '../Common/Modal/DeleteModal';
import EmptyDataTableHint from '../Common/EmptyDataTableHint';
import Icon from '../Common/Icon';
import IfUser from '../Common/IfUser';
import { ScaffoldingReference, SiteReference } from '../Common/ObjectReference';
import Table from '../Common/Table';
import Tooltip from '../Common/Tooltip';
import WeatherInfo from '../Common/WeatherInfo';
import Section from '../Layout/Section';
import PageTitle from '../Page/PageTitle';
import ConstructionDiaryEntryCategory from './ConstructionDiaryEntryCategory';
import DiaryEntryModal from './ConstructionDiaryEntryModal';
import { renderDiaryEntryTimeDuration, renderEmployeeRoleLabel } from './Form/utils';

class ConstructionDiaryEntryDetail extends Component {

    constructor(props) {
        super(props);
        this.renderEntryReference = this.renderEntryReference.bind(this);
    }

    renderEntryReference() {
        const {
            scaffoldingSelection, site, scaffoldings, sectionSelection, sections,
        } = this.props.entry;

        let scaffoldingReference = (
            <SiteReference
                id={site.id}
                label="Gesamte Baustelle"
            />
        );

        let sectionReference = scaffoldingSelection === 'all' ? '' :
            <span className="object-reference__details">Alle Gerüstabschnitte</span>;

        // applies to single scaffolding
        if (scaffoldingSelection === 'single') {

            const singleScaffolding = _.head(scaffoldings);

            scaffoldingReference = (
                <React.Fragment
                    key={singleScaffolding.id}
                >
                    <ScaffoldingReference
                        id={singleScaffolding.id}
                        label={singleScaffolding.name}
                        badge={singleScaffolding.archived && <ArchivedBadge size="small"/>}
                    />
                </React.Fragment>
            );
        }

        // applies to single section
        if (sectionSelection === 'single') {

            const singleSection = _.head(sections);

            const sectionPath = singleSection.archived ? `/archive/sections/${singleSection.id}` : `/sections/${singleSection.id}`;

            sectionReference = (
                <div className="job__section-list">
                    <span
                        key={singleSection.id}
                        className="job__section-lnk"
                    >
                        <Link
                            to={sectionPath}
                        >
                            {singleSection.name}
                            {singleSection.archived && <ArchivedBadge size="small"/>}
                        </Link>
                    </span>
                </div>
            );
        }

        return (
            <Fragment>
                <div> {scaffoldingReference} </div>
                <div> {sectionReference} </div>
            </Fragment>
        );
    }

    static renderImage(file) {
        return (
            <a href={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}>
                <img
                    alt="attachment-upload"
                    className="attachment-upload__file-image"
                    src={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}
                />
            </a>
        );
    }

    static renderEntryAttachments(attachments) {

        if (!attachments || attachments.length === 0) {
            return <small>Keine Anhänge vorhanden</small>;
        }

        return (
            <div className="diary-entry__description">
                <div className="comments__attachments">
                    {
                        attachments.map(file =>
                            (
                                <div
                                    className="attachment-upload__ctn"
                                    key={file.id}
                                >
                                    {
                                        isImage(file.mimeType) ?
                                            ConstructionDiaryEntryDetail.renderImage(file)
                                            :
                                            ConstructionDiaryEntryDetail.renderPlaceholder(file)
                                    }
                                </div>
                            ))
                    }
                </div>
            </div>
        );
    }

    static renderPlaceholder(file) {
        return (
            <a
                className="attachment-upload__file-placeholder"
                href={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}
            >
                <span className="attachment-upload__file-icon"> <Icon name="document"/></span>
                <span
                    className="attachment-upload__file-extension"
                >{getFileExtension(file.fileName)}{', '} {autoFormatFileSize(file.size)}
                </span>
            </a>
        );
    }

    static showUpdateStatus(created, updated, updatedBy) {
        if (!created || !updated || !updatedBy) {
            return null;
        }

        if (created === updated) {
            return null;
        }

        return (
            <div className="object-reference__details">
                Nachträglich bearbeitet {'  '}
                <Tooltip id="section-connected-jobs">
                    {`am ${moment(updated)
                        .format('DD.MM.YYYY, HH:mm')} Uhr von ${updatedBy.firstName} ${updatedBy.lastName}`}
                </Tooltip>
            </div>
        );
    }

    showEntryContent() {
        const {isAcceptanceEntry, entry: {message}, toggleInspectionModal} = this.props;

        if (isAcceptanceEntry) {
            return (
                <Fragment>
                    <div className="diary-entry__description">
                        <span className="diary-entry__state-ok"> <Icon name="stateok"/> </span>
                        <span> Es wurde eine erfolgreiche Freigabe durchgeführt.</span>
                    </div>
                    <span
                        className="btn--link-neutral"
                        onClick={() => toggleInspectionModal()}
                    >Freigabeprotokoll einsehen
                    </span>
                </Fragment>
            );
        }

        if (message) {
            return <div className="diary-entry__description">{message}</div>;
        }

        return <span className="object-reference__details">Keine Notizen vorhanden</span>;
    }

    render() {
        const {
            entry,
            isAcceptanceEntry,
            isFetching,
            isDeletingEntry,
            showEditModal,
            showDeleteModal,
        } = this.props;

        if (!isFetching && !entry) {
            return <ResourceNotFoundErrorPage/>;
        }

        if (!entry) {
            return null;
        }

        const {
            created,
            site,
            category,
            createdBy,
            attachments,
            updated,
            updatedBy,
            weather,
            group,
            arrivalDuration,
            departureDuration,
            workingDuration,
            otherDurations,
        } = entry;

        return (
            <Fragment>
                {
                    !isFetching && (
                        <Fragment>
                            <PageTitle title="Bautagebucheintrag"/>

                            <Section
                                title="Allgemeine Angaben"
                                id="generalInformation"

                                cta={
                                    <IfUser
                                        can={['update-construction-diary-entries']}
                                        and={!isAcceptanceEntry && !site.archived}
                                    >

                                        <button
                                            className="btn btn--neutral-action"
                                            onClick={this.props.toggleEditModal}
                                        >
                                            <span className="btn__icon">
                                                <Icon name="edit"/>
                                            </span>
                                            <span>Bearbeiten</span>
                                        </button>
                                    </IfUser>
                                }
                            >
                                <DataRow
                                    label="Erstellt am"
                                >
                                    {`${moment(created)
                                        .format('DD.MM.YYYY, HH:mm')} Uhr`}
                                    {ConstructionDiaryEntryDetail.showUpdateStatus(created, updated, updatedBy)}
                                </DataRow>

                                <DataRow
                                    label="Baustelle"
                                >
                                    <SiteReference
                                        id={site.id}
                                        label={site.name}
                                        badge={site.archived ? <ArchivedBadge size="small"/> : null}
                                    >
                                        {site.address.line1} {site.address.zip} {site.address.city}
                                    </SiteReference>
                                </DataRow>

                                <DataRow label="Eintrag gilt für">
                                    {this.renderEntryReference()}
                                </DataRow>

                                <DataRow label="Kategorie">
                                    <ConstructionDiaryEntryCategory
                                        showTooltip
                                        category={category}
                                    />
                                </DataRow>

                                <DataRow label="Protokollant">
                                    {createdBy
                                        ? `${createdBy.firstName} ${createdBy.lastName}`
                                        : <DeletedUserPlaceholder/>
                                    }
                                </DataRow>

                                {!isAcceptanceEntry &&
                                <DataRow label="Wetter">
                                    {
                                        weather && <WeatherInfo weather={weather}/>
                                    }
                                </DataRow>
                                }

                                {showEditModal &&
                                <DiaryEntryModal
                                    mode="edit"
                                    id={this.props.diaryEntryId}
                                    onClose={this.toggleEditModal}
                                    onUpdated={this.onUpdated}
                                />
                                }

                                {showDeleteModal &&
                                <DeleteModal
                                    modalTitle="Eintrag löschen"
                                    deleteEntity={this.deleteEntry}
                                    closeModal={this.toggleDeleteModal}
                                    isDeletingEntity={isDeletingEntry}
                                >
                                    <p>
                                        Sie sind im Begriff, den derzeit ausgewählten Bautagebucheintrag zu
                                        löschen. Damit verlieren Sie sämtliche Daten des Eintrages.
                                    </p>
                                    <p>Möchten Sie trotzdem fortfahren?</p>
                                </DeleteModal>
                                }

                            </Section>

                            <Section
                                title="Eintrag"
                                id="diaryEntry"
                            >
                                <DataRow label="Notizen">
                                    {this.showEntryContent()}
                                </DataRow>

                                {!isAcceptanceEntry &&
                                <DataRow label="Anhänge">
                                    {ConstructionDiaryEntryDetail.renderEntryAttachments(attachments)}
                                </DataRow>
                                }
                            </Section>
                            {!isAcceptanceEntry &&
                            <Section
                                title="Zeitaufwand"
                                id="time"
                            >
                                <DataRow label="Anfahrt">
                                    {renderDiaryEntryTimeDuration(arrivalDuration)}
                                </DataRow>

                                <DataRow label="Abfahrt">
                                    {renderDiaryEntryTimeDuration(departureDuration)}
                                </DataRow>

                                <DataRow label="Arbeitszeit">
                                    {renderDiaryEntryTimeDuration(workingDuration)}
                                </DataRow>

                                <DataRow label="Sonstige Angaben">
                                    {
                                        otherDurations ?
                                            <div className="diary-entry__description">
                                                {otherDurations}
                                            </div>
                                            :
                                            null
                                    }
                                </DataRow>
                            </Section>
                            }
                            {!isAcceptanceEntry &&
                            <Section
                                title="Anwesende Kolonne"
                                type="list"
                                id="team"
                            >
                                <Table
                                    columns={['Name', 'Rolle']}
                                    rows={group || []}
                                    emptyComponent={
                                        <EmptyDataTableHint
                                            numColumns={6}
                                            title="Es wurden noch keine Angaben zur anwesenden Kolonne gemacht"
                                        >
                                        </EmptyDataTableHint>
                                    }
                                >
                                    {
                                        (groupMember, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>
                                            <span
                                                style={{fontWeight: 600}}
                                            >{groupMember.name}</span>
                                                    </td>
                                                    <td>
                                                        {renderEmployeeRoleLabel(groupMember.role)}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    }
                                </Table>

                            </Section>
                            }
                            <IfUser
                                can={['delete-scaffoldings']}
                            >
                                <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.props.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                                Bautagebucheintrag löschen
                        </span>
                                </div>
                            </IfUser>
                        </Fragment>
                    )
                }

            </Fragment>
        );
    }
}


export default ConstructionDiaryEntryDetail;


ConstructionDiaryEntryDetail.propTypes = {
    toggleEditModal: PropTypes.func.isRequired,
    toggleDeleteModal: PropTypes.func.isRequired,
    toggleInspectionModal: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    entry: PropTypes.object,
    isFetching: PropTypes.bool,
    isAcceptanceEntry: PropTypes.bool,
    isDeletingEntry: PropTypes.bool,
};

ConstructionDiaryEntryDetail.defaultProps = {
    entry: null,
    isFetching: false,
    isAcceptanceEntry: false,
    isDeletingEntry: false,
};
