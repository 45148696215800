import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { RequestTableRow } from '../Overview/RequestTableRow';
import { getRequestTableColumns } from '../properties';
import { ReferencedJobsTableBase } from './ReferencedJobsTableBase';

const ReferencedJobRequestsTable = ({ data, onItemChange, emptyTableHint }) => (
    <ReferencedJobsTableBase
        data={data}
        onItemChange={onItemChange}
        emptyTableHint={emptyTableHint}
        columns={getRequestTableColumns()}
        RowComponent={RequestTableRow}
        entityLabel="Anfragen"
        footerLink={<Link to="/requests">Alle Anfragen anzeigen</Link>}
    />
);

export default ReferencedJobRequestsTable;

ReferencedJobRequestsTable.propTypes = {
    data: PropTypes.array.isRequired,
    emptyTableHint: PropTypes.element.isRequired,
    onItemChange: PropTypes.func
};
