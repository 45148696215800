/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = input => !!(input && typeof input.focus === 'function');

/**
 * Gets all the inputs inside modal on the page
 */
export const getAllInputs = () => {

    const inputFields = window.document.getElementById('section-modal')
        .querySelectorAll('input, select');

    if (typeof inputFields === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(inputFields)
        .reduce((accumulator, input) => {
            if (isFocusableInput(input)) {
                return accumulator.concat(input);
            }

            return accumulator;
        }, []);
};
