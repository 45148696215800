import _ from 'lodash';
import callApi, { query, querySingle } from '../../action';
import * as types from '../../types';
import { showRequestError } from '../error/action';

/*
 * List
 */
export const getConstructionDiaryEntries = () => ({
    type: types.FETCH_CONSTRUCTION_DIARY
});

export const setCurrentPageNumber = page => ({
    type: types.SET_CURRENT_CONSTRUCTION_DIARY_PAGE,
    payload: page
});

export const setFilterOption = option => ({
    type: types.SET_CONSTRUCTION_DIARY_FILTER_OPTION,
    payload: option
});

export const getConstructionDiaryEntriesSuccess = data => ({
    type: types.FETCH_CONSTRUCTION_DIARY_SUCCESS,
    payload: data
});

export const getConstructionDiaryEntriesFailure = err => ({
    type: types.FETCH_CONSTRUCTION_DIARY_FAILURE,
    error: err
});

export const resetFilterOption = option => ({
    type: types.RESET_CONSTRUCTION_DIARY_FILTER_OPTION,
    payload: option
});

export const fetchConstructionDiaryTotal = (total) => ({
    type: types.FETCH_CONSTRUCTION_DIARY_TOTAL,
    payload: total
});

export function fetchConstructionDiaryEntries(args = {}) {
    return (dispatch, getState) => {
        dispatch(getConstructionDiaryEntries());

        const token = getState().currentUser.auth_token;
        return query(`FetchConstructionDiaries($page:Int,$limit:Int,$from:String,$to:String,$siteId:String,$scaffoldingId:String,$sectionId:String){
                constructionDiaryEntries(limit:$limit,page:$page,sortAsc:${args.sortAsc},category:${args.category},fromDate:$from,toDate:$to,siteId:$siteId,scaffoldingId:$scaffoldingId,sectionId:$sectionId) {
                    data {
                        id,
                        site {id, name, archived},
                        scaffoldings {id, name},
                        scaffoldingSelection,
                        sections {id, name},
                        sectionSelection,
                        category,
                        createdBy {firstName, lastName},
                        created,
                    },
                    total,
                    per_page
                }
            }`, {
            token,
            params: {
                page: args.page,
                limit: args.limit,
                category: args.category,
                from: args.fromDate,
                to: args.toDate,
                siteId: args.siteId,
                scaffoldingId: args.scaffoldingId,
                sectionId: args.sectionId
            }
        })
            .then((data) => {
                dispatch(getConstructionDiaryEntriesSuccess(data.constructionDiaryEntries));
                return data.constructionDiaryEntries;
            })
            .catch((error) => {
                dispatch(getConstructionDiaryEntriesFailure(error));
            });
    };
}

export function fetchingConstructionDiaryTotal() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(`FetchConstructionDiaries($page:Int,$limit:Int){
                constructionDiaryEntries(limit:$limit,page:$page,) {
                    total
                }
            }`, {
            token,
            params: {
                page: 1,
                limit: 1
            }
        }).then(data => {
            dispatch(fetchConstructionDiaryTotal(data.constructionDiaryEntries.total))
        }).catch(error => {
            showRequestError('Bautagebucheinträge konnten nicht geladen werden', error);
        })
    };
}

export const deleteSingleDiaryEntry = diaryEntryId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        return callApi(`v1/construction-diary-entries/${diaryEntryId}`, {
            method: 'DELETE',
            token
        })
            .then(data => data);
    }
);

export const fetchDiaryEntryDetailData = entryId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchConstructionDiaryEntry{constructionDiaryEntry(id:"${entryId}"){
                id,
                message,
                category,
                attachments {id, fileName, size, mimeType},
                site {id, name, address{line1, zip, city}, archived},
                scaffoldings {id, name, archived},
                scaffoldingSelection,
                sections {id, name, archived},
                sectionSelection,
                createdBy {firstName, lastName},
                created,
                updatedBy {firstName, lastName},
                updated,
                reference{__typename ... on Inspection{id}},
                weather,
                group,
                arrivalDuration,
                departureDuration,
                workingDuration,
                otherDurations
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'constructionDiaryEntry')
            .then(entry => entry);
    }
);

export const fetchDiaryEntryFormData = entryId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchConstructionDiaryEntry{constructionDiaryEntry(id:"${entryId}"){
                id,
                message,
                category,
                attachments {id,name, fileName, size, mimeType, visibility},
                site {id, name},
                scaffoldings {id, name},
                scaffoldingSelection,
                sections {id, name},
                sectionSelection,
                weather,
                group,
                arrivalDuration,
                departureDuration,
                workingDuration,
                otherDurations
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'constructionDiaryEntry')
            .then(entry => entry);
    }
);

export function updateDiaryEntry(entryId, EntryInfo) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi(`v1/construction-diary-entries/${entryId}`, {
            method: 'PUT',
            token,
            body: EntryInfo
        })
            .then(res => res.json())
            .then(result => result);
    };
}

export function autocompleteConstructionGroupMember(input) {

    const trimmedInput = _.trim(input);

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `ConstructionGroups{constructionDiaryGroupUsers(name:"${trimmedInput}") {
            name
        }}`;

        return query(gqlQuery, { token })
            .then(({ constructionDiaryGroupUsers }) => (constructionDiaryGroupUsers))
            .catch((error) => {
                showRequestError('Gerüste konnten nicht geladen werden', error);
            });
    };
}

export function autocompleteScaffolding(siteId, managed = null) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchSiteScaffoldings{scaffoldings(siteId:"${siteId}",managed:${managed},limit:-1){
            data{id,name}
        }}`;

        return query(gqlQuery, { token })
            .then(result => result.scaffoldings.data)
            .catch((error) => {
                showRequestError('Gerüste konnten nicht geladen werden', error);
            });
    };
}


export function addNewDiaryEntry(diaryEntryDto) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi('v1/construction-diary-entries', {
            method: 'POST',
            token,
            body: diaryEntryDto
        })
            .then(res => res.json())
            .then(result => result);
    };
}

export function generateConstructionDiaryExport() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const { filter, sort } = getState().constructionDiary;
        const path = 'v1/construction-diary-entries/export';

        if ( !filter.site.id ) {
            return;
        }

        const exportDto = {
            siteId: filter.site.id,
            scaffoldingId: filter.scaffolding.id,
            sectionId: filter.section.id,
            fromDate: filter.date.dateFrom,
            toDate: filter.date.dateTo,
            sort: sort.direction === '-' ? 'asc' : 'desc'
        };

        if ( filter.category.value ) {
            exportDto.category = filter.category.value;
        }

        return callApi(path, {
            method: 'POST',
            token,
            body: exportDto
        })
            .then(response => response);
    };
}

export const fetchSelectedSite = siteId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchSite{unknownSite(id:"${siteId}") {
            ... on Site {
                id,
                name
            }
            ... on ThirdPartySite {
                id,
                name
            }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'unknownSite')
            .then(site => (site));
    });

export const fetchSelectedScaffolding = scaffoldingId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Scaffolding{unknownScaffolding(id:"${scaffoldingId}"){
            ... on Scaffolding {
                id,
                name
            }
            ... on ThirdPartyScaffolding {
                id,
                name
            }
        }}`;


        return querySingle(gqlQuery, {
            token
        }, 'unknownScaffolding')
            .then(scaffolding => (scaffolding));
    });

export const fetchSelectedSection = sectionId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Section{unknownSection(id:"${sectionId}"){
            ... on Section {
                id,
                name,
                
            }
            ... on ThirdPartySection {
                id,
                name
            }
        }}`;


        return querySingle(gqlQuery, {
            token
        }, 'unknownSection')
            .then(scaffolding => (scaffolding));
    });
