import { JobStatus } from '../properties';

const JobStatusOptions = [
    { label: 'Auftrag erteilt', value: JobStatus.Approved },
    { label: 'Auftrag in Vorbereitung', value: JobStatus.Draft },
    { label: 'Auftrag in Ausführung', value: JobStatus.InProgress },
    { label: 'Auftrag abgeschlossen', value: JobStatus.Completed }
];

export default JobStatusOptions;
