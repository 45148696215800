import PropTypes from 'prop-types';
import React from 'react';
import StaticTableHeader from './StaticTableHeader';
import Table from './Table';
import { TableBody } from './TableBody';

export const DataTable = ({
                              columns,
                              RowComponent,
                              data,
                              isFetching,
                              onItemChange,
                              onItemDelete,
                              entityLabel,
                              collapsesAfter,
                              renderEmptyTableHint,
                              noDeletion,
                              noEdit,
                              itemsToShow,
                              footerLink
                          }) => {
    return (
        <Table>
            <StaticTableHeader
                columns={columns}
                isFetching={isFetching}
            />
            <TableBody
                RowComponent={RowComponent}
                data={data}
                isFetching={isFetching}
                entityLabel={entityLabel}
                renderEmptyTableHint={renderEmptyTableHint}
                collapsesAfter={collapsesAfter}
                noDeletion={noDeletion}
                noEdit={noEdit}
                itemsToShow={itemsToShow}
                footerLink={footerLink}
                onItemChange={onItemChange}
                onItemDelete={onItemDelete}
            />
        </Table>
    );
};

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    RowComponent: PropTypes.func.isRequired,
    renderEmptyTableHint: PropTypes.func,
    isFetching: PropTypes.bool,
    entityLabel: PropTypes.string,
    noDeletion: PropTypes.bool,
    noEdit: PropTypes.bool,
    footerLink: PropTypes.element,
    itemsToShow: PropTypes.number,
    onItemChange: PropTypes.func,
    onItemDelete: PropTypes.func,
};

DataTable.defaultProps = {
    renderEmptyTableHint: () => false,
    entityLabel: 'Daten',
    isFetching: false
};
