import _ from 'lodash';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { createSiteNote } from '../../../redux/modules/site/action';
import AttachmentUpload from '../../Common/AttachmentUpload';
import InputGroup from '../../Forms/InputGroup';
import TextArea from '../../Forms/Textarea';

class CommentForm extends Component {

    constructor(props) {
        super(props);
        this.siteId = this.props.siteId;
        this.state = {
            isSendingComment: false
        };
        this.onCommentSubmit = this.onCommentSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.isUploadingAttachment = this.isUploadingAttachment.bind(this);
    }

    onCommentSubmit(values) {

        if (this.state.isSendingComment) {
            return;
        }

        if (!_.trim(values.comment) && (!values.attachments || values.attachments.length === 0)) {
            this.setState({
                isSendingComment: false,
                commentError: 'Bitte geben sie einen Kommentar ein oder laden Sie einen Anhang hoch.'
            });
            return;
        }

        this.setState({
            isSendingComment: true,
            commentError: null
        });

        const { actions: { createSiteNote }, onSubmit } = this.props;

        const { comment } = values;

        const commentData = {
            content: comment,
            attachments: values.attachments
        };

        createSiteNote(this.siteId, commentData)
            .then(() => {
                this.setState({
                    isSendingComment: false
                });
                toast.success('Kommentar wurde gesendet', { autoClose: 2500 });
            })
            .catch(() => {
                this.setState({
                    isSendingComment: false
                });
            })
            .then(() => {
                this.setState({
                    isSendingComment: false
                });
                onSubmit();
            });
    }

    isUploadingAttachment(isUploadingAttachment) {
        this.setState({
            isUploadingAttachment
        });
    }

    renderButtonText() {
        const { isSendingComment } = this.state;
        return isSendingComment ? 'Wird gesendet' : 'Absenden';
    }

    handleAttachmentUpload(attachmentIds, setAttachmentIds) {
        setAttachmentIds('attachments', attachmentIds);

        this.setState({
            commentError: ''
        });
    }

    render() {
        const { isUploadingAttachment, commentError, isSendingComment } = this.state;

        return (
            <Form
                onSubmit={this.onCommentSubmit}
                initialValues={this.state.data}
                mutators={{
                    setAttachmentIds: ([name, ids], state, utils) => {
                        utils.changeValue(state, name, () => ids);
                    }
                }}
                render={({ handleSubmit, form: { mutators } }) => (
                    <form className="comments_form" onSubmit={handleSubmit}>
                        <InputGroup
                            label="Ihr Kommentar*"
                        >
                            <AttachmentUpload
                                uploadPath="v1/notes/attachments"
                                onUpdate={ids => this.handleAttachmentUpload(ids, mutators.setAttachmentIds)}
                                label="Anhang"
                                isUploadingAttachment={this.isUploadingAttachment}
                            />
                            <TextArea
                                name="comment"
                            />
                            {
                                commentError && (
                                    <div style={{
                                        textAlign: 'right',
                                        color: '#E64D45'
                                    }}
                                    >
                                        <small>{commentError}</small>
                                    </div>
                                )
                            }
                            <small>
                                *Alle hier verfassten Kommentare sind ausschließlich für Sie und die
                                Nutzer Ihres eigenen Unternehmens sichtbar.
                            </small>


                            <div className="btn-group">
                                <button
                                    type="submit"
                                    className="btn btn--save"
                                    disabled={isSendingComment || isUploadingAttachment}
                                >
                                    {this.renderButtonText()}
                                </button>
                            </div>
                        </InputGroup>
                    </form>
                )}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        createSiteNote
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(CommentForm);
