import { ModelType } from '../../../properties';
import { ConstructionPlanModelMode } from '../../ConstructionPlan/properties';
import { toMediaFormData, toMediaPayload } from '../../Forms/Media/utils';
import { ModelMediaType } from '../../Media/properties';

/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = input => !!(input && typeof input.focus === 'function');

/**
 * Gets all the inputs inside modal on the page
 */
export const getAllInputs = () => {

    const inputFields = window.document.getElementById('site-modal')
        .querySelectorAll('input, select');

    if (typeof inputFields === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(inputFields)
        .reduce((accumulator, input) => {
            if (isFocusableInput(input)) {
                return accumulator.concat(input);
            }
            return accumulator;
        }, []);
};

export function validate(values) {
    const errors = {};

    // Name
    if (!values.name) {
        errors.name = 'Bitte geben Sie eine Bezeichnung ein';
    }

    // Address Line 1
    if (!values.line1) {
        errors.line1 = 'Bitte geben Sie eine Adresse an';
    }

    // Zip
    if (!values.zip) {
        errors.zip = 'Bitte geben Sie eine Postleitzahl an';
    } else if (!values.zip.match(/\b\d{5}\b/g)) {
        errors.zip = 'Bitte geben Sie eine Zahl ein';
    }

    // City
    if (!values.city) {
        errors.city = 'Bitte geben Sie einen Ort ein';
    }

    return errors;
}

export function getFormValues(site, constructionPlanMode = null) {
    if (!site) {
        return null;
    }

    const {
        id,
        name,
        description,
        address,
        attachments
    } = site;

    const formValues = {};

    if (!constructionPlanMode) {
        formValues.invitees = [];
        if (site.invitations) {
            formValues.invitees = site.invitations.map((invitation) => {
                const {
                    inviteeEmail, allowInspections, allowCloseRequests, id
                } = invitation;

                return {
                    id,
                    inviteeEmail,
                    allowInspections,
                    allowCloseRequests
                };
            });
        }
    } else {
        formValues.id = id;
        formValues.mode = constructionPlanMode || ConstructionPlanModelMode.New;
    }

    formValues.name = name;

    if (address) {
        formValues.line1 = address.line1;
        formValues.line2 = address.line2;
        formValues.zip = address.zip;
        formValues.city = address.city;
    }

    formValues.description = description;
    formValues.attachments = attachments ? attachments.map(media => toMediaFormData(media, ModelType.Site, ModelMediaType.Site_Attachment, constructionPlanMode)) : [];

    return formValues;
}

export function getSubmitPayload(values, isPlan = false) {
    if (!values) {
        return null;
    }

    const {
        id,
        mode,
        name,
        line1,
        line2,
        zip,
        city,
        description,
        invitees,
        attachments
    } = values;

    if (isPlan && mode === ConstructionPlanModelMode.Reference) {
        return {
            id,
            mode
        }
    }

    const data = {};

    data.name = name;
    data.description = description;

    data.address = {
        line1,
        line2,
        zip,
        city,
        country: 'de'
    };

    data.attachments = attachments ? attachments.map(a => toMediaPayload(a, isPlan)) : [];

    // Invitations
    if (!isPlan) {
        data.invitations = invitees.map(invitee => ({
            email: invitee.inviteeEmail,
            allowInspections: invitee.allowInspections,
            allowCloseRequests: invitee.allowCloseRequests
        }));
    }

    // Return payload
    if (isPlan) {
        return {
            id,
            mode,
            data
        }
    } else {
        return data;
    }
}
