import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { autocompleteSite } from '../../../redux/modules/site/action';
import AutocompleteSelect from './AutocompleteSelect';

const SiteAutocomplete = (props) => {

    const { onChange, initialValue, actions: { autocompleteSite } } = props;

    const initialOption = initialValue ? {
        label: initialValue,
        value: initialValue
    } : null;

    return (
        <AutocompleteSelect
            getValues={autocompleteSite}
            onSelectionChange={onChange}
            label="Baustelle"
            loadingMessage={() => 'Lade Baustellen ...'}
            placeholder="Baustelle wählen"
            initialOption={initialOption}
        />
    );
};

SiteAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string
};

SiteAutocomplete.defaultProps = {
    initialValue: null
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteSite
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(SiteAutocomplete);
