import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { currentTermsWereAccepted } from '../../redux/helper';
import { showApiError, showTempError } from '../../redux/modules/error/action';
import { acceptTerms, fetchAndUpdateUser } from '../../redux/modules/user/action';
import GraphQLError from '../../Services/GraphQLError';
import Modal from '../Common/Modal/Modal';
import Terms from './terms';

export const CURRENT_TERMS_VERSION = 'v2';

class TermsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scrolledToBottom: false,
            isSending: false,
            visible: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onError = this.onError.bind(this);
        this.onAlreadyAccepted = this.onAlreadyAccepted.bind(this);
    }

    onScrollToBottom() {
        this.setState({
            scrolledToBottom: true
        });
    }

    onSuccess() {
        toast.success('Die AGB wurden akzeptiert', { autoClose: 2500 });
        this.props.actions.fetchUser();
    }

    onError(error) {
        if (error instanceof GraphQLError) {
            if (error.is('terms_already_accepted')) {
                this.onAlreadyAccepted();
            } else if (error.is('invalid_terms_version')) {
                showTempError('Den allgemeinen Geschäftsbedingungen konnte nicht zugestimmt werden. (Fehler-Code: itv)');
            } else if (error.is('account_owner_action')) {
                showTempError('Die allgemeinen Geschäftsbedingungen können nur vom Eigentümer / der Eigentümerin Ihres Accounts akzeptiert werden.');
            } else {
                showApiError(error);
            }
        } else {
            showApiError(error);
        }
    }

    onAlreadyAccepted() {
        this.setState({ visible: false }, () => {
            toast.success('Die allgemeinen Geschäftsbedingungen wurden bereits akzeptiert.', { autoClose: 2500 });
            this.props.actions.fetchUser();
        });
    }

    handleSubmit() {
        const { actions: { acceptTerms } } = this.props;
        const { isSending } = this.state;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true
        });

        acceptTerms(CURRENT_TERMS_VERSION)
            .then(() => {
                this.setState({
                    isSending: false,
                    visible: false
                }, this.onSuccess);
            })
            .catch((error) => {
                this.setState({
                    isSending: false
                }, () => this.onError(error));
            });
    }

    renderButtonText() {
        const { isSending } = this.state;
        return isSending ? 'Wird gesendet ...' : 'Akzeptieren';
    }

    render() {
        const {
            scrolledToBottom,
            visible
        } = this.state;

        if (!visible) {
            return null;
        }

        const { currentTermsWereAccepted } = this.props;

        return (

            <Modal
                title="Aktualisierte AGB"
                onScrollToBottom={() => this.onScrollToBottom()}
                id="termsModal"
                footer={
                    <div className="btn-group">
                        <button
                            className="btn btn--link-neutral"
                            onClick={() => this.props.closeModal()}
                        >
                            Später akzeptieren
                        </button>

                        {currentTermsWereAccepted ?
                            <span style={{ float: 'right' }}>
                                {`Akzeptiert am ${currentTermsWereAccepted} Uhr`}
                            </span>
                            :
                            <button
                                type="submit"
                                className="btn btn--forward-action btn--save"
                                disabled={!scrolledToBottom}
                                onClick={this.handleSubmit}
                            >
                                {this.renderButtonText()}
                            </button>
                        }
                    </div>
                }
            >
                <div className="terms-intro">
                    <h3>Unsere Allgemeinen Geschäftsbedingungen haben sich geändert </h3>
                    <p>Bitte nehmen Sie sich einen Moment Zeit, um sich unsere aktualisierten AGB
                        durchzulesen.
                        Durch Klick auf den Button &quot;Akzeptieren&quot; ( dieser wird nach dem
                        Durchlesen
                        aktiviert ) nehmen Sie unsere neuen AGB an.
                    </p>
                    <p>Sollten Sie den AGB innerhalb der nächsten 14 Tage nicht schriftlich
                        widersprechen, gelten die AGB ebenfalls als akzeptiert.
                    </p>
                </div>
                <Terms />
            </Modal>
        );
    }

}

const mapStateToProps = state => ({
    currentTermsWereAccepted: currentTermsWereAccepted(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        acceptTerms,
        fetchUser: fetchAndUpdateUser
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsModal);
