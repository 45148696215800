import React from 'react';
import { getDeletedUserLabel } from '../../utils';

export function getInspectorCompany(inspection) {

    if (inspection.isAnonymous) {
        return <span className="anonymized-info">Gerüstnutzer (anonym)</span>;
    }

    return inspection?.createdBy?.account?.company?.name || 'n.a.';
}

export function getInspector(inspection) {

    if (inspection.isAnonymous) {
        return <span className="anonymized-info">Anonym</span>;
    }

    const { createdBy } = inspection;

    return createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : getDeletedUserLabel();
}
