import React from 'react';
import PropTypes from 'prop-types';
import Container from '../../Container';
import TopNav from '../../../Components/Page/TopNav';
import PrivatePage from './PrivatePage';
import Dashboard from '../../../Components/Dashboard/Dashboard';

const DashboardPage = ({ history }) => (
    <PrivatePage>
        <Container>
            <TopNav />
            <h1>Übersicht</h1>
            <Dashboard history={history} />
        </Container>
    </PrivatePage>
);

export default DashboardPage;

DashboardPage.propTypes = {
    history: PropTypes.object
};

DashboardPage.defaultProps = {
    history: {}
};
