import getSyncMutations, {
    getConstructionPlanSyncUpdateMutation,
    getMutationBatchQuery
} from '../../../Components/ConstructionPlan/Sync/mutations';
import { callGraphQlApi, mutate, query, querySingle } from '../../action';

const GraphQlType = 'job';

/**
 * Creates a job
 *
 * @param data
 * @returns {function(*, *): *}
 */
export const createJob = data => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const mutationQuery = `
            CreateJob($input: CreateJobInput!) { 
                createJob(input: $input) {
                    id,
                    name
                }
            }
        `;

    const params = {
        input: {
            name: data.name,
            type: data.type,
            status: data.status,
            customerId: data.customerId,
            description: data.description,
            sectionIds: data.sectionIds,
            siteId: data.siteId,
            attachmentIds: data.attachmentIds,
            offerDeadline: data.offerDeadline,
            customerContactId: data.customerContactId
        }
    };

    return mutate(mutationQuery, params, {
        token
    })
        .then(data => data.createJob);
};

/**
 * Fetches a job
 *
 * @param jobId
 * @returns {function(*, *): (Promise<string> | Promise | * | Promise<T | string>)}
 */
export const fetchJob = jobId => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const query = `
            Job {
                job(id:"${jobId}") {
                    id,
                    name,
                    type,
                    status,
                    created,
                    description,
                    constructionPlan,
                    customerContact{id,isMainContact, position,firstName,lastName, email, mobile, phone},
                    site{id,name,description,address{line1,line2,city,zip}},
                    sections{id,name,scaffolding{id,name}},
                    customer{id,name,line1,contactPerson{firstName, lastName}},
                    attachments{id, name, fileName, mimeType, size, visibility},
                    correspondence{id, name, fileName, mimeType, size, created, updated, visibility},
                    offerDeadline,
                    archived,
                    archivedAt,
                    
                }
            }
        `;

    return querySingle(query, {
        token
    }, GraphQlType);
};

export const fetchJobChangelog = jobId => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const query = `
            Job {
                job(id:"${jobId}") {
                    type,
                    name,
                    history(limit:100){
                        created,
                        createdBy{firstName, lastName}, 
                        old, 
                        new
                     }
                }
            }
        `;

    return querySingle(query, {
        token
    }, GraphQlType);
};

export const fetchJobArchivationData = jobId => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const query = `
            JobArchivationData($id: String!) {
                job(id: $id) {
                    id,
                    name,
                    status,
                    type,
                    archived
                }
            }
        `;

    return querySingle(query, {
        token,
        params: {
            id: jobId
        }
    }, GraphQlType);
};

/**
 * Fetches the job correspondence
 *
 * @param jobId
 * @returns {function(*, *): (Promise<string> | Promise | * | Promise<T | string>)}
 */
export const fetchJobCorrespondence = jobId => (dispatch, getState) => {
    const token = getState().currentUser.auth_token;
    const query = `
            Job {
                job(id:"${jobId}") {
                    correspondence{id, name, fileName, visibility, mimeType, size, created, updated}
                }
            }
        `;

    return querySingle(query, {
        token
    }, GraphQlType)
        .then(job => job ? job.correspondence : null);
};

export const archiveJob = jobId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `
                 ArchiveJob($id: String!){
                    archiveJob(id: $id){
                        id
                    }
                }
            `;

        const params = {
            id: jobId
        };

        return mutate(queryString, params, {
            token
        });
    }
);

export const reactivateJob = (jobId, status) => (
    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        const mutationQueries = [
            {
                query: `
                        mutation ReactivateJob($id: String!){
                            reactivateJob(id: $id){
                                id
                            }
                        }`,
                params: {
                    id: jobId
                }
            },
            {
                query: `
                    mutation UpdateJob($id: String!, $input: UpdateJobInput!) { 
                        updateJob(id: $id, input: $input) {
                            id
                        }
                    }`,
                params: {
                    id: jobId,
                    input: {
                        status
                    }
                }
            }
        ];

        return callGraphQlApi(mutationQueries, token);

    }
);

/**
 * Fetches the job to be edited in a form
 * @param jobId
 * @returns {function(*, *)}
 */
export const fetchJobFormData = jobId => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const query = `
            Job {
                job(id:"${jobId}") {
                    id,
                    name,
                    type,
                    status,
                    description,
                    offerDeadline,
                    customerContact{id},
                    customer{id,name},
                    site{id,name},
                    sections{id,name,scaffolding{id,name}},
                    attachments{id, name, fileName, mimeType, size, visibility}
                }
            }
        `;

    return querySingle(query, {
        token
    }, GraphQlType);
};

/**
 * Fetches all jobs for the current jobs table state
 *
 * Triggers getJobs(), getJobsSuccess() and getJobsFailure()
 */
export const fetchJobs = (args = {}, archived = false) => (dispatch, getState) => {
    const token = getState().currentUser.auth_token;
    return internalFetchJobs(token, args, false, archived);
};

/**
 * Fetches all requests for the current requests table state
 *
 * Triggers getRequests(), getRequestsSuccess() and getRequestsFailure()
 */
export const fetchRequests = (args = {}, archived = false) => (dispatch, getState) => {
    const token = getState().currentUser.auth_token;
    return internalFetchJobs(token, args, true, archived);
};

export const JobsListType = {
    ListOfActiveJobs: 'list_of_active_jobs',
    ListOfArchivedJobs: 'list_of_archived_jobs'
};

function getJobInclusionOptions(jobsListType) {

    if (jobsListType === JobsListType.ListOfArchivedJobs) {

        return {
            includeArchived: true,
            includeActive: false
        };
    }

    return {
        includeArchived: false,
        includeActive: true
    };
}

function internalFetchJobs(token, args = {}, isRequest = false, archivedOnly = false) {

    const inclusionOptions = archivedOnly ? getJobInclusionOptions(JobsListType.ListOfArchivedJobs) : getJobInclusionOptions(JobsListType.ListOfActiveJobs);

    let params = {
        page: args.page,
        limit: args.limit,
        type: isRequest ? 'request' : 'job',
        name: args.name,
        siteName: args.siteName,
        customerName: args.customerName,
        sort: args.sortField,
        sortAsc: args.sortAsc,
        ...inclusionOptions
    };

    return query(`FetchJobs($type:JobsQueryTypeEnum,$page:Int,$limit:Int,$name:String,$siteName:String,$customerName:String,$sort:JobsQuerySortEnum,$sortAsc:Boolean, $includeArchived:Boolean, $includeActive:Boolean) {
            jobs(
                type:$type,
                name:$name,
                siteName:$siteName,
                customerName:$customerName,
                limit:$limit,
                page:$page,
                sort:$sort,
                sortAsc:$sortAsc,
                includeArchived: $includeArchived,
                includeActive: $includeActive
            ) {
                data {
                    id,name,status,created,updated,
                    archived,archivedAt,
                    offerDeadline,
                    customer {
                        id,name
                    },
                    site {
                        id,name,address{city},archived
                    },
                    sections {
                        id,name,scaffolding{id,name,archived},archived
                    }
                },
                total,
                per_page
            }
        }`, {
        token,
        params
    })
        .then(data => data.jobs);
}

/**
 *
 * @param jobId
 * @param data
 * @returns {function(*, *): (Promise<any> | Promise | * | Promise<T>)}
 */
export const updateJob = (jobId, data) => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const mutationQuery = `
            UpdateJob($id: String!, $input: UpdateJobInput!) { 
                updateJob(id: $id, input: $input) {
                    id,
                    name,
                    status
                }
            }
        `;

    const input = {};

    if (data.hasOwnProperty('name')) {
        input.name = data.name;
    }
    if (data.hasOwnProperty('type')) {
        input.type = data.type;
    }
    if (data.hasOwnProperty('status')) {
        input.status = data.status;
    }
    if (data.hasOwnProperty('customerId')) {
        input.customerId = data.customerId;
    }
    if (data.hasOwnProperty('description')) {
        input.description = data.description;
    }
    if (data.hasOwnProperty('siteId')) {
        input.siteId = data.siteId;
    }
    if (data.hasOwnProperty('attachmentIds')) {
        input.attachmentIds = data.attachmentIds;
    }
    if (data.hasOwnProperty('offerDeadline')) {
        input.offerDeadline = data.offerDeadline;
    }
    if (data.hasOwnProperty('customerContactId')) {
        input.customerContactId = data.customerContactId;
    }
    if (data.hasOwnProperty('correspondenceIds')) {
        input.correspondenceIds = data.correspondenceIds;
    }

    const params = {
        id: jobId,
        input
    };

    return mutate(mutationQuery, params, {
        token
    })
        .then(data => data.updateJob);
};

export const fetchConstructionPlanFormData = jobId => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const gqlQuery = `FetchConstructionPlan{job(id:"${jobId}") {
            constructionPlan
        }}`;

    return querySingle(gqlQuery, {
        token
    }, 'job')
        .then((job) => {
            return job.constructionPlan;
        });
};

export const fetchConstructionPlanSiteReferenceData = siteId => (dispatch, getState) => {
    const token = getState().currentUser.auth_token;

    const gqlQuery = `
        FetchConstructionPlanReferenceData {
            site(id:"${siteId}") {
                id,
                name,
                description,
                address { line1, line2, city, zip, country }
                attachments{id},
                archived,
                scaffoldings {
                    id,
                    name,
                    description,
                    scheduledErection,
                    scheduledDismantling,
                    utilizationPlans{id},
                    attachments{id},
                    anchorProtocol{id},
                    archived,
                    sections {
                        id,
                        name,
                        customer,
                        equippedSurfaces,
                        archived,
                        plannedWorks,
                        purposes,
                        otherProtectivePurpose,
                        specialConstruction
                        staticsCertificateNecessary
                        supportType,
                        constructionType,
                        loadClass,
                        wallClearance,
                        widthClass,
                        claddings,
                        specialFeatures,
                        approvedByName,
                        description,  
                        proofOfStability{id},
                        utilizationPlans{id},
                        anchorProtocol{id},
                        attachments{id},
                        status
                    }
                }
            }
        }
    `;

    return querySingle(gqlQuery, {
        token
    }, 'site');
};

export const updateConstructionPlan = (jobId, data) => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const mutationQuery = `
            UpdateJob($id: String!, $input: UpdateJobInput!) { 
                updateJob(id: $id, input: $input) {
                    id,
                    name,
                    constructionPlan
                }
            }
        `;

    const params = {
        id: jobId,
        input: {
            constructionPlan: data
        }
    };

    return mutate(mutationQuery, params, {
        token
    })
        .then(data => data.updateJob);
};

export const deleteConstructionPlan = (jobId) => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const mutationQuery = `
            UpdateJob($id: String!, $input: UpdateJobInput!) { 
                updateJob(id: $id, input: $input) {
                    id,
                    name
                }
            }
        `;

    const params = {
        id: jobId,
        input: {
            constructionPlan: {
                site: null
            }
        }
    };


    return mutate(mutationQuery, params, {
        token
    })
        .then(data => data.updateJob);
};

/**
 *
 * @param jobId
 * @returns {function(*=, *): (Promise<any> | Promise | * | Promise<T>)}
 */
export const deleteJob = jobId => (dispatch, getState) => {
    const token = getState().currentUser.auth_token;

    const mutationQuery = `
            DeleteJob($id: String!) { 
                deleteJob(id: $id) { 
                    id, name 
                }
            }
        `;

    const params = {
        id: jobId
    };

    return mutate(mutationQuery, params, {
        token
    })
        .then(data => data.deleteJob);
};

/**
 * Updates the job type and status
 * @returns {function(*, *): Promise}
 * @param id
 * @param newType
 * @param newStatus
 */
export const updateJobTypeAndStatus = (id, newType, newStatus) => (dispatch, getState) => {

    const token = getState().currentUser.auth_token;

    const mutationQuery = `
            UpdateJob($id: String!, $input: UpdateJobInput!) { 
                updateJob(id: $id, input: $input) {
                    id,
                    name,
                    status,
                    type
                }
            }
        `;

    const params = {
        id,
        input: {
            type: newType,
            status: newStatus
        }
    };

    return mutate(mutationQuery, params, {
        token
    })
        .then(data => data.updateJob);
};

/**
 * Autocomplete jobs by name
 */
export function autocompleteJob(input, isRequest = false, archivedOnly = false) {
    return (dispatch, getState) => {

        const inclusionOptions = archivedOnly ? `includeArchived: true, includeActive:false` : `includeArchived: false, includeActive:true`;

        const token = getState().currentUser.auth_token;
        return query(`
            FetchRequests{
                jobs(
                    type: ${isRequest ? 'request' : 'job'},
                    name: "${input}"
                    limit: -1,
                    page: 1,
                    sort: name,
                    sortAsc: true,
                    ${inclusionOptions}
                ) {
                    data {
                        name
                    }
                }
            }
        `, {
            token
        })
            .then(({ jobs }) => jobs.data);
    };
}

export function autocompleteRequest(input, archivedOnly) {
    return autocompleteJob(input, true, archivedOnly);
}

export function applyConstructionPlan(jobId, constructionPlan, syncData, liveData, resolvedMedia) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const syncMutations = getSyncMutations(syncData, liveData, resolvedMedia);
        const mutationQueries = getMutationBatchQuery(syncMutations);

        // No changes were detected, so nothing needs to be done
        if (mutationQueries.length === 0) {
            return Promise.resolve();
        }

        mutationQueries.push(getConstructionPlanSyncUpdateMutation(jobId, constructionPlan, syncData));

        return callGraphQlApi(mutationQueries, token);
    };
}

