import PropTypes from 'prop-types';
import React from 'react';
import { getSitePropertyLabel } from '../../../Common/Diff/labels';
import { ConstructionPlanModelMode } from '../../properties';
import { isIdenticalSiteFormValue } from '../Site/equals';
import ConstructionPlanSyncModelSummary from './ConstructionPlanSyncModelSummary';
import ConstructionPlanSyncSummaryField from './ConstructionPlanSyncSummaryField';
import ConstructionPlanSyncSummaryRow from './ConstructionPlanSyncSummaryRow';
import {
    getLiveValue,
    getSyncValue,
    renderAddress,
    renderMediaList,
    renderMultilineText,
    renderValue
} from './functions';

const ConstructionPlanSyncSiteSummary = ({ syncModel, liveData, resolvedMedia }) => {

    const SyncSummaryProperties = [
        'name',
        'address',
        'description',
        'attachments'
    ];

    const isNew = syncModel.mode === ConstructionPlanModelMode.New;
    const isReference = syncModel.mode === ConstructionPlanModelMode.Reference;
    const isIgnored = syncModel.ignored;

    const renderSiteValue = (property, value, isLive = false) => {
        switch (property) {
            case 'address':
                return renderAddress(value);
            case 'attachments':
                // handled in renderProperty
                return null;
            case 'description':
                return renderMultilineText(value);
            default:
                return renderValue(value);
        }
    };

    const renderProperty = (property) => {

        if (property === 'attachments') {
            return renderMediaList(property, syncModel, liveData, resolvedMedia);
        }

        const value = getSyncValue(property, syncModel);
        const liveValue = getLiveValue(property, liveData);
        const isIdentical = isIdenticalSiteFormValue(property, syncModel.data, liveData);

        const left = renderSiteValue(property, liveValue, true);
        const right = renderSiteValue(property, value);

        return (
            <ConstructionPlanSyncSummaryField
                key={property}
                label={getSitePropertyLabel(property)}
                isIdentical={isIdentical}
                isNew={isNew}
            >
                <ConstructionPlanSyncSummaryRow
                    left={left}
                    right={right}
                    isIdentical={isIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        );
    };

    const renderProperties = () => {
        if (isReference) {
            return 'Keine Konfigurationsänderungen';
        }

        if (isIgnored) {
            return (
                <span className="sync-summary__ressource-is-ignored-hint">
                    Wird nicht berücksichtigt
                </span>
            )
        }

        return SyncSummaryProperties.map(renderProperty);
    };

    return (
        <ConstructionPlanSyncModelSummary
            title={`Baustelle${isReference ? `:  ${liveData.name}` : ''}`}
            isNew={isNew}
        >
            {renderProperties()}
        </ConstructionPlanSyncModelSummary>
    );
};

ConstructionPlanSyncSiteSummary.propTypes = {
    syncModel: PropTypes.object.isRequired,
    liveData: PropTypes.object
};

export default ConstructionPlanSyncSiteSummary;
