import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import ResourceNotFoundErrorPage from '../../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { ResourceViewMode } from '../../../Containers/Pages/Private/properties';
import { isThirdPartyAndArchived } from '../../../Containers/Pages/Private/SiteDetailPage';
import { showApiError, showRequestError, showTempError } from '../../../redux/modules/error/action';
import {
    deleteSingleScaffolding,
    fetchScaffoldingDetailData
} from '../../../redux/modules/scaffolding/action';
import {
    addNewSection,
    addSectionNote,
    deleteSingleSection
} from '../../../redux/modules/section/action';
import { LockedByException } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { UserType } from '../../../user';
import { applyModelChangesToCollection, getFieldCount, optionallyAddCount } from '../../../utils';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import DataRow from '../../Common/DataRow';
import EmptyCardHint from '../../Common/EmptyCardHint';
import EmptyDataTableHint from '../../Common/EmptyDataTableHint';
import Icon from '../../Common/Icon';
import IfUser from '../../Common/IfUser';
import InvitedIndicator from '../../Common/InvitedIndicator';
import DeleteModal from '../../Common/Modal/DeleteModal';
import MultiLineText from '../../Common/MultiLineText';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import { SiteReference } from '../../Common/ObjectReference';
import ScaffoldingDropdown from '../../Common/ScaffoldingDropdown';
import ThirdPartyIndicator from '../../Common/ThirdPartyIndicator';
import Tooltip from '../../Common/Tooltip';
import UnmanagedResourceIndicator from '../../Common/UnmanagedIndicator';
import DetailPageHeader from '../../DetailPageNavigation/DetailPageHeader';
import { JobType } from '../../Job/properties';
import ReferencedJobRequestsTable from '../../Job/Referenced/ReferencedJobRequestsTable';
import { ReferencedJobsTable } from '../../Job/Referenced/ReferencedJobsTable';
import Section from '../../Layout/Section';
import MediaList from '../../Media/MediaList';
import { PageLoadingSpinner } from '../../Page/LoadingSpinner';
import TopNav from '../../Page/TopNav';
import SectionFormModal from '../../Sections/Form/SectionFormModal';
import ScaffoldingFormModal from '../Form/ScaffoldingFormModal';
import ScaffoldingBreadcrumb from './ScaffoldingBreadcrumb';
import { ScaffoldingSectionsTable } from './ScaffoldingSectionsTable';
import ScaffoldingServiceTime from './ScaffoldingServiceTime';

const getNavigationLinks = (scaffolding) => {

    const sectionCount = getFieldCount(scaffolding, 'sections');
    const documentCount = getFieldCount(scaffolding, 'attachments');
    const jobRequestCount = getFieldCount(scaffolding, 'jobs', job => job.type === JobType.Request);
    const jobCount = getFieldCount(scaffolding, 'jobs', job => job.type === JobType.Job);

    return [
        {
            id: 'generalInformation',
            label: 'Allgemeine Angaben',
        },
        {
            id: 'sections',
            label: optionallyAddCount('Gerüstabschnitte', sectionCount),
        },
        {
            id: 'documents',
            label: optionallyAddCount('Dokumente', documentCount),
        },
        {
            id: 'related-requests',
            label: optionallyAddCount('Anfragen', jobRequestCount),
        },
        {
            id: 'related-jobs',
            label: optionallyAddCount('Aufträge', jobCount),
        },
    ];
}

const getInitialState = () => ({
    loading: true,
    scaffolding: {},
    entityToDelete: null,
    isFetching: true,
    isDeletingScaffolding: false,
    isDeletingSection: false,
    showEditModal: false,
    showDeleteModal: false,
    showDeleteSectionModal: false,
    showAddSectionModal: false,
    pageHeaderCollapsed: false,
});

class ScaffoldingDetail extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = getInitialState(props);

        this.onUpdated = this.onUpdated.bind(this);
        this.onSectionCreated = this.onSectionCreated.bind(this);

        this.renderNotes = this.renderNotes.bind(this);
        this.deleteScaffolding = this.deleteScaffolding.bind(this);
        this.deleteSection = this.deleteSection.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.toggleAddSectionModal = this.toggleAddSectionModal.bind(this);
        this.toggleDeleteSectionModal = this.toggleDeleteSectionModal.bind(this);
        this.onJobChange = this.onJobChange.bind(this);
        this.onPageHeaderCollapse = this.onPageHeaderCollapse.bind(this);
    }

    static renderScaffoldings(scaffoldings) {
        if (!scaffoldings || scaffoldings.length === 0) {
            return 'n.a.';
        }

        return <ScaffoldingDropdown scaffoldings={scaffoldings}/>;

    }

    componentDidMount() {

        this.loadScaffolding();

        const {location} = this.props.history;
        if (!location.state) {
            return;
        }
        const {openModal} = location.state;
        if (!openModal) {
            return;
        }

        if (openModal.type === 'section') {
            this.props.history.replace({
                ...location,
                state: {},
            });
            this.toggleAddSectionModal();
        }
    }

    componentDidUpdate(prevProps) {

        const {scaffoldingId, history: {location}} = this.props;

        if (scaffoldingId !== prevProps.scaffoldingId) {
            this.setState(getInitialState(this.props), () => this.loadScaffolding());

            const {openModal} = location.state;

            if (openModal) {
                if (openModal.type === 'section') {
                    this.props.history.replace({
                        ...location,
                        state: {},
                    });
                    this.toggleAddSectionModal();
                }
            }

        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onUpdated() {
        this.toggleEditModal();
        this.loadScaffolding();
    }

    onSectionCreated(sectionId) {
        this.toggleAddSectionModal();
        this.props.history.push(`/sections/${sectionId}`);
    }

    loadScaffolding() {
        this.setState({
            loading: true,
        });

        const {actions: {fetchScaffoldingDetailData}, scaffoldingId} = this.props;

        fetchScaffoldingDetailData(scaffoldingId)
            .then((scaffolding) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                if (isThirdPartyAndArchived(scaffolding)) {
                    return Promise.reject('Das Gerüst ist nicht mehr verfügbar. Bitte kontaktieren Sie den Gerüstersteller für weitere Informationen.');
                }

                const {archived} = scaffolding;

                this.setState({
                    scaffolding,
                    loading: false,
                    isFetching: false,
                    viewMode: archived ? ResourceViewMode.Archived : ResourceViewMode.Active,
                });
            })
            .catch((error) => {

                if (typeof error === 'string') {
                    showTempError(error);
                } else {
                    showRequestError('Das Gerüst konnte nicht geladen werden');
                }

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    scaffolding: null,
                    loading: false,
                    isFetching: false,
                });
            });
    }

    onJobChange(changedJob, changes) {
        const {scaffolding} = this.state;
        if (!scaffolding) {
            return;
        }

        const {jobs} = scaffolding;
        if (!jobs) {
            return;
        }

        const updatedJobs = applyModelChangesToCollection(jobs, changedJob.id, changes);

        this.setState({
            scaffolding: {
                ...scaffolding,
                jobs: updatedJobs,
            },
        });
    }

    deleteScaffolding() {
        const {scaffolding} = this.state;
        const scaffoldingId = scaffolding.id;
        const siteId = scaffolding.site.id;

        const {
            actions: {deleteSingleScaffolding},
            history,
        } = this.props;

        this.setState({
            isDeletingScaffolding: true,
        });

        deleteSingleScaffolding(scaffoldingId)
            .then(() => {
                this.setState({
                    isDeletingScaffolding: false,
                });
                this.toggleDeleteModal();
                toast.success('Gerüst wurde gelöscht', {autoClose: 2500});
                history.push(`/sites/${siteId}`);
            })
            .catch((error) => {

                this.setState({
                    isDeletingScaffolding: false,
                });

                if (error instanceof LockedByException) {
                    return ErrorService.handleLockedByError(error, 'scaffoldingLocked')
                                       .then(({error}) => (showTempError(error)));
                }

                return showApiError(error);

            });
    }

    deleteSection() {
        const {entityToDelete} = this.state;

        if (!entityToDelete) {
            return;
        }

        const {
            actions: {deleteSingleSection},
        } = this.props;

        this.setState({
            isDeletingSection: true,
        });

        deleteSingleSection(entityToDelete.id)
            .then(() => {
                this.setState({
                    isDeletingSection: false,
                });
                this.toggleDeleteSectionModal();
                toast.success('Gerüstabschnitt wurde gelöscht', {autoClose: 2500});

                this.setState({
                    isFetching: true,
                });
            })
            .catch((error) => {

                this.setState({
                    isDeletingSection: false,
                });

                if (error instanceof LockedByException) {
                    return ErrorService.handleLockedByError(error, 'sectionLocked')
                                       .then(({error}) => (showTempError(error)));
                }

                return showApiError(error);

            })
            .then(() => {
                this.loadScaffolding();
            });
    }

    toggleEditModal() {
        this.setState({
            showEditModal: !this.state.showEditModal,
        });
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal,
        }));
    }

    toggleAddSectionModal() {
        this.setState(prevState => ({
            showAddSectionModal: !prevState.showAddSectionModal,
        }));
    }

    toggleDeleteSectionModal(section = null) {
        if (section) {
            this.setState({
                showDeleteSectionModal: true,
                entityToDelete: section,
            });
            return;
        }

        this.setState({
            showDeleteSectionModal: false,
            entityToDelete: null,
        });
    }

    hasMedia() {
        const {scaffolding: {attachments, utilizationPlans, anchorProtocol}} = this.state;
        return (attachments && attachments.length)
            || (utilizationPlans && utilizationPlans.length)
            || anchorProtocol;
    }

    renderNotes() {
        const {notes} = this.state.scaffolding;

        if (!notes || !notes.length) {
            return <NotAvailablePlaceholder/>;
        }

        return notes.map(note => (
            <p key={note.id}>{note.content}</p>
        ));
    }

    onPageHeaderCollapse(collapsed) {
        this.setState({
            pageHeaderCollapsed: collapsed,
        });
    }

    render() {
        const {
            scaffolding,
            entityToDelete,
            isFetching,
            loading,
            isDeletingScaffolding,
            isDeletingSection,
            showEditModal,
            showDeleteModal,
            showAddSectionModal,
            showDeleteSectionModal,
            viewMode,
            pageHeaderCollapsed,
        } = this.state;

        if (!isFetching && !scaffolding) {
            return <ResourceNotFoundErrorPage/>;
        }

        const {
            name,
            description,
            sectionCount,
            site,
            sections,
            attachments,
            utilizationPlans,
            scheduledErection,
            scheduledDismantling,
            isThirdParty,
            isInvited,
            managed,
            jobs,
        } = scaffolding;

        const anchorProtocol = scaffolding.anchorProtocol ? [scaffolding.anchorProtocol] : [];

        const relatedJobs = jobs || [];

        const pageTitle = isFetching ? 'Lade Gerüst' : `Gerüst: ${scaffolding ? scaffolding.name : 'n.a.'}`;

        const actions = (
            <IfUser
                can={['update-scaffoldings']}
                and={scaffolding && !scaffolding.isThirdParty}
            >
                <button
                    onClick={this.toggleDeleteModal}
                    className="btn btn--delete-action btn--icon"
                    title="Gerüst löschen"
                >
                    <span className="btn__icon">
                        <Icon name="delete"/>
                    </span>
                </button>

                {
                    viewMode !== ResourceViewMode.Archived && (
                        <Fragment>
                            <button
                                onClick={this.toggleEditModal}
                                className="btn btn--edit-action btn--icon"
                                title="Gerüst bearbeiten"
                            >
                                <span className="btn__icon">
                                    <Icon name="edit"/>
                                </span>
                                <span>
                                    Bearbeiten
                                </span>
                            </button>
                        </Fragment>
                    )
                }
            </IfUser>
        );

        return (
            <Fragment>

                <TopNav
                    hasNewPageHead
                    hide={pageHeaderCollapsed}
                />

                <ScaffoldingBreadcrumb
                    collapse={pageHeaderCollapsed}
                    scaffolding={scaffolding}
                />

                <DetailPageHeader
                    type="scaffolding"
                    title={pageTitle}
                    titleAddOns={viewMode === ResourceViewMode.Archived ? <ArchivedBadge/> : null}
                    navigationalLinks={getNavigationLinks(scaffolding)}
                    actions={actions}
                    onCollapse={this.onPageHeaderCollapse}
                    isLoading={loading}
                />

                {loading && <PageLoadingSpinner/>}

                {
                    !loading && (
                        <Fragment>
                            <IfUser
                                isOneOfType={[UserType.ProQualifiedWorker, UserType.ProWorker]}
                                and={viewMode === ResourceViewMode.Archived}
                            >
                                <Redirect
                                    to={{
                                        pathname: '/',
                                    }}
                                />
                            </IfUser>

                            <Section
                                title="Allgemeine Angaben"
                                id="generalInformation"
                            >
                                <DataRow
                                    label="Bezeichnung"
                                >
                                    {name}
                                    {
                                        (isThirdParty && !isInvited) ?
                                            <ThirdPartyIndicator/>
                                            :
                                            null
                                    }
                                    {
                                        (isThirdParty && isInvited) ?
                                            <InvitedIndicator/>
                                            :
                                            null
                                    }
                                    {
                                        (!isThirdParty && !managed) ?
                                            <UnmanagedResourceIndicator
                                                label="Fremdes Gerüst"
                                            />
                                            :
                                            null
                                    }
                                    {
                                        (viewMode === ResourceViewMode.Archived) &&
                                        <ArchivedBadge size="small"/>
                                    }
                                </DataRow>

                                <DataRow
                                    label="Gerüstabschnitte"
                                >
                                    {`${sectionCount} Total`}
                                </DataRow>

                                <DataRow
                                    label="Baustelle"
                                >
                                    {site &&

                                    <SiteReference
                                        id={site.id}
                                        label={site.name}
                                        badge={(site && site.archived) ? <ArchivedBadge size="small"/> : null}
                                    >
                                        {`${site.address.line1}, ${site.address.zip} ${site.address.city}`}

                                    </SiteReference>
                                    }
                                    {
                                        (site && site.isThirdParty && !site.isInvited) ?
                                            <ThirdPartyIndicator size="small"/>
                                            :
                                            null
                                    }
                                    {
                                        (site && site.isThirdParty && site.isInvited) ?
                                            <InvitedIndicator size="small"/>
                                            :
                                            null
                                    }


                                </DataRow>

                                <DataRow
                                    label="Geplante Standzeit"
                                >
                                    <ScaffoldingServiceTime
                                        scheduledDismantling={scheduledDismantling}
                                        scheduledErection={scheduledErection}
                                    />
                                </DataRow>

                                <DataRow
                                    label="Notizen"
                                >
                                    <MultiLineText text={description}/>
                                </DataRow>

                            </Section>

                            <Section
                                title="Gerüstabschnitte"
                                type="list"
                                id="sections"
                                cta={
                                    (viewMode !== ResourceViewMode.Archived) &&
                                    <IfUser
                                        can={['create-sections']}
                                        and={!isThirdParty}
                                    >
                                        <button
                                            onClick={this.toggleAddSectionModal}
                                            className="btn btn--add-action"
                                        >
                                            <span className="btn__icon">
                                                <Icon name="add"/>
                                            </span>
                                            <span>Neuer Gerüstabschnitt</span>
                                        </button>
                                    </IfUser>
                                }
                            >
                                <ScaffoldingSectionsTable sections={sections} viewMode={viewMode} />
                            </Section>

                            <Section
                                title="Dokumente"
                                id="documents"
                            >
                                {
                                    this.hasMedia() ?
                                        <React.Fragment>
                                            <DataRow
                                                label="Nutzungspläne"
                                                type="media"
                                            >
                                                <MediaList
                                                    media={utilizationPlans}
                                                    groupByVisibility={!site.isThirdParty}
                                                    emptyLabel="Keine Nutzungspläne hinterlegt"
                                                />
                                            </DataRow>
                                            <DataRow
                                                label="Ankerprotokoll"
                                                type="media"
                                            >
                                                <MediaList
                                                    media={anchorProtocol}
                                                    groupByVisibility={!site.isThirdParty}
                                                    emptyLabel="Kein Ankerprotokoll hinterlegt"
                                                />
                                            </DataRow>
                                            <DataRow
                                                label="Anhänge"
                                                type="media"
                                            >
                                                <MediaList
                                                    media={attachments}
                                                    groupByVisibility={!site.isThirdParty}
                                                    emptyLabel="Keine Anhänge hinterlegt"
                                                />
                                            </DataRow>
                                        </React.Fragment>
                                        :
                                        <EmptyCardHint
                                            title="Es wurden keine Dokumente für dieses Gerüst hochgeladen."
                                        >
                                            <p>
                                                Laden Sie zum Gerüst zugehörige Dokumente (Nutzungsplan,
                                                Ankerprotokoll oder sonstige Anhänge) hoch, um diese hier
                                                angezeigt zu bekommen.
                                            </p>
                                            <p>
                                                Klicken Sie dazu auf &quot;Bearbeiten&quot; und wählen Sie Ihre
                                                gewünschten Anhänge im Bereich &quot;Dokumente&quot; aus. Sie
                                                können wählen, ob die Anhänge nur für Ihr Team oder für alle
                                                Nutzer von Scaffeye® (öffentliches Dokument) sichtbar sein
                                                sollen.
                                            </p>
                                        </EmptyCardHint>
                                }
                            </Section>
                            <IfUser
                                can={['read-jobs']}
                                and={!isThirdParty && managed}
                            >
                                <Section
                                    title="Anfragen"
                                    type="list"
                                    classes="related-requests"
                                    subline={
                                        <Fragment>
                                            Nur aktive Anfragen <Tooltip id="related-request-tt">Unter "Aktive
                                                                                                 Anfragen" werden alle Anfragen
                                                                                                 zusammengefasst, die in Scaffeye®
                                                                                                 hinterlegt und nicht archiviert
                                                                                                 sind.</Tooltip>
                                        </Fragment>
                                    }
                                    id="related-requests"
                                >
                                    <ReferencedJobRequestsTable
                                        data={relatedJobs && relatedJobs.filter((job) => job.type === JobType.Request && !job.archived)}
                                        onItemChange={this.onJobChange}
                                        emptyTableHint={<EmptyDataTableHint
                                            numColumns={7}
                                            title="Es wurden noch keine Anfragen mit diesem Gerüst verknüpft."
                                        >
                                            <p>
                                                Sie können Gerüste und Anfragen miteinander
                                                verknüpfen. Wählen Sie dazu die
                                                gewünschte Anfrage im Sidebar-Reiter
                                                {' '}<Link to="/requests">Anfragen</Link>{' '}
                                                aus und folgen Sie den Anweisungen
                                                der Sektion &quot;Baustellendaten&quot;.
                                            </p>
                                        </EmptyDataTableHint>}
                                    />
                                </Section>

                                <Section
                                    title="Aufträge"
                                    type="list"
                                    classes="related-jobs"
                                    id="related-jobs"
                                    subline={
                                        <Fragment>
                                            Nur aktive Aufträge <Tooltip id="related-jobs-tt">Unter "Aktive
                                                                                              Aufträge" werden alle Aufträge
                                                                                              zusammengefasst, die in Scaffeye®
                                                                                              hinterlegt und nicht archiviert
                                                                                              sind.</Tooltip>
                                        </Fragment>
                                    }
                                >
                                    <ReferencedJobsTable
                                        data={relatedJobs && relatedJobs.filter((job) => job.type === JobType.Job && !job.archived)}
                                        onItemChange={this.onJobChange}
                                        emptyTableHint={<EmptyDataTableHint
                                            numColumns={6}
                                            title="Es wurden noch keine Aufträge mit diesem Gerüst verknüpft."
                                        >
                                            <p>
                                                Sie können Gerüste und Aufträge miteinander
                                                verknüpfen. Wählen Sie dazu den
                                                gewünschten Auftrag im Sidebar-Reiter
                                                {' '}<Link to="/jobs">Aufträge</Link>{' '}
                                                aus und folgen Sie den Anweisungen
                                                der Sektion &quot;Baustellendaten&quot;.
                                            </p>
                                        </EmptyDataTableHint>}
                                    />
                                </Section>
                            </IfUser>

                            <IfUser
                                can={['delete-managed-scaffoldings']}
                                and={!isFetching && !isThirdParty && managed && viewMode !== ResourceViewMode.Archived}
                            >
                                <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                            Gerüst löschen
                        </span>
                                </div>
                            </IfUser>

                            <IfUser
                                can={['delete-scaffoldings']}
                                and={!isFetching && !isThirdParty && !managed && viewMode !== ResourceViewMode.Archived}
                            >
                                <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                            Gerüst löschen
                        </span>
                                </div>
                            </IfUser>

                            {showEditModal &&
                            <ScaffoldingFormModal
                                mode="edit"
                                id={scaffolding.id}
                                onClose={this.toggleEditModal}
                                onUpdated={this.onUpdated}
                            />
                            }

                            {showDeleteModal &&
                            <DeleteModal
                                modalTitle="Gerüst löschen"
                                deleteEntity={this.deleteScaffolding}
                                closeModal={this.toggleDeleteModal}
                                isDeletingEntity={isDeletingScaffolding}
                            >
                                <p>
                                    Sie sind im Begriff, das aktive Gerüst {' '}
                                    <span
                                        onClick={this.toggleDeleteModal}
                                        className="btn--link-neutral"
                                    >
                            {scaffolding.name}
                        </span>
                                    {' '} zu löschen.
                                    <strong> Alle dazugehörigen Gerüstabschnitte werden damit ebenfalls
                                             geschlossen und gelöscht.
                                    </strong>
                                </p>
                                <p>Möchten Sie fortfahren?</p>
                            </DeleteModal>
                            }

                            {showAddSectionModal &&
                            <SectionFormModal
                                scaffoldingId={scaffolding.id}
                                onClose={this.toggleAddSectionModal}
                                onCreated={this.onSectionCreated}
                            />
                            }

                            {showDeleteSectionModal &&
                            <DeleteModal
                                modalTitle="Gerüstabschnitt löschen"
                                deleteEntity={this.deleteSection}
                                closeModal={this.toggleDeleteSectionModal}
                                isDeletingEntity={isDeletingSection}
                            >
                                <p>Sie sind im Begriff, den aktiven Gerüstabschnitt

                                    <Link
                                        to={`/sections/${entityToDelete.id}`}
                                    > {entityToDelete.name}
                                    </Link> zu löschen.
                                </p>
                                <p>Möchten Sie fortfahren?</p>
                            </DeleteModal>
                            }
                        </Fragment>
                    )
                }


            </Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchScaffoldingDetailData,
        deleteSingleScaffolding,
        deleteSingleSection,
        addNewSection,
        addSectionNote,
    }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ScaffoldingDetail));

ScaffoldingDetail.propTypes = {
    scaffoldingId: PropTypes.string,
};

ScaffoldingDetail.defaultProps = {
    scaffoldingId: '',
};
