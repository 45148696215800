import * as types from '../../types';

const initialState = {
    isFetchingList: false,
    listWasFetched: false,
    error: null,
    currentPage: 1,
    amountPerPage: 10,
    customersList: {
        items: [],
        total: 0
    },
    sort: {
        direction: '-',
        field: 'created'
    },
    addCustomerError: null,
    fetchCustomersError: null
};

export default function customersReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_NEW_CUSTOMER_SUCCESS:
            return {
                ...state,
                customerWasAdded: true
            };
        case types.ADD_NEW_CUSTOMER_FAILURE:
            return {
                ...state,
                addCustomerError: action.error
            };

        /*
         * Fetching customers
         */
        case types.FETCH_CUSTOMERS:
            return {
                ...state,
                isFetchingList: true
            };

        /*
         * Fetching list succeeded
         */
        case types.FETCH_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                isFetchingList: false,
                listWasFetched: true,
                customersList: {
                    items: action.payload.data,
                    total: action.payload.total
                }
            };
        }

        /*
         * Sorting
         */
        case types.SET_CUSTOMERS_SORT_OPTION:
            return {
                ...state,
                sort: action.payload
            };
        /*
         * Paginator
         */
        case types.SET_CURRENT_CUSTOMERS_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        /*
         * Fetching list failed
         */
        case types.FETCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                isFetchingList: false,
                error: action.error
            };

        default:
            return state;
    }
}
