import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ title, titleSuffix, children }) => (
    <div className="page__title">
        <h1>{title} {titleSuffix}</h1>
        {children}
    </div>
);

export default PageTitle;

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    titleSuffix: PropTypes.element
};

PageTitle.defaultProps = {
    titleSuffix: null
};
