import React from 'react';
import JobStatus from '../../Job/JobStatus';
import { JobType } from '../../Job/properties';
import {
    DiffType,
    JobDiffProperty, JobDiffType,
    ScaffoldingDiffProperty,
    SectionDiffProperty,
    SiteDiffProperty
} from './properties';

export function getSitePropertyLabel(property) {
    switch (property) {
        case SiteDiffProperty.Name:
            return 'Name';
        case SiteDiffProperty.Description:
            return 'Notizen';
        case SiteDiffProperty.Address:
            return 'Adresse';
        case SiteDiffProperty.Attachments:
            return 'Anhänge';
        default:
            return `Unbekannt (${property})`;
    }
}

export function getScaffoldingPropertyLabel(property) {
    switch (property) {
        case ScaffoldingDiffProperty.Name:
            return 'Name';
        case ScaffoldingDiffProperty.Description:
            return 'Notizen';
        case ScaffoldingDiffProperty.ScheduledServiceTime:
            return 'Geplante Standzeit';
        case ScaffoldingDiffProperty.Attachments:
            return 'Anhänge';
        case ScaffoldingDiffProperty.UtilizationPlans:
            return 'Nutzungspläne';
        case ScaffoldingDiffProperty.AnchorProtocol:
            return 'Ankerprotokoll';
        default:
            return `Unbekannt (${property})`;
    }
}

export function getSectionPropertyLabel(property) {
    switch (property) {
        case SectionDiffProperty.Managed:
            return 'Art des Gerüstabschnittes';
        case SectionDiffProperty.Name:
            return 'Name';
        case SectionDiffProperty.EquippedSurfaces:
            return 'Eingerüstete Flächen';
        case SectionDiffProperty.Status:
            return 'Status';
        case SectionDiffProperty.PlannedWorks:
            return 'Geplante Arbeiten';
        case SectionDiffProperty.Purpose:
            return 'Verwendungszweck';
        case SectionDiffProperty.SupportType:
            return 'Tragsystem';
        case SectionDiffProperty.StabilityAndLoadSafety:
                return 'Stand- und Tragsicherheit';
        case SectionDiffProperty.ConstructionType:
            return 'Ausführungsart';
        case SectionDiffProperty.LoadClass:
            return 'Lastklasse';
        case SectionDiffProperty.WallClearance:
            return 'Wandabstand';
        case SectionDiffProperty.WidthClass:
            return 'Breitenklasse';
        case SectionDiffProperty.Claddings:
            return 'Gerüstverkleidungen';
        case SectionDiffProperty.SpecialFeatures:
            return 'Besonderheiten';
        case SectionDiffProperty.Description:
            return 'Notizen';
        case SectionDiffProperty.Scaffolding:
            return 'Gerüst';
        case SectionDiffProperty.Site:
            return 'Baustelle';
        case SectionDiffProperty.Contractor:
            return 'Gerüstersteller';
        case SectionDiffProperty.UtilizationPlans:
            return 'Nutzungsplan';
        case SectionDiffProperty.AnchorProtocol:
            return 'Ankerprotokoll';
        case SectionDiffProperty.ProofOfStability:
            return 'Standsicherheitsnachweis';
        case SectionDiffProperty.Attachments:
            return 'Anhänge';
        case SectionDiffProperty.ApprovedByName:
            return 'Freigegeben durch (befähigte Person)';
        case SectionDiffProperty.Customer:
            return 'Auftraggeber';
        case SectionDiffProperty.Archived:
            return '';
        default:
            return `Unbekannt (${property})`;
    }
}

export function getJobPropertyLabel(property) {
    switch (property) {
        case JobDiffProperty.Name:
            return 'Name';
        case JobDiffProperty.ConstructionPlan:
            return 'Bauplanung';
        case JobDiffProperty.Customer:
            return 'Kunde';
        case JobDiffProperty.CustomerContact:
            return 'Ansprechpartner';
        case JobDiffProperty.Description:
            return 'Notizen';
        case JobDiffProperty.Status:
            return 'Status';
        case JobDiffProperty.OfferDeadline:
            return 'Angebotsabgabe bis';
        default:
            return `Unbekannt (${property})`;
    }
}

export function getDiffTypeLabel(diffType) {
    switch (diffType) {
        case DiffType.New:
            return 'Neue Angabe';
        case DiffType.Deleted:
            return 'Entfernte Angabe';
        case DiffType.Archived:
            return 'Die Baustelle wurde archiviert';
        case DiffType.Reactivated:
            return 'Die Baustelle wurde reaktiviert';
        case DiffType.Changed:
            return 'Geänderte Angabe';
        default:
            return '';
    }
}

export function getJobDiffTypeLabel(diffType, diff, type) {

    const label = type === JobType.Job ? 'Auftrag' : 'Anfrage';

    switch (diffType) {
        case DiffType.Archived:
            return <span className={`change-log__diff-lbl--${DiffType.Archived}`}
            > <span className="change-log__diff-main-action">{label}</span> wurde archiviert</span>;
        case DiffType.Reactivated:
            return <span className={`change-log__diff-lbl--${DiffType.Reactivated}`}
            > <span className="change-log__diff-main-action"
            >{label}</span> wurde reaktiviert</span>;
        case JobDiffType.ConstructionPlanCreated:
            return <span className={`change-log__diff-lbl--${JobDiffType.ConstructionPlanCreated}`}
            > <span className="change-log__diff-main-action"
            >Baustellenplanung</span> wurde erstellt</span>;
        case JobDiffType.ConstructionPlanChanged:
            return <span className={`change-log__diff-lbl--${JobDiffType.ConstructionPlanChanged}`}
            > <span className="change-log__diff-main-action"
            >Baustellenplanung</span> wurde geändert</span>;
        case JobDiffType.ConstructionPlanDeleted:
            return <span className={`change-log__diff-lbl--${JobDiffType.ConstructionPlanDeleted}`}
            > <span className="change-log__diff-main-action"
            >Baustellenplanung</span> wurde entfernt</span>;
        case JobDiffType.StatusChanged:
            return <span className={`change-log__diff-lbl--${JobDiffType.StatusChanged}`}
            > <span className="change-log__diff-main-action"
            >Statusänderung</span> von "{JobStatus.renderStatusWithJobType(diff.oldVal)}"
                zu <span className="change-log__diff-highlight"
                > {JobStatus.renderStatusWithJobType(diff.newVal)}</span></span>;
        case JobDiffType.JobTypeChangedToJob:
            return (
                <span className={`change-log__diff-lbl--${JobDiffType.JobTypeChangedToJob}`}>
                    <span className="change-log__diff-main-action">
                        Umwandlung
                    </span>
                    von <span className="change-log__diff-main-action"
                >Anfrage</span>({JobStatus.renderStatusWithJobType(diff.oldVal)})
                    zu <span className="change-log__diff-main-action"
                >Auftrag</span>({JobStatus.renderStatusWithJobType(diff.newVal)})
                </span>
            );
        case JobDiffType.JobTypeChangedToRequest:
            return (
                <span className={`change-log__diff-lbl--${JobDiffType.JobTypeChangedToRequest}`}>
                     <span className="change-log__diff-main-action">
                         Umwandlung
                     </span>
                     von <span className="change-log__diff-main-action"
                >Auftrag</span>({JobStatus.renderStatusWithJobType(diff.oldVal)})
                     zu <span className="change-log__diff-main-action"
                >Anfrage</span>({JobStatus.renderStatusWithJobType(diff.newVal)})
                </span>
            );
        default:
            return getDiffTypeLabel(diffType);
    }
}
