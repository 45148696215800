import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

class ScrollSpy extends Component {

    static childContextTypes = {
        scrollSpy: PropTypes.object
    };

    state = {
        sections: [],
        currentSection: null
    };

    constructor(props) {
        super(props);
        this.setCurrentSection = this.setCurrentSection.bind(this);
        this.debouncedSetCurrentSection = _.throttle(this.setCurrentSection, 50);
    }

    getChildContext() {
        return {
            scrollSpy: {
                registerLink: this.registerLink,
                currentSection: this.state.currentSection,
                jumpToSection: this.jumpToSection
            }
        };
    }

    jumpToSection = (sectionId) => {
        if (!sectionId) {
            return;
        }

        const targetSection = this.state.sections.find(section => section.id === sectionId);
        if (!targetSection) {
            return;
        }

        const scrollOptions = {
            top: sectionId !== 'generalInformation' ? targetSection.offsetTop - 50 : 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);

    };

    componentDidMount() {
        window.addEventListener('scroll', this.debouncedSetCurrentSection);
        this.setCurrentSection();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.debouncedSetCurrentSection);
    }

    registerLink = (section) => {
        const { sections } = this.state;
        const el = document.getElementById(section);
        if (el) {
            sections.push(el);
            this.setState({ sections });
        }
    };

    getSectionOffsetTop(section) {
        return section.getBoundingClientRect().top;
    }

    setCurrentSection = () => {

        const { sections } = this.state;
        const { defaultSection } = this.props;
        const sectionCount = sections.length;

        if (!sectionCount) return;

        let currentSection = defaultSection ? sections[0].getAttribute('id') : null;

        for (let i = 0; i < sectionCount; i++) {
            const section = sections[i];
            if (!section) {
                continue;
            }

            const top = this.getSectionOffsetTop(section);
            if (top <= (this.props.collapsedHeader ? 114 : 160) - 96 + 30) {
                currentSection = section.getAttribute('id');
            }
        }

        this.setState({ currentSection });
    };

    render() {
        return this.props.children;
    }
}

export default ScrollSpy;
