import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ScaffoldingServiceTime from '../../../../Scaffoldings/Detail/ScaffoldingServiceTime';
import asSyncChoiceField from '../../asSyncChoiceField';
import SyncChoiceIdenticalRow from '../../Base/Input/SyncChoiceIdenticalRow';
import SyncChoiceOptionHint, { SyncOptionHintType } from '../../Base/Input/SyncChoiceOptionHint';
import SyncChoiceRow from '../../Base/Input/SyncChoiceRow';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';
import { validateSingleChoiceComplete } from '../../validation';
import { isIdenticalSchedule } from '../equals';

const validateScheduledServiceTime = validateSingleChoiceComplete();

const ScheduledServiceTimeSyncChoice = ({ planValue, liveValue, value: currentFormValue, onChange, showIdentical }) => {

    const isSelected = (value) => {
        if (!currentFormValue || !value) {
            return false;
        }
        return isIdenticalSchedule(value, currentFormValue);
    };

    const renderServiceTimeLabel = (value) => {

        if (!value) {
            return '-';
        }

        const { scheduledErection, scheduledDismantling } = value;
        if (!scheduledErection && !scheduledDismantling) {
            return '-';
        }

        return (
            <ScaffoldingServiceTime
                scheduledErection={scheduledErection}
                scheduledDismantling={scheduledDismantling}
            />
        );
    };

    const showHintForOutdatedServiceTime = (value) => {

        const { scheduledDismantling } = value;

        if (!scheduledDismantling) {
            return null;
        }

        if (!moment().isAfter(scheduledDismantling)) {
            return null;
        }

        return (
            <SyncChoiceOptionHint
                content="Ihre angegebene geplante Standzeit liegt in der Vergangenheit. Bitte überprüfen Sie die Standzeit und passen Sie diese bei Bedarf an."
                type={SyncOptionHintType.Warning}
                label="Warnung"
                tooltipId="sync-service-time-outdated-hint"
            />
        );
    };

    const renderServiceTimes = (isIdentical) => {

        const leftValue = planValue ? planValue : null;
        const rightValue = liveValue ? liveValue : null;

        if(isIdentical){
            return(
                <SyncChoiceIdenticalRow
                    leftValue={null}
                    rightValue={null}
                    renderValue={renderServiceTimeLabel}
                />
            )
        }

        return (
            <SyncChoiceRow
                leftValue={leftValue}
                rightValue={rightValue}
                renderValue={renderServiceTimeLabel}
                onSelect={onChange}
                isSelected={isSelected}
                hint={showHintForOutdatedServiceTime}
                isIdentical={isIdentical}
            />
        );
    };

    const isIdentical = isIdenticalSchedule(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Geplante Standzeit"
        >
            {renderServiceTimes(isIdentical)}
        </SyncInputGroup>
    );
};

ScheduledServiceTimeSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    showIdentical: PropTypes.bool
};

export default asSyncChoiceField(ScheduledServiceTimeSyncChoice, {
    allowNull: true,
    validate: validateScheduledServiceTime
});
