import PropTypes from 'prop-types';
import React from 'react';
import { getFileExtension } from '../../utils';

const FileExtension = ({ fileName }) => {

    if (!fileName) {
        return 'n.a';
    }

    const extension = getFileExtension(fileName);

    const fileType = (extension) => {

        switch (extension) {
            case 'jpeg':
            case 'jpg':
                return 'JPG';

            default:
                return extension;
        }
    };

    return (
        <div style={{ textTransform: 'uppercase' }}>
            {fileType(extension)}
        </div>
    );
};

export default FileExtension;

FileExtension.propTypes = {
    fileName: PropTypes.string.isRequired
};
