import _ from 'lodash';
import React from 'react';
import { ModelType } from '../../properties';
import { isEmpty } from '../Common/Diff/functions';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import { ModelMediaCollections } from '../Media/properties';
import { modelHasMedia, resolveMediaCollection } from '../Media/utils';
import { ConstructionPlanModelMode } from './properties';

const getReferenceData = (modelId, referenceDataList) => {
    if (!modelId || !referenceDataList) {
        return undefined;
    }

    return _.find(referenceDataList, referenceData => referenceData.id === modelId);
};

const walkConstructionPlanSection = (constructionPlanSection, referenceData, modelCallback) => {
    if (!constructionPlanSection) {
        return null;
    }

    modelCallback(ModelType.Section, constructionPlanSection, referenceData);
};

export const walkConstructionPlanScaffolding = (constructionPlanScaffolding, referenceData, modelCallback) => {
    if (!constructionPlanScaffolding) {
        return null;
    }

    const stopTraversing = modelCallback(ModelType.Scaffolding, constructionPlanScaffolding, referenceData);
    if (stopTraversing === true) {
        return;
    }

    const { sections } = constructionPlanScaffolding;
    if (!sections || !sections.length) {
        return;
    }

    const { sections: sectionsReferenceData } = referenceData || {};

    sections.forEach(section => walkConstructionPlanSection(section, getReferenceData(section.id, sectionsReferenceData), modelCallback));
};

export const walkConstructionPlanSite = (constructionPlanSite, siteReferenceData, modelCallback) => {
    if (!constructionPlanSite) {
        return null;
    }

    const stopTraversing = modelCallback(ModelType.Site, constructionPlanSite, siteReferenceData);
    if (stopTraversing === true) {
        return;
    }

    const { scaffoldings } = constructionPlanSite;
    if (!scaffoldings || !scaffoldings.length) {
        return;
    }

    const { scaffoldings: scaffoldingsReferenceData } = siteReferenceData || {};

    scaffoldings.forEach(scaffolding => walkConstructionPlanScaffolding(scaffolding, getReferenceData(scaffolding.id, scaffoldingsReferenceData), modelCallback));
};

const extractMedia = (modelMode, modelData, referenceData, field, isSingle = false, excludeUnreferenced = true) => {

    // We can't make any valid extraction without a model mode
    if (!modelMode) {
        return {};
    }

    // 'new' or 'edit' mode -> evaluate modelData
    const resolvedMedia = {};
    if (modelMode !== ConstructionPlanModelMode.Reference) {
        if (modelData) {
            const modelMedia = modelData[field];
            if (isSingle) {
                if (modelMedia) {
                    resolvedMedia[modelMedia.id] = { ...modelMedia };
                }
            } else if (_.isArray(modelMedia)) {
                modelMedia.forEach(media => {
                    resolvedMedia[media.id] = {
                        ...media
                    };
                });
            }
        }
    }

    // If only directly referenced should be included and we're looking
    // at an edited model we return the found ones
    if (modelMode === ConstructionPlanModelMode.Edit && excludeUnreferenced) {
        return resolvedMedia;
    }

    // If no reference data is given, we can't resolve anything
    if (!referenceData) {
        return resolvedMedia;
    }

    // 'reference' or 'edit' mode -> evaluate referenceData
    const mediaReferenceData = referenceData[field];
    if (isSingle) {
        if (mediaReferenceData) {
            if (!resolvedMedia[mediaReferenceData.id]) {
                resolvedMedia[mediaReferenceData.id] = { ...mediaReferenceData };
            }
        }
    } else if (_.isArray(mediaReferenceData)) {
        mediaReferenceData.forEach(media => {
            if (!resolvedMedia[mediaReferenceData.id]) {
                resolvedMedia[media.id] = {
                    ...media,
                    mode: ConstructionPlanModelMode.Reference
                };
            }
        });
    }

    return resolvedMedia;
};

const extractConstructionPlanSiteMedia = (data, siteReferenceData, mode, excludeUnreferenced = true) => {
    return extractMedia(mode, data, siteReferenceData, 'attachments', false, excludeUnreferenced);
};

const extractConstructionPlanScaffoldingMedia = (data, scaffoldingReferenceData, mode, excludeUnreferenced = true) => {
    const attachmentMedia = extractMedia(mode, data, scaffoldingReferenceData, 'attachments', false, excludeUnreferenced);
    const utilizationPlanMedia = extractMedia(mode, data, scaffoldingReferenceData, 'utilizationPlans', false, excludeUnreferenced);
    const anchorProtocolMedia = extractMedia(mode, data, scaffoldingReferenceData, 'anchorProtocol', true, excludeUnreferenced);

    return {
        ...attachmentMedia,
        ...utilizationPlanMedia,
        ...anchorProtocolMedia
    };
};

const extractConstructionPlanSectionMedia = (data, sectionReferenceData, mode, excludeUnreferenced = true) => {
    const attachmentMedia = extractMedia(mode, data, sectionReferenceData, 'attachments', false, excludeUnreferenced);
    const utilizationPlanMedia = extractMedia(mode, data, sectionReferenceData, 'utilizationPlans', false, excludeUnreferenced);
    const anchorProtocolMedia = extractMedia(mode, data, sectionReferenceData, 'anchorProtocol', true, excludeUnreferenced);
    const proofOfStabilityMedia = extractMedia(mode, data, sectionReferenceData, 'proofOfStability', true, excludeUnreferenced);

    return {
        ...attachmentMedia,
        ...utilizationPlanMedia,
        ...anchorProtocolMedia,
        ...proofOfStabilityMedia
    };
};

/**
 * Walks the whole construction plan (and reference data) and extract
 * all media referenced, edited or added
 *
 * @return Object map from media id to media object
 */
export const getMediaMap = (constructionPlanSite, siteReferenceData, excludeUnreferenced = true) => {

    let constructionPlanMedia = {};

    walkConstructionPlanSite(constructionPlanSite, siteReferenceData, (modelType, { mode, data }, referenceData) => {
        switch (modelType) {
            case ModelType.Site:
                constructionPlanMedia = {
                    ...constructionPlanMedia,
                    ...extractConstructionPlanSiteMedia(data, referenceData, mode, excludeUnreferenced)
                };
                break;
            case ModelType.Scaffolding:
                constructionPlanMedia = {
                    ...constructionPlanMedia,
                    ...extractConstructionPlanScaffoldingMedia(data, referenceData, mode, excludeUnreferenced)
                };
                break;
            case ModelType.Section:
                constructionPlanMedia = {
                    ...constructionPlanMedia,
                    ...extractConstructionPlanSectionMedia(data, referenceData, mode, excludeUnreferenced)
                };
                break;
            default:
                console.error(`Encountered unknown model type ${modelType}`);
        }
    });

    return constructionPlanMedia;
};

export const extractAndResolveModelMedia = (modelType, modelId, data, mediaKeys, resolvedMedia, formMode = false) => {
    const mediaByCollection = {};
    mediaKeys.forEach(mediaCollectionKey => {

        const mediaCollection = data[mediaCollectionKey];
        if (!mediaCollection) {
            if (formMode) {
                mediaByCollection[mediaCollectionKey] = [];
                return;
            }
            return;
        }

        if (_.isArray(mediaCollection)) {
            mediaByCollection[mediaCollectionKey] = resolveMediaCollection(modelType, modelId, mediaCollectionKey, mediaCollection, resolvedMedia, formMode);
        } else {
            mediaByCollection[mediaCollectionKey] = resolveMediaCollection(modelType, modelId, mediaCollectionKey, [mediaCollection], resolvedMedia, formMode);
        }
    });

    return Object.keys(mediaByCollection).length > 0 ? mediaByCollection : null;
};

const getSiteMediaGroup = (model, referenceData, resolvedMedia, formMode = false) => {
    return getModelMediaGroup(ModelType.Site, model, referenceData, resolvedMedia, formMode);
};

const getScaffoldingMediaGroup = (model, referenceData, resolvedMedia, formMode = false) => {
    return getModelMediaGroup(ModelType.Scaffolding, model, referenceData, resolvedMedia, formMode);
};

const getSectionMediaGroup = (model, referenceData, resolvedMedia, formMode = false) => {
    return getModelMediaGroup(ModelType.Section, model, referenceData, resolvedMedia, formMode);
};

const getModelMediaGroup = (modelType, model, referenceData, resolvedMedia, formMode = false) => {

    const { id, mode, data } = model;
    const _data = mode === ConstructionPlanModelMode.Reference ? referenceData : data;

    if (!formMode && !modelHasMedia(_data, ModelMediaCollections[modelType])) {
        return null;
    }

    const group = {
        id,
        type: modelType,
        name: getData({
            mode,
            data
        }, referenceData, 'name', 'n.a.'),
        media: extractAndResolveModelMedia(modelType, id, _data, ModelMediaCollections[modelType], resolvedMedia, formMode)
    };

    if (formMode) {
        group.mode = mode;
    }

    return group;
};

export const getGroupedConstructionPlanMedia = (constructionPlanSite, siteReferenceData, resolvedMedia, formMode = false) => {

    const groupedMedia = [];

    if (!constructionPlanSite) {
        return groupedMedia;
    }

    walkConstructionPlanSite(constructionPlanSite, siteReferenceData, (modelType, model, referenceData) => {
        switch (modelType) {
            case ModelType.Site:
                groupedMedia.push(
                    getSiteMediaGroup(model, referenceData, resolvedMedia, formMode)
                );
                break;
            case ModelType.Scaffolding:
                groupedMedia.push(
                    getScaffoldingMediaGroup(model, referenceData, resolvedMedia, formMode)
                );
                break;
            case ModelType.Section:
                groupedMedia.push(
                    getSectionMediaGroup(model, referenceData, resolvedMedia, formMode)
                );
                break;
            default:
                console.error(`Encountered unknown model type ${modelType}`);
        }
    });

    return groupedMedia.filter(mg => mg);
};

export const getData = (model, referenceData, fieldName, fallback =
    <NotAvailablePlaceholder />) => {
    if (!model) {
        return fallback;
    }

    const { data } = model;

    if (model.mode === ConstructionPlanModelMode.Reference) {
        return referenceData ? referenceData[fieldName] : fallback;
    } else if (model.mode === ConstructionPlanModelMode.Edit) {
        if (data && data[fieldName]) {
            return data[fieldName];
        } else if (referenceData && referenceData[fieldName]) {
            return referenceData[fieldName];
        }
        return fallback;
    } else {
        if (data && data[fieldName]) {
            return data[fieldName];
        }
        return fallback;
    }
};

export const getSyncChoiceValue = (key, data, fallback = false) => ({
    [key]: data[key] || fallback
});

function negate(obj) {
    if(!_.isObject(obj)) {
        return null;
    }

    const keys = Object.keys(obj);
    if(keys.length !== 1) {
        return null;
    }

    const key = keys[0];
    const value = obj[key];
    if(_.isBoolean(value)) {
        return {
            [key]: !value
        };
    }
    if(_.isString(value)) {
        return {
            [key]: ''
        };
    }

    return null;
}

export const getObjectSyncChoiceValues = (planValue, liveValue) => {
    let leftValue = !isEmpty(planValue) ? getSyncChoiceValue(Object.keys(planValue)[0], planValue, null) : null;
    let rightValue = !isEmpty(liveValue) ? getSyncChoiceValue(Object.keys(liveValue)[0], liveValue, null) : null;

    if(leftValue !== rightValue) {
        if(leftValue === null) {
            leftValue = negate(rightValue);
        }
        if(rightValue === null) {
            rightValue = negate(leftValue);
        }
    }

    return {
        leftValue,
        rightValue
    }
};

export const isNewPlan = constructionPlan => constructionPlan.site.mode  === ConstructionPlanModelMode.New;
