import React from 'react';
import Icon from '../Common/Icon';

const componentCssClass = 'help-option';

const HelpOption = ({ icon, title, iconLinksTo, children, id }) => {

    const wrapperClasses = [componentCssClass];

    if (id) {
        wrapperClasses.push(`${componentCssClass}--${id}`);
    }

    return (
        <div className={wrapperClasses.join(' ')}>
            {
                icon && <div className="help-option__icn-wrp">
                    {iconLinksTo ? <a
                            href={iconLinksTo}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="help-option__icn"><Icon name={icon} /> </span>
                        </a>
                        :
                        <span className="help-option__icn"><Icon name={icon} /> </span>
                    }
                </div>
            }
            <div className="help-option__cnt">
                {title && <div className="help-option__ttl">
                    {title}
                </div>}
                <div className="help-option__desc">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default (HelpOption);




