import moment from 'moment';
import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import ResourceNotFoundErrorPage from '../../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { ResourceViewMode } from '../../../Containers/Pages/Private/properties';
import { isThirdPartyAndArchived } from '../../../Containers/Pages/Private/SiteDetailPage';
import Permission from '../../../permissions';
import { showApiError, showRequestError, showTempError } from '../../../redux/modules/error/action';
import {
    deleteSingleSection,
    fetchSectionDetailData,
    sendCloseRequest,
    updateSection,
    updateSectionStatus,
} from '../../../redux/modules/section/action';
import { LockedByException } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { UserType } from '../../../user';
import {
    applyModelChangesToCollection,
    getFieldCount,
    optionallyAddCount,
    renderCurrentPosting,
} from '../../../utils';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import DataRow from '../../Common/DataRow';
import EmptyCardHint from '../../Common/EmptyCardHint';
import EmptyDataTableHint from '../../Common/EmptyDataTableHint';
import Icon from '../../Common/Icon';
import IfUser from '../../Common/IfUser';
import InvitedIndicator from '../../Common/InvitedIndicator';
import DeleteModal from '../../Common/Modal/DeleteModal';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import { ScaffoldingReference, SiteReference } from '../../Common/ObjectReference';
import PrimaryColumn from '../../Common/PrimaryColumn';
import ScaffoldingDropdown from '../../Common/ScaffoldingDropdown';
import Table from '../../Common/Table';
import ThirdPartyIndicator from '../../Common/ThirdPartyIndicator';
import Tooltip from '../../Common/Tooltip';
import UnmanagedResourceIndicator from '../../Common/UnmanagedIndicator';
import DetailPageHeader from '../../DetailPageNavigation/DetailPageHeader';
import { getInspector, getInspectorCompany } from '../../Inspections/helpers';
import InspectionType from '../../Inspections/InspectionType';
import { JobType } from '../../Job/properties';
import ReferencedJobRequestsTable from '../../Job/Referenced/ReferencedJobRequestsTable';
import { ReferencedJobsTable } from '../../Job/Referenced/ReferencedJobsTable';
import Section from '../../Layout/Section';
import SectionFooter from '../../Layout/SectionFooter';
import MediaList from '../../Media/MediaList';
import { PageLoadingSpinner } from '../../Page/LoadingSpinner';
import TopNav from '../../Page/TopNav';
import SectionFormModal from '../Form/SectionFormModal';
import SectionStatusQuickEdit from '../Form/SectionStatusQuickEdit';

import {
    formatShortId,
    hasRightToSendCloseRequest,
    renderManagedApprovedByInformation,
    showCladdings,
    showConstructionType,
    showEquippedSurfaces,
    showLoadClass,
    showPlannedWorks,
    showSpecialFeatures,
    showStabilityAndLoadSafety,
    showSupportType,
    showWallClearance,
    showWidthClass,
} from '../helpers';
import InspectionResult from '../InspectionResult';
import { SectionPurpose } from '../SectionPurpose';
import SectionStatusBadge from '../SectionStatusBadge';
import InspectionModal from './InspectionModal';
import PostingsModal from './PostingsModal';
import SectionBreadcrumb from './SectionBreadcrumb';
import SectionChangeLogModal from './SectionChangeLogModal';
import SendCloseRequestModal from './SendCloseRequestModal';


const getNavigationLinks = (section) => {

    const documentCount =
        getFieldCount(section, 'attachments')
        + getFieldCount(section, 'utilizationPlans')
        + section.anchorProtocol ? 1 : 0
        + section.proofOfStability ? 1 : 0;
    
    const jobRequestCount = getFieldCount(section, 'jobs', job => job.type === JobType.Request);
    const jobCount = getFieldCount(section, 'jobs', job => job.type === JobType.Job);

    return [
        {
            id: 'generalInformation',
            label: 'Allgemeine Angaben',
        },
        {
            id: 'specifications',
            label: 'Spezifikationen',
        },
        {
            id: 'notes',
            label: 'Notizen',
        },
        {
            id: 'documents',
            label: optionallyAddCount('Dokumente', documentCount),
        },
        {
            id: 'inspection-history',
            label: 'Prüfhistorie',
        },
        {
            id: 'related-requests',
            label: optionallyAddCount('Anfragen', jobRequestCount),
        },
        {
            id: 'related-jobs',
            label: optionallyAddCount('Aufträge', jobCount),
        },
    ];
};

class SectionDetail extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            section: {},
            loading: false,
            isFetching: true,
            isDeletingSection: false,
            showInspectionModal: false,
            showEditModal: false,
            showDeleteModal: false,
            showPostingsModal: false,
            showChangeLogModal: false,
            showSendCloseRequestModal: false,
            isSendingCloseRequest: false,
            pageHeaderCollapsed: false,
        };

        this.onUpdated = this.onUpdated.bind(this);
        this.deleteSection = this.deleteSection.bind(this);

        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.togglePostingsModal = this.togglePostingsModal.bind(this);
        this.toggleInspectionModal = this.toggleInspectionModal.bind(this);
        this.toggleSendCloseRequestModal = this.toggleSendCloseRequestModal.bind(this);
        this.toggleChangeLogModal = this.toggleChangeLogModal.bind(this);
        this.onSendCloseRequest = this.onSendCloseRequest.bind(this);
        this.sectionStatusChanged = this.sectionStatusChanged.bind(this);
        this.onJobChange = this.onJobChange.bind(this);
        this.onPageHeaderCollapse = this.onPageHeaderCollapse.bind(this);
    }

    static renderScaffoldings(scaffoldings) {
        if (!scaffoldings || scaffoldings.length === 0) {
            return 'n.a.';
        }

        return (
            <ScaffoldingDropdown scaffoldings={scaffoldings}/>
        );
    }

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onUpdated() {
        this.toggleEditModal();
        this.load();
    }

    onSendCloseRequest() {
        const {section: {id}} = this.state;

        const {
            actions: {sendCloseRequest},
        } = this.props;

        this.setState({
            isSendingCloseRequest: true,
        });

        sendCloseRequest(id)
            .then(() => {
                this.setState({
                    isSendingCloseRequest: false,
                });
                this.toggleSendCloseRequestModal();
                toast.success('Sperranfrage wurde gesendet', {autoClose: 2500});
            })
            .catch((error) => {
                this.setState({
                    isSendingCloseRequest: false,
                });
                showApiError(error);
            });
    }

    hasMedia() {
        const {section: {attachments, utilizationPlans, anchorProtocol, proofOfStability}} = this.state;
        return (attachments && attachments.length)
            || (utilizationPlans && utilizationPlans.length)
            || anchorProtocol
            || proofOfStability;
    }

    load() {
        this.setState({loading: true});

        this.props.actions.fetchSectionDetailData(this.props.sectionId)
            .then((section) => {

                if (isThirdPartyAndArchived(section)) {
                    return Promise.reject('Der Gerüstabschnitt ist nicht mehr verfügbar. Bitte kontaktieren Sie den Gerüstersteller für weitere Informationen.');
                }

                if (this.loadingGotCancelled) {
                    return;
                }

                const scaffolding = section.scaffolding || {};
                const site = scaffolding.site || {};

                const {history} = this.props;
                history.replace({
                    ...history.location,
                    state: {
                        ...history.location.state,
                        sectionId: section.id,
                        scaffoldingId: scaffolding.id,
                        siteId: site.id,
                        sitesOverviewQueryString: (history.location.state && history.location.state.sitesOverviewQueryString) || (scaffolding.site.archived ? 'archived=true' : ''),
                    },
                });

                const {archived} = section;

                this.setState({
                    section,
                    loading: false,
                    isFetching: false,
                    viewMode: archived ? ResourceViewMode.Archived : ResourceViewMode.Active,
                });
            })
            .catch((error) => {
                if (typeof error === 'string') {
                    showTempError(error);
                } else {
                    showRequestError('Der Gerüstabschnitt konnte nicht geladen werden');
                }

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    section: null,
                    loading: false,
                    isFetching: false,
                });
            });

    }

    deleteSection() {
        const {section, section: {id}} = this.state;

        const {
            actions: {deleteSingleSection},
            history,
        } = this.props;

        this.setState({
            isDeletingSection: true,
        });

        deleteSingleSection(id)
            .then(() => {
                this.setState({
                    isDeletingSection: false,
                });
                this.toggleDeleteModal();
                toast.success('Gerüstabschnitt wurde gelöscht', {autoClose: 2500});
                history.push(`/scaffoldings/${section.scaffolding.id}`);
            })
            .catch((error) => {

                this.setState({
                    isDeletingSection: false,
                });

                if (error instanceof LockedByException) {
                    return ErrorService.handleLockedByError(error, 'sectionLocked')
                                       .then(({error}) => (showTempError(error)));
                }

                return showApiError(error);

            });
    }

    toggleEditModal() {
        this.setState(prevState => ({
            showEditModal: !prevState.showEditModal,
        }));
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal,
        }));
    }

    showInspectionModal(inspectionId) {
        this.setState({
            currentInspectionId: inspectionId,
            showInspectionModal: true,
        });
    }

    toggleInspectionModal() {
        this.setState(prevState => ({
            showInspectionModal: !prevState.showInspectionModal,
        }));
    }

    toggleChangeLogModal() {
        this.setState(prevState => ({
            showChangeLogModal: !prevState.showChangeLogModal,
        }));
    }

    toggleSendCloseRequestModal() {
        this.setState(prevState => ({
            showSendCloseRequestModal: !prevState.showSendCloseRequestModal,
        }));
    }

    togglePostingsModal() {
        this.setState(prevState => ({
            showPostingsModal: !prevState.showPostingsModal,
        }));
    }

    componentDidUpdate(prevProps) {

        const {sectionId} = this.props;

        if (sectionId !== prevProps.sectionId) {
            this.load();
        }
    }

    sectionStatusChanged(status, publicStatus) {
        this.setState({
            section: {
                ...this.state.section,
                status,
                publicStatus,
            },
        });
    }

    onJobChange(changedJob, changes) {
        const {section} = this.state;
        if (!section) {
            return;
        }

        const {jobs} = section;
        if (!jobs) {
            return;
        }

        const updatedJobs = applyModelChangesToCollection(jobs, changedJob.id, changes);

        this.setState({
            section: {
                ...section,
                jobs: updatedJobs,
            },
        });
    }

    onPageHeaderCollapse(collapsed) {
        this.setState({
            pageHeaderCollapsed: collapsed,
        });
    }

    render() {
        const {
            loading,
            section,
            isFetching,
            currentInspectionId,
            showEditModal,
            showDeleteModal,
            showChangeLogModal,
            showInspectionModal,
            showSendCloseRequestModal,
            isSendingCloseRequest,
            showPostingsModal,
            isDeletingSection,
            viewMode,
            pageHeaderCollapsed,
        } = this.state;

        if (!isFetching && !section) {
            return <ResourceNotFoundErrorPage/>;
        }

        const {
            shortId,
            status,
            name,
            plannedWorks,
            supportType,
            constructionType,
            loadClass,
            wallClearance,
            widthClass,
            staticsCertificateNecessary,
            equippedSurfaces,
            isThirdParty,
            managed,
            contractor,
            currentPosting,
            updated,
            created,
            customer,
            description,
            approvedByName,
            activeAcceptanceInspection,
        } = section;

        const attachments = section.attachments || [];
        const utilizationPlans = section.utilizationPlans || [];
        const inspections = section.inspections || [];
        const claddings = section.claddings || [];
        const specialFeatures = section.specialFeatures || [];
        const postings = section.postings || [];
        const jobs = section.jobs || [];
        const scaffolding = section.scaffolding || {};
        const site = scaffolding.site || {};

        const proofOfStability = section.proofOfStability ? [section.proofOfStability] : [];
        const anchorProtocol = section.anchorProtocol ? [section.anchorProtocol] : [];

        const sectionHasChanged = updated !== created;
        const hasProofOfStability = proofOfStability.length > 0;

        const lastUpdated = moment(updated)
            .format('DD.MM.YYYY, HH:mm');

        const canSendCloseRequests = hasRightToSendCloseRequest(section);
        const pageTitle = isFetching ? 'Lade Gerüstabschnitt' : `Gerüstabschnitt: ${section ? section.name : 'n.a.'}`;

        const actions = (
            <Fragment>
                <IfUser
                    can={Permission.UpdateSections}
                    and={section && !section.isThirdParty}
                >
                    <button
                        onClick={this.toggleDeleteModal}
                        className="btn btn--delete-action btn--icon"
                        title="Gerüstabschnitt löschen"
                    >
                        <span className="btn__icon">
                            <Icon name="delete"/>
                        </span>
                    </button>

                    {
                        !section.isArchived && (
                            <button
                                onClick={this.toggleEditModal}
                                className="btn btn--edit-action btn--icon"
                                title="Gerüstabschnitt bearbeiten"
                            >
                                <span className="btn__icon">
                                    <Icon name="edit"/>
                                </span>
                                <span>
                                    Bearbeiten
                                </span>
                            </button>
                        )
                    }
                </IfUser>
                {canSendCloseRequests &&
                <button
                    className="btn btn--primary-green"
                    onClick={() => this.toggleSendCloseRequestModal()}
                >
                        <span className="btn__icon">
                            <Icon name="info"/>
                        </span>
                    Sperranfrage senden
                </button>
                }
            </Fragment>
        );

        return (
            <Fragment>

                <TopNav
                    hasNewPageHead
                    hide={pageHeaderCollapsed}
                />

                <SectionBreadcrumb
                    collapse={pageHeaderCollapsed}
                    section={section}
                />

                <DetailPageHeader
                    type="section"
                    title={pageTitle}
                    titleAddOns={viewMode === ResourceViewMode.Archived ? <ArchivedBadge/> : null}
                    navigationalLinks={getNavigationLinks(section)}
                    actions={actions}
                    onCollapse={this.onPageHeaderCollapse}
                    isLoading={loading}
                />

                {
                    loading && <PageLoadingSpinner/>
                }

                {
                    !loading && (
                        <Fragment>
                            <IfUser
                                can={['update-sections']}
                                and={sectionHasChanged && !isThirdParty}
                            >
                                <button
                                    className="btn btn--link-neutral page__last-changed"
                                    onClick={this.toggleChangeLogModal}
                                >
                                    Letzte Bearbeitung: {lastUpdated} Uhr
                                </button>
                            </IfUser>

                            <IfUser
                                isOneOfType={[UserType.ProQualifiedWorker, UserType.ProWorker]}
                                and={viewMode === ResourceViewMode.Archived}
                            >
                                <Redirect
                                    to={{
                                        pathname: '/',
                                    }}
                                />
                            </IfUser>

                            <Section
                                title="Allgemeine Angaben"
                                id="generalInformation"
                            >
                                <DataRow
                                    label="Bezeichnung"
                                >
                                    {name} <Tooltip id="tt-section-id">ID: {formatShortId(shortId)}</Tooltip>

                                    {section.isThirdParty && !section.isInvited &&
                                    <ThirdPartyIndicator/>}

                                    {section.isThirdParty && section.isInvited &&
                                    <InvitedIndicator/>}

                                    {!section.isThirdParty && !section.managed &&
                                    <UnmanagedResourceIndicator
                                        label="Fremder Gerüstabschnitt"
                                    />}
                                </DataRow>

                                <DataRow
                                    label="Status"
                                >
                                    <IfUser
                                        can={Permission.UpdateManagedSections}
                                        and={!isThirdParty && managed}
                                        ifNot={<SectionStatusBadge
                                            section={section}
                                            showIcon
                                        />}
                                    >
                                        <SectionStatusQuickEdit
                                            id={section.id}
                                            status={status}
                                            onUpdate={this.sectionStatusChanged}
                                        />
                                    </IfUser>
                                </DataRow>

                                <DataRow
                                    label="Gerüst"
                                >
                                    {scaffolding &&
                                    <ScaffoldingReference
                                        label={<React.Fragment>{scaffolding.name} {scaffolding.archived &&
                                        <ArchivedBadge size="small"/>} </React.Fragment>}
                                        id={scaffolding.id}
                                    />
                                    }
                                    {
                                        (scaffolding && scaffolding.isThirdParty && !scaffolding.isInvited) ?
                                            <ThirdPartyIndicator size="small"/>
                                            :
                                            null
                                    }

                                    {
                                        (scaffolding && scaffolding.isThirdParty && scaffolding.isInvited) ?
                                            <InvitedIndicator size="small"/>
                                            :
                                            null
                                    }

                                    {scaffolding && !scaffolding.isThirdParty && !scaffolding.managed &&
                                    <UnmanagedResourceIndicator
                                        label="Fremdes Gerüst"
                                        size="small"
                                    />
                                    }
                                </DataRow>

                                <DataRow
                                    label="Baustelle"
                                    isRelatedToPrevious
                                >
                                    <SiteReference
                                        label={site.name}
                                        id={site.id}
                                    />
                                    {
                                        (site && site.isThirdParty && !site.isInvited) ?
                                            <ThirdPartyIndicator size="small"/>
                                            :
                                            null
                                    }

                                    {
                                        (site && site.isThirdParty && site.isInvited) ?
                                            <InvitedIndicator size="small"/>
                                            :
                                            null
                                    }
                                </DataRow>

                                <hr/>

                                <DataRow
                                    label="Auftraggeber"
                                >
                                    {customer}
                                </DataRow>

                                {
                                    !managed &&
                                    <DataRow
                                        label="Gerüstersteller"
                                    >
                                        {contractor &&
                                        <React.Fragment>
                                            <div><strong>{contractor.name}</strong></div>
                                            <div>{contractor.addressLine1} {contractor.addressLine2}</div>
                                            <div>{contractor.addressZip} {contractor.addressCity}</div>
                                        </React.Fragment>
                                        }
                                    </DataRow>
                                }

                                <DataRow
                                    label="Freigegeben durch (befähigte Person)"
                                >
                                    {managed ? renderManagedApprovedByInformation(activeAcceptanceInspection) : approvedByName}

                                </DataRow>

                                <hr/>

                                {managed &&
                                    <Fragment>
                                        <DataRow
                                            label="Aushang"
                                        >
                                            { renderCurrentPosting(currentPosting) }
                                            {
                                                (postings.length > 0) &&
                                                <span
                                                    style={{
                                                        paddingLeft: 0,
                                                        marginTop: 15,
                                                    }}
                                                    className="btn btn--link-neutral"
                                                    onClick={this.togglePostingsModal}
                                                >
                                                    Aushanghistorie anzeigen
                                                </span>
                                            }
                                        </DataRow>
                                        <hr/>
                                    </Fragment>
                                }

                                <DataRow
                                    label="Eingerüstete Flächen"
                                >
                                    {equippedSurfaces && showEquippedSurfaces(equippedSurfaces)}
                                </DataRow>

                                <SectionPurpose
                                    mode="data-rows"
                                    purposes={section.purposes}
                                    otherProtectivePurpose={section.otherProtectivePurpose}
                                />

                                <DataRow
                                    label="Geplante Arbeiten"
                                >
                                    {showPlannedWorks(plannedWorks)}
                                </DataRow>

                            </Section>

                            <Section
                                title="Spezifikationen"
                                id="specifications"
                            >
                                <DataRow
                                    label="Tragsystem"
                                >
                                    {showSupportType(supportType)}
                                </DataRow>

                                <DataRow
                                    label="Ausführungsart"
                                >
                                    {showConstructionType(constructionType)}
                                </DataRow>

                                <DataRow
                                    label="Stand- und Tragsicherheit"
                                >
                                    {showStabilityAndLoadSafety(section)}
                                    {
                                        staticsCertificateNecessary && hasProofOfStability &&
                                        <span>
                                            {' –'} <a href="#documents">Zum Statiknachweis</a>
                                        </span>
                                    }
                                </DataRow>

                                <DataRow
                                    label="Lastklasse"
                                >
                                    {showLoadClass(loadClass)}
                                </DataRow>

                                <DataRow
                                    label="Wandabstand"
                                >
                                    {showWallClearance(wallClearance)}
                                </DataRow>

                                <DataRow
                                    label="Breitenklasse"
                                >
                                    {showWidthClass(widthClass)}
                                </DataRow>

                                <DataRow
                                    label="Gerüstverkleidungen"
                                >
                                    {showCladdings(claddings)}
                                </DataRow>

                                <DataRow
                                    label="Besonderheiten"
                                >
                                    {showSpecialFeatures(specialFeatures)}
                                </DataRow>
                            </Section>

                            <Section
                                title="Notizen"
                                id="notes"
                            >
                                {description
                                    ?
                                    <div className="section-notes">
                                        {description}
                                    </div>
                                    : <NotAvailablePlaceholder />
                                }
                            </Section>

                            <Section
                                title="Dokumente"
                                id="documents"
                            >
                                {
                                    this.hasMedia() ?
                                        <React.Fragment>
                                            <DataRow
                                                label="Nutzungspläne"
                                                type="media"
                                            >
                                                <MediaList
                                                    media={utilizationPlans}
                                                    groupByVisibility={!section.isThirdParty}
                                                    emptyLabel="Keine Nutzungspläne hinterlegt"
                                                />
                                            </DataRow>

                                            <DataRow
                                                label="Ankerprotokoll"
                                                type="media"
                                            >
                                                <MediaList
                                                    media={anchorProtocol}
                                                    groupByVisibility={!section.isThirdParty}
                                                    emptyLabel="Kein Ankerprotokoll hinterlegt"
                                                />
                                            </DataRow>

                                            <DataRow
                                                label="Statiknachweis"
                                                type="media"
                                            >
                                                <a id="proof-of-stability"/>
                                                <MediaList
                                                    media={proofOfStability}
                                                    groupByVisibility={!section.isThirdParty}
                                                    emptyLabel="Kein Statiknachweis hinterlegt"
                                                />
                                            </DataRow>

                                            <DataRow
                                                label="Anhänge"
                                                type="media"
                                            >
                                                <MediaList
                                                    media={attachments}
                                                    groupByVisibility={!section.isThirdParty}
                                                    emptyLabel="Keine Anhänge hinterlegt"
                                                />
                                            </DataRow>
                                        </React.Fragment>
                                        :
                                        <EmptyCardHint
                                            title="Es wurden keine Dokumente für diesen Gerüstabschnitt hochgeladen."
                                        >
                                            <p>
                                                Laden Sie zum Gerüstabschnitt zugehörige Dokumente (Nutzungsplan
                                                oder sonstige Anhänge) hoch, um diese hier angezeigt zu
                                                bekommen.
                                            </p>
                                            <p>
                                                Klicken Sie dazu auf &quot;Bearbeiten&quot; und wählen Sie Ihre
                                                gewünschten Anhänge im Bereich &quot;Weitere
                                                Dokumente&quot; aus. Sie können wählen, ob die Anhänge nur für
                                                Ihr Team oder für alle Nutzer von Scaffeye® (öffentliches
                                                Dokument) sichtbar sein sollen.
                                            </p>
                                        </EmptyCardHint>

                                }
                            </Section>

                            <IfUser can={['read-inspections']}>
                                <Section
                                    title="Prüfhistorie"
                                    type="list"
                                    id="inspection-history"
                                >
                                    <Table
                                        columns={['Unternehmen', 'Typ', 'Prüfer', 'Datum', 'Ergebnis']}
                                        rows={inspections}
                                        emptyComponent={
                                            <EmptyDataTableHint
                                                numColumns={5}
                                                title="Es wurde noch keine Prüfung oder Freigabe durchgeführt"
                                            >
                                                <p>
                                                    Führen Sie eine Sicherheitsprüfung für diesen
                                                    Gerüstabschnitt
                                                    mit Hilfe der Scaffeye®-App, erhältlich für {' '}
                                                    <a
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href="https://play.google.com/store/apps/details?id=de.scaffeye"
                                                    >
                                                        Android
                                                    </a> und {' '}
                                                    <a
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        href="https://itunes.apple.com/de/app/scaffeye/id1367410149?mt=8"
                                                    >
                                                        iOS
                                                    </a>
                                                    , durch. Nach der Prüfung können Sie die Prüfhistorie dieses
                                                    Gerüstabschnittes hier einsehen und Prüfprotokolle bei
                                                    Bedarf
                                                    exportieren.
                                                </p>
                                            </EmptyDataTableHint>
                                        }
                                    >
                                        {
                                            (inspection) => (
                                                <tr key={inspection.id}>
                                                    <PrimaryColumn
                                                        link={
                                                            <a
                                                                onClick={() => this.showInspectionModal(inspection.id)}
                                                            >
                                                                {getInspectorCompany(inspection)}
                                                            </a>
                                                        }
                                                    >
                                                        {
                                                            <a
                                                                onClick={() => this.showInspectionModal(inspection.id)}
                                                            >
                                                                Details
                                                            </a>
                                                        }
                                                    </PrimaryColumn>
                                                    <td>
                                                        <InspectionType
                                                            isAcceptanceInspection={inspection.isAcceptanceInspection}
                                                        />
                                                    </td>
                                                    <td>
                                                        {getInspector(inspection)}
                                                    </td>
                                                    <td>{`${moment(inspection.completed)
                                                        .format('DD.MM.YYYY, HH:mm')} Uhr`}

                                                    </td>
                                                    <td>
                                                        <InspectionResult
                                                            errors={inspection.success ? 0 : inspection.answers.filter(item => !item.success).length}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </Table>
                                    {
                                        inspections.length > 0
                                        &&
                                        <SectionFooter>
                                            <Link to={`/sections/${section.id}/inspections`}>
                                                Gesamte Prüfhistorie anzeigen
                                            </Link>
                                        </SectionFooter>
                                    }
                                </Section>
                            </IfUser>

                            <IfUser
                                can={['read-jobs']}
                                and={!isThirdParty && managed}
                            >
                                <Section
                                    title="Anfragen"
                                    type="list"
                                    classes="related-requests"
                                    id="related-requests"
                                    subline={
                                        <Fragment>
                                            Nur aktive Anfragen <Tooltip id="related-request-tt">
                                            Unter "Aktive Anfragen" werden alle Anfragen
                                            zusammengefasst, die in Scaffeye® hinterlegt und nicht
                                            archiviert sind.</Tooltip>
                                        </Fragment>
                                    }
                                    id="related-requests"
                                >
                                    <ReferencedJobRequestsTable
                                        data={jobs && jobs.filter((job) => job.type === JobType.Request && !job.archived)}
                                        onItemChange={this.onJobChange}
                                        emptyTableHint={<EmptyDataTableHint
                                            numColumns={7}
                                            title="Es wurden noch keine Anfragen mit diesem Gerüstabschnitt verknüpft."
                                        >
                                            <p>
                                                Sie können Gerüstabschnitte und Anfragen miteinander
                                                verknüpfen. Wählen Sie dazu die
                                                gewünschte Anfrage im Sidebar-Reiter
                                                {' '}<Link to="/requests">Anfragen</Link>{' '}
                                                aus und folgen Sie den Anweisungen
                                                der Sektion &quot;Baustellendaten&quot;.
                                            </p>
                                        </EmptyDataTableHint>}
                                    />
                                </Section>

                                <Section
                                    title="Aufträge"
                                    type="list"
                                    classes="related-jobs"
                                    id="related-jobs"
                                    subline={
                                        <Fragment>
                                            Nur aktive Aufträge <Tooltip id="related-jobs-tt">Unter "Aktive
                                                                                              Aufträge" werden alle
                                                                                              Aufträge
                                                                                              zusammengefasst, die in
                                                                                              Scaffeye®
                                                                                              hinterlegt und nicht
                                                                                              archiviert
                                                                                              sind.</Tooltip>
                                        </Fragment>
                                    }
                                >
                                    <ReferencedJobsTable
                                        data={jobs && jobs.filter((job) => job.type === JobType.Job && !job.archived)}
                                        onItemChange={this.onJobChange}
                                        emptyTableHint={<EmptyDataTableHint
                                            numColumns={6}
                                            title="Es wurden noch keine Aufträge mit diesem Gerüstabschnitt verknüpft."
                                        >
                                            <p>
                                                Sie können Gerüstabschnitte und Aufträge miteinander
                                                verknüpfen. Wählen Sie dazu den
                                                gewünschten Auftrag im Sidebar-Reiter
                                                {' '}<Link to="/jobs">Aufträge</Link>{' '}
                                                aus und folgen Sie den Anweisungen
                                                der Sektion &quot;Baustellendaten&quot;.
                                            </p>
                                        </EmptyDataTableHint>}
                                    />
                                </Section>
                            </IfUser>

                            <IfUser
                                can={['delete-managed-sections']}
                                and={!isFetching && !isThirdParty && managed && viewMode !== ResourceViewMode.Archived}
                            >
                                <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                            Gerüstabschnitt löschen
                        </span>
                                </div>
                            </IfUser>

                            <IfUser
                                can={['delete-sections']}
                                and={!isFetching && !isThirdParty && !managed && viewMode !== ResourceViewMode.Archived}
                            >
                                <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                            Gerüstabschnitt löschen
                        </span>
                                </div>
                            </IfUser>

                            {showEditModal &&
                            <SectionFormModal
                                mode="edit"
                                id={section.id}
                                onClose={this.toggleEditModal}
                                onUpdated={this.onUpdated}
                            />
                            }

                            {showSendCloseRequestModal &&
                            <SendCloseRequestModal
                                modalTitle="Sperranfrage für Gerüstabschnitt "
                                onSendCloseRequest={this.onSendCloseRequest}
                                closeModal={this.toggleSendCloseRequestModal}
                                isSendingCloseRequest={isSendingCloseRequest}
                            >
                                <p>
                                    Sie sind im Begriff, eine Sperranfrage für den aktiven Gerüstabschnitt
                                    <span
                                        onClick={this.toggleSendCloseRequestModal}
                                        className="btn--link-neutral"
                                    >
                            {' '}{section.name}
                        </span> zu versenden.
                                </p>
                                <p>Möchten Sie fortfahren?</p>
                            </SendCloseRequestModal>
                            }

                            {showDeleteModal &&
                            <DeleteModal
                                modalTitle="Gerüstabschnitt löschen"
                                deleteEntity={this.deleteSection}
                                closeModal={this.toggleDeleteModal}
                                isDeletingEntity={isDeletingSection}
                            >
                                <p>
                                    Sie sind im Begriff, den aktiven Gerüstabschnitt
                                    <span
                                        onClick={this.toggleDeleteModal}
                                        className="btn--link-neutral"
                                    >
                            {' '}{section.name}
                        </span> zu löschen.

                                    zu löschen.
                                </p>
                                <p>Möchten Sie fortfahren?</p>
                            </DeleteModal>
                            }

                            {showPostingsModal &&
                            <PostingsModal
                                closeModal={this.togglePostingsModal}
                                postings={postings}
                            />
                            }

                            {showInspectionModal &&
                            <InspectionModal
                                closeModal={this.toggleInspectionModal}
                                inspectionId={currentInspectionId}
                            />
                            }

                            {showChangeLogModal &&
                            <SectionChangeLogModal
                                closeModal={this.toggleChangeLogModal}
                                sectionId={section.id}
                                scaffoldingName={scaffolding.name}
                                siteName={scaffolding.site.name}
                                sectionName={section.name}
                            />
                            }
                        </Fragment>
                    )
                }

            </Fragment>
        );
    }

}

const mapStateToProps = ({currentUser}) => {
    const userAccountType = currentUser.usermeta.account.type || null;
    return {
        userAccountType,
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSectionDetailData,
        updateSection,
        deleteSingleSection,
        sendCloseRequest,
        updateSectionStatus,
    }, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionDetail));

SectionDetail.propTypes = {
    sectionId: PropTypes.string,
};

SectionDetail.defaultProps = {
    sectionId: '',
};

