import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { userCan } from '../../../Services/UserService';
import Icon from '../../Common/Icon';
import { getManagedExplanationLabel, getManagedLabel } from '../labels';

const ddRoot = document.getElementById('dropdown-root');

const ScaffoldingManagedChoiceDropdown = ({ onSelect, anchor, canSelectManaged }) => {

    const elMemo = useMemo(() => document.createElement('div'), []);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [scrollY, setScrollY] = React.useState(window.scrollY);

    useEffect(() => {
        ddRoot.appendChild(elMemo);
        return () => {
            ddRoot.removeChild(elMemo);
        }
    }, [elMemo])

    useEffect(() => {
        const debouncedHandleResize = _.debounce(() => {
            setWindowWidth(window.innerWidth)
        }, 400)

        const debouncedHandleScroll = _.debounce(() => {
            setScrollY(window.scrollY)
        }, 400)

        window.addEventListener('resize', debouncedHandleResize);
        window.addEventListener('scroll', debouncedHandleScroll);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            window.removeEventListener('scroll', debouncedHandleScroll);
        }
    }, []);

    if (!anchor) {
        return null;
    }

    const dd = (
        <div className="scaff-managed-choice__dd" style={{
            top: `${anchor.bottom + scrollY || 0}px`,
            right: `${windowWidth - anchor.right || 0}px`
        }}>
            <button className="scaff-managed-choice__dd-btn" onClick={() => onSelect(true)} disabled={!canSelectManaged}>
                <span className="scaff-managed-choice__dd-lbl">
                    {getManagedLabel(true)}
                </span>
                <span className="scaff-managed-choice__dd-hint">
                    {getManagedExplanationLabel(true)}
                </span>
            </button>
            <button className="scaff-managed-choice__dd-btn" onClick={() => onSelect(false)}>
                <span className="scaff-managed-choice__dd-lbl">
                    {getManagedLabel(false)}
                </span>
                <span className="scaff-managed-choice__dd-hint">
                    {getManagedExplanationLabel(false)}
                </span>
            </button>
        </div>
    )

    return ReactDOM.createPortal(
        dd,
        elMemo,
    );
}

/**
 * Controls the managed flag of a scaffolding
 *
 * @param managed
 * @param canCreateManagedScaffolding boolean
 * @param readonly boolean
 * @param onChange function
 * @return {JSX.Element}
 * @constructor
 */
const ScaffoldingManagedChoice = ({ managed, onChange, readonly, canCreateManagedScaffolding }) => {

    const [showDropdown, setShowDropdown] = useState(false);
    const ctRef = useRef();
    const [ddAnchor, setDdAnchor] = useState();

    const setManaged = (managed) => {
        typeof onChange === 'function' && onChange(managed);
        setShowDropdown(false);
    }

    // Fun stuff to show the drop down at the right position
    useEffect(() => {
        if(readonly) {
            return;
        }

        const update = () => {
            if (!ctRef || !ctRef.current) {
                return;
            }
            const rect = ctRef.current.getBoundingClientRect()
            setDdAnchor(rect);
        }

        // Subscribe to window resize events
        const debouncedUpdate = _.debounce(update, 200);
        window.addEventListener('resize', debouncedUpdate);

        // Fire initially
        update();

        // Remove event subscription on cleanup
        return () => {
            window.removeEventListener('resize', debouncedUpdate)
        }
    }, [ctRef.current, readonly]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    return (
        <div className="scaff-managed-choice" ref={ctRef}>
            <button
                onClick={toggleDropdown}
                disabled={readonly}
                className={`scaff-managed-choice__btn ${managed && 'is-managed'}`}
            >
                <span className="scaff-managed-choice__btn-indicator" />
                <span className="scaff-managed-choice__btn-lbl">{getManagedLabel(managed)}</span>
                {!readonly &&
                <span className="scaff-managed-choice__btn-chevron">
                    <Icon name={showDropdown ? 'chevronUp' : 'chevronDown'} />
                </span>
                }
            </button>

            {(!readonly && showDropdown) &&
            <ScaffoldingManagedChoiceDropdown onSelect={setManaged} anchor={ddAnchor} canSelectManaged={canCreateManagedScaffolding} />}
        </div>
    )
}

const mapStateToProps = state => ({
    canCreateManagedScaffolding: userCan(['create-managed-scaffoldings'], state?.currentUser?.usermeta?.permissions)
});

export default connect(mapStateToProps, null)(ScaffoldingManagedChoice);
