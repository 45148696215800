import PropTypes from 'prop-types';
import React from 'react';
import BoolSyncChoice from '../../Base/Input/Bool/BoolSyncChoice';
import SyncChoiceOptionHint from '../../Base/Input/SyncChoiceOptionHint';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';

function getValues(name, getPlanValue, getLiveValue) {
    const planValue = !!getPlanValue(name);
    const liveValue = !!getLiveValue(name);
    const identical = planValue === liveValue;

    return {
        planValue,
        liveValue,
        identical
    }
}

function showDocumentHint(optionValue) {
    if (optionValue !== true) {
        return null;
    }

    return (
        <SyncChoiceOptionHint
            content={
                <span>
                        Wählen Sie einen Statiknachweis im Dokumentebereich aus, um die Planung anzuwenden.<br /><br />
                        Sollten Sie bisher keinen Statiknachweis hochgeladen haben, passen Sie bitte zunächst Ihre Planung an oder versetzen Sie den Gerüstabschnitt in den Status “In Planung”
                    </span>
            }
            label="Rechtliche Hinweise"
            tooltipId="sync-special-construction-proof-of-stability-hint"
        />
    );
}

const StabilityAndLoadSafetySyncChoice = (props) => {

    const {
        getPlanValue,
        getLiveValue,
        getFormNameForProperty,
        showIdentical,
        setFieldTouched,
        mode
    } = props;

    const specialConstruction = getValues('specialConstruction', getPlanValue, getLiveValue);
    const staticsCertificateNecessary = getValues('staticsCertificateNecessary', getPlanValue, getLiveValue);

    if (specialConstruction.identical && staticsCertificateNecessary.identical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Stand- und Tragsicherheit"
        >
            {(!specialConstruction.identical || showIdentical) &&
            <SyncInputGroup
                label="Sonderkonstruktion"
                subLevel
            >
                <BoolSyncChoice
                    name={getFormNameForProperty('specialConstruction')}
                    planValue={specialConstruction.planValue}
                    liveValue={specialConstruction.liveValue}
                    showIdentical={showIdentical}
                    setFieldTouched={setFieldTouched}
                    mode={mode}
                />
            </SyncInputGroup>
            }

            {(!staticsCertificateNecessary.identical || showIdentical) &&
            <SyncInputGroup
                label="Statiknachweis erforderlich"
                subLevel
            >
                <BoolSyncChoice
                    name={getFormNameForProperty('staticsCertificateNecessary')}
                    planValue={staticsCertificateNecessary.planValue}
                    liveValue={staticsCertificateNecessary.liveValue}
                    showHint={showDocumentHint}
                    showIdentical={showIdentical}
                    setFieldTouched={setFieldTouched}
                    mode={mode}
                />
            </SyncInputGroup>
            }
        </SyncInputGroup>
    );
};

StabilityAndLoadSafetySyncChoice.propTypes = {
    getPlanValue: PropTypes.func.isRequired,
    getLiveValue: PropTypes.func.isRequired,
    getFormNameForProperty: PropTypes.func.isRequired,
    showIdentical: PropTypes.bool,
    setFieldTouched: PropTypes.func
};

export default StabilityAndLoadSafetySyncChoice;
