import React from 'react';
import { components } from 'react-select';
import SelectField from '../../Select/SelectField';
import { getModelTypeLabel } from '../utils';

const { SingleValue, Option, Group, GroupHeading } = components;

const ReferenceOption = props => (
    <Option {...props}>
        <div className={`media-reference media-reference--${props.data.type}`}>
            <span className="media-reference__lbl">
                {props.data.label}
            </span>
        </div>
    </Option>
);

const ReferenceValue = ({ children, ...props }) => (
    <SingleValue {...props}>
        <div className={`media-reference media-reference--${props.data.type}`}>
            <span className="media-reference__type">
                {getModelTypeLabel(props.data.type)}
            </span>
            <span className="media-reference__lbl">
                {props.data.label}
            </span>
        </div>
    </SingleValue>
);

const ReferenceGroup = ({ children, ...props }) => {
    const type = props.data.type || false;
    return (
        <Group
            {...props}
            className={`media-reference__group ${type ? 'media-reference__group--' + type : ''}`}
        >
            {children}
        </Group>
    )
};

const ReferenceGroupHeading = ({ children, ...props }) => (
    <GroupHeading
        {...props}
        className={`media-reference__group-hd`}
    >
        {children}
    </GroupHeading>
);

const MediaReferenceSelectField = ({ ...fieldProps }) => (
    <SelectField
        {...fieldProps}
        placeholder="Dokumente zuweisen"
        label="Zuweisung"
        isSearchable
        components={{
            Option: ReferenceOption,
            SingleValue: ReferenceValue,
            Group: ReferenceGroup,
            GroupHeading: ReferenceGroupHeading
        }}
    />
);

export default MediaReferenceSelectField;
