import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import ArchivedBadge from '../Common/Badge/ArchivedBadge';
import Icon from '../Common/Icon';
import IfUser from '../Common/IfUser';
import DetailPageHeader from '../DetailPageNavigation/DetailPageHeader';
import DeleteJobLink from '../Job/DeleteJobLink';
import JobStatusQuickEdit from '../Job/Form/JobStatusQuickEdit';

export const JobPageHeader = ({ job, disabled, isJobRequest, navLinks, isArchived, onCollapse, onChange, onClickShowChangelog, onClickArchive,  onClickEdit, isLoading }) => {

    const jobTypeLabel = isJobRequest ? 'Anfrage' : 'Auftrag';
    const pageTitle = isLoading ? `Lade ${jobTypeLabel}` : `${jobTypeLabel} : ${job ? job.name : 'n.a.'}`;

    const history = useHistory();

    const onDeleted = () => {
        const redirect = isJobRequest ? '/requests' : '/jobs';
        history.push(redirect);
    }

    return (
        <DetailPageHeader
            title={pageTitle}
            titleAddOns={
                <Fragment>
                    {isArchived
                        ? <ArchivedBadge />
                        : !isLoading && <JobStatusQuickEdit job={job} onUpdate={onChange} horizontal badge />
                    }
                </Fragment>
            }
            navigationalLinks={navLinks}
            onCollapse={onCollapse}
            isLoading={isLoading}
            actions={
                <div className="jobPageHeader__actions">

                    <div className="jobPageHeader__action-group">
                        <button
                            className='btn btn--icon btn--outline btn-history'
                            disabled={disabled}
                            onClick={onClickShowChangelog}
                            title={isJobRequest ? 'Anfragehistorie ansehen' : 'Auftragshistorie ansehen'}
                        >
                                    <span
                                        className={['btn-icon', isJobRequest ? '' : 'btn-icon--flip'].join(' ')}
                                    >
                                        <Icon name="history" />
                                    </span>
                            Historie
                        </button>
                    </div>

                    <div className="jobPageHeader__action-group">

                        <IfUser
                            can={['delete-jobs']}
                        >
                            <DeleteJobLink
                                id={job.id}
                                name={job.name}
                                type={job.type}
                                onDeleted={onDeleted}
                                link={(disabled, onClick) => (
                                    <button
                                        onClick={onClick}
                                        className="btn btn--delete-action btn--icon"
                                        title={`${jobTypeLabel} löschen`}
                                        disabled={disabled}
                                    >
                                        <span className="btn__icon">
                                            <Icon name="delete" />
                                        </span>
                                    </button>
                                )}
                            />
                        </IfUser>

                        <IfUser
                            can={['update-jobs']}
                        >
                            <button
                                onClick={onClickArchive}
                                className="btn btn--secondary-action btn--icon"
                                title={`${jobTypeLabel} ${isArchived ? 'reaktivieren' : 'archivieren'}`}
                            >
                                <span className="btn__icon">
                                    <Icon name={isArchived ? 'restore' : 'archiveBox'} />
                                </span>
                            </button>
                        </IfUser>

                        <IfUser
                            can={['update-jobs']}
                            and={!isArchived}
                        >
                            <button
                                className="btn btn--neutral-action btn--icon"
                                title={`${jobTypeLabel} bearbeiten`}
                                disabled={disabled}
                                onClick={onClickEdit}
                            >
                                    <span className="btn__icon">
                                        <Icon name="edit" />
                                    </span>
                            </button>

                        </IfUser>
                    </div>
                </div>
            }
        />
    );
};
