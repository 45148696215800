import moment from 'moment/moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSubscriptionInformation } from '../../../redux/modules/account/action';
import { showApiError } from '../../../redux/modules/error/action';
import { formatCurrency, mapZeroBasedMonthNumberToMonthName } from '../../../utils';
import DataRow from '../../Common/DataRow';
import DeleteViaEmailModal from '../DeleteViaEmailModal';
import IfUser from '../../Common/IfUser';
import Section from '../../Layout/Section';
import LoadingSpinner, {PageLoadingSpinner} from '../../Page/LoadingSpinner';
import SubscriptionTypes, { AccountTypes } from '../subscriptionTypes';
import ResubscribeLink from './ResubscripeLink';
import InfoModal from '../../Common/Modal/InfoModal';
import ExternalLink from '../../Common/ExternalLink';
import MailToLink from '../../Common/MailToLink';
import PhoneToLink from '../../Common/PhoneToLink';

class SubscriptionDetails extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            subscriptionDeleteModalIsOpen: false,
            showUpgradeModal: false
        };
    }

    static showSubscriptionLabel(type) {
        switch (type) {
            case 'pro':
                return 'Scaffeye\t\xAE Pro';

            case 'basic':
                return 'Scaffeye\t\xAE Basic';

            case 'light':
                return 'Scaffeye\t\xAE Light';
            default:
                return 'Scaffeye\t\xAE Light';
        }
    }

    componentWillMount() {
        this.setState({
            isFetching: true
        });

        this.toggleSubscriptionDeleteModal = this.toggleSubscriptionDeleteModal.bind(this);

        const { actions: { fetchSubscriptionInformation } } = this.props;

        fetchSubscriptionInformation()
            .then((subscriptionDetails) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    subscriptionDetails: { ...subscriptionDetails },
                    isFetching: false
                });
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    showAccountUserSummary() {
        const { subscriptionDetails: { account: { users } } } = this.state;

        if (!users) {
            return null;
        }

        const accountUsers = users || [];

        const userTotal = accountUsers.length;

        const userTypes = {};

        accountUsers.forEach((user) => {
            if (userTypes[user.type] !== undefined) {
                userTypes[user.type] += 1;
            } else {
                userTypes[user.type] = 1;
            }
        });

        const labels = Object.keys(userTypes)
            .map((type, index) => (
                <div key={index}>{userTypes[type]} x {SubscriptionTypes[type]}</div>));

        return (
            <React.Fragment>
                <div>{userTotal} Nutzer total</div>
                <div> -</div>
                {labels}
            </React.Fragment>
        );
    }

    showInvoiceAmountForecast() {
        const { subscriptionDetails: { account: { invoiceAmountForecast } } } = this.state;

        if(invoiceAmountForecast < 1 ) {
            return null;
        }

        const invoiceMonth = moment().format('MMMM');

        return (
            <div className="invoice-forecast">
                <strong>{`Voraussichtlicher Rechnungsbetrag für ${invoiceMonth}: ${formatCurrency(invoiceAmountForecast, 2, ',', '.')}`} &euro;</strong>
            </div>
        )
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    toggleSubscriptionDeleteModal() {
        this.setState(prevState => ({
            subscriptionDeleteModalIsOpen: !prevState.subscriptionDeleteModalIsOpen
        }));
    }

    toggleUpgradeModal() {
        this.setState(prevState => ({
            showUpgradeModal: !prevState.showUpgradeModal
        }));
    }

    render() {
        const {
            subscriptionDetails,
            isFetching,
            showUpgradeModal,
            subscriptionDeleteModalIsOpen
        } = this.state;

        let accountType;

        if (subscriptionDetails) {
            accountType = subscriptionDetails.account ? subscriptionDetails.account.type : null;
        }

        const { companyId } = this.props;

        if (isFetching) {
            return <PageLoadingSpinner />;
        }

        const { account } = subscriptionDetails;
        const { mainSubscription: subscription, isOnTrial } = account;
        const { ends, nextRenewal } = subscription;
        const endsLabel = ends ? moment(ends)
            .format('DD.MM.YYYY') : '';
        const renewsOn = !ends ? moment(nextRenewal)
            .format('DD.MM.YYYY') : '';
        const expired = ends && moment(ends)
            .isBefore(moment());
        const subscriptionIsExpiring = !expired
            && moment(ends)
                .isAfter(moment())
            && moment(ends)
                .subtract(7, 'days')
                .isBefore(moment());

        return (
            <React.Fragment>
                <Section
                    title="Aktueller Tarif"
                >
                    <DataRow
                        label="Abonnement"
                    >
                        <div>
                            {SubscriptionDetails.showSubscriptionLabel(account.type)}
                            {isOnTrial && ' (Probeabonnement)'}
                        </div>
                        {(accountType === AccountTypes.basic || accountType === AccountTypes.light)  &&
                            <div
                                onClick={() => this.toggleUpgradeModal()}
                                className="btn btn--primary-green"
                                style={{ marginTop: '10px' }}
                            >Upgrade durchführen
                            </div>
                        }
                    </DataRow>
                    {accountType !== AccountTypes.light &&
                        <DataRow
                            label="Laufzeit"
                        >
                        1 Monat <br />
                            {ends ?
                                <React.Fragment>
                                    <div>
                                        {`Gekündigt zum ${endsLabel}.`}
                                        {
                                            subscriptionIsExpiring &&
                                            <div>
                                                <small>
                                                    <strong>Ihr Abonnement endet innerhalb der
                                                    nächsten 7 Tage.
                                                    </strong>
                                                </small>
                                            </div>
                                        }
                                        <div>
                                            <ResubscribeLink
                                                label="Jetzt verlängern"
                                                user={subscriptionDetails}
                                                account={account}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                <div>
                                    {`Ihr Abonnement wird am ${renewsOn} automatisch um einen Monat verlängert `} <br />  <br />

                                </div>
                            }
                            {this.showInvoiceAmountForecast()}
                        </DataRow>
                    }

                    <DataRow
                        label="Aktive Nutzer"
                    >
                        {this.showAccountUserSummary()}
                    </DataRow>
                </Section>

                <Section
                    title="Abonnement anpassen"
                >
                    <p style={{ marginBottom: 15 }}>
                        <strong>Bitte kontaktieren Sie den Support, um
                            eine Anpassung oder einen Wechsel Ihres Abonnements durchzuführen.
                        </strong>
                    </p>
                    <DataRow
                        label="Telefon"
                    >
                        <PhoneToLink number="491602535711">+49 160 2535711</PhoneToLink>
                    </DataRow>
                    <DataRow
                        label="E-Mail-Adresse"
                    >
                        <MailToLink email="info@scaffeye.de" >info@scaffeye.de </MailToLink>
                    </DataRow>
                    <p style={{ marginTop: 20 }}>Bei Fragen rund um das Leistungsspektrum besuchen
                        Sie unsere Seite <ExternalLink target="https://www.scaffeye.de/" label="Pakete & Preise" />
                    </p>

                    <IfUser can={['update-subscription']}>
                        <p style={{ marginTop: 20 }}>
                            <span
                                className="btn--link-neutral"
                                onClick={this.toggleSubscriptionDeleteModal}
                            >
                            Abonnement kündigen
                            </span>
                        </p>
                    </IfUser>

                    {subscriptionDeleteModalIsOpen &&
                    <DeleteViaEmailModal
                        modalTitle="Abonnement kündigen"
                        closeModal={this.toggleSubscriptionDeleteModal}
                        subject={`Kündigung meines Scaffeye®-Abonnements [${companyId}]`}
                        body={`Sehr geehrte Damen und Herren, %0D%0A %0D%0A hiermit kündige ich mein Abonnement bei Scaffeye® zum nächstmöglichen Zeitpunkt.%0D%0A %0D%0A Ich bin mir darüber bewusst, dass ich und meine Nutzer dadurch nach Ablauf der aktuellen Laufzeit die Möglichkeit verlieren, Scaffeye® zu nutzen. %0D%0A Der Zugriff auf Protokolle bereits durchgeführter Prüfungen bleibt mir dadurch weiterhin erhalten. %0D%0A %0D%0A Mit freundlichen Grüßen, %0D%0A %0D%0A ${subscriptionDetails.firstName} ${subscriptionDetails.lastName}`}
                    >
                        <p>Sie sind im Begriff, Ihr aktives Abonnement zu kündigen. <br />
                            <strong>Damit verlieren Sie und alle weiteren Nutzer Ihres Kontos nach
                                Ende
                                der aktuellen Laufzeit die Möglichkeit, Scaffeye&reg; zu nutzen.
                            </strong>
                        </p>
                        <p>Der Zugriff auf Protokolle bereits durchgeführter Prüfungen bleibt
                            unberührt.
                        </p>
                        <p>Um diesen Prozess einzuleiten, kontaktieren Sie bitte den
                            Scaffeye&reg;-Support. Hierzu werden Sie im nächsten Schritt auf ihr
                            E-Mail
                            Programm weitergeleitet.
                        </p>
                        <p>Möchten Sie fortfahren?</p>
                    </DeleteViaEmailModal>
                    }

                    {
                        showUpgradeModal &&
                        <InfoModal
                            title="Upgrade durchführen"
                            onClose={() => this.toggleUpgradeModal()}
                        >
                            <p>
                                Vielen Dank für Ihr Vertrauen in Scaffeye®! <br />
                                Um ein Konto-Upgrade auf Scaffeye®-Basic oder -Pro durchzuführen,
                                kontaktieren Sie bitte unseren Support:
                            </p>
                            <p>
                                <strong>Telefon:</strong> <br />
                                <PhoneToLink number="491602535711">+49 160 2535711</PhoneToLink>
                            </p>
                            <p>
                                <strong>E-Mail:</strong> <br />
                                <MailToLink email="info@scaffeye.de">
                                    info@scaffeye.de
                                </MailToLink>
                            </p>

                            <p>Bei Fragen rund um das Leistungsspektrum, besuchen Sie bitte unsere
                                Seite <ExternalLink
                                target="https://www.scaffeye.de/preise/"
                                label="Pakete & Preise"
                                />
                            </p>
                        </InfoModal>
                    }

                </Section>

                <div className="btn-group">
                    <a
                        onClick={() => this.props.history.push('/account/subscription')}
                        className="btn btn--backward-action"
                    >
                        Zurück
                    </a>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = ({ currentUser }) => {
    const companyId = currentUser.usermeta.account.company.id || null;
    return {
        companyId
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSubscriptionInformation
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails);
