import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import { showRequestError } from '../../../../redux/modules/error/action';
import { fetchScaffoldingsSelectOptions } from '../../../../redux/modules/scaffoldings/action';
import LinkButton from '../../../Common/LinkButton';
import { ConstructionPlanModelMode } from '../../properties';
import ConstructionPlanFormItemList from '../Common/ConstructionPlanFormItemList';
import {
    ConstructionPlanFormScaffoldingPropType,
    ConstructionPlanModelModePropType
} from '../propTypes';
import ConstructionPlanFormScaffolding from './ConstructionPlanFormScaffolding';
import ConstructionPlanFormScaffoldingPlaceholder
    from './ConstructionPlanFormScaffoldingPlaceholder';
import ConstructionPlanScaffoldingFormModal from './Modal/ConstructionPlanScaffoldingFormModal';

class ConstructionPlanFormScaffoldingList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            liveScaffoldingIds: [],
            showFormModal: false,
            showPlaceholder: false,
            scaffoldingFormModalMode: undefined,
            currentScaffolding: null
        };

        this.onScaffoldingChanged = this.onScaffoldingChanged.bind(this);
        this.onReferenceAll = this.onReferenceAll.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.hasScaffoldingsLeftToSelect = this.hasScaffoldingsLeftToSelect.bind(this);
        this.onCloseFormModal = this.onCloseFormModal.bind(this);
        this.onAddExisting = this.onAddExisting.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillMount() {
        const { siteId } = this.props;
        if (siteId) {
            this.fetchSiteScaffoldingIds(siteId);
        }
    }

    componentDidUpdate(prevProps) {

        const { siteId } = this.props;

        if (typeof siteId === 'undefined') {
            return;
        }

        // Update available scaffolding options if site changes
        if (siteId !== prevProps.siteId) {
            this.fetchSiteScaffoldingIds(siteId);
        }
    }

    /**
     * Returns true if there are scaffoldings left to reference
     *
     * @returns {boolean}
     */
    hasScaffoldingsLeftToSelect() {

        // if mode of parent is new
        const { siteMode } = this.props;

        if (siteMode === ConstructionPlanModelMode.New) {
            return false;
        }

        // existing scaffoldings of a site
        const { liveScaffoldingIds } = this.state;
        if (!liveScaffoldingIds || !liveScaffoldingIds.length) {
            return false;
        }

        const { scaffoldings } = this.props;

        const planScaffoldingIds = scaffoldings
            .filter(s => s.mode !== ConstructionPlanModelMode.New)
            .map(s => s.id);

        return _.difference(liveScaffoldingIds, planScaffoldingIds).length > 0;
    }

    // Remove scaffolding from list
    onDelete(scaffoldingToDelete) {

        const { onChange, scaffoldings } = this.props;

        const updatedScaffoldings = scaffoldings.filter(scaffolding => scaffolding.id !== scaffoldingToDelete.id);

        onChange(updatedScaffoldings);
    }

    onAddNew() {
        this.setState({
            showFormModal: true,
            scaffoldingFormModalMode: ConstructionPlanModelMode.New
        });
    }

    onAddExisting() {
        this.setState({
            showFormModal: true,
            scaffoldingFormModalMode: ConstructionPlanModelMode.Reference
        });
    }

    fetchSiteScaffoldingIds(siteId) {

        const { fetchScaffoldingsSelectOptions } = this.props.actions;

        this.setState({
            isFetching: true
        });

        fetchScaffoldingsSelectOptions(siteId, false, true)
            .then((scaffoldings) => {
                this.setState({
                    liveScaffoldingIds: scaffoldings ? scaffoldings.map(s => s.id) : [],
                    isFetching: false
                });
            })
            .catch(error => {
                showRequestError('Gerüste konnte nicht geladen werden', error);
                this.setState({
                    isFetching: false
                });
            });
    }

    onScaffoldingChanged(updatedScaffolding, oldScaffolding) {
        const { onChange, scaffoldings } = this.props;

        let updatedScaffoldings = [
            ...scaffoldings
        ];

        // Replace the scaffolding in the list of scaffoldings
        let updated = false;
        updatedScaffoldings = updatedScaffoldings
            .map(scaffolding => {
                if (
                    scaffolding.id === updatedScaffolding.id
                    || oldScaffolding && scaffolding.id === oldScaffolding.id
                ) {
                    updated = true;

                    const _updatedScaffolding = {
                        ...updatedScaffolding
                    };

                    if (scaffolding.originalMode) {
                        _updatedScaffolding.originalMode = scaffolding.originalMode;
                    }

                    if (scaffolding.lastApplied) {
                        _updatedScaffolding.lastApplied = scaffolding.lastApplied;
                    }

                    return _updatedScaffolding;
                }

                return scaffolding;
            });

        // No existing scaffolding was found having the same id: must be new
        if (!updated) {
            updatedScaffoldings.push({ ...updatedScaffolding });
        }

        onChange(updatedScaffoldings);
        this.setState({
            showPlaceholder: false
        });
        this.onCloseFormModal();
    }

    onReferenceAll(scaffoldingsToReference, editedScaffolding = undefined) {

        const { onChange, scaffoldings } = this.props;
        const scaffoldingsWithModeNew = scaffoldings.filter(scaffolding => scaffolding.mode === ConstructionPlanModelMode.New);
        let referencedScaffoldings = [
            ...scaffoldingsToReference,
            ...scaffoldingsWithModeNew
        ];

        /*
         * If there was a referenced/edited scaffolding in the first place and the user wants to
         * reference all instead we move the sections reference/added/edited in the original
         * to the new referenced scaffolding, just in case.
         */
        if (editedScaffolding && editedScaffolding.id) {
            const { mode, sections } = editedScaffolding;
            if ((mode === ConstructionPlanModelMode.Reference
                || mode === ConstructionPlanModelMode.Edit
            ) && sections && sections.length > 0) {
                referencedScaffoldings = referencedScaffoldings
                    .map(referencedScaffolding => {
                        if (referencedScaffolding.id === editedScaffolding.id) {
                            return {
                                ...referencedScaffolding,
                                sections
                            };
                        }

                        return referencedScaffolding;
                    });
            }
        }

        onChange(referencedScaffoldings);
        this.setState({
            showPlaceholder: false
        });
        this.onCloseFormModal();
    }

    onCloseFormModal() {
        this.setState({
            showFormModal: false,
            scaffoldingFormModalMode: undefined
        });
    }

    getInitialFormModalData() {
        const { scaffoldingFormModalMode } = this.state;

        return {
            id: scaffoldingFormModalMode === ConstructionPlanModelMode.New ? uuidv4() : undefined,
            mode: scaffoldingFormModalMode,
            data: {},
            sections: []
        };
    }

    getReferencedScaffoldingIds() {
        return this.props.scaffoldings
            .filter(scaffolding => this.state.liveScaffoldingIds.indexOf(scaffolding.id) !== -1)
            .map(scaffolding => scaffolding.id);
    }

    getReferenceData(id) {
        const { referenceData } = this.props;
        if (!referenceData || referenceData.length === 0) {
            return null;
        }

        return _.find(referenceData, entry => entry.id === id);
    }

    render() {
        const { scaffoldings, disabled, siteId, isArchived } = this.props;

        const { showFormModal, showPlaceholder } = this.state;

        const showAddMoreButton = scaffoldings && scaffoldings.length > 0;

        const wrapperClasses = ['constructionPlannerScaffoldingList'];
        if (disabled) {
            wrapperClasses.push('constructionPlannerScaffoldingList--disabled');
        }

        const referencedScaffoldingIds = this.getReferencedScaffoldingIds();

        if (showFormModal) {
            return (
                <ConstructionPlanScaffoldingFormModal
                    siteId={siteId}
                    onChange={this.onScaffoldingChanged}
                    onReferenceAllScaffoldings={this.onReferenceAll}
                    onClose={this.onCloseFormModal}
                    scaffolding={this.getInitialFormModalData()}
                    referencedScaffoldingIds={referencedScaffoldingIds}
                />
            );
        }

        const hasScaffoldingsLeftToSelect = this.hasScaffoldingsLeftToSelect();

        return (
            <div className={wrapperClasses.join(' ')}>

                <ConstructionPlanFormItemList
                    listItems={scaffoldings || []}
                    emptyComponent={
                        !isArchived ? <ConstructionPlanFormScaffoldingPlaceholder
                                onAddExisting={this.onAddExisting}
                                onAddNew={this.onAddNew}
                                scaffoldingsLeftToReference={!disabled && hasScaffoldingsLeftToSelect}
                            />
                            :
                            <div></div>
                    }
                    showEmptyComponentEvenForList={showPlaceholder}
                >
                    {
                        scaffolding => (
                            <ConstructionPlanFormScaffolding
                                siteId={siteId}
                                key={scaffolding.id}
                                scaffolding={scaffolding}
                                onDelete={this.onDelete}
                                onChange={this.onScaffoldingChanged}
                                onReferenceAllScaffoldings={this.onReferenceAll}
                                referencedScaffoldingIds={referencedScaffoldingIds}
                                hasScaffoldingsLeftToSelect={hasScaffoldingsLeftToSelect}
                                referenceData={this.getReferenceData(scaffolding.id)}
                                showFormModal={showFormModal}
                                parentIsArchived={isArchived}
                            />
                        )
                    }
                </ConstructionPlanFormItemList>

                {(showAddMoreButton && !isArchived) && <LinkButton
                    onClick={() => this.setState({ showPlaceholder: true })}
                    label="Weiteres Gerüst hinzufügen"

                />}

            </div>
        );
    }
}

ConstructionPlanFormScaffoldingList.propTypes = {
    siteId: PropTypes.string,
    siteMode: ConstructionPlanModelModePropType,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    isArchived: PropTypes.bool,
    scaffoldings: PropTypes.arrayOf(ConstructionPlanFormScaffoldingPropType),
    referenceData: PropTypes.arrayOf(PropTypes.object)
};

ConstructionPlanFormScaffoldingList.defaultProps = {
    scaffoldings: [],
    referenceData: [],
    onChange: () => false,
    disabled: false,
    isArchived: false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchScaffoldingsSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanFormScaffoldingList);
