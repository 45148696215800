import PropTypes from 'prop-types';
import React from 'react';
import { autoFormatFileSize } from '../../../../../utils';
import Icon from '../../../../Common/Icon';
import { MediaFormDataPropType } from '../../../../Media/properties';
import { ConstructionPlanModelMode } from '../../../properties';
import { ConstructionPlanModelModePropType } from '../../propTypes';

const ConstructionPlanFormMediaListItem = ({ media, modelMode, onPreview, onEdit, onDelete }) => {
    const isNamed = media.fileName !== media.name;
    const canBeEdited = modelMode !== ConstructionPlanModelMode.Reference;
    return (
        <div className="media-field__itm">

            <div className="media-field__itm-main">
                <div
                    className="media-field__itm-name"
                    role="button"
                    onClick={() => onPreview(media)}
                    title={media.name}
                >
                    {media.name}
                </div>
                <div className="media-field__itm-actions">
                    {onPreview &&
                    <a
                        className="media-field__itm-action"
                        role="button"
                        onClick={() => onPreview(media)}
                    >
                        Vorschau
                    </a>
                    }
                    {canBeEdited && onEdit &&
                    <a
                        className="media-field__itm-action"
                        role="button"
                        onClick={() => onEdit(media)}
                    >
                        Bearbeiten
                    </a>
                    }
                </div>
            </div>

            <div className="media-field__itm-meta">
                {isNamed &&
                <div className="media-field__itm-file-name" title={media.fileName}>
                    {media.fileName}
                </div>
                }
                <div className="media-field__itm-size">
                    {autoFormatFileSize(media.size)}
                </div>
            </div>

            {canBeEdited && onDelete &&
            <a
                className="media-field__itm-delete"
                role="button"
                onClick={() => onDelete(media)}
                title="Entfernen"
            >
                <Icon name="removefile" />
            </a>
            }
        </div>
    )
};

ConstructionPlanFormMediaListItem.propTypes = {
    media: MediaFormDataPropType.isRequired,
    modelMode: ConstructionPlanModelModePropType,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onPreview: PropTypes.func
};

ConstructionPlanFormMediaListItem.defaultProps = {
    onEdit: undefined,
    onDelete: undefined,
    onPreview: undefined
};

export default ConstructionPlanFormMediaListItem;
