import React from 'react';
import { Link } from 'react-router-dom';
import AddResourceDropDown from '../Common/AddResourceDropDown';
import Icon from '../Common/Icon';
import SettingsDropdown from '../Common/SettingsDropdown/SettingsDropdown';

const SecondaryNavigation = () => (
    <div className="secondary-navigation">
        <AddResourceDropDown />
        <div className="secondary-navigation__meta">
            <div className="secondary-navigation__help-btn">
                <Link to="/help">
                    <Icon name="question"></Icon>
                </Link>
            </div>
            <div className="secondary-navigation__divider" />
            <SettingsDropdown />
        </div>
    </div>
);

export default SecondaryNavigation;

