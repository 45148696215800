import PropTypes from 'prop-types';
import React from 'react';
import LightBoxLink from '../../../../../Common/LightBoxLink';

const SyncChoiceMediaPreviewLink = ({ file }) => {

    const previewButtonClasses = ['sync-mediaoption__sidebar-btn', 'sync-mediaoption__sidebar-preview-btn'];
    const disabled = !file;

    if (disabled) {
        previewButtonClasses.push('sync-mediaoption__sidebar-options--disabled');
    }

    return (
        <div className={previewButtonClasses.join(' ')}>
            <LightBoxLink media={file || {}} />
        </div>
    );
};

export default SyncChoiceMediaPreviewLink;


SyncChoiceMediaPreviewLink.propTypes = {
    file : PropTypes.object
};

SyncChoiceMediaPreviewLink.defaultProps = {
    file: null
};
