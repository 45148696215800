import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../Common/Icon';
import Select from 'react-select';

const ActionTypes = {
    clear: 'clear',
    createOption: 'create-option',
    deselectOption: 'deselect-option',
    popValue: 'pop-value',
    removeValue: 'remove-value',
    selectOption: 'select-option',
    setValue: ' set-value'
};

class Autocomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            itemSelected: props.hasInitialOption,
            isInitialized: props.hasInitialOption,
            selectedOption: props.initialOption
        };

        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.internalSelectionChange = this.internalSelectionChange.bind(this);
    }

      componentWillReceiveProps(newProps) {
        if (newProps.updateKey !== undefined) {
            if (newProps.updateKey === '' || newProps.updateKey !== this.props.updateKey) {
                this.setState({
                    isInitialized: false,
                    itemSelected: false,
                    menuOpen: false
                });
            }
        }
    }

    onMenuOpen() {
        this.setState({
            menuOpen: true
        });
    }

    onMenuClose() {
        this.setState({
            menuOpen: false
        });
    }

    toggleMenu() {
        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        this.setState(prevState => ({
            isInitialized: true,
            menuOpen: !prevState.menuOpen
        }));
    }

    internalSelectionChange(selectedValue, actionType) {
        if (ActionTypes.selectOption === actionType.action) {
            this.setState({
                itemSelected: true,
                selectedOption: selectedValue
            });
        } else {
            this.setState({
                itemSelected: false,
                menuOpen: false,
                selectedOption: null
            });
        }

        this.props.onSelectionChange(selectedValue);
    }

    renderSelectComponent() {
        const { menuOpen, selectedOption} = this.state;

        return (
            <Select
                onChange={this.internalSelectionChange}
                isClearable
                value={selectedOption}
                options={this.props.options}
                isMulti={false}
                classNamePrefix="filterbar"
                noOptionsMessage={this.props.noOptionsMessage}
                onMenuOpen={this.onMenuOpen}
                onMenuClose={this.onMenuClose}
                menuIsOpen={menuOpen}
                backspaceRemovesValue={false}
                placeholder={this.props.placeholder}
            />
        );
    }

    render() {
        const { itemSelected, menuOpen, isInitialized } = this.state;
        const { disabled } = this.props;

        let wrapperClasses = ['filterbar__autocomplete'];

        if (menuOpen) {
            wrapperClasses.push('filterbar__is-expanded');
        } else {
            wrapperClasses = wrapperClasses.filter(item => item !== 'filterbar__is-expanded');
        }

        if (disabled) {
            wrapperClasses.push('filterbar__is-disabled');
        }

        if (itemSelected && isInitialized) {
            wrapperClasses.push('filterbar__item-selected');
        }

        return (
            <React.Fragment>
                <label className={wrapperClasses.join(' ')}>
                    <div
                        onClick={this.toggleMenu}
                        className="filterbar__autocomplete-label"
                    >
                        {this.props.label}
                        <span className="icon">
                            <Icon name="reactselectChevron" />
                        </span>
                    </div>
                    {this.renderSelectComponent()}
                </label>
            </React.Fragment>
        );
    }

}

export default Autocomplete;

Autocomplete.propTypes = {
    getValues: PropTypes.func,
    onSelectionChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    loadingMessage: PropTypes.func,
    noOptionsMessage: PropTypes.func
};

Autocomplete.defaultProps = {
    noOptionsMessage: () => 'Wir konnten keine Ergebnisse für Ihre Eingabe finden',
    loadingMessage: () => 'Lade Ergebnisse',
    getValues: () => false
};
