import PropTypes from 'prop-types';
import React from 'react';

const MailToLink = ({
    email, subject, body, ...props
}) => (
    <a href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`}>
        {props.children}
    </a>
);

export default MailToLink;

MailToLink.propTypes = {
    email: PropTypes.string.isRequired,
    subject: PropTypes.string,
    body: PropTypes.string
};

MailToLink.defaultProps = {
    subject: '',
    body: ''
};
