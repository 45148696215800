import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../../Common/Icon';
import SyncInputGroup from '../SyncInputGroup';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceRow from '../SyncChoiceRow';
import { validateSingleChoiceComplete } from '../../../validation';
import SyncChoiceMediaOption from './SyncChoiceMediaOption';

const validateMedia = validateSingleChoiceComplete();

const MediaSyncChoice = ({ planValue, label, modelMediaType, liveValue, value: currentFormValue, onChange, showIdentical }) => {

    const isSelected = (value) => {

        if(value === undefined) {
            return false;
        }

        if(value === null) {
            return currentFormValue === null;
        }

        const { visibility: a, ...relevantOptionValues } = value || {};
        const { visibility: b, ...relevantFormValues } = currentFormValue || {};

        return _.isEqual(relevantOptionValues, relevantFormValues);
    };

    const renderMediaLabel = (mediaData, wasEdited) => {

        if (!mediaData) {
            return '-';
        }

        const { name, fileName } = mediaData;

        return (
            <div className="sync-mediaoption__media-content">
                <div className="sync-mediaoption__media-content-fileIcon">
                    <Icon name="document" />
                </div>
                <div className="sync-mediaoption__media-content-name">{name}</div>
                {
                    wasEdited ?
                        <div className="sync-mediaoption__media-content-renamed-hint">
                            (Dokument umbenannt)
                        </div>
                        :
                        <div className="sync-mediaoption__media-content-fileName">{fileName}</div>
                }
            </div>
        );
    };

    const mediaWasEdited = (planMedia, liveMedia) => {

        if(planMedia === null || liveMedia === null){
            return false;
        }

         return (planMedia.mode === 'edit' && planMedia.id === liveMedia.id );
    }

    const isIdentical = _.isEqual(planValue, liveValue);

    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label={label}
        >
            <SyncChoiceRow
                leftValue={planValue}
                rightValue={liveValue}
                renderValue={renderMediaLabel}
                isIdentical={isIdentical}
                showIdentical={showIdentical}
                onSelect={onChange}
                wasEdited={mediaWasEdited(planValue, liveValue)}
                isSelected={isSelected}
                currentFormValue={currentFormValue}
                OptionComponent={SyncChoiceMediaOption}
                modelMediaType={modelMediaType}
            />
        </SyncInputGroup>
    );
};

MediaSyncChoice.propTypes = {
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    showIdentical: PropTypes.bool
};

MediaSyncChoice.defaultProps = {
    planValue: null,
    liveValue: null,
    value: null,
    onChange: () => false
};

export default asSyncChoiceField(MediaSyncChoice, {
    allowNull: true,
    validate: validateMedia
});
