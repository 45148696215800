import React from 'react';
import { components } from 'react-select';
import SelectField from '../../Forms/Select/SelectField';
import { getStatusLabel } from '../labels';

const SingleValue = props => {

    const status = props.data.value;

    return (
        <components.SingleValue {...props} >
            <span className="status-label">Status: </span>
            <span className="status-value">{getStatusLabel(status)}</span>
        </components.SingleValue>
    );
};

const SectionStatusSelectField = props => (
    <SelectField
        {...props}
        components={{
            SingleValue
        }}
        placeholder="Status wählen"
    />
);

export default SectionStatusSelectField;
