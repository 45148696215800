import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Icon from '../../../Components/Common/Icon';
import IfUser from '../../../Components/Common/IfUser';
import DeleteModal from '../../../Components/Common/Modal/DeleteModal';
import DetailPageHeader from '../../../Components/DetailPageNavigation/DetailPageHeader';
import JobFormModal from '../../../Components/Job/Form/JobFormModal';
import { JobType } from '../../../Components/Job/properties';
import { PageLoadingSpinner } from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import ScaffoldingFormModal from '../../../Components/Scaffoldings/Form/ScaffoldingFormModal';
import ArchiveConfirmationModal, { ArchiveActions } from '../../../Components/Sites/ArchiveConfirmationModal';
import SiteDetail from '../../../Components/Sites/Detail/SiteDetail';
import SiteFormModal from '../../../Components/Sites/Form/SiteFormModal';
import SitesBreadcrumb from '../../../Components/Sites/SitesBreadcrumb';
import Permission from '../../../permissions';
import { showApiError, showRequestError, showTempError } from '../../../redux/modules/error/action';
import { deleteSingleSite, fetchSiteDetailData } from '../../../redux/modules/site/action';
import { LockedByException } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { UserType } from '../../../user';
import { applyModelChangesToCollection, getFieldCount, optionallyAddCount } from '../../../utils';
import Container from '../../Container';
import ResourceNotFoundErrorPage from '../Errors/ResourceNotFoundErrorPage';
import PrivatePage from './PrivatePage';
import { ResourceViewMode } from './properties';

export const isThirdPartyAndArchived = (ressource) => {

    return ressource.isThirdParty && ressource.archived;
};

const getNavigationLinks = (site) => {

    const scaffoldingCount = getFieldCount(site, 'scaffoldings');
    const documentCount = getFieldCount(site, 'attachments');
    const jobRequestCount = getFieldCount(site, 'jobs', job => job.type === JobType.Request);
    const jobCount = getFieldCount(site, 'jobs', job => job.type === JobType.Job);

    return [
        {
            id: 'generalInformation',
            label: 'Allgemeine Angaben',
        },
        {
            id: 'scaffoldings',
            label: optionallyAddCount('Gerüste', scaffoldingCount),
        },
        {
            id: 'related-requests',
            label: optionallyAddCount('Anfragen', jobRequestCount),
        },
        {
            id: 'related-jobs',
            label: optionallyAddCount('Aufträge', jobCount),
        },
        {
            id: 'construction-diary',
            label: 'Bautagebuch',
        },
        {
            id: 'documents',
            label: optionallyAddCount('Dokumente', documentCount),
        },
        {
            id: 'comments',
            label: 'Kommentare',
        },
    ];
};

class SiteDetailPage extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            initialized: false,
            loading: true,
            site: null,
            showEditModal: false,
            showScaffoldingModal: false,
            showDeleteModal: false,
            showConstructionDiaryEntryModal: false,
            showJobModal: false,
            showRequestModal: false,
            isDeleting: false,
            pageHeaderCollapsed: false,
            showArchiveConfirmationModal: false,
            viewMode: ResourceViewMode.Active,
        };

        this.update = this.update.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleScaffoldingModal = this.toggleScaffoldingModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.toggleConstructionDiaryEntryModal = this.toggleConstructionDiaryEntryModal.bind(this);
        this.toggleArchiveConfirmationModal = this.toggleArchiveConfirmationModal.bind(this);
        this.delete = this.delete.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.onScaffoldingCreated = this.onScaffoldingCreated.bind(this);
        this.onConstructionDiaryEntryCreated = this.onConstructionDiaryEntryCreated.bind(this);
        this.onPageHeaderCollapse = this.onPageHeaderCollapse.bind(this);
        this.onJobChange = this.onJobChange.bind(this);
        this.toggleJobModal = this.toggleJobModal.bind(this);
        this.onJobCreate = this.onJobCreate.bind(this);
    }

    componentWillMount() {
        const {match} = this.props;

        const siteId = match.params.id;
        if (!siteId) {
            this.setState({
                initialized: false,
            });
            return;
        }

        this.initialize(siteId);
    }

    componentDidMount() {
        if (this.shouldOpenScaffoldingModal()) {
            this.toggleScaffoldingModal();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match} = this.props;
        if (prevState.site && prevState.site.id !== match.params.id) {
            this.setState({
                site: null,
                initialized: false,
                showScaffoldingModal: this.shouldOpenScaffoldingModal(),
            }, () => this.initialize(match.params.id));
        }
    }

    shouldOpenScaffoldingModal() {
        const {location} = this.props.history;
        const {state} = location;
        if (!state) {
            return;
        }

        const {openModal} = state;
        if (!openModal) {
            return;
        }

        return openModal.type === 'scaffolding';
    }

    initialize(siteId) {

        this.setState({
            loading: true,
        });

        this.props.actions.fetchSiteDetailData(siteId)
            .then(site => {

                if (!site) {
                    return;
                }

                if (isThirdPartyAndArchived(site)) {
                    return Promise.reject('Die Baustelle ist nicht mehr verfügbar. Bitte kontaktieren Sie den Gerüstersteller für weitere Informationen.');
                }

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState(() => {

                    const {archived} = site;

                    let queryString = '';

                    if (archived) {
                        queryString = 'archived=true';
                    }

                    this.updateHistory(queryString);

                    return {
                        site,
                        viewMode: archived ? ResourceViewMode.Archived : ResourceViewMode.Active,
                    };
                });
            })
            .catch((error) => {
                if (typeof error === 'string') {
                    showTempError(error);
                } else {
                    showRequestError('Die Baustelle konnte nicht geladen werden');
                }
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    loading: false,
                    initialized: true,
                });
            });
    }

    update() {
        const {match} = this.props;

        const siteId = match.params.id;
        if (!siteId) {
            return;
        }

        this.initialize(siteId);
    }

    toggleEditModal() {
        this.setState(prevState => ({
            showEditModal: !prevState.showEditModal,
        }));
    }

    toggleScaffoldingModal() {
        this.setState(prevState => ({
            showScaffoldingModal: !prevState.showScaffoldingModal,
        }));
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal,
        }));
    }

    toggleConstructionDiaryEntryModal() {
        this.setState(prevState => ({
            showConstructionDiaryEntryModal: !prevState.showConstructionDiaryEntryModal,
        }));
    }

    toggleArchiveConfirmationModal() {
        this.setState(prevState => ({
            showArchiveConfirmationModal: !prevState.showArchiveConfirmationModal,
        }));
    }

    toggleJobModal(type) {
        if(type === JobType.Job){
            this.setState(prevState => ({
                showJobModal: !prevState.showJobModal,
            }));
            return;
        }

        this.setState(prevState => ({
            showRequestModal: !prevState.showRequestModal,
        }));
    }

    delete() {
        const {site: {id}} = this.state;

        const {
            actions: {deleteSingleSite},
            history,
        } = this.props;

        this.setState({
            isDeletingSite: true,
        });

        deleteSingleSite(id)
            .then(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isDeletingSite: false,
                });
                this.toggleDeleteModal();
                toast.success('Baustelle wurde gelöscht', {autoClose: 2500});
                history.push('/sites');
            })
            .catch((error) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isDeletingSite: false,
                });

                if (error instanceof LockedByException) {
                    return ErrorService.handleLockedByError(error, 'siteLocked')
                                       .then(({error}) => (showTempError(error)));
                }
                return showApiError(error);

            });
    }

    onUpdated() {
        this.toggleEditModal();
        this.update();
    }

    onScaffoldingCreated(scaffoldingId, createSectionNext) {
        if (createSectionNext) {
            this.props.history.push(`/scaffoldings/${scaffoldingId}`, {
                scaffoldingId,
                openModal: {
                    type: 'section',
                },
            });
        } else {
            this.toggleScaffoldingModal();
            this.update();
        }
    }

    onConstructionDiaryEntryCreated() {
        this.toggleConstructionDiaryEntryModal();
    }

    updateHistory(queryString) {

        const {history, match} = this.props;

        history.replace({
            ...history.location,
            state: {
                ...history.location.state,
                sitesOverviewQueryString: (history.location.state && history.location.state.sitesOverviewQueryString) || queryString,
                siteId: match.params.id,
            },
        });
    }

    onSiteArchiveConfirmation(action) {

        const {site: {id: siteId}} = this.state;

        const {history} = this.props;

        let queryString = '';

        if (action === ArchiveActions.Archived) {
            queryString = 'archived=true';
        }

        history.replace({
            ...history.location,
            state: {
                ...history.location.state,
                sitesOverviewQueryString: queryString,
            },
        });

        this.toggleArchiveConfirmationModal();
        this.initialize(siteId);

    }

    onPageHeaderCollapse(collapsed) {
        this.setState({
            pageHeaderCollapsed: collapsed,
        });
    }

    onJobChange(changedJob, changes) {
        const {site} = this.state;
        if (!site) {
            return;
        }

        const {jobs} = site;
        if (!jobs) {
            return;
        }

        const updatedJobs = applyModelChangesToCollection(jobs, changedJob.id, changes);

        this.setState({
            site: {
                ...site,
                jobs: updatedJobs,
            },
        });
    }

    onJobCreate(jobId){
        this.props.history.push(`/jobs/${jobId}`);
    }

    renderPageContent() {
        const {
            site,
            showEditModal,
            showScaffoldingModal,
            showDeleteModal,
            isDeletingSite,
            loading,
            showArchiveConfirmationModal,
            viewMode,
            showJobModal,
            showRequestModal,
        } = this.state;

        if (!site) {
            return <ResourceNotFoundErrorPage/>;
        }

        return (
            <Fragment>
                <SiteDetail
                    site={site}
                    toggleEditModal={this.toggleEditModal}
                    toggleScaffoldingModal={this.toggleScaffoldingModal}
                    toggleDeleteModal={this.toggleDeleteModal}
                    onJobChange={this.onJobChange}
                    toggleConstructionDiaryEntryModal={this.toggleConstructionDiaryEntryModal}
                    onUpdate={this.update}
                    loading={loading}
                    viewMode={viewMode}
                    onJobCreate={this.onJobCreate}
                    onClickAddJob={this.toggleJobModal}
                />

                {showEditModal &&
                <SiteFormModal
                    mode="edit"
                    id={site.id}
                    onUpdated={this.onUpdated}
                    onClose={this.toggleEditModal}
                />
                }

                {showScaffoldingModal &&
                <ScaffoldingFormModal
                    siteId={site.id}
                    onCreated={this.onScaffoldingCreated}
                    onClose={this.toggleScaffoldingModal}
                />
                }

                {
                    showArchiveConfirmationModal &&
                    <ArchiveConfirmationModal
                        onConfirmationSuccess={(action) => this.onSiteArchiveConfirmation(action)}
                        siteId={site.id}
                        isArchived={site.archived}
                        onClose={() => this.toggleArchiveConfirmationModal()}
                    />
                }

                {showDeleteModal &&
                <DeleteModal
                    modalTitle="Baustelle löschen"
                    deleteEntity={this.delete}
                    closeModal={this.toggleDeleteModal}
                    isDeletingEntity={isDeletingSite}
                >
                    <p>
                        Sie sind im Begriff, die aktive Baustelle
                        <span
                            onClick={this.toggleDeleteModal}
                            className="btn--link-neutral"
                        >
                            {' '} {site.name}
                        </span> zu löschen.

                        <strong> Alle dazugehörigen Gerüste und Gerüstabschnitte werden damit ebenfalls
                                 geschlossen und gelöscht
                        </strong>
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteModal>
                }

                {
                    (showJobModal || showRequestModal) &&
                    <JobFormModal
                        onCreated={this.onJobCreate}
                        onClose={() => this.toggleJobModal(showJobModal ? JobType.Job : JobType.Request)}
                        isRequest={!showJobModal}
                    />
                }
            </Fragment>
        );
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    render() {
        const {initialized, loading, site, pageHeaderCollapsed, viewMode} = this.state;
        const pageTitle = !initialized ? 'Lade Baustelle' : `Baustelle: ${site ? site.name : 'n.a.'}`;

        const actions = (!initialized) ? null : (
            <IfUser
                can={['update-sites']}
                and={site && !site.isThirdParty}
            >
                <button
                    onClick={this.toggleDeleteModal}
                    className="btn btn--delete-action btn--icon"
                    title="Baustelle löschen"
                >
                    <span className="btn__icon">
                        <Icon name="delete"/>
                    </span>
                </button>

                <button
                    onClick={this.toggleArchiveConfirmationModal}
                    className="btn btn--archive-action btn--icon"
                    title={viewMode === ResourceViewMode.Archived ? 'Baustelle reaktivieren' : 'Baustelle archivieren'}
                >
                    <span className="btn__icon">
                        {
                            viewMode === ResourceViewMode.Archived ?
                                <Icon name="restore"/>
                                :
                                <Icon name="archiveBox"/>
                        }
                    </span>
                </button>
                {
                    viewMode !== ResourceViewMode.Archived && (
                        <Fragment>
                            <button
                                onClick={this.toggleEditModal}
                                className="btn btn--neutral-action btn--icon"
                                title="Baustelle bearbeiten"
                            >
                                <span className="btn__icon">
                                    <Icon name="edit"/>
                                </span>
                            </button>
                        </Fragment>
                    )
                }
            </IfUser>
        );

        return (
            <PrivatePage requiredUserPermission={Permission.ReadSites}>
                <IfUser
                    isOneOfType={[UserType.ProQualifiedWorker, UserType.ProWorker]}
                    and={viewMode === ResourceViewMode.Archived}
                >
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                </IfUser>

                <Container>
                    <TopNav
                        hasNewPageHead={!!(initialized && site)}
                        hide={pageHeaderCollapsed}
                    />

                    <SitesBreadcrumb
                        collapse={pageHeaderCollapsed}
                        site={site}
                        showSite={true}
                    />

                    <DetailPageHeader
                        type="site"
                        title={pageTitle}
                        navigationalLinks={getNavigationLinks(site)}
                        actions={actions}
                        onCollapse={this.onPageHeaderCollapse}
                        viewMode={viewMode}
                        isLoading={loading}
                    />

                    {
                        loading && <PageLoadingSpinner/>
                    }

                    {initialized && this.renderPageContent()}
                </Container>
            </PrivatePage>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSiteDetailData,
        deleteSingleSite,
    }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)((SiteDetailPage)));
