import callApi, { querySingle } from '../../action';

import * as types from '../../types';
import { showRequestError } from '../error/action';

export const deleteScaffolding = () => ({
    type: types.DELETE_SCAFFOLDING
});

export const fetchScaffolding = () => ({
    type: types.FETCH_SCAFFOLDING
});

export const fetchScaffoldingSuccess = scaffolding => ({
    type: types.FETCH_SCAFFOLDING_SUCCESS,
    payload: scaffolding
});

export const fetchScaffoldingFailure = error => ({
    type: types.FETCH_SCAFFOLDING_FAILURE,
    payload: error
});

export function addNewScaffolding(scaffoldingData) {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        return callApi('v1/scaffoldings', {
            method: 'POST',
            token,
            body: scaffoldingData
        })
            .then(res => res.json());
    };
}

export function updateScaffolding(scaffoldingId, scaffoldingInfo) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi(`v1/scaffoldings/${scaffoldingId}`, {
            method: 'PUT',
            token,
            body: scaffoldingInfo
        })
            .then(res => res.json());
    };
}

export const fetchScaffoldingFormData = scaffoldingId => (

    (dispatch, getState) => {
        dispatch(fetchScaffolding());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Scaffolding{scaffolding(id:"${scaffoldingId}"){
            id,
            name,
            description,
            scheduledErection,
            scheduledDismantling,
            managed,
            site{id,name},
            utilizationPlans{id, name, fileName, mimeType, size, visibility},
            attachments{id, name, fileName, mimeType, size, visibility},
            anchorProtocol{id, name, fileName, mimeType, size, visibility}
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'scaffolding')
            .then((scaffolding) => {
                if (!scaffolding) {
                    dispatch(fetchScaffoldingFailure());
                    return null;
                }

                dispatch(fetchScaffoldingSuccess(scaffolding));
                return scaffolding;
            })
            .catch((error) => {
                dispatch(fetchScaffoldingFailure(error));
                showRequestError('Gerüstdaten konnten nicht geladen werden', error);
            });
    });


export const fetchScaffoldingDetailData = scaffoldingId => (

    (dispatch, getState) => {
        dispatch(fetchScaffolding());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Scaffolding{unknownScaffolding(id:"${scaffoldingId}"){
            ... on Scaffolding {
                id,
                name,
                archived,
                description,
                scheduledErection,
                scheduledDismantling,
                jobs{
                    id,
                    name,
                    status,
                    created,
                    updated,
                    archived,
                    type,
                    customer {
                        id,name
                    },
                    site {
                        id,name,address{city},archived
                    },
                    sections {
                        id,name,scaffolding{id,name,archived},archived
                    }
                },
                sectionCount,
                managed,
                site{id,name,archived, address{line1, line2, city, zip}},
                notes{content, id},
                sections{id,archived,name,status,publicStatus,equippedSurfaces,purposes,otherProtectivePurpose,plannedWorks,loadClass,managed},
                utilizationPlans{id, name, fileName, mimeType, size, visibility},
                attachments{id,name,fileName,size,mimeType,visibility},
                anchorProtocol{id, name, fileName, mimeType, size, visibility}
            }
            ... on ThirdPartyScaffolding {
                id,
                name,
                scheduledErection,
                scheduledDismantling,
                sectionCount,
                isThirdParty,
                isInvited,
                site{
                    id,name,isThirdParty,isInvited, 
                    address{line1, line2, city, zip}, 
                    invitations {
                       allowInspections,
                       allowCloseRequests 
                    },
                    archived
                },
                archived,
                sections{id,name,archived,publicStatus,equippedSurfaces,purposes,otherProtectivePurpose,plannedWorks,loadClass,isThirdParty,isInvited},
                utilizationPlans{id, name, fileName, mimeType, size},
                attachments{id,name,fileName,size,mimeType},
                anchorProtocol{id, name, fileName, mimeType, size}
            }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'unknownScaffolding')
            .then((scaffolding) => {
                if (!scaffolding) {
                    dispatch(fetchScaffoldingFailure());
                    return null;
                }

                dispatch(fetchScaffoldingSuccess(scaffolding));
                return scaffolding;
            })
            .catch((error) => {
                dispatch(fetchScaffoldingFailure(error));
                showRequestError('Gerüstdaten konnten nicht geladen werden', error);
            });
    });

/**
 *
 * @param scaffoldingId
 * @returns {function(*=, *): (Promise<any> | Promise | * | Promise<T>)}
 */
export const deleteSingleScaffolding = scaffoldingId => (

    (dispatch, getState) => {
        dispatch(deleteScaffolding());

        const token = getState().currentUser.auth_token;

        return callApi(`v1/scaffoldings/${scaffoldingId}`, {
            method: 'DELETE',
            token
        })
            .then(data => data);
    }
);

export const fetchConstructionPlanScaffoldingFormData = scaffoldingId => (
    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        const gqlQuery = `Scaffolding{scaffolding(id:"${scaffoldingId}"){
            id,
            name,
            description,
            scheduledErection,
            scheduledDismantling,
            utilizationPlans{id, name, fileName, mimeType, size, visibility},
            attachments{id, name, fileName, mimeType, size, visibility},
            anchorProtocol{id, name, fileName, mimeType, size, visibility},
            sections{id, name, equippedSurfaces, plannedWorks, purposes, otherProtectivePurpose}
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'scaffolding');
    });

