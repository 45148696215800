import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchAccountUsers } from '../../redux/modules/account/action';
import { deleteUser } from '../../redux/modules/user/action';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import EmptyTableHint from '../Common/EmptyTableHint';
import Section from '../Layout/Section';
import Table from '../Common/Table';
import { showApiError } from '../../redux/modules/error/action';
import PageTitle from '../Page/PageTitle';
import Icon from '../Common/Icon';
import PrimaryColumn from '../Common/PrimaryColumn';
import IfUser from '../Common/IfUser';
import DeleteModal from '../Common/Modal/DeleteModal';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';
import AccountType from '../Common/AccountType';

class AccountUserTable extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            accountUsers: [],
            isFetching: false,
            userDeleteModalIsOpen: false,
            isDeletingUser: false
        };
        this.removeUser = this.removeUser.bind(this);
        this.toggleUserDeleteModal = this.toggleUserDeleteModal.bind(this);
    }

    componentWillMount() {
        const { actions: { fetchAccountUsers } } = this.props;

        this.setState({
            isFetching: true
        });

        fetchAccountUsers()
            .then((accountUsers) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setState({
                    isFetching: false,
                    accountUsers
                });
            })
            .catch((error) => {

                this.setState({
                    isFetching: false
                });

                showApiError((error));
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    toggleUserDeleteModal(user = null) {

        if (user) {
            this.setState({
                userDeleteModalIsOpen: true,
                entityToDelete: user
            });
            return;
        }

        this.setState({
            userDeleteModalIsOpen: false,
            entityToDelete: null
        });
    }

    removeUser() {

        const { entityToDelete } = this.state;

        if (!entityToDelete) {
            return;
        }

        const {
            actions: { deleteUser, fetchAccountUsers }
        } = this.props;

        this.setState({
            isDeletingUser: true
        });

        deleteUser(entityToDelete.id)
            .then(() => {
                this.setState({
                    isDeletingUser: false
                });

                this.toggleUserDeleteModal();
                toast.success('Nutzer wurde gelöscht', { autoClose: 2500 });

                this.setState({
                    isFetching: true
                });

                fetchAccountUsers()
                    .then((accountUsers) => {
                        if (this.loadingGotCancelled) {
                            return;
                        }
                        this.setState({
                            isFetching: false,
                            accountUsers
                        });
                    })
                    .catch((error) => {
                        showApiError((error));
                    });
            })
            .catch((error) => {
                this.setState({
                    isDeletingUser: false
                });

                showApiError(error);
            });
    }

    render() {

        const {
            accountUsers,
            isFetching,
            isDeletingUser,
            userDeleteModalIsOpen,
            entityToDelete
        } = this.state;

        if (isFetching) {
            return (
                <Fragment>
                    <PageTitle title="Nutzerverwaltung"/>
                    <PageLoadingSpinner />
                </Fragment>
            );
        }

        return (
            <div className="user-management">
                <PageTitle title="Nutzerverwaltung">
                    <IfUser can={['create-users']}>
                        <Link className="btn btn--add-action" to="/account/users/add">
                            <span className="btn__icon">
                                <Icon name="add" />
                            </span>
                            Neuer Nutzer
                        </Link>
                    </IfUser>
                </PageTitle>

                <Section
                    type="list"
                >
                    <Table
                        columns={['Name', 'Kontotyp', 'E-Mail Adresse']}
                        rows={accountUsers}
                        emptyComponent={
                            <EmptyTableHint
                                numColumns={2}
                                title="Es wurden noch keine Nutzer hinzugefügt"
                            />
                        }
                    >
                        {
                            (accountUser) => {
                                const userName = `${accountUser.firstName} ${accountUser.lastName}`;
                                return (
                                    <tr key={accountUser.id}>
                                        <IfUser
                                            can={['delete-users', 'update-users']}
                                            ifNot={<td className="name-column__link"
                                            >{userName}</td>}
                                        >
                                            <PrimaryColumn
                                                link={
                                                    <Link
                                                        to={`/account/users/${accountUser.id}/edit`}
                                                    >
                                                        {userName}
                                                        {accountUser.isAccountOwner && <span className="indicator--isAccountOwner"><Icon name="owner" /> Eigentümer</span>}
                                                    </Link>
                                                }
                                            >
                                                <Link
                                                    to={`/account/users/${accountUser.id}/edit`}
                                                >Bearbeiten</Link>
                                                <a
                                                    onClick={() => this.toggleUserDeleteModal({
                                                        id: accountUser.id,
                                                        name: userName
                                                    })}
                                                >
                                                    Entfernen
                                                </a>
                                            </PrimaryColumn>
                                        </IfUser>
                                        <td>
                                            <AccountType accountLabel={accountUser.type} />
                                        </td>
                                        <td>
                                            {accountUser.email ?
                                                <a href={`mailto:${accountUser.email}`}>{accountUser.email}</a>
                                                :
                                                <NotAvailablePlaceholder />
                                            }
                                        </td>
                                    </tr>
                                );
                            }
                        }
                    </Table>
                </Section>

                {userDeleteModalIsOpen &&
                <DeleteModal
                    modalTitle="Nutzer löschen"
                    deleteEntity={this.removeUser}
                    closeModal={this.toggleUserDeleteModal}
                    isDeletingEntity={isDeletingUser}
                >
                    <p>Sie sind im Begriff, den Nutzer {' '}
                        <strong>{entityToDelete.name}</strong>
                        {' '}
                        zu
                        löschen.
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteModal>
                }
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchAccountUsers,
        deleteUser
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(AccountUserTable);
