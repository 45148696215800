import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Icon from '../../../Components/Common/Icon';
import Input from '../../../Components/Forms/Input';
import InputGroup from '../../../Components/Forms/InputGroup';
import LoadingSpinner from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import { ADV_DOCUMENT_URL, PRIVACY_DOCUMENT_URL } from '../../../constants';
import history from '../../../history';
import { showApiError, showTempError } from '../../../redux/modules/error/action';
import { resetPassword } from '../../../redux/modules/user/action';
import { UnauthorizedError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import PublicFooter from './PublicFooter';
import PublicPage from './PublicPage';

const INITIAL_MODE = 'initial';

const focusOnError = createDecorator();

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResetting: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    getUrlParameter(parameter) {
        const url = new URL(window.location);
        return url.searchParams.get(parameter);
    }

    componentWillMount() {
        const mode = this.getUrlParameter('m');

        if (mode === INITIAL_MODE) {
            this.setState({
                mode: 'initial'
            });
        }
    }

    onSubmit(values) {

        const { mode } = this.state;

        const data = {
            password: values.password,
            token: this.getUrlParameter('t')
        };

        if (mode === INITIAL_MODE) {
            data.accept_adv = true;
        }

        this.setState({
            isResetting: true
        });

        this.props.actions.resetPassword(data)
            .then(() => {
                this.setState({
                    isResetting: false
                });
                history.push('/login');
                toast.success('Sie können sich jetzt mit Ihren neuem Passwort einloggen', { autoClose: 2500 });
            })
            .catch(error => {
                this.setState({
                    isResetting: false
                });

                if (error instanceof UnauthorizedError) {
                    const errorKey = this.state.mode === INITIAL_MODE ? 'newUserAcceptsInvitation' : 'resetPassword';
                    return ErrorService.handleUnauthorizedError(error, errorKey)
                        .then(({ message }) => {
                            showTempError(message);
                        });
                }

                return showApiError(error);
            });
    };

    validate(values) {

        const errors = {};

        // E-Mail
        if (!values.password) {
            errors.password = 'Bitte geben Sie ein Passwort ein';
        } else if (values.password.length < 6) {
            errors.password = 'Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein';

        } else if (values.password !== values.passwordConfirm) {
            errors.passwordConfirm = 'Ihre Passwörter stimmen nicht überein';
        }
        return errors;
    };

    render() {
        const { isResetting, mode } = this.state;

        return (
            <PublicPage>
                <TopNav isPublic />

                <div className="forgot-password">
                    <h1>Geben Sie Ihr neues Passwort ein</h1>
                    <div className="section section--login">
                        <Form
                            onSubmit={this.onSubmit}
                            decorators={[focusOnError]}
                            validate={this.validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <InputGroup
                                        label="Neues Passwort*"
                                    >
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder="Neues Passwort eingeben"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Neues Passwort wiederholen*"
                                    >
                                        <Input
                                            type="password"
                                            name="passwordConfirm"
                                            placeholder="Passwort wiederholen"
                                        />
                                    </InputGroup>
                                    <div className="hints flex justify-content-between">
                                        <span>* Pflichtfeld</span>
                                    </div>
                                    {mode === INITIAL_MODE &&
                                    <div className="accept-invitation-form__disclaimer">
                                            <span
                                                className="accept-invitation-form__disclaimer-icon"
                                            > <Icon name="info" />
                                            </span>
                                        <div>
                                            Mit Annehmen der Einladung akzeptieren Sie
                                            unsere <a
                                            href={PRIVACY_DOCUMENT_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >Datenschutzerklärung
                                        </a> sowie die <a
                                            href={ADV_DOCUMENT_URL}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        > Auftragsdatenverarbeitung
                                        </a>
                                        </div>
                                    </div>
                                    }

                                    <div className="flex justify-content-between">
                                        <Link className="btn btn--backward-action" to="/login">Zum
                                            Login</Link>
                                        <button
                                            type="submit"
                                            className="btn btn--primary-green"
                                            disabled={isResetting}
                                        >
                                            {isResetting ? <LoadingSpinner
                                                label="Passwort zurücksetzen"
                                            /> : 'Passwort zurücksetzen'}
                                        </button>
                                    </div>

                                </form>
                            )}
                        />
                    </div>
                    <PublicFooter />
                </div>
            </PublicPage>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ resetPassword }, dispatch)
});

export default connect(null, mapDispatchToProps)(ResetPassword);

ResetPassword.propTypes = {
    actions: PropTypes.shape({
        forgotPassword: PropTypes.func
    })
};

ResetPassword.defaultProps = {
    actions: {
        forgotPassword: () => false
    }
};
