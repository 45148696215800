import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import DownloadLinkInline from './Components/Common/DownloadlinkInline';
import NotAvailablePlaceholder from './Components/Common/NotAvailablePlaceholder';
import Tooltip from './Components/Common/Tooltip';
import { LOCAL_STORAGE_IS_NEW_APP_VERSION_AVAILABLE } from './properties';

export const validateEmail = (email) => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email)
        .toLowerCase());
};

export const autoFormatFileSize = (fileSize) => {

    if (!fileSize || typeof fileSize !== 'number') {
        return 'n.a.';
    }

    if (fileSize > 1000000000) {
        return `${(fileSize / 1000000000.0)
            .toPrecision(3)} GB`;
    } else if (fileSize > 1000000) {
        return `${(fileSize / 1000000.0)
            .toPrecision(3)} MB`;
    } else if (fileSize > 1000) {
        return `${(fileSize / 1000.0)
            .toPrecision(3)} kB`;
    }
    return `${fileSize} bytes`;
};

export const renderCurrentPosting = (currentPosting) => {

    const renderTooltipContent = () => (
        <Fragment>
            <p>
                Mit Hilfe des Gerüstabschnitt-Aushanges können Sie Nutzern die Übergabe sowie die
                Spezifikationen Ihrer Gerüstabschnitte auf der Baustelle zur Verfügung stellen. Drucken
                Sie dazu den jeweils aktuellsten Aushang aus und hängen Sie ihn an den Eingang des
                Gerüstabschnittes.
                <br /> <br />
            </p>

            <p>
                Ein Gerüstabschnitt-Aushang wird generiert und steht Ihnen zum Download zur Verfügung,
                sobald Sie eine Freigabeprüfung mit Hilfe der App durchgeführt haben.
                <br /> <br />
            </p>

            <p>
                <strong>Bitte beachten Sie:</strong> <br />
                Sobald sich die Spezifikationen oder der Status Ihres Gerüstabschnittes ändert, muss
                eine erneute Freigabeprüfung durchgeführt werden. Achten Sie darauf, dass stets der
                jeweils aktuelle Aushang am Eingang Ihres Gerüstabschnittes hängt.
            </p>
        </Fragment>
    );

    if (!currentPosting) {
        return (
            <div className="current-posting">

                <NotAvailablePlaceholder />

                <div className="current-posting__info-icon">
                    <Tooltip id="postings-info">
                        {renderTooltipContent()}
                    </Tooltip>
                </div>
            </div>
        );
    }
    return (
        <div className="current-posting">
            <DownloadLinkInline
                key={currentPosting.id}
                attachment={currentPosting}
                showFileSize={false}
            />

            <div className="current-posting__info-icon">
                <Tooltip id="postings-info">
                    {renderTooltipContent()}
                </Tooltip>
            </div>
        </div>
    );

};

export const isImage = mimeType => mimeType && mimeType.indexOf('image/') === 0;
export const isPdf = mimeType => mimeType === 'application/pdf';

export const getFileExtension = fileName => fileName.split('.')
    .pop();

export const formatExpirationMonth = (expirationMonth) => {
    if (typeof expirationMonth !== 'string') {
        return null;
    }
    return expirationMonth.replace(/^0+/, '');
};

export const limit = (val, max) => {
    let result = val;

    if (val.length === 1 && val[0] > max[0]) {
        result = `0${val}`;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            result = '01';
        } else if (val > max) {
            result = max;
        }
    }

    return result;
};

export function cardExpiry(val) {
    const month = limit(val.substring(0, 2), '12');
    const date = limit(val.substring(2, 4), '50');
    return month + (date.length ? `/${date}` : '');
}

export function formatCurrency(value, decimals, delimiterD, delimiterT) {
    const c = isNaN(Math.abs(decimals)) ? 2 : decimals;
    const d = delimiterD === undefined ? '.' : delimiterD;
    const t = delimiterT === undefined ? ',' : delimiterT;
    const s = value < 0 ? '-' : '';
    const i = String(parseInt(Math.abs(Number(value) || 0)
        .toFixed(decimals), 10));
    const j = (i.length) > 3 ? (i.length) % 3 : 0;

    return s + (j ? i.substr(0, j) + t : '') + i.substr(j)
        .replace(/(\d{3})(?=\d)/g, `$1${t}`) + (c ? d + Math.abs(value - i)
        .toFixed(c)
        .slice(2) : '');
}


export const truncateText = (text, limit = 100, ending = '...') => {

    if (!text) {
        return null;
    }

    if (text.length > limit) {
        return '"' + text.substring(0, limit) + ending + '"';
    }
    return '"' + text + '"';
};

export const getDatePeriodLabel = (startDate, endDate) => {
    if (!startDate && !endDate) {
        return '';
    }

    let startLabel = '';
    if (startDate) {
        startLabel = moment(startDate)
            .format('DD.MM.YYYY');
    }

    let endLabel = '';
    if (endDate) {
        endLabel = moment(endDate)
            .format('DD.MM.YYYY');
    }

    if (startDate && endDate) {
        return `${startLabel} - ${endLabel}`;
    }

    if (startDate) {
        return `ab ${startLabel}`;
    }

    return `bis ${endLabel}`;
};

export const getDeletedUserLabel = () => {
    return 'Unbekannt (Benutzer gelöscht)';
};

export const toggleArrayElement = (array, value) => {
    const _array = array;

    const index = _array.indexOf(value);

    if (index === -1) {
        _array.push(value);
    } else {
        _array.splice(index, 1);
    }

    return _array;
};

export function isUsingInternetExplorer() {
    let isIE = false;

    if (navigator.appName == 'Microsoft Internet Explorer' || navigator.appName == 'Netscape') {
        var uA = navigator.userAgent;
        var rE = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');

        if (rE.exec(uA) != null) {
            isIE = true;
        }
        /*check for IE 11*/
        else if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            isIE = true;
        }
    }

    return isIE;
}

export function isNewAppVersionAvailable() {
    return !!localStorage.getItem(LOCAL_STORAGE_IS_NEW_APP_VERSION_AVAILABLE);
}

export const applyModelChangesToCollection = (modelCollection, modelId, modelChanges) => {
    if(!modelCollection || !_.isArray(modelCollection)) {
        return modelCollection;
    }

    return modelCollection.map(model => {
        if (model.id !== modelId) {
            return model;
        }

        return {
            ...model,
            ...modelChanges
        };
    })
}

export const optionallyAddCount = (label, count) => {
    if(!count) {
        return label;
    }

    return `${label} (${count})`;
}

export const getFieldCount = (object, fieldName, filter) => {
    if (!object || !_.isObject(object)) {
        return 0;
    }

    const field = object[fieldName];
    if (!field || !_.isArray(field)) {
        return 0;
    }

    let list = field;
    if(_.isFunction(filter)) {
        list = list.filter(filter);
    }

    return list.length;
}

export function enumToList(enumObject) {
    if(!enumObject) {
        return [];
    }

    return Object.values(enumObject);
}
