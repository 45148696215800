import PropTypes from 'prop-types';
import React from 'react';

const SyncInputGroup = ({ label, children, subLevel }) => {

    let _label = label;
    if (subLevel) {
        _label = `– ${label}`;
    }

    return (
        <div className={`syncInputGroup ${subLevel ? 'syncInputGroup--sub' : ''}`}>
            <div className="syncInputGroup__title">
                {_label}
            </div>
            <div className="syncInputGroup__cnt">
                {children}
            </div>
        </div>
    );
};

SyncInputGroup.propTypes = {
    label: PropTypes.string,
    subLevel: PropTypes.bool
};

SyncInputGroup.defaultProps = {
    label: '',
    subLevel: false
};

export default SyncInputGroup;
