import React from 'react';
import { Field } from 'react-final-form';
import Condition from '../../../Forms/Condition';
import Error from '../../../Forms/Error';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import { LoadClass, Purpose, SectionStatus } from '../../properties';
import { LoadClassOptions } from '../options';
import { isForbidden, isRequired } from '../validate';

export const SectionLoadClassInput = ({ values, values: { status } }) => {
    const isDisabled = isForbidden('loadClass', values);
    let disabledToolTip = undefined;
    if (isDisabled) {
        disabledToolTip = 'Lastklassen können bei Fluchttreppen nicht konfiguriert werden';
    }

    return (
        <InputGroup
            label="Lastklasse"
            required={isRequired('loadClass', values)}
            tooltip={disabledToolTip}
        >
            <Field name="loadClass" component="select" disabled={isDisabled}>
                {
                    LoadClassOptions.map((option, i) => (
                        <option
                            key={i}
                            value={option.value}
                            disabled={isDisabled}
                        >
                            {option.label}
                        </option>
                    ))
                }
            </Field>

            {status !== SectionStatus.Draft &&
            <Condition
                when="purpose.work"
                isOneOf={[Purpose.BirdcageScaffolding, Purpose.InteriorBirdcageScaffolding]}
            >
                <Condition when="loadClass" isNotSet>
                    <InputHint>
                        Sie haben Ihren Gerüstabschnitt als &quot;Raumgerüst&quot;
                        oder &quot;Flächengerüst&quot; klassifiziert.
                        Für diese Arbeitsgerüste sind nur die Lastklasse 6 und
                        Sonderklassen zulässig. Bitte beachten Sie
                        diesen Umstand bei der Planung und Erstellung Ihres
                        Gerüstabschnittes.
                    </InputHint>
                </Condition>
            </Condition>
            }

            <Condition
                when="purpose.work"
                isOneOf={[Purpose.BirdcageScaffolding, Purpose.InteriorBirdcageScaffolding]}
            >
                <Condition
                    when="loadClass"
                    isOneOf={[LoadClass.LC1, LoadClass.LC2, LoadClass.LC3, LoadClass.LC4, LoadClass.LC5]}
                >
                    <InputHint>
                        Raum-/Flächengerüste sollten mit Lastklasse 6 oder
                        höher erbaut werden.
                    </InputHint>
                </Condition>
            </Condition>

            <Condition when="loadClass" is={LoadClass.LC1}>
                <InputHint>
                    Arbeitsgerüste der Lastklasse 1 und der
                    Breitenklasse W 06 dürfen nur für Inspektionstätigkeiten
                    eingesetzt werden. Dabei darf je Gerüstfeld ein
                    Nutzgewicht von 1,5 kN (1 Person zuzüglich Werkzeug)
                    nicht überschritten werden. Materiallagerung ist
                    unzulässig.
                </InputHint>
            </Condition>

            <Condition when="loadClass" is={LoadClass.LC2}>
                <InputHint>
                    Arbeitsgerüste der Lastklasse 2 und der Breitenklasse
                    W 06 dürfen nur für Arbeiten eingesetzt werden, die kein
                    Lagern von Baustoffen und Bauteilen erfordern. Einzelne
                    Belagteile, die schmaler als 0,50 m sind (z.B.
                    Gerüstbohlen), dürfen innerhalb ihrer zulässigen
                    Stützung nur mit 1,5 kN beansprucht werden.
                </InputHint>
            </Condition>

            <Condition when="loadClass" is={LoadClass.LC3}>
                <InputHint>
                    Arbeitsgerüste der Lastklasse 3 und der Breitenklasse W
                    06 dürfen nur für Arbeiten eingesetzt werden, bei denen
                    die Belastung aus Material und Personen das
                    flächenbezogene Nutzgewicht von 2 kN/m2 nicht
                    überschreitet. Einzelne Belagteile, die schmaler als
                    0,50 m sind (z.B. Gerüstbohlen), dürfen innerhalb ihrer
                    zulässigen Stützung mit höchstens 1,5 kN beansprucht
                    werden.
                </InputHint>
            </Condition>

            <Condition when="loadClass" isOneOf={[LoadClass.LC4, LoadClass.LC5, LoadClass.LC6]}>
                <InputHint>
                    Arbeitsgerüste der Lastklassen 4, 5 und 6 und der
                    Breitenklasse W 09 und höher dürfen für Arbeiten
                    eingesetzt werden, bei denen Baustoffe oder Bauteile auf
                    dem Gerüstbelag abgesetzt oder gelagert werden.
                    Zulässige Gesamtbelastung ist auch hier zu beachten.
                </InputHint>
            </Condition>

            <Condition when="loadClass" is={LoadClass.Other}>
                <Field
                    name="loadClassOther"
                    component="input"
                    type="text"
                    placeholder={`z.B. "Siehe Statiknachweis"${isRequired('loadClassOther', values) ? '*' : ''}`}
                />
            </Condition>
            <Error name="loadClassOther" />
            <Error name="loadClass" />
        </InputGroup>
    )
}
