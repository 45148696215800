import 'moment/locale/de';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFilterOption } from '../../redux/modules/constructiondiary/action';
import { autocompleteSection } from '../../redux/modules/section/action';
import { getDatePeriodLabel } from '../../utils';
import Icon from '../Common/Icon';
import { FILTER_KEYS } from './Overview/ConstructionDiaryOverview';

class DateFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            startDate: null,
            endDate: null
        };

        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    componentWillMount() {
        const { initialValueFrom, initialValueTo } = this.props;

        const startDate = initialValueFrom ? moment(initialValueFrom) : null;
        const endDate = initialValueTo ? moment(initialValueTo) : null;

        this.setState({
            startDate,
            endDate
        });

        const exportFilterOption = {
            dateFrom: startDate || '',
            dateTo: endDate || ''
        };

        this.props.actions.setFilterOption({
            key: FILTER_KEYS.date,
            value: exportFilterOption
        });
    }

    toggleMenu() {
        const { disabled } = this.props;

        if ( disabled ) {
            return;
        }

        this.setState(prevState => ({
            isInitialized: true,
            menuOpen: !prevState.menuOpen
        }));
    }

    onMenuOpen() {
        this.setState({
            menuOpen: true
        });
    }

    onMenuClose() {
        this.setState({
            menuOpen: false
        });
    }

    handleClickOutside() {
        this.setState({
            menuOpen: false
        });
    }

    handleStartDateChange(startDate) {
        this.setState({
            startDate
        }, this.onDateChange);
    }

    handleEndDateChange(endDate) {
        let _endDate = endDate;
        if ( _endDate ) {
            _endDate = moment(endDate).endOf('D');
        }

        this.setState({
            endDate: _endDate
        }, this.onDateChange);
    }

    onDateChange() {
        const { startDate, endDate } = this.state;

        this.props.onSelectionChange({
            dateFrom: startDate ? moment(startDate).format() : '',
            dateTo: endDate ? moment(endDate).format() : ''
        });

        if ( (startDate && endDate) || (!startDate && !endDate) ) {
            this.setState({
                menuOpen: false
            });
        }
    }

    resetDate() {
        this.setState({
            startDate: null,
            endDate: null,
            menuOpen: false
        }, () => {
            this.props.onSelectionChange(null);
        });
    }

    render() {
        const { menuOpen, startDate, endDate } = this.state;
        const { disabled } = this.props;

        let wrapperClasses = [ 'filterbar__datepicker' ];

        if ( menuOpen ) {
            wrapperClasses.push('filterbar__is-eexpanded');
        } else {
            wrapperClasses = wrapperClasses.filter(item => item !== 'filterbar__is-expanded');
        }

        if ( disabled ) {
            wrapperClasses.push('filterbar__datepicker-disabled');
        }

        const itemSelected = startDate || endDate;

        if ( itemSelected ) {
            wrapperClasses.push('filterbar__datepicker-is-selected');
        }

        return (
            <div className="filterbar__datepicker-wrapper">

                <label className={wrapperClasses.join(' ')}>
                    <div
                        onClick={this.toggleMenu}
                        className="filterbar__datepicker-label"
                    >
                        {this.props.label}
                        <span className="icon">
                            <Icon name="reactselectChevron"/>
                        </span>
                    </div>
                    {itemSelected &&
                    <div className="filterbar__datepicker-item-selected">
                        <div
                            className="filterbar__datepicker-content"
                            onClick={() => this.toggleMenu()}
                        >
                            {this.getDatePeriodLabel(startDate, endDate)}
                        </div>
                        <div className="filterbar__datepicker-indicators">
                            <span
                                className="filterbar__datepicker-indicator"
                                onClick={() => this.resetDate()}
                            >
                                <Icon name="reactselectClose"/>
                            </span>
                            <span className="filterbar__datepicker-separator"/>
                            <span
                                className="filterbar__datepicker-indicator"
                                onClick={() => this.toggleMenu()}
                            >
                                <Icon name="reactselectChevron"/>
                            </span>
                        </div>
                    </div>
                    }
                </label>
                {menuOpen &&
                <div className="filterbar__datepicker-dropdown">
                    <div className="filterbar__datepicker-picker">
                        <span className="datepicker__icon"> <Icon name="calendarAlt"/> </span>
                        <DatePicker
                            selected={startDate}
                            selectsStart
                            placeholderText="Anfangsdatum"
                            startDate={startDate}
                            endDate={endDate}
                            onChange={date => this.handleStartDateChange(date)}
                            isClearable
                        />
                    </div>
                    <p> bis </p>
                    <div className="filterbar__datepicker-picker">
                        <span className="datepicker__icon"> <Icon name="calendarAlt"/> </span>
                        <DatePicker
                            selected={endDate}
                            selectsEnd
                            placeholderText="Enddatum"
                            startDate={startDate}
                            endDate={endDate}
                            onChange={date => this.handleEndDateChange(date)}
                            isClearable
                        />
                    </div>
                </div>
                }
            </div>
        );
    }

    getDatePeriodLabel(startDate, endDate) {
        return getDatePeriodLabel(startDate, endDate);
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteSection,
        setFilterOption
    }, dispatch)
});
export default connect(null, mapDispatchToProps)(onClickOutside(DateFilter));

DateFilter.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func
};

DateFilter.defaultProps = {
    disabled: false,
    onSelectionChange: () => false
};
