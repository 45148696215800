import PropType from 'prop-types';
import { getStabilityAndLoadSafetyLabel } from './labels';

export const SectionStabilityAndLoadSafety = ({ specialConstruction, staticsCertificateNecessary, fallback }) => {
    return getStabilityAndLoadSafetyLabel(specialConstruction, staticsCertificateNecessary, fallback);
}

SectionStabilityAndLoadSafety.propTypes = {
    specialConstruction: PropType.bool,
    staticsCertificateNecessary: PropType.bool,
    fallback: PropType.node
}
