import React from 'react';

const Changelog_1_22_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>
                    Dokumentation der Korrespondenz zu Anfragen und Aufträgen
                </h4>
                <p>
                    Zu Anfragen und Aufträgen zugehörige Korrespondenz (z.B. E-Mails und
                    Textdateien) kann ab sofort auf den
                    jeweiligen Detailseiten dokumentiert werden.
                </p>

                <h4>
                    Änderungshistorie für Anfragen und Aufträge
                </h4>
                <p>
                    Veränderungen von Anfragen und Aufträgen über die Zeit lassen sich ab sofort in
                    einer übersichtlichen Änderungshistorie einsehen.
                </p>
                <h4>
                    Anpassung der "Befähigten Person"
                </h4>
                <p>Die Angabe zur "Befähigten Person" entspricht ab sofort demjenigen Mitarbeiter,
                    der die letzte Freigabe eines Gerüstabschnitts erstellt hat.
                </p>

            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>
                        Bessere Rückmeldung und Optimierung des Verhaltens bei abgelaufenen
                        Abonnements
                    </li>
                    <li>Weitere kleine Anpassungen und Verbesserungen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_22_0;
