import * as types from '../../types';

const initialState = {
    tokens: {}
};

export default function mediaReducer(state = initialState, action) {
    switch (action.type) {

        case types.FETCH_MEDIA_TOKEN_SUCCESS:
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    [action.payload.mediaId]: {
                        token: action.payload.token,
                        expires: action.payload.expires
                    }
                }
            };

        default:
            return state;
    }
}
