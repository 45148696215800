import React from 'react';

const LiveBadge = () => {
    return (
        <span className="live-badge">Live</span>
    );
};

export default LiveBadge;



