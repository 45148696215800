import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../../../Common/Icon';
import LightBox from '../../../Common/LightBox';
import { getPrivateMedia, getPublicMedia } from '../../../Media/utils';
import MediaFormModal from '../Modal/MediaFormModal';
import { MediaFormDataPropType } from '../../../Media/properties';
import MediaFieldItem from './MediaFieldItem';

class MediaFieldItemList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showEditModal: false,
            mediaItemToEdit: undefined,
            showMediaPreview: false,
            mediaItemToPreview: undefined
        };

        this.editItem = this.editItem.bind(this);
        this.onCloseEditModal = this.onCloseEditModal.bind(this);
        this.onItemUpdated = this.onItemUpdated.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.previewItem = this.previewItem.bind(this);
        this.onClosePreviewModal = this.onClosePreviewModal.bind(this);
    }

    previewItem(mediaItem) {
        this.setState({
            showMediaPreview: true,
            mediaItemToPreview: mediaItem
        });
    }

    onClosePreviewModal() {
        this.setState({
            showMediaPreview: false,
            mediaItemToPreview: undefined
        });
    }

    editItem(mediaItem) {
        this.setState({
            showEditModal: true,
            mediaItemToEdit: mediaItem
        });
    }

    onCloseEditModal() {
        this.setState({
            showEditModal: false
        });
    }

    /**
     * Replace file in file list w/ updated file
     *
     * @param updatedMediaItem
     */
    onItemUpdated(updatedMediaItem) {

        const mediaList = this.props.media;

        // Update media list
        const updatedMediaList = mediaList.map((mediaItem) => {
            if (mediaItem.id === updatedMediaItem.id) {
                return {
                    ...updatedMediaItem
                };
            }

            return mediaItem;
        });

        // Update state and form value
        this.setState({
            showEditModal: false,
            mediaItemToEdit: undefined
        }, () => {
            // Update value
            this.updateFormValue(updatedMediaList);
        });
    }

    /**
     * Removes media from list
     *
     * @param mediaItemToDelete
     */
    deleteItem(mediaItemToDelete) {

        const mediaList = this.props.media;

        // Update media list
        const newMediaList = mediaList.filter(mediaItem => mediaItem.id !== mediaItemToDelete.id);

        // Update form value
        this.updateFormValue(newMediaList);
    }

    updateFormValue(newMediaList) {
        this.props.onChange(newMediaList);
    }

    render() {
        const { media, emptyLabel, readOnly, isConstructionPlan } = this.props;
        const { showEditModal, mediaItemToEdit, showMediaPreview, mediaItemToPreview } = this.state;

        const hasItems = media && media.length > 0;
        if (!hasItems) {
            return (
                <div className="media-field__items-ct is-empty">
                    <div className="media-field__empty-lbl">
                        {emptyLabel}
                    </div>
                </div>
            );
        }

        const privateMedia = getPrivateMedia(media);
        const hasPrivateMedia = privateMedia.length > 0;

        const publicMedia = getPublicMedia(media);
        const hasPublicMedia = publicMedia.length > 0;

        return (
            <div className="media-field__items-ct">

                {/*
                    Private media
                */}
                {hasPrivateMedia &&
                <div className="media-field__items">
                    {!isConstructionPlan && <div className="media-field__visibility">
                        <span className="media-field__visibility-icn">
                            <Icon name="locked" />
                        </span>
                        <span className="media-field__visibility-lbl">
                            Intern
                        </span>
                    </div>
                    }
                    <div className="media-field__items-items">
                        {privateMedia.map(mediaItem => (
                            <MediaFieldItem
                                key={mediaItem.id}
                                media={mediaItem}
                                onEdit={!readOnly ? this.editItem : null}
                                onDelete={!readOnly ? this.deleteItem : null}
                                onPreview={this.previewItem}
                            />
                        ))}
                    </div>
                </div>
                }

                {/*
                    Public media
                */}
                {hasPublicMedia &&
                <div className="media-field__items media-field__items--public">
                    {!isConstructionPlan && <div className="media-field__visibility">
                        <span className="media-field__visibility-icn">
                            <Icon name="unlocked" />
                        </span>
                        <span className="media-field__visibility-lbl">
                            Öffentlich
                        </span>
                    </div>
                    }
                    <div className="media-field__items-items">
                        {publicMedia.map(mediaItem => (
                            <MediaFieldItem
                                key={mediaItem.id}
                                media={mediaItem}
                                onEdit={!readOnly ? this.editItem : null}
                                onDelete={!readOnly ? this.deleteItem : null}
                                onPreview={this.previewItem}
                            />
                        ))}
                    </div>
                </div>
                }

                {/*
                    Edit modal
                 */}
                {showEditModal &&
                <MediaFormModal
                    media={mediaItemToEdit}
                    onSaved={this.onItemUpdated}
                    onClose={this.onCloseEditModal}
                    isConstructionPlan={isConstructionPlan}
                />
                }

                {/*
                    Light box
                */}
                {showMediaPreview &&
                <LightBox media={mediaItemToPreview} onClose={this.onClosePreviewModal}/>
                }
            </div>
        );
    }

}

MediaFieldItemList.propTypes = {
    media: PropTypes.arrayOf(MediaFormDataPropType).isRequired,
    emptyLabel: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isConstructionPlan: PropTypes.bool
};

MediaFieldItemList.defaultProps = {
    emptyLabel: 'Keine Dokumente vorhanden',
    readOnly: false,
    isConstructionPlan: false
};

export default MediaFieldItemList;
