import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import FieldError from '../../Forms/FieldError';

const internalOnChange = (input, value, setFieldTouched) => {
    // propagate value to form state
    input.onChange(value);

    if (typeof setFieldTouched === 'function') {
        setFieldTouched(input.name, true);
    }
};

const internalValidate = (validate, props) => (value, allValues, meta) => {
    if (!validate || !props) {
        return undefined;
    }

    return validate(value, props.planValue, props.liveValue, allValues, meta, props.required);
};

const asSyncChoiceField = (DiffComponent, { allowNull = false, validate = undefined }) => {
    const SyncChoiceField = ({ name, onChange, setFieldTouched, ...componentProps }) => {
        validate = componentProps.validate ? componentProps.validate : validate;
        return (
            <Field
                name={name}
                allowNull={allowNull}
                validate={internalValidate(validate, componentProps)}
            >
                {
                    ({ input, meta }) => (
                        <div className={`sync-choice-field ${name}-field-wrap ${meta.error ? 'has-error' : ''}`}>
                            <div className="sync-choice-field__comp">
                                <DiffComponent
                                    {...componentProps}
                                    value={input.value}
                                    onChange={(value) => internalOnChange(input, value, setFieldTouched)}
                                    meta={meta}
                                />
                            </div>
                            <div className="sync-choice-field__error">
                                <FieldError meta={meta}/>
                            </div>
                        </div>
                    )
                }
            </Field>
        );
    };

    SyncChoiceField.propTypes = {
        name: PropTypes.string.isRequired,
        required: PropTypes.bool
    };

    return SyncChoiceField;
};

asSyncChoiceField.propTypes = {
    DiffComponent: PropTypes.component
};

export default asSyncChoiceField;
