import * as types from '../../types';

const initialState = {
    addSiteError: null,
    addNoteError: null,
    sitedata: {},
    isFetchingsite: false
};

export default function siteReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_SITE_SUCCESS:
            return {
                ...state
            };
        case types.ADD_SITE_FAILURE:
            return {
                ...state,
                addsiteError: action.error
            };

        case types.FETCH_SITE:
            return {
                ...state,
                isFetchingsite: true
            };

        case types.FETCH_SITE_SUCCESS:
            return {
                ...state,
                isFetchingsite: false,
                sitedata: action.payload
            };

        case types.FETCH_SITE_FAILURE:
            return {
                ...state,
                isFetchingsite: false,
                errors: action.payload
            };
        default:
            return state;
    }
}
