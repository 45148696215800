import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Linkify from 'react-linkify';
import CollapseToggleLink from './Collapse/CollapseToggleLink';
import NotAvailablePlaceholder from './NotAvailablePlaceholder';

const formatLineBreaks = text => (text.split('\n')
        .map((item, key) => (<span key={key}>{item}<br /></span>))
);

const renderCollapsibleText = (text, isCollapsed, textLimit) => {

    if (!isCollapsed || text.length < textLimit) {
        return formatLineBreaks(text);
    }

    const ellipsis = '...';

    return formatLineBreaks(text.substring(0, textLimit - ellipsis.length) + ellipsis);
};


const renderCollapsibleContent = (text, isCollapsed, toggleCollapsed, textLimit) => (
    <Fragment>
        {renderCollapsibleText(text, isCollapsed, textLimit)}
        {text.length > textLimit && <CollapseToggleLink
            onToggle={() => toggleCollapsed(!isCollapsed)}
            isCollapsed={isCollapsed}
            hideIcon
            expandLabel="mehr anzeigen"
            collapseLabel="weniger anzeigen"
        />
        }
    </Fragment>
);


const renderRegularContent = (text) => (formatLineBreaks(text));

const MultiLineText = ({ text, emptyPlaceholder, isCollapsible, textLimit }) => {

    const [collapsed, toggleCollapsed] = useState(true);

    if (!text) {
        return emptyPlaceholder || null;
    }

    if (typeof text !== 'string') {
        console.error('Text must be of type string');
        return null;
    }

    return (
        <div className="multiline-text">
            <Linkify properties={{ target: '_blank' }}>
                {isCollapsible ? renderCollapsibleContent(text, collapsed, toggleCollapsed, textLimit) : renderRegularContent(text)}
            </Linkify>
        </div>
    );
};

export default MultiLineText;

MultiLineText.propTypes = {
    text: PropTypes.string,
    emptyPlaceholder: PropTypes.node,
    isCollapsible: PropTypes.bool,
    textLimit: PropTypes.number
};

MultiLineText.defaultProps = {
    text: undefined,
    isCollapsible: false,
    emptyPlaceholder: <NotAvailablePlaceholder />,
    textLimit: 400
};
