import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';

const EmptyTableHint = ({
    numColumns, title, children, layout
}) => {
    const wrapperClasses = ['table-empty-hint__row'];

    if (layout === 'center') {
        wrapperClasses.push('table-empty-hint__row--center');
    }
    return (
        <tr className={wrapperClasses.join(' ')}>
            <td colSpan={numColumns} className="table-empty-hint__column">
                <div className="table-empty-hint">
                    <h2 className="table-empty-hint__hd"><span className="table-empty-hint__icon"><Icon name="info" /></span> {title}</h2>
                    {!children ? null :
                        <div className="table-empty-hint__bd">
                            {children}
                        </div>
                    }
                </div>
            </td>
        </tr>
    );
};

export default EmptyTableHint;

EmptyTableHint.propTypes = {
    numColumns: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    layout: PropTypes.string
};

EmptyTableHint.defaultProps = {
    layout: ''
};

