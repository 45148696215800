import _ from 'lodash';
import { isIdenticalValue, isObjectIdenticalValue } from './equals';

export const DefaultErrorMessageSingle = 'Bitte treffen Sie ihre Auswahl.';
export const DefaultErrorMessageList = 'Bitte vervollständigen Sie ihre Auswahl.';

/**
 * A choice is regarded as being 'complete' when all keys from plan and live
 * are present in the final value and are not 'undefined'
 *
 * @param {string} errorMessage
 * @return {function} - the validation function
 */
export const validateListChoiceComplete = (errorMessage = DefaultErrorMessageList) =>
    (value, planValue, liveValue) => {

        if (isIdenticalValue(planValue, liveValue)) {
            return;
        }

        if (typeof planValue === 'object' || typeof liveValue === 'object') {

            if (!value) {
                return errorMessage;
            }

            let allKeys = [];
            if (planValue) {
                allKeys = [...allKeys, ...Object.keys(planValue)];
            }
            if (liveValue) {
                allKeys = [...allKeys, ...Object.keys(liveValue)];
            }

            allKeys = _.uniq(allKeys);

            for (let i = 0; i < allKeys.length; i++) {
                const key = allKeys[i];
                if ((!liveValue || !planValue) || !isIdenticalValue(planValue[key], liveValue[key])) {
                    if (value[key] === undefined) {
                        return errorMessage;
                    }
                }
            }
        }
    };

/**
 * A single choice is regarded as being 'complete' when the user has chosen any value
 *
 * @param {string} errorMessage
 * @param {function} identityFn
 * @return {function} - the validation function
 */
export const validateSingleChoiceComplete =
    (errorMessage = DefaultErrorMessageSingle, identityFn = isIdenticalValue) =>
        (value, planValue, liveValue) => {

            if (planValue === undefined || liveValue === undefined) {
                return;
            }

            if (identityFn(planValue, liveValue)) {
                return;
            }

            if (value === undefined) {
                return errorMessage;
            }
        };

export const validateSingleObjectChoiceComplete =
    (errorMessage = DefaultErrorMessageSingle) =>
        (value, planValue, liveValue) => {

            if (planValue === undefined || liveValue === undefined) {
                return;
            }

            if (isObjectIdenticalValue(planValue, liveValue)) {
                return;
            }

            if (value === undefined) {
                return errorMessage;
            }
        };
