import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import connect from 'react-redux/lib/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../redux/modules/error/action';
import { fetchJobCorrespondence, updateJob } from '../../../redux/modules/job/actions';
import JobCorrespondenceSection from './JobCorrespondenceSection';
import JobCorrespondenceUploadModal from './JobCorrespondenceUploadModal';

class JobCorrespondence extends Component {

    constructor(props) {
        super(props);

        let { job } = props || {};
        let { correspondence } = job || {};

        let isInitialized = !!correspondence;
        let isLoading = !isInitialized;

        this.state = {
            isInitialized,
            isLoading,
            isUpdating: false,
            showUploadModal: false,
            items: correspondence
        };

        this.onUpdate = this.onUpdate.bind(this);
        this.deleteCorrespondence = this.deleteCorrespondence.bind(this);
        this.toggleUploadModal = this.toggleUploadModal.bind(this);

        if (!isInitialized) {
            this.initialize();
        }
    }

    initialize() {
        this.reload()
            .then(() => {
                this.setState({
                    isInitialized: true
                });
            });
    }

    reload() {
        this.setState({
            isLoading: true
        });

        const { job, actions: { fetchJobCorrespondence } } = this.props;

        return fetchJobCorrespondence(job.id)
            .then(correspondence => {
                this.setState({
                    items: correspondence
                });
            })
            .catch((error) => {
                showRequestError('Korrespondenz konnte nicht geladen werden', error);
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                });
            });
    }

    toggleUploadModal() {
        this.setState({
            showUploadModal: !this.state.showUploadModal
        });
    }

    onUpdate() {
        this.reload();
    }

    handleFetchCorrespondenceError(error) {
        showRequestError('Korrespondenz konnte nicht geladen werden', error);
    }

    deleteCorrespondence(correspondenceIdToDelete) {

        // Nothing to do here
        if (!correspondenceIdToDelete) {
            return;
        }

        this.updateCorrespondence(existingCorrespondence => {
            const existingCorrespondenceIds = existingCorrespondence ? existingCorrespondence.map(correspondence => correspondence.id) : [];

            return existingCorrespondenceIds.filter(correspondence => correspondence !== correspondenceIdToDelete);
        }, 'Korrespondenz wurde entfernt', 'Korrespondenz konnte nicht entfernt werden')
            .catch(error => {
                this.handleFetchCorrespondenceError(error);

                this.setState({
                    isUpdating: false
                });
            });

    }

    addCorrespondence(newCorrespondenceItems) {

        // No need to update if nothing was added
        if (!newCorrespondenceItems || !newCorrespondenceItems.length) {
            return;
        }

        this.updateCorrespondence(existingCorrespondence => {
            const existingCorrespondenceIds = existingCorrespondence ? existingCorrespondence.map(correspondence => correspondence.id) : [];
            const addedCorrespondenceIds = newCorrespondenceItems.map(newCorrespondence => newCorrespondence.id);
            existingCorrespondenceIds.push(...addedCorrespondenceIds);

            return existingCorrespondenceIds;
        }, 'Korrespondenz erfolgreich gespeichert', 'Korrespondenz konnte nicht gespeichert werden')
            .catch(error => {
                this.handleFetchCorrespondenceError(error);

                this.setState({
                    isUpdating: false
                });
            });

    }

    async updateCorrespondence(updateFnc, successMessage, errorMessage) {

        const { job, actions: { fetchJobCorrespondence, updateJob } } = this.props;

        this.setState({
            isUpdating: true
        });

        // 1) Load current correspondence fresh from the server
        const existingCorrespondence = await fetchJobCorrespondence(job.id);

        // 2) Add the updated correspondence items
        const updatedCorrespondenceIds = existingCorrespondence ? updateFnc(existingCorrespondence) : [];

        // 3) Save everything
        updateJob(job.id, { correspondenceIds: updatedCorrespondenceIds })
            .then(() => {
                toast.success(successMessage, { autoClose: 2500 });
                this.setState({
                    isUpdating: false
                }, () => this.reload());
            })
            .catch((error) => {
                showRequestError(errorMessage, error);
            });

    }

    render() {
        const { isLoading, isUpdating, isInitialized, items, showUploadModal } = this.state;
        const { disableManipulation } = this.props;
        return (
            <Fragment>
                <JobCorrespondenceSection
                    isLoading={isLoading}
                    disableManipulation={disableManipulation}
                    isInitialized={isInitialized}
                    isUpdating={isUpdating}
                    items={items}
                    onClickAdd={this.toggleUploadModal}
                    onCorrespondenceUpdate={this.onUpdate}
                    onCorrespondenceDelete={this.deleteCorrespondence}
                />
                {showUploadModal &&
                <JobCorrespondenceUploadModal
                    onUploaded={(newCorrespondence) => this.addCorrespondence(newCorrespondence)}
                    onClose={this.toggleUploadModal}
                />}
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchJobCorrespondence,
        updateJob
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(JobCorrespondence);


JobCorrespondence.propType = {
    job: PropTypes.object,
    disableManipulation: PropTypes.bool
};

JobCorrespondence.defaultProps = {
    job: null
};
