import { JobType } from '../Components/Job/properties';

const allowedFileTypes = [ '.obj', '.dwg', '.dxf', '.stp', '.fbx', '.pdf', '.jpeg', '.jpg', '.ifc', '.step', '.png' ];

const jobErrors = {
    status: {
        is_valid_enum: 'Der Auftragsstatus ist ungültig'
    },
    name: {
        required: 'Bitte geben Sie einen Auftragsnamen an'
    },
    customerId: {
        is_valid_u_u_i_d: 'Die Kundendaten sind ungültig'
    },
    siteId: {
        is_valid_u_u_i_d: 'Die Baustellendaten sind ungültig'
    },
    sectionIds: {
        array: 'Bitte geben Sie gültige Gerüstabschnittsdaten ein'
    },
    attachmentIds: {
        array: 'Der angegebene Anhang ist fehlerhaft'
    }
};

const registrationErrors = {
    email: {
        email: 'Bitte geben Sie eine gültige E-Mail Adresse an'
    }
};

const forgotPasswordErrors = {
    email: {
        email: 'Bitte geben Sie eine gültige E-Mail Adresse an'
    }
};

const addNewUserErrors = {
    email: {
        email: 'Bitte geben Sie eine gültige E-Mail Adresse an'
    }
};

const scaffoldingErrors = {
    siteId: {
        required: 'Bitte geben Sie eine Baustelle an'
    },
    name: {
        required: 'Bitte geben Sie einen Gerüstnamen an'
    }
};

const sectionErrors = {
    status: {
        required: 'Bitte geben Sie einen Status an'
    },
    name: {
        required: 'Bitte geben Sie einen Gerüstabschnittsname an'
    },
    scaffoldingId: {
        required: 'Bitte wählen Sie ein Gerüst aus'
    },
    purposes: {
        array: 'Bitte wählen Sie einen Verwendungszweck'
    }
};

const fileUploadErrors = {
    'utilization-plan': {
        is_valid_file_type: `Dateityp wird nicht unterstützt. Bitte nur Dateien mit folgenden Dateiendungen hochladen: ${allowedFileTypes.join(', ')}`
    },
    proofOfStability: {
        is_valid_file_type: `Dateityp wird nicht unterstützt. Bitte nur Dateien mit folgenden Dateiendungen hochladen: ${allowedFileTypes.join(', ')}`
    },
    attachment: {
        is_valid_file_type: `Dateityp wird nicht unterstützt. Bitte nur Dateien mit folgenden Dateiendungen hochladen: ${allowedFileTypes.join(', ')}`
    },
    anchorProtocol: {
        is_valid_file_type: `Dateityp wird nicht unterstützt. Bitte nur Dateien mit folgenden Dateiendungen hochladen: ${allowedFileTypes.join(', ')}`
    },
    document: {
        is_valid_file_type: `Dateityp wird nicht unterstützt. Bitte nur Dateien mit folgenden Dateiendungen hochladen: ${allowedFileTypes.join(', ')}`
    }
};

export function getErrorId(errorData) {
    return errorData.error;
}

function getFieldErrorMessage(fieldError, type) {
    switch ( type ) {
        case 'job':
            return jobErrors[fieldError.field][fieldError.error];
        case 'file':
            return fileUploadErrors[fieldError.field][fieldError.error];
        case 'scaffolding':
            return scaffoldingErrors[fieldError.field][fieldError.error];
        case 'section':
            return sectionErrors[fieldError.field][fieldError.error];
        case 'register':
            return registrationErrors[fieldError.field][fieldError.error];
        case 'forgotpassword':
            return forgotPasswordErrors[fieldError.field][fieldError.error];
        case 'addnewuser':
            return addNewUserErrors[fieldError.field][fieldError.error];
        default:
            return fieldError.message;
    }
}

export function isValidationError(errorId) {
    return errorId === 'validation_error';
}

export function getValidationError(payload, type) {
    if ( !payload ) {
        return false;
    }

    let errors = {};
    payload.forEach((fieldError) => {
        errors = {
            ...errors,
            [fieldError.field]: getFieldErrorMessage(fieldError, type)
        };
    });

    return errors;
}

function handleJobError(errorData) {
    switch ( errorData.error ) {
        case 'media_not_found':
            return {
                attachments: 'Der angegebene Anhang kann nicht gefunden werden.'
            };

        case 'section_not_found':
            return {
                sectionIds: 'Eine der angegebenen Gerüstabschnitte kann nicht gefunden werden.'
            };

        case 'site_not_found':
            return {
                siteId: 'Die angegebene Baustelle kann nicht gefunden werden.'
            };

        default:
            return {
                customerId: 'Der angegebene Kunde kann nicht gefunden werden.'
            };
    }
}

function handleScaffoldingLockedError(errorData) {

    if ( !errorData.error ) {
        return;
    }

    switch ( errorData.error ) {
        case 'locked_by_job':
            return {
                error: 'Das Gerüst kann nicht entfernt werden, da es derzeit mit einem oder mehreren Anfragen oder Aufträgen verknüpft ist. Entfernen Sie die Verknüpfungen, um das Gerüst zu löschen.'
            };

        case 'locked_by_inspection':
            return {
                error: 'Das Gerüst kann nicht entfernt werden, da es Gerüstabschnitte enthält, die mit Prüfungen verknüpft sind.'
            };

        case 'locked_by_construction_diary_entry':
            return {
                error: 'Das Gerüst kann nicht entfernt werden, da es mit einem oder mehreren Bautagebucheinträgen verknüpft ist.'
            };

        default:
            return {
                error: 'Das Gerüst kann nicht entfernt werden.'
            };
    }
}


function handleSiteLockedError(errorData) {

    if ( !errorData.error ) {
        return;
    }

    switch ( errorData.error ) {
        case 'locked_by_job':
            return {
                error: 'Die Baustelle kann nicht entfernt werden, da sie derzeit mit einem oder mehreren Anfragen oder Aufträgen verknüpft ist. Entfernen Sie die Verknüpfungen, um die Baustelle zu löschen.'
            };

        case 'locked_by_inspection':
            return {
                error: 'Die Baustelle kann nicht entfernt werden, da sie Gerüstabschnitte enthält, die mit Prüfungen verknüpft sind.'
            };

        case 'locked_by_construction_diary_entry':
            return {
                error: 'Die Baustelle kann nicht entfernt werden, da sie mit einem oder mehreren Bautagebucheinträgen verknüpft ist.'
            };

        default:
            return {
                error: 'Die Baustelle kann nicht entfernt werden.'
            };
    }
}


function handleSectionLockedError(errorData) {

    if ( !errorData.error ) {
        return;
    }

    switch ( errorData.error ) {
        case 'locked_by_job':
            return {
                error: 'Der Gerüstabschnitt kann nicht entfernt werden, da er derzeit mit einem oder mehreren Anfragen oder Aufträgen verknüpft ist. Entfernen Sie die Verknüpfungen, um den Gerüstabschnitt zu löschen.'
            };
        case 'locked_by_inspection':
            return {
                error: 'Der Gerüstabschnitt kann nicht entfernt werden, da bereits Prüfungen durchgeführt wurden.'
            };

        case 'locked_by_construction_diary_entry':
            return {
                error: 'Der Gerüstabschnitt kann nicht entfernt werden, da er mit einem oder mehreren Bautagebucheinträgen verknüpft ist.'
            };

        default:
            return {
                error: 'Der Gerüstabschnitt kann nicht entfernt werden.'
            };
    }
}

function handleSignUpError(errorData) {
    switch ( errorData.error ) {
        case 'email_taken':
            return {
                email: 'Nutzerdaten: Die angegebene E-Mail Adresse ist bereits vergeben.'
            };

        case 'invalid_email_recipient':
            return {
                email: 'Bitte geben Sie eine gültige E-Mail Adresse an.'
            };

        default:
            return {
                email: 'Die angegebene E-Mail Adresse ist bereits vergeben.'
            };
    }
}

function handleUserManagementError(errorData) {
    switch ( errorData.error ) {

        case 'invalid_email_recipient':
            return {
                email: 'Bitte geben Sie eine gültige E-Mail Adresse an.'
            };

        default:
            return {
                email: 'Die eingegebenen Nutzer-Daten sind fehlerhaft. Bitte versuchen Sie es erneut.'
            };
    }
}


function handleLoginError(errorData) {
    switch ( errorData.error ) {
        case 'invalid_credentials':
            return {
                credentials: 'Wir konnten die Kombination aus E-Mail Adresse und Passwort nicht finden. Bitte versuchen Sie es erneut.'
            };

        case 'user_subscription_expired':
            return {
                credentials: 'Ihr Abonnement ist abgelaufen. Bitte kontaktieren Sie den Eigentümer Ihres Unternehmenskontos, um ihr Abonnement zu verlängern.'
            };

        case 'account_subscription_expired':
            return {
                credentials: 'Das Abonnement Ihres Unternehmenskontos ist abgelaufen. Bitte kontaktieren Sie den Eigentümer Ihres Unternehmenskontos, um ihr Abonnement zu verlängern.'
            };

        case 'user_inactive':
            return {
                credentials: 'Ihr Konto wurde noch nicht freigeschaltet. Bitte schließen Sie den Registrierungsvorgang über die Bestätigungsemail ab oder kontaktieren Sie unseren Support.'
            };
        default:
            return {
                credentials: 'Die eingegebenen Login-Daten sind fehlerhaft. Bitte versuchen Sie es erneut.'
            };
    }
}

function handleUpdatePersonalData(errorData) {
    switch ( errorData.error ) {
        case 'wrong_password':
            return {
                error: 'Das eingegebene Passwort ist falsch. Bitte versuchen Sie es erneut.'
            };

        default:
            return {
                error: 'Die eingegebenen Login-Daten sind fehlerhaft. Bitte versuchen Sie es erneut.'
            };
    }
}

function handleSiteError(errorData) {
    if ( !errorData.error ) {
        return;
    }

    switch ( errorData.error ) {
        case 'invitation_same_account':
            return {
                message: `Der Nutzer mit der E-Mail Adresse "${errorData.payload.email}" gehört Ihrem SCAFFEYE-Konto an und hat damit bereits Zugriff auf diese Baustelle. Bitte beachten Sie, dass Sie nur Nutzer einladen können, die nicht Ihrem SCAFFEYE-Konto angehören.`
            };
        default:
            return;
    }
}

function handleNewUserInvitationError(errorData) {
    if ( !errorData.error ) {
        return;
    }

    switch ( errorData.error ) {
        case 'invalid_password_reset_token':
            return {
                message: 'Ihre Einladung zu Scaffeye scheint ungültig zu sein. Bitte kontaktieren Sie den Inhaber Ihres Unternehmenskontos, um eine neue Einladung zu erhalten.'
            };
        default:
            return;
    }
}


function handleResetPasswordError(errorData) {
    if ( !errorData.error ) {
        return;
    }

    switch ( errorData.error ) {
        case 'invalid_password_reset_token':
            return {
                message: 'Der Link zum Zurücksetzen Ihres Passworts scheint ungültig zu sein.'
            };
        default:
            return;
    }
}

function handleJobLockedError(errorData, type) {

    if ( errorData.error !== 'GraphQLError' ) {
        return;
    }

    const isJob = () => type === JobType.Job;
    const typeName = isJob() ? 'Auftrag' : 'Anfrage';

    switch ( errorData.errorCode ) {
        case 'locked_by_construction_plan':
            return {
                error: `${typeName} kann nicht entfernt werden, da ein Bauplan hinterlegt wurde.`
            };

        default:
            return {
                error: `${typeName} kann nicht entfernt werden.`
            };
    }
}

export function getSemanticValidationError(errorData, type) {
    if ( !errorData ) {
        return false;
    }
    switch ( type ) {
        case 'job':
            return handleJobError(errorData);
        case 'register':
            return handleSignUpError(errorData);
        case 'addnewuser':
            return handleUserManagementError(errorData);
        case 'login':
            return handleLoginError(errorData);
        case 'updatePersonalData':
            return handleUpdatePersonalData(errorData);
        case 'site':
            return handleSiteError(errorData);
        case 'newUserAcceptsInvitation':
            return handleNewUserInvitationError(errorData);
        case 'resetPassword':
            return handleResetPasswordError(errorData);
        case 'sectionLocked':
            return handleSectionLockedError(errorData);
        case 'siteLocked':
            return handleSiteLockedError(errorData);
        case 'scaffoldingLocked':
            return handleScaffoldingLockedError(errorData);
        default:
            return false;
    }
}

function getGraphQLError(error, type){

    if(!error || error.error !== 'GraphQLError'){
        return false;
    }

    switch(type){
        case 'jobLocked':
            return handleJobLockedError(error, JobType.Job);
        case 'requestLocked':
            return handleJobLockedError(error, JobType.Request);
        default:
            return false;
    }
}

export default class ErrorService {

    static handleBadRequestError(error, type) {

        return error.getData()
                    .then((errorData) => {

                        const errorId = getErrorId(errorData);

                        if ( isValidationError(errorId) ) {
                            return getValidationError(errorData.payload, type);
                        }
                        return getSemanticValidationError(errorData, type);
                    });
    }

    static handleEntityTooLargeError(error, type) {
        return error.getData()
                    .then(errorData => (getSemanticValidationError(errorData, type)));
    }

    static handleConflictError(error, type) {
        return error.getData()
                    .then(errorData => (getSemanticValidationError(errorData, type)));
    }

    static handleUnauthorizedError(error, type) {
        return error.getData()
                    .then(errorData => (getSemanticValidationError(errorData, type)));
    }

    static handleLockedByError(error, type) {
        return error.getData()
                    .then(errorData => (getSemanticValidationError(errorData, type)));
    }

    static handleGraphQLError(error, type){
        return new Promise(resolve => {
            resolve(getGraphQLError(error, type));
        });
    }

}
