import React, { Component } from 'react';
import PropTypes from 'prop-types';

const BannerTypes = {
    warning: 'warning'
};

class Banner extends Component {

    render() {
        const { children, type } = this.props;

        const classes = ['banner'];

        switch (type){
            case BannerTypes.warning:
                classes.push('banner--warning');
                break;
            default:
                return null;
        }

        return (
            <div className={classes.join(' ')}>
                {children}
            </div>

        );
    }
}
export default Banner;

Banner.propTypes = {
    type: PropTypes.oneOf(['warning']).isRequired
};

