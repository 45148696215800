import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getModelReferenceOptionsFromConstructionPlanSite } from '../../../Forms/Media/utils';
import { getGroupedConstructionPlanMedia } from '../../utils';
import ConstructionPlanFormButton from '../Common/ConstructionPlanFormButton';
import ConstructionPlanFormGroup from '../Common/ConstructionPlanFormGroup';
import ConstructionPlanFormItemList from '../Common/ConstructionPlanFormItemList';
import { ConstructionPlanFormSitePropType } from '../propTypes';
import ConstructionPlanFormMediaGroup from './ConstructionPlanFormMediaGroup';
import MediaSectionActions from './ConstructionPlanMediaActions';
import ConstructionPlanMediaFormModal from './Modal/ConstructionPlanMediaFormModal';

class ConstructionPlanFormMedia extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onChanged = this.onChanged.bind(this);
    }

    showModal() {
        this.toggleModal(true);
    }

    closeModal() {
        this.toggleModal(false);
    }

    toggleModal(show) {
        this.setState({
            showModal: show
        });
    }

    getMediaFormReferenceOptions() {
        return getModelReferenceOptionsFromConstructionPlanSite(this.props.site, this.props.referenceData);
    }

    onChanged(updatedSite) {
        this.props.onChange(updatedSite);
        this.closeModal();
    }

    render() {
        const { site, referenceData, resolvedMedia, disabled, readOnly } = this.props;
        const { showModal } = this.state;

        const wrapperClasses = ['constructionPlannerMedia'];
        if (disabled) {
            wrapperClasses.push('constructionPlannerMedia--disabled');
        }

        const groupedConstructionPlanMedia = getGroupedConstructionPlanMedia(site, referenceData, resolvedMedia);

        const isEmpty = !groupedConstructionPlanMedia.length;

        return (
            <div className={wrapperClasses.join(' ')}>
                <ConstructionPlanFormGroup
                    title="Medien"
                    dashedBorder
                    readOnly={readOnly}
                    showActions={!isEmpty && !readOnly}
                    actions={<MediaSectionActions onAdd={this.showModal} onEdit={this.showModal} />}
                >
                    <ConstructionPlanFormItemList
                        emptyComponent={
                             <ConstructionPlanFormButton
                                block
                                label="Dokumente hinzufügen"
                                icon="add"
                                onClick={this.showModal}
                            />
                        }
                        listItems={groupedConstructionPlanMedia}
                    >
                        {
                            (mediaGroupData, index) => (
                                <ConstructionPlanFormMediaGroup
                                    key={index}
                                    mediaGroupData={mediaGroupData}
                                />
                            )
                        }
                    </ConstructionPlanFormItemList>

                    {showModal &&
                        <ConstructionPlanMediaFormModal
                            onChange={this.onChanged}
                            onClose={this.closeModal}
                            site={site}
                            referenceData={referenceData}
                            resolvedMedia={resolvedMedia}
                            referenceOptions={this.getMediaFormReferenceOptions()}
                        />
                    }
                </ConstructionPlanFormGroup>
                {disabled && <div className="input-hint">Ändern Sie die Planung Ihrer Baustelle, Gerüste oder Gerüstabschnitte auf "Konfiguration anpassen", um Medien hochzuladen</div>}
            </div>
        );
    };
}

ConstructionPlanFormMedia.propTypes = {
    site: ConstructionPlanFormSitePropType,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    resolvedMedia: PropTypes.object,
    referenceData: PropTypes.object,
    readOnly: PropTypes.bool
};

ConstructionPlanFormMedia.defaultProps = {
    disabled: false,
    onChange: () => false,
    readOnly: false
};

export default ConstructionPlanFormMedia;
