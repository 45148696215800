import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFormButton from '../Common/ConstructionPlanFormButton';

const ConstructionPlanFormModelModeActions = (props) => {
    const {
        onAddNew,
        addNewLabel,
        onAddExisting,
        addExistingLabel,
        modelsLeftToReference
    } = props;

    return (
        <div className="construction-plan__mode-mode-actions">
            <ConstructionPlanFormButton
                onClick={onAddNew}
                label={addNewLabel}
                icon="add"
                block={!modelsLeftToReference}
            />
            {modelsLeftToReference &&
            <ConstructionPlanFormButton
                onClick={onAddExisting}
                label={addExistingLabel}
                icon="plannerExisting"
            />
            }
        </div>
    );
};

ConstructionPlanFormModelModeActions.propTypes = {
    addNewLabel: PropTypes.string.isRequired,
    onAddNew: PropTypes.func.isRequired,
    addExistingLabel: PropTypes.string.isRequired,
    onAddExisting: PropTypes.func.isRequired,
    modelsLeftToReference: PropTypes.bool
};

ConstructionPlanFormModelModeActions.defaultProps = {
    modelsLeftToReference: false
};

export default ConstructionPlanFormModelModeActions;
