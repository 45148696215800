import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Icon from '../Common/Icon';
import IfUser from '../Common/IfUser';
import DiaryEntryModal from '../ConstructionDiary/ConstructionDiaryEntryModal';
import CustomerFormModal from '../Customers/Form/CustomerFormModal';
import JobFormModal from '../Job/Form/JobFormModal';
import ScaffoldingFormModal from '../Scaffoldings/Form/ScaffoldingFormModal';
import SectionFormModal from '../Sections/Form/SectionFormModal';
import SiteFormModal from '../Sites/Form/SiteFormModal';

export const ModalTypes = {
    JobRequest: 'jobRequest',
    Job: 'job',
    Customer: 'customer',
    Site: 'site',
    Scaffolding: 'scaffolding',
    Section: 'section',
    DiaryEntry: 'diary-entry',
    ConstructionPlan: 'construction-plan'
};
Object.freeze(ModalTypes);

const linkGroups = [
    {
        type: 'jobManagement',
        label: 'Auftragsverwaltung',
        requiredPermissions: ['create-jobs', 'create-customers'],
        links: [
            {
                type: ModalTypes.JobRequest,
                label: 'Anfrage',
                permission: 'create-jobs'
            },
            {
                type: ModalTypes.Job,
                label: 'Auftrag',
                permission: 'create-jobs'
            },
            {
                type: ModalTypes.Customer,
                label: 'Kunde',
                permission: 'create-customers'
            }
        ]
    },
    {
        type: 'siteManagement',
        label: 'Baustellenverwaltung',
        requiredPermissions: ['create-sites', 'create-scaffoldings', 'create-sections'],
        links: [
            {
                type: ModalTypes.Site,
                label: 'Baustelle',
                permission: 'create-sites'
            },
            {
                type: ModalTypes.Scaffolding,
                label: 'Gerüst',
                permission: 'create-scaffoldings'
            },
            {
                type: ModalTypes.Section,
                label: 'Gerüstabschnitt',
                permission: 'create-sections'
            }
        ]
    },
    {
        type: 'documentation',
        label: 'Dokumentation',
        requiredPermissions: ['create-construction-diary-entries'],
        links: [
            {
                type: ModalTypes.DiaryEntry,
                label: 'Bautagebuch-Eintrag',
                permission: 'create-construction-diary-entries'
            }
        ]
    }
];


class AddResourceDropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            modals: {}
        };

        this.onJobCreated = this.onJobCreated.bind(this);
        this.onCustomerCreated = this.onCustomerCreated.bind(this);
        this.onSiteCreated = this.onSiteCreated.bind(this);
        this.onScaffoldingCreated = this.onScaffoldingCreated.bind(this);
        this.onSectionCreated = this.onSectionCreated.bind(this);
        this.onDiaryEntryCreated = this.onDiaryEntryCreated.bind(this);
        this.onJobRequestCreated = this.onJobRequestCreated.bind(this);
    }

    /* Used by wrapping component */
    handleClickOutside() {
        if (this.state.listOpen) {
            this.setState({
                listOpen: false
            });
        }
    }

    onJobCreated(jobId, createConstructionPlanNext) {
        this.closeModal(ModalTypes.Job, () => {

            const routeState = {
                jobId
            };

            if (createConstructionPlanNext) {
                routeState.openModal = {
                    type: ModalTypes.ConstructionPlan
                };
            }

            this.props.history.push(`/jobs/${jobId}`, routeState);
        });
    }

    onJobRequestCreated(jobId, createConstructionPlanNext) {
        this.closeModal(ModalTypes.JobRequest, () => {

            const routeState = {
                jobId
            };

            if (createConstructionPlanNext) {
                routeState.openModal = {
                    type: ModalTypes.ConstructionPlan
                };
            }

            this.props.history.push(`/requests/${jobId}`, routeState);
        });
    }

    onCustomerCreated(customerId) {
        this.closeModal(ModalTypes.Customer, () => {
            this.props.history.push(`/customers/${customerId}`, { customerId });
        });
    }

    onDiaryEntryCreated(diaryEntryId) {
        this.closeModal(ModalTypes.DiaryEntry, () => {
            this.props.history.push(`/diary/${diaryEntryId}`, { diaryEntryId });
        });
    }

    onSiteCreated(siteId, createScaffoldingNext) {
        this.closeModal(ModalTypes.Site, () => {
            const routeState = {
                siteId
            };
            if (createScaffoldingNext) {
                routeState.openModal = {
                    type: ModalTypes.Scaffolding
                };
            }

            this.props.history.push(`/sites/${siteId}`, routeState);
        });
    }

    onScaffoldingCreated(scaffoldingId, createSectionNext) {
        this.closeModal(ModalTypes.Scaffolding, () => {
            const routeState = {
                scaffoldingId
            };
            if (createSectionNext) {
                routeState.openModal = {
                    type: ModalTypes.Section
                };
            }
            this.props.history.push(`/scaffoldings/${scaffoldingId}`, routeState);
        });
    }

    onSectionCreated(sectionId) {
        this.closeModal(ModalTypes.Section, () => {
            this.props.history.push(`/sections/${sectionId}`, { sectionId });
        });
    }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }

    openModal(type, payload = null) {
        this.setState({
            ...this.state,
            modals: {
                ...this.state.modals,
                [type]: {
                    open: true,
                    payload
                }
            }
        }, () => this.toggleList());
    }

    closeModal(type, fn) {
        this.setState({
            ...this.state,
            modals: {
                ...this.state.modals,
                [type]: {
                    open: false
                }
            }
        }, fn);
    }

    renderJobModal() {
        return (
            <JobFormModal
                onCreated={this.onJobCreated}
                onClose={() => this.closeModal(ModalTypes.Job)}
            />
        );
    }

    renderJobRequestModal() {
        return (
            <JobFormModal
                onCreated={this.onJobRequestCreated}
                onClose={() => this.closeModal(ModalTypes.JobRequest)}
                isRequest
            />
        );
    }

    renderCustomerModal() {
        return (
            <CustomerFormModal
                onCreated={this.onCustomerCreated}
                onClose={() => this.closeModal(ModalTypes.Customer)}
            />
        );
    }

    renderDiaryEntryModal() {
        return (
            <DiaryEntryModal
                onCreated={this.onDiaryEntryCreated}
                onClose={() => this.closeModal(ModalTypes.DiaryEntry)}
            />
        );
    }

    renderSiteModal() {
        return (
            <SiteFormModal
                onCreated={this.onSiteCreated}
                onClose={() => this.closeModal(ModalTypes.Site)}
            />
        );
    }

    renderScaffoldingModal() {
        return (
            <ScaffoldingFormModal
                onCreated={this.onScaffoldingCreated}
                onClose={() => this.closeModal(ModalTypes.Scaffolding)}
            />
        );
    }

    renderSectionModal() {
        return (
            <SectionFormModal
                onCreated={this.onSectionCreated}
                onClose={() => this.closeModal(ModalTypes.Section)}
            />
        );
    }

    renderPopup(type) {
        switch (type) {
            case ModalTypes.Job:
                return this.renderJobModal();
            case ModalTypes.JobRequest:
                return this.renderJobRequestModal();
            case ModalTypes.Customer:
                return this.renderCustomerModal();
            case ModalTypes.Site:
                return this.renderSiteModal();
            case ModalTypes.Scaffolding:
                return this.renderScaffoldingModal();
            case ModalTypes.Section:
                return this.renderSectionModal();
            case ModalTypes.DiaryEntry:
                return this.renderDiaryEntryModal();
            default:
                return null;
        }
    }

    renderModals() {
        const { modals } = this.state;
        const keys = Object.keys(modals);
        if (!keys) {
            return null;
        }

        return keys.map((popupKey) => {
            if (modals[popupKey] && modals[popupKey].open) {
                return (
                    <React.Fragment key={popupKey}>
                        {this.renderPopup(popupKey)}
                    </React.Fragment>
                );
            }
            return null;
        });
    }

    renderDropDownContent(linkGroups) {

        return linkGroups.map((group) => {

            const groupLinks = group.links.map(link => (
                <IfUser key={link.type} can={[link.permission]}>
                    <li>
                        <button
                            className="navigationDropdown__group-list-item"
                            onClick={() => this.openModal(link.type)}
                        >
                            {link.label}
                        </button>
                    </li>
                </IfUser>
            ));

            return (
                <IfUser
                    canOneOf={group.requiredPermissions}
                    key={group.label}
                >
                    <div className="navigationDropdown__group">
                        <ul className="navigationDropdown__group-list">
                            {groupLinks}
                        </ul>
                    </div>
                </IfUser>
            );
        });
    }

    render() {
        const { listOpen } = this.state;
        const { testPeriodHasExpired } = this.props;

        if (testPeriodHasExpired) {
            return null;
        }

        const buttonClasses = ['navigationDropdown__button'];

        if (listOpen) {
            buttonClasses.push('navigationDropdown__button--open');
        }

        return (
            <IfUser
                canOneOf={['create-jobs', 'create-customers', 'create-construction-diary-entries', 'create-sites', 'create-managed-scaffoldings', 'create-managed-sections']}
            >
                <div className="navigationDropdown">
                    <button
                        className={buttonClasses.join(' ')}
                        onClick={() => this.toggleList()}
                    >
                        <Icon name="add" />
                        <span className="navigationDropdown__lbl">
                            Erstellen
                        </span>
                        {!listOpen
                            ?
                            <span className="navigationDropdown__button-chevron">
                                    <Icon name="chevronDown" />
                                </span>
                            :
                            <span className="navigationDropdown__button-chevron">
                                <Icon name="chevronUp" />
                            </span>
                        }
                    </button>

                    {
                        listOpen &&
                        <div className="navigationDropdown__ct">
                            {this.renderDropDownContent(linkGroups)}
                        </div>
                    }

                    {this.renderModals()}
                </div>
            </IfUser>
        );
    }
}

const mapStateToProps = ({ currentUser }) => {
    const testPeriodHasExpired = currentUser.usermeta.account ? currentUser.usermeta.account.testPeriodHasExpired : null;

    return {
        testPeriodHasExpired
    };
};

export default withRouter(connect(mapStateToProps, null)(onClickOutside(AddResourceDropDown)));
