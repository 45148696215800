import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanSyncFormSection from './ConstructionPlanSyncFormSection';

const ConstructionPlanSyncSectionList = ({ data, liveScaffolding, values, setModelDataMutator, setFieldTouchedMutator, resolvedMedia }) => {

    const extractSectionLiveValues = (section) => {
        if (!liveScaffolding) {
            return null;
        }
        const searchedSection = liveScaffolding.sections.find(liveSection => {
            return liveSection.id === section.id;
        });

        return searchedSection !== undefined ? searchedSection : null;
    };

    return data.map(section =>
        <ConstructionPlanSyncFormSection
            key={section.id}
            values={values}
            planValues={section}
            liveValues={extractSectionLiveValues(section)}
            setModelDataMutator={setModelDataMutator}
            setFieldTouchedMutator={setFieldTouchedMutator}
            resolvedMedia={resolvedMedia}
        />
    );
};

ConstructionPlanSyncSectionList.propTypes = {
    data: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    setModelDataMutator: PropTypes.func.isRequired,
    setFieldTouchedMutator: PropTypes.func.isRequired,
    resolvedMedia: PropTypes.object.isRequired,
    liveScaffolding: PropTypes.any
};

ConstructionPlanSyncSectionList.defaultProps = {
    liveScaffolding: null
};

export default ConstructionPlanSyncSectionList;
