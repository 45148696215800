import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { getDeletedUserLabel } from '../../../utils';
import DiffTypeHeading from '../Diff/DiffTypeHeading';
import { DiffType, JobDiffType } from '../Diff/properties';
import { ChangeLogEntryChangeGroup } from './ChangeLogEntryChangeGroup';
import ChangeLogEntryWrapper from './ChangeLogEntryWrapper';

const renderSingleRowChanges = (singleRowChanges, entry, getDiffTypeLabel, getDiffTypeIcon) => {

    if (!singleRowChanges.length) {
        return null;
    }

    return singleRowChanges.map((change, index) => {

        const { type: diffType } = change;
        const diffTypeIconName = getDiffTypeIcon(diffType);
        const diffTypeLabel = getDiffTypeLabel(diffType, change);

        return (
            <ChangeLogEntryWrapper
                entryMetaInformation={renderLogEntryMeta(entry)}
                key={index}
            >
                <div className="change-log__single-row-change" key={diffType}>
                    <DiffTypeHeading
                        diffType={diffType}
                        icon={diffTypeIconName}
                    >
                        {diffTypeLabel}
                    </DiffTypeHeading>
                </div>
            </ChangeLogEntryWrapper>
        );
    });
};

const renderLogEntryMeta = ({ created: dateOfCreation, createdBy }) => {

    const timeOfCreation = moment(dateOfCreation)
        .format('DD.MM.YYYY, HH:mm');
    const author = createdBy !== null ? `${createdBy.firstName} ${createdBy.lastName}` : getDeletedUserLabel();

    return `${timeOfCreation} Uhr von ${author}`;
};

const sortChangeLogDiffsByType = (changeLogDiffs) => {

    const changedValues = [];
    const newlyAddedValues = [];
    const deletedValues = [];
    const singleRowChanges = [];

    changeLogDiffs.forEach(diffItem => {
        switch (diffItem.type) {
            case DiffType.New:
                newlyAddedValues.push(diffItem);
                break;
            case DiffType.Changed:
                changedValues.push(diffItem);
                break;
            case DiffType.Deleted:
                deletedValues.push(diffItem);
                break;
            case DiffType.Archived:
            case DiffType.Reactivated:
            case JobDiffType.ConstructionPlanCreated:
            case JobDiffType.ConstructionPlanDeleted:
            case JobDiffType.ConstructionPlanChanged:
            case JobDiffType.StatusChanged:
            case JobDiffType.JobTypeChangedToRequest:
            case JobDiffType.JobTypeChangedToJob:
                singleRowChanges.push(diffItem);
                break;
            default:
                console.error('Encountered diff item without type');
        }
    });

    return {
        changedValues,
        newlyAddedValues,
        deletedValues,
        singleRowChanges
    };

};

const renderMultiRowChanges = (multiRowChanges, entry, renderDiffItemValue, getDiffTypeLabel, getDiffTypeIcon) => {

    const { newlyAddedValues, changedValues, deletedValues } = multiRowChanges;

    if (!multiRowChanges || (!newlyAddedValues.length && !changedValues.length && !deletedValues.length)) {
        return null;
    }

    return (
        <ChangeLogEntryWrapper
            entryMetaInformation={renderLogEntryMeta(entry)}
        >
            <div className="change-log__groups">

                {/* New properties */}
                {newlyAddedValues.length > 0 &&
                <ChangeLogEntryChangeGroup diffType={DiffType.New} items={newlyAddedValues} renderDiffItemValue={renderDiffItemValue} getDiffTypeLabel={getDiffTypeLabel} getDiffTypeIcon={getDiffTypeIcon} />
                }

                {/* Changed properties */}
                {changedValues.length > 0 &&
                <ChangeLogEntryChangeGroup diffType={DiffType.Changed} items={changedValues} renderDiffItemValue={renderDiffItemValue} getDiffTypeLabel={getDiffTypeLabel} getDiffTypeIcon={getDiffTypeIcon} />
                }

                {/* Deleted properties */}
                {deletedValues.length > 0 &&
                <ChangeLogEntryChangeGroup diffType={DiffType.Deleted} items={deletedValues} renderDiffItemValue={renderDiffItemValue} getDiffTypeLabel={getDiffTypeLabel} getDiffTypeIcon={getDiffTypeIcon} />
                }
            </div>
        </ChangeLogEntryWrapper>
    );
};

const ChangeLogEntry = ({ entry, getDiff, renderChangeItemValue, getChangeTypeLabel, getChangeTypeIcon }) => {

    const changeLogDiffs = getDiff(entry.new, entry.old);

    const diffsByType = sortChangeLogDiffsByType(changeLogDiffs);

    return (
        <Fragment>
            {renderSingleRowChanges(diffsByType['singleRowChanges'], entry, getChangeTypeLabel, getChangeTypeIcon)}
            {renderMultiRowChanges({
                newlyAddedValues: diffsByType['newlyAddedValues'],
                changedValues: diffsByType['changedValues'],
                deletedValues: diffsByType['deletedValues']
            }, entry, renderChangeItemValue, getChangeTypeLabel, getChangeTypeIcon)}
        </Fragment>
    );
};

export default ChangeLogEntry;

ChangeLogEntry.propTypes = {
    entry: PropTypes.object.isRequired,
    getDiff: PropTypes.func.isRequired,
    renderChangeItemValue: PropTypes.func.isRequired,
    getChangeTypeLabel: PropTypes.func.isRequired,
    getChangeTypeIcon: PropTypes.func.isRequired
};
