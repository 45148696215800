const SubscriptionTypes = {
    'pro_administrator': 'Administrator',
    'pro_standard': 'Vollzugriff',
    'pro_qualified-worker': 'Befähigte Person',
    'pro_worker': 'Helfer',
    'basic_administrator': 'Administrator',
    'basic_standard': 'Vollzugriff',
    'light_administrator': 'Administrator',
    'light_standard': 'Vollzugriff'
};

export const AccountTypes = {
    pro: 'pro',
    basic: 'basic',
    light: 'light'
};

export default SubscriptionTypes;
