import PropTypes from 'prop-types';
import React from 'react';
import { Elements } from 'react-stripe-elements';
import BillingDetailsForm from '../../../Components/Account/BillingDetailsForm';
import EditBillingDetailsBreadcrumb from '../../../Components/Account/EditBillingDetailsBreadcrumb';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const EditBillingDetailsPage = ({ history }) => (
    <PrivatePage requiredUserPermission={Permission.UpdateBilling}>
        <Container>
            <TopNav />
            <EditBillingDetailsBreadcrumb />
            <PageTitle
                title="Zahlungsdetails bearbeiten"
            />
            <Elements locale="de">
                <BillingDetailsForm
                    history={history}
                />
            </Elements>
        </Container>
    </PrivatePage>
);

export default EditBillingDetailsPage;

EditBillingDetailsPage.propTypes = {
    history: PropTypes.object
};

EditBillingDetailsPage.defaultProps = {
    history: {}
};
