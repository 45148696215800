import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Icon from '../Common/Icon';

const FormHint = ({ title, initial, warning, children, collapsible }) => {

    const [isExpanded, setExpanded] = useState(false);

    let classNames = ['form-hint'];
    if (initial) {
        classNames.push('form-hint--initial');
    }

    if(warning) {
        classNames.push('form-hint--warning');
    }

    if(collapsible) {
        classNames.push('form-hint--collapsible');

        if(isExpanded) {
            classNames.push('form-hint--expanded');
        }
    }

    return (
        <div className={classNames.join(' ')}>
            <div className="form-hint__icon"><Icon name="info" /></div>
            <div className="form-hint__cnt">
                {title && <div className="form-hint__ttl">{title}</div>}
                <div className="form-hint__msg">
                    {children}
                </div>
                {collapsible &&
                <div className="form-hint__collapse-toggle-ct">
                    <a role="button" onClick={() => setExpanded(!isExpanded)} className="form-hint__collapse-toggle">
                        {isExpanded ? 'Weniger' :  'Mehr'} anzeigen <Icon name={isExpanded ? 'chevronUp' : 'chevronDown'} />
                    </a>
                </div>
                }
            </div>
        </div>
    );
};

FormHint.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    initial: PropTypes.bool,
    children: PropTypes.node.isRequired,
    collapsible: PropTypes.bool

};

FormHint.defaultProps = {
    title: undefined,
    initial: false,
    collapsible: false
};

export default FormHint;
