import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../Common/Modal/Modal';

const DeleteViaEmailModal = ({
                                 modalTitle, subject, body, closeModal, classes, children
                             }) => (
    <Modal
        id="modal-delete"
        title={modalTitle}
        type="confirmation"
        classes={classes}
        onBackdropClick={() => closeModal()}
        footer={
            <div className="btn-group">
                <button
                    className="btn btn--backward-action"
                    onClick={() => closeModal()}
                >
                    Abbrechen
                </button>
                <a
                    href={`mailto:info@scaffeye.de?subject=${subject}&body=${body}`}
                    className="btn btn--forward-action btn--save btn--mailto"
                >
                    Fortfahren
                </a>
            </div>
        }
    >
        {children}
    </Modal>
);

export default DeleteViaEmailModal;


DeleteViaEmailModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.string
};


DeleteViaEmailModal.defaultProps = {
    classes: ''
};
