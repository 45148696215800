import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import Tooltip from '../../../Common/Tooltip';
import Checkbox from '../../../Forms/Checkbox';
import Condition from '../../../Forms/Condition';
import Error from '../../../Forms/Error';
import FormHint from '../../../Forms/FormHint';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import OptionGroup from '../../../Forms/OptionGroup';
import { getPurposeGroupLabel } from '../../labels';
import { ConstructionType, PurposeGroup } from '../../properties';
import { isUnmanaged } from '../functions';
import {
    EmergencyStairwayOptions,
    PlatformStairwayScaffoldingOptions,
    SafetyScaffoldingOptions,
    WorkScaffoldingOptions
} from '../options';
import {
    isEmergencyStairwayAllowed,
    isLadderAscentAllowed,
    isPlatformStairwayAllowed,
    isProtectivePurposeAllowed,
    isRequired,
    isSiteStaircaseAllowed,
    isWorkPurposeAllowed
} from '../validate';

export const SectionPurposesInput = ({ values, mutators }) => {

    const workEnabled = isWorkPurposeAllowed(values);
    const protectiveEnabled = isProtectivePurposeAllowed(values);
    const ladderAscentEnabled = isLadderAscentAllowed(values);
    const platformStairwayEnabled = isPlatformStairwayAllowed(values);
    const siteStaircaseEnabled = isSiteStaircaseAllowed(values)
    const emergencyStairwayEnabled = isEmergencyStairwayAllowed(values);

    const groups = values?.purpose?.groups || [];
    const groupsId = groups.join('-');
    const groupsLabel = groups.map(getPurposeGroupLabel).join(', ');

    return (
        <InputGroup
            label="Verwendungszweck"
            required={isRequired('purpose', values)}
        >

            {/* Work scaffolding */}
            <Checkbox
                label="Arbeitsgerüst"
                name="purpose.groups"
                value={PurposeGroup.WorkScaffolding}
                disabled={!workEnabled}
                showErrors={false}
                tooltip={!workEnabled && <Tooltip id={`purpose-work-not-available-${groupsId}`}>
                    Arbeitsgerüste sind nicht mit {groupsLabel} kombinierbar
                </Tooltip>}
            />

            <Condition when="purpose.groups" contains={PurposeGroup.WorkScaffolding}>
                <div className="input-grp__cnt">
                    <OptionGroup
                        name="purpose.work"
                        options={WorkScaffoldingOptions}
                        type="radio"
                    />
                </div>

                <Condition
                    when="purpose.work"
                    isOneOf={['interior_birdcage_scaffolding', 'birdcage_scaffolding']}
                >
                    <FormHint collapsible>
                        Bitte beachten Sie die folgenden Richtlinien: Für Raum- oder Flächengerüste
                        ab
                        Lastklasse 6* benötigen Sie in der Regel einen Statik- oder
                        Herstellernachweis.
                        Diesen können Sie im Bereich &quot;Dokumente&quot; hinterlegen.
                        Alternativ können Sie darauf achten, dass Ihr Gerüstabschnitt die
                        Faustregel &quot;Zulässige Belastung = 2kN / 6m²&quot; einhält. <br />
                        <br />
                        * Geringere Lastklassen erfordern u.U. ebenfalls einen Statik- oder
                        Herstellernachweis. Bitte beachten Sie die AuV Ihres Herstellers.
                    </FormHint>
                </Condition>
            </Condition>

            {/* Protective scaffolding */}
            <Checkbox
                label="Schutzgerüst"
                name="purpose.groups"
                value={PurposeGroup.ProtectiveScaffolding}
                disabled={!protectiveEnabled}
                showErrors={false}
                tooltip={!protectiveEnabled &&
                <Tooltip id={`purpose-protective-not-available-${groupsId}`}>
                    Schutzgerüste sind nicht mit {groupsLabel} kombinierbar
                </Tooltip>}
            />

            <Condition when="purpose.groups" contains="protective_scaffolding">
                <div className="input-grp__cnt">
                    <OptionGroup
                        name="purpose.protective"
                        options={SafetyScaffoldingOptions}
                        type="checkbox"
                    />

                    <Condition
                        when="purpose.protective"
                        contains="other_protective_scaffolding"
                    >
                        <Field
                            name="purpose.otherProtective"
                            component="input"
                            type="text"
                            placeholder='z.B. "Schutzdach"'
                        />
                        <Error name="otherProtectivePurpose" />
                    </Condition>
                </div>
            </Condition>

            <Condition
                when="purpose.groups"
                contains={[PurposeGroup.WorkScaffolding, PurposeGroup.ProtectiveScaffolding]}
            >
                <InputHint>
                    Fangschutz, Fanggitter oder Konsolen ggf.
                    erforderlich. Bitte überprüfen Sie Ihre Konstruktion
                    und installieren Sie eine geeignete Schutzvorrichtung.
                </InputHint>
            </Condition>

            {/* Ladder ascent / Leiteraufstieg */}
            <Checkbox
                label="Einzelfelder als Aufstieg"
                name="purpose.groups"
                value={PurposeGroup.LadderAscent}
                disabled={!ladderAscentEnabled}
                showErrors={false}
                tooltip={!ladderAscentEnabled &&
                <Tooltip id={`purpose-ladder-ascent-not-available-${groupsId}`}>
                    Einzelfelder als Aufstieg ist nicht mit {groupsLabel} kombinierbar
                </Tooltip>
                }
            />

            {/* Platform stairway / Podesttreppe */}
            <Checkbox
                label="Podesttreppe"
                name="purpose.groups"
                value={PurposeGroup.PlatformStairway}
                disabled={!platformStairwayEnabled}
                showErrors={false}
                tooltip={!platformStairwayEnabled &&
                <Tooltip id={`purpose-platform-stairway-not-available-${groupsId}`}>
                    Nicht mit {groupsLabel} kombinierbar
                </Tooltip>}
            />

            <Condition
                when="purpose.groups"
                contains={PurposeGroup.PlatformStairway}
            >
                <div className="input-grp__cnt">
                    <OptionGroup
                        name="purpose.platformStairway"
                        options={PlatformStairwayScaffoldingOptions}
                        type="radio"
                    />
                </div>
            </Condition>

            {/* Site staircase / Bautreppe */}
            <Checkbox
                label="Bautreppe"
                name="purpose.groups"
                value={PurposeGroup.SiteStaircase}
                disabled={!siteStaircaseEnabled}
                showErrors={false}
                tooltip={!siteStaircaseEnabled &&
                <Tooltip id={`purpose-site-staircase-not-available-${groupsId}`}>
                    Nicht mit {groupsLabel} kombinierbar
                </Tooltip>}
            />

            {/* Emergency stairway / Fluchttreppe */}
            <Checkbox
                label="Fluchttreppe"
                name="purpose.groups"
                value={PurposeGroup.EmergencyStairway}
                disabled={!emergencyStairwayEnabled}
                showErrors={false}
                tooltip={!emergencyStairwayEnabled &&
                <Tooltip id={`purpose-emergency-stairway-not-available-${groupsId}`}>
                    Nicht mit {groupsLabel} kombinierbar
                </Tooltip>}
            />

            <Condition
                when="purpose.groups"
                contains={PurposeGroup.EmergencyStairway}
            >
                <div className="input-grp__cnt">
                    <OptionGroup
                        name="purpose.emergencyStairway"
                        options={EmergencyStairwayOptions}
                        type="radio"
                    />
                </div>
            </Condition>

            <Error name="purpose.groups" />

            {/* What to do when the groups change */}
            <OnChange name="purpose.groups">
                {(value, prevValue) => {

                    // Check special construction if emergency stairway is selected
                    if (value && ! isUnmanaged(values)) {
                        const isEmergencyStairway = value.indexOf(PurposeGroup.EmergencyStairway) >= 0;
                        if (isEmergencyStairway) {
                            mutators.setField('specialConstruction', true);
                            mutators.setField('constructionType', ConstructionType.ModuleSystemScaffolding);
                            mutators.setField('loadClass', null);
                            mutators.setField('widthClass', null);
                            mutators.setField('wallClearance', null);
                        }

                        const wasEmergencyStairway = prevValue && prevValue.indexOf(PurposeGroup.EmergencyStairway) >= 0;
                        if(wasEmergencyStairway && !isEmergencyStairway) {
                            mutators.setField('specialConstruction', false);
                            mutators.setField('constructionType', null);
                        }

                        if (_.intersection(value, [
                            PurposeGroup.EmergencyStairway,
                            PurposeGroup.LadderAscent
                        ]).length > 0) {
                            mutators.setField('plannedWorks', []);
                        }
                    }
                }}
            </OnChange>

        </InputGroup>
    );
}
