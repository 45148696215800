import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../../../properties';
import LinkButton from '../../../../Common/LinkButton';
import Condition from '../../../../Forms/Condition';
import ConstructionPlanAllOrSingleSelection
    from '../../../../Forms/ConstructionPlan/ConstructionPlanAllOrSingleSelection';
import ConstructionPlanModeSelection
    from '../../../../Forms/ConstructionPlan/ConstructionPlanModeSelection';
import Input from '../../../../Forms/Input';
import InputGroup from '../../../../Forms/InputGroup';
import { FormMode } from '../../../../Forms/properties';
import ScaffoldingFormContent from '../../../../Scaffoldings/Form/ScaffoldingFormContent';
import ScaffoldingSelect from '../../../../Scaffoldings/ScaffoldingSelect';
import { ConstructionPlanModelMode, ConstructionPlanReferenceMode } from '../../../properties';

function isFormReadOnly(values) {
    return values.mode === ConstructionPlanModelMode.Reference;
}

function renderNewScaffoldingFormContent(props) {
    const { values, inputDisabled, attachmentsMutator, anchorProtocolMutator, utilizationPlanMutator, onUploading } = props;
    return (
        <ScaffoldingFormContent
            mode={FormMode.Create}
            values={values}
            attachmentsMutator={attachmentsMutator}
            anchorProtocolMutator={anchorProtocolMutator}
            utilizationPlanMutator={utilizationPlanMutator}
            onUploading={onUploading}
            readOnly={isFormReadOnly(values) || inputDisabled}
            isConstructionPlan
            constructionPlanMode={ConstructionPlanModelMode.New}
        />
    );
}

function renderReferenceScaffoldingFormContent(props) {
    const { values, referenceData, referencedScaffoldingIds, disableModeAll, onResetScaffolding, inputDisabled, siteId, attachmentsMutator, anchorProtocolMutator, utilizationPlanMutator, onUploading } = props;
    return (
        <Fragment>
            <ConstructionPlanAllOrSingleSelection
                type={ModelType.Scaffolding}
                disableModeAll={disableModeAll}
            />

            <Condition when="selection" is={ConstructionPlanReferenceMode.Single}>
                {
                    values.id ?
                        <InputGroup label="Gerüst*">
                            <div className="construction-plan-form__reference">
                                {referenceData && referenceData.name}
                            </div>
                            <LinkButton
                                label="Gerüst ändern (Anpassungen werden verworfen)"
                                onClick={onResetScaffolding}
                                bold
                                underline
                            />
                            <Input
                                readOnly
                                type="hidden"
                                name="id"
                            />
                        </InputGroup> :
                        <InputGroup label="Gerüst*">
                            <ScaffoldingSelect
                                name="id"
                                siteId={siteId}
                                managed
                                excludeScaffoldingIds={referencedScaffoldingIds}
                            />
                        </InputGroup>
                }

                <Condition when="id" isSet>
                    <ConstructionPlanModeSelection
                        type={ModelType.Scaffolding}
                    />

                    <ScaffoldingFormContent
                        values={{ ...values }}
                        mode={FormMode.Edit}
                        attachmentsMutator={attachmentsMutator}
                        utilizationPlanMutator={utilizationPlanMutator}
                        anchorProtocolMutator={anchorProtocolMutator}
                        onUploading={onUploading}
                        readOnly={isFormReadOnly(values) || inputDisabled}
                        isConstructionPlan
                        constructionPlanMode={values.mode}
                        referenceData={referenceData}
                    />
                </Condition>
            </Condition>
        </Fragment>
    );
}

const ConstructionPlanScaffoldingFormContent = (props) => {
    const { values } = props;
    if (values.mode !== ConstructionPlanModelMode.New) {
        return renderReferenceScaffoldingFormContent(props);
    }
    return renderNewScaffoldingFormContent(props);
};

ConstructionPlanScaffoldingFormContent.propTypes = {
    values: PropTypes.object.isRequired,
    siteId: PropTypes.string.isRequired,
    referencedScaffoldingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    anchorProtocolMutator: PropTypes.func.isRequired,
    utilizationPlanMutator: PropTypes.func.isRequired,
    onUploading: PropTypes.func.isRequired,
    onResetScaffolding: PropTypes.func.isRequired,
    referenceData: PropTypes.object,
    inputDisabled: PropTypes.bool,
    disableModeAll: PropTypes.bool
};

ConstructionPlanScaffoldingFormContent.defaultProps = {
    referenceData: null,
    inputDisabled: false,
    disableModeAll: false
};

export default ConstructionPlanScaffoldingFormContent;
