import _ from 'lodash';
import { MediaVisibility, ModelMediaType } from '../../Media/properties';
import { ConstructionPlanModelMode } from '../properties';

export const transformMediaItemToSyncMediaItem = (mediaItem) => {

    if (!mediaItem) {
        return null;
    }

    return {
        ...mediaItem
    };
};

export const getInitialMediaFormField = (property, model, modelReferenceData, modelMediaType, resolvedMedia) => {

    const modelData = model.data ? model.data[property] : [];
    const referenceData = modelReferenceData ? modelReferenceData[property] : [];
    const isModelNew = model.mode === ConstructionPlanModelMode.New;
    const initialMedia = extractInitialMedia(isModelNew, modelData, referenceData, modelMediaType, resolvedMedia);

    return {
        [property]: initialMedia
    };
};

export const getInitialSingleMediaFormField = (property, model, modelReferenceData, modelMediaType, resolvedMedia) => {

    let modelData = model.data ? model.data[property] : null;
    let referenceData = modelReferenceData ? modelReferenceData[property] : null;
    const isModelNew = model.mode === ConstructionPlanModelMode.New;

    modelData = modelData ? [modelData] : [];
    referenceData = referenceData ? [referenceData] : [];

    const initialMedia = extractInitialMedia(isModelNew, modelData, referenceData, modelMediaType, resolvedMedia);
    if (_.isEmpty(initialMedia)) {
        return {};
    }

    return {
        [property]: initialMedia[Object.keys(initialMedia)[0]]
    };
};

const extractInitialMedia = (isModelNew, modelMedia, referenceMedia, modelMediaType, resolvedMedia) => {

    if (!modelMedia || !modelMedia.length || !referenceMedia || (!isModelNew && !referenceMedia.length)) {
        return {};
    }

    if (isModelNew) {
        return transformMediaToFormValues(modelMedia, [], modelMediaType, resolvedMedia);
    }

    const modelMediaIds = modelMedia.map(mi => mi.id);
    const referenceMediaIds = referenceMedia.map(mediaItem => mediaItem.id);
    const allMediaIds = _.uniq([...modelMediaIds, ...referenceMediaIds]);

    return allMediaIds.reduce((acc, mediaId) => {
        if (modelMediaIds.indexOf(mediaId) >= 0) {
            const refMediaItem = modelMedia.find(mm => mm.id === mediaId && mm.mode === ConstructionPlanModelMode.Reference);
            if (refMediaItem) {
                acc[mediaId] = transformSingleMediaToFormValues(refMediaItem, modelMediaType, resolvedMedia);
            }
        }

        if (!acc.hasOwnProperty(mediaId)) {
            acc[mediaId] = undefined;
        }

        return acc;
    }, {});
};

export function updateVisibility(mediaItem, modelMediaType) {
    switch (modelMediaType) {
        case ModelMediaType.Scaffolding_UtilizationPlan:
        case ModelMediaType.Scaffolding_AnchorProtocol:
        case ModelMediaType.Section_AnchorProtocol:
        case ModelMediaType.Section_UtilizationPlan:
        case ModelMediaType.Section_ProofOfStability:
            mediaItem.visibility = MediaVisibility.Public;
            break;
        default:
        // nothing to do
    }
}

const selectAllMedia = (media, referenceMedia, modelMediaType, resolvedMedia) => {

    if (!resolvedMedia) {
        return {};
    }

    const mediaIds = media ? media.map(mi => mi.id) : [];
    const referenceMediaIds = referenceMedia ? referenceMedia.map(mi => mi.id) : [];
    const allMediaIds = _.uniq([...mediaIds, ...referenceMediaIds]);

    return allMediaIds.reduce((acc, mediaId) => {
        if (mediaIds.indexOf(mediaId) >= 0 && resolvedMedia.hasOwnProperty(mediaId)) {
            const mediaItem = media.find(mi => mi.id === mediaId);
            const resolvedMediaItem = { ...resolvedMedia[mediaId] };
            updateVisibility(resolvedMediaItem, modelMediaType);
            resolvedMediaItem.mode = mediaItem.mode || ConstructionPlanModelMode.Reference;
            acc[mediaId] = resolvedMediaItem;
        } else {
            acc[mediaId] = null;
        }

        return acc;
    }, {});
};

function normalizeMediaValue(media) {
    if(!media) {
        return [];
    }

    if(_.isArray(media)) {
        return media;
    }

    if(_.isObject(media)) {
        return Object.values(media).filter(x => x);
    }

    return media;
}

export const isIdenticalMediaValue = (_media, _referenceMedia) => {

    const media = normalizeMediaValue(_media);
    const referenceMedia = normalizeMediaValue(_referenceMedia);

    // Both are empty -> identical
    if (_.isEmpty(media) && _.isEmpty(referenceMedia)) {
        return true;
    }

    const mediaIds = media.map(mi => mi.id);
    const referenceMediaIds = referenceMedia.map(mi => mi.id);

    // Different ids -> not identical
    if (!_.isEqual(mediaIds, referenceMediaIds)) {
        return false;
    }

    // Same ids -> need to check every item
    let identical = true;
    media.forEach(mediaItem => {

        // If this media item is a reference we don't need to check the data
        if (mediaItem.mode === ConstructionPlanModelMode.Reference) {
            return;
        }

        const referenceMediaItem = referenceMedia.find(rmi => rmi.id === mediaItem.id);
        if(!referenceMediaItem) {
            identical &= false;
            return;
        }

        // The only thing that can be changed is 'name' and 'visibility'
        // so it's sufficient to compare those

        const a = {
            name: mediaItem.name,
            visibility: mediaItem.visibility
        };
        const b = {
            name: referenceMediaItem.name,
            visibility: referenceMediaItem.visibility
        };

        identical &= _.isEqual(a, b);
    });

    return identical;
};

export const isIdenticalSingleMediaValue = (_mediaItem, _referenceMediaItem) => {

    const mediaItem = _mediaItem || null;
    const referenceMediaItem = _referenceMediaItem || null;

    if (!mediaItem && !referenceMediaItem) {
        return true;
    }

    if(
        (_mediaItem && !_referenceMediaItem)
        || (!_mediaItem && referenceMediaItem)
    ) {
        return false;
    }

    if (_mediaItem.mode === ConstructionPlanModelMode.Reference) {
        return true;
    }

    const mediaValueRelevantFields = {
        id: mediaItem && mediaItem.id,
        name: mediaItem && mediaItem.name,
        visibility: mediaItem && mediaItem.visibility
    };

    const referenceMediaRelevantFields = {
        id: referenceMediaItem && referenceMediaItem.id,
        name: referenceMediaItem && referenceMediaItem.name,
        visibility: referenceMediaItem && referenceMediaItem.visibility
    };

    return _.isEqual(mediaValueRelevantFields, referenceMediaRelevantFields);
};

export const transformMediaToFormValues = (media, refMedia, modelMediaType, resolvedMedia) => {
    return selectAllMedia(media, refMedia, modelMediaType, resolvedMedia);
};

export const transformSingleMediaToFormValues = (mediaItem, modelMediaType, resolvedMedia) => {
    if (!resolvedMedia || !mediaItem) {
        return null;
    }

    if (!resolvedMedia.hasOwnProperty(mediaItem.id)) {
        return null;
    }

    const resolvedMediaItem = resolvedMedia[mediaItem.id];
    updateVisibility(resolvedMediaItem, modelMediaType);

    resolvedMediaItem.mode = mediaItem.mode || ConstructionPlanModelMode.Reference;

    return resolvedMediaItem;
};

export const propertyReferencesMedia = (property) => {
    return (property === 'attachments' || property === 'utilizationPlans');
};

export const propertyReferencesSingleMedia = (property) => {
    return (property === 'proofOfStability' || property === 'anchorProtocol');
};

export const getSingleMediaFromRepository = (data, property, resolvedMedia) => {
    if (!data) {
        return null;
    }

    const mediaItem = data ? data[property] : null;
    if (!mediaItem || !mediaItem.id) {
        return null;
    }

    if (!resolvedMedia || !resolvedMedia.hasOwnProperty(mediaItem.id)) {
        return null;
    }

    const resolvedMediaItem = resolvedMedia[mediaItem.id];
    if (mediaItem.mode === ConstructionPlanModelMode.Edit) {
        resolvedMediaItem.name = mediaItem.name;
    }

    return resolvedMediaItem;
};

export const transformMediaToSyncMedia = (media) => {

    if (!media) {
        return [];
    }

    return Object.keys(media)
        .map(mediaId => {

            const mediaItem = media[mediaId];
            if (!mediaItem) {
                return null;
            }

            const syncMediaItem = { ...mediaItem };
            if (!syncMediaItem.mode) {
                syncMediaItem.mode = ConstructionPlanModelMode.New;
            }

            return syncMediaItem;
        })
        .filter(media => media);
};

export const getMediaListDiff = (planOrSyncModel, referenceModel, property, resolvedMedia) => {

    let planOrSyncMedia = planOrSyncModel.data ? planOrSyncModel.data[property] : null;
    planOrSyncMedia = planOrSyncMedia || [];

    let referenceMedia = referenceModel ? referenceModel[property] : null;
    referenceMedia = referenceMedia || [];

    // Compatibility with the plan/sync form value structure ({id => media})
    if (_.isObject(planOrSyncMedia) && !Array.isArray(planOrSyncMedia)) {
        planOrSyncMedia = Object.values(planOrSyncMedia);
    }

    const planOrSyncMediaIds = planOrSyncMedia.map(mi => mi.id);
    const referenceMediaIds = referenceMedia.map(mi => mi.id);

    const allMediaIds = _.uniq([...planOrSyncMediaIds, ...referenceMediaIds]);

    const referencedMediaBucket = [];
    const changedMediaBucket = [];
    const newMediaBucket = [];
    const removedMediaBucket = [];

    allMediaIds.forEach(mediaId => {

        const planOrSyncMediaItem = planOrSyncMedia.find(mi => mi.id === mediaId);
        const referenceMediaItem = referenceMedia.find(mi => mi.id === mediaId);

        const resolvedPlanOrSyncMediaItem = getResolvedMediaItem(planOrSyncMediaItem, resolvedMedia, false);
        const resolvedReferenceMediaItem = getResolvedMediaItem(referenceMediaItem, resolvedMedia, false);

        // Media id is present in both
        if (planOrSyncMediaItem && referenceMediaItem) {
            if (planOrSyncMediaItem.mode === ConstructionPlanModelMode.Reference) {
                referencedMediaBucket.push(resolvedPlanOrSyncMediaItem);
            } else {
                changedMediaBucket.push({
                    new: resolvedPlanOrSyncMediaItem,
                    old: resolvedReferenceMediaItem
                });
            }
        } else if (planOrSyncMediaItem) {
            newMediaBucket.push(resolvedPlanOrSyncMediaItem)
        } else {
            removedMediaBucket.push(resolvedReferenceMediaItem);
        }
    });

    const isIdentical = changedMediaBucket.length === 0
        && newMediaBucket.length === 0
        && removedMediaBucket.length === 0;

    return {
        referenced: referencedMediaBucket,
        changed: changedMediaBucket,
        new: newMediaBucket,
        removed: removedMediaBucket,
        isIdentical
    }
};

export const getResolvedMediaItem = (mediaItem, resolvedMedia, wrap = true) => {

    if (!mediaItem) {
        return null;
    }

    const { id, name, visibility } = mediaItem;

    const resolvedMediaData = resolvedMedia
        && resolvedMedia.hasOwnProperty(id)
        && resolvedMedia[id]
        || {};

    const mediaData = {
        ...resolvedMediaData,
        id
    };

    // if file was edited/renamed
    if (mediaItem.mode === ConstructionPlanModelMode.Edit) {
        mediaData.name = name;
        mediaData.mode = ConstructionPlanModelMode.Edit;
    }

    if (visibility) {
        mediaData.visibility = visibility;
    }

    if (!wrap) {
        return mediaData;
    }

    return {
        [id]: mediaData
    };
};

export const getMediaFromRepository = (_mediaReferences, resolvedMedia) => {
    if(!_mediaReferences) {
        return [];
    }

    const mediaReferences = normalizeMediaValue(_mediaReferences);

    if (!mediaReferences || !_.isArray(mediaReferences) || mediaReferences.length === 0) {
        return null;
    }

    let media = {};

    mediaReferences.forEach((referencedMediaItem) => {
        media = {
            ...media,
            ...getResolvedMediaItem(referencedMediaItem, resolvedMedia)
        };
    });

    return media;
};
