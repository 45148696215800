import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '../../Common/IconButton';
import Tooltip from '../../Common/Tooltip';
import Section from '../../Layout/Section';
import { MediaPropType } from '../../Media/properties';
import LoadingSpinner from '../../Page/LoadingSpinner';
import JobCorrespondenceTimeline from './Timeline/JobCorrespondenceTimeline';

export default function JobCorrespondenceSection({ items, isInitialized, isUpdating, isLoading, onClickAdd, onCorrespondenceUpdate, onCorrespondenceDelete, disableManipulation }) {

    return (
        <Section
            title={
                <span className="job-correspondance-section-title">
                    Korrespondenz
                    <Tooltip id="job-correspondance-info" icon="info">
                        Laden Sie hier E-Mails, Briefe und Anhänge aus der Kommunikation mit Ihrem Kunden hoch. <br />
                        <br />
                        Anhänge können in diesem Zusammenhang beispielsweise Kostenvoranschläge oder Vertragsdokumente sein. <br />
                        <br />
                        Durch die regelmäßige Nutzung der Korrespondenz-Sektion können Sie den Kontaktverlauf mit Ihrem Kunden chronologisch nachvollziehen.
                    </Tooltip>
                </span>
            }
            id="correspondence"
            cta={
                (isInitialized && !disableManipulation) ?
                    <IconButton
                        onClick={onClickAdd}
                        title="Korrespondenz hinzufügen"
                        icon="add"
                    />
                    :
                    null
            }
            isBusy={isUpdating}
            busyLabel="Aktualisiere Korrespondenz"
        >

            {isInitialized && isLoading &&
            <div style={{
                marginBottom: 23,
                marginTop: -10
            }}
            ><LoadingSpinner label="Aktualisiere Korrespondenz" />
            </div>}
            {
                !isInitialized
                    ? <LoadingSpinner label="Lade Korrespondenz" />
                    : <JobCorrespondenceTimeline
                        items={items}
                        onTimelineItemEdit={onCorrespondenceUpdate}
                        onTimelineItemDelete={onCorrespondenceDelete}
                        readOnly={disableManipulation}
                    />
            }
        </Section>
    );
}

JobCorrespondenceSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isInitialized: PropTypes.bool.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onCorrespondenceUpdate: PropTypes.func.isRequired,
    onCorrespondenceDelete: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    disableManipulation: PropTypes.bool,
    items: PropTypes.arrayOf(MediaPropType)
};

JobCorrespondenceSection.defaultProps = {
    items: null
};
