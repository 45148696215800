import _ from 'lodash';

export const userCan = (requiredPermissions, userPermissions) => {

    if (!userPermissions || !_.isArray(userPermissions) || _.isEmpty(userPermissions)) {
        return false;
    }

    if (_.isString(requiredPermissions)) {
        return _.includes(userPermissions, requiredPermissions);
    } else if (_.isArray(requiredPermissions)) {
        return requiredPermissions.every(requiredPermission => (_.includes(userPermissions, requiredPermission)));
    }

    console.error('Permission check failed: Unknown permission format');
    return false;
}
