import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AllowedFileFormats } from '../../properties';
import { showApiError, showTempError } from '../../redux/modules/error/action';
import { upload } from '../../redux/modules/media/action';
import { BadRequestError, ServerError } from '../../Services/ApiError';
import { getErrorId, isValidationError } from '../../Services/ErrorService';
import { autoFormatFileSize, getFileExtension, isImage } from '../../utils';
import Icon from './Icon';
import Image from './Image';
import Tooltip from './Tooltip';

class AttachmentUpload extends Component {

    constructor(props) {
        super(props);

        this.onDropAccepted = this.onDropAccepted.bind(this);
        this.renderUploadErrorMessage = this.renderUploadErrorMessage.bind(this);

        this.state = {
            mediaIds: [],
            mediaFiles: [],
            uploadErrorMessage: null,
            isUploading: false,
            single: this.props.single !== undefined
        };
    }

    handleValidationError(validationError) {
        if (!validationError || !validationError.field) {
            return;
        }

        const errorCode = validationError.error;
        let error = 'Fehlerhafte Eingabe';
        if (errorCode === 'is_valid_file_type') {
            error = 'Dateityp wird zur Zeit nicht unterstützt';
        } else if (errorCode === 'max') {
            error = 'Datei ist zu groß';
        } else {
            error = `${error} (${errorCode})`;
        }

        this.setState({
            uploadErrorMessage: error
        });

    }

    handleValidationErrors(validationErrors) {
        if (!validationErrors) {
            this.handleUnknownError();
        } else {
            const firstValidationError = validationErrors[0];
            this.handleValidationError(firstValidationError);
        }
    }

    handleBadRequestError(errorData) {
        const { error } = errorData;

        if (error === 'upload_incomplete') {

            this.setState({
                uploadErrorMessage: 'Die Dateien konnten nicht vollständig an den Server übermittelt werden. Bitte versuchen Sie es erneut.'
            });

        } else {
            this.handleUnknownError();
        }

    }

    handleUnknownError() {
        let errorMessage = 'Beim Hochladen ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal';

        this.setState({
            uploadErrorMessage: errorMessage
        });
    }


    componentDidMount() {
        const files = this.props.files || [];

        if (!files || files.length === 0) {
            return;
        }

        const mediaIds = [];
        const mediaFiles = [];

        files.forEach((item) => {
            mediaIds.push(item.id);
            mediaFiles.push(item);
        });

        this.setState({
            mediaIds,
            mediaFiles
        });
    }

    onDropAccepted(files) {
        const formData = new FormData();
        formData.append('attachment', files[0]);

        this.setState({
            isUploading: true,
            uploadErrorMessage: null
        }, () => this.props.isUploadingAttachment(true));

        return this.props.actions.upload(formData, this.props.uploadPath, this.props.label)
            .then((result) => {
                this.setState({
                    mediaFiles: [...this.state.mediaFiles, result],
                    isUploading: false,
                    mediaIds: [...this.state.mediaIds, result.id]
                }, () => this.props.onUpdate(this.state.mediaIds));
            })
            .catch((error) => {
                this.setState({
                    isUploading: false
                });

                return this.handleUploadError(error);

            })
            .then(() => this.props.isUploadingAttachment(false));
    }

    handleServerError() {
        return showTempError('Der Dateiupload ist fehlgeschlagen. Der Grund dafür könnte eine technische Störung oder das Überschreiten der maximalen Dateigröße für Uploads (maximal 100 MB) sein.', 10000);
    }

    handleUploadError(error) {

        if (error instanceof BadRequestError) {
            const { response } = error;
            const errorId = getErrorId(response);

            if (isValidationError(errorId)) {
                return this.handleValidationErrors(response.payload);
            }

            return this.handleBadRequestError(response);
        }

        if (error instanceof ServerError) {
            return this.handleServerError();
        }

        return showApiError(error);

    }

    removeMediaById(id) {
        this.setState({
            mediaFiles: this.state.mediaFiles.filter(file => file.id !== id),
            mediaIds: this.state.mediaIds.filter(mediaId => mediaId !== id)
        }, () => this.props.onUpdate(this.state.mediaIds));
    }

    renderControls() {
        const { single, mediaFiles, uploadErrorMessage } = this.state;

        if (single && mediaFiles.length >= 1) {
            return null;
        }

        const { label, type } = this.props;
        const { isUploading } = this.state;

        return (
            <div className="attachment-upload__controls">
                <Dropzone
                    onDropAccepted={this.onDropAccepted}
                    multiple={false}
                    className="attachment-upload__dropzone"
                >
                    {isUploading ?
                        <span className="attachment-upload__ld-txt">
                            <i className="btn__spinner" />
                            {label || 'Dokument'} wird hochgeladen
                        </span>
                        :
                        <label
                            htmlFor={`mediaUpload-${type}`}
                            className="attachment-upload__lbl"
                        >
                            {label || 'Dokument'} hochladen
                        </label>
                    }
                    <span className="attachment-upload__note">
                        Maximale Dateigröße für Uploads: 100MB
                        <span style={{
                            paddingLeft: 4,
                            paddingBottom: 4
                        }}
                        >
                            <Tooltip id="allowed-formats-hint">Bitte beachten Sie die zulässigen Dateiendungen: <br />
                                <strong>{AllowedFileFormats.join(', ')}</strong>
                            </Tooltip>
                        </span>
                </span>
                </Dropzone>
                {uploadErrorMessage && this.renderUploadErrorMessage()}
            </div>
        )
            ;
    }

    static renderImage(file) {
        return (
            <a href={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}>
                <Image
                    imageUrl={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}
                    className="attachment-upload__file-image"
                />
            </a>
        );
    }

    static renderPlaceholder(file) {
        return (
            <a
                className="attachment-upload__file-placeholder"
                href={`${process.env.REACT_APP_API_URL}/v1/attachments/${file.id}`}
            >
                <span className="attachment-upload__file-icon"> <Icon name="document" /></span>
                <span className="attachment-upload__file-extension"
                >{getFileExtension(file.fileName)}{', '} {autoFormatFileSize(file.size)} <span
                    className="attachment-upload__download-indicator"
                ><Icon name="download" /></span></span>
            </a>
        );
    }

    renderAttachments() {
        const attachments = this.state.mediaFiles.map((file, index) =>
            (
                <div className="attachment-upload__ctn" key={index}>
                    {
                        isImage(file.type) ?
                            AttachmentUpload.renderImage(file)
                            :
                            AttachmentUpload.renderPlaceholder(file)
                    }
                    <span
                        className="attachment-upload__close-icn"
                        onClick={() => this.removeMediaById(file.id)}
                    >
                        <Icon name="close" />
                    </span>
                </div>
            ));

        return (
            <div className="section-info__ctn">
                <span>{attachments}</span>
            </div>
        );
    }

    renderUploadErrorMessage() {
        return (
            <span
                style={{
                    display: 'block',
                    marginLeft: 0
                }}
                className="input-grp__error"
            >
                {this.state.uploadErrorMessage}
            </span>);
    }

    render() {

        return (
            <div className="attachment-upload">
                {this.renderAttachments()}
                {this.renderControls()}
            </div>
        );
    }
}

AttachmentUpload.propTypes = {
    actions: PropTypes.object,
    onUpdate: PropTypes.func,
    type: PropTypes.string,
    uploadPath: PropTypes.string
};

AttachmentUpload.defaultProps = {
    onUpdate: () => false,
    type: '',
    uploadPath: ''
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ upload }, dispatch)
});

export default connect(null, mapDispatchToProps)(AttachmentUpload);
