import ContactPersonDTO from './ContactPersonDTO';

export default class CustomerDTO {
    constructor() {
        this.name = '';
        this.customerId = '';
        this.line1 = '';
        this.line2 = '';
        this.city = '';
        this.zip = '';
        this.country = 'DE';
        this.billingAddress = undefined;
        this.mainContact = new ContactPersonDTO();
    }
}
