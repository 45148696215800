import React from 'react';

const Changelog_1_16_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">

            <section>
                <h4>Archivierungsfunktion für Baustellen</h4>
                <p>
                    Eigene Baustellen könnnen ab sofort archiviert werden. Archivierte Baustellen sind nun getrennt von aktiven Baustellen einsehbar und können nach Name, Adresse sowie Archivierungsdatum sortiert werden. Eine archivierte Baustelle kann bei Bedarf reaktiviert werden und steht der Planung und Verwaltung danach wieder zur Verfügung.                </p>
            </section>

            <section>
                <h4>
                    Upload von größeren Dateien möglich
                </h4>
                <p>
                    Ab sofort können Dateien bis zu einer Dateigröße von 100 MB hochgeladen werden.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>Bilder, die über mobile Endgeräte hochgeladen wurden, werden nicht mehr "gedreht" dargestellt</li>
                    <li>Verbesserung der Darstellung von wichtigen Hinweisen und Informationen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_16_0;
