import React from 'react';
import { showManagedLabel } from '../helpers';

/**
 * Controls the managed flag of a scaffolding
 *
 * @param managed
 * @param canCreateManagedScaffolding boolean
 * @return {JSX.Element}
 * @constructor
 */
export const SectionManagedIndicator = ({ managed }) => (
    <div className="scaff-managed-choice">
        <button
            disabled={true}
            className={`scaff-managed-choice__btn ${managed && 'is-managed'}`}
        >
            <span className="scaff-managed-choice__btn-indicator" />
            <span className="scaff-managed-choice__btn-lbl">{showManagedLabel(managed, true)}</span>
        </button>
    </div>
);
