import _ from 'lodash';
import React from 'react';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import { determinePurposeGroups } from './functions';
import {
    Bracket,
    Cladding,
    ConstructionType,
    EquippedSurface,
    LoadClass,
    PlannedWork,
    Purpose,
    PurposeGroup,
    SectionPublicStatus,
    SectionStatus,
    SpecialFeature,
    SupportType,
    WallClearance,
    WidthClass
} from './properties';

export function getManagedLabel(isManaged) {
    return isManaged ? 'Eigener Gerüstabschnitt' : 'Fremder Gerüstabschnitt';
}

export const getPublicStatusLabel = (publicStatus) => {
    if (!publicStatus) {
        return <NotAvailablePlaceholder />;
    }

    switch (publicStatus) {
        case SectionPublicStatus.UnderConstruction:
            return 'Gesperrt: Im Aufbau';
        case SectionPublicStatus.Open:
            return 'Freigegeben';
        case SectionPublicStatus.UnderMaintenance:
            return 'Gesperrt: Wartung / Instandsetzung';
        case SectionPublicStatus.BeingAltered:
            return 'Gesperrt: Umbauarbeiten';
        case SectionPublicStatus.Closed:
            return 'Gesperrt';
        case SectionPublicStatus.Dismantled:
            return 'Abgebaut';
        case SectionPublicStatus.Unknown:
            return 'Unbekannt';

        default:
            return `Unbekannt (${publicStatus})`;
    }
}

export const getStatusLabel = (status, fallback = <NotAvailablePlaceholder />) => {
    if (!status) {
        return fallback;
    }

    switch (status) {
        case SectionStatus.Pending:
            return 'Ausstehend';

        case SectionStatus.UnderConstruction:
            return 'Gesperrt: Im Aufbau';

        case SectionStatus.Draft:
            return 'In Planung';

        case SectionStatus.Constructed:
            return 'Freigegeben';

        case SectionStatus.Closed:
            return 'Gesperrt';

        case SectionStatus.UnderMaintenance:
            return 'Gesperrt: Wartung / Instandsetzung';

        case SectionStatus.Dismantled:
            return 'Abgebaut';

        case SectionStatus.Unknown:
            return 'Unbekannt';

        case SectionStatus.BeingAltered:
            return 'Gesperrt: Umbauarbeiten';

        default:
            return `Unbekannt (${status})`;
    }
};

export const getPurposeGroupsLabel = (purposes, fallback = <NotAvailablePlaceholder />) => {
    if (!purposes || !purposes.length) {
        return fallback;
    }

    const workAndProtectiveScaffolding = [PurposeGroup.WorkScaffolding, PurposeGroup.ProtectiveScaffolding];
    let groups = determinePurposeGroups(purposes);
    let prefix = '';
    if (_.difference(workAndProtectiveScaffolding, groups).length === 0) {
        groups = _.difference(groups, workAndProtectiveScaffolding);
        prefix = 'Arbeits- und Schutzgerüst';
    }

    const groupLabels = groups
        .map(getPurposeGroupLabel);

    if (prefix) {
        groupLabels.unshift(prefix);
    }

    return groupLabels.join(', ');
};

export const getPurposeGroupLabel = (purposeGroup) => {
    switch (purposeGroup) {
        case PurposeGroup.WorkScaffolding:
            return 'Arbeitsgerüst';
        case PurposeGroup.ProtectiveScaffolding:
            return 'Schutzgerüst';
        case PurposeGroup.MobileScaffolding:
            return 'Fahrgerüst';
        case PurposeGroup.LadderAscent:
            return 'Einzelfelder als Aufstieg';
        case PurposeGroup.PlatformStairway:
            return 'Podesttreppe';
        case PurposeGroup.SiteStaircase:
            return 'Bautreppe';
        case PurposeGroup.EmergencyStairway:
            return 'Fluchttreppe';
        default:
            return `Unbekannt (${purposeGroup})`;
    }
};

export const getPurposeLabel = (purpose, fallback = 'n.a.') => {
    if (!purpose) {
        return fallback;
    }

    switch (purpose) {

        /* Arbeitsgerüste */
        case Purpose.FacadeScaffolding:
            return 'Fassadengerüst';
        case Purpose.InteriorBirdcageScaffolding:
            return 'Raumgerüst';
        case Purpose.BirdcageScaffolding:
            return 'Flächengerüst';

        /* Schutzgerüste */
        case Purpose.SafetyCatchScaffolding:
            return 'Fanggerüst';
        case Purpose.RoofSafetyCatchScaffolding:
            return 'Dachfanggerüst';
        case Purpose.OtherProtectiveScaffolding:
            return 'Andere';

        /* Leiteraufstieg */
        case Purpose.LadderAscent:
            return 'Einzelfelder als Aufstieg';

        /* Podesttreppen */
        case Purpose.PlatformStairwayW06:
            return 'Podesttreppe W06';
        case Purpose.PlatformStairwayW09:
            return 'Podesttreppe W09';

        /* Bautreppe */
        case Purpose.SiteStaircase:
            return 'Bautreppe';

        /* Fluchttreppen */
        case Purpose.EmergencyStairway500:
            return 'Fluchttreppe 500';
        case Purpose.EmergencyStairway750:
            return 'Fluchttreppe 750';

        default:
            return `Unbekannt (${purpose})`;
    }
};

export function getProtectivePurposesLabel(protectivePurposes, otherProtectivePurpose, fallback = 'n.a.') {
    if (!protectivePurposes || !protectivePurposes.length) {
        return fallback;
    }

    return protectivePurposes
        .map(purpose => {
            let label = getPurposeLabel(purpose);
            if (purpose === Purpose.OtherProtectiveScaffolding) {
                label = `${label}: ${otherProtectivePurpose || 'n.a.'}`;
            }
            return label;
        })
        .join(', ');
}

export const getConstructionTypeLabel = (constructionType, fallback) => {
    if (!constructionType) {
        return fallback;
    }

    switch (constructionType) {
        case ConstructionType.ModuleSystemScaffolding:
            return 'Modulsystem';
        case ConstructionType.FrameScaffolding:
            return 'Rahmengerüst';
        case ConstructionType.SteelPipeScaffolding:
            return 'Stahlrohrkupplungsgerüst';
        case ConstructionType.PrefabricatedComponents:
            return 'Vorgefertigte Bauteile';
        case ConstructionType.SystemFree:
            return 'Systemfrei';
        default:
            return `Unbekannt (${constructionType})`;
    }
};

export const getEquippedSurfaceLabel = (equippedSurface) => {
    switch (equippedSurface) {
        case EquippedSurface.Front:
            return 'Vorne';
        case EquippedSurface.Back:
            return 'Hinten';
        case EquippedSurface.Left:
            return 'Links';
        case EquippedSurface.Right:
            return 'Rechts';
        default:
            return `Unbekannt (${equippedSurface})`;
    }
};

export const getStabilityAndLoadSafetyLabel = (specialConstruction, staticsCertificateNecessary, fallback = 'n.a.') => {
    if (!specialConstruction && !staticsCertificateNecessary) {
        return fallback;
    }

    const specifications = [
        { value: specialConstruction, label: 'Sonderkonstruktion' },
        { value: staticsCertificateNecessary, label: 'Statiknachweis erforderlich' },
    ];

    return specifications.filter(item => !!item.value).map(item => item.label).join(', ');
}

export const getSpecialFeatureLabel = (specialFeature) => {
    switch (specialFeature) {
        case SpecialFeature.ProtectiveRoof:
            return 'Schutzdach';
        case SpecialFeature.FallProtection:
            return 'Fangschutz';
        case SpecialFeature.PedestrianTunnel:
            return 'Fußgängertunnel';
        case SpecialFeature.StairTower:
            return 'Treppenturm';
        case SpecialFeature.AccessHatch:
            return 'Durchstieg';
        case SpecialFeature.Anchoring:
            return 'Verankerung';
        case SpecialFeature.Detached:
            return 'Freistehende Gerüstkonstruktion';
        case SpecialFeature.LatticeGirder:
            return 'Gitterträger';
        case SpecialFeature.DoubleGuardRails:
            return 'Innenschutz doppelt';
        case SpecialFeature.TripleGuardRails:
            return 'Innenschutz dreiteilig';
        case SpecialFeature.Bracket:
            return 'Konsole';
        case SpecialFeature.CraneMovable:
            return 'Kranversetzbar';
        default:
            return `Unbekannt (${specialFeature})`;
    }
};

export const getPlannedWorkLabel = (plannedWork) => {
    switch (plannedWork) {
        case PlannedWork.Roofing:
            return 'Dachdeckerarbeiten';
        case PlannedWork.Painting:
            return 'Malerarbeiten';
        case PlannedWork.Insulation:
            return 'Wärmedämmung';
        case PlannedWork.Plumbing:
            return 'Klempnerarbeiten';
        case PlannedWork.Bricklaying:
            return 'Maurerarbeiten';
        case PlannedWork.Other:
            return 'Sonstiges';
        default:
            return `Unbekannt (${plannedWork})`;
    }
};

export const getCladdingLabel = (cladding) => {
    switch (cladding) {
        case Cladding.Tarp:
            return 'Plane';
        case Cladding.AttikaHousing:
            return 'Attika-Einhausung';
        case Cladding.Canopy:
            return 'Wetterschutzdach';
        case Cladding.Net:
            return 'Netze';
        case Cladding.WinterConstructionFoil:
            return 'Winterbaufolie';
        case Cladding.FixedTrim:
            return 'Festverkleidung';
        default:
            return `Unbekannt (${cladding})`;
    }
};

export const wallClearanceLabel = (wallClearance) => {
    switch (wallClearance) {
        case WallClearance.Below300MM:
            return '< 30cm';
        case WallClearance.Exactly300MM:
            return '30cm';
        case WallClearance.Above300MM:
            return 'Andere oder genauere Angabe:';
        default:
            return `Unbekannt (${wallClearance})`;

    }
};

export const widthClassLabel = (widthClass) => {
    switch (widthClass) {
        case WidthClass.W06:
            return 'W06';
        case WidthClass.W09:
            return 'W09';
        default:
            return `Unbekannt (${widthClass})`;
    }
};

export const loadClassLabel = (loadClass) => {
    switch (loadClass) {
        case LoadClass.LC1:
            return '1';
        case LoadClass.LC2:
            return '2';
        case LoadClass.LC3:
            return '3';
        case LoadClass.LC4:
            return '4';
        case LoadClass.LC5:
            return '5';
        case LoadClass.LC6:
            return '6';
        default:
            return `Unbekannt (${loadClass})`;
    }
};

export const bracketLabel = (bracket) => {
    switch (bracket) {
        case Bracket.MM700:
            return '700mm';
        case Bracket.MM300:
            return '300mm';
        default:
            return `Unbekannt (${bracket})`;
    }
};

export function getSupportTypeLabel(supportType) {
    switch (supportType) {
        case SupportType.Standing:
            return 'Standgerüst';
        default:
            return `Unbekannt (${supportType})`;
    }
}
