import React from 'react';
import { ConstructionPlanModelMode, ConstructionPlanReferenceMode } from '../properties';

export const getMediaCollectionData = (modelMediaCollection, mediaLiveData, mode) => {

    if (!modelMediaCollection) {
        return;
    }

    return modelMediaCollection
        .map(modelMediaItem => getMediaItemData(modelMediaItem, mediaLiveData, mode));
};

export const getMediaItemData = (modelMediaItem, mediaLiveData, mode) => {
    // Mode is (ordered by priority) the media items mode, the global mode or when in doubt 'reference'
    let _mode = modelMediaItem.mode || mode || ConstructionPlanModelMode.Reference;

    const liveMediaItem = mediaLiveData.find(liveMediaItem => liveMediaItem.id === modelMediaItem.id);

    return {
        ...liveMediaItem,
        ...modelMediaItem,
        mode: _mode
    };
};

export const getInitialConstructionFormSelection = (constructionPlanMode, selectedModelId) => {
    switch (constructionPlanMode) {
        case ConstructionPlanModelMode.Edit:
            return ConstructionPlanReferenceMode.Single;
        case ConstructionPlanModelMode.Reference:
            return selectedModelId ? ConstructionPlanReferenceMode.Single : ConstructionPlanReferenceMode.All;
        case ConstructionPlanModelMode.New:
        default:
            return undefined;
    }
};
