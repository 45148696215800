import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../../../Common/Tooltip';

export const SyncOptionHintType = {
    Info: 'info',
    Warning: 'warning'
};

const SyncChoiceOptionHint = ({ tooltipId, content, label, type }) => {

    const classes = ['sync-option__hint'];

    switch (type) {

        case SyncOptionHintType.Warning:
            classes.push('sync-option__hint--warning');
            break;
        case SyncOptionHintType.Info:
        default:
            classes.push('sync-option__hint--info');
            break;
    }

    return (
        <span className={classes.join(' ')}>
            <Tooltip label={label} id={tooltipId}>
                {content}
            </Tooltip>
        </span>
    );
};

SyncChoiceOptionHint.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    label: PropTypes.string,
    type: PropTypes.oneOf([SyncOptionHintType.Info, SyncOptionHintType.Warning])
};

SyncChoiceOptionHint.defaultProps = {
    label: 'Hinweis',
    type: SyncOptionHintType.Info
};

export default SyncChoiceOptionHint;
