import React from 'react';
import Permission from '../../../permissions';
import PrivatePage from './PrivatePage';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Container from '../../Container';
import Documents from '../../../Components/Documents/Documents';

const DocumentsPage = () => (
    <PrivatePage requiredUserPermission={Permission.ReadDocument}>
        <Container>
            <TopNav />
            <PageTitle
                title="Dokumente"
            />
            <Documents />
        </Container>
    </PrivatePage>
);

export default DocumentsPage;



