import React from 'react';
import Icon from '../Common/Icon';
import { ConstructionPlanModelModePropType } from './Form/propTypes';
import { ConstructionPlanModelMode } from './properties';

function getIconName(mode) {
    switch (mode) {
        case ConstructionPlanModelMode.New:
            return 'add';
        case ConstructionPlanModelMode.Reference:
        case ConstructionPlanModelMode.Edit:
            return 'plannerExisting';
        default:
            return null;
    }
}

const ConstructionPlanModelModeIcon = ({ mode }) => {

    const iconName = getIconName(mode);
    if (!iconName) {
        return null;
    }

    return <Icon name={iconName} />;
};

ConstructionPlanModelModeIcon.propTypes = {
    mode: ConstructionPlanModelModePropType
};

ConstructionPlanModelModeIcon.defaultProps = {
    mode: undefined
};

export default ConstructionPlanModelModeIcon;
