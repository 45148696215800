import PropTypes from 'prop-types';
import React from 'react';
import { DataTable } from '../../Common/Table/DataTable';

export const ReferencedJobsTableBase = ({ data, columns, RowComponent, emptyTableHint, entityLabel, footerLink, onItemChange }) => (
    <DataTable
        columns={columns}
        RowComponent={RowComponent}
        data={data}
        entityLabel={entityLabel}
        renderEmptyTableHint={() => emptyTableHint}
        noDeletion
        itemsToShow={5}
        footerLink={footerLink}
        onItemChange={onItemChange}
        isFetching={false}
    />
);

ReferencedJobsTableBase.propTypes = {
    data: PropTypes.array.isRequired,
    onItemChange: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    RowComponent: PropTypes.func.isRequired,
    emptyTableHint: PropTypes.element.isRequired,
    entityLabel: PropTypes.string,
    footerLink: PropTypes.node,
};
