import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast as toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { getPublicDownloadUrl } from '../../redux/modules/media/action';
import { autoFormatFileSize } from '../../utils';
import Icon from './Icon';
import LightBoxLink from './LightBoxLink';

class DownloadLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requesting: false
        };
        this.download = this.download.bind(this);
    }

    download(id) {
        this.setState({ requesting: true });
        this.props.actions.getPublicDownloadUrl(id)
            .then(url => window.open(url, '_blank'))
            .catch(() => {
                toast.error('Download konnte nicht gestartet werden');
            })
            .finally(() => {
                this.setState({ requesting: false });
            });
    }

    renderLink() {
        const { requesting } = this.state;
        const { attachment, showFileVisibility } = this.props;

        let fileVisibility;

        if (showFileVisibility) {
            if (attachment.visibility === 'public') {
                fileVisibility = <span> <Icon name="unlocked" /> Öffentlich</span>;
            } else {
                fileVisibility = <span> <Icon name="locked" /> Intern</span>;
            }
        }

        const name = attachment.name ? attachment.name : attachment.fileName;
        const fileName = attachment.name && attachment.name !== attachment.fileName ? attachment.fileName : false;

        return (
            <React.Fragment>
                <div>
                    <LightBoxLink media={attachment}>
                        <span className="download-link__name">
                            {name}
                        </span>
                    </LightBoxLink>
                    <span
                        className="download-link__filename"
                    >{fileName ? `${fileName} - ` : null}{autoFormatFileSize(attachment.size)}

                    </span>
                </div>
                <div className="download-link__meta">
                    {showFileVisibility &&
                    <div className="download-link__visibility">
                        {fileVisibility}
                    </div>
                    }

                    <a
                        role="button"
                        className="download-link__link"
                        onClick={() => this.download(attachment.id)}
                    >
                        {requesting ? <i className="btn__spinner" /> : <Icon name="download" />}
                    </a>
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { downloadLinkError } = this.state;
        const { attachment } = this.props;

        return (
            <div className="download-link" key={attachment.id}>
                <div className="download-link__ctn">
                    {this.renderLink()}
                    {downloadLinkError && <small>{downloadLinkError}</small>}
                </div>
            </div>
        );
    }

}

DownloadLink.propTypes = {
    attachment: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string
    }).isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getPublicDownloadUrl
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(DownloadLink);
