import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFormModelModeActions from '../Common/ConstructionPlanFormModelModeActions';

const ConstructionPlanFormSectionModeActions = ({ onAddNew, onAddExisting, sectionsLeftToReference }) => (
    <ConstructionPlanFormModelModeActions
        addNewLabel="Neuen Gerüstabschnitt erstellen"
        addExistingLabel="Bestehenden Gerüstabschnitt wählen"
        onAddExisting={onAddExisting}
        onAddNew={onAddNew}
        modelsLeftToReference={sectionsLeftToReference}
    />
);

ConstructionPlanFormSectionModeActions.propTypes = {
    onAddNew: PropTypes.func.isRequired,
    onAddExisting: PropTypes.func.isRequired,
    sectionsLeftToReference: PropTypes.bool
};

ConstructionPlanFormSectionModeActions.defaultProps = {
    sectionsLeftToReference: false
};

export default ConstructionPlanFormSectionModeActions;
