import React from 'react';

const Changelog_1_20_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>
                    Verbesserte Auftragsübersicht
                </h4>
                <p>
                    Die Übersichtsseite zu Aufträgen lässt sich ab sofort nach
                    Erstellungsdatum sortieren.
                </p>

                <h4>
                    Weitere Möglichkeiten zur Spezifikation des Anfragestatus
                </h4>
                <p>
                    Der Status einer Anfrage kann ab sofort zusätzlich mit "Anfrage versendet",
                    "Anfrage vom Gerüstersteller abgelehnt" sowie "Angebot vom Kunden abgelehnt"
                    spezifiziert werden.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>Auslaufende Fristen zur Angebotsabgabe werden auf der Detailseite einer
                        Anfrage angezeigt
                    </li>
                    <li>Das Erstellungsdatum einer Anfrage / eines Auftrags ist auf der jeweiligen
                        Detailseite einsehbar
                    </li>
                    <li>Ab sofort können GAEB- und Mov-Dateien hochgeladen werden</li>
                    <li>Für den Anprechpartner eines Kunden kann zusätzlich eine Handynummer hinterlegt
                        werden
                    </li>
                    <li>Weitere kleinere Verbesserungen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_20_0;
