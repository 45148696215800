import _ from 'lodash';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { validateEmail } from '../../utils';
import Checkbox from '../Forms/Checkbox';
import FormHint from '../Forms/FormHint';
import Input from '../Forms/Input';
import InputGroup from '../Forms/InputGroup';
import InputHint from '../Forms/InputHint';
import LinkButton from '../Common/LinkButton';
import Modal from '../Common/Modal/Modal';
import ModalTooltip from '../Common/ModalTooltip';

const VIEW_MODES = {
    edit: 'edit',
    add: 'add',
    list: 'list'
};

class InvitationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false,
            invitees: [],
            currentInvitee: {}
        };

        this.renderInviteesList = this.renderInviteesList.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.addInvitee = this.addInvitee.bind(this);
        this.removeInviteeById = this.removeInviteeById.bind(this);
        this.handleInternalChange = this.handleInternalChange.bind(this);
        this.validate = this.validate.bind(this);
        this.openFreshModal = this.openFreshModal.bind(this);
    }

    static showPermissions(invitee) {
        const { allowInspections, allowCloseRequests } = invitee;

        let inspectionPrefix = '(Basic/Pro)';
        let allowCloseRequestsText = '';

        if (allowInspections) {
            inspectionPrefix = '(Alle)';
        }

        if (allowCloseRequests) {
            allowCloseRequestsText = '+ Sperranfragen';
        }

        return `Prüfen ${inspectionPrefix} ${allowCloseRequestsText}`;
    }

    // Will be set in the render method
    handleSubmit = () => false;

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                invitees: this.props.value
            });
        }
    }

    validate(values) {
        const errors = {};

        const { currentUserEmail } = this.props;

        if (!values.inviteeEmail) {
            errors.inviteeEmail = 'Bitte geben Sie eine E-Mail Adresse ein';
        } else if (!validateEmail(values.inviteeEmail)) {
            errors.inviteeEmail = `"${values.inviteeEmail}" ist keine gültige E-Mail Adresse`;
        } else if (currentUserEmail === values.inviteeEmail) {
            errors.inviteeEmail = 'Sie haben Ihre eigene E-Mail Adresse angegeben. Einladungen zu eigenen Baustellen sind nicht möglich. Bitte geben Sie eine E-Mail Adresse an, die mit keinem Nutzer Ihres Scaffeye-Kontos verknüpft ist.';
        }

        return errors;
    }

    handleInternalChange() {
        const { invitees } = this.state;
        this.props.onChange(invitees);
    }

    onSubmit(values) {
        const { viewMode, currentInvitee } = this.state;

        const invitee = {};
        invitee.allowInspections = values.allowInspections === true;
        invitee.allowCloseRequests = values.allowCloseRequests === true;
        invitee.inviteeEmail = values.inviteeEmail;

        if (viewMode === VIEW_MODES.edit) {
            this.updateInvitee(currentInvitee.id, invitee);
        } else {
            invitee.id = _.uniqueId('temp_');
            this.addInvitee(invitee);
        }
    }

    addInvitee(invitee) {
        this.setState(prevState => ({
            invitees: [...prevState.invitees, invitee]
        }), () => {
            this.toggleModal();
            this.handleInternalChange();
        });
    }

    updateInvitee(id, updatedInviteeData) {
        const { invitees } = this.state;

        const updatedInvitees = invitees.map((invitee) => {
            if (invitee.id === id) {
                const updatedInvitee = {
                    ...invitee,
                    ...updatedInviteeData
                };
                return updatedInvitee;
            }
            return invitee;
        });

        this.setState(() => ({
            invitees: updatedInvitees,
            viewMode: VIEW_MODES.list,
            currentInvitee: {}
        }), () => {
            this.toggleModal();
            this.handleInternalChange();
        });
    }

    removeInviteeById(id) {
        this.setState(prevState => ({
            invitees: prevState.invitees.filter(invitee => invitee.id !== id),
            currentInvitee: {}
        }), () => {
            this.handleInternalChange();
        });
    }

    renderModal() {
        const { viewMode } = this.state;

        const initialValues = this.state.currentInvitee;

        return (
            <Modal
                title={viewMode === VIEW_MODES.edit ? 'Einladung bearbeiten' : 'Nutzer einladen'}
                onBackdropClick={this.toggleModal}
                footer={(
                    <div className="btn-group">
                        <button
                            className="btn btn--backward-action"
                            onClick={this.toggleModal}
                        >
                            Abbrechen
                        </button>
                        <span className="btn btn--dummy">*Pflichtfeld</span>
                        <button
                            type="submit"
                            className="btn btn--forward-action btn--save"
                            onClick={(e) => this.handleSubmit(e)}
                        >
                            Speichern
                        </button>
                    </div>
                )}
            >
                <Form
                    onSubmit={this.onSubmit}
                    initialValues={initialValues}
                    validate={this.validate}
                    render={({ handleSubmit }) => {
                        this.handleSubmit = handleSubmit;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="site-invitations">

                                    <FormHint initial={true}>
                                        Hier können Sie externe Nutzer (Nutzer die ihrem Scaffeye-Unternehmens-Konto
                                        nicht angehören) zur Baustelle einladen. <br/>
                                        Diese bekommen dadurch Zugriff auf die öffentlichen Informationen der
                                        Baustelle und der darauf befindlichen Gerüste und Gerüstabschnitte.
                                        Zusätzlich können Sie bei Bedarf einzelnen Nutzern das Recht zur
                                        Prüfung und die Berechtigung zum Verfassen einer Sperranfrage geben.
                                    </FormHint>

                                    <InputGroup
                                        label="E-Mail*"
                                    >
                                        <Input
                                            type="email"
                                            name="inviteeEmail"
                                            placeholder="E-Mail Adresse eingeben"
                                            disabled={viewMode === VIEW_MODES.edit}
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Berechtigungen"
                                    >
                                        <div className="site-invitations__permission-group">
                                            <div
                                                className="site-invitations__permission-group-heading"
                                            >Gerüstabschnitte prüfen
                                            </div>
                                            <div
                                                className="site-invitations__permission-group-options"
                                            >
                                                <label
                                                    className="input-grp__optionlabel input-grp__optionlabel--preselected"
                                                >
                                                    <input type="checkbox" disabled checked />
                                                    Berechtigung bei Basic- oder Pro-Konto erteilen
                                                    <ModalTooltip id="hint-1">
                                                        Basic- oder Pro-Nutzer besitzen stets die
                                                        Berechtigung, Prüfungen auf Gerüstabschnitten
                                                        durchzuführen.
                                                    </ModalTooltip>


                                                </label>
                                                <div>
                                                    <Checkbox
                                                        name="allowInspections"
                                                        label="Berechtigung bei Light-Konto erteilen"
                                                        tooltip={
                                                            <ModalTooltip id="hint-2">
                                                                Falls der Nutzer, den Sie hiermit
                                                                zur
                                                                Ihrer Baustelle einladen, ein
                                                                Light-Konto besitzt, können Sie
                                                                entscheiden, ob Sie diesem Nutzer
                                                                die
                                                                Berechtigung zur Prüfung Ihrer
                                                                Gerüstabschnitte erteilen möchten oder
                                                                lediglich einen lesenden Zugriff auf
                                                                die
                                                                Baustelle gewähren.
                                                            </ModalTooltip>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="site-invitations__permission-group">
                                            <div
                                                className="site-invitations__permission-group-heading"
                                            >
                                                Sperranfrage senden
                                            </div>
                                            <div
                                                className="site-invitations__permission-group-options"
                                            >
                                                <Checkbox
                                                    name="allowCloseRequests"
                                                    label="Berechtigung erteilen"
                                                    tooltip={
                                                        <ModalTooltip id="hint-3">
                                                            Hiermit können Sie entscheiden, ob der
                                                            eingeladene Nutzer Ihnen Sperranfragen
                                                            für
                                                            Ihre Gerüstabschnitte senden darf.
                                                            Empfehlung: Aktivieren Sie diese
                                                            Funktion,
                                                            wenn Ihr eingeladener Nutzer ein Prüfer
                                                            oder
                                                            Gutachter ist, der Ihre Baustelle
                                                            kontrollieren soll. Somit erhalten Sie
                                                            ein
                                                            schnelleres Feedback bei Mängeln.
                                                        </ModalTooltip>
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <InputHint>
                                            Geben Sie hier an, welche Aktionen der eingeladene
                                            Nutzer auf Ihrer Baustelle ausführen darf.
                                        </InputHint>
                                    </InputGroup>
                                </div>
                            </form>
                        )
                    }}
                />
            </Modal>
        );
    }

    openFreshModal() {
        this.setState({
            currentInvitee: {},
            viewMode: VIEW_MODES.add
        }, () => this.toggleModal());
    }

    toggleModal() {
        this.setState(prevState => ({
            modalIsOpen: !prevState.modalIsOpen
        }));
    }

    setCurrentInvitee(invitee) {
        this.setState({
            currentInvitee: invitee,
            viewMode: VIEW_MODES.edit
        }, () => this.toggleModal());
    }

    renderInviteesList() {
        const { invitees } = this.state;

        if (!invitees) {
            return null;
        }

        const listOfInvitees = invitees.map((invitee, index) => (
            <div className="site-invitees__list-item" key={index}>
                <div className="site-invitees__invitee-info">
                    <span
                        className="site-invitees__invitee-email"
                    >
                        {invitee.inviteeEmail}
                    </span>
                    <span
                        className="site-invitees__btn site-invitees__btn--edit"
                        onClick={() => this.setCurrentInvitee(invitee)}
                    >
                            Bearbeiten
                    </span>
                    <span
                        className="site-invitees__btn site-invitees__btn--delete"
                        onClick={() => this.removeInviteeById(invitee.id)}
                    >
                        Entfernen
                    </span>
                </div>
                <div>
                    <div className="site-invitees__invitee-permissions">
                        {InvitationModal.showPermissions(invitee)}
                    </div>
                </div>
            </div>
        ));

        return (
            <div className="site-invitees__list">
                <span>{listOfInvitees}</span>
            </div>
        );
    }

    renderControls() {
        return (
            <LinkButton
                onClick={this.openFreshModal}
                label="Nutzer zur Baustelle einladen"
            />
        );
    }

    render() {
        const { modalIsOpen } = this.state;

        if (modalIsOpen) {

            return (
                <React.Fragment>
                    {this.renderModal()}
                </React.Fragment>
            );
        }

        return (
            <div className="site-invitees">
                {this.renderControls()}
                {this.renderInviteesList()}
            </div>
        );
    }

}


export default InvitationModal;
