import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../../../Common/Tooltip';

const Invitation = ({ id, wrapperClass, icon, tooltipContent, heading, children }) => (
    <div className={['site-invitation', wrapperClass].join(' ')}>
        <div className="site-invitation__icon">
            <Tooltip
                id={`inv-${id}`}
                icon={icon}
            >
                {tooltipContent}
            </Tooltip>
        </div>
        <div className="site-invitation__ct">
            <div className="site-invitation__hd">
                {heading}
            </div>
            <div className="site-invitation__meta">
                {children}
            </div>
        </div>
    </div>
);

export default Invitation;


Invitation.propTypes = {
    heading: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    tooltipContent: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    wrapperClass: PropTypes.string
};
