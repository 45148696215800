import PropTypes from 'prop-types';
import React, { Component } from 'react';
import JobsTableHeader from '../../Job/Overview/JobsTableHeader';
import SortLink from '../../Job/SortLink';

class SitesTableHeader extends Component {

    constructor(props) {
        super(props);
        this.sortData = this.sortData.bind(this);
        this.getClasses = this.getClasses.bind(this);
    }

    getClasses(fieldValue) {
        const selectedClass = this.props.sort.field === fieldValue ? 'selected' : '';
        return `${selectedClass} sort__link`;
    }

    getInvertedDirection() {
        return this.props.sort.direction === '-' ? '+' : '-';
    }

    sortData(field) {

        const { sort, onChange } = this.props;

        let direction = '+';
        if (sort.field === field) {
            direction = this.getInvertedDirection();
        }

        onChange(field, direction);
    }

    render() {

        const { archived, sort: { field, direction } } = this.props;

        return (
            <thead>
            <tr>
                <th>
                    <SortLink
                        to="#"
                        field="name"
                        label="Bezeichnung"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                <th>
                    <SortLink
                        to="#"
                        field="address"
                        label="Adresse"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                {archived &&
                <th>
                    <SortLink
                        to="#"
                        field="archivedAt"
                        label="Archiviert am"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                }
            </tr>
            </thead>
        );
    }
}

export default SitesTableHeader;

JobsTableHeader.propTypes = {
    onChange: PropTypes.func
};

JobsTableHeader.defaultProps = {
    onChange: () => false
};
