import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ModelType } from '../../../../properties';
import { autoFormatFileSize } from '../../../../utils';
import DeleteModal from '../../../Common/Modal/DeleteModal';
import DownloadIcon from '../../../Common/DownloadIcon';
import FileExtension from '../../../Common/FileExtension';
import Icon from '../../../Common/Icon';
import LightBox from '../../../Common/LightBox';
import { MediaPropType, ModelMediaType } from '../../../Media/properties';
import JobCorrespondenceUploadModal from '../JobCorrespondenceUploadModal';

export default function JobCorrespondenceTimelineItem({ data: mediaRenderData, onEdit, onDelete, readOnly }) {

    const { name, fileName, size, id, visibility, mimeType } = mediaRenderData;

    const correspondenceMediaItem = {
        id,
        name,
        fileName,
        visibility,
        size,
        mimeType,
        modelType: ModelType.Job,
        modelMediaType: ModelMediaType.Job_Correspondence
    };

    const [previewIsVisible, showPreview] = useState(false);
    const [editModalIsVisible, showEditModal] = useState(false);
    const [deleteModalIsVisible, showDeleteModal] = useState(false);

    const internalOnDelete = () => {
        showDeleteModal(false);
        onDelete(id);
    };

    return (
        <div className="job-correspondence-timeline__item">

            <div className="job-correspondence-timeline__file-info">
                <span className="job-correspondence-timeline__file-name">{name}</span>
                <span className="job-correspondence-timeline__file-specs">
                    <span className="job-correspondence-timeline__upload-file-name"
                    >{fileName}</span>{' '}(<FileExtension fileName={fileName}
                />, {autoFormatFileSize(size)})
                </span>
            </div>
           <div className="job-correspondence-timeline__actions">
               {!readOnly &&  <div className="hidden-actions">
                    <div className="hidden-actions__bd">
                         <span
                             className="job-correspondence-timeline__action job-correspondence-timeline__action--delete"
                             onClick={() => showDeleteModal(true)}
                         >
                             Entfernen
                         </span>
                        <span
                            className="job-correspondence-timeline__action job-correspondence-timeline__action--edit"
                            onClick={() => showEditModal(true)}
                        >
                            Bearbeiten
                        </span>
                    </div>
                    <div className="hidden-actions__dots">
                        <span className="hidden-actions__dot" />
                        <span className="hidden-actions__dot" />
                        <span className="hidden-actions__dot" />
                    </div>
                </div> }
                <button
                    className="btn--dummy job-correspondence-timeline__action job-correspondence-timeline__action--download"
                    title="Datei herunterladen"
                >
                    <DownloadIcon
                        mediaId={id}
                    />
                </button>
                <button
                    className="btn--dummy job-correspondence-timeline__action job-correspondence-timeline__action--preview"
                    title="Vorschau"
                    onClick={() => showPreview(true)}
                >
                    <Icon name="filePreview" />
                </button>
            </div>
            {previewIsVisible &&
            <LightBox media={mediaRenderData} onClose={() => showPreview(false)} />}

            {editModalIsVisible &&
            <JobCorrespondenceUploadModal
                onSaved={onEdit}
                onClose={() => showEditModal(false)}
                correspondenceMediaItem={correspondenceMediaItem}
            />}

            {deleteModalIsVisible &&
            <DeleteModal
                modalTitle="Korrespondenz löschen"
                deleteEntity={internalOnDelete}
                closeModal={() => showDeleteModal(false)}
            >
                <p>
                    Sie sind im Begriff, die Korrespondenz <br />
                    <strong> {name} </strong> <br />
                    zu löschen.
                </p>
                <p>Möchten Sie fortfahren?</p>
            </DeleteModal>
            }

        </div>
    );
}

JobCorrespondenceTimelineItem.propTypes = {
    data: MediaPropType.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
};
