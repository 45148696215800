import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';


class PublicPage extends Component {
    render() {
        if (this.props.signedIn) {
            return (
                <Redirect to="/" />
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = state => ({
    signedIn: state.currentUser.loggedIn
});

export default connect(mapStateToProps)(PublicPage);

PublicPage.propTypes = {
    children: PropTypes.node.isRequired,
    dispatch: PropTypes.func,
    signedIn: PropTypes.bool
};

PublicPage.defaultProps = {
    dispatch: () => false,
    signedIn: false
};
