import React from 'react';
import Permission from '../../permissions';
import MainNavigationMenuItem from './MainNavigationMenuItem';

const mainMenuItems = [
    {
        label: 'Übersicht',
        to: '/',
        exact: true
    },
    {
        label: 'Anfragen',
        to: '/requests',
        requiredPermission: [Permission.ReadJobs]
    },
    {
        label: 'Aufträge',
        to: '/jobs',
        requiredPermission: [Permission.ReadJobs]
    },
    {
        label: 'Kunden',
        to: '/customers',
        requiredPermission: [Permission.ReadCustomers]
    },
    {
        label: 'Baustellen',
        to: '/sites',
        requiredPermission: [Permission.ReadSites],
        isActive: (match, location) => match
            || (
                location && location.pathname && (
                    location.pathname.indexOf('/scaffoldings') === 0
                    || location.pathname.indexOf('/sections') === 0
                    || location.pathname.indexOf('/archive/sites') === 0
                    || location.pathname.indexOf('/archive/scaffoldings') === 0
                    || location.pathname.indexOf('/archive/sections') === 0
                    || location.pathname.indexOf('/diary') === 0
                )
            )
    },
    {
        label: 'Allg. Dokumente',
        to: '/documents'
    }
];

const MainNavigation = () => {

    return (
        <div className="main-navigation">

            <div className="main-navigation__cnt">
                {
                    mainMenuItems.map((menuItem, index) => (
                        <MainNavigationMenuItem key={index} {...menuItem} />))
                }
            </div>
        </div>
    );
};

export default MainNavigation;

