import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import ExpandableTextArea from '../Common/ExpandableTextArea';
import FieldError from './FieldError';

const TextArea = (props) => {

    const { name, limit, readOnly, placeholder } = props;

    return (
        <Field
            name={name}
        >
            {({ input, meta }) => {

                const inputCssClasses = ['input-grp__field'];

                if (meta.error) {
                    inputCssClasses.push('is-invalid');
                } else {

                    inputCssClasses.push('is-valid');
                }
                if (meta.touched) {

                    inputCssClasses.push('is-dirty');
                } else {

                    inputCssClasses.push('is-pristine');
                }

                return (
                    <Fragment>
                        <ExpandableTextArea
                            {...input}
                            limit={limit}
                            readOnly={readOnly}
                            placeholder={placeholder}
                        />
                        <FieldError meta={meta} />
                    </Fragment>
                );
            }}
        </Field>
    );
};

export default TextArea;




