import { query } from '../../action';
import * as types from '../../types';

export const getCustomersFailure = err => ({
    type: types.FETCH_CUSTOMERS_FAILURE,
    error: err
});

/**
 * fetch all customers
 */

export const fetchCustomers = (args = {}) => (dispatch, getState) => {
    const token = getState().currentUser.auth_token;
    return internalFetchCustomers(token, args);
};

function internalFetchCustomers(token, args = {}) {
    return query(`FetchCustomers($page:Int, $sort:CustomerQuerySortEnum, $limit:Int, $sortAsc:Boolean, $name:String, $contact:String, $address:String) {
                customers(limit:$limit, page:$page, sort:$sort, sortAsc:$sortAsc, contact: $contact, name: $name, address: $address) 
                {
                    data {
                        id,
                        name,
                        zip,
                        city,
                        jobs {type, archived},
                        mainContact{phone,email,firstName,lastName},
                        customerContacts{firstName,lastName,position,isMainContact},
                        customerId,
                        line1,
                        line2,
                        jobCount
                    },
                    total,
                    per_page 
                }
            }`, {
        token,
        params: {
            page: args.page,
            limit: args.limit,
            name: args.name,
            contact: args.contactPerson,
            address: args.address,
            sortAsc: args.sortAsc,
            sort: args.sortField
        }
    })
        .then(data => data.customers);

}


/**
 * Fetch all customers for a form select
 */
export function fetchCustomerSelectOptions() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(`FetchCustomers($page:Int,$limit:Int) {
                customers(limit:$limit,page:$page,sort:name,sortAsc:true) 
                {
                    data {
                        id,
                        name,
                    },
                    total,
                    per_page
                }
            }`, {
            token,
            params: {
                page: 1,
                limit: -1
            }
        })
            .then(data => data.customers)
            .catch((error) => {
                dispatch(getCustomersFailure(error));
            });
    };
}


