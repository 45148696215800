import React from 'react';
import { components } from 'react-select';
import SelectField from '../../Forms/Select/SelectField';
import { weatherSelectionOptions } from './properties';
import { renderWeatherOption } from './utils';

const { SingleValue, Option } = components;

const WeatherOption = props => (
    <Option {...props}>
        <div className={`weather-option weather-option--${props.data.value}`}>
            <span className="weather-option__lbl">
                {renderWeatherOption(props.data.value)}
            </span>
        </div>
    </Option>
);

const WeatherValue = ({ children, ...props }) => (
    <SingleValue {...props}>
        <div className={`weather-option  weather-option --${props.data.value}`}>
            {renderWeatherOption(props.data.value)}
        </div>
    </SingleValue>
);

const ConstructionDiaryWeatherSelectField = ({ ...fieldProps }) => (
    <SelectField
        {...fieldProps}
        placeholder="Wetter wählen"
        label="Wetter"
        isSearchable
        options={weatherSelectionOptions}
        isClearable
        components={{
            Option: WeatherOption,
            SingleValue: WeatherValue
        }}
    />
);

export default ConstructionDiaryWeatherSelectField;
