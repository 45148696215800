import PropTypes from 'prop-types';
import React, { Children } from 'react';

const FilterBarRow = ({ title, children }) => {

    const renderFilterBarComponents = () => {
        return Children.map(children, (child, i) => (
            <div key={i} className="filterbar__component">
                {child}
            </div>
        ));
    };

    return (
        <div className="list-filterbar__row">
            <div className="list-filterbar__cnt">
                <div className="list-filterbar__heading">{title}</div>
                <div className="list-filterbar__components">
                    {renderFilterBarComponents()}
                </div>
            </div>
        </div>

    );
};

export default FilterBarRow;

FilterBarRow.propTypes = {
    title: PropTypes.string.isRequired
};
