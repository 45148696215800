import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { isIdenticalAddress } from '../../../equals';
import EqualSyncOptionHeight from '../../EqualSyncOptionHeight';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceIdenticalRow from '../SyncChoiceIdenticalRow';
import SyncChoiceRow from '../SyncChoiceRow';
import SyncInputGroup from '../SyncInputGroup';
import { validateSingleChoiceComplete } from '../../../validation';

const validateAddress = validateSingleChoiceComplete();

const AddressSyncChoice = ({ planValue, liveValue, value: currentFormValue, onChange, showIdentical }) => {

    const isSelected = (value) => {
        return _.isEqual(value, currentFormValue);
    };

    const renderAddressLabel = (valueObject) => {

        if (!valueObject) {
            return '-'
        }

        const { line1, line2, zip, city } = valueObject;

        return (
            <div>
                <div>{line1}</div>
                <div>{line2}</div>
                <div>{zip} {' '} {city}</div>
            </div>
        );
    };

    const renderAddress = (leftValue, rightValue, isIdentical) => {
        if (isIdentical) {
            return (
                <SyncChoiceIdenticalRow
                    leftValue={leftValue}
                    rightValue={rightValue}
                    renderValue={renderAddressLabel}
                    showIdentical={showIdentical}
                />
            );
        }

        return (
            <EqualSyncOptionHeight>
                <SyncChoiceRow
                    leftValue={leftValue}
                    rightValue={rightValue}
                    onSelect={onChange}
                    isSelected={isSelected}
                    renderValue={renderAddressLabel}
                />
            </EqualSyncOptionHeight>
        );
    };

    const leftValue = planValue;
    const rightValue = liveValue;

    const isIdentical = isIdenticalAddress(leftValue, rightValue);

    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Adresse"
        >
            {renderAddress(leftValue, rightValue, isIdentical)}
        </SyncInputGroup>
    )
};

AddressSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    showIdentical: PropTypes.bool
};

AddressSyncChoice.defaultProps = {
    planValue: undefined,
    liveValue: undefined,
    onChange: () => false
};

export default asSyncChoiceField(AddressSyncChoice, {
    allowNull: true,
    validate: validateAddress
});
