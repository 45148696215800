import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import JobFormModal from '../../Components/Job/Form/JobFormModal';
import Permission from '../../permissions';
import { showApiError } from '../../redux/modules/error/action';
import {
    fetchDashboardConstructionDiaryEntries,
    fetchDashboardJobs,
    fetchDashboardSites
} from '../../redux/modules/sites/action';
import { userHasPermission } from '../../user';
import { isImage, truncateText } from '../../utils';
import { ModalTypes } from '../Common/AddResourceDropDown';
import DashboardTable from '../Common/DashboardTable';
import DeletedUserPlaceholder from '../Common/DeletedUserPlaceholder';
import EmptyDataTableHint from '../Common/EmptyDataTableHint';
import Icon from '../Common/Icon';
import IfUser from '../Common/IfUser';
import InvitedIndicator from '../Common/InvitedIndicator';
import PrimaryColumn from '../Common/PrimaryColumn';
import {
    DiaryEntryLink,
    ScaffoldingPageLink,
    SectionPageLink,
    SitePageLink
} from '../Common/ResourcePageLink';
import ThirdPartyIndicator from '../Common/ThirdPartyIndicator';
import Tooltip from '../Common/Tooltip';
import ConstructionDiaryEntryCategory from '../ConstructionDiary/ConstructionDiaryEntryCategory';
import Section from '../Layout/Section';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';
import InspectionResult from '../Sections/InspectionResult';
import SectionStatusBadge from '../Sections/SectionStatusBadge';
import SiteFormModal from '../Sites/Form/SiteFormModal';
import DashboardSummary from './DashboardSummary';
import DashboardSummaryCard from './DashboardSummaryCard';

const defaultDashboardJobData = {
    activeRequestsCount: 0,
    requestsWithExpiringOfferDeadline: 0,
    activeJobsCount: 0
};

const defaultDashboardSitesData = {
    activeSitesCount: 0,
    sitesWithNewComments: [],
    sitesWithWarnings: [],
    sitesWithStatusChange: []
};

const defaultDashboardConstructionDiaryEntries = [];

const getInitialDashboardData = () => {
    return {
        dashboardJobs: defaultDashboardJobData,
        dashboardSites: defaultDashboardSitesData,
        dashboardConstructionDiaryEntries: defaultDashboardConstructionDiaryEntries
    };
};

const getInitialState = () => {

    return {
        isFetchingDashboardData: false,
        showAddSiteModal: false,
        dashboardData: getInitialDashboardData()
    };
};

class Dashboard extends Component {

    static imagePreview(imageCount) {
        if ( imageCount ) {
            const label = imageCount > 1 ? 'Fotos' : 'Foto';

            return (
                <div><Icon name="image"/>{' '} {imageCount} {label} {' '} hochgeladen</div>
            );
        }
        return null;
    }

    static documentPreview(documentCount) {
        if ( documentCount ) {
            const label = documentCount > 1 ? 'Dokumente' : 'Dokument';

            return (
                <div><Icon name="document"/>{' '} {documentCount} {label} {' '} hochgeladen</div>
            );
        }
        return null;
    }

    static renderSiteCommentPreview(note) {
        let imageCount = 0;
        let documentCount = 0;

        note.attachments.forEach((attachment) => {
            if ( isImage(attachment.mimeType) ) {
                imageCount += 1;
            } else {
                documentCount += 1;
            }
        });

        return (
            <div>
                <span className="comment-preview">{truncateText(note.content, 45)}</span>
                {Dashboard.imagePreview(imageCount)}
                {Dashboard.documentPreview(documentCount)}
            </div>
        );
    }

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = getInitialState();
        this.onSiteCreated = this.onSiteCreated.bind(this);
        this.toggleAddSiteModal = this.toggleAddSiteModal.bind(this);
        this.toggleAddJobModal = this.toggleAddJobModal.bind(this);
        this.onJobCreated = this.onJobCreated.bind(this);
    }

    static renderSub(item) {
        if ( !item ) {
            return null;
        }

        if ( item.isThirdParty ) {
            if ( item.isInvited ) {
                return <InvitedIndicator size="small"/>;
            }
            return <ThirdPartyIndicator size="small"/>;
        }

        return null;
    }

    static showDiaryEntryUpdateStatus(created, updated, updatedBy) {
        if ( !created || !updated || !updatedBy ) {
            return null;
        }

        if ( created === updated ) {
            return null;
        }

        return (
            <span className="diary-entry-tooltip">
                <Tooltip id={`diary-entry-${created}`}>
                    {`Nachträglich bearbeitet von ${updatedBy.firstName} ${updatedBy.lastName}`}
                </Tooltip>
            </span>
        );
    }

    fetchDashboardData() {

        const { userPermissions, actions: { fetchDashboardSites, fetchDashboardJobs, fetchDashboardConstructionDiaryEntries } } = this.props;

        return Promise.all([
            userHasPermission(userPermissions, Permission.ReadSites) ? fetchDashboardSites() : Promise.resolve(),
            userHasPermission(userPermissions, Permission.ReadJobs) ? fetchDashboardJobs() : Promise.resolve(),
            userHasPermission(userPermissions, Permission.ReadConstructionDiaryEntries) ? fetchDashboardConstructionDiaryEntries() : Promise.resolve()
        ]);
    }

    getDashboardData([ dashboardSites, dashboardJobs, dashboardConstructionDiaryEntries ]) {

        return {
            dashboardSites: dashboardSites ? { ...dashboardSites } : defaultDashboardSitesData,
            dashboardJobs: dashboardJobs ? { ...dashboardJobs } : defaultDashboardJobData,
            dashboardConstructionDiaryEntries: dashboardConstructionDiaryEntries ? dashboardConstructionDiaryEntries : defaultDashboardConstructionDiaryEntries
        };
    }

    componentDidMount() {
        this.setState({
            isFetchingDashboardData: true
        });

        this.fetchDashboardData()
            .then((dashboardData) => {
                if ( this.loadingGotCancelled ) {
                    return;
                }

                this.setState({
                    isFetchingDashboardData: false,
                    dashboardData: this.getDashboardData(dashboardData)
                });
            })
            .catch((error) => {
                showApiError(error);
            })
            .finally(() => {
                if ( this.loadingGotCancelled ) {
                    return;
                }
                this.setState({
                    isFetchingDashboardData: false
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    static showDiaryEntryReference(scaffoldings, scaffoldingSelection, sections, sectionSelection) {
        if ( scaffoldingSelection === 'all' ) {
            return 'Alle Gerüste / Alle Gerüstabschnitte';
        }

        const singleScaffolding = _.head(scaffoldings);
        const scaffoldingName = singleScaffolding.name;

        if ( sectionSelection === 'all' ) {
            return `${scaffoldingName} / Alle Gerüstabschnitte`;
        }

        const singleSection = _.head(sections);
        const sectionName = singleSection.name;

        return `${scaffoldingName} / ${sectionName}`;
    }

    onSiteCreated(siteId, createScaffoldingNext) {
        this.toggleAddSiteModal();

        const routeState = {
            siteId
        };
        if ( createScaffoldingNext ) {
            routeState.openModal = {
                type: ModalTypes.Site
            };
        }
        this.props.history.push(`/sites/${siteId}`, routeState);
    }

    onJobCreated(jobId) {
        this.toggleAddJobModal();
        this.props.history.push(`/jobs/${jobId}`, { jobId });
    }

    toggleAddSiteModal() {
        this.setState(prevState => ({
            showAddSiteModal: !prevState.showAddSiteModal
        }));
    }

    toggleAddJobModal() {
        this.setState(prevState => ({
            showAddJobModal: !prevState.showAddJobModal
        }));
    }

    hasJobsOrSites() {
        const {
            dashboardJobs: { activeRequestsCount, activeJobsCount },
            dashboardSites: { activeSitesCount }
        } = this.state.dashboardData;

        return activeRequestsCount || activeJobsCount || activeSitesCount;
    }

    linkToConstructionDiaryEntriesOfLastWeek() {
        const today = moment().format('YYYY-MM-DD');
        const oneWeekAgo = moment().subtract(7, 'day').format('YYYY-MM-DD');

        return `/diary?archived=0&from=${oneWeekAgo}&to=${today}`;
    }

    render() {
        const { isFetchingDashboardData, showAddSiteModal, showAddJobModal, dashboardData: { dashboardJobs, dashboardSites, dashboardConstructionDiaryEntries: diaryEntries } } = this.state;

        // Job / request rel. dashboard data
        const {
            activeRequestsCount,
            requestsWithExpiringOfferDeadlineCount,
            activeJobsCount
        } = dashboardJobs;

        // Site rel. dashboard data
        const {
            activeSitesCount,
            sitesWithStatusChange,
            sitesWithNewComments,
            sitesWithWarnings
        } = dashboardSites;

        if ( isFetchingDashboardData ) {
            return <PageLoadingSpinner />;
        }

        return (
            <React.Fragment>
                <IfUser can={[ 'read-jobs' ]}>
                    <Section>
                        <DashboardSummary>
                            <DashboardSummaryCard
                                title="Anfragen"
                                activeCount={activeRequestsCount}
                                tooltipContent='Unter "Aktive Anfragen" werden alle Anfragen zusammengefasst, die in Scaffeye® hinterlegt und nicht archiviert sind.'
                                metaInfo={(requestsWithExpiringOfferDeadlineCount > 0) &&
                                <Tooltip icon="time" id="offerDeadline">
                                    <span>{`Die Frist zur Abgabe eines Angebots für ${requestsWithExpiringOfferDeadlineCount} ${requestsWithExpiringOfferDeadlineCount === 1 ? 'Anfrage' : 'Anfragen'} läuft in 3 Tagen oder weniger ab.`}</span>
                                </Tooltip>}
                                linkToActive="/requests?sortAsc=0&sortBy=created"
                                linktoArchived="/requests?archived=true&sortAsc=0&sortBy=created"
                            >
                            </DashboardSummaryCard>

                            <DashboardSummaryCard
                                title="Aufträge"
                                activeCount={activeJobsCount}
                                tooltipContent='Unter "Aktive Aufträge" werden alle Aufträge zusammengefasst, die in Scaffeye® hinterlegt und nicht archiviert sind.'
                                linkToActive="/jobs?sortAsc=0&sortBy=created"
                                linktoArchived="/jobs?archived=true&sortAsc=0&sortBy=created"
                            >
                            </DashboardSummaryCard>

                            <DashboardSummaryCard
                                title="Baustellen"
                                activeCount={activeSitesCount}
                                tooltipContent='Unter "Aktive Baustellen" werden alle Baustellen zusammengefasst, die in Scaffeye® hinterlegt und nicht archiviert sind.'
                                linkToActive="/sites?sortAsc=true&sortBy=name"
                                linktoArchived="/sites?archived=true&sortAsc=false&sortBy=archivedAt"
                            >
                            </DashboardSummaryCard>
                        </DashboardSummary>

                        {!this.hasJobsOrSites() &&
                        <div className="dashboard-empty-hint">
                            <div className="dashboard-empty-hint__hd">
                                <strong>
                                    Es sind noch
                                    keine Anfragen, Aufträge oder Baustellen
                                    vorhanden
                                </strong>
                            </div>

                            <div className="dashboard-empty-hint__bd">
                                <p>
                                    Beginnen Sie mit der Erstellung von Baustellen zur
                                    Verwaltung Ihrer aktuellen Bauprojekte und fügen Sie
                                    Anfragen und Aufträge hinzu.
                                </p>
                                <div
                                    className="navigationDropdown__button"
                                    onClick={() => this.toggleAddJobModal()}
                                >
                                    <Icon name="add"/> Anfrage /Auftrag erstellen
                                </div>

                                <div
                                    className="navigationDropdown__button"
                                    onClick={() => this.toggleAddSiteModal()}
                                >
                                    <Icon name="add"/>Baustelle erstellen
                                </div>

                            </div>
                        </div>
                        }
                    </Section>
                </IfUser>

                <Section
                    title="Warnungen"
                    subline="Letzte 7 Tage"
                    type="list"
                >
                    <DashboardTable
                        columns={[ 'Baustelle', 'Warnung', 'Gerüst | Gerüstabschnitt', 'Datum' ]}
                        rows={sitesWithWarnings || []}
                        limit={5}
                        emptyComponent={
                            <EmptyDataTableHint
                                numColumns={5}
                                title="Keine Warnungen innerhalb der letzten 7 Tage"
                                tooltipContent="Dieser Bereich informiert Sie zu Prüfungsmängeln, die bei von Ihnen genutzten Gerüstabschnitten dokumentiert wurden."
                                tooltipId="warnings"
                            />
                        }
                    >
                        {
                            (row, index) => (
                                <tr key={index}>
                                    <PrimaryColumn
                                        link={
                                            <SitePageLink
                                                id={row.site.id}
                                            >
                                                {row.site.name}
                                            </SitePageLink>
                                        }
                                        sub={Dashboard.renderSub(row.site)}
                                    >
                                        <SitePageLink
                                            id={row.site.id}
                                        >
                                            Details
                                        </SitePageLink>
                                    </PrimaryColumn>
                                    <td>
                                        <InspectionResult
                                            errors={row.inspection.success ? 0 : row.inspection.deficiencyCount}
                                        />
                                    </td>
                                    <td>
                                        <ScaffoldingPageLink
                                            id={row.scaffolding.id}
                                        >
                                            {row.scaffolding.name}
                                        </ScaffoldingPageLink>
                                        <span className="delimiter">|</span>
                                        <SectionPageLink id={row.section.id}>
                                            {row.section.name}
                                        </SectionPageLink>
                                        {Dashboard.renderSub(row.scaffolding)}
                                    </td>
                                    <td>
                                        {`${moment(row.inspection.completed)
                                            .format('DD.MM.YYYY, HH:mm')} Uhr`}
                                    </td>
                                </tr>
                            )
                        }
                    </DashboardTable>
                </Section>

                <Section
                    title="Statusänderungen"
                    subline="Letzte 7 Tage"
                    type="list"
                >
                    <DashboardTable
                        columns={[ 'Baustelle', 'Neuer Status', 'Gerüst | Gerüstabschnitt', 'Datum' ]}
                        rows={sitesWithStatusChange || []}
                        limit={5}
                        emptyComponent={
                            <EmptyDataTableHint
                                numColumns={5}
                                tooltipId="statuschanges"
                                title="Keine Statusänderungen innerhalb der letzten 7 Tage"
                                tooltipContent='Sie werden an dieser Stelle darüber informiert, wenn sich der öffentliche Status eines Gerüstabschnitts von "Freigegeben" zu "Gesperrt" (und umgekehrt) geändert hat.'
                            />
                        }
                    >
                        {
                            (row, index) => (
                                <tr key={index}>
                                    <PrimaryColumn
                                        link={
                                            <SitePageLink
                                                id={row.site.id}
                                            >
                                                {row.site.name}
                                            </SitePageLink>
                                        }
                                        sub={Dashboard.renderSub(row.site)}
                                    >
                                        <SitePageLink
                                            id={row.site.id}
                                        >
                                            Details
                                        </SitePageLink>
                                    </PrimaryColumn>
                                    <td>
                                        <SectionStatusBadge
                                            section={row.section}
                                            showIcon
                                            small
                                        />
                                    </td>
                                    <td>
                                        <ScaffoldingPageLink
                                            id={row.scaffolding.id}
                                        >
                                            {row.scaffolding.name}
                                        </ScaffoldingPageLink>
                                        <span className="delimiter">|</span>
                                        <SectionPageLink id={row.section.id}>
                                            {row.section.name}
                                        </SectionPageLink>
                                        {Dashboard.renderSub(row.scaffolding)}
                                    </td>
                                    <td>
                                        {moment(row.statusChanged)
                                            .format('LLL')}
                                        {} Uhr
                                    </td>
                                </tr>
                            )
                        }
                    </DashboardTable>
                </Section>

                <IfUser can={[ 'read-construction-diary-entries' ]}>
                    <Section
                        title="Bautagebuch"
                        subline="Letzte 7 Tage"
                        type="list"
                    >
                        <DashboardTable
                            columns={[ 'Eintrag für', 'Kategorie', 'Protokollant', 'Datum' ]}
                            rows={diaryEntries || []}
                            className="content-table--recent-diary-entries"
                            limit={5}
                            footerLink={<Link className="table__footer-link"
                                              to={this.linkToConstructionDiaryEntriesOfLastWeek()}
                            >Weitere Einträge anzeigen</Link>}
                            emptyComponent={
                                <EmptyDataTableHint
                                    numColumns={4}
                                    title="Keine Bautagebuch-Einträge innerhalb der letzten 7 Tage"
                                    tooltipId="diaryentryid"
                                    tooltipContent="Dieser Bereich informiert Sie über Bautagebuch-Einträge, die in den letzten 7 Tagen zu Ihren Baustellen, Gerüsten oder Gerüstabschnitten erstellt wurden."
                                />
                            }
                        >
                            {
                                (diaryEntry, index) => (
                                    <tr key={index}>
                                        <PrimaryColumn
                                            link={(
                                                <DiaryEntryLink
                                                    id={diaryEntry.id}
                                                >
                                                    {Dashboard.showDiaryEntryUpdateStatus(diaryEntry.created, diaryEntry.updated, diaryEntry.updatedBy)}
                                                    {diaryEntry.site.name}
                                                </DiaryEntryLink>
                                            )}
                                            sub={Dashboard.showDiaryEntryReference(diaryEntry.scaffoldings, diaryEntry.scaffoldingSelection, diaryEntry.sections, diaryEntry.sectionSelection)}
                                        >
                                            <DiaryEntryLink
                                                id={diaryEntry.id}
                                            >
                                                Details
                                            </DiaryEntryLink>

                                        </PrimaryColumn>
                                        <td className="note-column">
                                            <ConstructionDiaryEntryCategory
                                                category={diaryEntry.category}
                                            />
                                        </td>
                                        <td>
                                            {diaryEntry.createdBy
                                                ? `${diaryEntry.createdBy.firstName} ${diaryEntry.createdBy.lastName}`
                                                : <DeletedUserPlaceholder/>
                                            }
                                        </td>
                                        <td>
                                            {`${moment(diaryEntry.created)
                                                .format('DD.MM.YYYY, HH:mm')} Uhr`}
                                        </td>
                                    </tr>
                                )
                            }
                        </DashboardTable>
                    </Section>
                </IfUser>

                <IfUser can={[ 'create-jobs' ]}>
                    <Section
                        title="Kommentare"
                        subline="Letzte 7 Tage"
                        type="list"
                    >

                        <DashboardTable
                            columns={[ 'Baustelle', 'Nachricht', 'Nutzer', 'Datum' ]}
                            rows={sitesWithNewComments || []}
                            className="content-table--recent-diary-entries"
                            limit={5}
                            emptyComponent={
                                <EmptyDataTableHint
                                    numColumns={4}
                                    title="Keine Kommentare innerhalb der letzten 7 Tage"
                                    tooltipId="sitecomments"
                                    tooltipContent="Dieser Bereich enthält die neuesten Kommentare, die von Mitarbeitern Ihres Unternehmens auf einer Ihrer Baustellenseiten verfasst wurden."
                                />
                            }
                        >
                            {
                                (site, index) => (
                                    <tr key={index}>
                                        <PrimaryColumn
                                            link={(
                                                <SitePageLink
                                                    id={site.site.id}
                                                >
                                                    {site.site.name}
                                                </SitePageLink>
                                            )}
                                        >
                                            <SitePageLink
                                                id={site.site.id}
                                            >
                                                Details
                                            </SitePageLink>
                                        </PrimaryColumn>
                                        <td className="note-column">
                                            {Dashboard.renderSiteCommentPreview(site.note)}
                                        </td>
                                        <td>
                                            {site.note.createdBy
                                                ? `${site.note.createdBy.firstName} ${site.note.createdBy.lastName}`
                                                : <DeletedUserPlaceholder/>
                                            }
                                        </td>
                                        <td>
                                            {`${moment(site.note.created)
                                                .format('DD.MM.YYYY, HH:mm')} Uhr`}
                                        </td>
                                    </tr>
                                )
                            }
                        </DashboardTable>
                    </Section>
                </IfUser>

                {showAddSiteModal &&
                <SiteFormModal
                    onClose={this.toggleAddSiteModal}
                    onCreated={this.onSiteCreated}
                />
                }

                {showAddJobModal &&
                <JobFormModal
                    onClose={this.toggleAddJobModal}
                    onCreated={this.onJobCreated}
                />
                }

            </React.Fragment>
        );
    }

}

const mapStateToProps = ({ currentUser }) => {
    const { usermeta } = currentUser || {};
    const userPermissions = usermeta.permissions || [];

    return {
        userPermissions
    };
};


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchDashboardSites,
        fetchDashboardJobs,
        fetchDashboardConstructionDiaryEntries
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

