import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Modal from '../../../Components/Common/Modal/Modal';
import UpdateAvailableBanner from '../../../Components/Common/UpdateAvailableBanner';
import { hideExpirationModal, logout } from '../../../redux/modules/user/action';
import { userHasPermission, userHasType, UserTypePropType } from '../../../user';

class PrivatePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: !this.props.loggedIn && this.props.refreshTokenValid === false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            modalVisible: !nextProps.loggedIn && nextProps.refreshTokenValid === false
        });
    }

    userIsAuthorizedToAccess() {
        const { userPermissions, userType, requiredUserPermission, requiredUserType } = this.props;

        let userIsAuthorized = true;

        // Check permission
        if (typeof requiredUserPermission !== 'undefined') {
            userIsAuthorized &= userHasPermission(userPermissions, requiredUserPermission);
        }

        // Check type
        if (typeof requiredUserType !== 'undefined') {
            userIsAuthorized &= userHasType(userType, requiredUserType);
        }

        return userIsAuthorized;
    }

    render() {
        const { modalVisible } = this.state;

        const {
            actions, children, loggedIn, refreshTokenValid, expirationModalIsOpen, isAccountOwner
        } = this.props;

        /*
         * Redirect to login if user is not logged in and the refresh token is invalid
         */
        if (!loggedIn && !refreshTokenValid) {
            return (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: this.props.location }
                    }}
                />
            );
        }

        if (!this.userIsAuthorizedToAccess()) {
            return (
                <Redirect
                    to={{
                        pathname: '/'
                    }}
                />
            );
        }

        return (
            <React.Fragment>
                {children}
                {expirationModalIsOpen && isAccountOwner &&
                <React.Fragment>
                    <Modal
                        title="Abonnement abgelaufen"
                        type="confirmation"
                        footer={
                            <div className="btn-group">
                                <a
                                    onClick={() => {
                                        this.props.actions.hideExpirationModal();
                                    }}
                                    className="btn btn--cancel"
                                >
                                    Schließen
                                </a>
                                <a
                                    className="btn btn--save btn--forward-action"
                                    onClick={() => {
                                        this.props.history.push('/account/subscription/details');
                                        this.props.actions.hideExpirationModal();
                                    }}
                                >
                                    Abonnement verlängern
                                </a>
                            </div>
                        }
                    >
                        <p>Ihr Abonnement ist abgelaufen. Verlängern Sie Ihr Abonnement jetzt, um
                            auch in Zukunft alle Vorzüge von Scaffeye® zu genießen.
                        </p>
                    </Modal>
                </React.Fragment>
                }

                {modalVisible &&
                <Modal
                    title="Sitzung abgelaufen"
                    type="confirmation"
                    footer={
                        <div className="btn-group">
                            <a
                                onClick={() => this.setState({ modalVisible: false })}
                                className="btn modal-link-btn"
                            >
                                Auf dieser Seite bleiben
                            </a>

                            <a
                                className="btn btn--save"
                                onClick={() => {
                                    actions.logout();
                                }}
                            >
                                Neu anmelden
                            </a>
                        </div>
                    }
                >
                    <p>Bitte melden Sie sich erneut mit Ihren Zugangsdaten an, um mit der Nutzung
                        der Plattform fortzufahren.
                    </p>
                </Modal>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ currentUser }) => {

    const { loggedIn, refreshTokenValid, expirationModalIsOpen, usermeta } = currentUser || {};
    const userPermissions = usermeta.permissions || [];
    const userType = usermeta.type || undefined;
    const isAccountOwner = usermeta.isAccountOwner;

    return {
        loggedIn,
        userPermissions,
        userType,
        refreshTokenValid,
        expirationModalIsOpen,
        isAccountOwner
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        logout,
        hideExpirationModal
    }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivatePage));

PrivatePage.propTypes = {
    children: PropTypes.node.isRequired,
    loggedIn: PropTypes.bool,
    requiredUserPermission: PropTypes.string,
    requiredUserType: UserTypePropType
};

PrivatePage.defaultProps = {
    loggedIn: false,
    requiredUserPermission: undefined,
    requiredUserType: undefined
};
