import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModelType } from '../../properties';
import { showRequestError } from '../../redux/modules/error/action';
import { fetchConstructionPlanSiteReferenceData } from '../../redux/modules/job/actions';
import { resolveConstructionPlanMedia } from '../../redux/modules/media/action';
import Address from '../Common/Address';
import LiveBadge from '../Common/Badge/LiveBadge';
import DiffItem, { RenderMode } from '../Common/Diff/DiffItem';
import DiffModal from '../Common/Diff/DiffModal';
import EmptyDiff from '../Common/Diff/EmptyDiff';
import { getScaffoldingDiff, getSectionDiffs, getSiteDiff } from '../Common/Diff/functions';
import { ScaffoldingDiffValue } from '../Common/Diff/Value/ScaffoldingDiffValue';
import { SectionDiffValue } from '../Common/Diff/Value/SectionDiffValue';
import { SiteDiffValue } from '../Common/Diff/Value/SiteDiffValue';
import Icon from '../Common/Icon';
import { getModelTypeLabel } from '../Forms/Media/utils';
import { ConstructionPlanFormSitePropType } from './Form/propTypes';
import { ConstructionPlanModelMode } from './properties';
import { getData, walkConstructionPlanSite } from './utils';

class ConstructionPlanDiffModal extends Component {

    static propTypes = {
        constructionPlan: ConstructionPlanFormSitePropType.isRequired,
        onClose: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            liveSite: null,
            resolvedMedia: null
        };

        this.unmounted = false;
    }

    componentWillMount() {
        const { constructionPlan: { site } } = this.props;
        if (site && site.mode !== ConstructionPlanModelMode.New) {
            this.loadLiveSite(true);
        } else {
            this.resolveMedia(true);
        }
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    loadLiveSite() {
        const { constructionPlan } = this.props || {};
        const { site } = constructionPlan;
        if (!site || !site.id) {
            return;
        }

        const { actions: { fetchConstructionPlanSiteReferenceData } } = this.props;

        fetchConstructionPlanSiteReferenceData(site.id)
            .then(site => {
                if (this.unmounted) {
                    return;
                }

                this.setState({
                    liveSite: site
                }, () => this.resolveMedia());
            })
            .catch(err => {
                this.setState({
                    initialized: true
                });
                showRequestError('Änderungsübersicht konnte nicht geladen werden', err);
            });
    }

    resolveMedia() {
        const { constructionPlan, actions: { resolveConstructionPlanMedia } } = this.props;
        const { site } = constructionPlan;
        if (!site || !site.id) {
            return;
        }

        const { liveSite } = this.state;

        resolveConstructionPlanMedia(site, liveSite, false)
            .then(media => {
                this.setState({
                    resolvedMedia: media
                });
            })
            .catch(err => {
                showRequestError('Dokumente konnten nicht geladen werden', err);
            })
            .finally(() => {
                if (this.unmounted) {
                    return;
                }

                this.setState({
                    initialized: true
                });
            });
    }

    renderModelHeader(modelType, constructionPlanModel, referenceData, first = false) {
        const { mode } = constructionPlanModel;

        const classes = [
            'construction-plan-diff__model'
        ];

        classes.push(`construction-plan-diff__model--${modelType}`);
        if (first) {
            classes.push(`construction-plan-diff__model--first`);
        }

        const showArrow = first && modelType === ModelType.Section;

        return (
            <tr className="construction-plan-diff__model-row">
                <td colSpan={2}>
                    <div className={classes.join(' ')}>
                        {showArrow && <div className="construction-plan-diff__model-arrow"><Icon
                            name="arrowDown"
                        /></div>}
                        <div className="construction-plan-diff__model-ttl">
                            <span className="construction-plan-diff__model-type">
                                {getModelTypeLabel(modelType)}
                            </span>
                            <span className="construction-plan-diff__model-name">
                                {getData(constructionPlanModel, referenceData, 'name')}
                            </span>
                            {mode === ConstructionPlanModelMode.New &&
                            <div className="new-badge">Neu</div>
                            }
                        </div>
                        {mode === ConstructionPlanModelMode.Reference &&
                        <div className="construction-plan-diff__model-ref">
                            Konfiguration übernommen (keine Änderungen)
                        </div>
                        }
                    </div>
                </td>
            </tr>
        );
    }

    renderSiteDiff(model, referenceData) {
        const { id, mode, data } = model;
        const { resolvedMedia } = this.state;

        const header = this.renderModelHeader(ModelType.Site, model, referenceData);
        const diff = getSiteDiff(data, referenceData, resolvedMedia);

        return (
            <React.Fragment key={id}>
                {header}
                {!diff.length
                    ? <EmptyDiff
                        renderMode={RenderMode.Table}
                    />
                    : diff.map(diffItem =>
                        <DiffItem
                            key={diffItem.property}
                            diffItem={diffItem}
                            renderValue={(value, diff) => <SiteDiffValue value={value} diff={diff} />}
                            renderMode={RenderMode.Table}
                            isReference={mode === ConstructionPlanModelMode.Reference}
                        />
                    )}
            </React.Fragment>
        );
    }

    renderScaffoldingDiff(model, referenceData) {
        const { id, mode, data } = model;
        const { resolvedMedia } = this.state;

        const header = this.renderModelHeader(ModelType.Scaffolding, model, referenceData);
        const diff = getScaffoldingDiff(data, referenceData, resolvedMedia);

        return (
            <React.Fragment key={id}>
                {header}
                {!diff.length
                    ? <EmptyDiff
                        renderMode={RenderMode.Table}
                    />
                    : diff.map(diffItem =>
                        <DiffItem
                            key={diffItem.property}
                            diffItem={diffItem}
                            renderValue={(value, diff) => <ScaffoldingDiffValue value={value} diff={diff} />}
                            renderMode={RenderMode.Table}
                            isReference={mode === ConstructionPlanModelMode.Reference}
                        />
                    )}
            </React.Fragment>
        );
    }

    renderSectionDiff(model, referenceData, isFirst = false) {
        const { id, mode, data } = model;
        const { resolvedMedia } = this.state;

        const header = this.renderModelHeader(ModelType.Section, model, referenceData, isFirst);
        const diff = getSectionDiffs(data, referenceData, resolvedMedia, true);

        return (
            <React.Fragment key={id}>
                {header}
                {!diff.length
                    ? <EmptyDiff
                        renderMode={RenderMode.Table}
                    />
                    : diff.map(diffItem =>
                        <DiffItem
                            key={diffItem.property}
                            diffItem={diffItem}
                            renderValue={(value, diff) => <SectionDiffValue value={value} diff={diff} />}
                            renderMode={RenderMode.Table}
                            isReference={mode === ConstructionPlanModelMode.Reference}
                        />
                    )}
            </React.Fragment>
        );
    }

    renderModelDiffs() {
        const { constructionPlan: { site } } = this.props;
        const { liveSite } = this.state;
        const content = [];

        let isFirstSectionOfScaffolding = true;

        walkConstructionPlanSite(site, liveSite, (modelType, model, referenceData) => {
            if (modelType === ModelType.Site) {
                content.push(this.renderSiteDiff(model, referenceData));
            } else if (modelType === ModelType.Scaffolding) {
                content.push(this.renderScaffoldingDiff(model, referenceData));
                isFirstSectionOfScaffolding = true;
            } else if (modelType === ModelType.Section) {
                content.push(this.renderSectionDiff(model, referenceData, isFirstSectionOfScaffolding));
                isFirstSectionOfScaffolding = false;
            }
        });

        return content;
    }

    render() {
        const { onClose, constructionPlan } = this.props;
        const { site } = constructionPlan;
        const { initialized, liveSite } = this.state;

        return (
            <DiffModal
                title="Änderungsübersicht"
                onCloseClick={onClose}
                onBackdropClick={onClose}
                loading={!initialized}
                loadingLabel={'Lade Änderungsübersichtsdaten'}
            >
                {
                    initialized &&
                    <Fragment>
                        <div className="diff-log__hd">
                            <h3 className="diff-log__ttl">Baustelle: {getData(site, liveSite, 'name')}</h3>
                            <div className="diff-log__subttl">
                                <Address address={getData(site, liveSite, 'address')} />
                            </div>
                        </div>
                        <div className="diff-log__bd">
                            <table className="diff-table">
                                <thead className="diff-table__hd">
                                <tr>
                                    <th className="diff-col--plan">Baustellenplanung</th>
                                    <th className="diff-col--live">Live-Baustelle <LiveBadge /></th>
                                </tr>
                                </thead>
                                <tbody className="diff-table__bd">
                                {this.renderModelDiffs()}
                                </tbody>
                            </table>
                        </div>
                    </Fragment>
                }

            </DiffModal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanSiteReferenceData,
        resolveConstructionPlanMedia
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanDiffModal);
