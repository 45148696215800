import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { userAcceptedADVAgreement } from '../../redux/helper';
import { showApiError, showTempError } from '../../redux/modules/error/action';
import { acceptADV, fetchAndUpdateUser } from '../../redux/modules/user/action';
import GraphQLError from '../../Services/GraphQLError';
import Modal from '../Common/Modal/Modal';
import ADVAgreement from './advAgreement';

export const CURRENT_ADV_VERSION = 'v1';

class ADVModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scrolledToBottom: false,
            isSending: false,
            visible: true
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleAlreadyAccepted = this.handleAlreadyAccepted.bind(this);
    }

    onScrollToBottom() {
        this.setState({
            scrolledToBottom: true
        });
    }

    handleSuccess() {
        toast.success('Die Vereinbarung zur Auftragsverarbeitung wurde akzeptiert', { autoClose: 2500 });
        this.props.actions.fetchUser();
    }

    handleError(error) {
        if (error instanceof GraphQLError) {
            if (error.is('adv_already_accepted')) {
                this.handleAlreadyAccepted();
            } else if (error.is('invalid_adv_version')) {
                showTempError('Der Auftragsverarbeitung konnte nicht zugestimmt werden. (Fehler-Code: iadvv)');
            } else {
                showApiError(error);
            }
        } else {
            showApiError(error);
        }
    }

    handleAlreadyAccepted() {
        this.setState({
            visible: false
        }, () => {
            this.props.actions.fetchUser();
            toast.success('Sie haben die Auftragsverarbeitung bereits akzeptiert', { autoClose: 2500 });
        });
    }

    handleSubmit() {
        const { isSending } = this.state;

        if (isSending) {
            return;
        }

        this.setState({
            isSending: true
        });

        this.props.actions.acceptADV()
            .then(() => {
                this.setState({
                    isSending: false,
                    visible: false
                }, this.handleSuccess);
            })
            .catch((error) => {
                this.setState({
                    isSending: false
                }, () => this.handleError(error));
            });
    }

    renderButtonText() {
        const { isSending } = this.state;
        return isSending ? 'Wird gesendet ...' : 'Akzeptieren';
    }

    render() {
        const {
            scrolledToBottom,
            visible
        } = this.state;

        if (!visible) {
            return null;
        }

        const { userAcceptedADVAgreement, closeButtonText } = this.props;
        const closeButtonLabel = closeButtonText || 'Später akzeptieren';

        return (

            <Modal
                title="Auftragsverarbeitung"
                onScrollToBottom={() => this.onScrollToBottom()}
                id="advModal"
                footer={
                    <div className="btn-group">
                        <button
                            className="btn btn--link-neutral"
                            onClick={() => this.props.closeModal()}
                        >
                            {closeButtonLabel}
                        </button>

                        {userAcceptedADVAgreement ?
                            <span style={{ float: 'right' }}>
                                {`Akzeptiert am ${userAcceptedADVAgreement} Uhr`}
                            </span>
                            :
                            <button
                                type="submit"
                                className="btn btn--forward-action btn--save"
                                disabled={!scrolledToBottom}
                                onClick={this.handleSubmit}
                            >
                                {this.renderButtonText()}
                            </button>
                        }
                    </div>
                }
            >
                <div className="terms-intro">
                    <p>Wenn wir für Sie personenbezogene Daten als Auftragsverarbeiter nach Art. 28
                        DSGVO (Datenschutzgrundverordnung) verarbeiten, sollten Sie mit uns eine
                        Vereinbarung zur Auftragsverarbeitung abschließen.
                        Bitte prüfen Sie, ob Sie mit den von uns bereitgestellten Leistungen
                        personenbezogene Daten im Sinne der DSGVO verarbeiten. Sollte dies der Fall
                        sein, schließen Sie bitte diese Vereinbarung mit uns ab. Sie können diese
                        Vereinbarung im Falle einer Kontrolle durch Ihre Datenschutzaufsichtsbehörde
                        vorlegen.
                    </p>
                    <p>Sollten Sie der Auftragsverarbeitung innerhalb der nächsten 14 Tage nicht
                        schriftlich
                        widersprechen, gilt die Auftragsverarbeitung ebenfalls als akzeptiert.
                    </p>
                </div>
                <ADVAgreement />
            </Modal>
        );
    }

}

const mapStateToProps = state => ({
    userAcceptedADVAgreement: userAcceptedADVAgreement(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        acceptADV,
        fetchUser: fetchAndUpdateUser
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ADVModal);

