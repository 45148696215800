import React from 'react';
import { NewLineToBreak } from '../Common/NewLineToBreak';

const FieldError = ({ meta }) => {

    if (!meta.submitFailed && !meta.touched && !meta.submitError) {
        return null;
    }

    const error = meta.submitError || meta.error;
    if (!error) {
        return null;
    }

    return (
        <span
            className="input-grp__error"
        >
            <NewLineToBreak>
                {error}
            </NewLineToBreak>
        </span>
    );
};

export default FieldError;
