import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { autocompleteJob } from '../../../redux/modules/job/actions';
import AutocompleteSelect from './AutocompleteSelect';

const JobAutocomplete = (props) => {
    const { onChange, initialValue, actions: { autocompleteJob }, archivedOnly } = props;

    const initialOption = initialValue ? {
        label: initialValue,
        value: initialValue
    } : null;

    return (
        <AutocompleteSelect
            getValues={(input) => autocompleteJob(input, false, archivedOnly)}
            onSelectionChange={onChange}
            label="Name"
            loadingMessage={() => 'Lade Aufträge ...'}
            placeholder="Auftrag wählen"
            initialOption={initialOption}
        />
    );
};

JobAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string
};

JobAutocomplete.defaultProps = {
    initialValue: null
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteJob
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(JobAutocomplete);
