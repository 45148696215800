import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFormButton from '../Common/ConstructionPlanFormButton';
import ConstructionPlanFormGroup, { ConstructionPlannerSectionType } from '../Common/ConstructionPlanFormGroup';
import ConstructionPlanFormGroupTitle from '../Common/ConstructionPlanFormGroupTitle';

const ConstructionPlanFormSitePlaceholder = ({ onAddNew, onAddExisting }) => (
    <ConstructionPlanFormGroup
        title={
            <ConstructionPlanFormGroupTitle
                type={ConstructionPlannerSectionType.Site}
            />
        }
    >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ConstructionPlanFormButton
                onClick={onAddNew}
                label="Neue Baustelle hinzufügen"
                icon="add"
            />
            <ConstructionPlanFormButton
                onClick={onAddExisting}
                label="Bestehende Baustelle wählen"
                icon="plannerExisting"
            />
        </div>
    </ConstructionPlanFormGroup>
);

ConstructionPlanFormSitePlaceholder.propTypes = {
    onAddNew: PropTypes.func.isRequired,
    onAddExisting: PropTypes.func.isRequired
};

export default ConstructionPlanFormSitePlaceholder;
