import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../Components/Common/Icon';
import ArchivedBadge from './Badge/ArchivedBadge';

class ScaffoldingDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            hasMoreThanOne: props.scaffoldings.length > 1
        };

        this.renderSections = this.renderSections.bind(this);
    }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }

    renderSections(sections) {

        const _sections = sections.map((section, index) => {

            const path = `/sections/${section.id}`;

            return (
                <Link
                    className="scaffoldingDropdown__list-item"
                    to={path}
                    key={index}
                >
                    - {section.name}
                </Link>);
        });

        return (
            <div className="scaffoldingDropdown__list-group-cnt">
                {_sections}
            </div>
        );
    }

    render() {

        const { scaffoldings } = this.props;
        const { listOpen, hasMoreThanOne } = this.state;

        if (!scaffoldings || !scaffoldings.length) {
            return 'n.a';
        }

        const firstScaffolding = scaffoldings[0];

        const numberOfScaffoldings = scaffoldings.length;

        const headerSuffix = numberOfScaffoldings === 2 ? '+ 1 weiteres' : `+ ${numberOfScaffoldings - 1} weitere`;

        const _scaffoldings = scaffoldings.map((scaffolding, index) => {

            const isFirst = index === 0;

            const sections = this.renderSections(scaffolding.sections);


            return (
                <div className="scaffoldingDropdown__list-group" key={index}>
                    {
                        (!isFirst && hasMoreThanOne) ?
                            <div className="scaffoldingDropdown__list-group-header">
                                <Link
                                    className="scaffoldingDropdown__header-item"
                                    to={`/scaffoldings/${scaffolding.id}`}
                                >
                                    {scaffolding.name}
                                </Link>
                            </div>
                            :
                            null
                    }
                    {sections}
                </div>
            );
        });


        const scaffoldingPath = `/scaffoldings/${firstScaffolding.id}`;

        return (
            <div className="scaffoldingDropdown">
                <div className="scaffoldingDropdown__header">
                    <div className="scaffoldingDropdown__header-title">
                        <Link
                            className="scaffoldingDropdown__header-item"
                            to={scaffoldingPath}
                        >
                            {firstScaffolding.name}
                            {firstScaffolding.archived && <ArchivedBadge size="small" />}

                        </Link>
                        {hasMoreThanOne &&
                        <span className="scaffoldingDropdown__header-suffix"> {headerSuffix}
                        </span>}
                    </div>
                </div>

                {listOpen &&
                <div className="scaffoldingDropdown__list">
                    {_scaffoldings}
                </div>
                }

                <div className="scaffoldingDropdown__toggle" onClick={() => this.toggleList()}>
                    {!listOpen
                        ?
                        <Fragment>Mehr anzeigen <span> <Icon name="chevronDown" /></span></Fragment>
                        :
                        <Fragment>Weniger anzeigen <span><Icon name="chevronUp" /></span></Fragment>
                    }
                </div>

            </div>
        );
    }

}

ScaffoldingDropdown.propTypes = {
    scaffoldings: PropTypes.array.isRequired
};

export default ScaffoldingDropdown;
