import PropTypes from 'prop-types';
import React from 'react';
import { SelectOptionsPropType } from '../../Select';
import SelectField from '../../Select/SelectField';

const ModelMediaTypeSelectField = (props) => (
    <SelectField
        {...props}
        placeholder="Typ wählen"
        label="Typ"
        noOptionsMessage={() => 'Keine passender Typ gefunden'}
        isSearchable={false}
    />
);

ModelMediaTypeSelectField.propTypes = {
    options: PropTypes.arrayOf(SelectOptionsPropType).isRequired,
    isMultiUpload: PropTypes.bool
};

ModelMediaTypeSelectField.defaultProps = {
    isMultiUpload: false
};

export default ModelMediaTypeSelectField;
