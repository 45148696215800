import PropTypes from 'prop-types';
import React from 'react';

const ConstructionPlanSyncSummaryRow = ({ left, right, isIdentical }) => {
    return (
        <div className={`sync-summary__row ${isIdentical ? 'sync-summary__row--identical' : ''}`}>
            <div className="sync-summary__col sync-summary__col--l">
                {left}
            </div>
            <div className="sync-summary__col sync-summary__col--r">
                {right}
            </div>
        </div>
    );
};

ConstructionPlanSyncSummaryRow.propTypes = {
    left: PropTypes.node,
    right: PropTypes.node,
    isIdentical: PropTypes.bool
};

export default ConstructionPlanSyncSummaryRow;
