import PropTypes from 'prop-types';
import React from 'react';
import { getPurposeLabel } from '../../../../../Sections/labels';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceRow from '../../../Base/Input/SyncChoiceRow';
import SyncInputGroup from '../../../Base/Input/SyncInputGroup';
import { isIdenticalValue } from '../../../equals';
import { getSyncFormModelData } from '../../../form';
import { validateSingleChoiceComplete } from '../../../validation';
import { isConfigItemSelected } from '../../form';

const validateEmergencyStairway = (value, planValue, liveValue, allValues, meta) => {

    const formModelData = getSyncFormModelData(allValues, meta.name);
    const { purposeGroups } = formModelData;
    if (typeof purposeGroups?.emergency_stairway === 'undefined' || typeof value === 'undefined') {
        return validateSingleChoiceComplete()(value, planValue, liveValue, allValues, meta);
    }

    const isEmergencyStairway = purposeGroups.emergency_stairway;
    const isSelected = isConfigItemSelected(value);
    if (!isEmergencyStairway && isSelected) {
        return 'Bitte wählen Sie den Verwendungszweck “Fluchttreppe” aus.'
    } else if (isEmergencyStairway && !isSelected) {
        return 'Bitte wählen Sie eine Fluchttreppen-Option aus.'
    }
};

const EmergencyStairwayPurposeSyncChoice = (props) => {

    const {
        value: currentFormValue,
        planValue,
        liveValue,
        onChange,
        showIdentical
    } = props;

    const isSelected = (value) => {
        return value === currentFormValue;
    };

    const leftValue = planValue;
    const rightValue = liveValue;
    const isIdentical = isIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Fluchttreppe"
            subLevel
        >
            <SyncChoiceRow
                leftValue={leftValue}
                rightValue={rightValue}
                renderValue={v => getPurposeLabel(v, '-')}
                onSelect={onChange}
                isSelected={isSelected}
                isIdentical={isIdentical}
                showIdentical={showIdentical}
            />
        </SyncInputGroup>
    );
};

EmergencyStairwayPurposeSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.string,
    liveValue: PropTypes.string,
    value: PropTypes.any,
    showIdentical: PropTypes.bool
};

export default asSyncChoiceField(EmergencyStairwayPurposeSyncChoice, {
    allowNull: true,
    validate: validateEmergencyStairway
});
