import PropTypes from 'prop-types';
import React from 'react';
import { getDiffTypeIcon } from '../Diff/icons';
import { getDiffTypeLabel } from '../Diff/labels';
import ChangeLogEntry from './ChangeLogEntry';

const ChangeLog = ({
                            entries,
                            getChanges,
                            renderChangeItemValue,
                            getChangeTypeLabel,
                            getChangeTypeIcon
                        }) => {

    const renderChangeLogEntries = () => {
        if (!entries) {
            return null;
        }

        return entries.map((entry, index) => (
            <ChangeLogEntry
                entry={entry}
                key={index}
                getDiff={getChanges}
                renderChangeItemValue={renderChangeItemValue}
                getChangeTypeLabel={getChangeTypeLabel}
                getChangeTypeIcon={getChangeTypeIcon}
            />
        ));
    };

    return (
        <table className="diff-table diff-table--changelog">
            <thead className="diff-table__hd">
            <tr>
                <th className="change-log-col--type">Änderungstyp</th>
                <th className="change-log-col--new">Neu</th>
                <th className="change-log-col--old">Vorher</th>
            </tr>
            </thead>
            <tbody className="diff-table__bd">
            {renderChangeLogEntries()}
            </tbody>
        </table>
    );
};

ChangeLog.propTypes = {
    entries: PropTypes.arrayOf(PropTypes.object),
    getChanges: PropTypes.func.isRequired,
    renderChangeItemValue: PropTypes.func.isRequired,
    getChangeTypeLabel: PropTypes.func,
    getChangeTypeIcon: PropTypes.func
};

ChangeLog.defaultProps = {
    getChangeTypeLabel: getDiffTypeLabel,
    getChangeTypeIcon: getDiffTypeIcon
};

export default ChangeLog;
