var noop = function noop() {};

var createDecorator = function createDecorator() {
    return function (form) {

        var focusOnFirstError = function focusOnFirstError(errors) {

            if(errors){
                const firstPart = Object.keys(errors)[0];
                const secondPart = Object.keys(errors[firstPart].data)[0];

                const targetClassName = `${firstPart}.data.${secondPart}-field-wrap`;

                const el = document.getElementsByClassName(targetClassName)[0];

                if(el) {
                    el.scrollIntoView(true);
                } else {
                    console.error(`Can't find element to scroll to. Selector class '${targetClassName}'`);
                }
            }
        };

        var originalSubmit = form.submit;

        // Subscribe to errors, and keep a local copy of them
        var state = {};
        var unsubscribe = form.subscribe(function (nextState) {
            state = nextState;
        }, {
            errors: true,
            submitErrors: true
        });

        // What to do after submit
        var afterSubmit = function afterSubmit() {
            var _state = state,
                errors = _state.errors,
                submitErrors = _state.submitErrors;

            if (errors && Object.keys(errors).length) {
                focusOnFirstError(errors);
            } else if (submitErrors && Object.keys(submitErrors).length) {
                focusOnFirstError(submitErrors);
            }
        };

        // Rewrite submit function
        form.submit = function () {
            var result = originalSubmit.call(form);

            if (result && typeof result.then === 'function') {
                // async
                result.then(afterSubmit, noop);
            } else {
                // sync
                afterSubmit();
            }

            return result;
        };

        return function () {
            unsubscribe();
            form.submit = originalSubmit;
        };
    };
};



export default createDecorator;

