import React from 'react';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';
import OptionGroup from '../../../Forms/OptionGroup';
import { isEmergencyStairway, isUnmanaged } from '../functions';
import { WallClearanceOptions } from '../options';
import { isRequired } from '../validate';

export const SectionWallClearanceInput = ({ values }) => {
    const _isUnmanaged = isUnmanaged(values);
    const _isEmergencyStairway = isEmergencyStairway(values);
    const disabled = _isEmergencyStairway && !(_isUnmanaged);

    const tooltip = disabled ? 'Wandabstand kann bei Fluchttreppen nicht konfiguriert werde' : null;
    let options = WallClearanceOptions;
    if(disabled) {
        options = WallClearanceOptions.map(option => {
            return {
                ...option,
                disabled: true
            }
        })
    }

    return (
        <InputGroup
            label="Wandabstand"
            required={isRequired('wallClearance', values)}
            tooltip={tooltip}
        >
            <OptionGroup
                name="wallClearance"
                options={options}
                ifSelectionIs="above_300_mm"
                thenRender={field => (
                    {
                        above_300_mm: <Input
                            name="wallClearanceAbove300"
                            type="text"
                            placeholder={`z.B. "45 cm"${isRequired('wallClearanceAbove300', values) ? '*' : ''}`}
                        />
                    }[field]
                )}
                type="radio"
                disabled={disabled}
            />
        </InputGroup>
    )
}
