import createDecorator from 'final-form-focus';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../../../redux/modules/error/action';
import { fetchMediaData } from '../../../../../redux/modules/media/action';
import { fetchConstructionPlanScaffoldingFormData } from '../../../../../redux/modules/scaffolding/action';
import { fetchScaffoldingsSelectOptions } from '../../../../../redux/modules/scaffoldings/action';
import Modal from '../../../../Common/Modal/Modal';
import ModalFormFooter from '../../../../Common/ModalFormFooter';
import { collectMediaIds } from '../../../../Forms/Media/utils';
import {
    getAllInputs,
    getFormValues,
    getSubmitPayload,
    validate
} from '../../../../Scaffoldings/Form/utils';
import { ConstructionPlanModelMode, ConstructionPlanReferenceMode } from '../../../properties';
import { ConstructionPlanFormScaffoldingPropType } from '../../propTypes';
import { getMediaCollectionData, getMediaItemData } from '../../utils';
import ConstructionPlanScaffoldingFormContent from './ConstructionPlanScaffoldingFormContent';
import LoadingSpinner from '../../../../Page/LoadingSpinner';

const focusOnError = createDecorator(getAllInputs);

const initialFormValues = {
    id: undefined,
    mode: ConstructionPlanModelMode.Reference,
    selection: ConstructionPlanReferenceMode.Single,
    name: '',
    description: '',
    scheduledErection: null,
    scheduledDismantling: null,
    attachments: [],
    utilizationPlans: [],
    anchorProtocol: []
};

class ConstructionPlanScaffoldingFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scaffolding: null,
            referenceData: undefined,
            initialFormValues: {
                ...initialFormValues,
            },
            isUploading: false,
            isSubmitting: false,
            isInitializing: true,
            isInitializingScaffoldingReference: false,
            loadingGotCancelled: false
        };

        this.initializeForm = this.initializeForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.onUploading = this.onUploading.bind(this);
        this.onScaffoldingChanged = this.onScaffoldingChanged.bind(this);
    }

    componentWillMount() {
        const { scaffolding } = this.props;
        const { mode, id } = scaffolding;

        //add referenced scaffolding initial
        if(mode === ConstructionPlanModelMode.Reference && !id){
            this.initializeForm();
            return;
        }

        switch (mode) {
            case ConstructionPlanModelMode.Reference:
                this.initializeScaffoldingReferenceForm(scaffolding.id);
                break;
            case ConstructionPlanModelMode.Edit:
                this.initializeScaffoldingEditForm(scaffolding);
                break;
            case ConstructionPlanModelMode.New:
                this.initializeScaffoldingNewForm(scaffolding);
                break;
            default:
                this.initializeForm();
                break;
        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    /**
     * Initializes the form based on a site reference
     * @param scaffoldingId
     * @param callback
     */
    initializeScaffoldingReferenceForm(scaffoldingId, callback = undefined) {

        this.setState({
            isInitializingScaffoldingReference: true
        });

        this.props.actions.fetchConstructionPlanScaffoldingFormData(scaffoldingId)
            .then(scaffolding => {
                this.setState({
                    isInitializingScaffoldingReference: false,
                    referenceData: scaffolding
                }, () => {
                    this.initializeForm({
                        ...scaffolding
                    }, ConstructionPlanModelMode.Reference, callback);
                });
            })
            .catch(err => {
                showRequestError('Gerüstdaten konnten nicht geladen werden', err);
            });
    }

    /**
     * Initializes the form for the given site in 'edit' mode
     * @param scaffolding
     */
    initializeScaffoldingEditForm(scaffolding) {
        this.props.actions.fetchConstructionPlanScaffoldingFormData(scaffolding.id)
            .then(liveScaffolding => {
                this.setState({
                    referenceData: liveScaffolding
                }, () => {
                    this.initializeNewOrEditedScaffoldingFormWithLiveMedia(scaffolding, ConstructionPlanModelMode.Edit);
                });
            });
    }

    /**
     * Initializes the form for the given site in 'new' mode
     * @param scaffolding
     */
    initializeScaffoldingNewForm(scaffolding) {
        this.initializeNewOrEditedScaffoldingFormWithLiveMedia(scaffolding, ConstructionPlanModelMode.New);
    }

    /**
     * Initializes the site form with the given site but fetches media data first (which
     * is not present in the construction plan)
     *
     * @param scaffolding
     * @param mode
     */
    initializeNewOrEditedScaffoldingFormWithLiveMedia(scaffolding, mode) {

        const scaffoldingData = {
            id: scaffolding.id,
            mode: scaffolding.mode,
            ...scaffolding.data,
        };

        const { attachments, utilizationPlans, anchorProtocol } = scaffoldingData;
        const attachmentIds = collectMediaIds(attachments);
        const utilizationPlanIds = collectMediaIds(utilizationPlans);
        const anchorProtocolId = anchorProtocol ? anchorProtocol.id : null;

        const allMediaIds = [...attachmentIds, ...utilizationPlanIds];
        if (anchorProtocolId) {
            allMediaIds.push(anchorProtocolId);
        }

        if (allMediaIds.length !== 0) {
            this.props.actions.fetchMediaData(allMediaIds)
                .then(fetchedMedia => {

                    const attachmentData = getMediaCollectionData(attachments, fetchedMedia, mode);
                    const utilizationPlanData = getMediaCollectionData(utilizationPlans, fetchedMedia, mode);

                    let anchorProtocolData = null;
                    if (anchorProtocolId) {
                        anchorProtocolData = getMediaItemData(anchorProtocol, fetchedMedia, mode);
                    }

                    this.initializeForm({
                        ...scaffoldingData,
                        attachments: attachmentData,
                        utilizationPlans: utilizationPlanData,
                        anchorProtocol: anchorProtocolData
                    }, mode);
                });
        } else {
            this.initializeForm(scaffoldingData, mode);
        }
    }

    initializeForm(scaffoldingData, mode, callback) {

        // Special case: the user wants to add a new reference or reference all (mode: undefined)
        if (!mode || !scaffoldingData) {
            const selection = this.getOtherReferencedScaffoldingIds().length > 0
                ? ConstructionPlanReferenceMode.Single
                : ConstructionPlanReferenceMode.All;

            this.setState({
                referenceData: null,
                initialFormValues: {
                    ...initialFormValues,
                    selection
                },
                isInitializing: false
            }, callback);
            return;
        }

        const formValues = getFormValues(scaffoldingData, mode);

        this.setState({
            initialFormValues: { ...formValues },
            isInitializing: false
        }, callback);
    }

    getOtherReferencedScaffoldingIds() {
        const { referencedScaffoldingIds } = this.props;
        if (!referencedScaffoldingIds || referencedScaffoldingIds.length === 0) {
            return [];
        }

        const { scaffolding } = this.props;
        if (!scaffolding || !scaffolding.id) {
            return referencedScaffoldingIds;
        }

        return _.difference(referencedScaffoldingIds, [scaffolding.id]);
    }

    getModalTitle() {
        const { scaffolding } = this.props;

        if (!scaffolding.mode) {
            return 'Bestehendes Gerüst wählen';
        }

        if (scaffolding.mode === ConstructionPlanModelMode.Edit) {
            return 'Bestehendes Gerüst anpassen';
        } else if (scaffolding.mode === ConstructionPlanModelMode.Reference) {
            return 'Bestehendes Gerüst wählen';
        }

        if (scaffolding.mode === ConstructionPlanModelMode.New) {
            if (!scaffolding.data || _.isEmpty(scaffolding.data)) {
                return 'Neues Gerüst erstellen';
            }
            return 'Gerüst bearbeiten';
        }
    }

    getModalSubtitle() {
        const { scaffolding } = this.props;

        if (scaffolding.mode) {
            return null;
        }

        return 'Bitte geben Sie an, ob Sie alle bestehenden Gerüste dieser Baustelle oder nur ein bestimmtes Gerüst zuweisen möchten.';
    }

    isModelModeNew() {
        return this.props.scaffolding.mode === ConstructionPlanModelMode.New;
    }

    resetScaffolding(reset) {
        this.setState({
            isInitializing: true,
            referenceData: undefined
        }, () => {
            this.initializeForm(null, null, reset);
        });
    }

    onSubmit(values) {
        this.setState({
            isSubmitting: true
        });

        if (values.selection === ConstructionPlanReferenceMode.All) {
            this.onReferenceAllSubmit();
            return;
        }

        const payload = this.getSubmitPayload(values);
        this.props.onChange(payload);
        this.setState({
            isSubmitting: false
        });
    }

    getSubmitPayload(values) {
        const payload = getSubmitPayload(values, true);

        // Append sections when scaffolding did not change
        if (values.id === this.props.scaffolding.id) {
            payload.sections = this.props.scaffolding.sections;
        }

        return payload;
    }

    onReferenceAllSubmit() {
        const { siteId, actions: { fetchScaffoldingsSelectOptions } } = this.props;
        return fetchScaffoldingsSelectOptions(siteId, false, true)
            .then(scaffoldings => scaffoldings.map(scaffolding => ({
                id: scaffolding.id,
                mode: ConstructionPlanModelMode.Reference
            })))
            .then(scaffoldings => this.props.onReferenceAllScaffoldings(scaffoldings, this.props.scaffolding))
            .catch((error) => {
                this.setState({
                    isSubmitting: false
                });
                showRequestError('Gerüste konnte nicht geladen werden', error);
            });
    }

    onScaffoldingChanged(newScaffoldingId, reset) {
        this.initializeScaffoldingReferenceForm(newScaffoldingId, reset);
    }

    onModeChange(newMode, reset, setMode) {
        const { isInitializing } = this.state;
        const { scaffolding } = this.props;

        if (newMode === ConstructionPlanModelMode.Reference && !isInitializing) {
            if (window.confirm('Möchten Sie alle Anpassungen des Gerüsts verwerfen?')) {
                //edit
                if (scaffolding.id) {
                    this.onScaffoldingChanged(scaffolding.id);
                    return;
                }
                reset();
            } else {
                setMode(ConstructionPlanModelMode.Edit);
            }
        }
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    onUploading(isUploading) {
        this.setState({
            isUploading
        });
    }

    renderButtonText() {
        return !this.isModelModeNew() ? 'Gerüst auswählen' : 'Gerüst erstellen';
    }

    render() {
        const {
            initialFormValues,
            isInitializing,
            isUploading,
            isSubmitting,
            referenceData,
            isInitializingScaffoldingReference
        } = this.state;

        if (isInitializing) {
            return (
                <Modal
                    title={this.getModalTitle()}
                    subtitle={this.getModalSubtitle()}
                    loading
                    loadingLabel="Lade Gerüstdaten"
                    temporary
                />
            );
        }

        const { siteId } = this.props;
        const otherReferencedScaffoldingIds = this.getOtherReferencedScaffoldingIds();
        const hasReferenceScaffoldingsOtherThanThis = otherReferencedScaffoldingIds.length > 0;

        return (
            <Form
                initialValues={initialFormValues}
                validate={(values) => validate(values, true)}
                onSubmit={this.onSubmit}
                decorators={[focusOnError]}
                mutators={{
                    setUploadId: ([name, id], state, utils) => {
                        utils.changeValue(state, name, () => id);
                    },
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    },
                    setAnchorProtocol: ([newAnchorProtocol], state, utils) => {
                        utils.changeValue(state, 'anchorProtocol', () => newAnchorProtocol);
                    },
                    setUtilizationPlans: ([newUtilizationPlan], state, utils) => {
                        utils.changeValue(state, 'utilizationPlans', () => newUtilizationPlan);
                    },
                    setMode: ([mode], state, utils) => {
                        utils.changeValue(state, 'mode', () => mode);
                    }
                }}
                render={({
                             values, handleSubmit, pristine, form: { mutators, reset }
                         }) => {

                    const { selection, id } = values;

                    // if selection is single and no scaffolding is selected => disable form submit
                    const isEmptyScaffoldingSelection = (selection === ConstructionPlanReferenceMode.Single) && !id;

                    const subTitle = values.mode !== ConstructionPlanModelMode.New && 'Bitte geben Sie an, ob Sie Ihrer Anfrage alle bestehenden Gerüste oder nur ein bestimmtes Gerüst zuweisen möchten.';

                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                title={this.getModalTitle()}
                                id="scaffolding-modal"
                                classes="construction-plan-scaffolding-modal"
                                subtitle={subTitle}
                                collapseHeader={!!subTitle}
                                autoHeight
                                footer={
                                    <ModalFormFooter
                                        onSubmit={handleSubmit}
                                        onClose={() => this.onClose(pristine)}
                                        submitLabel={this.renderButtonText()}
                                        submitDisabled={isUploading || isEmptyScaffoldingSelection || isInitializingScaffoldingReference}
                                        hasRequiredFields={true}
                                        isSubmitting={isSubmitting}
                                    />
                                }
                            >
                                <OnChange name="mode">
                                    {(newMode) => this.onModeChange(newMode, reset, mutators.setMode)}
                                </OnChange>

                                <OnChange name="id">
                                    {(newId) => this.onScaffoldingChanged(newId, reset)}
                                </OnChange>

                                {isInitializingScaffoldingReference && <LoadingSpinner label="Lade Gerüstdaten" block/>}

                                <ConstructionPlanScaffoldingFormContent
                                    siteId={siteId}
                                    referenceData={{ ...referenceData }}
                                    values={{ ...values }}
                                    attachmentsMutator={mutators.setAttachments}
                                    anchorProtocolMutator={mutators.setAnchorProtocol}
                                    utilizationPlanMutator={mutators.setUtilizationPlans}
                                    onUploading={this.onUploading}
                                    onResetScaffolding={() => this.resetScaffolding(reset)}
                                    inputDisabled={isUploading}
                                    disableModeAll={hasReferenceScaffoldingsOtherThanThis}
                                    referencedScaffoldingIds={otherReferencedScaffoldingIds}
                                />
                            </Modal>
                        </form>
                    );
                }}
            />
        );
    }
}

ConstructionPlanScaffoldingFormModal.propTypes = {
    siteId: PropTypes.string.isRequired,
    scaffolding: ConstructionPlanFormScaffoldingPropType.isRequired,
    referencedScaffoldingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReferenceAllScaffoldings: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanScaffoldingFormData,
        fetchScaffoldingsSelectOptions,
        fetchMediaData
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanScaffoldingFormModal);
