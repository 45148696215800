import {
    Bracket,
    Cladding,
    ConstructionType,
    EquippedSurface,
    LoadClass,
    PlannedWork,
    Purpose,
    SpecialFeature,
    SupportType,
    WallClearance,
    WidthClass
} from '../properties';

export const SectionStatusOptions = [
    { label: 'Status auswählen...', value: '' },
    { label: 'In Planung', value: 'draft' },
    { label: 'Ausstehend', value: 'pending' },
    { label: 'Gesperrt: Im Aufbau', value: 'under_construction' },
    { label: 'Freigegeben', value: 'constructed' },
    { label: 'Gesperrt', value: 'closed' },
    { label: 'Gesperrt: Umbauarbeiten', value: 'being_altered' },
    { label: 'Gesperrt: Wartung / Instandsetzung', value: 'under_maintenance' },
    { label: 'Abgebaut', value: 'dismantled' },
];

/* Ausführende Arbeiten */
export const PlannedWorkOptions = [
    { label: 'Dachdeckerarbeiten', value: PlannedWork.Roofing },
    { label: 'Klempnerarbeiten', value: PlannedWork.Plumbing },
    { label: 'Malerarbeiten', value: PlannedWork.Painting },
    { label: 'Mauerarbeiten', value: PlannedWork.Bricklaying },
    { label: 'Wärmedämmung', value: PlannedWork.Insulation },
    { label: 'Sonstiges', value: PlannedWork.Other },
];

export const WallClearanceOptions = [
    { label: '< 30 cm', value: WallClearance.Below300MM },
    { label: '30 cm', value: WallClearance.Exactly300MM },
    { label: '> 30 cm', value: WallClearance.Above300MM },
];

/* Lastklasse */
export const LoadClassOptions = [
    { label: 'Lastklasse auswählen...', value: '' },
    { label: '1', value: LoadClass.LC1 },
    { label: '2', value: LoadClass.LC2 },
    { label: '3', value: LoadClass.LC3 },
    { label: '4', value: LoadClass.LC4 },
    { label: '5', value: LoadClass.LC5 },
    { label: '6', value: LoadClass.LC6 },
    { label: 'Andere', value: LoadClass.Other },
];
//
/* Breitenklasse */
export const WidthClassOptions = [
    { label: 'W06', value: WidthClass.W06 },
    { label: 'W09', value: WidthClass.W09 },
    { label: 'Andere', value: WidthClass.Other },
];

/* Arbeitsgerüste */
export const WorkScaffoldingOptions = [
    { label: 'Fassadengerüst', value: Purpose.FacadeScaffolding },
    { label: 'Raumgerüst', value: Purpose.InteriorBirdcageScaffolding },
    { label: 'Flächengerüst', value: Purpose.BirdcageScaffolding },
];

/* Schutzgerüste */
export const SafetyScaffoldingOptions = [
    { label: 'Fanggerüst', value: Purpose.SafetyCatchScaffolding },
    { label: 'Dachfanggerüst', value: Purpose.RoofSafetyCatchScaffolding },
    { label: 'Anderes Schutzgerüst', value: Purpose.OtherProtectiveScaffolding },
];

/* Podesttreppen */
export const PlatformStairwayScaffoldingOptions = [
    { label: 'Podesttreppe W06', value: Purpose.PlatformStairwayW06 },
    { label: 'Podesttreppe W09', value: Purpose.PlatformStairwayW09 },
]

/* Fluchttreppen */
export const EmergencyStairwayOptions = [
    { label: 'Fluchttreppe 500', value: Purpose.EmergencyStairway500 },
    { label: 'Fluchttreppe 750', value: Purpose.EmergencyStairway750 },
]

/* Eingerüstete Flächen */
export const EquippedSurfacesOptions = [
    { label: 'Vorne', value: EquippedSurface.Front },
    { label: 'Hinten', value: EquippedSurface.Back },
    { label: 'Rechts', value: EquippedSurface.Right },
    { label: 'Links', value: EquippedSurface.Left },
];

/* Ausführungsart */
export const ConstructionTypeOptions = [
    { label: 'Ausführungsart auswählen ...', value: '' },
    { label: 'Rahmengerüst', value: ConstructionType.FrameScaffolding },
    { label: 'Modulsystem', value: ConstructionType.ModuleSystemScaffolding },
    { label: 'Stahlrohrkupplungsgerüst', value: ConstructionType.SteelPipeScaffolding },
    { label: 'Vorgefertigte Bauteile', value: ConstructionType.PrefabricatedComponents },
    { label: 'Systemfrei', value: ConstructionType.SystemFree },
];

export const SupportTypeOptions = [
    { label: 'Tragsystem auswählen...', value: '' },
    { label: 'Standgerüst', value: SupportType.Standing },
];

/* Gerüstverkleidungen */
export const SectionCladdingOptions = [
    { label: 'Attika-Einhausung', value: Cladding.AttikaHousing },
    { label: 'Netze', value: Cladding.Net },
    { label: 'Festverkleidung', value: Cladding.FixedTrim },
    { label: 'Plane', value: Cladding.Tarp },
    { label: 'Wetterschutzdach', value: Cladding.Canopy },
    { label: 'Winterbaufolie', value: Cladding.WinterConstructionFoil },
];
//
/* Besonderheiten */
export const SpecialFeatureOptions = [
    { label: 'Schutzdach', value: SpecialFeature.ProtectiveRoof },
    { label: 'Fangschutz', value: SpecialFeature.FallProtection },
    { label: 'Fußgängertunnel', value: SpecialFeature.PedestrianTunnel },
    { label: 'Treppenturm', value: SpecialFeature.StairTower },
    { label: 'Durchstieg', value: SpecialFeature.AccessHatch },
    { label: 'Verankerung', value: SpecialFeature.Anchoring },
    { label: 'Freistehende Gerüstkonst.', value: SpecialFeature.Detached },
    { label: 'Gitterträger', value: SpecialFeature.LatticeGirder },
    { label: 'Innenschutz doppelt', value: SpecialFeature.DoubleGuardRails },
    { label: 'Innenschutz dreiteilig', value: SpecialFeature.TripleGuardRails },
    { label: 'Kranversetzbar', value: SpecialFeature.CraneMovable }
];

export const BracketOptions = [
    { label: '300mm', value: Bracket.MM300 },
    { label: '700mm', value: Bracket.MM700 },
    { label: 'Andere', value: Bracket.Other },
];
