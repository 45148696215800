import PropTypes from 'prop-types';
import React from 'react';
import { WeatherOptions } from '../ConstructionDiary/Form/properties';
import { renderWeatherOption } from '../ConstructionDiary/Form/utils';

const WeatherInfo = ({ weather }) => {

    if (!weather) {
        return null;
    }

    return (
        <div className="weather-info">
            {renderWeatherOption(weather)}
        </div>
    );
};

export default WeatherInfo;

WeatherInfo.propTypes = {
    weather: PropTypes.oneOf(Object.values(WeatherOptions))
};

WeatherInfo.defaultProps = {
    weather: null
};





