import PropTypes from 'prop-types';
import React from 'react';

import { Field } from 'react-final-form';
import FieldError from '../../FieldError';
import MediaUploadDropzone from './MediaDropzone';
import MediaDropzoneFile from './MediaDropzoneFile';

const MediaDropzoneField = ({ onChange, allowedMimes, fileUploadProgressList, multiple, disabled, ...fieldProps }) => (
    <Field
        {...fieldProps}
        disabled={disabled}
    >
        {({ input, meta }) => {
            return (
                <React.Fragment>
                    {!disabled &&
                    <MediaUploadDropzone
                        files={input.value || []}
                        fileUploadProgressList={fileUploadProgressList}
                        onChange={(files) => {
                            input.onChange(files);
                            onChange(files);
                        }}
                        allowedMimes={allowedMimes}
                        multiple={multiple}
                    />
                    }
                    {disabled &&
                    <div className="media-form__files media-form__files--readonly">
                        {input.value && input.value.map(file =>
                            <MediaDropzoneFile
                                file={file}
                                key={file.id}
                            />
                        )}
                    </div>
                    }
                    <FieldError meta={meta} />
                </React.Fragment>
            );
        }}
    </Field>
);

MediaDropzoneField.propTypes = {
    onChange: PropTypes.func.isRequired,
    allowedMimes: PropTypes.arrayOf(PropTypes.string),
    fileUploadProgressList: PropTypes.object,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
};

MediaDropzoneField.defaultProps = {
    allowedMimes: undefined,
    fileUploadProgressList: {},
    multiple: true,
    disabled: false,
};

export default MediaDropzoneField;
