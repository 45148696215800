import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '../Common/Tooltip';

const DashboardSummaryCard = ({ title, activeCount, tooltipContent, metaInfo, linkToActive, linktoArchived }) => (
    <div className="dashboard-summary__card">
        <div className="dashboard-summary__hd">{title}</div>
        <div className="dashboard-summary__cnt">
            <div className="dashboard-summary__counter"
            > {activeCount}</div>
            <div className="dashboard-summary__details">
                <div
                    className="dashboard-summary__row dashboard-summary__row--main"
                >
                    <Link to={linkToActive}
                    >Aktive {title}</Link>
                    {metaInfo && <span style={{ marginLeft: 2 }}>{metaInfo}</span>}
                    <span style={{ marginLeft: 10 }}>
                            <Tooltip icon="info" id={`${title}-info`}>
                                <span>{tooltipContent}</span>
                            </Tooltip>
                        </span>
                </div>

                <div className="dashboard-summary__row">
                    <div className="dashboard-summary__row-cnt">
                        <Link
                            to={linktoArchived}
                        >Archivierte {title}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);


export default DashboardSummaryCard;

DashboardSummaryCard.propTypes = {
    title: PropTypes.string.isRequired,
    activeCount: PropTypes.number.isRequired,
    linkToActive: PropTypes.string.isRequired,
    linktoArchived: PropTypes.string.isRequired,
    metaInfo: PropTypes.any,
    tooltipContent: PropTypes.string
};

