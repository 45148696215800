import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { goToResourcePage, ResourcePageType } from '../../../Components/Common/ResourcePageLink';
import TopNav from '../../../Components/Page/TopNav';
import LoadingSpinner from '../../../Components/Page/LoadingSpinner';
import { acceptSiteInvitation } from '../../../redux/modules/site/action';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

class AcceptInvitationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAccepting: true,
            successful: false
        };
    }

    componentDidMount() {
        const invitationId = this.props.match.params.id;

        this.props.actions.acceptSiteInvitation(invitationId)
            .then((payload) => {
                this.setState({
                    successful: true
                }, () => window.setTimeout(() => {
                    goToResourcePage(this.props.history, ResourcePageType.Site, payload.siteId);
                }, 3000));
            })
            .catch(() => false)
            .then(() => {
                this.setState({
                    isAccepting: false
                });
            });
    }

    renderBody() {
        if (this.state.isAccepting) {
            return (
                <LoadingSpinner label="Einladung wird angenommen" />
            );
        }

        const { successful } = this.state;
        if (!successful) {
            return (
                <div>
                    Einladung konnte nicht angenommen werden. Möglicherweise wurde die
                    Einladung in der Zwischenzeit zurückgezogen.
                </div>
            );
        }

        return (
            <div>
                Einladung angenommen. Sie werden in 3 Sekunden weitergeleitet ...
            </div>
        );
    }

    render() {
        return (
            <PrivatePage>
                <Container>
                    <TopNav />
                    <h1>Einladung annehmen</h1>
                    {this.renderBody()}
                </Container>
            </PrivatePage>
        );
    }

}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        acceptSiteInvitation
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitationPage);
