import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../Common/Tooltip';
import { DiaryCategories } from './properties';

const ConstructionDiaryEntryCategory = ({ category, showTooltip }) => {

    const renderTooltip = (category, showTooltip) => {

        if (showTooltip && category === DiaryCategories.Acceptance){
            return (
                <Tooltip id="acceptance-hint">
                    <p>
                        Einträge der Kategorie “Freigabe” können nur erstellt werden,
                        indem Sie eine Freigabeprüfung per App auf der Baustelle
                        durchführen.
                    </p>
                    <p> Das Erstellen eines Eintrages der Kategorie “Freigabe” ist ohne
                        Freigabeprüfung per App nicht möglich.
                    </p>
                </Tooltip>
            );
        }
        return null;
    };

    const showCategory = (category) => {
        switch (category) {
            case DiaryCategories.PsaDocumentation:
                return 'PSA-Dokumentation';

            case DiaryCategories.Construction:
                return 'Aufbau';

            case DiaryCategories.Dismantling:
                return 'Abbau';

            case DiaryCategories.ObjectDamaged:
                return 'Schaden am Objekt';

            case DiaryCategories.Repairs:
                return 'Instandsetzung / Reparatur';

            case DiaryCategories.WaitingPeriod:
                return 'Wartezeit';

            case DiaryCategories.Warehouse:
                return 'Lager';

            case DiaryCategories.Acceptance:
                return 'Freigabe';

            case DiaryCategories.Other:
                return 'Sonstiges';

            default:
                return 'Nicht kategorisiert';
        }
    };

    return (
        <div className="diaryEntry-category">
            <span
                className={`diaryEntry-category__badge diaryEntry-category__badge--${category}`}
            >{showCategory(category)}
            </span>
            {'  '}
            {renderTooltip(category, showTooltip)}
        </div>
    );
};

export default ConstructionDiaryEntryCategory;

ConstructionDiaryEntryCategory.propTypes = {
    category: PropTypes.string.isRequired,
    showTooltip: PropTypes.bool
};

ConstructionDiaryEntryCategory.defaultProps = {
    showTooltip: undefined
};
