export const SectionStatus = {
    Draft: 'draft',
    Pending: 'pending',
    UnderConstruction: 'under_construction',
    Constructed: 'constructed',
    Closed: 'closed',
    BeingAltered: 'being_altered',
    UnderMaintenance: 'under_maintenance',
    Dismantled: 'dismantled',
    Unknown: 'unknown'
};

export const SectionPublicStatus = {
    UnderConstruction: 'under_construction',
    Open: 'open',
    Closed: 'closed',
    UnderMaintenance: 'under_maintenance',
    BeingAltered: 'being_altered',
    Dismantled: 'dismantled',
    Unknown: 'unknown',
};

/* Ausführende Arbeiten */
export const PlannedWork = {
    Roofing: 'roofing',
    Painting: 'painting',
    Insulation: 'insulation',
    Plumbing: 'plumbing',
    Bricklaying: 'bricklaying',
    Other: 'other'
};

export const PlannedWorks = Object.values(PlannedWork);

/* Eingerüstete Flächen */
export const EquippedSurface = {
    Front: 'front',
    Back: 'back',
    Left: 'left',
    Right: 'right',
    Other: 'other'
};

export const EquippedSurfaces = Object.values(EquippedSurface);

/* Lastklasse */
export const LoadClass = {
    LC1: 'LC1',
    LC2: 'LC2',
    LC3: 'LC3',
    LC4: 'LC4',
    LC5: 'LC5',
    LC6: 'LC6',
    Other: 'other'
};

/* Breitenklasse */
export const WidthClass = {
    W06: 'W06',
    W09: 'W09',
    W12: 'W12',
    W15: 'W15',
    W18: 'W18',
    W21: 'W21',
    W24: 'W24',
    Other: 'other'
};

/* Verwendungszweck-Gruppe */
export const PurposeGroup = {
    WorkScaffolding: 'work_scaffolding',
    ProtectiveScaffolding: 'protective_scaffolding',
    LadderAscent: 'ladder_ascent',
    PlatformStairway: 'platform_stairway',
    SiteStaircase: 'site_staircase',
    EmergencyStairway: 'emergency_stairway',
    MobileScaffolding: 'mobile_scaffolding',
    Unknown: 'unknown'
};

/* Verwendungszweck */
export const Purpose = {
    /* Work */
    FacadeScaffolding: 'facade_scaffolding',
    InteriorBirdcageScaffolding: 'interior_birdcage_scaffolding',
    BirdcageScaffolding: 'birdcage_scaffolding',
    /* Protective */
    SafetyCatchScaffolding: 'safety_catch_scaffolding',
    RoofSafetyCatchScaffolding: 'roof_safety_catch_scaffolding',
    OtherProtectiveScaffolding: 'other_protective_scaffolding',
    /* Ladder ascent */
    LadderAscent: 'ladder_ascent',
    /* Platform stairway */
    PlatformStairwayW06: 'platform_stairway_w06_scaffolding',
    PlatformStairwayW09: 'platform_stairway_w09_scaffolding',
    /* Site staircase */
    SiteStaircase: 'site_staircase',
    /* Fluchttreppe / Emergency stairway */
    EmergencyStairway500: 'emergency_stairway_500_scaffolding',
    EmergencyStairway750: 'emergency_stairway_750_scaffolding'
};

export const WorkPurposes = [
    Purpose.FacadeScaffolding,
    Purpose.InteriorBirdcageScaffolding,
    Purpose.BirdcageScaffolding
];

export const ProtectivePurposes = [
    Purpose.SafetyCatchScaffolding,
    Purpose.RoofSafetyCatchScaffolding,
    Purpose.OtherProtectiveScaffolding
];

export const PlatformStairwayPurposes = [
    Purpose.PlatformStairwayW06,
    Purpose.PlatformStairwayW09,
]

export const EmergencyStairwayPurposes = [
    Purpose.EmergencyStairway500,
    Purpose.EmergencyStairway750,
]

/* Ausführungsart */
export const ConstructionType = {
    FrameScaffolding: 'frame_scaffolding',
    ModuleSystemScaffolding: 'module_system_scaffolding',
    SteelPipeScaffolding: 'steel_pipe_scaffolding',
    PrefabricatedComponents: 'prefabricated_components_scaffolding',
    SystemFree: 'system_free_scaffolding'
};

export const ConstructionTypes = Object.values(ConstructionType);

/* Wandabstand */
export const WallClearance = {
    Below300MM: 'below_300_mm',
    Exactly300MM: 'exactly_300_mm',
    Above300MM: 'above_300_mm'
};

/* Support Type */
export const SupportType = {
    Standing: 'standing'
};

/* Gerüstverkleidungen */
export const Cladding = {
    AttikaHousing: 'attika_housing',
    Net: 'net',
    Tarp: 'tarp',
    Canopy: 'canopy',
    WinterConstructionFoil: 'winter_construction_foil',
    FixedTrim: 'fixed_trim',
    Other: 'other'
};

// SpecialFeatures (Konsolen)
export const Bracket = {
    MM300: '300mm',
    MM700: '700mm',
    Other: 'other'
};

export const SpecialFeature = {
    ProtectiveRoof: 'protective_roof',
    FallProtection: 'fall_protection',
    PedestrianTunnel: 'pedestrian_tunnel',
    StairTower: 'stair_tower',
    AccessHatch: 'access_hatch',
    Anchoring: 'anchoring',
    Detached: 'detached',
    LatticeGirder: 'lattice_girder',
    DoubleGuardRails: 'double_guard_rails',
    TripleGuardRails: 'triple_guard_rails',
    Bracket: 'bracket',
    CraneMovable: 'crane_movable'
};
