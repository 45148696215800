import PropTypes from 'prop-types';
import React from 'react';
import SyncChoiceRow from './SyncChoiceRow';

const SyncChoiceIdenticalRow = ({ leftValue, rightValue, renderValue, showIdentical }) => (
    <SyncChoiceRow
        leftValue={leftValue}
        rightValue={rightValue}
        onSelect={() => false}
        isSelected={() => true}
        renderValue={renderValue}
        isIdentical
        showIdentical={showIdentical}
    />
);

SyncChoiceIdenticalRow.propTypes = {
    leftValue: PropTypes.any,
    rightValue: PropTypes.any,
    renderValue: PropTypes.func.isRequired,
    showIdentical: PropTypes.bool
};

SyncChoiceIdenticalRow.defaultProps = {
    showIdentical: true
};

export default SyncChoiceIdenticalRow;


