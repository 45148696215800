import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_ITEMS_PER_TABLE_PAGE } from '../../../properties';
import Pagination from '../Pagination';
import SortableTableHeader from './SortableTableHeader';
import { TableBody } from './TableBody';

export const SortableTable = ({
                                  onSortChange,
                                  sort,
                                  columns,
                                  RowComponent,
                                  data,
                                  isFetching,
                                  total,
                                  onSetPage,
                                  page,
                                  entityLabel,
                                  renderEmptyTableHint,
                                  onChange,
                                  onChangeItem,
                                  onDeleteItem,
                                  itemsPerPage
                              }) => {
    return (
        <div className="content-table standalone">
            <table cellPadding="0" cellSpacing="0" className="hover stack">
                <SortableTableHeader
                    onChange={onSortChange}
                    sort={{
                        field: sort.field,
                        direction: sort.direction
                    }}
                    columns={columns}
                    isFetching={isFetching}
                />
                <TableBody
                    RowComponent={RowComponent}
                    data={data}
                    isFetching={isFetching}
                    entityLabel={entityLabel}
                    renderEmptyTableHint={renderEmptyTableHint}
                    onChange={onChange}
                    onItemChange={onChangeItem}
                    onItemDelete={onDeleteItem}
                    itemsToShow={itemsPerPage}
                />
            </table>
            {isFetching ?
                <div />
                :
                <Pagination
                    total={total}
                    amountPerPage={itemsPerPage}
                    setPage={onSetPage}
                    currentPage={page}
                />
            }
        </div>
    );
};

SortableTable.propTypes = {
    onSortChange: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    RowComponent: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    total: PropTypes.number,
    onSetPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    renderEmptyTableHint: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    entityLabel: PropTypes.string,
    onChangeItem: PropTypes.func,
    onDeleteItem: PropTypes.func,
    itemsPerPage: PropTypes.number
};

SortableTable.defaultProps = {
    itemsPerPage: DEFAULT_ITEMS_PER_TABLE_PAGE,
    onChange: () => false,
    entityLabel: 'Daten',
    onChangeItem: (id, status) => false,
    onDeleteItem: (id) => false
};
