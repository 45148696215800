import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { __RouterContext as RouterContext } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterToUrlQuery } from 'react-url-query';
import { bindActionCreators } from 'redux';
import history from '../history';
import { fetchAndUpdateUser } from '../redux/modules/user/action';
import App from './App';

// 10m
const USER_REFRESH_TIMEOUT = 600000;

class Root extends Component {

    constructor(props) {
        super(props);
        this.intervalId = null;
    }

    componentDidMount() {

        const { userIsLoggedIn } = this.props;

        if (userIsLoggedIn) {
            this.updateUser();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }

    componentDidUpdate(prevProps) {

        const { userIsLoggedIn } = this.props;

        // if user loggs in
        if (prevProps.userIsLoggedIn !== userIsLoggedIn && userIsLoggedIn === true) {
            this.updateUser();
        }

        // if user loggs out
        if (prevProps.userIsLoggedIn !== userIsLoggedIn && userIsLoggedIn === false) {
            clearTimeout(this.intervalId);
        }
    }

    updateUser() {
        const { actions: { fetchUser }, userIsLoggedIn } = this.props;

        if (!userIsLoggedIn) {
            clearTimeout(this.intervalId);
            return;
        }

        fetchUser()
            .then(() => {
                this.intervalId = setTimeout(this.updateUser.bind(this), USER_REFRESH_TIMEOUT);
            });
    }

    render() {

        return (
            <Fragment>
                <Router history={history}>
                    <RouterToUrlQuery routerContext={RouterContext}>
                        <App />
                    </RouterToUrlQuery>
                </Router>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ currentUser }) => {
    return {
        userIsLoggedIn: currentUser.loggedIn
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchUser: fetchAndUpdateUser
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
