import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import LoadingSpinner from '../../Page/LoadingSpinner';

const modalRoot = document.getElementById('modal-root');

const baseZIndex = 2000;

const CollapseThreshold = 20;

function getElementNodeIndex(element) {
    let index = 0;
    const { children } = modalRoot;
    for(let i = 0; i < children.length; i++) {
        const child = children[i];
        if(child === element) {
            break;
        }
        index += child.getAttribute('data-temp') ? 0 : 1;
    }

    return index;
}

export default class Modal extends Component {

    constructor(props) {
        super(props);

        this.el = document.createElement('div');
        if(this.props.temporary) {
            this.el.setAttribute('data-temp', 'true');
        }

        this.bodyRef = React.createRef();
        this.headRef = React.createRef();

        this.state = {
            bodyPaddingTop: 0,
            collapseHeader: props.collapseHeader && props.type === 'default',
            headerCollapsed: false,
            headerCollapsing: false
        };

        this.onScroll = _.debounce(this.onScroll.bind(this), 15);
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
        this.preventBackgroundScrolling(true);
        this.updateHeaderHeight();
        ReactTooltip.rebuild();
    }

    updateHeaderHeight() {
        const { collapseHeader } = this.state;
        if (!collapseHeader) {
            return;
        }

        const header = this.headRef.current;
        const height = Math.max(0, header ? header.clientHeight - 50 : 0);

        this.setState({
            bodyPaddingTop: height
        });
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        this.preventBackgroundScrolling(false);
    }

    preventBackgroundScrolling(preventScrolling) {
        const bodyTag = document.getElementsByTagName('body');

        if (preventScrolling) {
            bodyTag[0].setAttribute('class', 'unscrollable');
        } else {
            bodyTag[0].setAttribute('class', '');
        }
    }

    getZIndexBackdrop() {
        const nodeIndex = getElementNodeIndex(this.el);
        return baseZIndex + (nodeIndex + 1) * 2;
    }

    getZIndexWindow() {
        const nodeIndex = getElementNodeIndex(this.el);
        return baseZIndex + (nodeIndex + 1) * 2 + 1;
    }

    getZIndexLoading() {
        const nodeIndex = getElementNodeIndex(this.el);
        return baseZIndex + (nodeIndex + 1) * 2 + 2;
    }

    handleScrollCollapse() {
        const scrollCt = this.bodyRef.current;
        const { headerCollapsed, headerCollapsing } = this.state;

        if (!scrollCt) {
            return;
        }

        if ((headerCollapsed || headerCollapsing) && scrollCt.scrollTop < CollapseThreshold) {
            if(this.collapsedTimeout) {
                clearTimeout(this.collapsedTimeout);
            }

            this.setState({
                headerCollapsing: false,
                headerCollapsed: false
            });
        } else if (!headerCollapsed && !headerCollapsing && scrollCt.scrollTop >= CollapseThreshold) {
            this.setState({
                headerCollapsing: true
            });

            if(this.collapsedTimeout) {
                clearTimeout(this.collapsedTimeout);
            }

            this.collapsedTimeout = setTimeout(()=>{
                this.setState({
                    headerCollapsing: false,
                    headerCollapsed: true
                })
            }, 400);
        }
    }

    handleScrollToBottom() {
        const scrollCt = this.bodyRef.current;

        const isScrolledToBottom = scrollCt.scrollHeight
            - scrollCt.scrollTop
            - scrollCt.clientHeight <= 5;

        if (isScrolledToBottom) {
            this.props.onScrollToBottom();
        }
    }

    onScroll(event) {
        if (this.state.collapseHeader) {
            this.handleScrollCollapse(event);
        }

        if (this.props.onScrollToBottom) {
            this.handleScrollToBottom(event);
        }
    }

    render() {
        const {
            id,
            type,
            title,
            subtitle,
            children,
            footer,
            classes,
            autoHeight,
            onBackdropClick,
            loading,
            loadingLabel,
            headerRight
        } = this.props;

        const { collapseHeader } = this.state;

        // Base modal css classes
        const modalWrapperClasses = ['modal', `modal--${type}`];

        // Stack position
        modalWrapperClasses.push(`modal--idx-${getElementNodeIndex(this.el)}`);

        // Auto height
        if (autoHeight) {
            modalWrapperClasses.push('modal--auto-height');
        }

        if (collapseHeader) {
            modalWrapperClasses.push('modal--collapse-header');
        }

        if(subtitle) {
            modalWrapperClasses.push('modal--has-subtitle')
        }

        if(footer) {
            modalWrapperClasses.push('modal--has-footer')
        }

        if (classes) {
            const supplementalClasses = classes.split(', ');
            modalWrapperClasses.push(supplementalClasses);
        }

        const bodyStyle = {};
        if (collapseHeader) {
            bodyStyle.paddingTop = this.state.bodyPaddingTop;
        }

        const modal = (
            <div id={id} className={modalWrapperClasses.join(' ')}>
                <div className="modal__ct">
                    <div
                        className="modal__window"
                        style={{
                            zIndex: this.getZIndexWindow()
                        }}
                    >
                        <div
                            className={`modal__hd ${this.state.headerCollapsing ? 'collapsing' : ''} ${this.state.headerCollapsed ? 'collapsed' : ''}`}
                        >
                            <div className={`modal__hd-ct ${headerRight ? 'has-right-hd' : ''}`} ref={this.headRef}>
                                <h1 className="modal__ttl">{title}</h1>
                                {subtitle &&
                                <div className="modal__subttl">{subtitle}</div>
                                }
                                {headerRight &&
                                <div className="modal__hd-right">{headerRight}</div>
                                }
                            </div>
                        </div>
                        <div
                            ref={this.bodyRef}
                            className="modal__bd"
                            onScroll={this.onScroll}
                            style={bodyStyle}
                        >
                            <div className="modal__bd-ct">
                                {children}
                            </div>
                        </div>
                        {footer &&
                        <div className="modal__ft">
                            {footer}
                        </div>
                        }
                        {loading &&
                        <div
                            className="modal__loading"
                            style={{ zIndex: this.getZIndexLoading() }}
                        >
                            <LoadingSpinner size="large" label={loadingLabel} />
                        </div>
                        }
                    </div>
                </div>
                <div
                    className="modal__backdrop"
                    onClick={onBackdropClick}
                    style={{ zIndex: this.getZIndexBackdrop() }}
                />
            </div>
        );

        return ReactDOM.createPortal(
            modal,
            this.el,
        );
    }

}

Modal.propTypes = {
    children: PropTypes.node,
    footer: PropTypes.element,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.oneOf(['confirmation', 'upload', 'micro-form', 'default']),
    loading: PropTypes.bool,
    loadingLabel: PropTypes.string,
    id: PropTypes.string,
    collapseHeader: PropTypes.bool,
    classes: PropTypes.string,
    autoHeight: PropTypes.bool,
    onBackdropClick: PropTypes.func,
    headerRight: PropTypes.element,
    temporary: PropTypes.bool
};

Modal.defaultProps = {
    subtitle: null,
    type: 'default',
    loading: false,
    loadingLabel: null,
    id: '',
    collapseHeader: false,
    classes: '',
    autoHeight: false,
    footer: null,
    onBackdropClick: undefined,
    headerRight: null,
    temporary: false
};
