import constructionDiaryReducer from './modules/constructiondiary/reducer';
import customersReducer from './modules/customers/reducer';
import inspectionsReducer from './modules/inspection/reducer';
import mediaReducer from './modules/media/reducer';
import scaffoldingReducer from './modules/scaffolding/reducer';
import sectionReducer from './modules/section/reducer';
import serviceWorkerReducer from './modules/serviceworker/reducer';
import siteReducer from './modules/site/reducer';
import sitesReducer from './modules/sites/reducer';
import userLoginReducer from './modules/user/reducer';

const rootReducer = {
    currentUser: userLoginReducer,
    customers: customersReducer,
    scaffolding: scaffoldingReducer,
    section: sectionReducer,
    site: siteReducer,
    sites: sitesReducer,
    media: mediaReducer,
    inspections: inspectionsReducer,
    serviceworker: serviceWorkerReducer,
    constructionDiary: constructionDiaryReducer
};

export default rootReducer;
