export const ConstructionPlanModelMode = {
    Reference: 'reference',
    New: 'new',
    Edit: 'edit'
};

export const ConstructionPlanReferenceMode = {
    Single: 'single',
    All: 'all'
};
