import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { bindActionCreators } from 'redux';
import Permission from '../../../permissions';
import { DEFAULT_ITEMS_PER_TABLE_PAGE } from '../../../properties';
import { showRequestError } from '../../../redux/modules/error/action';
import { fetchJobs, fetchRequests } from '../../../redux/modules/job/actions';
import DateService from '../../../Services/DateService';
import CustomerAutocomplete from '../../Common/Autocomplete/CustomerAutocomplete';
import JobAutocomplete from '../../Common/Autocomplete/JobAutocomplete';
import RequestAutocomplete from '../../Common/Autocomplete/RequestAutocomplete';
import SiteAutocomplete from '../../Common/Autocomplete/SiteAutocomplete';
import { AddButton } from '../../Common/Button/AddButton';
import EmptyTableHint from '../../Common/EmptyTableHint';
import FilterBar from '../../Common/Filterbar/Filterbar';
import FilterBarRow from '../../Common/Filterbar/FilterbarRow';
import PaginationTotal from '../../Common/PaginationTotal';
import ScaffoldingDropdown from '../../Common/ScaffoldingDropdown';
import { SortableTable } from '../../Common/Table/SortableTable';
import PageTitle from '../../Page/PageTitle';
import JobFormModal from '../Form/JobFormModal';
import {
    getJobTableColumns,
    getRequestTableColumns,
    InitialJobsSortState,
    InitialRequestSortState
} from '../properties';
import { JobTableRow } from './JobTableRow';
import { RequestTableRow } from './RequestTableRow';
import {ModalTypes} from '../../Common/AddResourceDropDown';

const ListViewOptions = {
    ShowActive: 'show_active',
    ShowArchived: 'show_archived'
};

const JobsListViewSelectOptions = [
    {
        label: 'Aktive Aufträge',
        value: ListViewOptions.ShowActive
    },
    {
        label: 'Archivierte Aufträge',
        value: ListViewOptions.ShowArchived
    }
];

const RequestsListViewSelectOptions = [
    {
        label: 'Aktive Anfragen',
        value: ListViewOptions.ShowActive
    },
    {
        label: 'Archivierte Anfragen',
        value: ListViewOptions.ShowArchived
    }
];

const urlPropsQueryConfig = {
    archived: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'archived'
    },
    job: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'name'
    },
    customer: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'customer'
    },
    site: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'site'
    },
    sortBy: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'sortBy'
    },
    asc: {
        type: UrlQueryParamTypes.boolean,
        updateType: 'replaceIn',
        queryParam: 'sortAsc'
    },
    page: {
        type: UrlQueryParamTypes.number,
        updateType: 'replaceIn',
        queryParam: 'page'
    }
};

class JobsOverview extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            loading: true,
            items: [],
            total: 0,
            filters: {
                jobName: '',
                siteName: '',
                customerName: ''
            },
            sort: this.getInitialSortState(props),
            page: 1,
            currentListViewOption: ListViewOptions.ShowActive,
            showAddModal: false
        };

        this.setJobNameFilter = this.setJobNameFilter.bind(this);
        this.setCustomerNameFilter = this.setCustomerNameFilter.bind(this);
        this.setSiteNameFilter = this.setSiteNameFilter.bind(this);
        this.updateList = this.updateList.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onSetPage = this.onSetPage.bind(this);
        this.updateHistory = this.updateHistory.bind(this);
        this.renderEmptyTableHint = this.renderEmptyTableHint.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onItemDelete = this.onItemDelete.bind(this);
        this.onJobCreated = this.onJobCreated.bind(this);
    }

    static renderScaffoldings(scaffoldings) {

        if (!scaffoldings || scaffoldings.length === 0) {
            return 'n.a.';
        }

        return (
            <ScaffoldingDropdown scaffoldings={scaffoldings} />
        );
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    getInitialSortState() {
        const { sortBy, asc, showRequests } = this.props;

        if (!sortBy) {
            return showRequests ? InitialRequestSortState : InitialJobsSortState;
        }

        let sortDirection;

        if (asc === undefined) {
            sortDirection = '+';
        } else {
            sortDirection = asc ? '+' : '-';
        }

        return {
            field: sortBy,
            direction: sortDirection
        };

    }

    componentDidMount() {
        const {
            job, site, customer, page, archived
        } = this.props;

        const filters = {
            jobName: job || '',
            siteName: site || '',
            customerName: customer || ''
        };

        this.setState({
            filters,
            page: page || 1,
            currentListViewOption: archived ? ListViewOptions.ShowArchived : ListViewOptions.ShowActive
        }, () => this.updateList());
    }

    renderEmptyTableHint() {
        const { showRequests, archived } = this.props;

        if (!this.hasActiveFilters()) {
            return (
                <EmptyTableHint
                    numColumns={showRequests ? 7 : 6}
                    title={`Keine ${archived ? 'archivierten' : ''} ${showRequests ? 'Anfragen' : 'Aufträge'} vorhanden`}
                >
                    <p>
                        <strong>
                            Sie haben bisher noch
                            keine {showRequests ? 'Anfragen ' : 'Aufträge '}
                            {archived ? 'archiviert.' : 'erstellt.'}
                        </strong>
                    </p>
                    {!archived &&
                    <p>
                        Beginnen Sie mit der Verwaltung
                        Ihrer {showRequests ? 'Anfragen' : 'Aufträge'}, indem Sie diese
                        über den grünen &quot;Erstellen&quot; Button am oberen rechten Rand der
                        Seite anlegen.
                    </p>
                    }
                </EmptyTableHint>
            );
        }

        return (
            <EmptyTableHint
                numColumns={showRequests ? 7 : 6}
                title={`Wir konnten keine passenden ${showRequests ? 'Anfragen' : 'Aufträge'} zu Ihrer Filterauswahl finden. Entfernen oder ändern Sie die Filtereinstellungen, um mehrere Ergebnisse zu erhalten.`}
            />
        );
    }

    getFetchParams() {
        const {
            filters, sort, page
        } = this.state;

        return {
            name: filters.jobName,
            siteName: filters.siteName,
            customerName: filters.customerName,
            sortAsc: sort.direction !== '-',
            sortField: sort.field,
            page: page,
            limit: DEFAULT_ITEMS_PER_TABLE_PAGE
        };
    }

    hasActiveFilters() {
        const { filters } = this.state;
        return filters.siteName || filters.jobName || filters.customerName;
    }

    markFirstRowOlderThanSevenDays(data) {

        const indexOfFirstRequestOlderThanSevenDays = _.findIndex(data, (request) => {
            return DateService.isOlderThan(request.created, 7);
        });

        if (indexOfFirstRequestOlderThanSevenDays !== -1) {
            data[indexOfFirstRequestOlderThanSevenDays] = {
                ...data[indexOfFirstRequestOlderThanSevenDays],
                isFirstOlderThanSevenDays: true
            };
        }

        return data;
    }

    updateList() {
        this.setState({
            loading: true
        });

        const { currentListViewOption } = this.state;
        const fetchParams = this.getFetchParams();

        const { actions, showRequests } = this.props;
        let fetchItems;
        if (showRequests) {
            fetchItems = actions.fetchRequests;
        } else {
            fetchItems = actions.fetchJobs;
        }

        const archived = currentListViewOption === ListViewOptions.ShowArchived;

        fetchItems(fetchParams, archived)
            .then((result) => {

                if (this.loadingGotCancelled) {
                    return;
                }
                this.setState({
                    items: this.prepareTableContent(result.data),
                    total: result.total,
                    loading: false
                });
            })
            .catch((error) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    loading: false
                }, () => {
                    showRequestError(`${showRequests ? 'Anfragen' : 'Aufträge'} konnten nicht geladen werden`, error);
                });
            });
    }

    onItemChange(updatedItem, changes) {
        const { items } = this.state;

        if ('type' in changes) {
            this.onItemDelete(updatedItem.id);
            return;
        }

        // Patch field
        let updatedItems = items.map(item => {
            if (item.id !== updatedItem.id) {
                return item;
            }

            return {
                ...item,
                ...changes
            };
        });

        this.setState({ items: updatedItems });
    }

    onItemDelete(id) {
        const { items } = this.state;

        let updatedItems = items.filter(item => item.id !== id);

        this.setState({ items: updatedItems });
    }

    onJobCreated(jobId, createConstructionPlanNext) {
        this.setState({ showAddModal: false });
        const routeState = {
            jobId
        };

        if (createConstructionPlanNext) {
            routeState.openModal = {
                type: ModalTypes.ConstructionPlan
            };
        }

        this.props.history.push(`/jobs/${jobId}`, routeState);
    }

    prepareTableContent(rawData) {

        const { sort: { field, direction } } = this.state;

        if (field === 'created' && direction === '-') {
            return this.markFirstRowOlderThanSevenDays(rawData);
        }
        return rawData;
    }

    onSortChange(updatedSortByField, updatedSortDirection) {
        const { field, direction } = this.state.sort;

        if (field !== updatedSortByField) {
            this.props.onChangeSortBy(updatedSortByField);
            this.setState(prevState => ({
                sort: {
                    field: updatedSortByField,
                    direction: prevState.sort.direction
                }
            }), () => this.updateList());
        }

        if (direction !== updatedSortDirection) {
            this.props.onChangeAsc(updatedSortDirection !== '-');
            this.setState(prevState => ({
                sort: {
                    field: prevState.sort.field,
                    direction: updatedSortDirection
                }
            }), () => this.updateList());
        }
    }

    onSetPage(page) {
        this.setState({
            page
        }, () => this.updateQueryAndList({ page }));
    }

    updateQueryAndList(queryParamPatch) {
        this.props.onChangeUrlQueryParams({
            ...queryParamPatch
        });

        this.updateHistory();
        this.updateList();
    }

    updateHistory() {

        window.setTimeout(() => {
            const { history, location, showRequests } = this.props;

            const stateKey = showRequests ? 'requestQueryString' : 'jobQueryString';

            // Get current query string
            const currentQueryString = location.search || '';

            history.replace({
                ...location,
                state: {
                    [stateKey]: currentQueryString
                }
            });
        }, 100);
    }

    setJobNameFilter(selection) {
        const value = selection ? selection.value : null;
        this.setState({
            filters: {
                ...this.state.filters,
                jobName: value || ''
            },
            page: 1
        }, () => {
            this.updateQueryAndList({
                job: value,
                page: null
            });
        });
    }

    setCustomerNameFilter(selection) {
        const value = selection ? selection.value : null;
        this.setState({
            filters: {
                ...this.state.filters,
                customerName: value || ''
            },
            page: 1
        }, () => {
            this.updateQueryAndList({
                customer: value,
                page: null
            });
        });
    }

    setSiteNameFilter(selection) {
        const value = selection ? selection.value : null;
        this.setState({
            filters: {
                ...this.state.filters,
                siteName: value || ''
            },
            page: 1
        }, () => {
            this.updateQueryAndList({
                site: value,
                page: null
            });
        });
    }

    getSelectedViewOption() {
        const { showRequests } = this.props;
        const { currentListViewOption } = this.state;

        // Anfragen
        if (showRequests) {
            return currentListViewOption === ListViewOptions.ShowActive ? RequestsListViewSelectOptions[0] : RequestsListViewSelectOptions[1];
        }

        // Aufträge
        return currentListViewOption === ListViewOptions.ShowActive ? JobsListViewSelectOptions[0] : JobsListViewSelectOptions[1];
    }

    onListViewOptionChange(viewOptionSelection) {

        if (!viewOptionSelection) {
            return;
        }

        const { value: currentListViewOption } = viewOptionSelection;
        const { onChangeArchived } = this.props;

        onChangeArchived(currentListViewOption === ListViewOptions.ShowArchived ? true : null);

        this.setState({
            currentListViewOption
        }, () => {
            this.resetList();
        });

    }

    resetList() {

        this.resetQueryParameters();

        this.setState({
            page: 1,
            filters: {
                jobName: null,
                siteName: null,
                customerName: null
            }
        }, () => this.updateQueryAndList());
    }

    resetQueryParameters() {

        const { onChangeUrlQueryParams } = this.props;

        onChangeUrlQueryParams({
            job: null,
            customer: null,
            site: null,
            sortBy: null,
            asc: null,
            page: null
        });
    }

    render() {
        const {
            loading, total, sort, page, items, showAddModal
        } = this.state;
        const {
            showRequests, job: jobName, customer: customerName, site: siteName, archived
        } = this.props;

        const NameAutocomplete = showRequests ? RequestAutocomplete : JobAutocomplete;

        const selectedViewOption = this.getSelectedViewOption();

        const entityLabel = showRequests ? (!archived ? 'Anfragen' : 'archivierte Anfragen') : (!archived ? 'Aufträge' : 'archivierte Aufträge');

        const tableWrapperClass = showRequests ? 'requests-table' : 'jobs-table';

        return (
            <Fragment>
                <PageTitle
                    title={showRequests ? 'Anfragen' : 'Aufträge'}
                >
                    <AddButton
                        neededPermissions={Permission.CreateJobs}
                        onClick={() => this.setState({ showAddModal: true })}
                        label={showRequests ? 'Neue Anfrage' : 'Neuer Auftrag'}
                    />
                </PageTitle>
                <PaginationTotal total={total} standalone />
                <div className={tableWrapperClass}>
                    <FilterBar>
                        <FilterBarRow
                            title="Ansicht"
                        >
                            <Select
                                className="filterbar__archive-select"
                                classNamePrefix="filterbar-element"
                                isSearchable={false}
                                options={showRequests ? RequestsListViewSelectOptions : JobsListViewSelectOptions}
                                value={selectedViewOption}
                                onChange={(selection) => this.onListViewOptionChange(selection)}
                                isDisabled={loading}
                            />
                        </FilterBarRow>

                        <FilterBarRow
                            title="Filter"
                        >
                            <NameAutocomplete
                                onChange={this.setJobNameFilter}
                                initialValue={jobName}
                                archivedOnly={archived}
                            />
                            <CustomerAutocomplete
                                onChange={this.setCustomerNameFilter}
                                initialValue={customerName}
                            />
                            <SiteAutocomplete
                                onChange={this.setSiteNameFilter}
                                initialValue={siteName}
                            />
                        </FilterBarRow>
                    </FilterBar>

                    <SortableTable
                        onSortChange={this.onSortChange}
                        entityLabel={entityLabel}
                        sort={{
                            field: sort.field,
                            direction: sort.direction
                        }}
                        isFetching={loading}
                        data={items}
                        columns={showRequests ? getRequestTableColumns(archived) : getJobTableColumns(archived)}
                        RowComponent={showRequests ? RequestTableRow : JobTableRow}
                        onChange={this.updateList}
                        total={total}
                        onSetPage={this.onSetPage}
                        page={page}
                        renderEmptyTableHint={this.renderEmptyTableHint}
                        onChangeItem={this.onItemChange}
                        onDeleteItem={this.onItemDelete}
                    />
                </div>

                {showAddModal &&
                <JobFormModal
                    onCreated={this.onJobCreated}
                    onClose={() => this.setState({ showAddModal: false })}
                    isRequest={showRequests}
                />
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchJobs,
        fetchRequests
    }, dispatch)
});

export default withRouter(addUrlProps({ urlPropsQueryConfig })(connect(null, mapDispatchToProps)(JobsOverview)));

