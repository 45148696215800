import React, { Component, Fragment } from 'react';
import InputHint from '../../Forms/InputHint';
import { FormMode, FormModePropType } from '../../Forms/properties';
import Section from '../../Layout/Section';
import { CustomerMainContactCreateFormPart } from './CustomerMainContactCreateFormPart';
import CustomerContactSelectionFormPart from './CustomerContactSelectionFormPart';

class CustomerMainContactFormPart extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { mode, ...editFormProps } = this.props;

        return (
            <Section
                title="Hauptkontakt"
            >
                {mode === FormMode.Create
                    ?
                    <Fragment>
                        <CustomerMainContactCreateFormPart />
                        <InputHint>
                            Weitere Ansprechpartner können nach Anlegen des Kunden in der Sektion
                            "Ansprechpartner" hinterlegt werden."
                        </InputHint>
                    </Fragment>
                    :
                    <CustomerContactSelectionFormPart {...editFormProps} />
                }
            </Section>
        );
    }
}


export default CustomerMainContactFormPart;

CustomerMainContactFormPart.propTypes = {
    mode: FormModePropType.isRequired
};

CustomerMainContactFormPart.defaultProps = {};
