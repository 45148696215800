import PropTypes from 'prop-types';
import React from 'react';
import { ModelType } from '../../../properties';
import { ConstructionPlanModelMode } from '../../ConstructionPlan/properties';
import InputGroup from '../InputGroup';
import OptionGroup from '../OptionGroup';

function getInfoText(type) {
    switch (type) {
        case ModelType.Site:
            return infoTextContextSite();
        case ModelType.Scaffolding:
            return infoTextContextScaffolding();
        case ModelType.Section:
            return infoTextContextSection();
        default:
            return '';
    }
}

function infoTextContextSite() {
    return infoText('der Baustelle');
}

function infoTextContextScaffolding() {
    return infoText('des Gerüstes');
}

function infoTextContextSection() {
    return infoText('des Gerüstabschnitts');
}

function infoText(insertion) {
    return `In diesem Formular angegebene Anpassungen dienen ausschließlich der Planung und werden nicht auf die
        öffentlich einsehbare Live-Konfiguration ${insertion} (Bereich “Baustellenverwaltung”) übertragen`;
}

const ConstructionPlanModeSelection = ({ type, readOnly }) => {

    const infoText = getInfoText(type);

    const containerClasses = ['construction-plan__mode-selection'];

    if(readOnly){
        containerClasses.push('construction-plan__mode-selection--disabled')
    }

    return (
            <InputGroup label="Konfiguration*" containerClass={containerClasses.join(' ')}>
                <OptionGroup
                    name="mode"
                    options={[
                        {
                            label: 'Konfiguration beibehalten',
                            value: ConstructionPlanModelMode.Reference
                        },
                        {
                            label: 'Konfiguration anpassen',
                            value: ConstructionPlanModelMode.Edit
                        }
                    ]}
                    type="radio"
                />
                <div style={{
                    marginBottom: 8,
                    marginTop: 4,
                    paddingLeft: 30
                }} className="input-hint">
                    {infoText}
                </div>
            </InputGroup>
    );
};

ConstructionPlanModeSelection.propTypes = {
    type: PropTypes.oneOf([ModelType.Site, ModelType.Scaffolding, ModelType.Section]).isRequired
};

export default ConstructionPlanModeSelection;
