import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import IfUser from '../IfUser';

const SettingsDropdownSection = ({ sectionLabel, sectionLinks }) => {

    const requiredPermissions = sectionLinks
        .filter(link => link.requiredPermission)
        .map(link => link.requiredPermission);

    const renderContent = () => {
        return <div className="settingsDropdown__section">
            {
                sectionLabel && <div className="settingsDropdown__section-hd">
                    {sectionLabel}
                </div>

            }
            <ul className="settingsDropdown__section-links">
                {sectionLinks.map(link => {

                    const classes = ['settingsDropdown__section-link'];

                    if (link.cssClass) {
                        classes.push(link.cssClass);
                    }

                    return (
                        <IfUser key={link.label} can={link.requiredPermission}>
                            <li>
                                <Link
                                    className={classes.join(' ')}
                                    to={link.target}
                                >
                                    {link.label}
                                </Link>
                                {link.secondaryContent}
                            </li>
                        </IfUser>
                    );
                })}
            </ul>
        </div>;
    };

    if (requiredPermissions.length < 1) {
        return renderContent();
    }

    return (
        <IfUser canOneOf={requiredPermissions}>
            {renderContent()}
        </IfUser>
    );
};


export default SettingsDropdownSection;

SettingsDropdownSection.propTypes = {
    sectionLinks: PropTypes.array.isRequired,
    sectionLabel: PropTypes.string
};

