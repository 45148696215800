import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast as toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { getPublicDownloadUrl } from '../../redux/modules/media/action';
import Icon from './Icon';

class DownloadIcon extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requesting: false
        };
        this.download = this.download.bind(this);
    }

    download(id) {
        this.setState({ requesting: true });
        this.props.actions.getPublicDownloadUrl(id)
            .then(url => window.open(url, '_blank'))
            .catch(() => {
                toast.error('Download konnte nicht gestartet werden');
            })
            .finally(() => {
                this.setState({ requesting: false });
            });
    }

    render() {
        const { requesting } = this.state;
        const { mediaId } = this.props;

        return (
            <div className="download-icon">
                <div className="download-icon__ctn">
                    <a
                        role="button"
                        className="download-icon__link"
                        onClick={() => this.download(mediaId)}
                    >
                        {requesting ? <i className="btn__spinner" /> : <Icon name="download" />}
                    </a>
                </div>
            </div>
        );
    }
}

DownloadIcon.propTypes = {
    mediaId: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getPublicDownloadUrl
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(DownloadIcon);
