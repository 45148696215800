import React from 'react';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';
import TopNav from '../../../Components/Page/TopNav';
import ManageSubscription from '../../../Components/Account/Subscription/ManageSubscription';
import PageTitle from '../../../Components/Page/PageTitle';
import IfUser from '../../../Components/Common/IfUser';
import ResourceNotFoundErrorPage from '../Errors/ResourceNotFoundErrorPage';

const ManageSubscriptionPage = () => (
    <PrivatePage requiredUserPermission={Permission.UpdateSubscription}>
        <Container>
            <TopNav />
            <IfUser
                can={['update-subscription']}
                ifNot={<ResourceNotFoundErrorPage />}
            >
                <PageTitle title="Abo & Rechnung" />
                <ManageSubscription />
            </IfUser>
        </Container>
    </PrivatePage>
);

export default ManageSubscriptionPage;
