import React from 'react';
import Changelog_1_11_0 from '../../../Components/AppChangeLog/Content/1_11_0';
import Changelog_1_12_0 from '../../../Components/AppChangeLog/Content/1_12_0';
import Changelog_1_13_0 from '../../../Components/AppChangeLog/Content/1_13_0';
import Changelog_1_14_0 from '../../../Components/AppChangeLog/Content/1_14_0';
import Changelog_1_15_0 from '../../../Components/AppChangeLog/Content/1_15_0';
import Changelog_1_16_0 from '../../../Components/AppChangeLog/Content/1_16_0';
import Changelog_1_17_0 from '../../../Components/AppChangeLog/Content/1_17_0';
import Changelog_1_18_0 from '../../../Components/AppChangeLog/Content/1_18_0';
import Changelog_1_19_0 from '../../../Components/AppChangeLog/Content/1_19_0';
import Changelog_1_20_0 from '../../../Components/AppChangeLog/Content/1_20_0';
import Changelog_1_21_0 from '../../../Components/AppChangeLog/Content/1_21_0';
import Changelog_1_22_0 from '../../../Components/AppChangeLog/Content/1_22_0';
import { Changelog_1_23_0 } from '../../../Components/AppChangeLog/Content/1_23_0';
import { Changelog_1_24_0 } from '../../../Components/AppChangeLog/Content/1_24_0';
import Section from '../../../Components/Layout/Section';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const AppChangeLogPage = () => (
    <PrivatePage>
        <Container>
            <TopNav />

            <PageTitle title="Versionshistorie" />

            <div className="changelog">
                <Section title="Version 1.24.0">
                    <Changelog_1_24_0 />
                </Section>

                <Section title="Version 1.23.0">
                    <Changelog_1_23_0 />
                </Section>

                <Section title="Version 1.22.0">
                    <Changelog_1_22_0 />
                </Section>

                <Section title="Version 1.21.0">
                    <Changelog_1_21_0 />
                </Section>

                <Section title="Version 1.20.0">
                    <Changelog_1_20_0 />
                </Section>

                <Section title="Version 1.19.0">
                    <Changelog_1_19_0 />
                </Section>

                <Section title="Version 1.18.0">
                    <Changelog_1_18_0 />
                </Section>

                <Section title="Version 1.17.0">
                    <Changelog_1_17_0 />
                </Section>

                <Section title="Version 1.16.0">
                    <Changelog_1_16_0 />
                </Section>

                <Section title="Version 1.15.0">
                    <Changelog_1_15_0 />
                </Section>

                <Section title="Version 1.14.0">
                    <Changelog_1_14_0 />
                </Section>

                <Section title="Version 1.13.0">
                    <Changelog_1_13_0 />
                </Section>

                <Section title="Version 1.12.0">
                    <Changelog_1_12_0 />
                </Section>

                <Section title="Version 1.11.0">
                    <Changelog_1_11_0 />
                </Section>

                <Section title="Vorherige Versionen">
                    <div className="changelog-entry">
                        <div className="changelog-infotext">
                            <p>
                                Die Protokolle älterer Updates werden zur Zeit aufbereitet und
                                können bald
                                hier eingesehen werden.
                            </p>
                        </div>
                    </div>
                </Section>

            </div>
        </Container>
    </PrivatePage>
);

export default AppChangeLogPage;
