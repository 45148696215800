import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSelectedSection } from '../../redux/modules/constructiondiary/action';
import { autocompleteSection } from '../../redux/modules/section/action';
import AsyncAutocomplete from '../Common/AsyncAutocomplete';
import { showApiError } from '../../redux/modules/error/action';

class SectionAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.onSelectionChange = this.onSelectionChange.bind(this);

        this.state = {
            initialOption: undefined,
            hasInitialOption: false
        };
    }

    onSelectionChange(selectedValue) {
        this.setState({ hasInitialOption: false });
        this.props.onSelectionChange(selectedValue);
    }

    setInitialOption(value) {
        this.setState({
            initialOption: {
                label: value.name,
                value: value.id,
                id: value.id
            }
        });
    }

    componentWillMount() {
        const { initialValue, actions: { fetchSelectedSection } } = this.props;

        if (!initialValue) {
            return;
        }

        if (initialValue) {
            this.setState({
                hasInitialOption: true
            });

            fetchSelectedSection(initialValue)
                .then((value) => {
                    this.setInitialOption(value);
                })
                .catch((error) => {
                    showApiError(error);
                });
        }
    }


    render(){
        const { actions: { autocompleteSection }, managed,disabled, scaffoldingId } = this.props;

        const { initialOption, hasInitialOption } = this.state;

        return (
            <AsyncAutocomplete
                getValues={() => autocompleteSection(scaffoldingId, managed)}
                onSelectionChange={this.onSelectionChange}
                label="Gerüstabschnitt"
                loadingMessagePreselected="Lade Gerüstabschnitt ..."
                loadingMessage={() => 'Lade Gerüstabschnitte ...'}
                placeholder="Gerüstabschn. wählen"
                disabled={disabled}
                hasInitialOption={hasInitialOption}
                initialOption={initialOption}
                updateKey={scaffoldingId}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteSection,
        fetchSelectedSection
    }, dispatch)
});
export default connect(null, mapDispatchToProps)(SectionAutocomplete);

SectionAutocomplete.propTypes = {
    onSelectionChange: PropTypes.func,
    disabled: PropTypes.bool,
    scaffoldingId: PropTypes.string,
    managed: PropTypes.bool
};

SectionAutocomplete.defaultProps = {
    onSelectionChange: () => false,
    disabled: true,
    scaffoldingId: '',
    managed: null
};
