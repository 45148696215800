import 'moment/locale/de';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError } from '../../../redux/modules/error/action';
import {
    fetchInspectionDetail,
    generateInspectionProtocol
} from '../../../redux/modules/inspection/action';
import { getPublicDownloadUrl } from '../../../redux/modules/media/action';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import DataRow from '../../Common/DataRow';
import DeletedUserPlaceholder from '../../Common/DeletedUserPlaceholder';
import InvitedIndicator from '../../Common/InvitedIndicator';
import Modal from '../../Common/Modal/Modal';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import {
    ScaffoldingReference,
    SectionReference,
    SiteReference
} from '../../Common/ObjectReference';
import ThirdPartyIndicator from '../../Common/ThirdPartyIndicator';
import UnmanagedResourceIndicator from '../../Common/UnmanagedIndicator';
import { getInspector, getInspectorCompany } from '../../Inspections/helpers';
import Section from '../../Layout/Section';
import LoadingSpinner from '../../Page/LoadingSpinner';
import InspectionResult from '../InspectionResult';
import InspectionResults from './InspectionResults';

class InspectionModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            found: false,
            inspection: null,
            exportInProgress: false
        };
        this.generateInspectionProtocol = this.generateInspectionProtocol.bind(this);
    }

    static renderInspectionImages(images) {
        return images.map(image => (
            <img
                alt={image.name}
                key={image.id}
                src={`${process.env.REACT_APP_API_URL}/v1/images/${image.id}/${image.fileName}`}
            />
        ));
    }

    static renderInspectionNotes(notes) {
        return notes.map(note => (
            <p className="note" key={note.id}>„{note.content}“</p>
        ));
    }

    generateInspectionProtocol() {
        const { actions: { generateInspectionProtocol, getPublicDownloadUrl } } = this.props;
        const { inspection } = this.state;
        const inspectionId = this.props.inspectionId;

        this.setState({
            exportInProgress: true
        });

        // Protocol export exists
        if (inspection.protocol) {
            getPublicDownloadUrl(inspection.protocol.id, inspection.protocol.fileName)
                .then((url) => {

                    this.setState({
                        exportInProgress: false
                    });

                    toast.success('Prüfprotokoll wurde erfolgreich exportiert', { autoClose: 2500 });
                    window.open(url, '_blank');
                    this.props.closeModal();
                });
        } else {
            generateInspectionProtocol(inspectionId)
                .then((media) => {
                    getPublicDownloadUrl(media.id, media.fileName)
                        .then((url) => {
                            this.setState({
                                exportInProgress: false
                            });

                            window.open(url, '_blank');
                            this.props.closeModal();
                        });
                })
                .catch((error) => {
                    this.setState({
                        exportInProgress: false
                    });
                    return showApiError(error);
                });
        }
    }


    componentDidMount() {
        this.props.actions.fetchInspectionDetail(this.props.inspectionId)
            .then((inspection) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    inspection,
                    isFetching: false,
                    found: !!inspection
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    getInspectorCompany() {
        const { inspection } = this.state;
        const company = getInspectorCompany(inspection);

        if (inspection.isAnonymous) {
            return <span className="anonymized-hint">{company}</span>;
        }

        return company !== 'n.a' ? company : <NotAvailablePlaceholder />;
    }

    getInspector() {
        const { inspection, inspection: { createdBy } } = this.state;
        const inspector = getInspector(inspection);

        if (inspection.isAnonymous) {
            return <span className="anonymized-hint">{inspector}</span>;
        }

        return createdBy ?
            <div>
                <strong>{inspector}</strong><br />
                {createdBy.email}
            </div>
            : <DeletedUserPlaceholder />;
    }

    renderBody() {
        const { inspection, found } = this.state;

        if (!found) {
            return 'Prüfung konnte nicht gefunden werden';
        }

        const { section, answers, isAcceptanceInspection, createdByName: acceptanceInspectionCreatedByName } = inspection;
        const lastAcceptanceInspectionCreatedByName = inspection.acceptanceInspection && inspection.acceptanceInspection.createdByName;
        const scaffolding = section ? section.scaffolding : null;
        const site = scaffolding ? scaffolding.site : null;
        const siteAddress = site ? site.address : null;
        const siteAddressFormatted = !siteAddress ? '' : `${siteAddress.line1}, ${siteAddress.zip} ${siteAddress.city}`;
        const isAnonymous = !!inspection.isAnonymous;

        return (
            <React.Fragment>
                <Section
                    title="Zustand"
                >
                    <DataRow
                        label="Prüfergebnis"
                    >
                        <InspectionResult
                            errors={inspection.success ? 0 : answers.filter(item => !item.success).length}
                        />
                    </DataRow>
                </Section>

                <Section
                    title="Allgemeine Angaben"
                >
                    <DataRow
                        label="Gerüstabschnitt"
                    >
                        {section ?
                            <SectionReference
                                label={section.name}
                                id={section.id}
                                badge={section.archived && <ArchivedBadge size="small" />}
                            />
                            :
                            <NotAvailablePlaceholder />
                        }
                        {
                            (section && section.isThirdParty && !section.isInvited) ?
                                <ThirdPartyIndicator size="small" />
                                :
                                null
                        }
                        {
                            (section && section.isThirdParty && section.isInvited) ?
                                <InvitedIndicator size="small" />
                                :
                                null
                        }

                        {section && !section.isThirdParty && !section.managed &&
                        <UnmanagedResourceIndicator
                            label="Fremder Gerüstabschnitt"
                            size="small"
                        />
                        }
                    </DataRow>

                    <DataRow
                        label="Gerüstabschnitt-ID"
                    >
                        {section ?
                            section.shortId
                            :
                            <NotAvailablePlaceholder />
                        }
                    </DataRow>

                    <DataRow
                        label="Gerüst"
                    >
                        {scaffolding ?
                            <ScaffoldingReference
                                label={scaffolding.name}
                                id={scaffolding.id}
                                badge={scaffolding.archived && <ArchivedBadge size="small" />}
                            />
                            :
                            <NotAvailablePlaceholder />
                        }
                        {
                            scaffolding && scaffolding.isThirdParty && !scaffolding.isInvited ?
                                <ThirdPartyIndicator size="small" />
                                :
                                null
                        }
                        {
                            scaffolding && scaffolding.isThirdParty && scaffolding.isInvited ?
                                <InvitedIndicator size="small" />
                                :
                                null
                        }

                        {scaffolding && !scaffolding.isThirdParty && !scaffolding.managed &&
                        <UnmanagedResourceIndicator
                            label="Fremdes Gerüst"
                            size="small"
                        />
                        }
                    </DataRow>

                    <DataRow
                        label="Baustelle"
                    >
                        {site &&
                        <SiteReference
                            label={site.isThirdParty ? siteAddressFormatted : site.name}
                            id={site.id}
                            badge={site.archived && <ArchivedBadge size="small" />}
                        >
                            {site.isThirdParty ? null : siteAddressFormatted}
                        </SiteReference>
                        }
                        {
                            (site.isThirdParty && !site.isInvited) ?
                                <ThirdPartyIndicator size="small" />
                                :
                                null
                        }
                        {
                            (site.isThirdParty && site.isInvited) ?
                                <InvitedIndicator size="small" />
                                :
                                null
                        }
                    </DataRow>

                    <DataRow
                        label="Gerüstersteller"
                    >
                        {
                            section.contractor ?
                                <div>
                                    <strong>{section.contractor.addressName}</strong>
                                    <div> {section.contractor.addressLine1} {section.contractor.addressLine2}</div>
                                    <div>{section.contractor.addressZip} {section.contractor.addressCity}</div>
                                </div>
                                :
                                <NotAvailablePlaceholder />
                        }
                    </DataRow>

                    <DataRow
                        label="Freigegeben durch (befähigte Person)"
                    >
                        {isAcceptanceInspection ? acceptanceInspectionCreatedByName : lastAcceptanceInspectionCreatedByName}
                    </DataRow>

                    <DataRow
                        label="Auftraggeber"
                    >
                        {section.customer || <NotAvailablePlaceholder />}
                    </DataRow>

                </Section>

                <Section
                    title="Prüfer"
                >
                    <DataRow
                        label="Unternehmen"
                    >
                        {this.getInspectorCompany()}
                    </DataRow>

                    <DataRow
                        label="Prüfer"
                    >
                        {this.getInspector()}
                    </DataRow>

                    <DataRow
                        label="Geprüft am"
                    >
                        {moment(inspection.completed)
                            .format('DD.MM.YYYY, HH:mm') + ' Uhr'}
                    </DataRow>
                </Section>

                <Section
                    title="Prüfergebnisse"
                >

                    <InspectionResults isAnonymous={isAnonymous} answers={answers} />
                </Section>

                <Section
                    title="Prüfvermerke"
                >
                    {this.renderAdditionalInspectionInformation()}
                </Section>
            </React.Fragment>
        );
    }

    renderAdditionalInspectionInformation() {
        const { inspection } = this.state;
        const { images, notes, isAnonymous } = inspection;

        if (isAnonymous) {
            return (
                <span className="anonymized-hint">Prüfvermerke nicht einsehbar</span>
            );
        }

        if (!images.length && !notes.length) {
            return '-';
        }

        return (
            <React.Fragment>
                <div className="inspection__images">
                    {images.length > 0 && InspectionModal.renderInspectionImages(images)}
                </div>
                {notes.length > 0 &&
                <div className="inspection__notes">
                    {InspectionModal.renderInspectionNotes(notes)}
                </div>
                }
            </React.Fragment>
        );
    }

    render() {
        const {
            isFetching,
            exportInProgress
        } = this.state;

        return (
            <Modal
                title="Prüfprotokoll"
                onBackdropClick={() => this.props.closeModal()}
                footer={
                    <div className="btn-group">
                        <a
                            className="btn btn--backward-action"
                            onClick={this.props.closeModal}
                        >
                            Schließen
                        </a>
                        {isFetching ||
                        <button
                            className="btn btn--forward-action btn--save"
                            onClick={this.generateInspectionProtocol}
                            disabled={exportInProgress}
                        >
                            {exportInProgress ? 'Wird exportiert ...' : ' Prüfprotokoll exportieren'}
                        </button>
                        }
                    </div>
                }
            >

                {isFetching ? <LoadingSpinner
                    block
                    label="Lade Prüfungsdetails"
                    backgroundColor="white"
                /> : this.renderBody()}
            </Modal>
        );
    }

}

InspectionModal.propTypes = {
    inspectionId: PropTypes.string.isRequired
};


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchInspectionDetail,
        getPublicDownloadUrl,
        generateInspectionProtocol
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(InspectionModal);
