import 'moment/locale/de';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCompanyDocuments, fetchPublicDocuments } from '../../redux/modules/documents/action';
import { showApiError } from '../../redux/modules/error/action';
import { autoFormatFileSize } from '../../utils';
import DocumentUploadModal from '../Common/Modal/DocumentUploadModal';
import DownloadIcon from '../Common/DownloadIcon';
import EmptyTableHint from '../Common/EmptyTableHint';
import FileExtension from '../Common/FileExtension';
import Icon from '../Common/Icon';
import IfUser from '../Common/IfUser';
import LightBoxLink from '../Common/LightBoxLink';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import PrimaryColumn from '../Common/PrimaryColumn';
import Tooltip from '../Common/Tooltip';
import Section from '../Layout/Section';
import LoadingSpinner from '../Page/LoadingSpinner';
import CompanyDocumentsTableHeader from './CompanyDocumentsTableHeader';
import DeleteDocumentLink from './DeleteDocumentLink';
import PublicDocumentsTableHeader from './PublicDocumentsTableHeader';

class Documents extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {

            loading: false,
            uploadModalIsOpen: false,
            companyDocumentsSortOptions: {
                direction: '-',
                field: 'name'
            },
            publicDocumentsSortOptions: {
                direction: '-',
                field: 'name'
            }
        };

        this.toggleDocumentUploadModal = this.toggleDocumentUploadModal.bind(this);
        this.onCompanyDocumentsSortOptionChange = this.onCompanyDocumentsSortOptionChange.bind(this);
        this.onPublicDocumentsSortOptionChange = this.onPublicDocumentsSortOptionChange.bind(this);
        this.loadCompanyDocuments = this.loadCompanyDocuments.bind(this);
        this.loadPublicDocuments = this.loadPublicDocuments.bind(this);
    }

    componentWillMount() {
        this.loadCompanyDocuments();
        this.loadPublicDocuments();
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onDocumentUpload() {

        this.loadCompanyDocuments();
        this.closeDocumentUploadModal();
    }

    onCompanyDocumentsSortOptionChange(sortConfiguration) {
        this.setState({
            companyDocumentsSortOptions: {
                ...sortConfiguration
            }
        }, () => {
            this.loadCompanyDocuments();
        });
    }

    onPublicDocumentsSortOptionChange(sortConfiguration) {
        this.setState({
            publicDocumentsSortOptions: {
                ...sortConfiguration
            }
        }, () => {
            this.loadPublicDocuments();
        });
    }

    openDocumentUploadModal(currentDocument = null) {
        this.setState({
            uploadModalIsOpen: true,
            currentDocument
        });
    }

    loadPublicDocuments() {
        this.setState({
            isFetchingPublicDocuments: true
        });

        const { publicDocumentsSortOptions } = this.state;

        this.props.actions.fetchPublicDocuments(publicDocumentsSortOptions)
            .then((publicDocuments) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setState({
                    publicDocuments
                });
            })
            .catch((error) => {
                showApiError(error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isFetchingPublicDocuments: false
                });
            });
    }

    loadCompanyDocuments() {
        this.setState({
            isFetchingCompanyDocuments: true
        });

        const { companyDocumentsSortOptions } = this.state;


        this.props.actions.fetchCompanyDocuments(companyDocumentsSortOptions)
            .then((companyDocuments) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setState({
                    companyDocuments
                });
            })
            .catch((error) => {
                showApiError(error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isFetchingCompanyDocuments: false
                });
            });
    }

    toggleDocumentUploadModal() {
        this.setState(prevState => ({
            uploadModalIsOpen: !prevState.uploadModalIsOpen
        }));
    }

    closeDocumentUploadModal() {
        this.setState({
            uploadModalIsOpen: false,
            currentDocument: null
        });
    }

    renderPublicDocumentRows(rows) {
        const { isFetchingPublicDocuments } = this.state;

        if (isFetchingPublicDocuments) {
            return (
                <tr>
                    <td colSpan="6"><LoadingSpinner label="Lade allgemeine Dokumente ..." block />
                    </td>
                </tr>
            );
        }

        if (!rows.length && !isFetchingPublicDocuments) {
            return (
                <EmptyTableHint
                    numColumns={5}
                    layout="center"
                    title="Es wurden noch keine allgemeinen Dokumente hinterlegt"
                />
            );
        }
        return rows;
    }

    renderCompanyDocumentRows(rows) {
        const { isFetchingCompanyDocuments } = this.state;

        if (isFetchingCompanyDocuments) {
            return (
                <tr>
                    <td colSpan="6"><LoadingSpinner
                        label="Lade unternehmensinterne Dokumente ..."
                        block
                    />
                    </td>
                </tr>
            );
        }

        if (!rows.length && !isFetchingCompanyDocuments) {
            return (
                <EmptyTableHint
                    numColumns={5}
                    title="Es wurden noch keine eigenen Dokumente hinterlegt"
                    layout="center"
                >
                    <p>
                        Laden Sie hier auftrags- und baustellenunabhängige Dokumente hoch, die Sie
                        allen Nutzern Ihres Unternehmens zur Verfügung stellen möchten.
                    </p>
                    <p>
                        Die hochgeladenen Dokumente können anschließend von den Nutzern Ihres
                        Unternehmens über die Web-App oder über die App abgerufen werden.
                    </p>
                </EmptyTableHint>
            );
        }

        return rows;
    }

    render() {
        const {
            isFetchingDocuments,
            publicDocuments,
            companyDocuments,
            uploadModalIsOpen,
            loading,
            currentDocument,
            companyDocumentsSortOptions,
            publicDocumentsSortOptions
        } = this.state;

        const companyDocumentRows = [];
        const publicDocumentRows = [];

        if (isFetchingDocuments) {
            return <LoadingSpinner label="Lade Dokumente" block />;
        }

        if (companyDocuments) {
            companyDocuments.forEach((document) => {
                companyDocumentRows.push((
                    <tr key={document.id}>
                        <PrimaryColumn
                            link={
                                <LightBoxLink media={document.file}>
                                    <span style={{ fontWeight: 600 }}>{document.name}</span>
                                </LightBoxLink>
                            }
                        >
                            <IfUser
                                can={['update-documents']}
                            >
                                <a
                                    onClick={() => this.openDocumentUploadModal({
                                        id: document.id,
                                        name: document.name,
                                        description: document.description,
                                        file: document.file
                                    })}
                                >
                                    Bearbeiten
                                </a>
                            </IfUser>
                            <IfUser
                                can={['delete-documents']}
                            >
                                <DeleteDocumentLink
                                    id={document.id}
                                    name={document.name}
                                    label="Entfernen"
                                    onDeleted={this.loadCompanyDocuments}
                                />
                            </IfUser>
                        </PrimaryColumn>
                        <td>
                            {document.description || <NotAvailablePlaceholder />}
                        </td>
                        <td>
                            <FileExtension fileName={document.file.fileName} />
                        </td>
                        <td>
                            {
                                autoFormatFileSize(document.file.size)
                            }
                        </td>
                        <td>
                            <DownloadIcon
                                mediaId={document.file.id}
                            />
                        </td>
                    </tr>
                ));
            });
        }

        if (publicDocuments) {
            publicDocuments.forEach((document, index) => {
                publicDocumentRows.push((
                    <tr key={index}>
                        <td className="name-column">
                            <LightBoxLink media={document.file}>
                                <span style={{ fontWeight: 600 }}>
                                    {document.name}
                                </span>
                            </LightBoxLink>
                        </td>
                        <td>
                            {document.description || <NotAvailablePlaceholder />}
                        </td>
                        <td>
                            <FileExtension fileName={document.file.fileName} />
                        </td>
                        <td>
                            {
                                autoFormatFileSize(document.file.size)
                            }
                        </td>
                        <td>
                            <DownloadIcon
                                mediaId={document.file.id}
                            />
                        </td>
                    </tr>
                ));
            });
        }

        return (
            <React.Fragment>
                {loading && <LoadingSpinner label="Aktualisiere Dokumente" block />}
                <Section
                    title="Unternehmensintern"
                    type="list"
                    subline={
                        <div className="section__header-tooltip">
                            <Tooltip id="company-documents">
                                In dieser Sektion können Sie auftrags- und baustellenunabhängige
                                Dokumente hochladen, die Sie allen Nutzern Ihres Unternehmens zur
                                Verfügung stellen möchten. <br /> <br /> Das Hochladen,
                                nachträgliche Bearbeiten
                                sowie Entfernen dieser Dokumente ist ausschließlich den Nutzern
                                Ihres Unternehmenskontos vorbehalten, denen die
                                Kontotypen &quot;Administrator&quot; oder &quot;Vollzugriff&quot; zugewiesen
                                wurden.
                            </Tooltip>
                        </div>
                    }
                    cta={
                        <IfUser
                            can={['create-documents']}
                        >
                            <button
                                className="btn btn--add-action"
                                onClick={() => this.openDocumentUploadModal()}
                            >
                                <span className="btn__icon">
                                    <Icon name="add" />
                                </span>
                                <span>Neues Dokument</span>
                            </button>
                        </IfUser>
                    }
                >
                    <div className="content-table">
                        <table cellPadding="0" cellSpacing="0" className="hover stack">
                            <CompanyDocumentsTableHeader
                                onChange={this.onCompanyDocumentsSortOptionChange}
                                sortOptions={companyDocumentsSortOptions}
                            />
                            <tbody>
                                {this.renderCompanyDocumentRows(companyDocumentRows)}
                            </tbody>
                        </table>
                    </div>
                </Section>


                <Section
                    title="Allgemein"
                    type="list"
                    subline={
                        <div className="section__header-tooltip">
                            <Tooltip id="general-documents">
                                In dieser Sektion finden Sie Dokumente, die kostenlos von Scaffeye®
                                für Sie bereitgestellt werden. Das nachträgliche Bearbeiten oder
                                Entfernen dieser Dokumente ist nicht möglich. <br /> <br /> Eigene
                                Dokumente
                                können Sie in der Sektion &quot;Unternehmensintern&quot; hochladen,
                                um diese den anderen Nutzern Ihres Unternehmenskontos zur Verfügung
                                zu stellen.
                            </Tooltip>
                        </div>
                    }
                >
                    <div className="content-table">
                        <table cellPadding="0" cellSpacing="0" className="hover stack">
                            <PublicDocumentsTableHeader
                                onChange={this.onPublicDocumentsSortOptionChange}
                                sortOptions={publicDocumentsSortOptions}
                            />
                            <tbody>
                                {this.renderPublicDocumentRows(publicDocumentRows)}
                            </tbody>
                        </table>
                    </div>
                </Section>
                {
                    uploadModalIsOpen && (
                        <DocumentUploadModal
                            onUpload={() => this.onDocumentUpload()}
                            onClose={() => this.closeDocumentUploadModal()}
                            document={currentDocument}
                        />
                    )
                }
            </React.Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchPublicDocuments,
        fetchCompanyDocuments
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(Documents);

