import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ResourceViewMode } from '../../Containers/Pages/Private/properties';
import ArchivedBadge from '../Common/Badge/ArchivedBadge';
import Icon from '../Common/Icon';
import ScrollSpy from '../Common/ScrollSpy/ScrollSpy';
import ScrollSpyLink from '../Common/ScrollSpy/ScrollSpyLink';
import LoadingSpinner from '../Page/LoadingSpinner';

const COLLAPSE_THRESHOLD = 40;

const renderNavigationalLinks = (navigationalLinks) =>
    navigationalLinks.map((navigationalLink) => (
        <ScrollSpyLink
            key={navigationalLink.id}
            className="detail-page-header__link"
            sectionId={navigationalLink.id}
        >
            {navigationalLink.label}
        </ScrollSpyLink>
    ));

const getIcon = (type) => {
    switch (type) {

        case 'diaryEntry':
            return <Icon name="diary" />;

        case 'site':
        default:
            return <Icon name="scaffold" />;
    }
};

const DetailPageHeader = ({ type, title, titleAddOns, actions, navigationalLinks, isLoading, onCollapse }) => {

    const [isCollapsed, setCollapsed] = useState(false);
    const [maxTitleWidth, setMaxTitleWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        updateCollapsed();

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    useEffect(() => {
        if (typeof onCollapse === 'function') {
            onCollapse(isCollapsed);
        }
    }, [onCollapse, isCollapsed]);

    const updateTitleMaxWidth = _.debounce(() => {
        const titleCt = document.getElementsByClassName('detail-page-header__ttl').item(0);
        if(!titleCt) {
            return;
        }

        const actionsCt = document.getElementsByClassName('detail-page-header__actions').item(0);
        const addOnsCt = document.getElementsByClassName('detail-page-header__ttl-add-ons').item(0);

        let maxWidth = titleCt.clientWidth - 24; // base margin between title and actions
        if(actionsCt) {
            maxWidth -= actionsCt.clientWidth;
        }

        if(addOnsCt) {
            maxWidth -= addOnsCt.clientWidth + 16; // margin
        }

        setMaxTitleWidth(maxWidth);
    });

    useEffect(() => {
        updateTitleMaxWidth();
    }, [type, title, titleAddOns, actions, navigationalLinks]);

    useEffect(() => {
        window.addEventListener("resize", updateTitleMaxWidth);

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", updateTitleMaxWidth);
    }, [])

    const onScroll = _.debounce(() => {
        updateCollapsed();
    });

    const updateCollapsed = () => {
        setCollapsed(window.pageYOffset > COLLAPSE_THRESHOLD);
    }

    const classes = ['detail-page-header'];

    if (isCollapsed) {
        classes.push('is-collapsed');
    }

    if (isLoading) {
        classes.push('is-loading');
    }

    // We have to disable nav links since the scroll spy does not work with async content
    const hasNavLinks = !isLoading && !_.isEmpty(navigationalLinks);
    if(hasNavLinks) {
        classes.push('has-nav-links');
    }

    return (
        <div className={`detail-page-header-ct ${hasNavLinks ? 'has-nav-links' : ''}`}>
            <header className={classes.join(' ')}>
                <div className="detail-page-header__cnt">
                    <div className="detail-page-header__ttl-ct">
                        <h1 className="detail-page-header__ttl">
                            <span className="detail-page-header__ttl-boundary" style={{maxWidth: maxTitleWidth}}>
                                {title}
                            </span>
                            {titleAddOns &&
                            <div className="detail-page-header__ttl-add-ons-ct">
                                <div className="detail-page-header__ttl-add-ons">
                                    {titleAddOns}
                                </div>
                            </div>
                            }
                        </h1>
                        <div className="detail-page-header__ttl-icon">
                            {isLoading ? <LoadingSpinner />: getIcon(type)}
                        </div>
                        {actions &&
                        <div className="detail-page-header__actions">
                            {actions}
                        </div>
                        }
                    </div>
                    {hasNavLinks &&
                    <nav className="detail-page-header__navigational-links">
                        <div className="detail-page-header__navigational-links-ct">
                            <ScrollSpy defaultSection collapsedHeader={isCollapsed}>
                                {renderNavigationalLinks(navigationalLinks)}
                            </ScrollSpy>
                        </div>
                    </nav>
                    }
                </div>
            </header>
        </div>
    );
}

DetailPageHeader.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    titleAddOns: PropTypes.node,
    actions: PropTypes.node,
    navigationalLinks: PropTypes.array,
    onCollapse: PropTypes.func,
    isLoading: PropTypes.bool,
    defaultSection: PropTypes.string,
};

DetailPageHeader.defaultProps = {
    title: '',
    titleAddOns: null,
    type: '',
    actions: null,
    navigationalLinks: [],
    onCollapse: undefined,
    isLoading: false
};

export default DetailPageHeader;
