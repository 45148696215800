import moment from 'moment/moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from '../../Common/Icon';

const bannerTriggerTypes = {
    trialEnds: 'trial_ends',
    mainSubscriptionEnds: 'main_subscription_ends'
};

const warningThresholdDays = 7;

class SubscriptionEndingSoonWarningBanner extends Component {

    static mainSubscriptionEndsBanner(subscriptionEndsOn) {
        return (
            <div>
                Ihr Abonnement wurde zum {subscriptionEndsOn} gekündigt.
                {' '}
                <Link
                    to="/account/subscription"
                >Verlängern Sie Ihr Abonnement jetzt
                </Link>, um auch weiterhin alle Vorzüge von
                Scaffeye® zu
                genießen.
            </div>
        );
    }

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        };

        this.hideBanner = this.hideBanner.bind(this);
    }

    componentWillMount() {
        if (this.trialEndsAndNoBillingDetails() || this.mainSubscriptionEndsAndNoBillingDetails() || this.mainSubscriptionEnds()) {

            if (!localStorage.getItem('bannerClosed')) {
                this.setState({
                    isVisible: true
                });
            }
        }
    }

    incompleteBillingDetailsBanner(trigger, daysUntilExpiration) {
        let subject;

        switch (trigger) {
            case bannerTriggerTypes.trialEnds:
                subject = 'Ihre Testphase';
                break;

            case bannerTriggerTypes.mainSubscriptionEnds:
                subject = 'Ihr Abonnement';
                break;

            default:
                subject = 'Ihr Abonnement';
        }
        return (
            <React.Fragment>
                <div>
                    {subject} läuft in {daysUntilExpiration} Tagen aus. Ihre Zahlungsdetails sind
                    jedoch
                    unvollständig.
                    Bitte überprüfen Sie Ihre Zahlungsdaten im Bereich
                    <Link
                        to="/account/subscription"
                    >
                        {' '} Abo & Rechnungen
                    </Link>, um auch weiterhin alle Vorzüge von
                    Scaffeye® zu
                    genießen.
                </div>
                <span
                    onClick={() => this.hideBanner()}
                    className="warning-banner__close-banner"
                >
                    <Icon name="close" />
                </span>
            </React.Fragment>

        );
    }

    hideBanner() {

        this.setState({
            isVisible: false
        });
        localStorage.setItem('bannerClosed', 'true');
    }


    mainSubscriptionEndsAndNoBillingDetails() {
        const { account: { mainSubscription }, billingDetailsComplete } = this.props;

        if (!mainSubscription || mainSubscription.ends === null) {
            return false;
        }

        if (moment(mainSubscription.ends) < moment()) {
            return false;
        }

        return (Math.abs(moment()
            .diff(mainSubscription.ends, 'days')) <= warningThresholdDays) && !billingDetailsComplete;
    }

    mainSubscriptionEnds() {
        const { account: { mainSubscription } } = this.props;

        if (!mainSubscription || mainSubscription.ends === null) {
            return false;
        }

        return (Math.abs(moment()
            .diff(mainSubscription.ends, 'days')) <= warningThresholdDays);
    }

    trialEndsAndNoBillingDetails() {
        const { account: { isOnTrial, trialEnd }, billingDetailsComplete } = this.props;

        return isOnTrial && (Math.abs(moment()
            .diff(trialEnd, 'days')) <= warningThresholdDays) && !billingDetailsComplete;
    }

    renderBannerContent() {
        let daysUntilExpiration;

        if (this.trialEndsAndNoBillingDetails()) {
            daysUntilExpiration = Math.abs(moment()
                .diff(this.props.account.trialEnd, 'days'));
            return this.incompleteBillingDetailsBanner(bannerTriggerTypes.trialEnds, daysUntilExpiration);
        }

        if (this.mainSubscriptionEndsAndNoBillingDetails()) {
            daysUntilExpiration = Math.abs(moment()
                .diff(this.props.account.mainSubscription.ends, 'days'));
            return this.incompleteBillingDetailsBanner(bannerTriggerTypes.mainSubscriptionEnds, daysUntilExpiration);
        }

        if (this.mainSubscriptionEnds()) {
            const subscriptionEndsOn = moment(this.props.account.mainSubscription.ends)
                .format('DD.MM.YYYY');
            return SubscriptionEndingSoonWarningBanner.mainSubscriptionEndsBanner(subscriptionEndsOn);
        }

        return null;
    }

    render() {
        const { isVisible } = this.state;

        if (isVisible) {
            return (
                <div className="warning-banner warning-banner--top">
                    {this.renderBannerContent()}
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = ({ currentUser }) => {
    const {
        billingAddress, isOnTrial, trialEnd, mainSubscription
    } = currentUser.usermeta.account || null;

    const billingDetailsComplete = billingAddress ? billingAddress.billingDetailsComplete : false;

    return {
        billingDetailsComplete,
        account: {
            isOnTrial,
            trialEnd,
            mainSubscription
        }
    };
};

export default connect(mapStateToProps, null)(SubscriptionEndingSoonWarningBanner);

