import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from './Icon';
import LightBox from './LightBox';

class LightBoxLink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lightBoxVisible: false
        };

        this.showLightBox = this.showLightBox.bind(this);
        this.hideLightBox = this.hideLightBox.bind(this);
    }

    showLightBox() {
        this.setState({
            lightBoxVisible: true
        });
    }

    hideLightBox() {
        this.setState({
            lightBoxVisible: false
        });
    }

    render() {
        const { media, children, showIcon } = this.props;
        const { lightBoxVisible } = this.state;

        return (
            <React.Fragment>
                <span className="light-box-link" onClick={this.showLightBox} role="button">
                    {children} {showIcon && <Icon name="filePreview" />}
                </span>

                {lightBoxVisible &&
                <LightBox media={media} onClose={this.hideLightBox} />
                }
            </React.Fragment>
        );
    }

}

LightBoxLink.propTypes = {
    media: PropTypes.object.isRequired,
    showIcon: PropTypes.bool
};

LightBoxLink.defaultProps = {
    showIcon: true
};


export default LightBoxLink;
