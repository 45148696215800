import _ from 'lodash';
import React from 'react';
import { MediaDiffValueItem } from './MediaDiffValueItem';

export const MediaDiffValue = ({ media, diff }) => {

    const isLeft = media === diff.newVal;
    const mediaIds = media ? media.map(mediaItem => mediaItem.id) : [];

    /*
     * Merge media, prioritizing the new or the old, depending on which column
     * is rendered right now
     */
    const leftOperand = isLeft ? diff.newVal : diff.oldVal;
    const rightOperand = isLeft ? diff.oldVal : diff.newVal;

    // Sort into buckets

    // New media
    const newMedia = _.differenceBy(leftOperand, rightOperand, 'id');

    // Changed media
    const changedMedia = _.intersectionWith(leftOperand, rightOperand, (left, right) => {
        return left.id === right.id && left.name !== right.name;
    });

    // Removed Media
    const removedMedia = _.differenceBy(rightOperand, leftOperand, 'id');

    // Untouched Media
    const untouchedMedia = _.intersectionWith(leftOperand, rightOperand, _.isEqual);

    // Collect the render media
    const renderMedia = [
        ...newMedia,
        ...changedMedia,
        ...removedMedia,
        ...untouchedMedia
    ];

    return (
        <div className="diff-media-collection">
            {renderMedia.map(mediaItem => {
                const isMine = mediaIds.indexOf(mediaItem.id) >= 0;
                return (
                    <MediaDiffValueItem
                        key={mediaItem.id}
                        mediaItem={isMine ? mediaItem : null}
                        diff={diff}
                    />
                );
            })}
        </div>
    );
}
