import React from 'react';
import Condition from '../../../Forms/Condition';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import OptionGroup from '../../../Forms/OptionGroup';
import { WidthClass } from '../../properties';
import { isEmergencyStairway, isUnmanaged } from '../functions';
import { WidthClassOptions } from '../options';
import { isRequired } from '../validate';

export const SectionWidthClassInput = ({ values }) => {
    const _isUnmanaged = isUnmanaged(values);
    const _isEmergencyStairway = isEmergencyStairway(values);
    const disabled = _isEmergencyStairway && !(_isUnmanaged);

    const tooltip = disabled ? 'Breitenklasse kann bei Fluchttreppen nicht konfiguriert werde' : null;
    let options = WidthClassOptions;
    if (disabled) {
        options = WidthClassOptions.map(option => {
            return {
                ...option,
                disabled: true
            }
        })
    }

    return (
        <InputGroup
            label="Breitenklasse"
            required={isRequired('widthClass', values)}
            tooltip={tooltip}
        >
            <OptionGroup
                name="widthClass"
                options={options}
                ifSelectionIs={WidthClass.Other}
                thenRender={field => ({
                    [WidthClass.Other]: <Input
                        name="widthClassOther"
                        type="text"
                        placeholder={`z.B. "W1,2"${isRequired('widthClassOther', values) ? '*' : ''}`}
                    />
                }[field])}
                type="radio"
                disabled={disabled}
            />

            <Condition
                when="purpose.protective"
                contains="safety_catch_scaffolding"
            >
                <InputHint>
                    Sie haben &quot;Fanggerüst&quot; im Verwendungszweck
                    angegeben. Bitte achten Sie darauf, dass die für Ihr
                    Fanggerüst zulässige Breitenklasse abhängig von der
                    Absturzkante Ihres Gerüstes ist. <br /> <br />
                    Konfigurationshilfen: <br />
                    In der Regel gilt bei Fanggerüsten die
                    Breitenklasse &quot;W06&quot; (bei einem Abstand von 0,9
                    m zwischen Gebäudewand und Außenkante und einem Abstand
                    des letzten Belags von max. 1,00 m unter der
                    Absturzkante).
                    Bei einer Absturzkante von 2,00 m gilt in der Regel eine
                    Breitenklasse von &quot;W09&quot; zzgl. 30 cm
                    Wandabstand. Alternativ können Sie auch eine
                    Breitenklasse von &quot;W06&quot; zzgl. 30 cm
                    Wandabstand wählen und die Breitenklasse mit Konsolen
                    (300 mm) verbeitern. Die Konsolen können Sie in der
                    Sektion &quot;Besonderheiten&quot; angeben.
                </InputHint>
            </Condition>
        </InputGroup>
    )
}
