import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSectionChangeLog } from '../../../redux/modules/section/action';
import ChangeLog from '../../Common/ChangeLog/ChangeLog';
import DiffModal from '../../Common/Diff/DiffModal';
import { getSectionDiffs } from '../../Common/Diff/functions';
import { SectionDiffValue } from '../../Common/Diff/Value/SectionDiffValue';
import LoadingSpinner from '../../Page/LoadingSpinner';

class SectionChangeLogModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            sectionChangeLog: null
        };
    }

    componentWillMount() {
        this.props.actions.fetchSectionChangeLog(this.props.sectionId)
            .then((sectionChangeLog) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    sectionChangeLog,
                    isFetching: false
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    renderModalContent() {
        const { scaffoldingName, siteName, sectionName } = this.props;
        const { sectionChangeLog } = this.state;
        return (
            <Fragment>
                <div className="diff-log__hd">
                    <h3 className="diff-log__ttl">Gerüstabschnitt: {sectionName}</h3>
                    <div className="diff-log__subttl">
                        Baustelle: {siteName} <br />
                        Gerüst: {scaffoldingName}
                    </div>
                </div>
                <div className="diff-log__bd">
                    <ChangeLog
                        entries={sectionChangeLog}
                        getChanges={getSectionDiffs}
                        renderChangeItemValue={(value, diff) => <SectionDiffValue value={value} diff={diff} />}
                    />
                </div>
            </Fragment>
        );
    }

    render() {
        const {
            isFetching
        } = this.state;

        return (
            <DiffModal
                title="Änderungshistorie"
                onBackdropClick={this.props.closeModal}
                onCloseClick={this.props.closeModal}
            >
                {isFetching ? <LoadingSpinner
                    block
                    label="Lade Änderungshistorie"
                    backgroundColor="white"
                /> : this.renderModalContent()}
            </DiffModal>
        );
    }
}

SectionChangeLogModal.propTypes = {
    sectionId: PropTypes.string.isRequired,
    scaffoldingName: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    sectionName: PropTypes.string.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSectionChangeLog
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(SectionChangeLogModal);
