import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../../properties';
import { ModelMediaType } from '../../../Media/properties';
import { showSupportType } from '../../../Sections/helpers';
import { getConstructionTypeLabel } from '../../../Sections/labels';
import { ConstructionTypes, SupportType } from '../../../Sections/properties';
import { ConstructionPlanModelMode } from '../../properties';
import ConstructionPlanSyncModelReferenceHint from '../Base/ConstructionPlanSyncModelReferenceHint';
import MediaSyncChoice from '../Base/Input/Media/MediaSyncChoice';
import MediaListSyncChoice from '../Base/Input/MediaListSyncChoice/MediaListSyncChoice';
import TextSyncChoice from '../Base/Input/Text/TextSyncChoice';
import SyncModelSection from '../Base/SyncModelSection';
import { getModelTypeKeyWithIdForForm } from '../form';
import {
    getMediaFromRepository,
    getSingleMediaFromRepository,
    propertyReferencesMedia,
    propertyReferencesSingleMedia
} from '../media';
import CladdingsSyncChoice from './Claddings/CladdingsSyncChoice';
import { isIdenticalSectionFormValue } from './equals';
import EquippedSurfacesSyncChoice from './EquippedSurfaces/EquippedSurfacesSyncChoice';
import { transformSectionToFormValues } from './form';
import LoadClassSyncChoice from './LoadClass/LoadClassSyncChoice';
import PlannedWorksSyncChoice from './PlannedWorks/PlannedWorksSyncChoice';
import EmergencyStairwayPurposeSyncChoice
    from './Purpose/EmergencyStairway/EmergencyStairwayPurposeSyncChoice';
import PurposeGroupSyncChoice from './Purpose/Groups/PurposeGroupsSyncChoice';
import PlatformStairwayPurposeSyncChoice
    from './Purpose/PlatformStairway/PlatformStairwayPurposeSyncChoice';
import ProtectivePurposeSyncChoice from './Purpose/Protective/ProtectivePurposeSyncChoice';
import WorkPurposeSyncChoice from './Purpose/Work/WorkPurposeSyncChoice';
import SectionStatusSyncChoice from './SectionStatus/SectionStatusSyncChoice';
import SpecialFeaturesSyncChoice from './SpecialFeatures/SpecialFeaturesSyncChoice';
import StabilityAndLoadSafetySyncChoice
    from './StabilityAndLoadSafety/StabilityAndLoadSafetySyncChoice';
import WallClearanceSyncChoice from './WallClearance/WallClearanceSyncChoice';
import WidthClassSyncChoice from './WidthClass/WidthClassSyncChoice';

class ConstructionPlanSyncFormSection extends React.Component {

    static propTypes = {
        values: PropTypes.object.isRequired,
        planValues: PropTypes.any,
        liveValues: PropTypes.any,
        setModelDataMutator: PropTypes.func.isRequired,
        setFieldTouchedMutator: PropTypes.func.isRequired,
        resolvedMedia: PropTypes.object.isRequired
    };

    static defaultProps = {
        liveValues: null
    };

    constructor(props) {
        super(props);
        this.sectionKey = getModelTypeKeyWithIdForForm(ModelType.Section, props.planValues);

        const planData = props.planValues?.data || {};
        const liveData = props.liveValues || {};
        const resolvedMedia = props.resolvedMedia || {};

        this.state = {
            planFormValues: Object.freeze(transformSectionToFormValues(planData, liveData, resolvedMedia)),
            liveFormValues: Object.freeze(transformSectionToFormValues(liveData, planData, resolvedMedia)),
        }

        this.selectAllPlan = this.selectAllPlan.bind(this);
        this.selectAllLive = this.selectAllLive.bind(this);
        this.setModelData = this.setModelData.bind(this);
        this.getPlanValue = this.getPlanValue.bind(this);
        this.getLiveValue = this.getLiveValue.bind(this);
        this.getFormNameForProperty = this.getFormNameForProperty.bind(this);
    }

    selectAllPlan() {
        const { values } = this.props;
        const newFormValues = {
            ...values[this.sectionKey],
            data: {
                ...this.state.planFormValues
            }
        };
        newFormValues.data.status = values[this.sectionKey].data.status;
        this.props.setModelDataMutator(this.sectionKey, newFormValues);
    }

    selectAllLive() {
        const { values } = this.props;
        const newFormValues = {
            ...values[this.sectionKey],
            data: {
                ...this.state.liveFormValues
            }
        };
        newFormValues.data.status = values[this.sectionKey].data.status;
        this.props.setModelDataMutator(this.sectionKey, newFormValues);
    }

    getFormNameForProperty(property) {
        return `${this.sectionKey}.data.${property}`;
    }

    hasIdenticalOptions(all = true) {
        const { planFormValues, liveFormValues } = this.state;

        const allProperties = [
            ...Object.keys(planFormValues),
            ...Object.keys(liveFormValues),
        ];

        let hasIdentical = all;
        allProperties.forEach(property => {

            // We need to ignore 'status' and 'contractorId' as they can't be specified
            // in the construction planner
            if(property === 'status' || property === 'contractorId') {
                return;
            }

            const identical = isIdenticalSectionFormValue(property, planFormValues, liveFormValues);
            if(all) {
                hasIdentical &= identical;
            } else {
                hasIdentical |= identical;
            }
        });

        return !!hasIdentical;
    }

    setModelData(data) {
        const { setModelDataMutator } = this.props;
        setModelDataMutator(this.sectionKey, data);
    }

    getName() {
        const { liveValues, planValues } = this.props;
        return planValues.data ? planValues.data.name : liveValues.name;
    }

    getLiveValue(property) {
        const {liveFormValues, planFormValues} = this.state;
        return this.getValue(property, liveFormValues, planFormValues);
    }

    getPlanValue(property) {
        const {liveFormValues, planFormValues} = this.state;
        return this.getValue(property, planFormValues, liveFormValues);
    }

    getLiveStatus() {
        const { liveValues } = this.props;

        if (!liveValues) {
            return null;
        }

        return liveValues.status;
    }

    getStringValue(property, model) {
        return model[property];
    }

    getObjectValue(property, model) {
        let isOnePropertySet = Object.keys(model[property])
            .filter(valueProperty => !!model[property][valueProperty]).length > 0;

        if (isOnePropertySet) {
            return model[property];
        }

        return {};
    }

    renderConstructionType(constructionType) {
        if(!constructionType || ConstructionTypes.indexOf(constructionType) < 0) {
            return '-';
        }

        return getConstructionTypeLabel(constructionType);
    }

    renderSupportType(supportType) {
        switch (supportType) {
            case SupportType.Standing:
                return showSupportType(SupportType.Standing);
            default:
                return '-';
        }
    }

    getValue(property, data) {
        let defaultValue = null;
        const { resolvedMedia } = this.props;

        if (propertyReferencesMedia(property)) {
            return getMediaFromRepository(data ? data[property] : null, resolvedMedia);
        } else if (propertyReferencesSingleMedia(property)) {
            return getSingleMediaFromRepository(data, property, resolvedMedia);
        }

        if (['specialConstruction', 'staticsCertificateNecessary'].indexOf(property) >= 0) {
            return !!data[property];
        } else if (data && data.hasOwnProperty(property)) {
            const val = data[property];
            if (typeof val === 'string') {
                return this.getStringValue(property, data);
            }

            if(typeof val === 'object') {
                return _.isEmpty(val) ? null : val;
            }

            return val;
        }

        return defaultValue;
    }

    renderContentBeforeActions(identical) {
        const {
            planValues
        } = this.props;

        if (planValues.mode === ConstructionPlanModelMode.Reference || identical) {
            return null;
        }

        return (
            <SectionStatusSyncChoice
                name={this.getFormNameForProperty('status')}
                liveValue={this.getLiveValue('status')}
            />
        );
    }

    renderInputFields() {
        const {
            planValues,
            values,
            setFieldTouchedMutator
        } = this.props;

        const { mode } = planValues;

        if (mode === ConstructionPlanModelMode.Reference) {
            return <ConstructionPlanSyncModelReferenceHint />;
        }

        const showIdentical = values[this.sectionKey].showIdentical;

        return (
            <Fragment>

                <TextSyncChoice
                    label="Name"
                    name={this.getFormNameForProperty('name')}
                    planValue={this.getPlanValue('name')}
                    liveValue={this.getLiveValue('name')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                    required
                />

                <TextSyncChoice
                    label="Auftraggeber"
                    name={this.getFormNameForProperty('customer')}
                    planValue={this.getPlanValue('customer')}
                    liveValue={this.getLiveValue('customer')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <EquippedSurfacesSyncChoice
                    name={this.getFormNameForProperty('equippedSurfaces')}
                    planValue={this.getPlanValue('equippedSurfaces')}
                    liveValue={this.getLiveValue('equippedSurfaces')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <PurposeGroupSyncChoice
                    name={this.getFormNameForProperty('purposeGroups')}
                    planValue={this.getPlanValue('purposeGroups')}
                    liveValue={this.getLiveValue('purposeGroups')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <WorkPurposeSyncChoice
                    name={this.getFormNameForProperty('workPurpose')}
                    planValue={this.getPlanValue('workPurpose')}
                    liveValue={this.getLiveValue('workPurpose')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <ProtectivePurposeSyncChoice
                    name={this.getFormNameForProperty('protectivePurposes')}
                    planValue={this.getPlanValue('protectivePurposes')}
                    liveValue={this.getLiveValue('protectivePurposes')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <PlatformStairwayPurposeSyncChoice
                    name={this.getFormNameForProperty('platformStairwayPurpose')}
                    planValue={this.getPlanValue('platformStairwayPurpose')}
                    liveValue={this.getLiveValue('platformStairwayPurpose')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <EmergencyStairwayPurposeSyncChoice
                    name={this.getFormNameForProperty('emergencyStairwayPurpose')}
                    planValue={this.getPlanValue('emergencyStairwayPurpose')}
                    liveValue={this.getLiveValue('emergencyStairwayPurpose')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <PlannedWorksSyncChoice
                    name={this.getFormNameForProperty('plannedWorks')}
                    planValue={this.getPlanValue('plannedWorks')}
                    liveValue={this.getLiveValue('plannedWorks')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <TextSyncChoice
                    label="Tragsystem"
                    name={this.getFormNameForProperty('supportType')}
                    planValue={this.getPlanValue('supportType')}
                    liveValue={this.getLiveValue('supportType')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                    renderValue={this.renderSupportType}
                />

                <TextSyncChoice
                    label="Ausführungsart"
                    name={this.getFormNameForProperty('constructionType')}
                    planValue={this.getPlanValue('constructionType')}
                    liveValue={this.getLiveValue('constructionType')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                    renderValue={this.renderConstructionType}
                />

                <StabilityAndLoadSafetySyncChoice
                    getFormNameForProperty={this.getFormNameForProperty}
                    getPlanValue={this.getPlanValue}
                    getLiveValue={this.getLiveValue}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                    mode={mode}
                />

                <LoadClassSyncChoice
                    name={this.getFormNameForProperty('loadClass')}
                    planValue={this.getPlanValue('loadClass')}
                    liveValue={this.getLiveValue('loadClass')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <WallClearanceSyncChoice
                    name={this.getFormNameForProperty('wallClearance')}
                    planValue={this.getPlanValue('wallClearance')}
                    liveValue={this.getLiveValue('wallClearance')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <WidthClassSyncChoice
                    name={this.getFormNameForProperty('widthClass')}
                    planValue={this.getPlanValue('widthClass')}
                    liveValue={this.getLiveValue('widthClass')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <CladdingsSyncChoice
                    name={this.getFormNameForProperty('claddings')}
                    planValue={this.getPlanValue('claddings')}
                    liveValue={this.getLiveValue('claddings')}
                    liveStatus={this.getLiveStatus()}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <SpecialFeaturesSyncChoice
                    name={this.getFormNameForProperty('specialFeatures')}
                    planValue={this.getPlanValue('specialFeatures')}
                    liveValue={this.getLiveValue('specialFeatures')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <TextSyncChoice
                    label="Notizen"
                    name={this.getFormNameForProperty('description')}
                    planValue={this.getPlanValue('description')}
                    liveValue={this.getLiveValue('description')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <MediaListSyncChoice
                    label="Anhänge"
                    name={this.getFormNameForProperty('attachments')}
                    planValue={this.getPlanValue('attachments')}
                    liveValue={this.getLiveValue('attachments')}
                    setFieldTouched={setFieldTouchedMutator}
                    modelMediaType={ModelMediaType.Section_Attachment}
                    showIdentical={showIdentical}
                />

                <MediaSyncChoice
                    label="Ankerprotokoll"
                    name={this.getFormNameForProperty('anchorProtocol')}
                    setFieldTouched={setFieldTouchedMutator}
                    planValue={this.getPlanValue('anchorProtocol')}
                    liveValue={this.getLiveValue('anchorProtocol')}
                    modelMediaType={ModelMediaType.Section_AnchorProtocol}
                    showIdentical={showIdentical}
                />

                <MediaListSyncChoice
                    label="Nutzungsplan"
                    name={this.getFormNameForProperty('utilizationPlans')}
                    planValue={this.getPlanValue('utilizationPlans')}
                    liveValue={this.getLiveValue('utilizationPlans')}
                    setFieldTouched={setFieldTouchedMutator}
                    modelMediaType={ModelMediaType.Section_AnchorProtocol}
                    showIdentical={showIdentical}
                />

                <MediaSyncChoice
                    label="Statiknachweis"
                    name={this.getFormNameForProperty('proofOfStability')}
                    setFieldTouched={setFieldTouchedMutator}
                    planValue={this.getPlanValue('proofOfStability')}
                    liveValue={this.getLiveValue('proofOfStability')}
                    modelMediaType={ModelMediaType.Section_ProofOfStability}
                    showIdentical={showIdentical}
                />
            </Fragment>
        );
    }


    render() {
        const {
            planValues,
            values,
            resolvedMedia
        } = this.props;

        const {
            planFormValues,
            liveFormValues,
        } = this.state;

        const identical = this.hasIdenticalOptions(true);

        return (
            <SyncModelSection
                type={ModelType.Section}
                formValues={values[this.sectionKey]}
                name={this.getName()}
                onChange={this.setModelData}
                planData={planFormValues}
                liveData={liveFormValues}
                hasIdenticalOptions={this.hasIdenticalOptions(false)}
                mode={planValues.mode}
                contentBeforeActions={this.renderContentBeforeActions(identical)}
                onSelectAllPlan={this.selectAllPlan}
                onSelectAllLive={this.selectAllLive}
                allValuesIdentical={identical}
                resolvedMedia={resolvedMedia}
            >
                {this.renderInputFields()}
                {/*<FormDebugger values={values[this.sectionKey]} />*/}
            </SyncModelSection>
        );
    }
}

export default ConstructionPlanSyncFormSection;
