import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../../../properties';
import ArchivedBadge from '../../../../Common/Badge/ArchivedBadge';
import LinkButton from '../../../../Common/LinkButton';
import Tooltip from '../../../../Common/Tooltip';
import Condition from '../../../../Forms/Condition';
import ConstructionPlanModeSelection
    from '../../../../Forms/ConstructionPlan/ConstructionPlanModeSelection';
import Input from '../../../../Forms/Input';
import InputGroup from '../../../../Forms/InputGroup';
import { FormMode } from '../../../../Forms/properties';
import SiteFormContent from '../../../../Sites/Form/SiteFormContent';
import SiteSelect from '../../../../Sites/SiteSelect';
import { ConstructionPlanModelMode } from '../../../properties';
import { ConstructionPlanModelModePropType } from '../../propTypes';

function isFormReadOnly(values) {
    return (values.mode === ConstructionPlanModelMode.Reference);
}

const SiteReferenceFormContent = ({ referenceData, isArchived, mode, attachmentsMutator, onUploading, inputDisabled, values, onResetSite }) => {
    return (
        <Fragment>
            {values.id ?
                <InputGroup
                    label="Baustelle*"
                >
                        <div className="construction-plan-form__reference">
                            {referenceData && referenceData.name}
                            {isArchived && (<Fragment>
                                <ArchivedBadge size="small" />
                                <Tooltip id="site-is-archived-tooltip">Die verknüpfte Baustelle wurde archiviert. Archivierten Baustellen stehen die Funktionen zur Konfigurationsanpassung nicht mehr zur Verfügung.</Tooltip>
                            </Fragment>)
                            }
                        </div>
                        <LinkButton
                            label="Baustelle ändern (Anpassungen werden verworfen)"
                            onClick={onResetSite}
                            bold
                            underline
                        />
                        <Input
                            readOnly
                            type="hidden"
                            name="id"
                        />
                </InputGroup> :
                <InputGroup tooltip="Es stehen Ihnen ausschließlich aktive Baustellen aus der Baustellenverwaltung zur Auswahl zur Verfügung. Archivierte Baustellen können nicht zur Planung verwendet werden." label="Baustelle*">
                    <SiteSelect
                        name="id"
                    />
                </InputGroup>
            }

            <Condition when="id" isSet>
                <ConstructionPlanModeSelection
                    type={ModelType.Site}
                    readOnly={isArchived}
                />

                <SiteFormContent
                    values={{ ...values }}
                    mode={FormMode.Edit}
                    referenceData={referenceData}
                    attachmentsMutator={attachmentsMutator}
                    onUploading={onUploading}
                    readOnly={isFormReadOnly(values) || inputDisabled || isArchived}
                    isArchived={isArchived}
                    isConstructionPlan
                    constructionPlanMode={mode}
                />
            </Condition>
        </Fragment>
    );
};

const NewSiteFormContent = ({ values, attachmentsMutator, onUploading }) => (
    <SiteFormContent
        mode={FormMode.Edit}
        values={values}
        attachmentsMutator={attachmentsMutator}
        onUploading={onUploading}
        isConstructionPlan
        constructionPlanMode={ConstructionPlanModelMode.New}
    />
);

const ConstructionPlanSiteFormContent = (props) => {
    const { mode } = props;

    if (mode !== ConstructionPlanModelMode.New) {
        return SiteReferenceFormContent(props);
    }

    return NewSiteFormContent(props);
};

ConstructionPlanSiteFormContent.propTypes = {
    values: PropTypes.object.isRequired,
    mode: ConstructionPlanModelModePropType.isRequired,
    isInitializing: PropTypes.bool,
    inputDisabled: PropTypes.bool.isRequired,
    onResetSite: PropTypes.func.isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    onUploading: PropTypes.func.isRequired,
    referenceData: PropTypes.object,
    isArchived: PropTypes.bool,
};

ConstructionPlanSiteFormContent.defaultProps = {
    isInitializing: false,
    referenceData: null,
    isArchived:false
};

export default ConstructionPlanSiteFormContent;

