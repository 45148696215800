import React from 'react';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';

export const SectionNameInput = ({ readOnly }) => (
    <InputGroup
        label="Bezeichnung*"
    >
        <Input
            type="text"
            name="name"
            placeholder='z.B. "Abschnitt 2-A" , "Vorderseite" oder "Dachfang links"'
            readOnly={readOnly}
        />
    </InputGroup>
);
