import React from 'react';
import EqualHeight from '../../../Common/EqualHeight';

const EqualSyncOptionHeight = ({ children }) => (
    <EqualHeight
        selector=".js-sync-option-cnt"
    >
        {children}
    </EqualHeight>
);

export default EqualSyncOptionHeight;
