import React from 'react';

const Terms = () => (
        <div className="terms-ct">
            <div>
                <h2>Allgemeine Nutzungs­bedingungen für Scaffeye-Apps</h2>
                <p>Durch das Herunterladen kommt ein Vertrag über die Nutzung der Software Scaffeye
                    zwischen dem Nutzer und der Firma Scaffeye GbR, Im Paesch 17-18, 54340 Longuich
                    (nachfolgend Firma Scaffeye) zustande.<br/>
                        Die Nutzung ist während einer Probezeit von 30 Tagen kostenfrei.</p>
                <p>Besteht der Nutzungsvertrag über diesem Zeitraum hinaus fort, so kommt zwischen
                    der Firma Scaffeye und dem Nutzer ein entgeltlicher Nutzungsvertrag über die
                    jeweils konkret bestellte Leistung zustande. Das Entgelt über die vereinbarte
                    Leistung ergibt sich – soweit es nicht konkret vereinbart ist – aus der
                    aktuellen Preisliste der Firma Scaffeye, die der Nutzer bereits vor Abschluss
                    des Nutzungsvertrages einsehen kann.</p>
                <p>Mit dem Herunterladen erklärt sich der Nutzer mit den nachfolgenden Bedingungen
                    einverstanden:</p>
                <p><strong>1.</strong> Mit dem Herunterladen gewährt die Firma Scaffeye dem Nutzer
                    das nicht-ausschließliche Recht zur Nutzung der App auf einem Gerät, auf dem die
                    App laut Systemvoraussetzungen lauffähig ist. Das Recht zur Nutzung umfasst
                    dabei nicht die Bereitstellung der App über ein Netzwerk zur gleichzeitigen
                    Nutzung auf mehreren Endgeräten.</p>
                <p>Alle anderen Rechte an der App verbleiben bei der Firma Scaffeye. Dem Nutzer ist
                    insbesondere nicht gestattet, die Software zu vertreiben und/oder anderweitig
                    Dritten zu übertragen, beispielsweise durch Vermietung, Verpachtung, Leihgabe
                    und/oder Lizenzierung. Die vorstehenden Bedingungen gelten auch für alle
                    Updates/Upgrades und Programmergänzungen für die Software, die dem Nutzer von
                    Scaffeye zum Download bereitgestellt werden und die nicht Gegenstand einer
                    gesonderten Vereinbarung sind.</p>
                <p><strong>2.</strong> Mit Zustandekommen des Nutzungsvertrages wird dem Nutzer ein
                    eigener Account zur Verfügung gestellt. Der Nutzer ist dadurch berechtigt, die
                    Scaffeye-Datenbank zu nutzen. Die von ihm gespeicherten Daten werden dort und
                    auch im mobilen Gerät gespeichert.</p>
                <p><strong>3.</strong> Scaffeye gewährleistet, dass seine Datenbank und die dazu
                    gehörige Software durchgehend 24 Stunden, sieben Tage die Woche mit einer
                    Verfügbarkeit von 98% im Monatsmittel einsatzfähig ist.<br/>
                        Hiervon ausgenommen sind Ausfallzeiten für Wartung und Software-Updates
                        sowie für Störungen der Verfügbarkeit des Servers über das Internet, die auf
                        technischen oder sonstigen Problemen beruhen, die nicht im Verfügungsbereich
                        von Scaffeye liegen (z.B. Störungen im Telekommunikationsnetz, höhere Gewalt
                        u.ä.). Zur Vornahme von Wartungsarbeiten ist Scaffeye berechtigt, die
                        Verfügbarkeit der Datenbank und der dazu gehörigen Software an jedem
                        Wochentag zwischen 18 und 22 Uhr Wartungsarbeiten durchzuführen. Diese
                        Wartungsarbeiten können zu Ausfällen bis zu wenigen Minuten führen. Größere
                        Wartungsarbeiten werden in einer Zeit zwischen 0 Uhr und 5 Uhr durchgeführt.
                        Sie können zu Nutzungsunterbrechungen von bis zu 4 Stunden führen. Solche
                        größeren Wartungsarbeiten werden dem Nutzer außer in Eilfällen mindestens
                        drei Tage vorher angekündigt.</p>
                <p><strong>4.</strong> Im Falle einer wiederholten Leistungsstörung ist Scaffeye
                    berechtigt den Nutzungsvertrag fristlos zu kündigen. Dies gilt insbesondere
                    dann, wenn der Nutzer mit seine Zahlungen mehr als zwei Monate in Verzug ist.
                </p>
                <p>Scaffeye ist ferner berechtigt, den Nutzer dann, wenn er die App trotz Abmahnung
                    rechtsmissbräulich nutzt, durch eine Sperre von der Nutzung der App
                    vorübergehend auszuschließen.</p>
                <p><strong>5.</strong> Vor Nutzung der App hat der Kunde die App auf eine
                    ordnungsgemäße Installation und Benutzbarkeit zu prüfen. Kommt er im Rahmen
                    dieser Prüfung zu dem Ergebnis, dass die App nicht ordnungsgemäß funktioniert,
                    so hat er diese der Firma Scaffeye gegenüber unverzüglich, spätestens innerhalb
                    von zwei Werktagen nach Bestellung in Textform zu rügen. Tut er dies nicht, sind
                    eventuelle Gewährleistungsansprüche bis zur Mängelanzeige ausgeschlossen. Bei
                    schuldhafter Nichtanzeige haftet der Besucher auf Schadensersatz.</p>
                <p>Der Nutzer ist für einen ordnungsgemäßen Umgang mit der Scaffeye-App
                    verantwortlich. Insbesondere hat er bei der Nutzung die für die Verwendung
                    notwendige Sorgfaltspflicht einzuhalten und die mit der App generierten
                    Ergebnisse regelmäßig vor deren Verwendung auf Richtigkeit zu prüfen. Kommt er
                    im Rahmen dieser Prüfung zu dem Ergebnis, dass die App nicht ordnungsgemäß
                    funktioniert, so hat er dies der Firma Scaffeye unverzüglich, spätestens
                    innerhalb von zwei Werktagen in Textform zu rügen. Der Nutzer ist gehalten, alle
                    von Scaffeye zur Verfügung gestellten Updates sofort herunterzuladen.
                    Andernfalls kann die App möglicherweise nicht ordnungsgemäß genutzt werden.</p>
                <p>Im Streitfall ist der Nutzer verpflichtet, die ordnungsgemäße Verwendung der App
                    beziehungsweise ihrer Software sowie auch das sofortige Herunterladen von
                    Updates nachzuweisen.</p>
                <p>Soweit der Nutzer mit Hilfe der App personenbezogene Daten Dritter speichert, ist
                    er verpflichtet, die datenschutzrechtlichen Bestimmungen zu beachten. Soweit
                    erforderlich, wird er eine ordnungsgemäße Einwilligung des Dritten zur
                    Verwendung seiner personenbezogenen Daten einholen und dessen personenbezogene
                    Daten bei Widerruf einer solchen Einwilligung den einschlägigen Vorschriften
                    gemäß löschen oder deren Nutzung einschränken.</p>
                <p><strong>6.</strong> Soweit gesetzliche Ansprüche wegen Mängeln bestehen, hat der
                    Nutzer einen Anspruch Behebung des Mangels. Abzüge werden Mängeln von laufenden
                    Zahlungen sind ausgeschlossen. Dem Nutzer bleibt vorbehalten, etwaige
                    Minderungsbeträge im Wege der Rückforderungsklage geltend zu machen.</p>
                <p>Schadensersatzansprüche gegen Scaffeye bestehen bei Vorsatz und grober
                    Fahrlässigkeit. Sie bestehen ferner bei der fahrlässigen Verletzung von
                    Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
                    überhaupt erst ermöglicht, deren Verletzung die Erreichung des Vertragszwecks
                    gefährdet und auf deren Einhaltung der Kunde regelmäßig vertraut. Im
                    letztgenannten Fall ist die Haftung für nicht vorhersehbare, nicht
                    vertragstypische Schäden ausgeschlossen. Schadensersatzansprüche wegen leicht
                    fahrlässiger Verletzung anderer Vertragspflichten gegen Scaffeye sind
                    ausgeschlossen. Die vorstehenden Haftungsausschlüsse gelten nicht bei der
                    Verletzung von Leben, Körper und Gesundheit. Die Haftung nach dem
                    Produkthaftungsgesetz bleibt unberührt.</p>
                <p><strong>7.</strong> Macht ein Dritter wegen der von Scaffeye gelieferten App dem
                    Nutzer gegenüber Ansprüche aus Patenten, Urheberrechten oder sonstigen
                    gewerblichen Schutzrechten geltend, übernimmt Scaffeye auf seine Kosten die
                    Vertretung des Nutzers in jedem gegen diesen geführten Rechtsstreit und stellt
                    den Nutzer hinsichtlich derartiger Ansprüche frei.<br/>
                        Dies gilt allerdings nur dann, wenn der Nutzer Scaffeye über entsprechende
                        Anspruchsschreiben Dritter und Einzelheiten etwaiger Rechtsstreite
                        unverzüglich in Kenntnis setzt und Scaffeye sämtliche Entscheidungen
                        hinsichtlich der weiteren Verwendung der vom Dritten angegriffenen Software,
                        der Rechtsverteidigung sowie eines Vergleichsabschlusses überlässt.</p>
                <p><strong>8.</strong> Der Nutzer ist berechtigt, die von ihm gespeicherten Daten
                    bei Vertragsende in dem gespeicherten Format auf ein Speichermedium seiner Wahl
                    herunterzuladen. Nach dem Herunterladen der Daten werden die Daten gelöscht,
                    soweit sie nicht von anderen Nutzern berechtigt verwendet werden und soweit
                    keine gesetzlichen Speicherpflichten bestehen.</p>
                <p>Lädt der Nutzer die Daten nicht herunter, werden diese nicht von dritten Nutzern
                    benutzt und bestehen keine gesetzlichen Speicherpflichten, werden die Daten
                    spätestens 6 Monate nach Ende der Vertragsbeziehung gelöscht. Der Nutzer wird
                    von der beabsichtigten Löschung spätestens einen Monat vor der Löschung
                    unterrichtet. Die Unterrichtung erfolgt an die zuletzt bekannten und für
                    Mitteilungen verwendeten Kommunikationsdaten (z.B. Anschrift bzw.
                    E-Mail-Adresse).</p>
                <p><strong>9.</strong> Für den Vertrag über die Nutzung der App gelten nur diese
                    allgemeinen Geschäftsbedingungen. Allgeneine Geschäftsbedingungen des Nutzers
                    gelten nicht.</p>
                <p><strong>10.</strong> Die Aufrechnung mit anderen als unbestrittenen oder
                    rechtskräftig festgestellten Forderungen ist unzulässig. Der vorstehende Satz
                    gilt nicht, wenn mit einem Anspruch, der auf einer mangelhaften Leistung von
                    Scaffeye besteht, gegen diesen Vergütungsanspruch aufgerechnet wird.<br/>
                        Die Ausübung eines Zurückbehaltungsrechts, das nicht auf einem Recht aus
                        diesem Vertragsverhältnis beruht, ist unzulässig.</p>
                <p><strong>11.</strong> Sollten einzelne Bestimmungen der Nutzungsbedingungen
                    rechtlich unwirksam sein, werden hierfür die übrigen Nutzungsbedingungen in
                    ihrer Rechtswirksamkeit nicht berührt.</p>
                <p><strong>12.</strong> Das Vertragsverhältnis unterliegt deutschem Recht unter
                    Ausschluss des UN-Kaufrechts. Ist der Nutzer Kaufmann, juristische Person des
                    öffentlichen Rechts, oderöffentlich-rechtliches Sondervermögen, ist Gerichtstand
                    für alle Streitigkeiten aus dem Vertragsverhältnis Trier.</p>
            </div>
        </div>
);

export default Terms;
