import PropTypes from 'prop-types';
import React, { Component } from 'react';

const UploadProgress = ({progress}) => {

    const classes = ['upload-progress'];

    if(typeof progress === 'boolean'){
        classes.push('upload-progress--failed');
    }

    if(progress === 0 ){
        return null;
    }

    return (
        <div className={classes.join(' ')}>
            <div className="upload-progress__bar">
                <span className="upload-progress__fill" style={{width: `${progress}%`}}></span>
            </div>

            <div className="upload-progress__upload-percentage">
                {progress > 0 ?
                     `${progress} %`
                    :
                    '0%'
                }
            </div>
        </div>
    )
}


export default UploadProgress;

UploadProgress.propTypes = {
    progress: PropTypes.number,
};

UploadProgress.defaultProps = {
    progress: 0,
};





