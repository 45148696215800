import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import SectionStatusSelectField from '../../../../Sections/Form/SectionStatusSelectField';
import { getStatusLabel } from '../../../../Sections/labels';
import { SectionStatus } from '../../../../Sections/properties';
import SyncChoiceOptionHint from '../../Base/Input/SyncChoiceOptionHint';

const EDIT_STATUS = [
    SectionStatus.Pending,
    SectionStatus.UnderConstruction,
    SectionStatus.BeingAltered,
    SectionStatus.UnderMaintenance,
    SectionStatus.Closed,
    SectionStatus.Dismantled
];

function getEditStatusOptions(liveStatus) {
    const availableOptions = [...EDIT_STATUS];
    if(liveStatus === SectionStatus.Draft) {
        availableOptions.push(SectionStatus.Draft);
    }

    return availableOptions.map(value => ({
        value,
        label: getStatusLabel(value)
    }));
}

const validateStatus = (value) => {
    if (!value) {
        return 'Bitte wählen Sie einen Status';
    }
};

const SectionStatusSyncChoice = ({ name, liveValue }) => {

    const renderForNewSection = () => {
        return (
            <Fragment>
                <span className="status-label">Status: </span>
                <span className="status-value">{getStatusLabel(SectionStatus.Draft)}</span>
                <SyncChoiceOptionHint
                    content={
                        <Fragment>
                            <p>
                                Neu geplante Gerüstabschnitte können nur im Status “In Planung” auf
                                eine Live-Baustelle
                                übertragen werden.
                            </p>
                        </Fragment>
                    }
                    tooltipId="sync-section-status-hint"
                />
            </Fragment>
        );
    };

    const renderForEditSection = () => {
        return (
            <SectionStatusSelectField
                name={name}
                options={getEditStatusOptions(liveValue)}
                validate={validateStatus}
            />
        );
    };

    return (
        <div
            className="sync-row sync-row--sec-status"
        >
            <div className="sync-column">
                {
                    liveValue ? renderForEditSection() : renderForNewSection()
                }
            </div>
            <div className="sync-column sync-column--live">
                {getStatusLabel(liveValue)}
            </div>
        </div>
    );
};

SectionStatusSyncChoice.propTypes = {
    name: PropTypes.string.isRequired,
    liveValue: PropTypes.string,
    value: PropTypes.any
};

export default SectionStatusSyncChoice;
