import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import Icon from './Icon';

const tooltipRoot = document.getElementById('tooltip-root') || document.body.appendChild(document.createElement('div'));

export class PortalTooltip extends Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
        id: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        tooltipRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        tooltipRoot.removeChild(this.el);
    }

    renderTooltip() {
        const { children, id } = this.props;
        return (
            <ReactTooltip isCapture className="tooltip__content" place="bottom" id={id}>
                {children}
            </ReactTooltip>
        );
    }

    render() {
        return ReactDOM.createPortal(
            this.renderTooltip(),
            this.el,
        );
    }
}

const Tooltip = ({ children, label, icon, id }) => (
    <span className="tooltip">
        <span className="tooltip__icon" data-for={id} data-tip>
            <Icon name={icon} />
        </span>
        {label &&
        <span className="tooltip__label">
            {label}
        </span>
        }

        <PortalTooltip id={id}>
            {children}
        </PortalTooltip>
    </span>
);

Tooltip.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string
};

Tooltip.defaultProps = {
    icon: 'info',
    label: ''
};

export default Tooltip;
