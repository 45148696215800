import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../Common/Icon';
import InputHint from '../../../../Forms/InputHint';
import { showEquippedSurfaces, showPlannedWorks } from '../../../../Sections/helpers';
import { SectionPurpose } from '../../../../Sections/SectionPurpose';

export const ConstructionPlanSectionDataPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    equippedSurfaces: PropTypes.object,
    plannedWorks: PropTypes.object,
    purposes: PropTypes.arrayOf(PropTypes.string),
    otherProtectivePurpose: PropTypes.string
});

const ConstructionPlanScaffoldingSectionItem = ({ data }) => {

    const { name, equippedSurfaces, plannedWorks, purposes, otherProtectivePurpose } = data;

    return (
        <div className="constructionplan-reference-item">
            <div className="constructionplan-reference-item__hd">
                <span className="constructionplan-reference-item__icon">
                    <Icon
                        name="plannerExisting"
                    />
                </span>
                <span className="constructionplan-reference-item__title">{name}</span>
            </div>
            <InputHint>
                Eingerüstete Flächen: {showEquippedSurfaces(equippedSurfaces, 'n.a.')} <br />
                Verwendungszweck: <SectionPurpose purposes={purposes} otherProtectivePurpose={otherProtectivePurpose} mode="inline"/> <br/>
                Geplante Arbeiten: {showPlannedWorks(plannedWorks, 'n.a.')}
            </InputHint>
        </div>
    );
};

ConstructionPlanScaffoldingSectionItem.propTypes = {
    data: ConstructionPlanSectionDataPropType.isRequired
};

export default ConstructionPlanScaffoldingSectionItem;

