import { FORM_ERROR } from 'final-form';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import InputGroup from '../Forms/InputGroup';
import Section from '../Layout/Section';
import Input from '../Forms/Input';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { fetchAndUpdateUser, updateMyself } from '../../redux/modules/user/action';
import { showApiError } from '../../redux/modules/error/action';
import { validateEmail } from '../../utils';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';
import Tooltip from '../Common/Tooltip';
import SubscriptionTypes from './subscriptionTypes';
import ErrorService from '../../Services/ErrorService';
import { ForbiddenError } from '../../Services/ApiError';

class PersonalDataForm extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            isFetching: false,
            isSending: false,
            resetPasswordFormIsVisible: false,
            data: {}
        };
        this.prepareFormData = this.prepareFormData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.toggleResetPasswordForm = this.toggleResetPasswordForm.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentWillMount() {
        const { actions: { fetchUser } } = this.props;

        this.setState({ isFetching: true });

        fetchUser()
            .then((userData) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.prepareFormData(userData);
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    validate(values) {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = 'Bitte geben Sie einen Vornamen ein';
        }

        if (!values.lastName) {
            errors.lastName = 'Bitte geben Sie einen Nachnamen ein';
        }

        // E-Mail
        if (!values.email) {
            errors.email = 'Bitte geben Sie eine E-Mail Adresse ein';
        } else if (!validateEmail(values.email)) {
            errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein';
        }


        if (this.state.resetPasswordFormIsVisible) {
            if (!values.oldPassword) {
                errors.oldPassword = 'Bitte geben Sie Ihr altes Passwort ein';
            } else if (values.oldPassword.length < 6) {
                errors.oldPassword = 'Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein';
            }

            if (!values.newPassword) {
                errors.newPassword = 'Bitte geben Sie ein neues Passwort ein';
            } else if (values.newPassword.length < 6) {
                errors.newPassword = 'Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein';
            } else if (values.newPassword !== values.confirmNewPassword) {
                errors.confirmNewPassword = 'Ihre Passwörter stimmen nicht überein';
            }
        }

        return errors;
    }

    onSubmit(values) {
        const userData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email
        };

        if (this.state.resetPasswordFormIsVisible) {
            userData.password = values.newPassword;
            userData.oldPassword = values.oldPassword;
        }

        this.setState({ isSending: true });

        const { actions: { updateMyself }, history } = this.props;

        return updateMyself(userData)
            .then(() => {
                this.setState({
                    isSending: false
                });
                toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });

                if (this.props.redirect) {
                    history.push(this.props.redirect);
                } else {
                    history.push('/account/profile');
                }
            })

            .catch((error) => {
                this.setState({
                    isSending: false
                });

                if (error instanceof ForbiddenError) {
                    return ErrorService.handleUnauthorizedError(error, 'updatePersonalData')
                        .then(data => ({ [FORM_ERROR]: data.error }));
                }
                return showApiError(error);
            });
    }

    toggleResetPasswordForm(toggle) {

        this.setState({
            resetPasswordFormIsVisible: toggle
        });
    }

    prepareFormData(data) {
        if (!data) {
            this.setState({
                data: null,
                isFetching: false
            });
            return;
        }

        const { user } = data;

        const postLoadData = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            type: user.type
        };

        this.setState({
            data: postLoadData,
            isFetching: false
        });
    }

    renderButtonText() {
        const { isSending } = this.state;
        return isSending ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
    }

    resetPasswordFields([fields], state, utils) {
        fields.forEach((field) => {
            utils.changeValue(state, field, () => '');
        });
    }

    render() {
        const {
            data,
            isFetching,
            isSending,
            resetPasswordFormIsVisible
        } = this.state;

        if (!isFetching && !data) {
            return <ResourceNotFoundErrorPage />;
        }

        if (isFetching) {
            return <PageLoadingSpinner />;
        }

        return (
            <div className="updatePersonalDataForm">
                <Form
                    onSubmit={this.onSubmit}
                    mutators={{
                        resetPasswordFields: this.resetPasswordFields
                    }}
                    validate={this.validate}
                    initialValues={data}
                    render={({ handleSubmit, mutators, submitError }) => (
                        <form onSubmit={handleSubmit}>
                            <Section
                                title="Persönliche Daten bearbeiten"
                            >
                                {submitError && <div className="form-error">{submitError}</div>}
                                <InputGroup
                                    label="Vorname*"
                                >
                                    <Input
                                        type="text"
                                        name="firstName"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Nachname*"
                                >
                                    <Input
                                        type="text"
                                        name="lastName"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Email*"
                                >
                                    <Input
                                        type="text"
                                        name="email"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Kontotyp"
                                >
                                    {SubscriptionTypes[data.type]}

                                    <div className="account-type-tooltip">
                                        <Tooltip id="account-type">
                                            Der Kontotyp des eigenen Nutzerkontos kann nicht
                                            angepasst werden. Um den Kontotyp Ihres Nutzerkontos zu
                                            ändern, wenden Sie sich bitte an den Eigentümer dieses
                                            Unternehmenskontos.
                                        </Tooltip>
                                    </div>
                                </InputGroup>

                                <InputGroup
                                    label="Passwort"
                                >
                                    {resetPasswordFormIsVisible ?
                                        <div className="reset-password-block">
                                            <span
                                                className="btn--link-neutral"
                                                onClick={() => {
                                                    mutators.resetPasswordFields(['oldPassword','newPassword','confirmNewPassword']);
                                                    this.toggleResetPasswordForm(false);
                                                }}
                                            >
                                                Abbrechen
                                            </span>
                                            <div>
                                                <Input
                                                    type="password"
                                                    name="oldPassword"
                                                    placeholder="Altes Passwort eingeben"
                                                />
                                            </div>
                                            <div>
                                                <Input
                                                    type="password"
                                                    name="newPassword"
                                                    placeholder="Neues Passwort eingeben"
                                                />
                                            </div>
                                            <div>
                                                <Input
                                                    type="password"
                                                    name="confirmNewPassword"
                                                    placeholder="Neues Passwort wiederholen"
                                                />
                                            </div>
                                        </div>
                                        :
                                        <span
                                            className="btn--link-neutral"
                                            onClick={() => this.toggleResetPasswordForm(true)}
                                        >
                                            Passwort ändern
                                        </span>
                                    }
                                </InputGroup>
                            </Section>

                            <div className="btn-group">
                                <a
                                    onClick={this.props.history.goBack}
                                    className="btn btn--backward-action"
                                >
                                    Abbrechen
                                </a>

                                <span className="btn btn--dummy">*Pflichtfeld</span>
                                <button
                                    type="submit"
                                    className="btn btn--forward-action btn--save"
                                    disabled={isSending}
                                >
                                    {this.renderButtonText()}
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateMyself,
        fetchUser: fetchAndUpdateUser
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(PersonalDataForm);
