import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Modal from '../../Common/Modal/Modal';

export const AppChangeLogModal = ({ showIntro, onClickClose, children }) => (
    <Modal
        title={
            <Fragment>
                Ein neues Update wurde installiert!
                <a
                    className="btn changelog-modal__close"
                    onClick={onClickClose}
                    title="Schließen"
                >
                    &times;
                </a>
            </Fragment>
        }
        id="changeLogModal"
        classes="changelog-modal"
        autoHeight
    >
        {showIntro &&
        <div className="changelog-intro">
            <h3>Ihnen stehen neue Funktionen und Verbesserungen zur Verfügung</h3>
            <p>
                Wir arbeiten ständig daran, Scaffeye® für Sie zu verbessern und zu
                erweitern. Hier sind die neuesten Updates, die Ihnen ab sofort zur Verfügung
                stehen.
            </p>
        </div>
        }

        {children}
    </Modal>
);

AppChangeLogModal.propTypes = {
    showIntro: PropTypes.bool
};

AppChangeLogModal.defaultProps = {
    showIntro: true
}
