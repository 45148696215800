import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModelType } from '../../../../properties';
import CollapseToggleLink from '../../../Common/Collapse/CollapseToggleLink';
import LinkButton from '../../../Common/LinkButton';
import FormHint from '../../../Forms/FormHint';
import { getModelTypeLabel } from '../../../Forms/Media/utils';
import { ConstructionPlanModelModePropType } from '../../Form/propTypes';
import { ConstructionPlanModelMode } from '../../properties';
import {
    transformLiveScaffoldingToFormValues,
    transformScaffoldingPlanToFormValues
} from '../Scaffolding/functions';
import { transformLiveSiteToFormValues, transformSitePlanToFormValues } from '../Site/functions';
import { SyncModelFormStatePropType } from './propTypes';
import SyncAllButton from './SyncAllButton';

class SyncModelSection extends Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf([ModelType.Site, ModelType.Scaffolding, ModelType.Section]).isRequired,
        formValues: SyncModelFormStatePropType.isRequired,
        planData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        liveData: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        onSelectAllPlan: PropTypes.func.isRequired,
        onSelectAllLive: PropTypes.func.isRequired,
        contentBeforeActions: PropTypes.node,
        hasIdenticalOptions: PropTypes.bool,
        mode: ConstructionPlanModelModePropType,
        allValuesIdentical: PropTypes.bool,
        resolvedMedia: PropTypes.object
    };

    static defaultProps = {
        contentBeforeActions: undefined,
        hasIdenticalOptions: false,
        allValuesIdentical: false
    };

    constructor(props) {
        super(props);

        this.toggleIgnore = this.toggleIgnore.bind(this);
        this.toggleShowIdentical = this.toggleShowIdentical.bind(this);

        this.selectAllLive = this.selectAllLive.bind(this);
        this.selectAllPlan = this.selectAllPlan.bind(this);
    }

    toggleIgnore() {
        const { formValues, onChange } = this.props;

        const newFormState = {
            ...formValues,
            ignored: !formValues.ignored
        };

        onChange(newFormState);
    }

    toggleShowIdentical() {
        const { formValues, onChange } = this.props;

        const newFormState = {
            ...formValues,
            showIdentical: !formValues.showIdentical
        };

        onChange(newFormState);
    }

    selectAllLive() {
        this.props.onSelectAllLive();
    }

    selectAllPlan() {
        this.props.onSelectAllPlan();
    }

    getToggleIgnoreLabel() {
        const { formValues: { ignored }, type } = this.props;

        const modelName = this.getIgnoredModelTypeLabel(type);

        if (ignored) {
            return `${modelName} berücksichtigen`;
        }

        return `${modelName} ignorieren`;
    }

    getIgnoredModelTypeLabel = (modelType) => {
        switch (modelType) {
            case ModelType.Site:
                return 'Baustellenänderungen';
            case ModelType.Scaffolding:
                return 'Gerüständerungen';
            case ModelType.Section:
                return 'Gerüstabschnittänderungen';
            default:
                return `n.a. (${modelType})`;
        }
    };

    getIgnoredLabel() {

        const { type } = this.props;
        const typeLabel = this.getIgnoredModelTypeLabel(type);

        return `Die ${typeLabel} werden beim Anwenden Ihrer Planung nicht berücksichtigt.`;
    }

    getCleanedValue(_data, type) {

        const data = _.cloneDeep(_data);

        Object.keys(data)
            .forEach(property => {
                const value = data[property];
                if (_.isObject(value)) {
                    if (_.isEmpty(value)) {
                        delete data[property];
                    } else {
                        data[property] = this.getCleanedValue(value);
                    }
                } else if (value !== false && !value) {
                    delete data[property];
                }
            });

        if(type === ModelType.Section) {
            delete data.status;
            if(data.contractorId) {
                delete data.contractorId;
            }
        }

        return data;
    }

    hasAllPlanValuesSelected() {
        const { type, planData, liveData, resolvedMedia, formValues: { data } } = this.props;
        let planDataFormValues;

        switch (type) {
            case ModelType.Site:
                planDataFormValues = transformSitePlanToFormValues(planData, liveData, resolvedMedia);
                break;
            case ModelType.Scaffolding:
                planDataFormValues = transformScaffoldingPlanToFormValues(planData, liveData, resolvedMedia);
                break;
            case ModelType.Section:
            default:
                planDataFormValues = { ...planData };
                break;
        }

        planDataFormValues.id = data.id;

        const cleanedPlanFormValues = this.getCleanedValue(planDataFormValues, type);
        const cleanedFormValues = this.getCleanedValue(data, type);
        return _.isEqual(cleanedPlanFormValues, cleanedFormValues);
    }

    hasAllLiveValuesSelected() {
        const { type, planData, liveData, resolvedMedia, formValues: { data } } = this.props;
        let liveDataFormValues;

        if (liveData) {
            switch (type) {
                case ModelType.Site:
                    liveDataFormValues = transformLiveSiteToFormValues(liveData, planData, resolvedMedia);
                    break;
                case ModelType.Scaffolding:
                    liveDataFormValues = transformLiveScaffoldingToFormValues(liveData, planData, resolvedMedia);
                    break;
                case ModelType.Section:
                default:
                    liveDataFormValues = { ...liveData };
                    break;
            }
        }

        const cleanedLiveDataValues = this.getCleanedValue(liveDataFormValues);
        const cleanedFormValues = this.getCleanedValue(data);

        return _.isEqual(cleanedLiveDataValues, cleanedFormValues);
    }

    renderBody() {
        const {
            formValues: { ignored, showIdentical },
            liveData,
            contentBeforeActions,
            hasIdenticalOptions,
            children,
            mode,
            type,
            allValuesIdentical
        } = this.props;

        if (allValuesIdentical) {
            return (
                <div className="model-sync-section__ignored">
                    Keine Konfigurationsänderungen durchgeführt
                </div>
            );
        }

        if (ignored) {
            return (
                <div className="model-sync-section__ignored">
                    <FormHint initial>
                        {this.getIgnoredLabel()}
                    </FormHint>
                </div>
            );
        }

        return (
            <div className="model-sync-section__bd">

                {/* Content before actions (optional; i.e. status dropdown for section) */}
                {contentBeforeActions &&
                <div className="model-sync-section__bbd">
                    {contentBeforeActions}
                </div>
                }

                {/* Bulk actions */}
                {mode !== ConstructionPlanModelMode.Reference &&
                <div className="model-sync-section__bulk-actions">
                    <div className="model-sync-section__sync-all-plan">
                        <SyncAllButton
                            onClick={this.selectAllPlan}
                            active={this.hasAllPlanValuesSelected()}
                        />
                    </div>

                    {
                        liveData && <div className="model-sync-section__sync-all-live">
                            <SyncAllButton
                                onClick={this.selectAllLive}
                                active={this.hasAllLiveValuesSelected()}
                            />
                        </div>
                    }

                </div>
                }

                {/* Show identical options toggle */}
                {!(type === ModelType.Section && mode === ConstructionPlanModelMode.Reference) && hasIdenticalOptions &&
                <div className="model-sync-section__toggle-identical">
                    <CollapseToggleLink
                        isCollapsed={!showIdentical}
                        collapseLabel="Identische Konfigurationen ausblenden"
                        expandLabel="Identische Konfigurationen einblenden"
                        onToggle={this.toggleShowIdentical}
                        block
                    />
                </div>
                }

                {/* Input fields */}
                {children}
            </div>
        );
    }

    render() {
        const {
            name,
            type,
            mode,
            allValuesIdentical
        } = this.props;

        return (
            <section className="model-sync-section">
                <header className="model-sync-section__hd">
                    <h2 className="model-sync-section__ttl">
                        <span className="model-sync-section__model-type">
                            {getModelTypeLabel(type)}
                        </span>
                        {' '}
                        <span className="model-sync-section__model-name">
                            {name}
                        </span>
                    </h2>
                    {(!allValuesIdentical && ((type !== ModelType.Site && mode !== ConstructionPlanModelMode.Reference)
                        || (type === ModelType.Site && mode === ConstructionPlanModelMode.Edit))) &&
                    <div className="model-sync-section__sync-toggle">
                        <LinkButton
                            onClick={this.toggleIgnore}
                            label={this.getToggleIgnoreLabel()}
                        />
                    </div>
                    }
                </header>

                {this.renderBody()}
            </section>
        );
    }
}

export default SyncModelSection;
