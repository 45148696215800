import React from 'react';
import { Field } from 'react-final-form';
import FieldError from './FieldError';

const Input = ({ type, disabled, label, placeholder, readOnly, ...fieldProps }) => (
    <Field
        {...fieldProps}
    >
        { ({ input, meta }) => {

            const inputCssClasses = ['input-grp__field'];

            if (meta.error) {
                inputCssClasses.push('is-invalid');
            } else {
                inputCssClasses.push('is-valid');
            }
            if (meta.touched) {
                inputCssClasses.push('is-dirty');
            } else {
                inputCssClasses.push('is-pristine');
            }

            return (
                <React.Fragment>
                    {label && <div>{label}</div>}
                    <input
                        {...input}
                        type={type}
                        disabled={disabled}
                        readOnly={readOnly}
                        placeholder={placeholder}
                        className={inputCssClasses.join(' ')}
                    />
                    <FieldError meta={meta} />
                </React.Fragment>
            );

        }}
    </Field>
);


export default Input;
