import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { getSectionPropertyLabel } from '../../../Common/Diff/labels';
import { SectionDiffProperty } from '../../../Common/Diff/properties';
import {
    getPlatformStairwayPurpose,
    getProtectivePurposes,
    getWorkPurpose
} from '../../../Sections/functions';
import {
    showCladdings,
    showConstructionType,
    showEquippedSurfaces,
    showLoadClass,
    showPlannedWorks,
    showSpecialFeatures,
    showSupportType,
    showWallClearance,
    showWidthClass
} from '../../../Sections/helpers';
import {
    getProtectivePurposesLabel,
    getPurposeGroupsLabel,
    getPurposeLabel,
    getStatusLabel
} from '../../../Sections/labels';
import { ConstructionPlanModelMode } from '../../properties';
import { isIdenticalSectionFormValue } from '../Section/equals';
import ConstructionPlanSyncModelSummary from './ConstructionPlanSyncModelSummary';
import ConstructionPlanSyncSummaryField from './ConstructionPlanSyncSummaryField';
import ConstructionPlanSyncSummaryRow from './ConstructionPlanSyncSummaryRow';
import {
    getLiveValue,
    getSyncValue,
    renderMediaList,
    renderMultilineText,
    renderSingleMedia,
    renderValue
} from './functions';

const SyncSummaryProperties = [
    'name',
    'status',
    'customer',

    'equippedSurfaces',
    'purpose',
    'plannedWorks',

    'supportType',
    'constructionType',
    'stabilityAndLoadSafety',

    'loadClass',
    'wallClearance',
    'widthClass',
    'claddings',
    'specialFeatures',

    'attachments',
    'anchorProtocol',
    'utilizationPlans',
    'proofOfStability',

    'description'
];

const MediaListProperties = [
    'attachments',
    'utilizationPlans'
];

const ConstructionPlanSyncSectionSummary = ({ syncModel, liveData, resolvedMedia }) => {

    const isNew = syncModel.mode === ConstructionPlanModelMode.New;
    const isReference = syncModel.mode === ConstructionPlanModelMode.Reference;
    const isIgnored = syncModel.ignored;

    let renderedPurpose = false;

    const renderSectionValue = (property, value) => {
        switch (property) {
            case 'attachments':
            case 'utilizationPlans':
                // handled elsewhere
                return null;
            case 'anchorProtocol':
            case 'proofOfStability':
                return renderSingleMedia(value, resolvedMedia);
            case 'description':
                return renderMultilineText(value);
            case 'status':
                return getStatusLabel(value, '-');
            case 'equippedSurfaces':
                return showEquippedSurfaces(value, '-');
            case 'claddings':
                return showCladdings(value, '-');
            case 'specialFeatures':
                return showSpecialFeatures(value, '-');
            case 'wallClearance':
                return showWallClearance(value, '-');
            case 'loadClass':
                return showLoadClass(value, '-');
            case 'widthClass':
                return showWidthClass(value, '-');
            case 'plannedWorks':
                return showPlannedWorks(value, '-');
            case 'supportType':
                return showSupportType(value, '-');
            case 'constructionType':
                return showConstructionType(value, '-');
            default:
                return renderValue(value);
        }
    };

    function renderPurposeGroups(_syncPurposes, _livePurposes) {

        const syncPurposes = _syncPurposes || [];
        const livePurposes = _livePurposes || [];

        const left = getPurposeGroupsLabel(livePurposes, '-');
        const right = getPurposeGroupsLabel(syncPurposes, '-');
        const isIdentical = _.isEqual(left, right);

        return (
            <ConstructionPlanSyncSummaryField
                label="Verwendungszweck"
                isNew={isNew}
                isIdentical={isIdentical}
                key="purposeGroup"
            >
                <ConstructionPlanSyncSummaryRow
                    left={left}
                    right={right}
                    isIdentical={isIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        );
    }

    function renderPurposeGroup (label, isIdentical, left, right) {
        return (
            <ConstructionPlanSyncSummaryField
                key={label}
                label={label}
                isNew={isNew}
                isIdentical={isIdentical}
                isSub
            >
                <ConstructionPlanSyncSummaryRow
                    left={left}
                    right={right}
                    isIdentical={isIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        );
    }

    function renderWorkPurposeGroup(syncPurposes, livePurposes) {
        return renderSinglePurposePurposeGroup("Arbeitsgerüst", getWorkPurpose, syncPurposes, livePurposes);
    }

    function renderProtectivePurposeGroup(syncPurposes, syncOtherProtectivePurpose, livePurposes, liveOtherProtectivePurpose) {
        const syncProtectivePurposes = getProtectivePurposes(syncPurposes);
        const liveProtectivePurposes = getProtectivePurposes(livePurposes);

        const isIdentical =
            _.isEqual(syncProtectivePurposes, liveProtectivePurposes)
            && _.isEqual(syncOtherProtectivePurpose, liveOtherProtectivePurpose);

        return renderPurposeGroup(
            "Schutzgerüst",
            isIdentical,
            getProtectivePurposesLabel(liveProtectivePurposes, liveOtherProtectivePurpose, '-'),
            getProtectivePurposesLabel(syncProtectivePurposes, syncOtherProtectivePurpose, '-'),
        );
    }

    function renderPlatformStairwayPurposeGroup(syncPurposes, livePurposes) {
        return renderSinglePurposePurposeGroup("Podesttreppe", getPlatformStairwayPurpose, syncPurposes, livePurposes);
    }

    function renderSinglePurposePurposeGroup(label, getPurposeFn, syncPurposes, livePurposes) {
        const syncPurpose = getPurposeFn(syncPurposes);
        const livePurpose = getPurposeFn(livePurposes);

        return renderPurposeGroup(
            label,
            _.isEqual(syncPurpose, livePurpose),
            getPurposeLabel(livePurpose, '-'),
            getPurposeLabel(syncPurpose, '-')
        );
    }

    const renderPurpose = () => {

        // renderPurpose might be called multiple times as the purposeGroup, purposes or
        // otherProtectivePurpose might have changed
        if(renderedPurpose) {
            // already rendered
            return;
        }
        renderedPurpose = true;

        const syncPurposes = getSyncValue('purposes', syncModel, []);
        const syncOtherProtectivePurpose = getSyncValue('otherProtectivePurpose', syncModel, null);

        const livePurposes = getLiveValue('purposes', liveData, []);
        const liveOtherProtectivePurpose = getLiveValue('otherProtectivePurpose', liveData, null);

        const renderGroups = [];

        renderGroups.push(renderPurposeGroups(syncPurposes, livePurposes));
        renderGroups.push(renderWorkPurposeGroup(syncPurposes, livePurposes));
        renderGroups.push(renderProtectivePurposeGroup(syncPurposes, syncOtherProtectivePurpose, livePurposes, liveOtherProtectivePurpose));
        renderGroups.push(renderPlatformStairwayPurposeGroup(syncPurposes, livePurposes));

        return renderGroups.filter(x => x);
    };

    const renderStabilityAndLoadSafety = () => {

        // Special construction
        const syncSpecialConstruction = getSyncValue('specialConstruction', syncModel, false);
        const liveSpecialConstruction = getLiveValue('specialConstruction', liveData, false);
        const isSCIdentical = syncSpecialConstruction === liveSpecialConstruction;
        const specialConstructionLabel = 'Sonderkonstruktion';
        const rightSC = syncSpecialConstruction ? specialConstructionLabel : '-';
        const leftSC = liveSpecialConstruction ? specialConstructionLabel : '-';

        // Statics certificate necessary
        const syncStaticsCertificateNecessary = getSyncValue('staticsCertificateNecessary', syncModel, false);
        const liveStaticsCertificateNecessary = getLiveValue('staticsCertificateNecessary', liveData, false);
        const isSCNIdentical = syncStaticsCertificateNecessary === liveStaticsCertificateNecessary;
        const staticsCertificateNecessaryLabel = 'Statiknachweis erforderlich';
        const rightSCN = syncStaticsCertificateNecessary ? staticsCertificateNecessaryLabel : '-';
        const leftSCN = liveStaticsCertificateNecessary ? staticsCertificateNecessaryLabel : '-';

        return (
            <ConstructionPlanSyncSummaryField
                label={getSectionPropertyLabel('stabilityAndLoadSafety')}
                isNew={isNew}
                isIdentical={isSCIdentical && isSCNIdentical}
                key="stabilityAndLoadSafety"
            >
                <ConstructionPlanSyncSummaryRow
                    left={leftSC}
                    right={rightSC}
                    isIdentical={isSCIdentical}
                />
                <ConstructionPlanSyncSummaryRow
                    left={leftSCN}
                    right={rightSCN}
                    isIdentical={isSCNIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        );
    }

    const renderProperty = (property) => {
        if (property === SectionDiffProperty.Purpose) {
            return renderPurpose();
        }

        if(property === SectionDiffProperty.StabilityAndLoadSafety) {
            return renderStabilityAndLoadSafety();
        }

        if (MediaListProperties.indexOf(property) >= 0) {
            return renderMediaList(property, syncModel, liveData, resolvedMedia);
        }

        const value = getSyncValue(property, syncModel);
        const liveValue = getLiveValue(property, liveData);
        const isIdentical = isIdenticalSectionFormValue(property, syncModel.data, liveData);

        const left = renderSectionValue(property, liveValue);
        const right = renderSectionValue(property, value);

        return (
            <ConstructionPlanSyncSummaryField
                key={property}
                label={getSectionPropertyLabel(property)}
                isIdentical={isIdentical}
                isNew={isNew}
            >
                <ConstructionPlanSyncSummaryRow
                    left={left}
                    right={right}
                    isIdentical={isIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        );
    };

    const renderProperties = () => {
        if (isReference) {
            return 'Keine Konfigurationsänderungen';
        }

        if (isIgnored) {
            return (
                <span className="sync-summary__ressource-is-ignored-hint">
                    Wird nicht berücksichtigt
                </span>
            );
        }

        return SyncSummaryProperties.map(renderProperty);
    };

    const name = syncModel?.data?.name || liveData?.name;

    return (
        <ConstructionPlanSyncModelSummary
            title={`Gerüstabschnitt: ${name}`}
            isNew={isNew}
        >
            {renderProperties()}
        </ConstructionPlanSyncModelSummary>
    );
};

ConstructionPlanSyncSectionSummary.propTypes = {
    syncModel: PropTypes.object.isRequired,
    liveData: PropTypes.object
};

export default ConstructionPlanSyncSectionSummary;
