import _ from 'lodash';
import { ModelType } from '../../../properties';
import { getInitialConstructionFormSelection } from '../../ConstructionPlan/Form/utils';
import { ConstructionPlanModelMode } from '../../ConstructionPlan/properties';
import { toMediaFormData, toMediaPayload } from '../../Forms/Media/utils';
import { ModelMediaType } from '../../Media/properties';
import { determinePurposeGroups } from '../functions';
import {
    Bracket,
    Cladding,
    EmergencyStairwayPurposes,
    EquippedSurface,
    LoadClass,
    PlannedWork,
    PlatformStairwayPurposes,
    ProtectivePurposes,
    Purpose,
    PurposeGroup,
    SectionStatus,
    WallClearance,
    WidthClass,
    WorkPurposes
} from '../properties';
import { getValidPurposesForGroup } from './validate';

function convertArrayToObject(arr) {
    if (arr) {
        return arr.reduce((result, item) => {
            result[item] = true;
            return result;
        }, {});
    }
    return {};
}

export function getFormValues(section, constructionPlanMode) {
    if (!section) {
        return null;
    }

    const formData = {};

    if (!constructionPlanMode) {
        const scaffolding = section.scaffolding || {};
        const site = scaffolding.site || {};

        formData.scaffoldingId = scaffolding.id;
        formData.siteId = site.id;

        // Form status
        formData.status = section.status;
        formData.managed = section.managed;

        if (!section.managed) {
            formData.contractorId = section?.contractor?.id;
        }
    } else {
        formData.id = section.id;
        formData.mode = constructionPlanMode;
        formData.selection = getInitialConstructionFormSelection(constructionPlanMode, section.id);
    }

    formData.name = section.name;
    formData.customer = section.customer;
    formData.approvedByName = section.approvedByName;

    /*
     * Populate purpose object
     */
    const { purposes } = section;
    let workPurpose = undefined;
    let platformStairwayPurpose = undefined;
    let emergencyStairwayPurpose = undefined;
    const protectivePurposes = [];
    if (purposes) {
        purposes.forEach(purpose => {
            if (WorkPurposes.indexOf(purpose) >= 0) {
                workPurpose = purpose;
            } else if (ProtectivePurposes.indexOf(purpose) >= 0) {
                protectivePurposes.push(purpose);
            } else if (Purpose.LadderAscent === purpose) {
                // ignore
            } else if (PlatformStairwayPurposes.indexOf(purpose) >= 0) {
                platformStairwayPurpose = purpose;
            } else if (Purpose.SiteStaircase === purpose) {
                // ignore
            } else if (EmergencyStairwayPurposes.indexOf(purpose) >= 0) {
                emergencyStairwayPurpose = purpose;
            } else {
                console.error(`Encountered unknown purpose '${purpose}'. Ignoring value in form`)
            }
        })
    }

    formData.purpose = {
        groups: determinePurposeGroups(purposes),
        work: workPurpose,
        protective: protectivePurposes,
        otherProtective: section.otherProtectivePurpose,
        platformStairway: platformStairwayPurpose,
        emergencyStairway: emergencyStairwayPurpose
    };

    /*
     * Equipped surfaces
     */
    formData.equippedSurfaces = null;
    if (section.equippedSurfaces) {
        formData.equippedSurfaces = Object.keys(section.equippedSurfaces);
        if (_.includes(Object.keys(section.equippedSurfaces), EquippedSurface.Other)) {
            formData.equippedSurfacesOther = section.equippedSurfaces.other;
        }
    }

    formData.plannedWorks = null;
    if (section.plannedWorks) {
        formData.plannedWorks = Object.keys(section.plannedWorks);
        if (_.includes(Object.keys(section.plannedWorks), PlannedWork.Other)) {
            formData.plannedWorksOther = section.plannedWorks.other;
        }
    }

    formData.supportType = section.supportType;
    formData.specialConstruction = section.specialConstruction;
    formData.staticsCertificateNecessary = section.staticsCertificateNecessary;
    formData.constructionType = section.constructionType;
    formData.loadClass = null;
    if (section.loadClass) {
        const loadClassKeys = Object.keys(section.loadClass);
        if (loadClassKeys.length > 0) {
            formData.loadClass = loadClassKeys[0];
        }

        if (_.includes(Object.keys(section.loadClass), LoadClass.Other)) {
            formData.loadClassOther = section.loadClass.other;
        }
    }

    formData.wallClearance = null;
    if (section.wallClearance) {
        const wallClearanceKeys = Object.keys(section.wallClearance);
        if (wallClearanceKeys.length > 0) {
            formData.wallClearance = wallClearanceKeys[0];
        }

        if (_.includes(Object.keys(section.wallClearance), WallClearance.Above300MM)) {
            formData.wallClearanceAbove300 = section.wallClearance.above_300_mm;
        }
    }

    formData.widthClass = null;
    if (section.widthClass) {
        const widthClassKeys = Object.keys(section.widthClass);
        if (widthClassKeys.length > 0) {
            formData.widthClass = widthClassKeys[0];
        }

        if (_.includes(Object.keys(section.widthClass), WidthClass.Other)) {
            formData.widthClassOther = section.widthClass.other;
        }
    }

    if (section.claddings) {
        formData.claddings = Object.keys(section.claddings);

        if (_.includes(Object.keys(section.claddings), Cladding.FixedTrim)) {
            formData.claddingsFixedTrim = section.claddings.fixed_trim;
        }
    }

    if (section.specialFeatures) {
        formData.specialFeatures = Object.keys(section.specialFeatures);
        // if specialFeatures array of fetched data contains bracket
        if (_.includes(Object.keys(section.specialFeatures), 'bracket')) {
            // then init form state w/ values of received bracket object
            formData.specialFeatureBracket = Object.keys(section.specialFeatures.bracket);
            // if values of received bracket object contains 'other' (custom text input)
            if (_.includes(Object.keys(section.specialFeatures.bracket), 'other')) {
                // then init form state w/ input of 'other'
                formData.specialFeatureBracketOther = section.specialFeatures.bracket.other;
            }
        }
    }

    // Description
    formData.description = section.description;

    // Media
    if (section.proofOfStability) {
        formData.proofOfStability = [toMediaFormData(section.proofOfStability, ModelType.Section, ModelMediaType.Section_ProofOfStability, constructionPlanMode)];
    } else {
        formData.proofOfStability = [];
    }

    if (section.attachments) {
        formData.attachments = section.attachments.map(media => toMediaFormData(media, ModelType.Section, ModelMediaType.Section_Attachment, constructionPlanMode));
    } else {
        formData.attachments = [];
    }

    if (section.utilizationPlans) {
        formData.utilizationPlans = section.utilizationPlans.map(media => toMediaFormData(media, ModelType.Section, ModelMediaType.Section_UtilizationPlan, constructionPlanMode));
    } else {
        formData.utilizationPlans = [];
    }

    if (section.anchorProtocol) {
        formData.anchorProtocol = [toMediaFormData(section.anchorProtocol, ModelType.Section, ModelMediaType.Section_AnchorProtocol, constructionPlanMode)];
    } else {
        formData.anchorProtocol = [];
    }

    /*
     * Meta data and references
     */
    formData.activeAcceptanceInspection = section.activeAcceptanceInspection;

    return formData;
}

export function getSubmitPayload(values, isPlan = false) {
    if (!values) {
        return null;
    }

    const {
        id,
        mode,
        name,
        status,
        approvedByName,
        scaffoldingId,
        attachments,
        utilizationPlans,
        anchorProtocol,
        proofOfStability,
        customer,
        contractorId
    } = values;

    if (isPlan && mode === ConstructionPlanModelMode.Reference) {
        return {
            id,
            mode
        };
    }

    const sectionData = {};

    if (!isPlan) {
        sectionData.scaffoldingId = scaffoldingId;

        sectionData.managed = true;
        sectionData.status = status;

        // managed
        if (!values.managed) {
            sectionData.managed = false;
            sectionData.status = 'unknown';
        }
    }

    // Contractor Id
    if (!values.managed) {
        sectionData.contractorId = contractorId;
    }

    // Media
    if (utilizationPlans && utilizationPlans.length) {
        sectionData.utilizationPlans = utilizationPlans.map(plan => toMediaPayload(plan, isPlan));
    }

    if (anchorProtocol && anchorProtocol.length) {
        sectionData.anchorProtocol = toMediaPayload(_.first(anchorProtocol), isPlan);
    }

    if (attachments && attachments.length) {
        sectionData.attachments = attachments.map(attachment => toMediaPayload(attachment, isPlan));
    }

    if (proofOfStability && proofOfStability.length) {
        sectionData.proofOfStability = toMediaPayload(_.first(proofOfStability), isPlan);
    }

    // Bezeichnung
    sectionData.name = name;

    // Equipped Surfaces
    if (values.equippedSurfaces) {
        sectionData.equippedSurfaces = values.equippedSurfaces.reduce((result, item) => {
            if (item !== 'other') {
                result[item] = true;
            }

            return result;
        }, {});
    }

    if (values.equippedSurfacesOther) {
        if (!sectionData.equippedSurfaces) {
            sectionData.equippedSurfaces = {};
        }
        sectionData.equippedSurfaces.other = values.equippedSurfacesOther;
    }

    // geplante Arbeiten
    if (values.plannedWorks) {
        sectionData.plannedWorks = convertArrayToObject(values.plannedWorks);
    } else {
        sectionData.plannedWorks = {};
    }

    // geplante Arbeiten Textfeld
    if (_.includes(values.plannedWorks, 'other')) {
        if (values.plannedWorksOther !== undefined) {
            sectionData.plannedWorks.other = values.plannedWorksOther;
        } else {
            sectionData.plannedWorks.other = '';
        }
    }

    /*
     * Purposes / Verwendungszweck
     */
    const { purpose } = values;
    if (purpose) {
        const {
            groups,
            work,
            protective,
            otherProtective,
            platformStairway,
            emergencyStairway
        } = purpose;

        // Purposes
        const purposes = [];
        if (work) {
            purposes.push(work);
        }
        if (protective) {
            purposes.push(...protective);
        }
        if (groups.indexOf(PurposeGroup.LadderAscent) >= 0) {
            purposes.push(Purpose.LadderAscent);
        }
        if (platformStairway) {
            purposes.push(platformStairway);
        }
        if (groups.indexOf(PurposeGroup.SiteStaircase) >= 0) {
            purposes.push(Purpose.SiteStaircase);
        }
        if (emergencyStairway) {
            purposes.push(emergencyStairway);
        }

        sectionData.purposes = getValidPurposesForGroup(groups, purposes);

        // Other protective purpose
        if (_.includes(sectionData.purposes, Purpose.OtherProtectiveScaffolding)) {
            sectionData.otherProtectivePurpose = otherProtective ? otherProtective.trim() : '';
        }
    }

    // Gerüstverkleidungen
    if (values.claddings) {
        const claddings = convertArrayToObject(values.claddings);

        if (_.includes(values.claddings, 'fixed_trim')) {
            if (values.status === SectionStatus.Draft) {
                claddings.fixed_trim = values.claddingsFixedTrim || '';
            } else if (values.claddingsFixedTrim !== undefined) {
                claddings.fixed_trim = values.claddingsFixedTrim;
            }
        }

        sectionData.claddings = claddings;
    }

    // Tragsystem
    if (values.supportType) {
        sectionData.supportType = values.supportType;
    }

    // Sonderkonstruktion
    sectionData.specialConstruction = !!values.specialConstruction;
    sectionData.staticsCertificateNecessary = !!values.staticsCertificateNecessary;

    // Ausführungsart
    if (values.constructionType) {
        sectionData.constructionType = values.constructionType;
    }

    // Lastklasse
    if (values.loadClass) {
        sectionData.loadClass = {
            [values.loadClass]: (values.loadClass === LoadClass.Other) ? values.loadClassOther || '' : true
        };
    } else {
        sectionData.loadClass = {};
    }

    // Wandabstand
    if (values.wallClearance) {
        sectionData.wallClearance = {
            [values.wallClearance]: (values.wallClearance === WallClearance.Above300MM) ? values.wallClearanceAbove300 || '' : true
        };
    } else {
        sectionData.wallClearance = {};
    }

    // Breitenklasse
    if (values.widthClass) {
        sectionData.widthClass = {
            [values.widthClass]: (values.widthClass === WidthClass.Other) ? values.widthClassOther || '' : true
        };
    } else {
        sectionData.widthClass = {};
    }

    // Special Features
    if (values.specialFeatures) {
        const specialFeatures = convertArrayToObject(values.specialFeatures);

        if (_.includes(values.specialFeatures, 'bracket')) {
            specialFeatures.bracket = {};
            if (values.specialFeatureBracket) {
                values.specialFeatureBracket.forEach(bracketKey => {
                    if (bracketKey !== Bracket.Other) {
                        specialFeatures.bracket[bracketKey] = true;
                    } else {
                        specialFeatures.bracket.other = values.specialFeatureBracketOther || '';
                    }
                });
            }
        }

        if (_.includes(values.purpose.protective, Purpose.RoofSafetyCatchScaffolding)) {
            specialFeatures.fall_protection = true;
        }

        sectionData.specialFeatures = specialFeatures;
    }

    // Notes
    if (values.description) {
        sectionData.description = values.description;
    }

    // Freigegeben durch ("Befähigte Person") nur bei Fremdgerüsten
    if (!values.managed && approvedByName) {
        sectionData.supervisor = approvedByName;
    }

    // Customer/Auftraggeber
    if (customer) {
        sectionData.customer = customer;
    }

    // Return payload
    if (isPlan) {
        return {
            id: values.id,
            mode: values.mode,
            data: sectionData
        };
    } else {
        return sectionData;
    }
}

export function isDraft(values) {
    if (values.managed === false) {
        return false;
    }

    return !values?.status || values?.status === SectionStatus.Draft;
}

export function isUnmanaged(values) {
    return values?.managed === false;
}

export function hasNoPurposeGroup(values) {
    return _.isEmpty(values?.purpose?.groups);
}

export function isLadderAscent(values, only = false) {
    return isPurposeGroup(values, PurposeGroup.LadderAscent, only);
}

export function isEmergencyStairway(values, only = false) {
    return isPurposeGroup(values, PurposeGroup.EmergencyStairway, only);
}

export function isSiteStaircase(values, only = false) {
    return isPurposeGroup(values, PurposeGroup.SiteStaircase, only);
}

export function isPlatformStairway(values) {
    return isPurposeGroup(values, PurposeGroup.PlatformStairway);
}

export function isPurposeGroup(values, group, only = false) {
    if (!values || !group) {
        return false;
    }

    const groups = values?.purpose?.groups || [];
    if (only && groups.length > 1) {
        return false;
    }

    return groups.indexOf(group) >= 0;
}

export function hasOnlyPurposeGroups(values, groups) {
    return _.difference(values || [], groups || []).length === 0;
}
