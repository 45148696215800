import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { autocompleteRequest } from '../../../redux/modules/job/actions';
import AutocompleteSelect from './AutocompleteSelect';

const RequestAutocomplete = (props) => {
    const { onChange, initialValue, actions: { autocomplete }, archivedOnly } = props;

    const initialOption = initialValue ? {
        label: initialValue,
        value: initialValue
    } : null;

    return (
        <AutocompleteSelect
            getValues={(input) => autocomplete(input, archivedOnly)}
            onSelectionChange={onChange}
            label="Name"
            loadingMessage={() => 'Lade Anfragen ...'}
            placeholder="Anfrage wählen"
            initialOption={initialOption}
        />
    );
};

RequestAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string
};

RequestAutocomplete.defaultProps = {
    initialValue: null
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocomplete: autocompleteRequest
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(RequestAutocomplete);
