import React from 'react';
import JobsOverview from '../../../../Components/Job/Overview/JobsOverview';
import TopNav from '../../../../Components/Page/TopNav';
import Permission from '../../../../permissions';
import Container from '../../../Container';
import PrivatePage from '../PrivatePage';

const RequestsPage = ({ location, history }) => {

    if (location.search && (!location.state || !location.state.requestQueryString)) {

        history.replace({
            ...location,
            state: {
                ...location.state,
                requestQueryString: location.search
            }
        });
    }

    return (
        <PrivatePage requiredUserPermission={Permission.ReadJobs}>
            <Container>
                <TopNav />
                <JobsOverview showRequests />
            </Container>
        </PrivatePage>
    );

}

export default RequestsPage;
