import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../Common/Icon';
import Button from '../../../Forms/Button';

const SyncAllButton = ({ onClick, active }) => (
    <Button
        className={`btn btn--sync-all ${active ? 'btn--sync-all-active' : ''}`}
        onClick={onClick}
        disableSubmit
    >
        {active &&
        <span className="btn__icon">
            <Icon name="correct" />
        </span>
        }
        {active ? 'Alle ausgewählt' : 'Alle Auswählen'}
    </Button>
);

SyncAllButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool
};

export default SyncAllButton;
