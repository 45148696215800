import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError, showTempError } from '../../redux/modules/error/action';
import {
    archiveJob,
    fetchJobArchivationData,
    reactivateJob
} from '../../redux/modules/job/actions';
import GraphQLError, { GraphQlErrorCodes } from '../../Services/GraphQLError';
import styles from '../Forms/Select/styles';
import JobStatusOptions from './Form/jobStatusOptions';
import RequestStatusOptions from './Form/requestStatusOptions';
import { JobType } from './properties';
import LoadingSpinner from '../Page/LoadingSpinner';
import Modal from '../Common/Modal/Modal';

export const ArchiveActions = {
    Archived: 'archived',
    Reactivated: 'reactivated'
};

const getInitialState = (props) => ({
    isProcessing: false,
    isInitializing: false,
    isArchived: props.isArchived
});

class ArchiveJobConfirmationModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = getInitialState(props);
        this.handleConfirmationClick = this.handleConfirmationClick.bind(this);
    }

    componentWillMount() {
        this.fetchSiteActivationState();
    }

    fetchSiteActivationState() {
        const { id: jobId, actions: { fetchJobArchivationData } } = this.props;

        this.setState({
            isInitializing: true
        });

        fetchJobArchivationData(jobId)
            .then(job => {

                if (this.loadingGotCancelled) {
                    return;
                }

                const { archived: isArchived } = job;

                this.setState({
                    job,
                    type: job.type,
                    isArchived,
                    isInitializing: false
                });
            })
            .catch(error => {
                showApiError(error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isInitializing: false
                });
            });
    }


    setIsProcessing(isProcessing) {
        this.setState({
            isProcessing
        });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onTargetStatusChange(targetStatusSelection) {

        if (!targetStatusSelection) {
            return;
        }

        const { value: selectedTargetStatus } = targetStatusSelection;

        this.setState({
            selectedTargetStatus,
            statusSelectionError: ''
        });
    }

    getStatusOptions() {
        const { type } = this.state;

        if (type === JobType.Job) {
            return JobStatusOptions;
        }

        return RequestStatusOptions;

    }

    renderModalContent() {

        const { onClose } = this.props;

        const { isArchived, job: { name: jobName }, type, statusSelectionError, isProcessing } = this.state;

        const jobTypeLabel = type === JobType.Job ? 'den Auftrag' : 'die Anfrage';
        const textTypeLabel = type === JobType.Job ? 'Ihr Auftrag' : 'Ihre Anfrage';

        // job is archived
        if (isArchived) {
            return (
                <Fragment>
                    <p>
                        Sie sind im Begriff, {' '}
                        {jobTypeLabel}
                        <span
                            onClick={onClose}
                            className="btn--link-neutral"
                        >
                            {' '} {jobName}
                        </span> wieder zu reaktivieren.
                    </p>


                    <Fragment>
                        <p>
                            <strong style={{ fontWeight: 600 }}> Bitte wählen Sie einen Status, in
                                dem
                                sich {textTypeLabel} nach der Reaktivierung befinden soll:
                            </strong>
                        </p>
                        <Select
                            classNamePrefix="react-select"
                            placeholder="Status wählen*"
                            options={this.getStatusOptions()}
                            styles={{ ...styles }}
                            isDisabled={isProcessing}
                            onChange={(selection) => this.onTargetStatusChange(selection)}
                        />
                        {statusSelectionError &&
                        <div className="input-capture__error">
                            {statusSelectionError}
                        </div>
                        }
                    </Fragment>


                </Fragment>
            );
        }

        // job is active
        return (
            <Fragment>
                <p>
                    Sie sind im Begriff, {jobTypeLabel}
                    <span
                        onClick={onClose}
                        className="btn--link-neutral"
                    >
                                 {' '} {jobName}
                            </span> zu archivieren.
                </p>

                {type === JobType.Job &&
                <p>
                    <strong style={{ fontWeight: 600 }}> Danach stehen Ihnen der Auftrag und
                        darunterliegende Baustellenplanungen nicht mehr zur Interaktion zu
                        Verfügung.
                    </strong>
                </p>

                }
                <p>Möchten Sie fortfahren?</p>

            </Fragment>
        );
    }

    showConfirmationSuccessMessage() {

        const { isArchived, type, job: { name: jobName } } = this.state;

        const jobTypeLabel = type === JobType.Job ? 'Auftrag' : 'Anfrage';

        const message = isArchived ?
            `${jobTypeLabel}: "${jobName}" wurde aktiviert.`
            :
            `${jobTypeLabel}: "${jobName}" wurde archiviert.`;

        toast.success(message, { autoClose: 2500 });

    }

    handleArchivationConfirmation() {
        const { isProcessing } = this.state;
        const { id: jobId, onConfirmationSuccess, actions: { archiveJob } } = this.props;

        if (isProcessing) {
            return;
        }

        this.setIsProcessing(true);

        archiveJob(jobId)
            .then(() => {

                if (this.loadingGotCancelled) {
                    return;
                }
                this.showConfirmationSuccessMessage();
                onConfirmationSuccess(ArchiveActions.Archived);
            })
            .catch((error) => {
                this.handleError(error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setIsProcessing(false);
            });
    }

    handleError(error) {

        const { isRequest } = this.props;

        if (error instanceof GraphQLError) {

            const prefix = isRequest ? 'Die Anfrage' : 'Der Auftrag';

            if (error.is(GraphQlErrorCodes.JobAlreadyArchived)) {
                showTempError(`${prefix} wurde bereits archiviert.`);
            } else if (error.is(GraphQlErrorCodes.JobAlreadyActive)) {
                showTempError(`${prefix} wurde bereits reaktiviert.`);
            } else {
                showApiError(error);
            }

        } else {
            showApiError(error);
        }

    }

    resetStatusSelectionError() {
        this.setState({
            statusSelectionError: ''
        });
    }

    handleReactivationConfirmation() {

        const { id: jobId, onConfirmationSuccess, actions: { reactivateJob } } = this.props;
        const { selectedTargetStatus } = this.state;

        this.resetStatusSelectionError();

        if (!selectedTargetStatus) {
            this.setState({
                statusSelectionError: `Bitte wählen Sie einen Status`
            });
            return;
        }

        this.setIsProcessing(true);

        reactivateJob(jobId, selectedTargetStatus)
            .then(() => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.showConfirmationSuccessMessage();
                onConfirmationSuccess(ArchiveActions.Reactivated);
            })
            .catch((error) => {
                this.handleError(error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setIsProcessing(false);
            });
    }

    handleConfirmationClick() {

        const { isArchived, isProcessing } = this.state;

        if (isProcessing) {
            return;
        }

        if (!isArchived) {
            this.handleArchivationConfirmation();
        } else {
            this.handleReactivationConfirmation();
        }

    }

    renderConfirmButtonText() {

        const { isArchived, isProcessing, hasActiveSections, currentPage } = this.state;

        let defaultButtonText = hasActiveSections ? 'Baustelle archivieren' : 'Fortfahren und archivieren';

        if (hasActiveSections && currentPage === 1) {
            defaultButtonText = 'Ja, fortfahren';
        }

        let processingStateMessage = 'Wird archiviert...';

        if (isArchived) {
            processingStateMessage = 'Wird reaktiviert...';
            defaultButtonText = 'Fortfahren und reaktivieren';
        }

        return isProcessing ? processingStateMessage : defaultButtonText;

    }

    renderModalTitle() {
        const { isArchived } = this.state;
        const { isRequest } = this.props;

        if (isRequest) {
            return isArchived ? 'Anfrage reaktivieren' : 'Anfrage archivieren';
        }

        return isArchived ? 'Auftrag reaktivieren' : 'Auftrag archivieren';

    }

    renderModalFooter() {

        const { onClose } = this.props;
        const { isProcessing, isInitializing, isArchived } = this.state;

        const submitButtonClasses = ['btn'];

        if (!isArchived) {
            submitButtonClasses.push('btn--primary-green btn--save');
        } else {
            submitButtonClasses.push('btn--primary-green btn--save');
        }

        return (
            <div className="btn-group">
                <button
                    className="btn btn--backward-action"
                    onClick={onClose}
                >
                    Abbrechen
                </button>
                {
                    !isInitializing && <button
                        type="submit"
                        className={submitButtonClasses.join(' ')}
                        disabled={isProcessing}
                        style={{ float: 'right' }}
                        onClick={this.handleConfirmationClick}
                    >
                        {this.renderConfirmButtonText()}
                    </button>
                }
            </div>
        );
    }

    render() {

        const { classes, onClose, isRequest } = this.props;
        const {isInitializing} = this.state;

        return (
            <Modal
                id="modal-archive"
                title={this.renderModalTitle()}
                type="confirmation"
                classes={classes}
                onBackdropClick={() => onClose()}
                footer={this.renderModalFooter()}
                loading={isInitializing}
                loadingLabel={isRequest ? 'Lade Anfragedetails ...' : 'Lade Auftragsdetails ...'}
            >
                {!isInitializing && this.renderModalContent()}
            </Modal>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        archiveJob,
        reactivateJob,
        fetchJobArchivationData
    }, dispatch)
});


export default connect(null, mapDispatchToProps)(ArchiveJobConfirmationModal);

ArchiveJobConfirmationModal.propTypes = {
    onConfirmationSuccess: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isArchived: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isRequest: PropTypes.bool.isRequired
};

