import setFieldTouched from 'final-form-set-field-touched';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../redux/modules/error/action';
import {
    applyConstructionPlan,
    fetchConstructionPlanSiteReferenceData
} from '../../../redux/modules/job/actions';
import { resolveConstructionPlanMedia } from '../../../redux/modules/media/action';
import GraphQLError from '../../../Services/GraphQLError';
import LiveBadge from '../../Common/Badge/LiveBadge';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import LoadingSpinner from '../../Page/LoadingSpinner';
import { ConstructionPlanFormPropType } from '../Form/propTypes';
import { isNewPlan } from '../utils';
import ConstructionPlanSyncModalHeader from './ConstructionPlanSyncModalHeader';
import createDecorator from './focusOnFirstSyncFormError';
import {
    getInitialConstructionPlanSyncFormValues,
    getModelValue,
    getSyncData,
    validateSyncForm
} from './form';
import ConstructionPlanSyncFormSite from './Site/ConstructionPlanSyncFormSite';
import ConstructionPlanSyncSummary from './Summary/ConstructionPlanSyncSummary';

const focusOnError = createDecorator();

class ConstructionPlanSyncModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isInitializing: true,
            isSaving: false,
            initialFormValues: null,
            resolvedMedia: null,
            liveSite: null,
            step: 1,
            syncData: null,
            submissionError: '',
            showConfirmationModal: false
        };

        this.loadingGotCancelled = false;
        this.validate = this.validate.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
    }

    static getConstructionPlanSyncErrorCodeMessage(errorCode) {
        switch (errorCode) {

            case 'site_id_already_exists':
                return 'Die Baustelle existiert bereits';

            case 'input_required_field_missing':
                return 'Bitte befüllen Sie alle erforderlichen Eingabefelder';

            case 'media_not_found':
                return 'Die Datei konnte nicht gefunden werden';

            case 'media_model_class_mismatch':
                return 'Die Datei konnte nicht zugeordnet werden';

            case 'section_invariant_violation':
                return 'Die zugeordneten Gerüstabschnitte/Gerüste sind fehlerhaft';

            case 'site_address_empty':
                return 'Es wurde keine Adresse hinterlegt';

            case 'scaffolding_id_already_exists':
                return 'Das Gerüst existiert bereits';

            case 'section_id_already_exists':
                return 'Der Gerüstabschnitt existiert bereits';

            case 'scaffolding_not_found':
                return 'Das zugehörige Gerüst konnte nicht gefunden werden';

            default:
                return false;
        }
    }

    componentWillMount() {
        this.fetchSite()
            .then(() => this.resolveMedia())
            .then(() => this.initializeForm());
    }

    fetchSite() {
        const { constructionPlan, constructionPlan: { site }, actions: { fetchConstructionPlanSiteReferenceData } } = this.props;

        return new Promise((resolve, reject) => {

            if (isNewPlan(constructionPlan)) {
                resolve();
            }

            fetchConstructionPlanSiteReferenceData(site.id)
                .then((site) => {
                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        liveSite: site
                    }, () => resolve());
                })
                .catch(error => {
                    showRequestError('Baustellendaten konnte nicht geladen werden', error);
                    reject();
                });
        });
    }

    resolveMedia() {
        const { constructionPlan: { site }, actions: { resolveConstructionPlanMedia } } = this.props;
        const { liveSite } = this.state;

        return new Promise((resolve, reject) => {

            if (!site || !site.id) {
                resolve();
            }

            resolveConstructionPlanMedia(site, liveSite, false)
                .then(media => {
                    this.setState({
                        resolvedMedia: media
                    }, () => resolve());
                })
                .catch(err => {
                    showRequestError('Dokumente konnten nicht geladen werden', err);
                    reject();
                });
        });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    validate(values) {
        return validateSyncForm(values);
    }

    onCancel(pristine) {

        const { step } = this.state;
        if (step === 2) {
            this.setState({
                step: 1
            });
            return;
        }

        const { onClose } = this.props;

        if (pristine) {
            onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            onClose();
        }
    }

    setSubmissionError(errorMessage) {
        this.setState({
            submissionError: errorMessage
        });
    }

    resetSubmissionError() {
        this.setState({
            submissionError: ''
        });
    }

    handleFormSubmissionError(error, defaultMessage) {
        if (error instanceof GraphQLError) {
            const message = ConstructionPlanSyncModal.getConstructionPlanSyncErrorCodeMessage(error.errorCode);
            if (message) {
                this.setSubmissionError(message);
                return;
            }
        }

        showRequestError(defaultMessage, error);
    }

    initializeForm() {
        const { constructionPlan } = this.props;
        const { resolvedMedia, liveSite } = this.state;
        const initialFormValues = getInitialConstructionPlanSyncFormValues(constructionPlan, liveSite, resolvedMedia);

        this.setState({
            initialFormValues,
            isInitializing: false
        });
    }

    toggleConfirmationModal() {
        this.setState(prevState => ({
            showConfirmationModal: !prevState.showConfirmationModal
        }));
    }

    onSubmit(values) {

        const { constructionPlan } = this.props;
        const { step, liveSite } = this.state;

        if (step === 1) {
            const syncData = getSyncData(constructionPlan, liveSite, values);

            this.setState({
                step: 2,
                syncData
            });
            return false;
        }

        if (step === 2) {
            this.toggleConfirmationModal();
        }
    }

    getSuccessMessage() {
        const { constructionPlan } = this.props;
        const { liveSite } = this.state;
        const siteName = getModelValue(constructionPlan.site, liveSite, 'name', '-');

        return `Die Planung der Baustelle "${siteName}" wurde erfolgreich angewendet`;
    }

    submitSyncData() {

        const { jobId, constructionPlan, actions: { applyConstructionPlan }, onConstructionPlanSynchronization } = this.props;
        const { liveSite, resolvedMedia, syncData } = this.state;

        this.resetSubmissionError();

        this.setState({
            isSaving: true
        });

        applyConstructionPlan(jobId, constructionPlan, syncData, liveSite, resolvedMedia)
            .then(() => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isSaving: false
                });

                const successMessage = this.getSuccessMessage();
                toast.success(successMessage, { autoClose: 2500 });

                if (typeof onConstructionPlanSynchronization === 'function') {
                    onConstructionPlanSynchronization();
                }
            })
            .catch((error) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isSaving: false,
                    showConfirmationModal: false
                });

                this.handleFormSubmissionError(error, 'Die Bauplanung konnte nicht synchronisiert werden.');
            });
    }

    getModalTitle() {
        const { constructionPlan } = this.props;
        const { step } = this.state;
        const content = isNewPlan(constructionPlan)
            ? 'Live-Baustelle generieren'
            : <Fragment>
                Planung anwenden <span>{step === 1 ? 'Auswahl' : 'Zusammenfassung'}</span>
            </Fragment>;

        return (
            <div className="construction-planner-sync-form__title">
                {content}
            </div>
        );
    }

    getModalSubTitle() {
        const { constructionPlan } = this.props;
        const { step } = this.state;

        if (step === 2) {
            return 'Bitte überprüfen Sie Ihre Angaben abschließend, bevor Sie die Synchronisierung abschließen.';
        }

        const content = isNewPlan(constructionPlan) ?
            'Bitte geben Sie an, welche Dokumente Sie auf die Live-Baustelle übertragen möchten und welche Sichtbarkeit diese aufweisen sollen.'
            : (<Fragment>
                Einige Angaben in der Baustellenplanung unterscheiden sich von der Baustellen- und
                Gerüstkonfiguration der Live-Baustelle. Bitte geben Sie an, welche Daten und
                Dokumente Sie auf die Live-Baustelle anwenden möchten.

            </Fragment>);

        return (
            <div className="construction-planner-sync-form__subtitle">
                {content}
            </div>
        );
    }

    renderLeftSyncFormHeader = () => {

        const { jobLabel } = this.props;

        return (
            <Fragment>
                <h2>Baustellenplanung</h2>
                <div className="construction-planner-sync-form-header__reference">{jobLabel}</div>
            </Fragment>
        );
    };

    renderRightSyncFormHeader = () => {

        const { constructionPlan, liveSite, liveSiteName } = this.props;
        const name = liveSiteName ? liveSiteName : getModelValue(constructionPlan.site, liveSite, 'name', '-');
        const title = isNewPlan(constructionPlan)
            ? 'Neue Live-Baustelle'
            : <Fragment>Live-Baustelle <LiveBadge/></Fragment>;
        const subline = <div
            className="construction-planner-sync-form-header__reference">{name}</div>;

        return (
            <Fragment>
                <h2>{title}</h2>
                {subline}
            </Fragment>
        );
    };

    renderInputBody(values, mutators) {
        const { liveSite, resolvedMedia } = this.state;
        const { constructionPlan } = this.props;

        return (
            <Fragment>

                <img
                    src="/images/sync-form-usage-visualization.png"
                    alt="Bauplanung anwenden"
                    className="construction-planner-sync-form__usage-visualization"
                />

                <ConstructionPlanSyncModalHeader
                    left={() => this.renderLeftSyncFormHeader()}
                    right={() => this.renderRightSyncFormHeader()}
                />

                <ConstructionPlanSyncFormSite
                    values={values}
                    setModelDataMutator={mutators.setModelData}
                    setFieldTouchedMutator={mutators.setFieldTouched}
                    setFieldMutator={mutators.setField}
                    constructionPlan={constructionPlan}
                    liveSite={liveSite}
                    resolvedMedia={resolvedMedia}
                />

                {/* For debugging purposes */}
                {/*<FormDebugger values={values} />*/}
            </Fragment>
        );
    }

    renderSummaryBody() {
        const { liveSite, syncData, resolvedMedia } = this.state;

        return (
            <ConstructionPlanSyncSummary
                syncData={syncData}
                liveSite={liveSite}
                resolvedMedia={resolvedMedia}
            />
        );
    }

    renderBody(values, mutators) {
        const { step } = this.state;
        return step === 1 ? this.renderInputBody(values, mutators) : this.renderSummaryBody(values);
    }

    getConfirmationModalContent() {

        const { constructionPlan } = this.props;

        if (!isNewPlan(constructionPlan)) {
            return (
                <div>
                    <p>
                        <strong>Durch das Anwenden der Planung werden die Daten auf der
                            Live-Baustelle überschrieben.</strong> Bitte überprüfen Sie Ihre Auswahl
                        daher sorgfältig, bevor Sie die Planung auf die Baustellenverwaltung
                        anwenden.
                    </p>
                    <p>
                        Möchten Sie fortfahren und die Planung anwenden?
                    </p>
                </div>
            );
        }
        return (
            <div>
                <p>
                    Durch den Klick auf “Baustelle generieren” wird eine neue Baustelle im Bereich
                    “Baustellenverwaltung” angelegt.
                </p>
                <p>
                    <strong>Dadurch werden die Baustellendaten öffentlich verfügbar
                        sein.</strong>
                </p>
                <p>
                    Möchten Sie fortfahren und die Baustelle generieren?
                </p>
            </div>
        );
    }

    render() {

        const { initialFormValues, isSaving, isInitializing, step, submissionError, showConfirmationModal } = this.state;
        const { constructionPlan } = this.props;

        if (isInitializing) {
            return (
                <Modal
                    title={this.getModalTitle()}
                    subtitle={this.getModalSubTitle()}
                    collapseHeader
                    temporary
                >
                    <LoadingSpinner label="Initialisiere Formular..."/>
                </Modal>
            );
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                decorators={[focusOnError]}
                mutators={{
                    setModelData: ([modelKey, modelData], state, utils) => {
                        utils.changeValue(state, modelKey, () => modelData);
                    },
                    setFieldTouched
                }}
                validate={this.validate}
                initialValues={initialFormValues}
                render={({ handleSubmit, pristine, values, form: { mutators } }) => {
                    return <form name="constructionSyncForm" onSubmit={handleSubmit}>
                        <Modal
                            title={this.getModalTitle()}
                            subtitle={this.getModalSubTitle()}
                            id="constructionplanner-syncmodal"
                            loading={isInitializing}
                            loadingLabel="Lade Baustellendaten"
                            collapseHeader
                            footer={
                                <ModalFormFooter
                                    onSubmit={handleSubmit}
                                    isSubmitting={isSaving}
                                    onClose={() => this.onCancel(pristine)}
                                    submitLabel={step === 1 ? 'Weiter' : 'Speichern'}
                                    cancelLabel={step === 1 ? 'Abbrechen' : 'Zurück'}
                                    submitDisabled={isSaving || isInitializing}
                                />
                            }
                        >
                            {submissionError &&
                            <div className="form-error">{submissionError}</div>}
                            {this.renderBody(values, mutators)}
                        </Modal>

                        {showConfirmationModal &&
                        <ConfirmationModal
                            title={isNewPlan(constructionPlan) ? 'Baustelle generieren' : 'Hinweis'}
                            isConfirming={isSaving}
                            onConfirm={() => this.submitSyncData()}
                            closeModal={() => this.toggleConfirmationModal()}
                            buttonTitle={isNewPlan(constructionPlan) ? 'Baustelle generieren' : 'Planung anwenden'}
                        >
                            {this.getConfirmationModalContent()}
                        </ConfirmationModal>
                        }

                        {/* Debug the form */}
                        {/*<FormDebugger values={values} />*/}

                    </form>
                }}
            />
        );
    }
}

ConstructionPlanSyncModal.propTypes = {
    jobId: PropTypes.string.isRequired,
    constructionPlan: ConstructionPlanFormPropType,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
    jobLabel: PropTypes.string.isRequired,
    onConstructionPlanSynchronization: PropTypes.func.isRequired,
    liveSiteName: PropTypes.string
};

ConstructionPlanSyncModal.defaultProps = {
    constructionPlan: undefined,
    onUpdate: null,
    onClose: () => false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanSiteReferenceData,
        resolveConstructionPlanMedia,
        applyConstructionPlan
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanSyncModal);
