import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/de';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { fetchCompanyData } from '../../redux/modules/account/action';
import { fetchAccountDetails } from '../../redux/modules/user/action';
import Section from '../Layout/Section';
import DataRow from '../Common/DataRow';
import Icon from '../Common/Icon';
import PageTitle from '../Page/PageTitle';
import SubscriptionTypes from './subscriptionTypes';
import DeleteViaEmailModal from './DeleteViaEmailModal';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';
import { showApiError } from '../../redux/modules/error/action';

class UserInfo extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            accountDeleteModalIsOpen: false
        };

        this.toggleAccountDeleteModal = this.toggleAccountDeleteModal.bind(this);
    }

    componentWillMount() {
        this.setState({
            isFetching: true
        });

        const { actions: { fetchAccountDetails } } = this.props;

        fetchAccountDetails()
            .then((accountDetails) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    accountDetails,
                    isFetching: false
                });
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    toggleAccountDeleteModal() {
        this.setState(prevState => ({
            accountDeleteModalIsOpen: !prevState.accountDeleteModalIsOpen
        }));
    }

    render() {
        const {
            accountDetails,
            isFetching,
            accountDeleteModalIsOpen
        } = this.state;

        if (isFetching) {
            return (
                <Fragment>
                    <PageTitle title="Kontodaten"/>
                    <PageLoadingSpinner />
                </Fragment>
            )
        }

        return (
            <React.Fragment>

                <PageTitle title="Kontodaten">
                    <Link
                        className="btn btn--neutral-action"
                        to="/me/edit"
                    >
                            <span className="btn__icon">
                                <Icon name="edit" />
                            </span>
                        <span>Bearbeiten</span>
                    </Link>
                </PageTitle>

                <Section>
                    <DataRow
                        label="Name"
                    >
                        {accountDetails.firstName} {' '} {accountDetails.lastName}
                    </DataRow>

                    <DataRow
                        label="E-Mail Adresse"
                    >{accountDetails.email &&
                    <a href={`mailto:${accountDetails.email}`}>{accountDetails.email}</a>}
                    </DataRow>

                    <DataRow
                        label="Kontotyp"
                    >
                        {SubscriptionTypes[accountDetails.type]}
                    </DataRow>
                    <DataRow label="Passwort">
                        <Link to="/me/edit">
                            Passwort ändern
                        </Link>
                    </DataRow>
                </Section>

                {accountDeleteModalIsOpen &&
                <DeleteViaEmailModal
                    modalTitle="Konto schließen"
                    closeModal={this.toggleAccountDeleteModal}
                    subject={`Schließung meines Scaffeye®-Kontos [${accountDetails.account.company.id}]`}
                    body={`Sehr geehrte Damen und Herren, %0D%0A %0D%0A hiermit möchte ich die Schließung meines Scaffeye®-Kontos beantragen.%0D%0A %0D%0A Ich bin mir darüber bewusst, dass ich und meine Nutzer dadurch sämtlichen Zugriff auf die Plattform Scaffeye® verlieren. %0D%0A Ebenfalls bin ich mir darüber%20
bewusst, dass mir die in Scaffeye® hinterlegten Daten nicht mehr zur Verfügung stehen werden. %0D%0A %0D%0A Bitte setzen Sie sich hierzu über meine in den Kontoeinstellungen hinterlegten Stammdaten mit mir in Verbindung. %0D%0A %0D%0A Mit freundlichen Grüßen, %0D%0A %0D%0A ${accountDetails.firstName} ${accountDetails.lastName}`}
                >
                    <p>Sie sind im Begriff, Ihr Konto zu schließen. <br />
                        <strong>Damit verlieren Sie und alle weiteren Nutzer Ihres Kontos Zugriff
                            auf die Plattform Scaffeye&reg;. Damit stehen Ihnen ebenfalls die hier
                            hinterlegten Daten nicht mehr zur
                            Verfügung.
                        </strong>
                    </p>
                    <p>Um diesen Prozess einzuleiten, kontaktieren Sie bitte den
                        Scaffeye&reg;-Support. Hierzu werden Sie im nächsten Schritt auf ihr E-Mail
                        Programm weitergeleitet.
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteViaEmailModal>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ currentUser }) => {
    const companyId = currentUser.usermeta.account.company.id || null;
    return {
        companyId
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCompanyData,
        fetchAccountDetails
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);

