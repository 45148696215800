import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';

const EmptyCardHint = (({ title, children }) => (
    <div className="card-empty-hint">
        <div className="card-empty-hint__hd"><strong><Icon name="info" />{title}</strong></div>
        {!children ? null :
            <div className="card-empty-hint__bd">
                {children}
            </div>
        }
    </div>
));

export default EmptyCardHint;

EmptyCardHint.propTypes = {
    title: PropTypes.string.isRequired
};
