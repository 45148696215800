import React from 'react';

const Changelog_1_13_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">

            <section>
                <h4>Anonymisierung der Prüfhistorie</h4>
                <p>
                    Als Gerüstnutzer werden Ihnen die Prüfungen anderer Gerüstnutzer ab sofort anonymisiert angezeigt. Eigene Prüfungen und Prüfungen bzw. Freigaben des Gerüsterstellers sind nach wie vor vollständig einsehbar. Gerüsterstellern wird die Prüfhistorie der eigenen Gerüste nicht anonymisiert.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>
                        Konsistentere Darstellung der Auftraggeber-Informationen im Freigabeaushang
                    </li>
                    <li>
                        Weitere kleinere Verbesserungen und Korrekturen
                    </li>
                </ul>
            </section>

        </div>
    </div>
);

export default Changelog_1_13_0;
