import _ from 'lodash';

export const sortCustomerContactsByMainContact = (customerContacts) => {

    return _.sortBy(customerContacts, function (contact) {
        return contact.isMainContact ? 0 : 1;
    });

};

