import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import FieldError from './FieldError';

export const SelectOptionsPropType = PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool
});

const Select = ({ name, onChange, options, label, disabled, placeholder, ...fieldProps }) => (
    <Field
        name={name}
    >
        {({ input, meta }) => {

            const inputCssClasses = ['input-grp__field'];

            if (meta.error) {
                inputCssClasses.push('is-invalid');
            } else {
                inputCssClasses.push('is-valid');
            }

            if (meta.touched) {

                inputCssClasses.push('is-dirty');
            } else {
                inputCssClasses.push('is-pristine');
            }

            return (
                <React.Fragment>
                    <Field
                        name={name}
                        disabled={disabled}
                        component="select"
                        {...fieldProps}
                    >
                        {
                            placeholder ?
                                <option value="">
                                    {placeholder}
                                </option>
                                : null
                        }

                        {
                            options && options.map((option, i) => (
                                <option
                                    value={option.value}
                                    key={i}
                                    disabled={option.disabled}
                                >
                                    {option.label}
                                </option>
                            ))
                        }
                    </Field>
                    <FieldError meta={meta} />
                    {
                        onChange && (
                            <OnChange name={name}>
                                {(val, previous) => val && onChange(val, previous)}
                            </OnChange>
                        )
                    }

                </React.Fragment>
            );
        }}

    </Field>
);


export default Select;
