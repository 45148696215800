import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import { bindActionCreators } from 'redux';
import { autocompleteConstructionGroupMember } from '../../../redux/modules/constructiondiary/action';
import { showApiError } from '../../../redux/modules/error/action';
import { ActionTypes } from '../../Common/AsyncAutocomplete';
import AsyncCreatableSelectField from '../../Forms/Select/AsyncCreatableSelectField';

const DEBOUNCE_TIMEOUT = 500;

class TeamMemberSelectField extends Component {

    constructor(props) {
        super(props);

        this.getOptions = this.getOptions.bind(this);
        this.debouncedFetchOptions = _.debounce(this.fetchOptions, DEBOUNCE_TIMEOUT);
        this.renderNoOptionsMessage = this.renderNoOptionsMessage.bind(this);

        this.state = {
            allAvailableGroupMembersWereSelected: false
        };
    }

    fetchOptions(input, callback) {
        let querystring = '';

        if (input) {
            querystring = input;
        }

        // remove ' + " from input
        const preparedInput = querystring.replace(/['"]+/g, '');

        const { newlyCreatedGroupUserOptions, actions: { autocompleteConstructionGroupMember }, selectedGroupUsers } = this.props;

        autocompleteConstructionGroupMember(preparedInput)
            .then((options) => {
                const exisitingGroupUserOptions = options.map(option => {

                    const { name } = option;

                    return {
                        label: name,
                        value: name,
                        name
                    };
                });

                // merge previously created group user w/ existing ones
                const mergedGroupUserOptions = [...exisitingGroupUserOptions, ...newlyCreatedGroupUserOptions];

                // order options by name
                const orderedAndMergedGroupUserOptions = _.sortBy(mergedGroupUserOptions, option => option.name);

                // Exclude already selected group users
                const availableOptions = _.differenceBy(orderedAndMergedGroupUserOptions, selectedGroupUsers, 'name');

                this.setState({
                    allAvailableGroupMembersWereSelected: availableOptions.length === 0 && orderedAndMergedGroupUserOptions.length > 0
                });

                callback(availableOptions);

            })
            .catch((error) => {
                showApiError(error);
            });
    }

    formatSelection(selectedOption, action) {

        if (!selectedOption) {
            return null;
        }

        return {
            name: selectedOption.label,
            isNewlyCreated: action.action === ActionTypes.createOption
        };
    }

    getOptions(input, callback) {
        return this.debouncedFetchOptions(input, callback);
    }

    renderCreateNewOption = (value) => {

        return (
            <div className="construction-diary-team-members__nooptions-create-btn">
                <strong>{`${value}`}</strong> anlegen
            </div>
        );
    };

    renderNoOptionsMessage() {

        const { allAvailableGroupMembersWereSelected } = this.state;

        return (
            <div className="construction-diary-team-members__nooptions-hint">
                {
                    allAvailableGroupMembersWereSelected ?
                        <Fragment>
                            Es wurden alle bisher angelegten Mitarbeiter ausgewählt. Legen Sie
                            weitere
                            Mitarbeiter an, um diese auszuwählen und zur Kolonne hinzuzufügen.
                        </Fragment>
                        :
                        <Fragment>
                            Keine Mitarbeiter vorhanden. Legen Sie neue Mitarbeiter an, indem Sie
                            den
                            Namen (Vorname Nachname) in diesem Auswahlfeld eingeben und anschließend
                            auf
                            "Anlegen" klicken.
                        </Fragment>
                }
            </div>

        );
    }

    render() {

        const { ...fieldProps } = this.props;

        return (
            <AsyncCreatableSelectField
                {...fieldProps}
                noOptionsMessage={this.renderNoOptionsMessage}
                loadingMessage={() => 'Lade Mitarbeiter ...'}
                createLabel={this.renderCreateNewOption}
                isMulti={false}
                isClearable
                backspaceRemovesValue={false}
                formatSelection={this.formatSelection}
                loadOptions={this.getOptions}
            />
        );
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteConstructionGroupMember: autocompleteConstructionGroupMember
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(TeamMemberSelectField);


