import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModelType } from '../../../../properties';
import { fetchConstructionPlanSiteReferenceData } from '../../../../redux/modules/job/actions';
import { resolveConstructionPlanMedia } from '../../../../redux/modules/media/action';
import { ModelMediaType } from '../../../Media/properties';
import { ConstructionPlanFormPropType } from '../../Form/propTypes';
import { ConstructionPlanModelMode } from '../../properties';
import AddressSyncChoice from '../Base/Input/Address/AddressSyncChoice';
import MediaListSyncChoice from '../Base/Input/MediaListSyncChoice/MediaListSyncChoice';
import TextSyncChoice from '../Base/Input/Text/TextSyncChoice';
import SyncModelSection from '../Base/SyncModelSection';
import { getModelTypeKeyWithIdForForm, getModelValue, isOnlyOneValueSet } from '../form';
import ConstructionPlanSyncModelReferenceHint from '../Base/ConstructionPlanSyncModelReferenceHint';
import {
    getMediaFromRepository,
    getSingleMediaFromRepository,
    propertyReferencesMedia,
    propertyReferencesSingleMedia
} from '../media';
import ConstructionPlanSyncScaffoldingList from '../Scaffolding/ConstructionPlanSyncScaffoldingList';
import { isIdenticalSiteFormValue } from './equals';
import {
    transformLiveSiteToFormValues,
    transformSitePlanToFormValues
} from './functions';

class ConstructionPlanSyncFormSite extends React.Component {

    static propTypes = {
        values: PropTypes.object.isRequired,
        constructionPlan: ConstructionPlanFormPropType.isRequired,
        setModelDataMutator: PropTypes.func.isRequired,
        setFieldTouchedMutator: PropTypes.func.isRequired,
        resolvedMedia: PropTypes.object.isRequired,
        liveSite: PropTypes.any
    };

    static defaultProps = {
        liveSite: null
    };

    constructor(props) {
        super(props);
        const { site } = props.constructionPlan;
        this.siteKey = getModelTypeKeyWithIdForForm(ModelType.Site, site);

        this.selectAllPlan = this.selectAllPlan.bind(this);
        this.selectAllLive = this.selectAllLive.bind(this);
    }

    getFormNameForProperty(property) {
        return `${this.siteKey}.data.${property}`;
    }

    selectAllPlan() {
        const { values, constructionPlan: { site: { data } }, liveSite, resolvedMedia } = this.props;
        const newFormData = transformSitePlanToFormValues(data, liveSite, resolvedMedia);
        const newFormValues = { ...values[this.siteKey], data: newFormData };
        this.props.setModelDataMutator(this.siteKey, newFormValues);
    }

    selectAllLive() {
        const { values, liveSite, resolvedMedia, constructionPlan: { site: { data } } } = this.props;
        const newFormData = transformLiveSiteToFormValues(liveSite, data, resolvedMedia);
        const newFormValues = { ...values[this.siteKey], data: newFormData };
        this.props.setModelDataMutator(this.siteKey, newFormValues);
    }

    hasIdenticalOptions(stopTraverse = true) {
        const { constructionPlan: { site: { data } }, liveSite } = this.props;
        let hasIdentical = false;

        if (liveSite) {
            for (const property in data) {

                if (data.hasOwnProperty(property)) {
                    hasIdentical = isIdenticalSiteFormValue(property, data, liveSite);
                }

                if (stopTraverse === hasIdentical) {
                    break;
                }
            }
        }

        return hasIdentical;
    }

    getLiveValue(property) {
        const { liveSite, constructionPlan: { site: { data } }, resolvedMedia } = this.props;

        if (propertyReferencesMedia(property)) {
            if (!liveSite) {
                return null;
            }
            return getMediaFromRepository(liveSite[property], resolvedMedia);
        } else if (propertyReferencesSingleMedia(property)) {
            return getSingleMediaFromRepository(liveSite, property, resolvedMedia);
        }

        return this.getValue(property, liveSite, data, resolvedMedia);
    }

    getPlanValue(property) {
        const { constructionPlan: { site: { data } }, liveSite, resolvedMedia } = this.props;

        if (propertyReferencesMedia(property)) {
            if (!data) {
                return null;
            }
            return getMediaFromRepository(data[property], resolvedMedia);
        } else if (propertyReferencesSingleMedia(property)) {
            return getSingleMediaFromRepository(data, property, resolvedMedia);
        }

        return this.getValue(property, data, liveSite);
    }

    getValue(property, modelToGetBack, modelToCompare) {
        let defaultValue = undefined;

        // one value is set an the other not.
        // input fields allow null to select without warning
        if (isOnlyOneValueSet(property, modelToGetBack, modelToCompare)) {
            defaultValue = null;
        }

        return modelToGetBack && modelToGetBack.hasOwnProperty(property) && modelToGetBack[property] &&
        (
            (typeof modelToGetBack[property] === 'object' && Object.keys(modelToGetBack[property]).length > 0)
            || typeof modelToGetBack[property] === 'string'
        )
            ? modelToGetBack[property] : defaultValue;
    }

    renderInputFields() {
        const {
            constructionPlan,
            values,
            setFieldTouchedMutator
        } = this.props;

        const { site } = constructionPlan;
        if (site.mode === ConstructionPlanModelMode.Reference) {
            return <ConstructionPlanSyncModelReferenceHint />;
        }

        const showIdentical = values[this.siteKey].showIdentical;

        return (
            <Fragment>
                <TextSyncChoice
                    label="Name"
                    name={this.getFormNameForProperty('name')}
                    planValue={this.getPlanValue('name')}
                    liveValue={this.getLiveValue('name')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                    required
                />

                <TextSyncChoice
                    label="Notizen"
                    name={this.getFormNameForProperty('description')}
                    planValue={this.getPlanValue('description')}
                    liveValue={this.getLiveValue('description')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <AddressSyncChoice
                    name={this.getFormNameForProperty('address')}
                    planValue={this.getPlanValue('address')}
                    liveValue={this.getLiveValue('address')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <MediaListSyncChoice
                    label="Anhänge"
                    name={this.getFormNameForProperty('attachments')}
                    planValue={this.getPlanValue('attachments')}
                    liveValue={this.getLiveValue('attachments')}
                    modelMediaType={ModelMediaType.Site_Attachment}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />
            </Fragment>
        );
    }

    render() {
        const {
            constructionPlan: { site, site: { mode, data, scaffoldings } },
            liveSite,
            values,
            setModelDataMutator,
            setFieldTouchedMutator,
            resolvedMedia
        } = this.props;

        let liveData;

        if (liveSite) {
            const { scaffoldings: a, ...liveValuesWithoutScaffoldings } = liveSite;
            liveData = liveValuesWithoutScaffoldings;
        } else {
            liveData = liveSite;
        }

        return (
            <Fragment>
                <SyncModelSection
                    type={ModelType.Site}
                    mode={mode}
                    formValues={values[this.siteKey]}
                    name={getModelValue(site, liveSite, 'name', '-')}
                    onChange={(data) => setModelDataMutator(this.siteKey, data)}
                    planData={data}
                    liveData={liveData}
                    hasIdenticalOptions={this.hasIdenticalOptions(true)}
                    onSelectAllPlan={this.selectAllPlan}
                    onSelectAllLive={this.selectAllLive}
                    allValuesIdentical={this.hasIdenticalOptions(false)}
                    resolvedMedia={resolvedMedia}
                >
                    {this.renderInputFields()}
                </SyncModelSection>

                <ConstructionPlanSyncScaffoldingList
                    setFieldTouchedMutator={setFieldTouchedMutator}
                    data={scaffoldings || []}
                    resolvedMedia={resolvedMedia}
                    setModelDataMutator={setModelDataMutator}
                    values={values}
                    liveSite={liveSite}
                />
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanSiteReferenceData,
        resolveConstructionPlanMedia
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanSyncFormSite);
