import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { fetchCompanyData, updateBaseData } from '../../redux/modules/account/action';
import { showApiError } from '../../redux/modules/error/action';
import Input from '../Forms/Input';
import InputGroup from '../Forms/InputGroup';
import Section from '../Layout/Section';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';

class BaseDataForm extends Component {

    static validate(values) {
        const errors = {};

        // Firmenname
        if (!values.name) {
            errors.name = 'Bitte geben Sie einen Firmennamen ein';
        }

        if (!values.addressLine1) {
            errors.addressLine1 = 'Bitte geben Sie eine Adresse ein';
        }

        // Zip
        if (!values.addressZip) {
            errors.addressZip = 'Bitte geben Sie eine Postleitzahl an';
        } else if (!values.addressZip.match(/\d{5}/i)) {
            errors.addressZip = 'Bitte geben Sie eine Zahl ein';
        }

        // City
        if (!values.addressCity) {
            errors.addressCity = 'Bitte geben Sie einen Ort ein';
        }

        return errors;
    }

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            companyId: this.props.edit,
            isFetching: false,
            isSending: false,
            data: {}
        };

        this.prepareFormData = this.prepareFormData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
    }

    componentWillMount() {
        const { edit, actions: { fetchCompanyData } } = this.props;

        if (!edit) {
            return;
        }

        this.setState({ isFetching: true });

        const { companyId } = this.state;

        fetchCompanyData(companyId)

            .then((companyData) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.prepareFormData(companyData);
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onSubmit(values) {

        const companyData = {
            addressCity: values.addressCity,
            addressCountry: values.addressCountry,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            addressName: values.addressName,
            addressZip: values.addressZip,
            email: values.email,
            name: values.name,
            phone: values.phone
        };

        this.setState({ isSending: true });

        if (this.props.edit) {

            const { actions: { updateBaseData }, history } = this.props;

            updateBaseData(companyData)
                .then(() => {

                    this.setState({
                        isSending: false
                    });
                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });

                    if (this.props.redirect) {
                        history.push(this.props.redirect);
                    } else {
                        history.push('/account/profile');
                    }
                })
                .catch((error) => {
                    this.setState({
                        isSending: false
                    });
                    showApiError(error);
                });
        }
    }

    prepareFormData(data) {

        if (!data) {
            this.setState({
                data: null,
                isFetching: false
            });
            return;
        }

        const postLoadData = {
            ...data,
            addressZip: data.addressZip.toString(),
            phone: data.phone ? data.phone.replace(/[()]/g, '') : ''
        };

        this.setState({
            data: postLoadData,
            isFetching: false
        });
    }

    renderButtonText() {
        const { isSending } = this.state;
        const { edit } = this.props;

        if (edit) {
            return isSending ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }

        return isSending ? 'Unternehmen wird gespeichert ...' : 'Unternehmen anlegen';
    }

    render() {
        const {
            data,
            isFetching,
            isSending
        } = this.state;

        const { edit } = this.props;

        if (edit && !isFetching && !data) {
            return <ResourceNotFoundErrorPage />;
        }

        if (isFetching) {
            return <PageLoadingSpinner />;
        }


        return (
            <React.Fragment>
                <Form
                    onSubmit={this.onSubmit}
                    validate={BaseDataForm.validate}
                    initialValues={data}
                    render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <Section
                                title="Unternehmen bearbeiten"
                            >

                                <InputGroup
                                    label="Firmenname*"
                                >
                                    <Input
                                        type="text"
                                        name="name"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Adresszeile1*"
                                >
                                    <Input
                                        type="text"
                                        name="addressLine1"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Adresszeile2"
                                >
                                    <Input
                                        type="text"
                                        name="addressLine2"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Postleitzahl, Ort*"
                                    horizontal
                                >
                                    <div className="input-grp__field--zip">
                                        <Input
                                            type="text"
                                            name="addressZip"
                                            placeholder="PLZ"
                                        />
                                    </div>
                                    <div className="input-grp__field--city">
                                        <Input
                                            type="text"
                                            name="addressCity"
                                            placeholder="Ort"
                                        />
                                    </div>
                                </InputGroup>

                                <InputGroup
                                    label="E-Mail Adresse"
                                >
                                    <Input
                                        type="text"
                                        name="email"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Telefon"
                                >
                                    <Input
                                        type="text"
                                        name="phone"
                                    />
                                </InputGroup>
                            </Section>

                            <div className="btn-group">
                                <a
                                    onClick={this.props.history.goBack}
                                    className="btn btn--backward-action"
                                >
                                    Abbrechen
                                </a>

                                <span className="btn btn--dummy">*Pflichtfeld</span>
                                <button
                                    type="submit"
                                    className="btn btn--forward-action btn--save"
                                    disabled={isSending}
                                >
                                    {this.renderButtonText()}
                                </button>
                            </div>
                        </form>
                    )}
                />
            </React.Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCompanyData,
        updateBaseData
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(BaseDataForm);
