import React from 'react';
import PropTypes from 'prop-types';
import ConstructionPlanSyncFormScaffolding from './ConstructionPlanSyncFormScaffolding';

const ConstructionPlanSyncScaffoldingList = ({ data, liveSite, values, setModelDataMutator, setFieldTouchedMutator, resolvedMedia }) => {

    if(!data){
        return null;
    }

    const extractScaffoldingLiveValues = (scaffolding) => {
        if (!liveSite) {
            return null;
        }
        const searchedScaffolding = liveSite.scaffoldings.find(liveScaffolding => {
            return liveScaffolding.id === scaffolding.id;
        });

        return searchedScaffolding !== undefined ? searchedScaffolding : null;
    };

    return data.map(scaffolding => (
        <ConstructionPlanSyncFormScaffolding
            key={scaffolding.id}
            values={values}
            planValues={scaffolding}
            liveValues={extractScaffoldingLiveValues(scaffolding)}
            setModelDataMutator={setModelDataMutator}
            setFieldTouchedMutator={setFieldTouchedMutator}
            resolvedMedia={resolvedMedia}
        />
    ));
};


ConstructionPlanSyncScaffoldingList.propTypes = {
    data: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    setModelDataMutator: PropTypes.func.isRequired,
    setFieldTouchedMutator: PropTypes.func.isRequired,
    resolvedMedia: PropTypes.object.isRequired,
    liveSite: PropTypes.any
};

ConstructionPlanSyncScaffoldingList.defaultProps = {
    liveSite: null
};

export default ConstructionPlanSyncScaffoldingList;
