import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { getPublicMediaContentUrl } from '../../redux/modules/media/action';
import Icon from '../Common/Icon';
import LightBox from '../Common/LightBox';
import MediaListItem from './MediaListItem';
import { MediaPropType, MediaVisibility } from './properties';
import { getPrivateMedia, getPublicMedia } from './utils';

class MediaList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMediaPreview: false,
            mediaItemToPreview: undefined,
            publicMediaUrlRequestIds: []
        };

        this.previewItem = this.previewItem.bind(this);
        this.onClosePreviewModal = this.onClosePreviewModal.bind(this);
        this.downloadItem = this.downloadItem.bind(this);
    }

    previewItem(mediaItem) {

        this.setState({
            showMediaPreview: true,
            mediaItemToPreview: mediaItem
        });
    }

    downloadItem(mediaItem) {
        this.setState({
            publicMediaUrlRequestIds: [
                ...this.state.publicMediaUrlRequestIds,
                mediaItem.id
            ]
        });

        this.props.actions.getPublicMediaContentUrl(mediaItem.id, mediaItem.fileName, true)
            .then((url) => {
                window.open(url, 'se_media_download');
            })
            .catch(() => {
                toast.error('Download konnte nicht gestartet werden');
            })
            .finally(() => {
                this.setState({
                    publicMediaUrlRequestIds: this.state.publicMediaUrlRequestIds.filter(id => id !== mediaItem.id)
                });
            });
    }

    onClosePreviewModal() {
        this.setState({
            showMediaPreview: false,
            mediaItemToPreview: undefined
        })
    }

    isDownloading(id) {
        return this.state.publicMediaUrlRequestIds.indexOf(id) >= 0;
    }

    renderItems(mediaItems, visibility = undefined) {

        return (
            <div className="media-list__items-ct">
                <div className="media-list__items">
                    {visibility &&
                    <div className="media-list__visibility media-visibility">
                        <span className="media-visibility__icn">
                            <Icon
                                name={visibility === MediaVisibility.Public ? 'unlocked' : 'locked'}
                            />
                        </span>
                        <span className="media-visibility__lbl">
                            {visibility === MediaVisibility.Public ? 'Öffentlich' : 'Intern'}
                        </span>
                    </div>
                    }
                    <div className="media-list__items-items">
                        {mediaItems.map(mediaItem => (
                            <MediaListItem
                                key={mediaItem.id}
                                media={mediaItem}
                                onPreview={this.previewItem}
                                onDownload={this.downloadItem}
                                isDownloading={this.isDownloading(mediaItem.id)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    renderPreview() {
        const { showMediaPreview, mediaItemToPreview } = this.state;

        if (!showMediaPreview) {
            return null;
        }

        return (
            <LightBox media={mediaItemToPreview} onClose={this.onClosePreviewModal} />
        )
    }

    render() {
        const { media, emptyLabel, groupByVisibility } = this.props;

        const hasItems = media && media.length > 0;
        if (!hasItems) {
            return (
                <div className="media-list__items-ct is-empty">
                    <div className="media-list__empty-lbl">
                        {emptyLabel}
                    </div>
                </div>
            );
        }

        /*
         * Do not group by visibility (i.e. third party attachments)
         */
        if (!groupByVisibility) {
            return (
                <div className="media-list__items-ct">

                    {/* Items */}
                    {this.renderItems(media)}

                    {/* Preview */}
                    {this.renderPreview()}
                </div>
            );
        }

        const privateMedia = getPrivateMedia(media);
        const hasPrivateMedia = privateMedia.length > 0;

        const publicMedia = getPublicMedia(media);
        const hasPublicMedia = publicMedia.length > 0;

        return (
            <div className="media-list__items-ct is-grouped">

                {/*
                    Private media
                */}
                {hasPrivateMedia && this.renderItems(privateMedia, MediaVisibility.AccountPrivate)}

                {/*
                    Public media
                */}
                {hasPublicMedia && this.renderItems(publicMedia, MediaVisibility.Public)}

                {/* Preview */}
                {this.renderPreview()}
            </div>
        );
    }
}

MediaList.propTypes = {
    media: PropTypes.arrayOf(MediaPropType).isRequired,
    emptyLabel: PropTypes.string,
    groupByVisibility: PropTypes.bool
};

MediaList.defaultProps = {
    emptyLabel: 'Keine Dokumente hinterlegt',
    groupByVisibility: true
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getPublicMediaContentUrl
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(MediaList);
