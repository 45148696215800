import PropTypes from 'prop-types';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showRequestError, showTempError } from '../../../redux/modules/error/action';
import { updateSectionStatus } from '../../../redux/modules/section/action';
import GraphQLError from '../../../Services/GraphQLError';
import Icon from '../../Common/Icon';
import LoadingSpinner from '../../Page/LoadingSpinner';
import { getStatusLabel } from '../labels';
import { SectionStatus } from '../properties';

const getStatusIcon = (status) => {
    switch (status) {
        case SectionStatus.Constructed:
            return <Icon name="stateok" />;

        case SectionStatus.Closed:
        case SectionStatus.UnderMaintenance:
        case SectionStatus.BeingAltered:
        case SectionStatus.UnderConstruction:
            return <Icon name="stateclosed" />;

        case SectionStatus.Dismantled:
            return <Icon name="statedismantled" />;

        default:
            return null;
    }
};

class SectionStatusQuickEdit extends Component {

    static propTypes = {
        status: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        onUpdate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            isSaving: false
        };
    }

    toggleList() {
        if (!this.state.isSaving) {
            this.setState(prevState => ({
                listOpen: !prevState.listOpen
            }));
        }
    }

    handleClickOutside() {
        if (this.state.listOpen) {
            this.setState({
                listOpen: false
            });
        }
    }

    showStatusUpdateFailedDueToInvalidSpecError() {
        const text = `
            Der Status des Gerüstabschnitts kann nicht geänert werden, da seine Spezifikation 
            unvollständig ist. 
            Zum Vervollständigen der Spezifikation öffnen Sie bitte das Gerüstabschnitts-Formular 
            über den "Bearbeiten"-Button im Seitenkopf. 
            Hier bekommen Sie nach dem setzen des gewünschten Status eine Hilfestellung zum 
            Ausfüllen der notwendigen Felder.
        `;

        showTempError(text, 10000)
    }

    updateStatus(status) {
        const { id, actions: { updateSectionStatus }, onUpdate } = this.props;
        this.setState({ isSaving: true });
        updateSectionStatus(id, status)
            .then((data) => onUpdate(data.section.status, data.section.publicStatus))
            .catch(err => {
                if(
                    err instanceof GraphQLError
                    && err.errorCategory === 'api_exception'
                    && err.errorCode === 'model_validation_exception'
                ) {
                    this.showStatusUpdateFailedDueToInvalidSpecError();
                } else {
                    showRequestError('Status konnte nicht aktualisiert werden', err)
                }
            })
            .finally(() => this.setState({ isSaving: false }));
    }

    renderSectionStatusList() {

        const links = Object.entries(SectionStatus)
            .filter(([key, value]) => value !== SectionStatus.Unknown)
            .map(([key, value]) => {
                return {
                    label: getStatusLabel(value),
                    value: value
                };
            });

        return links.map(link => {
            const icon = getStatusIcon(link.value);
            return (
                <li key={link.label}>
                    <div
                        onClick={() => {
                            this.setState({ listOpen: false });
                            this.updateStatus(link.value);
                        }}
                        className={`sectionStatusDropdown__menu-item ${!!icon && 'has-icon'}`}
                    >
                        {!!icon &&
                        <div
                            className={`sectionStatusDropdown__menu-item-icon has-status-${link.value}`}>
                            {icon}
                        </div>
                        }
                        {link.label}
                    </div>
                </li>
            );
        });
    }

    render() {
        const { listOpen, isSaving } = this.state;
        const { status } = this.props;

        const label = getStatusLabel(status);
        const icon = getStatusIcon(status);
        const hasIcon = !!icon;

        return (
            <div className="sectionStatusDropdown">
                <button
                    className={`btn sectionStatusDropdown__current sectionStatusDropdown__current--${status} ${hasIcon && 'has-icon'}`}
                    onClick={() => this.toggleList()} role="button">
                    {hasIcon &&
                    <div className="sectionStatusDropdown__status-icon">
                        {icon}
                    </div>}
                    {label}
                    <div className="sectionStatusDropdown__chevron">
                        <Icon name={!listOpen ? 'chevronDown' : 'chevronUp'} />
                    </div>
                    {isSaving &&
                    <LoadingSpinner block />}
                </button>

                {listOpen &&
                <ul className="sectionStatusDropdown__menu">
                    {this.renderSectionStatusList()}
                </ul>
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateSectionStatus
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(onClickOutside(SectionStatusQuickEdit));
