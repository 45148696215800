import PropTypes from 'prop-types';
import React from 'react';

const NotAvailablePlaceholder = ({ label }) => (
    <span className="notAvailablePlaceholder">{label}</span>
);

NotAvailablePlaceholder.protTypes = {
    label: PropTypes.string
};

NotAvailablePlaceholder.defaultProps = {
    label: 'n.a.'
};

export default NotAvailablePlaceholder;
