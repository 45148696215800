import React from 'react';
import Address from '../../Address';
import ContactPerson from '../../ContactPerson';
import MultiLineText from '../../MultiLineText';
import { DiffItemRenderType } from '../properties';
import { MediaDiffValue } from './MediaDiffValue';
import { MediaDiffValueItem } from './MediaDiffValueItem';

export const DiffValueFallback = '-';

export const DiffValue = ({ value, diff, fallback }) => {
    switch (diff.renderType) {
        case DiffItemRenderType.Address:
            return value ? <Address address={value} /> : fallback;
        case DiffItemRenderType.ContactPerson:
            return value ? <ContactPerson contactPerson={value} /> : fallback;
        case DiffItemRenderType.Bool:
            return value ? 'Ja' : 'Nein';
        case DiffItemRenderType.ModelReference:
            return value && value.name || fallback;
        case DiffItemRenderType.Media:
            return <MediaDiffValue media={value} diff={diff} />;
        case DiffItemRenderType.MediaItem:
            return <MediaDiffValueItem mediaItem={value} diff={diff} />;
        case DiffItemRenderType.MultiLine:
            return <MultiLineText
                textLimit={300}
                isCollapsible
                text={value}
                emptyPlaceholder={fallback}
            />;
        default:
            return value ? value.toString() : fallback;
    }
}

DiffValue.defaultProps = {
    fallback: DiffValueFallback
}
