import PersonDTO from './PersonDTO';

export default class ContactPersonDTO extends PersonDTO{

    constructor() {
        super();
        this.isMainContact = '';
        this.position = '';
    }
}
