import moment from 'moment';
import 'moment/locale/de';
import PropTypes from 'prop-types';
import React from 'react';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';

const ScaffoldingServiceTime = ({ scheduledErection, scheduledDismantling }) => {

    if (!scheduledErection && !scheduledDismantling) {
        return <NotAvailablePlaceholder label="-"/>;
    }

    let start = <NotAvailablePlaceholder/>;
    let end = <NotAvailablePlaceholder/>;
    if (scheduledErection) {
        start = moment(scheduledErection)
            .format('DD.MM.YYYY');
    }
    if (scheduledDismantling) {
        end = moment(scheduledDismantling)
            .format('DD.MM.YYYY');
    }

    return <span className="scaffolding-service-time">{start} - {end}</span>;
};

ScaffoldingServiceTime.propTypes = {
    scheduledErection: PropTypes.string,
    scheduledDismantling: PropTypes.string,
};

ScaffoldingServiceTime.defaultProps = {
    scheduledErection: undefined,
    scheduledDismantling: undefined,
};

export default ScaffoldingServiceTime;
