import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getAccountType } from '../../../redux/helper';
import { AccountTypes } from '../subscriptionTypes';

const CreditCardBillingDeprecatedWarningBanner = ({ accountType }) => {
    if (accountType === AccountTypes.light) {
        return null;
    }

    return (
        <div className="warning-banner warning-banner__section-banner">
            Zum 14.09.2019 tritt die neue EU-Richtlinie “PSD2” in Kraft. Diese reguliert, wie Daten zwischen Banken und
            Internet-Diensten in Zukunft geteilt werden dürfen.
            <br />
            Bitte aktualisieren Sie Ihre Zahlungsdaten, indem Sie Ihre Kreditkarte neu
            hinterlegen. So können
            wir sicherstellen, dass Sie Scaffeye® auch in Zukunft wie gewohnt nutzen können.
        </div>
    );
};

const mapStateToProps = (state) => {
    const accountType = getAccountType(state);
    return {
        accountType
    };
};

export default withRouter(connect(mapStateToProps, null)(CreditCardBillingDeprecatedWarningBanner));
