import React from 'react';
import InputGroup from '../../../Forms/InputGroup';
import Select from '../../../Forms/Select';

export const SectionContractorInput =
    ({
         managed,
         scaffoldingId,
         isFetchingContractors,
         contractorChoices,
         isConstructionPlan
     }) => {

        if (isConstructionPlan) {
            return null;
        }

        if (!scaffoldingId || managed || managed === undefined) {
            return null;
        }

        return (
            <InputGroup
                label="Gerüstersteller"
            >
                <Select
                    name="contractorId"
                    placeholder={isFetchingContractors ? 'Lade Gerüstersteller ...' : 'Gerüstersteller auswählen'}
                    options={contractorChoices}
                    disabled={isFetchingContractors}
                />
            </InputGroup>
        );
    };
