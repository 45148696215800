/**
 * Media Upload
 */

/**
 * Validation errors
 */
export const mimeError = fileTypes => `Dateityp wird nicht unterstützt. Bitte nur Dateien der Typen ${fileTypes} hochladen.`;

export const sizeError = size => `Datei zu groß. Die maximal erlaubte Dateigröße ist ${size}.`;

export const authorizationError = () => 'Sie sind nicht authorisiert eine Datei hochzuladen.';

// Fallback error
export const fallbackError = () => 'Die Datei ist zu groß.';

/**
 * Login Error
 */
export const invalidCredentials = () => 'Bitte überprüfen Sie ihre Anmeldedaten';

/**
 * Server error
 */
export const serverError = () => 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal. Wenn der Fehler wiederholt auftreten sollte, wenden Sie sich bitte an unseren Support.';


/**
 * Refresh token error
 */
export const invalidRefreshTokenError = () => 'Bitten melden Sie sich erneut mit Ihren Anmeldedaten an.';
