import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Icon';

const DiffTypeHeading = ({ icon, children, diffType }) => (
    <div className={`diff-type-heading diff-type-heading--${diffType}`}>
        <Icon name={icon} /> <span>{children}</span>
    </div>
);


export default DiffTypeHeading;


DiffTypeHeading.propTypes = {
    icon: PropTypes.string.isRequired,
    diffType: PropTypes.string.isRequired
};
