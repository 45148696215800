import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { deleteSingleDocument } from '../../redux/modules/documents/action';
import { showApiError } from '../../redux/modules/error/action';
import DeleteModal from '../Common/Modal/DeleteModal';

class DeleteDocumentLink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showConfirmationModal: false,
            isDeleting: false
        };

        this.renderConfirmationModal = this.renderConfirmationModal.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
    }

    deleteDocument() {

        const { id, name, onDeleted } = this.props;

        const {
            actions: { deleteSingleDocument }
        } = this.props;

        this.setState({
            isDeleting: true
        });

        deleteSingleDocument(id)
            .then(() => {
                toast.success(`Dokument '${name}' wurde gelöscht`, { autoClose: 2500 });
                return true;
            })
            .catch((error) => {
                showApiError(error);
            })
            .then((success) => {
                this.setState({
                    isDeleting: false
                });

                if (success) {
                    this.toggleConfirmationModal();
                    onDeleted();
                }
            });
    }

    toggleConfirmationModal() {
        this.setState(prevState => ({
            showConfirmationModal: !prevState.showConfirmationModal
        }));
    }

    renderConfirmationModal() {

        const {
            name, disabled
        } = this.props;

        if (!this.state.showConfirmationModal || disabled) {
            return null;
        }

        return (
            <DeleteModal
                modalTitle="Dokument löschen"
                deleteEntity={this.deleteDocument}
                closeModal={this.toggleConfirmationModal}
                isDeletingEntity={this.state.isDeleting}
            >
                <p>Sie sind im Begriff, das Dokument <a
                    onClick={this.closeModal}
                    className="btn--link-neutral"
                    role="button">{name}</a> zu löschen.
                </p>
                <p>Möchten Sie fortfahren?</p>
            </DeleteModal>
        );
    }

    renderLink() {
        const {
            link,
            label
        } = this.props;

        let { disabled } = this.props;

        disabled = disabled && this.state.isDeleting;

        if (link && typeof link === 'function') {
            return link(disabled, this.toggleConfirmationModal);
        }

        return (
            <a role="button" onClick={this.toggleConfirmationModal}>{label}</a>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLink()}
                {this.renderConfirmationModal()}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        deleteSingleDocument
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(DeleteDocumentLink);

DeleteDocumentLink.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onDeleted: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    link: PropTypes.func,
    label: PropTypes.string
};

DeleteDocumentLink.defaultProps = {
    disabled: false,
    link: null,
    label: null
};
