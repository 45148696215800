import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../properties';
import { ConstructionPlanReferenceMode } from '../../ConstructionPlan/properties';
import FormHint from '../FormHint';
import InputHint from '../InputHint';
import OptionGroup from '../OptionGroup';

function infoTextOnDisabledModeAll(type) {

    const firstInsertion = type === ModelType.Scaffolding ? ' bestimmtes Gerüst' : 'bestimmen Gerüstabschnitt';
    const secondInsertion = type === ModelType.Scaffolding ? 'Gerüste' : 'Gerüstabschnitte';

    return (
        <FormHint initial>
            Sie haben bereits mindestens ein {firstInsertion} im Baustellenplaner hinzugefügt. Daher
            steht Ihnen die Funktion “Alle bestehenden {secondInsertion} wählen” nicht mehr zur
            Verfügung.
        </FormHint>
    );
}

function getLabelForAll(type) {
    const insertion = type === ModelType.Scaffolding ? 'Gerüste' : 'Gerüstabschnitte';

    return (
        <Fragment>
            <span>
                Alle bestehenden {insertion} wählen
            </span>
            <InputHint>
                Alle bestehenden {insertion} dieser Baustelle werden ausgewählt.
                Die {insertion} können danach im
                Baustellenplaner
                individuell angepasst oder entfernt werden.
            </InputHint>
        </Fragment>
    );
}

function getLabelForSingle(type) {
    return type === ModelType.Scaffolding ? 'Bestimmtes Gerüst wählen' : 'Bestimmten Gerüstabschnitt wählen';
}

const ConstructionPlanAllOrSingleSelection = ({ type, disableModeAll }) => {
    return (
        <div className="constructionplan-allorsingle-selection">

            {
                disableModeAll && infoTextOnDisabledModeAll(type)
            }

            <OptionGroup
                name="selection"
                options={[
                    {
                        label: getLabelForAll(type, disableModeAll),
                        value: ConstructionPlanReferenceMode.All,
                        disabled: disableModeAll
                    },
                    {
                        label: getLabelForSingle(type),
                        value: ConstructionPlanReferenceMode.Single
                    }
                ]}
                type="radio"
            />
        </div>
    );
};

ConstructionPlanAllOrSingleSelection.propTypes = {
    type: PropTypes.oneOf([ModelType.Scaffolding, ModelType.Section]).isRequired,
    disableModeAll: PropTypes.bool
};

ConstructionPlanAllOrSingleSelection.defaultProps = {
    disableModeAll: false
};

export default ConstructionPlanAllOrSingleSelection;
