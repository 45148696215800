import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Icon from '../../../Components/Common/Icon';
import LoadingSpinner from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import { refreshToken } from '../../../redux/action';
import { verifyUser } from '../../../redux/modules/user/action';
import PublicPage from './PublicPage';

class VerifyTokenPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChecking: true,
            isValid: false
        };
    }

    componentWillMount() {
        const { t } = queryString.parse(this.props.location.search);

        this.props.actions.verifyUser(t)
            .then(() => {
                this.setState({
                    isValid: true,
                    isChecking: false
                });
            })
            .catch(() => {
                this.setState({
                    isValid: false,
                    isChecking: false
                });
            });
    }

    render() {

        const { isChecking, isValid } = this.state;
        const renderContent = () => {

            if (isValid) {
                return (
                    <React.Fragment>
                        <h1>
                    <span className="verification-success">
                        <Icon name="stateok" />
                    </span>Registrierung abgeschlossen
                        </h1>
                        <div className="section">
                            <div className="confirmation">
                                <p>Melden Sie sich jetzt mit Ihren Zugangsdaten bei Scaffeye®
                                    an.</p>
                                <Link className="btn btn--save" to="/login">Zur Anmeldung</Link>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    <h1>
                    <span className="verification-failure">
                        <Icon name="statealert" />
                    </span>Registrierung fehlgeschlagen
                    </h1>
                    <div className="section">
                        <div className="confirmation">
                            <p>Wir konnten Ihre Registrierung nicht abschliessen, da der Link, den
                                wir an Ihre E-Mail Adresse geschickt haben ungültig oder veraltet
                                ist.
                                Bitte wiederholen Sie Ihre Registrierung.
                            </p>
                            <Link to="/registration">Zur Registrierung</Link>
                        </div>
                    </div>
                </React.Fragment>
            );
        };

        return (
            <PublicPage>
                <TopNav isPublic />

                <div className="registration-form">

                    {isChecking ?
                        <LoadingSpinner />
                        :
                        renderContent()
                    }
                </div>
            </PublicPage>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        verifyUser,
        refreshToken
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(VerifyTokenPage);

