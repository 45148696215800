import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFormSectionModeActions from './ConstructionPlanFormSectionModeActions';

const ConstructionPlanFormSectionPlaceholder = ({ onAddNew, onAddExisting, sectionsLeftToReference }) => (
    <div className="constructionPlannerEmptyListPlaceholder">
        <ConstructionPlanFormSectionModeActions
            onAddNew={onAddNew}
            onAddExisting={onAddExisting}
            sectionsLeftToReference={sectionsLeftToReference}
        />
    </div>
);

ConstructionPlanFormSectionPlaceholder.propTypes = {
    onAddNew: PropTypes.func.isRequired,
    onAddExisting: PropTypes.func.isRequired,
    sectionsLeftToReference: PropTypes.bool
};

ConstructionPlanFormSectionPlaceholder.defaultProps = {
    sectionsLeftToReference: false
};

export default ConstructionPlanFormSectionPlaceholder;
