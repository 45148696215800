import PropTypes from 'prop-types';
import React from 'react';
import { getPurposeLabel } from '../../../../../Sections/labels';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceRow from '../../../Base/Input/SyncChoiceRow';
import SyncInputGroup from '../../../Base/Input/SyncInputGroup';
import { isIdenticalValue } from '../../../equals';
import { getSyncFormModelData } from '../../../form';
import { validateSingleChoiceComplete } from '../../../validation';
import { isConfigItemSelected } from '../../form';

const validateWorkPurpose = (value, planValue, liveValue, allValues, meta) => {

    const formModelData = getSyncFormModelData(allValues, meta.name);
    const { purposeGroups, workPurpose } = formModelData;
    if (typeof purposeGroups?.work_scaffolding === 'undefined' || typeof workPurpose === 'undefined') {
        return validateSingleChoiceComplete()(value, planValue, liveValue, allValues, meta);
    }

    const isWorkScaffolding = purposeGroups.work_scaffolding;
    const isSelected = isConfigItemSelected(value);
    if (!isWorkScaffolding && isSelected) {
        return 'Bitte wählen Sie den Verwendungszweck “Arbeitsgerüst” aus.'
    } else if (isWorkScaffolding && !isSelected) {
        return 'Bitte wählen Sie eine Arbeitsgerüst-Option aus.'
    }
};

const WorkPurposeSyncChoice = ({
                                   value: currentFormValue,
                                   planValue,
                                   liveValue,
                                   onChange,
                                   showIdentical
                               }) => {

    const getPurposeValueLabel = (option) => {
        if (!option) {
            return '-';
        }

        return getPurposeLabel(option);
    };

    const isSelected = (value) => {
        return value === currentFormValue;
    };

    const leftValue = planValue;
    const rightValue = liveValue;

    const isIdentical = isIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Arbeitsgerüst"
            subLevel
        >
            <SyncChoiceRow
                leftValue={leftValue}
                rightValue={rightValue}
                renderValue={getPurposeValueLabel}
                onSelect={onChange}
                isSelected={isSelected}
                isIdentical={isIdentical}
                showIdentical={showIdentical}
            />
        </SyncInputGroup>
    );
};

WorkPurposeSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.string,
    liveValue: PropTypes.string,
    value: PropTypes.any,
    showIdentical: PropTypes.bool
};

export default asSyncChoiceField(WorkPurposeSyncChoice, {
    allowNull: true,
    validate: validateWorkPurpose
});
