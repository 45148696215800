import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFormGroup from '../Common/ConstructionPlanFormGroup';
import ConstructionPlanFormScaffoldingModeActions
    from './ConstructionPlanFormScaffoldingModeActions';

const ConstructionPlanFormScaffoldingPlaceholder = ({ onAddNew, onAddExisting, scaffoldingsLeftToReference }) => (
    <ConstructionPlanFormGroup dashedBorder title="Gerüst">
        <div className="constructionPlannerEmptyListPlaceholder">
            <ConstructionPlanFormScaffoldingModeActions
                onAddNew={onAddNew}
                onAddExisting={onAddExisting}
                scaffoldingsLeftToReference={scaffoldingsLeftToReference}
            />
        </div>
    </ConstructionPlanFormGroup>
);

ConstructionPlanFormScaffoldingPlaceholder.propTypes = {
    onAddNew: PropTypes.func.isRequired,
    onAddExisting: PropTypes.func.isRequired,
    scaffoldingsLeftToReference: PropTypes.bool
};

ConstructionPlanFormScaffoldingPlaceholder.defaultProps = {
    scaffoldingsLeftToReference: true
};

export default ConstructionPlanFormScaffoldingPlaceholder;
