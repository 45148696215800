import { ModelMediaType } from '../../../Media/properties';
import { ConstructionPlanModelMode } from '../../properties';
import {
    extractFormModelById,
    getBasicInitialFormModelValues,
    getInitialFormField,
    getInitialFormValue
} from '../form';
import {
    getInitialMediaFormField,
    getInitialSingleMediaFormField,
    transformMediaItemToSyncMediaItem,
    transformMediaToFormValues,
    transformMediaToSyncMedia,
    transformSingleMediaToFormValues
} from '../media';
import { isIdenticalScaffoldingFormValue } from './equals';

const defaultScaffoldingDataFormValues = {
    name: null,
    description: null,
    serviceTime: {
        scheduledErection: null,
        scheduledDismantling: null
    },
    attachments: [],
    utilizationPlans: [],
    anchorProtocol: null
};

export const getInitialScaffoldingFormValues = (model, modelReferenceData, resolvedMedia) => {
    const scaffoldingValues = getBasicInitialFormModelValues(model);

    const ignoredProperties = [
        'archived',
        'scheduledDismantling',
        'scheduledErection'
    ];

    if (model.mode !== ConstructionPlanModelMode.Reference) {
        Object.keys(model.data)
            .filter(property => ignoredProperties.indexOf(property) < 0)
            .forEach(property => {
                const value = getInitialScaffoldingFormField(property, model, modelReferenceData, resolvedMedia);
                scaffoldingValues.data = {
                    ...scaffoldingValues.data,
                    ...value
                };
            });

        scaffoldingValues.data.serviceTime = getInitialServiceTimeFormValue(model, modelReferenceData);
    }

    return scaffoldingValues;
};

function getInitialServiceTimeFormValue(model, modelReferenceData) {

    const scheduledDismantling = getInitialFormValue('scheduledDismantling', model, modelReferenceData, isIdenticalScaffoldingFormValue);
    const scheduledErection = getInitialFormValue('scheduledErection', model, modelReferenceData, isIdenticalScaffoldingFormValue);

    if (!scheduledDismantling && !scheduledErection) {
        return undefined;
    }

    return {
        scheduledErection,
        scheduledDismantling
    };
}

const getInitialScaffoldingFormField = (property, model, modelReferenceData, resolvedMedia) => {
    switch (property) {
        case 'attachments':
            return getInitialMediaFormField(property, model, modelReferenceData, ModelMediaType.Scaffolding_Attachment, resolvedMedia);
        case 'utilizationPlans':
            return getInitialMediaFormField(property, model, modelReferenceData, ModelMediaType.Scaffolding_UtilizationPlan, resolvedMedia);
        case 'anchorProtocol':
            return getInitialSingleMediaFormField(property, model, modelReferenceData, ModelMediaType.Scaffolding_AnchorProtocol, resolvedMedia);
        case 'scheduledErection':
        case 'scheduledDismantling':
            // handled elsewhere
            return undefined;
        default:
            return getInitialFormField(property, model, modelReferenceData, isIdenticalScaffoldingFormValue);
    }
};

export const transformLiveScaffoldingToFormValues = (liveData, planData, resolvedMedia) => {
    return transformScaffoldingToFormValues(liveData, planData, resolvedMedia);
};

export const transformScaffoldingPlanToFormValues = (planData, liveData, resolvedMedia) => {
    return transformScaffoldingToFormValues(planData, liveData, resolvedMedia);
};

function transformScaffoldingToFormValues(_scaffoldingData, _refData, resolvedMedia) {

    const newFormValues = { ...defaultScaffoldingDataFormValues };

    const scaffoldingData = _scaffoldingData || {};
    const refData = _refData || {};

    const ignoredProperties = [
        'id',
        'archived',
        'sections'
    ];

    const customProperties = [
        'scheduledErection',
        'scheduledDismantling',
        'attachments',
        'utilizationPlans',
        'anchorProtocol'
    ];

    // Copy non-transform properties

    Object
        .keys(scaffoldingData)
        .filter(property => ignoredProperties.indexOf(property) < 0)
        .filter(property => customProperties.indexOf(property) < 0)
        .forEach(property => {
            newFormValues[property] = scaffoldingData[property];
        });

    /*
     * Custom properties
     */

    newFormValues.attachments = transformMediaToFormValues(
        scaffoldingData.attachments,
        refData.attachments,
        ModelMediaType.Scaffolding_Attachment,
        resolvedMedia
    );

    newFormValues.utilizationPlans = transformMediaToFormValues(
        scaffoldingData.utilizationPlans,
        refData.utilizationPlans,
        ModelMediaType.Scaffolding_UtilizationPlan,
        resolvedMedia
    );

    newFormValues.anchorProtocol = transformSingleMediaToFormValues(
        scaffoldingData.anchorProtocol,
        ModelMediaType.Scaffolding_AnchorProtocol,
        resolvedMedia
    );

    newFormValues.serviceTime = {
        scheduledErection: scaffoldingData.scheduledErection,
        scheduledDismantling: scaffoldingData.scheduledDismantling
    };

    return newFormValues;
}

/*
 * Sync data
 */

export const getScaffoldingSyncData = (values, constructionPlanScaffolding) => {

    const scaffoldingModel = extractFormModelById(values, constructionPlanScaffolding.id);
    const scaffoldingValues = scaffoldingModel.data;

    const {
        name,
        description,
        serviceTime,
        attachments,
        anchorProtocol,
        utilizationPlans
    } = scaffoldingValues;

    const scaffolding = {
        id: constructionPlanScaffolding.id,
        mode: constructionPlanScaffolding.mode,
        data: {},
        sections: {}
    };

    const mode = constructionPlanScaffolding.mode;
    if (mode === ConstructionPlanModelMode.Reference) {
        return scaffolding;
    }

    scaffolding.ignored = scaffoldingModel.ignored;

    scaffolding.data = {
        name,
        description,
        attachments: transformMediaToSyncMedia(attachments),
        utilizationPlans: transformMediaToSyncMedia(utilizationPlans),
        anchorProtocol: transformMediaItemToSyncMediaItem(anchorProtocol),
        scheduledErection: serviceTime?.scheduledErection || null,
        scheduledDismantling: serviceTime?.scheduledDismantling || null
    };

    return scaffolding;
};
