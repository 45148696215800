import * as types from '../../types';

const initialState = {
    newAppVersionIsAvailable: false
};

export default function serviceWorkerReducer(state = initialState, action) {
    switch (action.type) {
        case types.NEW_APP_VERSION_IS_AVAILABLE:
            return {
                ...state,
                newAppVersionIsAvailable: true
            };
        case types.NEW_APP_VERSION_IS_ACTIVATED:
            return {
                ...state,
                newAppVersionIsAvailable: false
            };
        default:
            return state;
    }
}
