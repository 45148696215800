import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { PersistGate } from 'redux-persist/lib/integration/react';
import WebFont from 'webfontloader';
import Root from './Containers/Root';
import configureStore from './redux/configureStore';
import registerServiceWorker from './registerServiceWorker';
import './style.css';
import { LOCAL_STORAGE_IS_NEW_APP_VERSION_AVAILABLE } from './properties';
import { isNewAppVersionAvailable } from './utils';
import { newVersionIsActivated, newVersionIsAvailable } from './redux/modules/serviceworker/action';

const { Store, persistor } = configureStore();

// triggered on new service worker is installed
const onUpdate = () => {
    // local storage is used to show banner in all tabs
    localStorage.setItem(LOCAL_STORAGE_IS_NEW_APP_VERSION_AVAILABLE, '1');
    // redux is used to re-render app and show banner immediate after new service worker is recognized
    Store.dispatch(newVersionIsAvailable());
};

// triggered on new service worker become active
const onActivate = () => {
    localStorage.removeItem(LOCAL_STORAGE_IS_NEW_APP_VERSION_AVAILABLE);
    Store.dispatch(newVersionIsActivated());
};

ReactDOM.render(
    <Provider store={Store}>
        <PersistGate persistor={persistor}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Root/>
            </StripeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker(onUpdate, onActivate, isNewAppVersionAvailable());

WebFont.load({
    google: {
        families: [ 'Source Sans Pro:300,400,600', 'sans-serif' ]
    }
});
