import PropTypes from 'prop-types';
import React from 'react';
import SectionDetail from '../../../Components/Sections/Detail/SectionDetail';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const SectionDetailPage = ({ match }) => (
    <PrivatePage requiredUserPermission={Permission.ReadSections}>
        <Container>
            <SectionDetail sectionId={match.params.id} />
        </Container>
    </PrivatePage>
);

export default SectionDetailPage;

SectionDetailPage.propTypes = {
    match: PropTypes.object
};

SectionDetailPage.defaultProps = {
    match: {}
};
