import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Breadcrumb from '../../Navigation/Breadcrumbs/Breadcrumb';
import {BreadcrumbLink} from '../../Navigation/Breadcrumbs/BreadcrumbLink';

const SectionBreadcrumb = ({section, collapse}) => {
    const query = section && section.archived ? 'archived=true' : '';
    const sect = section || {};
    const scaffolding = sect.scaffolding || {};
    const site = scaffolding.site || {};

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label={'Baustellen'}
                    path="/sites"
                    icon="scaffold"
                    query={query}
                />
                <BreadcrumbLink
                    label={site.name || 'Lade Baustelle ...'}
                    path={site.id ? `/sites/${site.id}` : null}
                />
                <BreadcrumbLink
                    label={scaffolding.name || 'Lade Gerüst ...'}
                    path={scaffolding.id ? `/scaffoldings/${scaffolding.id}` : null}
                />
                <BreadcrumbLink
                    label={sect.name || 'Lade Gerüstabschnitt ...'}
                    path={sect.id ? `/sections/${sect.id}` : null}
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default SectionBreadcrumb;

SectionBreadcrumb.propTypes = {
    section: PropTypes.object,
    collapse: PropTypes.bool,
};

SectionBreadcrumb.defaultProps = {
    section: null,
    collapse: false,
};
