import _ from 'lodash';
import { ModelMediaType } from '../../../Media/properties';
import { determinePurposeGroups } from '../../../Sections/functions';
import {
    EmergencyStairwayPurposes,
    PlatformStairwayPurposes,
    ProtectivePurposes,
    Purpose,
    SectionStatus,
    WorkPurposes
} from '../../../Sections/properties';
import { ConstructionPlanModelMode } from '../../properties';
import { extractFormModelById, getBasicInitialFormModelValues, getInitialFormField } from '../form';
import {
    getInitialMediaFormField,
    getInitialSingleMediaFormField,
    transformMediaItemToSyncMediaItem,
    transformMediaToFormValues,
    transformMediaToSyncMedia,
    transformSingleMediaToFormValues
} from '../media';
import { isIdenticalSectionFormValue } from './equals';

const DefaultSectionDataFormValues = {
    name: undefined,
    managed: true,
    status: undefined,
    equippedSurfaces: {},
    approvedByName: undefined,
    plannedWorks: {},
    purposeGroups: {
        work_scaffolding: false,
        protective_scaffolding: false,
        platform_stairway: false
    },
    workPurpose: undefined,
    protectivePurposes: {},
    platformStairwayPurpose: undefined,
    emergencyStairwayPurpose: undefined,
    specialConstruction: undefined,
    staticsCertificateNecessary: undefined,
    supportType: undefined,
    constructionType: undefined,
    loadClass: undefined,
    wallClearance: undefined,
    widthClass: undefined,
    claddings: {},
    specialFeatures: {},
    description: undefined,
    attachments: [],
    anchorProtocol: undefined,
    utilizationPlans: [],
    proofOfStability: undefined
};

const ObjectProperties = [
    'equippedSurfaces',
    'loadClass',
    'wallClearance',
    'widthClass',
    'plannedWorks',
    'claddings',
    'specialFeatures'
];

const SingleObjectProperties = [
    'loadClass',
    'wallClearance',
    'widthClass'
];

const BoolProperties = [
    'specialConstruction',
    'staticsCertificateNecessary'
];

export function getInitialSectionFormValues(model, modelReferenceData, resolvedMedia) {
    const sectionValues = getBasicInitialFormModelValues(model);

    if (model.mode !== ConstructionPlanModelMode.Reference) {
        Object.keys(model.data)
            .forEach(property => {
                const value = getInitialSectionFormField(property, model, modelReferenceData, resolvedMedia);
                sectionValues.data = {
                    ...sectionValues.data,
                    ...value
                };
            });

        sectionValues.data.managed = true;
        sectionValues.data.status = getInitialStatusFormField(model, modelReferenceData);
    }

    return sectionValues;
}

/**
 * For object types we copy all identical options as initial form value
 *
 * @param property
 * @param model
 * @param modelReferenceData
 * @return {{}}
 */
function getInitialObjectFormField(property, model, modelReferenceData) {
    const modelObject = (model && model.data && model.data[property]) || {};
    const modelReferenceObject = (modelReferenceData && modelReferenceData[property]) || {};
    const isNew = model.mode === ConstructionPlanModelMode.New;

    const allKeys = [
        ...Object.keys(modelObject),
        ...Object.keys(modelReferenceObject)
    ];

    const objectIntersection = {};
    allKeys.forEach(key => {
        const modelValue = modelObject[key];
        const refValue = modelReferenceObject[key];
        if(isNew || _.isEqual(modelValue, refValue)) {
            objectIntersection[key] = modelValue;
        }
    });

    if(_.isEmpty(objectIntersection)) {
        return {};
    }

    return {
        [property]: objectIntersection
    };
}

export const getInitialSectionFormField = (property, model, modelReferenceData, resolvedMedia) => {

    if(ObjectProperties.indexOf(property) >= 0) {
        return getInitialObjectFormField(property, model, modelReferenceData);
    }

    switch (property) {
        case 'attachments':
            return getInitialMediaFormField(property, model, modelReferenceData, ModelMediaType.Section_Attachment, resolvedMedia);
        case 'utilizationPlans':
            return getInitialMediaFormField(property, model, modelReferenceData, ModelMediaType.Section_UtilizationPlan, resolvedMedia);
        case 'anchorProtocol':
            return getInitialSingleMediaFormField(property, model, modelReferenceData, ModelMediaType.Section_AnchorProtocol, resolvedMedia);
        case 'proofOfStability':
            return getInitialSingleMediaFormField(property, model, modelReferenceData, ModelMediaType.Section_ProofOfStability, resolvedMedia);
        case 'purposes':
            return getInitialPurposesFormFields(model, modelReferenceData);
        case 'otherProtectivePurpose':
            // handled in getInitialPurposesFormFields
            return {};
        default:
            return getInitialFormField(property, model, modelReferenceData, isIdenticalSectionFormValue);
    }
};

const getInitialStatusFormField = (model, modelReferenceData) => {
    if (model.mode === ConstructionPlanModelMode.New) {
        return SectionStatus.Draft;
    } else if (model.mode === ConstructionPlanModelMode.Edit) {
        if (modelReferenceData && modelReferenceData.status === SectionStatus.Draft) {
            return SectionStatus.Draft;
        }

        return SectionStatus.BeingAltered;
    }

    return undefined;
};

/**
 * Populates all section purpose based form fields
 *
 * @param model
 * @param modelReferenceData
 * @return {{protectivePurposes: {}}}
 */
const getInitialPurposesFormFields = (model, modelReferenceData) => {

    const modelPurposes = model?.data?.purposes || [];
    const modelReferencePurposes = modelReferenceData?.purposes || [];

    let purposes = modelPurposes;
    if (model.mode !== ConstructionPlanModelMode.New) {
        purposes = _.intersection(modelPurposes, modelReferencePurposes);
    }

    const modelOtherProtectivePurpose = model?.data?.otherProtectivePurpose || null;
    const modelReferenceOtherProtectivePurpose = modelReferenceData?.otherProtectivePurpose || null;

    let otherProtectivePurpose = null;
    if (modelOtherProtectivePurpose === modelReferenceOtherProtectivePurpose) {
        otherProtectivePurpose = modelOtherProtectivePurpose;
    }

    return {
        purposeGroups: getPurposeGroupsFormValue(purposes),
        ...getPurposesFormValue(purposes, otherProtectivePurpose),
    }
};

export const getPurposeGroupsFormValue = (purposes) => {

    const formValue = {};
    if (!purposes || purposes.length === 0) {
        return formValue;
    }

    /*
     * Set groups
     */
    const groups = determinePurposeGroups(purposes);
    return groups.reduce((formValue, group) => {
        formValue[group] = true;
        return formValue;
    }, formValue);
};

export const getPurposesFormValue = (purposes, otherProtectivePurpose) => {

    const formValue = {
        protectivePurposes: {},
    };

    if (!purposes || purposes.length === 0) {
        return formValue;
    }

    /*
     * Iterate over purposes and add them to the corresponding bucket
     */
    purposes.forEach(purpose => {
        if (WorkPurposes.indexOf(purpose) >= 0) {
            formValue.workPurpose = purpose;
        } else if (ProtectivePurposes.indexOf(purpose) >= 0) {
            formValue.protectivePurposes[purpose] = true;

            if (purpose === Purpose.OtherProtectiveScaffolding) {
                formValue.protectivePurposes[purpose] = otherProtectivePurpose
            }
        } else if (PlatformStairwayPurposes.indexOf(purpose) >= 0) {
            formValue.platformStairwayPurpose = purpose;
        } else if (EmergencyStairwayPurposes.indexOf(purpose) >= 0) {
            formValue.emergencyStairwayPurpose = purpose;
        }
    });

    return formValue;
};

export function transformSectionToFormValues (_sectionData, _sectionRefData, resolvedMedia) {

    const newFormValues = { ...DefaultSectionDataFormValues };

    const sectionData = _sectionData || {};
    const sectionRefData = _sectionRefData || {};

    const ignoredProperties = [
        'id',
        'archived',
        'purposes',
        'otherProtectivePurpose',
        'attachments',
        'utilizationPlans',
        'anchorProtocol',
        'proofOfStability',
        ...ObjectProperties
    ];

    // Copy non-transform properties
    const mergedObjectKeys = [
        ...Object.keys(sectionData),
        ...Object.keys(sectionRefData),
    ];

    mergedObjectKeys
        .filter(property => ignoredProperties.indexOf(property) < 0)
        .forEach(property => {
            if(BoolProperties.indexOf(property) >= 0) {
                newFormValues[property] = sectionData[property] || false;
            } else {
                newFormValues[property] = sectionData[property] || null;
            }
        });

    /*
     * Object properties
     */
    ObjectProperties.forEach(property => {
        const value = sectionData[property] || {};
        const refValue = sectionRefData[property] || {};

        let keys;
        if (SingleObjectProperties.indexOf(property) >= 0) {
            keys = Object.keys(value);
        } else {
            keys = _.uniq([
                ...Object.keys(value),
                ...Object.keys(refValue)
            ]);
        }

        newFormValues[property] = keys.reduce((acc, key) => {
            acc[key] = value[key] || false;
            return acc;
        }, {});
    });

    /*
     * Custom properties which needs a bit of extra processing
     */

    // Purpose
    const purposeFormValue = transformPurposesToFormValues(sectionData, sectionRefData);
    Object
        .keys(purposeFormValue)
        .forEach(property => {
            newFormValues[property] = purposeFormValue[property];
        });

    // Media
    newFormValues.attachments = transformMediaToFormValues(
        sectionData.attachments,
        sectionRefData.attachments,
        ModelMediaType.Section_Attachment,
        resolvedMedia
    );

    newFormValues.utilizationPlans = transformMediaToFormValues(
        sectionData.utilizationPlans,
        sectionRefData.utilizationPlans,
        ModelMediaType.Section_UtilizationPlan,
        resolvedMedia
    );

    newFormValues.anchorProtocol = transformSingleMediaToFormValues(
        sectionData.anchorProtocol,
        ModelMediaType.Section_AnchorProtocol,
        resolvedMedia
    );

    newFormValues.proofOfStability = transformSingleMediaToFormValues(
        sectionData.proofOfStability,
        ModelMediaType.Section_ProofOfStability,
        resolvedMedia
    );

    return newFormValues;
}

export function transformPurposesToFormValues(planData, liveData) {

    const planPurposes = planData?.purposes || [];
    const livePurposes = liveData?.purposes || [];

    const otherProtectivePurpose = planData?.otherProtectivePurpose || null;

    const allPurposes = _.uniq([
        ...planPurposes,
        ...livePurposes
    ]);

    // Create a merged form value
    const formValues = getPurposesFormValue(allPurposes, otherProtectivePurpose);

    // Reset all values which the user did not plan

    // Work purpose
    const planWorkPurposes = _.intersection(planPurposes, WorkPurposes);
    if (planWorkPurposes.length === 0) {
        formValues.workPurpose = null;
    }

    // Protective purposes
    if (formValues.protectivePurposes) {
        Object.keys(formValues.protectivePurposes)
            .forEach(purpose => {
                formValues.protectivePurposes[purpose] = planPurposes && planPurposes.indexOf(purpose) >= 0;

                if (purpose === Purpose.OtherProtectiveScaffolding) {
                    formValues.protectivePurposes[purpose] = otherProtectivePurpose;
                }
            });
    }

    // Platform stairway purpose
    const platformStairwayPurposes = _.intersection(planPurposes, PlatformStairwayPurposes);
    if (platformStairwayPurposes.length === 0) {
        formValues.platformStairwayPurpose = null;
    }

    // Emergency stairway purpose
    const emergencyStairwayPurposes = _.intersection(planPurposes, EmergencyStairwayPurposes);
    if (emergencyStairwayPurposes.length === 0) {
        formValues.emergencyStairwayPurpose = null;
    }

    /*
     * Determine and set groups
     */
    const purposeGroupsFV = getPurposeGroupsFormValue(allPurposes);
    const planPurposeGroups = determinePurposeGroups(planPurposes);

    Object
        .keys(purposeGroupsFV)
        .forEach(purposeGroupKey => {
            purposeGroupsFV[purposeGroupKey] = planPurposeGroups.indexOf(purposeGroupKey) >= 0;
        })

    formValues.purposeGroups = purposeGroupsFV;

    return formValues;
}

/*
 * Sync data
 */

export const getSectionSyncObject = (values, sectionPlan) => {

    const sectionFormState = extractFormModelById(values, sectionPlan.id);

    const syncSection = {
        id: sectionPlan.id,
        mode: sectionPlan.mode,
        data: {}
    };

    const mode = sectionPlan.mode;
    if (mode === ConstructionPlanModelMode.Reference) {
        return syncSection;
    }

    syncSection.ignored = sectionFormState.ignored;

    syncSection.data = getSectionDataFromSyncFormValues(sectionFormState.data);

    return syncSection;
}

export function getSectionDataFromSyncFormValues(values = {}) {

    const {
        purposeGroups,
        workPurpose,
        protectivePurposes,
        platformStairwayPurpose,
        emergencyStairwayPurpose,
        attachments,
        utilizationPlans,
        anchorProtocol,
        proofOfStability
    } = values;

    const syncData = {};

    const excludedProperties = [
        'purposeGroups',
        'workPurpose',
        'protectivePurposes',
        'platformStairwayPurpose',
        'emergencyStairwayPurpose',
        'attachments',
        'utilizationPlan',
        'anchorProtocol',
        'proofOfStability'
    ];

    Object.keys(values)
        .filter(property => excludedProperties.indexOf(property) < 0)
        .forEach(property => {
            syncData[property] = values[property];
        });

    // Media
    syncData.attachments = transformMediaToSyncMedia(attachments);
    syncData.utilizationPlans = transformMediaToSyncMedia(utilizationPlans);
    syncData.anchorProtocol = transformMediaItemToSyncMediaItem(anchorProtocol);
    syncData.proofOfStability = transformMediaItemToSyncMediaItem(proofOfStability);

    /*
     * Purposes
     */
    syncData.purposes = [];

    // Work
    if (workPurpose && purposeGroups?.work_scaffolding) {
        syncData.purposes = [
            ...syncData.purposes,
            workPurpose
        ];
    }

    // Protective
    if (protectivePurposes && purposeGroups?.protective_scaffolding) {
        const protectivePurposeValues = Object
            .keys(protectivePurposes)
            .filter(purpose => protectivePurposes[purpose]);

        syncData.purposes = [
            ...syncData.purposes,
            ...protectivePurposeValues
        ];

        if (protectivePurposeValues.indexOf(Purpose.OtherProtectiveScaffolding) >= 0) {
            syncData.otherProtectivePurpose = protectivePurposes.other_protective_scaffolding;
        }
    }

    // Ladder ascent stairway
    if (purposeGroups?.ladder_ascent) {
        syncData.purposes = [
            ...syncData.purposes,
            Purpose.LadderAscent
        ];
    }

    // Platform stairway
    if (platformStairwayPurpose && purposeGroups?.platform_stairway) {
        syncData.purposes = [
            ...syncData.purposes,
            platformStairwayPurpose
        ];
    }

    // Site staircase
    if(purposeGroups?.site_staircase) {
        syncData.purposes = [
            ...syncData.purposes,
            Purpose.SiteStaircase
        ];
    }

    // Emergency stairway
    if (emergencyStairwayPurpose && purposeGroups?.emergency_stairway) {
        syncData.purposes = [
            ...syncData.purposes,
            emergencyStairwayPurpose
        ];
    }

    syncData.specialConstruction = values.specialConstruction || false;
    syncData.staticsCertificateNecessary = values.staticsCertificateNecessary || false;

    //remove all properties of objects which has value false, because this means property is desleted
    Object.keys(syncData)
        .forEach(property => {
            const value = syncData[property];
            if (value && _.isObject(value) && !Array.isArray(value) && Object.keys(value).length) {

                if (property === 'attachments' || property === 'utilizationPlans') {
                    syncData[property] = value.map(val => {
                        Object.keys(val)
                            .forEach(valueProperty => {
                                if (!val[valueProperty]) {
                                    delete val[valueProperty];
                                }
                            });
                        return val;
                    });

                    return;
                }

                const cleanedValue = { ...value };

                Object.keys(cleanedValue)
                    .forEach(valueProperty => {
                        if (!cleanedValue[valueProperty]) {
                            delete cleanedValue[valueProperty];
                        }
                    });
                syncData[property] = cleanedValue;
            }
        });

    return syncData;
}

/**
 * Returns true if a config item object is selected:
 *
 * { myConfig: true } => true
 * { myConfig: false } | null | {}  => false
 *
 * @param configItemObject
 * @return {boolean}
 */
export function isConfigItemSelected(configItemObject) {
    if(!configItemObject || _.isEmpty(configItemObject)) {
        return false;
    }

    return Object.keys(configItemObject)
        .filter(key => configItemObject[key]).length > 0;
}
