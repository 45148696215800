import React, { Component } from 'react';
import InspectionHistoryTable from './InspectionHistoryTable';
import PageTitle from '../Page/PageTitle';

class InspectionHistory extends Component {
    render() {
        return (
            <React.Fragment>
                <PageTitle
                    title="Gesamte Prüfhistorie"
                />
                <InspectionHistoryTable sectionId={this.props.sectionId} />
            </React.Fragment>
        );
    }
}

export default InspectionHistory;
