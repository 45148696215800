import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import UpdateAvailableBanner from '../Common/UpdateAvailableBanner';
import MainNavigation from '../Navigation/MainNavigation';
import SecondaryNavigation from '../Navigation/SecondaryNavigation';
import ScaffeyeLogo from './ScaffeyeLogo';

const TopNav = ({ hasNewPageHead, isPublic, hide, newAppVersionIsAvailable }) => {

    return (
        <Fragment>
            <div
                className={`top-nav ${isPublic ? 'top-nav--public' : ''} ${hasNewPageHead ? 'no-shadow' : ''} ${hide ? 'hide' : ''}`}
            >
                <div className="top-nav__ct">

                    <div className="top-nav__logo-wrapper">
                        <ScaffeyeLogo />
                    </div>
                    {
                        !isPublic && <div className="top-nav__main-navigation-wrapper">
                            <MainNavigation />
                        </div>
                    }
                    {
                        !isPublic &&
                        <div className="top-nav__secondary-navigation-wrapper">
                            <SecondaryNavigation />
                        </div>
                    }
                </div>
            </div>
            {!isPublic && newAppVersionIsAvailable && <UpdateAvailableBanner />}
        </Fragment>
    );
};

TopNav.propTypes = {
    isPublic: PropTypes.bool,
    hasNewPageHead: PropTypes.bool,
    hide: PropTypes.bool
};

TopNav.defaultProps = {
    isPublic: false,
    hasNewPageHead: false,
    hide: false
};

const mapStateToProps = currentState => {

    const { serviceworker } = currentState;
    const newAppVersionIsAvailable = serviceworker.newAppVersionIsAvailable;

    return {
        newAppVersionIsAvailable
    };
};

export default connect(mapStateToProps, null)(TopNav);
