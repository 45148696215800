import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import FieldError from '../../Forms/FieldError';
import styles from './styles';

const ActionTypes = {
    clear: 'clear',
    createOption: 'create-option',
    deselectOption: 'deselect-option',
    popValue: 'pop-value',
    removeValue: 'remove-value',
    selectOption: 'select-option',
    setValue: ' set-value'
};
const ReactSelectAdapter = ({ input, meta, createLabel, isClearable, formatSelection, components, selectStyles, placeholder, loadingMessage, noOptionsMessage, loadOptions }) => {

    const internalOnChange = (selectedOption, action) => {

        let formatedSelection;

        if (action === ActionTypes.clear) {
            formatedSelection = {};
        } else {
            formatedSelection = formatSelection(selectedOption, action);
        }

        input.onChange(formatedSelection);

    };

    const transformFormValueToSelectOption = (value) => {

        if (!value) {
            return null;
        }

        return {
            label: value.name,
            value: value.name
        };
    };

    return (
        <Fragment>
            <AsyncCreatableSelect
                onChange={internalOnChange}
                loadOptions={loadOptions}
                value={transformFormValueToSelectOption(input.value)}
                className="react-select"
                classNamePrefix="react-select"
                isClearable={isClearable}
                cacheOptions
                styles={{...styles, ...selectStyles}}
                defaultOptions
                backspaceRemovesValue={false}
                loadingMessage={loadingMessage}
                noOptionsMessage={noOptionsMessage}
                placeholder={placeholder}
                formatCreateLabel={createLabel}
                components={components}
            />
            <FieldError meta={meta} />
        </Fragment>
    );
};

const AsyncCreatableSelectField = ({ ...props }) => (
    <Field
        component={ReactSelectAdapter}
        {...props}
    />
);

AsyncCreatableSelectField.propTypes = {
    components: PropTypes.object,
    selectStyles: PropTypes.object,
    getInitialValue: PropTypes.func
};

AsyncCreatableSelectField.defaultProps = {
    components: null,
    selectStyles: {}
};

export default AsyncCreatableSelectField;
