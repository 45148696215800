import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchSubscriptionDetails } from '../../../redux/modules/account/action';
import { showApiError } from '../../../redux/modules/error/action';
import { getBillingData, getPaymentMethod } from '../../../redux/modules/user/action';
import DataRow from '../../Common/DataRow';
import Icon from '../../Common/Icon';
import IfUser from '../../Common/IfUser';
import Section from '../../Layout/Section';
import LoadingSpinner, {PageLoadingSpinner} from '../../Page/LoadingSpinner';
import InvoiceBillingDeprecatedWarningBanner from '../Billing/InvoiceBillingDeprecatedWarningBanner';
import { BillingTypes } from '../BillingDetailsForm';
import SubscriptionStatus from './SubscriptionStatus';
import { AccountTypes } from '../subscriptionTypes';
import CreditCardInfo from '../../Common/CreditCardInfo';
import SepaInfo from '../../Common/SepaInfo';
import CreditCardBillingDeprecatedWarningBanner from '../Billing/CreditCardBillingDeprecatedWarningBanner';

class ManageSubscription extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
    }

    componentWillMount() {
        this.setState({
            isFetching: true
        });

        const { actions: { fetchSubscriptionDetails, getBillingData, getPaymentMethod } } = this.props;

        const billingDataRequest = getBillingData();
        const subscriptionDetailsRequest = fetchSubscriptionDetails();

        Promise.all([billingDataRequest, subscriptionDetailsRequest])
            .then(([billingData, subscriptionDetails]) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                const isLegacy = billingData.type === BillingTypes.Card && !!billingData.id;
                const shouldLoadNewInformation = billingData.type === BillingTypes.Card && !billingData.id;
                this.setState({
                    subscriptionDetails: { ...subscriptionDetails },
                    billingInformation: billingData,
                    isFetching: shouldLoadNewInformation,
                    isLegacyPaymentInfo: isLegacy
                });

                if (shouldLoadNewInformation) {
                    return getPaymentMethod()
                        .then(paymentInformation => this.setState({
                            billingInformation: paymentInformation,
                            isFetching: false
                        }));
                }

                return Promise.resolve();
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    renderBillingInformation() {
        const {
            billingInformation
        } = this.state;

        if (!billingInformation) {
            return null;
        }

        const { type } = billingInformation;

        if (type === BillingTypes.SepaDebit) {
            const { sepaDebit, owner } = billingInformation;
            if (!sepaDebit) {
                return null;
            }

            const ownerName = owner && owner.name;

            return (
                <SepaInfo style={{ marginBottom: 10 }} sepaLast4={sepaDebit.last4} sepaOwner={ownerName}/>
            );

        } else if (type === BillingTypes.Card) {
            const { card } = billingInformation;
            if (!card) {
                return null;
            }
            return (
                <CreditCardInfo style={{ marginBottom: 10 }} cardBrand={card.brand} cardLast4={card.last4}
                                cardExpMonth={card.expMonth} cardExpYear={card.expYear}/>
            );
        }
        return <div>Rechnung</div>;
    }

    render() {
        const {
            subscriptionDetails,
            billingInformation,
            isFetching,
            isLegacyPaymentInfo
        } = this.state;

        let accountType;

        if (subscriptionDetails) {
            accountType = subscriptionDetails.account ? subscriptionDetails.account.type : null;
        }

        if (isFetching) {
            return <PageLoadingSpinner />;
        }

        return (
            <React.Fragment>

                <InvoiceBillingDeprecatedWarningBanner billingInformation={billingInformation} showLink/>

                <Section
                    title="Abonnement"
                >
                    <SubscriptionStatus
                        details={subscriptionDetails}
                        type={accountType}
                    />
                </Section>
                {accountType !== AccountTypes.light &&
                <React.Fragment>
                    <Section
                        title="Zahlungsdetails"
                        cta={
                            <IfUser can={['update-billing']}>
                                <Link
                                    className="btn btn--neutral-action"
                                    to="/account/subscription/edit/billingdetails"
                                >
                                    <span className="btn__icon">
                                        <Icon name="edit"/>
                                    </span>
                                    <span>Bearbeiten</span>
                                </Link>
                            </IfUser>
                        }
                    >
                        <DataRow
                            label="Nächstes Rechnungsdatum"
                        >
                            {subscriptionDetails.account.mainSubscription && moment(subscriptionDetails.account.mainSubscription.nextInvoice)
                                .format('DD.MM.YYYY')} <br/>
                        </DataRow>

                        <DataRow
                            label="Zahlungsmethode"
                        >
                            {this.renderBillingInformation()}
                        </DataRow>
                        {
                            isLegacyPaymentInfo && <CreditCardBillingDeprecatedWarningBanner />
                        }
                    </Section>
                    <Section
                        title="Rechnungsadresse"
                        cta={
                            <IfUser can={['update-billing']}>
                                <Link
                                    className="btn btn--neutral-action"
                                    to="/account/subscription/edit/billingaddress"
                                >
                                    <span className="btn__icon">
                                        <Icon name="edit"/>
                                    </span>
                                    <span>Bearbeiten</span>
                                </Link>
                            </IfUser>
                        }
                    >
                        <DataRow
                            label="Firma"
                        >
                            {subscriptionDetails.account.billingAddress && subscriptionDetails.account.billingAddress.name}
                        </DataRow>

                        <DataRow
                            label="Adresse"
                        >
                            {subscriptionDetails.account.billingAddress &&
                            <div>
                                {subscriptionDetails.account.billingAddress.line1}<br/>
                                {subscriptionDetails.account.billingAddress.line2 && subscriptionDetails.account.billingAddress.line2}
                                <br/>
                                {subscriptionDetails.account.billingAddress.zip} {subscriptionDetails.account.billingAddress.city}
                            </div>
                            }
                        </DataRow>

                        <DataRow
                            label="USt.ID."
                        >
                            {subscriptionDetails.account.billingAddress.vatId}
                        </DataRow>
                    </Section>
                </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSubscriptionDetails,
        getBillingData,
        getPaymentMethod
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ManageSubscription);
