import PropTypes from 'prop-types';
import React from 'react';

class TableHiddenActions extends React.Component {

    renderChildren() {
        const { children } = this.props;
        return React.Children.map(children, (child, i) => (
            <span key={i} className="hidden-actions__action">
                {child}
            </span>
        ));
    }

    render() {
        return (
            <div className="name-column__actions">
                <div className="hidden-actions">
                    <div className="hidden-actions__bd">
                        {this.renderChildren()}
                    </div>
                    <div className="hidden-actions__dots">
                        <span className="hidden-actions__dot" />
                        <span className="hidden-actions__dot" />
                        <span className="hidden-actions__dot" />
                    </div>
                </div>
            </div>
        );
    }

}

export default TableHiddenActions;

TableHiddenActions.propTypes = {
    children: PropTypes.node.isRequired
};
