import React from 'react';
import { Field } from 'react-final-form';
import ConstructionPlanFormSite from './ConstructionPlanFormSite';

const ConstructionPlanFormSiteField = ({ onConstructionPlanFormSiteChange, ...fieldProps  }) => (
    <Field
        name="site"
        {...fieldProps}
        allowNull
    >
        {
            ({ input }) => {
                return (
                    <ConstructionPlanFormSite
                        site={input.value}
                        onChange={input.onChange}
                        onConstructionPlanFormSiteChange={onConstructionPlanFormSiteChange}
                    />
                )
            }
        }
    </Field>
);

export default ConstructionPlanFormSiteField;
