import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DateService from '../../../Services/DateService';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import IfUser from '../../Common/IfUser';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import PrimaryColumn from '../../Common/PrimaryColumn';
import { CustomerPageLink, RequestPageLink, SitePageLink } from '../../Common/ResourcePageLink';
import DeleteJobLink from '../DeleteJobLink';
import JobStatusQuickEdit from '../Form/JobStatusQuickEdit';
import { JobType } from '../properties';
import mapSectionsToScaffoldings, {
    getJobTypeByJobStatusString,
    renderOfferDeadLine,
    renderScaffoldings
} from '../utils';

export const RequestTableRow = ({ data: request, noDeletion, onChange, onDelete }) => {

    const internalOnStatusChange = (id, status) => {

        const changes = { status: status };

        if (getJobTypeByJobStatusString(status) === JobType.Job) {
            changes.type = JobType.Job;
        }

        onChange(request, changes);
    }

    const { customer, site, sections, isFirstOlderThanSevenDays, archived, archivedAt } = request;

    const scaffoldings = mapSectionsToScaffoldings(sections);
    const PageLink = RequestPageLink;

    return (
        <Fragment>
            {isFirstOlderThanSevenDays && !archived &&
            <tr className="requests-older-than-table-row">
                <td colSpan="7">
                    Älter als 7 Tage
                </td>
            </tr>
            }
            <tr key={request.id}>
                <PrimaryColumn
                    link={<PageLink id={request.id}
                    >{DateService.getReadableDate(request.created)}</PageLink>}
                >
                    <PageLink id={request.id}>
                        Details
                    </PageLink>
                    <IfUser
                        can={['delete-jobs']}
                        and={!noDeletion}
                    >
                        <DeleteJobLink
                            id={request.id}
                            name={request.name}
                            type={JobType.Request}
                            label="Entfernen"
                            archived={archived}
                            onDeleted={() => onDelete(request.id)}
                        />
                    </IfUser>
                </PrimaryColumn>
                {!archived &&
                <td>
                    {renderOfferDeadLine(request.offerDeadline, request.status)}
                </td>
                }
                {!archived &&
                <td>
                    <JobStatusQuickEdit
                        job={request}
                        onUpdate={internalOnStatusChange}
                    />
                </td>
                }
                <td>
                    {request.name ?
                        <Fragment> <span> {request.name}</span> {archived && <ArchivedBadge
                            hoverContent={`Archiviert am: ${DateService.getReadableDateTime(archivedAt)}`}
                            size="small"
                        />} </Fragment>
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
                <td>
                    {customer ?
                        <CustomerPageLink id={customer.id}>
                            {customer.name}
                        </CustomerPageLink>
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
                <td>
                    {site ?
                        <SitePageLink id={site.id}>
                            {site.name}
                            {site.archived && <ArchivedBadge size="small" />}
                        </SitePageLink>
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
                <td>
                    {scaffoldings ?
                        renderScaffoldings(scaffoldings)
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
            </tr>
        </Fragment>
    );
};


RequestTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
};

RequestTableRow.defaultProps = {
    onChange: (itemData, field, value) => false,
    onDelete: (itemId) => false
};
