import React from 'react';
import FormHint from '../../../Forms/FormHint';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';
import OptionGroup from '../../../Forms/OptionGroup';
import { Cladding, SectionStatus } from '../../properties';
import { SectionCladdingOptions } from '../options';

export const SectionCladdingInput = ({ status }) => (
    <InputGroup
        label="Gerüstverkleidungen"
    >
        {status === SectionStatus.Draft ?
            <OptionGroup
                name="claddings"
                options={SectionCladdingOptions}
                ifSelectionIs={[Cladding.FixedTrim]}
                thenRender={field => (
                    {
                        [Cladding.FixedTrim]: <Input
                            name="claddingsFixedTrim"
                            type="text"
                            placeholder='z.B. "Protect", "Scaffguard" oder "Holz"'
                        />
                    }[field]
                )}
                type="checkbox"
            />
            :
            <OptionGroup
                name="claddings"
                options={SectionCladdingOptions}
                ifSelectionIs={[Cladding.FixedTrim, Cladding.Net, Cladding.Tarp]}
                thenRender={field => (
                    {
                        [Cladding.FixedTrim]: <Input
                            name="claddingsFixedTrim"
                            type="text"
                            placeholder='z.B. "Protect", "Scaffguard" oder "Holz"*'
                        />,
                        [Cladding.Net]: (
                            <FormHint warning>
                                Bei Verwendung von
                                Netzen, muss die Option <strong>Verankerung</strong> (unter
                                Besonderheiten) gewählt oder
                                das Gerüst als <strong>freistehende
                                Gerüstkonstruktion</strong> (unter Besonderheiten) markiert
                                werden.
                            </FormHint>
                        ),
                        [Cladding.Tarp]: (
                            <FormHint warning>
                                Bei Verwendung einer &quot;Plane&quot;, muss die
                                Option <strong>&quot;Verankerung&quot;</strong> (unter
                                Besonderheiten) gewählt oder
                                das Gerüst als <strong>&quot;Freistehende
                                Gerüstkonstruktion&quot;</strong> (unter Besonderheiten)
                                markiert werden.
                            </FormHint>
                        )
                    }[field]
                )}
                type="checkbox"
            />
        }
    </InputGroup>
)
