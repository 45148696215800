import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Input from '../../../Components/Forms/Input';
import InputGroup from '../../../Components/Forms/InputGroup';
import Section from '../../../Components/Layout/Section';
import LoadingSpinner from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import { showApiError } from '../../../redux/modules/error/action';
import { loginUser } from '../../../redux/modules/user/action';
import {
    AccountSubscriptionExpiredError,
    ForbiddenError,
    UnauthorizedError,
    UserInactiveError,
    UserSubscriptionExpiredError
} from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { validateEmail } from '../../../utils';
import PublicFooter from './PublicFooter';
import PublicPage from './PublicPage';

const focusOnError = createDecorator();

class LoginPage extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isLoggingIn: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {

        const { actions: { loginUser } } = this.props;

        this.setState({
            isLoggingIn: true
        });

        const { from } = this.props.location.state || { from: { pathname: '/' } };

        return loginUser(values, from)
            .catch((error) => {
                this.setState({
                    isLoggingIn: false
                });

                if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
                    return ErrorService.handleUnauthorizedError(error, 'login')
                        .then(data => ({ [FORM_ERROR]: data.credentials }));
                }
                else if (error instanceof AccountSubscriptionExpiredError) {
                    return ErrorService.handleUnauthorizedError(error, 'login')
                        .then(() => ({ [FORM_ERROR]: 'Das SCAFFEYE-Abo ihres Untenehmens ist abgelaufen. Bitte kontaktieren Sie ihren SCAFFEYE-Konto-Administrator.' }));
                }
                else if (error instanceof UserSubscriptionExpiredError) {
                    return ErrorService.handleUnauthorizedError(error, 'login')
                        .then(() => ({ [FORM_ERROR]: 'Ihr SCAFFEYE-Abo ist abgelaufen. Bitte kontaktieren Sie den Administrator ihres SCAFFEYE-Kontos um es erneut zu aktivieren.' }));
                }
                else if (error instanceof UserInactiveError) {
                    return ErrorService.handleUnauthorizedError(error, 'login')
                        .then(data => ({[FORM_ERROR]: 'Ihr Konto wurde noch nicht freigeschaltet. Bitte schließen Sie den Registrierungsvorgang über die Bestätigungsemail ab oder kontaktieren Sie unseren Support.'}))
                }

                return showApiError(error);
            });
    };

    validate(values) {
        const errors = {};

        // E-Mail
        if (!values.email) {
            errors.email = 'Bitte geben Sie eine E-Mail Adresse ein';
        } else if (!validateEmail(values.email)) {
            errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein';
        }

        if (!values.password) {
            errors.password = 'Bitte geben Sie ein Passwort ein';
        }

        return errors;
    };

    renderErrorMessages() {
        return this.props.errors.map((error, index) => (
            <span key={index}>{error}</span>
        ));
    }

    render() {
        const { isLoggingIn } = this.state;

        return (
            <PublicPage>
                <TopNav isPublic />

                <div className="login-form">
                    <h1>Anmelden</h1>

                    <div className="warning-banner">
                        <p style={{ marginBottom: 0 }}>Scaffeye gibt es aktuell nur auf
                            Deutsch.<br /> Die englische Version ist
                            demnächst verfügbar.<br /><br />
                            Scaffeye is currently only available in German.<br /> The English
                            Version will
                            be available soon.</p>
                    </div>

                    <Section
                        title="Willkommen zurück"
                        classes="section--login"
                    >
                        <p>Melden Sie sich hier an, wenn Sie bereits ein Konto besitzen.</p>

                        <Form
                            onSubmit={this.onSubmit}
                            decorators={[focusOnError]}
                            validate={this.validate}
                            render={({ handleSubmit, submitError }) => (

                                <form onSubmit={handleSubmit}>
                                    {submitError && <div className="form-error">{submitError}</div>}
                                    <InputGroup
                                        label="E-Mail Adresse*"
                                    >
                                        <Input
                                            type="text"
                                            name="email"
                                            placeholder="E-Mail Adresse eingeben"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Passwort*"
                                    >
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder="Passwort eingeben"
                                        />
                                    </InputGroup>

                                    {this.props.errors && this.renderErrorMessages()}

                                    <div className="hints flex justify-content-between">
                                        <span>* Pflichtfeld</span>
                                        <Link to="/forgot-password">Passwort vergessen</Link>
                                    </div>

                                    <button
                                        type="submit"
                                        className="btn btn--primary-green btn--block"
                                        disabled={isLoggingIn}
                                    >
                                        {isLoggingIn ?
                                            <LoadingSpinner label="Anmelden" /> : 'Anmelden'}
                                    </button>

                                    <p> Sie haben noch kein Konto?
                                        <Link to="/registration">
                                            {} Jetzt registrieren
                                        </Link>
                                    </p>
                                </form>
                            )}
                        />
                    </Section>
                    <PublicFooter />
                </div>
            </PublicPage>
        );
    }
}

const mapStateToProps = state => ({
    isLoggingIn: state.currentUser.isLoggingIn
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ loginUser }, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));

LoginPage.propTypes = {
    actions: PropTypes.object
};

LoginPage.defaultProps = {
    actions: {
        loginUser: () => false
    }
};

