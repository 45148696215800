import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ModelType } from '../../../properties';
import { JobType } from '../../Job/properties';
import { getJobTypeByJobStatusString } from '../../Job/utils';
import { ScaffoldingMedia, SectionMedia, SiteMedia } from '../../Media/properties';
import { resolveMediaCollection, resolveMediaItem } from '../../Media/utils';
import { determinePurposeGroups } from '../../Sections/functions';
import {
    getJobPropertyLabel,
    getScaffoldingPropertyLabel,
    getSectionPropertyLabel,
    getSitePropertyLabel
} from './labels';
import {
    DiffItemRenderType,
    DiffType,
    jobDiffProperties,
    JobDiffProperty,
    JobDiffType,
    scaffoldingDiffProperties,
    ScaffoldingDiffProperty,
    sectionDiffProperties,
    SectionDiffProperty,
    siteDiffProperties,
    SiteDiffProperty
} from './properties';

/*
 * Helper functions
 */

export const isListOrObject = value => {
    // array || object
    return (typeof value === 'object');
};

export const isEmpty = value => {
    return (value === 0 || value === undefined || value === false || value === null || value === '' || (_.isArray(value) && !value.length) || (_.isObject(value) && _.isEmpty(value)));
};

export const normalizeValue = value => {
    if (_.isArray(value) && !value.length) {
        return false;
    }

    switch (value) {
        case 1:
        case true:
            return true;

        case 0:
        case undefined:
        case false:
        case null:
        case '':
            return false;

        default:
            return value;
    }
};

/*
 * Equality functions
 */

export const objectEquals = (newValue, oldValue) => {
    if (isEmpty(newValue) && isEmpty(oldValue)) {
        return true;
    }

    return _.isEqual(newValue, oldValue);
};

export const equals = (newValue, oldValue) => {

    if (newValue === oldValue) {
        return true;
    }

    if (isEmpty(newValue) && isEmpty(oldValue)) {
        return true;
    }

    const normalizedNewValue = normalizeValue(newValue);
    const normalizedOldValue = normalizeValue(oldValue);

    if (isListOrObject(normalizedNewValue) || isListOrObject(normalizedOldValue)) {
        return objectEquals(normalizedNewValue, normalizedOldValue);
    }

    return _.isEqual(normalizedNewValue, normalizedOldValue);
};

export function mediaEquals(newMedia, oldMedia) {
    if (isEmpty(newMedia) && isEmpty(oldMedia)) {
        return true;
    }

    return _.isEqual(newMedia, oldMedia, (newMediaValue, oldMediaValue, key) => {
        // Ignore mode
        if (key === 'mode') {
            return true;
        }
    });
}

export function addressEquals(newAddress, oldAddress) {
    return _.isEqual(getAddressDiffData(newAddress), getAddressDiffData(oldAddress));
}


/*
 * Diff functions
 */

export const getDiffType = (newValue, oldValue) => {

    if (isEmpty(newValue) && isEmpty(oldValue)) {
        return undefined;
    }

    // New Value
    if (newValue && isEmpty(oldValue)) {
        return DiffType.New;
    }

    // Deleted Value
    if (oldValue && isEmpty(newValue)) {
        return DiffType.Deleted;
    }

    // Changed Value
    return DiffType.Changed;
};

export function getAddressDiffData(address) {
    if (isEmpty(address)) {
        return null;
    }

    const { name, line1, line2, city, zip, country } = address;

    return {
        name: normalizeValue(name),
        line1: normalizeValue(line1),
        line2: normalizeValue(line2),
        zip: normalizeValue(zip),
        city: normalizeValue(city),
        country: isEmpty(country) ? 'de' : normalizeValue(country)
    };
}

export const getDiff = (modelType, newData, oldData, propertiesToCompare, equalsFn, propertyLabelFn, renderTypeFn, diffTypeFn) => {
    if (!propertiesToCompare || !propertiesToCompare.length) {
        return [];
    }

    return propertiesToCompare
        .map(property => {

            const newValue = newData ? newData[property] : undefined;
            const oldValue = oldData ? oldData[property] : undefined;

            if (equalsFn(newValue, oldValue, property)) {
                return null;
            }

            return {
                modelType,
                property,
                type: diffTypeFn ? diffTypeFn(property, newValue, oldValue) : getDiffType(newValue, oldValue),
                renderType: renderTypeFn(property),
                label: propertyLabelFn(property, modelType),
                newVal: newValue,
                oldVal: oldValue
            };
        })
        .filter(cle => cle);
};

/*
 * ===============================================
 *
 *          Site related diff functions
 *
 * ===============================================
 */

export const getSiteDiff = (newSiteData, oldSiteData, resolvedMedia) => {
    const diffDataNew = getSiteDiffData(newSiteData, resolvedMedia);
    const diffDataOld = getSiteDiffData(oldSiteData, resolvedMedia);

    return getDiff(
        ModelType.Site,
        diffDataNew,
        diffDataOld,
        siteDiffProperties,
        sitePropertyEquals,
        getSitePropertyLabel,
        getSitePropertyRenderType
    );
};

function getSiteDiffData(site, resolvedMedia) {
    if (!site) {
        return {};
    }

    const siteDiffData = { ...site };

    // Resolve attachments
    siteDiffData.attachments = resolveMediaCollection(ModelType.Site, site.id, SiteMedia.Attachments, site.attachments, resolvedMedia);

    return siteDiffData;
}

function sitePropertyEquals(newValue, oldValue, property) {

    if (property === SiteDiffProperty.Attachments) {
        return mediaEquals(newValue, oldValue);
    }

    if (property === SiteDiffProperty.Address) {
        return addressEquals(newValue, oldValue);
    }

    return equals(newValue, oldValue);
}

function getSitePropertyRenderType(property) {
    switch (property) {
        case SiteDiffProperty.Address:
            return DiffItemRenderType.Address;
        case SiteDiffProperty.Description:
            return DiffItemRenderType.MultiLine;
        case SiteDiffProperty.Attachments:
            return DiffItemRenderType.Media;
        default:
            return DiffItemRenderType.Default;
    }
}

/*
 * ===============================================
 *
 *       Scaffolding related diff functions
 *
 * ===============================================
 */

export const getScaffoldingDiff = (newScaffoldingData, oldScaffoldingData, resolvedMedia) => {
    const diffDataNew = getScaffoldingDiffData(newScaffoldingData, resolvedMedia);
    const diffDataOld = getScaffoldingDiffData(oldScaffoldingData, resolvedMedia);

    return getDiff(
        ModelType.Scaffolding,
        diffDataNew,
        diffDataOld,
        scaffoldingDiffProperties,
        scaffoldingPropertyEquals,
        getScaffoldingPropertyLabel,
        getScaffoldingPropertyRenderType
    );
};

function getScaffoldingDiffData(scaffolding, resolvedMedia) {
    if (!scaffolding) {
        return {};
    }

    const diffData = { ...scaffolding };

    // Scheduled service time
    const { scheduledErection, scheduledDismantling } = scaffolding;
    if (!scheduledErection && !scheduledDismantling) {
        diffData.scheduledServiceTime = 'n.a.';
    } else {
        const seLabel = scheduledErection ? moment(scheduledErection)
            .format('DD.MM.YYYY') : 'n.a.';
        const sdLabel = scheduledDismantling ? moment(scheduledDismantling)
            .format('DD.MM.YYYY') : 'n.a.';
        diffData.scheduledServiceTime = `${seLabel} - ${sdLabel}`;
    }
    delete diffData.scheduledErection;
    delete diffData.scheduledDismantling;

    // Resolve media
    diffData.attachments = resolveMediaCollection(ModelType.Scaffolding, scaffolding.id, ScaffoldingMedia.Attachments, scaffolding.attachments, resolvedMedia);
    diffData.utilizationPlans = resolveMediaCollection(ModelType.Scaffolding, scaffolding.id, ScaffoldingMedia.UtilizationPlans, scaffolding.utilizationPlans, resolvedMedia);
    diffData.anchorProtocol = resolveMediaItem(ModelType.Scaffolding, scaffolding.id, ScaffoldingMedia.AnchorProtocol, scaffolding.anchorProtocol, resolvedMedia);

    return diffData;
}

function scaffoldingPropertyEquals(newValue, oldValue, property) {

    if ([
        ScaffoldingDiffProperty.Attachments,
        ScaffoldingDiffProperty.UtilizationPlans,
        ScaffoldingDiffProperty.AnchorProtocol
    ].indexOf(property) >= 0) {
        return mediaEquals(newValue, oldValue);
    }

    return equals(newValue, oldValue);
}

function getScaffoldingPropertyRenderType(property) {
    switch (property) {
        case ScaffoldingDiffProperty.Description:
            return DiffItemRenderType.MultiLine;
        case ScaffoldingDiffProperty.Attachments:
        case ScaffoldingDiffProperty.UtilizationPlans:
            return DiffItemRenderType.Media;
        case ScaffoldingDiffProperty.AnchorProtocol:
            return DiffItemRenderType.MediaItem;
        default:
            return DiffItemRenderType.Default;
    }
}

/*
 * ===============================================
 *
 *          Section related diff functions
 *
 * ===============================================
 */

export const getSectionDiffs = (newSectionData, oldSectionData, resolvedMedia, isPlan) => {
    const diffDataNew = getSectionDiffData(newSectionData, resolvedMedia);
    const diffDataOld = getSectionDiffData(oldSectionData, resolvedMedia);

    const filteredSectionDiffProperties = isPlan ? sectionDiffProperties.filter(property => property !== 'status') : sectionDiffProperties;

    return getDiff(
        ModelType.Section,
        diffDataNew,
        diffDataOld,
        filteredSectionDiffProperties,
        sectionPropertyEquals,
        getSectionPropertyLabel,
        getSectionPropertyRenderType,
        getSectionDiffType
    );
};

function getSectionDiffType(property, newValue, oldValue) {

    if (property === SectionDiffProperty.Archived) {

        if (isEmpty(newValue) && isEmpty(oldValue)) {
            return undefined;
        }

        // Section Archived
        if (newValue && isEmpty(oldValue)) {
            return DiffType.Archived;
        }

        // Section Reactivated
        if (isEmpty(newValue) && oldValue) {
            return DiffType.Reactivated;
        }
    }

    return getDiffType(newValue, oldValue);

}

function getSectionDiffData(sectionData, resolvedMedia) {
    if (!sectionData) {
        return {};
    }

    const diffData = { ...sectionData };

    diffData.purpose = {};
    if (diffData.purposes && diffData.purposes.length) {
        diffData.purpose.groups = determinePurposeGroups(diffData.purposes);
        diffData.purpose.items = diffData.purposes;
    }
    delete diffData.purposes;

    if (diffData.otherProtectivePurpose) {
        diffData.purpose.otherProtective = diffData.otherProtectivePurpose;
    }
    delete diffData.otherProtective;

    diffData.stabilityAndLoadSafety = {};
    if(diffData.specialConstruction) {
        diffData.stabilityAndLoadSafety.specialConstruction = diffData.specialConstruction;
    }
    if(diffData.staticsCertificateNecessary) {
        diffData.stabilityAndLoadSafety.staticsCertificateNecessary = diffData.staticsCertificateNecessary;
    }
    delete diffData.specialConstruction;

    // Media
    diffData.attachments = resolveMediaCollection(ModelType.Section, sectionData.id, SectionMedia.Attachments, sectionData.attachments, resolvedMedia);
    diffData.utilizationPlans = resolveMediaCollection(ModelType.Section, sectionData.id, SectionMedia.UtilizationPlans, sectionData.utilizationPlans, resolvedMedia);
    diffData.anchorProtocol = resolveMediaItem(ModelType.Section, sectionData.id, SectionMedia.AnchorProtocol, sectionData.anchorProtocol, resolvedMedia);
    diffData.proofOfStability = resolveMediaItem(ModelType.Section, sectionData.id, SectionMedia.ProofOfStability, sectionData.proofOfStability, resolvedMedia);

    return diffData;
}

function sectionPropertyEquals(newValue, oldValue, property) {

    // Site, Scaffolding and Contractor are objects
    if (
        property === SectionDiffProperty.Site
        || property === SectionDiffProperty.Scaffolding
        || property === SectionDiffProperty.Contractor
    ) {
        if(!newValue && !oldValue) {
            return true;
        }

        if(
            (newValue && !oldValue)
            || (!newValue && oldValue)) {
            return false;
        }

        return newValue.id === oldValue.id;
    }

    return equals(newValue, oldValue);
}

function getSectionPropertyRenderType(property) {
    switch (property) {
        case SectionDiffProperty.Description:
            return DiffItemRenderType.MultiLine;
        case SectionDiffProperty.Attachments:
        case SectionDiffProperty.UtilizationPlans:
            return DiffItemRenderType.Media;
        case SectionDiffProperty.AnchorProtocol:
        case SectionDiffProperty.ProofOfStability:
            return DiffItemRenderType.MediaItem;
        case SectionDiffProperty.Site:
        case SectionDiffProperty.Scaffolding:
        case SectionDiffProperty.Contractor:
            return DiffItemRenderType.ModelReference;
        case SectionDiffProperty.Archived:
            return DiffItemRenderType.Row;
        default:
            return DiffItemRenderType.Default;
    }
}

/*
 * ===============================================
 *
 *          Job related diff functions
 *
 * ===============================================
 */

function getJobPropertyRenderType(property) {
    switch (property) {
        case JobDiffProperty.Description:
            return DiffItemRenderType.MultiLine;
        case JobDiffProperty.Customer:
            return DiffItemRenderType.ModelReference;
        case JobDiffProperty.CustomerContact:
            return DiffItemRenderType.ContactPerson;
        default:
            return DiffItemRenderType.Default;
    }
}

function getJobDiffType(property, newValue, oldValue) {

    if (isEmpty(newValue) && isEmpty(oldValue)) {
        return undefined;
    }

    if (property === JobDiffProperty.Archived) {

        // Job Archived
        if (newValue && isEmpty(oldValue)) {
            return DiffType.Archived;
        }

        // Job Reactivated
        if (isEmpty(newValue) && oldValue) {
            return DiffType.Reactivated;
        }
    }

    if (property === JobDiffProperty.ConstructionPlan) {

        // CP created
        if (newValue && isEmpty(oldValue)) {
            return JobDiffType.ConstructionPlanCreated;
        }

        // CP deleted
        if (isEmpty(newValue) && oldValue) {
            return JobDiffType.ConstructionPlanDeleted;
        }

        // CP changed
        if (!isEmpty(newValue) && !isEmpty(oldValue) && !equals(newValue, oldValue)) {
            return JobDiffType.ConstructionPlanChanged;
        }

    }

    if (property === JobDiffProperty.Status) {

        // Job transformed to type "Auftrag"
        if ((newValue && getJobTypeByJobStatusString(newValue) === JobType.Job) && (oldValue && getJobTypeByJobStatusString(oldValue) === JobType.Request)) {
            return JobDiffType.JobTypeChangedToJob;
        }

        // Job transformed to type "Anfrage"
        if ((newValue && getJobTypeByJobStatusString(newValue) === JobType.Request) && (oldValue && getJobTypeByJobStatusString(oldValue) === JobType.Job)) {
            return JobDiffType.JobTypeChangedToRequest;
        }

        // Status Changed
        if (newValue !== oldValue) {
            return JobDiffType.StatusChanged;
        }

    }

    return getDiffType(newValue, oldValue);

}

function jobPropertyEquals(newValue, oldValue) {
    return equals(newValue, oldValue);
}

// if necessary, transform job data for comparison
function getJobDiffData(jobData, resolvedMedia) {
    if (!jobData) {
        return {};
    }

    const diffData = { ...jobData };

    diffData.constructionPlan = jobData.constructionPlan && jobData.constructionPlan.site;
    diffData.offerDeadline = jobData.offerDeadline ? moment(jobData.offerDeadline)
        .format('DD.MM.YYYY') : null;
    return diffData;
}

export const getJobDiffs = (newJobData, oldJobData, resolvedMedia) => {
    const diffDataNew = getJobDiffData(newJobData, resolvedMedia);
    const diffDataOld = getJobDiffData(oldJobData, resolvedMedia);

    return getDiff(
        ModelType.Job,
        diffDataNew,
        diffDataOld,
        jobDiffProperties,
        jobPropertyEquals,
        getJobPropertyLabel,
        getJobPropertyRenderType,
        getJobDiffType
    );
};
