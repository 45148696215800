export default class PersonDTO {

    constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phone = '';
        this.mobile= '';
    }
}
