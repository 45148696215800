import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import MediaSyncChoiceListItem from './MediaSyncChoiceListItem';

const MediaSyncChoiceDiffOptionRows = ({ planValue, modelMediaType, currentFormValue, liveValue, getOptionRows, onSelect }) => {

    const diffOptionRows = getOptionRows(planValue, liveValue);

    if (_.isEmpty(diffOptionRows)) {
        return null;
    }

    const internalSelect = (key, selection) => {

        let value = null;

        if(selection){
            value = {
                ...selection
            }
        }

        const updatedEntry = {
            [key] : value
        };
        onSelect(updatedEntry);
    };

    return diffOptionRows.map(optionRow => {

        const pV = optionRow.left && optionRow.left[optionRow.key] ? Object.values(optionRow.left)[0] : null;
        const lV = optionRow.right && optionRow.right[optionRow.key] ? Object.values(optionRow.right)[0] : null;

        return (
            <MediaSyncChoiceListItem
                planValue={pV}
                modelMediaType={modelMediaType}
                liveValue={lV}
                id={optionRow.key}
                key={optionRow.key}
                onSelect={(value) => internalSelect(optionRow.key, value)}
                currentFormValue={currentFormValue}
            />
        )
    });
};

MediaSyncChoiceDiffOptionRows.propTypes = {
    getOptionRows: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    modelMediaType:PropTypes.string.isRequired,
    currentFormValue: PropTypes.any,
    planValue: PropTypes.any,
    liveValue: PropTypes.any,
};


export default MediaSyncChoiceDiffOptionRows;
