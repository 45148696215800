import { getDiffOptionRows, getIdenticalOptionRows } from '../../../optionRowData';

export const getIdenticalMediaListOptionRows = (planValue, liveValue) => {
    const mediaOptions = Object.assign({}, planValue, liveValue);
    return getIdenticalOptionRows(mediaOptions, planValue, liveValue);
};

export const getDiffMediaListOptionRows = (planValue, liveValue) => {
    const mediaOptions = Object.assign({}, planValue, liveValue);
    return getDiffOptionRows(mediaOptions, planValue, liveValue);
};
