import React, { Fragment } from 'react';
import Condition from '../../../Forms/Condition';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import Select from '../../../Forms/Select';
import { SectionStatusOptions } from '../options';
import { isRequired } from '../validate';

export const SectionStatusInput = ({ values, isConstructionPlan }) => {

    if (isConstructionPlan) {
        return null;
    }

    if (!values.scaffoldingId || !values.managed) {
        return null;
    }

    return (
        <Fragment>
            <InputGroup
                label="Status"
                required={isRequired('status', values)}
            >
                <Select
                    name="status"
                    disabled={!values.managed}
                    options={SectionStatusOptions}
                />
                <Condition
                    when="status"
                    is="draft"
                >
                    <InputHint important>
                        <strong>Hinweis: </strong>Gerüstabschnitte, die
                        sich &#34;In Planung&#34; befinden, können nicht
                        geprüft oder freigegeben werden und besitzen neben
                        den allgemeinen Angaben
                        (Bezeichnung, Baustelle, Gerüst) keine weiteren
                        Pflichtfelder. Sobald Sie den Gerüstabschnitt in einen
                        anderen Status wechseln, müssen Sie zusätzliche
                        Angaben zur Konfiguration und Spezifikation Ihres
                        Gerüstabschnittes hinterlegen, um diesen abzuspeichern
                        und zur Prüfung oder Freigabe zuzulassen.
                    </InputHint>
                </Condition>
            </InputGroup>
        </Fragment>
    );
}
