import PropTypes from 'prop-types';
import React from 'react';

const Table = ({
    columns,
    rows,
    children,
    emptyComponent,
    wrapperClass,
    footer
}) => {
    const renderTableHeader = () => columns.map((col, i) => <th key={i}>{col}</th>);

    const renderTableBody = () => (rows && rows.length > 0 ? rows.map((row, i) => children(row, i)) : emptyComponent);

    const wrapperClasses = ['content-table'];

    if (wrapperClass) {
        wrapperClasses.push(wrapperClass);
    }

    return (
        <div className={wrapperClasses.join(' ')}>
            <table cellPadding="0" cellSpacing="0" className="hover stack">
                <thead>
                    <tr>
                        {renderTableHeader()}
                    </tr>
                </thead>
                <tbody>
                    {renderTableBody()}
                </tbody>
            </table>
            { footer }
        </div>
    );
};

export default Table;

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.array.isRequired,
    children: PropTypes.func.isRequired,
    emptyComponent: PropTypes.element.isRequired,
    footer: PropTypes.element
};
