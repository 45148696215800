import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getPublicMediaContentUrl } from '../../../../../redux/modules/media/action';
import LightBox from '../../../../Common/LightBox';
import MediaFormModal from '../../../../Forms/Media/Modal/MediaFormModal';
import { ConstructionPlanMediaPropType, ConstructionPlanModelModePropType } from '../../propTypes';
import ConstructionPlanFormMediaListItem from './ConstructionPlanFormMediaListItem';

class ConstructionPlanFormMediaList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPreviewModal: false,
            previewItem: undefined,
            showEditModal: false,
            editItem: undefined
        };

        this.previewItem = this.previewItem.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.updateItem = this.updateItem.bind(this);

        this.closePreviewModal = this.closePreviewModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
    }

    previewItem(mediaItem) {
        this.setState({
            showPreviewModal: true,
            previewItem: mediaItem
        });
    }

    closePreviewModal() {
        this.setState({
            showPreviewModal: false,
            previewItem: undefined
        });
    }

    editItem(mediaItem) {
        this.setState({
            showEditModal: true,
            editItem: mediaItem
        });
    }

    updateItem(updatedMediaItem) {
        this.closeEditModal();
        this.props.onChange(updatedMediaItem);
    }

    closeEditModal() {
        this.setState({
            showEditModal: false,
            editItem: undefined
        });
    }

    deleteItem(mediaItem) {
        this.props.onDelete(mediaItem);
    }

    renderItems() {
        const { media, parentMode } = this.props;
        return (
            <div className="media-field__items-ct">
                <div className="media-field__items">
                    <div className="media-field__items-items">
                        {media.map(mediaItem => (
                            <ConstructionPlanFormMediaListItem
                                key={mediaItem.id}
                                media={mediaItem}
                                onPreview={this.previewItem}
                                onDelete={this.deleteItem}
                                onEdit={this.editItem}
                                modelMode={parentMode}
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    renderPreviewModal() {
        const { showPreviewModal, previewItem } = this.state;
        if (!showPreviewModal) {
            return null;
        }

        return (
            <LightBox media={previewItem} onClose={this.closePreviewModal} />
        )
    }

    renderEditModal() {
        const { showEditModal, editItem } = this.state;
        if (!showEditModal || !editItem) {
            return null;
        }

        return (
            <MediaFormModal
                media={editItem}
                onSaved={this.updateItem}
                modelType={this.props.parentMode}
                isConstructionPlan
            />
        );
    }

    render() {
        const { media, emptyLabel } = this.props;

        const hasItems = media && media.length > 0;
        if (!hasItems) {
            return (
                <div className="media-list__items-ct is-empty">
                    <div className="media-list__empty-lbl">
                        {emptyLabel}
                    </div>
                </div>
            );
        }

        return (
            <div className="media-list__items-ct">

                {/* Items */}
                {this.renderItems()}

                {/* Edit */}
                {this.renderEditModal()}

                {/* Preview */}
                {this.renderPreviewModal()}
            </div>
        );
    }
}

ConstructionPlanFormMediaList.propTypes = {
    media: PropTypes.arrayOf(ConstructionPlanMediaPropType).isRequired,
    parentMode: ConstructionPlanModelModePropType.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    emptyLabel: PropTypes.string
};

ConstructionPlanFormMediaList.defaultProps = {
    emptyLabel: 'Keine Dokumente hinterlegt',
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getPublicMediaContentUrl
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanFormMediaList);
