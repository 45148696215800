import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { showRequestError, showTempError } from '../../../redux/modules/error/action';
import GraphQLError from '../../../Services/GraphQLError';
import DeleteModal from './DeleteModal';

const getInitialState = (props) => {
    return {
        showConfirmationModal: false,
        isDeleting: false
    };
};

class DeleteResourceLink extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = getInitialState(props);
        this.renderConfirmationModal = this.renderConfirmationModal.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.deleteResource = this.deleteResource.bind(this);
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    deleteResource() {

        const { resourceName, resourceTypeLabel, onSuccessfulDeletion, deleteFnc } = this.props;

        const { isDeleting } = this.state;

        if (isDeleting) {
            return;
        }

        this.setState({
            isDeleting: true
        });

        deleteFnc()
            .then(() => {
                toast.success(`${resourceTypeLabel} '${resourceName}' wurde gelöscht`, { autoClose: 2500 });

                if (this.loadingGotCancelled) {
                    return;
                }

                this.toggleConfirmationModal();
                onSuccessfulDeletion();
            })
            .catch((error) => {

                if (error instanceof GraphQLError) {
                    if (error.is('main_customer_contact_not_deleteable')) {
                        showTempError('Der Hauptkontakt eines Kunden kann nicht gelöscht werden. Bitte markieren Sie vorher einen anderen Ansprechpartner als Hauptkontakt.', 8000);
                        return;
                    }

                    if (error.is('locked_by_job')) {
                        showTempError(`Der ${resourceTypeLabel} '${resourceName}' ist mit einem Auftrag oder einer Anfrage verknüpft und konnte deshalb nicht gelöscht werden. Bitte wählen Sie vorher im jeweiligen Auftrag bzw. in der jeweiligen Anfrage einen anderen Ansprechpartner aus.`, 8000);
                        return;
                    }
                }
                showRequestError(`${resourceTypeLabel} '${resourceName}' konnte nicht gelöscht werden`, error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isDeleting: false
                });
            });
    }

    toggleConfirmationModal() {
        this.setState(prevState => ({
            showConfirmationModal: !prevState.showConfirmationModal
        }));
    }

    renderConfirmationModal() {

        const { isDeleting, showConfirmationModal } = this.state;

        const {
            disabled, resourceTypeLabel, resourceName
        } = this.props;

        if (!showConfirmationModal || disabled) {
            return null;
        }

        return (
            <DeleteModal
                modalTitle={`${resourceTypeLabel} löschen`}
                deleteEntity={this.deleteResource}
                closeModal={this.toggleConfirmationModal}
                isDeletingEntity={isDeleting}
            >
                <p>Sie sind im Begriff, den {resourceTypeLabel} <a
                    onClick={this.toggleConfirmationModal}
                    className="btn--link-neutral"
                    role="button"
                >{resourceName}</a> zu löschen.
                </p>
                <p>Möchten Sie fortfahren?</p>
            </DeleteModal>
        );
    }

    renderLink() {
        const {
            deleteBtnLabel
        } = this.props;

        return (
            <a role="button" onClick={this.toggleConfirmationModal}>{deleteBtnLabel}</a>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderLink()}
                {this.renderConfirmationModal()}
            </Fragment>
        );
    }
}

export default DeleteResourceLink;

DeleteResourceLink.propTypes = {
    deleteFnc: PropTypes.func.isRequired,
    resourceTypeLabel: PropTypes.string.isRequired,
    resourceName: PropTypes.string.isRequired,
    deleteBtnLabel: PropTypes.string,
    onSuccessfulDeletion: PropTypes.func.isRequired
};

DeleteResourceLink.defaultProps = {
    deleteBtnLabel: 'Entfernen'
};

