import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from '../../../../Common/Diff/functions';
import { widthClassLabel } from '../../../../Sections/labels';
import { WidthClass } from '../../../../Sections/properties';
import { getSyncChoiceValue } from '../../../utils';
import asSyncChoiceField from '../../asSyncChoiceField';
import SyncChoiceRow from '../../Base/Input/SyncChoiceRow';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';
import { isObjectIdenticalValue } from '../../equals';
import { validateSingleChoiceComplete } from '../../validation';

const WidthClassSyncChoice = ({
                                  value: currentFormValue,
                                  planValue,
                                  liveValue,
                                  onChange,
                                  showIdentical,
                                  showHint
                              }) => {

    const renderWidthClassChoiceLabel = (valueObject) => {

        if (!valueObject) {
            return '-';
        }

        const valueKey = Object.keys(valueObject)[0];

        // > 30 cm
        if (valueKey === WidthClass.Other) {
            return `Andere: ${valueObject[valueKey]}`;
        }

        return widthClassLabel(valueKey);
    };

    const isSelected = (value) => {
        return isObjectIdenticalValue(currentFormValue, value);
    };

    const leftValue = !isEmpty(planValue) ? getSyncChoiceValue(Object.keys(planValue)[0], planValue, null) : null;
    const rightValue = !isEmpty(liveValue) ? getSyncChoiceValue(Object.keys(liveValue)[0], liveValue, null) : null;

    const isIdentical = isObjectIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Breitenklasse"
        >
            <SyncChoiceRow
                leftValue={leftValue}
                rightValue={rightValue}
                renderValue={renderWidthClassChoiceLabel}
                onSelect={onChange}
                isSelected={isSelected}
                isIdentical={isIdentical}
                showIdentical={showIdentical}
            />
        </SyncInputGroup>
    );
};

WidthClassSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    showIdentical: PropTypes.bool,
    showHint: PropTypes.func
};

WidthClassSyncChoice.defaultProps = {
    showHint: () => false
};

export default asSyncChoiceField(WidthClassSyncChoice, {
    allowNull: true,
    validate: validateSingleChoiceComplete(undefined, isObjectIdenticalValue)
});
