import React from 'react';
import PropTypes from 'prop-types';
import Miscellaneous from '../../../Components/Miscellaneous/Miscellaneous';
import Container from '../../Container';
import PrivatePage from './PrivatePage';
import TopNav from '../../../Components/Page/TopNav';

const MiscellaneousPage = ({ history }) => (
    <PrivatePage>
        <Container>
            <TopNav />

            <Miscellaneous
                history={history}
            />
        </Container>
    </PrivatePage>
);

export default MiscellaneousPage;

MiscellaneousPage.propTypes = {
    history: PropTypes.object
};

MiscellaneousPage.defaultProps = {
    history: {}
};
