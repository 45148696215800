import _ from 'lodash';
import { SelectablePurposeGroups } from './helpers';
import { getPurposeGroupLabel, getPurposeLabel } from './labels';
import {
    EmergencyStairwayPurposes,
    PlatformStairwayPurposes,
    ProtectivePurposes,
    Purpose,
    PurposeGroup,
    WorkPurposes
} from './properties';

export const getPurposeMap = (purposes, otherProtectivePurpose = null) => {

    if (!purposes || !purposes.length) {
        return null;
    }

    const purposeMap = SelectablePurposeGroups.reduce((acc, group) => {
        acc[group] = {
            group,
            label: getPurposeGroupLabel(group),
            items: []
        };
        return acc;
    }, {});

    purposes.forEach((purpose) => {

        // Handled below
        if (purpose === Purpose.OtherProtectiveScaffolding) {
            return;
        }

        let label = getPurposeLabel(purpose);
        const purposeGroup = determinePurposeGroup(purpose);
        const group = purposeMap[purposeGroup];
        if (!group) {
            console.error(`Encountered unknown purpose '${purpose}'`);
            return;
        }

        group.items.push({
            purpose: purpose,
            label
        });
    });

    // Special case: other protective purpose
    if (otherProtectivePurpose) {
        purposeMap[PurposeGroup.ProtectiveScaffolding].items.push({
            purpose: Purpose.OtherProtectiveScaffolding,
            label: `Andere: „${otherProtectivePurpose}“`
        });
    }

    // Cleanup
    SelectablePurposeGroups.forEach(purposeGroup => {
        if (purposeMap[purposeGroup].items.length === 0) {
            delete purposeMap[purposeGroup];
        }
    });

    return purposeMap;
};

/**
 * Determines the purpose group set for the given purposes
 *
 * @param purposes
 * @return {*[]|*}
 */
export function determinePurposeGroups(purposes) {
    if (!purposes || purposes.length === 0) {
        return [];
    }

    return _.uniq(purposes.map(determinePurposeGroup));
}

export function determinePurposeGroup(purpose) {
    if (!purpose) {
        return PurposeGroup.Unknown;
    }

    if (WorkPurposes.indexOf(purpose) >= 0) {
        return PurposeGroup.WorkScaffolding;
    } else if (ProtectivePurposes.indexOf(purpose) >= 0) {
        return PurposeGroup.ProtectiveScaffolding;
    } else if (Purpose.LadderAscent === purpose) {
        return PurposeGroup.LadderAscent;
    } else if (PlatformStairwayPurposes.indexOf(purpose) >= 0) {
        return PurposeGroup.PlatformStairway;
    } else if (Purpose.SiteStaircase === purpose) {
        return PurposeGroup.SiteStaircase;
    } else if (EmergencyStairwayPurposes.indexOf(purpose) >= 0) {
        return PurposeGroup.EmergencyStairway;
    }

    return PurposeGroup.Unknown;
}

/**
 * Returns the work purpose if existing
 *
 * @param purposes
 * @return {unknown}
 */
export function getWorkPurpose(purposes) {
    return _.find(purposes, purpose => WorkPurposes.indexOf(purpose) >= 0);
}

export function getProtectivePurposes(purposes) {
    return _.filter(purposes, purpose => ProtectivePurposes.indexOf(purpose) >= 0);
}

export function getPlatformStairwayPurpose(purposes) {
    return _.find(purposes, purpose => PlatformStairwayPurposes.indexOf(purpose) >= 0);
}

export function getSiteStaircasePurpose(purposes) {
    return _.find(purposes, purpose => purpose === Purpose.SiteStaircase);
}

export function isPlatformStairwayPurpose(purposes, exclusive) {
    if (!purposes || !purposes.length) {
        return false;
    }

    if (!exclusive) {
        return _.intersection(purposes, PlatformStairwayPurposes).length >= 1;
    }

    if (purposes.length > 1) {
        return false;
    }

    return _.intersection(purposes, PlatformStairwayPurposes).length === 1;
}
