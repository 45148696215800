import { toast } from 'react-toastify';
import callApi, {mutate, query, querySingle} from '../../action';
import * as types from '../../types';
import { showRequestError } from '../error/action';

export const addSectionNotesSuccess = () => ({
    type: types.ADD_SECTION_NOTES_SUCCESS
});
export const addSectionNotesFailure = err => ({
    type: types.ADD_SECTION_NOTES_FAILURE,
    error: err
});
export const fetchSection = () => ({
    type: types.FETCH_SECTION
});
export const fetchSectionSuccess = section => ({
    type: types.FETCH_SECTION_SUCCESS,
    payload: section
});
export const fetchSectionFailure = error => ({
    type: types.FETCH_SECTION_FAILURE,
    payload: error
});

export const deleteSection = () => ({
    type: types.DELETE_SECTION
});

export const deleteSectionSuccess = () => ({
    type: types.DELETE_SECTION_SUCCESS
});

export const deleteSectionFailure = error => ({
    type: types.DELETE_SECTION_FAILURE,
    error
});

export function addNewSection(sectionDto) {
    // sectionDto.managed = true;
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        return callApi('v1/sections', {
            method: 'POST',
            token,
            body: sectionDto
        })
            .then(res => res.json());
    };
}

export function updateSection(sectionId, sectionInfo) {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        return callApi(`v1/sections/${sectionId}`, {
            method: 'PUT',
            token,
            body: sectionInfo
        })
            .then(res => res.json())
            .then(result => result);
    };
}

export function addSectionNote(note, sectionId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi(`v1/sections/${sectionId}/notes`, {
            method: 'POST',
            token,
            body: note
        })
            .then((response) => {
                if (!response.ok) {
                    dispatch(addSectionNotesFailure(response.statusText));
                    toast.error('Ein Fehler ist aufgetreten', { autoClose: 2500 });
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json()
                .then((result) => {
                    dispatch(addSectionNotesSuccess());
                    return result;
                }))
            .catch((error) => {
                dispatch(addSectionNotesFailure(error));
                toast.error(error, { autoClose: 2500 });
            });
    };
}

export const fetchSectionDetailData = sectionId => (
    (dispatch, getState) => {
        dispatch(fetchSection());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Section{unknownSection(id:"${sectionId}"){
            ... on Section {
                id,
                shortId,
                archived,
                jobs{
                    id,
                    name,
                    status,
                    created,
                    updated,
                    archived,
                    type,
                    customer {
                        id,name
                    },
                    site {
                        id,name,address{city},archived
                    },
                    sections {
                        id,name,scaffolding{id,name,archived},archived
                    }
                },
                status,
                customer,
                publicStatus,
                name,
                managed,
                scaffolding{id,name,managed,site{id,name,archived}, archived},
                equippedSurfaces,
                plannedWorks,
                purposes,
                otherProtectivePurpose,
                currentPosting{id,name,fileName,size,mimeType,created},
                postings{id,name,fileName,size,mimeType,created},
                specialConstruction
                staticsCertificateNecessary
                proofOfStability{id,name,fileName,size,mimeType,visibility},
                supportType,
                constructionType
                loadClass,
                wallClearance,
                widthClass,
                contractor {name, addressName,addressLine1,addressLine2,addressCity,addressZip},
                claddings,
                specialFeatures,
                description,  
                utilizationPlans{id,name,fileName,size,mimeType,visibility},
                anchorProtocol{id,name,fileName,size,mimeType,visibility},
                attachments{id,name,fileName,size,mimeType,visibility},
                created,
                updated,
                inspections{
                    ... on Inspection { id, isAcceptanceInspection, isThirdParty, completed, createdBy{ firstName, lastName, account{ id, company{id,name}} }, success, answers{success}}
                    ... on ThirdPartyInspection { id, isAcceptanceInspection, isThirdParty, completed, createdBy{ firstName, lastName, account{ id, company{id,name}} }, success, answers{success}}
                },
                approvedByName,
                activeAcceptanceInspection{ createdByName, completed}
            }
            ... on ThirdPartySection {
                id,
                shortId,
                publicStatus,
                name,
                isThirdParty,
                isInvited,
                archived,
                scaffolding{
                    id,name,isThirdParty,isInvited,
                    archived,
                    site{
                        id,
                        name,
                        archived,
                        isInvited,
                        invitations {
                           allowInspections,
                           allowCloseRequests 
                        }
                    }
                },
                equippedSurfaces,
                plannedWorks,
                purposes,
                otherProtectivePurpose,
                specialConstruction
                staticsCertificateNecessary
                proofOfStability{id,name,fileName,size,mimeType},
                supportType,
                constructionType
                loadClass,
                wallClearance,
                widthClass,
                contractor{name, addressName,addressLine1,addressLine2,addressCity,addressZip},
                customer,
                claddings,
                specialFeatures,
                description,
                utilizationPlans{id,name,fileName,size,mimeType},
                anchorProtocol{id,name,fileName,size,mimeType},
                attachments{id,name,fileName,size,mimeType},
                created,
                updated,
                inspections{
                    ... on Inspection { id, isAcceptanceInspection, isThirdParty, completed, createdBy{ firstName, lastName, account{ id, company{id,name}} }, success, answers{success}}
                    ... on ThirdPartyInspection { isAnonymous, images{id, name, isAnonymous}, notes{id, content, isAnonymous}, id, isAcceptanceInspection, isThirdParty, completed, createdBy{ firstName, lastName, account{ id, company{id,name}} }, success, answers{success}}
                },
                approvedByName,
                activeAcceptanceInspection{ createdByName, completed}
            }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'unknownSection')
            .then((section) => {
                dispatch(fetchSectionSuccess(section));
                return section;
            })
            .catch((error) => {
                dispatch(fetchSectionFailure(error));
                showRequestError('Baubschnittsdaten konnten nicht geladen werden', error);
            });
    }
);

export const fetchSectionFormData = sectionId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Section{section(id:"${sectionId}"){
            id,
            shortId,
            status,
            publicStatus,
            name,
            managed,
            customer,
            scaffolding{id,name,managed,site{id,name}},
            equippedSurfaces,
            plannedWorks,
            purposes,
            description,
            otherProtectivePurpose,
            specialConstruction
            staticsCertificateNecessary
            proofOfStability{id,name,fileName,size,mimeType,visibility},
            supportType,
            constructionType
            loadClass,
            wallClearance,
            widthClass,
            contractor{id,name, addressName,addressLine1,addressLine2,addressCity,addressZip},
            claddings,
            specialFeatures,
            utilizationPlans{id,name,fileName,size,mimeType,visibility},
            anchorProtocol{id,name,fileName,size,mimeType,visibility},
            attachments{id,name,fileName,size,mimeType,visibility},
            created,
            updated,
            approvedByName,
            activeAcceptanceInspection{ createdByName, completed}
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'section')
            .then((section) => {
                return section;
            })
            .catch((error) => {
                showRequestError('Baubschnittsdaten konnten nicht geladen werden', error);
                return null;
            });
    }
);

export const deleteSingleSection = sectionId => (

    (dispatch, getState) => {
        dispatch(deleteSection());

        const token = getState().currentUser.auth_token;

        return callApi(`v1/sections/${sectionId}`, {
            method: 'DELETE',
            token
        })
            .then((data) => {
                dispatch(deleteSectionSuccess());
                return data;
            });
    }
);

export const sendCloseRequest = sectionId => (
    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        return callApi(`v1/sections/${sectionId}/close-request`, {
            method: 'POST',
            token
        })
            .then((data) => {
                dispatch(deleteSectionSuccess());
                return data;
            })
            .catch(error => (
                error.getData()
                    .then((data) => {
                        dispatch(deleteSectionFailure(error));
                        return Promise.reject(data.message);
                    })
            ));
    }
);

export function createContractor(contractorDto) {

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi('v1/companies', {
            method: 'POST',
            token,
            body: contractorDto
        })
            .then(res => res.json())
            .then((result) => {
                return result;
            });
    };
}

export function fetchContractorChoices() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const contractorQuery = 'Companies{companies(limit:-1){data{id,name}}}';

        return query(contractorQuery, {
            token
        })
            .then(({ companies }) => {
                return companies.data;
            });
    };
}

export function fetchSectionChangeLog(sectionId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const changeLogQuery = `
            FetchSectionAudits{
                changelogs( page:1, sortAsc:false, id:"${sectionId}", type: section){
                    data{
                        created,
                        createdBy{firstName, lastName}, 
                        old, 
                        new
                    }, 
                    total,
                    per_page
                }
            }
        `;

        return query(changeLogQuery, {
            token,
            params: {
                page: 1
            }
        })
            .then(({ changelogs }) => (changelogs.data));
    };
}

export function fetchSectionSelectOptions(scaffoldingId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(`Scaffolding{scaffolding(id:"${scaffoldingId}") {
           sections {
               id,
               name
           }
       }}`, {
            token
        })
            .then(data => data.scaffolding.sections);
    };
}

export const fetchConstructionPlanSectionFormData = sectionId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Section{section(id:"${sectionId}"){
            id,
            name,
            managed,
            equippedSurfaces,
            plannedWorks,
            purposes,
            description,
            otherProtectivePurpose,
            specialConstruction
            staticsCertificateNecessary
            customer,
            proofOfStability{id,name,fileName,size,mimeType,visibility},
            supportType,
            constructionType
            loadClass,
            wallClearance,
            widthClass,
            claddings,
            specialFeatures,
            approvedByName,
            utilizationPlans{id,name,fileName,size,mimeType,visibility},
            anchorProtocol{id,name,fileName,size,mimeType,visibility},
            attachments{id,name,fileName,size,mimeType,visibility},
            created,
            updated,
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'section')
            .then((section) => {
                return section;
            })
            .catch((error) => {
                showRequestError('Baubschnittsdaten konnten nicht geladen werden', error);
                return null;
            });
    }
);


/**
 * Fetches section select opions for a given scaffolding id
 * @param scaffoldingId
 * @returns sections
 */

export const fetchSectionsSelectOptions = scaffoldingId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Scaffolding{scaffolding(id:"${scaffoldingId}"){
            sections{id, name, managed}
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'scaffolding')
            .then((scaffolding) => {
                return scaffolding;
            });
    });

export function fetchConstructionPlanSections(scaffoldingId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(`Scaffolding{scaffolding(id:"${scaffoldingId}") {
           sections {
            id,
            name,
            managed,
            equippedSurfaces,
            plannedWorks,
            purposes,
            description,
            otherProtectivePurpose,
            specialConstruction
            staticsCertificateNecessary
            proofOfStability{id,name,fileName,size,mimeType,visibility},
            supportType,
            constructionType
            loadClass,
            wallClearance,
            widthClass,
            claddings,
            specialFeatures,
            approvedByName,
            utilizationPlans{id,name,fileName,size,mimeType,visibility},
            anchorProtocol{id,name,fileName,size,mimeType,visibility},
            attachments{id,name,fileName,size,mimeType,visibility},
            created,
            updated,
           }
       }}`, {
            token
        })
            .then(data => data.scaffolding.sections);
    };
}

export function autocompleteSection(scaffoldingId, managed) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchScaffoldingSections{sections(scaffoldingId:"${scaffoldingId}",managed:${managed},limit:-1){
            data{id,name}
        }}`;

        return query(gqlQuery, { token })
            .then(result => result.sections.data)
            .catch((error) => {
                showRequestError('Gerüstabschnitte konnten nicht geladen werden', error);
            });
    };
}

export function updateSectionStatus(id, status){
    return(dispatch,getState) => {
        const token = getState().currentUser.auth_token;
        const mutation = `UpdateSectionStatus($id: String!, $input: SectionInput!){
                section: updateSection(id: $id, input: $input)
                {
                    id,
                    status,
                    publicStatus,
                    updated
                }
            }`;

        const params = {
            id,
            input: {
                status
            }
        };

        return mutate(mutation, params, {token})
    }
}
