const styles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: 33,
        borderRadius: 6,
        borderColor: '#81858D',
        ':hover': {
            borderColor: '#81858D'
        }
    }),

    clearIndicator: (provided, state) => ({
        ...provided,
        padding: 4
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        height: 31
    }),
    input: (provided, state) => ({
        ...provided,
        margin: 0
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 5,
        paddingTop: 6,
        color: '#0D1626',
        ':hover': {
            color: '#0D1626'
        }
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;

        return {
            ...provided,
            opacity
        };
    }
};

export default styles;
