import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { BadRequestError } from '../../Services/ApiError';
import ErrorService from '../../Services/ErrorService';
import InputGroup from '../Forms/InputGroup';
import Section from '../Layout/Section';
import Input from '../Forms/Input';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { createUser, deleteUser, updateUser } from '../../redux/modules/user/action';
import { fetchSingleUser } from '../../redux/modules/account/action';
import { showApiError } from '../../redux/modules/error/action';
import IfUser from '../Common/IfUser';
import Icon from '../Common/Icon';
import { validateEmail } from '../../utils';
import Select from '../Forms/Select';
import DeleteModal from '../Common/Modal/DeleteModal';
import Condition from '../Forms/Condition';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';

const AccountRoleOptions = {
    pro: [
        {
            label: 'Administrator – 23,95€*',
            value: 'pro_administrator'
        },
        {
            label: 'Vollzugriff – 23,95€*',
            value: 'pro_standard'
        },
        {
            label: 'Befähigte Person – 14,95€*',
            value: 'pro_qualified-worker'
        },
        {
            label: 'Helfer – 5,95€*',
            value: 'pro_worker'
        }
    ],
    basic: [
        {
            label: 'Administrator – 16,35€*',
            value: 'basic_administrator'
        },
        {
            label: 'Vollzugriff – 16,35€*',
            value: 'basic_standard'
        }
    ],
    light: [
        {
            label: 'Administrator',
            value: 'light_administrator'
        },
        {
            label: 'Vollzugriff',
            value: 'light_standard'
        }
    ]
};

class UserForm extends Component {

    static  validate(values) {
        const errors = {};


        if (!values.firstName) {
            errors.firstName = 'Bitte geben Sie einen Vornamen ein';
        }

        if (!values.lastName) {
            errors.lastName = 'Bitte geben Sie einen Nachnamen ein';
        }

        // E-Mail
        if (!values.email) {
            errors.email = 'Bitte geben Sie eine E-Mail Adresse ein';
        } else if (!validateEmail(values.email)) {
            errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein';
        }

        if (!values.type) {
            errors.type = 'Bitte wählen Sie einen Kontotyp';
        }

        return errors;
    }

    removeUser(userId) {
        const { deleteUser } = this.props.actions;

        this.setState({
            isDeletingUser: true
        });

        deleteUser(userId)
            .then(() => {
                this.setState({
                    isDeletingUser: false
                });
                toast.success('Nutzer wurde entfernt', { autoClose: 2500 });
                this.props.history.push('/account/users');
            })
            .catch((error) => {
                this.setState({
                    isDeletingUser: false
                });

                showApiError(error);
            });
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            deleteModalIsOpen: !prevState.deleteModalIsOpen
        }));
    }

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;

        this.state = {
            userId: this.props.edit,
            isFetching: false,
            isSending: false,
            data: {},
            deleteModalIsOpen: false
        };

        this.prepareFormData = this.prepareFormData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    }

    componentWillMount() {
        const { edit } = this.props;

        if (!edit) {
            return false;
        }

        this.setState({ isFetching: true });

        const { userId } = this.state;
        return this.props.actions.fetchSingleUser(userId)
            .then((userData) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.prepareFormData(userData);
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onSubmit(values) {
        const userData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            type: values.type,
            phone: values.phone
        };

        this.setState({ isSending: true });

        const { actions: { createUser, updateUser }, history } = this.props;

        if (this.props.edit) {
            const { userId } = this.state;

            updateUser(userId, userData)
                .then(() => {
                    this.setState({
                        isSending: false
                    });
                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });
                    this.props.history.push('/account/users');
                })
                .catch((error) => {
                    this.setState({
                        isSending: false
                    });
                    showApiError(error);
                });
        } else {
           return createUser(userData)
                .then(() => {
                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSending: false
                    });

                    toast.success('Nutzer wurde angelegt', { autoClose: 2500 });

                    if (this.props.redirect) {
                        history.push(this.props.redirect);
                    } else {
                        history.push('/account/users');
                    }
                })
                .catch((error) => {
                    this.setState({
                        isSending: false
                    });

                    if(error instanceof BadRequestError){
                        return ErrorService.handleBadRequestError(error, 'addnewuser')
                            .then(data => ({ [FORM_ERROR]: data.email }));
                    }

                    return showApiError(error);
                });
        }
    }

    prepareFormData(data) {
        if (!data) {
            this.setState({
                data: null,
                isFetching: false
            });
            return;
        }

        const postLoadData = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            type: data.type,
            phone: data.phone
        };

        this.setState({
            data: postLoadData,
            isFetching: false
        });
    }

    renderButtonText() {
        const { isSending } = this.state;
        const { edit } = this.props;

        if (edit) {
            return isSending ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }

        return isSending ? 'Nutzer wird angelegt ...' : 'Nutzer erstellen';
    }

    render() {
        const {
            data,
            isFetching,
            isSending,
            isDeletingUser,
            deleteModalIsOpen
        } = this.state;

        if (!isFetching && !data) {
            return <ResourceNotFoundErrorPage />;
        }

        if (isFetching) {
            return <PageLoadingSpinner />;
        }

        return (
            <React.Fragment>
                <Form
                    onSubmit={this.onSubmit}
                    validate={UserForm.validate}
                    initialValues={data}
                    render={({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit}>
                            {submitError && <div className="form-error">{submitError}</div>}
                            <Section
                                title="Nutzerdaten"
                            >
                                <InputGroup
                                    label="Vorname*"
                                >
                                    <Input
                                        type="text"
                                        name="firstName"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Nachname*"
                                >
                                    <Input
                                        type="text"
                                        name="lastName"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="E-Mail Adresse*"
                                >
                                    <Input
                                        type="text"
                                        name="email"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Telefon"
                                >
                                    <Input
                                        type="text"
                                        name="phone"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Kontotyp*"
                                >
                                    <Select
                                        placeholder="Kontotyp wählen"
                                        name="type"
                                        options={AccountRoleOptions[this.props.accountType]}
                                    />

                                    <Condition when="type" is="pro_administrator">
                                        <small>
                                            Als Administrator haben Sie den vollen Zugriff auf alle
                                            Bereiche und Funktionen von Scaffeye®. Im Gegensatz zum
                                            Kontotyp "Vollzugriff" besitzen Sie zusätzlich die
                                            Berechtigung, Ihre Nutzer und Ihre
                                            Unternehmensdaten sowie Ihr Abonnement und Ihre
                                            Zahlungsmethode zu verwalten.
                                        </small>
                                    </Condition>

                                    <Condition when="type" is="pro_standard">
                                        <small>
                                            Als Nutzer des Kontotyps "Vollzugriff" genießen Sie
                                            den vollen Zugriff auf alle Bereiche und Funktionen von
                                            Scaffeye®. Verwalten Sie Ihre Aufträge, Baustellen und
                                            Kunden und kommunizieren Sie mit Ihrem Team über die
                                            baustellenspezifische Kommentarfunktion. Im Gegensatz zum Kontotyp "Befähigte Person" können sie firmeneigene und von Scaffeye® verwaltete Baustellen, Gerüste und Gerüstabschnitte anlegen.
                                        </small>
                                    </Condition>

                                    <Condition when="type" is="pro_qualified-worker">
                                        <small>
                                            Als Nutzer des Kontotyps "Befähigte Person" erhalten Sie lesenden Zugriff auf firmeneigene Baustellen, Gerüste und Gerüstabschnitte und kommunizieren mit Ihrem Team
                                            über die baustellenspezifische Kommentarfunktion. Sie können alle Funktionen
                                            der Scaffeye® App nutzen, um beispielsweise Freigaben
                                            und Prüfungen durchzuführen.
                                        </small>
                                    </Condition>

                                    <Condition when="type" is="pro_worker">
                                        <small>
                                            Als Nutzer des Kontotyps "Helfer" genießen Sie lesenden
                                            Zugriff auf alle Baustellendaten Ihres
                                            Unternehmens. Sehen Sie sich alle Spezifikationen und
                                            die Prüfhistorie Ihrer Gerüstabschnitte an und
                                            kommunizieren Sie mit Ihrem Team über die
                                            baustellenspezifische Kommentarfunktion. In der
                                            Scaffeye®-App sind Sie nicht berechtigt, Prüfungen oder
                                            Freigaben abzusenden und zu speichern.
                                        </small>
                                    </Condition>
                                    <Condition when="type" is="basic_administrator">
                                        <small>
                                            Als Administrator können Sie Baustellen, Gerüste und Gerüstabschnitte zur internen Dokumentation und Gerüstprüfung anlegen.
                                            Sie haben lesenden Zugriff auf alle von Ihnen abonnierten Baustellen, Gerüste und Gerüstabschnitte mitsamt Prüfhistorie. Scaffeye® informiert Sie zu aktuellen Gerüstprüfungen und Statusänderungen Ihrer abonnierten Gerüstabschnitte. Kommunizieren Sie mit Ihrem Team über die
                                            baustellenspezifische Kommentarfunktion. Im Gegensatz zum
                                            Kontotyp "Vollzugriff" besitzen Sie zusätzlich die
                                            Berechtigung, Ihre Nutzer und Ihre
                                            Unternehmensdaten sowie Ihr Abonnement und Ihre
                                            Zahlungsmethode zu verwalten.
                                        </small>
                                    </Condition>
                                    <Condition when="type" is="basic_standard">
                                        <small>
                                            Als Nutzer des Kontotyps "Vollzugriff" können Sie Baustellen, Gerüste und Gerüstabschnitte zur internen Dokumentation und Gerüstprüfung anlegen.
                                            Sie haben lesenden Zugriff auf alle von Ihnen abonnierten Baustellen, Gerüste und Gerüstabschnitte mitsamt Prüfhistorie. Scaffeye® informiert Sie zu aktuellen Gerüstprüfungen und Statusänderungen Ihrer abonnierten Gerüstabschnitte. Kommunizieren Sie mit Ihrem Team über die
                                            baustellenspezifische Kommentarfunktion.
                                        </small>
                                    </Condition>

                                    <Condition when="type" is="light_administrator">
                                        <small>
                                            Als Administrator erhalten lesenden Zugriff für ausgewählte Baustellen, Gerüste und Gerüstabschnitte (über Einladung oder Einscannen vor Ort mit der Scaffeye®-App). Scaffeye® informiert Sie zu aktuellen Statusänderungen Ihrer abonnierten Gerüstabschnitte. Sie können die Prüfhistorie einzelner Gerüstabschnitte einsehen, Prüfprotokolle exportieren sowie Sperranfragen versenden. Im Gegensatz zum
                                            Kontotyp "Vollzugriff" besitzen Sie zusätzlich die
                                            Berechtigung, Ihre Nutzer und Ihre
                                            Unternehmensdaten sowie Ihr Abonnement und Ihre
                                            Zahlungsmethode zu verwalten.
                                        </small>
                                    </Condition>

                                    <Condition when="type" is="light_standard">
                                        <small>
                                            Als Nutzer des Kontotyps "Vollzugriff" erhalten lesenden Zugriff für ausgewählte Baustellen, Gerüste und Gerüstabschnitte (über Einladung oder Einscannen vor Ort mit der Scaffeye®-App). Scaffeye® informiert Sie zu aktuellen Statusänderungen Ihrer abonnierten Gerüstabschnitte. Sie können die Prüfhistorie einzelner Gerüstabschnitte einsehen, Prüfprotokolle exportieren sowie Sperranfragen versenden.
                                        </small>
                                    </Condition>

                                    <div style={{marginTop: 8}}>
                                        <small>
                                        * Preise pro Monat, zzgl. MwSt.
                                        </small>
                                    </div>

                                </InputGroup>
                            </Section>

                            {this.props.edit ?
                                <IfUser
                                    can={['delete-users']}
                                >
                                    <div className="delete-option">
                                        <div
                                            className="btn btn--delete"
                                            onClick={() => this.toggleDeleteModal()}
                                        >
                                            <span className="btn__icon">
                                                <Icon name="delete" />
                                            </span>
                                            Nutzer entfernen
                                        </div>
                                    </div>
                                </IfUser>
                                :
                                null
                            }

                            <div className="btn-group">
                                <a
                                    onClick={this.props.history.goBack}
                                    className="btn btn--backward-action"
                                >
                                    Abbrechen
                                </a>

                                <span className="btn btn--dummy">*Pflichtfeld</span>
                                <button
                                    type="submit"
                                    className="btn btn--forward-action btn--save"
                                    disabled={isSending}
                                >
                                    {this.renderButtonText()}
                                </button>
                            </div>
                        </form>
                    )}
                />

                {deleteModalIsOpen &&
                <DeleteModal
                    modalTitle="Nutzer löschen"
                    deleteEntity={() => this.removeUser(this.props.edit)}
                    closeModal={this.toggleDeleteModal}
                    isDeletingEntity={isDeletingUser}
                >
                    <p>Sie sind im Begriff, den Nutzer <span
                        onClick={this.toggleDeleteModal}
                        className="btn--link-neutral"
                    >{data.firstName} {' '} {data.lastName}

                                                       </span> zu
                        löschen.
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteModal>
                }
            </React.Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        createUser,
        fetchSingleUser,
        updateUser,
        deleteUser
    }, dispatch)
});

const mapStateToProps = ({ currentUser }) => {
    const accountType = currentUser.usermeta.account.type || null;
    return {
        accountType
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
