import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CollapseToggleLink from '../../../Common/Collapse/CollapseToggleLink';
import EmptyCardHint from '../../../Common/EmptyCardHint';
import { MediaPropType } from '../../../Media/properties';
import JobCorrespondenceTimelineCluster from './JobCorrespondenceTimelineCluster';

const MAX_ITEMS_TO_SHOW = 3;

function getTimelineClusters(correspondence) {

    // 1) Sort correspondence by creation time (asc)
    const sortedCorrespondence = _.sortBy(correspondence, (item) => item.created);

    // 2) Iterate over items to determine if the newest item of the cluster is less than X minutes
    // older than the current one
    var lastItemCreatedAt = moment();
    const clusters = sortedCorrespondence.reduce((clusters, item) => {

        const itemCreatedAt = moment(item.created);
        var cluster;
        if (clusters.length === 0 || Math.abs(itemCreatedAt.diff(lastItemCreatedAt, 'minutes')) > 3) {
            cluster = {
                id: item.id,
                created: item.created,
                items: [item]
            };
            clusters.push(cluster);
        } else {
            cluster = clusters[clusters.length - 1];
            cluster.items.push(item);
        }

        lastItemCreatedAt = itemCreatedAt;

        return clusters;
    }, []);

    // Reverse order (asc => desc)
    clusters.reverse();

    return clusters;
}

export default function JobCorrespondenceTimeline({ items, onTimelineItemEdit, onTimelineItemDelete, readOnly }) {

    const [collapsed, toggleCollapsed] = useState(true);

    if (!items || !items.length) {
        return (
            <EmptyCardHint
                title={readOnly ? 'Es wurde keine Korrespondenz hochgeladen.' : 'Es wurde bisher keine Korrespondenz hochgeladen.'}
            >
                {!readOnly &&
                <p>
                    Laden Sie zur Anfrage bzw. zum Auftrag zugehörige Korrespondenz wie E-Mails und
                    Textdateien hoch, um diese hier angezeigt zu bekommen. Klicken Sie dazu
                    auf &quot;+&quot; und wählen Sie Ihre gewünschte Korrespondenz aus.
                </p>
                }
            </EmptyCardHint>
        );
    }

    const clusters = getTimelineClusters(items);
    const showCount = collapsed ? MAX_ITEMS_TO_SHOW : -1;

    return (
        <div className="job-correspondence-list">
            <div className="job-correspondence-list__bd">
                {clusters.map((cluster, index) => {

                        if (showCount > 0 && index >= showCount) {
                            return null;
                        }

                        return <JobCorrespondenceTimelineCluster
                            data={cluster}
                            key={cluster.id}
                            onTimelineItemEdit={onTimelineItemEdit}
                            readOnly={readOnly}
                            onTimelineItemDelete={onTimelineItemDelete}
                        />;
                    }
                )}
            </div>
            {clusters.length > MAX_ITEMS_TO_SHOW &&
            <div className="job-correspondence-list__collapse-toggle">
                <CollapseToggleLink
                    onToggle={() => toggleCollapsed(!collapsed)}
                    isCollapsed={collapsed}
                    expandLabel="Gesamte Korrespondenz anzeigen"
                    collapseLabel="Nur letzte Korrespondenz anzeigen"
                />
            </div>
            }
        </div>
    );
}

JobCorrespondenceTimeline.propTypes = {
    onTimelineItemEdit: PropTypes.func.isRequired,
    onTimelineItemDelete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    items: PropTypes.arrayOf(MediaPropType)
};
