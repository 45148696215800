import PropTypes from 'prop-types';
import React from 'react';
import Help from '../../../Components/Help/Help';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const HelpPage = ({ history }) => (
    <PrivatePage>
        <Container>
            <TopNav />
            <PageTitle title="Hilfe"/>
            <Help
                history={history}
            />
        </Container>
    </PrivatePage>
);

export default HelpPage;

HelpPage.propTypes = {
    history: PropTypes.object
};

HelpPage.defaultProps = {
    history: {}
};
