import React from 'react';
import { components } from 'react-select';
import JobStatus from '../JobStatus';
import SelectField from '../../Forms/Select/SelectField';

const SingleValue = props => (
    <components.SingleValue {...props} >
        <JobStatus status={props.data.value} horizontal showJobType />
    </components.SingleValue>
);

const JobStatusSelectField = props => (
    <SelectField
        {...props}
        components={{
            SingleValue
        }}
        placeholder="Status wählen"
    />
);

export default JobStatusSelectField;
