export const JobType = {
    Job: 'job',
    Request: 'request'
};

export const RequestStatus = {
    New: 'request_new',
    InProgress: 'request_in_progress',
    OfferSent: 'request_offer_sent',
    RejectedByCustomer: 'request_rejected_customer',
    RejectedByContractor: 'request_rejected_contractor',
    Rejected: 'request_rejected'
};

export const JobStatus = {
    Draft: 'job_draft',
    Approved: 'job_approved',
    InProgress: 'job_in_progress',
    Completed: 'job_completed'
};

// request status considered as "active"
export const ActiveRequestStatusOptions = [...Object.values(RequestStatus)];

// request status options that can indicate a warning hint reg. an expiring offer deadline
export const NotifiableRequestStatusOptions = [RequestStatus.New, RequestStatus.InProgress];

export const getRequestTableColumns = archived => (archived ? RequestsTableColumns['archived'] : RequestsTableColumns['active']);

export const getJobTableColumns = archived => (archived ? JobsTableColumns['archived'] : JobsTableColumns['active']);


export const RequestsTableColumns = {
    'active': [
        {
            fieldName: 'created',
            label: 'Erstellt am',
            isSortable: true
        },
        {
            fieldName: 'offerDeadline',
            label: 'Angebotsabg. bis',
            isSortable: true
        },
        {
            fieldName: 'status',
            label: 'Status',
            isSortable: true,
            wrapperClass: 'status-column'
        },
        {
            fieldName: 'name',
            label: 'Bezeichnung',
            isSortable: true
        },
        {
            fieldName: 'customerName',
            label: 'Kunde',
            isSortable: true
        },
        {
            label: 'Baustelle',
            isSortable: false
        },
        {
            label: 'Gerüste',
            isSortable: false
        }
    ],
    'archived': [
        {
            fieldName: 'created',
            label: 'Erstellt am',
            isSortable: true
        },
        {
            fieldName: 'name',
            label: 'Bezeichnung',
            isSortable: true
        },
        {
            fieldName: 'customerName',
            label: 'Kunde',
            isSortable: true
        },
        {
            label: 'Baustelle',
            isSortable: false
        },
        {
            label: 'Gerüste',
            isSortable: false
        }
    ]

};

export const JobsTableColumns = {
    'active': [
        {
            fieldName: 'created',
            label: 'Erstellt am',
            isSortable: true
        },
        {
            fieldName: 'status',
            label: 'Status',
            isSortable: true,
            wrapperClass: 'status-column'
        },
        {
            fieldName: 'name',
            label: 'Bezeichnung',
            isSortable: true
        },
        {
            fieldName: 'customerName',
            label: 'Kunde',
            isSortable: true
        },
        {
            label: 'Baustelle',
            isSortable: false
        },
        {
            label: 'Gerüste',
            isSortable: false
        }
    ],
    'archived': [
        {
            fieldName: 'created',
            label: 'Erstellt am',
            isSortable: true
        },
        {
            fieldName: 'name',
            label: 'Bezeichnung',
            isSortable: true
        },
        {
            fieldName: 'customerName',
            label: 'Kunde',
            isSortable: true
        },
        {
            label: 'Baustelle',
            isSortable: false
        },
        {
            label: 'Gerüste',
            isSortable: false
        }
    ]

};

export const InitialRequestSortState = {
    field: 'created',
    direction: '-'
};

export const InitialJobsSortState = {
    field: 'created',
    direction: '-'
};
