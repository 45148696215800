import PropTypes from 'prop-types';
import React, { Component } from 'react';

class StaticTableHeader extends Component {

    renderTableColumns(columns) {

        return columns.map((column, index) => {

            return (
                <th key={index}>
                    {column.label}
                </th>
            );
        });
    }

    render() {
        const { columns } = this.props;
        return (
            <thead>
            <tr>
                {this.renderTableColumns(columns)}
            </tr>
            </thead>
        );
    }
}

export default StaticTableHeader;

StaticTableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired
};




