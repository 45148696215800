import PropTypes from 'prop-types';
import React from 'react';

const LinkButton = ({
    label, disabled, bold, underline, children, ...buttonProps
}) => {

    const classes = [''];

    if(bold){

        if(underline){
            classes.push('btn--link-neutral-bold-underline');
        }
        else {
            classes.push('btn--link-neutral-bold');
        }

    } else {
        classes.push('btn--link-neutral')
    }

    if (disabled) {
        classes.push('btn--link-neutral--disabled');
    }

    return (
        <span
            className={classes.join((' '))}
            {...buttonProps}
        >
            {label}
            {' '}
            {children}
        </span>
    );
};

export default LinkButton;

LinkButton.propTypes = {
    label: PropTypes.string.isRequired,
    bold: PropTypes.bool,
    underline: PropTypes.bool
};

LinkButton.defaultProps = {
    bold: false,
    underline: false
};


