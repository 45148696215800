import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../../Common/Icon';
import { MediaVisibility } from '../../../../../Media/properties';
import { getAvailableVisibilityOptions, getDefaultVisibilitySelection } from './functions';
import SyncChoiceMediaPreviewLink from './SyncChoiceMediaPreviewLink';
import SyncChoiceMediaVisibilityOptions from './SyncMediaSidebarOptions';


const SyncChoiceMediaOption = ({ value, modelMediaType, renderValue, wasEdited, isLiveRessource, currentFormValue, isSelected, onSelect, hint, referenceValue }) => {

    const classes = ['sync-mediaoption'];
    const selectedVisibility = isSelected && currentFormValue && currentFormValue.visibility;
    const referencedVisibility = referenceValue && referenceValue.visibility;

    if (isSelected) {
        classes.push('is-selected');
    }

    const mediaWasRenamed = wasEdited && !isLiveRessource;

    let renderedValue = renderValue(value, mediaWasRenamed);

    if (!renderedValue) {
        renderedValue = '-';
    }

    if (!renderedValue || renderedValue === '-') {
        classes.push('is-empty');
    }

    const internalSelect = (value, selectedVisibility) => {

        const updatedValue = value === null ? value : {
            ...value,
            visibility: selectedVisibility || getDefaultVisibilitySelection(value, isLiveRessource, wasEdited, referencedVisibility, modelMediaType)
        };

        onSelect(updatedValue);
    };

    return (
        <div className={classes.join(' ')} role="button">
            <div className="sync-mediaoption__cnt-wrapper">
                <div onClick={() => internalSelect(value)}
                     className="sync-mediaoption__cnt js-sync-mediaoption-cnt"
                >
                    {renderedValue}
                </div>

                <div className="sync-mediaoption__meta">
                    {isSelected &&
                    <div className="sync-mediaoption__selected">
                        <span className="sync-mediaoption__selected-icon">
                            <Icon name="correct" />
                        </span>
                        <span className="sync-mediaoption__selected-label">
                            Ausgewählt
                        </span>
                    </div>
                    }
                </div>
            </div>

            <div className="sync-mediaoption__sidebar-options">

                <SyncChoiceMediaPreviewLink
                    file={value}
                />

                <SyncChoiceMediaVisibilityOptions
                    selectedVisibility={selectedVisibility}
                    onChange={(selectedVisibility) => internalSelect(value, selectedVisibility)}
                    availableVisibilityOptions={getAvailableVisibilityOptions(value, isLiveRessource, modelMediaType)}
                />
            </div>
        </div>
    );
};

SyncChoiceMediaOption.propTypes = {
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    modelMediaType: PropTypes.string.isRequired,
    currentFormValue: PropTypes.any,
    renderValue: PropTypes.func,
    wasEdited: PropTypes.bool,
    isLiveRessource: PropTypes.bool,
    renderedValue: PropTypes.string,
    referenceValue: PropTypes.object,
    hint: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

SyncChoiceMediaOption.defaultProps = {
    renderValue: (value) => value,
    wasEdited: false,
    isLiveRessource: false,
    hint: undefined,
    referenceValue: undefined
};

export default SyncChoiceMediaOption;

