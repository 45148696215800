import React from 'react';
import Modal from '../../Common/Modal/Modal';

const SendCloseRequestModal = ({
                         modalTitle, isSendingCloseRequest, onSendCloseRequest, closeModal, classes, children
                     }) => (
    <Modal
        id="modal-delete"
        title={modalTitle}
        type="confirmation"
        classes={classes}
        onBackdropClick={() => closeModal()}
        footer={
            <div className="btn-group">
                <button
                    className="btn btn--backward-action"
                    onClick={() => closeModal()}
                >
                    Abbrechen
                </button>
                <button
                    type="submit"
                    className="btn btn--forward-action btn--link-neutral"
                    disabled={isSendingCloseRequest}
                    onClick={onSendCloseRequest}
                >
                    {!isSendingCloseRequest ? 'Fortfahren und senden' : 'Wird gesendet ...'}
                </button>
            </div>
        }
    >
        {children}
    </Modal>
);

export default SendCloseRequestModal;
