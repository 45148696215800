import React from 'react';
import UserInfo from '../../../Components/Account/UserInfo';
import TopNav from '../../../Components/Page/TopNav';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const UserPage = () => (
    <PrivatePage>
        <Container>
            <TopNav />
            <UserInfo />
        </Container>
    </PrivatePage>
);

export default UserPage;
