import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Breadcrumb from '../Navigation/Breadcrumbs/Breadcrumb';
import {BreadcrumbLink} from '../Navigation/Breadcrumbs/BreadcrumbLink';

const SitesBreadcrumb = ({site, collapse, showSite}) => {
    const query = site && site.archived ? 'archived=true' : '';

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label={'Baustellen'}
                    path="/sites"
                    icon="scaffold"
                    query={query}
                />
                {(showSite || site) &&
                <BreadcrumbLink
                    label={site ? site.name : 'Lade Baustelle ...'}
                    path={site ? `/sites/${site.id}` : null}
                />
                }
            </Fragment>
        </Breadcrumb>
    )
};

SitesBreadcrumb.propTypes = {
    site: PropTypes.object,
    collapse: PropTypes.bool,
    showSite: PropTypes.bool,
};

SitesBreadcrumb.defaultProps = {
    site: null,
    collapse: false,
    showSite: false,
};

export default SitesBreadcrumb;
