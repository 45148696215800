import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../Forms/Button';
import Icon from '../Icon';

const CollapseToggleLink = ({ collapseLabel, expandLabel, isCollapsed, hideIcon, onToggle, block }) => {
    return (
        <Button
            onClick={onToggle}
            className={`collapse-toggle btn ${block ? 'block' : ''}`}
            disableSubmit
        >
            <span className="collapse-toggle__label">
                {isCollapsed ? expandLabel : collapseLabel}
            </span>
            {!hideIcon &&
            <span className="collapse-toggle__icon">
                {isCollapsed
                    ? <Icon name="chevronDown" />
                    : <Icon name="chevronUp" />
                }
            </span>
            }
        </Button>
    );
};

CollapseToggleLink.propTypes = {
    collapseLabel: PropTypes.string.isRequired,
    expandLabel: PropTypes.string.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    hideIcon: PropTypes.bool,
    block: PropTypes.bool
};

export default CollapseToggleLink;
