import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Checkbox from '../../Forms/Checkbox';
import Input from '../../Forms/Input';
import InputGroup from '../../Forms/InputGroup';

export const MODE_AS_MAIN_CONTACT = 'asMainContact';
export const MODE_AS_STANDARD_CONTACT = 'asStandardContact';

export const CustomerContactFormContent = ({ mode, isMainContact }) => {

    const renderMainContactCheckbox = () => {

        if (mode !== MODE_AS_STANDARD_CONTACT) {
            return null;
        }

        return (
            <Fragment>
                {isMainContact ?
                    <InputGroup
                        label="Hauptkontakt"
                    >
                        <div><strong>Als Hauptkontakt festgelegt</strong></div>
                    </InputGroup>
                    :
                    <InputGroup
                        label="Hauptkontakt"
                    >
                        <Checkbox
                            label="Diesen Ansprechpartner als Hauptkontakt festlegen"
                            name="isMainContact"
                        />
                    </InputGroup>
                }
            </Fragment>

        );

    };

    return (
        <Fragment>
            <InputGroup
                label="Vorname*"
            >
                <Input
                    type="text"
                    name="contactPersonFirstName"
                />
            </InputGroup>

            <InputGroup
                label="Nachname*"
            >
                <Input
                    type="text"
                    name="contactPersonLastName"
                />
            </InputGroup>
            <InputGroup
                label="Telefon"
            >
                <Input
                    type="text"
                    name="contactPersonPhoneNumber"
                />
            </InputGroup>
            <InputGroup
                label="Telefon (mobil)"
            >
                <Input
                    type="text"
                    name="contactPersonMobilePhoneNumber"
                />
            </InputGroup>
            <InputGroup
                label="E-Mail Adresse"
            >
                <Input
                    type="email"
                    name="contactPersonEmail"
                />
            </InputGroup>
            <InputGroup
                label="Position"
            >
                <Input
                    type="text"
                    name="contactPersonPosition"
                />

            </InputGroup>

            {renderMainContactCheckbox()}

        </Fragment>
    );
};

CustomerContactFormContent.propTypes = {
    mode: PropTypes.oneOf([MODE_AS_MAIN_CONTACT, MODE_AS_STANDARD_CONTACT]),
    values: PropTypes.object
};

CustomerContactFormContent.defaultProps = {
    mode: MODE_AS_STANDARD_CONTACT
};


