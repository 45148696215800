import { getModelMediaTypeVisibilities } from '../../../../../Forms/Media/utils';
import { MediaVisibility, ModelMediaType } from '../../../../../Media/properties';

export const mapMediaTypeToVisibilityOptions = mediaType => {

    switch (mediaType) {
        case 'anchorProtocol':
            return [MediaVisibility.Public];

        case 'attachment':
            return [MediaVisibility.Public, MediaVisibility.AccountPrivate];

        default:
            return [MediaVisibility.AccountPrivate];
    }
};
export const getDefaultVisibilitySelection = (value,isLiveRessource, wasEdited = false, referencedVisibility = null, modelMediaType) => {

    if(!value){
        return;
    }

    // Live ressource: use exisiting visibility
    if(value && isLiveRessource){
        return value.visibility;
    }

    // Media was edited: use exisiting visibility
    if(value && !isLiveRessource && wasEdited && referencedVisibility){
        return referencedVisibility
    }

    switch (modelMediaType) {
        case ModelMediaType.Site_Attachment:
        case ModelMediaType.Scaffolding_Attachment:
        case ModelMediaType.Section_Attachment:
            return MediaVisibility.AccountPrivate;
        default:
            return MediaVisibility.Public;
    }
};
export const getAvailableVisibilityOptions = (value, isLiveRessource = false, modelMediaType) => {

    if (!value) {
        return [];
    }

    if(isLiveRessource && value.visibility){
        return [value.visibility];
    }

    return getModelMediaTypeVisibilities(modelMediaType);


};

