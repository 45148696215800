import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SyncInputGroup from '../SyncInputGroup';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceIdenticalRow from '../SyncChoiceIdenticalRow';
import { validateListChoiceComplete } from '../../../validation';
import { getDiffMediaListOptionRows, getIdenticalMediaListOptionRows } from './function';
import MediaSyncChoiceDiffOptionRows from './MediaSyncChoiceDiffOptionRows';

const validateMediaListSyncChoice = validateListChoiceComplete();

const MediaListSyncChoice = ({ label, modelMediaType, planValue, liveValue, value: currentFormValue, onChange, meta, showIdentical }) => {

    const onSelect = (selectedValue) => {
        const newValue = {
            ...currentFormValue,
            ...selectedValue
        };
        onChange(newValue);
    };

    const renderIdenticalMediaLabel = (valueObject) => {
        if (!valueObject) {
            return '-';
        }
        return Object.values(valueObject)[0].name;
    };

    const renderIdenticalRows = () => {

        const identicalOptionRows = getIdenticalMediaListOptionRows(planValue, liveValue);

        if (_.isEmpty(identicalOptionRows)) {
            return (
                <SyncChoiceIdenticalRow
                    leftValue={null}
                    rightValue={null}
                    renderValue={renderIdenticalMediaLabel}
                />
            )
        }

        return identicalOptionRows.map(optionRow => (
            <SyncChoiceIdenticalRow
                key={optionRow.key}
                leftValue={optionRow.left}
                rightValue={optionRow.right}
                renderValue={renderIdenticalMediaLabel}
            />
        ));
    };

    const isIdentical = _.isEqual(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label={label}
        >
            {showIdentical && renderIdenticalRows()}

            <MediaSyncChoiceDiffOptionRows
                planValue={planValue}
                liveValue={liveValue}
                getOptionRows={getDiffMediaListOptionRows}
                onSelect={onSelect}
                currentFormValue={currentFormValue}
                modelMediaType={modelMediaType}
            />
        </SyncInputGroup>
    );
};

MediaListSyncChoice.propTypes = {
    name: PropTypes.string,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    showIdentical: PropTypes.bool
};

MediaListSyncChoice.defaultProps = {
    planValue: null,
    liveValue: null,
    value: null,
    onChange: () => false
};

export default asSyncChoiceField(MediaListSyncChoice, {
    allowNull: true,
    validate: validateMediaListSyncChoice
});
