import React from 'react';

const UpdateAvailableBanner = () => (
    <div className="update-banner update-banner--update-available">
        <div className="update-banner__cnt">
            Eine neue Version von Scaffeye® wurde im Hintergrund geladen. Bitte
            schließen Sie alle Scaffeye-Fenster und öffnen Sie die Applikation
            anschließend wieder, um direkt Zugriff auf die neue Version zu erhalten.
        </div>
    </div>
);

export default UpdateAvailableBanner;

