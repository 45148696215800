import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

export const ResourcePageType = {
    Request: 'request',
    Job: 'job',
    Site: 'site',
    Scaffolding: 'scaffolding',
    Section: 'section',
    Customer: 'customer',
    DiaryEntry: 'diaryEntry'
};

function getBaseUrl(resourcePageType) {
    switch (resourcePageType) {
        case ResourcePageType.Request:
            return '/requests';
        case ResourcePageType.Job:
            return '/jobs';
        case ResourcePageType.Site:
            return '/sites';
        case ResourcePageType.Scaffolding:
            return '/scaffoldings';
        case ResourcePageType.Section:
            return '/sections';
        case ResourcePageType.Customer:
            return '/customers';
        case ResourcePageType.DiaryEntry:
            return '/diary';
        default:
            return '/';
    }
}

function getUrl(resourcePageType, id) {
    return `${getBaseUrl(resourcePageType)}/${id}`;
}

export const goToResourcePage = (history, resourcePageType, id) => {
    const url = getUrl(resourcePageType, id);
    history.push(url, {
        ...history.location.state,
        [`${resourcePageType}Id`]: id
    });
};

function handleClick(e, history, resourcePageType, id) {
    e.preventDefault();
    goToResourcePage(history, resourcePageType, id);
}

const ResourcePageLink = ({ resourcePageType, id, children, history, staticContext, ...props }) => {
    const baseUrl = getBaseUrl(resourcePageType);

    //removes match and location as props from <a> to prevent render failure
    const {match, location, ...restProps} = props;

    let href= baseUrl;
    if(id) {
        href = `${href}/${id}`;
    }

    return (
        <a
            href={href}
            onClick={(e) => handleClick(e, history, resourcePageType, id)}
            {...restProps}
        >
            {children}
        </a>
    );
};

ResourcePageLink.propTypes = {
    resourcePageType: PropTypes.oneOf([
        ResourcePageType.Request,
        ResourcePageType.Job,
        ResourcePageType.Site,
        ResourcePageType.Scaffolding,
        ResourcePageType.Section,
        ResourcePageType.Customer,
        ResourcePageType.DiaryEntry
    ]).isRequired,
    id: PropTypes.string
};

const ResourcePageLinkWithRouter = withRouter(ResourcePageLink);

export const JobPageLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id} resourcePageType={ResourcePageType.Job} {...props} />
);

export const RequestPageLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id} resourcePageType={ResourcePageType.Request} {...props} />
);

export const DiaryEntryLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id} resourcePageType={ResourcePageType.DiaryEntry} {...props} />
);

export const SitePageLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id}
                                resourcePageType={ResourcePageType.Site} {...props} />
);

export const ScaffoldingPageLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id}
                                resourcePageType={ResourcePageType.Scaffolding} {...props} />
);

export const SectionPageLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id}
                                resourcePageType={ResourcePageType.Section} {...props} />
);

export const CustomerPageLink = ({ id, ...props }) => (
    <ResourcePageLinkWithRouter id={id} resourcePageType={ResourcePageType.Customer} {...props} />
);
