import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import { showApiError } from '../../../redux/modules/error/action';
import Icon from '../Icon';

const ActionTypes = {
    clear: 'clear',
    createOption: 'create-option',
    deselectOption: 'deselect-option',
    popValue: 'pop-value',
    removeValue: 'remove-value',
    selectOption: 'select-option',
    setValue: ' set-value'
};

const DEBOUNCE_TIMEOUT = 500;

class AutocompleteSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            itemSelected: false,
            isInitialized: false
        };

        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.internalSelectionChange = this.internalSelectionChange.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.debouncedFetchOptions = _.debounce(this.fetchOptions, DEBOUNCE_TIMEOUT);
    }

    componentDidMount() {
        if (this.props.initialOption) {
            this.setState({
                itemSelected: true,
                isInitialized: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.initialOption !== this.props.initialOption && this.props.initialOption === null) {
            this.setState({
                itemSelected: false,
                isInitialized: false
            });
        }
    }

    onMenuOpen() {
        this.setState({
            menuOpen: true
        });
    }

    onMenuClose() {
        this.setState({
            menuOpen: false
        });
    }

    getOptions(input, callback) {
        return this.debouncedFetchOptions(input, callback);
    }

    toggleMenu() {
        this.setState(prevState => ({
            isInitialized: true,
            menuOpen: !prevState.menuOpen
        }));
    }

    fetchOptions(input, callback) {
        let querystring = '';

        if (input) {
            querystring = input;
        }

        // remove ' + " from input
        const preparedInput = querystring.replace(/['"]+/g, '');

        this.props.getValues(preparedInput)
            .then((options) => {
                const opts = options.map(option => ({
                    label: option.name,
                    value: option.name
                }));
                callback(opts);
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    internalSelectionChange(selectedValue, actionType) {
        if (ActionTypes.selectOption === actionType.action) {
            this.setState({
                itemSelected: true
            });
        } else {
            this.setState({
                itemSelected: false
            });
        }
        this.props.onSelectionChange(selectedValue);
    }

    render() {
        const { itemSelected, menuOpen, isInitialized } = this.state;

        let wrapperClasses = ['filterbar__autocomplete'];

        if (menuOpen) {
            wrapperClasses.push('filterbar__is-expanded');
        } else {
            wrapperClasses = wrapperClasses.filter(item => item !== 'filterbar__is-expanded');
        }

        if (itemSelected) {
            wrapperClasses.push('filterbar__item-selected');
        }

        return (
            <label className={wrapperClasses.join(' ')}>
                <div
                    onClick={this.toggleMenu}
                    className="filterbar__autocomplete-label"
                >
                    {this.props.label}
                    <span className="icon">
                        <Icon name="reactselectChevron" />
                    </span>
                </div>
                {isInitialized ?
                    <AsyncSelect
                        onChange={this.internalSelectionChange}
                        loadOptions={this.getOptions}
                        isClearable
                        value={this.props.initialOption}
                        isMulti={false}
                        defaultOptions
                        classNamePrefix="filterbar"
                        loadingMessage={this.props.loadingMessage}
                        noOptionsMessage={this.props.noOptionsMessage}
                        onMenuOpen={this.onMenuOpen}
                        onMenuClose={this.onMenuClose}
                        menuIsOpen={menuOpen}
                        backspaceRemovesValue={false}
                        placeholder={this.props.placeholder}
                    />
                    :
                    null
                }
            </label>
        );
    }

}

export default AutocompleteSelect;

AutocompleteSelect.propTypes = {
    getValues: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    loadingMessage: PropTypes.func,
    noOptionsMessage: PropTypes.func,
    initialOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

AutocompleteSelect.defaultProps = {
    noOptionsMessage: () => 'Wir konnten keine Ergebnisse für Ihre Eingabe finden',
    loadingMessage: () => 'Lade Ergebnisse',
    initialOption: null
};
