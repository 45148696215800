import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

export default class WizardForm extends Component {

    static Page({ children }) {return children};

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        };
    }

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
            ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = values => {

        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;

        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.next(values);
        }
    };

    renderNavigation = () => {
        const { navigationalLinks } = this.props;
        const { page } = this.state;

        const links = navigationalLinks.map((linkLabel, index) => {

            const classes = index === page ? 'active' : '';

            return (
                <li key={index} className={classes}> {linkLabel} </li>
            );
        });

        return (
            <ul className="registration-form__navigation">
                {links}
            </ul>
        );
    };

    render() {
        const { children, isSending } = this.props;
        const { page } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        return (
            <Form
                validate={this.validate}
                onSubmit={this.handleSubmit}
            >
                {({ handleSubmit, submitError }) => (
                    <div className="section">
                        <h2 className="registration-form__heading">Registrierung</h2>
                        <form onSubmit={handleSubmit}>
                            {this.renderNavigation()}
                            {submitError && <div className="form-error">{submitError}</div>}
                            <div className="registration-form__content">
                                { activePage }
                            </div>
                            <div className="registration-form__buttons">
                                <div>
                                    {page > 0 && (
                                        <button className="btn btn--cancel" type="button"
                                                onClick={this.previous}
                                        >
                                            Zurück
                                        </button>
                                    )}
                                    <span className="btn btn--dummy">*Pflichtfeld</span>
                                </div>

                                {!isLastPage &&
                                <button className="btn btn--save" type="submit">Weiter</button>}

                                {isLastPage && (
                                    <button className="btn btn--save" type="submit"
                                            disabled={isSending}
                                    >
                                        Jetzt registrieren
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                )}
            </Form>
        );
    }
}

WizardForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

