import _ from 'lodash';
import moment from 'moment';
import { JobType } from '../../../Components/Job/properties';
import { requestIsActive, requestIsNotifiable } from '../../../Components/Job/utils';
import DateService from '../../../Services/DateService';
import { query } from '../../action';

/*
 * List
 */

const isRelevantSectionStatus = status => _.indexOf(['pending', 'draft'], status) < 0;

const isWithinTimeframe = (statusChanged) => {
    if (!statusChanged) {
        return false;
    }

    return moment(statusChanged)
        .format() >= moment()
        .subtract(7, 'days')
        .format();
};

const getStatusChanges = (sites) => {
    const sitesWithStatusChange = [];

    sites.forEach((site) => {
        site.scaffoldings.forEach((scaffolding) => {
            scaffolding.sections.forEach((section) => {
                if (isRelevantSectionStatus(section.status) && isWithinTimeframe(section.statusChanged)) {
                    sitesWithStatusChange.push({
                        site: {
                            id: site.id,
                            name: site.name,
                            isThirdParty: site.isThirdParty,
                            isInvited: site.isInvited
                        },
                        publicStatus: section.publicStatus,
                        scaffolding: {
                            id: scaffolding.id,
                            name: scaffolding.name,
                            isThirdParty: scaffolding.isThirdParty,
                            isInvited: scaffolding.isInvited
                        },
                        statusChanged: section.statusChanged,
                        section: {
                            id: section.id,
                            name: section.name,
                            status: section.status,
                            publicStatus: section.publicStatus,
                            isThirdParty: section.isThirdParty,
                            isInvited: section.isInvited,
                            managed: section.managed,
                        }
                    });
                }
            });
        });
    });

    const orderedSitesWithStatusChange = _.sortBy(sitesWithStatusChange, site => (moment(site.statusChanged)))
        .reverse();

    return orderedSitesWithStatusChange;
};

const getInspectionWarnings = (sites) => {
    const sitesWithWarnings = [];

    // Warnings
    sites.forEach((site) => {
        if (site.scaffoldings) {
            site.scaffoldings.forEach((scaffolding) => {
                if (scaffolding.sections) {
                    scaffolding.sections.forEach((section) => {
                        if (section.inspections) {
                            section.inspections.forEach((inspection) => {
                                if (!inspection.success && (moment(inspection.completed)
                                    .format() >= moment()
                                    .subtract(7, 'days')
                                    .format())) {
                                    sitesWithWarnings.push({
                                        site: {
                                            id: site.id,
                                            name: site.name,
                                            isThirdParty: site.isThirdParty,
                                            isInvited: site.isInvited
                                        },
                                        inspection,
                                        scaffolding: {
                                            id: scaffolding.id,
                                            name: scaffolding.name,
                                            isThirdParty: scaffolding.isThirdParty,
                                            isInvited: scaffolding.isInvited
                                        },
                                        section: {
                                            id: section.id,
                                            name: section.name,
                                            isThirdParty: section.isThirdParty,
                                            isInvited: section.isInvited
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
        }
    });

    const orderedsitesWithWarnings = _.sortBy(sitesWithWarnings, site => (moment(site.inspection.completed)))
        .reverse();

    return orderedsitesWithWarnings;
};

const getNewComments = (sites) => {
    const sitesWithNewComments = [];

    // Notes
    sites.forEach((site) => {
        if (site.notes && site.notes.length > 0) {
            site.notes.forEach((note) => {
                if (moment(note.created)
                    .format() >= moment()
                    .subtract(7, 'days')
                    .format()) {
                    sitesWithNewComments.push({
                        site: {
                            id: site.id,
                            name: site.name
                        },
                        note,
                        noteCreated: note.created
                    });
                }
            });
        }
    });

    const orderedSitesWithNewComments = _.sortBy(sitesWithNewComments, site => (moment(site.noteCreated)))
        .reverse();

    return orderedSitesWithNewComments;
};

export function fetchDashboardJobs() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const args = {
            page: 1,
            limit: -1
        };

        const q = `DashboardJobs($page:Int, $limit:Int, $includeArchived:Boolean, $includeActive:Boolean) {
                jobs(limit:$limit,page:$page, includeArchived: $includeArchived, includeActive: $includeActive ) {
                    data {
                        status,
                        type,
                        offerDeadline
                    },
                    total,
                    per_page
                }
            }`;

        return query(q, {
            token,
            params: {
                page: args.page,
                limit: args.limit,
                includeArchived: false,
                includeActive: true
            }
        })
            .then((result) => {
                const jobs = result.jobs.data;
                let activeRequestsCount = 0;
                let activeJobsCount = 0;
                let requestsWithExpiringOfferDeadlineCount = 0;

                _.each(jobs, (jobOrRequest) => {
                    if (!jobOrRequest.status || !jobOrRequest.type) {
                        return;
                    }

                    // "Auftrag"
                    if (jobOrRequest.type === JobType.Job) {
                        activeJobsCount += 1;

                        // "Anfrage"
                    } else if (jobOrRequest.type === JobType.Request && requestIsActive(jobOrRequest.status)) {

                        activeRequestsCount += 1;

                        // hint reg. an expiring offer deadline
                        if (jobOrRequest.offerDeadline && DateService.isWithinNext(jobOrRequest.offerDeadline, 3) && requestIsNotifiable(jobOrRequest.status)) {
                            requestsWithExpiringOfferDeadlineCount += 1;
                        }
                    }
                });

                return ({
                    activeRequestsCount,
                    activeJobsCount,
                    requestsWithExpiringOfferDeadlineCount
                });
            });
    };
}

export function fetchDashboardSites() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const args = {
            page: 1,
            limit: -1
        };

        const siteInclusionOptions = getSiteInclusionOptions(SitesListType.ListOfActiveSites);

        // fetch all sites
        const q = `DashboardSites($page:Int,$limit:Int){
            sites_v2(limit:$limit,page:$page, ${siteInclusionOptions}){
                data{
                    ... on Site {
                        id,
                        name,
                        address{id,line1,line2,city,zip},
                        notes{id,content,attachments{mimeType},updated,created,createdBy{id,firstName,lastName}}
                        scaffoldings{
                            id,
                            name,
                            sections{
                                id,
                                name,
                                status,
                                created,
                                updated,
                                isThirdParty,
                                managed,
                                statusChanged,
                                publicStatus,
                                inspections(last:1) {
                                    ... on Inspection {
                                        id,success,completed,isThirdParty,deficiencyCount
                                    }
                                    ... on ThirdPartyInspection {
                                        id,success,completed,isThirdParty,deficiencyCount
                                    }
                                }
                            },
                            isThirdParty
                        },
                        isThirdParty
                    }
                    ... on ThirdPartySite {
                        id,
                        name,
                        address{id,line1,line2,city,zip},
                        scaffoldings {
                            id,
                            name,
                            sections{
                                id,
                                name,
                                updated,
                                isThirdParty,
                                statusChanged,
                                publicStatus,
                                inspections(last:1) {
                                    ... on Inspection {
                                        id,success,completed,isThirdParty,deficiencyCount
                                    }
                                    ... on ThirdPartyInspection {
                                        id,success,completed,isThirdParty,deficiencyCount
                                    }
                                }
                            },
                            isThirdParty,
                            isInvited
                        },
                        isThirdParty,
                        isInvited
                    }
                },
                total,
                per_page
            } 
        }`;

        return query(q, {
            token,
            params: {
                page: args.page,
                limit: args.limit
            }
        })
            .then((result) => {

                const sites = result.sites_v2.data;
                return ({
                    sitesWithStatusChange: getStatusChanges(sites),
                    sitesWithWarnings: getInspectionWarnings(sites),
                    sitesWithNewComments: getNewComments(sites),
                    activeSitesCount: sites.length
                });
            });
    };
}

export function fetchDashboardConstructionDiaryEntries() {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        return query(`dashboard {
                dashboard {recentDiaryEntries {id,scaffoldings {id, name},site{id, name},updated,updatedBy {firstName, lastName},scaffoldingSelection,sections {id, name},sectionSelection,category,createdBy {firstName, lastName},created}}
            }`, {
            token
        })
            .then(data => data.dashboard.recentDiaryEntries);
    };
}

export const SitesListType = {
    ListOfActiveSites: 'list_of_active_sites',
    ListOfActiveAndProprietarySites: 'list_of_active_and_proprietary_sites',
    ListOfArchivedSites: 'list_of_archived_sites'
};

export function getSiteInclusionOptions(sitesListType) {

    switch (sitesListType) {
        case SitesListType.ListOfArchivedSites:
            return `includeInvited:false, includeSubscribed:false, includeInspected:false, includeArchived:true, includeActive: false`;
        case SitesListType.ListOfActiveAndProprietarySites:
            return 'includeInvited:false, includeSubscribed:false, includeInspected:false, includeArchived:false, includeActive: true';
        case SitesListType.ListOfActiveSites:
        default:
            return 'includeInvited:true, includeSubscribed:true, includeInspected:false, includeArchived:false, includeActive: true';
    }
}


export function fetchSites(fetchParams, archivedOnly = false) {

    return (dispatch, getState) => {
        const args = {
            page: fetchParams.currentListPage || 1,
            limit: fetchParams.listItemsPerPage || 10,
            sortAsc: fetchParams.sortListBy.direction !== '-',
            sortField: fetchParams.sortListBy.field || 'name'
        };

        const siteInclusionOptions = archivedOnly ? getSiteInclusionOptions(SitesListType.ListOfArchivedSites) : getSiteInclusionOptions(SitesListType.ListOfActiveSites);
        const token = getState().currentUser.auth_token;
        return query(`FetchSites($page:Int,$limit:Int) {
                sites_v2(limit:$limit,page:$page,sort:${args.sortField},sortAsc:${args.sortAsc},${siteInclusionOptions}) {
                    data {
                        ... on Site {
                            id,name,description,created,updated,isThirdParty,archived,archivedAt,
                            address {
                                name, line1, line2, city, zip, country
                            }
                        }
                        ... on ThirdPartySite {
                            id,name,created,updated,isThirdParty,isInvited,archived,archivedAt,
                            address {
                                line1, line2, city, zip, country
                            }
                        }
                    },
                    total,
                    per_page
                }
            }`, {
            token,
            params: {
                page: args.page,
                limit: args.limit
            }
        })
            .then(({ sites_v2 }) => (sites_v2));
    };
}

export function fetchSitesSelectOptions() {
    return (dispatch, getState) => {
        const args = {
            page: 1,
            limit: -1,
            sortAsc: true,
            sortField: 'name'
        };

        const siteInclusionOptions = getSiteInclusionOptions(SitesListType.ListOfActiveAndProprietarySites);

        const token = getState().currentUser.auth_token;
        return query(`FetchSiteOptions($page:Int,$limit:Int) {
                sites_v2(limit:$limit,page:$page,sort:${args.sortField},sortAsc:${args.sortAsc},${siteInclusionOptions}) {
                    data {
                        ... on Site {
                            id,name
                        }
                        ... on ThirdPartySite {
                            id,name
                        }
                    },
                    total,
                    per_page
                }
            }`, {
            token,
            params: {
                page: args.page,
                limit: args.limit
            }
        })
            .then(({ sites_v2 }) => (sites_v2));
    };
}
