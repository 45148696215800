import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError } from '../../../redux/modules/error/action';
import { createContractor } from '../../../redux/modules/section/action';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import Input from '../../Forms/Input';
import InputGroup from '../../Forms/InputGroup';
import Section from '../../Layout/Section';

class AddContractorModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSaving: false
        };

        this.validate = this.validate.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
    }

    validate(values) {

        const errors = {};

        if (!values.name) {
            errors.name = 'Bitte geben Sie einen Namen ein';
        }

        if (values.zip && !values.zip.match(/^[0-9]{5}$/i)) {
            errors.zip = 'Bitte geben Sie eine Zahl ein';
        }

        return errors;
    }

    onSubmit(values) {
        const {
            name,
            line1,
            line2,
            zip,
            city,
            phone,
            email
        } = values;

        const dto = {};

        dto.name = name;
        dto.addressLine1 = line1;
        dto.addressLine2 = line2;
        dto.addressCity = city;
        dto.addressZip = zip;
        dto.phone = phone;
        dto.email = email;

        this.setState({ isSaving: true });

        this.props.actions.createContractor(dto)
            .then((contractor) => {
                this.setState({
                    isSaving: false
                });
                toast.success('Gerüstersteller wurde angelegt', { autoClose: 2500 });
                return contractor.id;
            })
            .catch((error) => {

                this.setState({
                    isSaving: false
                }, () => {
                    showApiError(error);
                });
            })
            .then((id) => {
                this.props.onCreated(id);
            });
    }


    renderButtonText() {
        const { isSaving } = this.state;
        return isSaving ? 'Gerüstersteller wird gespeichert ...' : 'Gerüstersteller speichern';
    }

    onClose() {
        this.props.onClose(true);
    }

    // Will be set in the render method
    handleSubmit = () => false;

    render() {
        const {
            isSaving
        } = this.state;

        return (
            <Modal
                title={'Neuer Gerüstersteller'}
                footer={
                    <ModalFormFooter
                        onSubmit={(e) => this.handleSubmit(e)}
                        isSubmitting={isSaving}
                        onClose={this.onClose}
                        submitLabel={this.renderButtonText()}
                        submitDisabled={isSaving}
                    />
                }
            >
                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    initialValues={{}}
                    render={({ handleSubmit }) => {
                        this.handleSubmit = handleSubmit;

                        return (
                            <form onSubmit={handleSubmit}>
                                <Section
                                    title="Allgemeine Angaben"
                                >
                                    <InputGroup
                                        label="Vollständiger Name*"
                                    >
                                        <Input
                                            name="name"
                                            type="text"
                                            placeholder="Name eingeben"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Addresszeile 1"
                                    >
                                        <Input
                                            name="line1"
                                            type="text"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Addresszeile 2"
                                    >
                                        <Input
                                            name="line2"
                                            type="text"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Postleitzahl, Ort"
                                        horizontal="true"
                                    >
                                        <div className="input-grp__field--zip">
                                            <Input
                                                type="text"
                                                name="zip"
                                                placeholder="PLZ"
                                            />
                                        </div>
                                        <div className="input-grp__field--city">
                                            <Input
                                                type="text"
                                                name="city"
                                                placeholder="Ort"
                                            />
                                        </div>
                                    </InputGroup>
                                </Section>

                                <Section
                                    title="Kontakt"
                                >
                                    <InputGroup
                                        label="Telefon"
                                    >
                                        <Input
                                            name="phone"
                                            type="text"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="E-Mail Adresse"
                                    >
                                        <Input
                                            name="email"
                                            type="email"
                                        />
                                    </InputGroup>
                                </Section>
                            </form>
                        )
                    }}
                />
            </Modal>
        );
    }

}

AddContractorModal.propTypes = {
    onCreated: PropTypes.func,
    onClose: PropTypes.func
};

AddContractorModal.defaultProps = {
    onCreated: null,
    onClose: () => false
};


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        createContractor
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(AddContractorModal);
