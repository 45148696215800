import * as types from '../../types';

const initialState = {

    isLoggingIn: false,

    /*
     * Loading spinner flag
     */
    isLoading: false,

    /*
     * Sign in flag
     */
    loggedIn: false,

    /*
     Refresh token
     */
    refreshTokenValid: undefined,


    /*
     * API tokens
     */
    auth_token: null,
    expiration_time: null,

    /*
     * User information
     */
    usermeta: {
        firstName: null,
        lastName: null,
        email: null,
        account: null,
        permissions: null,
        acceptedAdv: null,
        type: null
    },

    /*
    * Requesting refresh token
    */
    refreshing_auth_token: false,

    /*
    * Expiration Modal
    */
    expirationModalIsOpen: false,

    errors: []
};


export const userInitialState = initialState;

export default function userLoginReducer(state = userInitialState, action) {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                isLoggingIn: true,
                errors: null
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                auth_token: action.payload.access_token,
                expiration_time: action.payload.expiration_time,
                loggedIn: true,
                refreshTokenValid: true,
                isLoggingIn: false,
                errors: null
            };

        case types.LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                errors: action.payload
            };

        case types.FETCH_LOAD_SPINNER:
            return {
                ...state,
                isLoading: action.payload
            };
        case types.FETCH_USER_SUCCESS:
            return {
                ...state,
                usermeta: { ...action.payload }
            };
        case types.FETCH_USER_FAILURE:
            return {
                ...state,
                errors: action.error
            };
        case types.LOGOUT:
            return {
                ...initialState
            };

        case types.REFRESH_TOKEN_REQUESTING:
            return {
                ...state,
                refreshing_auth_token: true
            };
        case types.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                refreshing_auth_token: false,
                refreshTokenValid: true,
                auth_token: action.payload.access_token,
                expiration_time: action.payload.expiration_time
            };

        case types.REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                refreshing_auth_token: false,
                loggedIn: false,
                errors: action.payload,
                refreshTokenValid: false
            };

        case types.TEST_PERIOD_HAS_EXPIRED:
            return {
                ...state,
                usermeta: {
                    ...state.usermeta,
                    account: {
                        ...state.usermeta.account,
                        testPeriodHasExpired: true
                    }
                }
            };

        case types.MAIN_SUBSCRIPTION_HAS_EXPIRED:
            return {
                ...state,
                expirationModalIsOpen: true,
                usermeta: {
                    ...state.usermeta,
                    account: {
                        ...state.usermeta.account,
                        mainSubscriptionHasExpired: true
                    }
                }
            };

        case types.HIDE_EXPIRATION_MODAL:
            return {
                ...state,
                expirationModalIsOpen: false
            };

        default:
            return state;
    }
}
