import PropTypes from 'prop-types';
import React, { Component } from 'react';

class DashboardTable extends Component {

    constructor(props) {
        super(props);
        this.originalLimit = this.props.limit;
        this.state = {
            limit: this.props.limit > this.props.rows.length ? false : this.props.limit,
            showToggleButton: this.props.rows.length > this.props.limit,
            listIsExpanded: false
        };

        this.renderTableHeader = this.renderTableHeader.bind(this);
        this.renderTableBody = this.renderTableBody.bind(this);
        this.toggleList = this.toggleList.bind(this);
    }

    toggleList() {
        this.setState(prevState => ({
            limit: prevState.limit ? false : this.originalLimit,
            listIsExpanded: !prevState.listIsExpanded
        }));
    }

    renderTableHeader() {
        return this.props.columns.map((col, i) => <th key={i}>{col}</th>);
    }

    renderTableBody() {
        const { rows, children, emptyComponent } = this.props;
        const { limit } = this.state;

        if (!rows || rows.length < 1) {
            return emptyComponent;
        }

        return rows.map((row, i) => {
            if (limit) {
                return i < limit && children(row, i);
            }
            return children(row, i);
        });
    }

    render() {
        const { showToggleButton, listIsExpanded } = this.state;
        const { className, footerLink, rows } = this.props;

        const wrapperClasses = ['content-table'];

        if (className) {
            wrapperClasses.push(className);
        }

        return (
            <div className={wrapperClasses.join(' ')}>
                <table cellPadding="0" cellSpacing="0" className="hover stack">
                    <thead>
                        <tr>
                            {this.renderTableHeader()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableBody()}
                    </tbody>
                </table>
                {(showToggleButton && !footerLink) && <span
                    className="table__footer-link"
                    onClick={this.toggleList}
                > {listIsExpanded ? 'Weniger Einträge anzeigen' : 'Weitere Einträge anzeigen'}
                </span>}
                { rows.length > 0 && footerLink}
            </div>
        );
    }

}

export default DashboardTable;

DashboardTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.array.isRequired,
    children: PropTypes.func.isRequired,
    emptyComponent: PropTypes.element.isRequired,
    className: PropTypes.string,
    footerLink: PropTypes.node
};

DashboardTable.defaultProps = {
    className: '',
    footerLink: null
};
