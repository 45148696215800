import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormMode } from '../Forms/properties';

const getInitialState = (props) => {
    return {
        showEditModal: false,
        isUpdating: false
    };
};

class EditResourceLink extends Component {

    constructor(props) {
        super(props);
        this.state = getInitialState(props);
        this.renderEditModal = this.renderEditModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.onInternalSuccesfulEdit = this.onInternalSuccesfulEdit.bind(this);
    }

    toggleEditModal() {
        this.setState(prevState => ({
            showEditModal: !prevState.showEditModal
        }));
    }

    onInternalSuccesfulEdit() {
        const { onSuccessfulEdit } = this.props;

        this.toggleEditModal();
        onSuccessfulEdit();
    }

    renderEditModal() {
        const { editModalComponent: ModalComponent, modalExtraProps, resourceId } = this.props;
        const { showEditModal } = this.state;

        if (!showEditModal) {
            return null;
        }

        return (
            <ModalComponent
                onCreated={this.onInternalSuccesfulEdit}
                onClose={this.toggleEditModal}
                mode={FormMode.Edit}
                id={resourceId}
                {...modalExtraProps}
            />
        );
    }

    renderLink() {
        const {
            editBtnLabel
        } = this.props;

        return (
            <a role="button" onClick={this.toggleEditModal}> {editBtnLabel}</a>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderLink()}
                {this.renderEditModal()}
            </Fragment>
        );
    }
}

export default EditResourceLink;

EditResourceLink.propTypes = {
    resourceId: PropTypes.string.isRequired,
    editModalComponent: PropTypes.func.isRequired,
    onSuccessfulEdit: PropTypes.func,
    editBtnLabel: PropTypes.string,
    modalExtraProps: PropTypes.object
};

EditResourceLink.defaultProps = {
    editBtnLabel: 'Bearbeiten',
    modalExtraProps: {}
};

