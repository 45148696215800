import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchSitesSelectOptions } from '../../redux/modules/sites/action';
import Select from '../Forms/Select';

class SiteSelect extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            choices: [],
            isFetching: true
        };

        this.onChange = this.onChange.bind(this);
        this.updateOnFetching = this.updateOnFetching.bind(this);
    }

    componentWillMount() {
        this.loadOptions();
    }

    loadOptions() {

        if (this.loadingGotCancelled) {
            return;
        }

        this.setState({
            isFetching: true
        }, this.updateOnFetching);

        this.props.actions.fetchSitesSelectOptions()
            .then((_sites) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                const sites = _sites || [];
                const choices = sites.data.map(site => ({
                    value: site.id,
                    label: site.name,
                }));
                this.setState({
                    choices
                });
            })
            .catch(() => {
                toast.error('Baustellen konnten nicht geladen werden', { autoClose: 4000 });
            })
            .then(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isFetching: false
                }, this.updateOnFetching);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    updateOnFetching() {
        const { onFetching } = this.props;
        if (typeof onFetching === 'function') {
            onFetching(this.state.isFetching);
        }
    }

    onChange(value) {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    render() {
        const {
            isFetching,
            choices
        } = this.state;

        return (
            <Select
                name={this.props.name}
                placeholder={isFetching ? 'Lade Baustellen ...' : 'Baustelle auswählen'}
                options={choices}
                disabled={isFetching}
                onChange={this.onChange}
            />
        );
    }

}

SiteSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onFetching: PropTypes.func,
    onChange: PropTypes.func
};

SiteSelect.defaultProps = {
    onFetching: null,
    onChange: null
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSitesSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(SiteSelect);
