import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DataRow from '../../Common/DataRow';
import Icon from '../../Common/Icon';
import MultiLineText from '../../Common/MultiLineText';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import { SectionReference } from '../../Common/ObjectReference';
import {
    showCladdings,
    showConstructionType,
    showEquippedSurfaces,
    showLoadClass,
    showPlannedWorks,
    showSpecialFeatures,
    showStabilityAndLoadSafety,
    showSupportType,
    showWallClearance,
    showWidthClass
} from '../../Sections/helpers';
import { SectionPurpose } from '../../Sections/SectionPurpose';
import { ConstructionPlanFormSectionPropType } from '../Form/propTypes';
import { getData } from '../utils';
import ConstructionPlanModelName from './ConstructionPlanModelName';

class ConstructionPlanSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sectionDetailsAreCollapsed: true
        };
    }

    getData(field, fallback = <NotAvailablePlaceholder />) {
        const { section, referenceData } = this.props;
        return getData(section, referenceData, field, fallback);
    }

    toggleCollapsibleContent() {

        this.setState(prevState => {
            return {
                sectionDetailsAreCollapsed: !prevState.sectionDetailsAreCollapsed
            };
        });
    }

    renderData() {

        const { section } = this.props;
        const { mode } = section;

        const name = this.getData('name');

        // section data
        const equippedSurfaces = this.getData('equippedSurfaces', null);
        const plannedWorks = this.getData('plannedWorks', null);
        const supportType = this.getData('supportType', null);
        const constructionType = this.getData('constructionType', null);
        const specialConstruction = this.getData('specialConstruction', false);
        const staticsCertificateNecessary = this.getData('staticsCertificateNecessary', false);
        const loadClass = this.getData('loadClass', null);
        const wallClearance = this.getData('wallClearance', null);
        const widthClass = this.getData('widthClass', null);
        const claddings = this.getData('claddings', null);
        const specialFeatures = this.getData('specialFeatures', null);
        const description = this.getData('description', null);
        const purposes = this.getData('purposes', null);
        const otherProtectivePurpose = this.getData('otherProtectivePurpose', null);
        const customer = this.getData('customer', null);

        const { sectionDetailsAreCollapsed } = this.state;

        const wrapperClasses = ['job-constructionplan-details__single-section'];

        if (sectionDetailsAreCollapsed) {
            wrapperClasses.push('job-constructionplan-details__single-section--isCollapsed');
        }

        const collapseToggleButtonLabel = sectionDetailsAreCollapsed ? 'Mehr Informationen anzeigen' : 'Weniger Informationen anzeigen';

        return (
            <div className={wrapperClasses.join(' ')}>

                <DataRow label="Gerüstabschnitt">
                    <ConstructionPlanModelName
                        id={section.id}
                        name={name}
                        mode={mode}
                        ReferenceComponent={SectionReference}
                    />
                </DataRow>

                <DataRow
                    label="Eingerüstete Flächen"
                    isSubRow
                >
                    {equippedSurfaces && showEquippedSurfaces(equippedSurfaces)}
                </DataRow>

                <DataRow
                    label="Verwendungszweck"
                    isSubRow
                >
                    <SectionPurpose purposes={purposes} otherProtectivePurpose={otherProtectivePurpose} mode="inline" />
                </DataRow>

                <DataRow
                    label="Geplante Arbeiten"
                    isSubRow
                >
                    {showPlannedWorks(plannedWorks)}
                </DataRow>

                <div className="job-constructionplan-details__single-section-collapsible-content">

                    <DataRow
                        label="Auftraggeber"
                        isSubRow
                    >
                        {customer}
                    </DataRow>

                    <DataRow
                        label="Tragsystem"
                        isSubRow
                    >
                        {showSupportType(supportType)}
                    </DataRow>

                    <DataRow
                        label="Ausführungsart"
                        isSubRow
                    >
                        {showConstructionType(constructionType)}
                    </DataRow>

                    <DataRow
                        label="Stand- und Tragsicherheit"
                        isSubRow
                    >
                        {showStabilityAndLoadSafety({specialConstruction, staticsCertificateNecessary})}
                    </DataRow>

                    <DataRow
                        label="Lastklasse"
                        isSubRow
                    >
                        {showLoadClass(loadClass)}
                    </DataRow>

                    <DataRow
                        label="Wandabstand"
                        isSubRow
                    >
                        {showWallClearance(wallClearance)}
                    </DataRow>

                    <DataRow
                        label="Breitenklasse"
                        isSubRow
                    >
                        {showWidthClass(widthClass)}
                    </DataRow>

                    <DataRow
                        label="Gerüstverkleidungen"
                        isSubRow
                    >
                        {showCladdings(claddings)}
                    </DataRow>

                    <DataRow
                        label="Besonderheiten"
                        isSubRow
                    >
                        {showSpecialFeatures(specialFeatures)}
                    </DataRow>

                    <DataRow
                        label="Notizen"
                        isSubRow
                    >
                        <MultiLineText text={description} />
                    </DataRow>
                </div>

                <div onClick={() => this.toggleCollapsibleContent()}
                     className="job-constructionplan-details__section-toggle"
                >
                    {collapseToggleButtonLabel} {sectionDetailsAreCollapsed ?
                    <Icon name="chevronDown" /> : <Icon name="chevronUp" />}
                </div>

            </div>
        );
    }

    render() {
        return (
            <div className="construction-plan__model construction-plan__model--section">
                {this.renderData()}
            </div>
        );
    }
}

ConstructionPlanSection.propTypes = {
    section: ConstructionPlanFormSectionPropType.isRequired,
    referenceData: PropTypes.object
};

ConstructionPlanSection.defaultProps = {
    referenceData: null
};

export default ConstructionPlanSection;
