import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import Icon from '../../Common/Icon';

export const BreadcrumbLink = ({ query, label, icon, path }) => {

    const getPath = (path) => {

        if (!path) {
            return '';
        }

        return path;
    };

    const getLabel = () => {

        if (!label) {
            return null;
        }

        return label;
    };

    const getQuery = query => {

        if (!query) {
            return '';
        }

        return query;

    };

    const getIcon = icon => {
        if (!icon) {
            return null;
        }

        return <Icon name={icon} />;
    };

    return (
        <Route
            path={path}
            exact
            children={({ match }) => {
                return (
                    <div className="breadcrumbs__menuitem">
                        <NavLink
                            to={{
                                pathname: getPath(path),
                                search: getQuery(query)
                            }}
                            className={`breadcrumbs__link ${icon ? 'has-icon' : ''}`}
                            activeClassName="breadcrumbs__link--active"
                            isActive={() => match}
                        >
                            {icon &&
                            <span className="breadcrumbs__link-icn">{getIcon(icon)}</span>}
                            <span className="breadcrumbs__link-lbl">{getLabel(label)}</span>
                        </NavLink>
                    </div>
                );
            }}
        />
    );
};

BreadcrumbLink.propTypes = {
    label: PropTypes.string.isRequired,
    query: PropTypes.string,
    icon: PropTypes.string,
    path: PropTypes.string
};

