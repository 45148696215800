import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import LoadingSpinner from '../../Page/LoadingSpinner';

export const TableBody = ({ data, collapsesAfter, itemsToShow, footerLink, RowComponent, renderEmptyTableHint, entityLabel, isFetching, noEdit, noDeletion, onItemChange, onItemDelete }) => {

    const [isCollapsed, setCollapse] = useState(true);

    const renderCollapseToggle = () => {

        if (!collapsesAfter || data.length <= collapsesAfter || collapsesAfter === 0) {
            return null;
        }

        const bntText = isCollapsed ? 'Mehr anzeigen' : 'Weniger anzeigen';

        return <tfoot>
        <tr>
            <td><span className="btn btn--link-neutral"
                      onClick={() => setCollapse(!isCollapsed)}
            >{bntText}</span></td>
        </tr>
        </tfoot>;
    };

    const renderFooterLink = () => {

        if (data.length <= itemsToShow) {
            return null;
        }

        return <tfoot>
        <tr>
            <td>{footerLink}</td>
        </tr>
        </tfoot>;
    };

    if (isFetching) {
        return (
            <tbody>
            <tr>
                <td colSpan="7">
                    <LoadingSpinner
                        label={`Lade ${entityLabel} ...`}
                        block
                    />
                </td>
            </tr>
            </tbody>
        );
    }

    if (!data || !data.length) {
        return (
            <tbody>
            {renderEmptyTableHint()}
            </tbody>
        );
    }

    const rows = data.map((dataRow, index) => {

        if ((isCollapsed && index > collapsesAfter - 1) || (index > itemsToShow - 1)) {
            return null;
        }

        return <RowComponent
            key={index}
            data={dataRow}
            noEdit={noEdit}
            noDeletion={noDeletion}
            isSingleRow={data.length === 1}
            onChange={onItemChange}
            onDelete={onItemDelete}
        />;
    });

    return (
        <Fragment>
            <tbody>{rows}</tbody>
            {footerLink && renderFooterLink()}
            {collapsesAfter && renderCollapseToggle()}
        </Fragment>
    );

};


TableBody.propTypes = {
    RowComponent: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    renderEmptyTableHint: PropTypes.func.isRequired,
    entityLabel: PropTypes.string.isRequired,
    collapsesAfter: PropTypes.number,
    noDeletion: PropTypes.bool,
    noEdit: PropTypes.bool,
    footerLink: PropTypes.element,
    itemsToShow: PropTypes.number,
    onItemChange: PropTypes.func,
    onItemDelete: PropTypes.func
};

TableBody.defaultProps = {
    onItemChange: (item) => false,
    onItemDelete: (itemId) => false
};
