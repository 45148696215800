import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import FieldError from '../../FieldError';
import MediaFieldItemList from './MediaFieldItemList';

const MediaField = ({ emptyListLabel, readOnly, isConstructionPlan, ...fieldProps }) => (
    <Field
        {...fieldProps}
        className="media-field"
        allowNull
    >
        {
            ({ input, meta }) => (
                <Fragment>
                    <MediaFieldItemList
                        media={input.value}
                        onChange={input.onChange}
                        emptyLabel={emptyListLabel}
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                    <FieldError meta={meta} />
                </Fragment>
            )
        }
    </Field>
);

MediaField.propTypes = {
    name: PropTypes.string.isRequired,
    emptyListLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    isConstructionPlan: PropTypes.bool
};

MediaField.defaultProps = {
    emptyListLabel: 'Keine Dokumente vorhanden',
    readOnly: false,
    isConstructionPlan: false
};

export default MediaField;
