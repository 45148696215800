import React from 'react';
import PropTypes from 'prop-types';

const PaginationTotal = ({total, standalone}) => (

    standalone ?
        <div style={{marginBottom: '15px'}}>
            <span className="pagination-total">
                {total} Einträge
            </span>
        </div> :
        <span className="pagination-total">
            {total} Einträge
        </span>


);

PaginationTotal.propTypes = {
    total: PropTypes.number.isRequired,
    standalone: PropTypes.bool
};

PaginationTotal.defaultProps = {
    standalone: false
}

export default PaginationTotal;