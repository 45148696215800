import createDecorator from 'final-form-focus';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../../../redux/modules/error/action';
import { fetchMediaData } from '../../../../../redux/modules/media/action';
import { fetchSiteFormData } from '../../../../../redux/modules/site/action';
import Modal from '../../../../Common/Modal/Modal';
import ModalFormFooter from '../../../../Common/ModalFormFooter';
import { collectMediaIds } from '../../../../Forms/Media/utils';
import LoadingSpinner from '../../../../Page/LoadingSpinner';
import {
    getAllInputs,
    getFormValues,
    getSubmitPayload,
    validate
} from '../../../../Sites/Form/utils';
import { ConstructionPlanModelMode, ConstructionPlanReferenceMode } from '../../../properties';
import { ConstructionPlanFormSitePropType } from '../../propTypes';
import { getMediaCollectionData } from '../../utils';
import ConstructionPlanSiteFormContent from './ConstructionPlanSiteFormContent';

const focusOnError = createDecorator(getAllInputs);

const initialFormValues = {
    id: undefined,
    mode: ConstructionPlanModelMode.Reference,
    selection: ConstructionPlanReferenceMode.Single,
    name: '',
    description: '',
    attachments: []
};

class ConstructionPlanSiteFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            site: null,
            referenceData: undefined,
            initialFormValues: {
                ...initialFormValues
            },
            isInitializing: true,
            isInitializingSiteReference: false,
            isUploading: false,
            loadingGotCancelled: false
        };

        this.initializeForm = this.initializeForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.onUploading = this.onUploading.bind(this);
        this.onModeChange = this.onModeChange.bind(this);
    }

    componentWillMount() {
        const { site } = this.props;
        const { mode, id } = site;

        //add referenced site initial
        if (mode === ConstructionPlanModelMode.Reference && !id) {
            this.initializeForm();
            return;
        }

        switch (mode) {
            case ConstructionPlanModelMode.Reference:
                this.initializeSiteReferenceForm(site.id);
                break;
            case ConstructionPlanModelMode.Edit:
                this.initializeSiteEditForm(site);
                break;
            case ConstructionPlanModelMode.New:
                this.initializeSiteNewForm(site);
                break;
            default:
                this.initializeForm();
                break;
        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    /**
     * Initializes the form based on a site reference
     * @param siteId
     * @param callback
     */
    initializeSiteReferenceForm(siteId, callback = undefined) {
        this.setState({
            isInitializingSiteReference: true
        });
        this.fetchReferenceData(siteId)
            .then(site => {
                this.setState({
                    isInitializingSiteReference: false
                }, () => {
                    this.initializeForm({
                        ...site
                    }, ConstructionPlanModelMode.Reference, callback);
                });

            })
            .catch(err => {
                showRequestError('Baustellendaten konnten nicht geladen werden', err);
            });
    }

    /**
     * Initializes the form for the given site in 'edit' mode
     * @param site
     */
    initializeSiteEditForm(site) {
        this.fetchReferenceData(site.id)
            .then(() => {
                this.initializeNewOrEditedSiteFormWithLiveMedia(site, ConstructionPlanModelMode.Edit);
            });
    }

    /**
     * Initializes the form for the given site in 'new' mode
     * @param site
     */
    initializeSiteNewForm(site) {
        this.initializeNewOrEditedSiteFormWithLiveMedia(site, ConstructionPlanModelMode.New);
    }

    /**
     * Initializes the site form with the given site but fetches media data first (which
     * is not present in the construction plan)
     *
     * @param site
     * @param mode
     */
    initializeNewOrEditedSiteFormWithLiveMedia(site, mode) {

        const siteData = {
            id: site.id,
            mode: site.mode,
            ...site.data
        };

        const { attachments } = siteData;
        const attachmentMediaIds = collectMediaIds(attachments);

        if (attachmentMediaIds.length !== 0) {
            this.props.actions.fetchMediaData(attachmentMediaIds)
                .then(media => {
                    const attachmentData = getMediaCollectionData(attachments, media, mode);
                    this.initializeForm({
                        ...siteData,
                        attachments: attachmentData
                    }, mode);
                });
        } else {
            this.initializeForm(siteData, mode);
        }
    }

    initializeForm(siteData, mode, callback) {
        if (!siteData || !siteData.id) {
            this.setState({
                referenceData: null,
                initialFormValues: { ...initialFormValues },
                isInitializing: false
            }, callback);
            return;
        }

        const formValues = getFormValues(siteData, mode);

        this.setState({
            initialFormValues: { ...formValues },
            isInitializing: false
        }, callback);
    }

    onUploading(isUploading) {
        this.setState({
            isUploading
        });
    }

    onResetSite(reset) {
        this.setState({
            isInitializing: true
        }, () => {
            this.initializeForm(null, null, reset);
        });
    }

    onSelectSite(siteId, reset) {
        this.initializeSiteReferenceForm(siteId, reset);
    }

    fetchReferenceData(siteId = null) {
        const { actions: { fetchSiteFormData } } = this.props;

        return new Promise((resolve, reject) => {
            fetchSiteFormData(siteId)
                .then((site) => {
                    this.setState({
                        referenceData: site
                    }, () => {
                        resolve(this.state.referenceData);
                    });
                })
                .catch(error => {
                    showRequestError('Baustelle konnte nicht geladen werden', error);
                    reject(error);
                });
        });
    }

    onSubmit(values) {
        const payload = getSubmitPayload(values, true);
        this.props.onUpdate(payload);
    }

    renderButtonText() {
        const { site } = this.props;

        return site.mode !== ConstructionPlanModelMode.New ? 'Baustelle auswählen' : 'Baustelle erstellen';
    }

    onModeChange(newMode, reset, setMode) {
        const { isInitializing } = this.state;
        const { site } = this.props;

        if (newMode === ConstructionPlanModelMode.Reference && !isInitializing) {
            if (site.id) {
                if (window.confirm('Möchten Sie alle Anpassungen der Baustelle verwerfen?')) {
                    this.onSelectSite(site.id, reset);
                } else {
                    setMode(ConstructionPlanModelMode.Edit);
                }
            }
        }
    }

    onClose(pristine) {

        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    getModalTitle() {
        const { site } = this.props;
        if (site.mode === ConstructionPlanModelMode.New) {
            if (!site.data || _.isEmpty(site.data)) {
                return 'Neue Baustelle erstellen';
            }
            return 'Neue Baustelle bearbeiten';
        } else if (site.mode === ConstructionPlanModelMode.Reference) {
            return 'Bestehende Baustelle wählen';
        } else {
            return 'Bestehende Baustelle bearbeiten';
        }
    }

    render() {
        const {
            initialFormValues,
            isInitializing,
            isUploading,
            isInitializingSiteReference
        } = this.state;

        if (isInitializing) {
            return (
                <Modal
                    title={this.getModalTitle()}
                    loading={true}
                    loadingLabel="Lade Baustellendaten"
                    temporary
                />
            );
        }

        const { mode } = this.props.site;

        return (
            <Form
                onSubmit={this.onSubmit}
                decorators={[focusOnError]}
                initialValues={initialFormValues}
                validate={validate}
                mutators={{
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    },
                    setMode: ([mode], state, utils) => {
                        utils.changeValue(state, 'mode', () => mode);
                    }
                }}
                render={({ values, handleSubmit, pristine, form: { mutators, reset } }) => {

                    const { id } = values;

                    // if no site is selected => disable form submit
                    const isEmptySiteSelection = !id;

                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                title={this.getModalTitle()}
                                id="site-modal"
                                autoHeight
                                classes="construction-plan-site-modal"
                                footer={
                                    <ModalFormFooter
                                        onSubmit={handleSubmit}
                                        onClose={() => this.onClose(pristine)}
                                        submitLabel={this.renderButtonText()}
                                        submitDisabled={isUploading || isEmptySiteSelection || isInitializingSiteReference}
                                    />
                                }
                            >
                                {isInitializingSiteReference &&
                                <LoadingSpinner label="Lade Baustellendaten" block />}
                                <ConstructionPlanSiteFormContent
                                    mode={mode}
                                    referenceData={{ ...this.state.referenceData }}
                                    values={{ ...values }}
                                    attachmentsMutator={mutators.setAttachments}
                                    onUploading={this.onUploading}
                                    onResetSite={() => this.onResetSite(reset)}
                                    inputDisabled={isInitializing || isUploading}
                                />

                                <OnChange name="mode">
                                    {(newMode) => this.onModeChange(newMode, reset, mutators.setMode)}
                                </OnChange>

                                <OnChange name="id">
                                    {(newId) => this.onSelectSite(newId, reset)}
                                </OnChange>
                            </Modal>
                        </form>
                    );
                }}
            />
        );
    }
}

ConstructionPlanSiteFormModal.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    site: ConstructionPlanFormSitePropType.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSiteFormData,
        fetchMediaData
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanSiteFormModal);
