import React, { Fragment } from 'react';

export const NewLineToBreak = ({ children }) => {
    if (typeof children !== 'string') {
        return children;
    }

    return children
        .split('\n')
        .map((value, i) => (
            <Fragment key={i}>
                {i > 0 && <br />}
                {value}
            </Fragment>
        ));
}
