import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DateService from '../../../Services/DateService';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import IfUser from '../../Common/IfUser';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import PrimaryColumn from '../../Common/PrimaryColumn';
import { CustomerPageLink, JobPageLink, SitePageLink } from '../../Common/ResourcePageLink';
import DeleteJobLink from '../DeleteJobLink';
import JobStatusQuickEdit from '../Form/JobStatusQuickEdit';
import { JobType } from '../properties';
import mapSectionsToScaffoldings, {
    getJobTypeByJobStatusString,
    renderScaffoldings
} from '../utils';

export const JobTableRow = ({ data: job, noDeletion, onChange, onDelete }) => {

    const internalOnStatusChange = (id, status) => {

        const changes = { status: status };

        if (getJobTypeByJobStatusString(status) === JobType.Request) {
            changes.type = JobType.Request;
        }

        onChange(job, changes);
    }

    const { customer, site, sections, isFirstOlderThanSevenDays, archived, archivedAt } = job;
    const scaffoldings = mapSectionsToScaffoldings(sections);
    const PageLink = JobPageLink;
    return (
        <Fragment>
            {isFirstOlderThanSevenDays && !archived &&
            <tr className="requests-older-than-table-row">
                <td colSpan="7">
                    Älter als 7 Tage
                </td>
            </tr>
            }

            <tr key={job.id}>
                <PrimaryColumn
                    link={<PageLink id={job.id}
                    >{DateService.getReadableDate(job.created)}</PageLink>}
                >
                    <PageLink id={job.id}>
                        Details
                    </PageLink>
                    <IfUser
                        can={['delete-jobs']}
                        and={!noDeletion}
                    >
                        <DeleteJobLink
                            id={job.id}
                            name={job.name}
                            type={JobType.Job}
                            label="Entfernen"
                            archived={archived}
                            onDeleted={() => onDelete(job.id)}
                        />
                    </IfUser>
                </PrimaryColumn>
                {!archived &&
                <td>
                    <JobStatusQuickEdit
                        job={job}
                        onUpdate={internalOnStatusChange}
                    />
                </td>
                }
                <td>
                    {job.name ?
                        <Fragment> <span> {job.name}</span> {archived && <ArchivedBadge
                            hoverContent={`Am ${DateService.getReadableDate(archivedAt)} archiviert`}
                            size="small"
                        />} </Fragment>
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
                <td>
                    {customer ?
                        <CustomerPageLink id={customer.id}>
                            {customer.name}
                        </CustomerPageLink>
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
                <td>
                    {site ?
                        <SitePageLink id={site.id}>
                            {site.name}
                            {site.archived && <ArchivedBadge size="small" />}
                        </SitePageLink>
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
                <td>
                    {scaffoldings ?
                        renderScaffoldings(scaffoldings)
                        :
                        <NotAvailablePlaceholder />
                    }
                </td>
            </tr>
        </Fragment>
    );
};

JobTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
};

JobTableRow.defaultProps = {
    onChange: (itemData, field, value) => false,
    onDelete: (itemId) => false
};
