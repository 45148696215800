import React from 'react';
import JobDetail from '../../../../Components/Job/JobDetail';
import Permission from '../../../../permissions';
import Container from '../../../Container';
import PrivatePage from '../PrivatePage';

const RequestDetailPage = ({ match, history }) => (
    <PrivatePage requiredUserPermission={Permission.ReadJobs}>
        <Container>
            <JobDetail history={history} jobId={match.params.id} isRequest />
        </Container>
    </PrivatePage>
);

export default RequestDetailPage;
