import PropTypes from 'prop-types';
import React from 'react';

const SIZE_DEFAULT = 'default';
const SIZE_LARGE = 'large';
const SIZE_SMALL = 'small';

export const Size = {
    Small: SIZE_SMALL,
    Default: SIZE_DEFAULT,
    Large: SIZE_LARGE
};

export const BACKGROUND_COLOR_WHITE = 'white';
export const BACKGROUND_COLOR_GREY = 'grey';

/**
 * load spinner while waitng response from server
 */
const LoadingSpinner = ({ size, label, color, invertedColors, backgroundColor, block }) => (
    <div
        className={[
            'loading-spinner',
            `loading-spinner--${size}`,
            invertedColors ? 'loading-spinner--inverted' : null,
            backgroundColor === BACKGROUND_COLOR_WHITE ? 'loading-spinner--bg-w' : null,
            block ? 'loading-spinner--block' : null
        ].join(' ')}
    >
        <div
            className={[
                'loading-spinner__spinner',
                color && `loading-spinner__spinner--${color}`
            ].join(' ')}
        />
        {label &&
        <div className="loading-spinner__label">{label}</div>
        }
    </div>
);

LoadingSpinner.propTypes = {
    size: PropTypes.oneOf([Size.Default, Size.Large, Size.Small]),
    invertedColors: PropTypes.bool,
    backgroundColor: PropTypes.oneOf([BACKGROUND_COLOR_WHITE, BACKGROUND_COLOR_GREY]),
    block: PropTypes.bool,
    color: PropTypes.string
};

LoadingSpinner.defaultProps = {
    size: Size.Default,
    invertedColors: false,
    backgroundColor: BACKGROUND_COLOR_GREY,
    block: false
};

export const PageLoadingSpinner = () => (
    <div className="loading-spinner-page">
        <LoadingSpinner size={Size.Large} block />
    </div>
);

export default LoadingSpinner;
