import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/es/storage';
import * as types from '../../types';

const initialState = {
    isFetchingList: false,
    listWasFetched: false,
    error: null,
    currentPage: 1,
    amountPerPage: 10,
    inspectionsList: {
        items: [],
        total: 0
    },
    sort: {
        direction: '-',
        field: 'date'
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        /*
         * Fetching list
         */
        case types.FETCH_INSPECTION_LIST:
            return {
                ...state,
                isFetchingList: true
            };

        /*
         * Fetching list succeeded
         */
        case types.FETCH_INSPECTION_LIST_SUCCESS: {
            return {
                ...state,
                isFetchingList: false,
                listWasFetched: true,
                inspectionsList: {
                    items: action.payload.data,
                    total: action.payload.total
                }
            };
        }
        /*
         * Fetching list failed
         */
        case types.FETCH_INSPECTION_LIST_FAILURE:
            return {
                ...state,
                isFetchingList: false,
                error: action.error
            };

        /*
         * Sorting
         */
        case types.SET_INSPECTION_LIST_SORT_OPTION:
            return {
                ...state,
                sort: action.payload
            };
        /*
         * Paginator
         */
        case types.SET_CURRENT_INSPECTION_LIST_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        case types.RESET_INSPECTION_LIST_SORT_OPTIONS:
            return {
                ...state,
                sort: initialState.sort
            };

        default:
            return state;
    }
};

const storageConfig = {
    key: 'inspections',
    storage,
    blacklist: ['isFetchingList', 'listWasFetched', 'inspectionsList']
};

const inspectionsReducer = persistReducer(storageConfig, reducer);
export default inspectionsReducer;
