import PropTypes from 'prop-types';
import React from 'react';
import { loadClassLabel } from '../../../../Sections/labels';
import { LoadClass } from '../../../../Sections/properties';
import { getObjectSyncChoiceValues } from '../../../utils';
import asSyncChoiceField from '../../asSyncChoiceField';
import SyncChoiceRow from '../../Base/Input/SyncChoiceRow';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';
import { isObjectIdenticalValue } from '../../equals';
import { validateSingleObjectChoiceComplete } from '../../validation';

const LoadClassSyncChoice = (props) => {

    const {
        value: currentFormValue,
        planValue,
        liveValue,
        onChange,
        showIdentical
    } = props;

    const renderLoadClassChoiceLabel = (valueObject) => {

        if (!valueObject) {
            return '-';
        }

        const valueKey = Object.keys(valueObject)[0];
        if (!valueObject[valueKey]) {
            return '-';
        }

        if (valueKey === LoadClass.Other) {
            return `Andere: ${valueObject[valueKey]}`;
        }

        return loadClassLabel(valueKey);
    };

    const isSelected = (value) => {
        return isObjectIdenticalValue(currentFormValue, value);
    };

    const { leftValue, rightValue } = getObjectSyncChoiceValues(planValue, liveValue);

    const isIdentical = isObjectIdenticalValue(leftValue, rightValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Lastklasse"
        >
            <SyncChoiceRow
                leftValue={leftValue}
                rightValue={rightValue}
                renderValue={renderLoadClassChoiceLabel}
                onSelect={onChange}
                isSelected={isSelected}
                isIdentical={isIdentical}
                showIdentical={showIdentical}
            />
        </SyncInputGroup>
    );
};

LoadClassSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    showIdentical: PropTypes.bool,
    showHint: PropTypes.func
};

LoadClassSyncChoice.defaultProps = {
    showHint: () => false
};

export default asSyncChoiceField(LoadClassSyncChoice, {
    allowNull: true,
    validate: validateSingleObjectChoiceComplete()
});
