import { DiffType, JobDiffType } from './properties';

export const getJobDiffTypeIcon = (diffType) => {
    switch (diffType) {
        case JobDiffType.StatusChanged:
            return 'statusChangeJobChangeLog';
        case JobDiffType.JobTypeChangedToRequest:
            return 'jobTransform';
        case JobDiffType.JobTypeChangedToJob:
            return 'jobTransform';
        case JobDiffType.ConstructionPlanCreated:
            return 'newlyAdded';
        case JobDiffType.ConstructionPlanDeleted:
            return 'statedismantled';
        default:
            return getDiffTypeIcon(diffType);
    }
};

export const getDiffTypeIcon = (diffType) => {
    switch (diffType) {
        case DiffType.New:
            return 'newlyAdded';
        case DiffType.Deleted:
            return 'statedismantled';
        case DiffType.Archived:
            return 'archiveBox';
        case DiffType.Reactivated:
            return 'reactivate';
        case JobDiffType.StatusChanged:
            return 'statusChangeJobChangeLog';
        case DiffType.Changed:
        default:
            return 'changed';
    }
};
