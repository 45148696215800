import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../Common/Icon';

const ConstructionPlanFormSiteActions = ({ onDelete,archived,onEdit }) => (
    <div className="constructionPlannerSection__actions">
        <button
            className="btn btn--backward-action btn--icon"
            onClick={onDelete}
        >
            <span className="btn__icon">
                <Icon name="delete" />
            </span>
        </button>
        {!archived && <button
            className="btn btn--neutral-action btn--icon"
            onClick={onEdit}
        >
            <span className="btn__icon">
                <Icon name="edit" />
            </span>
        </button>
        }
    </div>
);

ConstructionPlanFormSiteActions.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    archived: PropTypes.bool
};

ConstructionPlanFormSiteActions.defaultProps = {
    archived: false
};


export default ConstructionPlanFormSiteActions;
