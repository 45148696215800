import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchScaffoldingsSelectOptions } from '../../redux/modules/scaffoldings/action';
import Select from '../Forms/Select';

class ScaffoldingSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onChange = this.onChange.bind(this);
        this.updateOnFetching = this.updateOnFetching.bind(this);
        this.getScaffoldingChoices = this.getScaffoldingChoices.bind(this);
        this.showPlaceholderText = this.showPlaceholderText.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.siteId !== nextProps.siteId) {
            return {
                siteId: nextProps.siteId,
                choices: [],
                isFetching: false,
                hasFetched: false
            };
        }

        return null;
    }

    componentDidMount() {
        this.loadOptions();
    }

    componentDidUpdate() {
        this.loadOptions();
    }

    getScaffoldingChoices() {
        const { choices } = this.state;

        return choices;
    }

    loadOptions() {
        if (this.state.hasFetched || this.state.isFetching || !this.props.siteId) {
            return;
        }

        this.setState({
            isFetching: true
        }, this.updateOnFetching);

        this.props.actions.fetchScaffoldingsSelectOptions(this.props.siteId, true, this.props.managed)
            .then((scaffoldings) => {
                this.props.onScaffoldingOptionsLoaded(scaffoldings);

                const choices = scaffoldings.map(scaffolding => ({
                    value: scaffolding.id,
                    label: scaffolding.name
                }));
                this.setState({
                    choices
                });
            })
            .catch(() => {
                toast.error('Gerüste konnten nicht geladen werden', { autoClose: 4000 });
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                    hasFetched: true
                }, this.updateOnFetching);
            });
    }

    updateOnFetching() {
        const { onFetching } = this.props;
        if (typeof onFetching === 'function') {
            onFetching(this.state.isFetching);
        }
    }

    onChange(value) {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    showPlaceholderText() {
        const { isFetching } = this.state;

        const { sectionChoices } = this.props;

        if (isFetching) {
            return 'Lade Gerüste';
        }

        if (sectionChoices) {
            return 'Alle';
        }
        return 'Gerüstabschnitt auswählen';
    }

    render() {
        const {
            siteId,
            isFetching,
            choices
        } = this.state;

        let placeholderText = 'Gerüst auswählen';

        if (isFetching) {
            placeholderText = 'Lade Gerüste';
        }

        if (choices && !isFetching) {
            placeholderText = 'Alle';
        }

        return (
            <Select
                name={this.props.name}
                placeholder={placeholderText}
                options={this.getScaffoldingChoices()}
                disabled={isFetching || !siteId}
                onChange={this.onChange}
            />
        );
    }

}

ScaffoldingSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onFetching: PropTypes.func,
    onChange: PropTypes.func,
    managed: PropTypes.bool
};

ScaffoldingSelect.defaultProps = {
    onFetching: null,
    onChange: null,
    managed: null
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchScaffoldingsSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ScaffoldingSelect);
