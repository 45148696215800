import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import Input from '../../Forms/Input';
import { addDocument, updateDocument } from '../../../redux/modules/documents/action';
import { showRequestError } from '../../../redux/modules/error/action';
import { BadRequestError } from '../../../Services/ApiError';
import { getErrorId, isValidationError } from '../../../Services/ErrorService';
import Modal from './Modal';
import FormError from '../../Forms/FormError';
import InputGroup from '../../Forms/InputGroup';
import MediaDropzoneField from '../../Forms/Media/Dropzone/MediaDropzoneField';
import TextArea from '../../Forms/Textarea';
import LoadingSpinner from '../../Page/LoadingSpinner';
import MediaFormModal from '../../Forms/Media/Modal/MediaFormModal';

const focusOnError = createDecorator();

const ViewModes = {
    ADD_MODE: 'ADD_MODE',
    EDIT_MODE: 'EDIT_MODE'
};

const UPLOAD_TYPE = 'document';

class DocumentUploadModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            isUploading: false,
            uploadErrorMessage: null,
            fileUploadProgressList: {}
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.validate = this.validate.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onUploadFailure = this.onUploadFailure.bind(this);
    }

    getInitialState() {
        const formData = {
            name: '',
            description: '',
            files: []
        };

        const { document } = this.props;

        const viewMode = document ? ViewModes.EDIT_MODE : ViewModes.ADD_MODE;

        if (document) {
            formData.name = document.name;
            formData.description = document.description;
            formData.files = [{
                name: document.file.fileName,
                ...document.file,
            }];
        }

        return {
            viewMode,
            formData,
            editMode: !!document
        };
    }

    componentWillMount() {
        this.setState(this.getInitialState(this.props));
    }

    onSubmit(values) {
        const {
            viewMode
        } = this.state;

        const { name, description, files } = values;

        this.setState({
            isUploading: true,
            uploadErrorMessage: ''
        });

        if (viewMode === ViewModes.EDIT_MODE) {
            this.updateDocument(this.props.document.id, name, description);
        } else if (viewMode === ViewModes.ADD_MODE) {
           return this.addNewDocument(name, description, files[0]);
        }
    }

    validate(values) {
        const errors = {};

        const { viewMode } = this.state;

        const name = values.name || '';

        if (!name.trim()) {
            errors.name = 'Bitte geben Sie einen Namen für das Dokument an';
        }

        if (viewMode === ViewModes.ADD_MODE) {
            if (!values.files || !values.files.length) {
                errors.files = 'Bitte laden Sie eine Datei hoch';
            }
        }
        return errors;
    }

    updateDocument(documentId, documentName, documentDescription) {
        return this.props.actions.updateDocument(documentName, documentDescription, documentId)
            .then(() => {
                this.setState({
                    isUploading: false
                }, () => {
                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });
                    this.props.onUpload();
                });
            })
            .catch((error) => {
                this.setState({
                    isUploading: false
                });

                showRequestError('Dokument konnte nicht aktualisiert werden', error);
            });
    }

    onProgress = (fileId) => progressEvent => {
        const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

        const { fileUploadProgressList } = this.state;

        this.setState({
            fileUploadProgressList: {
                ...fileUploadProgressList,
                [fileId] : percentCompleted
            }
        });
    };

    onUploadFailure = (fileId) => {

        const { fileUploadProgressList } = this.state;

        this.setState({
            fileUploadProgressList: {
                ...fileUploadProgressList,
                [fileId] : false
            }
        });
    };

    handleUploadError(error) {

        if (error instanceof BadRequestError) {
            const { response } = error;
            const errorId = getErrorId(response);

            if (isValidationError(errorId)) {
                return MediaFormModal.handleValidationErrors(response.payload);
            } else {
                return MediaFormModal.handleBadRequestError(response);
            }
        } else {
            return MediaFormModal.handleUnknownError(error.response);
        }
    }

    addNewDocument(name, description, file) {

        return this.props.actions.addDocument(name, description, file.original, this.onProgress(file.id))
            .then(() => {
                this.setState({
                    isUploading: false
                }, () => {
                   this.props.onUpload();
                    toast.success('Dokument wurde hochgeladen', { autoClose: 2500 });
                });
            })
            .catch((error) => {

                this.setState({
                    isUploading: false
                });

                this.onUploadFailure(file.id);

                return this.handleUploadError(error);

            });
    }

    closeModal() {
        this.props.onClose();
    }

    renderButtonText() {
        const { isUploading, viewMode } = this.state;

        if (viewMode === ViewModes.EDIT_MODE) {
            return isUploading ?
                <LoadingSpinner label="Änderungen werden gespeichert" /> : 'Änderungen speichern';
        }

        const activeUploadText = <LoadingSpinner label="Dokument wird hochgeladen" />;
        return isUploading ? activeUploadText : 'Hochladen';
    }

    // Will be set in the render method
    handleSubmit = () => false;

    render() {
        const {
            isUploading, viewMode, formData
        } = this.state;

        const modalTitle = viewMode === ViewModes.EDIT_MODE ? 'Dokument bearbeiten' : 'Dokument hochladen';

        return (
            <Form
                onSubmit={this.onSubmit}
                decorators={[focusOnError]}
                validate={this.validate}
                initialValues={formData}
                mutators={{
                    setFileName: ([name, id], state, utils) => {
                        utils.changeValue(state, name, () => id);
                    }
                }}
                render={({ handleSubmit, values, form: { mutators }, submitError }) => {
                    this.handleSubmit = handleSubmit;
                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                title={modalTitle}
                                onBackdropClick={this.closeModal}
                                type="upload"
                                footer={
                                    <div className="btn-group">
                                        <button
                                            className="btn btn--backward-action"
                                            onClick={this.closeModal}
                                        >
                                            Abbrechen
                                        </button>
                                        <span className="btn btn--dummy">*Pflichtfeld</span>
                                        <button
                                            type="submit"
                                            className="btn btn--forward-action btn--save"
                                            disabled={isUploading}
                                            onClick={handleSubmit}
                                        >
                                            {this.renderButtonText()}
                                        </button>
                                    </div>
                                }
                            >
                                <FormError message={submitError} />

                                <InputGroup
                                    label="Name*"
                                >
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Name des Dokumentes"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Notizen"
                                >
                                <TextArea
                                    name="description"
                                    limit={130}
                                />
                                </InputGroup>

                                <InputGroup
                                    label="Datei*"
                                >
                                    <MediaDropzoneField
                                        name="files"
                                        onChange={(files) => {
                                            if (files.length === 1 && !values.name) {
                                                mutators.setFileName('name', files[0].name)
                                            }
                                        }}
                                        multiple={false}
                                        fileUploadProgressList = {this.state.fileUploadProgressList}
                                        disabled={this.state.editMode}
                                    />

                                </InputGroup>
                            </Modal>
                        </form>
                    )
                }}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateDocument,
        addDocument
    }, dispatch)
});

DocumentUploadModal.propTypes = {
    onUpload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    document: PropTypes.object
};

DocumentUploadModal.defaultProps = {
    document: undefined
};

export default connect(null, mapDispatchToProps)(DocumentUploadModal);
