import moment from 'moment';
import { toast } from 'react-toastify';
import { getMediaMap } from '../../../Components/ConstructionPlan/utils';
import callApi, { generateErrorMessages, query, uploadMedia } from '../../action';
import * as types from '../../types';

const getMediaTokenSuccess = (mediaId, token, expires) => {
    return {
        type: types.FETCH_MEDIA_TOKEN_SUCCESS,
        payload: {
            mediaId,
            token,
            expires
        }
    };
};

const getMediaPublicUrl = (token, fileName = '', download = false) => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/v1/public/media/${token}`;
    return baseUrl + (fileName ? `/${fileName}` : '') + (download ? `?download` : '');
};

/**
 * Uploads media using a classical multi part form post request to the givne path
 *
 * @param formData
 * @param path
 * @param label
 * @param showToast
 * @returns {function(*, *): *}
 */
export function upload(formData, path, label, showToast = true, progressFunction) {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        return uploadMedia(path, {
            token,
            body: formData,
            progressFunction
        })
            .then(({data}) => {

                let { name } = data;
                if (name) {
                    name = `"${name}"`;
                } else {
                    name = label || 'Dokument';
                }
                if (showToast) {
                    toast.success(`${name} wurde hochgeladen`, { autoClose: 2500 });
                }
                return data;
            });
    };
}

/**
 * Returns the media token for the given media id
 *
 * @param mediaId
 * @returns {Function}
 */
const getMediaToken = (mediaId, dispatch, getState) => {
    const state = getState();
    const { tokens } = state.media;

    // Check if state contains public url
    if (tokens && tokens[mediaId]) {

        // Check if saved url data is still valid
        const tokenData = tokens[mediaId];
        if (moment(tokenData.expires).isAfter()) {
            return Promise.resolve(tokenData.token);
        }
    }

    // Request (new) public media url
    const authToken = state.currentUser.auth_token;
    const path = `v1/media/${mediaId}/token`;

    return callApi(path, {
        method: 'GET',
        token: authToken
    })
        .then(response => response.json())
        .then(data => {

            // save to public media urls for this media id
            dispatch(getMediaTokenSuccess(mediaId, data.token, data.expires));

            return data.token;
        });
};

/**
 * Requests and returns the public media url for the given media id
 *
 * @param mediaId
 * @param fileName
 * @param download
 * @returns {Function}
 */
export const getPublicMediaContentUrl = (mediaId, fileName = '', download = false) =>
    (dispatch, getState) =>
        getMediaToken(mediaId, dispatch, getState)
            .then(token => getMediaPublicUrl(token, fileName, download));

/**
 *
 * @param mediaId
 * @returns {Function}
 */
export function getPublicDownloadUrl(mediaId, mediaFileName = '') {
    return getPublicMediaContentUrl(mediaId, mediaFileName, true);
}

export function update(formData, mediaId, label) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const path = `v1/media/${mediaId}`;

        return callApi(path, {
            method: 'PUT',
            token,
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then((result) => {
                let { name } = result;
                if (name) {
                    name = `"${name}"`;
                } else {
                    name = label || 'Dokument';
                }
                toast.success(`${name} wurde erfolgreich bearbeitet`, { autoClose: 2500 });
                return result;
            })
            .catch((err) => {
                if (typeof err.json === 'function') {
                    return err.json()
                        .then((errorDetails) => {
                            const errorMessages = generateErrorMessages(errorDetails);
                            return Promise.reject(errorMessages);
                        });
                }
                const errorMessages = generateErrorMessages();
                toast.error('Ein Fehler ist aufgetreten', { autoClose: 2500 });
                return Promise.reject(errorMessages);
            });
    };
}

const internalFetchMediaData = (mediaIds, getState) => {
    const token = getState().currentUser.auth_token;

    const gqlQuery = `
        FetchMedia($ids: [String!]!) { 
            media( ids:$ids ) { 
                id,
                name,
                fileName,
                mimeType,
                size,
                visibility
            }
        }
    `;

    return query(gqlQuery, {
        token,
        params: {
            ids: mediaIds
        }
    }).then(data => data.media);
};

export const fetchMediaData = (mediaIds) => (dispatch, getState) => {
    return internalFetchMediaData(mediaIds, getState);
};

export const resolveConstructionPlanMedia = (constructionPlanSite, referenceData, excludeUnreferenced = true) => (dispatch, getState) => {

    /*
     * 1) Generate the shallow media map
     */
    const mediaMap = getMediaMap(constructionPlanSite, referenceData, excludeUnreferenced);

    /*
     * 2) Gather all media ids
     */
    const mediaIds = Object.keys(mediaMap);

    return new Promise((resolve, reject) => {

        /*
         * 3) Load all media data
         */
        internalFetchMediaData(mediaIds, getState)
            .then(media => {

                /*
                 * 4) Update media with live data
                 */
                media.forEach(mediaItem => {
                    const mediaEntry = mediaMap[mediaItem.id];
                    mediaMap[mediaItem.id] = {
                        ...mediaEntry,
                        ...mediaItem
                    }
                });

                resolve(mediaMap);
            })
            .catch(err => {
                reject(err);
            });
    });
};
