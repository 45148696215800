import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Icon from '../Common/Icon';

class AddressSearchDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            selectedSearchString: '',
            currentSearchString: ''
        };

        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.handleSearchStringChange = this.handleSearchStringChange.bind(this);
    }

    componentWillMount() {
        const { initialValue } = this.props;

        this.setState({
            selectedSearchString: initialValue,
            currentSearchString: initialValue
        });
    }

    toggleMenu() {

        this.setState(prevState => ({
            isInitialized: true,
            menuOpen: !prevState.menuOpen
        }));
    }

    onMenuOpen() {
        this.setState({
            menuOpen: true
        });
    }

    onMenuClose() {

        const { currentSearchString, selectedSearchString } = this.state;

        this.setState({
            menuOpen: false
        }, () => {
            if(currentSearchString === selectedSearchString){
                return;
            }
            this.submitSearch();
        });
    }

    handleClickOutside() {

        this.onMenuClose();
    }

    handleSearchStringChange(event) {

        if (!event || !event.target) {
            return;
        }

        const { target: { value: searchString } } = event;

        this.setState({
            currentSearchString: searchString
        });
    }

    resetAddressSearch() {
        this.setState({
            currentSearchString: '',
            selectedSearchString: '',
            menuOpen: false
        }, () => {
            this.props.onChange(null);
        });
    }

    render() {
        const { menuOpen, selectedSearchString, currentSearchString } = this.state;

        let wrapperClasses = ['filterbar__addresssearch'];

        if (menuOpen) {
            wrapperClasses.push('filterbar__is-expanded');
        } else {
            wrapperClasses = wrapperClasses.filter(item => item !== 'filterbar__is-expanded');
        }

        const itemSelected = selectedSearchString;

        if (itemSelected) {
            wrapperClasses.push('filterbar__addresssearch-is-selected');
        }

        return (
            <div className="filterbar__addresssearch-wrapper">

                <label className={wrapperClasses.join(' ')}>
                    <div
                        onClick={this.toggleMenu}
                        className="filterbar__addresssearch-label"
                    >
                        {this.props.label}
                        <span className="icon">
                            <Icon name="reactselectChevron" />
                        </span>
                    </div>
                    {itemSelected &&
                    <div className="filterbar__addresssearch-item-selected">
                        <div
                            className="filterbar__addresssearch-content"
                            onClick={() => this.toggleMenu()}
                        >
                            {selectedSearchString}
                        </div>
                        <div className="filterbar__addresssearch-indicators">
                            <span
                                className="filterbar__addresssearch-indicator"
                                onClick={() => this.resetAddressSearch()}
                            >
                                <Icon name="reactselectClose" />
                            </span>
                            <span className="filterbar__addresssearch-separator" />
                            <span
                                className="filterbar__addresssearch-indicator"
                                onClick={() => this.toggleMenu()}
                            >
                                <Icon name="reactselectChevron" />
                            </span>
                        </div>
                    </div>
                    }
                </label>
                {menuOpen &&
                <div className="filterbar__addresssearch-dropdown">
                    <div
                        className={['filterbar__addresssearch-picker', currentSearchString ? 'filterbar__addresssearch-picker--focused' : ''].join(' ')}
                    >
                        <form onSubmit={(event) => this.submitSearch(event)}>
                            <input
                                type="text"
                                ref={input => input && input.focus()}
                                onChange={this.handleSearchStringChange}
                                value={currentSearchString || ''}
                                placeholder="Straße, PLZ oder Stadt"
                                className="search-input"
                            />

                            <button type="submit" className="btn filterbar__addresssearch-btn">
                                <Icon
                                    name="search"
                                /></button>
                        </form>
                    </div>
                </div>
                }
            </div>
        );
    }

    submitSearch(submitEvent) {
        if (submitEvent) {
            submitEvent.preventDefault();
        }


        const { currentSearchString } = this.state;
        const { onChange } = this.props;

        if (!currentSearchString || !currentSearchString.trim()) {
            return;
        }

        this.setState({
            selectedSearchString: currentSearchString,
            menuOpen: false
        }, () => {
            onChange(this.state.selectedSearchString);
        });
    }
}

export default onClickOutside(AddressSearchDropdown);

AddressSearchDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    initialValue: PropTypes.string
};

AddressSearchDropdown.defaultProps = {
    onChange: () => false,
    initialValue: ''
};
