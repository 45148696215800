import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useLocation } from 'react-router';
import Breadcrumb from '../../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../../Navigation/Breadcrumbs/BreadcrumbLink';

const CustomerBreadcrumb = ({ customer, showCustomer, collapse }) => {
    const location = useLocation();

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label={'Kunden'}
                    path={'/customers'}
                    icon="clients"
                    query={location.search}
                />
                {(customer || showCustomer) &&
                <BreadcrumbLink
                    label={customer ? customer.name : 'Lade Kunde ...'}
                    path={customer ? `/customers/${customer.id}` : null}
                />
                }
            </Fragment>
        </Breadcrumb>
    );
};

CustomerBreadcrumb.propTypes = {
    customer: PropTypes.object,
    collapse: PropTypes.bool,
    showCustomer: PropTypes.bool
};

CustomerBreadcrumb.defaultProps = {
    customer: null,
    collapse: false,
    showCustomer: false
};

export default CustomerBreadcrumb;


