import PropTypes from 'prop-types';
import { ModelType } from '../../properties';
import { ConstructionPlanModelModePropType } from '../ConstructionPlan/Form/propTypes';

export const SiteMedia = {
    Attachments: 'attachments'
};

export const ScaffoldingMedia = {
    Attachments: 'attachments',
    AnchorProtocol: 'anchorProtocol',
    UtilizationPlans: 'utilizationPlans'
};

export const SectionMedia = {
    Attachments: 'attachments',
    AnchorProtocol: 'anchorProtocol',
    UtilizationPlans: 'utilizationPlans',
    ProofOfStability: 'proofOfStability'
};

export const ModelMediaCollections = {
    [ModelType.Site]: [
        SiteMedia.Attachments
    ],
    [ModelType.Scaffolding]: [
        ScaffoldingMedia.Attachments,
        ScaffoldingMedia.UtilizationPlans,
        ScaffoldingMedia.AnchorProtocol
    ],
    [ModelType.Section]: [
        SectionMedia.Attachments,
        SectionMedia.UtilizationPlans,
        SectionMedia.AnchorProtocol,
        SectionMedia.ProofOfStability
    ]
};

export const ModelMediaType = {
    /* Job */
    Job_Attachment: 'job_attachment',
    Job_Correspondence: 'job_correspondence',
    /* Site */
    Site_Attachment: 'site_attachment',
    /* Scaffolding */
    Scaffolding_AnchorProtocol: 'scaffolding_anchorProtocol',
    Scaffolding_UtilizationPlan: 'scaffolding_utilization-plan',
    Scaffolding_Attachment: 'scaffolding_attachment',
    /* Section */
    Section_ProofOfStability: 'section_proofOfStability',
    Section_UtilizationPlan: 'section_utilization-plan',
    Section_AnchorProtocol: 'section_anchorProtocol',
    Section_Attachment: 'section_attachment',
    /* Construction diary entry */
    ConstructionDiaryEntry_Attachment: 'constructionDiaryEntry_attachment'
};

export const MediaVisibility = {
    Public: 'public',
    AccountPrivate: 'account-private'
};

export const MediaPropType = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fileName: PropTypes.string,
    visibility: PropTypes.oneOf([MediaVisibility.AccountPrivate, MediaVisibility.Public])
});

const MediaProps = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    visibility: PropTypes.oneOf([MediaVisibility.AccountPrivate, MediaVisibility.Public]).isRequired,
    modelType: PropTypes.oneOf([
        ModelType.Job,
        ModelType.Site,
        ModelType.Scaffolding,
        ModelType.Section,
        ModelType.ConstructionDiaryEntry
    ]).isRequired,
    modelMediaType: PropTypes.oneOf([
        ModelMediaType.Job_Attachment,
        ModelMediaType.Job_Correspondence,
        ModelMediaType.Site_Attachment,
        ModelMediaType.Scaffolding_AnchorProtocol,
        ModelMediaType.Scaffolding_UtilizationPlan,
        ModelMediaType.Scaffolding_Attachment,
        ModelMediaType.Section_ProofOfStability,
        ModelMediaType.Section_UtilizationPlan,
        ModelMediaType.Section_AnchorProtocol,
        ModelMediaType.Section_Attachment,
        ModelMediaType.ConstructionDiaryEntry_Attachment
    ]).isRequired
};

export const MediaFormDataPropType = PropTypes.shape({
    ...MediaProps
});

export const ConstructionPlanMediaFormDataPropType = PropTypes.shape({
    ...MediaProps,
    mode: ConstructionPlanModelModePropType
});
