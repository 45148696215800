import PropTypes from 'prop-types';
import React from 'react';
import BillingAddressForm from '../../../Components/Account/BillingAddressForm';
import EditBillingAddressBreadcrumb from '../../../Components/Account/EditBillingAddressBreadcrumb';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const EditBillingAddressPage = ({ history }) => (
    <PrivatePage requiredUserPermission={Permission.UpdateBilling}>
        <Container>
            <TopNav />
            <EditBillingAddressBreadcrumb />
            <PageTitle
                title="Rechnungsadresse bearbeiten"
            />
            <BillingAddressForm
                history={history}
            />
        </Container>
    </PrivatePage>
);

export default (EditBillingAddressPage);

EditBillingAddressPage.propTypes = {
    history: PropTypes.object
};

EditBillingAddressPage.defaultProps = {
    history: {}
};
