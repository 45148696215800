import PropTypes from 'prop-types';
import React from 'react';
import LiveBadge from '../../Common/Badge/LiveBadge';
import { ConstructionPlanModelModePropType } from '../Form/propTypes';
import { ConstructionPlanModelMode } from '../properties';

const ConstructionPlanModelName = ({ id, name, mode, ReferenceComponent }) => {

    if (mode === ConstructionPlanModelMode.New) {
        return (
            <div className="construction-plan__model-name">
                {name}
                <span className="new-badge">Neu</span>
            </div>
        )
    }



    return (
        <ReferenceComponent
            id={id}
            label={name}
            badge={mode === ConstructionPlanModelMode.Reference ? <LiveBadge /> : null}
        />
    )
};

ConstructionPlanModelName.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mode: ConstructionPlanModelModePropType.isRequired,
    ReferenceComponent: PropTypes.func.isRequired
};

export default ConstructionPlanModelName;
