import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ADVAcceptanceWarningBanner from '../Components/Account/ADVAcceptanceWarningBanner';
import CreditCardBillingDeprecatedWarningModal
    from '../Components/Account/Billing/CreditCardBillingDeprecatedWarningModal';
import InvoiceBillingDeprecatedWarningModal
    from '../Components/Account/Billing/InvoiceBillingDeprecatedWarningModal';
import SubscriptionEndingSoonWarningBanner
    from '../Components/Account/Subscription/SubscriptionEndingSoonWarningBanner';
import TermsAcceptanceWarningBanner from '../Components/Account/TermsAcceptanceWarningBanner';
import LatestAppChangeLogModal from '../Components/AppChangeLog/Modal/LatestAppChangeLogModal';
import IfUser from '../Components/Common/IfUser';
import LegacyBrowserInfoModal from '../Components/LegacyBrowserInfoModal';

const Container = ({ children, newAppVersionIsAvailable }) => (

    <div className="container">
        <div
            className={`container__cnt ${newAppVersionIsAvailable ? 'update-available' : ''}`}
        >
            <SubscriptionEndingSoonWarningBanner />
            <TermsAcceptanceWarningBanner />
            <ADVAcceptanceWarningBanner />
            <LatestAppChangeLogModal />
            <LegacyBrowserInfoModal />
            {children}
        </div>
        <IfUser can={['update-billing']}>
            <InvoiceBillingDeprecatedWarningModal />
            <CreditCardBillingDeprecatedWarningModal />
        </IfUser>
    </div>
);

Container.propTypes = {
    children: PropTypes.node.isRequired,
    newAppVersionIsAvailable: PropTypes.bool
};

const mapStateToProps = ({ serviceworker }) => {

    const newAppVersionIsAvailable = serviceworker.newAppVersionIsAvailable;

    return {
        newAppVersionIsAvailable
    };
};

export default connect(mapStateToProps, null)(Container);
