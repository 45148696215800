import createDecorator from 'final-form-focus';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { ModelType } from '../../properties';
import {
    addNewDiaryEntry,
    fetchDiaryEntryFormData,
    updateDiaryEntry
} from '../../redux/modules/constructiondiary/action';
import { showApiError } from '../../redux/modules/error/action';
import Modal from '../Common/Modal/Modal';
import ModalFormFooter from '../Common/ModalFormFooter';
import ResourceNotFoundModal from '../Common/Modal/ResourceNotFoundModal';
import DurationInput from '../Forms/DurationInput';
import InputGroup from '../Forms/InputGroup';
import { toMediaFormData } from '../Forms/Media/utils';
import Select from '../Forms/Select';
import TextArea from '../Forms/Textarea';
import Section from '../Layout/Section';
import { ModelMediaType } from '../Media/properties';
import LoadingSpinner from '../Page/LoadingSpinner';
import SiteSelect from '../Sites/SiteSelect';
import ConstructionDiaryCategoryOptions from './ConstructionDiaryCategoryOptions';
import ConstructionDiaryEntryMediaFormPart from './ConstructionDiaryEntryMediaFormPart';
import ConstructionDiaryTeamMemberInput from './Form/ConstructionDiaryTeamMemberInput';
import ConstructionDiaryWeatherSelectField from './Form/ConstructionDiaryWeatherSelectField';
import { DiaryCategories } from './properties';
import ScaffoldingSelect from './ScaffoldingSelect';
import SectionSelect from './SectionSelect';


/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = input => !!(input && typeof input.focus === 'function');

/**
 * Gets all the inputs inside modal on the page
 */
const getAllInputs = () => {

    const inputFields = window.document.getElementById('construction-diary-modal')
        .querySelectorAll('input, select, textarea');

    if (typeof inputFields === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(inputFields)
        .reduce((accumulator, input) => {
            if (isFocusableInput(input)) {
                return accumulator.concat(input);
            }
            return accumulator;
        }, []);
};

const focusOnError = createDecorator(getAllInputs);


function validate(values) {
    const errors = {};

    if (!values.siteId) {
        errors.siteId = 'Bitte wählen Sie eine Baustelle aus';
    }

    if (!_.trim(values.message) && (!values.attachments || values.attachments.length === 0)) {
        errors.message = 'Bitte geben sie eine Notiz ein oder laden Sie einen Anhang hoch.';
        errors.attachments = 'Bitte geben sie eine Notiz ein oder laden Sie einen Anhang hoch.';
    }

    return errors;
}

const MODE_CREATE = 'create';
const MODE_EDIT = 'edit';

class ConstructionDiaryEntryModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;

        this.initialSiteSelect = true;
        this.initialScaffoldingSelect = true;

        this.state = {
            mode: this.props.mode,
            formData: {
                attachments: [],
                siteId: props.siteId
            },
            isSaving: false,
            isFetching: this.props.mode === MODE_EDIT,
            initialized: this.props.mode !== MODE_EDIT
        };

        this.prepareFormData = this.prepareFormData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.onFetchingSites = this.onFetchingSites.bind(this);
        this.onFetchingScaffoldings = this.onFetchingScaffoldings.bind(this);
        this.onScaffoldingOptionsLoaded = this.onScaffoldingOptionsLoaded.bind(this);
    }

    componentWillMount() {
        const { mode } = this.state;

        if (mode === MODE_CREATE) {
            return;
        }

        if (!this.props.id) {
            return;
        }

        this.props.actions.fetchDiaryEntryFormData(this.props.id)
            .then((entryData) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.prepareFormData(entryData);
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onSubmit(values) {
        const {
            siteId,
            scaffoldingId,
            sectionId,
            message,
            attachments,
            category,
            group,
            weather,
            arrivalDuration,
            departureDuration,
            workingDuration,
            otherDurations
        } = values;

        this.setState({ isSaving: true });

        const diaryEntry = {
            siteId,
            scaffoldingId: scaffoldingId || '',
            sectionId: sectionId || '',
            message: message || '',
            attachments: attachments.map(a => a.id),
            category: category || DiaryCategories.Other,
            weather,
            group,
            arrivalDuration,
            departureDuration,
            workingDuration,
            otherDurations
        };

        if (this.state.mode === MODE_EDIT) {

            const { id, actions: { updateDiaryEntry }, onUpdated } = this.props;

            updateDiaryEntry(id, diaryEntry)
                .then((entry) => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });

                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });

                    const id = entry.id;

                    if (id) {
                        onUpdated(id);
                    }

                })
                .catch((errorMsg) => {
                    showApiError(errorMsg);
                })
                .finally(() => {
                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });
        } else {

            const { actions: { addNewDiaryEntry }, onCreated } = this.props;

            addNewDiaryEntry(diaryEntry)
                .then((customer) => {

                    if (this.loadingGotCancelled) {
                        return;
                    }
                    this.setState({
                        isSaving: false
                    });

                    toast.success('Bautagebuch-Eintrag wurde angelegt', { autoClose: 2500 });

                    const id = customer.id;

                    if (id) {
                        onCreated(id);
                    }

                })
                .catch((error) => {
                    showApiError(error);

                })
                .finally(() => {
                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });
        }
    }

    prepareFormData(entry) {
        if (!entry) {
            return this.setState({
                entry: null,
                isFetching: false
            });
        }

        const {
            category,
            attachments,
            message,
            scaffoldings,
            scaffoldingSelection,
            sections,
            sectionSelection,
            site,
            group,
            weather,
            arrivalDuration,
            departureDuration,
            workingDuration,
            otherDurations
        } = entry;

        let scaffolding = null;
        let section = null;

        if (scaffoldingSelection === 'single') {
            scaffolding = _.head(scaffoldings);
        }

        if (sectionSelection === 'single') {
            section = _.head(sections);
        }

        const formData = {
            siteId: site.id,
            scaffoldingId: scaffolding ? scaffolding.id : '',
            sectionId: section ? section.id : '',
            category,
            message: message || '',
            attachments: attachments ? attachments.map(attachment => toMediaFormData(attachment, ModelType.ConstructionDiaryEntry, ModelMediaType.ConstructionDiaryEntry_Attachment)) : [],
            group,
            weather,
            arrivalDuration,
            departureDuration,
            workingDuration,
            otherDurations
        };

        return this.setState({
            formData,
            isFetching: false,
            entry,
            initialized: true
        });
    }

    onFetchingSites(isFetching) {
        this.setState({
            isFetchingSites: isFetching
        });
    }

    onFetchingScaffoldings(isFetching) {
        this.setState({
            isFetchingScaffoldings: isFetching
        });
    }

    onScaffoldingOptionsLoaded(scaffoldingOptions) {
        const sectionChoices = {};

        scaffoldingOptions.forEach((scaffolding) => {
            sectionChoices[scaffolding.id] = scaffolding.sections.map(section => ({
                value: section.id,
                label: section.name
            }));
        });

        this.setState({
            sectionChoices
        });
    }

    renderButtonText() {
        const { mode, isSaving } = this.state;
        if (mode === MODE_EDIT) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }
        return isSaving ? 'Eintrag wird erstellt ...' : 'Eintrag erstellen';
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose(true);
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose(true);
        }
    }

    handleSiteChange(mutators) {
        if (!this.initialSiteSelect && MODE_EDIT) {
            mutators.setField('scaffoldingId', '');
            mutators.setField('sectionId', '');
        }
        this.initialSiteSelect = false;
    }

    handleScaffoldingChange(mutators) {
        if (!this.initialScaffoldingSelect && MODE_EDIT) {
            mutators.setField('sectionId', '');
        }
        this.initialScaffoldingSelect = false;
    }

    render() {
        const {
            mode,
            entry,
            formData,
            isSaving,
            isFetching,
            sectionChoices,
            isFetchingScaffoldings,
            initialized,
            isFetchingSites
        } = this.state;

        if (mode === MODE_EDIT && !isFetching && !entry) {
            return (
                <ResourceNotFoundModal
                    title="Kunde nicht gefunden"
                    onClose={this.props.onClose}
                />
            );
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                validate={validate}
                decorators={[focusOnError]}
                initialValues={formData}
                mutators={{
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    },
                    setField: ([name, value], state, utils) => {
                        utils.changeValue(state, name, () => value);
                    }
                }}
                render={({ handleSubmit, pristine, values, form: { mutators } }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            title={mode === MODE_CREATE ? 'Bautagebuch - Eintrag erstellen' : 'Bautagebuch - Eintrag bearbeiten'}
                            id="construction-diary-modal"
                            loading={!initialized}
                            loadingLabel="Lade Bautagebuch - Eintrag"
                            footer={
                                <ModalFormFooter
                                    onSubmit={handleSubmit}
                                    isSubmitting={isSaving}
                                    onClose={() => this.onClose(pristine)}
                                    submitLabel={this.renderButtonText()}
                                    submitDisabled={isFetching}
                                />
                            }
                        >
                            <Section
                                title="Zuordnung"
                                collapsible
                            >
                                <InputGroup
                                    label="Baustelle*"
                                >
                                    <SiteSelect
                                        name="siteId"
                                        onFetching={this.onFetchingSites}
                                    />
                                    <OnChange name="siteId">
                                        {() => this.handleSiteChange(mutators)}
                                    </OnChange>
                                </InputGroup>

                                <InputGroup
                                    label="Gerüst"

                                    containerClass={!values.siteId || isFetchingSites || isFetchingScaffoldings ? 'input-grp--disabled' : ''}
                                >
                                    <ScaffoldingSelect
                                        name="scaffoldingId"
                                        siteId={values.siteId}
                                        onFetching={this.onFetchingScaffoldings}
                                        onScaffoldingOptionsLoaded={this.onScaffoldingOptionsLoaded}
                                        managed
                                    />

                                    <OnChange name="scaffoldingId">
                                        {() => this.handleScaffoldingChange(mutators)}
                                    </OnChange>

                                </InputGroup>

                                <InputGroup
                                    label="Gerüstabschnitt"
                                    containerClass={!values.scaffoldingId || isFetchingSites || isFetchingScaffoldings ? 'input-grp--disabled' : ''}
                                >
                                    <SectionSelect
                                        name="sectionId"
                                        scaffoldingId={values.scaffoldingId}
                                        sectionChoices={sectionChoices && sectionChoices[values.scaffoldingId]}
                                    />
                                </InputGroup>
                            </Section>
                            <Section
                                title="Weitere Angaben"
                                collapsible
                            >
                                <InputGroup
                                    label="Kategorie"
                                >
                                    <Select
                                        name="category"
                                        placeholder="Kategorie wählen"
                                        options={ConstructionDiaryCategoryOptions}
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Wetter"
                                >
                                    <ConstructionDiaryWeatherSelectField
                                        name="weather"
                                    />
                                </InputGroup>
                            </Section>
                            <Section
                                title="Eintrag"
                                collapsible
                            >
                                <InputGroup
                                    label="Notiz"
                                >
                                    <TextArea
                                        type="text"
                                        name="message"
                                        placeholder="Notiz eingeben"
                                    />
                                </InputGroup>
                            </Section>
                            <Section
                                title="Dokumente"
                                collapsible
                            >
                                <ConstructionDiaryEntryMediaFormPart
                                    attachments={values.attachments}
                                    attachmentsMutator={mutators.setAttachments}
                                />
                            </Section>
                            <Section
                                title="Zeitaufwand"
                                collapsible
                            >

                                <InputGroup
                                    label="Anfahrt"
                                >

                                    <DurationInput
                                        name="arrivalDuration"
                                    />

                                </InputGroup>

                                <InputGroup
                                    label="Abfahrt"
                                >

                                    <DurationInput
                                        name="departureDuration"
                                    />

                                </InputGroup>

                                <InputGroup
                                    label="Arbeitszeit"
                                >
                                    <DurationInput
                                        name="workingDuration"
                                    />

                                </InputGroup>

                                <InputGroup
                                    label="Sonstige Angaben"
                                >
                                    <TextArea
                                        name="otherDurations"
                                        placeholder="Sonstige Angaben zum Zeitaufwand"
                                    />

                                </InputGroup>

                            </Section>
                            <Section
                                title="Anwesende Kolonne"
                                collapsible
                                style={{ marginBottom: 64 }}
                            >
                                <InputGroup
                                    label="Mitarbeiter"
                                >
                                    <ConstructionDiaryTeamMemberInput name="group" />
                                </InputGroup>
                            </Section>

                        </Modal>
                    </form>
                )}
            />
        );
    }

}

ConstructionDiaryEntryModal.propTypes = {
    mode: PropTypes.oneOf([MODE_CREATE, MODE_EDIT]),
    id: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func
};

ConstructionDiaryEntryModal.defaultProps = {
    mode: MODE_CREATE,
    id: null,
    onCreated: (id) => false,
    onUpdated: () => false,
    onClose: () => false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        addNewDiaryEntry,
        fetchDiaryEntryFormData,
        updateDiaryEntry
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionDiaryEntryModal);
