import React, { Fragment } from 'react';
import Breadcrumb from '../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../Navigation/Breadcrumbs/BreadcrumbLink';

const EditBillingAddressBreadcrumb = () => {

    return (
        <Breadcrumb>
            <Fragment>
                <BreadcrumbLink
                    label="Abo & Rechnung"
                    path="/account/subscription"
                    icon="settings"
                />
                <BreadcrumbLink
                    label="Rechnungsadresse bearbeiten"
                    path="/account/subscription/edit/billingaddress"
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default EditBillingAddressBreadcrumb;

