import PropTypes from 'prop-types';
import React from 'react';
import { getDatePeriodLabel } from '../../../../../utils';
import Icon from '../../../../Common/Icon';
import InputHint from '../../../../Forms/InputHint';

export const PlannerScaffoldingDataPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    scheduledErection: PropTypes.string,
    scheduledDismantling: PropTypes.string
});

const ConstructionPlanSiteScaffoldingItem = ({ data }) => {

    const { name, scheduledErection, scheduledDismantling } = data;

    return (
        <div className="constructionplan-reference-item">
            <div className="constructionplan-reference-item__hd">
                <span className="constructionplan-reference-item__icon"> <Icon name="plannerExisting"/> </span>
                <span className="constructionplan-reference-item__title">{name}</span>
            </div>
            <InputHint>
                Geplante Standzeit: { (scheduledErection || scheduledDismantling ) ? getDatePeriodLabel(scheduledErection, scheduledDismantling) : 'n.a.'}
            </InputHint>
        </div>
    );
};

ConstructionPlanSiteScaffoldingItem.propTypes = {
    data: PlannerScaffoldingDataPropType.isRequired
};

export default ConstructionPlanSiteScaffoldingItem;
