export const DiaryCategories = {
    PsaDocumentation: 'psaDocumentation',
    Construction: 'construction',
    Dismantling: 'dismantling',
    ObjectDamaged: 'objectDamaged',
    Repairs: 'repairs',
    WaitingPeriod: 'waitingPeriod',
    Warehouse: 'warehouse',
    Acceptance: 'acceptance',
    Other: 'other'
};

export const ConstructionDiaryOverviewColumns = [
    {
        fieldName: 'created',
        label: 'Erstellt am',
        isSortable: true
    },
    {
        label: 'Kategorie',
        isSortable: false
    },
    {
        label: 'Baustelle',
        isSortable: false
    },
    {
        label: 'Gültig für',
        isSortable: false
    },
    {
        label: 'Protokollant',
        isSortable: false
    }
];

