import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    userHasAllPermissions,
    userHasSomePermission,
    userHasSomeType,
    userHasType,
    UserTypePropType
} from '../../user';

const IfUser = (props) => {

    const {
        userType,
        userPermissions,
        can,
        canOneOf,
        isType,
        isOneOfType,
        and,
        children,
        ifNot,
    } = props;

    let userIsAuthorized = true;

    if (typeof can !== 'undefined') {
        userIsAuthorized &= userHasAllPermissions(userPermissions, can);
    }

    if (typeof canOneOf !== 'undefined') {
        userIsAuthorized &= userHasSomePermission(userPermissions, canOneOf);
    }

    if (typeof isType !== 'undefined') {
        userIsAuthorized &= userHasType(userType, isType);
    }

    if (typeof isOneOfType !== 'undefined') {
        userIsAuthorized &= userHasSomeType(userType, isOneOfType);
    }

    if (typeof and !== 'undefined') {
        userIsAuthorized &= and;
    }

    return userIsAuthorized ? children : ifNot;
};

const mapStateToProps = ({ currentUser }) => {
    const userPermissions = currentUser.usermeta.permissions ? currentUser.usermeta.permissions : [];
    const userType = currentUser.usermeta.type ? currentUser.usermeta.type : [];

    return {
        userPermissions,
        userType
    };
};

export default connect(mapStateToProps, null)(IfUser);

IfUser.propTypes = {
    userType: UserTypePropType.isRequired,
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node.isRequired,
    can: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    canOneOf: PropTypes.arrayOf(PropTypes.string),
    isType: UserTypePropType,
    isOneOfType: PropTypes.arrayOf(UserTypePropType),
    and: PropTypes.bool,
    ifNot: PropTypes.node
};

IfUser.defaultProps = {
    can: undefined,
    canOneOf: undefined,
    isType: undefined,
    isOneOfType: undefined,
    and: undefined,
    ifNot: null
};
