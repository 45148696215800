import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import {
    CURRENT_CHANGE_LOG_VERSION,
    getChangeLogVersionNotificationId,
    userHasReadLatestChangelogEntry
} from '../../../redux/helper';
import { showApiError } from '../../../redux/modules/error/action';
import { fetchAndUpdateUser, markNotificationAsRead } from '../../../redux/modules/user/action';
import { Changelog_1_24_0 } from '../Content/1_24_0';
import { AppChangeLogModal } from './AppChangeLogModal';

const LatestAppChangeLogModal = ({ userHasReadLatestChangelogEntry, actions: { fetchAndUpdateUser, markNotificationAsRead } }) => {

    const location = useLocation();
    const [isOpen, setOpen] = useState(!userHasReadLatestChangelogEntry);
    const [readLatestChangelog, setReadLatestChangelog] = useState(userHasReadLatestChangelogEntry);

    const markAsRead = () => {
        return markNotificationAsRead(getChangeLogVersionNotificationId(CURRENT_CHANGE_LOG_VERSION))
            .then(() => {
                setReadLatestChangelog(true);
            })
            .catch((error) => {
                if (error.errorCode === 'notification_already_read') {
                    setReadLatestChangelog(true);
                    fetchAndUpdateUser();
                } else {
                    onError(error);
                }
            });
    }

    const onClickClose = () => {
        setOpen(false);
        return markAsRead()
            .then(() => {
                fetchAndUpdateUser();
            });
    }

    const onError = (error) => {
        showApiError(error, 'Die Nachricht zu Änderungen und Verbesserungen konnte nicht als \'gelesen\' markiert werden');
    }

    if (!isOpen || readLatestChangelog || location.pathname === '/changelog') {
        return null;
    }

    return (
        <AppChangeLogModal
            onClickClose={onClickClose}
            showIntro={true}
        >
            <Changelog_1_24_0 onClickClose={onClickClose} modalMode />
        </AppChangeLogModal>
    )
};

const mapStateToProps = state => ({
    userHasReadLatestChangelogEntry: userHasReadLatestChangelogEntry(state)
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchAndUpdateUser,
        markNotificationAsRead
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestAppChangeLogModal);
