import _ from 'lodash';
import callApi, { mutate, query, querySingle } from '../../action';
import * as types from '../../types';
import { showRequestError } from '../error/action';
import { getSiteInclusionOptions, SitesListType } from '../sites/action';

const type = 'site';

export const addSiteSuccess = () => ({
    type: types.ADD_SITE_SUCCESS
});

export const addSiteFailure = err => ({
    type: types.ADD_SITE_FAILURE,
    error: err
});

export const fetchSite = () => ({
    type: types.FETCH_SITE
});

export const fetchSiteSuccess = site => ({
    type: types.FETCH_SITE_SUCCESS,
    payload: site
});

export const fetchSiteFailure = error => ({
    type: types.FETCH_SITE_FAILURE,
    payload: error
});

export function addNewSite(siteInfo) {

    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        return callApi('v1/sites', {
            method: 'POST',
            token,
            body: siteInfo
        })
            .then((response) => {
                if (!response.ok) {
                    dispatch(addSiteFailure(response.statusText));
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then((result) => {
                dispatch(addSiteSuccess());
                return result;
            });
    };
}

export function updateSite(SiteId, SiteInfo) {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        return callApi(`v1/sites/${SiteId}`, {
            method: 'PUT',
            token,
            body: SiteInfo
        })
            .then((response) => {
                if (!response.ok) {
                    dispatch(addSiteFailure(response.statusText));
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(res => res.json())
            .then((result) => {
                dispatch(addSiteSuccess());
                return result;
            });
    };
}

export const fetchSiteFormData = SiteId => (
    (dispatch, getState) => {
        dispatch(fetchSite());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchSite{site(id:"${SiteId}"){
            id,
            name,
            description,
            attachments{id,name,fileName,size,mimeType,visibility},
            address{line1,line2, zip, city, country},
            invitations{id,inviteeEmail,allowInspections,allowCloseRequests},
            scaffoldings{id, name, scheduledErection, scheduledDismantling},
            archived
        }}`;

        return querySingle(gqlQuery, {
            token
        }, type)
            .then((site) => {
                dispatch(fetchSiteSuccess(site));
                return site;
            })
            .catch((error) => {
                dispatch(fetchSiteFailure(error));
                showRequestError('Baustellendaten konnten nicht geladen werden', error);
            });
    }
);

export const fetchSiteArchivationData = siteId => (
    (dispatch, getState) => {
        dispatch(fetchSite());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchSite{unknownSite(id:"${siteId}") {
            ... on Site {
                id,
                name,
                archived,
                scaffoldings{sections{id,name,status}}
            }
            ... on ThirdPartySite {
                id,
                name,
                archived,
                scaffoldings{sections{id,name}}
            }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'unknownSite')
            .then((site) => {
                dispatch(fetchSiteSuccess(site));
                return site;
            })
            .catch((error) => {
                dispatch(fetchSiteFailure(error));
                showRequestError('Baustellendaten konnten nicht geladen werden', error);
            });
    }
);

export const fetchSiteDetailData = siteId => (
    (dispatch, getState) => {
        dispatch(fetchSite());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchSite{unknownSite(id:"${siteId}") {
            ... on Site {
                id,
                name,
                description,
                isThirdParty,
                attachments{id,name,fileName,size,mimeType,visibility},
                scaffoldings{id,name,scheduledErection,scheduledDismantling,sectionCount,isThirdParty,managed,sections{id,name, archived, status, publicStatus, managed, isThirdParty}, archived},
                address{line1,line2,zip,city,country},
                invitations{id,inviteeEmail,accepted,acceptedBy{firstName,lastName,account{company{name}}}},
                jobs{
                    id,
                    name,
                    status,
                    created,
                    archived,
                    offerDeadline,
                    updated,
                    customer {
                        id,name
                    },
                    site {
                        id,name,address{city},archived
                    },
                    sections {
                        id,name,scaffolding{id,name,archived},archived
                    },
                    type
                },
                archived,
                archivedAt
            }
            ... on ThirdPartySite {
                id,
                name,
                isThirdParty,
                isInvited,
                attachments{id,name,fileName,size,mimeType},
                scaffoldings{id,name,scheduledErection,scheduledDismantling,sectionCount,isThirdParty,isInvited,sections{id,name,managed, isThirdParty,publicStatus, archived}, archived},
                address{line1,line2,zip,city,country},
                invitations {
                   allowInspections,
                   allowCloseRequests 
                },
                archived,
                archivedAt
            }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'unknownSite')
            .then((site) => {
                dispatch(fetchSiteSuccess(site));
                return site;
            })
            .catch((error) => {
                dispatch(fetchSiteFailure(error));
                showRequestError('Baustellendaten konnten nicht geladen werden', error);
            });
    }
);

export const deleteSingleSite = siteId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        return callApi(`v1/sites/${siteId}`, {
            method: 'DELETE',
            token
        })
            .then((data) => {
                return data;
            });
    }
);

export const fetchSiteNotes = siteId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const gqlQuery = `FetchSiteNotes{site(id:"${siteId}"){
            notes{id, content, created, createdBy{firstName, lastName}, attachments{id,name, type, fileName,size,mimeType} }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, type)
            .then(comments => comments)
            .catch(error => showRequestError('Baustellendaten konnten nicht geladen werden', error));
    }
);

export const createSiteNote = (siteId, commentData) => (
    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        return callApi(`v1/sites/${siteId}/notes`, {
            method: 'POST',
            token,
            body: commentData
        })
            .catch(error => (
                error.getData()
                    .then((data) => {
                        return Promise.reject(data.message);
                    })
            ));
    }
);

export const acceptSiteInvitation = invitationId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi(`v1/site-invitations/${invitationId}/accept`, {
            method: 'POST',
            token
        })
            .then(data => data.json());
    }
);

export function autocompleteSite(input, archived = false) {
    return (dispatch, getState) => {

        const trimmedInput = _.trim(input);

        const token = getState().currentUser.auth_token;

        const siteInclusionOptions = getSiteInclusionOptions(archived ? SitesListType.ListOfArchivedSites : SitesListType.ListOfActiveAndProprietarySites);

        return query(`FetchSites{sites_v2(limit:-1,sort:name,sortAsc:true,name:"${trimmedInput}",${siteInclusionOptions}) {data{... on Site {name, id}... on ThirdPartySite {name, id}}}}`, {
            token
        })
            .then(({ sites_v2 }) => sites_v2.data);
    };
};

export const archiveSite = (siteId, force = false) => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `
                 ArchiveSiteMutation($siteId: String!, $force: Boolean){
                    archiveSite(siteId: $siteId, force: $force){
                        id
                    }
                }
            `;

        const params = {
            siteId,
            force
        };

        return mutate(queryString, params, {
            token
        });
    }
);


export const unarchiveSite = siteId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `
                 ReactivateSiteMutation($siteId: String!){
                    reactivateSite(siteId: $siteId){
                        id
                    }
                }   
            `;

        const params = {
            siteId
        };

        return mutate(queryString, params, {
            token
        });
    }
);

export const resendSiteInvitation = (siteId, inviteeEmail) => {

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `
                 ResendSiteInvitation($siteId: String!, $email: String!){
                    resendSiteInvitation(siteId: $siteId, email: $email)
                }   
            `;

        const params = {
            siteId,
            email: inviteeEmail
        };

        return mutate(queryString, params, {
            token
        });
    };
};

