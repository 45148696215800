import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSortOption } from '../../redux/modules/inspection/action';
import SortLink from '../Job/SortLink';

class InspectionHistoryTableHeader extends Component {

    constructor(props) {
        super(props);
        this.sortData = this.sortData.bind(this);
        this.getClasses = this.getClasses.bind(this);
    }

    /**
     * give every Sortlink sort__link class and selected class if the link was clicked
     * @param fieldValue == field value of the SortLink
     * @returns string with the css classes for the Sortlink
     */
    getClasses(fieldValue) {
        const selectedClass = this.props.sort.field === fieldValue ? 'selected' : '';
        return `${selectedClass} sort__link`;
    }

    /**
     *
     * @returns 'opposite' of the active sort direction
     */
    getInvertedDirection() {
        return this.props.sort.direction === '-' ? '+' : '-';
    }

    /**
     *
     * @param field == key from the jobs reducer list.items
     */
    sortData(field) {
        let direction = '+';
        if (this.props.sort.field === field) {
            direction = this.getInvertedDirection();
        }
        this.props.actions.setSortOption({
            field,
            direction
        });
        this.props.onChange();
    }

    render() {
        const { field, direction } = this.props.sort;
        return (
            <thead>
            <tr>
                <th>
                    Unternehmen
                </th>
                <th>
                    Typ
                </th>
                <th>
                    Prüfer
                </th>
                <th>
                    <SortLink
                        to="#"
                        field="date"
                        label="Datum"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                <th>
                    <SortLink
                        to="#"
                        field="success"
                        label="Ergebnis"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
            </tr>
            </thead>
        );
    }

}

const mapStateToProps = state => ({
    sort: state.inspections.sort
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ setSortOption }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(InspectionHistoryTableHeader);

InspectionHistoryTableHeader.propTypes = {
    actions: PropTypes.object,
    onChange: PropTypes.func,
    sort: PropTypes.shape({
        direction: PropTypes.string,
        field: PropTypes.string
    }).isRequired
};

InspectionHistoryTableHeader.defaultProps = {
    actions: {
        setSortOption: () => false
    },
    onChange: () => false
};
