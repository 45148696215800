import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Icon from '../../../Common/Icon';
import LightBox from '../../../Common/LightBox';

class MediaGroupListItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showMediaPreview: false,
            mediaItemToPreview: undefined,
        };

        this.previewItem = this.previewItem.bind(this);
        this.onClosePreviewModal = this.onClosePreviewModal.bind(this);
    }

    previewItem() {
        this.setState({
            showMediaPreview: true
        });
    }

    onClosePreviewModal() {
        this.setState({
            showMediaPreview: false,
            mediaItemToPreview: undefined
        })
    }

    renderPreview() {
        const { showMediaPreview } = this.state;

        const { file } = this.props;

        if (!showMediaPreview) {
            return null;
        }

        return (
            <LightBox media={file} onClose={this.onClosePreviewModal} />
        )
    }


    render() {

        const { file } = this.props;

        const { name, fileName, url } = file;

        return (
            <Fragment>
                <div className="constructionPlannerMediaGroup__listitem" onClick={() => this.previewItem()}>
                    <div className="constructionPlannerMediaGroup__main" >
                        <div
                            className="constructionPlannerMediaGroup__listitem-name"
                        >
                            {name}
                        </div>
                        <div
                            className="constructionPlannerMediaGroup__listitem-filename"
                        >
                            {fileName}
                        </div>
                    </div>
                    <div className="constructionPlannerMediaGroup__actions">
                        <a
                            className="constructionPlannerMediaGroup__action"
                            href={url}
                        >
                        <span
                              className="constructionPlannerMediaGroup-icon">
                            <Icon name="filePreview" />
                        </span>
                        </a>
                    </div>
                </div>
            {/* Preview */}
            {this.renderPreview()}
            </Fragment>
    );
    }
}

MediaGroupListItem.propTypes = {
    file: PropTypes.shape({
        name: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
    }).isRequired
};

export default MediaGroupListItem;
