import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Address from '../../Common/Address';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import DataRow from '../../Common/DataRow';
import MultiLineText from '../../Common/MultiLineText';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import { SiteReference } from '../../Common/ObjectReference';
import { ConstructionPlanFormSitePropType } from '../Form/propTypes';
import { getData } from '../utils';
import ConstructionPlanModelName from './ConstructionPlanModelName';

class ConstructionPlanSite extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetchingMedia: true,
            resolvedMedia: null
        };
    }

    getData(field, fallback = <NotAvailablePlaceholder />) {
        return getData(this.props.site, this.props.referenceData, field, fallback);
    }

    renderData() {
        const { site, isArchived } = this.props;
        const mode = site.mode;
        const name = this.getData('name');
        const address = this.getData('address', null);

        const description = this.getData('description', null);

        if (isArchived) {
            return (
                <DataRow label="Baustelle">
                    <SiteReference
                        id={site.id}
                        label={name}
                        badge={<ArchivedBadge size="small" />}
                    >
                        {`${address.line1}, ${address.zip} ${address.city}`}
                    </SiteReference>
                </DataRow>
            );
        }

        return (
            <Fragment>
                <DataRow label="Baustelle">
                    <ConstructionPlanModelName
                        id={site.id}
                        name={name}
                        mode={mode}
                        ReferenceComponent={SiteReference}
                        isArchived={this.props.referenceData && this.props.referenceData.archived}
                    />
                    {(this.props.referenceData && this.props.referenceData.archived) &&
                    <ArchivedBadge size="small" />}
                </DataRow>

                <DataRow
                    label="Adresse"
                    isSubRow
                >
                    <Address address={address} />
                </DataRow>

                <DataRow
                    label="Notizen"
                    isSubRow
                >
                    <MultiLineText text={description} />
                </DataRow>
            </Fragment>
        );
    }

    render() {
        return (
            <div className="construction-plan__model construction-plan__model--site">
                {this.renderData()}
            </div>
        );
    }
}

ConstructionPlanSite.propTypes = {
    site: ConstructionPlanFormSitePropType.isRequired,
    referenceData: PropTypes.object,
    isArchived: PropTypes.bool
};

ConstructionPlanSite.defaultProps = {
    referenceData: null,
    isArchived: false
};

export default ConstructionPlanSite;
