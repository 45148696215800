import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../../redux/modules/error/action';
import { fetchConstructionPlanSiteReferenceData } from '../../../../redux/modules/job/actions';
import { resolveConstructionPlanMedia } from '../../../../redux/modules/media/action';
import Address from '../../../Common/Address';
import ArchivedBadge from '../../../Common/Badge/ArchivedBadge';
import DataRow from '../../../Common/DataRow';
import DeleteModal from '../../../Common/Modal/DeleteModal';
import MultiLineText from '../../../Common/MultiLineText';
import NotAvailablePlaceholder from '../../../Common/NotAvailablePlaceholder';
import LoadingSpinner from '../../../Page/LoadingSpinner';
import ConstructionPlanModelModeIcon from '../../ConstructionPlanModelModeIcon';
import { ConstructionPlanModelMode } from '../../properties';
import { getData, walkConstructionPlanSite } from '../../utils';
import ConstructionPlanFormGroup, { ConstructionPlannerSectionType } from '../Common/ConstructionPlanFormGroup';
import ConstructionPlanFormGroupTitle from '../Common/ConstructionPlanFormGroupTitle';
import ConstructionPlanFormModelEditHint from '../Common/ConstructionPlanFormModelEditHint';
import ConstructionPlanFormMedia from '../Media/ConstructionPlanFormMedia';
import { ConstructionPlanFormSitePropType } from '../propTypes';
import ConstructionPlanFormScaffoldingList
    from '../Scaffolding/ConstructionPlanFormScaffoldingList';
import ConstructionPlanFormSiteActions from './ConstructionPlanFormSiteActions';
import ConstructionPlanSitePlaceholder from './ConstructionPlanFormSitePlaceholder';
import ConstructionPlanSiteFormModal from './Modal/ConstructionPlanSiteFormModal';

const uuidv4 = require('uuid/v4');

export const ConstructionPlanFormSiteChangeEvents = {
    InitializedArchivedSite: 'initialized_archived_site',
    IsFetchingReferenceData: 'is_fetching_reference_data'
};

class ConstructionPlanFormSite extends Component {

    constructor(props) {

        super(props);

        this.state = {
            referenceData: undefined,
            fetchingReferenceData: true,
            fetchingMediaData: true,
            showFormModal: false,
            showDeleteModal: false
        };

        this.onAddNew = this.onAddNew.bind(this);
        this.onAddExisting = this.onAddExisting.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onChanged = this.onChanged.bind(this);
        this.onMediaChanged = this.onMediaChanged.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.onCloseFormModal = this.onCloseFormModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.onScaffoldingsChange = this.onScaffoldingsChange.bind(this);
    }

    componentWillMount() {
        this.refreshReferenceData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { site: oldSite } = prevProps;
        const { site: newSite } = this.props;

        // We have to update the reference data if the site was changed
        if (
            ((!oldSite && newSite)
                || (newSite && oldSite && !_.isEqual(oldSite, newSite)))
        ) {
            this.refreshReferenceData();
        }
    }

    refreshReferenceData() {
        const { site, onConstructionPlanFormSiteChange } = this.props;

        if (!site || !site.id) {
            this.setState({
                referenceData: undefined,
                fetchingReferenceData: false
            }, () => this.updateMedia());
            return;
        }

        this.setState({
            fetchingReferenceData: true
        });

        onConstructionPlanFormSiteChange({
            changeType: ConstructionPlanFormSiteChangeEvents.IsFetchingReferenceData,
            changeValue: true
        });

        this.props.actions.fetchConstructionPlanSiteReferenceData(site.id)
            .then(referenceData => {

                onConstructionPlanFormSiteChange({
                    changeType: ConstructionPlanFormSiteChangeEvents.InitializedArchivedSite,
                    changeValue: referenceData && referenceData.archived
                });

                onConstructionPlanFormSiteChange({
                    changeType: ConstructionPlanFormSiteChangeEvents.IsFetchingReferenceData,
                    changeValue: false
                });

                this.setState({
                    referenceData,
                    isArchived: referenceData && referenceData.archived,
                    fetchingReferenceData: false
                }, () => this.updateMedia());
            })
            .catch(err => {

                showRequestError(`Live-Daten konnten nicht geladen werden. Bitte laden Sie die
                    Seite erneut, da dadurch bei der Bearbeitung Anzeigefehler auftreten können.`, err);
                this.setState({
                    fetchingReferenceData: false
                });

                onConstructionPlanFormSiteChange({
                    changeType: ConstructionPlanFormSiteChangeEvents.IsFetchingReferenceData,
                    changeValue: false
                });
            });
    }

    onAddNew() {
        if (this.props.site) {
            return;
        }

        this.setState({
            showFormModal: true,
            siteFormModalMode: ConstructionPlanModelMode.New
        });
    }

    onAddExisting() {
        if (this.props.site) {
            return;
        }

        this.setState({
            showFormModal: true,
            siteFormModalMode: ConstructionPlanModelMode.Reference
        });
    }

    onEdit() {
        if (!this.props.site) {
            return;
        }

        this.setState({
            showFormModal: true
        });
    }

    onChanged(updatedSite) {
        let newSite = {
            ...updatedSite
        };

        const oldSite = this.props.site;
        if (oldSite) {
            if (oldSite.id === updatedSite.id) {
                newSite.scaffoldings = oldSite.scaffoldings;
            }
            if (oldSite.originalMode) {
                newSite.originalMode = oldSite.originalMode;
            }
            if (oldSite.lastApplied) {
                newSite.lastApplied = oldSite.lastApplied;
            }
        }

        this.props.onChange(newSite);
        this.onCloseFormModal();
    }

    onMediaChanged(siteWithUpdatedMedia) {
        let newSite = {
            ...siteWithUpdatedMedia
        };

        this.props.onChange(newSite);
    }

    onDelete() {

        const { onChange, onConstructionPlanFormSiteChange } = this.props;

        onChange(null);

        onConstructionPlanFormSiteChange({
            changeType: ConstructionPlanFormSiteChangeEvents.InitializedArchivedSite,
            changeValue: false
        });
        this.toggleDeleteModal();
    }

    updateMedia() {
        const { site } = this.props;
        if (!site || !site.id) {
            this.setState({
                media: null
            });
            return;
        }

        const { referenceData } = this.state;

        this.setState({
            isFetchingMedia: true
        });

        this.props.actions.resolveConstructionPlanMedia(site, referenceData)
            .then(media => {
                this.setState({
                    isFetchingMedia: false,
                    media
                });
            })
            .catch(err => {
                showRequestError('Dokumentdaten konnten nicht geladen werden', err);
            });
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }

    onCloseFormModal() {
        this.setState({
            showFormModal: false
        });
    }

    getFormModalData() {
        if (this.props.site) {
            return this.props.site;
        }

        return {
            id: this.state.siteFormModalMode === ConstructionPlanModelMode.New ? uuidv4() : undefined,
            mode: this.state.siteFormModalMode,
            data: {},
            scaffoldings: []
        };
    }

    onScaffoldingsChange(updatedScaffoldings) {

        const { site } = this.props;

        const updatedSite = {
            ...site,
            scaffoldings: updatedScaffoldings
        };

        this.props.onChange(updatedSite);
    }

    getName() {
        return this.getData('name');
    }

    getDescription() {
        return this.getData('description', '');
    }

    getAddress() {
        return this.getData('address', null);
    }

    getData(fieldName, fallback = <NotAvailablePlaceholder />) {
        const { site } = this.props;
        const { referenceData } = this.state;
        return getData(site, referenceData, fieldName, fallback);
    }

    getScaffoldingsReferenceData() {
        const referenceData = this.state.referenceData;
        if (!referenceData) {
            return null;
        }

        return referenceData.scaffoldings;
    }

    renderSite() {

        const { site } = this.props;
        const { fetchingReferenceData, isArchived } = this.state;

        if (!site) {
            return (
                <div className="constructionPlanSite">
                    <ConstructionPlanSitePlaceholder
                        onAddExisting={() => this.onAddExisting()}
                        onAddNew={() => this.onAddNew()}
                    />
                </div>
            );
        }

        const { mode } = site;

        return (
            <div className="constructionPlanSite">
                {
                    isArchived && !fetchingReferenceData &&

                    <div className="warning-banner" style={{ marginTop: -46 }}>
                        Die Baustelle <strong>{this.getName()}</strong> wurde archiviert. Die
                        Bearbeitungs- und Planungsfunktionen des Baustellenplaners stehen
                        archivierten Baustellen nicht zur Verfügung.
                    </div>
                }

                <ConstructionPlanFormGroup
                    headerIcon={<ConstructionPlanModelModeIcon mode={mode} />}
                    wrapperClass="constructionPlannerSiteSelection"
                    title={
                        <ConstructionPlanFormGroupTitle
                            type={ConstructionPlannerSectionType.Site}
                        >
                            {this.getName()}
                            {isArchived && <ArchivedBadge />}
                        </ConstructionPlanFormGroupTitle>}
                    actions={
                        <ConstructionPlanFormSiteActions
                            onEdit={this.onEdit}
                            onDelete={this.toggleDeleteModal}
                            archived={isArchived}
                        />
                    }
                >
                    {mode === ConstructionPlanModelMode.Edit &&
                    <ConstructionPlanFormModelEditHint />}
                    <DataRow label="Adresse">
                        <Address address={this.getAddress()} />
                    </DataRow>
                    <DataRow label="Notizen">
                        <MultiLineText text={this.getDescription()} />
                    </DataRow>
                </ConstructionPlanFormGroup>
            </div>
        );
    }

    renderScaffoldings() {

        const { site } = this.props;
        const { isArchived } = this.state;

        if (!site || !site.id) {
            return (
                <ConstructionPlanFormScaffoldingList
                    disabled
                />
            );
        }

        return (
            <ConstructionPlanFormScaffoldingList
                siteId={site.id}
                siteMode={site.mode}
                scaffoldings={site.scaffoldings}
                onChange={this.onScaffoldingsChange}
                referenceData={this.getScaffoldingsReferenceData()}
                isArchived={isArchived}
            />
        );
    }

    isReferenceOnlyPlan(site) {

        let onlyReferences = true;

        walkConstructionPlanSite(site, null, (modelType, model) => {
            onlyReferences &= model.mode === ConstructionPlanModelMode.Reference;
        });

        return onlyReferences;
    };


    renderMedia() {

        const { site } = this.props;

        const isReferenceOnlyPlan = this.isReferenceOnlyPlan(site);
        const { isArchived } = this.state;

        if (!site || isReferenceOnlyPlan) {
            return (
                <ConstructionPlanFormMedia
                    site={null}
                    disabled
                />
            );
        }

        const { isFetchingMedia, referenceData, media } = this.state;
        if (isFetchingMedia) {
            return <LoadingSpinner block label="Lade Dokumente" />;
        }

        return (
            <ConstructionPlanFormMedia
                site={site}
                referenceData={referenceData}
                resolvedMedia={media}
                onChange={this.props.onChange}
                readOnly={isArchived}
            />
        );
    }

    getDeleteModalContent() {

        const { isArchived } = this.state;

        if (isArchived) {
            return (
                <Fragment>
                    <p>Sie sind im Begriff, Ihre Baustelle aus der Baustellenplanung zu
                        entfernen.</p>
                    <p style={{ fontWeight: 700 }}>Die verknüpfte Baustelle wurde bereits
                        archiviert. Archivierte Baustellen stehen für Baustellenplanungen nicht zur
                        Verfügung. Eine erneute Verknüpfung ist daher nicht möglich.</p>
                    <p>Möchten Sie fortfahren?</p>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <p>
                    Sie sind im Begriff, Ihre Baustelle aus der Baustellenplanung zu
                    löschen.

                    <strong> Alle angelegten Gerüste und Gerüstabschnitte werden damit
                        ebenfalls
                        zurückgesetzt.
                    </strong>
                </p>
                <p>Möchten Sie fortfahren?</p>
            </Fragment>
        );


    }

    render() {

        const { fetchingReferenceData, showFormModal, isArchived, showDeleteModal } = this.state;

        return (
            <Fragment>

                {fetchingReferenceData &&
                <LoadingSpinner label="Lade Livedaten" block />
                }

                {this.renderSite()}
                {this.renderScaffoldings()}
                {this.renderMedia()}

                {
                    showFormModal &&
                    <ConstructionPlanSiteFormModal
                        onUpdate={this.onChanged}
                        onClose={this.onCloseFormModal}
                        site={this.getFormModalData()}
                    />
                }

                {
                    showDeleteModal &&
                    <DeleteModal
                        modalTitle={isArchived ? 'Baustelle aus der Planung entfernen' : 'Baustelle entfernen'}
                        deleteEntity={this.onDelete}
                        closeModal={this.toggleDeleteModal}
                        submitBtnText="Fortfahren und entfernen"
                        submitBtnTextWhileDeleting="Wird entfernt ..."
                    >
                        {this.getDeleteModalContent()}

                    </DeleteModal>
                }
            </Fragment>
        );
    }
}

ConstructionPlanFormSite.propTypes = {
    site: ConstructionPlanFormSitePropType,
    onChange: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanSiteReferenceData,
        resolveConstructionPlanMedia
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanFormSite);
