import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFormModelModeActions from '../Common/ConstructionPlanFormModelModeActions';

const ConstructionPlanFormScaffoldingModeActions = ({ onAddNew, onAddExisting, scaffoldingsLeftToReference }) => (
    <ConstructionPlanFormModelModeActions
        addNewLabel="Neues Gerüst erstellen"
        addExistingLabel="Bestehendes Gerüst wählen"
        onAddExisting={onAddExisting}
        onAddNew={onAddNew}
        modelsLeftToReference={scaffoldingsLeftToReference}
    />
);

ConstructionPlanFormScaffoldingModeActions.propTypes = {
    onAddNew: PropTypes.func.isRequired,
    onAddExisting: PropTypes.func.isRequired,
    scaffoldingsLeftToReference: PropTypes.bool
};

ConstructionPlanFormScaffoldingModeActions.defaultProps = {
    scaffoldingsLeftToReference: false
};

export default ConstructionPlanFormScaffoldingModeActions;
