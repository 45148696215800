import React from 'react';
import { Link } from 'react-router-dom';

const ScaffeyeLogo = () => (
    <Link to="/">
        <img
            src="/images/Scaffeye-Logo.png"
            alt="SCAFFEYE"
            className="app-header__logo"
        />
    </Link>
);

export default ScaffeyeLogo;
