import React from 'react';

const Changelog_1_14_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">

            <section>
                <h4>Upload von größeren Dateien möglich</h4>
                <p>
                    Scaffeye® ermöglicht ab sofort das Hochladen von Dateien bis bis zu einer Größe von 60 MB pro Datei. Dabei dürfen die hochgeladenen Dateien in Summe die Grenze von 250 MB nicht überschreiten.
                </p>
            </section>

            <section>
                <h4>Fortschrittsanzeige für Dateiuploads</h4>
                <p>
                    Der Fortschritt eines Uploads wird ab sofort mit einer entsprechenden Anzeige deutlich gemacht.
                </p>
            </section>

            <section>
                <h4>Download von nicht unterstützen Dateiformaten in der Dateivorschau</h4>
                <p>
                    Die Datei-Vorschau bietet ab sofort die Möglichkeit, Dateien, die nicht in der Vorschau angezeigt werden können, herunterzuladen.
                </p>
            </section>

            <section>
                <h4>Anpassung des Formulars für Gerüstabschnitte</h4>
                <p>
                    Das Maske zur Erstellung von Gerüstabschnitten ermöglicht ab sofort die Mehrfachauswahl von Konsolen. Darüber hinaus ist für die Angabe der eingerüsteten Flächen der Inhalt des Freitextfeldes ausreichend.
                </p>
            </section>

            <section>
                <h4>DIN-SPEC Dokumente verfügbar</h4>
                <p>
                    Ab sofort sind die aktuellen Dokumente der DIN-SPEC 91402 im Bereich „Allg. Dokumente“ (Reiter „Sonstiges) zum Download verfügbar.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>
                        Nutzer mit technisch veralteten Internetbrowsern erhalten ab sofort einen Hinweis mit nützlichen Informationen und Tipps, um ihren Browser zu aktualisieren
                    </li>
                    <li>
                        Die Standzeit von Gerüsten ist ab sofort vordatierbar
                    </li>
                    <li>Fehlerhafte Formulareingaben werden deutlicher dargestellt</li>
                    <li>Weitere kleinere Verbesserungen und Korrekturen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_14_0;
