import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LoadingSpinner from '../Page/LoadingSpinner';

class Image extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            imageErrorMessage: ''
        };

        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    handleImageLoaded() {
        this.setState({
            isLoading: false,
            imageErrorMessage: ''
        });
    }

    handleImageError() {
        this.setState({
            isLoading: false,
            imageErrorMessage: 'Das Bild konnte nicht geladen werden'
        });
    }

    render() {
        const { isLoading, imageErrorMessage } = this.state;

        return (
            <React.Fragment>
                {isLoading && <LoadingSpinner label="Lade Bildvorschau ..." block />}
                {imageErrorMessage && <span className="input-grp__error">{imageErrorMessage}</span>}
                <img
                    className={this.props.className}
                    src={this.props.imageUrl}
                    onLoad={this.handleImageLoaded}
                    onError={() => this.handleImageError()}
                    alt="Bildvorschau"
                />
            </React.Fragment>
        );
    }
}

export default Image;

Image.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    className: PropTypes.string
};

Image.defaultProps = {
    className: ''
};
