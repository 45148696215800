import React from 'react';
import Icon from '../../../Common/Icon';
import { MediaVisibility } from '../../../Media/properties';
import SyncChoiceMediaPreviewLink from '../Base/Input/Media/SyncChoiceMediaPreviewLink';

const ConstructionPlanSyncMediaSummaryItem = ({ media }) => {

    const { name, visibility, id } = media;

    const renderMediaVisibilityIcon = (visibility) => {

        switch (visibility) {
            case MediaVisibility.AccountPrivate:
                return <Icon name="locked" />;

            default:
                return <Icon name="globe" />;
        }
    };

    const isSet = id !== null;

    if (!isSet) {
        return null;
    }

    return (
        <div className="sync-summary-media-item">
            <div className="sync-summary-media-item__cnt">

                <div className="sync-summary-media-item__media-info">
                    {name}
                </div>

                <div className="sync-summary-media-item__actions">
                    <span className="sync-summary-media-item__action-icon">
                        {renderMediaVisibilityIcon(visibility)}
                    </span>

                    <span className="sync-summary-media-item__action-icon">
                        <SyncChoiceMediaPreviewLink
                            file={media}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ConstructionPlanSyncMediaSummaryItem;
