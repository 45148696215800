import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ConstructionPlanReferenceList
    from '../../ConstructionPlan/Form/Common/ConstructionPlanReferenceList';
import { ConstructionPlanModelModePropType } from '../../ConstructionPlan/Form/propTypes';
import ConstructionPlanScaffoldingSectionItem
    from '../../ConstructionPlan/Form/Scaffolding/Modal/ConstructionPlanScaffoldingSectionItem';
import { ConstructionPlanModelMode } from '../../ConstructionPlan/properties';
import Checkbox from '../../Forms/Checkbox';
import ScaffeyeDatePicker from '../../Forms/DatePicker';
import Input from '../../Forms/Input';
import InputGroup from '../../Forms/InputGroup';
import InputHint from '../../Forms/InputHint';
import { FormMode, FormModePropType } from '../../Forms/properties';
import TextArea from '../../Forms/Textarea';
import Section from '../../Layout/Section';
import SiteSelect from '../../Sites/SiteSelect';
import ScaffoldingMediaFormPart from './ScaffoldingMediaFormPart';

function renderName({ readOnly }) {
    return (
        <InputGroup label="Bezeichnung*">
            <Input
                type="text"
                name="name"
                placeholder='z.B. "Rückfassade"'
                readOnly={readOnly}
            />
        </InputGroup>
    );
}

function renderSite({ isConstructionPlan, mode, values }) {

    if (isConstructionPlan) {
        return null;
    }

    if (mode === FormMode.Create) {
        return (
            <InputGroup
                label="Baustelle*"
            >
                <SiteSelect
                    name="siteId"
                />
            </InputGroup>
        );
    }

    return (
        <InputGroup
            label="Baustelle"
        >
            {values.site && values.site.name}
        </InputGroup>
    );
}

function renderPeriod({ values, readOnly }) {
    return (
        <Fragment>
            <InputGroup
                label="Geplantes Aufbaudatum"
            >
                <ScaffeyeDatePicker
                    name="scheduledErection"
                    placeholder='z.B. "01.02.2020"'
                    readOnly={readOnly}
                />
            </InputGroup>

            <InputGroup
                label="Geplantes Abbaudatum"
            >
                <ScaffeyeDatePicker
                    name="scheduledDismantling"
                    placeholder='z.B. "15.03.2020"'
                    minDate={values.scheduledErection}
                    readOnly={readOnly}
                />
            </InputGroup>
        </Fragment>
    );
}

function renderDescription({ readOnly }) {
    return (
        <InputGroup label="Notizen">
            <TextArea
                type="text"
                name="description"
                readOnly={readOnly}
                placeholder=" Hier können Sie zusätzliche Daten zum Gerüst hinterlegen"
            />
        </InputGroup>
    );
}

function renderTechnicalSpecification({ mode, isConstructionPlan }) {
    return (
        <InputGroup
            label="Gerüstabschnitte und Technische Spezifikation"
        >
            {!isConstructionPlan && mode === FormMode.Create ?
                <Fragment>
                    <Checkbox
                        name="createSection"
                        label="Gerüstabschnitte nach Gerüsterstellung anlegen"
                    />
                    <p>
                        Wählen Sie dieses Feld aus, wenn einer oder mehrere
                        Gerüstabschnitte für dieses
                        Gerüst bereits bekannt sind und Sie diese direkt hinterlegen
                        möchten.
                    </p>
                </Fragment>
                :
                <InputHint showIcon>
                    Technische Spezifikationen dieses Gerüstes können in den Gerüstabschnitten
                    hinterlegt werden.
                    {isConstructionPlan && mode === FormMode.Create &&
                    `Gerüstabschnitte können nach Erstellung des Gerüstes über die Baustellenplanung erstellt werden.`
                    }
                </InputHint>
            }
        </InputGroup>
    );
}

function renderSections({ referenceData, isConstructionPlan, constructionPlanMode }) {

    if (!isConstructionPlan) {
        return null;
    }

    const sections = (referenceData && referenceData.sections) ? referenceData.sections : [];

    return (
        <InputGroup
            label="Zugehörige Gerüstabschnitte"
        >
            {constructionPlanMode !== ConstructionPlanModelMode.New &&
            <ConstructionPlanReferenceList
                data={sections}
            >
                {(section) =>
                    <ConstructionPlanScaffoldingSectionItem
                        key={section.id}
                        data={section}
                    />
                }
            </ConstructionPlanReferenceList>
            }

            <InputHint showIcon>
                Gerüstabschnitte können nach dem Abspeichern im Baustellenplaner eingesehen,
                hinzugefügt und angepasst werden
            </InputHint>

        </InputGroup>
    );
}

const ScaffoldingFormContent = (props) => {

    const { attachmentsMutator, utilizationPlanMutator, anchorProtocolMutator, values, readOnly, isConstructionPlan } = props;

    const wrapperClasses = ['form-content'];

    if (readOnly) {
        wrapperClasses.push('form-content--disabled');
    }

    return (
        <React.Fragment>
            <Section
                title="Allgemeine Angaben*"
                collapsible
            >
                <div className={wrapperClasses.join(' ')}>
                    {renderName(props)}
                    {renderSite(props)}
                    {renderPeriod(props)}
                    {renderDescription(props)}
                </div>
                {renderTechnicalSpecification(props)}
                {renderSections(props)}
            </Section>

            <Section
                title="Dokumente"
                collapsible
                isCollapsed={!readOnly}
            >
                <ScaffoldingMediaFormPart
                    attachments={values.attachments}
                    attachmentsMutator={attachmentsMutator}
                    utilizationPlans={values.utilizationPlans}
                    utilizationPlanMutator={utilizationPlanMutator}
                    anchorProtocolMutator={anchorProtocolMutator}
                    onUploading={props.onUploading}
                    isConstructionPlan={isConstructionPlan}
                    readOnly={readOnly}
                />
            </Section>
        </React.Fragment>
    );
};

ScaffoldingFormContent.propTypes = {
    values: PropTypes.object.isRequired,
    mode: FormModePropType.isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    utilizationPlanMutator: PropTypes.func.isRequired,
    anchorProtocolMutator: PropTypes.func.isRequired,
    onUploading: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isConstructionPlan: PropTypes.bool,
    constructionPlanMode: ConstructionPlanModelModePropType,
    referenceData: PropTypes.object
};

ScaffoldingFormContent.defaultProps = {
    readOnly: false,
    isConstructionPlan: false,
    constructionPlanMode: undefined,
    referenceData: undefined
};

export default ScaffoldingFormContent;
