import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { autocompleteCustomerMainContact } from '../../../redux/modules/customer/action';
import AutocompleteSelect from './AutocompleteSelect';

const ContactPersonAutocomplete = (props) => {
    const { onChange, label, initialValue, actions: { autocompleteCustomerMainContact } } = props;

    const initialOption = initialValue ? {
        label: initialValue,
        value: initialValue
    } : null;

    return (
        <AutocompleteSelect
            getValues={autocompleteCustomerMainContact}
            onSelectionChange={onChange}
            label={label}
            loadingMessage={() => 'Lade Hauptkontakte ...'}
            placeholder="Kontakt wählen"
            initialOption={initialOption}
        />
    );
};

ContactPersonAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    label: PropTypes.string
};

ContactPersonAutocomplete.defaultProps = {
    initialValue: null,
    label: 'Hauptkontakt'
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteCustomerMainContact
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ContactPersonAutocomplete);
