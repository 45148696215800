import React, { Fragment } from 'react';
import Icon from '../../Common/Icon';
import { EmployeeRole, EmployeeRoleLabel, getWeatherLabel, WeatherOptions } from './properties';

export const renderWeatherOption = (weather) => {
    return <Fragment>{renderWeatherIcon(weather)} {getWeatherLabel(weather)}</Fragment>;
};

export const renderWeatherIcon = (weather) => {

    switch (weather) {
        case WeatherOptions.Sunny:
            return <span className="weather-option__icn"><Icon name="weatherSunny" /></span>;
        case WeatherOptions.Cloudy:
            return <span className="weather-option__icn"><Icon name="weatherCloudy" /></span>;
        case WeatherOptions.Rainy:
            return <span className="weather-option__icn"><Icon name="weatherRainy" /></span>;
        case WeatherOptions.Stormy:
            return <span className="weather-option__icn"><Icon name="weatherStormy" /></span>;
        default:
            return null;
    }

};

export const renderDiaryEntryTimeDuration = (timeInMinutes) => {

    if (!timeInMinutes || parseInt(timeInMinutes, 10) === 0) {
        return null;
    }

    let hours = Math.floor(timeInMinutes / 60) || null;
    let minutes = timeInMinutes % 60 || null;

    if (hours) {
        hours += 'h';
    }

    if (minutes) {
        minutes += 'min';
    }

    return `${hours || ''} ${minutes || ''}`;

};


export const renderEmployeeRoleLabel = (role) => {

    switch (role) {
        case EmployeeRole.ScaffoldingGroupMasterLeader:
        case EmployeeRole.ScaffoldingGroupLeader:
            return (
                <div className="employee-role-label">
                    <span className="employee-role-label__icn"> <Icon name="star" /> </span>
                    <span className="employee-role-label__cnt"
                    > {EmployeeRoleLabel[role]}</span>
                </div>
            );
        default:
            return (
                <div className="employee-role-label">
                    {EmployeeRoleLabel[role]}
                </div>
            );
    }
};
