import _ from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';

function checkCondition(value, contains, is, isOneOf, isNot, isNotSet, isSet) {
    if (contains) {
        if (_.isArray(contains)) {
            return _.difference(contains, value).length === 0;
        }
        return _.includes(value, contains);
    } else if (is) {
        return value === is;
    } else if (isSet) {
        return !!value;
    } else if (isNot) {
        return value !== isNot;
    } else if (isOneOf) {
        return _.includes(isOneOf, value);
    } else if (isNotSet) {
        return !value;
    }
}

const Condition = ({ when, contains, is, isOneOf, isNot, isNotSet, isSet, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {

            if (!checkCondition(value, contains, is, isOneOf, isNot, isNotSet, isSet)) {
                return null;
            }

            return children;
        }}
    </Field>
);

export default Condition;
