import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResubscribeLink from './ResubscripeLink';
import { AccountTypes } from '../subscriptionTypes';
import InfoModal from '../../Common/Modal/InfoModal';
import MailToLink from '../../Common/MailToLink';
import ExternalLink from '../../Common/ExternalLink';

class SubscriptionStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            showUpgradeModal: false
        };
        this.renderIcon = this.renderIcon.bind(this);
    }

    getSubscriptionName() {
        switch (this.state.type) {
            case AccountTypes.pro:
                return 'Scaffeye\t\xAE Pro';
            case AccountTypes.basic:
                return 'Scaffeye\t\xAE Basic';
            case AccountTypes.light:
                return 'Scaffeye\t\xAE Light';
            default:
                return 'Scaffeye\t\xAE Light';
        }
    }

    renderIcon() {
        let text;
        let className;

        switch (this.state.type) {
            case 'pro':
                text = 'PRO';
                className = 'pro';
                break;
            case 'basic':
                text = 'BASIC';
                className = 'basic';
                break;
            case 'light':
                text = 'LIGHT';
                className = 'light';
                break;
            default:
                text = 'PRO';
                className = 'pro';
                break;
        }
        return (
            <div className={`subscription-details__icon ${className}`}><span>{text}</span></div>
        );
    }

    toggleUpgradeModal() {
        this.setState(prevState => ({
            showUpgradeModal: !prevState.showUpgradeModal
        }));
    }

    render() {
        const { details: user } = this.props;
        const { type, showUpgradeModal } = this.state;
        const { account } = user;

        const { ends, nextRenewal, isOnTrial } = account.mainSubscription;
        const endsLabel = ends ? moment(ends)
            .format('DD.MM.YYYY') : '';
        const renewsOn = !ends ? moment(nextRenewal)
            .format('DD.MM.YYYY') : '';
        const expired = ends && moment(ends)
            .isBefore(moment());
        const subscriptionIsExpiring = !expired
            && moment(ends)
                .isAfter(moment())
            && moment(ends)
                .subtract(7, 'days')
                .isBefore(moment());

        if (type === AccountTypes.light) {
            return (
                <div className="subscription-details">
                    {this.renderIcon()}
                    <div className="subscription-details__content">
                        <div><strong>{this.getSubscriptionName()}</strong></div>
                        <div>
                            <span>Kostenloses Konto</span>
                            <span
                                onClick={() => this.toggleUpgradeModal()}
                                className="btn btn--primary-green subscription-details__upgrade-cta"
                            >Upgrade durchführen
                            </span>
                        </div>
                        <div className="subscription-details__metaoptions">
                            <Link to="/account/subscription/details">
                                Details
                            </Link>
                        </div>
                    </div>
                    {
                        showUpgradeModal &&
                        <InfoModal
                            title="Upgrade durchführen"
                            onClose={() => this.toggleUpgradeModal()}
                        >
                            <p>
                                Vielen Dank für Ihr Vertrauen in Scaffeye®! <br />
                                Um ein Konto-Upgrade auf Scaffeye®-Basic oder -Pro durchzuführen,
                                kontaktieren Sie bitte unseren Support:
                            </p>
                            <p>
                                <strong>Telefon:</strong> <br />
                                +49(0) 651 69933021
                            </p>
                            <p>
                                <strong>E-Mail:</strong> <br />
                                <MailToLink email="info@scaffeye.de">
                                    info@scaffeye.de
                                </MailToLink>
                            </p>

                            <p>Bei Fragen rund um das Leistungsspektrum, besuchen Sie bitte unsere
                                Seite <ExternalLink
                                target="https://www.scaffeye.de/preise/"
                                label="Pakete & Preise"
                                />
                            </p>
                        </InfoModal>
                    }
                </div>
            );
        }

        return (
            <div className="subscription-details">
                {this.renderIcon()}
                <div className="subscription-details__content">
                    <strong>{this.getSubscriptionName()} {' '} {isOnTrial && '(Probeabonnement)'}</strong>
                    {ends ?
                        <div>
                            {`Gekündigt zum ${endsLabel}.`}
                            {
                                subscriptionIsExpiring &&
                                <div>
                                    <small>
                                        <strong>
                                            Ihr Abonnement endet innerhalb der nächsten 7 Tage.
                                        </strong>
                                    </small>
                                </div>
                            }
                            <div>
                                <ResubscribeLink
                                    label="Jetzt verlängern"
                                    user={user}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            {`Verlängerung am: ${renewsOn}`}
                        </div>
                    }

                    <div className="subscription-details__metaoptions">
                        <Link to="/account/subscription/details">
                            Details
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

}

SubscriptionStatus.propTypes = {
    details: PropTypes.object.isRequired
};

export default SubscriptionStatus;
