import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../Common/Icon';

const ConstructionPlanFormButton = ({ onClick, label, icon, block }) => {

    const wrapperClasses = ['constructionPlannerBtn'];

    if (block) {
        wrapperClasses.push('constructionPlannerBtn--block');
    }

    return (
        <div className={wrapperClasses.join(' ')} onClick={onClick}>
        <span className="constructionPlannerBtn-icon">
            <Icon name={icon} />
        </span>
            {label}
        </div>
    );
};

export default ConstructionPlanFormButton;

ConstructionPlanFormButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    block: PropTypes.bool
};

ConstructionPlanFormButton.defaultProps = {
    onClick: () => false,
    block: false
};


