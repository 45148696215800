import React from 'react';

const Table = ({ children }) => {

    return (
        <div className="content-table standalone">
            <table
                cellPadding="0"
                cellSpacing="0"
                className="hover stack"
            >
                {children}
            </table>
        </div>
    );
};

export default Table;
