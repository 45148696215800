import React from 'react';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';
import OptionGroup from '../../../Forms/OptionGroup';
import { isEmergencyStairway, isLadderAscent } from '../functions';
import { PlannedWorkOptions } from '../options';
import { isForbidden, isRequired } from '../validate';

export const SectionPlannedWorkInput = ({ values }) => {

    const _isLadderAscent = isLadderAscent(values);
    const _isEmergencyStairway = isEmergencyStairway(values);
    const isDisabled = isForbidden('plannedWorks', values);
    let disabledToolTip = undefined;
    if (isDisabled) {
        if(_isLadderAscent) {
            disabledToolTip = 'Auf Einzelfelder als Aufstieg können keine Arbeiten durchgeführt werden';
        } else if (_isEmergencyStairway) {
            disabledToolTip = 'Auf Fluchttreppen können keine Arbeiten durchgeführt werden';
        } else {
            disabledToolTip = 'Auf der gewählten Gerüstabschnitts-Spezifikation können keine Arbeiten druchgeführt werden';
        }
    }

    let options = PlannedWorkOptions;
    if (isDisabled) {
        options = PlannedWorkOptions.map(option => {
            return {
                ...option,
                disabled: true
            }
        })
    }

    return (
        <InputGroup
            label="Geplante Arbeiten"
            required={isRequired('plannedWorks', values)}
            tooltip={isDisabled ? disabledToolTip : undefined}
        >
            <OptionGroup
                name="plannedWorks"
                options={options}
                ifSelectionIs="other"
                thenRender={() => (
                    <Input
                        name="plannedWorksOther"
                        type="text"
                        placeholder='z.B. "Fassadenrestauration"'
                        disabled={isDisabled}
                    />
                )}
                type="checkbox"
            />
        </InputGroup>
    );
}
