import { query } from '../../action';

export function fetchScaffoldingsSelectOptions(siteId, loadSections = false, managed) {
    return (dispatch, getState) => {
        const sectionsQuery = loadSections ? 'sections{id,name}' : '';
        const divideByManagedFlag = managed !== undefined ? `managed:${managed}` : '';

        const token = getState().currentUser.auth_token;
        return query(`Scaffoldings{scaffoldings(siteId:"${siteId}",limit:-1,${divideByManagedFlag}) {
           data {
               id,
               name,
               managed
               ${sectionsQuery}
           }
       }}`, {
            token
        })
            .then(data => data.scaffoldings.data);
    };
}

export function fetchScaffoldingForSectionFormData(scaffoldingId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(`Scaffolding{scaffolding(id:"${scaffoldingId}") {
            id,
            managed,
            name,
            site {
                id, name
            }
       }}`, {
            token
        })
            .then(data => data.scaffolding);
    };
}

export function fetchScaffoldingsForConstructionPlan(siteId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(`Scaffoldings{scaffoldings(siteId:"${siteId}",limit:-1,managed: true) {
           data {
               id,
               name,
               managed,
               sections{id, name, equippedSurfaces, plannedWorks},
               utilizationPlans{id, name, fileName, mimeType, size, visibility},
               attachments{id, name, fileName, mimeType, size, visibility},
               anchorProtocol{id, name, fileName, mimeType, size, visibility},
           }
       }}`, {
            token
        })
            .then(data => data.scaffoldings.data);
    };
}
