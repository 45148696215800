import PropTypes from 'prop-types';
import React from 'react';

const ModalFormFooter = ({ onClose, onSubmit, submitLabel, cancelLabel, hasRequiredFields, isSubmitting, submitDisabled }) => (
    <div className="btn-group">
        <a
            className="btn btn--backward-action"
            onClick={onClose}
        >
            {cancelLabel}
        </a>
        {hasRequiredFields && <span className="btn btn--dummy">*Pflichtfeld</span>}
        <button
            type="submit"
            className="btn btn--forward-action btn--save"
            disabled={isSubmitting || submitDisabled}
            onClick={onSubmit}
        >
            {submitLabel}
        </button>
    </div>
);

ModalFormFooter.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    cancelLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    isSubmitting: PropTypes.bool,
    submitDisabled: PropTypes.bool,
    hasRequiredFields: PropTypes.bool
};

ModalFormFooter.defaultProps = {
    isSubmitting: false,
    submitDisabled: false,
    hasRequiredFields: true,
    cancelLabel: 'Abbrechen'
};

export default ModalFormFooter;
