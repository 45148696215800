import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import JobsBreadcrumb from '../../Containers/Pages/Private/JobManagement/JobsBreadcrumb';
import { showRequestError } from '../../redux/modules/error/action';
import { fetchJob } from '../../redux/modules/job/actions';
import DateService from '../../Services/DateService';
import { getFieldCount, optionallyAddCount } from '../../utils';
import { ModalTypes } from '../Common/AddResourceDropDown';
import ArchiveJobConfirmationModal, { ArchiveActions } from './ArchiveJobConfirmationModal';
import ContentReveal from '../Common/ContentReveal';
import DataRow from '../Common/DataRow';
import EmptyCardHint from '../Common/EmptyCardHint';
import Icon from '../Common/Icon';
import IfUser from '../Common/IfUser';
import MailToLink from '../Common/MailToLink';
import MultiLineText from '../Common/MultiLineText';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import { CustomerReference } from '../Common/ObjectReference';
import ConstructionPlan from '../ConstructionPlan/Detail/ConstructionPlan';
import Section from '../Layout/Section';
import MediaList from '../Media/MediaList';
import { JobPageHeader } from '../Page/JobPageHeader';
import { PageLoadingSpinner } from '../Page/LoadingSpinner';
import TopNav from '../Page/TopNav';
import JobCorrespondenceSection from './Correspondence/JobCorrespondence';
import DeleteJobLink from './DeleteJobLink';
import JobChangeLogModal from './Detail/JobChangeLogModal';
import JobFormModal from './Form/JobFormModal';
import JobStatus from './JobStatus';
import { renderOfferDeadLine } from './utils';

const getNavLinks = (job) => {
    const documentCount = getFieldCount(job, 'attachments');
    const correspondenceCount = 0;

    return [
        {
            id: 'generalInformation',
            label: 'Allgemeine Angaben',
        },
        {
            id: 'constructionplanner',
            label: 'Baustelle & Gerüste',
        },
        {
            id: 'documents',
            label: optionallyAddCount('Dokumente', documentCount),
        },
        {
            id: 'correspondence',
            label: optionallyAddCount('Korrespondenz', correspondenceCount),
        },
    ];
}

class JobDetail extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            loading: true,
            isFetchingJob: true,
            job: {},
            isRequest: props.isRequest,
            constructionPlanFormModalIsOpen: false,
            showArchiveConfirmationModal: false,
            showChangeLogModal: false,
        };

        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.onConstructionPlanUpdate = this.onConstructionPlanUpdate.bind(this);
        this.onConstructionPlanSyncronization = this.onConstructionPlanSyncronization.bind(this);
        this.toggleArchiveConfirmationModal = this.toggleArchiveConfirmationModal.bind(this);
        this.toggleChangeLogModal = this.toggleChangeLogModal.bind(this);
        this.onCollapse = this.onCollapse.bind(this);
    }

    conditionallyOpenConstructionPlannerModal() {
        const {history: {location}} = this.props;

        if (!location.state) {
            return;
        }

        const {openModal} = location.state;

        if (!openModal) {
            return;
        }

        if (openModal.type === ModalTypes.ConstructionPlan) {
            this.props.history.replace({
                ...location,
                state: {},
            });

            this.setState({
                constructionPlanFormModalIsOpen: true,
            });
        }

    }

    componentDidMount() {
        this.loadJob();
        this.conditionallyOpenConstructionPlannerModal();
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onUpdated() {
        this.toggleEditModal();
        this.loadJob();
    }

    toggleEditModal() {

        this.setState((prevState) => {
            return {
                showEditModal: !prevState.showEditModal,
            };
        });
    }

    onConstructionPlanUpdate() {
        this.loadJob();
        this.setState({
            constructionPlanFormModalIsOpen: false,
        });
    }

    onConstructionPlanSyncronization() {
        this.loadJob();
    }

    componentDidUpdate(prevProps) {
        const {jobId} = this.props;
        if (jobId !== prevProps.jobId) {
            this.loadJob();
            this.conditionallyOpenConstructionPlannerModal();
        }
    }

    showDocuments(documents) {

        if (!documents) {
            return null;
        }

        if (documents.length > 1) {
            return (
                <ContentReveal
                    showMoreLabel={`${documents.length} Dokumente anzeigen`}
                    classes="content-reveal--documents"
                >
                    <DataRow
                        label="Anhänge"
                    >
                        <MediaList
                            media={documents}
                            emptyLabel="Keine Anhänge hinterlegt"
                            groupByVisibility
                        />
                    </DataRow>
                </ContentReveal>
            );
        }

        return (
            <DataRow
                label="Anhänge"
            >
                <MediaList
                    media={documents}
                    emptyLabel="Keine Anhänge hinterlegt"
                    groupByVisibility
                />
            </DataRow>
        );
    }

    loadJob() {
        this.setState({
            loading: true,
        });

        this.props.actions.fetchJob(this.props.jobId)
            .then((job) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                const isRequest = job.type === 'request';
                const isArchived = job.archived;
                const historyKey = isRequest ? 'requestId' : 'jobId';

                const {history} = this.props;
                history.replace({
                    ...history.location,
                    state: {
                        ...history.location.state,
                        [historyKey]: job.id,
                    },
                });

                this.setState({
                    job,
                    constructionPlan: _.isEmpty(job.constructionPlan) ? {} : job.constructionPlan,
                    isFetchingJob: false,
                    loading: false,
                    isRequest,
                    isArchived,
                });
            })
            .catch((error) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                showRequestError('Auftragsdaten konnten nicht geladen werden', error);

                this.setState({
                    job: null,
                    isFetchingJob: false,
                    loading: false,
                });
            });
    }

    toggleArchiveConfirmationModal() {
        this.setState(prevState => ({
            showArchiveConfirmationModal: !prevState.showArchiveConfirmationModal,
        }));
    }

    onArchiveConfirmation(action) {

        const {isRequest} = this.state;

        const {history} = this.props;

        let queryString = '';

        if (action === ArchiveActions.Archived) {
            queryString = 'archived=true';
        }

        const historyStateKey = isRequest ? 'requestQueryString' : 'jobQueryString';

        history.replace({
            ...history.location,
            state: {
                ...history.location.state,
                [historyStateKey]: queryString,
            },
        });

        this.toggleArchiveConfirmationModal();
        this.loadJob();

    }

    toggleChangeLogModal() {
        this.setState(prevState => ({
            showChangeLogModal: !prevState.showChangeLogModal,
        }));
    }

    onCollapse(collapsed) {
        this.setState({collapseHeader: collapsed});
    }

    render() {
        const {
            job,
            isFetchingJob,
            loading,
            showEditModal,
            isRequest,
            constructionPlan,
            constructionPlanFormModalIsOpen,
            showArchiveConfirmationModal,
            isArchived,
            showChangeLogModal,
            collapseHeader,
        } = this.state;

        if (!isFetchingJob && !job) {
            return <ResourceNotFoundErrorPage/>;
        }

        const {
            status,
            customer,
            description,
            offerDeadline,
            customerContact,
        } = job;

        const {jobId} = this.props;

        return (
            <Fragment>
                <TopNav hide={collapseHeader}/>

                <JobsBreadcrumb
                    job={job}
                    isRequest={isRequest}
                    collapse={collapseHeader}
                />

                <JobPageHeader
                    job={job}
                    isJobRequest={isRequest}
                    isArchived={isArchived}
                    disabled={loading}
                    navLinks={getNavLinks(job)}
                    onCollapse={this.onCollapse}
                    onChange={() => this.loadJob()}
                    onClickArchive={this.toggleArchiveConfirmationModal}
                    onClickEdit={this.toggleEditModal}
                    onClickShowChangelog={this.toggleChangeLogModal}
                    isLoading={loading}
                />

                <Fragment>
                    {loading && <PageLoadingSpinner/>}

                    {
                        !loading && (
                            <Fragment>
                                <Section
                                    title="Allgemeine Angaben"
                                    id="generalInformation"
                                >
                                    <DataRow
                                        label="Bezeichnung"
                                    >
                                        {job.name}
                                    </DataRow>

                                    {isArchived &&
                                    <div className="section-intro">
                                        <DataRow
                                            label="Archiviert am"
                                        >
                                            {DateService.getReadableDateTime(job.archivedAt)}
                                        </DataRow>

                                        <DataRow
                                            label="Status vor Archivierung"
                                        >
                                            <JobStatus
                                                status={job.status}
                                                horizontal
                                            />
                                        </DataRow>
                                    </div>
                                    }

                                    <DataRow
                                        label="Kunde"
                                    >
                                        {customer &&
                                        <CustomerReference
                                            id={customer.id}
                                            label={customer.name}
                                        >
                                        </CustomerReference>
                                        }
                                    </DataRow>

                                    <DataRow
                                        label="Ansprechpartner"
                                        containerClass="job__customer-contact"
                                    >
                                        {customerContact ?
                                            <Fragment>
                                                <div>
                                                    <strong>{customerContact.firstName} {customerContact.lastName} </strong> {customerContact.position && `(${customerContact.position})`}
                                                </div>
                                                {customerContact.phone &&
                                                <div>Telefon: {customerContact.phone}</div>}
                                                {customerContact.mobile &&
                                                <div>Telefon (mobil): {customerContact.mobile}</div>}
                                                {customerContact.email &&
                                                <div>E-Mail Adresse: <MailToLink
                                                    email={customerContact.email}
                                                >{customerContact.email}
                                                </MailToLink>
                                                </div>}
                                            </Fragment>
                                            : <NotAvailablePlaceholder/>
                                        }
                                    </DataRow>

                                    <DataRow
                                        label="Notizen"
                                    >
                                        {description ?
                                            <div className="data-row__description">
                                                <MultiLineText text={description}/>
                                            </div> : <NotAvailablePlaceholder/>
                                        }
                                    </DataRow>

                                    <DataRow
                                        label="Erstellt am"
                                    >
                                        {DateService.getReadableDateTime(job.created)}
                                    </DataRow>

                                    {isRequest && !isArchived && (
                                        <DataRow
                                            label="Angebotsabgabe bis"
                                        >
                                            {renderOfferDeadLine(offerDeadline, status)}
                                        </DataRow>
                                    )}

                                </Section>

                                <ConstructionPlan
                                    jobId={this.props.jobId}
                                    jobLabel={`${isRequest ? 'Anfrage' : 'Auftrag'}: ${job.name}`}
                                    constructionPlan={constructionPlan}
                                    onConstructionPlanUpdate={this.onConstructionPlanUpdate}
                                    onConstructionPlanSynchronization={this.onConstructionPlanSyncronization}
                                    constructionPlanFormModalIsOpen={constructionPlanFormModalIsOpen}
                                    isArchived={isArchived}
                                />

                                <Section
                                    title="Dokumente"
                                    id="documents"
                                >
                                    {job.attachments && job.attachments.length ?
                                        this.showDocuments(job.attachments)
                                        :
                                        <EmptyCardHint
                                            title="Es wurden keine Dokumente für diese Anfrage hochgeladen."
                                        >
                                            <p>Laden Sie zur Anfrage zugehörige Dokumente hoch, um diese hier
                                               angezeigt zu bekommen. Klicken Sie dazu
                                               auf &quot;Bearbeiten&quot; und wählen Sie Ihre gewünschten Anhänge
                                               im Bereich &quot;Dokumente&quot; aus.
                                            </p>
                                        </EmptyCardHint>
                                    }
                                </Section>

                                {/* Job correspondence section */}
                                <JobCorrespondenceSection
                                    disableManipulation={isArchived}
                                    job={job}
                                />

                                <IfUser
                                    can={['delete-jobs']}
                                >
                                    <div className="delete-option">
                                        <DeleteJobLink
                                            id={job.id}
                                            name={job.name}
                                            type={job.type}
                                            disabled={isFetchingJob}
                                            onDeleted={() => this.props.history.push('/jobs')}
                                            link={(disabled, onClick) => (
                                                <a
                                                    className={['btn', 'btn--delete', disabled ? 'btn--disabled' : null].join(' ')}
                                                    onClick={onClick}
                                                >
                                        <span className="btn__icon">
                                            <Icon name="delete"/>
                                        </span>
                                                    Löschen
                                                </a>
                                            )}
                                        />
                                    </div>
                                </IfUser>
                            </Fragment>
                        )
                    }

                </Fragment>

                {showEditModal &&
                <JobFormModal
                    mode="edit"
                    id={job.id}
                    onClose={this.toggleEditModal}
                    onUpdated={this.onUpdated}
                    isRequest={isRequest}
                />
                }

                {
                    showArchiveConfirmationModal &&
                    <ArchiveJobConfirmationModal
                        onConfirmationSuccess={(action) => this.onArchiveConfirmation(action)}
                        id={jobId}
                        isRequest={isRequest}
                        isArchived={isArchived}
                        onClose={this.toggleArchiveConfirmationModal}
                    />
                }

                {showChangeLogModal &&
                <JobChangeLogModal
                    jobId={job.id}
                    onClose={this.toggleChangeLogModal}
                />
                }
            </Fragment>
        );
    }
}

JobDetail.propTypes = {
    jobId: PropTypes.string.isRequired,
    isRequest: PropTypes.bool,
};

JobDetail.defaultProps = {
    isRequest: false,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchJob,
    }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(JobDetail));
