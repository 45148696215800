import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Common/Icon';

const InputHint = ({ children, showIcon, important }) => {
    let classNames = ['input-hint'];
    if (important) {
        classNames.push('input-hint--important');
    }

    return (
        <div className={classNames.join(' ')}>
            {
                showIcon &&
                <div className="input-hint__icon"><Icon name="info" /></div>
            }
            <div className="input-hint__msg">
                {children}
            </div>
        </div>
    );
};

InputHint.propTypes = {
    children: PropTypes.node.isRequired,
    showIcon: PropTypes.bool
};

InputHint.defaultProps = {
    showIcon: false
};

export default InputHint;
