import * as PropTypes from 'prop-types';
import React from 'react';

function getSubject(account) {
    return `Wiedereröffnung meines Scaffeye®-Kontos [${account.id}]`;
}

function getBody(user) {
    return `
Sehr geehrte Damen und Herren, %0D%0A 
%0D%0A 
gerne würde ich mein Abonnement bei Scaffeye® wieder aufnehmen. %0D%0A
%0D%0A
Neben meinem eigenen Nutzerkonto möchte ich gerne folgende Nutzer ebenfalls reaktivieren: %0D%0A 
%0D%0A 
[Bitte Nutzer angeben] %0D%0A 
%0D%0A 
Mit freundlichen Grüßen, %0D%0A 
%0D%0A
${user.firstName} ${user.lastName}`;
}

const ResubscribeLink = props => (
    <a
        href={`mailto:info@scaffeye.de?subject=${getSubject(props.user.account)}&body=${getBody(props.user)}`}
        className="btn btn--save btn--mailto btn--resubscribe"
    >
        {props.label}
    </a>
);

ResubscribeLink.requiredProps = {
    user: PropTypes.object.isRequired
};

export default ResubscribeLink;
