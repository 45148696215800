import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import SyncChoiceRow from './SyncChoiceRow';

const SyncChoiceDiffOptionRows = ({ planValue, liveValue, getOptionRows, renderValue, onSelect, isSelected, getHint }) => {

    const diffOptionRows = getOptionRows(planValue, liveValue);
    if (_.isEmpty(diffOptionRows)) {
        return null;
    }

    return diffOptionRows.map(optionRow => (
        <SyncChoiceRow
            key={optionRow.key}
            leftValue={optionRow.left}
            rightValue={optionRow.right}
            renderValue={renderValue}
            onSelect={onSelect}
            isSelected={isSelected}
            hint={getHint}
        />
    ));
};

SyncChoiceDiffOptionRows.propTypes = {
    getOptionRows: PropTypes.func.isRequired,
    renderValue: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    planValue: PropTypes.any,
    liveValue: PropTypes.any,
    getHint: PropTypes.func
};

SyncChoiceDiffOptionRows.defaultProps = {
    getHint: () => undefined
};

export default SyncChoiceDiffOptionRows;
