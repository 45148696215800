import PropTypes from 'prop-types';
import React from 'react';
import Modal from './Modal';

const ConfirmationModal = (props) => {
    const {
        title, buttonClassName, isConfirming, buttonTitle, onConfirm, closeModal, classes, children
    } = props;
    return (
        <Modal
            id="modal-confirmation"
            title={title}
            type="confirmation"
            classes={classes}
            onBackdropClick={() => closeModal()}
            footer={
                <div className="btn-group">
                    <button
                        className="btn btn--backward-action"
                        onClick={() => closeModal()}
                    >
                        Zurück
                    </button>
                    <button
                        type="submit"
                        className={buttonClassName}
                        disabled={isConfirming}
                        onClick={onConfirm}
                    >
                        {isConfirming ? 'Wird gesendet' : buttonTitle}
                    </button>
                </div>
            }
        >
            {children}
        </Modal>
    );
}

export default ConfirmationModal;

ConfirmationModal.propTypes = {
    title: PropTypes.string.isRequired,
    isConfirming: PropTypes.bool,
    buttonTitle: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.string,
    buttonClassName: PropTypes.string,
    backButtonTitle: PropTypes.string
};

ConfirmationModal.defaultProps = {
    classes: '',
    buttonClassName: 'btn btn--forward-action btn--save',
    isConfirming: false
};
