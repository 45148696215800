import * as types from '../../types';

const initialState = {
    addSectionError: null,
    addNoteError: null,
    sectiondata: {},
    isFetchingSection: false
};

export default function sectionReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_SECTION_SUCCESS:
            return {
                ...state
            };
        case types.ADD_SECTION_FAILURE:
            return {
                ...state,
                addSectionError: action.error
            };
        case types.ADD_SECTION_NOTES_SUCCESS:
            return {
                ...state
            };
        case types.ADD_SECTION_NOTES_FAILURE:
            return {
                ...state,
                addNoteError: action.error
            };

        case types.FETCH_SECTION:
            return {
                ...state,
                isFetching: true
            };

        case types.FETCH_SECTION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                sectiondata: action.payload
            };

        case types.FETCH_SECTION_FAILURE:
            return {
                ...state,
                isFetching: false,
                errors: action.payload
            };
        default:
            return state;
    }
}
