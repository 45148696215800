import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import FieldError from './FieldError';

function renderOptions(options) {

    if (!options) {
        return null;
    }

    return options.map((option, i) => {
        if (typeof option.value !== 'string') {
            return (
                <optgroup key={i} label={option.label}>
                    {renderOptions(option.value)}
                </optgroup>
            );
        }

        return (
            <option
                value={option.value}
                key={i}
                disabled={option.disabled}
            >
                {option.label}
            </option>
        );
    });
}

const OptionGroupSelect = ({ name, onChange, options, label, disabled, placeholder, ...fieldProps }) => (
    <Field
        name={name}
    >
        {({ input, meta }) => {

            const inputCssClasses = ['input-grp__field'];

            if (meta.error) {
                inputCssClasses.push('is-invalid');
            } else {
                inputCssClasses.push('is-valid');
            }

            if (meta.touched) {

                inputCssClasses.push('is-dirty');
            } else {
                inputCssClasses.push('is-pristine');
            }

            return (
                <React.Fragment>
                    <Field
                        name={name}
                        disabled={disabled}
                        component="select"
                        {...fieldProps}
                    >
                        {
                            placeholder ?
                                <option value="">
                                    {placeholder}
                                </option>
                                : null
                        }
                        {
                            renderOptions(options)
                        }
                    </Field>
                    <FieldError meta={meta} />

                    {
                        onChange && (
                            <OnChange name={name}>
                                {val => val && onChange(val)}
                            </OnChange>
                        )
                    }

                </React.Fragment>
            );
        }}

    </Field>
);


export default OptionGroupSelect;
