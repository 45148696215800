import PropTypes from 'prop-types';
import React from 'react';
import UserForm from '../../../Components/Account/UserForm';
import UserManagementBreadcrumb from '../../../Components/Account/UserManagementBreadcrumb';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const EditUserPage = ({ history, match }) => (
    <PrivatePage requiredUserPermission={Permission.UpdateUsers}>
        <Container>
            <TopNav />
            <UserManagementBreadcrumb userId={match.params.id} editUser />
            <PageTitle title="Nutzer bearbeiten" />
            <UserForm
                history={history}
                edit={match.params.id}
            />
        </Container>
    </PrivatePage>
);

export default EditUserPage;

EditUserPage.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
};

EditUserPage.defaultProps = {
    match: {},
    history: {}
};
