import React, { Component } from 'react';
import { Field } from 'react-final-form';
import InvitationModal from './InvitationModal';

class SiteInviteesInput extends Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(inviteesList) {
        this.props.input.onChange(inviteesList);
    }

    render() {
        const { input } = this.props;
        const { currentUserEmail } = this.props;
        return (
            <React.Fragment>
                <InvitationModal
                    {...input}
                    currentUserEmail={currentUserEmail}
                    onChange={this.handleChange}
                />
            </React.Fragment>
        );
    }
}

const SiteInviteesModal = ({ ...props }) => (
    <Field
        {...props}
        component={SiteInviteesInput}
    />
);

export default SiteInviteesModal;
