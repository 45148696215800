import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { ModelType } from '../../../../properties';
import { showApiError } from '../../../../redux/modules/error/action';
import { update, upload } from '../../../../redux/modules/media/action';
import { BadRequestError, ServerError } from '../../../../Services/ApiError';
import { getErrorId, isValidationError } from '../../../../Services/ErrorService';
import Modal from '../../../Common/Modal/Modal';
import ModalFormFooter from '../../../Common/ModalFormFooter';
import { ConstructionPlanModelMode } from '../../../ConstructionPlan/properties';
import {
    ConstructionPlanMediaFormDataPropType,
    MediaFormDataPropType,
    MediaVisibility
} from '../../../Media/properties';
import LoadingSpinner from '../../../Page/LoadingSpinner';
import FormError from '../../FormError';
import Input from '../../Input';
import InputGroup from '../../InputGroup';
import InputHint from '../../InputHint';
import MediaDropzoneField from '../Dropzone/MediaDropzoneField';
import {
    getMediaUploadFieldName,
    getMediaUploadPath,
    getModelMediaTypeLabel,
    getModelMediaTypes,
    getModelMediaTypeVisibilities,
    getRestrictedVisibilityOptionsHint,
    getVisibilityLabel,
    isMultiUploadAllowed,
    toMediaFormData
} from '../utils';
import MediaReferenceSelectField from './MediaReferenceSelectField';
import MediaVisibilitySelectField from './MediaVisibilitySelectField';
import ModelMediaTypeSelectField from './ModelMediaTypeSelectField';

const focusOnError = createDecorator();

class MediaFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            isUploading: false,
            edit: !!this.props.media,
            fileUploadProgressList: {},
            initialFormValues: {
                name: '',
                files: [],
                modelReference: undefined,
                modelType: undefined,
                modelMediaType: undefined,
                visibility: MediaVisibility.Public
            }
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.getSubmitLabel = this.getSubmitLabel.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.validate = this.validate.bind(this);
    }

    static getModelMediaTypes(modelType = null, modelMediaTypes = undefined) {
        if (!modelType) {
            return [];
        }

        const availableModelMediaTypes = getModelMediaTypes(modelType);
        if (modelMediaTypes && modelMediaTypes.length) {
            return _.intersection(availableModelMediaTypes, modelMediaTypes);
        }

        return availableModelMediaTypes;
    }

    static getModelReferenceOptions(props) {
        const { modelReferenceOptions } = props;
        if (!modelReferenceOptions || modelReferenceOptions.length === 0) {
            return [];
        }

        return modelReferenceOptions;
    }

    static getInitialModelType(props) {

        const { media, modelType } = props;

        if (media) {
            return media.modelType;
        }

        if (modelType) {
            return modelType;
        }

        return null;
    }

    static getInitialState(props) {
        const { media, isConstructionPlan } = props;

        // Case 1: edit
        if (media) {
            return {
                initialFormValues: {
                    name: media.name,
                    visibility: media.visibility,
                    modelReference: undefined,
                    modelType: media.modelType,
                    modelMediaType: media.modelMediaType,
                    files: [
                        {
                            id: media.id,
                            name: media.fileName,
                            size: media.size,
                            mimeType: media.mimeType
                        }
                    ]
                }
            };
        }

        // Case 2: add new media
        const initialModelType = MediaFormModal.getInitialModelType(props);
        const initialModelMediaTypes = MediaFormModal.getModelMediaTypes(initialModelType, props.modelMediaTypes);
        const initialModelMediaType = initialModelMediaTypes && initialModelMediaTypes.length > 0 ? initialModelMediaTypes[0] : null;
        const initialVisibility = (isConstructionPlan || !initialModelMediaType) ? null : MediaFormModal.getModelMediaTypeVisibilityOptions(initialModelMediaType)[0].value;

        return {
            initialFormValues: {
                name: '',
                files: [],
                modelReference: null,
                modelType: initialModelType,
                modelMediaType: initialModelMediaType,
                visibility: initialVisibility
            }
        };
    }

    static getModelMediaTypeVisibilityOptions(modelMediaType) {
        const visibilities = getModelMediaTypeVisibilities(modelMediaType);

        return visibilities.map(visibility => ({
            label: getVisibilityLabel(visibility),
            value: visibility
        }));
    }

    static isMultiUpload(values) {
        const { files } = values;
        return files && files.length > 1;
    }

    static isMultiUploadAllowed(values) {
        return isMultiUploadAllowed(values.modelMediaType);
    }

    static handleValidationError(validationError) {
        if (!validationError || !validationError.field) {
            return;
        }

        // We need to map the upload field name back to 'files'
        let field = validationError.field;
        if (['attachment', 'utilization-plan', 'proofOfStability', 'anchorProtocol', 'media', 'document', 'file'].indexOf(field) >= 0) {
            field = 'files';
        }

        const errorCode = validationError.error;
        let error = 'Fehlerhafte Eingabe';
        if (errorCode === 'is_valid_file_type') {
            error = 'Dateityp wird zur Zeit nicht unterstützt';
        } else if (errorCode === 'max') {
            error = 'Datei ist zu groß';
        } else if (field === 'visibility' && errorCode === 'is_valid_enum') {
            error = 'Die gewählte Sichtbarkeit wird nicht unterstützt. Bitte wählen Sie eine andere';
        } else {
            error = `${error} (${errorCode})`;
        }

        return {
            [field]: error
        };
    }

    static handleUnknownError() {
        let errorMessage = 'Beim Hochladen ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.';

        return {
            [FORM_ERROR]: errorMessage
        };
    }

    static handleValidationErrors(validationErrors) {
        if (!validationErrors) {
            MediaFormModal.handleUnknownError();
        }

        const firstValidationError = validationErrors[0];
        return MediaFormModal.handleValidationError(firstValidationError);
    }

    static handleServerError() {
        return {
            [FORM_ERROR]: 'Der Dateiupload ist fehlgeschlagen. Der Grund dafür könnte eine technische Störung oder das Überschreiten der maximalen Dateigröße für Uploads (maximal 100 MB) sein.'
        };
    }

    static handleBadRequestError(errorData) {
        const { error } = errorData;
        if (error === 'invalid_media_visibility') {
            return {
                visibility: 'Die gewählte Sichtbarkeit ist nicht gültig. Bitte nutzen Sie ein andere.'
            };
        }

        if (error === 'upload_incomplete') {
            return {
                [FORM_ERROR]: 'Die Dateien konnten nicht vollständig an den Server übermittelt werden. Bitte versuchen Sie es erneut.'
            };
        }

        return MediaFormModal.handleUnknownError();
    }

    static onFilesChange(files, mutators, values) {
        if (files.length > 1) {
            mutators.setName('');
        } else if (!values.name) {
            mutators.setName(files[0].name);
        }
    }

    componentWillMount() {
        this.setState({
            ...MediaFormModal.getInitialState(this.props)
        });
    }

    getModelMediaTypeOptions(values) {
        const { files, modelType } = values;

        const modelMediaTypes = MediaFormModal.getModelMediaTypes(modelType || this.props.modelType, this.props.modelMediaTypes);
        if (!modelMediaTypes) {
            return [];
        }

        const isMultiUpload = files && files.length > 1;

        return modelMediaTypes.map(modelMediaType => ({
            label: getModelMediaTypeLabel(modelMediaType),
            value: modelMediaType,
            disabled: isMultiUpload && !isMultiUploadAllowed(modelMediaType)
        }));
    }

    validate(values) {
        const errors = {};

        const {
            edit
        } = this.state;

        const {
            isConstructionPlan
        } = this.props;

        const name = values.name || '';

        if (!MediaFormModal.isMultiUpload(values) && !name.trim()) {
            errors.name = 'Bitte geben Sie einen Dateinamen an';
        }

        if (!isConstructionPlan && !values.visibility) {
            errors.visibility = 'Bitte wählen Sie die gewünschte Sichtbarkeit aus';
        }

        if (!edit) {
            if (!values.files || !values.files.length) {
                errors.files = 'Bitte laden Sie eine Datei hoch';
            }
        }

        const hasReferences = !!this.props.modelReferenceOptions;
        if (hasReferences) {
            if (!values.modelReference) {
                errors.modelReference = 'Bitte wählen sie eine Zuweisung';
            }
        }

        if (!values.modelMediaType) {
            errors.modelMediaType = 'Bitte wählen sie einen Typ';
        }

        return errors;
    }

    closeModal() {
        this.setState({
            isOpen: false
        }, () => {
            const { onClose } = this.props;
            if (typeof onClose === 'function') {
                onClose();
            }
        });
    }

    /**
     * Save edited existing media
     */
    saveMedia(values) {

        const { onSaved, isConstructionPlan, media } = this.props;

        const { name, visibility } = values;
        const { id, mode } = media;

        /*
         * If this is a construction plan media modal we
         * need to check if this is our own media (mode = 'new')
         * or if we inherited the media from a model referenced
         * by the construction plan. Only in case mode = 'new'
         * we save the update, otherwise we just return the changed
         * name
         */
        if (isConstructionPlan && mode !== ConstructionPlanModelMode.New) {
            if (typeof onSaved === 'function') {
                const savedMedia = {
                    ...media,
                    mode: ConstructionPlanModelMode.Edit,
                    name: values.name
                };
                const returnValue = toMediaFormData(savedMedia, media.modelType, media.modelMediaType);
                onSaved(returnValue);
            }
            return;
        }

        this.setState({
            isSaving: true
        });

        const updatePayload = {
            visibility,
            name
        };

        return this.props.actions.update(updatePayload, id, this.props.label)
            .then((updatedFile) => {
                this.setState({
                    isSaving: false
                }, () => {
                    this.closeModal();

                    if (typeof onSaved === 'function') {
                        const updateMedia = toMediaFormData(updatedFile, media.modelType, media.modelMediaType, media.mode);
                        onSaved(updateMedia);
                    }
                });
            })
            .catch(() => {
                this.setState({
                    isSaving: false
                });
            });
    }


    onProgress = (fileId) => progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        const { fileUploadProgressList } = this.state;

        this.setState({
            fileUploadProgressList: {
                ...fileUploadProgressList,
                [fileId]: percentCompleted
            }
        });
    };

    onUploadFailure = (fileId) => {

        const { fileUploadProgressList } = this.state;

        this.setState({
            fileUploadProgressList: {
                ...fileUploadProgressList,
                [fileId]: false
            }
        });
    };


    /**
     * Upload new media
     */
    uploadMedia(values) {
        const { isConstructionPlan } = this.props;
        const { modelReference, modelType, modelMediaType, visibility, files } = values;
        let { name } = values;

        this.setState({
            isUploading: true
        }, () => {
            const { onUploading } = this.props;
            if (typeof onUploading === 'function') {
                onUploading(true);
            }
        });

        const multiple = files.length > 1;

        const uploadPath = getMediaUploadPath(modelMediaType, isConstructionPlan);
        const uploadFieldName = getMediaUploadFieldName(modelMediaType, isConstructionPlan);

        return Promise.all(files.map((file) => {

            if (multiple) {
                name = file.name;
            }

            const formData = new FormData();
            if (!isConstructionPlan) {
                formData.append('visibility', visibility);
            }
            formData.append('name', name);
            formData.append(uploadFieldName, file.original);

            return this.props.actions.upload(formData, uploadPath, this.props.label, false, this.onProgress(file.id))
                .catch(error => {
                    this.onUploadFailure(file.id);
                    return Promise.reject(error);
                });
        }))
            .then((uploadedMedia) => {
                if (uploadedMedia.length > 1) {
                    toast.success('Dateien wurden hochgeladen', { autoClose: 2500 });
                } else {
                    toast.success(`${uploadedMedia[0].name} wurde hochgeladen`, { autoClose: 2500 });
                }

                const { onUploaded } = this.props;
                if (typeof onUploaded === 'function') {
                    const newMedia = uploadedMedia.map(media => toMediaFormData(media, modelType, modelMediaType, ConstructionPlanModelMode.New, modelReference));
                    onUploaded(newMedia);
                }

                this.closeModal();
            })
            .catch((error) => {

                this.setState({
                    isUploading: false
                });

                return this.handleUploadError(error);

            })
            .finally(() => {
                const { onUploading } = this.props;
                if (typeof onUploading === 'function') {
                    onUploading(false);
                }
            });
    }

    onSubmit(values) {
        const {
            edit
        } = this.state;

        if (edit) {
            return this.saveMedia(values);
        }

        return this.uploadMedia(values);
    }

    getSubmitLabel() {
        const { isSaving, isUploading, edit } = this.state;

        if (edit) {
            return isSaving ?
                <LoadingSpinner label="Änderungen werden gespeichert" /> : 'Änderungen speichern';
        }

        const activeUploadText = this.props.multiple ?
            <LoadingSpinner label="Dateien werden hochgeladen" /> :
            <LoadingSpinner label="Datei wird hochgeladen" />;

        return isUploading ? activeUploadText : 'Hochladen';
    }

    renderModelMediaTypeSelect(values) {
        const options = this.getModelMediaTypeOptions(values);
        if (!options) {
            return null;
        }

        if (options.length === 1) {
            const option = options[0];
            return (
                <Fragment>
                    {option.label}
                    <input type="hidden" name="modelMediaType" value={option.value} />
                </Fragment>
            );
        }

        const { edit } = this.state;
        if (edit) {
            const mediaType = values.modelMediaType;
            return (
                <Fragment>
                    {getModelMediaTypeLabel(mediaType)}
                    <input type="hidden" name="modelMediaType" value={mediaType} />
                </Fragment>
            );
        }

        return (
            <ModelMediaTypeSelectField
                name="modelMediaType"
                options={options}
                readOnly={edit}
            />
        );
    }

    renderModelReferenceSelect(values, mutators) {

        const options = MediaFormModal.getModelReferenceOptions(this.props);
        if (!options || options.length === 0) {
            return null;
        }

        return (
            <MediaReferenceSelectField
                name="modelReference"
                options={options}
                onChange={(chosenReference) => {
                    const newModelType = chosenReference ? chosenReference.type : null;
                    mutators.setModelType(newModelType);
                    const availableModelMediaTypes = getModelMediaTypes(newModelType);
                    const newModelMediaType = availableModelMediaTypes ? availableModelMediaTypes[0] : undefined;
                    mutators.setModelMediaType(newModelMediaType);
                }}
            />
        );
    }

    handleUploadError(error) {
        if (error instanceof BadRequestError) {
            const { response } = error;
            const errorId = getErrorId(response);
            if (isValidationError(errorId)) {
                return MediaFormModal.handleValidationErrors(response.payload);
            } else {
                return MediaFormModal.handleBadRequestError(response);
            }
        } else if (error instanceof ServerError) {
            return MediaFormModal.handleServerError();
        } else {
            return showApiError(error);
        }
    }

    // Will be set in the render method
    handleSubmit = () => false;

    render() {

        const { isUploading, edit, initialFormValues, isOpen } = this.state;

        const { title, allowedMimes, isConstructionPlan, isCorrespondence } = this.props;

        const modalTitle = title(edit);

        if (!isOpen) {
            return null;
        }

        const hasReferences = !!this.props.modelReferenceOptions;

        return (
            <Modal
                title={modalTitle}
                onBackdropClick={this.closeModal}
                type="upload"
                footer={
                    <ModalFormFooter
                        onClose={this.closeModal}
                        onSubmit={(e) => this.handleSubmit(e)}
                        submitLabel={this.getSubmitLabel()}
                        isSubmitting={isUploading}
                    />
                }
            >
                <Form
                    onSubmit={this.onSubmit}
                    decorators={[focusOnError]}
                    validate={this.validate}
                    initialValues={initialFormValues}
                    mutators={{
                        setName: ([newName], state, utils) => {
                            utils.changeValue(state, 'name', () => newName);
                        },
                        setFiles: ([newFiles], state, utils) => {
                            utils.changeValue(state, 'files', () => newFiles);
                        },
                        setModelType: ([newModelType], state, utils) => {
                            utils.changeValue(state, 'modelType', () => newModelType);
                        },
                        setModelMediaType: ([newModelMediaType], state, utils) => {
                            utils.changeValue(state, 'modelMediaType', () => newModelMediaType);
                        },
                        setVisibility: ([newVisibility], state, utils) => {
                            utils.changeValue(state, 'visibility', () => newVisibility);
                        }
                    }}
                    render={({ handleSubmit, values, form: { mutators }, submitError }) => {
                        const isMultiUpload = MediaFormModal.isMultiUpload(values);
                        const isMultiUploadAllowed = MediaFormModal.isMultiUploadAllowed(values);
                        const visibilityHint = getRestrictedVisibilityOptionsHint(values);
                        this.handleSubmit = handleSubmit;
                        return (
                            <form onSubmit={handleSubmit}>

                                <FormError message={submitError} />

                                {/* Name */}
                                <InputGroup
                                    label="Name*"
                                    containerClass={isMultiUpload ? 'input-grp--disabled' : ''}
                                >
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Name der Datei"
                                        disabled={isMultiUpload}
                                    />
                                    {isMultiUpload &&
                                    <InputHint showIcon>
                                        Beim gleichzeitigen Hochladen mehrerer Dateien kann kein
                                        zusätzlicher Dateiname vergeben werden.
                                        Dateinamen können bei Bedarf später über
                                        die &quot;Bearbeiten&quot; Funktion der Dateien
                                        individuell hinzugefügt werden.
                                    </InputHint>
                                    }
                                </InputGroup>

                                {/* Files */}
                                <InputGroup
                                    label={`${isMultiUpload ? 'Dateien' : 'Datei'}`}
                                    required={!edit}
                                >
                                    <MediaDropzoneField
                                        name="files"
                                        fileUploadProgressList={this.state.fileUploadProgressList}
                                        multiple={isMultiUploadAllowed}
                                        allowedMimes={allowedMimes}
                                        onChange={files => MediaFormModal.onFilesChange(files, mutators, values)}
                                        disabled={edit}
                                    />
                                </InputGroup>

                                {/* Reference */}
                                {hasReferences &&
                                <InputGroup
                                    label="Zuweisung"
                                    required={hasReferences}
                                >
                                    {this.renderModelReferenceSelect(values, mutators)}
                                    {isConstructionPlan &&
                                    <InputHint>
                                        Zuweisungen nur für angepasste Konfigurationen oder neue
                                        Planungen möglich
                                    </InputHint>
                                    }
                                </InputGroup>
                                }

                                {/* Media type */}
                                <InputGroup
                                    label="Typ"
                                    required={hasReferences}
                                >
                                    {this.renderModelMediaTypeSelect(values)}
                                </InputGroup>

                                {/* Visibility */}
                                <InputGroup
                                    label="Sichtbarkeit"
                                    required={!isConstructionPlan}
                                >
                                    {!isConstructionPlan &&
                                    <MediaVisibilitySelectField
                                        name="visibility"
                                        options={MediaFormModal.getModelMediaTypeVisibilityOptions(values.modelMediaType)}
                                    />
                                    }

                                    {/* When the model media type changes, reset it to 'public' */}
                                    <OnChange name="modelMediaType">
                                        {() => {
                                            mutators.setVisibility('public');
                                        }}
                                    </OnChange>

                                    {!isConstructionPlan && isMultiUpload && !isCorrespondence &&
                                    <InputHint showIcon>
                                        Die hier eingestellte Sichtbarkeit gilt für alle oben
                                        aufgeführten, gleichzeitig hochgeladenen Dateien. Die
                                        Sichtbarkeit einzelner Dateien kann bei Bedarf später
                                        über die &quot;Bearbeiten&quot; - Funktion der Dateien
                                        individuell angepasst werden.
                                    </InputHint>
                                    }

                                    {!isConstructionPlan && visibilityHint &&
                                    <InputHint showIcon>
                                        {visibilityHint}
                                    </InputHint>
                                    }

                                    {isConstructionPlan &&
                                    <InputHint showIcon>
                                        Alle hier hochgeladenen Dokumente werden unternehmensintern
                                        gespeichert. Beim Anwenden des Bauplans haben Sie die
                                        Möglichkeit eine andere Sichtbarkeit zu wählen.
                                    </InputHint>
                                    }
                                </InputGroup>
                            </form>
                        );
                    }}
                />
            </Modal>
        );
    }
}

MediaFormModal.propTypes = {
    media: PropTypes.oneOfType([
        MediaFormDataPropType,
        ConstructionPlanMediaFormDataPropType
    ]),
    modelType: PropTypes.oneOf([
        ModelType.Job,
        ModelType.Site,
        ModelType.Scaffolding,
        ModelType.Section,
        ModelType.ConstructionDiaryEntry
    ]),
    modelMediaTypes: PropTypes.array,
    modelReferenceOptions: PropTypes.array,
    title: PropTypes.func,
    onUploading: PropTypes.func,
    onUploaded: PropTypes.func,
    onSaved: PropTypes.func,
    onClose: PropTypes.func,
    isConstructionPlan: PropTypes.bool,
    isCorrespondence: PropTypes.bool
};

MediaFormModal.defaultProps = {
    media: undefined,
    modelType: undefined,
    modelMediaTypes: undefined,
    modelReferenceOptions: undefined,
    title: (edit) => {
        return edit ? 'Dokument bearbeiten' : 'Dokumente hochladen';
    },
    onUploading: undefined,
    onUploaded: undefined,
    onSaved: undefined,
    onClose: undefined,
    isConstructionPlan: false,
    isCorrespondence: false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        upload,
        update
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(MediaFormModal);
