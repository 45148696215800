import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Breadcrumb from '../../../../Components/Navigation/Breadcrumbs/Breadcrumb';
import {BreadcrumbLink} from '../../../../Components/Navigation/Breadcrumbs/BreadcrumbLink';

const JobsBreadcrumb = ({job, collapse, isRequest, showJob}) => {
    const query = job && job.archived ? '?archived=true' : '';

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label={isRequest ? 'Anfragen' : 'Aufträge'}
                    path={isRequest ? '/requests' : '/jobs'}
                    icon="jobs"
                    query={query}
                />
                {(showJob || job) &&
                <BreadcrumbLink
                    label={job ? job.name : `Lade ${isRequest ? 'Anfrage' : 'Auftrag'} ...`}
                    path={job ? isRequest ? `/requests/${job.id}` : `/jobs/${job.id}` : null}
                />
                }
            </Fragment>
        </Breadcrumb>
    )
};

JobsBreadcrumb.propTypes = {
    job: PropTypes.object,
    isRequest: PropTypes.bool,
    collapse: PropTypes.bool,
    showJob: PropTypes.bool,
};

JobsBreadcrumb.defaultProps = {
    job: null,
    isRequest: true,
    collapse: false,
    showJob: false,
};

export default JobsBreadcrumb;
