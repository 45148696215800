import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import DateService from '../../../../Services/DateService';
import CollapseToggleLink from '../../../Common/Collapse/CollapseToggleLink';
import Icon from '../../../Common/Icon';
import { MediaPropType } from '../../../Media/properties';
import JobCorrespondenceTimelineItem from './JobCorrespondenceTimelineItem';

const MAX_ITEMS_TO_SHOW = 2;

export default function JobCorrespondenceTimelineCluster({ data, onTimelineItemEdit, onTimelineItemDelete, readOnly }) {

    const [collapsed, toggleCollapsed] = useState(true);

    const { created, items } = data;
    const itemsCount = items.length;
    const correspondenceLabel = itemsCount > 1 ? 'Dateien' : 'Datei';

    const headline = <Fragment>
        <span className="job-correspondence-type-lbl"
        >{itemsCount} {correspondenceLabel}</span> am {DateService.getReadableDateTime(created)} hochgeladen
    </Fragment>;

    const showCount = collapsed ? MAX_ITEMS_TO_SHOW : -1;

    return (
        <div className="job-correspondence-timeline__cluster">
            <div className="job-correspondence-timeline__cluster-icon">
                <Icon name="document" />
            </div>
            <div className="job-correspondence-timeline__cluster-hd">
                {headline}
            </div>
            <div className="job-correspondence-timeline__cluster-items">
                {data.items.map((item, index) => {

                    if (typeof showCount === 'number' && showCount > 0 && index >= showCount) {
                        return null;
                    }

                    return (
                        <JobCorrespondenceTimelineItem
                            data={item}
                            key={item.id}
                            onEdit={onTimelineItemEdit}
                            onDelete={onTimelineItemDelete}
                            readOnly={readOnly}
                        />
                    );
                })}
            </div>
            {itemsCount > MAX_ITEMS_TO_SHOW &&
            <div className="job-correspondence-timeline-cluster__collapse-toggle">
                <CollapseToggleLink
                    onToggle={() => toggleCollapsed(!collapsed)}
                    isCollapsed={collapsed}
                    hideIcon
                    expandLabel={`+ ${itemsCount - MAX_ITEMS_TO_SHOW} weitere`}
                    collapseLabel="weniger anzeigen"
                />
            </div>
            }
        </div>
    );
}

JobCorrespondenceTimelineCluster.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(MediaPropType).isRequired
    }).isRequired,
    onTimelineItemEdit: PropTypes.func,
    onTimelineItemDelete: PropTypes.func,
    readOnly: PropTypes.bool,
};
