import { ModelMediaType } from '../../../Media/properties';
import { ConstructionPlanModelMode } from '../../properties';
import { extractFormModelById, getBasicInitialFormModelValues, getInitialFormField } from '../form';
import {
    getInitialMediaFormField,
    transformMediaToFormValues,
    transformMediaToSyncMedia
} from '../media';
import { isIdenticalSiteFormValue } from './equals';

const DefaultSiteDataFormValues = {
    name: null,
    description: null,
    address: null,
    attachments: []
};

export const getInitialSiteFormValues = (model, modelReferenceData, resolvedMedia) => {
    const siteValues = getBasicInitialFormModelValues(model);

    const ignoredProperties = [
        'archived'
    ];

    if (model.mode !== ConstructionPlanModelMode.Reference) {
        Object.keys(model.data)
            .filter(property => ignoredProperties.indexOf(property) < 0)
            .forEach(property => {
                const value = getInitialSiteFormValue(property, model, modelReferenceData, resolvedMedia);
                siteValues.data = {
                    ...siteValues.data,
                    ...value
                };
            });
    }

    return siteValues;
};

const transformSiteToFormValues = (_siteData, _refData, resolvedMedia) => {

    const siteData = _siteData || {};
    const refData = _refData || {};

    const newFormValues = { ...DefaultSiteDataFormValues };

    const ignoredProperties = [
        'id',
        'archived',
        'scaffoldings'
    ];

    const customProperties = [
        'attachments'
    ];

    // Copy properties

    Object
        .keys(siteData)
        .filter(property => ignoredProperties.indexOf(property) < 0)
        .filter(property => customProperties.indexOf(property) < 0)
        .forEach(property => {
            newFormValues[property] = siteData[property];
        });

    /*
     * Custom properties
     */

    newFormValues.attachments = transformMediaToFormValues(
        siteData.attachments,
        refData.attachments,
        ModelMediaType.Site_Attachment,
        resolvedMedia
    );

    return newFormValues;
};

const getInitialSiteFormValue = (property, model, modelReferenceData, resolvedMedia) => {
    switch (property) {
        case 'attachments':
            return getInitialMediaFormField(property, model, modelReferenceData, ModelMediaType.Site_Attachment, resolvedMedia);
        default:
            return getInitialFormField(property, model, modelReferenceData, isIdenticalSiteFormValue);
    }
};

export const transformSitePlanToFormValues = (planData, liveData, resolvedMedia) => {
    return transformSiteToFormValues(planData, liveData, resolvedMedia);
};

export const transformLiveSiteToFormValues = (liveData, planData, resolvedMedia) => {
    return transformSiteToFormValues(liveData, planData, resolvedMedia);
};

/*
 * Sync data
 */

export const getSiteSyncData = (values, constructionPlanSite) => {

    const siteModel = extractFormModelById(values, constructionPlanSite.id);
    const siteValues = siteModel.data;
    const { attachments } = siteValues;

    const site = {
        id: constructionPlanSite.id,
        mode: constructionPlanSite.mode,
        data: {},
        scaffoldings: {}
    };

    const mode = constructionPlanSite.mode;

    if (mode === ConstructionPlanModelMode.Reference) {
        return site;
    }

    site.ignored = siteModel.ignored;

    site.data = {
        name: siteValues.name,
        description: siteValues.description,
        address: siteValues.address,
        attachments: transformMediaToSyncMedia(attachments)
    };

    return site;
};
