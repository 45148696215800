import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../../redux/modules/user/action';
import PrivatePage from './PrivatePage';
import Container from '../../Container';

class LogoutPage extends Component {
    componentDidMount() {
        this.props.actions.logoutUser();
    }
    render() {

        return (
            <PrivatePage>
                <Container>
                    <h4 className="loading-text">
                    Sie werden abgemeldet ...
                    </h4>
                </Container>
            </PrivatePage>
        );
    }
}

const mapStateToProps = state => ({
    isLoginFetching: state.currentUser.isLoading,
    error: state.currentUser.error
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ logoutUser }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);

LogoutPage.propTypes = {
    isLoginFetching: PropTypes.bool,
    error: PropTypes.string,
    actions: PropTypes.object
};

LogoutPage.defaultProps = {
    isLoginFetching: false,
    error: '',
    actions: {
        logoutUser: () => false
    }
};

