import PropTypes from 'prop-types';
import React from 'react';
import SubscriptionDetailsBreadcrumb
    from '../../../Components/Account/Subscription/SubscriptionDetailBreadcrumb';
import SubscriptionDetails from '../../../Components/Account/Subscription/SubscriptionDetails';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const SubscriptionDetailPage = ({ history }) => (
    <PrivatePage requiredUserPermission={Permission.ReadSections}>
        <Container>
            <TopNav />
            <SubscriptionDetailsBreadcrumb />
            <PageTitle
                title="Abonnement"
            />
            <SubscriptionDetails
                history={history}
            />
        </Container>
    </PrivatePage>
);

export default (SubscriptionDetailPage);

SubscriptionDetailPage.propTypes = {
    history: PropTypes.object
};

SubscriptionDetailPage.defaultProps = {
    history: {}
};
