import React from 'react';
import JobsOverview from '../../../../Components/Job/Overview/JobsOverview';
import TopNav from '../../../../Components/Page/TopNav';
import Permission from '../../../../permissions';
import Container from '../../../Container';
import PrivatePage from '../PrivatePage';

const JobsPage = ({ location, history }) => {

    if (location.search && (!location.state || !location.state.jobQueryString)) {

        history.replace({
            ...location,
            state: {
                ...location.state,
                jobQueryString: location.search
            }
        });
    }

    return(
        <PrivatePage requiredUserPermission={Permission.ReadJobs}>
            <Container>
                <TopNav />
                <JobsOverview />
            </Container>
        </PrivatePage>
    );
}

export default JobsPage;
