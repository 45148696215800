import React, { Component } from 'react';
import SortLabel from './SortLabel';

class CompanyDocumentsTableHeader extends Component {

    constructor(props) {
        super(props);
        this.sortData = this.sortData.bind(this);
        this.getClasses = this.getClasses.bind(this);
    }

    getClasses(fieldValue) {
        const selectedClass = this.props.sortOptions.field === fieldValue ? 'selected' : '';
        return `${selectedClass} sort__link`;
    }

    getInvertedDirection() {
        return this.props.sortOptions.direction === '-' ? '+' : '-';
    }

    sortData(field) {
        let direction = '+';
        if (this.props.sortOptions.field === field) {
            direction = this.getInvertedDirection();
        }
        this.props.onChange({
            field,
            direction
        });
    }

    render() {
        const { field, direction } = this.props.sortOptions;
        return (
            <thead>
            <tr>
                <th style={{ width: '17%' }}>
                    <SortLabel
                        field="name"
                        label="Name"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                <th style={{ width: '53%' }}>
                    Notizen
                </th>
                <th style={{ width: '6%' }}>
                    Typ
                </th>
                <th style={{ width: '8%' }}>
                    Größe
                </th>
                <th style={{ width: '6%' }}>
                    Download
                </th>
            </tr>
            </thead>
        );
    }
}

export default CompanyDocumentsTableHeader;


