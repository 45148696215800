import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteCustomerContact } from '../../../../redux/modules/customer/action';
import DeleteResourceLink from '../../../Common/Modal/DeleteResourceLink';
import EditResourceLink from '../../../Common/EditResourceLink';
import Icon from '../../../Common/Icon';
import MailToLink from '../../../Common/MailToLink';
import PrimaryColumn from '../../../Common/PrimaryColumn';
import CustomerContactFormModal, { MODAL_CONTEXT_STANDALONE } from '../../Form/CustomerContactFormModal';

const renderContactName = (name, isMainContact) => {

    if (!isMainContact) {
        return name;
    }

    return <div>
        {name}
        <div className="main-contact-indicator">
            <span className="icon"><Icon name="star" /></span>
            <small>Hauptkontakt</small></div>
    </div>;
};

const CustomerContactPersonTableRow = ({ data: contactPerson, onChange, isSingleRow, actions: { deleteCustomerContact } }) => {


    const {
        id,
        firstName,
        lastName,
        position,
        isMainContact,
        email,
        phone,
        mobile
    } = contactPerson;

    const name = `${firstName} ${lastName}`;

    return (
        <Fragment>
            <tr key={id}>
                <PrimaryColumn
                    link={renderContactName(name, isMainContact)}
                >
                    <EditResourceLink
                        resourceId={id}
                        editModalComponent={CustomerContactFormModal}
                        modalExtraProps={{ modalContext: MODAL_CONTEXT_STANDALONE }}
                        onSuccessfulEdit={onChange}
                    />

                    {!isSingleRow && !isMainContact &&
                    <DeleteResourceLink
                        deleteFnc={() => deleteCustomerContact(id)}
                        resourceTypeLabel="Ansprechpartner"
                        resourceName={name}
                        onSuccessfulDeletion={onChange}
                    />
                    }
                </PrimaryColumn>
                <td>
                    {position || 'n.a.'}
                </td>
                <td>
                    {phone || 'n.a.'}
                </td>
                <td>
                    {mobile || 'n.a.'}
                </td>
                <td>
                    {email ?
                        <MailToLink email={email}
                        >{email}</MailToLink>
                        :
                        'n.a'
                    }
                </td>

            </tr>
        </Fragment>
    );
};

CustomerContactPersonTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isSingleRow: PropTypes.bool.isRequired
};

CustomerContactPersonTableRow.defaultProps = {};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        deleteCustomerContact
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(CustomerContactPersonTableRow);
