async function getResponseJson(response) {
    try {
        return await response.json();
    } catch (e) {
        return null;
    }
}

export class ApiError {

    constructor(error, response) {
        this.error = error;
        this.response = response;
        this.data = undefined;
    }

    async getData() {

        if (typeof this.data === 'undefined') {
            this.data = await getResponseJson(this.response);
        }

        return this.data;
    }
}

export class TooManyLoginAttemptsError extends ApiError {
    constructor(response) {
        super('TooManyLoginAttempts', response);
    }
}

export class BadRequestError extends ApiError {
    constructor(response) {
        super('BadRequest', response);
    }
}

export class ClientOutdatedError extends ApiError {
    constructor(response) {
        super('client_outdated', response);
    }
}

export class ForbiddenError extends ApiError {
    constructor(response) {
        super('Forbidden', response);
    }
}

export class MissingPermissionError extends ApiError {
    constructor(response) {
        super('MissingPermission', response);
    }
}

export class ConflictError extends ApiError {
    constructor(response) {
        super('Conflict', response);
    }
}

export class RessourceNotFoundError extends ApiError {
    constructor(response) {
        super('RessourceNotFound', response);
    }
}

export class LockedByException extends ApiError {
    constructor(response) {
        super('LockedByException', response);
    }
}

export class TooManyRequestsError extends ApiError {
    constructor(response) {
        super('TooManyRequests', response);
    }
}

export class RequestTimeoutError extends ApiError {
    constructor() {
        super('RequestTimeout', null);
    }
}

export class ServerError extends ApiError {
    constructor(response) {
        super('ServerError', response);
    }
}

export class MaintenanceModeError extends ApiError {
    constructor(response) {
        super('MaintenanceMode', response);
    }
}

export class UnauthorizedError extends ApiError {
    constructor(response) {
        super('Unauthorized', response);
    }
}


export class UnprocessableEntity extends ApiError {
    constructor(response) {
        super('UnprocessableEntity', response);
    }
}

export class AccountSubscriptionExpiredError extends ApiError {
    constructor(response) {
        super('account_subscription_expired', response);
    }
}

export class UserSubscriptionExpiredError extends ApiError {
    constructor(response) {
        super('user_subscription_expired', response);
    }
}

export class EntityTooLargeError extends ApiError {
    constructor(response) {
        super('entity_too_large', response);
    }
}

export class UserInactiveError extends ApiError {
    constructor(response){
        super('user_inactive', response);
    }
}
