import PropTypes from 'prop-types';
import React, {Component} from 'react';
import PowerModalTooltip from 'react-power-tooltip';
import Icon from './Icon';

class ModalTooltip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    showModalTooltip = bool => {
        this.setState({ show: bool })
    };

    render(){

        const {children, icon} = this.props;

        return (
            <span className="tooltip"
                  style={{ position: 'relative', zIndex: 2000, marginLeft: 5 }}
                  onMouseOver={() => this.showModalTooltip(true)}
                  onMouseLeave={() => this.showModalTooltip(false)}
            >
                <span style={{zIndex: '2'}}>
                    <Icon name={icon} />
                </span>
                <PowerModalTooltip
                    backgroundColor="#427EA6"
                    hoverColor="#fff"
                    hoverBackground="#427EA6"
                    textBoxWidth='200px'
                    padding="15px"
                    fontSize="12px"
                    zIndex="2000"
                    animation="fade"
                    position="left bottom"
                    flat
                    color="#fff"
                    show={this.state.show}>
                    <div className="modal-tooltip-content">{children}</div>
                </PowerModalTooltip>
            </span>
        )
    }
}

export default ModalTooltip;

ModalTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    icon: PropTypes.string
};

ModalTooltip.defaultProps = {
    icon: 'info'
};
