import React from 'react';
import { connect } from 'react-redux';
import DataRow from '../Common/DataRow';
import ExternalLink from '../Common/ExternalLink';
import MailToLink from '../Common/MailToLink';
import PhoneToLink from '../Common/PhoneToLink';
import Section from '../Layout/Section';
import HelpOption from './HelpOption';

const faq_link = 'https://www.scaffeye.de/faq/';
const tutorial_link = 'https://www.scaffeye.de/anleitung/';
const webinar_link = 'https://scaffeye.clickmeeting.com/';

const Help = () => {

    return (
        <div>
            <Section
                title="FAQ und Anleitungen"
            >
                <p style={{ marginBottom: 16 }}>Hier finden Sie hilfreiche Informationen und
                    Hilfestellungen zur Nutzung von Scaffeye®.</p>

                <HelpOption
                    icon="question_answer"
                    id="faq"
                    iconLinksTo={faq_link}
                    title={
                        <ExternalLink
                            label="Häufig gestellte Fragen (FAQ)"
                            target={faq_link}
                        />
                    }
                >
                    <p>Hier finden Sie eine Auflistung von häufig gestellten Anfragen und
                        deren Antworten. Sofern Sie dort keine passende Antwort auf
                        Ihre Frage finden, kontaktieren Sie bitte unseren Support.</p>
                </HelpOption>

                <HelpOption
                    icon="knowledge"
                    iconLinksTo={tutorial_link}
                    id="tutorials"
                    title={
                        <ExternalLink
                            label="Anleitungen"
                            target={tutorial_link}
                        />
                    }
                >
                    <p>Hier finden Sie Anleitungen rund um die Nutzung Scaffeye®.</p>
                </HelpOption>

            </Section>


            <Section
                title="Webinare"
            >
                <p style={{ maxWidth: 800 }}>Informieren Sie sich hier über anstehende
                    Webinar-Veranstaltungen oder fragen
                    Sie ihr persönliches Webinar an. Webinare sind öffentliche oder individuell
                    auf Sie abgestimmte Online-Schulungen und Präsentationen, in denen unsere
                    Expertinnen und Experten Ihnen die Funktionen von Scaffeye® näherbringen.
                </p>
                <HelpOption
                    icon="calendar_today"
                    id="webinar"
                    iconLinksTo={webinar_link}
                    title={
                        <ExternalLink
                            label="Anstehende Webinare"
                            target={webinar_link}
                        />
                    }
                >
                    <p>Sehen Sie hier, ob demnächst öffentliche Webinare stattfinden, an denen
                        Sie teilnehmen können.</p>
                </HelpOption>

                <HelpOption>
                    <h4 style={{
                        fontWeight: 600,
                        marginBottom: 16
                    }}
                    >Persönliche Webinare vereinbaren</h4>
                    <p style={{ marginBottom: 16 }}> Kontaktieren Sie uns unverbindlich, um Ihr
                        eigenes Webinar mit Ihrem individuellen Themenschwerpunkt
                        anzufragen.</p>
                    <DataRow
                        label="Telefon"
                    >
                        <PhoneToLink number="491602535711">+49(0) 160 2535711</PhoneToLink>
                        <small style={{ marginLeft: 10 }}><strong>( Mo. - Fr. 9:00 Uhr - 16:00
                            Uhr )</strong>
                        </small>
                    </DataRow>
                    <DataRow
                        label="E-Mail-Adresse"
                    >
                        <MailToLink subject="Support-Anfrage" email="info@scaffeye.de"
                        >info@scaffeye.de </MailToLink>
                    </DataRow>
                </HelpOption>

            </Section>

            <Section
                title="Scaffeye kontaktieren"
                classes="help-contact-section"
            >
                <p style={{
                    marginBottom: 16,
                    maxWidth: 730
                }}
                >Stellen Sie uns eine Frage, schlagen Sie
                    eine
                    Funktion oder Erweiterung vor oder melden Sie uns einen Fehler. Unser
                    Team
                    ist gerne für Sie da.</p>

                <DataRow
                    label="Telefon"
                >
                    <PhoneToLink number="491602535711">+49(0) 160 2535711</PhoneToLink>
                    <small style={{ marginLeft: 10 }}><strong>( Mo. - Fr. 9:00 Uhr - 16:00
                        Uhr )</strong>
                    </small>
                </DataRow>
                <DataRow
                    label="E-Mail-Adresse"
                >
                    <MailToLink subject="Support-Anfrage" email="info@scaffeye.de"
                    >info@scaffeye.de </MailToLink>
                </DataRow>

            </Section>
        </div>
    );
};

export default (Help);

