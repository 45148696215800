import PropTypes from 'prop-types';
import React from 'react';
import Collapse from '../../../Common/Collapse/Collapse';
import ConstructionPlanMediaGroupList from './ConstructionPlanMediaGroupList';

const getModelTypeLabel = (type) => {
    switch (type) {
        case 'site':
            return 'Baustelle';
        case 'scaffolding':
            return 'Gerüst';
        default :
            return 'Gerüstabschnitt';
    }
};

const ConstructionPlanFormMediaGroup = ({ mediaGroupData }) => {

    if (!mediaGroupData.media) {
        return null;
    }

    const groupTitle = (
        <div className="constructionPlannerMedia__item-hd">
            <span
                className="constructionPlannerMedia__item-hd-modelType"
            >
                {getModelTypeLabel(mediaGroupData.type)}
            </span>
            {mediaGroupData.name}
        </div>
    );

    return (
        <div className="constructionPlannerMedia__item">
            <Collapse title={groupTitle}>
                <div className="constructionPlannerMediaGroup">
                    <ConstructionPlanMediaGroupList
                        media={mediaGroupData.media}
                    />
                </div>
            </Collapse>
        </div>
    );
};

ConstructionPlanFormMediaGroup.propTypes = {
    mediaGroupData: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        media: PropTypes.object
    }).isRequired
};

export default ConstructionPlanFormMediaGroup;
