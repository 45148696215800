import { toast } from 'react-toastify';
import callApi, { mutate, query } from '../../action';

export const fetchCustomerDetail = CustomerId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const gqlQuery = `FetchCustomer{customer(id:"${CustomerId}"){
            id,
            name,
            line1,
            line2,
            customerId,
            zip,
            city,
            customerContacts{
                        id,
                        firstName,
                        lastName,
                        position,
                        isMainContact,
                        email,
                        phone,
                        mobile
                    }
            billingAddress{id, name, line1, line2, zip, city},
            jobs{
                id,
                name,
                status,
                type,
                archived,
                created,
                updated,
                customer {
                    id,name
                },
                site {
                    id,name,address{city}
                },
                sections {
                    id,name,scaffolding{id,name}
                }
            }
        }}`;

        return query(gqlQuery, {
            token
        })
            .then(({ customer }) => customer)
            .catch((err) => {
                toast.error('Ein Fehler ist aufgetreten', { autoClose: 2500 });
            });
    }
);

export const fetchCustomerFormData = customerId => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const gqlQuery = `FetchCustomer{customer(id:"${customerId}"){
            id,
            name,
            line1,
            line2,
            customerId,
            zip,
            city,
            mainContact{id},
            billingAddress{id, name, line1, line2, zip, city},
        }}`;

        return query(gqlQuery, {
            token
        })
            .then(({ customer }) => customer)
            .catch((err) => {
                toast.error('Ein Fehler ist aufgetreten', { autoClose: 2500 });
            });
    }
);


export const deleteSingleCustomer = customerId => (

    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        return callApi(`v1/customers/${customerId}`, {
            method: 'DELETE',
            token
        })
            .then(data => data);
    }
);


export const addNewCustomer = (customerDto) => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const { billingAddress, mainContact, ...customerBaseData } = customerDto;

        const queryString = `
                 CreateCustomerInput($customer: CreateCustomerInput!, $billingAddress: CreateAddressInput, $mainContact: CreateCustomerContactInput!){
                    createCustomer(customer: $customer, billingAddress: $billingAddress, mainContact: $mainContact ){
                        id
                    }
                }
            `;

        const params = {
            customer: customerBaseData,
            billingAddress,
            mainContact
        };

        return mutate(queryString, params, {
            token
        })
            .then(({ createCustomer: { id } }) => (id));
    }
);

export const updateCustomer = (customerId, customerDto) => (
    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        const { billingAddress, mainContactId, mainContact, ...customerProps } = customerDto;

        const queryString = `
                UpdateCustomer($id: String!, $customer: UpdateCustomerInput!, $billingAddress: UpdateAddressInput, $mainContactId: String!){
                   updateCustomer(id: $id, customer: $customer, billingAddress: $billingAddress, mainContactId: $mainContactId ){
                       id
                   }
               }
           `;

        const params = {
            id: customerId,
            customer: customerProps,
            billingAddress,
            mainContactId
        };

        return mutate(queryString, params, {
            token
        })
            .then(({ updateCustomer: { id } }) => (id));
    }
);

export function autocompleteCustomer(input) {

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(
            `FetchCompanies{
                customers(
                    name:"${input}",
                    limit:-1,
                    page:1,
                    sort:name,
                    sortAsc:true
                ) {
                    data {
                        name
                    }
                }
            }`, {
                token
            })
            .then(({ customers }) => customers.data);
    };
}

export function autocompleteCustomerMainContact(contact) {

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(
            `FetchCustomers($page:Int,$limit:Int, $contact:String){
                customers(
                    limit: $limit,
                    contact: $contact,
                    page: $page,
                    sort:name,
                    sortAsc:true
                ) {
                    data {
                        mainContact{firstName, lastName}
                    }
                }
            }`, {
                token,
                params: {
                    page: 1,
                    limit: -1,
                    contact: contact
                }
            })
            .then(({ customers }) => {
                if (!customers.data) {
                    return [];
                }
                return customers.data.map(({ mainContact }) => {
                    return {
                        name: `${mainContact.firstName} ${mainContact.lastName}`
                    };
                });
            });
    };
}

export function autocompleteAddress(address) {

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return query(
            `FetchCustomers($page:Int,$limit:Int, $address:String){
                customers(
                    limit: $limit,
                    address: $address,
                    page: $page,
                    sort:name,
                    sortAsc:true
                ) {
                    data {
                         line1,
                         line2,
                         zip,
                          city
                    }
                }
            }`, {
                token,
                params: {
                    page: 1,
                    limit: -1,
                    address: address
                }
            })
            .then(({ customers }) => {
                if (!customers.data) {
                    return [];
                }
                return customers.data.map(({ line1, line2, zip, city }) => {

                    const _line2 = line2 ? `${line2}, ` : '';

                    return {
                        name: `${line1}, ${_line2} ${zip} ${city}`
                    };
                });
            });
    };
}

export function fetchCustomerContactSelectOptions(customerId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchCustomerContactSelectOptions($customerId: String!)
            {
                customer(id: $customerId){
                    customerContacts{
                        id,
                        firstName,
                        lastName,
                        isMainContact,
                        position
                    }
                }
            }`;

        return query(gqlQuery, {
            token,
            params: {
                customerId
            }
        })
            .then(data => data.customer);
    };
}

export function fetchCustomerContactFormData(customerContactId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const gqlQuery = `FetchCustomerContact($id: String!)
            {
                customerContact(id: $id){
                    id,
                    firstName,
                    lastName,
                    isMainContact,
                    email,
                    position,
                    phone,
                    mobile
                }
            }`;

        return query(gqlQuery, {
            token,
            params: {
                id: customerContactId
            }
        })
            .then(({ customerContact }) => customerContact);
    };
}

export function addCustomerContact(customerId, contactPersonDTO) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `
                 CreateCustomerContact($customerId: String!,  $input: CreateCustomerContactInput!)
                 {
                    createCustomerContact(customerId:$customerId, input:$input)
                    {
                         id
                    }
                 }
            `;

        const params = {
            customerId,
            input: contactPersonDTO
        };

        return mutate(queryString, params, {
            token
        })
            .then(({ createCustomerContact: { id } }) => (id));
    };
}

export function updateCustomerContact(contactId, customerDto) {
    return (dispatch, getState) => {

        const token = getState().currentUser.auth_token;

        const queryString = `
                UpdateCustomerContact($id: String!, $input: UpdateCustomerContactInput!){
                   updateCustomerContact(id: $id, input: $input){
                       id,
                       firstName,
                       lastName,
                       position,
                       email,
                       phone,
                       mobile
                       isMainContact
                   }
               }
           `;

        const params = {
            id: contactId,
            input: customerDto
        };

        return mutate(queryString, params, {
            token
        })
            .then(({ updateCustomerContact: { id } }) => (id));
    };
}


export function deleteCustomerContact(contactId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `
                 DeleteCustomerContact($id: String!)
                 {
                    deleteCustomerContact(id:$id)
                 }
            `;

        const params = {
            id: contactId
        };

        return mutate(queryString, params, {
            token
        });
    };
}
