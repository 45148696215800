import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resolveConstructionPlanMedia } from '../../../redux/modules/media/action';
import ContentReveal from '../../Common/ContentReveal';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import Section from '../../Layout/Section';
import LoadingSpinner from '../../Page/LoadingSpinner';
import ConstructionPlanFormMediaGroup from '../Form/Media/ConstructionPlanFormMediaGroup';
import { ConstructionPlanFormSitePropType } from '../Form/propTypes';
import { getGroupedConstructionPlanMedia } from '../utils';

class ConstructionPlanMedia extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            resolvedMedia: null,
            mediaGroups: null
        }
    }

    componentWillMount() {
        this.resolveMediaAndGetGroups();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.site !== prevProps.site) {
            this.resolveMediaAndGetGroups();
        }
    }

    resolveMediaAndGetGroups() {
        const { site, referenceData } = this.props;
        this.setState({
            initialized: false
        });

        this.props.actions.resolveConstructionPlanMedia(site, referenceData)
            .then(resolvedMedia => {
                this.setState({
                    resolvedMedia
                }, () => this.updateMediaGroups());
            });
    }

    updateMediaGroups() {
        const { site, referenceData } = this.props;
        const { resolvedMedia } = this.state;

        this.setState({
            initialized: true,
            mediaGroups: getGroupedConstructionPlanMedia(site, referenceData, resolvedMedia)
        });
    }

    renderMediaGroups() {
        const { mediaGroups } = this.state;

        const mediaListItems = mediaGroups.map((mediaGroup) => {
            return (
                <ConstructionPlanFormMediaGroup
                    key={mediaGroup.id}
                    mediaGroupData={mediaGroup}
                />
            )
        });

        let count = 0;
        mediaGroups.forEach(mediaGroup => {
            Object.keys(mediaGroup.media)
                .forEach(mediaCollectionKey => {
                    const collection = mediaGroup.media[mediaCollectionKey];
                    count += collection.length;
                })
        });

        if(count === 0) {
            return (
                <div className="job-constructionplan-details__media-list">
                    <NotAvailablePlaceholder label="Keine Medien hinterlegt" />
                </div>
            );
        }

        const renderedMedia = (
            <div className="job-constructionplan-details__media-list">
                {mediaListItems}
            </div>
        );

        if(count <= 1) {
            return renderedMedia;
        }

        return (
            <ContentReveal
                showMoreLabel={`${count} Medien anzeigen`}
                showLessLabel={`${count} Medien verbergen`}
                classes='content-reveal--documents'
            >
                {renderedMedia}
            </ContentReveal>
        );
    }

    render() {
        const { initialized } = this.state;

        return (
            <Section classes="section--construction-plan-media" title="Medien">
                {initialized
                    ? this.renderMediaGroups()
                    : <LoadingSpinner block label="Lade Medien" />
                }
            </Section>
        );
    }
}

ConstructionPlanMedia.propTypes = {
    site: ConstructionPlanFormSitePropType.isRequired,
    referenceData: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        resolveConstructionPlanMedia
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanMedia);
