import PropTypes from 'prop-types';
import React from 'react';
import { getDeletedUserLabel } from '../../utils';
import NotAvailablePlaceholder from './NotAvailablePlaceholder';

const DeletedUserPlaceholder = ({ label }) => (
    <NotAvailablePlaceholder label={label} />
);

DeletedUserPlaceholder.protTypes = {
    label: PropTypes.string
};

DeletedUserPlaceholder.defaultProps = {
    label: getDeletedUserLabel()
};

export default DeletedUserPlaceholder;
