import React, { Component } from 'react';
import { Field } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { limit } from '../../utils';
import FieldError from './FieldError';

export function formatMinutes(val) {
    const minutes = limit(val.substring(0, 2), '59');
    return minutes;
}

class Duration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadModalIsOpen: false
        };
        this.transformFormStateToInputValue = this.transformFormStateToInputValue.bind(this);
        this.transformInputValueToFormState = this.transformInputValueToFormState.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.input.value && this.props.input.value) {
            this.transformFormStateToInputValue(this.props.input.value);
        }
    }

    componentWillMount() {
        const { value: durationInMinutes } = this.props.input;


        this.transformFormStateToInputValue(durationInMinutes);
    }

    transformFormStateToInputValue(durationInMinutes) {
        const hours = Math.floor(durationInMinutes / 60) || '';
        const minutes = durationInMinutes % 60 || '';

        this.setState({
            hours,
            minutes
        });
    }

    handleHourChange(e) {
        this.setState({
            hours: e.target.value
        }, () => this.emmitChange());
    }

    handleMinuteChange(e) {
        this.setState({
            minutes: e.target.value
        }, () => this.emmitChange());
    }


    emmitChange() {
        this.transformInputValueToFormState();
    }

    transformInputValueToFormState() {
        const { hours, minutes } = this.state;

        const _hours = parseInt((hours || 0), 10);
        const _minutes = parseInt((minutes || 0), 10);

        const { input } = this.props;

        const durationInMinutes = parseInt((_hours * 60) + _minutes, 10);

        input.onChange(durationInMinutes);

    }

    render() {
        const { meta } = this.props;
        const { hours, minutes } = this.state;

        return (
            <div className="timeduration-picker">
                <div className="timeduration-picker__wrapper timeduration-picker__wrapper--hour">
                    <span className="timeduration-picker__label">
                        h
                    </span>
                    <NumberFormat
                        onChange={(val) => this.handleHourChange(val)}
                        value={hours}
                        className="timeduration-picker__input"
                    />
                </div>
                <div className="timeduration-picker__wrapper timeduration-picker__wrapper--minutes">
                    <span className="timeduration-picker__label">min</span>
                    <NumberFormat
                        onChange={(val) => this.handleMinuteChange(val)}
                        value={minutes}
                        format={formatMinutes}
                        className="timeduration-picker__input"
                    />
                </div>
                <FieldError meta={meta} />
            </div>
        );
    }
};

const DurationInput = ({ ...props }) => (
    <Field
        component={Duration}
        {...props}
    />
);

export default DurationInput;
