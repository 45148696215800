import { ProtectivePurposes, PurposeGroup } from '../../../../Sections/properties';
import { getEnumListDiffOptionRows, getEnumListIdenticalOptionRows } from '../../optionRowData';

const availablePurposeGroups = [
    PurposeGroup.WorkScaffolding,
    PurposeGroup.ProtectiveScaffolding,
    PurposeGroup.LadderAscent,
    PurposeGroup.PlatformStairway,
    PurposeGroup.SiteStaircase,
    PurposeGroup.EmergencyStairway
];

/**
 * Returns all identical purpose group options
 *
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getIdenticalPurposeGroupOptionRows = (planValue, liveValue) => {
    return getEnumListIdenticalOptionRows(availablePurposeGroups, planValue, liveValue);
};

/**
 * Returns all purpose group options which are different
 *
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getDiffPurposeGroupOptionRows = (planValue, liveValue) => {
    return getEnumListDiffOptionRows(availablePurposeGroups, planValue, liveValue);
};


/**
 * Returns all identical protective purpose options
 *
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getIdenticalProtectivePurposeOptionRows = (planValue, liveValue) => {
    return getEnumListIdenticalOptionRows(ProtectivePurposes, planValue, liveValue);
};

/**
 * Returns all protective purpose options which are different
 *
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getDiffProtectivePurposeOptionRows = (planValue, liveValue) => {
    return getEnumListDiffOptionRows(ProtectivePurposes, planValue, liveValue);
};
