import React from 'react';

const Button = ({ onClick, children, disableSubmit, ...props }) => (
    <button
        {...props}
        onClick={(e) => {
            if (disableSubmit) {
                e.preventDefault();
                e.stopPropagation();
            }
            onClick(e)
        }}
    >
        {children}
    </button>
);

export default Button;
