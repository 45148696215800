import { isIdenticalValue, isObjectIdenticalValue } from '../equals';
import { isIdenticalMediaValue, isIdenticalSingleMediaValue } from '../media';

export const isIdenticalSectionFormValue = (property, modelData, modelReferenceData) => {

    const modelValue = modelData && modelData[property] || null;
    const liveValue = modelReferenceData && modelReferenceData[property] || null;

    switch (property) {
        case 'equippedSurfaces':
        case 'claddings':
        case 'widthClass':
        case 'loadClass':
        case 'wallClearance':
        case 'plannedWorks':
        case 'specialFeatures':
            return isObjectIdenticalValue(modelValue, liveValue);
        case 'attachments':
        case 'utilizationPlans':
            return isIdenticalMediaValue(modelValue, liveValue);
        case 'anchorProtocol':
        case 'proofOfStability':
            return isIdenticalSingleMediaValue(modelValue, liveValue);
        case 'name':
        case 'status':
        case 'managed':
        case 'description':
        case 'approvedByName':
        case 'specialConstruction':
        case 'staticsCertificateNecessary':
        case 'purposes':
        case 'otherProtectivePurpose':
        case 'constructionType':
        case 'supportType':
        case 'customer':
        default:
            return isIdenticalValue(modelValue, liveValue);
    }
};
