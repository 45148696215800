import React, {Component} from 'react';
import {Form} from 'react-final-form';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {toast} from 'react-toastify';
import {bindActionCreators} from 'redux';
import {showApiError} from '../../redux/modules/error/action';
import {updateJobTypeAndStatus} from '../../redux/modules/job/actions';
import Modal from '../Common/Modal/Modal';
import JobStatusOptions from './Form/jobStatusOptions';
import JobStatusSelectField from './Form/JobStatusSelectField';
import RequestStatusOptions from './Form/requestStatusOptions';
import {JobType} from './properties';

class JobStatusSwitchModal extends Component {

    static propTypes = {
        switchTo: PropTypes.oneOf([JobType.Request, JobType.Job]).isRequired,
        id: PropTypes.string.isRequired,
        closeModal: PropTypes.func.isRequired,
        jobName: PropTypes.string.isRequired,
        onSuccess: PropTypes.func,
        redirect: PropTypes.bool
    };

    static defaultProps = {
        onSuccess: (id, status) => false,
        redirect: true
    };

    constructor(props) {
        super(props);
        this.unmounted = false;
        this.state = {
            isSending: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        const {switchTo} = this.props;

        let statusOptions;

        switch (switchTo) {
            case JobType.Job:
                statusOptions = JobStatusOptions;
                break;
            default:
                statusOptions = RequestStatusOptions;
        }

        this.setState({
            statusOptions
        });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    renderModalTitle() {
        const {switchTo} = this.props;
        let titleText;

        if (switchTo === JobType.Job) {
            titleText = 'Anfrage in Auftrag umwandeln';
        } else {
            titleText = 'Auftrag in Anfrage umwandeln';
        }
        return titleText;
    }

    renderButtonText() {
        const {switchTo} = this.props;
        const {isSending} = this.state;
        let buttonText;

        if (isSending) {
            buttonText = 'Änderungen werden gespeichert ...';
            return buttonText;
        }

        if (switchTo === JobType.Job) {
            buttonText = 'Anfrage umwandeln';
        } else {
            buttonText = 'Auftrag umwandeln';
        }
        return buttonText;
    }

    renderIntroText() {
        const {switchTo} = this.props;
        let introText;

        if (switchTo === JobType.Job) {
            introText = 'Bitte wählen Sie einen Status, in dem sich Ihre Anfrage nach der Umwandlung in einen Auftrag befinden soll:';
        } else {
            introText = 'Bitte wählen Sie einen Status, in dem sich Ihr Auftrag nach der Umwandlung in eine Anfrage befinden soll:';
        }
        return introText;
    }

    renderSuccessMessage() {
        const {switchTo, jobName} = this.props;

        let successMessageText;

        if (switchTo === JobType.Job) {
            successMessageText = `Anfrage ${jobName} erfolgreich umgewandelt.`;
        }

        if (switchTo === JobType.Request) {
            successMessageText = `Auftrag ${jobName} erfolgreich umgewandelt.`;
        }

        return successMessageText;
    }

    onSubmit(values) {
        // const { status : {value}  = values;

        const {
            switchTo, id, actions: {updateJobTypeAndStatus}, history, redirect, closeModal, onSuccess
        } = this.props;

        this.setState({
            isSending: true
        });

        updateJobTypeAndStatus(id, switchTo, values.status)
            .then((updatedJob) => {
                closeModal();

                toast.success(this.renderSuccessMessage(), {autoClose: 2500});
                if (redirect) {
                    const path = switchTo === JobType.Job ? `/jobs/${updatedJob.id}` : `/requests/${updatedJob.id}`;
                    history.push(path);
                }

                onSuccess(id, values.status);
            })
            .catch((error) => {
                showApiError(error);
            })
            .finally(() => {
                if(this.unmounted) {
                    return;
                }

                this.setState({
                    isSending: false
                });
            });
    }


    render() {
        const {closeModal} = this.props;
        const {statusOptions, isSending} = this.state;

        return (
            <Form
                onSubmit={this.onSubmit}
                initialValues={{}}
                render={({handleSubmit, values}) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            id="statusSwitchModal"
                            title={this.renderModalTitle()}
                            type="micro-form"
                            onBackdropClick={() => closeModal()}
                            footer={
                                <div className="btn-group">
                                    <button
                                        className="btn btn--backward-action"
                                        onClick={() => closeModal()}
                                    >
                                        Abbrechen
                                    </button>
                                    <button
                                        type="submit"
                                        className={`btn btn--save btn--forward-action ${isSending ? 'btn--isSending' : ''}`}
                                        disabled={!values.status || isSending}
                                        onClick={handleSubmit}
                                    >
                                        {this.renderButtonText()}
                                    </button>
                                </div>
                            }
                        >
                            <p>
                                {this.renderIntroText()}
                            </p>

                            <JobStatusSelectField
                                name="status"
                                options={statusOptions}
                            />

                        </Modal>
                    </form>
                )}
            />
        );
    }

}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateJobTypeAndStatus
    }, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(JobStatusSwitchModal));
