import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import { cardExpiry } from '../../utils';
import { ViewModes } from '../Account/BillingDetailsForm';
import Error from '../Forms/Error';
import InputGroup from '../Forms/InputGroup';
import LinkButton from './LinkButton';
import Icon from './Icon';
import stripeInputStyles from './stripeInputStyles';
import Input from '../Forms/Input';
import CreditCardInfo from './CreditCardInfo';
import Tooltip from './Tooltip';

const errorMessageKeys = {
    cardNumber: 'cardNumberErrorMessage',
    cardExpiry: 'cardExpiryErrorMessage',
    cardCvc: 'cardCvcErrorMessage'
};

class CardSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardNumberErrorMessage: '',
            cardExpiryErrorMessage: '',
            cardCvcErrorMessage: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const dates = e.target.value.split('/');
        this.props.onExpirationDateChange('expMonth', dates[0]);
        this.props.onExpirationDateChange('expYear', dates[1]);
    }

    handleChange(event) {
        const elementType = event.elementType;

        let errorKey;

        switch (elementType) {
            case 'cardNumber':
                errorKey = errorMessageKeys.cardNumber;
                break;
            case 'cardExpiry':
                errorKey = errorMessageKeys.cardExpiry;
                break;
            case 'cardCvc':
            default:
                errorKey = errorMessageKeys.cardCvc;
                break;
        }

        if (event.error) {
            const errorMessage = event.error.message;
            this.setState({
                [errorKey]: errorMessage
            });
        } else {
            this.setState({
                [errorKey]: ''
            });
        }
    }

    renderCardViewForm() {
        const { billingData, disabled, isLegacy } = this.props;
        if (!billingData) {
            return null;
        }

        const { card } = billingData;
        const { name } = billingData.owner;

        if (!card) {
            return null;
        }

        return (
            <React.Fragment>
                <InputGroup
                    containerClass="creditcard creditcard--view"
                    label="Aktive Zahlungsmethode"
                    subInput
                >
                    <CreditCardInfo
                        cardOwner={name}
                        cardBrand={card.brand}
                        cardLast4={card.last4}
                        cardExpMonth={card.expMonth}
                        cardExpYear={card.expYear}
                    />
                    {this.props.viewMode === ViewModes.CardEdit &&
                    <React.Fragment>
                        <div className="creditcard__edit">
                            <label>Neues Ablaufdatum</label>
                            <NumberFormat
                                onChange={this.onChange}
                                format={cardExpiry}
                                placeholder="MM / JJ"
                                disabled={this.props.disabled}
                            />
                        </div>
                        <div>
                            <Error name="expMonth"/>
                        </div>
                    </React.Fragment>
                    }
                    <div className="creditcard__actions">
                        {this.props.viewMode === ViewModes.CardView &&
                        <LinkButton
                            label="Ablaufdatum bearbeiten"
                            style={{
                                textTextDecoration: 'underline',
                                fontWeight: 400
                            }}
                            onClick={() => {
                                if (!disabled && !isLegacy) {
                                    this.props.setViewMode(ViewModes.CardEdit);
                                }
                            }}
                            disabled={disabled | isLegacy}
                            children={isLegacy &&
                            <Tooltip id={'creditcard'}>
                                <span>
                                    Auf Grund der neuen EU-Richtlinie "PSD2" ist die Bearbeitung Ihrer hinterlegten Kreditkarte leider nicht möglich. Bitte legen Sie Ihre Kreditkarte neu an, um Änderungen durchzuführen.
                                </span>
                            </Tooltip>
                            }
                        />
                        }

                        {this.props.viewMode === ViewModes.CardEdit &&

                        <LinkButton
                            label="Abbrechen"
                            style={{ fontWeight: 400 }}
                            onClick={() => {
                                if (!disabled) {
                                    this.props.setViewMode(ViewModes.CardView);
                                }
                            }}
                            disabled={disabled}
                        />
                        }

                        {this.props.viewMode === ViewModes.CardView &&

                        <button
                            className="btn btn--neutral-action"
                            style={{ float: 'right' }}
                            disabled={disabled}
                            onClick={() => {
                                if (!disabled) {
                                    this.props.setViewMode(ViewModes.CardNew);
                                }
                            }}
                        >
                            <span className="btn__icon">
                                <Icon name="add"/>
                            </span>
                            <span>Neue Kreditkarte</span>
                        </button>
                        }
                    </div>
                </InputGroup>
            </React.Fragment>
        );
    }

    renderAddCardForm() {
        const { cardNumberErrorMessage, cardExpiryErrorMessage, cardCvcErrorMessage } = this.state;
        const { disabled } = this.props;

        return (
            <React.Fragment>
                <InputGroup label="Karteninhaber" subInput>
                    <Input
                        type="text"
                        name="creditCardOwner"
                        disabled={disabled}
                        placeholder="Karteninhaber angeben"
                        style={{ fontWeight: '300' }}
                    />
                </InputGroup>

                <InputGroup
                    containerClass="creditcard creditcard--add"
                    label="Kartendaten*"
                    subInput
                >
                    <CardNumberElement
                        disabled={disabled}
                        {...stripeInputStyles}
                        placeholder="Kartennummer angeben"
                        onChange={this.handleChange}
                    />
                    {cardNumberErrorMessage &&
                    <div className="input-grp__error">{cardNumberErrorMessage}</div>}
                </InputGroup>

                <InputGroup
                    label="Ablaufdatum & CVC-Code*"
                    subInput
                >
                    <div className="creditcard__expirationCvc" style={{ display: 'flex' }}>
                        <CardExpiryElement
                            className="credidcard__expiry"
                            disabled={disabled}
                            {...stripeInputStyles}
                            onChange={this.handleChange}
                        />
                        <CardCVCElement
                            className="credidcard__cvc"
                            disabled={disabled}
                            {...stripeInputStyles}
                            placeholder="CVC-Code"
                            onChange={this.handleChange}
                        />
                    </div>
                    {cardExpiryErrorMessage &&
                    <div className="input-grp__error">{cardExpiryErrorMessage}</div>}
                    {cardCvcErrorMessage &&
                    <div className="input-grp__error">{cardCvcErrorMessage}</div>}
                </InputGroup>

            </React.Fragment>
        );
    }

    render() {
        const { viewMode } = this.props;

        switch (viewMode) {
            case ViewModes.CardView:
            case ViewModes.CardEdit:
                return this.renderCardViewForm();

            default:
                return this.renderAddCardForm();
        }
    }

}

CardSelection.propTypes = {
    billingData: PropTypes.object,
    isLegacy: PropTypes.bool
};

CardSelection.defaultProps = {
    billingData: null,
    isLegacy: false
};

export default injectStripe(CardSelection);
