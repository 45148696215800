import PropTypes from 'prop-types';
import React from 'react';
import PersonalDataEditBreadcrumb from '../../../Components/Account/PersonalDataEditBreadcrumb';
import PersonalDataForm from '../../../Components/Account/PersonalDataForm';
import PageTitle from '../../../Components/Page/PageTitle';
import TopNav from '../../../Components/Page/TopNav';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const PersonalDataEditPage = ({ history }) => (
    <PrivatePage>
        <Container>
            <TopNav />
            <PersonalDataEditBreadcrumb />
            <PageTitle
                title="Kontodaten"
            />
            <PersonalDataForm
                history={history}
            />
        </Container>
    </PrivatePage>
);

export default (PersonalDataEditPage);

PersonalDataEditPage.propTypes = {
    history: PropTypes.object
};

PersonalDataEditPage.defaultProps = {
    history: {}
};
