import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';

const ThirdPartyIndicator = ({ label, size }) => (
    <div className={['indicator indicator--third-party', size === 'small' ? 'indicator--small' : ''].join(' ')}>
        <Icon name="thirdParty" verticalAlign="baseline" /><span className="indicator__lbl">{label}</span>
    </div>
);

export default ThirdPartyIndicator;

ThirdPartyIndicator.propTypes = {
    label: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default'])
};

ThirdPartyIndicator.defaultProps = {
    size: 'default',
    label: 'Abonniert'
};
