import React from 'react';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';

export const SectionCustomerInput = () => (
    <InputGroup
        label="Auftraggeber"
    >
        <Input
            name="customer"
            type="text"
            placeholder='z.B. "Schmitz Fassadenrestauration GmbH"'
        />
    </InputGroup>

);
