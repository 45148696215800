import moment from 'moment/moment';
import { CURRENT_ADV_VERSION } from '../Components/Account/ADVModal';
import { CURRENT_TERMS_VERSION } from '../Components/Account/TermsModal';

export const CURRENT_CHANGE_LOG_VERSION = '1.24.0';

export const isTokenExpired = (getState) => {
    const { expiration_time } = getState().currentUser;

    return expiration_time && (expiration_time - moment()
        .valueOf() < 3000);
};

export const isExpired = expiration_time => expiration_time - moment()
    .format('x') < 1000;

export const isTrialActive = (state) => {
    const { currentUser } = state;

    const { account } = currentUser.usermeta;
    if (!account) {
        return false;
    }

    const { trialEnd } = account;
    if (!trialEnd) {
        return false;
    }

    return moment(trialEnd) >= moment();
};

export const getAccountType = (state) => {
    const { currentUser } = state;

    const { account } = currentUser.usermeta;
    if (!account) {
        return undefined;
    }

    return account.type;
};

export const subscriptionHasExpired = (getState) => {
    const { currentUser } = getState();
    const { mainSubscription } = currentUser.usermeta.account;
    return !mainSubscription || mainSubscription.expired;
};

export const currentTermsWereAccepted = ({ currentUser }) => {
    let currentTermsWereAccepted = false;
    const acceptedTerms = currentUser.usermeta.account.acceptedTerms || [];

    const term = acceptedTerms.find(term => term.version === CURRENT_TERMS_VERSION);

    if (term) {
        currentTermsWereAccepted = moment(term.acceptedAt)
            .format('DD.MM.YYYY, HH:mm');
    }

    return currentTermsWereAccepted;
};

export const userHasReadLatestChangelogEntry = ({ currentUser }) => {
    const alreadyReadNotifications = currentUser.usermeta.readNotifications || [];
    const notificationId = getChangeLogVersionNotificationId(CURRENT_CHANGE_LOG_VERSION);
    return !!alreadyReadNotifications.find(entry => entry.id === notificationId);
};

export const getChangeLogVersionNotificationId = (changeLogVersion) => {
    return `webapp-changelog-${changeLogVersion}`;
};

export const userAcceptedADVAgreement = ({ currentUser }) => {
    let userAcceptedADVAgreement = false;

    const acceptedADV = currentUser.usermeta.acceptedAdv || [];

    const adv = acceptedADV.find(adv => adv.version === CURRENT_ADV_VERSION);

    if (adv) {
        userAcceptedADVAgreement = moment(adv.acceptedAt)
            .format('DD.MM.YYYY, HH:mm');
    }

    return userAcceptedADVAgreement;
};

export const isAccountOwner = ({ currentUser }) => currentUser.usermeta.isAccountOwner;

export const getUserName = ({ currentUser }) => {

    const { usermeta } = currentUser;

    return `${usermeta.firstName} ${usermeta.lastName}`;
};

export const getCompanyName = ({ currentUser }) => {

    const companyName = (currentUser.usermeta.account
        && currentUser.usermeta.account.company
        && currentUser.usermeta.account.company.name)
        || '...';

    return companyName;
};

export const getUserPermissions = ({ currentUser }) => {

    const userPermissions = currentUser.usermeta.permissions ? currentUser.usermeta.permissions : [];

    return userPermissions;
};
