import { isIdenticalValue } from '../equals';
import { isIdenticalMediaValue, isIdenticalSingleMediaValue } from '../media';

export const isIdenticalScaffoldingFormValue = (property, modelData, modelReferenceData) => {

    const modelValue = modelData && modelData[property] || undefined;
    const liveValue = modelReferenceData && modelReferenceData[property] || undefined;

    switch (property) {
        case 'name':
        case 'description':
            return isIdenticalValue(modelValue, liveValue);
        case 'scheduledErection':
        case 'scheduledDismantling':
            return isIdenticalSchedule(modelData, modelReferenceData);
        case 'attachments':
        case 'utilizationPlans':
            return isIdenticalMediaValue(modelValue, liveValue);
        case 'anchorProtocol':
            return isIdenticalSingleMediaValue(modelValue, liveValue);
        default:
            return false;
    }
};

export function isIdenticalSchedule(modelData, modelReferenceData) {
    const isScheduleErectionIdentical = isIdenticalValue(modelData.scheduledErection, modelReferenceData.scheduledErection);
    const isScheduleDismantlingIdentical = isIdenticalValue(modelData.scheduledDismantling, modelReferenceData.scheduledDismantling);

    return isScheduleErectionIdentical && isScheduleDismantlingIdentical;
}
