import React from 'react';
import Tooltip from '../../../Common/Tooltip';
import Checkbox from '../../../Forms/Checkbox';
import Condition from '../../../Forms/Condition';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import { ConstructionType, PurposeGroup } from '../../properties';
import { isEmergencyStairway, isUnmanaged } from '../functions';

export const SectionStabilityAndLoadSafetyInput = ({ values }) => {

    const _isUnmanaged = isUnmanaged(values);
    const _isEmergencyStairway = isEmergencyStairway(values);

    const isSpecialConstructionDisabled = _isEmergencyStairway && !_isUnmanaged;

    const specialConstructionTooltip = isSpecialConstructionDisabled ?
        <Tooltip id="specialConstructionEmergencyStairway">
            Bei Fluchttreppen handelt es sich immer um Sonderkonstruktionen
        </Tooltip> : null;

    return (
        <InputGroup
            label="Stand- und Tragsicherheit"
        >
            {/* Special construction */}

            <Checkbox
                label="Sonderkonstruktion"
                name="specialConstruction"
                disabled={isSpecialConstructionDisabled}
                tooltip={specialConstructionTooltip}
            />

            <Condition when="purpose.groups" contains={PurposeGroup.SiteStaircase}>
                <InputHint>
                    Bitte beachten Sie: Eine bauaufsichtliche Zulassung für {_isUnmanaged ? 'die' : 'Ihre'} Bautreppe muss
                    vorhanden sein.
                    <Condition when="constructionType"
                               is={ConstructionType.PrefabricatedComponents}>
                        {' '}Bitte beachten Sie die Herstellerangaben.
                    </Condition>
                </InputHint>
            </Condition>

            {/* Statics certificate necessary */}

            <Checkbox
                label={
                    <>
                        Statiknachweis erforderlich
                        <Condition when="staticsCertificateNecessary" is={true}>
                            {' - '} <a href="#section-form-upload-documents">Hier hochladen</a>
                        </Condition>
                    </>
                }
                name="staticsCertificateNecessary"
            />

            <Condition when="purpose.groups" contains={PurposeGroup.EmergencyStairway}>
                <InputHint>
                    Bitte beachten Sie: Eine bauaufsichtliche Zulassung für {_isUnmanaged ? 'die' : 'Ihre'} Fluchttreppe muss
                    vorhanden sein.
                </InputHint>
            </Condition>

        </InputGroup>
    )
}
