import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {bindActionCreators} from 'redux';
import ResourceNotFoundErrorPage from '../../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import {deleteSingleCustomer, fetchCustomerDetail} from '../../../redux/modules/customer/action';
import {showApiError} from '../../../redux/modules/error/action';
import {applyModelChangesToCollection} from '../../../utils';
import DataRow from '../../Common/DataRow';
import DeleteModal from '../../Common/Modal/DeleteModal';
import EmptyDataTableHint from '../../Common/EmptyDataTableHint';
import Icon from '../../Common/Icon';
import IfUser from '../../Common/IfUser';
import {ScaffoldingPageLink} from '../../Common/ResourcePageLink';
import SectionListDropDown from '../../Common/SectionListDropDown';
import Tooltip from '../../Common/Tooltip';
import {JobType} from '../../Job/properties';
import ReferencedJobRequestsTable from '../../Job/Referenced/ReferencedJobRequestsTable';
import {ReferencedJobsTable} from '../../Job/Referenced/ReferencedJobsTable';
import Section from '../../Layout/Section';
import LoadingSpinner, {PageLoadingSpinner} from '../../Page/LoadingSpinner';
import PageTitle from '../../Page/PageTitle';
import CustomerFormModal from '../Form/CustomerFormModal';
import CustomerBreadcrumb from './CustomerBreadcrumb';
import CustomerContactPersonOverview
    from './CustomerContactPersonOverview/CustomerContactPersonOverview';

class CustomerDetail extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            customer: {},
            isFetchingCustomer: true,
            isDeletingCustomer: false,
            showDeleteModal: false,
            showEditModal: false,
        };

        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.loadCustomer = this.loadCustomer.bind(this);
    }

    static renderScaffoldings(scaffoldings) {

        if (!scaffoldings || scaffoldings.length === 0) {
            return 'n.a.';
        }

        return scaffoldings.map(scaffolding => (
            <React.Fragment
                key={scaffolding.id}
            >
                <ScaffoldingPageLink
                    id={scaffolding.id}
                >
                    {scaffolding.name}
                </ScaffoldingPageLink>

                {scaffolding.sections ?
                    <SectionListDropDown
                        title="Gerüstabschnitte"
                        sections={scaffolding.sections}
                    />
                    :
                    null
                }
            </React.Fragment>
        ));
    }

    componentDidMount() {
        this.loadCustomer();
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onUpdated() {

        this.toggleEditModal();
        this.loadCustomer();
    }

    toggleEditModal() {
        this.setState(prevState => ({
            showEditModal: !prevState.showEditModal,
        }));
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal,
        }));
    }

    componentDidUpdate(prevProps) {

        const {customerId} = this.props;

        if (customerId !== prevProps.customerId) {
            this.loadCustomer();
        }

    }

    loadCustomer() {
        this.setState({
            loading: true,
        });

        this.props.actions.fetchCustomerDetail(this.props.customerId)
            .then((customer) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setState({
                    customer,
                    loading: false,
                    isFetchingCustomer: false,
                });
            })
            .catch(() => {
                this.setState({
                    customer: null,
                    loading: false,
                    isFetchingCustomer: false,
                });
            });
    }

    onJobChange(changedJob, changes) {
        const {customer} = this.state;
        if (!customer) {
            return;
        }

        let {jobs} = customer;

        const updatedJobs = applyModelChangesToCollection(jobs, changedJob.id, changes);

        this.setState({
            customer: {
                ...customer,
                jobs: updatedJobs,
            },
        });
    }

    deleteCustomer() {

        const {customer: {id}} = this.state;

        const {
            actions: {deleteSingleCustomer},
            history,
        } = this.props;

        this.setState({
            isDeletingCustomer: true,
        });

        deleteSingleCustomer(id)
            .then(() => {
                this.setState({
                    isDeletingCustomer: false,
                });
                this.toggleDeleteModal();
                toast.success('Kunde wurde gelöscht', {autoClose: 2500});
                history.push('/customers');
            })
            .catch((error) => {
                this.setState({
                    isDeletingCustomer: false,
                });
                return showApiError(error);
            });
    }

    render() {
        const {
            loading,
            isFetchingCustomer,
            customer,
            showDeleteModal,
            isDeletingCustomer,
            showEditModal,
        } = this.state;

        if (!isFetchingCustomer && !customer) {
            return <ResourceNotFoundErrorPage/>;
        }

        const {customerId: id} = this.props;

        const {
            name, line1, line2, zip, city, billingAddress, customerId,
        } = this.state.customer;

        if (isFetchingCustomer) {
            return (
                <Fragment>
                    <CustomerBreadcrumb showCustomer={true}/>
                    <PageTitle
                        title="Kunde"
                    />
                    <PageLoadingSpinner
                        label="Lade Kundendaten"
                    />
                </Fragment>
            );
        }

        return (
            <Fragment>
                <CustomerBreadcrumb customer={customer}/>

                <PageTitle
                    title={`Kunde: ${name}`}
                />

                {loading && <PageLoadingSpinner
                    label="Aktualisiere Kundendaten"
                />}

                <Section
                    title="Stammdaten"
                    cta={
                        <IfUser
                            can={['update-customers']}
                        >
                            <button
                                className="btn btn--neutral-action"
                                onClick={this.toggleEditModal}
                            >
                                <span className="btn__icon">
                                    <Icon name="edit"/>
                                </span>
                                <span>Bearbeiten</span>
                            </button>
                        </IfUser>
                    }
                >
                    <DataRow
                        label="Vollständiger Name"
                    >
                        {name}
                    </DataRow>

                    <DataRow
                        label="Kunden-ID"
                    >
                        {customerId}
                    </DataRow>

                    <DataRow
                        label="Adresszeile 1*"
                    >
                        {line1}
                    </DataRow>
                    <DataRow
                        label="Adresszeile 2"
                    >
                        {line2}
                    </DataRow>
                    <DataRow
                        label="Postleitzahl, Ort"
                    >
                        {`${zip} ${city}`}
                    </DataRow>
                    <DataRow
                        label="Rechnungsadresse"
                    >
                        {billingAddress ?
                            <React.Fragment>
                                {billingAddress.name && <div>{billingAddress.name}</div>}
                                {billingAddress.line1 && <div>{billingAddress.line1}</div>}
                                {billingAddress.line2 && <div>{billingAddress.line2}</div>}
                                {billingAddress.zip && billingAddress.city &&
                                <div> {`${billingAddress.zip} ${billingAddress.city}`}</div>}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {name && <div>{name}</div>}
                                {line1 && <div>{line1}</div>}
                                {line2 && <div>{line2}</div>}
                                {zip && city && <div>{`${zip} ${city}`}</div>}
                            </React.Fragment>
                        }
                    </DataRow>
                </Section>

                <CustomerContactPersonOverview
                    customerId={id}
                    onChange={this.loadCustomer}
                    customerContacts={customer.customerContacts}
                    isLoading={loading}
                />

                <IfUser
                    can={['read-jobs']}
                >
                    <Section
                        title="Anfragen"
                        type="list"
                        classes="related-requests"
                        subline={
                            <Fragment>
                                Nur aktive Anfragen <Tooltip id="related-request-tt">Unter "Aktive
                                                                                     Anfragen" werden alle Anfragen
                                                                                     zusammengefasst, die in Scaffeye®
                                                                                     hinterlegt und nicht archiviert
                                                                                     sind.</Tooltip>
                            </Fragment>
                        }
                        id="related-requests"
                    >
                        <ReferencedJobRequestsTable
                            data={customer.jobs ? customer.jobs.filter((job) => job.type === JobType.Request && !job.archived) : []}
                            onItemChange={(job, changes) => this.onJobChange(job, changes)}
                            emptyTableHint={<EmptyDataTableHint
                                numColumns={7}
                                title="Es wurden noch keine Anfragen mit diesem Kunden verknüpft."
                            >
                                <p>
                                    Sie können Kunden und Anfragen miteinander
                                    verknüpfen. Wählen Sie dazu die
                                    gewünschte Anfrage im Sidebar-Reiter
                                    {' '}<Link to="/requests">Anfragen</Link>{' '}
                                    aus und folgen Sie den Anweisungen
                                    der Sektion &quot;Baustellendaten&quot;.
                                </p>
                            </EmptyDataTableHint>}
                        />
                    </Section>

                    <Section
                        title="Aufträge"
                        type="list"
                        classes="related-jobs"
                        id="related-jobs"
                        subline={
                            <Fragment>
                                Nur aktive Aufträge <Tooltip id="related-jobs-tt">Unter "Aktive
                                                                                  Aufträge" werden alle Aufträge
                                                                                  zusammengefasst, die in Scaffeye®
                                                                                  hinterlegt und nicht archiviert
                                                                                  sind.</Tooltip>
                            </Fragment>
                        }
                    >
                        <ReferencedJobsTable
                            data={customer.jobs ? customer.jobs.filter((job) => job.type === JobType.Job && !job.archived) : []}
                            onItemChange={(job, changes) => this.onJobChange(job, changes)}
                            emptyTableHint={<EmptyDataTableHint
                                numColumns={6}
                                title="Es wurden noch keine Aufträge mit diesem Kunden verknüpft."
                            >
                                <p>
                                    Sie können Kunden und Aufträge miteinander
                                    verknüpfen. Wählen Sie dazu den
                                    gewünschten Auftrag im Sidebar-Reiter
                                    {' '}<Link to="/jobs">Aufträge</Link>{' '}
                                    aus und folgen Sie den Anweisungen
                                    der Sektion &quot;Baustellendaten&quot;.
                                </p>
                            </EmptyDataTableHint>}
                        />
                    </Section>
                </IfUser>

                {!isFetchingCustomer &&
                <IfUser
                    can={['delete-customers']}
                >
                    <div className="delete-option">
                        <span
                            className="btn btn--delete"
                            onClick={this.toggleDeleteModal}
                        >
                            <span className="btn__icon">
                                <Icon name="delete"/>
                            </span>
                            Kunde löschen
                        </span>
                    </div>
                </IfUser>
                }

                {showEditModal &&
                <CustomerFormModal
                    mode="edit"
                    id={customer.id}
                    onClose={this.toggleEditModal}
                    onUpdated={this.onUpdated}
                />
                }

                {showDeleteModal &&
                <DeleteModal
                    modalTitle="Kunde löschen"
                    deleteEntity={this.deleteCustomer}
                    closeModal={this.toggleDeleteModal}
                    isDeletingEntity={isDeletingCustomer}
                >
                    <p>Sie sind im Begriff, den Kunden {' '}
                        <span
                            onClick={this.toggleDeleteModal}
                            className="btn--link-neutral"
                        >
                            {customer.name}
                        </span> zu löschen.
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteModal>
                }
            </Fragment>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCustomerDetail,
        deleteSingleCustomer,
    }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(CustomerDetail));

CustomerDetail.propTypes = {
    customerId: PropTypes.string,
};

CustomerDetail.defaultProps = {
    customerId: '',
};
