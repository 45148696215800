import { DiaryCategories } from './properties';

const ConstructionDiaryCategoryOptions = [
    { label: 'PSA-Dokumentation', value: DiaryCategories.PsaDocumentation },
    { label: 'Aufbau', value: DiaryCategories.Construction },
    { label: 'Abbau', value: DiaryCategories.Dismantling },
    { label: 'Schaden am Objekt', value: DiaryCategories.ObjectDamaged },
    { label: 'Instandsetzung / Reparatur', value: DiaryCategories.Repairs },
    { label: 'Wartezeit', value: DiaryCategories.WaitingPeriod },
    { label: 'Lager', value: DiaryCategories.Warehouse },
    { label: 'Sonstiges', value: DiaryCategories.Other }
];

export const DiaryCategoryFilterOptions = [
    ...ConstructionDiaryCategoryOptions,
    { label: 'Freigabe', value: DiaryCategories.Acceptance }
];

export default ConstructionDiaryCategoryOptions;
