import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { MediaVisibility } from '../../../../../Media/properties';
import SyncChoiceMediaVisibilityButton from './SyncChoiceMediaVisibilityButton';

const SyncChoiceMediaVisibilityOptions = ({ onChange, availableVisibilityOptions, selectedVisibility }) => {

    const isSelected = (value) => value === selectedVisibility;

    const isDisabled = (visibilityOption) => {
        return availableVisibilityOptions.indexOf(visibilityOption) === -1;
    };

    return (
        <Fragment>
            <SyncChoiceMediaVisibilityButton
                icon="locked"
                value={MediaVisibility.AccountPrivate}
                isDisabled={isDisabled}
                isSelected={isSelected}
                onClick={onChange}
            />
            <SyncChoiceMediaVisibilityButton
                icon="globe"
                value={MediaVisibility.Public}
                isDisabled={isDisabled}
                isSelected={isSelected}
                onClick={onChange}
            />
        </Fragment>
    );
};

SyncChoiceMediaVisibilityOptions.propTypes = {
    onChange: PropTypes.func.isRequired,
    availableVisibilityOptions : PropTypes.array.isRequired,
    selectedVisibility: PropTypes.oneOf([MediaVisibility.AccountPrivate, MediaVisibility.Public, false]),
};

SyncChoiceMediaVisibilityOptions.defaultProps = {
    selectedVisibility: [MediaVisibility.AccountPrivate],
};

export default SyncChoiceMediaVisibilityOptions;
