import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import DeletedUserPlaceholder from '../../Common/DeletedUserPlaceholder';
import EmptyDataTableHint from '../../Common/EmptyDataTableHint';
import PrimaryColumn from '../../Common/PrimaryColumn';
import { DiaryEntryLink } from '../../Common/ResourcePageLink';
import Table from '../../Common/Table';
import ConstructionDiaryEntryCategory from '../ConstructionDiaryEntryCategory';

function renderReference(scaffoldings, scaffoldingSelection, sections, sectionSelection) {
    if (scaffoldingSelection === 'all') {
        return 'Alle Gerüste / Alle Gerüstabschnitte';
    }

    const singleScaffolding = _.head(scaffoldings);
    const scaffoldingName = singleScaffolding.name;

    if (sectionSelection === 'all') {
        return `${scaffoldingName} / Alle Gerüstabschnitte`;
    }

    const singleSection = _.head(sections);
    const sectionName = singleSection.name;

    return `${scaffoldingName} / ${sectionName}`;
}

export const EmbeddedConstructionDiaryEntriesTable = ({ diaryEntries, allEntriesLink }) => (
    <Table
        rows={diaryEntries}
        columns={['Erstellt am', 'Kategorie', 'Gültig für', 'Protokollant']}
        emptyComponent={
            <EmptyDataTableHint
                numColumns={4}
                title="Es wurden noch keine Bautagebucheinträge für diese Baustelle angelegt"
            >
                <p>Sie können einen neuen Bautagebucheintrag über den
                    &quot;Neuer Eintrag&quot; Button am oberen rechten Rand
                    dieser Box anlegen</p>
            </EmptyDataTableHint>
        }
        footer={
            diaryEntries
            && diaryEntries.length >= 1
            ? <Link
                to={allEntriesLink}
                className="table__footer-link"
            >
                Alle Einträge anzeigen
            </Link>
            : null
        }
    >
        {
            (diaryEntry) => (
                <tr key={diaryEntry.id}>
                    <PrimaryColumn
                        link={
                            <DiaryEntryLink
                                id={diaryEntry.id}
                            >
                                {`${moment(diaryEntry.created)
                                    .format('DD.MM.YYYY, HH:mm')} Uhr`}
                            </DiaryEntryLink>
                        }
                    >
                        <DiaryEntryLink
                            id={diaryEntry.id}
                        >Details
                        </DiaryEntryLink>

                    </PrimaryColumn>
                    <td>
                        <ConstructionDiaryEntryCategory
                            category={diaryEntry.category} />
                    </td>
                    <td>
                        {renderReference(diaryEntry.scaffoldings, diaryEntry.scaffoldingSelection, diaryEntry.sections, diaryEntry.sectionSelection)}
                    </td>
                    <td>
                        {diaryEntry.createdBy
                            ? `${diaryEntry.createdBy.firstName} ${diaryEntry.createdBy.lastName}`
                            : <DeletedUserPlaceholder />
                        }
                    </td>
                </tr>
            )
        }
    </Table>
);
