import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import {
    AccountSubscriptionExpiredError,
    BadRequestError,
    ClientOutdatedError,
    ConflictError,
    EntityTooLargeError,
    ForbiddenError,
    LockedByException,
    MaintenanceModeError,
    MissingPermissionError,
    RequestTimeoutError,
    RessourceNotFoundError,
    ServerError,
    TooManyRequestsError,
    UnauthorizedError,
    UnprocessableEntity, UserInactiveError,
    UserSubscriptionExpiredError
} from '../Services/ApiError';
import { getErrorId } from '../Services/ErrorService';
import GraphQLError from '../Services/GraphQLError';
import { Store } from './configureStore';
import * as ErrorMessages from './errorMessages';
import * as ErrorTypes from './errorTypes';
import { logout } from './modules/user/action';


/**
 * Returns the exception that belongs to the given errorCode
 *
 * @param errorCode
 * @param response
 *
 * @returns {Promise<never>}
 */
function getExceptionForErrorCode(errorCode, response) {
    switch (errorCode) {
        case 'account_subscription_expired':
            return new AccountSubscriptionExpiredError(response);
        case 'user_subscription_expired':
            return new UserSubscriptionExpiredError(response);
        case 'missing_permission':
            return new MissingPermissionError(response);
        case 'forbidden':
            return new ForbiddenError(response);
        case 'user_inactive':
            return new UserInactiveError(response);
        default:
            return null;
    }
}

/**
 * Timeout utility
 *
 * @param ms
 * @param promise
 * @returns {Promise<any>}
 */
function timeout(ms, promise) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject(new RequestTimeoutError());
        }, ms);
        promise.then(resolve, reject);
    });
}


const errorForcesLogout = error => {

    const { currentUser: { usermeta } } = Store.getState();
    const { isAccountOwner } = usermeta || null;

    // 1. Current session is expired
    if (error instanceof UnauthorizedError) {
        return true;
    }

    // 2. current user is not account owner and error is one of the following types
    return !isAccountOwner && (error instanceof AccountSubscriptionExpiredError || error instanceof UserSubscriptionExpiredError);

};

function getForbiddenError(response) {

    return response
        .json()
        .catch(() => null)
        .then((errorPayload) => {
            return errorPayload ? errorPayload.error : null;
        })
        .then((errorCode) => {
            const exception = getExceptionForErrorCode(errorCode, response);

            if (errorForcesLogout(exception)) {
                Store.dispatch(logout());
            }

            if (exception) {
                return Promise.reject(exception);
            }

            return Promise.reject(new ForbiddenError(response));
        });
}

function isClientRequestErrorStatus(statusCode) {
    return Math.floor(statusCode / 100) === 4;
}

function isServerRequestErrorStatus(statusCode) {
    return Math.floor(statusCode / 100) === 5;
}

/**
 * Handles the basic API errors based on HTTP status codes
 *
 * @param status int
 * @param response
 * @returns {*}
 */
function getHttpStatusError(status, response) {
    /*
     * Client request errors (4xx)
     */


    if (isClientRequestErrorStatus(status)) {

        // Unauthorized
        if (status === 401) {
            return new UnauthorizedError(response);
        }

        // Forbidden request
        if (status === 403) {
            return new ForbiddenError(response);
        }

        // Unprocessable entity
        if (status === 422) {
            return new UnprocessableEntity(response);
        }

        // Entity too large
        if (status === 413) {
            return new EntityTooLargeError(response);
        }

        // Resource in conflict
        if (status === 409) {
            return new ConflictError(response);
        }

        // Resource not found
        if (status === 404) {
            return new RessourceNotFoundError(response);
        }

        // Resource locked
        if (status === 423) {
            return new LockedByException(response);
        }

        // Too many requests
        if (status === 429) {
            return new TooManyRequestsError(response);
        }

        return new BadRequestError(response);
    }

    /*
     * Server errors (5xx)
     */
    if (isServerRequestErrorStatus(status)) {

        // Maintenance mode
        if (status === 503) {
            return new MaintenanceModeError(response);
        }

        return new ServerError(response);
    }

    return response;
}

/**
 * Handles the given GraphQL error
 *
 * @param error
 * @param response
 * @returns {undefined}
 */
function getGraphQlError(error, response) {

    // Check if error has an error code
    const errorCode = error && error.extensions && error.extensions.error;
    if (errorCode) {
        const exception = getExceptionForErrorCode(errorCode);
        if (exception) {
            return exception;
        }
    }

    return new GraphQLError(error, response);
}

function getFirstGraphQlResultWithErrors(jsonResponse, isBatch) {
    if (!jsonResponse) {
        return undefined;
    }

    let base = undefined;
    if (!isBatch) {
        if (jsonResponse.hasOwnProperty('errors') >= 0 && !_.isEmpty(jsonResponse.errors)) {
            base = jsonResponse;
        }
    } else {
        if (Array.isArray(jsonResponse)) {
            base = jsonResponse.find(result => result.hasOwnProperty('errors') >= 0 && !_.isEmpty(result.errors));
        }
    }

    return base;
}

function hasGraphQlErrors(jsonResponse, isBatch) {
    return !!getFirstGraphQlResultWithErrors(jsonResponse, isBatch);
}

/**
 * Handles the basic GraphQL API errors
 *
 * @param jsonResponse
 * @param isBatch
 * @param response
 */
function getFirstGraphQlError(jsonResponse, isBatch, response) {
    const firstResultWithErrors = getFirstGraphQlResultWithErrors(jsonResponse, isBatch);
    if (!firstResultWithErrors) {
        return undefined;
    }

    const firstError = firstResultWithErrors['errors'][0];
    return getGraphQlError(firstError, response);
}

function getRequestBody(body) {

    if (body instanceof FormData) {
        return body;
    }

    return JSON.stringify(body);
}

function getRequestParameters(path, options, isUpload) {

    let requestOptions = {
        method: options.method || 'GET',
        headers: {
            'Accept': options.accept || 'application/json',
            'Content-Type': options.contentType || 'application/json'
        }
    };

    if (options.token) {
        requestOptions.headers.Authorization = `Bearer ${options.token}`;
    }

    if (options.includeCredentials) {
        requestOptions.credentials = 'include';
    }

    if (options.contentType === false) {
        delete requestOptions.headers['Content-Type'];
    }

    if (isUpload) {
        requestOptions = {
            ...requestOptions,
            url: getRequestUrl(path)
        };

        if (options.body) {
            requestOptions.data = getRequestBody(options.body);
        }

        if (options.progressFunction) {
            requestOptions.onUploadProgress = options.progressFunction;
        }
    } else {
        if (options.body) {
            requestOptions.body = getRequestBody(options.body);
        }
    }

    return requestOptions;

}

function getRequestUrl(path) {
    return `${process.env.REACT_APP_API_URL}/${path}`;
}

function fetchData(path, options = {}, isUpload = false) {

    const _options = getRequestParameters(path, options, isUpload);

    // handle file uploads w/ axios. Axios allows for indication of upload progress.
    if (isUpload) {
        return axios(_options)
            .catch((error) => {

                if (!error.isAxiosError || !error.response) {
                    return Promise.reject(error);
                }

                const { response } = error;
                return Promise.reject(response.data);

            });
    }

    const requestTimeout = options.timeout || 30; // 30s

    const requestUrl = getRequestUrl(path);

    return timeout(requestTimeout * 1000, fetch(requestUrl, _options));

}

function isClientOutdatedError(error, isUpload) {

    if (!(error instanceof BadRequestError) || !error) {
        return Promise.resolve(false);
    }

    if (isUpload) {
        const errorCode = error.response;
        return Promise.resolve(getErrorId(errorCode) === 'client_outdated');
    }

    return error.getData()
        .then((errorData) => {
            return getErrorId(errorData) === 'client_outdated';
        });
}

/**
 * fetch api
 */
function callApi(path, options = {}, isUpload = false) {

    return fetchData(path, options, isUpload)

        .then((response) => {

            // A successful axios return does not contain "ok"
            if (response.ok !== undefined && !response.ok) {
                throw response;
            }

            return response;
        })
        .catch(response => {

            const { status } = response;

            if (status === 403 /* Forbidden */) {

                return getForbiddenError(response);

            } else if (status >= 400 /* Client or server error */) {

                const error = getHttpStatusError(status, response);

                if (errorForcesLogout(error)) {
                    Store.dispatch(logout());
                }

                return isClientOutdatedError(error, isUpload)
                    .then((isOutdated) => {
                        return Promise.reject(isOutdated ? new ClientOutdatedError(response) : error);
                    });
            }
            return handleUnknownError(response);

        });
}

const handleUnknownError = response => (Promise.reject(new ServerError(response)));


export default callApi;

export const callGraphQlApi = (body, token) => {

    const isBatch = Array.isArray(body);

    return callApi('v1/graphql', {
        method: 'POST',
        token,
        body
    })
        .then(response => response.json()
            .then((jsonResponse) => {

                if (hasGraphQlErrors(jsonResponse, isBatch)) {

                    const error = getFirstGraphQlError(jsonResponse, isBatch, response);

                    if (errorForcesLogout(error)) {
                        Store.dispatch(logout());
                    }

                    return Promise.reject(getFirstGraphQlError(jsonResponse, isBatch, response));
                }
                return jsonResponse;
            }));
};

/**
 * GraphQl Query
 */
export function query(queryBody, options = {}) {

    const requestBody = {
        query: `query ${queryBody}`
    };

    if (options.params) {
        requestBody.params = options.params;
    }

    return callGraphQlApi(requestBody, options.token)
        .then(result => result.data);
}

export function mutate(mutationQuery, params, options = {}) {

    const fullMutationQuery = `mutation ${mutationQuery}`;

    const requestBody = {
        query: fullMutationQuery.replace(/[\n\s]+/g, ' ')
    };

    if (params) {
        requestBody.params = params;
    }

    return callGraphQlApi(requestBody, options.token)
        .then(result => result.data);
}

/**
 * GraphQl Query for single entity
 */
export const querySingle = (queryFragment, options = {}, type) => (
    query(queryFragment, options)
        .then((data) => {
            if (!Object.prototype.hasOwnProperty.call(data, type)) {
                return null;
            }
            return data[type];
        })
);

/**
 * Upload media
 */
export function uploadMedia(path, options = {}) {

    return callApi(path, {
        method: 'POST',
        contentType: false,
        ...options
    }, true);
}


/**
 * Generate error messages
 */
export function generateErrorMessages(errorDetails = null) {

    // validation errors
    if (errorDetails && errorDetails.status === 400) {
        const errorMessages = errorDetails.payload.map((item) => {
            if (item.error === ErrorTypes.MIMES) {
                return errorMessages.mimeError(item.payload.join(', '));
            }

            if (item.error === ErrorTypes.MAX) {
                return errorMessages.sizeError(item.payload);
            }
            return false;
        });
        return errorMessages;
    }

    // Unauthorized
    if (errorDetails && errorDetails.status === 401) {
        // invalid credentials
        if (errorDetails.error === ErrorTypes.INVALID_CREDENTIALS) {
            return [ErrorMessages.invalidCredentials()];
        }

        if (errorDetails.error === ErrorTypes.UNAUTHORIZED) {
            return [ErrorMessages.authorizationError()];
        }

        if (errorDetails.error === ErrorTypes.INVALID_REFRESH_TOKEN) {
            return [ErrorMessages.invalidRefreshTokenError()];
        }
    }

    // Server Error
    if (errorDetails && errorDetails.status === 500) {
        return [ErrorMessages.serverError()];
    }

    return [ErrorMessages.serverError()];
}

/**
 * Requests a new access token based on the refresh token saved as cookie
 */
export function refreshToken(success, failure) {
    callApi('v1/auth/refresh', {
        method: 'POST',
        includeCredentials: true
    })
        .then((response) => {
            if (!response.ok) {
                throw response;
            }
            return response.json();
        })
        .then((data) => {
            success(data);
        })
        .catch((error) => {
            if (typeof error.json === 'function') {
                error.json()
                    .then((errorDetails) => {
                        const message = generateErrorMessages(errorDetails);
                        failure(message);
                    });
            } else {
                const message = generateErrorMessages();
                toast.error(message.join(', '), { autoClose: 2500 });
                failure(message);
            }
        });
}
