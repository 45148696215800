import React from 'react';
import {
    showCladdings,
    showConstructionType,
    showEquippedSurfaces,
    showLoadClass,
    showManagedLabel,
    showPlannedWorks,
    showSpecialFeatures,
    showSupportType,
    showWallClearance,
    showWidthClass
} from '../../../Sections/helpers';
import { getStatusLabel } from '../../../Sections/labels';
import { SectionPurpose } from '../../../Sections/SectionPurpose';
import { SectionStabilityAndLoadSafety } from '../../../Sections/SectionStabilityAndLoadSafety';
import { SectionDiffProperty } from '../properties';
import { DiffValue, DiffValueFallback } from './DiffValue';

export const SectionDiffValue = ({ value, diff }) => {

    const { property } = diff;

    switch (property) {
        case SectionDiffProperty.Managed:
            return showManagedLabel(value, DiffValueFallback);
        case SectionDiffProperty.Status:
            return getStatusLabel(value, DiffValueFallback);
        case SectionDiffProperty.EquippedSurfaces:
            return showEquippedSurfaces(value, DiffValueFallback);
        case SectionDiffProperty.Claddings:
            return showCladdings(value, DiffValueFallback);
        case SectionDiffProperty.SpecialFeatures:
            return showSpecialFeatures(value, DiffValueFallback);
        case SectionDiffProperty.WallClearance:
            return showWallClearance(value, DiffValueFallback);
        case SectionDiffProperty.LoadClass:
            return showLoadClass(value, DiffValueFallback);
        case SectionDiffProperty.WidthClass:
            return showWidthClass(value, DiffValueFallback);
        case SectionDiffProperty.PlannedWorks:
            return showPlannedWorks(value, DiffValueFallback);
        case SectionDiffProperty.Purpose:
            return <SectionPurpose
                purposes={value?.items}
                otherProtectivePurpose={value?.otherProtective}
                fallback={DiffValueFallback} />;
        case SectionDiffProperty.StabilityAndLoadSafety:
            return <SectionStabilityAndLoadSafety
                specialConstruction={value?.specialConstruction}
                staticsCertificateNecessary={value?.staticsCertificateNecessary}
                fallback={DiffValueFallback} />;
        case SectionDiffProperty.SupportType:
            return showSupportType(value, DiffValueFallback);
        case SectionDiffProperty.ConstructionType:
            return showConstructionType(value, DiffValueFallback);
        default:
            return <DiffValue value={value} diff={diff} />;
    }
};
