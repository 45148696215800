import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import BaseDataBreadcrumb from '../../../Components/Account/BaseDataBreadcrumb';
import BaseDataForm from '../../../Components/Account/BaseDataForm';
import TopNav from '../../../Components/Page/TopNav';
import PageTitle from '../../../Components/Page/PageTitle';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const BaseDataEditPage = ({ history, userCompanyId }) => (
    <PrivatePage>
        <Container>
            <TopNav />
            <BaseDataBreadcrumb  />
            <PageTitle
                title="Stammdaten"
            />
            <BaseDataForm
                edit={userCompanyId}
                history={history}
            />
        </Container>
    </PrivatePage>
);

const mapStateToProps = ({ currentUser }) => {
    const userCompanyId = currentUser.usermeta.account.company.id;

    return {
        userCompanyId
    };
};

export default connect(mapStateToProps, null)(BaseDataEditPage);

BaseDataEditPage.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object
};

BaseDataEditPage.defaultProps = {
    history: {},
    match: {}
};
