import PropTypes from 'prop-types';
import React from 'react';

const InspectionType = ({ isAcceptanceInspection }) => {

    const classes = ['inspection-type'];

    const getTypeLabel = () => {

        if (isAcceptanceInspection) {
            classes.push('inspection-type--acceptance-inspection');
            return 'Gerüstfreigabe';
        }

        classes.push('inspection-type--acceptance-inspection');
        return 'Prüfung';

    };

    const getClassNames = classes => (classes.join(' '));

    const label = getTypeLabel();

    return (
        <div className={getClassNames(classes)}>
            <span className="inspection-type__lbl">{label}</span>
        </div>
    );
};

export default InspectionType;

InspectionType.propTypes = {
    isAcceptanceInspection: PropTypes.bool
};

InspectionType.defaultProps = {
    isAcceptanceInspection: false
};
