import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from '../Forms/Select';

class SectionSelect extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            scaffoldingId: ''
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (prevState.scaffoldingId !== nextProps.scaffoldingId) {
            return {
                scaffoldingId: nextProps.scaffoldingId,
                choices: [],
                isFetching: false,
                hasFetched: false
            };
        }

        // nothing changes
        return null;
    }

    onChange(value) {

        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    render() {
        const {
            scaffoldingId
        } = this.state;

        return (
            <Select
                name={this.props.name}
                placeholder={this.props.sectionChoices ? 'Alle' : 'Gerüstabschnitt auswählen'}
                options={this.props.sectionChoices}
                disabled={!scaffoldingId || !this.props.sectionChoices}
                onChange={this.onChange}
            />
        );
    }
}

SectionSelect.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

SectionSelect.defaultProps = {
    onChange: null
};

export default SectionSelect;
