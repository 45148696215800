import PropTypes from 'prop-types';
import React from 'react';
import { RenderMode } from './DiffItem';

const EmptyDiff = ({ renderMode }) => {
    if (renderMode === RenderMode.Table) {
        return (
            <tr className="diff-item diff-item--empty diff-item--row">
                <td colSpan={2}>
                    <div className="diff-item__label">
                        Keine Änderungen
                    </div>
                </td>
            </tr>
        );
    }

    return (
        <div className="diff-item diff-item--empty">
            <div className="diff-item__label">
                Keine Änderungen
            </div>
        </div>
    )
};

EmptyDiff.propTypes = {
    renderMode: PropTypes.oneOf([RenderMode.Table, RenderMode.Default])
};

EmptyDiff.defaultProps = {
    renderMode: RenderMode.Default,
};

export default EmptyDiff;
