import callApi, { query, querySingle } from '../../action';
import * as types from '../../types';
import { showRequestError } from '../error/action';

const inspectionType = 'inspection';

/*
 * List action creators
 */
export const fetchInspectionList = () => ({
    type: types.FETCH_INSPECTION_LIST
});

export const fetchInspectionListSuccess = data => ({
    type: types.FETCH_INSPECTION_LIST_SUCCESS,
    payload: data
});

export const fetchInspectionListFailure = error => ({
    type: types.FETCH_INSPECTION_LIST_FAILURE,
    payload: error
});

export const setSortOption = option => ({
    type: types.SET_INSPECTION_LIST_SORT_OPTION,
    payload: option
});

export const resetSortOptions = () => ({
    type: types.RESET_INSPECTION_LIST_SORT_OPTIONS
});

export const setCurrentPageNumber = page => ({
    type: types.SET_CURRENT_INSPECTION_LIST_PAGE,
    payload: page
});

/*
 * Single action creators
 */
export const fetchInspection = () => ({
    type: types.FETCH_INSPECTION
});

export const fetchInspectionSuccess = data => ({
    type: types.FETCH_INSPECTION_SUCCESS,
    payload: data
});

export const fetchInspectionFailure = error => ({
    type: types.FETCH_INSPECTION_FAILURE,
    payload: error
});

/**
 * Fetch the inspection history for a single section
 *
 * @param sectionId
 * @returns {function(*, *): (Promise<any> | * | void | Promise<T | never>)}
 */
export const fetchInspectionHistory = sectionId => (
    (dispatch, getState) => {
        const state = getState().inspections;
        const args = {
            page: state.currentPage || 1,
            limit: state.amountPerPage || 10,
            sortAsc: state.sort.direction !== '-',
            sortField: state.sort.field || 'date'
        };

        const token = getState().currentUser.auth_token;

        const gqlQuery = `
            FetchInspections($page:Int,$limit:Int) {
                inspections(limit:$limit,page:$page,sort:${args.sortField},sortAsc:${args.sortAsc},sectionId:"${sectionId}" ) {
                    data {
                        ... on Inspection {
                            id,
                            isAcceptanceInspection,
                            createdByName,
                            isThirdParty,
                            completed,
                            createdBy {
                                firstName,
                                lastName,
                                account {
                                    company{name},
                                },
                            },
                            saved,
                            success,
                            answers{success}
                        }
                        ... on ThirdPartyInspection {
                            id,
                            isAcceptanceInspection,
                            createdByName,
                            isThirdParty,
                            completed,
                            createdBy {
                                firstName,
                                lastName,
                                account {
                                    company{name},
                                },
                            },
                            saved,
                            success,
                            answers{success, input},
                            isAnonymous
                        }
                    },
                    total,
                    per_page
                }
            }`;

        dispatch(fetchInspectionList());

        return query(gqlQuery, {
            token,
            params: {
                page: args.page,
                limit: args.limit
            }
        })
            .then((result) => {
                dispatch(fetchInspectionListSuccess(result.inspections));
            })
            .catch((error) => {
                dispatch(fetchInspectionListFailure(error));
            });
    }
);

/**
 * Fetch a single inspection including all details
 *
 * @param inspectionId
 * @returns {function(*, *): (Promise<any> | * | void | Promise<T | never>)}
 */
export const fetchInspectionDetail = inspectionId => (

    (dispatch, getState) => {
        dispatch(fetchInspection());
        const token = getState().currentUser.auth_token;

        const gqlQuery = `Inspection{inspection(id:"${inspectionId}"){
            ... on Inspection {
                id,
                acceptanceInspection{createdByName},
                isAcceptanceInspection,
                createdByName,
                success,
                answers{ name, success, input, images{id, name, fileName}, notes{id,content}},
                images{id,name,fileName},
                notes{id, content},
                completed,
                saved,
                createdBy {
                    id,
                    firstName,
                    lastName,
                    email,
                    account {
                        company{name},
                    },
                },
                section{
                    ... on Section {
                        id,name,shortId,isThirdParty,managed,approvedByName,archived,
                        contractor {name, addressName,addressLine1,addressLine2,addressCity,addressZip},
                        scaffolding {
                            id,name,isThirdParty,managed,archived,
                            site {
                                id,name,isThirdParty,archived,
                                address {
                                    id,name,line1,line2,zip,city,country
                                }
                            }
                        },
                        customer
                    },
                    ... on ThirdPartySection {
                        id,name,shortId,isThirdParty,approvedByName,isInvited,archived
                        contractor {name, addressName,addressLine1,addressLine2,addressCity,addressZip},
                        scaffolding {
                            id,name,isThirdParty,isInvited,archived
                            site {
                                id,isThirdParty,isInvited,archived
                                address {
                                    id,line1,line2,zip,city,country
                                }
                            }
                        },
                        customer
                    },
                },
                isThirdParty
            }
            ... on ThirdPartyInspection {
                id,
                success,
                acceptanceInspection{createdByName},
                isAcceptanceInspection,
                createdByName,
                answers{ name, success, input, images{id, name, fileName, isAnonymous}, notes{id, content, isAnonymous}},
                images{id,name,fileName, isAnonymous},
                notes{id, content, isAnonymous},
                completed,
                saved,
                isAnonymous,
                createdBy { 
                    id,
                    firstName,
                    lastName,
                    account {
                        company{name},
                    },
                },
                section{
                    ... on Section {
                        id,name,shortId,isThirdParty,managed,approvedByName,archived
                        contractor {name, addressName,addressLine1,addressLine2,addressCity,addressZip},
                        scaffolding {
                            id,name,isThirdParty,managed,archived,
                            site {
                                id,name,isThirdParty,archived,
                                address {
                                    id,name,line1,line2,zip,city,country
                                }
                            }
                        },
                        customer
                    },
                    ... on ThirdPartySection {
                        id,name,shortId,isThirdParty,approvedByName,isInvited,
                        contractor {name, addressName,addressLine1,addressLine2,addressCity,addressZip},
                        scaffolding {
                            id,name,isThirdParty,isInvited
                            site {
                                id,isThirdParty,isInvited
                                address {
                                    id,line1,line2,zip,city,country
                                }
                            }
                        },
                        customer
                    },
                },
                isThirdParty
            }
        }}`;

        return querySingle(gqlQuery, {
            token
        }, inspectionType)
            .then((inspection) => {
                dispatch(fetchInspectionSuccess(inspection));
                return inspection;
            })
            .catch((error) => {
                dispatch(fetchInspectionFailure(error));
                showRequestError('Prüfungsdaten konnten nicht geladen werden', error);
                return null;
            });
    }
);

export const generateInspectionProtocol = inspectionId => (

    (dispatch, getState) => {

        const token = getState().currentUser.auth_token;
        const path = `v1/inspections/${inspectionId}/protocol`;

        return callApi(path, {
            method: 'GET',
            token
        })
            .then(response => response.json());
    }
);
