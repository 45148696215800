import moment from 'moment';
import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'react-final-form';
import Icon from '../Common/Icon';
import FieldError from './FieldError';

const ReactDatePickerAdapter = ({ input, meta, placeholder, ...pickerOptions }) => {
    let selected = null;
    if (input.value) {
        try {
            selected = moment(input.value);
        } catch (e) {
        }
    }

    return (
        <Fragment>
            <div className="datepicker">
                <span className="datepicker__icon"> <Icon name="calendarAlt" /> </span>
                <DatePicker
                    onChange={input.onChange}
                    selected={selected}
                    placeholderText={placeholder}
                    {...pickerOptions}
                />
            </div>
            <FieldError meta={meta} />
        </Fragment>
    );
};

const ScaffeyeDatePicker = ({
                                name,
                                placeholder,
                                ...pickerOptions
                            }) => (
    <Field
        component={ReactDatePickerAdapter}
        name={name}
        placeholder={placeholder}
        {...pickerOptions}
    />
);

export default ScaffeyeDatePicker;
