import React from 'react';
import { components } from 'react-select';
import Icon from '../../../Common/Icon';
import { MediaVisibility } from '../../../Media/properties';
import SelectField from '../../Select/SelectField';

const { SingleValue, Option } = components;

const VisibilityOption = props => (
    <Option {...props}>
        <div className="media-visibility">
            <span className="media-visibility__icn">
                <Icon name={props.data.value === MediaVisibility.Public ? 'unlocked' : 'locked'} />
            </span>
            <span className="media-visibility__lbl">
                {props.data.value === MediaVisibility.Public ? 'Öffentlich' : 'Intern'}
            </span>
        </div>
    </Option>
);

const VisibilityValue = ({ children, ...props }) => (
    <SingleValue {...props}>
        <span className="media-visibility__icn">
            <Icon name={props.data.value === MediaVisibility.Public ? 'unlocked' : 'locked'} />
        </span>
        <span className="media-visibility__lbl">
            {props.data.value === MediaVisibility.Public ? 'Öffentlich' : 'Intern'}
        </span>
    </SingleValue>
);

const MediaVisibilitySelectField = ({ ...fieldProps }) => (
    <SelectField
        {...fieldProps}
        placeholder="Sichtbarkeit wählen"
        label="Sichtbarkeit"
        isSearchable={false}
        components={{
            Option: VisibilityOption,
            SingleValue: VisibilityValue
        }}
    />
);

export default MediaVisibilitySelectField;
