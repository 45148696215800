import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import { ResourceListDisplayType } from '../../../properties';
import { UserType } from '../../../user';
import FilterBar from '../../Common/Filterbar/Filterbar';
import FilterBarRow from '../../Common/Filterbar/FilterbarRow';
import IfUser from '../../Common/IfUser';
import { SitesListViewOption, SitesListViewSelectOptions } from '../../Sites/List/SitesList';
import CategoryAutocomplete from '../CategoryAutocomplete';
import DateFilter from '../DateFilter';
import ScaffoldingAutocomplete from '../ScaffoldingAutocomplete';
import SectionAutocomplete from '../SectionAutocomplete';
import SiteAutocomplete from '../SiteAutocomplete';

export const SELECTION_TYPES = {
    site: 'site',
    scaffolding: 'scaffolding',
    section: 'section',
    category: 'category',
    date: 'date',
    initialState: 'initialState',
    listViewOption: 'listViewOption'
};

class ConstructionDiaryFilterbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSite: '',
            scaffoldingSelectDisabled: true,
            sectionSelectDisabled: true
        };

        this.onSitesViewOptionChange = this.onSitesViewOptionChange.bind(this);
    }

    onSitesViewOptionChange(selectedViewOption) {

        const { sitesViewOption: currentSitesViewOption } = this.state;

        if ( !selectedViewOption || currentSitesViewOption === selectedViewOption ) {
            return;
        }

        this.deselectSite();

        this.setState({
            sitesViewOption: selectedViewOption
        });

        this.props.onChange(SELECTION_TYPES.listViewOption, selectedViewOption);
    }

    deriveFilterStateFromProps(filterState) {

        const scaffoldingSelectDisabled = !filterState.siteId;
        const sectionSelectDisabled = !filterState.scaffoldingId;

        const sitesViewOption = filterState.archived ? SitesListViewSelectOptions[1] : SitesListViewSelectOptions[0];

        this.setState({
            selectedSite: filterState.siteId,
            selectedScaffolding: filterState.scaffoldingId,
            selectedSection: filterState.sectionId,
            selectedCategory: filterState.category,
            selectedDateFrom: filterState.dateFrom,
            selectedDateTo: filterState.dateTo,

            sitesViewOption,

            scaffoldingSelectDisabled,
            sectionSelectDisabled
        });
    }

    componentWillMount() {
        const { initialFilterState } = this.props;

        if ( initialFilterState && initialFilterState.siteId !== undefined ) {
            this.deriveFilterStateFromProps(initialFilterState);
        }
    }

    resetFilterState() {
        this.setState({
            selectedSite: '',
            selectedScaffolding: '',
            selectedSection: '',
            scaffoldingSelectDisabled: true,
            sectionSelectDisabled: true
        });
    }

    deselectSite() {
        this.resetFilterState();
        this.props.onChange(SELECTION_TYPES.site, null);
    }

    onSiteSelectionChange(selection) {

        if ( !selection ) {
            this.deselectSite();
            return;
        }

        const { id } = selection;

        this.setState({
            selectedSite: id,
            selectedScaffolding: '',
            scaffoldingSelectDisabled: false,
            sectionSelectDisabled: true
        });
        this.props.onChange(SELECTION_TYPES.site, selection);
    }

    onScaffoldingSelectionChange(selection) {
        if ( !selection ) {
            this.setState({
                selectedScaffolding: '',
                selectedSection: '',
                sectionSelectDisabled: true
            });
            this.props.onChange(SELECTION_TYPES.scaffolding, selection);
            return;
        }

        const { id } = selection;

        this.setState({
            selectedScaffolding: id,
            sectionSelectDisabled: false
        });

        this.props.onChange(SELECTION_TYPES.scaffolding, selection);
    }

    onSectionSelectionChange(selection) {
        this.props.onChange(SELECTION_TYPES.section, selection);
    }

    onCategorySelectionChange(selection) {
        this.props.onChange(SELECTION_TYPES.category, selection);
    }

    onDateSelection(selection) {
        this.props.onChange(SELECTION_TYPES.date, selection);
    }

    render() {
        const {
            selectedSite,
            selectedCategory,
            scaffoldingSelectDisabled,
            sectionSelectDisabled,
            selectedScaffolding,
            selectedSection,
            selectedDateFrom,
            selectedDateTo,
            sitesViewOption
        } = this.state;

        return (
            <div className="logs-table">
                <FilterBar>
                    <IfUser
                        isOneOfType={[ UserType.ProAdministrator, UserType.ProStandard ]}
                    >
                        <FilterBarRow
                            title="Ansicht"
                        >
                            <Select
                                className="filterbar__archive-select"
                                classNamePrefix="filterbar-element"
                                isSearchable={false}
                                options={SitesListViewSelectOptions}
                                value={sitesViewOption}
                                onChange={this.onSitesViewOptionChange}
                            />
                        </FilterBarRow>
                    </IfUser>

                    <FilterBarRow
                        title="Filter"
                    >

                        <DateFilter
                            label="Zeitraum"
                            onSelectionChange={dateSelection => this.onDateSelection(dateSelection)}
                            initialValueFrom={selectedDateFrom}
                            initialValueTo={selectedDateTo}
                        />

                        <CategoryAutocomplete
                            onSelectionChange={categorySelection => this.onCategorySelectionChange(categorySelection)}
                            initialValue={selectedCategory}
                        />

                        <SiteAutocomplete
                            onSelectionChange={siteSelection => this.onSiteSelectionChange(siteSelection)}
                            initialValue={selectedSite}
                            show={sitesViewOption.value === SitesListViewOption.ShowArchivedSites ? ResourceListDisplayType.Archived : ResourceListDisplayType.Active}
                            key={JSON.stringify(sitesViewOption)}
                        />

                        <ScaffoldingAutocomplete
                            disabled={scaffoldingSelectDisabled}
                            siteId={selectedSite}
                            initialValue={selectedScaffolding}
                            onSelectionChange={scaffoldingSelection => this.onScaffoldingSelectionChange(scaffoldingSelection)}
                            key={selectedSite}
                            managed
                        />

                        <SectionAutocomplete
                            disabled={sectionSelectDisabled}
                            scaffoldingId={selectedScaffolding}
                            initialValue={selectedSection}
                            onSelectionChange={sectionSelection => this.onSectionSelectionChange(sectionSelection)}
                            managed
                        />

                    </FilterBarRow>
                </FilterBar>
            </div>
        );
    }

}

export default ConstructionDiaryFilterbar;

ConstructionDiaryFilterbar.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialFilterState: PropTypes.object
};

