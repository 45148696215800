import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { bracketLabel, getSpecialFeatureLabel } from '../../../../Sections/labels';
import { Bracket, SpecialFeature } from '../../../../Sections/properties';
import asSyncChoiceField from '../../asSyncChoiceField';
import SyncChoiceIdenticalRow from '../../Base/Input/SyncChoiceIdenticalRow';
import SyncChoiceDiffOptionRows from '../../Base/Input/SyncChoiceDiffOptionRows';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';
import { validateListChoiceComplete } from '../../validation';
import { isIdenticalValue } from '../../equals';
import { getDiffSpecialFeatureOptionRows, getIdenticalSpecialFeatureOptionRows } from './functions';

const validateSpecialFeatures = validateListChoiceComplete();

const SpecialFeaturesSyncChoice = ({ value: currentFormValue, planValue, liveValue, onChange, showIdentical }) => {

    const onSelect = (selectedValue) => {
        const newValue = {
            ...currentFormValue,
            ...selectedValue
        };

        onChange(newValue);
    };

    const getBracketLabel = (bracketObject) => {

        if(!bracketObject){
            return null;
        }

        let bracketOutput = Object.keys(bracketObject)
            .map(bracketKey => {
                if (!bracketObject[bracketKey] || bracketKey === Bracket.Other) {
                    return null;
                }
                return bracketLabel(bracketKey);
            })
            .filter(bracket => bracket);

        if (bracketObject.hasOwnProperty(Bracket.Other)) {
            bracketOutput.push(`Andere Konsole: ${bracketObject[Bracket.Other]}`);
        }

        return `Konsole: ${bracketOutput.join(', ')}`;
    };

    const renderSpecialFeatureLabel = (valueObject) => {

        if (!valueObject) {
            return '-';
        }

        const valueKey = Object.keys(valueObject)[0];

        // Consoles/Bracket
        if (valueKey === SpecialFeature.Bracket) {

            if (!valueObject[valueKey]) {
                return '-';
            }
            return getBracketLabel(valueObject[valueKey]);
        }

        // Not set
        if (valueObject[valueKey] === false) {
            return '-';
        }

        return getSpecialFeatureLabel(Object.keys(valueObject)[0]);
    };

    const isSelected = (value) => {
        if (!currentFormValue || !value) {
            return false;
        }

        const key = value ? Object.keys(value)[0] : undefined;
        if (!key) {
            return false;
        }

        return currentFormValue[key] === value[key];
    };

    const renderIdenticalRows = () => {

        if (_.isEmpty(planValue) && _.isEmpty(liveValue)) {
            return (
                <SyncChoiceIdenticalRow
                    key="special-features"
                    leftValue={'-'}
                    rightValue={'-'}
                    renderValue={a => a}
                    showIdentical={showIdentical}
                />
            );
        }

        const identicalOptionRows = getIdenticalSpecialFeatureOptionRows(planValue, liveValue);
        if (_.isEmpty(identicalOptionRows)) {
            return null;
        }

        return identicalOptionRows.map(optionRow => (
            <SyncChoiceIdenticalRow
                key={optionRow.key}
                leftValue={optionRow.left}
                rightValue={optionRow.right}
                renderValue={renderSpecialFeatureLabel}
            />
        ));
    };

    const isIdentical = isIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Besonderheiten"
        >
            {showIdentical && renderIdenticalRows()}

            <SyncChoiceDiffOptionRows
                planValue={planValue}
                liveValue={liveValue}
                getOptionRows={getDiffSpecialFeatureOptionRows}
                renderValue={renderSpecialFeatureLabel}
                onSelect={onSelect}
                isSelected={isSelected}
            />
        </SyncInputGroup>
    );
};

SpecialFeaturesSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    showIdentical: PropTypes.bool
};

export default asSyncChoiceField(SpecialFeaturesSyncChoice, {
    allowNull: true,
    validate: validateSpecialFeatures
});
