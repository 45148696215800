import PropTypes from 'prop-types';
import React from 'react';

const PhoneToLink = ({
    number, ...props
}) => (
    <a href={`tel:${number}`}>{props.children}</a>
);

export default PhoneToLink;

PhoneToLink.propTypes = {
    number: PropTypes.string.isRequired
};
