import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment/moment';
import DownLoadLink from '../../Common/Downloadlink';
import Modal from '../../Common/Modal/Modal';
import Table from '../../Common/Table';
import EmptyTableHint from '../../Common/EmptyTableHint';

class PostingsModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            postings: this.props.postings
        };
    }

    render() {
        const {
            postings
        } = this.state;

        return (
            <Modal
                title="Aushanghistorie"
                onBackdropClick={() => this.props.closeModal()}
                footer={
                    <div className="btn-group">
                        <a
                            className="btn btn--backward-action"
                            onClick={this.props.closeModal}
                        >
                            Schließen
                        </a>
                    </div>
                }
            >
                <Table
                    columns={['Generiert am', 'Download']}
                    rows={postings}
                    emptyComponent={
                        <EmptyTableHint
                            numColumns={3}
                            title="Es wurden noch keine Baustellenaushänge hinterlegt"
                        />
                    }
                >
                    {
                        posting => (
                            <tr key={posting.id}>
                                <td>
                                    {`${moment(posting.created)
                                        .format('DD.MM.YYYY, HH:mm')} Uhr`}
                                </td>
                                <td>
                                    <DownLoadLink attachment={posting} />
                                </td>
                            </tr>
                        )
                    }
                </Table>
            </Modal>
        );
    }

}

PostingsModal.propTypes = {
    postings: PropTypes.array.isRequired
};

export default PostingsModal;
