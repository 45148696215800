import { ApiError } from './ApiError';

export const GraphQlErrorCodes = {
    SiteArchiveBlockedBySection: 'site_archive_blocked_by_section',
    SiteAlreadyArchived: 'site_already_archived',
    SiteAlreadyActive: 'site_already_active',
    SiteNotFound: 'site_not_found',
    SiteInvitationAlreadyAccepted: 'invitation_already_accepted',
    SiteInvitationNotFound: 'not_found',
    ResendingSiteInvitationFailed: 'site_invitation_email_transport_error',
    JobAlreadyArchived: 'job_already_archived',
    JobAlreadyActive: 'job_already_active',
    JobLockedByConstructionPlan: 'locked_by_construction_plan'
};

export default class GraphQLError extends ApiError {

    constructor(errorJson, response) {
        super('GraphQLError', response);
        this.errorJson = errorJson;
        this.response = response;
        this.errorMessage = errorJson && errorJson.message;
        this.errorCategory = errorJson && errorJson.extensions && errorJson.extensions.category;
        this.errorCode = errorJson && errorJson.extensions && errorJson.extensions.error;
    }

    is(errorCode) {
        return errorCode === this.errorCode;
    }
}
