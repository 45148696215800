import React from 'react';
import TopNav from '../../../Components/Page/TopNav';
import SitesList from '../../../Components/Sites/List/SitesList';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const SitesPage = () => {
    return (
        <PrivatePage requiredUserPermission={Permission.ReadSites}>
            <Container>
                <TopNav />
                <SitesList />
            </Container>
        </PrivatePage>
    );
};

export default SitesPage;
