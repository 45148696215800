import createDecorator from 'final-form-focus';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../../../redux/modules/error/action';
import { fetchMediaData } from '../../../../../redux/modules/media/action';
import {
    fetchConstructionPlanSectionFormData,
    fetchConstructionPlanSections
} from '../../../../../redux/modules/section/action';
import Modal from '../../../../Common/Modal/Modal';
import ModalFormFooter from '../../../../Common/ModalFormFooter';
import { collectMediaIds } from '../../../../Forms/Media/utils';
import LoadingSpinner from '../../../../Page/LoadingSpinner';
import { getFormValues, getSubmitPayload } from '../../../../Sections/Form/functions';
import { getAllInputs } from '../../../../Sections/Form/helpers';
import { validate, VALIDATION_MODE_PLAN } from '../../../../Sections/Form/validate';
import { ConstructionPlanModelMode, ConstructionPlanReferenceMode } from '../../../properties';
import { getMediaCollectionData, getMediaItemData } from '../../utils';
import ConstructionPlanSectionFormContent from './ConstructionPlanSectionFormContent';

const focusOnError = createDecorator(getAllInputs);

const initialFormValues = {
    id: undefined,
    mode: ConstructionPlanModelMode.Reference,
    selection: ConstructionPlanReferenceMode.Single,
    name: '',
    description: '',
    specialConstruction: false,
    attachments: [],
    utilizationPlans: [],
    anchorProtocol: [],
    proofOfStability: []
};

class ConstructionPlanSectionFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            section: null,
            referenceData: undefined,
            initialFormValues: {
                ...initialFormValues
            },
            isUploading: false,
            isSubmitting: false,
            isInitializing: true,
            isInitializingSectionReference: false,
            loadingGotCancelled: false
        };

        this.initializeForm = this.initializeForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.onUploading = this.onUploading.bind(this);
        this.onSectionChanged = this.onSectionChanged.bind(this);
    }

    componentWillMount() {
        const { section } = this.props;
        const { mode } = section;

        switch (mode) {
            case ConstructionPlanModelMode.Reference:
                this.initializeSectionReferenceForm(section.id);
                break;
            case ConstructionPlanModelMode.Edit:
                this.initializeSectionEditForm(section);
                break;
            case ConstructionPlanModelMode.New:
                this.initializeSectionNewForm(section);
                break;
            default:
                this.initializeForm();
                break;
        }
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    /**
     * Initializes the form based on a site reference
     * @param sectionId
     * @param callback
     */
    initializeSectionReferenceForm(sectionId, callback = undefined) {
        this.setState({
            isInitializingSectionReference: true
        });
        this.props.actions.fetchConstructionPlanSectionFormData(sectionId)
            .then(section => {
                this.setState({
                    referenceData: section,
                    isInitializingSectionReference: false
                }, () => {
                    this.initializeForm({
                        ...section
                    }, ConstructionPlanModelMode.Reference, callback);
                });
            })
            .catch(err => {
                showRequestError('Gerüstabschnittdaten konnten nicht geladen werden', err);
            });
    }

    /**
     * Initializes the form for the given site in 'edit' mode
     * @param section
     */
    initializeSectionEditForm(section) {
        this.props.actions.fetchConstructionPlanSectionFormData(section.id)
            .then(liveSection => {
                this.setState({
                    referenceData: liveSection
                }, () => {
                    this.initializeNewOrEditedSectionFormWithLiveMedia(section, ConstructionPlanModelMode.Edit);
                });
            });
    }

    /**
     * Initializes the form for the given site in 'new' mode
     * @param section
     */
    initializeSectionNewForm(section) {
        this.initializeNewOrEditedSectionFormWithLiveMedia(section, ConstructionPlanModelMode.New);
    }

    /**
     * Initializes the site form with the given site but fetches media data first (which
     * is not present in the construction plan)
     *
     * @param section
     * @param mode
     */
    initializeNewOrEditedSectionFormWithLiveMedia(section, mode) {

        const sectionData = {
            id: section.id,
            mode: section.mode,
            ...section.data
        };

        const { attachments, utilizationPlans, anchorProtocol, proofOfStability } = sectionData;
        const attachmentIds = collectMediaIds(attachments);
        const utilizationPlanIds = collectMediaIds(utilizationPlans);
        const anchorProtocolId = anchorProtocol ? anchorProtocol.id : null;
        const proofOfStabilityId = proofOfStability ? proofOfStability.id : null;

        const allMediaIds = [...attachmentIds, ...utilizationPlanIds];
        if (anchorProtocolId) {
            allMediaIds.push(anchorProtocolId);
        }
        if (proofOfStabilityId) {
            allMediaIds.push(proofOfStabilityId);
        }

        if (allMediaIds.length !== 0) {
            this.props.actions.fetchMediaData(allMediaIds)
                .then(fetchedMedia => {

                    const attachmentData = getMediaCollectionData(attachments, fetchedMedia, mode);
                    const utilizationPlanData = getMediaCollectionData(utilizationPlans, fetchedMedia, mode);

                    let anchorProtocolData = null;
                    if (anchorProtocolId) {
                        anchorProtocolData = getMediaItemData(anchorProtocol, fetchedMedia, mode);
                    }

                    let proofOfStabilityData = null;
                    if (proofOfStabilityId) {
                        proofOfStabilityData = getMediaItemData(proofOfStability, fetchedMedia, mode);
                    }

                    this.initializeForm({
                        ...sectionData,
                        attachments: attachmentData,
                        utilizationPlans: utilizationPlanData,
                        anchorProtocol: anchorProtocolData,
                        proofOfStability: proofOfStabilityData
                    }, mode);
                });
        } else {
            this.initializeForm(sectionData, mode);
        }
    }

    initializeForm(sectionData, mode, callback) {
        // Special case: the user wants to add a new reference or reference all (mode: undefined)
        if (!mode || !sectionData) {
            const selection = this.getOtherReferencedSectionIds().length > 0
                ? ConstructionPlanReferenceMode.Single
                : ConstructionPlanReferenceMode.All;

            this.setState({
                referenceData: null,
                initialFormValues: {
                    ...initialFormValues,
                    selection
                },
                isInitializing: false
            }, callback);
            return;
        }

        const formValues = getFormValues(sectionData, mode);

        this.setState({
            initialFormValues: { ...formValues },
            isInitializing: false
        }, callback);
    }

    getModalTitle() {
        const { section } = this.props;

        if (!section.mode) {
            return 'Bestehenden Gerüstabschnitt wählen';
        }

        if (section.mode === ConstructionPlanModelMode.Edit) {
            return 'Bestehenden Gerüstabschnitt anpassen';
        } else if (section.mode === ConstructionPlanModelMode.Reference) {
            return 'Bestehenden Gerüstabschnitt wählen';
        }

        if (section.mode === ConstructionPlanModelMode.New) {
            if (!section.data || _.isEmpty(section.data)) {
                return 'Neuen Gerüstabschnitt erstellen';
            }
            return 'Gerüstabschnitt bearbeiten';
        }
    }

    getModalSubtitle() {
        const { section } = this.props;

        if (section.mode) {
            return null;
        }

        return 'Bitte geben Sie an, ob Sie alle bestehenden Gerüstabschnitte dieses Gerüstes oder nur einen bestimmten Gerüstabschnitt zuweisen möchten.';
    }

    isModelModeNew() {
        return this.props.section.mode === ConstructionPlanModelMode.New;
    }

    resetSection(reset) {
        this.setState({
            isInitializing: true,
            referenceData: undefined
        }, () => {
            this.initializeForm(null, null, reset);
        });
    }

    onSubmit(values) {
        this.setState({
            isSubmitting: true
        });

        if (values.selection === ConstructionPlanReferenceMode.All) {
            this.onReferenceAllSubmit();
            return;
        }

        const { contractorId } = this.props;

        const _values = {
            ...values,
            contractorId
        };

        const payload = getSubmitPayload(_values, true);
        this.props.onChange(payload);
        this.setState({
            isSubmitting: false
        });
    }

    onReferenceAllSubmit() {
        const { scaffoldingId, actions: { fetchConstructionPlanSections } } = this.props;
        return fetchConstructionPlanSections(scaffoldingId)
            .then(sections => sections.map(section => ({
                id: section.id,
                mode: ConstructionPlanModelMode.Reference
            })))
            .then(sections => this.props.onReferenceAllSections(sections, this.props.section))
            .catch((error) => {
                this.setState({
                    isSubmitting: false
                });
                showRequestError('Gerüstabschnitte konnte nicht geladen werden', error);
            });
    }

    onSectionChanged(newSectionId, reset) {
        this.initializeSectionReferenceForm(newSectionId, reset);
    }

    onModeChange(newMode, reset, setMode) {
        const { isInitializing } = this.state;
        const { section } = this.props;

        if (newMode === ConstructionPlanModelMode.Reference && !isInitializing) {
            if (window.confirm('Möchten Sie alle Anpassungen des Gerüstabschnitts verwerfen?')) {
                //edit
                if (section.id) {
                    this.onSectionChanged(section.id);
                    return;
                }
                reset();
            } else {
                setMode(ConstructionPlanModelMode.Edit);
            }
        }
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    onUploading(isUploading) {
        this.setState({
            isUploading
        });
    }

    getOtherReferencedSectionIds() {
        const { referencedSectionIds } = this.props;
        if (!referencedSectionIds || referencedSectionIds.length === 0) {
            return [];
        }

        const { section } = this.props;
        if (!section || !section.id) {
            return referencedSectionIds;
        }

        return _.difference(referencedSectionIds, [section.id]);
    }

    renderButtonText(values) {
        if(this.isModelModeNew()) {
            return 'Gerüstabschnitt erstellen';
        }

        if(values?.mode === ConstructionPlanModelMode.Reference) {
            return 'Gerüstabschnitt auswählen';
        }

        return 'Gerüstabschnitt anpassen';
    }

    render() {
        const {
            initialFormValues,
            isInitializing,
            isUploading,
            isSubmitting,
            referenceData,
            isInitializingSectionReference
        } = this.state;

        if (isInitializing) {
            return (
                <Modal
                    title={this.getModalTitle()}
                    subtitle={this.getModalSubtitle()}
                    loading={true}
                    loadingLabel="Lade Gerüstabschnittdaten"
                    temporary
                />
            );
        }

        const { scaffoldingId } = this.props;
        const otherReferencedSectionIds = this.getOtherReferencedSectionIds();
        const hasReferenceSectionsOtherThanThis = otherReferencedSectionIds.length > 0;

        return (
            <Form
                initialValues={initialFormValues}
                validate={(values) => validate(values, VALIDATION_MODE_PLAN)}
                onSubmit={this.onSubmit}
                decorators={[focusOnError]}
                mutators={{
                    resetField: ([name], state, utils) => {
                        utils.changeValue(state, name, () => '');
                    },
                    setField: ([name, value], state, utils) => {
                        utils.changeValue(state, name, () => value);
                    },
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    },
                    setUtilizationPlans: ([newUtilizationPlan], state, utils) => {
                        utils.changeValue(state, 'utilizationPlans', () => newUtilizationPlan);
                    },
                    setAnchorProtocol: ([newAnchorProtocol], state, utils) => {
                        utils.changeValue(state, 'anchorProtocol', () => newAnchorProtocol);
                    },
                    setProofOfStability: ([newProofOfStability], state, utils) => {
                        utils.changeValue(state, 'proofOfStability', () => newProofOfStability);
                    },
                    setMode: ([mode], state, utils) => {
                        utils.changeValue(state, 'mode', () => mode);
                    }
                }}
                render={({ handleSubmit, values, pristine, form: { mutators, reset } }) => {

                    const { selection, id } = values;
                    // if no section was selected => disable form submit
                    const isEmptySectionSelection = (selection === ConstructionPlanReferenceMode.Single) && !id;

                    const subTitle = this.getModalSubtitle();

                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal
                                title={this.getModalTitle()}
                                subtitle={subTitle}
                                collapseHeader={!!subTitle}
                                id="section-modal"
                                classes="construction-plan-section-modal"
                                onBackdropClick={() => this.onClose}
                                autoHeight
                                footer={
                                    <ModalFormFooter
                                        onSubmit={handleSubmit}
                                        onClose={() => this.onClose(pristine)}
                                        submitLabel={this.renderButtonText(values)}
                                        submitDisabled={isUploading || isEmptySectionSelection || isInitializingSectionReference}
                                        hasRequiredFields={true}
                                        isSubmitting={isSubmitting}
                                    />
                                }
                            >
                                <OnChange name="mode">
                                    {(newMode) => this.onModeChange(newMode, reset, mutators.setMode)}
                                </OnChange>

                                <OnChange name="id">
                                    {(newId) => this.onSectionChanged(newId, reset)}
                                </OnChange>

                                {isInitializingSectionReference &&
                                <LoadingSpinner label="Lade Gerüstabschnittdaten" block />}
                                <ConstructionPlanSectionFormContent
                                    scaffoldingId={scaffoldingId}
                                    referenceData={{ ...referenceData }}
                                    values={{ ...values }}
                                    mutators={mutators}
                                    onUploading={this.onUploading}
                                    onResetSection={() => this.resetSection(reset)}
                                    inputDisabled={isUploading}
                                    disableModeAll={hasReferenceSectionsOtherThanThis}
                                    referencedSectionIds={otherReferencedSectionIds}
                                />
                            </Modal>
                        </form>
                    );
                }}
            />
        );
    }
}

ConstructionPlanSectionFormModal.propTypes = {
    scaffoldingId: PropTypes.string,
    section: PropTypes.object.isRequired,
    referencedSectionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReferenceAllSections: PropTypes.func.isRequired
};

const mapStateToProps = ({ currentUser }) => {
    const userAccount = currentUser.usermeta.account || [];
    return {
        contractorId: userAccount.company.id
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionPlanSectionFormData,
        fetchConstructionPlanSections,
        fetchMediaData
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionPlanSectionFormModal);
