import { toast } from 'react-toastify';
import {
    AccountSubscriptionExpiredError,
    ApiError,
    ClientOutdatedError,
    ForbiddenError,
    MaintenanceModeError,
    MissingPermissionError,
    RequestTimeoutError,
    ServerError,
    TooManyRequestsError,
    UnauthorizedError,
    UserSubscriptionExpiredError
} from '../../../Services/ApiError';
import GraphQLError from '../../../Services/GraphQLError';

export function showApiError(apiError, defaultMessage = null) {

    defaultMessage = defaultMessage || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal.';

    let message = false;

    if (apiError instanceof ClientOutdatedError) {
        message = 'Ihre SCAFFEYE-Version ist veraltet. Bitte aktualisieren Sie Ihr Browserfenster';
    } else if (apiError instanceof UnauthorizedError) {
        message = 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an';
    } else if (apiError instanceof RequestTimeoutError) {
        message = 'Die Anfrage konnte vom Server nicht innerhalb der üblichen Zeit beantwortet werden';
    } else if (apiError instanceof TooManyRequestsError) {
        message = 'Ihre Anfrage wurde auf Grund einer hohen Anfragehäufigkeit abgelehnt. Bitte warten Sie einen Moment und tätigen Sie die Aktion erneut';
    } else if (apiError instanceof MaintenanceModeError) {
        message = 'Die Scaffeye-Server werden zur Zeit gewartet. Sie stehen in Kürze wieder zur Verfügung';
    } else if (apiError instanceof ServerError) {
        message = 'Anfrage konnte vom Server nicht beantwortet werden. Bitte versuchen Sie es später noch einmal';
    } else if (apiError instanceof ForbiddenError) {
        message = 'Sie sind nicht befugt diese Aktion auszuführen';
    } else if (apiError instanceof UserSubscriptionExpiredError) {
        message = 'Ihr SCAFFEYE-Abonnement ist abgelaufen. Bitte kontaktieren Sie den Eigentümer Ihres Unternehmenskontos, um ihr Abonnement zu verlängern.';
    } else if (apiError instanceof AccountSubscriptionExpiredError) {
        message = 'Das Abonnement Ihres SCAFFEYE-Unternehmenskontos ist abgelaufen. Bitte kontaktieren Sie den Eigentümer Ihres Unternehmenskontos, um ihr Abonnement zu verlängern.';
    } else if (apiError instanceof MissingPermissionError) {
        message = 'Sie sind zur Zeit nicht berechtigt die Aktion auszuführen. Bitte kontaktieren Sie ihren Konto-Administrator um die Aktion für Sie freizuschalten';
    } else if (apiError instanceof GraphQLError) {
        const messageBase = defaultMessage;
        if (apiError.errorCategory === 'graphql') {
            message = `${messageBase} (Fehler-Code: gql/${apiError.errorMessage})`;
        } else if (apiError.errorCategory === 'api_exception') {
            message = `${messageBase} (Fehler-Code: se/${apiError.errorCode})`;
        } else {
            message = `${messageBase} (Fehler-Nachricht: ${apiError.errorMessage})`;
        }
    } else if (apiError instanceof ApiError) {
        message = `${defaultMessage} (Fehler-Code: ${apiError.response.status}/${apiError.error})`;
    } else {
        message = defaultMessage;
    }

    toast.error(message, { autoClose: 6000 });
}

export function showTempError(message, duration = 6000) {
    toast.error(message, { autoClose: duration });
}

export function showRequestError(message, err) {
    if (err instanceof ApiError) {
        showApiError(err, message);
    } else if (typeof err === 'string') {
        showTempError(`${message} (${err})`);
    } else {
        showTempError(message);
    }
}
