import React from 'react';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';

export const SectionApprovedByNameInput =
    ({ readOnly, managed, scaffoldingId }) => {

        if (!scaffoldingId || managed || managed === undefined) {
            return null;
        }

        return (
            <InputGroup
                label="Freigegeben durch (befähigte Person)"
            >
                <Input
                    type="text"
                    name="approvedByName"
                    readOnly={readOnly}
                />
            </InputGroup>
        );
    };
