import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Breadcrumb from '../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../Navigation/Breadcrumbs/BreadcrumbLink';

const UserManagementBreadcrumb = ({ editUser, userId }) => {

    return (
        <Breadcrumb>
            <Fragment>
                <BreadcrumbLink
                    label="Nutzerverwaltung"
                    path="/account/users"
                    icon="settings"
                />
                <BreadcrumbLink
                    label={`Nutzer ${editUser ? 'bearbeiten' : 'hinzufügen'}`}
                    path={editUser ? `/account/users/${userId}/edit` : '/account/users/add'}
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default UserManagementBreadcrumb;

UserManagementBreadcrumb.propTypes = {
    editUser: PropTypes.bool,
    userId: PropTypes.string
};

UserManagementBreadcrumb.propTypes = {
    editUser: false,
    userId: ''
};
