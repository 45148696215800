import PropTypes from 'prop-types';
import React from 'react';
import EmptyCardHint from '../../Common/EmptyCardHint';
import IconButton from '../../Common/IconButton';
import Section from '../../Layout/Section';


const ConstructionPlanEmptyPlaceholder = ({onCreateConstructionPlan}) => (
    <Section
        title={
            <span className="job-constructionplan-section-title">
                    Baustelle & Gerüst
            </span>
        }
        id="constructionplanner"
        cta={
            <IconButton
                onClick={onCreateConstructionPlan}
                title="Baustelle & Gerüste hinzufügen"
                icon="add"
            />
        }
    >
        <EmptyCardHint title="Es wurde bisher keine Baustelle und Gerüste geplant.">
            <p className="card-empty-hint__bd">
                Geben Sie die zugehörige Baustelle in der Baustellenplanung an oder planen Sie dort
                eine neue Baustelle. Bei Bedarf können Sie auch existierende Baustellen umplanen.
            </p>
        </EmptyCardHint>
    </Section>
);


ConstructionPlanEmptyPlaceholder.propTypes = {
    onCreateConstructionPlan: PropTypes.func.isRequired,
};


export default ConstructionPlanEmptyPlaceholder;
