import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/es/storage';
import * as types from '../../types';

const initialState = {
    isFetchingList: false,
    listWasFetched: false,
    error: null,
    currentPage: 1,
    amountPerPage: 10,
    sitesList: {
        items: [],
        total: 0
    },
    dashboardSites: {},
    sort: {
        direction: '-',
        field: 'name'
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        /*
         * Fetching list
         */
        case types.FETCH_SITES:
            return {
                ...state,
                isFetchingList: true
            };
        /*
         * Fetching list succeeded
         */
        case types.FETCH_SITES_SUCCESS: {
            return {
                ...state,
                isFetchingList: false,
                listWasFetched: true,
                sitesList: {
                    items: action.payload.data,
                    total: action.payload.total
                }
            };
        }

        /*
         * Fetching list failed
         */
        case types.FETCH_SITES_FAILURE:
            return {
                ...state,
                isFetchingList: false,
                error: action.error
            };

        /*
         * Sorting
         */
        case types.SET_SITES_SORT_OPTION:
            return {
                ...state,
                sort: action.payload
            };
        /*
         * Paginator
         */
        case types.SET_CURRENT_SITES_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        default:
            return state;
    }
};

const storageConfig = {
    key: 'sites',
    storage,
    blacklist: ['isFetchingList', 'listWasFetched', 'SitesList']
};

const SitesReducer = persistReducer(storageConfig, reducer);
export default SitesReducer;
