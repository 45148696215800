import React from 'react';
import { connect } from 'react-redux';
import { currentTermsWereAccepted, isAccountOwner } from '../../redux/helper';
import ModalGate from './ModalGate';
import TermsModal from './TermsModal';

const STORAGE_KEY = 'terms_acceptance_hidden';

const TermsAcceptanceWarningBanner = ({ currentTermsWereAccepted, isAccountOwner, ignoreStorage }) => (
    <ModalGate
        opensIf={!currentTermsWereAccepted && isAccountOwner}
        storageKey={STORAGE_KEY}
        ignoreStorage={ignoreStorage}
    >
        {
            hideModal => (
                <TermsModal
                    closeModal={() => hideModal()}
                />
            )
        }
    </ModalGate>
);

const mapStateToProps = state => ({
    currentTermsWereAccepted: currentTermsWereAccepted(state),
    isAccountOwner: isAccountOwner(state)
});

export default connect(mapStateToProps, null)(TermsAcceptanceWarningBanner);
