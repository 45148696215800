import React from 'react';

const Changelog_1_19_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>
                    Verbesserte Anfrageübersicht
                </h4>
                <p>
                    Die Übersichtsseite zu Anfragen lässt sich ab sofort nach
                    Erstellungsdatum sortieren. Für Anfragen besteht außerdem die Möglichkeit eine
                    Frist zur Abgabe eines Angebotes zu hinterlegen.
                    Wenn Sie eine Frist hinterlegen, sendet Ihnen Scaffeye in den 3 Tagen vor diesem
                    Datum mehrmals eine E-Mail, die Sie an die Abgabe eines Angebotes erinnert.
                </p>

                <h4>
                    Weitere Möglichkeiten zur Filterung von Kunden
                </h4>
                <p>Kunden können auf der jeweiligen Übersichtsseite ab sofort nach Hauptkontakt und
                    Adresse gefiltert werden.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>Verbesserte Hilfetexte bei Formularen</li>
                    <li>Korrektur der Sortierungsfunktion für Anfragen und Aufträge</li>
                    <li>Weitere kleinere Verbesserungen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_19_0;
