import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResourceListDisplayType, ResourceListDisplayTypePropType } from '../../properties';
import { fetchSelectedSite, setFilterOption } from '../../redux/modules/constructiondiary/action';
import { showApiError } from '../../redux/modules/error/action';
import { autocompleteSite } from '../../redux/modules/site/action';
import AsyncAutocomplete from '../Common/AsyncAutocomplete';

class SiteAutocomplete extends Component {

    constructor(props) {
        super(props);
        this.onSelectionChange = this.onSelectionChange.bind(this);

        this.state = {
            initialOption: undefined,
            hasInitialOption: false
        };
    }

    onSelectionChange(selectedValue) {

        this.setState({ hasInitialOption: false });

        this.props.onSelectionChange(selectedValue);
    }

    setInitialOption(value) {

        const initialOption = {
            label: value.name,
            value: value.id,
            id: value.id
        };

        this.props.actions.setFilterOption({
            key: 'site',
            value: initialOption
        });

        this.setState({
            initialOption
        });
    }

    componentWillMount() {
        const { initialValue, actions: { fetchSelectedSite } } = this.props;

        if ( !initialValue ) {
            return;
        }

        if ( initialValue ) {
            this.setState({
                hasInitialOption: true
            });

            fetchSelectedSite(initialValue)
                .then((value) => {

                    this.setInitialOption(value);
                })
                .catch((error) => {
                    showApiError(error);
                });
        }
    }

    render() {
        const { actions: { autocompleteSite }, show } = this.props;

        const { initialOption, hasInitialOption } = this.state;

        return (
            <Fragment>
                <AsyncAutocomplete
                    getValues={(input) => autocompleteSite(input, show === ResourceListDisplayType.Archived)}
                    onSelectionChange={this.onSelectionChange}
                    label="Baustelle"
                    loadingMessagePreselected="Lade Baustelle ..."
                    loadingMessage={() => 'Lade Baustellen ...'}
                    placeholder="Baustelle wählen"
                    hasInitialOption={hasInitialOption}
                    initialOption={initialOption}
                />
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteSite,
        fetchSelectedSite,
        setFilterOption
    }, dispatch)
});
export default connect(null, mapDispatchToProps)(SiteAutocomplete);

SiteAutocomplete.propTypes = {
    onSelectionChange: PropTypes.func,
    show: ResourceListDisplayTypePropType
};

SiteAutocomplete.defaultProps = {
    onSelectionChange: () => false,
    show: ResourceListDisplayType.Active
};

