export const WeatherOptions = {
    Sunny: 'sunny',
    Cloudy: 'cloudy',
    Rainy: 'rainy',
    Stormy: 'stormy'
};

export const weatherSelectionOptions = [
    {
        value: WeatherOptions.Sunny
    },
    {
        value: WeatherOptions.Cloudy
    },
    {
        value: WeatherOptions.Rainy
    },
    {
        value: WeatherOptions.Stormy
    }
];


export const getWeatherLabel = (weather) => {

    switch (weather) {
        case WeatherOptions.Sunny:
            return 'Sonnig';
        case WeatherOptions.Cloudy:
            return 'Bewölkt';
        case WeatherOptions.Rainy:
            return 'Regnerisch';
        case WeatherOptions.Stormy:
            return 'Stürmisch / Gewitter';
        default:
            return 'n.a';
    }
};

export const EmployeeRole = {
    ScaffoldingGroupMasterLeader: 'scaffolding_group_master_leader', // Gerüstbau-Kolonnenführer/Gerüstmeister
    ScaffoldingGroupLeader: 'scaffolding_group_leader', // Gerüstbau-Kolonnenführer
    ScaffoldingMasterMechanic: 'scaffolding_master_mechanic', // Gerüstbau-Obermonteur
    ScaffoldingMechanic: 'scaffolding_mechanic', // Gerüstbau-Fachmonteur
    ScaffoldingScaffolder: 'scaffolding_scaffolder', // Gerüstbauer
    ScaffoldingCrafter: 'scaffolding_crafter', // Gerüstbau-Werker
    ScaffoldingHelper: 'scaffolding_helper', // Gerüstbau-Helfer
    StoreKeeper: 'store_keeper', // Lagerarbeiter
    ScaffoldingApprentice: 'scaffolding_apprentice' // Auszubildende(r) im Gerüstbau
};

export const EmployeeRoleLabel = {
    [EmployeeRole.ScaffoldingGroupMasterLeader]: 'Gerüstbau-Kolonnenführer / Gerüstmeister',
    [EmployeeRole.ScaffoldingGroupLeader]: 'Gerüstbau-Kolonnenführer',
    [EmployeeRole.ScaffoldingMasterMechanic]: 'Gerüstbau-Obermonteur',
    [EmployeeRole.ScaffoldingMechanic]: 'Gerüstbau-Fachmonteur',
    [EmployeeRole.ScaffoldingScaffolder]: 'Gerüstbauer',
    [EmployeeRole.ScaffoldingCrafter]: 'Gerüstbau-Werker',
    [EmployeeRole.ScaffoldingHelper]: 'Gerüstbau-Helfer',
    [EmployeeRole.StoreKeeper]: 'Lagerarbeiter',
    [EmployeeRole.ScaffoldingApprentice]: 'Auszubildende(r) im Gerüstbau'

};

export const EmployeeRoleOptions = [
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingGroupMasterLeader],
        value: EmployeeRole.ScaffoldingGroupMasterLeader
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingGroupLeader],
        value: EmployeeRole.ScaffoldingGroupLeader
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingMasterMechanic],
        value: EmployeeRole.ScaffoldingMasterMechanic
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingMechanic],
        value: EmployeeRole.ScaffoldingMechanic
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingScaffolder],
        value: EmployeeRole.ScaffoldingScaffolder
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingCrafter],
        value: EmployeeRole.ScaffoldingCrafter
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingHelper],
        value: EmployeeRole.ScaffoldingHelper
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.StoreKeeper],
        value: EmployeeRole.StoreKeeper
    },
    {
        label: EmployeeRoleLabel[EmployeeRole.ScaffoldingApprentice],
        value: EmployeeRole.ScaffoldingApprentice
    }

];
