import { SpecialFeature } from '../../../../Sections/properties';
import { getEnumObjectDiffOptionRows, getEnumObjectIdenticalOptionRows } from '../../optionRowData';

/**
 * Returns all identical options
 *
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getIdenticalSpecialFeatureOptionRows = (planValue, liveValue) => {
    return getEnumObjectIdenticalOptionRows(SpecialFeature, planValue, liveValue);
};

/**
 * Returns all options which are
 *
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getDiffSpecialFeatureOptionRows = (planValue, liveValue) => {
    return getEnumObjectDiffOptionRows(SpecialFeature, planValue, liveValue);
};
