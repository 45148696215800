import PropTypes from 'prop-types';
import React from 'react';
import { ConstructionPlannerSectionType } from './ConstructionPlanFormGroup';

const getSectionTypeLabel = (type) => {
    switch (type) {
        case ConstructionPlannerSectionType.Site:
            return 'Baustelle';
        case ConstructionPlannerSectionType.Scaffolding:
            return 'Gerüst';
        case ConstructionPlannerSectionType.Section:
            return 'Gerüstabschnitt';
        default:
            return 'n.a.';
    }
};

export const ConstructionPlanFormGroupTitle = ({ type, children }) => (
    <div className="constructionPlannerSection__lbl">
        {type &&
        <span className="constructionPlannerSection__type">{getSectionTypeLabel(type)}</span>}
        {children}
    </div>
);

ConstructionPlanFormGroupTitle.propTypes = {
    type: PropTypes.oneOf([
        ConstructionPlannerSectionType.Site,
        ConstructionPlannerSectionType.Scaffolding,
        ConstructionPlannerSectionType.Section,
        ConstructionPlannerSectionType.Media
    ]).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.node
    ]),
};

ConstructionPlanFormGroupTitle.defaultProps = {
    children: ''
};

export default ConstructionPlanFormGroupTitle;
