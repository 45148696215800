import React from 'react';
import PropTypes from 'prop-types';

const ConstructionPlanSyncModalHeader = ({ left, right }) => {

    return (
        <div className="construction-planner-sync-form-header">
            <div className="construction-planner-sync-form-header__column construction-planner-sync-form-header__column--left">
                <div className="construction-planner-sync-form-header__cnt">
                    {left()}
                </div>
            </div>
            <div className="construction-planner-sync-form-header__column construction-planner-sync-form-header__column--right">
                <div className="construction-planner-sync-form-header__cnt">
                    {right()}
                </div>
            </div>
        </div>
    );
};

export default ConstructionPlanSyncModalHeader;

ConstructionPlanSyncModalHeader.propTypes = {
    left: PropTypes.func.isRequired,
    right: PropTypes.func.isRequired
};
