import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uuidv4 from 'uuid/v4';
import { showRequestError } from '../../../../redux/modules/error/action';
import { fetchSectionSelectOptions } from '../../../../redux/modules/section/action';
import { ConstructionPlanModelMode } from '../../properties';
import ConstructionPlanFormItemList from '../Common/ConstructionPlanFormItemList';
import {
    ConstructionPlanFormSectionPropType,
    ConstructionPlanModelModePropType
} from '../propTypes';
import ConstructionPlanFormSection from './ConstructionPlanFormSection';
import ConstructionPlanFormSectionPlaceholder from './ConstructionPlanFormSectionPlaceholder';
import ConstructionPlanSectionFormModal from './Modal/ConstructionPlanSectionFormModal';

class ConstructionPlanFormSectionList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            liveSectionIds: [],
            showFormModal: false,
            sectionFormModalMode: undefined,
            showPlaceholder: false
        };

        this.onSectionChanged = this.onSectionChanged.bind(this);
        this.onReferenceAll = this.onReferenceAll.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.hasSectionsLeftToSelect = this.hasSectionsLeftToSelect.bind(this);
        this.onCloseFormModal = this.onCloseFormModal.bind(this);
        this.onAddExisting = this.onAddExisting.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
    }

    componentWillMount() {
        const { scaffoldingId, scaffoldingMode } = this.props;
        if (scaffoldingId && scaffoldingMode !== ConstructionPlanModelMode.New) {
            this.fetchScaffoldingSectionIds(scaffoldingId);
        }
    }

    componentDidUpdate(prevProps) {
        const { scaffoldingId, scaffoldingMode, referenceData } = this.props;

        if (typeof scaffoldingId === 'undefined') {
            return;
        }

        // Update available scaffolding options if scaffolding changes
        if (scaffoldingId !== prevProps.scaffoldingId && referenceData) {
            if (scaffoldingId && scaffoldingMode !== ConstructionPlanModelMode.New) {
                this.fetchScaffoldingSectionIds(scaffoldingId);
            }
        }
    }

    /**
     * Returns true if there are sections left to reference
     *
     * @returns {boolean}
     */
    hasSectionsLeftToSelect() {

        // if mode of parent is new
        const { scaffoldingMode } = this.props;

        if (scaffoldingMode === ConstructionPlanModelMode.New) {
            return false;
        }

        // existing sections of a scaffolding
        const { liveSectionIds } = this.state;
        if (!liveSectionIds || !liveSectionIds.length) {
            return false;
        }

        const { sections } = this.props;

        let planSectionIds = [];
        if (sections) {
            planSectionIds = sections
                .filter(s => s.mode !== ConstructionPlanModelMode.New)
                .map(s => s.id);
        }

        return _.difference(liveSectionIds, planSectionIds).length > 0;
    }

    // Remove section from list
    onDelete(sectionToDelete) {

        const { onChange, sections } = this.props;

        const updatedSections = sections.filter(section => section.id !== sectionToDelete.id);

        onChange(updatedSections);
    }

    onAddNew() {
        this.setState({
            showFormModal: true,
            sectionFormModalMode: ConstructionPlanModelMode.New
        });
    }

    onAddExisting() {
        this.setState({
            showFormModal: true,
            sectionFormModalMode: ConstructionPlanModelMode.Reference
        });
    }

    fetchScaffoldingSectionIds(scaffoldingId) {

        const { fetchSectionSelectOptions } = this.props.actions;

        this.setState({
            isFetching: true
        });

        fetchSectionSelectOptions(scaffoldingId, false, true)
            .then((sections) => {
                this.setState({
                    liveSectionIds: sections ? sections.map(s => s.id) : [],
                    isFetching: false
                });
            })
            .catch(error => {
                showRequestError('Gerüstabschnitte konnten nicht geladen werden', error);
                this.setState({
                    isFetching: false
                });
            });
    }

    onSectionChanged(updatedSection, oldSection) {
        const { onChange, sections } = this.props;

        let updatedSections = [
            ...sections
        ];

        // Replace the section in the list of sections
        let updated = false;
        updatedSections = updatedSections
            .map(section => {
                if (
                    section.id === updatedSection.id
                    || oldSection && section.id === oldSection.id
                ) {
                    updated = true;

                    const _updatedSection = {
                        ...updatedSection
                    };

                    if (section.originalMode) {
                        _updatedSection.originalMode = section.originalMode;
                    }

                    if (section.lastApplied) {
                        _updatedSection.lastApplied = section.lastApplied;
                    }

                    return _updatedSection;
                }

                return section;
            });

        // No existing section was found having the same id: must be new
        if (!updated) {
            updatedSections.push({ ...updatedSection });
        }

        onChange(updatedSections);
        this.onCloseFormModal();
    }

    onReferenceAll(sectionsToReference, editedSection = undefined) {

        const { onChange, sections } = this.props;
        const sectionsWithModeNew = sections.filter(section => section.mode === ConstructionPlanModelMode.New);
        let referencedSections = [
            ...sectionsToReference,
            ...sectionsWithModeNew
        ];

        /*
         * If there was a referenced/edited section in the first place and the user wants to
         * reference all instead we move the sections reference/added/edited in the original
         * to the new referenced section, just in case.
         */
        if (editedSection && editedSection.id) {
            const { mode, sections } = editedSection;
            if ((mode === ConstructionPlanModelMode.Reference
                || mode === ConstructionPlanModelMode.Edit
            ) && sections && sections.length > 0) {
                referencedSections = referencedSections
                    .map(referencedScaffolding => {
                        if (referencedScaffolding.id === editedSection.id) {
                            return {
                                ...referencedScaffolding,
                                sections
                            };
                        }

                        return referencedScaffolding;
                    });
            }
        }

        onChange(referencedSections);
        this.onCloseFormModal();
    }

    onCloseFormModal() {
        this.setState({
            showFormModal: false,
            sectionFormModalMode: undefined,
            showPlaceholder: false
        });
    }

    getInitialFormModalData() {
        const { sectionFormModalMode } = this.state;
        return {
            id: sectionFormModalMode === ConstructionPlanModelMode.New ? uuidv4() : undefined,
            mode: sectionFormModalMode === ConstructionPlanModelMode.New ? sectionFormModalMode : undefined,
            data: {},
            sections: []
        };
    }

    getReferencedSectionIds() {
        return this.props.sections
            .filter(section => this.state.liveSectionIds.indexOf(section.id) !== -1)
            .map(section => section.id);
    }

    getReferenceData(id) {
        const { referenceData } = this.props;
        if (!referenceData || referenceData.length === 0) {
            return null;
        }

        return _.find(referenceData, entry => entry.id === id);
    }

    render() {
        const { sections, scaffoldingId, isArchived } = this.props;
        const { showFormModal } = this.state;

        const showAddMoreButton = sections && sections.length > 0;
        const referencedSectionIds = this.getReferencedSectionIds();

        if (showFormModal) {
            return (
                <ConstructionPlanSectionFormModal
                    scaffoldingId={scaffoldingId}
                    onChange={this.onSectionChanged}
                    onReferenceAllSections={this.onReferenceAll}
                    onClose={this.onCloseFormModal}
                    section={this.getInitialFormModalData()}
                    referencedSectionIds={referencedSectionIds}
                />
            );
        }

        const hasSectionsLeftToSelect = this.hasSectionsLeftToSelect();

        return (
            <div className="constructionPlannerSectionList">

                <ConstructionPlanFormItemList
                    listItems={sections || []}
                    emptyComponent={
                        !isArchived ? <ConstructionPlanFormSectionPlaceholder
                                onAddExisting={this.onAddExisting}
                                onAddNew={this.onAddNew}
                                sectionsLeftToReference={hasSectionsLeftToSelect}
                            />
                            :
                            <div></div>
                    }
                >
                    {
                        section => (
                            <ConstructionPlanFormSection
                                scaffoldingId={scaffoldingId}
                                key={section.id}
                                section={section}
                                onDelete={this.onDelete}
                                onChange={this.onSectionChanged}
                                onReferenceAllSections={this.onReferenceAll}
                                referencedSectionIds={referencedSectionIds}
                                hasSectionsLeftToSelect={hasSectionsLeftToSelect}
                                referenceData={this.getReferenceData(section.id)}
                                parentIsArchived={isArchived}
                            />
                        )
                    }
                </ConstructionPlanFormItemList>
                {!isArchived &&
                (
                    showAddMoreButton &&
                    <ConstructionPlanFormSectionPlaceholder
                        onAddExisting={this.onAddExisting}
                        onAddNew={this.onAddNew}
                        sectionsLeftToReference={hasSectionsLeftToSelect}
                    />
                )
                }

            </div>
        );
    }
}

ConstructionPlanFormSectionList.propTypes = {
    scaffoldingId: PropTypes.string.isRequired,
    scaffoldingMode: ConstructionPlanModelModePropType.isRequired,
    onChange: PropTypes.func.isRequired,
    sections: PropTypes.arrayOf(ConstructionPlanFormSectionPropType),
    referenceData: PropTypes.arrayOf(PropTypes.object),
    isArchived: PropTypes.bool
};

ConstructionPlanFormSectionList.defaultProps = {
    sections: [],
    referenceData: [],
    isArchived: false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSectionSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanFormSectionList);
