import { isIdenticalAddress, isIdenticalValue } from '../equals';
import { isIdenticalMediaValue } from '../media';

export function isIdenticalSiteFormValue(property, modelData, modelReferenceData) {

    const modelValue = modelData && modelData[property] || undefined;
    const liveValue = modelReferenceData && modelReferenceData[property] || undefined;

    switch (property) {
        case 'name':
        case 'description':
            return isIdenticalValue(modelValue, liveValue);
        case 'address':
            return isIdenticalAddress(modelValue, liveValue);
        case 'attachments':
            return isIdenticalMediaValue(modelValue, liveValue);
        default:
            return false;
    }
}
