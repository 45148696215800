import React from 'react';
import { Field } from 'react-final-form';
import Checkbox from '../../../Forms/Checkbox';
import Condition from '../../../Forms/Condition';
import Error from '../../../Forms/Error';
import Input from '../../../Forms/Input';
import InputGroup from '../../../Forms/InputGroup';
import InputHint from '../../../Forms/InputHint';
import OptionGroup from '../../../Forms/OptionGroup';
import { SpecialFeature } from '../../properties';
import { BracketOptions, SpecialFeatureOptions } from '../options';

export const SectionSpecialFeaturesInput = () => (
    <InputGroup
        label="Besonderheiten"
    >
        <Checkbox
            name="specialFeatures"
            value="bracket"
            label="Konsolen (innen- oder außenliegend)"
        />

        <Condition when="specialFeatures" contains="bracket">
            <OptionGroup
                name="specialFeatureBracket"
                options={BracketOptions}
                ifSelectionIs="other"
                classes="input-grp__cnt"
                thenRender={field => (
                    {
                        other: <Input
                            type="text"
                            name="specialFeatureBracketOther"
                            placeholder='z.B. "450 mm"*'
                        />
                    }[field]
                )}
                type="checkbox"
            />
        </Condition>

        <Checkbox
            label="Verankerung"
            name="specialFeatures"
            value="anchoring"
        />

        <Condition when="specialFeatures" contains="anchoring">
            <InputHint>
                Im Bereich &quot;Dokumente&quot; können Sie ein Ankerprotokoll hinterlegen
            </InputHint>
        </Condition>

        <Field name="purpose.protective" subscription={{ value: true }}>
            {
                ({ input: { value } }) => {
                    const inputValue = value || [];
                    return SpecialFeatureOptions.map((option, i) => {
                        let attributes = {};

                        if ((inputValue.join('') === 'roof_safety_catch_scaffolding') && (option.value === SpecialFeature.FallProtection)) {
                            attributes = {
                                checked: true,
                                disabled: true
                            };
                        }
                        if (option.value === SpecialFeature.Anchoring) {
                            return false;
                        }

                        return (
                            <label
                                key={i}
                                className="input-grp__optionlabel"
                            >
                                <Field
                                    name="specialFeatures"
                                    component="input"
                                    type="checkbox"
                                    {...attributes}
                                    value={option.value}
                                />{' '}
                                {option.label}
                            </label>
                        );
                    });
                }
            }
        </Field>
        <Error name="specialFeatures" />
    </InputGroup>
)
