import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { enumToList } from '../../utils';
import DataRow from '../Common/DataRow';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import { getPurposeMap } from './functions';
import { getPurposeGroupsLabel, getPurposeLabel } from './labels';
import { Purpose, PurposeGroup } from './properties';

const MODE_DEFAULT = 'default';
const MODE_DATA_ROWS = 'data-rows';
const MODE_INLINE = 'inline';

const ModeWrapper = ({ mode, title, children }) => {
    if (mode === MODE_INLINE) {
        return children;
    }

    if (mode === MODE_DATA_ROWS) {
        return (
            <Fragment>
                <DataRow
                    label="Verwendungszweck"
                >
                    {title}
                </DataRow>
                {children}
            </Fragment>
        )
    }

    return (
        <div className="purpose">
            <div className="purpose__itm">
                {children}
            </div>
        </div>
    );
}

export const SectionPurpose = ({
                                   purposes,
                                   otherProtectivePurpose,
                                   mode,
                                   fallback
                               }) => {

    const groupsLabel = getPurposeGroupsLabel(purposes);

    // Single purpose - no need to show categories
    if(purposes && purposes.length === 1) {
        const purposeLabel = getPurposeLabel(purposes[0]);
        const isDataRowsMode = mode === MODE_DATA_ROWS;
        return (
            <ModeWrapper mode={mode} title={isDataRowsMode ? purposeLabel : undefined}>
                {isDataRowsMode ? null : purposeLabel}
            </ModeWrapper>
        )
    }

    const purposeMap = getPurposeMap(purposes, otherProtectivePurpose);
    if (!purposeMap) {
        return (
            <ModeWrapper mode={mode}>
                {fallback}
            </ModeWrapper>
        );
    }

    const title = groupsLabel;

    const groups = Object
        .keys(purposeMap)
        .map(purposeGroupKey => {
            const purposeGroup = purposeMap[purposeGroupKey];
            const { label, items } = purposeGroup;
            if (!items || !items.length) {
                return fallback;
            }

            const joinedItems = items.map(item => item.label).join(', ');
            const noItems = joinedItems === label; // group title same as item

            if (mode === MODE_INLINE) {
                return (
                    <span key={purposeGroupKey} className="construction-plan__section-purpose">
                        {noItems ? label : `${label}: ${joinedItems}`}
                    </span>
                )
            } else if (mode === MODE_DATA_ROWS) {
                if(purposeGroupKey === PurposeGroup.SiteStaircase) {
                    return null;
                }

                return (
                    <DataRow
                        label={label}
                        isSubRow
                        key={purposeGroup.group}
                    >
                        {joinedItems}
                    </DataRow>
                )
            } else {
                return (
                    <div className="purpose__itm" key={purposeGroupKey}>
                        {noItems ? label : `${label}: ${joinedItems}`}
                    </div>
                );
            }
        })
        .filter(g => g);

    return (
        <ModeWrapper mode={mode} title={title}>
            {groups}
        </ModeWrapper>
    )
}

SectionPurpose.propTypes = {
    mode: PropTypes.oneOf([MODE_DEFAULT, MODE_INLINE, MODE_DATA_ROWS]),
    purposes: PropTypes.arrayOf(PropTypes.oneOf(enumToList(Purpose))),
    otherProtectivePurpose: PropTypes.string,
    fallback: PropTypes.node
}

SectionPurpose.defaultProps = {
    mode: MODE_DEFAULT,
    fallback: <NotAvailablePlaceholder />
}
