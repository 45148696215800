import _ from 'lodash';
import moment from 'moment/moment';
import { ModelType } from '../../../properties';
import { getInitialConstructionFormSelection } from '../../ConstructionPlan/Form/utils';
import {
    ConstructionPlanModelMode,
    ConstructionPlanReferenceMode
} from '../../ConstructionPlan/properties';
import { toMediaFormData, toMediaPayload } from '../../Forms/Media/utils';
import { ModelMediaType } from '../../Media/properties';

/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = input => !!(input && typeof input.focus === 'function');

/**
 * Gets all the inputs inside modal on the page
 */
export const getAllInputs = () => {

    const inputFields = window.document.getElementById('scaffolding-modal')
        .querySelectorAll('input, select');

    if (typeof inputFields === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(inputFields)
        .reduce((accumulator, input) => {
            if (isFocusableInput(input)) {
                return accumulator.concat(input);
            }

            return accumulator;
        }, []);
};

export function validate(values, isPlan = false) {
    const errors = {};

    if (isPlan && values.selection === ConstructionPlanReferenceMode.All) {
        return {};
    }

    // Baustelle
    if (!isPlan && !values.siteId) {
        errors.siteId = 'Bitte wählen Sie eine Baustelle aus';
    }

    // Bezeichnung
    if (!values.name) {
        errors.name = 'Bitte geben Sie eine Bezeichnung ein';
    }

    return errors;
}

export function getFormValues(scaffolding, constructionPlanMode) {

    if (!scaffolding) {
        return null;
    }

    const {
        id,
        name,
        description,
        scheduledErection,
        scheduledDismantling,
        site,
        managed,
        utilizationPlans,
        anchorProtocol,
        attachments
    } = scaffolding;

    const formValues = {};

    if (!constructionPlanMode) {
        formValues.siteId = site && site.id;
        formValues.site = site;
        formValues.scaffolding = scaffolding;
        // managed
        formValues.managed = managed;
    } else {
        formValues.id = id;
        formValues.mode = constructionPlanMode;
        formValues.selection = getInitialConstructionFormSelection(constructionPlanMode, id);
    }

    // Basic information
    formValues.name = name;
    formValues.description = description || '';
    formValues.scheduledErection = scheduledErection ? moment(scheduledErection) : null;
    formValues.scheduledDismantling = scheduledDismantling ? moment(scheduledDismantling) : null;

    // Media
    formValues.utilizationPlans = [];
    formValues.anchorProtocol = [];
    formValues.attachments = [];

    if (utilizationPlans) {
        formValues.utilizationPlans = utilizationPlans
            .map(media => toMediaFormData(media, ModelType.Scaffolding, ModelMediaType.Scaffolding_UtilizationPlan, constructionPlanMode));
    }

    if (anchorProtocol) {
        formValues.anchorProtocol = [
            toMediaFormData(anchorProtocol, ModelType.Scaffolding, ModelMediaType.Scaffolding_AnchorProtocol, constructionPlanMode)
        ];
    }

    if (attachments) {
        formValues.attachments = attachments
            .map(media => toMediaFormData(media, ModelType.Scaffolding, ModelMediaType.Scaffolding_Attachment, constructionPlanMode));
    }

    return formValues;
}

export function getSubmitPayload(values, isPlan = false) {
    if (!values) {
        return null;
    }

    const {
        id,
        mode,
        name,
        siteId,
        description,
        scheduledErection,
        scheduledDismantling,
        utilizationPlans,
        attachments,
        anchorProtocol
    } = values;

    if (isPlan && mode === ConstructionPlanModelMode.Reference) {
        return {
            id,
            mode
        }
    }

    const data = {};

    // Basic information
    data.name = name;
    data.description = description;

    // Planed erection
    data.scheduledErection = scheduledErection ? moment(scheduledErection).format() : null;

    // Planed dismantling
    data.scheduledDismantling = scheduledDismantling ? moment(scheduledDismantling).format() : null;

    // Site id
    if (!isPlan) {
        data.siteId = siteId;
    }

    // Managed flag
    if (!isPlan) {
        // Managed
        data.managed = values.managed;
    }

    // Media
    if (utilizationPlans && utilizationPlans.length > 0) {
        data.utilizationPlans = utilizationPlans.map(plan => toMediaPayload(plan, isPlan));
    }

    if (anchorProtocol && anchorProtocol.length > 0) {
        data.anchorProtocol = toMediaPayload(_.first(anchorProtocol), isPlan);
    }

    if (attachments && attachments.length > 0) {
        data.attachments = attachments.map(attachment => toMediaPayload(attachment, isPlan));
    }

    // Return payload
    if (isPlan) {
        return {
            id: values.id,
            mode: values.mode,
            data
        };
    } else {
        return data;
    }
}
