import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../Common/Tooltip';

const InputGroup = ({ label, tooltip, required, subInput, containerClass, children, horizontal, type, ...otherProps }) => {
    const layoutClasses = ['input-grp'];

    if (horizontal) {
        layoutClasses.push('horizontal');
    }

    if (containerClass) {
        layoutClasses.push(containerClass);
    }

    let _label = label;
    if (subInput) {
        _label = `– ${_label}`;
        layoutClasses.push('input-grp--sub-input');
    }

    if (type) {
        layoutClasses.push(`input-grp--${type}`);
    }

    if (required) {
        _label = `${_label}*`;
    }

    return (
        <div {...otherProps} className={layoutClasses.join(' ')}>
            <div className="input-grp__lbl">
                {_label}
                {
                    tooltip && <Tooltip id={`input-grp-tt-${label.toLowerCase()}`}>
                        {tooltip}
                    </Tooltip>
                }

            </div>
            <div className="input-grp__cnt">
                {children}
            </div>
        </div>
    );
};

InputGroup.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.node,
    horizontal: PropTypes.bool,
    containerClass: PropTypes.string,
    subInput: PropTypes.bool,
    type: PropTypes.string,
    tooltip: PropTypes.node
};

InputGroup.defaultProps = {
    label: '',
    required: false,
    horizontal: false,
    children: '',
    containerClass: '',
    subInput: false
};

export default InputGroup;
