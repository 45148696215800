import React from 'react';
import CustomersOverview, { CUSTOMERS_QUERY_STRING_VARIABLE } from '../../../Components/Customers/Overview/CustomersOverview';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const CustomersListPage = ({ location, history }) => {

    if (location.search && (!location.state || !location.state[CUSTOMERS_QUERY_STRING_VARIABLE])) {

        history.replace({
            ...location,
            state: {
                ...location.state,
                [CUSTOMERS_QUERY_STRING_VARIABLE]: location.search
            }
        });
    }
    return (
        <PrivatePage requiredUserPermission={Permission.ReadCustomers}>
            <Container>
                <TopNav />
                <CustomersOverview />
            </Container>
        </PrivatePage>
    );

};

export default CustomersListPage;
