import 'moment/locale/de';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showRequestError } from '../../../redux/modules/error/action';
import { fetchJobChangelog } from '../../../redux/modules/job/actions';
import ChangeLog from '../../Common/ChangeLog/ChangeLog';
import DiffModal from '../../Common/Diff/DiffModal';
import { getJobDiffs } from '../../Common/Diff/functions';
import { getJobDiffTypeIcon } from '../../Common/Diff/icons';
import { JobDiffValue } from '../../Common/Diff/Value/JobDiffValue';
import { getJobDiffTypeLabel } from '../../Common/Diff/labels';
import Icon from '../../Common/Icon';
import LoadingSpinner from '../../Page/LoadingSpinner';
import { JobType } from '../properties';

class JobChangeLogModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            jobChangeLog: null
        };
    }

    componentWillMount() {

        const { actions: { fetchJobChangelog }, jobId } = this.props;

        fetchJobChangelog(jobId)
            .then(({ history: jobChangeLog, type, name }) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    jobChangeLog,
                    type,
                    name
                });
            })
            .catch(error => {
                showRequestError('Änderungsverlauf konnte nicht geladen werden', error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isFetching: false
                });
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    renderModalContent() {
        const { jobChangeLog, type, name } = this.state;

        const label = type === JobType.Job ? 'Auftrag' : 'Anfrage';

        return (
            <Fragment>
                <div className="diff-log__hd">
                    <h3 className="diff-log__ttl">{`${label}: ${name}`}</h3>
                </div>
                <div className="diff-log__bd">
                    <ChangeLog
                        entries={jobChangeLog}
                        getChanges={getJobDiffs}
                        getChangeTypeLabel={(diffType, diff) => getJobDiffTypeLabel(diffType, diff, type)}
                        getChangeTypeIcon={getJobDiffTypeIcon}
                        renderChangeItemValue={(value,diff) => <JobDiffValue value={value} diff={diff} />}
                    />
                </div>
            </Fragment>
        );
    }

    render() {
        const {
            isFetching
        } = this.state;

        const {
            onClose
        } = this.props;

        return (
            <DiffModal
                title={<span className="change-log-modal-title"><Icon
                    name="history" />Historie</span>}
                onBackdropClick={onClose}
                onCloseClick={onClose}
            >
                {isFetching ? <LoadingSpinner
                    block
                    label="Lade Historie"
                    backgroundColor="white"
                /> : this.renderModalContent()}
            </DiffModal>
        );
    }
}

JobChangeLogModal.propTypes = {
    jobId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchJobChangelog
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(JobChangeLogModal);
