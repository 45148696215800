import React from 'react';

const Changelog_1_18_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>
                    Erneutes Versenden von Baustelleneinladungen möglich
                </h4>
                <p>
                    Bestehende Einladungen zu eigenen Baustellen können ab sofort erneut versendet
                    werden. Das erneute Absenden kann von der Baustellen-Detailseite aus
                    durchgeführt werden und verbessert die Abstimmung mit den eingeladenen Nutzern.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>Korrektur von Hinweistexten zu Raum- und Flächengerüsten</li>
                    <li>Optimierung der Fehlermeldungen zu fehlgeschlagenen Datei-Uploads</li>
                    <li>Korrektur der Ansicht für "Allgemeine Dokumente"</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_18_0;
