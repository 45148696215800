import _ from 'lodash';
import PropTypes from 'prop-types';

const ACCOUNT_TYPE_PRO = 'pro';
const ACCOUNT_TYPE_BASIC = 'basic';
const ACCOUNT_TYPE_LIGHT = 'light';

export class AccountType {
    static Pro = ACCOUNT_TYPE_PRO;
    static Basic = ACCOUNT_TYPE_BASIC;
    static Light = ACCOUNT_TYPE_LIGHT;
}

export class UserType {

    //
    // Pro
    //

    // Pro - Admin
    static ProAdministrator = AccountType.Pro + '_administrator';

    // Pro - Standard / Vollzugriff
    static ProStandard = AccountType.Pro + '_standard';

    // Pro - Befähigte Person
    static ProQualifiedWorker = AccountType.Pro + '_qualified-worker';

    // Pro - Vorarbeiter / Helfer
    static ProWorker = AccountType.Pro + '_worker';


    //
    // Basic
    //

    // Basic - Admin
    static BasicAdministrator = AccountType.Basic + '_administrator';

    // Basic - Standard
    static BasicStandard = AccountType.Basic + '_standard';


    //
    // Light
    //

    // Light - Admin
    static LightAdministrator = AccountType.Light + '_administrator';

    // Basic - Standard
    static LightStandard = AccountType.Light + '_standard';

}

export const UserTypePropType = PropTypes.oneOf([
    UserType.ProAdministrator,
    UserType.ProStandard,
    UserType.ProQualifiedWorker,
    UserType.ProWorker,
    UserType.BasicAdministrator,
    UserType.BasicStandard,
    UserType.LightAdministrator,
    UserType.LightStandard
]);


export function userHasPermission(userPermissions, requestedPermission) {
    return _.includes(userPermissions, requestedPermission);
}

export function userHasAllPermissions(userPermissions, requestedPermissions) {
    if (!userPermissions) {
        return false;
    }

    if (typeof requestedPermissions === 'string') {
        return userHasPermission(userPermissions, requestedPermissions);
    }

    if (!_.isArray(requestedPermissions)) {
        return false;
    }

    return requestedPermissions.every(requiredPermission => userHasPermission(userPermissions, requiredPermission));
}

export function userHasSomePermission(userPermissions, requestedPermissions) {
    if (!userPermissions) {
        return false;
    }

    if (!_.isArray(requestedPermissions)) {
        return false;
    }

    return requestedPermissions.some(requiredPermission => userHasPermission(userPermissions, requiredPermission));
}

export function userHasType(userType, requestedType) {
    if (!userType) {
        return false;
    }

    if (!requestedType) {
        return true;
    }

    return userType === requestedType;
}

export function userHasSomeType(userType, requestedTypes) {
    if (!userType) {
        return false;
    }

    if (!requestedTypes) {
        return true;
    }

    if (!_.isArray(requestedTypes)) {
        return false;
    }

    return requestedTypes.some(requestedType => userHasType(userType, requestedType));
}
