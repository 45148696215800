import PropTypes from 'prop-types';
import React from 'react';
import Icon from './Icon';
import Tooltip from './Tooltip';

const EmptyDataTableHint = (({
                                 numColumns, tooltipContent, title, children, tooltipId
                             }) => (
    <tr className="table-empty-hint__row">
        <td colSpan={numColumns} className="table-empty-hint__column">
            <div className="datatable-empty-hint">
                <div className="datatable-empty-hint__hd">
                    <strong>
                        {!tooltipContent && <Icon name="info" />}
                        {title}
                    </strong>
                    {tooltipContent &&
                    <Tooltip id={tooltipId}>
                        {tooltipContent}
                    </Tooltip>
                    }
                </div>
                {!children ? null :
                    <div className="datatable-empty-hint__bd">
                        {children}
                    </div>
                }
            </div>
        </td>
    </tr>
));

export default EmptyDataTableHint;

EmptyDataTableHint.propTypes = {
    numColumns: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    tooltipContent: PropTypes.string,
    tooltipId: PropTypes.string
};

EmptyDataTableHint.defaultProps = {
    tooltipContent: ''
};
