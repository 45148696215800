export const INVALID_CREDENTIALS = 'invalid_credentials';
export const INVALID_REFRESH_TOKEN = 'invalid_refresh_token';
export const MIMES = 'mimes';
export const MAX = 'max';
export const UNAUTHORIZED = 'unauthorized';

export const SERVER_ERROR = 'SERVER_ERROR';
export const RESSOURCE_NOT_FOUND = 'RESSOURCE_NOT_FOUND';
export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';


