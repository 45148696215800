import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';
import Icon from '../../Common/Icon';
import SelectField from '../../Forms/Select/SelectField';
import { EmployeeRole, EmployeeRoleLabel, EmployeeRoleOptions } from './properties';

const { Option, SingleValue } = components;

const RoleOption = ({ children, ...props }) => (
    <Option {...props}>
        {(props.data.value === EmployeeRole.ScaffoldingGroupMasterLeader || props.data.value === EmployeeRole.ScaffoldingGroupLeader) && <span style={{
            color: '#93979F',
            marginRight: 8
        }}
        ><Icon name="star" /></span>}
        {EmployeeRoleLabel[props.data.value]}
    </Option>
);

const RoleValue = ({ children, ...props }) => (
    <SingleValue {...props}>
        {(props.data.value === EmployeeRole.ScaffoldingGroupMasterLeader || props.data.value === EmployeeRole.ScaffoldingGroupLeader) && <span style={{
            color: '#93979F',
            marginRight: 8
        }}
        ><Icon name="star" /></span>}
        {EmployeeRoleLabel[props.data.value]}
    </SingleValue>
);


class TeamMemberRoleSelectField extends Component {

    constructor(props) {
        super(props);
    }

    transformSelectedOptionToFormValue(selectedOption) {

        if (!selectedOption) {
            return null;
        }

        return {
            [selectedOption.value]: true
        };
    }


    renderCreateNewOption = (value) => {

        return <Fragment><strong>{`"${value}"`}</strong> erstellen</Fragment>;
    };

    render() {

        const { ...fieldProps } = this.props;

        return (
            <SelectField
                {...fieldProps}
                createLabel={this.renderCreateNewOption}
                options={EmployeeRoleOptions}
                isClearable
                formatSelection={this.transformSelectedOptionToFormValue}
                components={{
                    Option: RoleOption,
                    SingleValue: RoleValue
                }}
            />
        );
    }
}

export default TeamMemberRoleSelectField;


