import PropTypes from 'prop-types';
import React from 'react';
import InspectionHistory from '../../../Components/Inspections/InspectionHistory';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';

import Container from '../../Container';
import PrivatePage from './PrivatePage';

const InspectionHistoryPage = ({ match }) => (
    <PrivatePage requiredUserPermission={Permission.ReadInspections}>
        <Container>
            <TopNav />
            <InspectionHistory sectionId={match.params.id} />
        </Container>
    </PrivatePage>
);

export default InspectionHistoryPage;

InspectionHistoryPage.propTypes = {
    match: PropTypes.object
};

InspectionHistoryPage.defaultProps = {
    match: {}
};
