import * as types from '../../types';

const initialState = {
    isFetchingList: false,
    listWasFetched: false,
    currentPage: 1,
    amountPerPage: 10,
    total: 0,
    entriesList: {
        items: [],
        total: 0
    },
    sort: {
        direction: '-',
        field: 'created'
    },
    filter: {
        site: {
            id: '',
            label: '',
            value: ''
        },
        scaffolding: {
            id: '',
            label: '',
            value: ''
        },
        section: {
            id: '',
            label: '',
            value: ''
        },
        category: {
            value: ''
        },
        date: {
            dateFrom: '',
            dateTo: ''
        }
    }
};

const constructionDiaryReducer = (state = initialState, action) => {
    switch (action.type) {

        /*
         * Fetching list
         */
        case types.FETCH_CONSTRUCTION_DIARY:
            return {
                ...state,
                isFetchingList: true
            };

        /*
         * Fetching list succeeded
         */
        case types.FETCH_CONSTRUCTION_DIARY_SUCCESS: {
            return {
                ...state,
                isFetchingList: false,
                listWasFetched: true,
                entriesList: {
                    items: action.payload.data,
                    total: action.payload.total
                }
            };
        }

        /*
        * Fetching list failed
        */
        case types.FETCH_CONSTRUCTION_DIARY_FAILURE:
            return {
                ...state,
                isFetchingList: false
            };

        /*
         * Sorting
         */
        case types.SET_CONSTRUCTION_DIARY_SORT_OPTION:
            return {
                ...state,
                sort: action.payload
            };

        /*
        * Set filter option
        */
        case types.SET_CONSTRUCTION_DIARY_FILTER_OPTION:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: action.payload.value
                }
            };

        /*
        Reset single filter option
         */
        case types.RESET_CONSTRUCTION_DIARY_FILTER_OPTION:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [action.payload.key]: initialState.filter[action.payload.key]
                }
            };

        /*
         * Paginator
         */
        case types.SET_CURRENT_CONSTRUCTION_DIARY_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };

        /*
         * Total count of construction diary entries of account
         */
        case types.FETCH_CONSTRUCTION_DIARY_TOTAL:
            return {
                ...state,
                total: action.payload
            };
        default:
            return state;
    }
};

export default constructionDiaryReducer;
