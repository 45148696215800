import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NotAvailablePlaceholder from '../Common/NotAvailablePlaceholder';
import {
    JobStatus as JobStatusOption,
    JobType,
    RequestStatus as RequestStatusOption
} from './properties';

class JobStatus extends Component {

    static renderStatus(status) {

        switch (status) {

            /*
             * Job
             */
            case JobStatusOption.Approved:
                return 'Erteilt';
            case JobStatusOption.Draft:
                return 'In Vorbereitung';
            case JobStatusOption.InProgress:
                return 'In Ausführung';
            case JobStatusOption.Completed:
                return 'Abgeschlossen';

            /*
             * Request
             */
            case RequestStatusOption.New:
                return 'Eingegangen';
            case RequestStatusOption.InProgress:
                return 'In Bearbeitung';
            case RequestStatusOption.OfferSent:
                return 'Angebot versendet';
            case RequestStatusOption.RejectedByCustomer:
                return 'Angebot vom Kunden abgelehnt / Anfrage zurückgezogen';
            case RequestStatusOption.RejectedByContractor:
                return 'Anfrage abgelehnt (vom Gerüstersteller)';
            case RequestStatusOption.Rejected:
                return 'Anfrage abgelehnt';

            default:
                return 'Unbekannt';
        }

    }

    static renderStatusWithJobType(status) {
        switch (status) {

            /*
             * Job
             */
            case JobStatusOption.Approved:
                return 'Auftrag erteilt';
            case JobStatusOption.Draft:
                return 'Auftrag in Vorbereitung';
            case JobStatusOption.InProgress:
                return 'Auftrag in Ausführung';
            case JobStatusOption.Completed:
                return 'Auftrag abgeschlossen';

            /*
             * Request
             */
            case RequestStatusOption.New:
                return 'Anfrage eingegangen';
            case RequestStatusOption.InProgress:
                return 'Anfrage in Bearbeitung';
            case RequestStatusOption.OfferSent:
                return 'Angebot versendet';
            case RequestStatusOption.RejectedByCustomer:
                return 'Angebot vom Kunden abgelehnt / Angebot zurückgezogen';
            case RequestStatusOption.RejectedByContractor:
                return 'Anfrage vom Gerüstersteller abgelehnt';
            case RequestStatusOption.Rejected:
                return 'Anfrage abgelehnt';

            default:
                return 'Unbekannt';
        }
    }

    static getModeClassNames(mode) {
        switch (mode) {
            case 'badge':
                return 'job-status--horizontal job-status--badge';
            case 'horizontal':
                return 'job-status--horizontal';
            default:
                return '';
        }
    }

    static getJobType(status) {

        if (status.includes('job')) {
            return JobType.Job;
        }

        return JobType.Request;
    }

    renderJobStatus(status, mode, showJobType) {
        const { renderComponentAfterDots } = this.props;

        let job_approved = JobStatusOption.Approved;
        let job_draft = JobStatusOption.Draft;
        let job_in_progress = JobStatusOption.InProgress;
        let job_completed = JobStatusOption.Completed;

        if (status === JobStatusOption.Approved) {
            job_approved += ' is-active';
        } else if (status === JobStatusOption.Draft) {
            job_approved += ' is-complete';
            job_draft += ' is-active';
        } else if (status === JobStatusOption.InProgress) {
            job_approved += ' is-complete';
            job_draft += ' is-complete';
            job_in_progress += ' is-active';
        } else if (status === JobStatusOption.Completed) {
            job_approved += ' is-complete';
            job_draft += ' is-complete';
            job_in_progress += ' is-complete';
            job_completed += ' is-active';
        }

        return (
            <div className={`job-status ${JobStatus.getModeClassNames(mode)}`}>
                <div className="status-dots">
                    <span className={`status-dots__dot ${job_approved}`} />
                    <span className={`status-dots__dot ${job_draft}`} />
                    <span className={`status-dots__dot ${job_in_progress}`} />
                    <span className={`status-dots__dot ${job_completed}`} />
                </div>

                {
                    renderComponentAfterDots
                }

                <div className="job-status__label">
                    {
                        showJobType
                            ? JobStatus.renderStatusWithJobType(status)
                            : JobStatus.renderStatus(status)
                    }
                </div>
            </div>
        );

    }

    renderRequestStatus(status, mode, showJobType) {
        const { renderComponentAfterDots } = this.props;

        let request_new = RequestStatusOption.New;
        let request_in_progress = RequestStatusOption.InProgress;
        let request_offer_sent = RequestStatusOption.OfferSent;
        let request_rejected = '';

        if (status === RequestStatusOption.New) {
            request_new += ' is-active';
        } else if (status === RequestStatusOption.InProgress) {
            request_new += ' is-complete';
            request_in_progress += ' is-active';
        } else if (status === RequestStatusOption.OfferSent) {
            request_new += ' is-complete';
            request_in_progress += ' is-complete';
            request_offer_sent += ' is-active';
        } else if (status === RequestStatusOption.RejectedByCustomer || status === RequestStatusOption.Rejected || status === RequestStatusOption.RejectedByContractor) {
            request_new += ' is-complete';
            request_in_progress += ' is-complete';
            request_offer_sent += ' is-complete';
            request_rejected += ' is-rejected';
        }

        return (
            <div className={`job-status job-status--request ${JobStatus.getModeClassNames(mode)}`}>
                <div className="status-dots">
                    <span className={`status-dots__dot ${request_new}`} />
                    <span className={`status-dots__dot ${request_in_progress}`} />
                    <span
                        className={`status-dots__dot ${request_offer_sent} ${request_rejected}`}
                    />
                </div>

                {
                    renderComponentAfterDots
                }

                <div className="job-status__label">
                    {!showJobType
                        ? JobStatus.renderStatus(status, JobType.Request)
                        : JobStatus.renderStatusWithJobType(status, JobType.Request)
                    }
                </div>
            </div>
        );
    }

    render() {

        const { status, badge, horizontal, showJobType } = this.props;

        const mode = badge ? 'badge' : horizontal ? 'horizontal' : 'default';

        if (!status) {
            return <NotAvailablePlaceholder />;
        }

        if (JobStatus.getJobType(status) === JobType.Job) {
            return this.renderJobStatus(status, mode, showJobType);
        }

        return this.renderRequestStatus(status, mode, showJobType);
    }

}

export default JobStatus;

JobStatus.propTypes = {
    status: PropTypes.string.isRequired,
    showJobType: PropTypes.bool,
    badge: PropTypes.bool,
    horizontal: PropTypes.bool,
    renderComponentAfterDots: PropTypes.node
};

JobStatus.defaultProps = {
    horizontal: false,
    badge: false,
    renderComponentAfterDots: null
};
