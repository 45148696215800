import PropTypes from 'prop-types';
import React from 'react';
import DeleteModal from '../../../Common/Modal/DeleteModal';

const ConstructionPlanFormSectionDeleteModal = ({ onConfirm, onCancel }) => (
    <DeleteModal
        modalTitle="Gerüstabschnitt entfernen"
        deleteEntity={onConfirm}
        closeModal={onCancel}
    >
        <p>
            Sie sind im Begriff, Ihren Gerüstabschnitt aus der Baustellenplanung zu
            löschen.
        </p>
        <p>Möchten Sie fortfahren?</p>
    </DeleteModal>
);

ConstructionPlanFormSectionDeleteModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConstructionPlanFormSectionDeleteModal;
