import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import {
    addNewCustomer,
    fetchCustomerFormData,
    updateCustomer
} from '../../../redux/modules/customer/action';
import { showApiError } from '../../../redux/modules/error/action';
import { validateEmail } from '../../../utils';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import ResourceNotFoundModal from '../../Common/Modal/ResourceNotFoundModal';
import AddressDTO from '../../DTOs/AddressDTO';
import CustomerDTO from '../../DTOs/CustomerDTO';
import PersonDTO from '../../DTOs/PersonDTO';
import Checkbox from '../../Forms/Checkbox';
import Condition from '../../Forms/Condition';
import Input from '../../Forms/Input';
import InputGroup from '../../Forms/InputGroup';
import { FormMode, FormModePropType } from '../../Forms/properties';
import Section from '../../Layout/Section';
import LoadingSpinner from '../../Page/LoadingSpinner';
import CustomerMainContactFormPart from './CustomerMainContactFormPart';

/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = input => !!(input && typeof input.focus === 'function');

/**
 * Gets all the inputs inside modal on the page
 */
const getAllInputs = () => {

    const inputFields = window.document.getElementById('customer-modal')
        .querySelectorAll('input, select');

    if (typeof inputFields === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(inputFields)
        .reduce((accumulator, input) => {
            if (isFocusableInput(input)) {
                return accumulator.concat(input);
            }

            return accumulator;
        }, []);
};

const focusOnError = createDecorator(getAllInputs);

class CustomerFormModal extends Component {

    pristine = true;

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            mode: this.props.mode,
            customerId: this.props.id,
            customer: null,
            formData: {},
            isSaving: false,
            isFetching: this.props.mode === FormMode.Edit
        };

        this.prepareFormData = this.prepareFormData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
    }

    validate(values) {

        const errors = {};
        const { mode } = this.state;

        // Stammdaten
        if (!values.name) {
            errors.name = 'Bitte geben Sie einen Namen ein';
        }
        if (!values.line1) {
            errors.line1 = 'Bitte geben Sie eine Adresse ein';
        }

        if (!values.zip) {
            errors.zip = 'Bitte geben Sie eine Postleitzahl ein';
        } else if (!values.zip.toString()
            .match(/^[0-9]{5}$/i)) {
            errors.zip = 'Bitte geben Sie eine Zahl ein';
        }

        if (!values.city) {
            errors.city = 'Bitte geben Sie einen Ort ein';
        }

        // Rechnungsadresse
        if (values.differentBillingAddress) {
            if (!values.billingAddressName) {
                errors.billingAddressName = 'Bitte geben Sie einen Namen ein';
            }

            if (!values.billingAddressLine1) {
                errors.billingAddressLine1 = 'Bitte geben Sie eine Adresse ein';
            }
            if (!values.billingAddressZip) {
                errors.billingAddressZip = 'Bitte geben Sie eine Postleitzahl ein';

            } else if (!values.billingAddressZip.toString()
                .match(/^[0-9]{5}$/i)) {
                errors.billingAddressZip = 'Bitte geben Sie eine Zahl ein';
            }

            if (!values.billingAddressCity) {
                errors.billingAddressCity = 'Bitte geben Sie einen Ort ein';
            }
        }

        // Hauptkontakt

        if (mode === FormMode.Edit) {
            if (!values.mainContactId) {
                errors.mainContactId = 'Bitte wählen Sie einen Ansprechpartner als Hauptkontakt';
            }
        }

        if (mode === FormMode.Create) {
            if (!values.contactPersonFirstName) {
                errors.contactPersonFirstName = 'Bitte geben Sie einen Vornamen an';
            }

            if (!values.contactPersonLastName) {
                errors.contactPersonLastName = 'Bitte geben Sie einen Nachnamen an';
            }

            if (values.contactPersonEmail && !validateEmail(values.contactPersonEmail)) {
                errors.contactPersonEmail = 'Bitte geben Sie eine gültige E-Mail Adresse an';
            }

        }

        return errors;
    }


    componentWillMount() {
        const { mode, customerId } = this.state;

        if (mode === FormMode.Create) {
            return;
        }

        this.props.actions.fetchCustomerFormData(customerId)
            .then((customer) => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.prepareFormData(customer);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    getSubmitPayload(values) {
        const {
            name,
            customerId,
            line1,
            line2,
            city,
            zip,
            differentBillingAddress,
            contactPersonFirstName,
            contactPersonLastName,
            contactPersonEmail,
            contactPersonPhoneNumber,
            contactPersonMobilePhoneNumber,
            contactPersonPosition,
            mainContactId
        } = values;

        const { mode } = this.state;

        const customerDTO = new CustomerDTO();

        // Customer base data
        customerDTO.name = name;
        customerDTO.customerId = customerId || null;
        customerDTO.line1 = line1;
        customerDTO.line2 = line2 || null;
        customerDTO.zip = zip;
        customerDTO.city = city;
        customerDTO.country = 'DE';

        // Billing address
        if (differentBillingAddress) {

            const addressDTO = new AddressDTO();

            addressDTO.name = values.billingAddressName;
            addressDTO.line1 = values.billingAddressLine1;
            addressDTO.line2 = values.billingAddressLine2 || null;
            addressDTO.city = values.billingAddressCity;
            addressDTO.zip = values.billingAddressZip;
            addressDTO.country = 'DE';

            customerDTO.billingAddress = addressDTO;

        } else {
            customerDTO.billingAddress = null;
        }

        if (mode === FormMode.Create) {
            // Customer main contact
            const mainContact = new PersonDTO();
            mainContact.firstName = contactPersonFirstName;
            mainContact.lastName = contactPersonLastName;
            mainContact.email = contactPersonEmail;
            mainContact.phone = contactPersonPhoneNumber;
            mainContact.mobile = contactPersonMobilePhoneNumber;
            mainContact.position = contactPersonPosition;

            customerDTO.mainContact = mainContact;
            customerDTO.mainContact.isMainContact = true;

        } else if (mode === FormMode.Edit) {
            customerDTO.mainContactId = mainContactId;
        }

        return customerDTO;
    }

    onSubmit(values) {

        const payload = this.getSubmitPayload(values);


        if (this.loadingGotCancelled) {
            return;
        }

        this.setState({ isSaving: true });

        if (this.state.mode === FormMode.Edit) {
            const { customerId } = this.state;
            this.props.actions.updateCustomer(customerId, payload)
                .then((id) => {

                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });

                    if (id) {
                        this.props.onUpdated(id);
                    }
                })
                .catch((error) => {
                    showApiError(error);
                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });

        } else {
            this.props.actions.addNewCustomer(payload)
                .then((customerId) => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                    toast.success('Kunde wurde angelegt', { autoClose: 2500 });

                    if (customerId) {
                        this.props.onCreated(customerId);
                    }
                })
                .catch((error) => {

                    showApiError(error);

                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });

                });
        }
    }

    prepareFormData(customer) {

        if (!customer) {

            return this.setState({
                customer: null,
                isFetching: false
            });
        }

        const {
            customerId,
            name,
            line1,
            line2,
            city,
            billingAddress
        } = customer;

        const mainContactId = customer.mainContact && customer.mainContact.id;

        const zip = customer.zip.toString();
        const formData = {
            customerId,
            name,
            line1,
            line2,
            zip,
            city,
            mainContactId
        };

        if (billingAddress) {
            formData.differentBillingAddress = true;
            // Billing address
            formData.billingAddressName = billingAddress.name;
            formData.billingAddressLine1 = billingAddress.line1;
            formData.billingAddressLine2 = billingAddress.line2;
            formData.billingAddressZip = billingAddress.zip.toString();
            formData.billingAddressCity = billingAddress.city;
        } else {
            formData.differentBillingAddress = false;
        }

        if (this.loadingGotCancelled) {
            return false;
        }

        return this.setState({
            customer,
            formData,
            isFetching: false
        });
    }

    renderButtonText() {
        const { mode, isSaving } = this.state;
        if (mode === FormMode.Edit) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }
        return isSaving ? 'Kunde wird angelegt ...' : 'Kunde anlegen';
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose(true);
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose(true);
        }
    }

    // Will be set in the render method
    handleSubmit = () => false;

    render() {
        const {
            mode,
            customer,
            formData,
            isSaving,
            isFetching,
            customerId
        } = this.state;

        if (mode === FormMode.Edit && !isFetching && !customer) {
            return (
                <ResourceNotFoundModal
                    title="Kunde nicht gefunden"
                    onClose={this.props.onClose}
                />
            );
        }

        return (
            <Modal
                title={mode === FormMode.Create ? 'Kunde anlegen' : 'Kunde bearbeiten'}
                id="customer-modal"
                footer={
                    <ModalFormFooter
                        onSubmit={(e) => this.handleSubmit(e)}
                        isSubmitting={isSaving}
                        onClose={() => this.onClose(this.pristine)}
                        submitLabel={this.renderButtonText()}
                        submitDisabled={isFetching}
                    />
                }
                loading={isFetching}
                loadingLabel="Lade Kundendaten"
            >
                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    initialValues={formData}
                    mutators={{
                        setField: ([name, value], state, utils) => {
                            utils.changeValue(state, name, () => value);
                        }
                    }}
                    decorators={[focusOnError]}
                    render={({ handleSubmit, values, pristine, form: { mutators } }) => {
                        this.handleSubmit = handleSubmit;
                        this.pristine = pristine;

                        return (
                            <form onSubmit={handleSubmit}>
                                <Section
                                    title="Stammdaten"
                                >
                                    <InputGroup
                                        label="Vollständiger Name*"
                                    >
                                        <Input
                                            type="text"
                                            name="name"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Kunden-ID"
                                    >
                                        <Input
                                            type="text"
                                            name="customerId"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Adresszeile 1*"
                                    >
                                        <Input
                                            type="text"
                                            name="line1"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Adresszeile 2"
                                    >
                                        <Input
                                            type="text"
                                            name="line2"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Postleitzahl, Ort*"
                                        horizontal
                                    >
                                        <div className="input-grp__field--zip">
                                            <Input
                                                type="text"
                                                name="zip"
                                                placeholder="PLZ"
                                            />
                                        </div>
                                        <div className="input-grp__field--city">
                                            <Input
                                                type="text"
                                                name="city"
                                                placeholder="Ort"
                                            />
                                        </div>
                                    </InputGroup>
                                </Section>
                                <Section
                                    title="Rechnungsadresse"
                                >
                                    <InputGroup
                                        label="Angaben"
                                    >
                                        <Checkbox
                                            name="differentBillingAddress"
                                            label="Abweichende Rechnungsadresse"
                                        />
                                    </InputGroup>

                                    <Condition when="differentBillingAddress" is>
                                        <InputGroup
                                            label="Vollständiger Name*"
                                        >
                                            <Input
                                                type="text"
                                                name="billingAddressName"
                                            />
                                        </InputGroup>

                                        <InputGroup
                                            label="Adresszeile 1*"
                                        >
                                            <Input
                                                type="text"
                                                name="billingAddressLine1"
                                            />
                                        </InputGroup>
                                        <InputGroup
                                            label="Adresszeile 2"
                                        >
                                            <Input
                                                type="text"
                                                name="billingAddressLine2"
                                            />
                                        </InputGroup>

                                        <InputGroup
                                            label="Postleitzahl, Ort*"
                                            horizontal
                                        >
                                            <div className="input-grp__field--zip">
                                                <Input
                                                    type="text"
                                                    name="billingAddressZip"
                                                    placeholder="PLZ"
                                                />
                                            </div>
                                            <div className="input-grp__field--city">
                                                <Input
                                                    type="text"
                                                    name="billingAddressCity"
                                                    placeholder="Ort"
                                                />
                                            </div>
                                        </InputGroup>
                                    </Condition>
                                </Section>

                                <CustomerMainContactFormPart
                                    mode={mode}
                                    onLoadingMainContactOptions={this.onLoadingMainContactOptions}
                                    mutators={mutators}
                                    customerId={customerId}
                                    values={values}
                                />
                            </form>
                        );
                    }}
                />
            </Modal>
        );
    }
}

CustomerFormModal.propTypes = {
    mode: FormModePropType,
    id: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func
};

CustomerFormModal.defaultProps = {
    mode: FormMode.Create,
    id: null,
    onCreated: () => false,
    onUpdated: () => false,
    onClose: () => false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCustomerFormData,
        addNewCustomer,
        updateCustomer
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(CustomerFormModal);
