import PropTypes from 'prop-types';
import { ConstructionPlanModelMode } from '../properties';

export const ConstructionPlanModelModePropType = PropTypes.oneOf([
    ConstructionPlanModelMode.New,
    ConstructionPlanModelMode.Reference,
    ConstructionPlanModelMode.Edit
]);

export const ConstructionPlanMediaPropType = PropTypes.shape({
    id: PropTypes.string,
    mode: ConstructionPlanModelModePropType,
    name: PropTypes.string,
    fileName: PropTypes.string,
    size: PropTypes.number,
    mimeType: PropTypes.string
});

export const ConstructionPlanFormSectionPropType = PropTypes.shape({
    id: PropTypes.string,
    mode: ConstructionPlanModelModePropType,
    data: PropTypes.oneOfType([
        PropTypes.shape({
            attachments: PropTypes.arrayOf(ConstructionPlanMediaPropType),
            utilizationPlans: PropTypes.arrayOf(ConstructionPlanMediaPropType),
            anchorProtocol: ConstructionPlanMediaPropType,
            proofOfStability: ConstructionPlanMediaPropType
        }),
        PropTypes.array
    ])
});

export const ConstructionPlanFormScaffoldingPropType = PropTypes.shape({
    id: PropTypes.string,
    mode: ConstructionPlanModelModePropType,
    data: PropTypes.oneOfType([
        PropTypes.shape({
            attachments: PropTypes.arrayOf(ConstructionPlanMediaPropType),
            utilizationPlans: PropTypes.arrayOf(ConstructionPlanMediaPropType),
            anchorProtocol: ConstructionPlanMediaPropType
        }),
        PropTypes.array
    ]),
    sections: PropTypes.arrayOf(ConstructionPlanFormSectionPropType)
});

export const ConstructionPlanFormSitePropType = PropTypes.shape({
    id: PropTypes.string,
    mode: ConstructionPlanModelModePropType,
    data: PropTypes.oneOfType([
        PropTypes.shape({
            attachments: PropTypes.arrayOf(ConstructionPlanMediaPropType)
        }),
        PropTypes.array
    ]),
    scaffoldings: PropTypes.arrayOf(ConstructionPlanFormScaffoldingPropType)
});

export const ConstructionPlanFormPropType = PropTypes.shape({
    site: ConstructionPlanFormSitePropType
});
