import React, { Fragment } from 'react';
import Breadcrumb from '../../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../../Navigation/Breadcrumbs/BreadcrumbLink';

const SubscriptionDetailsBreadcrumb = () => {

    return (
        <Breadcrumb>
            <Fragment>
                <BreadcrumbLink
                    label="Abo & Rechnung"
                    path="/account/subscription"
                    icon="settings"
                />
                <BreadcrumbLink
                    label="Abonnement"
                    path="/account/subscription/details"
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default SubscriptionDetailsBreadcrumb;

