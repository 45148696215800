import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { JobTableRow } from '../Overview/JobTableRow';
import { getJobTableColumns } from '../properties';
import { ReferencedJobsTableBase } from './ReferencedJobsTableBase';

export const ReferencedJobsTable = ({ data, onItemChange, emptyTableHint }) => (
    <ReferencedJobsTableBase
        data={data}
        onItemChange={onItemChange}
        columns={getJobTableColumns()}
        RowComponent={JobTableRow}
        emptyTableHint={emptyTableHint}
        entityLabel="Aufträge"
        footerLink={<Link to="/jobs">Alle Aufträge anzeigen</Link>}
    />
);

ReferencedJobsTable.propTypes = {
    data: PropTypes.array.isRequired,
    emptyTableHint: PropTypes.element.isRequired,
    onItemChange: PropTypes.func
};
