import React from 'react';
import { ResourceViewMode } from '../../../Containers/Pages/Private/properties';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import EmptyDataTableHint from '../../Common/EmptyDataTableHint';
import IfUser from '../../Common/IfUser';
import InvitedIndicator from '../../Common/InvitedIndicator';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import PrimaryColumn from '../../Common/PrimaryColumn';
import { SectionPageLink } from '../../Common/ResourcePageLink';
import Table from '../../Common/Table';
import ThirdPartyIndicator from '../../Common/ThirdPartyIndicator';
import UnmanagedResourceIndicator from '../../Common/UnmanagedIndicator';
import { showEquippedSurfaces, showPlannedWorks } from '../../Sections/helpers';
import { SectionPurpose } from '../../Sections/SectionPurpose';
import SectionStatusBadge from '../../Sections/SectionStatusBadge';

function getHintSub(section) {

    if (!section) {
        return null;
    }

    if (section.isThirdParty && !section.isInvited) {
        return <ThirdPartyIndicator size="small"/>;
    }

    if (section.isThirdParty && section.isInvited) {
        return <InvitedIndicator size="small"/>;
    }

    if (!section.managed) {
        return (
            <UnmanagedResourceIndicator
                label="Fremder Gerüstabschnitt"
                size="small"
            />
        );
    }

    return null;
}

export const ScaffoldingSectionsTable = ({ sections, viewMode}) => {

    let sectionTableHeader = [
        'Gerüstabschnitt',
        'Status',
        'Eingerüstete Flächen',
        'Verwendungszeck',
        'Geplante Arbeiten',
    ];

    const sectionTableNumColumns = sectionTableHeader.length;

    return (
        <Table
            columns={sectionTableHeader}
            rows={sections}
            emptyComponent={
                <EmptyDataTableHint
                    numColumns={sectionTableNumColumns}
                    title="Es wurde noch kein Gerüstabschnitt für dieses Gerüst angelegt"
                >
                    <p>
                        Gerüstabschnitte dienen der technischen Spezifizierung Ihrer
                        Gerüste.
                    </p>

                    <p> Sie können einen neuen Gerüstabschnitt über den
                        Button &quot;Neuer
                        Gerüstabschnitt&quot;  am oberen rechten Rand dieser Box oder
                        über
                        den grünen &quot;Erstellen&quot; Button am oberen rechten Rand
                        der Seite anlegen.
                    </p>

                    <p> Bitte beachten Sie, dass Gerüste immer aus mindestens einem
                        Gerüstabschnitt bestehen müssen, damit Prüfungen und Freigaben
                        durchgeführt und Aushänge generiert werden können.
                    </p>
                </EmptyDataTableHint>
            }
        >
            {
                section => (
                    <tr key={section.id}>
                        <PrimaryColumn
                            link={
                                <SectionPageLink
                                    id={section.id}
                                >
                                    {section.name}
                                </SectionPageLink>
                            }
                            sub={getHintSub(section)}
                            extra={section.archived &&
                            <ArchivedBadge size="small"/>}
                        >
                            <SectionPageLink
                                id={section.id}
                            >Details</SectionPageLink>

                            {(viewMode !== ResourceViewMode.Archived) &&
                            <IfUser
                                canOneOf={['delete-sections']}
                                and={!section.isThirdParty}
                            >
                                <a
                                    onClick={() => this.toggleDeleteSectionModal({
                                        id: section.id,
                                        name: section.name,
                                    })}
                                >
                                    Entfernen
                                </a>
                            </IfUser>
                            }
                        </PrimaryColumn>
                        <td width="15%" style={{ maxWidth: 200 }}>
                            <SectionStatusBadge
                                section={section}
                                small
                            />
                        </td>
                        <td width="20%">
                            {
                                section.equippedSurfaces ? showEquippedSurfaces(section.equippedSurfaces)
                                    :
                                    <NotAvailablePlaceholder/>
                            }
                        </td>
                        <td width="20%">
                            <SectionPurpose purposes={section.purposes} otherProtectivePurpose={section.otherProtectivePurpose} />
                        </td>
                        <td width="20%">
                            {section.plannedWorks ?
                                showPlannedWorks(section.plannedWorks)
                                :
                                <NotAvailablePlaceholder/>
                            }
                        </td>
                    </tr>
                )
            }
        </Table>
    )
}
