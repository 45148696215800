import React, { Component } from 'react';
import ArrowDownward from 'react-icons/lib/md/arrow-drop-down';
import ArrowUpward from 'react-icons/lib/md/arrow-drop-up';
import PropTypes from 'prop-types';

class SortLink extends Component {
    render() {
        const {
            to, direction, field, getClasses, sortData, label, fieldValue
        } = this.props;

        let iconCtClasses = 'table-sort-icn-ct';

        if (field === fieldValue) {
            if (direction === '-') {
                iconCtClasses += ' desc';
            } else {
                iconCtClasses += ' asc';
            }
        }

        return (
            <span
                to={to}
                className={getClasses(field)}
                onClick={() => sortData(field)}
            >
                {label}
                <span className={iconCtClasses}>
                    <ArrowUpward className="table-sort-icn table-sort-icn--asc" />
                    <ArrowDownward className="table-sort-icn table-sort-icn--desc" />
                </span>
            </span>
        );
    }
}

export default SortLink;

SortLink.propTypes = {
    direction: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    fieldValue: PropTypes.string.isRequired,
    getClasses: PropTypes.func,
    label: PropTypes.string.isRequired,
    sortData: PropTypes.func,
    to: PropTypes.string
};

SortLink.defaultProps = {
    getClasses: () => false,
    sortData: () => false,
    to: '#'
};

