import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Modal from './Modal';

const links = [
    {
        label: 'Home',
        value: '/'
    },
    {
        label: 'Aufträge',
        value: '/jobs'
    },
    {
        label: 'Baustellen und Gerüste',
        value: '/sites'
    },
    {
        label: 'Kunden',
        value: '/customers'
    },
    {
        label: 'Mitarbeiter',
        value: '/employees'
    },
    {
        label: 'Kontoeinstellungen',
        value: '/account'
    }
];

class ResourceNotFoundModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: false,
        };
        this.close = this.close.bind(this);
    }

    static renderLinks() {
        return links.map((link, i) => (
            <span
                onClick={() => this.handleClick(link.value)}
                key={i}
            >
                {link.label}
            </span>
        ));
    }

    handleClick(dest) {
        this.props.history.push(dest);
    }

    close() {
        this.setState({
            closed: true
        });
        const { onClose } = this.props;
        if (typeof onClose === 'function') {
            onClose();
        }
    }

    render() {
        if (this.state.closed) {
            return null;
        }

        return (
            <Modal
                id="modal-delete"
                title={this.props.title}
                type="confirmation"
                onBackdropClick={() => this.props.onClose}
                footer={
                    <div className="btn-group">
                        <button
                            className="btn btn--backward-action"
                            onClick={this.close}
                        >
                            Schließen
                        </button>
                    </div>
                }
            >
                <p>Scheinbar wurden die angeforderten Daten mittlerweile gelöscht.</p>
                <p>Hier geht's weiter:</p>
                <div className="app-error__recoverylinks">
                    {ResourceNotFoundModal.renderLinks()}
                </div>
            </Modal>
        );
    }
}

export default withRouter(ResourceNotFoundModal);

ResourceNotFoundModal.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func
};

ResourceNotFoundModal.defaultProps = {
    onClose: null
};
