import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { getCompanyName, getUserName } from '../../../redux/helper';
import Icon from '../Icon';
import { sectionLinks, SettingsKey } from './properties';
import SettingsDropdownSection from './SettingsDropdownSection';

class SettingsDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false
        };
    }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }

    handleClickOutside() {
        if (this.state.listOpen) {
            this.setState({
                listOpen: false
            });
        }
    }

    render() {
        const { listOpen } = this.state;
        const { userName, companyName } = this.props;

        return (
            <div className="settingsDropdown">
                <div className="settingsDropdown__header" onClick={() => this.toggleList()}>
                    <div className="settingsDropdown__header-title">
                        <span className="settingsDropdown__header-title-avatar"> <Icon
                            name="avatarPlaceholder"
                        /></span>
                        {userName}
                        {!listOpen
                            ?
                            <span className="settingsDropdown__header-title-chevron"><Icon
                                name="chevronDown"
                            /></span>
                            :
                            <span className="settingsDropdown__header-title-chevron"><Icon
                                name="chevronUp"
                            /></span>
                        }
                    </div>
                </div>
                {listOpen &&
                <div className="settingsDropdown__cnt">
                    <SettingsDropdownSection
                        sectionLabel={userName}
                        sectionLinks={sectionLinks[SettingsKey.UserSettings]}
                    />
                    <SettingsDropdownSection
                        sectionLabel={companyName}
                        sectionLinks={sectionLinks[SettingsKey.CompanySettings]}
                    />
                    <SettingsDropdownSection
                        sectionLabel="Sonstiges"
                        sectionLinks={sectionLinks[SettingsKey.MiscSettings]}
                    />
                    <SettingsDropdownSection
                        sectionLinks={sectionLinks[SettingsKey.Logout]}
                    />
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userName: getUserName(state),
        companyName: getCompanyName(state)
    };
};

export default connect(mapStateToProps, null)(onClickOutside(SettingsDropdown));
