const stripeInputStyles = {
    style: {
        base: {
            fontWeight: '300',
            fontSize: '16px',
            '::placeholder': {
                color: '#81858D'
            },
            color: '#0D1626'
        },
        invalid: {
            color: '#E64D45',
            border: 'none'
        }
    }
};

export default stripeInputStyles;
