import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Input from '../../../Components/Forms/Input';
import InputGroup from '../../../Components/Forms/InputGroup';
import LoadingSpinner from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import { showApiError } from '../../../redux/modules/error/action';
import { forgotPassword } from '../../../redux/modules/user/action';
import { BadRequestError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import { validateEmail } from '../../../utils';
import PublicFooter from './PublicFooter';
import PublicPage from './PublicPage';

const focusOnError = createDecorator();

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResetting: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {

        this.setState({
            isResetting: true
        });

        const credentials = {
            email: values.email
        };

        return this.props.actions.forgotPassword(credentials)
            .then(() => {
                this.setState({
                    isResetting: false
                });
            })
            .catch(error => {
                this.setState({
                    isResetting: false
                });

                if (error instanceof BadRequestError) {
                    return ErrorService.handleBadRequestError(error, 'forgotpassword')
                        .then(data => ({ [FORM_ERROR]: data.email }));
                }

                return showApiError(error);
            });
    };

    validate(values) {
        const errors = {};
        // E-Mail
        if (!values.email) {
            errors.email = 'Bitte geben Sie eine E-Mail Adresse ein';
        } else if (!validateEmail(values.email)) {
            errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse ein';
        }
        return errors;
    };


    render() {

        const { isResetting } = this.state;

        return (
            <PublicPage>
                <TopNav isPublic />

                <div className="forgot-password">
                    <h1>Passwort zurücksetzen</h1>
                    <div className="section section--login">
                        <p>Bitte geben Sie Ihre E-Mail Adresse an, damit wir Ihnen einen Link zum
                            Zurücksetzen Ihres Passwortes zusenden können. </p>

                        <Form
                            onSubmit={this.onSubmit}
                            decorators={[focusOnError]}
                            validate={this.validate}
                            render={({ handleSubmit, submitError }) => (
                                <form onSubmit={handleSubmit}>
                                    {submitError && <div className="form-error">{submitError}</div>}
                                    <InputGroup
                                        label="E-Mail Adresse*"
                                    >
                                        <Input
                                            type="text"
                                            name="email"
                                            placeholder="E-Mail Adresse eingeben"
                                        />
                                    </InputGroup>
                                    <div className="hints flex justify-content-between">
                                        <span>* Pflichtfeld</span>
                                    </div>
                                    <div className="flex justify-content-between">
                                        <Link className="btn btn--backward-action" to="/login"
                                        >Zurück</Link>
                                        <button
                                            type="submit"
                                            className="btn btn--primary-green"
                                            disabled={isResetting}
                                        >
                                            {isResetting ? <LoadingSpinner
                                                label="Passwort zurücksetzen"
                                            /> : 'Passwort zurücksetzen'}
                                        </button>
                                    </div>

                                </form>
                            )}
                        />
                    </div>
                    <PublicFooter />
                </div>
            </PublicPage>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ forgotPassword }, dispatch)
});

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);

ForgotPasswordPage.propTypes = {
    actions: PropTypes.shape({
        forgotPassword: PropTypes.func
    })
};

ForgotPasswordPage.defaultProps = {
    actions: {
        forgotPassword: () => false
    }
};
