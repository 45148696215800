import PropTypes from 'prop-types';
import React from 'react';
import { ConstructionPlanModelModePropType } from '../../ConstructionPlan/Form/propTypes';
import InputGroup from '../../Forms/InputGroup';
import { FormModePropType } from '../../Forms/properties';
import Select from '../../Forms/Select';
import Textarea from '../../Forms/Textarea';
import Section from '../../Layout/Section';
import SectionAffiliationInput from './Fields/SectionAffiliationInput';
import { SectionApprovedByNameInput } from './Fields/SectionApprovedByNameInput';
import { SectionCladdingInput } from './Fields/SectionCladdingInput';
import { SectionConstructionTypeInput } from './Fields/SectionConstructionTypeInput';
import { SectionContractorInput } from './Fields/SectionContractorInput';
import { SectionCustomerInput } from './Fields/SectionCustomerInput';
import { SectionEquippedSurfacesInput } from './Fields/SectionEquippedSurfacesInput';
import { SectionLoadClassInput } from './Fields/SectionLoadClassInput';
import { SectionNameInput } from './Fields/SectionNameInput';
import { SectionPlannedWorkInput } from './Fields/SectionPlannedWorkInput';
import { SectionPurposesInput } from './Fields/SectionPurposesInput';
import { SectionSpecialFeaturesInput } from './Fields/SectionSpecialFeaturesInput';
import { SectionStabilityAndLoadSafetyInput } from './Fields/SectionStabilityAndLoadSafetyInput';
import { SectionStatusInput } from './Fields/SectionStatusInput';
import { SectionWallClearanceInput } from './Fields/SectionWallClearanceInput';
import { SectionWidthClassInput } from './Fields/SectionWidthClassInput';
import { SupportTypeOptions } from './options';
import SectionMediaFormPart from './SectionMediaFormPart';
import { isRequired } from './validate';

const SectionFormContent = (props) => {

    const {
        values,
        readOnly,
        onUploading,
        isConstructionPlan,
        mutators
    } = props;

    const wrapperClasses = ['form-content'];

    if (readOnly) {
        wrapperClasses.push('form-content--disabled');
    }

    return (
        <div className="section-form-cnt">

            <Section
                title="Allgemeine Angaben"
                collapsible={isConstructionPlan}
            >
                <div className={wrapperClasses.join(' ')}>

                    <SectionNameInput readOnly={readOnly} />

                    <SectionAffiliationInput
                        section={props.section}
                        values={values}
                        onFetchingSites={props.onFetchingSites}
                        onChangeSite={props.onChangeSite}
                        onFetchingScaffoldings={props.onFetchingScaffoldings}
                        onChangeScaffolding={props.onChangeScaffolding}
                        mode={props.mode}
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />

                    <SectionStatusInput
                        values={values}
                        isConstructionPlan={isConstructionPlan}
                    />

                    <SectionCustomerInput />

                    <SectionContractorInput
                        managed={values.managed}
                        scaffoldingId={values.scaffoldingId}
                        isFetchingContractors={props.isFetchingContractors}
                        contractorChoices={props.contractorChoices}
                        isConstructionPlan={props.isConstructionPlan}
                    />

                    <SectionApprovedByNameInput
                        managed={values.managed}
                        scaffoldingId={values.scaffoldingId}
                        readOnly={readOnly}
                    />

                    <hr />

                    <SectionEquippedSurfacesInput
                        readOnly={readOnly}
                        values={values}
                    />

                    <hr />

                    <SectionPurposesInput values={values} mutators={mutators} />

                    <SectionPlannedWorkInput values={values} />

                </div>
            </Section>

            <Section
                title="Spezifikationen"
                collapsible={isConstructionPlan}
                isCollapsed={isConstructionPlan && !readOnly}
            >
                <div className={wrapperClasses.join(' ')}>

                    <InputGroup
                        label="Tragsystem"
                        required={isRequired('supportType', values)}
                    >
                        <Select
                            name="supportType"
                            options={SupportTypeOptions}
                        />
                    </InputGroup>

                    <SectionConstructionTypeInput values={values} />

                    <SectionStabilityAndLoadSafetyInput values={values}/>

                    <hr />

                    <SectionLoadClassInput
                        values={values}
                    />

                    <SectionWallClearanceInput values={values} />

                    <SectionWidthClassInput values={values} />

                    <hr />

                    <SectionCladdingInput status={values.status} />

                    <SectionSpecialFeaturesInput />

                </div>
            </Section>

            <Section
                title="Notizen"
                collapsible={isConstructionPlan}
                isCollapsed
            >
                <div className={wrapperClasses.join(' ')}>
                    <Textarea
                        name="description"
                        placeholder="Notizen eingeben"
                        className="section-form__description"
                    />
                </div>
            </Section>

            <Section
                title="Dokumente"
                collapsible
                isCollapsed={!values.staticsCertificateNecessary && !readOnly}
                style={{
                    position: 'relative',
                    zIndex: 4
                }}
                id="section-form-upload-documents"
            >
                <SectionMediaFormPart
                    values={values}
                    attachmentsMutator={mutators.setAttachments}
                    utilizationPlanMutator={mutators.setUtilizationPlans}
                    anchorProtocolMutator={mutators.setAnchorProtocol}
                    proofOfStabilityMutator={mutators.setProofOfStability}
                    onUploading={onUploading}
                    isConstructionPlan={isConstructionPlan}
                    readOnly={readOnly}
                />
            </Section>
        </div>
    );
};

SectionFormContent.propTypes = {
    values: PropTypes.object.isRequired,
    mode: FormModePropType.isRequired,
    mutators: PropTypes.object.isRequired,
    onUploading: PropTypes.func.isRequired,
    userPermissions: PropTypes.array,
    isFetchingContractors: PropTypes.bool,
    onFetchingSites: PropTypes.func,
    onFetchingScaffoldings: PropTypes.func,
    contractorChoices: PropTypes.array,
    readOnly: PropTypes.bool,
    section: PropTypes.object,
    isConstructionPlan: PropTypes.bool,
    constructionPlanMode: ConstructionPlanModelModePropType,
    onChangeSite: PropTypes.func,
    onChangeScaffolding: PropTypes.func
};

SectionFormContent.defaultProps = {
    userPermissions: [],
    isFetchingContractors: false,
    onFetchingSites: () => false,
    onFetchingScaffoldings: () => false,
    contractorChoices: [],
    readOnly: false,
    section: null,
    isConstructionPlan: false,
    constructionPlanMode: undefined,
    onChangeSite: () => {
    },
    onChangeScaffolding: () => {
    }
};

export default SectionFormContent;
