import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchCustomerContactSelectOptions } from '../../../redux/modules/customer/action';
import InputGroup from '../../Forms/InputGroup';
import { FormMode } from '../../Forms/properties';
import Select from '../../Forms/Select';
import CustomerContactFormModal, {
    MODAL_CONTEXT_CUSTOMER_CREATION,
    MODAL_CONTEXT_JOB_REQUEST_CREATION,
    MODAL_CONTEXT_STANDALONE
} from './CustomerContactFormModal';

const createNewCustomerContactOption = {
    label: '+ Neuen Ansprechpartner anlegen',
    value: '-1'
};

class CustomerContactSelectionFormPart extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;

        this.state = {
            mainContactChoices: [],
            isLoadingMainContactOptions: false,
            showAddContactModal: false
        };

        this.fetchContactOptions = this.fetchContactOptions.bind(this);
        this.toggleAddContactModal = this.toggleAddContactModal.bind(this);
        this.onMainContactSelectionChange = this.onMainContactSelectionChange.bind(this);
        this.setDefaultValue = this.setDefaultValue.bind(this);
    }

    setCustomerContactOptions(contactOptions) {

        const { modalContext } = this.props;

        const mainContactChoices = [createNewCustomerContactOption];

        if (!contactOptions || !contactOptions.length) {
            return mainContactChoices;
        }

        contactOptions.forEach(contactOption => {

            const { id, firstName, lastName, isMainContact, position } = contactOption;

            mainContactChoices.push({
                value: id,
                label: `${firstName} ${lastName} ${isMainContact && (modalContext === MODAL_CONTEXT_JOB_REQUEST_CREATION) ? ' (Hauptkontakt)' : ''}`
            });
        });

        this.setState({
            mainContactChoices
        });
    }

    setDefaultValue(customerContacts) {

        const { mutators, name, values } = this.props;

        // find mainContact and set as default value
        const { id: mainContactId } = customerContacts.filter(contact => contact.isMainContact)[0];

        // Customer contact already selected
        if (values[name]) {
            return;
        }

        mutators.setField(name, mainContactId);

    }

    fetchContactOptions() {
        const { actions: { fetchCustomerContactSelectOptions }, customerId } = this.props;

        return fetchCustomerContactSelectOptions(customerId)
            .then(({ customerContacts }) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setDefaultValue(customerContacts);

                this.setCustomerContactOptions(customerContacts);
            });
    }

    componentDidMount() {

        const { disabled } = this.props;

        if (disabled) {
            return;
        }

        this.loadMainContactOptions();
    }

    loadMainContactOptions() {
        this.updateLoadingState(true);

        this.fetchContactOptions()
            .catch(() => {
                toast.error('Ansprechpartner konnten nicht geladen werden', { autoClose: 4000 });
            })
            .finally(() => {
                this.updateLoadingState(false);
            });

    }

    updateLoadingState(isLoading) {

        if (this.loadingGotCancelled) {
            return;
        }

        this.setState({
            isLoadingMainContactOptions: isLoading
        }, () => {
            const { onLoadingMainContactOptions } = this.props;

            onLoadingMainContactOptions(isLoading);

        });
    }

    onMainContactSelectionChange(value) {
        if (value === '-1') {
            this.toggleAddContactModal();
        }
    }

    toggleAddContactModal() {
        this.setState(prevState => ({
            showAddContactModal: !prevState.showAddContactModal
        }));
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onContactCreated() {
        this.toggleAddContactModal();
        this.loadMainContactOptions();
    }

    renderPlaceholder() {
        const { disabled, disabledPlaceholder } = this.props;
        const { isLoadingMainContactOptions } = this.state;

        if (disabled) {
            return disabledPlaceholder;
        }

        return isLoadingMainContactOptions ? 'Lade Ansprechpartner ...' : 'Ansprechpartner auswählen';
    }

    render() {

        const { isLoadingMainContactOptions, mainContactChoices, showAddContactModal } = this.state;
        const { mutators, customerId, disabled, isRequired, name, modalContext } = this.props;

        return (
            <Fragment>
                <InputGroup
                    label={`Ansprechpartner ${isRequired ? '*' : ''}`}
                >
                    <Select
                        name={name}
                        placeholder={this.renderPlaceholder()}
                        options={mainContactChoices}
                        disabled={isLoadingMainContactOptions || disabled}
                        onChange={this.onMainContactSelectionChange}
                    />
                </InputGroup>
                {
                    showAddContactModal &&
                    <CustomerContactFormModal
                        onCreated={(contactId) => {
                            mutators.setField(name, contactId);
                            this.onContactCreated();
                        }}
                        onClose={(wasCancelled = false) => {
                            if (wasCancelled) {
                                mutators.setField(name, null);
                            }
                            this.toggleAddContactModal();
                        }}
                        mode={FormMode.Create}
                        modalContext={modalContext}
                        customerId={customerId}
                    />
                }
            </Fragment>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCustomerContactSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(CustomerContactSelectionFormPart);

CustomerContactSelectionFormPart.propTypes = {
    mutators: PropTypes.object.isRequired,
    onLoadingMainContactOptions: PropTypes.func,
    modalContext: PropTypes.oneOf([MODAL_CONTEXT_CUSTOMER_CREATION, MODAL_CONTEXT_STANDALONE, MODAL_CONTEXT_JOB_REQUEST_CREATION]),
    customerId: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    disabledPlaceholder: PropTypes.string,
    isRequired: PropTypes.bool
};

CustomerContactSelectionFormPart.defaultProps = {
    onLoadingMainContactOptions: () => false,
    name: 'mainContactId',
    modalContext: MODAL_CONTEXT_CUSTOMER_CREATION
};


