import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ModelType } from '../../properties';
import InputGroup from '../Forms/InputGroup';
import MediaField from '../Forms/Media/Field/MediaField';
import MediaUploadButton from '../Forms/Media/MediaUploadButton';
import MediaUploadModal from '../Forms/Media/Modal/MediaFormModal';
import { MediaFormDataPropType } from '../Media/properties';

class ConstructionDiaryEntryMediaFormPart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadModalIsOpen: false
        };

        this.openMediaUploadModal = this.openMediaUploadModal.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.closeMediaUploadModal = this.closeMediaUploadModal.bind(this);
    }

    openMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: true
        });
    }

    closeMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: false
        });
    }

    onUploaded(mediaList) {

        const { attachments } = this.props;

        let newAttachments;

        if (attachments) {
            newAttachments = [
                ...attachments,
                ...mediaList
            ];
        } else {
            newAttachments = [
                ...mediaList
            ];
        }

        this.props.attachmentsMutator(newAttachments);
    }

    render() {
        const { uploadModalIsOpen } = this.state;

        return (
            <Fragment>
                <InputGroup
                    label="Anhänge"
                >
                    <MediaField
                        name="attachments"
                        emptyListLabel="Keine Anhänge vorhanden"
                    />
                </InputGroup>

                <InputGroup>
                    <MediaUploadButton
                        onClick={this.openMediaUploadModal}
                        label="Anhänge hochladen"
                    />
                </InputGroup>

                {uploadModalIsOpen &&
                <MediaUploadModal
                    onUploading={this.props.onUploading}
                    onUploaded={this.onUploaded}
                    modelType={ModelType.ConstructionDiaryEntry}
                    onClose={this.closeMediaUploadModal}
                />
                }
            </Fragment>
        );
    }
}

ConstructionDiaryEntryMediaFormPart.propTypes = {
    attachments: PropTypes.arrayOf(MediaFormDataPropType).isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    onUploading: PropTypes.func
};

ConstructionDiaryEntryMediaFormPart.defaultProps = {
    onUploading: undefined
};

export default ConstructionDiaryEntryMediaFormPart;
