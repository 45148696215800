export default class StripeElementsError {
    constructor(error) {
        this.errorMessage = error.message;
        this.error = error;
    }

    getErrorMessage() {
        return this.errorMessage;
    }
}

