import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getAccountType } from '../../../redux/helper';
import { BillingTypes } from '../BillingDetailsForm';
import { AccountTypes } from '../subscriptionTypes';

const InvoiceBillingDeprecatedWarningBanner = (props) => {

    const { billingInformation, accountType } = props;
    if (!billingInformation || accountType === AccountTypes.light) {
        return null;
    }

    if (billingInformation.type !== BillingTypes.Invoice) {
        return null;
    }

    return (
        <div className="warning-banner">
            Leider können wir die Zahlungsmöglichkeit “Rechnung” in Zukunft nicht mehr anbieten.
            Ab sofort steht Ihnen als
            Zahlungsmöglichkeit <em>SEPA-Lastschriftmandat</em> und <em>Kreditkarte</em> zur
            Verfügung.
            Bitte ändern Sie Ihre Zahlungsdetails bis zum 31. März.{' '}
            {props.showLink &&
            <Link
                to="/account/subscription/edit/billingdetails"
            >
                Jetzt anpassen
            </Link>
            }
        </div>
    );
};

InvoiceBillingDeprecatedWarningBanner.propTypes = {
    billingInformation: PropTypes.object,
    showLink: PropTypes.bool
};

InvoiceBillingDeprecatedWarningBanner.defaultProps = {
    billingInformation: null,
    showLink: false
};

const mapStateToProps = (state) => {
    const accountType = getAccountType(state);
    return {
        accountType
    };
};

export default withRouter(connect(mapStateToProps, null)(InvoiceBillingDeprecatedWarningBanner));
