import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ArchivedBadge from '../../../Common/Badge/ArchivedBadge';
import DataRow from '../../../Common/DataRow';
import NotAvailablePlaceholder from '../../../Common/NotAvailablePlaceholder';
import ScaffoldingServiceTime from '../../../Scaffoldings/Detail/ScaffoldingServiceTime';
import ConstructionPlanModelModeIcon from '../../ConstructionPlanModelModeIcon';
import { ConstructionPlanModelMode } from '../../properties';
import { getData } from '../../utils';
import ConstructionPlanFormGroup, { ConstructionPlannerSectionType } from '../Common/ConstructionPlanFormGroup';
import ConstructionPlanFormGroupTitle from '../Common/ConstructionPlanFormGroupTitle';
import ConstructionPlanFormModelEditHint from '../Common/ConstructionPlanFormModelEditHint';
import { ConstructionPlanFormScaffoldingPropType } from '../propTypes';
import ConstructionPlanFormSectionList from '../Section/ConstructionPlanFormSectionList';
import ConstructionPlannerScaffoldingSelectionActions
    from './ConstructionPlanFormScaffoldingActions';
import ConstructionPlanFormScaffoldingDeleteModal
    from './ConstructionPlanFormScaffoldingDeleteModal';
import ConstructionPlanScaffoldingFormModal from './Modal/ConstructionPlanScaffoldingFormModal';

class ConstructionPlanFormScaffolding extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFormModal: false,
            showDeleteModal: false
        };

        this.getSectionsReferenceData = this.getSectionsReferenceData.bind(this);

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onReferenceAll = this.onReferenceAll.bind(this);
        this.delete = this.delete.bind(this);

        this.closeFormModal = this.closeFormModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.onChangeSections = this.onChangeSections.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (
            prevProps.scaffolding
            && !prevProps.scaffolding.mode
            && this.props.scaffolding
            && this.props.scaffolding.mode
        ) {
            this.setState({
                showFormModal: true
            });
        }
    }

    getSectionsReferenceData() {
        const { referenceData } = this.props;
        if (!referenceData) {
            return null;
        }

        return referenceData.sections;
    }

    getName() {
        return this.getData('name');
    }

    getDescription() {
        return this.getData('description', '');
    }

    getScheduledErection() {
        return this.getData('scheduledErection', null);
    }

    getScheduledDismantling() {
        return this.getData('scheduledDismantling', null);
    }

    getData(fieldName, fallback = <NotAvailablePlaceholder />) {
        const { scaffolding, referenceData } = this.props;
        return getData(scaffolding, referenceData, fieldName, fallback);
    }

    onEdit() {
        if (!this.props.scaffolding.mode) {
            return;
        }

        this.setState({
            showFormModal: true
        });
    }

    onChange(updatedScaffolding) {
        this.props.onChange(updatedScaffolding, this.props.scaffolding);
        this.closeFormModal();
    }

    onReferenceAll(scaffoldingsToReference, editedScaffolding) {
        this.props.onReferenceAllScaffoldings(scaffoldingsToReference, editedScaffolding);
        this.closeFormModal();
    }

    delete() {
        this.setState({
            showDeleteModal: false
        }, () => {
            this.props.onDelete(this.props.scaffolding);
        });
    }

    onDelete() {
        // If scaffolding is a dummy we delete it directly
        if (!this.props.scaffolding.mode) {
            this.delete();
            return;
        }

        this.setState({
            showDeleteModal: true
        });
    }

    closeFormModal() {
        this.setState({
            showFormModal: false
        });
    }

    closeDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    onChangeSections(updatedSections) {
        const updatedScaffolding = {
            ...this.props.scaffolding,
            sections: updatedSections
        };

        this.props.onChange(updatedScaffolding);
    };

    renderDetails() {
        const { scaffolding, referenceData } = this.props;
        const {
            id,
            mode,
            sections
        } = scaffolding;

        const isArchived = referenceData ? referenceData.archived : false;

        return (
            <Fragment>
                {mode === ConstructionPlanModelMode.Edit &&
                <ConstructionPlanFormModelEditHint />}

                <DataRow label="Geplante Standzeit">
                    <ScaffoldingServiceTime
                        scheduledDismantling={this.getScheduledDismantling()}
                        scheduledErection={this.getScheduledErection()}
                    />
                </DataRow>

                <DataRow label="Notizen">
                    {this.getDescription()}
                </DataRow>

                <ConstructionPlanFormSectionList
                    scaffoldingId={id}
                    scaffoldingMode={mode}
                    sections={sections}
                    onChange={this.onChangeSections}
                    referenceData={this.getSectionsReferenceData()}
                    isArchived={isArchived}
                />
            </Fragment>
        );
    }

    render() {
        const { scaffolding, siteId, referencedScaffoldingIds, parentIsArchived, referenceData } = this.props;
        const { showFormModal, showDeleteModal } = this.state;

        const {
            mode
        } = scaffolding;

        let isArchived = false;

        // mode is "edit" or "reference"
        if (referenceData) {
            isArchived = referenceData.archived;
        }

        return (
            <ConstructionPlanFormGroup
                headerIcon={<ConstructionPlanModelModeIcon mode={scaffolding.mode} />}
                dashedBorder
                readOnly={parentIsArchived && mode === ConstructionPlanModelMode.New}
                title={
                    !mode ? 'Gerüst' :
                        <ConstructionPlanFormGroupTitle
                            type={ConstructionPlannerSectionType.Scaffolding}
                        >
                            {this.getName()}
                            {isArchived && <ArchivedBadge />}
                        </ConstructionPlanFormGroupTitle>
                }
                actions={
                    (!isArchived && !parentIsArchived) &&
                    <ConstructionPlannerScaffoldingSelectionActions
                        onEdit={!mode ? undefined : this.onEdit}
                        onDelete={this.onDelete}
                        isArchived={isArchived}
                    />
                }
            >
                {this.renderDetails()}

                {showFormModal &&
                <ConstructionPlanScaffoldingFormModal
                    siteId={siteId}
                    onChange={this.onChange}
                    onReferenceAllScaffoldings={this.onReferenceAll}
                    onClose={this.closeFormModal}
                    scaffolding={scaffolding}
                    referencedScaffoldingIds={referencedScaffoldingIds}
                />
                }

                {showDeleteModal &&
                <ConstructionPlanFormScaffoldingDeleteModal
                    onConfirm={this.delete}
                    onCancel={this.closeDeleteModal}
                />
                }
            </ConstructionPlanFormGroup>
        );
    };
}

ConstructionPlanFormScaffolding.propTypes = {
    scaffolding: ConstructionPlanFormScaffoldingPropType.isRequired,
    siteId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    referencedScaffoldingIds: PropTypes.arrayOf(PropTypes.string.isRequired),
    onReferenceAllScaffoldings: PropTypes.func.isRequired,
    referenceData: PropTypes.object,
    hasScaffoldingsLeftToSelect: PropTypes.bool,
    showFormModal: PropTypes.bool
};

ConstructionPlanFormScaffolding.defaultProps = {
    referencedScaffoldingIds: [],
    referenceData: null,
    hasScaffoldingsLeftToSelect: false,
    showFormModal: false
};

export default ConstructionPlanFormScaffolding;
