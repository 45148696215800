import PropTypes from 'prop-types';
import React from 'react';

const ExternalLink = ({
    target, label
}) => (
    <a
        href={target}
        target="_blank"
        rel="noopener noreferrer"
    >
        {label}
    </a>
);

export default ExternalLink;

ExternalLink.propTypes = {
    target: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

