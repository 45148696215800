import PropTypes from 'prop-types';
import React from 'react';
import Modal from './Modal';

const DeleteModal = ({
                         modalTitle, isDeletingEntity, deleteEntity, submitBtnText, submitBtnTextWhileDeleting, closeModal, classes, children
                     }) => (
    <Modal
        id="modal-delete"
        title={modalTitle}
        type="confirmation"
        classes={classes}
        onBackdropClick={() => closeModal()}
        footer={
            <div className="btn-group">
                <button
                    className="btn btn--backward-action"
                    onClick={() => closeModal()}
                >
                    Abbrechen
                </button>
                <button
                    type="submit"
                    className="btn btn--forward-action btn--link-neutral"
                    disabled={isDeletingEntity}
                    onClick={deleteEntity}
                >
                    {!isDeletingEntity ? submitBtnText : submitBtnTextWhileDeleting}
                </button>
            </div>
        }
    >
        {children}
    </Modal>
);

export default DeleteModal;

DeleteModal.propTypes = {
    modalTitle: PropTypes.string.isRequired,
    isDeletingEntity: PropTypes.bool,
    deleteEntity: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    classes: PropTypes.string,
    submitBtnText: PropTypes.string,
    submitBtnTextWhileDeleting: PropTypes.string
};

DeleteModal.defaultProps = {
    submitBtnText: 'Fortfahren und löschen',
    submitBtnTextWhileDeleting: 'Wird gelöscht ...'
};





