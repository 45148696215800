import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../Components/Common/Icon';

class SectionListDropDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            headerTitle: this.props.title
        };
    }

    toggleList() {
        this.setState(prevState => ({
            listOpen: !prevState.listOpen
        }));
    }

    render() {
        const { sections } = this.props;
        const { listOpen, headerTitle } = this.state;
        return (
            <div className="sectionDropdown">
                <div className="sectionDropdown__header" onClick={() => this.toggleList()}>
                    <div className="sectionDropdown__header-title">{headerTitle}
                        {!listOpen
                            ?
                            <span><Icon name="chevronDown" /></span>
                            :
                            <span><Icon name="chevronUp" /></span>
                        }
                    </div>
                </div>
                {listOpen &&
                <ul className="sectionDropdown__list">
                    {sections.map(section => (
                        <li key={section.id}>
                            <Link
                                className="sectionDropdown__list-item"
                                to={`/sections/${section.id}`}
                            >
                                - {section.name}
                            </Link>
                        </li>
                    ))}
                </ul>
                }
            </div>
        );
    }

}

SectionListDropDown.propTypes = {
    title: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired
};

export default SectionListDropDown;
