import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { getScaffoldingPropertyLabel } from '../../../Common/Diff/labels';
import ScaffoldingServiceTime from '../../../Scaffoldings/Detail/ScaffoldingServiceTime';
import { ConstructionPlanModelMode } from '../../properties';
import { isIdenticalScaffoldingFormValue } from '../Scaffolding/equals';
import ConstructionPlanSyncModelSummary from './ConstructionPlanSyncModelSummary';
import ConstructionPlanSyncSummaryField from './ConstructionPlanSyncSummaryField';
import ConstructionPlanSyncSummaryRow from './ConstructionPlanSyncSummaryRow';
import {
    getLiveValue,
    getSyncValue,
    renderMediaList,
    renderMultilineText,
    renderSingleMedia,
    renderValue
} from './functions';

const ConstructionPlanSyncScaffoldingSummary = ({ syncModel, liveData, resolvedMedia }) => {

    const syncSummaryProperties = [
        'name',
        'description',
        'serviceTime',
        'attachments',
        'utilizationPlans',
        'anchorProtocol'
    ];

    const mediaListProperties = [
        'attachments',
        'utilizationPlans'
    ];

    const isNew = syncModel.mode === ConstructionPlanModelMode.New;
    const isReference = syncModel.mode === ConstructionPlanModelMode.Reference;
    const isIgnored = syncModel.ignored;

    const renderScaffoldingValue = (property, value) => {
        switch (property) {
            case 'description':
                return renderMultilineText(value);
            case 'attachments':
            case 'utilizationPlans':
                // handled in renderProperty
                return null;
            case 'anchorProtocol':
                return renderSingleMedia(value, resolvedMedia);
            default:
                return renderValue(value);
        }
    };

    const renderServiceTime = () => {

        const syncScheduledErection = getSyncValue('scheduledErection', syncModel);
        const syncScheduledDismantling = getSyncValue('scheduledDismantling', syncModel);

        const liveScheduledErection = getLiveValue('scheduledErection', liveData);
        const liveScheduledDismantling = getLiveValue('scheduledDismantling', liveData);

        const isIdentical = _.isEqual(syncScheduledErection, liveScheduledErection)
            && _.isEqual(syncScheduledDismantling, liveScheduledDismantling);

        const left = isNew ? '-' : (
            <ScaffoldingServiceTime
                scheduledErection={liveScheduledErection}
                scheduledDismantling={liveScheduledDismantling}
            />
        );

        const right = (
            <ScaffoldingServiceTime
                scheduledErection={syncScheduledErection}
                scheduledDismantling={syncScheduledDismantling}
            />
        );

        return (
            <ConstructionPlanSyncSummaryField
                key="serviceTime"
                label={getScaffoldingPropertyLabel('scheduledServiceTime')}
                isIdentical={isIdentical}
                isNew={isNew}
            >
                <ConstructionPlanSyncSummaryRow
                    left={left}
                    right={right}
                    isIdentical={isIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        )
    };

    const renderProperty = (property) => {
        if (property === 'serviceTime') {
            return renderServiceTime();
        }

        if (mediaListProperties.indexOf(property) >= 0) {
            return renderMediaList(property, syncModel, liveData, resolvedMedia);
        }

        const value = getSyncValue(property, syncModel);
        const liveValue = getLiveValue(property, liveData);
        const isIdentical = isIdenticalScaffoldingFormValue(property, syncModel.data, liveData);
        const left = renderScaffoldingValue(property, liveValue);
        const right = renderScaffoldingValue(property, value);

        return (
            <ConstructionPlanSyncSummaryField
                key={property}
                label={getScaffoldingPropertyLabel(property)}
                isIdentical={isIdentical}
                isNew={isNew}
            >
                <ConstructionPlanSyncSummaryRow
                    left={left}
                    right={right}
                    isIdentical={isIdentical}
                />
            </ConstructionPlanSyncSummaryField>
        )
    };

    const renderProperties = () => {
        if (isReference) {
            return 'Keine Konfigurationsänderungen';
        }

        if (isIgnored) {
            return  (
                <span className="sync-summary__ressource-is-ignored-hint">
                    Wird nicht berücksichtigt
                </span>
            )
        }

        return syncSummaryProperties.map(renderProperty);
    };

    return (
        <ConstructionPlanSyncModelSummary
            title={`Gerüst${isReference ? `:  ${liveData.name}` : ''}`}
            isNew={isNew}
        >
            {renderProperties()}
        </ConstructionPlanSyncModelSummary>
    );
};

ConstructionPlanSyncScaffoldingSummary.propTypes = {
    syncModel: PropTypes.object.isRequired,
    liveData: PropTypes.object
};

export default ConstructionPlanSyncScaffoldingSummary;
