import PropTypes from 'prop-types';

export const FormMode = {
    Create: 'create',
    Edit: 'edit'
};

export const FormModePropType = PropTypes.oneOf([
    FormMode.Create,
    FormMode.Edit
]);
