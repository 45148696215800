import callApi, { query, querySingle } from '../../action';
import { fetchAndUpdateUser } from '../user/action';


const companyType = 'company';

export const fetchCompanyData = companyId => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `FetchCompany{company(id:"${companyId}"){
            name,
            addressName,
            addressLine1,
            addressLine2,
            addressZip,
            addressCity,
            addressCountry,
            phone,
            email
        }}`;

        return querySingle(queryString, {
            token
        }, companyType)
            .then(companyData => (companyData));
    }
);

export function updateBaseData(companyData) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi('v1/company', {
            method: 'PUT',
            token,
            body: companyData
        })
            .then(() => {
                dispatch(fetchAndUpdateUser());
                return Promise.resolve();
            });
    };
}

export const fetchSubscriptionDetails = () => (
    (dispatch, getState) => {
        const currentUser = getState().currentUser;
        const token = currentUser.auth_token;

        const gqlQuery = `FetchUser{user{
            account{id, type, isOnTrial, billingAddress{name, line1, line2, zip, city, vatId}, mainSubscription{nextInvoice,nextRenewal,earliestCancellation,starts,ends} },
            firstName,
            lastName
        }}`;

        return query(gqlQuery, {
            token
        })
            .then(({ user }) => (user));
    }
);

export const fetchSubscriptionInformation = () => (
    (dispatch, getState) => {

        const currentUser = getState().currentUser;
        const token = currentUser.auth_token;

        const gqlQuery = `FetchUser{user{
            account{id,type,isOnTrial,invoiceAmountForecast, users{id, type},mainSubscription{id,starts,ends,earliestCancellation,nextRenewal,nextInvoice,subscriptionPlan{id,name,description,refId,price}}},
            firstName,
            lastName
        }}`;

        return query(gqlQuery, {
            token
        })
            .then(({ user }) => (user));
    }
);

export const fetchSingleUser = userId => (
    (dispatch, getState) => {
        const currentUser = getState().currentUser;
        const token = currentUser.auth_token;

        const gqlQuery = `FetchUser{user(id:"${userId}"){
            firstName,
            lastName,
            id,
            email,
            type,
            phone
        }}`;

        return querySingle(gqlQuery, {
            token
        }, 'user')
            .then((user) => {
                // dispatch(fetchSiteSuccess(site));
                return user;
            });
    }
);


export const fetchAccountUsers = () => (
    (dispatch, getState) => {
        const currentUser = getState().currentUser;
        const token = currentUser.auth_token;
        const queryString = `FetchUser{user{
            account{users{firstName,lastName, id, email, type, isAccountOwner}}
        }}`;

        return query(queryString, {
            token
        })
            .then(({ user }) => (user.account.users));
    }
);


export function updateBillingAddress(billingAddressData) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi('v1/account/billing-address', {
            method: 'PUT',
            token,
            body: billingAddressData
        })
            .then(response => response.json())
            .then(result => (result));
    };
}

export const fetchBillingAddress = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const queryString = 'FetchUser{ user{account{ billingAddress{name, line1, line2, zip, city, vatId} } }  }';

        return query(queryString, {
            token
        })
            .then(billingAddress => (billingAddress));
    }
);
