import PropTypes from 'prop-types';
import React from 'react';
import ConstructionPlanFileGroup from './ConstructionPlanFileGroup';

const ConstructionPlanMediaGroupList = ({ media }) => (
    Object.keys(media)
        .map((groupKey) => {
            const mediaList = media[groupKey];
            if (!mediaList.length) {
                return null;
            }

            return (
                <ConstructionPlanFileGroup
                    key={groupKey}
                    groupKey={groupKey}
                    mediaList={mediaList}
                />
            );
        })
);

ConstructionPlanMediaGroupList.propTypes = {
    media: PropTypes.object.isRequired
};

export default ConstructionPlanMediaGroupList;
