import moment from 'moment';
import 'moment/locale/de';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { bindActionCreators } from 'redux';
import { DEFAULT_ITEMS_PER_TABLE_PAGE } from '../../../properties';
import {
    fetchConstructionDiaryEntries,
    fetchingConstructionDiaryTotal,
    resetFilterOption,
    setCurrentPageNumber,
    setFilterOption
} from '../../../redux/modules/constructiondiary/action';
import EmptyTableHint from '../../Common/EmptyTableHint';
import Icon from '../../Common/Icon';
import { SortableTable } from '../../Common/Table/SortableTable';
import PageTitle from '../../Page/PageTitle';
import { SitesListViewOption } from '../../Sites/List/SitesList';
import ConstructionDiaryExportModal from '../ConstructionDiaryExportModal';
import { ConstructionDiaryOverviewColumns } from '../properties';
import ConstructionDiaryFilterbar, { SELECTION_TYPES } from './ConstructionDiaryFilterbar';
import ConstructionDiaryOverviewBreadcrumb from './ConstructionDiaryOverviewBreadcrumb';
import { ConstructionDiaryTableRow } from './ConstructionDiaryTableRow';

export const FILTER_KEYS = {
    site: 'site',
    scaffolding: 'scaffolding',
    section: 'section',
    category: 'category',
    date: 'date',
    initialState: 'initialState'
};

const urlPropsQueryConfig = {
    archived: {
        type: UrlQueryParamTypes.boolean,
        updateType: 'replaceIn',
        queryParam: 'archived'
    },
    site: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'site'
    },
    scaffolding: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'scaffolding'
    },
    section: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'section'
    },
    category: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'category'
    },
    from: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'from'
    },
    to: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'to'
    },
    page: {
        type: UrlQueryParamTypes.number,
        updateType: 'replaceIn',
        queryParam: 'page'
    },
    sortAsc: {
        type: UrlQueryParamTypes.boolean,
        updateType: 'replaceIn',
        queryParam: 'sortAsc'
    }
};

const defaultFilterState = {
    siteId: '',
    scaffoldingId: '',
    sectionId: '',
    category: '',
    dateFrom: null,
    dateTo: null,
    currentPage: 1,
    sortDirection: '-'
};

class ConstructionDiaryOverview extends Component {

    constructor(props) {
        super(props);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.toggleExportModal = this.toggleExportModal.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.showEntryReference = this.showEntryReference.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.updateHistory = this.updateHistory.bind(this);
        this.renderEmptyTableHint = this.renderEmptyTableHint.bind(this);
        this.renderSelectFilterHint = this.renderSelectFilterHint.bind(this);
        this.hasSelectedFilter = this.hasSelectedFilter.bind(this);

        this.state = {
            showExportModal: false,
            isInitialized: false
        };
    }

    componentWillMount() {
        const {
            site, scaffolding, section, category, from, to, page, sortBy, sortAsc, archived, actions
        } = this.props;

        actions.fetchingConstructionDiaryTotal().then(() => this.setState({ isInitialized: true }));

        const filterState = {
            siteId: site || '',
            scaffoldingId: scaffolding || '',
            sectionId: section || '',
            category: category || '',
            dateFrom: from ? moment(from)
                .format() : null,
            dateTo: to ? moment(to).endOf('D')
                                   .format() : null,
            currentPage: page,
            sortField: sortBy || 'created',
            sortDirection: sortAsc ? '+' : '-',
            archived
        };

        this.initialFilterState = filterState;

        this.setState({
            filterState
        }, () => {
            this.emitQueryChange();
        });
    }

    getFilterParams() {
        const {
            siteId, currentPage, scaffoldingId, sectionId, sortField, sortDirection, category, dateFrom, dateTo
        } = this.state.filterState;

        return {
            siteId,
            scaffoldingId,
            sectionId,
            category: category || 'all',
            fromDate: dateFrom || '',
            toDate: dateTo || '',
            page: currentPage || 1,
            sortField: sortField || 'created',
            sortAsc: sortDirection !== '-',
            limit: DEFAULT_ITEMS_PER_TABLE_PAGE
        };
    }

    updateHistory() {

        const { history, location } = this.props;

        // Get current query string
        const currentQueryString = location.search || '';

        history.replace({
            ...location,
            state: {
                constructionDiaryQueryString: currentQueryString
            }
        });

    }

    emitQueryChange() {
        const filterParams = this.getFilterParams();
        this.updateHistory();
        if ( this.hasSelectedFilter() ) {
            this.props.actions.fetchConstructionDiaryEntries(filterParams);
        }
    }

    onSelectionChange(type, selection) {

        const queryValue = selection ? selection.value : '';

        switch ( type ) {
            case SELECTION_TYPES.site:

                const siteId = selection ? selection.id : '';

                // change URL parameter
                this.props.onChangeUrlQueryParams({
                    site: siteId,
                    page: null,
                    scaffolding: '',
                    section: ''
                });

                // update filter options
                if ( !selection ) {
                    this.props.actions.resetFilterOption({
                        key: FILTER_KEYS.site
                    });
                } else {
                    this.props.actions.setFilterOption({
                        key: 'site',
                        value: selection
                    });
                }

                // update filter state
                let updatedFilterState = {};

                if ( !selection ) {
                    const { dateFrom, dateTo, category } = this.state.filterState;
                    updatedFilterState = {
                        ...defaultFilterState,
                        dateFrom,
                        dateTo,
                        category
                    };
                } else {
                    updatedFilterState = {
                        siteId,
                        currentPage: 1
                    };
                }

                this.setState(prevState => ({
                    filterState: {
                        ...prevState.filterState,
                        ...updatedFilterState
                    }
                }), () => this.emitQueryChange());
                break;

            case SELECTION_TYPES.scaffolding:

                const scaffoldingId = selection ? selection.id : '';
                this.props.onChangeUrlQueryParams({
                    scaffolding: scaffoldingId,
                    section: '',
                    page: null
                });

                if ( !selection ) {
                    this.props.actions.resetFilterOption({
                        key: FILTER_KEYS.scaffolding
                    });

                } else {
                    this.props.actions.setFilterOption({
                        key: 'scaffolding',
                        value: selection
                    });
                }

                this.props.actions.resetFilterOption({
                    key: FILTER_KEYS.section
                });

                this.setState(prevState => ({
                    filterState: {
                        ...prevState.filterState,
                        scaffoldingId,
                        sectionId: undefined,
                        currentPage: 1
                    }
                }), () => this.emitQueryChange());

                break;

            case SELECTION_TYPES.section:
                const sectionId = selection ? selection.id : '';
                this.props.onChangeSection(sectionId);

                this.props.onChangeUrlQueryParams({
                    section: sectionId,
                    page: null
                });


                if ( !selection ) {
                    this.props.actions.resetFilterOption({
                        key: FILTER_KEYS.section
                    });
                } else {
                    this.props.actions.setFilterOption({
                        key: 'section',
                        value: selection
                    });
                }

                this.setState(prevState => ({
                    filterState: {
                        ...prevState.filterState,
                        sectionId,
                        currentPage: 1
                    }
                }), () => this.emitQueryChange());

                break;
            case SELECTION_TYPES.category:

                this.props.onChangeUrlQueryParams({
                    category: queryValue,
                    page: null
                });

                if ( !queryValue ) {
                    this.props.actions.resetFilterOption({
                        key: FILTER_KEYS.category
                    });
                } else {
                    this.props.actions.setFilterOption({
                        key: 'category',
                        value: { value: queryValue }
                    });
                }

                this.setState(prevState => ({
                    filterState: {
                        ...prevState.filterState,
                        category: queryValue,
                        currentPage: 1
                    }
                }), () => this.emitQueryChange());
                break;

            case SELECTION_TYPES.date:

                let queryDateFrom;
                let queryDateTo;

                let dateFrom;
                let dateTo;

                if ( !selection ) {
                    queryDateFrom = '';
                    queryDateTo = '';
                    dateFrom = '';
                    dateTo = '';
                } else {
                    queryDateFrom = selection.dateFrom ? moment(selection.dateFrom)
                        .format('YYYY-MM-DD') : '';
                    queryDateTo = selection.dateTo ? moment(selection.dateTo)
                        .format('YYYY-MM-DD') : '';

                    dateFrom = selection.dateFrom;
                    dateTo = selection.dateTo;
                }

                this.props.actions.setFilterOption({
                    key: FILTER_KEYS.date,
                    value: {
                        dateFrom,
                        dateTo
                    }
                });

                this.props.onChangeUrlQueryParams({
                    from: queryDateFrom,
                    to: queryDateTo,
                    page: null
                });

                this.setState(prevState => ({
                    filterState: {
                        ...prevState.filterState,
                        dateFrom,
                        dateTo,
                        currentPage: 1
                    }
                }), () => this.emitQueryChange());
                break;

            case SELECTION_TYPES.listViewOption:
                const { value } = selection;

                this.props.onChangeUrlQueryParams({
                    archived: value !== SitesListViewOption.ShowActiveSites
                });

            default:
                return null;
        }
    }

    showEntryReference(entry) {
        const { scaffoldingSelection, sectionSelection } = entry;

        if ( scaffoldingSelection === 'all' ) {
            return 'Gesamte Baustelle';
        }

        if ( scaffoldingSelection === 'single' && sectionSelection === 'all' ) {
            return '1 Gerüst';
        }

        return '1 Gerüstabschnitt';
    }

    onSortChange(updatedSortByField, updatedSortDirection) {

        const { sortDirection } = this.state.filterState;

        if ( updatedSortDirection !== sortDirection ) {

            this.props.onChangeSortAsc(updatedSortDirection !== '-');
            this.setState(prevState => ({
                filterState: {
                    ...prevState.filterState,
                    sortDirection: updatedSortDirection,
                    sortField: updatedSortByField
                }
            }), () => this.emitQueryChange());
        }
    }

    onPaginationChange(pageNumber) {
        this.props.onChangeUrlQueryParams({
            page: pageNumber
        });

        this.setState(prevState => ({
            filterState: {
                ...prevState.filterState,
                currentPage: pageNumber
            }
        }), () => this.emitQueryChange());
    }

    toggleExportModal() {
        const { isFetchingList, entries } = this.props;

        if ( isFetchingList || !entries || entries.length === 0 || !this.hasSelectedFilter() ) {
            return;
        }

        this.setState(prevState => ({
            showExportModal: !prevState.showExportModal
        }));
    }

    renderSelectFilterHint() {
        return (
            <EmptyTableHint
                numColumns={5}
                title="Keine Filtereinstellungen ausgewählt"
            >
                <p>Wählen Sie mindestens eine Filtereinstellung aus der Filterleiste aus, um die dazugehörigen
                    Tagebucheinträge angezeigt zu bekommen.
                </p>
            </EmptyTableHint>
        );
    }

    renderEmptyTableHint() {
        return (
            <EmptyTableHint
                numColumns={5}
                title=" Wir konnten keine passenden Bautagebuch-Einträge zu Ihrer Filterauswahl finden. Entfernen oder ändern Sie die Filtereinstellungen, um mehr Ergebnisse zu erhalten."
            />
        );
    }

    hasSelectedFilter() {
        const filterParams = this.getFilterParams();

        // selection of scaffolding and section are results of site
        return !!(filterParams.fromDate || filterParams.toDate || filterParams.siteId || filterParams.category !== 'all');
    }

    getEntries() {
        const showArchived = !!this.props.archived;
        return this.props.entries.filter(entry => entry.site.archived === showArchived);
    }

    render() {
        const { showExportModal, filterState, isInitialized } = this.state;
        const { isFetchingList } = this.props;
        const entries = this.getEntries();

        const exportLinkClasses = [ 'btn--link-neutral-bold' ];

        if ( isFetchingList || !entries || entries.length === 0 || !this.hasSelectedFilter() ) {
            exportLinkClasses.push('btn--link-neutral-bold--disabled');
        }

        return (
            <Fragment>

                <ConstructionDiaryOverviewBreadcrumb />

                <PageTitle
                    title="Bautagebuch - Einträge"
                >
                    <span
                        onClick={() => this.toggleExportModal()}
                        className={exportLinkClasses.join(' ')}
                    >
                        <Icon name="download"/> {' '}
                        Einträge exportieren
                    </span>
                </PageTitle>

                <ConstructionDiaryFilterbar
                    onChange={this.onSelectionChange}
                    initialFilterState={this.initialFilterState}
                />

                <SortableTable
                    onSortChange={this.onSortChange}
                    entityLabel="Bautagebuch-Einträge"
                    sort={{
                        field: filterState.sortField,
                        direction: filterState.sortDirection
                    }}
                    isFetching={!isInitialized || isFetchingList}
                    data={this.hasSelectedFilter() ? entries : []}
                    columns={ConstructionDiaryOverviewColumns}
                    RowComponent={ConstructionDiaryTableRow}
                    total={!this.hasSelectedFilter() ? this.props.total : this.props.totalFiltered}
                    onSetPage={this.onPaginationChange}
                    page={filterState.currentPage}
                    renderEmptyTableHint={this.hasSelectedFilter() ? this.renderEmptyTableHint : this.renderSelectFilterHint}
                />

                {showExportModal &&
                <ConstructionDiaryExportModal
                    closeModal={this.toggleExportModal}
                    filterState={this.state.filterState}
                />
                }
            </Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    const constructionDiaryState = state.constructionDiary;

    const entries = constructionDiaryState.entriesList.items || [];

    return {
        currentFilter: constructionDiaryState.filter,
        total: constructionDiaryState.total,
        totalFiltered: constructionDiaryState.entriesList.total,
        isFetchingList: constructionDiaryState.isFetchingList,
        amountPerPage: constructionDiaryState.amountPerPage,
        currentPage: constructionDiaryState.currentPage,
        entries: entries.map(entry => ({
            id: entry.id,
            category: entry.category,
            created: entry.created,
            createdBy: entry.createdBy,
            scaffoldings: entry.scaffoldings || [],
            scaffoldingSelection: entry.scaffoldingSelection,
            sections: entry.sections || [],
            sectionSelection: entry.sectionSelection,
            site: entry.site
        }))
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionDiaryEntries,
        setCurrentPageNumber,
        setFilterOption,
        resetFilterOption,
        fetchingConstructionDiaryTotal
    }, dispatch)
});


export default withRouter(addUrlProps({ urlPropsQueryConfig })(connect(mapStateToProps, mapDispatchToProps)(ConstructionDiaryOverview)));
