import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { PortalTooltip } from '../Tooltip';

const ArchivedBadge = ({ size, hoverContent }) => {

    const classes = ['archived-badge'];

    if (size === 'small') {
        classes.push('archived-badge--small');
    }

    return (
        <Fragment>
            {hoverContent ? <span data-for="archived-tt" data-tip className={classes.join(' ')}
                >Archiviert</span>
                :
                <span className={classes.join(' ')}
                >Archiviert</span>
            }
            {hoverContent &&
            <PortalTooltip id="archived-tt">
                {hoverContent}
            </PortalTooltip>
            }
        </Fragment>
    );
};

export default ArchivedBadge;


ArchivedBadge.propTypes = {
    size: PropTypes.string,
    hoverContent: PropTypes.string
};

ArchivedBadge.defaultProps = {
    size: '',
    hoverContent: ''
};



