import React from 'react';
import ConstructionDiaryOverview
    from '../../../Components/ConstructionDiary/Overview/ConstructionDiaryOverview';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const ConstructionDiaryEntriesPage = ({ location, history }) => {

    if (location.search && (!location.state || !location.state.constructionDiaryQueryString)) {

        history.replace({
            ...location,
            state: {
                ...location.state,
                constructionDiaryQueryString: location.search
            }
        });
    }

    return (
        <PrivatePage requiredUserPermission={Permission.ReadConstructionDiaryEntries}>
            <Container>
                <TopNav />
                <ConstructionDiaryOverview />
            </Container>
        </PrivatePage>
    );
};

export default ConstructionDiaryEntriesPage;



