import PropTypes from 'prop-types';
import React from 'react';

const Breadcrumb = ({ children, collapse }) => {

    const classes = ['breadcrumbs'];

    if (collapse) {
        classes.push('is-collapsed');
    }

    return (
        <div className="breadcrumbs-ct">
            <div className={classes.join(' ')}>
                <div className="breadcrumbs__cnt">
                    {children}
                </div>
            </div>
        </div>
    );
};

Breadcrumb.propTypes = {
    collapse: PropTypes.bool
}

Breadcrumb.defaultProps = {
    collapse: false
}

export default Breadcrumb;
