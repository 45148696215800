import React from 'react';
import PropTypes from 'prop-types';
import { getQuestion } from './InspectionQuestions';
import Icon from '../../Common/Icon';

const InspectionResults = ({ answers, isAnonymous }) => {
    const showFailureDetails = (imgs, nts) => {
        const images = imgs || [];
        const notes = nts || [];

        if (!images.length && !notes.length) {
            return null;
        }

        const outputImgs = () => {
            if (isAnonymous) {
                return [
                    <div key="anonymized-image" className="inspection-results__anonymized-image-placeholder">
                        Fotos nicht einsehbar
                    </div>
                ];
            }

            return images.map(image => {
                return (
                    <img
                        alt={image.name}
                        key={image.id}
                        src={`${process.env.REACT_APP_API_URL}/v1/images/${image.id}/${image.fileName}`}
                    />
                );
            });
        };

        const outputNts = () => {

            if (isAnonymous) {
                return [<p key="anonymized-note" className="note note--anonymized">Kommentar nicht einsehbar</p>];
            }

            return notes.map(note => {
                return (
                    <p key={note.id} className="note"> {`'${note.content}'`} </p>
                );
            });
        };

        return (
            <div className="inspection-results__failuredetails">
                {outputImgs().length > 0 && outputImgs()}
                {outputNts().length > 0 ?
                    <div className="inspection-results__notes">
                        {outputNts()}
                    </div>
                    :
                    null
                }
            </div>
        );
    };

    const generateSingleResult = questions => (

        questions.map((item, index) => {
            const classNames = ['inspection-results__result'];
            const resultsHeaderClasses = ['inspection-results__resultheading'];
            const classWrapperNames = ['inspection-results__resultwrapper'];

            if (item.success === false) {
                classNames.push('inspection-results__result--failure');
                classWrapperNames.push('inspection-results__resultwrapper--failure');
            } else if (item.success === 'not_applicable') {
                classNames.push('inspection-results__result--not-applicable');
            }

            if (item.input) {
                resultsHeaderClasses.push('inspection-results__resultheading--input-answer');
            }

            return (
                <div key={index} className={classWrapperNames.join(' ')}>
                    <div className={classNames.join(' ')}>

                        <div className={resultsHeaderClasses.join(' ')}>
                            <div
                                className="inspection-results__question"
                            >
                                {item.question.title}
                            </div>
                            {item.input ?
                                <div className="inspection-results__input">{item.input} </div>
                                :
                                <div className="inspection-results__status">
                                    {
                                        {
                                            true: <React.Fragment>Ja <span><Icon
                                                name="stateok"/> </span></React.Fragment>,
                                            false: <React.Fragment>Nein <span><Icon
                                                name="statealert"/> </span></React.Fragment>,
                                            not_applicable: <React.Fragment>nicht zutreffend <span><Icon
                                                name="placeholder"/> </span></React.Fragment>
                                        }[item.success]
                                    }
                                </div>
                            }
                        </div>
                        {(item.success && !item.input) || showFailureDetails(item.images, item.notes)}
                    </div>
                </div>
            );
        })
    );

    const generateResults = () => {
        let results = {};

        answers.forEach((item) => {
            const q = {
                question: getQuestion(item.name),
                success: item.success,
                images: item.images,
                notes: item.notes,
                input: item.input
            };
            const categoryKey = q.question.category.key;
            const categoryLabel = q.question.category.name || '';

            // Category already there
            if (results[categoryKey]) {
                results[categoryKey].values.push(q);
            } else {
                // Category not there yet
                results = {
                    ...results,
                    [categoryKey]: {
                        label: categoryLabel,
                        values: [q]
                    }
                };
            }
        });

        return Object.keys(results)
            .map((result, index) => (
                <div key={index} className="inspection-results__categoryblock">
                    <h3 className="inspection-results__categoryheading">{results[result].label}</h3>
                    <div className="inspection-results__cnt">
                        {generateSingleResult(results[result].values)}
                    </div>
                </div>
            ));
    };

    return (
        <div className="inspection-results">
            {generateResults()}
        </div>
    );
};

export default InspectionResults;

InspectionResults.propTypes = {
    answers: PropTypes.arrayOf(PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            fileName: PropTypes.string
        })),
        name: PropTypes.string,
        notes: PropTypes.arrayOf(PropTypes.shape({
            content: PropTypes.string
        })),
        success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    })).isRequired,
    isAnonymous: PropTypes.bool.isRequired
};
