import React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import _ from 'lodash';
import Checkbox from './Checkbox';
import Condition from './Condition';
import FieldError from './FieldError';
import Radio from './Radio';

const OptionGroup = ({ options, disabled, onChange, name, classes, ifSelectionIs, type, thenRender }) => (
    <Field
        name={name}
        disabled={disabled}
    >
        {({ input, meta }) => {

            const containerCssClasses = ['option-group'];
            const inputCssClasses = ['input-grp__field'];

            if (classes){
                containerCssClasses.push(classes);
            }

            if (meta.error) {
                inputCssClasses.push('is-invalid');
            } else {
                inputCssClasses.push('is-valid');
            }

            if (meta.touched) {
                inputCssClasses.push('is-dirty');
            } else {
                inputCssClasses.push('is-pristine');
            }

            return (
                <div className={containerCssClasses.join(' ')}>
                    {
                        options.map((option, i) => (
                            <React.Fragment key={i}>
                                {{
                                    checkbox: <Checkbox
                                        name={name}
                                        value={option.value}
                                        label={option.label}
                                        index={i}
                                        showErrors={false}
                                        disabled={option.disabled}
                                    />,
                                    radio: <Radio
                                        name={name}
                                        value={option.value}
                                        label={option.label}
                                        index={i}
                                        showErrors={false}
                                        disabled={option.disabled}
                                    />
                                }[type]}

                                {_.includes(ifSelectionIs, option.value) ?
                                    <Condition when={name} contains={option.value}>
                                        {thenRender(option.value)}
                                    </Condition> : null
                                }
                            </React.Fragment>
                        ))
                    }
                    <FieldError meta={meta} />

                    {
                        onChange && (
                            <OnChange name={name}>
                                {val => val && onChange(val)}
                            </OnChange>
                        )
                    }
                </div>
            );
        }}
    </Field>
);

export default OptionGroup;
