import PropTypes from 'prop-types';
import React from 'react';

const ConstructionPlanFormItemList = ({ children, listItems, emptyComponent, showEmptyComponentEvenForList }) => {

    if (!listItems || listItems.length === 0) {
        return emptyComponent;
    }

    const listContent = listItems.map((listItem,index) => (children(listItem,index)));

    return (
        <div className="constructionPlannerList">
            {listContent}
            {
                showEmptyComponentEvenForList && emptyComponent
            }
        </div>
    );
};

ConstructionPlanFormItemList.propTypes = {
    listItems: PropTypes.array,
    children: PropTypes.func.isRequired,
    emptyComponent: PropTypes.element.isRequired,
    showEmptyComponentEvenForList: PropTypes.bool
};

ConstructionPlanFormItemList.defaultProps = {
    listItems: [],
    showEmptyComponentEvenForList: false
};

export default ConstructionPlanFormItemList;
