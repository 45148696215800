import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import EmptyMediaDropzone from './EmptyMediaDropzone';
import MediaDropzoneFile from './MediaDropzoneFile';

let tempMediaId = 1;

function getNextTempMediaId() {
    const nextTempMediaId = tempMediaId;
    tempMediaId += 1;
    return `filedrop-${nextTempMediaId}`;
}

class MediaDropzone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: _.isEmpty(this.props.files) ? [] : this.props.files,
            rejectedErrorMessage: ''
        };
        this.onDropAccepted = this.onDropAccepted.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.renderFiles = this.renderFiles.bind(this);
    }

    onDropAccepted(files) {

        this.setState({
            rejectedErrorMessage: ''
        });

        if (files.length > 20) {
            this.setState({
                rejectedErrorMessage: 'Es können maximal 20 Dateien auf einmal hochgeladen werden'
            });

            return;
        }
        this.setState({
            files: files.map(file => ({
                id: getNextTempMediaId(),
                name: file.name,
                size: file.size,
                original: file
            }))
        }, () => {
            this.props.onChange(this.state.files);
        });
    }

    onDropRejected(rejectedFiles, evt) {

        let errorMsg = '';

        if (rejectedFiles.length > 1) {
            if (!this.props.multiple) {
                errorMsg = 'Für diesen Medientyp kann jeweils nur eine Datei hinterlegt werden.';
            } else {
                // Special case: mail drag and drop (mime type "text/uri-list")
                if (rejectedFiles.some(rejectedFile => rejectedFile.type === 'text/uri-list')) {
                    errorMsg = 'Drag & Drop von E-Mails direkt aus dem Mail-Programm wird zur Zeit nicht unterstützt. Tipp: Speichern Sie die E-Mails zuerst ab und laden Sie dann die abgespeicherte Datei hoch.';
                } else {
                    errorMsg = 'Die ausgewählten Dateien enthalten nicht unterstützte Formate. Bitte nutzen Sie nur Dateien der oben angegebenen Formate.';
                }
            }
        } else {
            errorMsg = `Die Datei „${rejectedFiles[0].name}“ besitzt ein nicht unterstütztes Dateiformat. Bitte verwenden Sie nur Dateien mit dem oben angegebenem Dateiformat.`;
        }

        this.setState({
            rejectedErrorMessage: errorMsg
        });
    }

    removeFile(fileToBeRemoved) {
        this.setState({
            files: this.state.files.filter(file => fileToBeRemoved.id !== file.id)
        }, () => {
            this.props.onChange(this.state.files);
        });
    }

    renderFiles(files) {

        return (
            <div className="media-form__files">
                {files.map(file => this.props.fileComponent(file, this.removeFile, this.props.fileUploadProgressList))}
            </div>
        );
    }

    render() {
        const { files, rejectedErrorMessage } = this.state;

        const dropzoneClasses = ['media-dropzone'];

        if (files.length > 1) {
            dropzoneClasses.push('media-dropzone--multiple');
        }

        return (
            <div className={dropzoneClasses.join(' ')}>
                <Dropzone
                    onDropAccepted={this.onDropAccepted}
                    onDropRejected={this.onDropRejected}
                    multiple={this.props.multiple}
                    disabledClassName="media-dropzone--disabled"
                    className="media-dropzone__dropzone"
                    accept={this.props.allowedMimes}
                    disabled={this.props.disabled}
                >
                    {
                        files.length ?
                            this.renderFiles(files)
                            : <EmptyMediaDropzone multiple={this.props.multiple} />
                    }
                </Dropzone>
                {rejectedErrorMessage &&
                <div className="input-grp__error">{this.state.rejectedErrorMessage}</div>
                }
            </div>
        );
    }

}

MediaDropzone.propTypes = {
    onChange: PropTypes.func.isRequired,
    files: PropTypes.arrayOf(PropTypes.object),
    fileComponent: PropTypes.func,
    allowedMimes: PropTypes.arrayOf(PropTypes.string),
    multiple: PropTypes.bool,
    fileUploadProgressList: PropTypes.object
};

MediaDropzone.defaultProps = {
    files: [],
    allowedMimes: undefined,
    fileUploadProgressList: {},
    multiple: true,
    fileComponent: (file, remove, fileUploadProgressList) => <MediaDropzoneFile
        fileUploadProgressList={fileUploadProgressList} key={file.id} file={file} remove={remove}
    />
};

export default MediaDropzone;
