import React from 'react';

const PublicFooter = () => (
    <div className="login-form__footer">
        <a href="https://www.scaffeye.de/">Kontakt</a>
        <a href="https://www.scaffeye.de/agb-plattform/">AGB</a>
        <a href="https://www.scaffeye.de/datenschutz-plattform/">Datenschutz</a>
    </div>
);

export default PublicFooter;
