import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { AppChangeLogSlide } from '../AppChangeLogSlide';

export const Changelog_1_23_0 = ({ onClickClose, modalMode }) => {

    const itemCount = modalMode ? 9 : 7;

    const slider = useRef();
    const history = useHistory();

    const previousSlide = () => {
        slider.current.slickPrev();
    }

    const nextSlide = () => {
        slider.current.slickNext();
    }

    const onShowFullChangelog = (e) => {
        e.preventDefault();
        onClickClose().finally(() => {
            history.push('/changelog');
        });
    }

    const [index, setIndex] = useState(0);

    const sliderSettings = {
        dots: true,
        arrows: false,
        adaptiveHeight: false,
        speed: 400,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => {
            setIndex(newIndex);
        }
    };

    return (
        <div className="changelog-entry">
            <div className="changelog-slides">
                <div className="changelog-slides-ct">
                    <Slider ref={slider} {...sliderSettings}>

                        {/* 0 */}
                        {modalMode &&
                        <AppChangeLogSlide
                            title="Willkommen bei Scaffeye 1.23"
                            image={
                                <img
                                    src="/images/changelog/1_23_0/0.jpg"
                                />
                            }
                        >
                            Wir arbeiten stets daran, Scaffeye noch besser für Sie zu machen.
                            Schauen Sie sich die wichtigsten Änderungen dieses Updates als
                            Zusammenfassung in dieser Slideshow an.
                        </AppChangeLogSlide>
                        }

                        {/* 1 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/1.jpg"
                                />
                            }
                            title="1. Verbesserung der Menüstruktur"
                            subTitle="Überarbeitung der Navigationsleisten"
                        >
                            Wir haben die Scaffeye-Sidebar und die obere Navigationsleiste
                            zusammengelegt. Dadurch wird die Menüführung simpler und übersichtlicher.
                            Zusätzlich haben wir eine Breadcrump-Navigation in Blau hinzugefügt. Diese
                            zeigt Ihnen ab sofort an, auf welcher Ebene Sie sich befinden.
                        </AppChangeLogSlide>

                        {/* 2 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/2.jpg"
                                />
                            }
                            title="2. Bautagebücher auf den Baustellenseiten"
                            subTitle="Implementierung des Reiters “Bautagebuch” auf den Baustellenseiten"
                        >
                            Ab sofort können Sie die Bautagebücher Ihrer Baustellen direkt auf den
                            Baustellenseiten einsehen. Dadurch erhalten Sie alle relevanten
                            Informationen zu Ihren Baustellen übersichtlich auf einer Seite.
                        </AppChangeLogSlide>

                        {/* 3 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/3.jpg"
                                />
                            }
                            title="3. Neue Quick-Edit Funktionen für Anfragen & Aufträge"
                            subTitle="Schnellanpassungen Ihrer Anfrage- und Auftrags-Status"
                        >
                            Ab sofort haben Sie die Möglichkeit, den Status Ihrer Anfragen und Aufträge
                            flexibel anzupassen, ohne dazu die Detailseiten öffnen zu müssen.
                        </AppChangeLogSlide>

                        {/* 4 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/4.jpg"
                                />
                            }
                            title="4. Neue Quick-Edit Funktionen für Gerüstabschnitte"
                            subTitle="Schnellanpassungen Ihres Gerüst-Status"
                        >
                            Auf den Detailseiten Ihrer Gerüstabschnitte haben wir eine Quick-Edit
                            Funktion zum Ändern des Status implementiert. Dadurch können Sie den Status
                            eines Gerüstabschnittes ändern, ohne zuvor die Bearbeitungsmaske zu öffnen.
                        </AppChangeLogSlide>

                        {/* 5 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/5.jpg"
                                />
                            }
                            title="5. Zusammenlegung öffentlicher / interner Gerüststatus"
                            subTitle="Ein Gerüststatus für alle"
                        >
                            Wir haben den öffentlichen und internen Gerüststatus zusammengelegt. Dadurch
                            wird das Status-Handling stark vereinfacht. Gerüstersteller und Gerüstnutzer
                            können sich ab sofort über den selben Gerüststatus verständigen.
                        </AppChangeLogSlide>

                        {/* 6 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/6.jpg"
                                />
                            }
                            title="6. Verbesserung des Benennungsschemas"
                            subTitle="Bauabschnitt heißt ab sofort Gerüstabschnitt"
                        >
                            Wir haben die Benennungsschemata einiger Begriffe in Scaffeye verbessert und
                            vereinheitlicht. Unter anderem heißen zur besseren Verständlichkeit
                            “Bauabschnitte” ab sofort “Gerüstabschnitte”.
                        </AppChangeLogSlide>

                        {/* 7 */}
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/7.jpg"
                                />
                            }
                            title="7. Schnellerer Zugang zum Hilfe- und FAQ-Bereich"
                            subTitle="Neues Hilfe-Icon in der Navigationsleiste"
                        >
                            Wir haben ein neues Hilfe-Icon in der Navigationsleiste hinzugefügt. So
                            können Sie den Hilfe-Bereich von Scaffeye mit einem Klick öffnen.
                        </AppChangeLogSlide>

                        {/* 8 */}
                        {modalMode &&
                        <AppChangeLogSlide
                            image={
                                <img
                                    src="/images/changelog/1_23_0/8.jpg"
                                />
                            }
                            title="Und vieles mehr..."
                            subTitle="Gesamtübersicht der Änderungen im Update-Protokoll"
                        >
                            Schauen Sie sich das <a href="/changelog" onClick={onShowFullChangelog}>vollständige
                            Update-Protokoll</a> an, um sich alle Änderungen und Erweiterungen in der
                            Übersicht anzusehen
                        </AppChangeLogSlide>
                        }

                    </Slider>

                    <div className="changelog-slides__nav">
                        <button className="btn btn--backward-action changelog-slides__prev"
                                onClick={previousSlide}
                                style={{ visibility: index === 0 ? 'hidden' : 'visible' }}>
                            Zurück
                        </button>
                        {index < itemCount - 1 &&
                        <button
                            className="btn btn--primary-green changelog-slides__next"
                            onClick={nextSlide}
                        >
                            Weiter
                        </button>
                        }
                        {index === itemCount - 1 && modalMode &&
                        <button
                            className="btn btn--primary-green changelog-slides__next"
                            onClick={onClickClose}
                        >
                            Schließen
                        </button>
                        }
                    </div>
                </div>
            </div>

            {!modalMode &&
            <div className="changelog-entry__cnt">
                <section>
                    <h4>
                        Die wichtigsten Änderungen um Überblick:
                    </h4>
                    <ul>
                        <li>
                            Die Menüführungen wurden optimiert. Die Scaffeye-Sidebar und die obere Navigationsleiste wurden zusammengefügt, um die Bedienung der Web-App einfacher und übersichtlicher zu gestalten
                        </li>
                        <li>
                            Es wurde eine Breadcrump-Navigation unterhalb der Navigationsleiste hinzugefügt, um sich besser auf den Ebenen der Web-App zurechtzufinden
                        </li>
                        <li>
                            Der Reiter “Bautagebuch” wurde in die Baustellenseiten integriert: Ab sofort können Sie die Bautagebücher Ihrer Baustellen direkt auf den Baustellenseiten einsehen.
                        </li>
                        <li>
                            Neue Quick-Edit Funktionen zum schnellen Ändern des Anfrage- und Auftrags-Status
                        </li>
                        <li>
                            Neue Quick-Edit Funktion zum schnellen Ändern des Gerüstabschnitt-Status auf der Gerüstabschnitt-Seite
                        </li>
                        <li>
                            Zusammenlegung des “internen Status” und des “öffentlichen Status” zu “Status”: Wir haben den öffentlichen und internen Gerüststatus zusammengelegt. Dadurch wird das Status-Handling stark vereinfacht. Gerüstersteller und Gerüstnutzer können sich ab sofort über den selben Gerüststatus verständigen.
                        </li>
                    </ul>
                </section>

                <section>
                    <h4>Weitere Verbesserungen:</h4>
                    <ul>
                        <li>
                            Schnellerer Zugang zum FAQ / Hilfe-Bereich über das Fragezeichen-Icon in der oberen Navigationsleiste
                        </li>
                        <li>
                            Zusammenlegung der Menüpunkte “Kontoeinstellungen” (Unternehmen) und “Kontodaten” (Nutzer) in einem übersichtlichen Dropdown
                        </li>
                        <li>
                            Der Status von Gerüstabschnitten wird nun direkt auf der Baustellenseite im Abschnitt “Gerüste” angezeigt
                        </li>
                        <li>
                            Baustellen, Kunden, Anfragen und Aufträge können ab sofort auch über einen Erstellen-Button auf den jeweiligen Übersichtsseiten erstellt werden
                        </li>
                        <li>
                            Weitere kleine Anpassungen und Verbesserungen
                        </li>
                    </ul>
                </section>

                <section>
                    <h4>Benennungen:</h4>
                    <ul>
                        <li>
                            “Bauabschnitt” wurde zu “Gerüstabschnitt” umbenannt
                        </li>
                        <li>
                            “Bemerkungen”, “Kurzbeschreibung” und “Beschreibung” wurden zu “Notizen” umbenannt
                        </li>
                        <li>
                            Baustelle
                            <ul>
                                <li>
                                    “Externe Nutzer” wurde zu “Eingeladene Nutzer” umbenannt
                                </li>
                                <li>
                                    Der Button “Externen Nutzer zur Baustelle einladen” wurde zu “Nutzer zur Baustelle einladen” umbenannt
                                </li>
                            </ul>
                        </li>
                        <li>
                            Gerüstabschnitt
                            <ul>
                                <li>
                                    “Gerüstabschnitt-Aushang” wurde zu “Aushang” umbenannt
                                </li>
                                <li>
                                    “Einzurüstende Flächen” wurde zu “Eingerüstete Flächen” umbenannt
                                </li>
                            </ul>
                        </li>
                        <li>
                            Baustelle, Gerüst, Gerüstabschnitt
                            <ul>
                                <li>
                                    “Weitere Dokumente” wurde zu “Dokumente” umbenannt
                                </li>
                                <li>
                                    “Allgemeine Informationen” wurde zu “Allgemeine Angaben” umbenannt
                                </li>
                                <li>
                                    “Verknüpfte Anfragen” wurde zu “Anfragen” umbenannt
                                </li>
                                <li>
                                    “Verknüpfte Aufträge” wurde zu “Aufträge” umbenannt
                                </li>
                            </ul>
                        </li>
                        <li>
                            Baustelle, Gerüst, Gerüstabschnitt, Anfrage, Auftrag
                            <ul>
                                <li>
                                    “Kennzeichnung” wurde zu “Bezeichnung” umbenannt
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section>
            </div>
            }
        </div>
    );
}

Changelog_1_23_0.defaultProps = {
    modalMode: false
}
