import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../Common/Icon';
import SyncChoiceOption from './SyncChoiceOption';

const SyncChoiceRow = ({ isIdentical, showIdentical, wasEdited, leftValue, rightValue, OptionComponent, ...optionProps }) => {

    if (isIdentical && !showIdentical) {
        return null;
    }

    const getColumn = (isLiveRessource, wasEdited, isIdentical, value, referenceValue, {
        isSelected,
        ...otherOptionProps
    }) => {
        if (isIdentical) {
            return (
                <div className="sync-option sync-option--identical">
                    <div className="sync-option__cnt">
                        <span className="sync-option__identical">
                            <Icon name="correct" /> Identisch
                        </span>
                        {optionProps.renderValue(value, isLiveRessource)}
                    </div>
                </div>
            )
        }

        return <OptionComponent
            value={value}
            isSelected={isSelected(value)}
            isLiveRessource={isLiveRessource}
            wasEdited={wasEdited}
            referenceValue={referenceValue}
            {...otherOptionProps}
        />
    };

    // const wasEdited = leftValue !== null && rightValue !== null;
    const leftColumn = getColumn(false, wasEdited, isIdentical, leftValue, rightValue, optionProps);
    const rightColumn = getColumn(true, wasEdited, isIdentical, rightValue, leftValue, optionProps);

    return (
        <div className={`sync-row ${isIdentical ? 'sync-row--identical' : ''}`}>
            <div className="sync-column">
                {leftColumn}
            </div>
            <div className="sync-column">
                {rightColumn}
            </div>
        </div>
    )
};

SyncChoiceRow.propTypes = {
    OptionComponent: PropTypes.func,
    leftValue: PropTypes.any,
    rightValue: PropTypes.any,
    isIdentical: PropTypes.bool,
    renderValue: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    hint: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    showIdentical: PropTypes.bool,
    wasEdited: PropTypes.bool
};

SyncChoiceRow.defaultProps = {
    OptionComponent: SyncChoiceOption,
    renderValue: (value) => value,
    showIdentical: false,
    wasEdited: false
};

export default SyncChoiceRow;
