import React, { Component } from 'react';

const DEFAULT_HEIGHT = 100;

class ExpandableTextArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: this.props.height || DEFAULT_HEIGHT,
            hasLimit: this.props.limit
        };

        this.setHiddenTextareaHeight = this.setHiddenTextareaHeight.bind(this);
        this.getHiddenTextarea = this.getHiddenTextarea.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.setHiddenTextareaHeight();

        const { hasLimit } = this.state;
        const { value } = this.props;

        if (hasLimit) {
            const initialLength = (value && value.length) || 0;
            this.setRemainingChars(initialLength);
        }
    }

    onChange(event) {
        const { hasLimit } = this.state;
        const { limit } = this.props;

        let tmpEvent = event;

        if (hasLimit) {
            const inputLength = event.target.value.length;
            this.setRemainingChars(inputLength);

            if (inputLength > limit) {

                // cutText
                const cutText = event.target.value.substring(0, limit);

                tmpEvent = {
                    ...event,
                    target: {
                        ...event.target,
                        value: cutText
                    }
                };
            }
        }

        this.props.onChange(tmpEvent);
    }

    getHiddenTextarea() {
        return (
            <div
                className="textarea textarea--hidden"
                ref={(c) => {
                    this.hiddenTextarea = c;
                }}
            >
                {this.props.value}
            </div>
        );
    }

    setHiddenTextareaHeight() {
        if (this.mounted) {
            const element = this.hiddenTextarea;
            this.setState({
                height: element.clientHeight
            });
        }
    }

    setRemainingChars(chars) {

        const threshold = ((this.props.limit - chars) <= 0) ? 0 : this.props.limit - chars;

        this.setState({
            charsLeft: threshold
        });
    }

    renderAvailableChars() {
        const { charsLeft } = this.state;

        const classes = ['textarea__charcount'];

        if (charsLeft <= 0) {
            classes.push('textarea__charcount--warning');
        }

        return (
            <div className={classes.join(' ')}>
                <span> {charsLeft} Zeichen verfügbar </span>
            </div>
        );
    }

    render() {
        const { height, hasLimit } = this.state;
        const { placeholder } = this.props;

        return (
            <div className="textarea-wrapper">
                <textarea
                    {...this.props}
                    onChange={this.onChange}
                    className="textarea"
                    onKeyUp={this.setHiddenTextareaHeight}
                    style={{
                        height,
                        resize: 'none'
                    }}
                    readOnly={this.props.readOnly}
                    placeholder={placeholder}
                />
                {hasLimit && this.renderAvailableChars()}
                {this.getHiddenTextarea()}
            </div>
        );
    }

}

export default ExpandableTextArea;
