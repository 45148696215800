import React, { Component } from 'react';
import { Field } from 'react-final-form';
import ConstructionDiaryTeamMemberModal from './ConstructionDiaryTeamMemberModal';

class ConstructionDiaryTeamMemberField extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(teamMemberList) {
        this.props.input.onChange(teamMemberList);
    }

    render() {
        const { input } = this.props;
        return (
            <ConstructionDiaryTeamMemberModal
                {...input}
                onChange={this.handleChange}
            />
        );
    }
}

const ConstructionDiaryTeamMemberInput = ({ ...props }) => (
    <Field
        {...props}
        component={ConstructionDiaryTeamMemberField}
    />
);

export default ConstructionDiaryTeamMemberInput;
