import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FORM_ERROR } from 'final-form';
import TopNav from '../../../Components/Page/TopNav';
import { validateEmail } from '../../../utils';
import { registerUser } from '../../../redux/modules/user/action';
import PublicPage from './PublicPage';
import InputGroup from '../../../Components/Forms/InputGroup';
import Input from '../../../Components/Forms/Input';
import Select from '../../../Components/Forms/Select';
import WizardForm from '../../../Components/Forms/WizardForm';
import { showApiError } from '../../../redux/modules/error/action';
import { BadRequestError, ConflictError } from '../../../Services/ApiError';
import Icon from '../../../Components/Common/Icon';
import ErrorService from '../../../Services/ErrorService';
import Error from '../../../Components/Forms/Error';
import Checkbox from '../../../Components/Forms/Checkbox';
import Condition from '../../../Components/Forms/Condition';
import Banner from '../../../Components/Common/Banner';
import ExternalLink from '../../../Components/Common/ExternalLink';
import { ADV_DOCUMENT_URL, PRIVACY_DOCUMENT_URL, TERMS_DOCUMENT_URL } from '../../../constants';

class RegistrationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSending: false,
            confirmationWasSent: false
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        const userData = {
            salutation: values.salutation,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            company: {
                name: values.companyName,
                address: {
                    line1: values.companyLine1,
                    line2: values.companyLine2,
                    zip: values.companyZip,
                    city: values.companyCity
                }
            },
            accountType: values.accountType,
            stripeToken: values.stripeToken
        };

        this.setState({
            isSending: true
        });

        return this.props.actions.registerUser(userData)
            .then(() => {
                this.setState({
                    isSending: false,
                    confirmationWasSent: true
                });
            })
            .catch((error) => {

                this.setState({
                    isSending: false
                });

                if(error instanceof BadRequestError){
                    return ErrorService.handleBadRequestError(error, 'register')
                        .then(data => ({ [FORM_ERROR]: data.email }));
                }

                if (error instanceof ConflictError) {
                    return ErrorService.handleConflictError(error, 'register')
                        .then(data => ({ [FORM_ERROR]: data.email }));
                }
                return showApiError(error);
            });
    }

    render() {
        const { confirmationWasSent, isSending } = this.state;

        return (
            <PublicPage>
                <TopNav isPublic />

                <div className="registration-form">

                    {confirmationWasSent ?
                        <React.Fragment>
                            <h1>
                                <span className="verification-success">
                                    <Icon name="stateok" />
                                </span> Registrierung erfolgreich
                            </h1>
                            <div className="section">
                                <div className="confirmation">
                                    <p>Wir haben soeben eine E-Mail zur Überprüfung Ihrer Adresse an
                                        die in den Nutzerdaten hinterlegte E-Mail Adresse
                                        gesendet. Bitte überprüfen Sie Ihr E-Mail-Postfach und
                                        klicken Sie auf den Link in der E-Mail, um die Registrierung
                                        abzuschließen.
                                    </p>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h1>Herzlich Willkommen bei Scaffeye®</h1>
                            <Banner type="warning">
                                <h4>Neu: Scaffeye® Light ab sofort kostenlos.</h4>
                                <p>Lassen Sie sich von Gerüsterstellern zu Baustellen einladen und
                                    prüfen Sie die dort vorhandenen Gerüste kostenlos.</p>
                            </Banner>

                            <WizardForm
                                onSubmit={this.onSubmit}
                                navigationalLinks={['1. Paketwahl', '2. Firmeninformationen', '3. Nutzer']}
                                isSending={isSending}
                            >
                                <WizardForm.Page
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.accountType) {
                                            errors.accountType = 'Bitte wählen Sie ein Paket aus';
                                        }
                                        return errors;
                                    }}
                                >
                                    <div className="registration-form__infobox">
                                        Bitte wählen Sie ein Paket aus, das am besten zu Ihnen und
                                        Ihrem Unternehmen passt.

                                        Bei Auswahl des Basic- oder Pro-Paketes starten Sie diese Pakete mit einer 30-tägigen Testversion.

                                    </div>

                                    <InputGroup
                                        label="Ihr Paket*"
                                    >
                                        <Select
                                            name="accountType"
                                            placeholder="Wählen Sie Ihr Paket"
                                            options={[
                                                {
                                                    label: 'Scaffeye LIGHT',
                                                    value: 'light'
                                                },
                                                {
                                                    label: 'Scaffeye BASIC',
                                                    value: 'basic'
                                                },
                                                {
                                                    label: 'Scaffeye PRO',
                                                    value: 'pro'
                                                }
                                            ]}
                                        />

                                        <Condition when="accountType" contains="light">
                                            <p className="registration-form__licencetext">
                                                Kostenlose Version von Scaffeye®.
                                                Optimal für alle Gerüstnutzer, die von
                                                Gerüsterstellern zum Prüfen ihrer Gerüste eingeladen
                                                werden.
                                            </p>
                                            <p className="registration-form__licencetext">
                                                Mit Scaffeye® Light können Sie Gerüstabschnitte prüfen,
                                                zu denen Sie zuvor von einem Gerüstersteller
                                                (Scaffeye® Pro Nutzer) eingeladen wurden.
                                            </p>
                                            <p className="registration-form__licencetext">
                                                Ohne Einladung haben Sie die Möglichkeit, alle
                                                Gerüst- und Gerüstabschnittsdetails einer Baustelle
                                                durch Einscannen der Scaffeye® QR-Codes einzusehen.
                                            </p>
                                            <p className="registration-form__licencetext">
                                                Um Gerüstabschnitte unabhängig vom Gerüstersteller
                                                prüfen zu können, wählen Sie bitte Scaffeye® Basic.
                                            </p>

                                            <ExternalLink
                                                label="Weitere Informationen zu den Paketen"
                                                target="https://www.scaffeye.de/"
                                            />
                                        </Condition>

                                        <Condition when="accountType" contains="basic">
                                            <p className="registration-form__licencetext">Optimal
                                                für Gerüstnutzer.
                                            </p>
                                            <p className="registration-form__licencetext"> Hinterlegen
                                                und verwalten Sie Gerüste anderer Unternehmen
                                                digital und führen Sie Sicherheitsprüfungen per App
                                                durch.
                                            </p>
                                            <p className="registration-form__licencetext">Erhalten
                                                Sie eine Prüfhistorie aller von Ihnen durchgeführten
                                                Sicherheitsprüfungen und exportieren Sie diese auf
                                                Wunsch. Kommunizieren Sie mit Ihrem Team über die
                                                baustellenspezifische Kommentarfunktion.
                                            </p>
                                            <ExternalLink
                                                label="Weitere Informationen zu den Paketen"
                                                target="https://www.scaffeye.de/"
                                            />
                                        </Condition>

                                        <Condition when="accountType" contains="pro">
                                            <p className="registration-form__licencetext">Optimal
                                                für Gerüstersteller und Unternehmen in
                                                Hybridrollen.
                                            </p>
                                            <p className="registration-form__licencetext">Erstellen
                                                und verwalten Sie Ihre Aufträge, Baustellen und
                                                Gerüste und geben Sie Ihre Gerüstabschnitte über
                                                Scaffeye® frei. Stellen Sie Ihren Nutzern alle
                                                Gerüstspezifikationen per QR-Aushang zur Verfügung
                                                und verfolgen Sie den Status Ihrer Gerüste.
                                                Kommunizieren Sie mit Ihrem Team über die
                                                baustellenspezifische Kommentarfunktion.
                                            </p>
                                            <p className="registration-form__licencetext">Erhalten
                                                Sie eine Prüfhistorie aller von Ihnen durchgeführten
                                                Sicherheitsprüfungen und exportieren Sie diese auf
                                                Wunsch. Kommunizieren Sie mit Ihrem Team über die
                                                baustellenspezifische Kommentarfunktion.
                                            </p>
                                            <ExternalLink
                                                label="Weitere Informationen zu den Paketen"
                                                target="https://www.scaffeye.de/"
                                            />
                                        </Condition>
                                    </InputGroup>
                                </WizardForm.Page>

                                <WizardForm.Page
                                    validate={(values) => {
                                        const errors = {};

                                        if (!values.companyName) {
                                            errors.companyName = 'Bitte geben Sie einen Firmennamen ein';
                                        }

                                        if (!values.companyLine1) {
                                            errors.companyLine1 = 'Bitte geben Sie einen eine Firmenadresse ein';
                                        }

                                        if (!values.companyZip) {
                                            errors.companyZip = 'Bitte geben Sie eine Postleitzahl ein';
                                        } else if (!values.companyZip.toString()
                                            .match(/^[0-9]{5}$/i)) {
                                            errors.companyZip = 'Bitte geben Sie eine Zahl ein';
                                        }

                                        if (!values.companyCity) {
                                            errors.companyCity = 'Bitte geben Sie einen Ort ein';
                                        }

                                        return errors;
                                    }}
                                >
                                    <div className="registration-form__infobox">
                                        Bitte hinterlegen Sie hier Ihre Unternehmensdaten
                                    </div>

                                    <InputGroup
                                        label="Firmenname*"
                                    >
                                        <Input
                                            type="text"
                                            name="companyName"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Adresszeile 1*"
                                    >
                                        <Input
                                            type="text"
                                            name="companyLine1"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Adresszeile 2"
                                    >
                                        <Input
                                            type="text"
                                            name="companyLine2"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="Postleitzahl, Ort*"
                                        horizontal="true"
                                    >
                                        <div className="input-grp__field--zip">
                                            <Input
                                                type="text"
                                                name="companyZip"
                                                placeholder="PLZ"
                                            />
                                        </div>
                                        <div className="input-grp__field--city">
                                            <Input
                                                type="text"
                                                name="companyCity"
                                                placeholder="Ort"
                                            />
                                        </div>
                                    </InputGroup>

                                    <InputGroup
                                        label="Land"
                                    >
                                        <Select
                                            options={[
                                                {
                                                    label: 'Deutschland',
                                                    value: 'DE'
                                                }
                                            ]}
                                            name="companyCountry"
                                        />
                                    </InputGroup>

                                </WizardForm.Page>

                                <WizardForm.Page
                                    validate={(values) => {
                                        const errors = {};
                                        if (!values.firstName) {
                                            errors.firstName = 'Bitte geben Sie einen Vornamen an';
                                        }
                                        if (!values.lastName) {
                                            errors.lastName = 'Bitte geben Sie einen Nachnamen an';
                                        }
                                        if (!values.email) {
                                            errors.email = 'Bitte geben Sie eine E-Mail Adresse an';
                                        } else if (!validateEmail(values.email)) {
                                            errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse an';
                                        }
                                        if (!values.password) {
                                            errors.password = 'Bitte geben Sie ein Passwort ein';
                                        } else if (values.password.length < 6) {
                                            errors.password = 'Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein';
                                        } else if (values.password !== values.passwordConfirm) {
                                            errors.passwordConfirm = 'Ihre Passwörter stimmen nicht überein';
                                        }

                                        if (!values.terms) {
                                            errors.terms = 'Bitte bestätigen, dass Sie unsere AGB und Datenschutzerklärung gelesen haben';
                                        }
                                        return errors;
                                    }}
                                >
                                    <div className="registration-form__infobox">
                                        Als letztes benötigen wir noch Informationen über den ersten
                                        Nutzer, den Sie für Ihr Unternehmenskonto bei Scaffeye®
                                        registrieren möchten. Dem ersten Nutzer wird stets die Rolle
                                        des Administrators zugewiesen.
                                    </div>

                                    <InputGroup
                                        label="Anrede"
                                    >
                                        <Select
                                            name="salutation"
                                            placeholder="Anrede wählen"
                                            options={[
                                                {
                                                    label: 'Herr',
                                                    value: 'mr'
                                                },
                                                {
                                                    label: 'Frau',
                                                    value: 'mrs'
                                                }
                                            ]}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Vorname*"
                                    >
                                        <Input
                                            type="text"
                                            name="firstName"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Nachname*"
                                    >
                                        <Input
                                            type="text"
                                            name="lastName"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label="E-Mail Adresse*"
                                    >
                                        <Input
                                            type="text"
                                            name="email"
                                            placeholder="E-Mail Adresse eingeben"
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        label="Passwort*"
                                    >
                                        <Input
                                            type="password"
                                            name="password"
                                            placeholder="Passwort eingeben"
                                        />
                                        <small> Ihr Passwort sollte mehr als 6 Zeichen enthalten.
                                        </small>
                                    </InputGroup>
                                    <InputGroup
                                        label="Passwort wiederholen"
                                    >
                                        <Input
                                            type="password"
                                            name="passwordConfirm"
                                            placeholder="Passwort wiederholen"
                                        />
                                    </InputGroup>

                                    <InputGroup
                                        label=""
                                    >
                                        <Checkbox
                                            name="terms"
                                            label={<span className="registration-form__terms">Ich habe die <a
                                                href={TERMS_DOCUMENT_URL}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >AGB
                                            </a> und die <a
                                                href={PRIVACY_DOCUMENT_URL}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >Datenschutzerklärung
                                            </a> gelesen und akzeptiere diese.
                                            </span>}
                                        />
                                        <Error name="terms" />
                                    </InputGroup>

                                    <InputGroup containerClass="last" label="">
                                        <span className="registration-form__terms">
                                        Mit der Registrierung bei Scaffeye® akzeptieren Sie unsere {' '}
                                            <a
                                                href={ADV_DOCUMENT_URL}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            > Auftragsdatenverarbeitung.
                                            </a>
                                        </span>
                                    </InputGroup>

                                </WizardForm.Page>
                            </WizardForm>
                        </React.Fragment>
                    }
                    <div className="registration-form__footer">
                        <p> Sie haben bereits ein Konto?
                            <Link to="/login">
                                Zur Anmeldung
                            </Link>
                        </p>
                    </div>
                </div>
            </PublicPage>
        );
    }

}

const mapStateToProps = state => ({
    isLoggingIn: state.currentUser.isLoggingIn
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ registerUser }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);

RegistrationPage.propTypes = {
    actions: PropTypes.object,
    isSending: PropTypes.bool
};


RegistrationPage.defaultProps = {
    actions: {
        loginUser: () => false
    },
    isLoggingIn: false

};
