import React from 'react';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';
import TopNav from '../../../Components/Page/TopNav';
import AccountProfile from '../../../Components/Account/AccountProfile';
import PageTitle from '../../../Components/Page/PageTitle';

const AccountProfilePage = () => (
    <PrivatePage requiredUserPermission={Permission.UpdateBaseData}>
        <Container>
            <TopNav />
            <PageTitle title="Stammdaten" />
            <AccountProfile />
        </Container>
    </PrivatePage>
);

export default AccountProfilePage;
