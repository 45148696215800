import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { getCladdingLabel } from '../../../../Sections/labels';
import { Cladding, SectionStatus } from '../../../../Sections/properties';
import asSyncChoiceField from '../../asSyncChoiceField';
import SyncChoiceDiffOptionRows from '../../Base/Input/SyncChoiceDiffOptionRows';
import SyncChoiceIdenticalRow from '../../Base/Input/SyncChoiceIdenticalRow';
import SyncChoiceOptionHint from '../../Base/Input/SyncChoiceOptionHint';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';
import { validateListChoiceComplete } from '../../validation';
import { getDiffCladdingOptionRows, getIdenticalCladdingOptionRows } from './functions';

const CladdingsSyncChoice = ({ planValue, liveValue, value: currentFormValue, onChange, liveStatus, showIdentical }) => {

    const onSelect = (selectedValue) => {
        const newValue = {
            ...currentFormValue,
            ...selectedValue
        };

        onChange(newValue);
    };

    const renderCladdingLabel = (value) => {

        if (!value) {
            return '-';
        }

        const valueKey = Object.keys(value)[0];

        // Festverkleidung
        if (valueKey === Cladding.FixedTrim) {

            if (value[valueKey] === false) {
                return '-';
            }

            return `Festverkleidung: ${value[valueKey]}`;
        }

        // Not set
        if (value[valueKey] === false) {
            return '-';
        }

        return getCladdingLabel(Object.keys(value)[0]);

    };

    const isSelected = (value) => {

        if (!currentFormValue || !value) {
            return false;
        }

        const key = value ? Object.keys(value)[0] : undefined;
        if (!key) {
            return false;
        }

        return currentFormValue[key] === value[key];
    };

    function hasSelected(value, claddingOption) {
        const keys = Object.keys(value);
        if (!keys.length) {
            return false;
        }

        return keys.indexOf(claddingOption) >= 0 && value[claddingOption] === true;
    }

    const showHint = (value) => {

        if (!value || liveStatus === SectionStatus.Draft) {
            return null;
        }

        if (hasSelected(Cladding.Net) || hasSelected(Cladding.Tarp)) {
            return <SyncChoiceOptionHint
                tooltipId="sync-claddings-net-hint"
                content="Bei Verwendung von Netzen und/oder Planen, muss die Option Verankerung (unter Besonderheiten) gewählt oder das Gerüst als freistehende Gerüstkonstruktion (unter Besonderheiten) markiert werden."
            />;
        }

        return null;
    };

    const renderIdenticalRows = () => {
        const identicalOptionRows = getIdenticalCladdingOptionRows(planValue, liveValue);
        if (_.isEmpty(identicalOptionRows)) {
            return (
                <SyncChoiceIdenticalRow
                    leftValue={null}
                    rightValue={null}
                    renderValue={renderCladdingLabel}
                />
            );
        }

        return identicalOptionRows.map(optionRow => (
            <SyncChoiceIdenticalRow
                key={optionRow.key}
                leftValue={optionRow.left}
                rightValue={optionRow.right}
                renderValue={renderCladdingLabel}
            />
        ));
    };

    const isIdentical = _.isEqual(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Gerüstverkleidungen"
        >
            {showIdentical && renderIdenticalRows()}
            <SyncChoiceDiffOptionRows
                planValue={planValue}
                liveValue={liveValue}
                getOptionRows={getDiffCladdingOptionRows}
                renderValue={renderCladdingLabel}
                onSelect={onSelect}
                isSelected={isSelected}
                getHint={showHint}
            />
        </SyncInputGroup>
    );
};

CladdingsSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    liveStatus: PropTypes.string,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    showIdentical: PropTypes.bool
};

CladdingsSyncChoice.defaultProps = {
    planValue: null,
    liveValue: null,
    onChange: () => false
};

export default asSyncChoiceField(CladdingsSyncChoice, {
    allowNull: true,
    validate: validateListChoiceComplete()
});
