import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../../properties';
import { ModelMediaType } from '../../../Media/properties';
import { ConstructionPlanModelMode } from '../../properties';
import ConstructionPlanSyncModelReferenceHint from '../Base/ConstructionPlanSyncModelReferenceHint';
import MediaSyncChoice from '../Base/Input/Media/MediaSyncChoice';
import MediaListSyncChoice from '../Base/Input/MediaListSyncChoice/MediaListSyncChoice';
import TextSyncChoice from '../Base/Input/Text/TextSyncChoice';
import SyncModelSection from '../Base/SyncModelSection';
import { getModelTypeKeyWithIdForForm, isOnlyOneValueSet } from '../form';
import {
    getMediaFromRepository,
    getSingleMediaFromRepository,
    propertyReferencesMedia,
    propertyReferencesSingleMedia
} from '../media';
import ConstructionPlanSyncSectionList from '../Section/ConstructionPlanSyncSectionList';
import { isIdenticalScaffoldingFormValue } from './equals';
import {
    transformLiveScaffoldingToFormValues,
    transformScaffoldingPlanToFormValues
} from './functions';
import ScheduledServiceTimeSyncChoice from './ScheduledServiceTime/ScheduledServiceTimeSyncChoice';

class ConstructionPlanSyncFormScaffolding extends React.Component {

    static propTypes = {
        values: PropTypes.object.isRequired,
        planValues: PropTypes.any,
        liveValues: PropTypes.any,
        setModelDataMutator: PropTypes.func.isRequired,
        setFieldTouchedMutator: PropTypes.func.isRequired,
        resolvedMedia: PropTypes.object.isRequired
    };

    static defaultProps = {
        liveValues: null
    };

    constructor(props) {
        super(props);
        this.scaffoldingKey = getModelTypeKeyWithIdForForm(ModelType.Scaffolding, props.planValues);
        this.selectAllPlan = this.selectAllPlan.bind(this);
        this.selectAllLive = this.selectAllLive.bind(this);
        this.setModelDataMutator = this.setModelDataMutator.bind(this);
    }

    selectAllPlan() {
        const { values, planValues, resolvedMedia, liveValues } = this.props;
        const newFormData = transformScaffoldingPlanToFormValues(planValues.data, liveValues, resolvedMedia);
        const newFormValues = {
            ...values[this.scaffoldingKey],
            data: newFormData
        };
        this.props.setModelDataMutator(this.scaffoldingKey, newFormValues);
    }

    selectAllLive() {
        const { values, planValues, liveValues, resolvedMedia } = this.props;
        const newFormData = transformLiveScaffoldingToFormValues(liveValues, planValues.data, resolvedMedia);
        const newFormValues = {
            ...values[this.scaffoldingKey],
            data: newFormData
        };
        this.props.setModelDataMutator(this.scaffoldingKey, newFormValues);
    }

    getFormNameForProperty(property) {
        return `${this.scaffoldingKey}.data.${property}`;
    }

    hasIdenticalOptions(stopTraverse = true) {
        const { planValues: { data }, liveValues } = this.props;
        let hasIdentical = false;

        if (liveValues) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) {
                    hasIdentical = isIdenticalScaffoldingFormValue(property, data, liveValues);
                }

                if (stopTraverse === hasIdentical) {
                    break;
                }
            }
        }

        return hasIdentical;
    }

    getName() {
        const { liveValues, planValues } = this.props;
        return planValues.data ? planValues.data.name : liveValues.name;
    }

    getLiveValue(property) {
        const { liveValues, planValues, resolvedMedia } = this.props;

        if (propertyReferencesMedia(property)) {
            return getMediaFromRepository(liveValues ? liveValues[property] : null, resolvedMedia);
        } else if (propertyReferencesSingleMedia(property)) {
            return getSingleMediaFromRepository(liveValues, property, resolvedMedia);
        }

        return this.getValue(property, liveValues, planValues.data);
    }

    getPlanValue(property) {
        const { planValues, liveValues, resolvedMedia } = this.props;

        if (propertyReferencesMedia(property)) {
            if (!planValues) {
                return null;
            }
            return getMediaFromRepository(planValues.data[property], resolvedMedia);
        } else if (propertyReferencesSingleMedia(property)) {
            if (!planValues) {
                return null;
            }
            return getSingleMediaFromRepository(planValues.data, property, resolvedMedia);
        }

        return this.getValue(property, planValues.data, liveValues);
    }

    getValue(property, modelToGetBack, modelToCompare) {
        let defaultValue = undefined;

        // one value is set an the other not.
        // input fields allow null to select without warning
        if (isOnlyOneValueSet(property, modelToGetBack, modelToCompare)) {
            defaultValue = null;
        }

        if (modelToGetBack && modelToGetBack.hasOwnProperty(property) && modelToGetBack[property]) {
            if (typeof modelToGetBack[property] === 'string') {
                return modelToGetBack[property];
            } else if (typeof modelToGetBack[property] === 'object' && Object.keys(modelToGetBack[property]).length > 0) {
                let isOnePropertySet = Object.keys(modelToGetBack[property])
                    .forEach(property => {
                        return !property;
                    });

                if (isOnePropertySet) {
                    return modelToGetBack[property];
                }
            }
        }

        return defaultValue;
    }

    renderInputFields() {

        const {
            values,
            setFieldTouchedMutator,
            planValues
        } = this.props;

        if (planValues.mode === ConstructionPlanModelMode.Reference) {
            return <ConstructionPlanSyncModelReferenceHint/>;
        }

        const showIdentical = values[this.scaffoldingKey].showIdentical;

        return (
            <Fragment>
                <TextSyncChoice
                    label="Name"
                    name={this.getFormNameForProperty('name')}
                    planValue={this.getPlanValue('name')}
                    liveValue={this.getLiveValue('name')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                    required
                />

                <TextSyncChoice
                    label='Notizen'
                    name={this.getFormNameForProperty('description')}
                    planValue={this.getPlanValue('description')}
                    liveValue={this.getLiveValue('description')}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <ScheduledServiceTimeSyncChoice
                    name={this.getFormNameForProperty('serviceTime')}
                    setFieldTouched={setFieldTouchedMutator}
                    planValue={{
                        scheduledErection: this.getPlanValue('scheduledErection'),
                        scheduledDismantling: this.getPlanValue('scheduledDismantling')
                    }}
                    liveValue={{
                        scheduledErection: this.getLiveValue('scheduledErection'),
                        scheduledDismantling: this.getLiveValue('scheduledDismantling')
                    }}
                    showIdentical={showIdentical}
                />

                <MediaListSyncChoice
                    label="Anhänge"
                    name={this.getFormNameForProperty('attachments')}
                    planValue={this.getPlanValue('attachments')}
                    liveValue={this.getLiveValue('attachments')}
                    modelMediaType={ModelMediaType.Scaffolding_Attachment}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />

                <MediaSyncChoice
                    label="Ankerprotokoll"
                    name={this.getFormNameForProperty('anchorProtocol')}
                    setFieldTouched={setFieldTouchedMutator}
                    planValue={this.getPlanValue('anchorProtocol')}
                    liveValue={this.getLiveValue('anchorProtocol')}
                    modelMediaType={ModelMediaType.Scaffolding_AnchorProtocol}
                    showIdentical={showIdentical}
                />

                <MediaListSyncChoice
                    label="Nutzungsplan"
                    name={this.getFormNameForProperty('utilizationPlans')}
                    planValue={this.getPlanValue('utilizationPlans')}
                    liveValue={this.getLiveValue('utilizationPlans')}
                    modelMediaType={ModelMediaType.Scaffolding_UtilizationPlan}
                    setFieldTouched={setFieldTouchedMutator}
                    showIdentical={showIdentical}
                />
            </Fragment>
        );
    }

    setModelDataMutator(data) {
        const { setModelDataMutator, planValues, values } = this.props;

        //toggle ignore sections like scaffolding
        if (planValues.sections) {
            planValues.sections.forEach(section => {
                if (section.mode !== ConstructionPlanModelMode.Reference) {
                    const sectionKey = getModelTypeKeyWithIdForForm(ModelType.Section, section);
                    const formSection = values[sectionKey];
                    formSection.ignored = data.ignored;
                    setModelDataMutator(sectionKey, formSection);
                }
            });
        }

        setModelDataMutator(this.scaffoldingKey, data);
    }

    render() {
        const {
            planValues,
            liveValues,
            values,
            setModelDataMutator,
            setFieldTouchedMutator,
            resolvedMedia
        } = this.props;

        const sections = planValues.sections ? planValues.sections : [];
        const ignoreSections = values[this.scaffoldingKey].ignored;

        let liveData;

        if (liveValues) {
            const { sections: a, ...liveValuesWithoutSections } = liveValues;
            liveData = liveValuesWithoutSections;
        } else {
            liveData = liveValues;
        }

        return (
            <Fragment>
                <SyncModelSection
                    type={ModelType.Scaffolding}
                    formValues={values[this.scaffoldingKey]}
                    name={this.getName()}
                    onChange={(data) => this.setModelDataMutator(data)}
                    planData={planValues.data}
                    liveData={liveData}
                    hasIdenticalOptions={this.hasIdenticalOptions(true)}
                    mode={planValues.mode}
                    resolvedMedia={resolvedMedia}
                    onSelectAllPlan={this.selectAllPlan}
                    onSelectAllLive={this.selectAllLive}
                    allValuesIdentical={this.hasIdenticalOptions(false)}
                >
                    {this.renderInputFields()}
                </SyncModelSection>

                {
                    !ignoreSections && <ConstructionPlanSyncSectionList
                        setFieldTouchedMutator={setFieldTouchedMutator}
                        data={sections}
                        resolvedMedia={resolvedMedia}
                        setModelDataMutator={setModelDataMutator}
                        values={values}
                        liveScaffolding={liveValues}
                    />
                }


            </Fragment>
        );
    }
}

export default ConstructionPlanSyncFormScaffolding;
