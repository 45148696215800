import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import InputGroup from '../Forms/InputGroup';
import Section from '../Layout/Section';
import Input from '../Forms/Input';
import ResourceNotFoundErrorPage from '../../Containers/Pages/Errors/ResourceNotFoundErrorPage';
import { fetchBillingAddress, updateBillingAddress } from '../../redux/modules/account/action';
import { showApiError } from '../../redux/modules/error/action';
import LoadingSpinner, {PageLoadingSpinner} from '../Page/LoadingSpinner';

class BillingAddressForm extends Component {

    static validate(values) {
        const errors = {};

        if (!values.name) {
            errors.name = 'Bitte geben Sie einen Namen ein';
        }

        if (!values.line1) {
            errors.line1 = 'Bitte geben Sie eine Adresse ein';
        }

        // Zip
        if (!values.zip) {
            errors.zip = 'Bitte geben Sie eine Postleitzahl an';
        } else if (!values.zip.match(/\d{5}/i)) {
            errors.zip = 'Bitte geben Sie eine Zahl ein';
        }

        // City
        if (!values.city) {
            errors.city = 'Bitte geben Sie einen Ort ein';
        }

        return errors;
    }

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            isFetching: false,
            isSending: false,
            data: {}
        };
        this.prepareFormData = this.prepareFormData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
    }

    componentWillMount() {
        const { actions: { fetchBillingAddress } } = this.props;

        this.setState({ isFetching: true });

        fetchBillingAddress()
            .then((billingAddressData) => {

                if (this.loadingGotCancelled) {
                    return;
                }
                this.prepareFormData(billingAddressData);
            })
            .catch((error) => {
                showApiError(error);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onSubmit(values) {

        const billingAddressData = {
            name: values.name,
            line1: values.line1,
            line2: values.line2,
            city: values.city,
            zip: values.zip,
            country: 'DE',
            vatId: values.vatId
        };

        this.setState({ isSending: true });

        const { actions: { updateBillingAddress }, history } = this.props;

        return updateBillingAddress(billingAddressData)
            .then(() => {
                this.setState({
                    isSending: false
                });
                toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });
                history.push('/account/subscription');
            })
            .catch((error) => {

                this.setState({
                    isSending: false
                });

                return showApiError(error);
            });
    };

    prepareFormData(data) {
        if (!data) {
            this.setState({
                data: null,
                isFetching: false
            });
            return;
        }

        const { user: { account: { billingAddress } } } = data;

        const postLoadData = {
            name: billingAddress.name,
            line1: billingAddress.line1,
            line2: billingAddress.line2,
            zip: billingAddress.zip,
            city: billingAddress.city,
            vatId: billingAddress.vatId
        };

        this.setState({
            data: postLoadData,
            isFetching: false
        });
    }

    renderButtonText() {
        const { isSending } = this.state;
        return isSending ? 'Änderungen werden gespeichert ...' : 'Speichern';
    }

    render() {
        const {
            data,
            isFetching,
            isSending
        } = this.state;

        if (!isFetching && !data) {
            return <ResourceNotFoundErrorPage />;
        }

        if (isFetching) {
            return <PageLoadingSpinner />;
        }

        return (
            <div className="billingAddressForm">
                <Form
                    onSubmit={this.onSubmit}
                    validate={BillingAddressForm.validate}
                    initialValues={data}
                    render={({ handleSubmit, submitError }) => (
                        <form onSubmit={handleSubmit}>
                            <Section
                                title="Anschrift"
                            >
                                <InputGroup
                                    label="Firmenname*"
                                >
                                    <Input
                                        type="text"
                                        name="name"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Adresszeile 1*"
                                >
                                    <Input
                                        type="text"
                                        name="line1"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Adresszeile 2"
                                >
                                    <Input
                                        type="text"
                                        name="line2"
                                    />
                                </InputGroup>

                                <InputGroup
                                    label="Postleitzahl, Ort*"
                                    horizontal="true"
                                >
                                    <div className="input-grp__field--zip">
                                        <Input
                                            type="text"
                                            name="zip"
                                            placeholder="PLZ"
                                        />
                                    </div>
                                    <div className="input-grp__field--city">
                                        <Input
                                            type="text"
                                            name="city"
                                            placeholder="Ort"
                                        />
                                    </div>
                                </InputGroup>

                                <InputGroup
                                    label="USt.ID."
                                >
                                    <Input
                                        type="text"
                                        name="vatId"
                                    />
                                </InputGroup>
                            </Section>

                            <div className="btn-group">
                                <a
                                    onClick={this.props.history.goBack}
                                    className="btn btn--backward-action"
                                >
                                    Abbrechen
                                </a>

                                <span className="btn btn--dummy">*Pflichtfeld</span>
                                <button
                                    type="submit"
                                    className="btn btn--forward-action btn--save"
                                    disabled={isSending}
                                >
                                    {this.renderButtonText()}
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateBillingAddress,
        fetchBillingAddress
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(BillingAddressForm);
