import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ModelType } from '../../../properties';
import InputGroup from '../../Forms/InputGroup';
import MediaField from '../../Forms/Media/Field/MediaField';
import MediaUploadButton from '../../Forms/Media/MediaUploadButton';
import MediaUploadModal from '../../Forms/Media/Modal/MediaFormModal';
import { MediaFormDataPropType, ModelMediaType } from '../../Media/properties';

class ScaffoldingMediaFormPart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadModalIsOpen: false
        };

        this.openMediaUploadModal = this.openMediaUploadModal.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.closeMediaUploadModal = this.closeMediaUploadModal.bind(this);
    }

    openMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: true
        });
    }

    closeMediaUploadModal() {
        this.setState({
            uploadModalIsOpen: false
        });
    }

    addAttachments(attachmentsToAdd) {

        if (!attachmentsToAdd || attachmentsToAdd.length === 0) {
            return;
        }

        const {
            attachments,
            attachmentsMutator
        } = this.props;

        let newMediaList;

        if (attachments) {
            newMediaList = [
                ...attachments,
                ...attachmentsToAdd
            ];
        } else {
            newMediaList = [
                ...attachmentsToAdd
            ];
        }

        attachmentsMutator(newMediaList);
    }

    addUtilizationPlans(utilizationPlansToAdd) {

        if (!utilizationPlansToAdd || utilizationPlansToAdd.length === 0) {
            return;
        }

        const {
            utilizationPlans,
            utilizationPlanMutator
        } = this.props;

        let newMediaList;

        if (utilizationPlans) {
            newMediaList = [
                ...utilizationPlans,
                ...utilizationPlansToAdd
            ];
        } else {
            newMediaList = [
                ...utilizationPlansToAdd
            ];
        }

        utilizationPlanMutator(newMediaList);
    }

    setAnchorProtocol(newAnchorProtocol) {
        const {
            anchorProtocolMutator
        } = this.props;

        anchorProtocolMutator([newAnchorProtocol]);
    }

    onUploaded(mediaList) {
        const addedAttachments = [];
        const addedUtilizationPlans = [];
        let anchorProtocol = null;

        mediaList.forEach((media) => {
            switch (media.modelMediaType) {
                case ModelMediaType.Scaffolding_UtilizationPlan:
                    addedUtilizationPlans.push(media);
                    break;

                case ModelMediaType.Scaffolding_AnchorProtocol:
                    anchorProtocol = media;
                    break;

                case ModelMediaType.Scaffolding_Attachment:
                default:
                    addedAttachments.push(media);
                    break;
            }
        });

        this.addAttachments(addedAttachments);
        this.addUtilizationPlans(addedUtilizationPlans);

        if (anchorProtocol) {
            this.setAnchorProtocol(anchorProtocol);
        }
    }

    render() {
        const { uploadModalIsOpen } = this.state;
        const { readOnly, isConstructionPlan } = this.props;

        return (
            <Fragment>
                <InputGroup
                    label="Nutzungspläne"
                    type="media"
                >
                    <MediaField
                        name="utilizationPlans"
                        emptyListLabel="Kein Nutzungsplan vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup
                    label="Ankerprotokoll"
                    type="media"
                >
                    <MediaField
                        name="anchorProtocol"
                        emptyListLabel="Kein Ankerprotokoll vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup
                    label="Anhänge"
                    type="media"
                >
                    <MediaField
                        name="attachments"
                        emptyListLabel="Keine Anhänge vorhanden"
                        readOnly={readOnly}
                        isConstructionPlan={isConstructionPlan}
                    />
                </InputGroup>

                <InputGroup>
                    <MediaUploadButton onClick={this.openMediaUploadModal} disabled={readOnly} />
                </InputGroup>

                {uploadModalIsOpen &&
                <MediaUploadModal
                    onUploading={this.props.onUploading}
                    onUploaded={this.onUploaded}
                    modelType={ModelType.Scaffolding}
                    onClose={this.closeMediaUploadModal}
                    isConstructionPlan={isConstructionPlan}
                />
                }
            </Fragment>
        );
    }
}

ScaffoldingMediaFormPart.propTypes = {
    onUploading: PropTypes.func.isRequired,
    attachments: PropTypes.arrayOf(MediaFormDataPropType).isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    anchorProtocolMutator: PropTypes.func.isRequired,
    utilizationPlans: PropTypes.arrayOf(MediaFormDataPropType).isRequired,
    utilizationPlanMutator: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    isConstructionPlan: PropTypes.bool
};

ScaffoldingMediaFormPart.defaultProps = {
    readOnly: false,
    isConstructionPlan: false
};

export default ScaffoldingMediaFormPart;
