import PropTypes from 'prop-types';
import React from 'react';
import SiteInvitation from './SiteInvitation';

const SiteInvitationsList = ({ siteId, invitations, onUpdate, siteIsArchived }) => {

    if (!invitations || !invitations.length) {
        return '-';
    }

    return (
        <div className="site-invitations-list">
            {
                invitations.map((invitation, index) => <SiteInvitation
                        key={index}
                        invitation={invitation}
                        onUpdate={onUpdate}
                        siteId={siteId}
                        siteIsArchived={siteIsArchived}
                    />
                )
            }
        </div>
    );
};

export default SiteInvitationsList;

SiteInvitationsList.propTypes = {
    siteId: PropTypes.string.isRequired,
    invitations: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    siteIsArchived: PropTypes.bool.isRequired
};
