import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Field } from 'react-final-form';
import Error from './Error';

const Checkbox = ({ index, disabled, name, value, label, tooltip, showErrors, ...props }) => (
    <Fragment>
        <label key={index} className="input-grp__optionlabel">
            <Field
                name={name}
                component="input"
                type="checkbox"
                value={value}
                disabled={disabled}
                {...props}
            />{' '}
            {label}
            {tooltip &&
            <>
                {' '}{tooltip}
            </>
            }
        </label>
        {showErrors &&
        <Error name={name} />
        }
    </Fragment>
);

Checkbox.propTypes = {
    showErrors: PropTypes.bool
};

Checkbox.defaultProps = {
    showErrors: true
};

export default Checkbox;
