import _ from 'lodash';
import { ConstructionPlanReferenceMode } from '../../ConstructionPlan/properties';
import { getConstructionTypeLabel, getPurposeGroupLabel } from '../labels';
import {
    Bracket,
    Cladding,
    ConstructionType,
    EmergencyStairwayPurposes,
    EquippedSurfaces,
    LoadClass,
    PlannedWork,
    PlannedWorks,
    PlatformStairwayPurposes,
    ProtectivePurposes,
    Purpose,
    PurposeGroup,
    SectionStatus,
    SpecialFeature,
    WallClearance,
    WidthClass,
    WorkPurposes
} from '../properties';
import {
    hasNoPurposeGroup,
    hasOnlyPurposeGroups,
    isDraft,
    isEmergencyStairway,
    isLadderAscent,
    isPlatformStairway,
    isSiteStaircase,
    isUnmanaged
} from './functions';

const ERROR_INVALID_CONFIG_OBJ_UNKNOWN_OPTION = 'unknown_option';
const ERROR_INVALID_CONFIG_OBJ_MULTIPLE_OPTIONS = 'multiple_options';
const ERROR_INVALID_CONFIG_OBJ_NO_OTHER = 'no_other';

const RequiredFields = {
    Managed: ['managed', 'name', 'siteId', 'scaffoldingId', 'equippedSurfaces', 'status', 'plannedWorks', 'purpose', 'supportType', 'constructionType', 'loadClass', 'wallClearance', 'widthClass', 'wallClearanceAbove300', 'widthClassOther', 'loadClassOther'],
    Unmanaged: ['managed', 'contractorId', 'name', 'siteId', 'scaffoldingId', 'wallClearanceAbove300', 'widthClassOther', 'loadClassOther'],
    Draft: ['managed', 'name', 'siteId', 'scaffoldingId']
};

export const VALIDATION_MODE_NORMAL = 'normal';
export const VALIDATION_MODE_PLAN = 'plan';
export const VALIDATION_MODE_SYNC = 'sync';

export function getRequiredFieldsBase(values, isPlan) {
    if (isDraft(values) || isPlan) {
        return RequiredFields.Draft;
    } else {
        if (values.managed === false) {
            return RequiredFields.Unmanaged;
        }
        return RequiredFields.Managed;
    }
}

export function getRequiredFields(values, isPlan) {
    const baseFields = getRequiredFieldsBase(values, isPlan);
    const _isLadderAscent = isLadderAscent(values);
    const _isEmergencyStairway = isEmergencyStairway(values);

    return baseFields.filter(field => {
        if (field === 'plannedWorks') {
            return !(_isLadderAscent || _isEmergencyStairway || isSiteStaircase(values) || isPlatformStairway(values));
        }

        if (field === 'loadClass') {
            return !_isEmergencyStairway;
        }

        if (field === 'widthClass') {
            return !_isEmergencyStairway;
        }

        if (field === 'wallClearance') {
            return !_isEmergencyStairway;
        }

        return true;
    });
}

export function getForbiddenFields(values, isPlan = false) {

    if(isPlan || isUnmanaged(values)) {
        return [];
    }

    const _isLadderAscent = isLadderAscent(values);
    const _isEmergencyStairway = isEmergencyStairway(values);

    const forbiddenFields = [];

    if (_isLadderAscent || _isEmergencyStairway) {
        forbiddenFields.push('plannedWorks');
    }

    if (_isEmergencyStairway) {
        forbiddenFields.push('loadClass');
    }

    if (_isEmergencyStairway) {
        forbiddenFields.push('widthClass');
    }

    if (_isEmergencyStairway) {
        forbiddenFields.push('wallClearance');
    }

    return forbiddenFields;
}

export function isRequired(field, values, isPlan = false) {
    const requiredFields = getRequiredFields(values, isPlan);
    return _.includes(requiredFields, field);
}

export function checkRequired(fieldName, values, requiredMessage, isPlan = false) {
    if (isRequired(fieldName, values, isPlan) && (!values || _.isEmpty(values[fieldName]))) {
        return {
            [fieldName]: requiredMessage
        }
    }
}

export function isForbidden(field, values, isPlan = false) {
    const forbiddenFields = getForbiddenFields(values, isPlan);
    return _.includes(forbiddenFields, field);
}

export function checkForbidden(fieldName, values, forbiddenMessage, isPlan = false) {
    if (isForbidden(fieldName, values, isPlan) && !_.isEmpty(values[fieldName])) {
        return {
            [fieldName]: forbiddenMessage
        }
    }
}

function checkRequiredOrForbidden(fieldName, values, requiredMessage, forbiddenMessage, isPlan = false) {
    const requiredError = checkRequired(fieldName, values, requiredMessage, isPlan);
    if (requiredError) {
        return requiredError;
    }
    return checkForbidden(fieldName, values, forbiddenMessage, isPlan);
}

/**
 * Checks if equipped surfaces are valid
 *
 * @param values
 * @param isPlan
 * @param isSync
 * @return {{equippedSurfaces: string} | undefined}
 */
function checkEquippedSurfaces(values, isPlan = false, isSync = false) {
    const { equippedSurfaces, equippedSurfacesOther } = values;
    if (_.isEmpty(equippedSurfaces) && _.isEmpty(equippedSurfacesOther) && isRequired('equippedSurfaces', values, isPlan, isSync)) {
        return {
            equippedSurfaces: `Bitte wählen Sie ${isSync ? 'mindestens ' : ''}eine eingerüstete Fläche aus`
        };
    }

    const error = checkConfigObjectValid(false, equippedSurfaces, EquippedSurfaces);
    if (error) {
        switch (error) {
            case ERROR_INVALID_CONFIG_OBJ_UNKNOWN_OPTION:
                return {
                    equippedSurfaces: 'Ungültige Konfiguration'
                };
            default:
                return {
                    equippedSurfaces: `Fehler: ${error}`
                }
        }
    }
}

export function isWorkPurposeAllowed(values) {
    if(isUnmanaged(values) || isDraft(values) || hasNoPurposeGroup(values)) {
        return true;
    }

    return hasOnlyPurposeGroups(values?.purpose?.groups, [
        PurposeGroup.WorkScaffolding,
        PurposeGroup.ProtectiveScaffolding,
        PurposeGroup.PlatformStairway
    ]);
}

export function isProtectivePurposeAllowed(values) {
    if(isUnmanaged(values) || isDraft(values) || hasNoPurposeGroup(values)) {
        return true;
    }

    return hasOnlyPurposeGroups(values?.purpose?.groups, [
        PurposeGroup.WorkScaffolding,
        PurposeGroup.ProtectiveScaffolding,
        PurposeGroup.PlatformStairway
    ]);
}

export function isLadderAscentAllowed(values) {
    return isUnmanaged(values)
        || isDraft(values)
        || hasNoPurposeGroup(values)
        || isLadderAscent(values, true);
}

export function isPlatformStairwayAllowed(values) {
    if(isUnmanaged(values) || isDraft(values) || hasNoPurposeGroup(values)) {
        return true;
    }

    return hasOnlyPurposeGroups(values?.purpose?.groups, [
        PurposeGroup.WorkScaffolding,
        PurposeGroup.ProtectiveScaffolding,
        PurposeGroup.PlatformStairway
    ]);
}

export function isSiteStaircaseAllowed(values) {
    return isUnmanaged(values)
        || isDraft(values)
        || hasNoPurposeGroup(values)
        || isSiteStaircase(values, true);
}

export function isEmergencyStairwayAllowed(values) {
    return isUnmanaged(values)
        || isDraft(values)
        || hasNoPurposeGroup(values)
        || isEmergencyStairway(values, true);
}

/**
 * Checks the section purpose
 *
 * @param values
 * @param isPlan
 * @param isSync
 * @return {{} | undefined}
 */
function checkPurposes(values, isPlan = false, isSync = false) {
    const { purpose } = values;
    const hasPurposeGroup = purpose && purpose.groups && purpose.groups.length;

    const errors = {};

    if (!hasPurposeGroup) {
        if (!isRequired('purpose', values, isPlan)) {
            return;
        }

        errors.purpose = errors.purpose || {};
        errors.purpose.groups = `Bitte wählen Sie ${isSync ? 'mindestens ' : ''}einen Verwendungszweck aus`;

        return errors;
    }

    const isWorkGroup = _.includes(purpose.groups, PurposeGroup.WorkScaffolding);
    const isProtectiveGroup = _.includes(purpose.groups, PurposeGroup.ProtectiveScaffolding);
    const isLadderAscentGroup = _.includes(purpose.groups, PurposeGroup.LadderAscent);
    const isPlatformStairwayGroup = _.includes(purpose.groups, PurposeGroup.PlatformStairway);
    const isSiteStaircaseGroup = _.includes(purpose.groups, PurposeGroup.SiteStaircase);
    const isEmergencyStairwayGroup = _.includes(purpose.groups, PurposeGroup.EmergencyStairway);

    /*
     * Check validity of sub properties
     */
    if (isWorkGroup) {
        if (!isWorkPurposeAllowed(values)) {
            errors.purpose = errors.purpose || {};
            errors.purpose.work = `Die gewählte Konfiguration ist nicht mit Arbeitsgerüsten kombinierbar`;
        } else if (!purpose.work) {
            errors.purpose = errors.purpose || {};
            errors.purpose.work = `Bitte wählen Sie ein Arbeitsgerüst aus`;
        }
    }

    if (isProtectiveGroup) {
        if (!isProtectivePurposeAllowed(values)) {
            errors.purpose = errors.purpose || {};
            errors.purpose.protective = `Die gewählte Konfiguration ist nicht mit Schutzsgerüsten kombinierbar`;
        } else {
            if (!purpose.protective || !purpose.protective.length) {
                errors.purpose = errors.purpose || {};
                errors.purpose.protective = `Bitte wählen Sie ${isSync ? 'mindestens ' : ''}ein Schutzgerüst aus`;
            }

            if (_.includes(purpose.protective, Purpose.OtherProtectiveScaffolding) && !purpose.otherProtective) {
                errors.purpose = errors.purpose || {};
                errors.purpose.otherProtective = 'Bitte geben Sie das genaue Schutzgerüst an';
            }
        }
    }

    if (isLadderAscentGroup && !isLadderAscentAllowed(values)) {
        errors.purpose = errors.purpose || {};
        errors.purpose.ladderAscent = `Die gewählte Konfiguration ist nicht mit Aufstiegen als Einzelfelder kombinierbar`;
    }

    if (isPlatformStairwayGroup) {
        if (!isPlatformStairwayAllowed(values)) {
            errors.purpose = errors.purpose || {};
            errors.purpose.platformStairway = `Die gewählte Konfiguration ist nicht mit Podesttreppen kombinierbar`;
        } else if (!purpose.platformStairway) {
            errors.purpose = errors.purpose || {};
            errors.purpose.platformStairway = 'Bitte wählen Sie eine Podesttreppe aus';
        }
    }

    if (isSiteStaircaseGroup && !isSiteStaircaseAllowed(values)) {
        errors.purpose = errors.purpose || {};
        errors.purpose.siteStaircase = `Die gewählte Konfiguration ist nicht mit Bautreppen kombinierbar`;
    }

    if (isEmergencyStairwayGroup) {
        if (!isEmergencyStairwayAllowed(values)) {
            errors.purpose = errors.purpose || {};
            errors.purpose.emergencyStairway = `Die gewählte Konfiguration ist nicht mit Fluchttreppen kombinierbar`;
        } else if (!purpose.emergencyStairway) {
            errors.purpose = errors.purpose || {};
            errors.purpose.emergencyStairway = 'Bitte wählen Sie eine Fluchttreppe aus';
        }
    }

    return errors;
}

/**
 * Checks if planned works is valid
 */
function checkPlannedWorks(values, isPlan = false, isSync = false) {
    const { plannedWorks, plannedWorksOther } = values;
    const _isEmergencyStairway = isEmergencyStairway(values);
    const isDraftMode = isDraft(values);

    // In draft mode or plan and everything is empty we dont' need any validation
    if ((isDraftMode || isPlan) && _.isEmpty(plannedWorks)) {
        return;
    }

    const hasPlannedWork = !_.isEmpty(plannedWorks);
    const hasOtherPlannedWork = (plannedWorks || []).indexOf(PlannedWork.Other) >= 0 && !_.isEmpty(plannedWorksOther);
    const isFilled = hasPlannedWork || hasOtherPlannedWork;

    // Ladder ascents must not have planned work
    if (isFilled && isForbidden('plannedWorks', values, isPlan)) {
        return {
            plannedWorks: `Auf ${_isEmergencyStairway ? getPurposeGroupLabel(PurposeGroup.EmergencyStairway) : getPurposeGroupLabel(PurposeGroup.LadderAscent)} können keinen Arbeiten stattfinden`
        };
    }

    if (!isFilled && isRequired('plannedWorks', values, isPlan, isSync)) {
        return {
            plannedWorks: `Bitte wählen Sie ${isSync ? 'mindestens ' : ''}eine geplante Arbeit`
        }
    }

    // Check object validity
    const error = checkConfigObjectValid(false, plannedWorks, PlannedWorks, plannedWorksOther, PlannedWork.Other);
    if (error) {
        switch (error) {
            case ERROR_INVALID_CONFIG_OBJ_UNKNOWN_OPTION:
                return {
                    plannedWorks: 'Ungültige Konfiguration'
                };
            case ERROR_INVALID_CONFIG_OBJ_NO_OTHER:
                return {
                    plannedWorksOther: 'Bitte füllen sie das Feld aus'
                };
            default:
                return {
                    plannedWorks: `Fehler: ${error}`
                }
        }
    }
}

/**
 * Checks validity of form values related to cladding
 */
function checkCladdings(values, isPlan = false) {

    const { claddings, claddingsFixedTrim } = values;

    const isDraftMode = isDraft(values);

    // Zusatzfeld 'fixed trim'
    if (_.includes(claddings, Cladding.FixedTrim) && _.isEmpty(claddingsFixedTrim)) {
        return {
            claddingsFixedTrim: 'Bitte geben Sie die genaue Festverkleidung an'
        };
    }

    // Check that if claddings 'net' or 'tarp' is selected one of the special feature 'detached' or 'anchoring' is set
    if (
        _.intersection(values.claddings, [Cladding.Net, Cladding.Tarp]).length > 0
        && _.intersection(values.specialFeatures, [SpecialFeature.Detached, SpecialFeature.Anchoring]).length === 0
        && !(isPlan || isDraftMode)
    ) {
        return {
            specialFeatures: 'Bei Verwendung von Netzen und/oder Planen muss die Option Verankerung (unter Besonderheiten) gewählt oder der Gerüstabschnitt als freistehende Gerüstkonstruktion (unter Besonderheiten) markiert werden.'
        }
    }
}

/**
 * Config objects consist of two fields in a form:
 * 1) Field for the base config options (radio or checkbox choice)
 * 2) Optional input field for other
 *
 * @param {boolean} singular
 * @param {string[]} formValue
 * @param {string[]} formValueOptions
 * @param {string} formOtherValue
 * @param {string} formKeyOther
 * @return {string | undefined}
 */
function checkConfigObjectValid(singular, formValue, formValueOptions, formOtherValue = undefined, formKeyOther = undefined) {
    if (_.isEmpty(formValue)) {
        return;
    }

    // Check if any invalid options are selected
    const invalidOptions = _.difference(formValue, formValueOptions);
    if (invalidOptions.length > 0) {
        return ERROR_INVALID_CONFIG_OBJ_UNKNOWN_OPTION;
    }

    // Check if singular object has more than one option selected
    if (singular && formValueOptions.length > 1) {
        return ERROR_INVALID_CONFIG_OBJ_MULTIPLE_OPTIONS;
    }

    // Check if other is given when selected
    if (formKeyOther) {
        if (formValue.indexOf(formKeyOther) >= 0) {
            if (_.isEmpty(formOtherValue)) {
                return ERROR_INVALID_CONFIG_OBJ_NO_OTHER;
            }
        }
    }

    // no error detected
}

function addErrors(errors, additionalErrors) {
    if (_.isEmpty(additionalErrors)) {
        return;
    }

    Object.keys(additionalErrors).forEach(propertyName => {
        errors[propertyName] = additionalErrors[propertyName];
    });
}

export function validate(values, mode = VALIDATION_MODE_NORMAL) {

    const isPlan = mode === VALIDATION_MODE_PLAN;
    const isSync = mode === VALIDATION_MODE_SYNC;

    // If everything is referenced we don't need to validate anything
    if (isPlan && values.selection === ConstructionPlanReferenceMode.All) {
        return {};
    }

    const isDraft = !values.status || values.status === SectionStatus.Draft;
    const isPlanOrDraft = isPlan || isDraft;

    const errors = {};

    // Bezeichnung
    if (!values.name) {
        errors.name = 'Bitte geben Sie eine Bezeichnung ein';
    }

    // Baustelle
    if (!values.siteId && !(isPlan || isSync)) {
        errors.siteId = 'Bitte wählen Sie eine Baustelle aus';
    }

    // Gerüst
    if (!values.scaffoldingId && !(isPlan || isSync)) {
        errors.scaffoldingId = 'Bitte wählen Sie ein Gerüst aus';
    }

    // Status
    if (!values.status && isRequired('status', values, isPlan, isSync)) {
        errors.status = 'Bitte geben Sie einen Status an';
    }
    if (values.status === SectionStatus.Unknown && values.managed) {
        errors.status = 'Bitte wählen Sie einen Status aus';
    }

    /*
     * Eingerüstete Flächen
     */
    addErrors(errors, checkEquippedSurfaces(values, isPlan, isSync));

    /*
     * Verwendungszweck
     */
    addErrors(errors, checkPurposes(values, isPlan, isSync));

    /*
     * Geplante Arbeiten
     */
    addErrors(errors, checkPlannedWorks(values, isPlan, isSync));

    // Tragsystem
    if (!values.supportType && isRequired('supportType', values, isPlan, isSync)) {
        errors.supportType = 'Bitte wählen Sie ein Tragsystem aus';
    }

    // Ausführungsart/construction type
    const { constructionType } = values;
    if (!constructionType) {
        if(isRequired('constructionType', values, isPlan, isSync)) {
            errors.constructionType = 'Bitte wählen Sie eine Ausführungsart aus';
        }
    } else {
        if(isEmergencyStairway(values) && constructionType !== ConstructionType.ModuleSystemScaffolding) {
            errors.constructionType = `Fluchttreppen können nur mit der Ausführungsart ${getConstructionTypeLabel(ConstructionType.ModuleSystemScaffolding)} erstellt werden`;
        }
    }

    // Statiknachweis benötigt -> Statiknachweis hochgeladen
    if (values.staticsCertificateNecessary && _.isEmpty(values.proofOfStability) && !isPlanOrDraft) {
        errors.staticsCertificateNecessary = 'Bitte hinterlegen Sie einen Statik- oder Herstellernachweis unter "Dokumente"';
        errors.proofOfStability = 'Bitte hinterlegen Sie einen Statik- oder Herstellernachweis';
    }

    // Lastklasse
    addErrors(errors, checkRequiredOrForbidden(
        'loadClass',
        values,
        'Bitte wählen Sie eine Lastklasse aus',
        'Für Fluchttreppen kann keine Lastklasse angegeben werden',
        isPlan
    ));
    if (values.loadClass === LoadClass.Other && _.isEmpty(values.loadClassOther) && !isPlanOrDraft) {
        errors.loadClassOther = 'Bitte geben Sie die genaue Lastklasse an';
    }

    // Wandabstand
    addErrors(errors, checkRequiredOrForbidden(
        'wallClearance',
        values,
        'Bitte wählen Sie den Wandabstand',
        'Für Fluchttreppen kann kein Wandabstand angegeben werden',
        isPlan
    ));

    if (values.wallClearance === WallClearance.Above300MM && _.isEmpty(values.wallClearanceAbove300) && !isPlanOrDraft) {
        errors.wallClearanceAbove300 = 'Bitte geben Sie den genauen Wandabstand an';
    }

    // Breitenklasse
    addErrors(errors, checkRequiredOrForbidden(
        'widthClass',
        values,
        'Bitte wählen Sie eine Breitenklasse',
        'Für Fluchttreppen kann keine Breitenklasse angegeben werden',
        isPlan
    ));
    if (values.widthClass === WidthClass.Other && _.isEmpty(values.widthClassOther) && !isPlanOrDraft) {
        errors.widthClassOther = 'Bitte geben Sie die genaue Breitenklasse an';
    }

    // Gerüstverkleidungen / claddings
    addErrors(errors, checkCladdings(values, isPlan, isSync));

    /*
     * Konsole
     */
    if (_.includes(values.specialFeatures, SpecialFeature.Bracket) && !isPlanOrDraft) {
        if (_.isEmpty(values.specialFeatureBracket)) {
            errors.specialFeatureBracket = 'Bitte wählen Sie eine Konsole aus';
        }

        // Konsole, verpflichtende Texteingabe
        if (_.includes(values.specialFeatureBracket, Bracket.Other) && _.isEmpty(values.specialFeatureBracketOther)) {
            errors.specialFeatureBracketOther = 'Bitte geben Sie die Konsolenkonfiguration an';
        }
    }

    return errors;
}

function internalGetValidPurposeForGroup(group, purposes) {
    if (group === PurposeGroup.WorkScaffolding) {
        return _.intersection(purposes, WorkPurposes);
    } else if (group === PurposeGroup.ProtectiveScaffolding) {
        return _.intersection(purposes, ProtectivePurposes);
    } else if (group === PurposeGroup.LadderAscent) {
        return [Purpose.LadderAscent];
    } else if (group === PurposeGroup.PlatformStairway) {
        return _.intersection(purposes, PlatformStairwayPurposes);
    } else if (group === PurposeGroup.SiteStaircase) {
        return [Purpose.SiteStaircase];
    } else if (group === PurposeGroup.EmergencyStairway) {
        return _.intersection(purposes, EmergencyStairwayPurposes);
    }

    return [];
}

export function getValidPurposesForGroup(groups, purposes) {
    if (!groups || groups.length === 0) {
        return [];
    }

    const validPurposes = [];
    groups.forEach(group => {
        validPurposes.push(...internalGetValidPurposeForGroup(group, purposes))
    });

    return validPurposes;
}
