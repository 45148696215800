import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ArchivedBadge from '../../Common/Badge/ArchivedBadge';
import DataRow from '../../Common/DataRow';
import Icon from '../../Common/Icon';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import { ScaffoldingReference } from '../../Common/ObjectReference';
import ScaffoldingServiceTime from '../../Scaffoldings/Detail/ScaffoldingServiceTime';
import { ConstructionPlanFormScaffoldingPropType } from '../Form/propTypes';
import { getData } from '../utils';
import ConstructionPlanModelName from './ConstructionPlanModelName';

const ConstructionPlanScaffolding = ({ scaffolding, isArchived, referenceData }) => {

    function _getData(field, fallback = <NotAvailablePlaceholder />) {
        return getData(scaffolding, referenceData, field, fallback);
    }

    function renderData() {

        const name = _getData('name');

        const { mode } = scaffolding;
        const description = _getData('description', null);
        const scheduledDismantling = _getData('scheduledDismantling', null);
        const scheduledErection = _getData('scheduledErection', null);

        const sections = scaffolding.sections || [];

        if (isArchived) {

            const { id: scaffoldingId } = scaffolding;

            return (
                <DataRow label="Gerüste">
                    <ScaffoldingReference
                        id={scaffoldingId}
                        label={name}
                        badge={<ArchivedBadge size="small" />}
                    >
                        Alle Gerüstabschnitte
                    </ScaffoldingReference>
                </DataRow>
            );
        }

        return (
            <Fragment>
                <DataRow label="Gerüst">
                    <ConstructionPlanModelName
                        id={scaffolding.id}
                        name={name}
                        mode={mode}
                        ReferenceComponent={ScaffoldingReference}
                    />
                    {referenceData && referenceData.archived && <ArchivedBadge size="small" />}
                </DataRow>
                <DataRow
                    label="Geplante Standzeit"
                    isSubRow
                >
                    <ScaffoldingServiceTime
                        scheduledDismantling={scheduledDismantling}
                        scheduledErection={scheduledErection}
                    />
                </DataRow>
                <DataRow
                    label="Notizen"
                    isSubRow
                >
                    {description}
                </DataRow>

                {sections.length > 0 &&
                <div className="construction-plan__scaffolding-section-separator">
                    <span
                        className="job-constructionplan-details__scaffolding-sections-separator-arrowicon"
                    >
                        <Icon name="arrowDown" />
                    </span>
                </div>
                }
            </Fragment>
        );
    }

    return (
        <div className="construction-plan__model construction-plan__model--scaffolding">
            {renderData()}
        </div>
    );
};

ConstructionPlanScaffolding.propTypes = {
    scaffolding: ConstructionPlanFormScaffoldingPropType.isRequired,
    referenceData: PropTypes.object,
    isArchived: PropTypes.bool
};

ConstructionPlanScaffolding.defaultProps = {
    referenceData: null,
    isArchived: false
};

export default ConstructionPlanScaffolding;
