import PropTypes from 'prop-types';
import React from 'react';
import TableHiddenActions from '../Layout/TableHiddenActions';

const PrimaryColumn = ({ link, sub, children, extra }) => {
    return (
        <td className="name-column">
            <div className="name-column__link">
                {link}
            </div>

            {sub ? <div className="name-column__sub">{sub}</div> : null}

            {children &&
            <div className="name-column__actions">
                {extra && <div className="name-column__extra">{extra} </div>}
                <TableHiddenActions>
                    {children}
                </TableHiddenActions>
            </div>
            }
        </td>
    );
};

export default PrimaryColumn;

PrimaryColumn.propTypes = {
    link: PropTypes.node.isRequired,
    sub: PropTypes.node,
    extra: PropTypes.node,
    children: PropTypes.node
};

PrimaryColumn.defaultProps = {
    sub: null,
    children: null,
    extra: null
};

