import React from 'react';
import Address from '../../../Common/Address';
import { getScaffoldingPropertyLabel } from '../../../Common/Diff/labels';
import MultiLineText from '../../../Common/MultiLineText';
import { ConstructionPlanModelMode } from '../../properties';
import { getMediaFromRepository, getMediaListDiff } from '../media';
import ConstructionPlanSyncMediaSummaryItem from './ConstructionPlanSyncMediaSummaryItem';
import ConstructionPlanSyncSummaryField from './ConstructionPlanSyncSummaryField';
import ConstructionPlanSyncSummaryRow from './ConstructionPlanSyncSummaryRow';

export const getSyncValue = (property, syncModel, fallback = undefined) => {
    return syncModel && syncModel.data && syncModel.data[property] || fallback;
};

export const getLiveValue = (property, liveModel, fallback = undefined) => {
    return liveModel && liveModel[property] || fallback;
};

export const renderValue = (value) => {
    if (value === undefined || value === null || value === '') {
        return '-';
    }

    if (typeof value === 'boolean') {
        return value ? 'Ja' : 'Nein';
    }

    if (typeof value === 'string') {
        return value;
    }

    return value.toString();
};

export const renderMultilineText = (value) => {
    return <MultiLineText text={value} emptyPlaceholder="-" />
};

export const renderAddress = (address) => {
    return address ? <Address address={address} /> : '-';
};

export const renderMediaList = (property, syncModel, referenceModel, resolvedMedia) => {

    const isNew = syncModel.mode === ConstructionPlanModelMode.New;
    const mediaListDiff = getMediaListDiff(syncModel, referenceModel, property, resolvedMedia);

    const renderMediaItem = (mediaItem) => {
        if (!mediaItem) {
            return (
                <div className="sync-summary-media-item is-empty">
                    -
                </div>
            );
        }

        return <ConstructionPlanSyncMediaSummaryItem key={mediaItem.id} media={mediaItem} />
    };

    const renderReferenceBucket = (bucket) => {
        if (bucket.length === 0) {
            return null;
        }

        return bucket.map(mediaItem => {
            const left = renderMediaItem(mediaItem);
            const right = renderMediaItem(mediaItem);

            return (
                <ConstructionPlanSyncSummaryRow
                    key={mediaItem.id}
                    left={left}
                    right={right}
                    isIdentical={true}
                />
            )
        });
    };

    const renderChangedBucket = (bucket) => {
        if (bucket.length === 0) {
            return null;
        }

        return bucket.map(bucketItem => {
            const left = renderMediaItem(bucketItem.old);
            const right = renderMediaItem(bucketItem.new);

            return (
                <ConstructionPlanSyncSummaryRow
                    key={bucketItem.id}
                    left={left}
                    right={right}
                    isIdentical={false}
                />
            )
        });
    };

    const renderNewBucket = (bucket) => {
        if (bucket.length === 0) {
            return null;
        }

        return bucket.map(mediaItem => {
            const left = renderMediaItem(null);
            const right = renderMediaItem(mediaItem);

            return (
                <ConstructionPlanSyncSummaryRow
                    key={mediaItem.id}
                    left={left}
                    right={right}
                    isIdentical={false}
                />
            )
        });
    };

    const renderRemovedBucket = (bucket) => {
        if (bucket.length === 0) {
            return null;
        }

        return bucket.map(mediaItem => {
            const left = renderMediaItem(mediaItem);
            const right = renderMediaItem(null);

            return (
                <ConstructionPlanSyncSummaryRow
                    key={mediaItem.id}
                    left={left}
                    right={right}
                    isIdentical={false}
                />
            )
        });
    };

    const hasAny = mediaListDiff?.referenced?.length
        || mediaListDiff?.changed?.length
        || mediaListDiff?.new?.length
        || mediaListDiff?.removed?.length

    return (
        <ConstructionPlanSyncSummaryField
            key={property}
            label={getScaffoldingPropertyLabel(property)}
            isIdentical={mediaListDiff.isIdentical}
            isNew={isNew}
        >
            {!hasAny &&
            <ConstructionPlanSyncSummaryRow
                key="empty"
                left="-"
                right="-"
                isIdentical={true}
            />
            }
            {renderReferenceBucket(mediaListDiff.referenced)}
            {renderChangedBucket(mediaListDiff.changed)}
            {renderNewBucket(mediaListDiff.new)}
            {renderRemovedBucket(mediaListDiff.removed)}
        </ConstructionPlanSyncSummaryField>
    )
};

export const renderSingleMedia = (media, resolvedMedia) => {

    if (!media) {
        return '-';
    }

    const preparedMedia = getMediaFromRepository([media], resolvedMedia);

    const mediaKey = Object.keys(preparedMedia)[0];

    return <ConstructionPlanSyncMediaSummaryItem key={mediaKey} media={preparedMedia[mediaKey]} />;
};


