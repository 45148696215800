import PropTypes from 'prop-types';
import React from 'react';
import { ConstructionPlanModelMode } from '../../../../properties';
import asSyncChoiceField from '../../../asSyncChoiceField';
import { validateSingleChoiceComplete } from '../../../validation';
import SyncChoiceRow from '../SyncChoiceRow';

const validateBool = validateSingleChoiceComplete();

function renderBoolChoiceLabel(valueObject, isLive, mode) {
    if(isLive && !valueObject && mode === ConstructionPlanModelMode.New) {
        return '-';
    }

    return valueObject ? 'Ja' : 'Nein';
}

const BoolSyncChoice = (props) => {

    const {
        planValue,
        showHint,
        liveValue,
        value: currentFormValue,
        onChange,
        showIdentical,
        mode
    } = props;

    const isSelected = (value) => (currentFormValue === value);

    const leftValue = planValue;
    const rightValue = liveValue;

    const isIdentical = leftValue === rightValue;

    return (
        <SyncChoiceRow
            leftValue={leftValue}
            rightValue={rightValue}
            renderValue={(value, isLive) => renderBoolChoiceLabel(value, isLive, mode)}
            onSelect={onChange}
            isSelected={isSelected}
            hint={showHint}
            isIdentical={isIdentical}
            showIdentical={showIdentical}
        />
    );
};

BoolSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.bool,
    liveValue: PropTypes.bool,
    value: PropTypes.any,
    showHint: PropTypes.func,
    showIdentical: PropTypes.bool,
    mode: PropTypes.oneOf([
        ConstructionPlanModelMode.New,
        ConstructionPlanModelMode.Edit,
        ConstructionPlanModelMode.Reference,
    ])
};

BoolSyncChoice.defaultProps = {
    showHint: () => false,
    showIdentical: false
};

export default asSyncChoiceField(BoolSyncChoice, {
    allowNull: true,
    validate: validateBool
});
