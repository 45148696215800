import React from 'react';

const Changelog_1_11_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>Baustellenplaner:</h4>
                <p>
                    Ab sofort steht Ihnen der Baustellenplaner zur Nutzung zur Verfügung.  Mit Hilfe des Planers können Baustellen, Gerüste und Gerüstabschnitte in Scaffeye geplant und vorkonfiguriert werden. Die Planungen sind Bestandteil der in der Auftragsverwaltung hinterlegten Anfragen und Aufträge. </p>

                <p>
                    Mit Hilfe des Sync-Tools (“Planung anwenden” oder “Baustelle generieren”) lassen sich Konfigurationen nach der Planung per Klick in die Baustellenverwaltung und damit auf die Live-Baustelle übertragen. Nach der Anwendung von Planungen auf der Live-Baustelle stehen die Konfigurationen allen Beteiligten zur Einsicht zur Verfügung.
                </p>
            </section>

            <section>
                <h4>Verbesserung der Dokumenten-Uploads:</h4>
                <p>
                    Die Funktionalität zum Hochladen von Dokumenten wurde verbessert. Ab sofort steht Ihnen ein vereinheitlichter Upload-Button auf den Detailseiten Ihrer Baustellen, Gerüste und Gerüstabschnitte zur Verfügung.
                </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen:</h4>
                <ul>
                    <li>Ab sofort werden Sie durch dieses Hinweisfenster über alle zukünftigen Updates informiert</li>
                    <li>Zum Nachlesen vergangener Updates steht Ihnen ab sofort die Changelog-Historie zur Verfügung, die Sie per Klick auf die Versionsnummer (unten links in der Scaffeye® Sidebar) öffnen können</li>
                    <li>Einführung einer neuen Sektionsnavigation zur Verbesserung der Übersichtlichkeit auf der Baustellendetailseite</li>
                    <li>Der Download von Dokumenten mit unbekannten Dateiformaten kann ab sofort auch über die Vorschauansicht gestartet werden </li>
                    <li>Weitere kleine Verbesserungen und Korrekturen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_11_0;
