import PropTypes from 'prop-types';
import React from 'react';
import ScaffoldingDetail from '../../../Components/Scaffoldings/Detail/ScaffoldingDetail';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const ScaffoldingDetailPage = ({ match }) => {
    return (
        <PrivatePage requiredUserPermission={Permission.ReadScaffoldings}>
            <Container>
                <ScaffoldingDetail scaffoldingId={match.params.id} />
            </Container>
        </PrivatePage>
    );
};

export default ScaffoldingDetailPage;

ScaffoldingDetailPage.propTypes = {
    match: PropTypes.object
};

ScaffoldingDetailPage.defaultProps = {
    match: {}
};
