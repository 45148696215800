import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ModelType } from '../../../../../properties';
import LinkButton from '../../../../Common/LinkButton';
import Condition from '../../../../Forms/Condition';
import ConstructionPlanAllOrSingleSelection
    from '../../../../Forms/ConstructionPlan/ConstructionPlanAllOrSingleSelection';
import ConstructionPlanModeSelection
    from '../../../../Forms/ConstructionPlan/ConstructionPlanModeSelection';
import Input from '../../../../Forms/Input';
import InputGroup from '../../../../Forms/InputGroup';
import { FormMode } from '../../../../Forms/properties';
import SectionFormContent from '../../../../Sections/Form/SectionFormContent';
import { ConstructionPlanModelMode, ConstructionPlanReferenceMode } from '../../../properties';
import ConstructionPlanSectionSelect from './ConstructionPlanSectionSelect';

function isFormReadOnly(values) {
    return values.mode === ConstructionPlanModelMode.Reference;
}

function renderNewSectionFormContent(props) {
    const { values, inputDisabled, mutators, onUploading } = props;

    return (
        <SectionFormContent
            mode={FormMode.Edit}
            values={values}
            mutators={mutators}
            attachmentsMutator={mutators.setAttachments}
            utilizationPlanMutator={mutators.setUtilizationPlans}
            anchorProtocolMutator={mutators.setAnchorProtocol}
            proofOfStabilityMutator={mutators.setProofOfStability}
            onUploading={onUploading}
            readOnly={isFormReadOnly(values) || inputDisabled}
            isConstructionPlan
            constructionPlanMode={ConstructionPlanModelMode.New}
        />
    );
}

function renderReferenceSectionFormContent(props) {
    const { values, referenceData, referencedSectionIds, disableModeAll, onResetSection, mutators, onUploading, scaffoldingId } = props;

    return (
        <Fragment>
            <ConstructionPlanAllOrSingleSelection
                type={ModelType.Section}
                disableModeAll={disableModeAll}
            />

            <Condition when="selection" is={ConstructionPlanReferenceMode.Single}>
                {
                    values.id ?
                        <InputGroup label="Gerüstabschnitt*">
                            <div className="construction-plan-form__reference">
                                {referenceData && referenceData.name}
                            </div>
                            <LinkButton
                                label="Gerüstabschnitt ändern (Anpassungen werden verworfen)"
                                onClick={onResetSection}
                                bold
                                underline
                            />
                            <Input
                                readOnly
                                type="hidden"
                                name="id"
                            />
                        </InputGroup>
                        :
                        <InputGroup label="Gerüstabschnitt*">
                            <ConstructionPlanSectionSelect
                                name="id"
                                scaffoldingId={scaffoldingId}
                                excludeSectionIds={referencedSectionIds}
                            />
                        </InputGroup>
                }


                <Condition when="id" isSet>
                    <ConstructionPlanModeSelection
                        type={ModelType.Section}
                    />

                    <SectionFormContent
                        values={{ ...values }}
                        mode={FormMode.Edit}
                        mutators={mutators}
                        onUploading={onUploading}
                        readOnly={isFormReadOnly(values)}
                        isConstructionPlan
                        constructionPlanMode={values.mode}
                        section={referenceData}
                    />
                </Condition>
            </Condition>
        </Fragment>
    );
}

const ConstructionPlanSectionFormContent = (props) => {
    const { values } = props;
    if (values.mode !== ConstructionPlanModelMode.New) {
        return renderReferenceSectionFormContent(props);
    }
    return renderNewSectionFormContent(props);
};

ConstructionPlanSectionFormContent.propTypes = {
    values: PropTypes.object.isRequired,
    scaffoldingId: PropTypes.string.isRequired,
    referencedSectionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    mutators: PropTypes.object.isRequired,
    onUploading: PropTypes.func.isRequired,
    onResetSection: PropTypes.func.isRequired,
    referenceData: PropTypes.object,
    inputDisabled: PropTypes.bool,
    disableModeAll: PropTypes.bool
};

ConstructionPlanSectionFormContent.defaultProps = {
    referenceData: null,
    inputDisabled: false,
    disableModeAll: false
};

export default ConstructionPlanSectionFormContent;
