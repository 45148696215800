import PropTypes from 'prop-types';
import React from 'react';
import Modal from '../Modal/Modal';

const DiffModal = ({ title, subtitle, onCloseClick, onBackdropClick, children, loading, loadingLabel }) => (
    <Modal
        title={title}
        subtitle={subtitle}
        classes="modal--diff"
        onBackdropClick={onBackdropClick}
        loading={loading}
        loadingLabel={loadingLabel}
        footer={
            <div className="btn-group">
                <a
                    className="btn btn--backward-action"
                    onClick={onCloseClick}
                >
                    Schließen
                </a>
            </div>
        }
    >
        {children}
    </Modal>
);

DiffModal.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onCloseClick: PropTypes.func,
    onBackdropClick: PropTypes.func,
    loading: PropTypes.bool,
    loadingLabel: PropTypes.string
};

DiffModal.defaultProps = {
    subtitle: null,
    loading: false
};

export default DiffModal;
