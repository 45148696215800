import _ from 'lodash';
import moment from 'moment';
import { ModelType } from '../../../properties';
import { MediaVisibility } from '../../Media/properties';
import { ConstructionPlanModelMode } from '../properties';
import { walkConstructionPlanScaffolding, walkConstructionPlanSite } from '../utils';
import { isIdenticalScaffoldingFormValue } from './Scaffolding/equals';
import { isIdenticalSectionFormValue } from './Section/equals';
import { isIdenticalSiteFormValue } from './Site/equals';

const SiteMediaFields = ['attachments'];
const ScaffoldingMediaFields = ['attachments', 'utilizationPlans', 'anchorProtocol'];
const SectionMediaFields = ['attachments', 'utilizationPlans', 'anchorProtocol', 'proofOfStability'];

/*
 * Section related functions
 */

/**
 * Adds all media mutations for the given section
 *
 * @param mutations
 * @param syncModel
 * @param liveData
 * @param resolvedMedia
 */
const addSectionMediaMutations = (mutations, syncModel, liveData, resolvedMedia) => {
    addMediaListMutations(mutations, ModelType.Section, syncModel, liveData, 'attachments', resolvedMedia);
    addMediaListMutations(mutations, ModelType.Section, syncModel, liveData, 'utilizationPlans', resolvedMedia);
    addMediaMutation(mutations, ModelType.Section, syncModel, liveData, 'anchorProtocol', resolvedMedia);
    addMediaMutation(mutations, ModelType.Section, syncModel, liveData, 'proofOfStability', resolvedMedia);
};

const addSectionMutations = (mutations, scaffolding, syncModel, liveData, resolvedMedia) => {

    if (syncModel.mode === ConstructionPlanModelMode.New) {
        addSectionCreateMutations(mutations, scaffolding.id, syncModel, resolvedMedia);
        return true;
    }

    const sectionInput = getSectionUpdateInput(syncModel, liveData);

    if (Object.keys(sectionInput).length > 0) {
        mutations.push({
            mutation: 'updateSection',
            args: {
                id: syncModel.id,
                input: sectionInput
            }
        });
    }

    // Add media mutations
    addSectionMediaMutations(mutations, syncModel, liveData, resolvedMedia);
};

const getSectionCreateInput = (syncModel) => {

    if (syncModel.ignored) {
        return null;
    }

    const input = _.omitBy(syncModel.data, (fieldValue, fieldKey) => {
        return SectionMediaFields.indexOf(fieldKey) >= 0;
    });

    input.id = syncModel.id;
    input.managed = true;

    return input;
};

const addSectionCreateMutations = (mutations, scaffoldingId, syncModel, resolvedMedia) => {
    mutations.push({
        mutation: 'createSection',
        args: {
            scaffoldingId,
            input: getSectionCreateInput(syncModel)
        }
    });

    addSectionMediaMutations(mutations, syncModel, resolvedMedia);
};

const getSectionUpdateInput = (syncModel, liveData) => {
    const updateInput = _.omitBy(syncModel.data, (fieldValue, fieldKey) => {
        if (SectionMediaFields.indexOf(fieldKey) >= 0) {
            return true;
        }

        if (!liveData) {
            return false;
        }

        if(fieldKey === 'archived'){
            return true;
        }

        return isIdenticalSectionFormValue(fieldKey, syncModel.data, liveData);
    });

    setUpdateInputMediaIds(updateInput, syncModel.data, liveData, 'utilizationPlans');
    setUpdateInputMediaIds(updateInput, syncModel.data, liveData, 'attachments');
    setUpdateInputMediaId(updateInput, syncModel.data, liveData, 'anchorProtocol');
    setUpdateInputMediaId(updateInput, syncModel.data, liveData, 'proofOfStability');

    // GraphQL does not accept a managed flag as update input since it's value is immutable
    if(typeof updateInput.managed !== 'undefined') {
        delete updateInput.managed;
    }

    return updateInput;
};

/*
 * Scaffolding related functions
 */

/**
 * Adds all media mutations for the given scaffolding
 *
 * @param mutations
 * @param syncModel
 * @param liveData
 * @param resolvedMedia
 */
const addScaffoldingMediaMutations = (mutations, syncModel, liveData, resolvedMedia) => {
    addMediaListMutations(mutations, ModelType.Scaffolding, syncModel, liveData, 'attachments', resolvedMedia);
    addMediaListMutations(mutations, ModelType.Scaffolding, syncModel, liveData, 'utilizationPlans', resolvedMedia);
    addMediaMutation(mutations, ModelType.Scaffolding, syncModel, liveData, 'anchorProtocol', resolvedMedia);
};

const getScaffoldingCreateInput = (syncModel) => {

    if (syncModel.ignored) {
        return null;
    }

    const input = _.omitBy(syncModel.data, (fieldValue, fieldKey) => {
        return ScaffoldingMediaFields.indexOf(fieldKey) >= 0;
    });

    input.id = syncModel.id;
    input.managed = true;

    const newSections = syncModel.sections;
    if (newSections && newSections.length) {
        input.sections = syncModel.sections
            .map(section => getSectionCreateInput(section))
            .filter(s => s);
    }

    return input;
};

const getScaffoldingUpdateInput = (syncModel, liveData) => {
    const updateInput = _.omitBy(syncModel.data, (fieldValue, fieldKey) => {
        if (ScaffoldingMediaFields.indexOf(fieldKey) !== -1) {
            return true;
        }

        if (!liveData) {
            return false;
        }

        if(fieldKey === 'archived'){
            return true;
        }

        return isIdenticalScaffoldingFormValue(fieldKey, syncModel.data, liveData);
    });

    setUpdateInputMediaIds(updateInput, syncModel.data, liveData, 'utilizationPlans');
    setUpdateInputMediaIds(updateInput, syncModel.data, liveData, 'attachments');
    setUpdateInputMediaId(updateInput, syncModel.data, liveData, 'anchorProtocol');

    return updateInput;
};

const addScaffoldingCreateMutations = (mutations, siteId, syncModel, resolvedMedia) => {

    // Create mutation
    mutations.push({
        mutation: 'createScaffolding',
        args: {
            siteId,
            input: getScaffoldingCreateInput(syncModel)
        }
    });

    // Add recursive media mutations (scaffolding > sections)
    walkConstructionPlanScaffolding(syncModel, null, (modelType, syncModel, liveData) => {
        switch (modelType) {
            case ModelType.Scaffolding:
                addScaffoldingMediaMutations(mutations, syncModel, liveData, resolvedMedia);
                break;
            case ModelType.Section:
                addSectionMediaMutations(mutations, syncModel, liveData, resolvedMedia);
                break;
            default:
            // nothing to do
        }
    });
};

const addScaffoldingMutations = (mutations, site, syncModel, liveData, resolvedMedia) => {

    // Create
    if (syncModel.mode === ConstructionPlanModelMode.New) {
        addScaffoldingCreateMutations(mutations, site.id, syncModel, resolvedMedia);
        return true;
    }

    // Update
    const scaffoldingInput = getScaffoldingUpdateInput(syncModel, liveData);

    if (Object.keys(scaffoldingInput).length > 0) {
        mutations.push({
            mutation: 'updateScaffolding',
            args: {
                id: syncModel.id,
                input: scaffoldingInput
            }
        });
    }

    addScaffoldingMediaMutations(mutations, syncModel, liveData, resolvedMedia);
};

/*
 * Site related functions
 */

/**
 * Adds all media mutations for the given site
 *
 * @param mutations
 * @param syncModel
 * @param liveData
 * @param resolvedMedia
 */
const addSiteMediaMutations = (mutations, syncModel, liveData, resolvedMedia) => {
    addMediaListMutations(mutations, ModelType.Site, syncModel, liveData, 'attachments', resolvedMedia);
};

const addSiteCreateMutations = (mutations, syncModel, resolvedMedia) => {

    const input = _.omitBy(syncModel.data, (fieldValue, fieldKey) => {
        return SiteMediaFields.indexOf(fieldKey) >= 0;
    });

    input.id = syncModel.id;

    const newScaffoldings = syncModel.scaffoldings;
    if (newScaffoldings && newScaffoldings.length) {
        input.scaffoldings = syncModel.scaffoldings
            .map(scaffolding => getScaffoldingCreateInput(scaffolding))
            .filter(s => s)
        ;
    }

    mutations.push({
        mutation: 'createSite',
        args: {
            input
        }
    });

    // Add recursive media mutations (site > scaffoldings > sections)
    walkConstructionPlanSite(syncModel, null, (modelType, syncModel, liveData) => {
        switch (modelType) {
            case ModelType.Site:
                addSiteMediaMutations(mutations, syncModel, liveData, resolvedMedia);
                break;
            case ModelType.Scaffolding:
                addScaffoldingMediaMutations(mutations, syncModel, liveData, resolvedMedia);
                break;
            case ModelType.Section:
                addSectionMediaMutations(mutations, syncModel, liveData, resolvedMedia);
                break;
            default:
            // nothing to do
        }
    });
};

const getSiteUpdateInput = (syncModel, liveData) => {
    const updateInput = _.omitBy(syncModel.data, (fieldValue, fieldKey) => {
        if (SiteMediaFields.indexOf(fieldKey) >= 0) {
            return true;
        }

        if (!liveData) {
            return false;
        }

        if(fieldKey === 'archived'){
            return true;
        }

        return isIdenticalSiteFormValue(fieldKey, syncModel.data, liveData);
    });

    setUpdateInputMediaIds(updateInput, syncModel.data, liveData, 'attachments');

    return updateInput;
};

const addSiteUpdateMutations = (mutations, syncModel, liveData, resolvedMedia) => {

    // Update
    const siteInput = getSiteUpdateInput(syncModel, liveData);

    if (Object.keys(siteInput).length > 0) {
        mutations.push({
            mutation: 'updateSite',
            args: {
                id: syncModel.id,
                input: siteInput
            }
        });
    }

    addSiteMediaMutations(mutations, syncModel, liveData, resolvedMedia);
};

const addSiteMutations = (mutations, syncModel, liveData, resolvedMedia) => {

    // Create
    if (syncModel.mode === ConstructionPlanModelMode.New) {
        addSiteCreateMutations(mutations, syncModel, resolvedMedia);
        return true;
    }

    addSiteUpdateMutations(mutations, syncModel, liveData, resolvedMedia);
};

/*
 * General purpose functions
 */

/**
 * Sets the media ids which should remain attached to the model
 * New media is added via a move mutation separately
 *
 * @param updateInput
 * @param syncModelData
 * @param liveData
 * @param property
 */
const setUpdateInputMediaIds = (updateInput, syncModelData, liveData, property) => {

    const newMediaList = syncModelData ? syncModelData[property] : [];
    const liveMediaList = liveData ? liveData[property] : [];

    const newMediaIds = newMediaList ? newMediaList.map(mi => mi.id) : [];
    const liveMediaIds = liveMediaList ? liveMediaList.map(mi => mi.id) : [];

    // We need to set the update input media field only if the media ids have changed
    if (_.difference(liveMediaIds, newMediaIds).length === 0) {
        return;
    }

    updateInput[property] = _.intersection(newMediaIds, liveMediaIds);
};

/**
 * Sets the update input to null iff the media has changed
 * New media is added by a move mutation later on
 *
 * @param updateInput
 * @param syncModelData
 * @param liveData
 * @param property
 * @return {null|*|null}
 */
const setUpdateInputMediaId = (updateInput, syncModelData, liveData, property) => {

    const newMedia = syncModelData ? syncModelData[property] : null;
    const liveMedia = liveData ? liveData[property] : null;

    // If no live media exists we don't need to reset the field to trigger
    // a deletion
    if (!liveMedia) {
        return;
    }

    const newMediaId = newMedia ? newMedia.id : null;
    const liveMediaId = liveMedia ? liveMedia.id : null;

    // We only return the media id if both are equal - otherwise we
    if (newMediaId === liveMediaId) {
        return;
    }

    updateInput[property] = null;
};

const addNewMediaMutations = (mutations, media, modelType, modelId, collectionName, replace = false) => {
    mutations.push({
        mutation: 'moveMedia',
        args: {
            id: media.id,
            input: {
                modelId,
                modelType,
                modelCollectionName: collectionName,
                replace
            }
        }
    });

    // New media is always flagged with visibility 'account-private'
    // but if the user has selected visibility 'public' we need to update it

    addUpdateMediaMutation(mutations, media);
};

const addUpdateMediaMutation = (mutations, media) => {

    const input = {};

    const { mode } = media;
    const isNew = mode === ConstructionPlanModelMode.New;
    const isEdit = mode === ConstructionPlanModelMode.Edit;

    if (isEdit || isNew && media.visibility !== MediaVisibility.AccountPrivate) {
        input.visibility = media.visibility;
    }

    if (isEdit) {
        input.name = media.name;
    }

    if (_.isEmpty(input)) {
        return;
    }

    mutations.push({
        mutation: 'updateMedia',
        args: {
            id: media.id,
            input
        }
    });
};

const addMediaListMutations = (mutations, modelType, syncModel, liveData, mediaProperty, resolvedMedia = null) => {

    const syncModelData = syncModel ? syncModel.data : null;
    const syncMedia = syncModelData ? syncModelData[mediaProperty] : [];
    if (!syncMedia) {
        return;
    }

    syncMedia.forEach((syncMediaItem) => {
        switch (syncMediaItem.mode) {
            case ConstructionPlanModelMode.New:
                addNewMediaMutations(mutations, syncMediaItem, modelType, syncModel.id, mediaProperty);
                break;
            case ConstructionPlanModelMode.Edit:
                addUpdateMediaMutation(mutations, syncMediaItem);
                break;
            case ConstructionPlanModelMode.Reference:
            default:
                // nothing to do
                break;
        }
    });
};

const addMediaMutation = (mutations, modelType, syncModel, liveData, mediaProperty, resolvedMedia = null) => {
    const syncModelData = syncModel ? syncModel.data : null;
    const syncMediaItem = syncModelData ? syncModelData[mediaProperty] : null;
    if (!syncMediaItem) {
        return;
    }

    const { mode } = syncMediaItem;
    if (!mode || mode === ConstructionPlanModelMode.Reference) {
        return;
    }

    if (mode === ConstructionPlanModelMode.New) {
        addNewMediaMutations(mutations, syncMediaItem, modelType, syncModel.id, mediaProperty, true);
    } else if (mode === ConstructionPlanModelMode.Edit) {
        addUpdateMediaMutation(mutations, syncMediaItem);
    }
};

const addModelMutation = (mutations, modelType, syncModel, liveData, resolvedMedia, parentModel = null) => {

    // Independent from the actual model: if the model is referenced,
    // marked as ignored or has no mode we can ignore it
    if (
        !syncModel.mode
        || syncModel.ignored
        || syncModel.mode === ConstructionPlanModelMode.Reference
    ) {
        return;
    }

    switch (modelType) {
        case ModelType.Site:
            return addSiteMutations(mutations, syncModel, liveData, resolvedMedia);
        case ModelType.Scaffolding:
            return addScaffoldingMutations(mutations, parentModel, syncModel, liveData, resolvedMedia);
        case ModelType.Section:
            return addSectionMutations(mutations, parentModel, syncModel, liveData, resolvedMedia);
        default:
            return null;
    }
};

const getMutations = (syncModel, liveSite, resolvedMedia) => {

    const mutations = [];
    let parentSite = null;
    let parentScaffolding = null;

    walkConstructionPlanSite(syncModel.site, liveSite, (modelType, syncModel, liveData) => {
        let stopTraverse;

        switch (modelType) {
            case ModelType.Site:
                stopTraverse = addModelMutation(mutations, modelType, syncModel, liveData, resolvedMedia);
                parentSite = syncModel;
                break;
            case ModelType.Scaffolding:
                stopTraverse = addModelMutation(mutations, modelType, syncModel, liveData, resolvedMedia, parentSite);
                parentScaffolding = syncModel;
                break;
            case ModelType.Section:
                stopTraverse = addModelMutation(mutations, modelType, syncModel, liveData, resolvedMedia, parentScaffolding);
                break;
            default:
                stopTraverse = true;
                break;
        }

        return stopTraverse;
    });

    return mutations;
};

/*
 * Mutation queries
 */

const getSectionMutationQuery = (sectionMutation) => {
    if (!sectionMutation) {
        return null;
    }

    const { mutation } = sectionMutation;

    const params = sectionMutation.args;
    const inputType = mutation === 'createSection' ? 'CreateSectionInput' : 'SectionInput';
    const variables = mutation === 'createSection' ? `$scaffoldingId: String!, $input: ${inputType}!` : `$id: String!, $input: ${inputType}!`;
    const args = mutation === 'createSection' ? 'scaffoldingId: $scaffoldingId, input: $input' : 'id: $id, input: $input';

    return getMutationQuery(mutation, variables, args, params);
};

const getScaffoldingMutationQuery = (scaffoldingMutation) => {
    if (!scaffoldingMutation) {
        return null;
    }

    const { mutation } = scaffoldingMutation;

    const params = scaffoldingMutation.args;
    const inputType = mutation === 'createScaffolding' ? 'CreateScaffoldingInput' : 'UpdateScaffoldingInput';
    const variables = mutation === 'createScaffolding' ? `$siteId: String!, $input: ${inputType}!` : `$id: String!, $input: ${inputType}!`;
    const args = mutation === 'createScaffolding' ? 'siteId: $siteId, input: $input' : 'id: $id, input: $input';

    return getMutationQuery(mutation, variables, args, params);
};

const getSiteMutationQuery = (siteMutation) => {
    if (!siteMutation) {
        return null;
    }

    const { mutation } = siteMutation;

    const params = siteMutation.args;
    const inputType = mutation === 'createSite' ? 'CreateSiteInput' : 'UpdateSiteInput';
    const variables = mutation === 'createSite' ? `$input: ${inputType}!` : `$id: String!, $input: ${inputType}!`;
    const args = mutation === 'createSite' ? 'input: $input' : 'id: $id, input: $input';

    return getMutationQuery(mutation, variables, args, params);
};

const getMediaMutationQuery = (mediaMutation) => {
    if (!mediaMutation) {
        return null;
    }

    const { mutation } = mediaMutation;

    const params = mediaMutation.args;
    const inputType = mutation === 'moveMedia' ? 'MoveMediaInput' : 'UpdateMediaInput';
    const variables = `$id: String!, $input: ${inputType}!`;
    const args = 'id: $id, input: $input';

    return getMutationQuery(mutation, variables, args, params);
};

const getMutationQuery = (mutation, variables, args, params) => {

    if (!mutation) {
        return null;
    }

    const mutationName = _.upperFirst(mutation);

    const query = `mutation ${mutationName}(${variables}){
        ${mutation}(${args}){
            id
        }
    }`;

    return {
        query,
        params
    };
};

export const getMutationBatchQuery = (mutations) => {
    return mutations.map(mutation => {
        const type = mutation.mutation;
        if (type.endsWith('Site')) {
            return getSiteMutationQuery(mutation);
        } else if (type.endsWith('Scaffolding')) {
            return getScaffoldingMutationQuery(mutation);
        } else if (type.endsWith('Section')) {
            return getSectionMutationQuery(mutation);
        } else if (type.endsWith('Media')) {
            return getMediaMutationQuery(mutation);
        } else {
            console.error(`Could not find mutation query method for mutation type '${type}'`);
        }
    })
        .filter(mutation => mutation);
};

export default getMutations;

export const getConstructionPlanSyncUpdateMutation = (jobId, constructionPlan, syncData) => {
    const params = {
        id: jobId,
        input: {
            constructionPlan: getUpdatedConstructionPlan(constructionPlan, syncData)
        }
    };

    return getMutationQuery('updateJob', '$id: String!, $input: UpdateJobInput!', 'id: $id, input: $input', params);
};

/**
 * Updates the construction plan according the synced data
 *
 * @param constructionPlan
 * @param syncData
 * @return {null|*}
 */
export const getUpdatedConstructionPlan = (constructionPlan, syncData) => {

    if (!constructionPlan) {
        return null;
    }

    const updatedPlan = _.cloneDeep(constructionPlan);
    const { site: planSite } = updatedPlan;
    if (!planSite) {
        return null;
    }

    const { site: syncSite } = syncData;
    updateConstructionPlanModel(planSite, syncSite);

    const planScaffoldings = planSite ? planSite.scaffoldings : [];
    const syncScaffoldings = syncSite ? syncSite.scaffoldings : [];

    if (!planScaffoldings) {
        return updatedPlan;
    }

    planScaffoldings.forEach(planScaffolding => {

        const syncScaffolding = syncScaffoldings.find(syncScaffolding => syncScaffolding.id === planScaffolding.id);
        updateConstructionPlanModel(planScaffolding, syncScaffolding);

        const planSections = planScaffolding ? planScaffolding.sections : [];
        const syncSections = syncScaffolding ? syncScaffolding.sections : [];

        if (!planSections) {
            return;
        }

        planSections.forEach(planSection => {
            const syncSection = syncSections.find(syncSection => syncSection.id === planSection.id);
            updateConstructionPlanModel(planSection, syncSection);
        });
    });

    return updatedPlan;
};

const updateConstructionPlanModel = (planModel, syncModel) => {
    if (!syncModel || syncModel.ignored) {
        return;
    }

    if (syncModel.mode === ConstructionPlanModelMode.Reference) {
        return;
    }

    // 1) Update model mode from 'new' to 'edit'
    if (syncModel.mode === ConstructionPlanModelMode.New) {

        if (!planModel.originalMode) {
            planModel.originalMode = ConstructionPlanModelMode.New;
        }

        planModel.mode = ConstructionPlanModelMode.Edit;
        planModel.lastApplied = moment()
            .toISOString();
    }

    const { data: planModelData } = planModel;
    const { data: syncModelData } = syncModel;
    if (!syncModelData || !planModelData) {
        return;
    }

    // 2) Update media list modes
    ['attachments', 'utilizationPlans'].forEach(mediaField => {
        if (!planModelData.hasOwnProperty(mediaField)) {
            return;
        }

        const planMedia = planModelData[mediaField] || [];
        if (!planMedia || planMedia.length === 0) {
            return;
        }

        if (!syncModelData.hasOwnProperty(mediaField)) {
            return;
        }

        const syncMedia = syncModelData[mediaField];
        if (!syncMedia || syncMedia.length === 0) {
            return;
        }

        // Compare plan and sync media - everything marked as 'new' or 'edit' in sync media
        // has to be changed to 'reference'
        planModelData[mediaField] = planMedia.map(planMediaItem => {
            const syncMediaItem = syncMedia.find(syncMediaItem => syncMediaItem.id === planMediaItem.id);
            if (syncMediaItem && syncMediaItem.mode !== ConstructionPlanModelMode.Reference) {
                return {
                    id: planMediaItem.id,
                    mode: ConstructionPlanModelMode.Reference
                };
            }

            return planMediaItem;
        });
    });

    // 3) Update single media
    ['anchorProtocol', 'proofOfStability'].forEach(mediaField => {
        if (!syncModelData.hasOwnProperty(mediaField)) {
            return;
        }

        const syncMediaItem = syncModelData[mediaField];

        // Plan media was not selected -> no change in plan
        if (!syncMediaItem) {
            return;
        }

        // Set mode to 'reference' for all synced media items
        if (syncMediaItem.mode !== ConstructionPlanModelMode.Reference) {
            planModelData[mediaField].mode = ConstructionPlanModelMode.Reference;
        }

        // Delete name if media file got edited
        if (syncMediaItem.mode === ConstructionPlanModelMode.Edit) {
            planModelData[mediaField].name = undefined;
        }
    });

};
