export const CustomerContactPersonsTableColumns = [
    {
        label: 'Name'
    },
    {
        label: 'Position'
    },
    {
        label: 'Telefon'
    },
    {
        label: 'Telefon (mobil)'
    },
    {
        label: 'E-Mail Adresse'
    }
];
