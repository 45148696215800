import PropTypes from 'prop-types';

export const ModelType = {
    Job: 'job',
    Site: 'site',
    Scaffolding: 'scaffolding',
    Section: 'section',
    ConstructionDiaryEntry: 'constructionDiaryEntry'
};

export const AllowedFileFormats = ['png', 'jpg', 'jpeg', 'pdf', 'obj', 'dwg', 'dxf', 'stp', 'skp', 'fbx', 'ifc', 'step', 'gaeb', 'mov', 'rtf', 'eml', 'txt', 'msg'];


export class ResourceListDisplayType {
    static Active = 'active';
    static Archived = 'archived';
    static Both = 'both';
}

export const ResourceListDisplayTypePropType = PropTypes.oneOf([
    ResourceListDisplayType.Active,
    ResourceListDisplayType.Archived,
    ResourceListDisplayType.Both
]);

export const LOCAL_STORAGE_IS_NEW_APP_VERSION_AVAILABLE = 'newAppVersionAvailable';

export const DEFAULT_ITEMS_PER_TABLE_PAGE = 25;
