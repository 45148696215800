import React from 'react';
import Icon from '../Icon';
import IfUser from '../IfUser';

export const AddButton = ({ label, onClick, neededPermissions }) => (
    <IfUser can={neededPermissions}>
        <button className="btn btn--add-action" onClick={onClick}>
            <span className="btn__icon">
                <Icon name="add" />
            </span>
            <span>
                {label}
            </span>
        </button>
    </IfUser>
);
