import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SortLink from '../../Job/SortLink';

class CustomersTableHeader extends Component {

    constructor(props) {
        super(props);
        this.sortData = this.sortData.bind(this);
        this.getClasses = this.getClasses.bind(this);
    }

    /**
     * give every Sortlink sort__link class and selected class if the link was clicked
     * @param fieldValue == field value of the SortLink
     * @returns string with the css classes for the Sortlink
     */
    getClasses(fieldValue) {
        const selectedClass = this.props.sort.field === fieldValue ? 'selected' : '';
        return `${selectedClass} sort__link`;
    }

    /**
     *
     * @returns 'opposite' of the active sort direction
     */
    getInvertedDirection() {
        return this.props.sort.direction === '-' ? '+' : '-';
    }

    /**
     *
     * @param field == key from the customers reducer customersList.items
     */
    sortData(field) {
        let direction = '+';
        if (this.props.sort.field === field) {
            direction = this.getInvertedDirection();
        }
        this.props.onChange(field, direction);
    }

    render() {
        const { field, direction } = this.props.sort;
        return (
            <thead>
            <tr>
                <th>
                    <SortLink
                        field="name"
                        label="Kunde"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                <th>
                    <SortLink
                        field="customerId"
                        label="Kunden-ID"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                <th>
                    <SortLink
                        field="baseData"
                        label="Adresse"
                        getClasses={this.getClasses}
                        sortData={this.sortData}
                        fieldValue={field}
                        direction={direction}
                    />
                </th>
                <th>
                    Hauptkontakt
                </th>
                <th>
                    Anfragen
                </th>
                <th>
                    Aufträge
                </th>
            </tr>
            </thead>
        );
    }
}


export default CustomersTableHeader;

CustomersTableHeader.propTypes = {
    onChange: PropTypes.func
};

CustomersTableHeader.defaultProps = {
    onChange: () => false
};
