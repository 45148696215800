import PropTypes from 'prop-types';
import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import IfUser from '../Common/IfUser';

const MainNavigationMenuItem = ({ to, label, exact, isActive, requiredPermission }) => {

    return (
        <IfUser
            can={requiredPermission}
        >
            <Route
                path={to}
                exact={exact}
                children={({ match, location }) => {
                    const active = isActive(match, location);
                    return (
                        <NavLink
                            to={{
                                pathname: to
                            }}
                            className="main-navigation__menu-itm"
                            activeClassName="main-navigation__menu-itm--active"
                            isActive={() => active}
                        >
                            <span className="nav-link__lbl">{label}</span>
                        </NavLink>
                    );
                }}
            />
        </IfUser>
    );
};

export default MainNavigationMenuItem;

MainNavigationMenuItem.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    isActive: PropTypes.func
};

MainNavigationMenuItem.defaultProps = {
    isActive: (match, location, exact) => {
        return match && (!exact || match.isExact);
    }
};

