import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { PDFObject } from 'react-pdfobject';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { getPublicMediaContentUrl } from '../../redux/modules/media/action';
import { isImage, isPdf } from '../../utils';
import LoadingSpinner, { BACKGROUND_COLOR_WHITE } from '../Page/LoadingSpinner';
import Icon from './Icon';

const lightBoxRoot = document.getElementById('lightbox-root');

class LightBox extends Component {

    constructor(props) {
        super(props);
        this.el = document.createElement('div');

        this.state = {
            loading: true,
            supported: false,
            media: null,
            publicUrl: null,
            isDownloading: false
        };

        this.onBackdropClick = this.onBackdropClick.bind(this);
        this.close = this.close.bind(this);
        this.downloadItem = this.downloadItem.bind(this);
    }

    static isSupported(media) {
        if (!media) {
            return false;
        }

        const { mimeType } = media;
        if (!mimeType) {
            return false;
        }

        return isImage(mimeType) || isPdf(mimeType);
    }


    downloadItem() {

        const { media: mediaItem } = this.state;

        this.setState({
            isDownloading: true
        });

        this.props.actions.getPublicMediaContentUrl(mediaItem.id, mediaItem.fileName, true)
            .then((url) => {
                window.open(url, 'se_media_download');
            })
            .catch(() => {
                toast.error('Download konnte nicht gestartet werden');
            })
            .finally(() => {
                this.setState({
                    isDownloading: false
                });
            });
    }

    componentDidMount() {
        lightBoxRoot.appendChild(this.el);
        this.preventBackgroundScrolling(true);

        const { media } = this.props;

        if (!LightBox.isSupported(media)) {
            this.setState({
                loading: false,
                supported: false,
                media
            });

            return;
        }

        if (isImage(media.mimeType)) {
            this.setState({
                loading: false,
                supported: true,
                media,
                publicUrl: `${process.env.REACT_APP_API_URL}/v1/images/${media.id}/${media.fileName}`
            });
        } else {
            this.props.actions.getPublicMediaContentUrl(media.id, media.fileName)
                .then((url) => {
                    this.setState({
                        loading: false,
                        supported: true,
                        media,
                        publicUrl: url
                    });
                })
                .catch(() => {
                    toast.error('PDF kann nicht geladen werden', { autoClose: 2500 });
                });
        }
    }

    componentWillUnmount() {
        lightBoxRoot.removeChild(this.el);
        this.preventBackgroundScrolling(false);
    }

    preventBackgroundScrolling(preventScrolling) {
        const bodyTag = document.getElementsByTagName('body');

        if (preventScrolling) {
            bodyTag[0].setAttribute('class', 'unscrollable');
        } else {
            bodyTag[0].setAttribute('class', '');
        }
    }


    renderUnsupportedMediaFile() {

        const { isDownloading } = this.state;

        return <div className="light-box__unsupported-hint">
            <div className="light-box__unsupported-hint-cnt">
                <h2> Unbekanntes Dateiformat</h2>
                <p> Laden Sie das Dokument herunter, um es öffnen </p>
            </div>

            <span className="btn btn--lg btn--neutral-action" onClick={this.downloadItem}>
                {
                    isDownloading ?
                        <LoadingSpinner backgroundColor={BACKGROUND_COLOR_WHITE} />
                        :
                        <span> <Icon name="download" /></span>
                }
                {' '}
                Herunterladen
            </span>
        </div>;
    }

    renderLoadError() {
        return 'Fehler beim Laden der Datei';
    }

    renderImage() {
        const { media, publicUrl } = this.state;
        return (
            <img src={publicUrl} alt={media.name} className="light-box__img" />
        );
    }

    renderPDF() {
        const { publicUrl } = this.state;
        return (
            <PDFObject
                url={publicUrl}
            />
        );
    }

    renderMedia() {
        const { media, supported } = this.state;

        if (!supported) {
            return this.renderUnsupportedMediaFile();
        }

        if (!media) {
            return this.renderLoadError();
        }

        if (isImage(media.mimeType)) {
            return this.renderImage();
        }

        if (isPdf(media.mimeType)) {
            return this.renderPDF();
        }

        return this.renderUnsupportedMediaFile();
    }

    onBackdropClick(ev) {

        if (ev.target.className === 'btn btn--lg btn--neutral-action') {
            return;
        }

        this.close();
    }

    close() {
        this.props.onClose();
    }

    renderLoadingSpinner() {
        return (
            <div
                className="light-box__loading"
            >
                <LoadingSpinner size="large" />
            </div>
        );
    }

    renderLightBox() {
        const { loading, media } = this.state;

        let mediaName = 'Datei';
        if (media) {
            if (media.name) {
                mediaName = media.name;
            } else if (media.fileName) {
                mediaName = media.fileName;
            }
        }

        const title = `${loading ? 'Lade ' : ''}${mediaName}${loading ? ' ... ' : ''}`;

        return (
            <div className="light-box">
                <div className="light-box__hd">
                    <div className="light-box__ttl">
                        {title}
                    </div>

                    <a role="button" className="light-box__close" onClick={this.close}>
                        <Icon name="close" />
                    </a>
                </div>
                <div className="light-box__window" onClick={this.onBackdropClick}>
                    <div className="light-box__bd">
                        {loading
                            ? this.renderLoadingSpinner()
                            : this.renderMedia()
                        }
                    </div>
                </div>
                <div
                    className="light-box__backdrop"
                />
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(
            this.renderLightBox(),
            this.el
        );
    }
}

LightBox.propTypes = {
    media: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getPublicMediaContentUrl
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(LightBox);
