import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from '../../../../Common/Diff/functions';
import { wallClearanceLabel } from '../../../../Sections/labels';
import { WallClearance } from '../../../../Sections/properties';
import { getSyncChoiceValue } from '../../../utils';
import asSyncChoiceField from '../../asSyncChoiceField';
import SyncChoiceRow from '../../Base/Input/SyncChoiceRow';
import SyncInputGroup from '../../Base/Input/SyncInputGroup';
import { isObjectIdenticalValue } from '../../equals';
import { validateSingleChoiceComplete } from '../../validation';

const WallClearanceSyncChoice = ({ planValue, showHint, liveValue, value: currentFormValue, onChange, showIdentical }) => {

    const renderWallClearanceChoiceLabel = (valueObject) => {

        if (!valueObject) {
            return '-';
        }

        const valueKey = Object.keys(valueObject)[0];

        // > 30 cm
        if (valueKey === WallClearance.Above300MM) {
            return `> 30 cm: ${valueObject[valueKey]}`;
        }

        return wallClearanceLabel(valueKey);
    };

    const isSelected = (value) => {
        return isObjectIdenticalValue(currentFormValue, value);
    };

    const leftValue = !isEmpty(planValue) ? getSyncChoiceValue(Object.keys(planValue)[0], planValue, null) : null;
    const rightValue = !isEmpty(liveValue) ? getSyncChoiceValue(Object.keys(liveValue)[0], liveValue, null) : null;

    const isIdentical = isObjectIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Wandabstand"
        >
            <SyncChoiceRow
                leftValue={leftValue}
                rightValue={rightValue}
                renderValue={renderWallClearanceChoiceLabel}
                onSelect={onChange}
                isSelected={isSelected}
                isIdentical={isIdentical}
                showIdentical={showIdentical}
            />
        </SyncInputGroup>
    );
};

WallClearanceSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    showHint: PropTypes.func,
    showIdentical: PropTypes.bool
};

WallClearanceSyncChoice.defaultProps = {
    showHint: () => false
};

export default asSyncChoiceField(WallClearanceSyncChoice, {
    allowNull: true,
    validate: validateSingleChoiceComplete(undefined, isObjectIdenticalValue)
});
