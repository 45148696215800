import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Permission from '../../../../permissions';
import { userCan } from '../../../../Services/UserService';
import InputGroup from '../../../Forms/InputGroup';
import { FormMode } from '../../../Forms/properties';
import ScaffoldingSelect from '../../../Scaffoldings/ScaffoldingSelect';
import SiteSelect from '../../../Sites/SiteSelect';

const SectionAffiliationInput = (props) => {
    const {
        section,
        values,
        onFetchingSites,
        onChangeSite,
        onFetchingScaffoldings,
        onChangeScaffolding,
        mode,
        readOnly,
        isConstructionPlan,
        userCanCreateManagedSections
    } = props;

    if (isConstructionPlan) {
        return null;
    }

    return (
        <InputGroup
            label={`Zugehörigkeit${mode === FormMode.Create ? '*' : ''}`}
            containerClass="input-grp--affiliation"
        >
            {mode === FormMode.Edit ? (
                <div>
                    Gerüst: {section?.scaffolding?.site?.name} <br />
                    Baustelle: {section?.scaffolding?.name}
                </div>
            ) : (
                <Fragment>
                    <SiteSelect
                        name="siteId"
                        onFetching={onFetchingSites}
                        onChange={onChangeSite}
                    />
                    <ScaffoldingSelect
                        name="scaffoldingId"
                        siteId={values.siteId}
                        onFetching={onFetchingScaffoldings}
                        onChange={onChangeScaffolding}
                        managed={userCanCreateManagedSections ? 'both' : false}
                        readOnly={readOnly}
                    />
                </Fragment>
            )}
        </InputGroup>
    );
}


const mapStateToProps = ({ currentUser }) => {
    const userPermissions = currentUser.usermeta.permissions ? currentUser.usermeta.permissions : [];
    const userCanCreateManagedSections = userCan(Permission.CreateManagedSections, userPermissions);
    return {
        userCanCreateManagedSections
    };
};

export default connect(mapStateToProps, null)(SectionAffiliationInput);
