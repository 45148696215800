/**
 * All permissions a user can have
 */
export default class Permission
{
    static CreateDocument = 'create-documents';
    static ReadDocument = 'read-documents';
    static UpdateDocument = 'update-documents';
    static DeleteDocument = 'delete-documents';

    /*
     * Application access
     */
    static AccessWebApp = 'access-webapp';
    static AccessApp = 'access-app';

    /*
     * Companies
     */
    static CreateCompanies = 'create-companies';
    static ReadCompanies = 'read-companies';
    static UpdateCompanies = 'update-companies';
    static DeleteCompanies = 'delete-companies';

    /*
     * Customers
     */
    static CreateCustomers = 'create-customers';
    static ReadCustomers = 'read-customers';
    static UpdateCustomers = 'update-customers';
    static DeleteCustomers = 'delete-customers';

    /*
     * Inspections
     */
    static CreateInspections = 'create-inspections';
    static ReadInspections = 'read-inspections';
    static DeleteInspections = 'delete-inspections';
    static CreateAcceptanceInspection = 'create-acceptance-inspection';

    /*
     * Jobs
     */
    static CreateJobs = 'create-jobs';
    static ReadJobs = 'read-jobs';
    static UpdateJobs = 'update-jobs';
    static DeleteJobs = 'delete-jobs';

    /*
     * Notes
     */
    static CreateNotes = 'create-notes';
    static ReadNotes = 'read-notes';
    static UpdateNotes = 'update-notes';
    static DeleteNotes = 'delete-notes';
    static DeleteOthersNotes = 'delete-others-notes';

    /*
     * Persons
     */
    static CreatePersons = 'create-persons';
    static ReadPersons = 'read-persons';
    static UpdatePersons = 'update-persons';
    static DeletePersons = 'delete-persons';

    /*
     * Scaffoldings
     */
    static CreateScaffoldings = 'create-scaffoldings';
    static ReadScaffoldings = 'read-scaffoldings';
    static UpdateScaffoldings = 'update-scaffoldings';
    static DeleteScaffoldings = 'delete-scaffoldings';

    static CreateManagedScaffoldings = 'create-managed-scaffoldings';
    static UpdateManagedScaffoldings = 'update-managed-scaffoldings';
    static DeleteManagedScaffoldings = 'delete-managed-scaffoldings';

    /*
     * Sections
     */
    static CreateSections = 'create-sections';
    static ReadSections = 'read-sections';
    static UpdateSections = 'update-sections';
    static DeleteSections = 'delete-sections';

    static CreateManagedSections = 'create-managed-sections';
    static UpdateManagedSections = 'update-managed-sections';
    static DeleteManagedSections = 'delete-managed-sections';

    /*
     * Watch sections
     */
    static WatchSections = 'watch-sections';
    static UnwatchSections = 'watch-sections';

    /*
     * Sites
     */
    static CreateSites = 'create-sites';
    static ReadSites = 'read-sites';
    static UpdateSites = 'update-sites';
    static DeleteSites = 'delete-sites';
    static CreateSiteInvitations = 'create-site-invitations';

    /*
     * Tasks
     */
    static CreateTasks = 'create-tasks';
    static ReadTasks = 'read-tasks';
    static UpdateTasks = 'update-tasks';
    static DeleteTasks = 'delete-tasks';

    /*
     * User management
     */
    static CreateUsers = 'create-users';
    static ReadUsers = 'read-users';
    static UpdateUsers = 'update-users';
    static DeleteUsers = 'delete-users';

    /*
     * Account & Subscription
     */
    static UpdateBaseData = 'update-company';
    static UpdateSubscription = 'update-subscription';
    static UpdateBilling = 'update-billing';
    static DeleteAccount = 'delete-account';

    /*
     * Construction diary
     */
    static CreateConstructionDiaryEntries = 'create-construction-diary-entries';
    static ReadConstructionDiaryEntries = 'read-construction-diary-entries';
    static UpdateConstructionDiaryEntries = 'update-construction-diary-entries';
    static DeleteConstructionDiaryEntries = 'delete-construction-diary-entries';
}
