import PropTypes from 'prop-types';
import React from 'react';
import DateService from '../../../../Services/DateService';
import Invitation from './Invitation';

const AcceptedInvitation = ({ data }) => {

    const { acceptedBy, id, acceptedBy: { firstName, lastName }, inviteeEmail, accepted: acceptionDate } = data;

    const acceptedByCompany = acceptedBy && acceptedBy.account && acceptedBy.account.company && acceptedBy.account.company.name || 'n.a';
    const acceptedByInvitee = `${firstName} ${lastName} (${inviteeEmail})`;
    const acceptedAt = DateService.getReadableDateWithMonthName(acceptionDate);

    return (
        <Invitation
            icon="stateok"
            id={id}
            heading={acceptedByCompany}
            tooltipContent={`Einladung wurde am ${acceptedAt} angenommen`}
        >
            {acceptedByInvitee}
        </Invitation>
    );
};


export default AcceptedInvitation;

AcceptedInvitation.propTypes = {
    data: PropTypes.object.isRequired
};
