export const FETCH_LOAD_SPINNER = 'FETCH_LOAD_SPINNER';

/*
 * Authentication
 */
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const VERIFY = 'VERIFY';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

/*
 * User
 */
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

/*
 * Refresh token
 */
export const REFRESH_TOKEN_REQUESTING = 'REFRESH_TOKEN_REQUESTING';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';


/*
 * Construction Diary
 */
export const FETCH_CONSTRUCTION_DIARY = 'FETCH_CONSTRUCTION_DIARY';
export const FETCH_CONSTRUCTION_DIARY_SUCCESS = 'FETCH_CONSTRUCTION_DIARY_SUCCESS';
export const FETCH_CONSTRUCTION_DIARY_FAILURE = 'FETCH_CONSTRUCTION_DIARY_FAILURE';
export const SET_CONSTRUCTION_DIARY_FILTER_OPTION = 'SET_CONSTRUCTION_DIARY_FILTER_OPTION';
export const RESET_CONSTRUCTION_DIARY_FILTER_OPTION = 'RESET_CONSTRUCTION_DIARY_FILTER_OPTION';
export const SET_CONSTRUCTION_DIARY_SORT_OPTION = 'SET_CONSTRUCTION_DIARY_SORT_OPTION';
export const SET_CURRENT_CONSTRUCTION_DIARY_PAGE = 'SET_CURRENT_CONSTRUCTION_DIARY_PAGE';
export const FETCH_CONSTRUCTION_DIARY_TOTAL = 'FETCH_CONSTRUCTION_DIARY_TOTAL';

/*
 * Fetching public media download tokens
 */
export const FETCH_MEDIA_TOKEN_SUCCESS = 'FETCH_MEDIA_TOKEN_SUCCESS';

/*
 * Customers
 */
export const ADD_NEW_CUSTOMER_SUCCESS = 'ADD_NEW_CUSTOMER_SUCCESS';
export const ADD_NEW_CUSTOMER_FAILURE = 'ADD_NEW_CUSTOMER_FAILURE';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const SET_CURRENT_CUSTOMERS_PAGE = 'SET_CURRENT_CUSTOMERS_PAGE';
export const SET_CUSTOMERS_SORT_OPTION = 'SET_CUSTOMERS_SORT_OPTION';

/*
 * Customer
 */
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

/*
 * Scaffolding
 */
export const ADD_SCAFFOLDING_SUCCESS = 'ADD_SCAFFOLDING_SUCCESS';
export const ADD_SCAFFOLDING_FAILURE = 'ADD_SCAFFOLDING_FAILURE';

export const UPDATE_SCAFFOLDING_SUCCESS = 'UPDATE_SCAFFOLDING_SUCCESS';
export const UPDATE_SCAFFOLDING_FAILURE = 'UPDATE_SCAFFOLDING_FAILURE';

export const FETCH_SCAFFOLDING = 'FETCH_SCAFFOLDING';
export const FETCH_SCAFFOLDING_SUCCESS = 'FETCH_SCAFFOLDING_SUCCESS';
export const FETCH_SCAFFOLDING_FAILURE = 'FETCH_SCAFFOLDING_FAILURE';

export const DELETE_SCAFFOLDING = 'DELETE_SCAFFOLDING';
export const DELETE_SCAFFOLDING_SUCCESS = 'DELETE_SCAFFOLDING_SUCCESS';
export const DELETE_SCAFFOLDING_FAILURE = 'DELETE_SCAFFOLDING_FAILURE';

/*
 * Section
 */
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS';
export const ADD_SECTION_FAILURE = 'ADD_SECTION_FAILURE';

export const ADD_SECTION_NOTES_SUCCESS = 'ADD_SECTION_NOTES_SUCCESS';
export const ADD_SECTION_NOTES_FAILURE = 'ADD_SECTION_NOTES_FAILURE';

export const FETCH_SECTION = 'FETCH_SECTION';
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS';
export const FETCH_SECTION_FAILURE = 'FETCH_SECTION_FAILURE';

export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';

/*
 * Site
 */
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_SITE_FAILURE = 'ADD_SITE_FAILURE';

export const FETCH_SITE = 'FETCH_SITE';
export const FETCH_SITE_SUCCESS = 'FETCH_SITE_SUCCESS';
export const FETCH_SITE_FAILURE = 'FETCH_SITE_FAILURE';

export const FETCH_SITES = 'FETCH_SITES';
export const FETCH_SITES_SUCCESS = 'FETCH_SITES_SUCCESS';
export const FETCH_SITES_FAILURE = 'FETCH_SITES_FAILURE';

export const SET_CURRENT_SITES_PAGE = 'SET_CURRENT_SITES_PAGE';
export const SET_SITES_SORT_OPTION = 'SET_SITES_SORT_OPTION';

export const DELETE_SITE = 'DELETE_SITE';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE';

/*
 * Inspections
 */
export const FETCH_INSPECTION = 'FETCH_INSPECTION';
export const FETCH_INSPECTION_SUCCESS = 'FETCH_INSPECTION_SUCCESS';
export const FETCH_INSPECTION_FAILURE = 'FETCH_INSPECTION_FAILURE';

export const FETCH_INSPECTION_LIST = 'FETCH_INSPECTION_LIST';
export const FETCH_INSPECTION_LIST_SUCCESS = 'FETCH_INSPECTION_LIST_SUCCESS';
export const FETCH_INSPECTION_LIST_FAILURE = 'FETCH_INSPECTION_LIST_FAILURE';

export const SET_INSPECTION_LIST_SORT_OPTION = 'SET_INSPECTION_LIST_SORT_OPTION';
export const SET_CURRENT_INSPECTION_LIST_PAGE = 'SET_CURRENT_INSPECTION_LIST_PAGE';

export const RESET_INSPECTION_LIST_SORT_OPTIONS = 'RESET_INSPECTION_LIST_SORT_OPTIONS';


/*
 * Errors
 */
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';

/*
 * Service Worker
 */

export const NEW_APP_VERSION_IS_AVAILABLE = 'NEW_APP_VERSION_IS_AVAILABLE';
export const NEW_APP_VERSION_IS_ACTIVATED = 'NEW_APP_VERSION_IS_ACTIVATED';

/*
 Test period has ended
 */

export const TEST_PERIOD_HAS_EXPIRED = 'TEST_PERIOD_HAS_EXPIRED';

/*
 Main subscription has ended
 */
export const MAIN_SUBSCRIPTION_HAS_EXPIRED = 'MAIN_SUBSCRIPTION_HAS_EXPIRED';

export const HIDE_EXPIRATION_MODAL = 'HIDE_EXPIRATION_MODAL';
