import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { ModelType } from '../../../properties';
import { showApiError, showRequestError } from '../../../redux/modules/error/action';
import {
    deleteConstructionPlan,
    fetchConstructionPlanSiteReferenceData
} from '../../../redux/modules/job/actions';
import { BadRequestError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import DeleteModal from '../../Common/Modal/DeleteModal';
import Icon from '../../Common/Icon';
import Section from '../../Layout/Section';
import LoadingSpinner from '../../Page/LoadingSpinner';
import ConstructionPlanDiffModal from '../ConstructionPlanDiffModal';
import { ConstructionPlanModelMode } from '../properties';
import ConstructionPlanSyncModal from '../Sync/ConstructionPlanSyncModal';
import { isNewPlan, walkConstructionPlanSite } from '../utils';
import JobConstructionPlanMedia from './ConstructionPlanMedia';
import ConstructionPlanScaffolding from './ConstructionPlanScaffolding';
import ConstructionPlanSection from './ConstructionPlanSection';
import ConstructionPlanSite from './ConstructionPlanSite';

const isReferenceOnlyPlan = (site) => {

    let onlyReferences = true;

    walkConstructionPlanSite(site, null, (modelType, model) => {
        onlyReferences &= model.mode === ConstructionPlanModelMode.Reference;
    });

    return onlyReferences;
};

class ConstructionPlanDetails extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            initialized: false,
            referenceData: null,
            showDeleteModal: false,
            showChangeLogModal: false,
            isDeletingPlan: false,
            showSyncModal: false
        };

        this.toggleChangeLogModal = this.toggleChangeLogModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.toggleSyncModal = this.toggleSyncModal.bind(this);
    }

    componentDidUpdate(prevProps) {

        const { site: oldSite } = prevProps.constructionPlan;
        const { site: newSite } = this.props.constructionPlan;

        if ((oldSite && oldSite.id) !== (newSite && newSite.id)) {
            this.refreshConstructionPlanSiteReferenceData();
        }
    }

    refreshConstructionPlanSiteReferenceData() {

        const { constructionPlan, actions: { fetchConstructionPlanSiteReferenceData } } = this.props;
        const { site } = constructionPlan;

        if (!site) {
            return;
        }

        const { id: siteId } = site;

        fetchConstructionPlanSiteReferenceData(siteId)
            .then(referenceData => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    initialized: true,
                    referenceData,
                    isArchived: referenceData && referenceData.archived
                });
            })
            .catch(err => {
                showRequestError('Planungsdaten können nicht geladen werden', err);
            });
    }

    componentWillMount() {
        const { constructionPlan } = this.props;
        const { site } = constructionPlan || {};

        if (!site || site.mode === ConstructionPlanModelMode.New) {
            this.setState({
                initialized: true
            });
            return;
        }

        this.refreshConstructionPlanSiteReferenceData();

    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    }

    toggleChangeLogModal() {
        this.setState(prevState => ({
            showChangeLogModal: !prevState.showChangeLogModal
        }));
    }

    toggleSyncModal() {
        this.setState(prevState => ({
            showSyncModal: !prevState.showSyncModal
        }));
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    deletePlan() {

        const { onDelete, jobId, actions: { deleteConstructionPlan } } = this.props;

        this.setState({
            isDeletingPlan: true
        });

        deleteConstructionPlan(jobId)
            .then((job) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isDeletingPlan: false
                });

                toast.success(`Die Baustellenplanung zu "${job.name}" wurde gelöscht.`, { autoClose: 2500 });
                this.toggleDeleteModal();
                onDelete();

            })
            .catch((error) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isDeletingPlan: false
                });

                if (error instanceof BadRequestError) {
                    ErrorService.handleBadRequestError(error, 'job');
                }
                showApiError(error);
            });
    }

    renderModeHint() {
        const { constructionPlan } = this.props;
        const { isArchived } = this.state;
        const { site } = constructionPlan || {};
        if (!site) {
            return null;
        }

        if (isArchived) {
            return null;
        }

        if (site.mode === ConstructionPlanModelMode.New) {
            return (
                <div className="construction-plan__mode-hint construction-plan__mode-hint--new">
                    <div className="construction-plan__mode-hint-lbl">
                        Neue Baustelle
                    </div>
                </div>
            );
        }

        if (!isReferenceOnlyPlan(site)) {
            return (
                <div className="construction-plan__mode-hint">
                    <div className="construction-plan__mode-hint-lbl">
                        Planung auf Basis einer Live-Baustelle
                    </div>

                    <button
                        onClick={() => this.toggleChangeLogModal()}
                        className="btn btn-icon btn--neutral-action"
                    >
                        <Icon name="plannerCompare" /> Änderungsübersicht
                    </button>
                </div>
            );
        }

        return (
            <div className="construction-plan__mode-hint construction-plan__mode-hint--ref-only">
                <Icon name="stateok" />
                <div className="construction-plan__mode-hint-lbl">
                    Planung identisch mit Live-Baustelle
                </div>
            </div>
        );
    }


    renderPlanModels() {
        const { constructionPlan: { site } } = this.props;
        const { referenceData, isArchived } = this.state;

        const refOnly = isReferenceOnlyPlan(site);

        const content = [];
        walkConstructionPlanSite(site, referenceData, (modelType, model, referenceData) => {
            if (modelType === ModelType.Site) {
                content.push(
                    <ConstructionPlanSite
                        key={model.id}
                        site={model}
                        referenceData={referenceData}
                        refOnly={refOnly}
                        isArchived={isArchived}
                    />
                );
            } else if (modelType === ModelType.Scaffolding) {
                content.push(
                    <ConstructionPlanScaffolding
                        key={model.id}
                        scaffolding={model}
                        referenceData={referenceData}
                        refOnly={refOnly}
                        isArchived={isArchived}
                    />
                );
            } else if (modelType === ModelType.Section && !isArchived) {
                content.push(
                    <ConstructionPlanSection
                        key={model.id}
                        section={model}
                        referenceData={referenceData}
                        refOnly={refOnly}
                    />
                );
            }
        });

        return (
            <Fragment>
                <div
                    className={`construction-plan__models ${refOnly ? 'construction-plan__models--ref-only' : ''} ${isArchived ? 'construction-plan__models--archived' : ''}`}
                >
                    {content}
                </div>
                {
                    isArchived && <div className="construction-plan__archived-hint-text">
                        Hinweis: Die angegebene Baustelle wurde zwischenzeitlich archiviert. Die
                        Funktionen zur Baustellenplanung stehen archivierten Baustellen nicht zur
                        Verfügung.
                    </div>
                }
            </Fragment>


        );
    }

    renderSyncButtonContent() {

        const { constructionPlan } = this.props;


        if (isNewPlan(constructionPlan)) {
            return (
                <Fragment>
                    <span style={
                        {
                            top: -1,
                            marginRight: 10,
                            marginLeft: 4,
                            position: 'relative',
                            fontSize: 12
                        }
                    }
                    >
                        <Icon name="add" />
                    </span>
                    Live-Baustelle generieren
                </Fragment>
            );
        }

        return (
            <Fragment>
                <span style={{
                    top: 3,
                    marginRight: 8,
                    marginLeft: 3,
                    position: 'relative'
                }}
                >
                    <Icon name="jobTransform" />
                </span>
                Planung anwenden
            </Fragment>
        );
    }

    renderModelSection() {

        const { constructionPlan, onEdit, editingDisabled } = this.props;
        const { isArchived } = this.state;

        const { site } = constructionPlan || {};

        if (!site) {
            return null;
        }


        return (
            <Section
                title="Baustellenplanung"
                cta={
                    !editingDisabled ?
                        <Fragment>
                            {
                                (isReferenceOnlyPlan(site) || isArchived) ?
                                    null
                                    :
                                    <button
                                        onClick={this.toggleSyncModal}
                                        className="btn btn--primary-green btn--icon"
                                        style={{
                                            marginRight: 24
                                        }}
                                    >
                                        {
                                            this.renderSyncButtonContent()
                                        }
                                    </button>
                            }
                            <button
                                onClick={this.toggleDeleteModal}
                                className="btn btn--backward-action btn--icon"
                            >
                                <span className="btn__icon"><Icon name="delete" /></span>
                            </button>
                            <button
                                onClick={onEdit}
                                className="btn btn--neutral-action btn--icon"
                            >
                                <span className="btn__icon"><Icon name="edit" /></span>
                            </button>
                        </Fragment>
                        :
                        <div />
                }
                classes="section--construction-plan"
            >
                {this.renderModeHint()}
                {this.renderPlanModels()}
            </Section>
        );
    }

    renderMediaSection() {
        const { constructionPlan } = this.props;
        const { site } = constructionPlan || {};
        const { referenceData, isArchived } = this.state;

        if (isArchived) {
            return null;
        }

        return (
            <JobConstructionPlanMedia
                site={site}
                referenceData={referenceData}
            />
        );
    }

    render() {

        const { jobId, constructionPlan, jobLabel, onConstructionPlanSynchronization } = this.props;
        const { initialized, showDeleteModal, isDeletingPlan, showChangeLogModal, showSyncModal, referenceData, isArchived } = this.state;

        if (!initialized) {
            return (
                <div className="section job__construction-plan">
                    <Section
                        title="Baustellenplanung"
                    >
                        <LoadingSpinner block label="Lade Plandaten" />
                    </Section>
                </div>
            );
        }

        const wrapperClasses = ['section', 'job__construction-plan'];

        if (isArchived) {
            wrapperClasses.push('job__construction-plan--archived');
        }

        return (
            <div className={wrapperClasses.join(' ')}>
                {this.renderModelSection()}
                {this.renderMediaSection()}

                {showDeleteModal &&
                <DeleteModal
                    modalTitle={isArchived ? 'Baustelle entfernen' : 'Baustellenplanung löschen'}
                    deleteEntity={() => this.deletePlan()}
                    closeModal={() => this.toggleDeleteModal()}
                    isDeletingEntity={isDeletingPlan}
                    submitBtnText='Fortfahren und entfernen'
                    submitBtnTextWhileDeleting='Wird entfernt ...'
                >

                    {isArchived ?
                        <Fragment>
                            <p> Sie sind im Begriff, Ihre Baustelle in der Baustellenplanung zu
                                entfernen.</p>

                            <p><strong style={{ fontWeight: 600 }}>Ihre verknüpfte Baustelle wurde
                                bereits archiviert. Dadurch steht Ihnen die Baustelle danach für
                                eine erneute Verknüpfung nicht mehr zur Verfügung.</strong></p>

                            <p>Möchten Sie fortfahren?</p>
                        </Fragment>
                        :
                        <Fragment>
                            <p>
                                Sie sind im Begriff, die aktuelle Baustellenplanung zu verwerfen.
                            </p>
                            <p>Möchten Sie trotzdem fortfahren?</p>
                        </Fragment>
                    }

                </DeleteModal>
                }

                {
                    showChangeLogModal &&
                    <ConstructionPlanDiffModal
                        constructionPlan={constructionPlan}
                        onClose={this.toggleChangeLogModal}
                    />
                }

                {
                    showSyncModal &&
                    <ConstructionPlanSyncModal
                        jobId={jobId}
                        constructionPlan={constructionPlan}
                        onClose={this.toggleSyncModal}
                        onConstructionPlanSynchronization={() => {
                            this.toggleSyncModal();
                            onConstructionPlanSynchronization();
                        }}
                        jobLabel={jobLabel}
                        liveSiteName={referenceData ? referenceData.name : null}
                    />
                }
            </div>
        );
    }
}

ConstructionPlanDetails.propTypes = {
    constructionPlan: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    jobId: PropTypes.string.isRequired,
    editingDisabled: PropTypes.bool.isRequired,
    onConstructionPlanSynchronization: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        deleteConstructionPlan,
        fetchConstructionPlanSiteReferenceData
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanDetails);
