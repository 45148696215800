import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IbanElement, injectStripe } from 'react-stripe-elements';
import { ViewModes } from '../Account/BillingDetailsForm';
import Input from '../Forms/Input';
import InputGroup from '../Forms/InputGroup';
import Icon from './Icon';
import stripeInputStyles from './stripeInputStyles';
import SepaInfo from './SepaInfo';

class SepaSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: ''
        };
        this.renderSepaForm = this.renderSepaForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        if (event.error) {
            this.setState({
                errorMessage: event.error.message
            });
        } else {
            this.setState({
                errorMessage: ''
            });
        }
    }

    renderCurrentSepaInformation() {
        const { billingData } = this.props;
        if (!billingData) {
            return null;
        }

        const { sepaDebit } = billingData;
        if (!sepaDebit) {
            return null;
        }

        return (
            <React.Fragment>
                <InputGroup containerClass="input-grp--light-label" label="Aktive Zahlungsmethode">
                    <SepaInfo sepaOwner={billingData.owner && billingData.owner.name} sepaLast4={sepaDebit.last4} />
                </InputGroup>
                <InputGroup containerClass="input-grp--light-label" label="Mandats-ID">
                    {sepaDebit.mandateReference} <br />
                    <a
                        href={sepaDebit.mandateUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            textDecoration: 'none',
                            display: 'block',
                            marginTop: 4
                        }}
                    >
                        Weitere Informationen zum SEPA-Mandat und zum Zahlungsempfänger
                    </a>
                </InputGroup>
            </React.Fragment>
        );
    }

    renderSepaActions() {
        const { disabled } = this.props;

        return (
            <InputGroup label="">
                <div className="sepadebit__actions">
                    {
                        this.props.viewMode !== ViewModes.SepaEdit && (
                            <button
                                className="btn btn--add-action"
                                style={{ float: 'right' }}
                                disabled={disabled}
                                onClick={() => {
                                    if (!disabled) {
                                        this.props.setViewMode(ViewModes.SepaEdit);
                                    }
                                }}
                            >
                                <span className="btn__icon">
                                    <Icon name="add" />
                                </span>
                                <span>Neues Mandat anlegen</span>
                            </button>
                        )
                    }
                </div>
            </InputGroup>
        );
    }

    renderSepaDisplay() {
        return (
            <React.Fragment>
                {
                    this.props.viewMode === ViewModes.SepaEdit
                        ? this.renderSepaForm()
                        : this.renderCurrentSepaInformation()
                }
                {this.renderSepaActions()}
            </React.Fragment>
        );
    }

    renderSepaForm() {
        const { errorMessage } = this.state;

        const { disabled } = this.props;

        return (
            <div className="sepadebit__addform">
                <InputGroup label="">
                    <p className="sepadebit__mandate-agreement">
                        Hiermit ermächtige ich die Scaffeye GbR, Zahlungen von meinem Konto mittels
                        Lastschrift einzuziehen. Zugleich weise ich unser Kreditinstitut an, die von
                        der Scaffeye GbR auf mein Konto gezogenen Lastschriften einzulösen.
                    </p>
                </InputGroup>

                <InputGroup
                    label="Name des Karteninhabers*"
                    subInput
                >
                    <Input
                        type="text"
                        name="name"
                        disabled={disabled}
                        placeholder="Karteninhaber angeben"
                    />
                </InputGroup>
                <InputGroup label="IBAN*" subInput>
                    <IbanElement
                        supportedCountries={['SEPA']}
                        onChange={this.handleChange}
                        disabled={disabled}
                        {...stripeInputStyles}
                    />
                    {errorMessage && <div className="input-grp__error">{errorMessage}</div>}
                </InputGroup>

            </div>
        );
    }

    render() {
        const { viewMode } = this.props;

        switch (viewMode) {
            case ViewModes.SepaView:
            case ViewModes.SepaEdit:
                return this.renderSepaDisplay();
            default:
                return this.renderSepaForm();
        }
    }

}

export default injectStripe(SepaSelection);

SepaSelection.propTypes = {
    billingData: PropTypes.object
};

SepaSelection.defaultProps = {
    billingData: null
};
