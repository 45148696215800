import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ArchivedBadge from '../../../Common/Badge/ArchivedBadge';
import ContentReveal from '../../../Common/ContentReveal';
import NotAvailablePlaceholder from '../../../Common/NotAvailablePlaceholder';
import {
    showCladdings,
    showConstructionType,
    showEquippedSurfaces,
    showLoadClass,
    showPlannedWorks,
    showSpecialFeatures,
    showStabilityAndLoadSafety,
    showSupportType,
    showWallClearance,
    showWidthClass
} from '../../../Sections/helpers';
import { SectionPurpose } from '../../../Sections/SectionPurpose';
import ConstructionPlanModelModeIcon from '../../ConstructionPlanModelModeIcon';
import { ConstructionPlanModelMode } from '../../properties';
import { getData } from '../../utils';
import ConstructionPlanFormModelEditHint from '../Common/ConstructionPlanFormModelEditHint';
import { ConstructionPlanFormSectionPropType } from '../propTypes';
import ConstructionPlanFormSectionActions from './ConstructionPlanFormSectionActions';
import ConstructionPlanFormSectionDeleteModal from './ConstructionPlanFormSectionDeleteModal';
import ConstructionPlanFormSectionPlaceholder from './ConstructionPlanFormSectionPlaceholder';
import ConstructionPlanSectionFormModal from './Modal/ConstructionPlanSectionFormModal';

class ConstructionPlanFormSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFormModal: false,
            showDeleteModal: false
        };

        this.onEdit = this.onEdit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onReferenceAll = this.onReferenceAll.bind(this);
        this.delete = this.delete.bind(this);

        this.closeFormModal = this.closeFormModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.onDelete = this.onDelete.bind(this);

        this.onChangeSections = this.onChangeSections.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.section
            && !prevProps.section.mode
            && this.props.section
            && this.props.section.mode
        ) {
            this.setState({
                showFormModal: true
            });
        }
    }

    getName() {
        return this.getData('name');
    }

    getDescription() {
        return this.getData('description', '');
    }

    getEquippedSurfaces() {
        const equippedSurfaces = this.getData('equippedSurfaces', null);
        return showEquippedSurfaces(equippedSurfaces);
    }

    getPlannedWorks() {
        const plannedWorks = this.getData('plannedWorks', null);
        return showPlannedWorks(plannedWorks);
    }

    getPurpose() {
        const purposes = this.getData('purposes', null);
        const otherProtectivePurpose = this.getData('otherProtectivePurpose', null);
        return <SectionPurpose purposes={purposes} otherProtectivePurpose={otherProtectivePurpose} mode="inline" />;
    }

    getSupportType() {
        const supportType = this.getData('supportType', null);
        return showSupportType(supportType, null);
    }

    getConstructionType() {
        const constructionType = this.getData('constructionType', null);
        return showConstructionType(constructionType, null);
    }

    getStabilityAndLoadSafety() {
        const specialConstruction = this.getData('specialConstruction');
        const staticsCertificateNecessary = this.getData('staticsCertificateNecessary');
        return showStabilityAndLoadSafety({specialConstruction, staticsCertificateNecessary}, null);
    }

    getWidthClass() {
        const widthClass = this.getData('widthClass', null);
        return showWidthClass(widthClass);
    }

    getLoadClass() {
        const loadClass = this.getData('loadClass', null);
        return showLoadClass(loadClass);
    }

    getWallClearance() {
        const wallClearance = this.getData('wallClearance', null);
        return showWallClearance(wallClearance);
    }

    getCladdings() {
        const claddings = this.getData('claddings', null);
        return showCladdings(claddings);
    }

    getSpecialFeatures() {
        const specialFeatures = this.getData('specialFeatures', null);
        return showSpecialFeatures(specialFeatures);
    }

    getCustomer() {
        return this.getData('customer');
    }

    getData(fieldName, fallback = <NotAvailablePlaceholder />) {
        const { section, referenceData } = this.props;
        return getData(section, referenceData, fieldName, fallback);
    }

    onEdit() {
        if (!this.props.section.mode) {
            return;
        }

        this.setState({
            showFormModal: true
        });
    }

    onChange(updatedSection) {
        this.props.onChange(updatedSection, this.props.section);
        this.closeFormModal();
    }

    onReferenceAll(sectionsToReference, editedSection) {
        this.props.onReferenceAll(sectionsToReference, editedSection);
        this.closeFormModal();
    }

    delete() {
        this.setState({
            showDeleteModal: false
        }, () => {
            this.props.onDelete(this.props.section);
        });
    }

    onDelete() {
        // If section is a dummy we delete it directly
        if (!this.props.section.mode) {
            this.delete();
            return;
        }

        this.setState({
            showDeleteModal: true
        });
    }

    closeFormModal() {
        this.setState({
            showFormModal: false
        });
    }

    closeDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    onChangeSections(updatedSections) {
        const updatedSection = {
            ...this.props.section,
            sections: updatedSections
        };

        this.props.onChange([updatedSection]);
    }

    setModeAndEdit(modelMode) {
        const updatedSection = {
            ...this.props.section,
            mode: modelMode
        };

        this.props.onChange(updatedSection);
    }

    renderPlaceholder() {
        const { sectionsLeftToReference } = this.props;
        return (
            <ConstructionPlanFormSectionPlaceholder
                onAddNew={() => this.setModeAndEdit(ConstructionPlanModelMode.New)}
                onAddExisting={() => this.setModeAndEdit(ConstructionPlanModelMode.Reference)}
                sectionsLeftToReference={sectionsLeftToReference}
            />
        );
    }

    renderDetails() {
        const description = this.getDescription();
        const equippedSurfaces = this.getEquippedSurfaces();
        const plannedWorks = this.getPlannedWorks();
        const purpose = this.getPurpose();
        const supportType = this.getSupportType();
        const constructionType = this.getConstructionType();
        const stabilityAndLoadSafety = this.getStabilityAndLoadSafety();
        const widthClass = this.getWidthClass();
        const loadClass = this.getLoadClass();
        const wallClearance = this.getWallClearance();
        const claddings = this.getCladdings();
        const specialFeatures = this.getSpecialFeatures();
        const customer = this.getCustomer();

        const { section: { mode }, parentIsArchived } = this.props;
        const contentIsRevealed = parentIsArchived && mode === ConstructionPlanModelMode.New;

        return (
            <ContentReveal
                contentIsRevealed={contentIsRevealed}
                intro={
                    <Fragment>
                        {mode === ConstructionPlanModelMode.Edit &&
                        <ConstructionPlanFormModelEditHint />}

                        <div className="construction-plan__section-data">
                            <strong>Eingerüstete Flächen:</strong> {equippedSurfaces}
                        </div>

                        <div className="construction-plan__section-data">
                            <strong>Verwendungszweck:</strong> {purpose}
                        </div>

                        <div className="construction-plan__section-data">
                            <strong>Geplante Arbeiten:</strong> {plannedWorks}
                        </div>
                    </Fragment>
                }
            >
                {customer &&
                <div className="construction-plan__section-data">
                    <strong>Auftraggeber:</strong> {customer}
                </div>
                }

                {supportType &&
                <div className="construction-plan__section-data">
                    <strong>Tragsystem:</strong> {supportType}
                </div>
                }

                {constructionType &&
                <div className="construction-plan__section-data">
                    <strong>Ausführungsart:</strong> {constructionType}
                </div>
                }

                {stabilityAndLoadSafety &&
                <div className="construction-plan__section-data">
                    <strong>Stand- und Tragsicherheit:</strong> {stabilityAndLoadSafety}
                </div>
                }

                {loadClass &&
                <div className="construction-plan__section-data">
                    <strong>Lastklasse:</strong> {loadClass}
                </div>
                }

                {wallClearance &&
                <div className="construction-plan__section-data">
                    <strong>Wandabstand:</strong> {wallClearance}
                </div>
                }

                {widthClass &&
                <div className="construction-plan__section-data">
                    <strong>Breitenklasse:</strong> {widthClass}
                </div>
                }

                {claddings &&
                <div className="construction-plan__section-data">
                    <strong>Gerüstverkleidungen:</strong> {claddings}
                </div>
                }

                {specialFeatures &&
                <div className="construction-plan__section-data">
                    <strong>Besonderheiten:</strong> {specialFeatures}
                </div>
                }

                {description &&
                <div className="construction-plan__section-data">
                    <strong>Notizen:</strong> {description}
                </div>
                }

            </ContentReveal>
        );
    }

    render() {
        const { section, scaffoldingId, referencedSectionIds, referenceData, parentIsArchived } = this.props;
        const { showFormModal, showDeleteModal } = this.state;

        const {
            mode
        } = section;

        const name = this.getName();

        const wrapperClasses = ['constructionPlannerSectionSelection'];

        let isArchived = false;

        // mode is "edit" or "reference"
        if (referenceData) {
            isArchived = referenceData.archived;
        } else if (parentIsArchived && mode === ConstructionPlanModelMode.New) {
            wrapperClasses.push('constructionPlannerSectionSelection--archived');
        }

        return (
            <div className={wrapperClasses.join(' ')}>
                <div className="constructionPlannerSectionSelection__hd">
                    <div className="constructionPlannerSectionSelection__title">
                    <span className="constructionPlannerSectionSelection__icon">
                        <ConstructionPlanModelModeIcon mode={mode} />
                    </span>
                        Gerüstabschnitt
                        {name &&
                        <span className="constructionPlannerSectionSelection__section-name"
                        >{name}</span>
                        }
                        {isArchived && <ArchivedBadge size="small" />}
                    </div>

                    {
                        (!isArchived && !parentIsArchived) &&
                        <ConstructionPlanFormSectionActions
                            onEdit={!mode ? undefined : this.onEdit}
                            onDelete={this.onDelete}
                        />
                    }
                </div>

                {!mode
                    ? this.renderPlaceholder()
                    : this.renderDetails()
                }

                {showFormModal &&
                <ConstructionPlanSectionFormModal
                    scaffoldingId={scaffoldingId}
                    onChange={this.onChange}
                    onReferenceAllSections={this.onReferenceAll}
                    onClose={this.closeFormModal}
                    section={section}
                    referencedSectionIds={referencedSectionIds}
                />
                }

                {showDeleteModal &&
                <ConstructionPlanFormSectionDeleteModal
                    onConfirm={this.delete}
                    onCancel={this.closeDeleteModal}
                />
                }
            </div>
        );
    }
}

ConstructionPlanFormSection.propTypes = {
    section: ConstructionPlanFormSectionPropType.isRequired,
    scaffoldingId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    referencedSectionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    onReferenceAllSections: PropTypes.func.isRequired,
    referenceData: PropTypes.object,
    hasSectionsLeftToReference: PropTypes.bool,
    showFormModal: PropTypes.bool,
    parentIsArchived: PropTypes.bool
};

ConstructionPlanFormSection.defaultProps = {
    referencedSectionIds: [],
    referenceData: null,
    hasSectionsLeftToReference: false,
    showFormModal: false,
    parentIsArchived: false
};

export default ConstructionPlanFormSection;
