import PropTypes from 'prop-types';
import React from 'react';
import { getMediaCollectionName } from '../../../Media/utils';
import MediaGroupListItem from './ConstructionPlanMediaGroupListItem';

const ConstructionPlanFileGroup = ({ groupKey, mediaList }) => {

    const groupListHeading = getMediaCollectionName(groupKey);

    return (
        <div className="constructionPlannerMediaGroup__list">
            <div className="constructionPlannerMediaGroup__hd">
                {groupListHeading}
            </div>
            <div className="constructionPlannerMediaGroup__listitems">
                {mediaList.map(file =>
                    <MediaGroupListItem
                        file={file}
                        key={file.id}
                    />
                )}
            </div>
        </div>
    );
};

ConstructionPlanFileGroup.propTypes = {
    groupKey: PropTypes.string.isRequired,
    mediaList: PropTypes.array.isRequired
};

export default ConstructionPlanFileGroup;
