import PropTypes from 'prop-types';
import React from 'react';

const ConstructionPlanSyncSummaryField = ({ label, children, isSub, isIdentical, isNew }) => {
    const _label = `${isSub ? '– ' : ''}${label}`;
    return (
        <div className={`sync-summary__field ${isIdentical ? 'has-no-change' : ''}`}>
            <div className="sync-summary__field-label-row">
                <div className="sync-summary__field-label sync-summary__field-label--l">
                    {_label}
                </div>
                <div className="sync-summary__field-label sync-summary__field-label--r">
                    {_label} {!isNew && !isIdentical ? <span className="new-badge">Neu</span> : null}
                </div>
            </div>
            <div className="sync-summary__field-value">
                {children}
            </div>
        </div>
    );
};

ConstructionPlanSyncSummaryField.propTypes = {
    label: PropTypes.string.isRequired,
    liveSite: PropTypes.object,
    isIdentical: PropTypes.bool,
    isSub: PropTypes.bool
};

export default ConstructionPlanSyncSummaryField;
