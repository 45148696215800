import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ConstructionPlanFormModal from '../Form/ConstructionPlanFormModal';
import JobConstructionPlanDetails from './ConstructionPlanDetails';
import ConstructionPlanEmptyPlaceholder from './ConstructionPlanEmptyPlaceholder';

class ConstructionPlan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            constructionPlanFormModalIsOpen: props.constructionPlanFormModalIsOpen
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.constructionPlanFormModalIsOpen !== prevProps.constructionPlanFormModalIsOpen) {
            this.setState({
                constructionPlanFormModalIsOpen: this.props.constructionPlanFormModalIsOpen
            });
        }
    }

    toggleConstructionPlanFormModal() {
        this.setState(prevState => {
            return {
                constructionPlanFormModalIsOpen: !prevState.constructionPlanFormModalIsOpen
            };
        });
    }

    render() {
        const { constructionPlanFormModalIsOpen } = this.state;
        const { onConstructionPlanUpdate, onConstructionPlanSynchronization, jobId, constructionPlan, jobLabel, isArchived } = this.props;

        if (isArchived && !constructionPlan.site) {
            return null;
        }

        return (
            <Fragment>
                {
                    (constructionPlan && constructionPlan.site) ?
                        <JobConstructionPlanDetails
                            constructionPlan={constructionPlan}
                            onEdit={() => this.toggleConstructionPlanFormModal()}
                            onDelete={onConstructionPlanUpdate}
                            onConstructionPlanSynchronization={onConstructionPlanSynchronization}
                            jobId={jobId}
                            jobLabel={jobLabel}
                            editingDisabled={isArchived}
                        />
                        :
                        <ConstructionPlanEmptyPlaceholder
                            onCreateConstructionPlan={() => this.toggleConstructionPlanFormModal()}
                        />
                }
                {
                    constructionPlanFormModalIsOpen &&
                    <ConstructionPlanFormModal
                        jobId={jobId}
                        onUpdate={(newConstructionPlan) => {
                            this.toggleConstructionPlanFormModal();
                            onConstructionPlanUpdate(newConstructionPlan);
                        }}
                        onClose={() => this.toggleConstructionPlanFormModal()}
                    />
                }
            </Fragment>
        );
    }
}

ConstructionPlan.propTypes = {
    jobId: PropTypes.string.isRequired,
    onConstructionPlanUpdate: PropTypes.func.isRequired,
    onConstructionPlanSynchronization: PropTypes.func.isRequired,
    constructionPlan: PropTypes.object,
    constructionPlanFormModalIsOpen: PropTypes.bool
};


ConstructionPlan.defaultProps = {
    constructionPlan: {},
    constructionPlanFormModalIsOpen: false
};

export default ConstructionPlan;
