import PropTypes from 'prop-types';
import React from 'react';
import { DiffValue } from './Value/DiffValue';
import { DiffItemPropType } from './properties';

export const RenderMode = {
    Default: 'default',
    Table: 'table'
};

const DefaultWrapper = ({ isReference, children }) => (
    <div className={`diff-item ${isReference ? 'diff-item--ref' : ''}`}>
        {children}
    </div>
);

const TableWrapper = ({ isReference, children }) => (
    <tr className={`diff-item diff-item--row ${isReference ? 'diff-item--ref' : ''}`}>
        <td colSpan={2}>
            {children}
        </td>
    </tr>
);

const DiffItem = ({ diffItem, renderValue, renderMode, isReference }) => {

    let newValue = diffItem.newVal;
    const oldValue = diffItem.oldVal;

    if (isReference) {
        newValue = oldValue;
    }

    const Wrapper = renderMode === RenderMode.Table ? TableWrapper : DefaultWrapper;

    return (
        <Wrapper isReference={isReference}>
            <div className="diff-item__label">
                {diffItem.label}
            </div>
            <div className="diff-item__data">
                <div className="diff-item__data-new">
                    {renderValue(newValue, diffItem)}
                </div>
                <div className="diff-item__data-old">
                    {renderValue(oldValue, diffItem)}
                </div>
            </div>
        </Wrapper>
    )
};

DiffItem.propTypes = {
    diffItem: DiffItemPropType.isRequired,
    renderMode: PropTypes.oneOf([RenderMode.Table, RenderMode.Default]),
    renderValue: PropTypes.func,
    isReference: PropTypes.bool
};

DiffItem.defaultProps = {
    renderValue: (value, diff) => <DiffValue value={value} diff={diff} />,
    renderMode: RenderMode.Default,
    isReference: false
};

export default DiffItem;
