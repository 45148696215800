import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showRequestError, showTempError } from '../../redux/modules/error/action';
import { deleteJob } from '../../redux/modules/job/actions';
import DeleteModal from '../Common/Modal/DeleteModal';
import { JobType } from './properties';
import ErrorService from '../../Services/ErrorService';
import GraphQLError, { GraphQlErrorCodes } from '../../Services/GraphQLError';

class DeleteJobLink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showConfirmationModal: false,
            isDeleting: false
        };

        this.renderConfirmationModal = this.renderConfirmationModal.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.deleteJob = this.deleteJob.bind(this);
    }

    deleteJob() {

        const { id, name, type, onDeleted } = this.props;

        const {
            actions: { deleteJob }
        } = this.props;

        this.setState({
            isDeleting: true
        });

        const typeLabel = type === JobType.Job ? 'Auftrag' : 'Anfrage';

        deleteJob(id)
            .then(() => {
                toast.success(`${typeLabel} '${name}' wurde gelöscht`, { autoClose: 2500 });
                return true;
            })
            .catch((error) => {
                return this.handleError(error);
            })
            .then((success) => {
                this.setState({
                    isDeleting: false
                });

                if ( success ) {
                    this.toggleConfirmationModal();
                    onDeleted();
                }
            });
    }

    handleError(error) {
        const { type, name } = this.props;
        const isJob = () => type === JobType.Job;
        const typeLabel = type === isJob() ? 'Auftrag' : 'Anfrage';

        if ( error instanceof GraphQLError ) {
            if ( error.is(GraphQlErrorCodes.JobLockedByConstructionPlan) ) {
                return ErrorService.handleGraphQLError(error, isJob() ? 'jobLocked' : 'requestLocked')
                                   .then(({ error }) => (showTempError(error)));
            }
        }

        showRequestError(`${typeLabel} '${name}' konnte nicht gelöscht werden`, error);
        return false;
    }

    toggleConfirmationModal() {
        this.setState(prevState => ({
            showConfirmationModal: !prevState.showConfirmationModal
        }));
    }

    renderConfirmationModal() {

        const {
            name, disabled, type, archived
        } = this.props;

        if ( !this.state.showConfirmationModal || disabled ) {
            return null;
        }

        const typeLabel = type === JobType.Job ? 'Auftrag' : 'Anfrage';
        const textLabel = archived ? (type === JobType.Job ? `den archivierten ${typeLabel} ` : `die archivierte ${typeLabel} `) : (JobType.Job === type ? `den aktiven ${typeLabel}` : `die aktive ${typeLabel}`);

        return (
            <DeleteModal
                modalTitle={`${typeLabel} löschen`}
                deleteEntity={this.deleteJob}
                closeModal={this.toggleConfirmationModal}
                isDeletingEntity={this.state.isDeleting}
            >
                <p>Sie sind im Begriff, {textLabel} <a
                    onClick={this.closeModal}
                    className="btn--link-neutral"
                    role="button"
                >{name}</a> zu löschen.
                </p>
                <p>Möchten Sie fortfahren?</p>
            </DeleteModal>
        );
    }

    renderLink() {
        const {
            link,
            label
        } = this.props;

        let { disabled } = this.props;

        disabled = disabled && this.state.isDeleting;

        if ( link && typeof link === 'function' ) {
            return link(disabled, this.toggleConfirmationModal);
        }

        return (
            <a role="button" onClick={this.toggleConfirmationModal}>{label}</a>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLink()}
                {this.renderConfirmationModal()}
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        deleteJob
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(DeleteJobLink);

DeleteJobLink.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onDeleted: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    link: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string
};

DeleteJobLink.defaultProps = {
    disabled: false,
    link: null,
    label: null,
    type: undefined
};
