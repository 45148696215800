import PropTypes from 'prop-types';
import React from 'react';
import NotAvailablePlaceholder from './NotAvailablePlaceholder';

const ContactPerson = ({ contactPerson }) => {

    if (!contactPerson) {
        return <NotAvailablePlaceholder />;
    }

    const { name } = contactPerson;

    return <div>{name}</div>;

};

ContactPerson.propTypes = {
    contactPerson: PropTypes.object.isRequired
};


export default ContactPerson;
