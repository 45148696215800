import React from 'react';
import { DiffValueFallback } from './DiffValue';

export const MediaDiffValueItem = ({ mediaItem, diff }) => {
    if (!mediaItem) {
        return DiffValueFallback;
    }

    const { name } = mediaItem;

    const isNew = mediaItem === diff.newVal;
    const other = isNew ? diff.oldVal : diff.newVal;
    const nameChanged = other && other.name !== name;

    return (
        <div className="diff-media-item">
            <div className="diff-media-item__name">
                {name}
                {isNew && nameChanged &&
                <div className="diff-media-item__name-hint">
                    (Angepasster Name)
                </div>
                }
            </div>
        </div>
    );
}

