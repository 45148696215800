import PropTypes from 'prop-types';
import React from 'react';
import { ModelType } from '../../../properties';
import MediaUploadModal from '../../Forms/Media/Modal/MediaFormModal';
import { MediaFormDataPropType, ModelMediaType } from '../../Media/properties';

export default function JobCorrespondenceUploadModal({ onUploaded, onSaved, onClose, correspondenceMediaItem }) {
    return (
        <MediaUploadModal
            onUploaded={onUploaded}
            modelType={ModelType.Job}
            modelMediaTypes={[ModelMediaType.Job_Correspondence]}
            onClose={onClose}
            title={(edit) => {
                return edit ? 'Korrespondenz bearbeiten' : 'Korrespondenz hochladen';
            }}
            media={correspondenceMediaItem}
            onSaved={onSaved}
            isCorrespondence
        />
    );
}

JobCorrespondenceUploadModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSaved: PropTypes.func,
    onUploaded: PropTypes.func,
    correspondenceMediaItem: MediaFormDataPropType
};
