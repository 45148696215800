import PropTypes from 'prop-types';
import React from 'react';

const CreditCardInfo = ({
    cardOwner, cardBrand, cardLast4, cardExpMonth, cardExpYear, ...componentProps
}) => {
    const cardClasses = ['creditCardInfo__brand pf'];

    switch (cardBrand) {
        case 'Visa':
            cardClasses.push('pf-visa');
            break;
        case 'MasterCard':
            cardClasses.push('pf-mastercard');
            break;
        case 'American Express':
            cardClasses.push('pf-american-express');
            break;
        case 'Discover':
            cardClasses.push('pf-discover');
            break;
        case 'Diners':
            cardClasses.push('pf-diners');
            break;
        case 'Jcb':
            cardClasses.push('pf-jcb');
            break;
        case 'Unknown':
            cardClasses.push('pf-credit-card');
            break;
        default:
            cardClasses.push('pf-credit-card');
    }

    return (
        <div className="creditCardInfo" {...componentProps}>
            { cardOwner && <div className="creditCardInfo__owner">{cardOwner}</div> }
            <span className={cardClasses.join(' ')} />
            <span className="creditCardInfo__number"> **** **** **** {cardLast4}</span>
            <span
                className="creditCardInfo__expiration-month-year"
            >Ablaufdatum: {cardExpMonth} / {cardExpYear}
            </span>
        </div>
    );
};


export default CreditCardInfo;

CreditCardInfo.propTypes = {
    cardOwner: PropTypes.string,
    cardBrand: PropTypes.string.isRequired,
    cardLast4: PropTypes.string.isRequired,
    cardExpMonth: PropTypes.string.isRequired,
    cardExpYear: PropTypes.string.isRequired
};

CreditCardInfo.defaultProps = {
    cardOwner: ''
};
