import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../Common/Icon';
import LoadingSpinner from '../Page/LoadingSpinner';

const MODE_COLLAPSIBLE = 'mode_collapsible';
const MODE_STATIC = 'mode_static';

class Section extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillMount() {
        const { collapsible, isCollapsed } = this.props;

        if (collapsible) {
            this.setState({
                mode: MODE_COLLAPSIBLE,
                isCollapsed: isCollapsed
            });

            return;
        }

        this.setState({
            mode: MODE_STATIC
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isCollapsed !== prevProps.isCollapsed) {
            this.setState({
                isCollapsed: this.props.isCollapsed
            });
        }
    }

    getToggleTitle() {
        const { collapsible, title } = this.props;
        if (!collapsible) {
            return null;
        }

        return `${title} ${this.state.isCollapsed ? 'aufklappen' : 'zuklappen'}`;
    }

    toggleCollapse() {

        const { mode } = this.state;

        if (mode === MODE_COLLAPSIBLE) {
            this.setState(prevState => ({
                isCollapsed: !prevState.isCollapsed
            }));
        }
    }


    render() {

        const { classes, title, type, id, subline, cta, children, collapsible, isBusy, busyLabel } = this.props;

        const { isCollapsed, mode } = this.state;

        const classNames = ['section'];

        if (classes) {
            classes.split(',')
                .forEach(name => classNames.push(name));
        }

        if (type === 'list') {
            classNames.push('section--list');
        }

        if (mode === MODE_COLLAPSIBLE) {
            classNames.push('section--collapsible');

            if (isCollapsed) {
                classNames.push('section--isCollapsed');
            }
        }

        if (isBusy) {
            classNames.push('section--busy');
        }

        return (
            <section style={{ ...this.props.style }} className={classNames.join(' ')}>
                {id && <div className="section__anchor" id={id} />}
                {title &&
                <header
                    className="section__hd"
                    onClick={() => this.toggleCollapse()}
                    role={collapsible ? 'button' : null}
                    title={this.getToggleTitle()}
                >
                    <h2 className="section__ttl">
                        {title}
                        {subline &&
                        <span className="section__subline">{subline}</span>}
                    </h2>
                    <div className="section__actions">
                        {cta}
                    </div>
                    {mode === MODE_COLLAPSIBLE &&
                    <span className="section-collapse__icon">
                        {!isCollapsed
                            ? <Icon name="chevronUp" />
                            : <Icon name="chevronDown" />
                        }
                    </span>}
                </header>
                }
                <div className="section__cnt">
                    {children}
                </div>
                {/* Busy overlay */}
                {isBusy &&
                <div className="section__busy-overlay">
                    <LoadingSpinner label={busyLabel} />
                </div>
                }
            </section>
        );
    }
}

export default Section;

Section.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    cta: PropTypes.element,
    type: PropTypes.string,
    id: PropTypes.string,
    collapsible: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    style: PropTypes.object,
    isBusy: PropTypes.bool,
    busyLabel: PropTypes.string
};

Section.defaultProps = {
    cta: null,
    type: '',
    title: null,
    id: '',
    collapsible: false,
    isCollapsed: false,
    style: {},
    isBusy: false,
    busyLabel: 'Aktualisiere Daten'
};
