export default class AddressDTO {
    constructor() {
        this.name = '';
        this.line1 = '';
        this.line2 = '';
        this.city = '';
        this.zip= '';
        this.country = 'DE'
    }
}
