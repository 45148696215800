import React from 'react';

export const AppChangeLogSlide = ({ title, image, subTitle, children }) => (
    <div className="changelog-slide">
        <h2 className="changelog-slide__ttl">{title}</h2>
        <div className="changelog-slide__img">{image}</div>
        <div className="changelog-slide__sub-ttl">{subTitle}</div>
        <div className="changelog-slide__bd">
            {children}
        </div>
    </div>
)
