import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import DeleteModal from '../../../Components/Common/Modal/DeleteModal';
import Icon from '../../../Components/Common/Icon';
import IfUser from '../../../Components/Common/IfUser';
import ConstructionDiaryEntryBreadcrumb
    from '../../../Components/ConstructionDiary/ConstructionDiaryEntryBreadcrumb';
import ConstructionDiaryDetail
    from '../../../Components/ConstructionDiary/ConstructionDiaryEntryDetail';
import DiaryEntryModal from '../../../Components/ConstructionDiary/ConstructionDiaryEntryModal';
import { DiaryCategories } from '../../../Components/ConstructionDiary/properties';
import DetailPageHeader from '../../../Components/DetailPageNavigation/DetailPageHeader';
import { PageLoadingSpinner } from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import InspectionModal from '../../../Components/Sections/Detail/InspectionModal';
import Permission from '../../../permissions';
import {
    deleteSingleDiaryEntry,
    fetchDiaryEntryDetailData,
} from '../../../redux/modules/constructiondiary/action';
import { showApiError, showRequestError } from '../../../redux/modules/error/action';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const siteNavigationLinks = [
    {
        id: 'generalInformation',
        label: 'Allgemeine Angaben',
    },
    {
        id: 'diaryEntry',
        label: 'Eintrag',
    },
    {
        id: 'time',
        label: 'Zeitaufwand',
    },
    {
        id: 'team',
        label: 'Anwesende Kolonne',
    },
];

const getInitialState = () => ({
    loading: true,
    initialized: false,
    isFetching: true,
    diaryEntry: null,
    isDeletingEntry: false,
    showEditModal: false,
    showDeleteModal: false,
    showInspectionModal: false,
    pageHeaderCollapsed: false,
});

class ConstructionDiaryEntryPage extends Component {

    constructor(props) {
        super(props);
        this.state = getInitialState();
        this.loadingGotCancelled = false;
        this.onPageHeaderCollapse = this.onPageHeaderCollapse.bind(this);
        this.toggleEditModal = this.toggleEditModal.bind(this);
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
        this.toggleInspectionModal = this.toggleInspectionModal.bind(this);
    }

    toggleEditModal() {
        this.setState({
            showEditModal: !this.state.showEditModal,
        });
    }

    toggleDeleteModal() {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal,
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        const {match} = this.props;
        if (prevState.diaryEntry && prevState.diaryEntry.id !== match.params.id) {
            this.setState({
                diaryEntry: null,
                initialized: false,
            }, () => this.initialize(match.params.id));
        }
    }

    deleteEntry() {
        const {
            actions: {deleteSingleDiaryEntry},
            history,
        } = this.props;

        const {diaryEntry: {id: diaryEntryId}} = this.state;

        this.setState({
            isDeletingEntry: true,
        });

        deleteSingleDiaryEntry(diaryEntryId)
            .then(() => {
                this.toggleDeleteModal();
                toast.success('Eintrag wurde gelöscht', {autoClose: 2500});
                history.push('/diary');
            })
            .catch((error) => {
                return showApiError(error);
            })
            .finally(() => {
                this.setState({
                    isDeletingEntry: false,
                });
            });
    }

    initialize(diaryEntryId) {

        const {actions: {fetchDiaryEntryDetailData}} = this.props;

        this.setState({
            loading: true,
        });

        fetchDiaryEntryDetailData(diaryEntryId)
            .then(diaryEntry => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    diaryEntry,
                    isAcceptanceEntry: diaryEntry.category === DiaryCategories.Acceptance,
                });
            })
            .catch(err => {
                showRequestError('Bautagebuch-Eintrag konnte nicht geladen werden');
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    loading: false,
                    initialized: true,
                });
            });
    }

    toggleInspectionModal() {
        this.setState(prevState => ({
            showInspectionModal: !prevState.showInspectionModal,
        }));
    }

    componentWillMount(): void {
        const {match} = this.props;

        const diaryEntryId = match.params.id;
        if (!diaryEntryId) {
            this.setState({
                initialized: false,
            });
            return;
        }

        this.initialize(diaryEntryId);
    }

    onUpdated() {
        this.toggleEditModal();

        const {match} = this.props;

        const diaryEntryId = match.params.id;
        if (!diaryEntryId) {
            return;
        }

        this.initialize(diaryEntryId);
    }

    renderPageContent() {

        const {
            diaryEntry,
            loading,
            showEditModal,
            showDeleteModal,
            isDeletingEntry,
            isAcceptanceEntry,
            showInspectionModal,
        } = this.state;

        return (
            <Fragment>
                <ConstructionDiaryDetail
                    entry={diaryEntry}
                    toggleEditModal={this.toggleEditModal}
                    toggleDeleteModal={this.toggleDeleteModal}
                    toggleInspectionModal={this.toggleInspectionModal}
                    onUpdate={this.onUpdated}
                    isFetching={loading}
                    isAcceptanceEntry={isAcceptanceEntry}
                    isDeletingEntry={isDeletingEntry}
                />
                {
                    showEditModal &&
                    <DiaryEntryModal
                        mode="edit"
                        id={diaryEntry.id}
                        onClose={this.toggleEditModal}
                        onUpdated={this.onUpdated}
                    />
                }
                {
                    showDeleteModal &&
                    <DeleteModal
                        modalTitle="Eintrag löschen"
                        deleteEntity={this.deleteEntry}
                        closeModal={this.toggleDeleteModal}
                        isDeletingEntity={isDeletingEntry}
                    >
                        <p>
                            Sie sind im Begriff, den derzeit ausgewählten Bautagebucheintrag zu
                            löschen. Damit verlieren Sie sämtliche Daten des Eintrages.
                        </p>
                        <p>Möchten Sie trotzdem fortfahren?</p>
                    </DeleteModal>
                }

                {
                    showInspectionModal &&
                    <InspectionModal
                        closeModal={this.toggleInspectionModal}
                        inspectionId={diaryEntry.reference && diaryEntry.reference.id}
                    />
                }
            </Fragment>
        );
    }

    onPageHeaderCollapse(collapsed) {
        this.setState({
            pageHeaderCollapsed: collapsed,
        });
    }

    render() {
        const {initialized, loading, diaryEntry, isAcceptanceEntry, pageHeaderCollapsed, isFetching} = this.state;

        const pageTitle = !isFetching ? 'Lade Bautagebucheintrag' : 'Bautagebuch-Eintrag';

        const actions = !initialized ? null : (
            <IfUser
                can={['update-construction-diary-entries']}
                and={!isAcceptanceEntry}
            >
                <button
                    onClick={this.toggleDeleteModal}
                    className="btn btn--backward-action btn--icon"
                    title="Bautagebucheintrag löschen"
                >
                    <span className="btn__icon">
                        <Icon name="delete"/>
                    </span>
                </button>

                <button
                    onClick={this.toggleEditModal}
                    className="btn btn--neutral-action btn--icon"
                    title="Nachträglich bearbeiten"
                >
                <span className="btn__icon">
                    <Icon name="edit"/>
                </span>
                </button>
            </IfUser>
        );

        return (
            <PrivatePage requiredUserPermission={Permission.ReadConstructionDiaryEntries}>
                <Container>
                    <TopNav
                        hasNewPageHead={!!(initialized && diaryEntry)}
                        hide={pageHeaderCollapsed}
                    />

                    <ConstructionDiaryEntryBreadcrumb
                        diaryEntry={diaryEntry}
                        collapse={pageHeaderCollapsed}
                    />

                    <DetailPageHeader
                        type="diaryEntry"
                        title={pageTitle}
                        navigationalLinks={siteNavigationLinks}
                        actions={actions}
                        onCollapse={this.onPageHeaderCollapse}
                        isLoading={loading}
                    />


                    {loading && <PageLoadingSpinner/>}

                    {!loading && this.renderPageContent()}

                </Container>
            </PrivatePage>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchDiaryEntryDetailData,
        deleteSingleDiaryEntry,
    }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ConstructionDiaryEntryPage));



