import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Toast as toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { getPublicDownloadUrl } from '../../redux/modules/media/action';
import { autoFormatFileSize } from '../../utils';
import Icon from './Icon';
import LightBoxLink from './LightBoxLink';

class DownloadLinkInline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            requesting: false
        };
        this.download = this.download.bind(this);
    }

    download(id) {
        this.setState({ requesting: true });
        this.props.actions.getPublicDownloadUrl(id)
            .then(url => window.open(url, '_blank'))
            .catch(() => {
                toast.error('Download konnte nicht gestartet werden');
            })
            .finally(() => {
                this.setState({ requesting: false });
            });
    }

    renderLink() {
        const { requesting } = this.state;
        const { attachment, showFileSize } = this.props;

        const name = attachment.name ? attachment.name : attachment.fileName;

        return (
            <Fragment>
                <div className="download-link__preview">
                    <LightBoxLink showIcon={false} media={attachment}>
                        <div className="download-link__icn"><Icon name="document"> </Icon></div>
                        <span className="download-link__name">
                            {name}
                        </span>
                    </LightBoxLink>
                    {showFileSize && <span className="download-link__filesize">
                        {autoFormatFileSize(attachment.size)}
                    </span>
                    }
                </div>
                <div className="download-link__meta">
                    <a
                        role="button"
                        className="download-link__link"
                        onClick={() => this.download(attachment.id)}
                    >
                        {requesting ? <i className="btn__spinner" /> : <Icon name="download" />}
                    </a>
                </div>
            </Fragment>
        );
    }

    render() {
        const { downloadLinkError } = this.state;
        const { attachment } = this.props;

        return (
            <div className="download-link" key={attachment.id}>
                <div className="download-link__ctn">
                    {this.renderLink()}
                    {downloadLinkError && <small>{downloadLinkError}</small>}
                </div>
            </div>
        );
    }
}

DownloadLinkInline.propTypes = {
    attachment: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    showFileSize: PropTypes.bool
};

DownloadLinkInline.defaultProps = {
    showFileSize: true
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        getPublicDownloadUrl
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(DownloadLinkInline);
