import PropTypes from 'prop-types';
import React from 'react';
import NotAvailablePlaceholder from './NotAvailablePlaceholder';

const DataRow = ({ label, isSubRow, isRelatedToPrevious, containerClass, type, children }) => {

    const layoutClasses = ['data-row'];
    let _label = label;

    if (containerClass) {
        layoutClasses.push(containerClass);
    }

    if (type) {
        layoutClasses.push(`data-row--${type}`);
    }

    if (isSubRow) {
        layoutClasses.push(`data-row--sub`);
        if (typeof (_label === 'string')) {
            _label = `– ${_label}`;
        }
    }

    if (isRelatedToPrevious) {
        layoutClasses.push(`data-row--related-to-previous`);
    }

    return (
        <div className={layoutClasses.join(' ')}>
            <div className="data-row__label">{_label}</div>
            <div className="data-row__content">
                {children || <NotAvailablePlaceholder />}
            </div>
        </div>
    );
};

DataRow.propTypes = {
    label: PropTypes.any.isRequired,
    children: PropTypes.node,
    containerClass: PropTypes.string,
    type: PropTypes.string,
    isSubRow: PropTypes.bool,
    isRelatedToPrevious: PropTypes.bool
};

DataRow.defaultProps = {
    children: null,
    containerClass: '',
    type: null,
    isSubRow: false,
    isRelatedToPrevious: false
};

export default DataRow;
