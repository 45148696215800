import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Breadcrumb from '../../Navigation/Breadcrumbs/Breadcrumb';
import {BreadcrumbLink} from '../../Navigation/Breadcrumbs/BreadcrumbLink';

const ScaffoldingBreadcrumb = ({scaffolding, collapse}) => {
    const query = scaffolding && scaffolding.archived ? 'archived=true' : '';
    const scaff = scaffolding || {};
    const site = scaff.site || {};

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label={'Baustellen'}
                    path="/sites"
                    icon="scaffold"
                    query={query}
                />
                <BreadcrumbLink
                    label={site.name || 'Lade Baustelle ...'}
                    path={site.id ? `/sites/${site.id}` : 'sites/:siteId'}
                />
                <BreadcrumbLink
                    label={scaff.name || 'Lade Gerüst ...'}
                    path={scaff.id ? `/scaffoldings/${scaff.id}` : null}
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default ScaffoldingBreadcrumb;

ScaffoldingBreadcrumb.propTypes = {
    scaffolding: PropTypes.object,
    collapse: PropTypes.bool,
};

ScaffoldingBreadcrumb.defaultProps = {
    scaffolding: null,
    collapse: false,
};
