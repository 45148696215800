import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Components/Common/Icon';

const InspectionResult = ({ errors }) => {
    const label = () => {
        if (errors > 1) {
            return 'Mängel';
        }
        return 'Mangel';
    };

    const showIcon = () => {
        if (!errors) {
            return <React.Fragment><span className="state-icon"><Icon name="stateok" /></span> O.K.</React.Fragment>;
        }

        return <React.Fragment> <span className="state-icon"><Icon name="statealert" /></span> {errors} {label()}</React.Fragment>;
    };

    const classNames = ['inspection-result'];

    if (errors > 0) {
        classNames.push('inspection-result--alert');
    } else {
        classNames.push('inspection-result--ok');
    }


    return (
        <div className={classNames.join(' ')}>
            <p className="inspection-result__lbl">{showIcon()}</p>
        </div>
    );
};

export default InspectionResult;

InspectionResult.propTypes = {
    errors: PropTypes.number.isRequired
};

