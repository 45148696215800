import React from 'react';
import { connect } from 'react-redux';
import { userAcceptedADVAgreement } from '../../redux/helper';
import ADVModal from './ADVModal';
import ModalGate from './ModalGate';

const STORAGE_KEY = 'adv_acceptance_hidden';

const ADVAcceptanceWarningBanner = ({ userAcceptedADVAgreement, ignoreStorage }) => (

    <ModalGate
        opensIf={!userAcceptedADVAgreement}
        storageKey={STORAGE_KEY}
        ignoreStorage={ignoreStorage}
    >
        {
            hideModal => (
                <ADVModal
                    closeModal={() => hideModal()}
                />
            )
        }
    </ModalGate>
);

const mapStateToProps = state => ({
    userAcceptedADVAgreement: userAcceptedADVAgreement(state)
});

export default connect(mapStateToProps, null)(ADVAcceptanceWarningBanner);

