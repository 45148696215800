import PropTypes from 'prop-types';
import React from 'react';
import { isIdenticalValue } from '../../../equals';
import EqualSyncOptionHeight from '../../EqualSyncOptionHeight';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceRow from '../SyncChoiceRow';
import { validateSingleChoiceComplete } from '../../../validation';
import SyncInputGroup from '../SyncInputGroup';

const validateText = validateSingleChoiceComplete();

const renderPlainValue = (value) => {
    if (!value) {
        return '-';
    }

    return (
        <span>{value}</span>
    );
};

const TextSyncChoice = ({ planValue, liveValue, value: currentFormValue, onChange, showIdentical, label, renderValue }) => {

    const isSelected = (value) => {
        return currentFormValue === value;
    };

    const leftValue = planValue;
    const rightValue = liveValue;
    const isIdentical = isIdenticalValue(leftValue, rightValue);

    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <EqualSyncOptionHeight>
            <SyncInputGroup label={label}>
                <SyncChoiceRow
                    leftValue={leftValue}
                    rightValue={rightValue}
                    onSelect={onChange}
                    isSelected={isSelected}
                    isIdentical={isIdentical}
                    showIdentical={showIdentical}
                    renderValue={renderValue}
                />
            </SyncInputGroup>
        </EqualSyncOptionHeight>
    );
};

TextSyncChoice.propTypes = {
    onChange: PropTypes.func.isRequired,
    planValue: PropTypes.string,
    liveValue: PropTypes.string,
    value: PropTypes.any,
    renderValue: PropTypes.func,
    showIdentical: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.func
};

TextSyncChoice.defaultProps = {
    onChange: () => false,
    showIdentical: false,
    renderValue: renderPlainValue,
    validate: null
};

export default asSyncChoiceField(TextSyncChoice, {
    allowNull: true,
    validate: validateText
});
