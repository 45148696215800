import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchSectionsSelectOptions } from '../../../../../redux/modules/section/action';
import OptionGroupSelect from '../../../../Forms/OptionGroupSelect';

class ConstructionPlanSectionSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            choices: []
        };

        this.onChange = this.onChange.bind(this);
        this.getSectionChoices = this.getSectionChoices.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (prevState.scaffoldingId !== nextProps.scaffoldingId) {
            return {
                scaffoldingId: nextProps.scaffoldingId,
                choices: [],
                isFetching: false,
                hasFetched: false
            };
        }

        // nothing changes
        return null;
    }

    componentDidMount() {
        this.loadOptions();
    }

    componentDidUpdate() {
        this.loadOptions();
    }

    getSectionChoices() {
        const { choices } = this.state;

        const options = [];

        choices.forEach((choice) => {

            const isExcluded = this.props.excludeSectionIds.filter(id => choice.value === id).length > 0;

            const option = {
                ...choice,
                disabled: isExcluded
            };

            options.push(option);
        });

        return options;
    }

    loadOptions() {

        if (this.state.hasFetched || this.state.isFetching || !this.props.scaffoldingId) {
            return;
        }

        this.setState({
            isFetching: true
        });

        this.props.actions.fetchSectionsSelectOptions(this.props.scaffoldingId)
            .then(({ sections }) => {

                const _sections = sections || [];
                const choices = _sections.map((section) => {
                    if (section.managed) {
                        return {
                            value: section.id,
                            label: section.name
                        };
                    }

                });
                this.setState({
                    choices
                });
            })
            .catch(() => {
                toast.error('Gerüstabschnitte konnten nicht geladen werden', { autoClose: 4000 });
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                    hasFetched: true
                });
            });
    }


    onChange(value) {

        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

    render() {
        const {
            isFetching
        } = this.state;

        return (
            <OptionGroupSelect
                name={this.props.name}
                placeholder={isFetching ? 'Lade Gerüstabschnitte ...' : 'Gerüstabschnitt wählen'}
                options={this.getSectionChoices()}
                disabled={isFetching}
                onChange={this.onChange}
            />
        );
    }
}

ConstructionPlanSectionSelect.propTypes = {
    name: PropTypes.string.isRequired,
    scaffoldingId: PropTypes.string,
    onChange: PropTypes.func,
    excludeSectionIds: PropTypes.array
};

ConstructionPlanSectionSelect.defaultProps = {
    scaffoldingId: '',
    onChange: () => false,
    excludeSectionIds: []
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSectionsSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ConstructionPlanSectionSelect);

