import { RequestStatus } from '../properties';

const RequestStatusOptions = [
    {
        label: 'Anfrage eingegangen',
        value: RequestStatus.New
    },
    {
        label: 'Anfrage in Bearbeitung',
        value: RequestStatus.InProgress
    },
    {
        label: 'Angebot versendet',
        value: RequestStatus.OfferSent
    },
    {
        label: 'Angebot vom Kunden abgelehnt / Anfrage zurückgezogen',
        value: RequestStatus.RejectedByCustomer
    },
    {
        label: 'Anfrage vom Gerüstersteller abgelehnt',
        value: RequestStatus.RejectedByContractor
    }
];

export default RequestStatusOptions;
