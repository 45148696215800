import _ from 'lodash';
import { getSyncChoiceValue } from '../utils';

function getOptionRowData(key, planValue, liveValue) {
    const leftValue = planValue ? getSyncChoiceValue(key, planValue) : undefined;
    const rightValue = liveValue ? getSyncChoiceValue(key, liveValue) : undefined;

    return {
        key,
        left: leftValue,
        right: rightValue
    }
}

/**
 * Returns all identical options
 *
 * @param propertyEnum
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getIdenticalOptionRows = (propertyEnum, planValue, liveValue) => {
    return Object
        .keys(propertyEnum)
        .filter((key) => {
            return ((planValue && key in planValue) && (liveValue && key in liveValue))
        })
        .filter((key) => {
            return _.isEqual(planValue[key], liveValue[key]);
        })
        .map((key) => getOptionRowData(key, planValue, liveValue));
};

/**
 * @see getIdenticalOptionRows
 * @see fromEnumList
 */
export function getEnumListIdenticalOptionRows(enumList, planValue, liveValue) {
    return getIdenticalOptionRows(fromEnumList(enumList), planValue, liveValue);
}

/**
 * @see getIdenticalOptionRows
 * @see fromEnumObject
 */
export function getEnumObjectIdenticalOptionRows(enumObject, planValue, liveValue) {
    return getIdenticalOptionRows(fromEnumObject(enumObject), planValue, liveValue);
}

/**
 * Returns all options which differ
 *
 * @param propertyEnum
 * @param planValue
 * @param liveValue
 * @return {{key: string, left: *, right: *}[]}
 */
export const getDiffOptionRows = (propertyEnum, planValue, liveValue) => {
    return Object
        .keys(propertyEnum)
        .filter((key) => {

            // Only one is present
            if ((planValue && key in planValue) ^ (liveValue && key in liveValue)) {
                return true;
            }

            const planOption = planValue ? planValue[key] : undefined;
            const liveOption = liveValue ? liveValue[key] : undefined;

            return !_.isEqual(planOption, liveOption);
        })
        .map((key) => getOptionRowData(key, planValue, liveValue));
};

/**
 * @see getDiffOptionRows
 * @see fromEnumList
 */
export function getEnumListDiffOptionRows(enumList, planValue, liveValue) {
    return getDiffOptionRows(fromEnumList(enumList), planValue, liveValue);
}

/**
 * @see getDiffOptionRows
 * @see fromEnumObject
 */
export function getEnumObjectDiffOptionRows(enumObject, planValue, liveValue) {
    return getDiffOptionRows(fromEnumObject(enumObject), planValue, liveValue);
}

/**
 * Converts an enum list to an object like this:
 *
 * <pre>
 * ['facade_scaffolding', 'birdcage_scaffolding']
 * =>
 * {
 *     facade_scaffolding: 'facade_scaffolding',
 *     birdcage_scaffolding: 'birdcage_scaffolding'
 * }
 * </pre>
 * @param list
 * @return {{}|*}
 */
function fromEnumList(list) {
    if (!list || list.length === 0) {
        return {};
    }

    return list.reduce((propertyEnum, item) => {
        propertyEnum[item] = item;
        return propertyEnum;
    }, {})
}

/**
 * Converts an enum list to an object like this:
 *
 * <pre>
 * {
 *     FacadeScaffolding: 'facade_scaffolding',
 *     BirdcageScaffolding: 'birdcage_scaffolding'
 * }
 * =>
 * {
 *     facade_scaffolding: 'facade_scaffolding',
 *     birdcage_scaffolding: 'birdcage_scaffolding'
 * }
 * </pre>
 * @param enumObject
 * @return {{}|*}
 */
function fromEnumObject(enumObject) {
    if (_.isEmpty(enumObject)) {
        return {};
    }

    return Object
        .values(enumObject)
        .reduce((result, item) => {
            result[item] = item;
            return result;
        }, {})
}
