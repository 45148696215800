import PropTypes from 'prop-types';
import React from 'react';
import AcceptedInvitation from './AcceptedInvitation';
import PendingInvitation from './PendingInvitation';

const SiteInvitation = ({ siteId, invitation, onUpdate, siteIsArchived}) => {

    if (!invitation) {
        return null;
    }

    const { accepted, acceptedBy } = invitation;

    if (accepted && acceptedBy) {
        return <AcceptedInvitation data={invitation} />;
    }

    return <PendingInvitation
        siteId={siteId}
        onUpdate={onUpdate}
        data={invitation}
        resendingIsEnabled={!siteIsArchived}
    />;

};

export default SiteInvitation;

SiteInvitation.propTypes = {
    siteId: PropTypes.string.isRequired,
    invitation: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    siteIsArchived: PropTypes.bool.isRequired
};
