import React from 'react';
import Icon from '../../../../../Common/Icon';

const SyncChoiceMediaVisibilityButton = ({ onClick, icon, value, isSelected, isDisabled = false }) => {

    const classes = ['sync-mediaoption__sidebar-btn', 'sync-mediaoption__sidebar-visibility-btn'];

    classes.push(`sync-mediaoption__sidebar-visibility-btn-${icon}`);

    const disabled = isDisabled(value);

    if (isSelected(value)) {
        classes.push('sync-mediaoption__sidebar-visibility-btn--selected');
    }

    if (disabled) {
        classes.push('sync-mediaoption__sidebar-visibility-btn--disabled');
    }

    const internalOnClick = () => {
        if (disabled) {
            return;
        }

        onClick(value);
    };

    return (
        <div
            className={classes.join(' ')}
            onClick={() => internalOnClick(value)}
        >
            <Icon name={icon}/>
        </div>
    );
};

export default SyncChoiceMediaVisibilityButton;
