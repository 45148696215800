import React from 'react';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';
import TopNav from '../../../Components/Page/TopNav';
import AccountUserTable from '../../../Components/Account/AccountUserTable';

const UserManagementPage = () => (
    <PrivatePage requiredUserPermission={Permission.UpdateUsers}>
        <Container>
            <TopNav />
            <AccountUserTable />
        </Container>
    </PrivatePage>
);

export default UserManagementPage;
