import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import Error from './Error';

const Radio = ({ index, name, disabled, value, label, showErrors }) => {

    const classes = ['input-grp__optionlabel'];

    if (disabled) {
        classes.push('input-grp__optionlabel--disabled');
    }

    return (
        <label key={index} className={classes.join(' ')}>
            <Field
                name={name}
                component="input"
                type="radio"
                value={value}
                disabled={disabled}
            />{' '}
            {label}
            {showErrors &&
            <Error name={name} />
            }
        </label>
    );

};

Radio.propTypes = {
    showErrors: PropTypes.bool
};

Radio.defaultProps = {
    showErrors: true
};

export default Radio;
