import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Permission from '../../../permissions';
import { showRequestError } from '../../../redux/modules/error/action';
import { updateJob, updateJobTypeAndStatus } from '../../../redux/modules/job/actions';
import { userCan } from '../../../Services/UserService';
import Icon from '../../Common/Icon';
import LoadingSpinner from '../../Page/LoadingSpinner';
import JobStatus from '../JobStatus';
import JobStatusSwitchModal from '../JobStatusSwitchModal';
import { JobType } from '../properties';
import { isRequest } from '../utils';
import JobStatusOptions from './jobStatusOptions';
import RequestStatusOptions from './requestStatusOptions';

class JobStatusQuickEdit extends Component {

    static propTypes = {
        job: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired
        }),
        onUpdate: PropTypes.func.isRequired,
        horizontal: PropTypes.bool,
        badge: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            listOpen: false,
            isSaving: false,
            toggleJobChangeTypeModal: false
        };

        this.toggleJobChangeTypeModal = this.toggleJobChangeTypeModal.bind(this);
        this.toggleList = this.toggleList.bind(this);
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    toggleJobChangeTypeModal() {
        if(this.unmounted) {
            return;
        }

        this.setState(prevState => ({
            toggleJobChangeTypeModal: !prevState.toggleJobChangeTypeModal
        }));
    }

    toggleList() {
        if(this.unmounted) {
            return;
        }

        if (!this.state.isSaving) {
            this.setState(prevState => ({
                listOpen: !prevState.listOpen
            }));
        }
    }

    handleClickOutside() {
        if(this.unmounted) {
            return;
        }

        if (this.state.listOpen) {
            this.setState({
                listOpen: false
            });
        }
    }

    update(status) {
        const { job, actions: { updateJob }, onUpdate } = this.props;

        if (status === job.status) {
            return;
        }

        if (status === JobType.Request || status === JobType.Job) {
            this.toggleJobChangeTypeModal();
            return;
        }

        this.setState({ isSaving: true });
        updateJob(job.id, { status })
            .then((data) => onUpdate(job.id, data.status))
            .catch(err => showRequestError('Status konnte nicht aktualisiert werden', err))
            .finally(() => this.setState({ listOpen: false, isSaving: false }));
    }

    renderJobStatusList() {
        const { job } = this.props;
        let links = [];

        if (isRequest(job)) {
            links = [
                ...RequestStatusOptions,
                {
                    label: 'In Auftrag umwandeln',
                    value: JobType.Job
                }
            ]
        } else {
            links = [
                ...JobStatusOptions,
                {
                    label: 'In Anfrage umwandeln',
                    value: JobType.Request
                }
            ];
        }

        return links.map(link => (
            <li key={link.label}>
                <span
                    onClick={() => {
                        this.setState({ listOpen: false });
                        this.update(link.value)
                    }}
                    className="jobStatusQuickEdit__list-item"
                >
                    {link.label}
                </span>
            </li>
        ));
    }

    getNewJobType() {
        const { job } = this.props;

        return isRequest(job) ? JobType.Job : JobType.Request;
    }

    render() {
        const { listOpen, isSaving, toggleJobChangeTypeModal } = this.state;
        const { job, userPermissions, onUpdate, horizontal, badge } = this.props;

        if (!userCan(Permission.UpdateJobs, userPermissions)) {
            return (
                <JobStatus status={job.status} />
            )
        }

        return (
            <Fragment>
                <div className={`jobStatusQuickEdit ${horizontal ? 'jobStatusQuickEdit--horizontal' : ''}`}>
                    <div
                        className={`jobStatusQuickEdit__btn`}
                        onClick={this.toggleList}
                        role="button"
                    >
                        <JobStatus
                            status={job.status}
                            renderComponentAfterDots={
                                <span className="jobStatusQuickEdit__chevron">
                                    <Icon name={listOpen ? 'chevronUp' : 'chevronDown'} />
                                </span>
                            }
                            horizontal={!!horizontal}
                            badge={!!badge}
                        />
                    </div>

                    {
                        isSaving && <LoadingSpinner block />
                    }

                    {listOpen &&
                    <ul className="jobStatusQuickEdit__list">
                        {this.renderJobStatusList()}
                    </ul>
                    }

                </div>

                {
                    toggleJobChangeTypeModal && <JobStatusSwitchModal
                        id={job.id}
                        jobName={job.name}
                        switchTo={this.getNewJobType()}
                        closeModal={this.toggleJobChangeTypeModal}
                        redirect={false}
                        onSuccess={onUpdate}
                    />
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        updateJob,
        updateJobTypeAndStatus
    }, dispatch)
});

const mapStateToProps = ({ currentUser }) => {
    const userPermissions = currentUser.usermeta.permissions ? currentUser.usermeta.permissions : [];

    return {
        userPermissions
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(JobStatusQuickEdit));
