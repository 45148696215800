import PropTypes from 'prop-types';
import React from 'react';
import CustomerDetail from '../../../Components/Customers/Detail/CustomerDetail';
import TopNav from '../../../Components/Page/TopNav';
import Permission from '../../../permissions';
import Container from '../../Container';
import PrivatePage from './PrivatePage';

const CustomerDetailPage = ({ match }) => (
    <PrivatePage requiredUserPermission={Permission.ReadCustomers}>
        <Container>
            <TopNav />
            <CustomerDetail customerId={match.params.id} />
        </Container>
    </PrivatePage>
);

export default CustomerDetailPage;

CustomerDetailPage.propTypes = {
    match: PropTypes.object
};

CustomerDetailPage.defaultProps = {
    match: {}
};
