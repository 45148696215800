import React, { Fragment } from 'react';
import Breadcrumb from '../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../Navigation/Breadcrumbs/BreadcrumbLink';

const BaseDataBreadcrumb = () => {

    return (
        <Breadcrumb>
            <Fragment>
                <BreadcrumbLink
                    label="Stammdaten"
                    path="/account/profile"
                    icon="settings"
                />
                <BreadcrumbLink
                    label="Unternehmen bearbeiten"
                    path="/account/profile/company/edit"
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default BaseDataBreadcrumb;
