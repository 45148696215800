import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ResourceViewMode } from '../../../Containers/Pages/Private/properties';
import Permission from '../../../permissions';
import { fetchConstructionDiaryEntries } from '../../../redux/modules/constructiondiary/action';
import { showRequestError, showTempError } from '../../../redux/modules/error/action';
import Icon from '../../Common/Icon';
import IfUser from '../../Common/IfUser';
import ConstructionDiaryEntryModal from '../../ConstructionDiary/ConstructionDiaryEntryModal';
import { EmbeddedConstructionDiaryEntriesTable } from '../../ConstructionDiary/Embedded/EmbeddedConstructionDiaryEntriesTable';
import Section from '../../Layout/Section';

const SiteConstructionDiarySection = ({ site, viewMode, actions }) => {

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setLoading] = useState('initial');
    const [diaryEntries, setDiaryEntries] = useState([]);
    const fetchAborted = useRef(false);

    const loadEntries = async (siteId, initial = false) => {

        setLoading(initial ? 'initial' : 'refresh');

        const diaryFilterParams = {
            page: 1,
            limit: 3,
            category: 'all',
            siteId: siteId,
            sortAsc: false
        };

        actions.fetchConstructionDiaryEntries(diaryFilterParams)
            .then(diaryEntries => {

                if (fetchAborted.current) {
                    return;
                }

                if (!diaryEntries) {
                    return;
                }

                setDiaryEntries(diaryEntries.data);
            })
            .catch((error) => {

                if (fetchAborted.current) {
                    return;
                }

                if (typeof error === 'string') {
                    showTempError(error);
                } else {
                    showRequestError('Bautagebucheinträge konnten nicht geladen werden');
                }
            })
            .finally(() => {
                if (!fetchAborted.current) {
                    setLoading(false);
                }
            });
    };

    useEffect(() => {
        loadEntries(site.id, true);
        fetchAborted.current = false;

        return () => {
            fetchAborted.current = true;
        };
    }, [site]);

    return (
        <Fragment>
            <Section
                title="Bautagebuch"
                id="construction-diary"
                type="list"
                cta={
                    <IfUser
                        can={Permission.CreateConstructionDiaryEntries}
                        and={!site.isThirdParty && viewMode !== ResourceViewMode.Archived}
                    >
                        <button
                            onClick={() => {
                                setShowModal(true);
                            }}
                            className="btn btn--add-action"
                        >
                            <span className="btn__icon"><Icon name="add" /></span>
                            <span>Neuer Eintrag</span>
                        </button>
                    </IfUser>
                }
                busyLabel="Aktualisiere Bautagebuch"
                isBusy={!!isLoading}
            >
                {isLoading !== 'initial' &&
                <EmbeddedConstructionDiaryEntriesTable
                    diaryEntries={diaryEntries}
                    allEntriesLink={`/diary?site=${site.id}`}
                />
                }
            </Section>


            {showModal &&
            <ConstructionDiaryEntryModal
                siteId={site.id}
                onCreated={() => {
                    setShowModal(false);
                    loadEntries(site.id);
                }}
                onClose={() => setShowModal(false)}
            />
            }
        </Fragment>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchConstructionDiaryEntries
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(SiteConstructionDiarySection);
