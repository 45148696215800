import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from './Modal';

class InfoModal extends Component {

    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
    }

    close() {
        const { onClose } = this.props;

        onClose();
    }

    render() {

        return (
            <Modal
                id="modal-info"
                title={this.props.title}
                type="confirmation"
                onBackdropClick={() => this.props.onClose}
                footer={
                    <div className="btn-group">
                        <button
                            className="btn btn--backward-action"
                            onClick={this.close}
                        >
                            Schließen
                        </button>
                    </div>
                }
            >
                {this.props.children}
            </Modal>
        );
    }
}

export default InfoModal;

InfoModal.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func
};

InfoModal.defaultProps = {
    onClose: null
};
