import React from 'react';

const Changelog_1_21_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>
                    Archivierung von Anfragen und Aufträgen
                </h4>
                <p>
                    Anfragen und Aufträge können ab sofort archiviert werden. Auf der Anfragen- und
                    Auftragsübersicht haben Sie die Möglichkeit, nach aktiven oder archivierten
                    Anfragen bzw. Aufträgen zu filtern.
                </p>

                <h4>
                    Mehrere Ansprechpartner bei Kunden
                </h4>
                <p>
                    Ab sofort haben Sie die Möglichkeit, mehrere Ansprechpartner pro Kunde zu
                    hinterlegen. Aus den vorhandenen Ansprechpartnern können Sie außerdem einen
                    Kontakt als Hauptkontakt bestimmen.
                </p>

            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>
                        Aktualisierung des Cards "Anfragen/Aufträge" auf der Baustellen-,
                        Gerüst-, Gerüstabschnitts- und Kundendetailseite
                    </li>
                    <li>Weitere kleine Anpassungen und Verbesserungen</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_21_0;
