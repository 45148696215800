import PropTypes from 'prop-types';
import React from 'react';
import SubscriptionTypes from '../Account/subscriptionTypes';

const AccountType = ({ accountLabel }) => {
    const classes = ['account-type'];

    const label = SubscriptionTypes[accountLabel];

    switch (label) {
        case 'Administrator':
            classes.push('account-type--administrator');
            break;
        case 'Vollzugriff':
            classes.push('account-type--standard');
            break;
        case 'Befähigte Person':
            classes.push('account-type--qualified-worker');
            break;
        case 'Helfer':
            classes.push('account-type--worker');
            break;
        default:
            classes.push('');
            break;
    }

    return (
        <div className={classes.join(' ')}>
            {label}
        </div>
    );
};

export default AccountType;

AccountType.propTypes = {
    accountLabel: PropTypes.string.isRequired
};
