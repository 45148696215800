import React from 'react';

export const Changelog_1_24_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h3>
                    Neue Verwendungszwecke verfügbar
                </h3>
                <p>
                    Ab sofort können Sie auch Gerüstabschnitte mit den folgenden Verwendungszwecken anlegen:
                </p>

                <ul>
                    <li>
                        Podesttreppe (W06 & W09)
                    </li>
                    <li>
                        Einzelfelder als Aufstieg
                    </li>
                    <li>
                        Bautreppen
                    </li>
                    <li>
                        Fluchttreppen (500 & 750)
                    </li>
                </ul>
            </section>

            <section>
                <h3>
                    Neue Ausführungsarten verfügbar
                </h3>
                <p>
                    Es stehen Ihnen neue Ausführungsarten zur Auswahl zur Verfügung:
                </p>
                <ul>
                    <li>Vorgefertigte Bauteile</li>
                    <li>Systemfrei</li>
                </ul>
            </section>

            <section>
                <h3>
                    Trennung von Sonderkonstruktion und Statiknachweis
                </h3>
                <p>
                    Ab sofort haben Sie die Möglichkeit, Sonderkonstruktionen und Statiknachweise getrennt voneinander auszuwählen und hochzuladen. Die Auswahlmöglichkeiten wurden in der Kategorie “Stand- und Tragsicherheit” zusammengefasst.
                </p>
            </section>

            <section>
                <h3>
                    Neue Besonderheit “Kranversetzbar”
                </h3>
                <p>
                    Ihre Gerüstabschnitte können Sie jetzt auch als “kranversetzbar” markieren. Bei kranversetzbaren Gerüstabschnitten wird dann zusätzlich die Prüffrage “Sind die Bauteile gesichert?” hinterlegt.
                </p>
            </section>

            <section>
                <h3>
                    Optimierung des Formulars “Neuer Gerüstabschnitt”
                </h3>
                <ul className="changelog-items-list is-sentences">
                    <li>Die Struktur, Sortierung und Darstellung des Formulars “Neuer Gerüstabschnitt” wurde verbessert.</li>
                    <li>Das Feld “Freigegeben durch (befähigte Person)” wurde entfernt, da dieses durch den Freigabeprozess in der App automatisch bestimmt wird.</li>
                    <li>“Notizen” wurde als eigenständige Sektion implementiert.</li>
                </ul>
            </section>

            <section>
                <h3>Weitere Verbesserungen und Korrekturen</h3>
                <ul className="changelog-items-list is-sentences">
                    <li>
                        Bei Änderungen auf freigegebenen Gerüstabschnitten werden Sie ab sofort darüber informiert, dass Ihre bestehende Freigabe dadurch ihre Gültigkeit verliert
                    </li>
                    <li>
                        Die Auswahl und Anzeige “Eigenes Gerüst” / “Fremdes Gerüst” wird jetzt im Header-Bereich der Gerüstformulare angezeigt
                    </li>
                    <li>
                        Weitere kleine Verbesserungen und Korrekturen
                    </li>
                </ul>
            </section>
        </div>
    </div>
);

