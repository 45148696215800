import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Common/Icon';

const MediaUploadButton = ({ onClick, label, disabled }) => (
    <button className="btn btn--upload" onClick={onClick} disabled={disabled}>
        <span className="btn__icon">
            <Icon name="upload" />
        </span>
        <span className="btn__lbl">
            {label}
        </span>
    </button>
);

MediaUploadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool
};

MediaUploadButton.defaultProps = {
    label: 'Dokumente hochladen',
    disabled: false
};

export default MediaUploadButton;
