import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

class ModalGate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: props.ignoreStorage || !this.isHidden()
        };

        this.hideModal = this.hideModal.bind(this);
    }

    isHidden() {

        const { storageKey } = this.props;

        const clickedAt = localStorage.getItem(storageKey);

        if (!clickedAt) {
            return false;
        }
        return moment(clickedAt)
            .add(1, 'd')
            .isAfter(moment());
    }

    hideModal() {

        const { ignoreStorage, storageKey } = this.props;

        this.setState({
            modalIsOpen: false
        }, () => {

            if (!ignoreStorage) {
                localStorage.setItem(storageKey, moment()
                    .format());
            }
        });
    }

    render() {
        const { modalIsOpen } = this.state;
        const { opensIf, children } = this.props;

        return (
            <Fragment>
                {
                    modalIsOpen && opensIf && children(this.hideModal)
                }
            </Fragment>
        );
    }
}

export default ModalGate;

ModalGate.propTypes = {
    opensIf: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
    ignoreStorage: PropTypes.bool,
    storageKey: PropTypes.string
};

ModalGate.defaultProps = {
    ignoreStorage: false,
    storageKey: ''
};



