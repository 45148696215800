import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError, showTempError } from '../../../../redux/modules/error/action';
import { resendSiteInvitation } from '../../../../redux/modules/site/action';
import GraphQLError, { GraphQlErrorCodes } from '../../../../Services/GraphQLError';
import LoadingSpinner from '../../../Page/LoadingSpinner';
import Invitation from './Invitation';

const PendingInvitation = ({ data, siteId, onUpdate, actions, resendingIsEnabled }) => {

    const { inviteeEmail, id } = data;

    const [isSending, setIsSending] = useState(false);

    const sendButtonLabel = isSending ? 'Einladung wird gesendet ...' : 'Einladung erneut senden';

    const handleError = error => {

        if (!(error instanceof GraphQLError)) {
            showApiError(error);
            return;
        }

        if (error.is(GraphQlErrorCodes.SiteNotFound)) {
            showTempError('Die zugehörige Baustelle konnte nicht gefunden werden');
        } else if (error.is(GraphQlErrorCodes.SiteInvitationAlreadyAccepted)) {
            showTempError('Die Einladung wurde bereits akzeptiert');
        } else if (error.is(GraphQlErrorCodes.SiteInvitationNotFound)) {
            showTempError('Die Einladung konnte nicht gefunden werden');
        } else if (error.is(GraphQlErrorCodes.ResendingSiteInvitationFailed)) {
            showTempError('Die Einladung konnte nicht erneut gesendet werden');
        } else {
            showApiError(error);
        }

    };

    const resendInvitation = () => {

        if (isSending) {
            return;
        }

        const { resendSiteInvitation } = actions;

        setIsSending(true);

        resendSiteInvitation(siteId, inviteeEmail)
            .then(() => {
                toast.success(`Die Einladung wurde erneut an ${inviteeEmail} gesendet.`, { autoClose: 6000 });
            })
            .catch(error => {
                handleError(error);
            })
            .finally(() => {
                setIsSending(false);
                onUpdate();
            });
    };

    const renderResendButton = () => {

        if (!resendingIsEnabled) {
            return '-';
        }

        return (
            <Fragment>
                {isSending && <LoadingSpinner size="small" color="blue" />}
                <button
                    type="button"
                    onClick={resendInvitation}
                    className="btn btn--link-neutral"
                >
                    {sendButtonLabel}
                </button>
            </Fragment>
        );

    };

    return (
        <Invitation
            icon="question"
            id={id}
            heading={inviteeEmail}
            tooltipContent={resendingIsEnabled ? 'Einladung wurde noch nicht angenommen.' : 'Einladung wurde nicht angenommen.'}
            wrapperClass={isSending ? 'site-invitation--isSending' : null}
        >
            {renderResendButton()}
        </Invitation>
    );
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        resendSiteInvitation
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(PendingInvitation);

PendingInvitation.propTypes = {
    data: PropTypes.object.isRequired,
    siteId: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    resendingIsEnabled: PropTypes.bool.isRequired
};
