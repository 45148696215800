import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchCustomerSelectOptions } from '../../../redux/modules/customers/action';
import { fetchSitesSelectOptions } from '../../../redux/modules/sites/action';
import LinkButton from '../../Common/LinkButton';
import { MODAL_CONTEXT_JOB_REQUEST_CREATION } from '../../Customers/Form/CustomerContactFormModal';
import CustomerContactSelectionFormPart
    from '../../Customers/Form/CustomerContactSelectionFormPart';
import CustomerFormModal from '../../Customers/Form/CustomerFormModal';
import Condition from '../../Forms/Condition';
import ScaffeyeDatePicker from '../../Forms/DatePicker';
import Input from '../../Forms/Input';
import InputGroup from '../../Forms/InputGroup';
import InputHint from '../../Forms/InputHint';
import Select from '../../Forms/Select';
import Textarea from '../../Forms/Textarea';
import JobStatusSwitchModal from '../JobStatusSwitchModal';
import { JobType, RequestStatus } from '../properties';
import { MODE_EDIT } from './JobFormModal';
import JobStatusOptions from './jobStatusOptions';
import RequestStatusOptions from './requestStatusOptions';

const required = message => value => (value ? undefined : message || 'Bitte geben Sie einen Wert ein');

class JobBasicInfoFormPart extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            isLoadingCustomers: false,
            customerChoices: [],
            showAddCustomerModal: false,
            jobStatusSwitchModalIsOpen: false
        };

        this.onCustomerSelectionChange = this.onCustomerSelectionChange.bind(this);
        this.loadCustomerOptions = this.loadCustomerOptions.bind(this);
    }

    componentDidMount() {
        // Fetch customers
        this.loadCustomerOptions();
    }

    onCustomerSelectionChange(value, prevVal) {

        const { mutators } = this.props;

        if (value === '-1') {
            this.toggleAddCustomerModal();
        }

        if (prevVal !== '') {
            mutators.setField('customerContactPersonId', undefined);
        }

    }

    onCustomerCreated() {
        this.loadCustomerOptions();
        this.toggleAddCustomerModal();
    }

    toggleAddCustomerModal() {
        this.setState(prevState => ({
            showAddCustomerModal: !prevState.showAddCustomerModal
        }));
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    loadCustomerOptions() {
        this.updateFetchingCustomers(true);
        this.props.actions.fetchCustomerSelectOptions()
            .then(({ data }) => {

                if (this.loadingGotCancelled) {
                    return;
                }

                const customerChoices = [
                    {
                        label: '+ Neuen Kunden anlegen',
                        value: -1
                    }
                ];
                const customerList = data || [];

                customerList.forEach((customer) => {
                    customerChoices.push({
                        value: customer.id,
                        label: customer.name
                    });
                });

                this.setState({
                    customerChoices
                });
            })
            .catch(() => {
                toast.error('Kunden konnten nicht geladen werden', { autoClose: 4000 });
            })
            .then(() => {
                this.updateFetchingCustomers(false);
            });
    }

    updateFetchingCustomers(isFetching) {

        if (this.loadingGotCancelled) {
            return;
        }

        this.setState({
            isLoadingCustomers: isFetching
        }, () => {
            const { onFetchingCustomers } = this.props;
            if (typeof onFetchingCustomers === 'function') {
                onFetchingCustomers(isFetching);
            }
        });
    }

    openJobStatusSwitchModal(open = true) {

        this.setState({
            jobStatusSwitchModalIsOpen: open
        });
    }

    render() {
        const {
            customerChoices,
            isLoadingCustomers,
            showAddCustomerModal,
            jobStatusSwitchModalIsOpen
        } = this.state;

        const { type, values, mode, jobId, mutators } = this.props;

        const { status: currentStatus, name: jobName } = values;

        return (
            <Fragment>
                <InputGroup
                    label="Bezeichnung*"
                >
                    <Input
                        type="text"
                        name="name"
                        placeholder="Bezeichnung eingeben"
                        validate={required('Bitte geben Sie eine Bezeichnung ein')}
                    />
                </InputGroup>

                <InputGroup
                    label="Status*"
                >
                    {type === JobType.Request ?
                        <Fragment>
                            <Select
                                name="status"
                                options={currentStatus === RequestStatus.Rejected ? [
                                    ...RequestStatusOptions,
                                    {
                                        label: 'Anfrage abgelehnt',
                                        value: RequestStatus.Rejected,
                                        disabled: true
                                    }
                                ] : RequestStatusOptions}
                                placeholder="Anfragestatus wählen"
                            />
                            <Condition
                                when="status"
                                is={RequestStatus.Rejected}
                            >
                                <InputHint>
                                    Der Status "Anfrage abgelehnt" kann zukünftig nicht mehr
                                    ausgewählt werden und wird durch die Optionen &quot;Anfrage vom
                                    Gerüstersteller abgelehnt&quot; bzw. &quot;Angebot vom Kunden
                                    abgelehnt&quot; ersetzt.
                                </InputHint>
                            </Condition>
                            {mode === MODE_EDIT && <div style={{ marginTop: 12 }}>
                                <LinkButton
                                    onClick={() => this.openJobStatusSwitchModal()}
                                    label="In Auftrag umwandeln"
                                />
                            </div>
                            }
                        </Fragment>
                        :
                        <Select
                            name="status"
                            options={JobStatusOptions}
                            placeholder="Auftragsstatus wählen"
                        />
                    }

                </InputGroup>
                <InputGroup
                    label="Kunde"
                >
                    <Select
                        name="customerId"
                        placeholder={isLoadingCustomers ? 'Lade Kunden ...' : 'Kunde auswählen'}
                        options={customerChoices}
                        disabled={isLoadingCustomers}
                        onChange={this.onCustomerSelectionChange}
                    />
                </InputGroup>

                <CustomerContactSelectionFormPart
                    customerId={values.customerId}
                    key={JSON.stringify(values.customerId)}
                    disabled={!values.customerId || values.customerId === '-1'}
                    disabledPlaceholder="Bitte wählen Sie einen Kunden"
                    isRequired={values.customerId && values.customerId !== '-1'}
                    modalContext={MODAL_CONTEXT_JOB_REQUEST_CREATION}
                    mutators={mutators}
                    values={values}
                    name="customerContactPersonId"
                />

                <InputGroup
                    label="Notizen"
                >
                    <Textarea
                        name="description"
                        rows={4}
                    />
                </InputGroup>

                {type === JobType.Request && (
                    <InputGroup
                        label="Angebotsabgabe bis"
                    >
                        <ScaffeyeDatePicker
                            name="offerDeadline"
                            placeholder='Datum eingeben'
                        />
                        <InputHint>
                            Wenn Sie hier ein Datum hinterlegen, werden Sie per E-Mail an die Abgabe
                            eines Angebots für diese Anfrage erinnert.
                        </InputHint>
                    </InputGroup>
                )
                }

                {showAddCustomerModal &&

                <CustomerFormModal
                    onCreated={(customerId) => {
                        this.props.mutators.setField('customerId', customerId);
                        this.onCustomerCreated();
                    }}
                    onClose={(wasCancelled = false) => {
                        if (wasCancelled) {
                            this.props.mutators.setField('customerId', null);
                        }
                        this.toggleAddCustomerModal();
                    }}
                />
                }
                {type === JobType.Request && mode === MODE_EDIT && jobStatusSwitchModalIsOpen &&
                <JobStatusSwitchModal
                    switchTo={JobType.Job}
                    jobName={jobName}
                    id={jobId}
                    closeModal={() => this.openJobStatusSwitchModal(false)}
                />
                }
            </Fragment>
        );
    }
}

JobBasicInfoFormPart.propTypes = {
    mutators: PropTypes.object.isRequired,
    type: PropTypes.oneOf([JobType.Request, JobType.Job]).isRequired,
    mode: PropTypes.oneOf(['edit', 'create']).isRequired,
    onFetchingCustomers: PropTypes.func,
    jobId: PropTypes.string,
    values: PropTypes.object
};

JobBasicInfoFormPart.defaultProps = {
    onFetchingCustomers: null,
    jobId: '',
    values: {}
};

const mapStateToProps = state => ({
    jobAttachmentIsUploading: state.media.jobAttachmentIsUploading
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCustomerSelectOptions,
        fetchSitesSelectOptions
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(JobBasicInfoFormPart);
