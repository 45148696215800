import createDecorator from 'final-form-focus';
import React, { Component, Fragment } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Icon from '../../../Components/Common/Icon';
import Input from '../../../Components/Forms/Input';
import InputGroup from '../../../Components/Forms/InputGroup';
import LoadingSpinner from '../../../Components/Page/LoadingSpinner';
import TopNav from '../../../Components/Page/TopNav';
import { showApiError, showTempError } from '../../../redux/modules/error/action';
import { acceptInvitation } from '../../../redux/modules/user/action';
import { UnauthorizedError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import PublicFooter from './PublicFooter';
import PublicPage from './PublicPage';

const focusOnError = createDecorator();

class NewUserInvitationPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSending: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.showAcceptInvitationForm = this.showAcceptInvitationForm.bind(this);
    }

    getUrlParameter() {
        const url = new URL(window.location);
        return url.searchParams.get('t');
    }

    onSubmit(values) {

        const { actions: { acceptInvitation } } = this.props;

        const data = {
            password: values.password,
            token: this.getUrlParameter(),
            accept_adv: true
        };

        this.setState({
            isSending: true
        });

        acceptInvitation(data)
            .then(() => {
                this.setState({
                    isSending: false,
                    invitationWasAccepted: true
                });
            })
            .catch((error) => {
                this.setState({
                    isSending: false
                });

                if (error instanceof UnauthorizedError) {

                    return ErrorService.handleUnauthorizedError(error, 'newUserAcceptsInvitation')
                        .then(({ message }) => {
                            showTempError(message);
                        });
                }

                return showApiError(error);
            });
    }

    showRegistrationConfirmation() {
        return (
            <Fragment>
                <h1>
                    <span className="verification-success">
                        <Icon name="stateok" />
                    </span> Registrierung erfolgreich
                </h1>
                <div className="section">
                    <div className="confirmation">
                        <p>Sie haben sich erfolgreich registriert. Ab sofort können Sie
                            sich mit Ihrer E-Mail Adresse und Ihrem Passwort bei
                            Scaffeye® anmelden.
                        </p>

                        <Link
                            className="btn btn--block btn--primary-green"
                            to={{
                                pathname: '/login',
                                state: {
                                    email: 'hs@spanier-wiedemann.de'
                                }
                            }}
                        >
                            Zur Anmeldung
                        </Link>
                    </div>
                </div>
            </Fragment>
        );
    }

    showAcceptInvitationForm() {

        const { isSending } = this.state;

        return (
            <Fragment>
                <h1>Einladung annehmen </h1>
                <div className="section">
                    <h2 className="accept-invitation-form__heading">Willkommen bei
                        Scaffeye®
                    </h2>
                    <p>
                        Um Ihr Konto zu aktivieren, müssen Sie nur noch Ihr
                        persönliches
                        Passwort wählen und die Einladung annehmen.
                    </p>
                    <Form
                        onSubmit={this.onSubmit}
                        decorators={[focusOnError]}
                        validate={this.validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <InputGroup
                                    label="Passwort*"
                                >
                                    <Input
                                        type="password"
                                        name="password"
                                        placeholder="Passwort eingeben"
                                    />
                                </InputGroup>
                                <InputGroup
                                    label="Passwort wiederholen*"
                                >
                                    <Input
                                        type="password"
                                        name="passwordConfirm"
                                        placeholder="Passwort wiederholen"
                                    />
                                </InputGroup>

                                <div className="accept-invitation-form__disclaimer">
                                    <span
                                        className="accept-invitation-form__disclaimer-icon"
                                    > <Icon name="info" />
                                    </span>
                                    <div>
                                        Mit Annehmen der Einladung akzeptieren Sie
                                        unsere <a
                                        href="https://www.scaffeye.de/datenschutz-plattform/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Datenschutzerklärung
                                    </a> sowie die <a
                                        href="https://www.scaffeye.de/agb-plattform/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    > Auftragsdatenverarbeitung
                                    </a>
                                    </div>
                                </div>

                                <div className="flex justify-content-between">
                                    <button
                                        type="submit"
                                        className="btn btn--primary-green btn--block"
                                        disabled={isSending}
                                    >
                                        {isSending ? <LoadingSpinner label="Einladung annehmen"
                                        /> : 'Einladung annehmen'}
                                    </button>
                                </div>

                                <div className="hints" style={{ marginTop: 15 }}>
                                    <span>* Pflichtfeld</span>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </Fragment>
        );
    }

    validate(values) {

        const errors = {};

        // E-Mail
        if (!values.password) {
            errors.password = 'Bitte geben Sie ein Passwort ein';
        } else if (values.password.length < 6) {
            errors.password = 'Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein';

        } else if (values.password !== values.passwordConfirm) {
            errors.passwordConfirm = 'Ihre Passwörter stimmen nicht überein';
        }
        return errors;
    }

    render() {
        const { invitationWasAccepted } = this.state;

        const formContent = invitationWasAccepted ? this.showRegistrationConfirmation() : this.showAcceptInvitationForm();

        return (
            <PublicPage>
                <TopNav isPublic />

                <div className="accept-invitation-form">
                    {formContent}
                    <PublicFooter />
                </div>
            </PublicPage>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ acceptInvitation }, dispatch)
});

export default connect(null, mapDispatchToProps)(NewUserInvitationPage);

