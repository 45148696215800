import _ from 'lodash';
import PropTypes from 'prop-types';
import { ModelType } from '../../../properties';


export const JobDiffType = {
    ConstructionPlanCreated: 'constructionPlanCreated',
    ConstructionPlanDeleted: 'constructionPlanDeleted',
    ConstructionPlanChanged: 'constructionPlanChanged',
    StatusChanged: 'statusChanged',
    JobTypeChangedToJob: 'jobTypeChangedToJob',
    JobTypeChangedToRequest: 'jobTypeChangedToRequest'
};

export const DiffType = {
    New: 'new',
    Changed: 'changed',
    Deleted: 'deleted',
    Archived: 'archived',
    Reactivated: 'reactivated'
};

export const DiffItemPropType = PropTypes.shape({
    modelType: PropTypes.oneOf([
        ModelType.Site,
        ModelType.Scaffolding,
        ModelType.Section,
        ModelType.Job
    ]),
    label: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
        DiffType.New,
        DiffType.Deleted,
        DiffType.Changed,
        ...Object.values(JobDiffType)
    ]),
    newVal: PropTypes.any,
    oldVal: PropTypes.any
});

export const DiffItemRenderType = {
    Bool: 'bool',
    MultiLine: 'multiline',
    ModelReference: 'modelReference',
    Media: 'media',
    MediaItem: 'mediaItem',
    Address: 'address',
    ContactPerson: 'contactPerson',
    Default: 'default',
    Row: 'row'
};

/*
 * Site specific properties
 */
export const SiteDiffProperty = {
    Name: 'name',
    Description: 'description',
    Address: 'address',
    Attachments: 'attachments'
};

export const siteDiffProperties = _.values(SiteDiffProperty);

/*
 * Scaffolding specific properties
 */
export const ScaffoldingDiffProperty = {
    Name: 'name',
    Description: 'description',
    ScheduledServiceTime: 'scheduledServiceTime',
    Attachments: 'attachments',
    UtilizationPlans: 'utilizationPlans',
    AnchorProtocol: 'anchorProtocol'
};

export const scaffoldingDiffProperties = _.values(ScaffoldingDiffProperty);

/*
 * Section specific properties
 */
export const SectionDiffProperty = {
    // Base
    Name: 'name',

    Site: 'site',
    Scaffolding: 'scaffolding',

    Managed: 'managed',
    Status: 'status',
    Archived: 'archived',

    Contractor: 'contractor',
    ApprovedByName: 'approvedByName',
    Customer: 'customer',

    EquippedSurfaces: 'equippedSurfaces',

    // Used as wrapper for 'purposes', their groups and 'otherProtectivePurpose'
    Purpose: 'purpose',

    PlannedWorks: 'plannedWorks',

    // Specification
    SupportType: 'supportType',
    ConstructionType: 'constructionType',

    // Used as reducer for 'specialConstruction' and 'staticsCertificateNecessary'
    StabilityAndLoadSafety: 'stabilityAndLoadSafety',

    LoadClass: 'loadClass',
    WidthClass: 'widthClass',
    WallClearance: 'wallClearance',
    Claddings: 'claddings',
    SpecialFeatures: 'specialFeatures',

    // Notes
    Description: 'description',

    // Attachments
    UtilizationPlans: 'utilizationPlans',
    AnchorProtocol: 'anchorProtocol',
    ProofOfStability: 'proofOfStability',
    Attachments: 'attachments',
};

export const sectionDiffProperties = _.values(SectionDiffProperty);


/*
 * Job specific properties
 */
export const JobDiffProperty = {
    // Base
    Name: 'name',
    ConstructionPlan: 'constructionPlan',
    Customer: 'customer',
    CustomerContact: 'customerContact',
    Description: 'description',
    Status: 'status',
    Archived: 'archived',
    OfferDeadline: 'offerDeadline'
};

export const jobDiffProperties = _.values(JobDiffProperty);
