import React from 'react';

const Changelog_1_17_0 = () => (
    <div className="changelog-entry">
        <div className="changelog-entry__cnt">
            <section>
                <h4>
                    Neuer Bereich für Hilfethemen und Support
                </h4>
                <p>Unter dem Reiter "Hilfe" finden Sie ab sofort Antworten zu häufig gestellten
                    Fragen, Anleitungen zur Benutzung von Scaffeye® als auch Links zu
                    weiterführenden
                    Hilfeangeboten. </p>
            </section>

            <section>
                <h4>Weitere Funktionen und Verbesserungen</h4>
                <ul>
                    <li>Verbesserung der Verständlichkeit von Beschriftungen und Hinweistexten</li>
                </ul>
            </section>
        </div>
    </div>
);

export default Changelog_1_17_0;
