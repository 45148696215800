import PropTypes from 'prop-types';
import React from 'react';
import { autoFormatFileSize } from '../../utils';
import Icon from '../Common/Icon';
import LoadingSpinner from '../Page/LoadingSpinner';
import { MediaPropType } from './properties';

const MediaListItem = ({ media, onPreview, onDownload, isDownloading }) => {
    const isNamed = media.fileName !== media.name;
    return (
        <div className="media-list__itm">

            <div className="media-list__itm-main">
                <div
                    className="media-list__itm-name"
                    role="button"
                    onClick={() => onPreview(media)}
                >
                    {media.name}
                </div>
                <div className="media-list__itm-actions">
                    {onPreview &&
                    <a
                        className="media-list__itm-action"
                        role="button"
                        onClick={() => onPreview(media)}
                    >
                        Vorschau
                    </a>
                    }
                </div>
            </div>

            <div className="media-list__itm-meta">
                {isNamed &&
                <div className="media-list__itm-file-name" title={media.fileName}>
                    {media.fileName}
                </div>
                }
                <div className="media-list__itm-size">
                    {autoFormatFileSize(media.size)}
                </div>
            </div>

            {onDownload &&
            <a
                className="media-list__itm-download"
                role="button"
                onClick={() => onDownload(media)}
                title="Herunterladen"
            >
                {isDownloading
                    ? <LoadingSpinner />
                    : <Icon name="download" />
                }
            </a>
            }
        </div>
    )
};

MediaListItem.propTypes = {
    media: MediaPropType.isRequired,
    onPreview: PropTypes.func,
    onDownload: PropTypes.func,
};

MediaListItem.defaultProps = {
    onPreview: undefined,
    onDownload: undefined
};

export default MediaListItem;
