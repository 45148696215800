import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from '../Common/Icon';

class ContentReveal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contentIsRevealed: props.contentIsRevealed
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contentIsRevealed !== this.props.contentIsRevealed) {
            this.setState({
                contentIsRevealed: this.props.contentIsRevealed
            });
        }
    }

    toggleContentReveal() {

        this.setState(prevState => ({
            contentIsRevealed: !prevState.contentIsRevealed
        }));
    }

    render() {

        const { contentIsRevealed } = this.state;
        const { showLessLabel, showMoreLabel, intro, children, classes } = this.props;

        const wrapperClasses = ['content-reveal'];

        if (contentIsRevealed) {
            wrapperClasses.push('content-reveal--contentIsRevealed');
        }

        if (classes) {
            const customClasses = classes.split(',');
            wrapperClasses.push(customClasses);
        }

        return (
            <div className={wrapperClasses.join(' ')}>
                {intro &&
                <div
                    className="content-reveal__intro"
                >
                    {intro}
                </div>
                }

                {
                    contentIsRevealed && (
                        <div
                            className="content-reveal__cnt"
                        >
                            {children}
                        </div>
                    )
                }

                <div className="content-reveal__toggle" onClick={() => this.toggleContentReveal()}>
                    {contentIsRevealed ? showLessLabel : showMoreLabel}
                    <span className="content-reveal__toggle-icon">
                        {contentIsRevealed ?
                            <Icon name="chevronUp" />
                            :
                            <Icon name="chevronDown" />
                        }
                    </span>
                </div>
            </div>
        );
    }
}


export default ContentReveal;

ContentReveal.propTypes = {
    intro: PropTypes.node,
    contentIsRevealed: PropTypes.bool,
    showMoreLabel: PropTypes.string,
    showLessLabel: PropTypes.string,
    classes: PropTypes.string
};

ContentReveal.defaultProps = {
    intro: undefined,
    contentIsRevealed: false,
    showMoreLabel: 'Mehr anzeigen',
    showLessLabel: 'Weniger anzeigen',
    classes: ''
};

