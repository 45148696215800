import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    fetchInspectionHistory,
    resetSortOptions,
    setCurrentPageNumber
} from '../../redux/modules/inspection/action';
import EmptyTableHint from '../Common/EmptyTableHint';

import PrimaryColumn from '../Common/PrimaryColumn';
import Pagination from '../Common/Pagination';
import InspectionModal from '../Sections/Detail/InspectionModal';
import InspectionResult from '../Sections/InspectionResult';
import { getInspector, getInspectorCompany } from './helpers';
import InspectionHistoryTableHeader from './InspectionHistoryTableHeader';
import InspectionType from './InspectionType';

class InspectionHistoryTable extends Component {

    constructor(props) {
        super(props);
        this.sectionId = this.props.sectionId;
        this.state = {
            inspectionModalIsOpen: false,
            currentInspectionId: null
        };
        this.onChange = this.onChange.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.toggleInspectionModal = this.toggleInspectionModal.bind(this);
    }

    componentDidMount() {
        this.props.actions.resetSortOptions();
        this.props.actions.setCurrentPageNumber(1);
        this.props.actions.fetchInspectionHistory(this.sectionId);
    }

    onChange() {
        this.props.actions.fetchInspectionHistory(this.sectionId);
    }

    toggleInspectionModal(inspectionId) {
        this.setState(prevState => ({
            inspectionModalIsOpen: !prevState.inspectionModalIsOpen,
            currentInspectionId: inspectionId
        }));
    }

    renderRows(rows) {
        if (this.props.isFetchingList) {
            return (
                <tr>
                    <td colSpan="6">Lade Prüfhistorie ...</td>
                </tr>
            );
        }

        if (!rows.length) {
            return (
                <EmptyTableHint
                    numColumns={5}
                    title="Es wurden noch keine Prüfungen durchgeführt"
                />
            );
        }

        return rows;
    }

    render() {
        const { inspectionModalIsOpen, currentInspectionId } = this.state;
        const { inspections, isFetchingList, amountPerPage } = this.props;
        const rows = [];

        inspections.forEach((inspection) => {
            rows.push((
                <tr key={inspection.id}>
                    <PrimaryColumn
                        link={
                            <a onClick={() => this.toggleInspectionModal(inspection.id)}>
                                {inspection.company}
                            </a>
                        }
                    >
                        <a onClick={() => this.toggleInspectionModal(inspection.id)}>Details</a>
                    </PrimaryColumn>
                    <td>
                        <InspectionType
                            isAcceptanceInspection={inspection.isAcceptanceInspection}
                        />
                    </td>
                    <td>
                        {inspection.controller}
                    </td>
                    <td>
                        {inspection.completed}
                    </td>
                    <td>
                        <InspectionResult
                            errors={inspection.success ? 0 : inspection.answers.filter(item => !item.success).length}
                        />
                    </td>
                </tr>));
        });

        return (
            <React.Fragment>
                <div className="inspections-table">
                    <div className="content-table standalone">
                        <table cellPadding="0" cellSpacing="0" className="hover stack">
                            <InspectionHistoryTableHeader
                                onChange={this.onChange}
                            />
                            <tbody>
                            {this.renderRows(rows)}
                            </tbody>
                        </table>
                        {isFetchingList ||
                        <Pagination
                            total={this.props.total}
                            amountPerPage={amountPerPage}
                            onChange={this.onChange}
                            setPage={this.props.actions.setCurrentPageNumber}
                            currentPage={this.props.currentPage}
                        />
                        }
                    </div>
                </div>

                {inspectionModalIsOpen &&
                <InspectionModal
                    closeModal={this.toggleInspectionModal}
                    inspectionId={currentInspectionId}
                />
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    const inspections = state.inspections.inspectionsList.items || [];

    return {
        total: state.inspections.inspectionsList.total,
        isFetchingList: state.inspections.isFetchingList,
        amountPerPage: state.inspections.amountPerPage,
        currentPage: state.inspections.currentPage,
        /**
         * define the fields, that will relevant for the table
         */
        inspections: inspections.map((inspection) => {
            const dateCompleted = `${moment(inspection.completed)
                .format('DD.MM.YYYY, HH:mm')} Uhr`;


            return {
                id: inspection.id,
                completed: dateCompleted,
                company: getInspectorCompany(inspection),
                controller: getInspector(inspection),
                success: inspection.success,
                answers: inspection.answers || [],
                isAcceptanceInspection: inspection.isAcceptanceInspection,
                isAnonymous: !!inspection.isAnonymous
            };
        })
    };
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchInspectionHistory,
        setCurrentPageNumber,
        resetSortOptions
    }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionHistoryTable);
