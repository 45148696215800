import _ from 'lodash';
import {
    validate as validateSection,
    VALIDATION_MODE_SYNC
} from '../../../Components/Sections/Form/validate';
import { ModelType } from '../../../properties';
import { isEmpty, isListOrObject } from '../../Common/Diff/functions';
import { getFormValues as getSectionFormValues } from '../../Sections/Form/functions';
import { Purpose, PurposeGroup } from '../../Sections/properties';
import { ConstructionPlanModelMode } from '../properties';
import { walkConstructionPlanSite } from '../utils';
import { getInitialScaffoldingFormValues, getScaffoldingSyncData } from './Scaffolding/functions';
import {
    getInitialSectionFormValues,
    getSectionDataFromSyncFormValues,
    getSectionSyncObject
} from './Section/form';
import { getInitialSiteFormValues, getSiteSyncData } from './Site/functions';

const FormSiteKey = 'site';
const FormScaffoldingKey = 'scaffolding';
const FormSectionKey = 'section';

const regexFormKey = /^([\w-]*)\.data\..*$/;
const regexFieldName = /^[\w-]*\.data\.(.*)$/;

const getFormKey = (inputName) => {
    if (!inputName) {
        return;
    }

    const match = inputName.match(regexFormKey);
    if (!match) {
        return undefined;
    }

    if (match.length < 2) {
        return undefined;
    }

    return match[1];
};

export const getFieldName = (inputName) => {
    const match = inputName.match(regexFieldName);
    if (!match || match.length < 2) {
        return inputName;
    }

    return match[1];
};

export const getSyncFormModelData = (formValues, inputName) => {
    const formKey = getFormKey(inputName);
    if (!formKey) {
        return formValues;
    }

    return formValues[formKey]?.data;
};

export const getDefaultInitialFormModelValues = () => ({
    selectedAll: undefined,
    showIdentical: false,
    ignored: false,
    data: {}
});

export const getBasicInitialFormModelValues = (model) => {
    const modelForm = getDefaultInitialFormModelValues();

    // SCAFFFEED-275
    // Added meta field to be able to turn off validation for reference only models
    modelForm.__MODE = model.mode;

    if (model.mode === ConstructionPlanModelMode.New) {
        modelForm.data.id = model.id;
    }

    return modelForm;
};

export const extractFormModelById = (syncForm, id) => {
    return Object.keys(syncForm)
        .filter(formKey => formKey.endsWith(id))
        .map(formKey => syncForm[formKey])[0];
};

export function getInitialFormValue(property, model, modelReferenceData, equalFn, defaultValue = undefined) {
    if (!modelReferenceData || equalFn(property, model.data, modelReferenceData)) {
        return model.data[property] || defaultValue;
    }

    return defaultValue;
}

export function getInitialFormField(property, model, modelReferenceData, equalFn, defaultValue = undefined) {
    const value = getInitialFormValue(property, model, modelReferenceData, equalFn, defaultValue);
    return { [property]: value };
}

export function getFormKeyForModelType(modelType) {
    switch (modelType) {
        case ModelType.Site:
            return FormSiteKey;
        case ModelType.Scaffolding:
            return FormScaffoldingKey;
        case ModelType.Section:
            return FormSectionKey;
        default:
            return '';
    }
}

export function getModelTypeKeyWithIdForForm(modelType, model) {
    const modelKey = getFormKeyForModelType(modelType);
    return modelKey + '-' + model.id;
}

export const getInitialConstructionPlanSyncFormValues = (constructionPlan, siteReferenceData, resolvedMedia) => {

    const { site } = constructionPlan;
    let form = {};

    walkConstructionPlanSite(site, siteReferenceData, (modelType, model, modelReferenceData) => {
        const modelKeyWithId = getModelTypeKeyWithIdForForm(modelType, model);

        switch (modelType) {
            case ModelType.Site:
                form[modelKeyWithId] = getInitialSiteFormValues(model, modelReferenceData, resolvedMedia);
                break;
            case ModelType.Scaffolding:
                form[modelKeyWithId] = getInitialScaffoldingFormValues(model, modelReferenceData, resolvedMedia);
                break;
            case ModelType.Section:
                form[modelKeyWithId] = getInitialSectionFormValues(model, modelReferenceData, resolvedMedia);
                break;
            default:
                // nothing
                break;
        }
    });

    return form;
};

export const isOnlyOneValueSet = (property, modelToGetBack, modelToCompare) => {
    return (!modelToGetBack && modelToCompare && modelToCompare[property])
        || (!modelToCompare && modelToGetBack && modelToGetBack[property])
        //special case if empty array or object
        || (modelToGetBack && isListOrObject(modelToGetBack[property]) && isEmpty(modelToGetBack[property]))
        || (modelToCompare && isListOrObject(modelToCompare[property]) && isEmpty(modelToCompare[property]));
};

export const getModelValue = (planModel, referenceModel, property, fallback = null) => {
    if (!planModel || !property) {
        return fallback;
    }

    if (planModel.mode === ConstructionPlanModelMode.Reference) {
        if (!referenceModel) {
            return fallback;
        }

        if (referenceModel.hasOwnProperty(property)) {
            return referenceModel[property];
        }

        return fallback;
    }

    if (planModel.data && planModel.data.hasOwnProperty(property)) {
        return planModel.data[property];
    }

    return fallback;
};

export const getSyncData = (constructionPlan, siteReferenceData, values) => {

    const { site } = constructionPlan;
    let syncData = {};
    let currentScaffolding = {};

    // Collect model data
    walkConstructionPlanSite(site, siteReferenceData, (modelType, model, modelReferenceData) => {

        switch (modelType) {
            case ModelType.Site:
                syncData = getSiteSyncData(values, site);
                break;
            case ModelType.Scaffolding:
                currentScaffolding = model;
                syncData = {
                    ...syncData,
                    scaffoldings: {
                        ...syncData.scaffoldings,
                        [model.id]: getScaffoldingSyncData(values, model)
                    }
                };
                break;
            case ModelType.Section:
                syncData = {
                    ...syncData,
                    scaffoldings: {
                        ...syncData.scaffoldings,
                        [currentScaffolding.id]: {
                            ...syncData.scaffoldings[currentScaffolding.id],
                            sections: {
                                ...syncData.scaffoldings[currentScaffolding.id].sections,
                                [model.id]: getSectionSyncObject(values, model)
                            }
                        }
                    }
                };
                break;
            default:
                break;
        }
    });

    // Convert scaffoldings and sections maps to array
    syncData.scaffoldings = Object.keys(syncData.scaffoldings)
        .map(scaffoldingId => {
            const scaffolding = syncData.scaffoldings[scaffoldingId];
            scaffolding.sections = Object.keys(scaffolding.sections)
                .map(sectionId => scaffolding.sections[sectionId]);

            return scaffolding;
        });

    return {
        site: syncData
    };
};

const PurposeErrorsMap = {
    groups: 'purposeGroups',
    work: 'workPurpose',
    protective: 'protectivePurposes',
    otherProtective: 'protectivePurposes',
    ladderAscent: 'purposeGroups',
    platformStairway: 'platformStairwayPurpose',
    siteStaircase: 'purposeGroups',
    emergencyStairway: 'emergencyStairwayPurpose'
}

function mapPurposeErrors(sectionErrorObject) {
    if(_.isEmpty(sectionErrorObject)) {
        return;
    }

    const { purpose } = sectionErrorObject;
    if(_.isEmpty(purpose)) {
        return;
    }

    Object.keys(PurposeErrorsMap).forEach(errorMapProperty => {
        if(typeof purpose[errorMapProperty] === 'undefined') {
            return;
        }
        const newErrorKey = PurposeErrorsMap[errorMapProperty];
        const existingErrorValue = sectionErrorObject[newErrorKey];

        let error = purpose[errorMapProperty];
        if(existingErrorValue && error !== existingErrorValue) {
            error = `${existingErrorValue}\n${error}`;
        }

        sectionErrorObject[newErrorKey] = error;
        delete purpose[errorMapProperty];
    });

    if(_.isEmpty(purpose)) {
        delete sectionErrorObject.purpose;
    } else {
        console.error(`Found unmapped section error(s): ${JSON.stringify(purpose)}`);
    }
}

function mapSectionErrorsToSectionSyncErrors(sectionErrors) {
    // Purposes are handled differently in section form vs section sync form
    // so we have to map the errors
    mapPurposeErrors(sectionErrors);
    return sectionErrors;
}

export function validateSyncForm(values) {
    if (!values || _.isEmpty(values)) {
        return;
    }

    const subFormKeys = Object.keys(values);
    const errors = {};
    subFormKeys.forEach(subFormKey => {

        const subFormValues = values[subFormKey] || {};

        // SCAFFFEED-275 Don't validate ignored sub forms and referenced models
        if(subFormValues?.ignored
            || subFormValues.__MODE === ConstructionPlanModelMode.Reference) {
            return;
        }

        const data = subFormValues?.data || {};

        if (subFormKey.startsWith(FormSiteKey)) {
            // no validation necessary
        } else if (subFormKey.startsWith(FormScaffoldingKey)) {
            // no validation necessary
        } else {
            const sectionData = getSectionDataFromSyncFormValues(data);
            const sectionFormData = getSectionFormValues(sectionData);
            const sectionErrors = validateSection(sectionFormData, VALIDATION_MODE_SYNC);
            const sectionSyncErrors = mapSectionErrorsToSectionSyncErrors(sectionErrors);
            const errorKeys = Object.keys(sectionSyncErrors);
            if (errorKeys.length > 0) {
                errors[subFormKey] = { data: sectionSyncErrors }
            }
        }
    })

    if (_.isEmpty(errors)) {
        return;
    }

    return errors;
}
