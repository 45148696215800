import _ from 'lodash';

const questions = [
    {
        name: 'Grundlagen',
        key: 'grundlagen',
        questions: [
            {
                key: 'GERUEST_UNBESCHAEDIGT',
                title: 'Ist das Gerüst augenscheinlich unbeschädigt?',
                deficiencyTitle: 'Augenscheinliche Beschädigung der Gerüstbauteile festgestellt'

            },
            {
                key: 'GERUEST_AUSREICHEND',
                title: 'Ist das Gerüst für die auszuführende Arbeit ausreichend?',
                deficiencyTitle: 'Unzureichendes Gerüst für auszuführenden Arbeiten'

            },
            {
                key: 'GERUEST_GEKENNZEICHNET',
                title: 'Ist das Gerüst an sichtbarer Stelle (z.B. Zugang / Aufstieg) gekennzeichnet?',
                deficiencyTitle: 'Das Gerüst ist nicht an einer sichtbaren Stelle gekennzeichnet'

            },
            {
                key: 'PRUEFUNG_DOKUMENTIERT',
                title: 'Wurden Prüfung und Freigabe vom Gerüstersteller dokumentiert?',
                deficiencyTitle: 'Prüfung und/oder Freigabe wurden vom Gerüstersteller nicht dokumentiert'

            }
        ]
    },
    {
        name: 'Stand- und Tragsicherheit',
        key: 'stand_tragsicherheit',
        questions: [
            {
                key: 'STANDSICHERHEIT_BESTAETIGT',
                title: 'Ist die Stand- und Tragsicherheit zum Zeitpunkt der jeweiligen Inbetriebnahme durch den Auftraggeber bestätigt?',
                deficiencyTitle: 'Die Stand- und Tragsicherheit wurde zum Zeitpunkt Inbetriebnahme durch den Auftraggeber nicht bestätigt'
            },
            {
                key: 'VERSTREBUNGEN_BEACHTET',
                title: 'Wurde auf Verstrebungen/Diagonalen geachtet?',
                deficiencyTitle: 'Wurde auf Verstrebungen/Diagonalen geachtet?'
            },
            {
                key: 'GITTERTRAEGER_BEFESTIGT',
                title: 'Wurden die Gitterträger an den Aussteifungen richtig befestigt?',
                deficiencyTitle: 'Wurde auf Verstrebungen/Diagonalen geachtet?'
            },
            {
                key: 'LAENGSRIEGEL_ANGEBRACHT',
                title: 'Wurden Längsriegel in Fußpunkthöhe angebracht?',
                deficiencyTitle: 'Wurden Längsriegel in Fußpunkthöhe angebracht?'
            },
            {
                key: 'LASTVERTEILENDE_UNTERLAGEN',
                title: 'Lastverteilende Unterlagen vorhanden?',
                deficiencyTitle: 'Keine Lastverteilende Unterlagen vorhanden'
            },
            {
                key: 'ANKERSCHRAUBEN',
                type: 'input',
                title: 'Welche Ankerschraubengröße wurde verwendet?'
            },
            {
                key: 'ANKERPRUEFUNG_DURCHGEFUEHRT',
                title: 'Wurde ein Probeauszug angefertig und das Ergebnis dokumentiert?'
            },
            {
                key: 'VERANKERUNGSPROTOKOLL_HINTERLEGT',
                title: 'Wurde ein Verankerungsprotokoll hinterlegt?'
            },
            {
                key: 'VERANKERUNGEN_NACH_ANWEISUNG_ANGEBRACHT',
                title: 'Wurden die Verankerungen nach Montageanweisungen/ Aufbau- und Verwendungsanleitungen angebracht?',
                deficiencyTitle: 'Die Verankerungen wurde nicht nach Montageanweisungen / Aufbau- und Verwendungsanleitungen angebracht'
            },
            {
                key: 'VERANKERUNG_VOLLSTAENDIG',
                title: 'Wurde die erforderliche Verankerung angebracht und ist diese noch vollständig?',
                deficiencyTitle: 'Erforderliche Verankerung nicht vorhanden oder unvollständig'
            },
            {
                key: 'KRANVERSETZBAR_BAUTEILE_GESICHERT',
                title: 'Sind die Bauteile gesichert?',
                deficiencyTitle: 'Bauteile sind nicht gesichert'
            }
        ]
    },
    {
        name: 'Arbeits- und Betriebssicherheit',
        key: 'arbeits_betriebssicherheit',
        questions: [
            {
                key: 'ZUGAENGE_VORHANDEN',
                title: 'Sind sichere Zugänge, z.B. innenliegende Leitergänge oder Treppen, vorhanden?',
                deficiencyTitle: 'Keine sicheren Zugänge vorhanden'
            },

            {
                key: 'BELAEGE_AUSGELEGT',
                title: 'Ist jede genutzte Gerüstlage vollflächig mit Belägen ausgelegt?',
                deficiencyTitle: 'Gerüstlage ist nicht vollflächig mit Belägen ausgelegt'
            },
            {
                key: 'BELAEGE_IN_ORDNUNG',
                title: 'Sind die Beläge, einschließlich der Konsolenbelag in Ordnung?',
                deficiencyTitle: 'Sind die Systembeläge, einschließlich der Konsolenbelag in Ordnung?',
            },
            {
                key: 'BELAEGE_GESICHERT',
                title: 'Sind die Gerüstbeläge und -bohlen so verlegt, dass sie weder wippen noch ausweichen können und sind sie gegen Abheben gesichert?',
                deficiencyTitle: 'Gerüstbeläge und -bohlen sind nicht gegen wippen, ausweichen und/oder Abheben gesichert'
            },
            {
                key: 'BELAEGE_FREI',
                title: 'Sind die Gerüstbeläge frei von Hindernissen (z.B. Eis und Schnee)?',
                deficiencyTitle: 'Gerüstbeläge sind nicht frei von Hindernissen'
            },
            {
                key: 'BELAEGE_HERUMGEFUEHRT',
                title: 'Ist bei der Einrüstung einer Bauwerksecke der Belag in voller Breite herumgeführt?',
                deficiencyTitle: 'Belag der Bauwerksecke nicht in voller Breite herumgeführt'
            },
            {
                key: 'BELAEGE_OEFFNUNGEN',
                title: 'Sind die Öffnungen zwischen den Haupt- und Konsolenbelägen kleiner als 8 cm?',
                deficiencyTitle: 'Bestehen Öffnungen zwischen den Belägen?'
            },
            {
                key: 'INNENLIEGENDER_SEITENSCHUTZ_ANGEBRACHT',
                title: 'Wurde ein innenliegender Seitenschutz angelegt?',
                deficiencyTitle: 'Wurde ein innenliegender Seitenschutz angelegt?',
            },
            {
                key: 'SEITENSCHUTZ_ABSTURZHOEHE',
                title: 'Sind alle Gerüstlagen bei mehr als 2,00 m Absturzhöhe mit einem 3-teiligen Seitenschutz (Geländeholm, Zwischenholm und Bordbrett) versehen?',
                deficiencyTitle: 'Gerüstlage mit mehr als 2,00 m Absturzhöhe ist nicht mit einem 3-teiligen Seitenschutz versehen'
            },
            {
                key: 'SEITENSCHUTZ_STIRNSEITE',
                title: 'Ist ein 3-teiliger Seitenschutz auch an Stirnseiten und Öffnungen angebracht?',
                deficiencyTitle: 'Gerüstlage mit mehr als 2,00 m Absturzhöhe ist nicht mit einem 3-teiligen Seitenschutz versehen'
            },
            {
                key: 'WANDABSTAND_EINGEHALTEN',
                title: 'Ist ein max. Wandabstand von 30 cm eingehalten? (Wenn nicht, auch hier Seitenschutz)',
                deficiencyTitle: 'Wandabstand ist größer als 30cm und besitzt keinen Seitenschutz'
            },
            {
                key: 'SCHUTZDACH_VORHANDEN_OEFFENTLICH',
                title: 'Ist am Gerüst beim Einsatz in öffentlichen Bereich ein Schutzdach vorhanden?'
            },
            {
                key: 'SCHUTZDACH_VORHANDEN',
                title: 'Besteht ein Schutzdach?'
            },
            {
                key: 'PLANE_BEFESTIGUNG',
                title: 'Wurde ein Plane befestigt und ist die Plane noch (vollständig) am Gerüst befestigt?',
                deficiencyTitle: 'Plane nicht vollständig befestigt'
            }
        ]
    },
    {
        name: 'Fang- und Dachfanggerüste',
        key: 'fang_dachfanggerueste',
        questions: [
            {
                key: 'DFG_BELAG_BREITE',
                title: 'Ist bei Dachfanggerüsten die Belagfläche mindestens 0,60 m breit?',
                deficiencyTitle: 'Dachfanggerüst hat eine zu schmale Belagfläche (< 0,60 m)'
            },
            {
                key: 'DFG_BELAG_TIEFE',
                title: 'Liegt der Belag des Dachfanggerüstes nicht tiefer als 1,50 m unter der Traufkante?',
                deficiencyTitle: 'Belag des Dachfanggerüstes liegt tiefer als 1,50 m unter der Traufkante'
            },
            {
                key: 'SCHUTZWAND_ABSTAND',
                title: 'Beträgt der Abstand zwischen Schutzwand und Traufkante mindestens 0,70 m?',
                deficiencyTitle: 'Abstand zwischen Schutzwand und Traufkante beträgt weniger als 0,70 m'
            },
            {
                key: 'SCHUTZWAND_NETZ',
                title: 'Besteht die Schutzwand aus Netzen oder Geflechten?',
                deficiencyTitle: 'Schutzwand hat weder Netze noch Geflechte'
            },
            {
                key: 'FG_BREITE',
                title: 'Ist bei Fanggerüsten die Belagfläche mindestens 0,90 m breit?',
                deficiencyTitle: 'Fanggerüst hat eine zu schmale Belagfläche (< 0,90 m)'
            },

            {
                key: 'FG_TIEFE',
                title: 'Liegt der Belag des Fanggerüstes nicht tiefer als 2,00 m unter der Absturzkante?',
                deficiencyTitle: 'Belag des Fanggerüstes liegt tiefer als 2,00 m unter der Absturzkante'

            }
        ]
    },
    {
        name: 'Sonstige Anforderungen',
        key: 'sonstiges',
        questions: [
            {
                key: 'SPANNUNGSICHER',
                title: 'Sind spannungsführende Leitungen und/oder Geräte im Gerüstbereich abgeschaltet, abgedeckt oder abgeschrankt?',
                deficiencyTitle: 'Spannungsführende Leitungen oder Geräte im Gerüstbereich sind eingeschaltet, nicht abgedeckt oder nicht abgeschrankt'
            },

            {
                key: 'BELEUCHTUNG_VERKEHR',
                title: 'Ist die Beleuchtung zur Sicherung des öffentlichen Verkehrs gewährleistet?',
                deficiencyTitle: 'Unzureichende Beleuchtung zur Sicherung des öffentlichen Verkehrs'
            },

            {
                key: 'SCHUTZWAND_VORHANDEN',
                title: 'Besteht eine Schutzwand?',
                deficiencyTitle: 'Besteht eine Schutzwand?'
            },

            {
                key: 'FUSSSPINDEL_BEACHTET',
                title: 'Wurden die Fußspindel in den Auszugslängen beachtet?',
                deficiencyTitle: 'Wurden die Fußspindel in den Auszugslängen beachtet?'
            },
            {
                key: 'NICHT_FERTIG_ABGERENZT',
                title: 'Sind nicht fertig gestellte Bereiche abgegrenzt?',
                deficiencyTitle: 'Sind nicht fertig gestellte Bereiche abgegrenzt?'
            },
            {
                key: 'VERBOTSZEICHEN_ANGEBRACHT',
                title: 'Wurde ein Verbotszeichen "Zutritt verboten" an nicht feriggestellte Gerüstabschnitte angebracht?',
                deficiencyTitle: 'Verbotszeichen "Zutritt verboten" fehlt and nicht fertiggestelltem Gerüstabschnitt'
            },
            {
                key: 'TREPPENTUERME_GESICHERT',
                title: 'Wurden vorhandene Treppentürme und Gerüsttreppen gesichert?',
                deficiencyTitle: 'Treppentürme oder Gerüsttreppe nicht gesichert'
            },
            {
                key: 'ANLEGELEITER_ANGEBRACHT',
                title: 'Ist die Anlegeleiter richtig angebracht?',
                deficiencyTitle: 'Anlegeleiter nicht richtig angebracht'
            },

            // ================= NOT USED ATM =======================
            {
                key: 'ZUGANG_ABSTAND_EINGEHALTEN',
                title: 'Wurde die Zugänge im Abstand von 50m eingehalten?',
                deficiencyTitle: 'Wurde die Zugänge im Abstand von 50m eingehalten?',
            },

            // ================= FAHRGERÜST ===========================
            {
                key: 'FAHRROLLEN_ANGEBRACHT',
                title: 'Sind die Fahrrollen sicher und korrekt angebracht?',
                deficiencyTitle: 'Sind die Fahrrollen sicher und korrekt angebracht?'
            },
            {
                key: 'BALLAST_ANGEBRACHT',
                title: 'Ballast / Verbreiterungen',
                deficiencyTitle: 'Ballast / Verbreiterungen'
            },
            {
                key: 'ZUGAENGE_MARKIERT',
                title: 'Wurde das Gerüst an den Zugängen markiert?',
                deficiencyTitle: 'Wurde das Gerüst an den Zugängen markiert?'
            },





            {
                key: 'ANLEGELEITER_ANGEBRACHT',
                title: 'Ist die Anlegeleiter richtig angebracht (5m)',
                deficiencyTitle: 'Ist die Anlegeleiter richtig angebracht (5m)'
            },

            {
                key: 'SCHUTZWAND_VORHANDEN',
                title: 'Besteht eine Schutzwand?',
                deficiencyTitle: 'Besteht eine Schutzwand?'
            },
            {
                key: 'SCHUTZDACH_VORHANDEN',
                title: 'Besteht ein Schutzdach?',
                deficiencyTitle: 'Besteht ein Schutzdach?'
            },

            {
                key: 'VERKEHRSSICHERUNG_EINGERICHTET',
                title: 'Wurde eine Verkehrssicherung und Beleuchtung eingerichtet?',
                deficiencyTitle: 'Wurde eine Verkehrssicherung und Beleuchtung eingerichtet?'
            },

            {
                key: 'GERUEST_BELAEGE_VOLL_AUSGELEGT',
                title: 'Sind die Gerüstlagen voll ausgelegt? Ist eine Belagsicherung vorhanden?',
                deficiencyTitle: 'Sind die Gerüstlagen voll ausgelegt? Ist eine Belagsicherung vorhanden?'
            },

            {
                key: 'SYSTEM_BELAEGE_IN_ORDNUNG',
                title: 'Sind die Systembeläge, einschließlich der Konsolenbelag in Ordnung?',
                deficiencyTitle: 'Sind die Systembeläge, einschließlich der Konsolenbelag in Ordnung?'
            },

            {
                key: 'ECKAUSBILDUNG_HERUMGEFUEHRT',
                title: 'Wurde die Eckausbildung in voller Breite herumgeführt?',
                deficiencyTitle: 'Wurde die Eckausbildung in voller Breite herumgeführt?'
            },

            {
                key: 'GERUESTBODEN_VERLEGT',
                title: 'Wurde der Gerüstboden überall verlegt, inklusive Querschnitt/Auflagerung?',
                deficiencyTitle: 'Wurde der Gerüstboden überall verlegt, inklusive Querschnitt/Auflagerung?'
            },

            {
                key: 'BELAEGE_OEFFNUNGEN',
                title: 'Sind die Öffnungen zwischen den Haupt- und Konsolenbelägen kleiner als 8 cm?',
                deficiencyTitle: 'Bestehen Öffnungen zwischen den Belägen?'
            },

            {
                key: 'FAHRROLLEN_ANGEBRACHT',
                title: 'Sind die Fahrrollen sicher und korrekt angebracht?',
                deficiencyTitle: 'Sind die Fahrrollen sicher und korrekt angebracht?'
            },

            {
                key: 'BALLAST_ANGEBRACHT',
                title: 'Ballast / Verbreiterungen',
                deficiencyTitle: 'Ballast / Verbreiterungen'
            },

            {
                key: 'ZUGAENGE_MARKIERT',
                title: 'Wurde das Gerüst an den Zugängen markiert?',
                deficiencyTitle: 'Wurde das Gerüst an den Zugängen markiert?'
            },

            {
                key: 'NICHT_FERTIG_ABGERENZT',
                title: 'Sind nicht fertig gestellte Bereiche abgegrenzt?',
                deficiencyTitle: 'Sind nicht fertig gestellte Bereiche abgegrenzt?'
            },

            {
                key: 'VERBOTSZEICHEN_ANGEBRACHT',
                title: 'Wurde Verbotszeichen "Zutritt verboten" angebracht?',
                deficiencyTitle: 'Wurde Verbotszeichen "Zutritt verboten" angebracht?'
            }
        ]
    }
];

function hasRole(question, role) {
    return question && role && question.roles && question.roles.indexOf(role) >= 0;
}

export function getFlattenedQuestions(role) {
    let flattenedQuestions = _.flatMap(questions, questionCategory => {
        return questionCategory.questions.map(question => ({
            ...question,
            category: {
                key: questionCategory.key,
                name: questionCategory.name
            }
        }));
    });

    if (role) {
        flattenedQuestions = flattenedQuestions.filter(q => hasRole(q, role));
    }

    return flattenedQuestions;
}

export function getQuestion(key) {
    return _.first(getFlattenedQuestions()
        .filter(question => question.key === key));
}
