import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../../../../Common/Icon';
import SyncInputGroup from '../SyncInputGroup';
import SyncChoiceRow from '../SyncChoiceRow';
import SyncChoiceMediaOption from '../Media/SyncChoiceMediaOption';

const MediaSyncChoiceListItem = ({ planValue, id, label, modelMediaType, liveValue, currentFormValue, onSelect }) => {

    const isSelected = (value) => {

        if (value) {
            //edited media differentiate by mode
            const formV = currentFormValue && currentFormValue[id] || {};
            const { visibility: a, name: b, ...relevantOptionValues } = value;
            const { visibility: c, name: d, ...relevantFormValues } = formV;

            return _.isEqual(relevantOptionValues, relevantFormValues);
        }

        return !currentFormValue ? true : currentFormValue[id] === null;
    };

    const renderMediaLabel = (mediaData, wasEdited) => {

        if (!mediaData) {
            return null;
        }

        const { name, fileName } = mediaData;

        return (
            <div className="sync-mediaoption__media-content">
                <div className="sync-mediaoption__media-content-fileIcon">
                    <Icon name="document" />
                </div>
                <div className="sync-mediaoption__media-content-name">{name}</div>
                {
                    wasEdited ?
                        <div className="sync-mediaoption__media-content-renamed-hint">
                            (Dokument umbenannt)
                        </div>
                        :
                        <div className="sync-mediaoption__media-content-fileName">
                            {fileName}
                        </div>
                }
            </div>
        );
    };

    return (
        <SyncInputGroup
            label={label}
        >
            <SyncChoiceRow
                leftValue={planValue}
                rightValue={liveValue}
                renderValue={renderMediaLabel}
                onSelect={onSelect}
                isSelected={isSelected}
                currentFormValue={currentFormValue && currentFormValue[id]}
                modelMediaType={modelMediaType}
                OptionComponent={SyncChoiceMediaOption}
            />
        </SyncInputGroup>
    );
};

MediaSyncChoiceListItem.propTypes = {
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    showIdentical: PropTypes.bool
};

MediaSyncChoiceListItem.defaultProps = {
    planValue: null,
    liveValue: null,
    value: null,
    onChange: () => false
};

export default MediaSyncChoiceListItem
