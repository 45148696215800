import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModelType } from '../../../../../properties';
import DataRow from '../../../../Common/DataRow';
import Modal from '../../../../Common/Modal/Modal';
import ModalFormFooter from '../../../../Common/ModalFormFooter';
import MediaUploadButton from '../../../../Forms/Media/MediaUploadButton';
import MediaFormModal from '../../../../Forms/Media/Modal/MediaFormModal';
import { getCollectionKey, isMultiUploadAllowed } from '../../../../Forms/Media/utils';
import { getMediaCollectionName } from '../../../../Media/utils';
import { ConstructionPlanModelMode } from '../../../properties';
import { walkConstructionPlanSite } from '../../../utils';
import ConstructionPlanFormGroup from '../../Common/ConstructionPlanFormGroup';
import ConstructionPlanFormGroupTitle from '../../Common/ConstructionPlanFormGroupTitle';
import ConstructionPlanFormModelReferenceHint
    from '../../Common/ConstructionPlanFormModelReferenceHint';
import { ConstructionPlanFormSitePropType } from '../../propTypes';
import { getMediaFormData } from '../utils';
import ConstructionPlanFormMediaList from './ConstructionPlanFormMediaList';

class ConstructionPlanMediaFormModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isInitializing: true,
            pristine: true,
            showUploadModal: false,
            mediaGroups: [],
            resolvedMedia: props.resolvedMedia,
            site: null
        };

        this.initialize = this.initialize.bind(this);

        this.openUploadModal = this.openUploadModal.bind(this);
        this.closeUploadModal = this.closeUploadModal.bind(this);
        this.addMedia = this.addMedia.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillMount(): void {
        this.initialize();
    }

    initialize() {
        const { site } = this.props;
        this.setState({
            isInitializing: false,
            site: _.cloneDeep(site),
        }, () => this.updateMediaGroups());
    }

    updateMediaGroups() {
        const { referenceData } = this.props;
        const { resolvedMedia } = this.state;
        const { site } = this.state;

        this.setState({
            mediaGroups: getMediaFormData(site, referenceData, resolvedMedia)
        });
    }

    openUploadModal() {
        this.toggleUploadModal(true);
    }

    closeUploadModal() {
        this.toggleUploadModal(false);
    }

    toggleUploadModal(showUploadModal) {
        this.setState({
            showUploadModal
        })
    }

    addMedia(mediaItemsToAdd) {

        // Add new items to resolved media
        const { resolvedMedia } = this.state;
        mediaItemsToAdd.forEach(mediaItem => {
            resolvedMedia[mediaItem.id] = mediaItem;
        });

        this.setState({
            resolvedMedia,
            site: this.findAndAddMedia(this.state.site, mediaItemsToAdd)
        }, this.updateMediaGroups());
    }

    updateMediaItem(changedMediaItem) {

        // Update resolved media since new media is persisted directly
        const { resolvedMedia } = this.state;
        if (changedMediaItem.mode === ConstructionPlanModelMode.New) {
            resolvedMedia[changedMediaItem.id] = changedMediaItem;
        }

        // Update site
        this.setState({
            resolvedMedia,
            site: this.findAndUpdateMediaItem(this.state.site, changedMediaItem)
        }, this.updateMediaGroups());
    }

    removeMediaItem(mediaItemToRemove) {
        this.setState({
            site: this.findAndRemoveMediaItem(this.state.site, mediaItemToRemove)
        }, this.updateMediaGroups());
    }

    findAndAddMedia(site, mediaItemsToAdd) {

        if (!mediaItemsToAdd || mediaItemsToAdd.length === 0) {
            return site;
        }

        const newSite = { ...site };

        // We expect that all media items have the same target so we just pick the first one
        const sample = mediaItemsToAdd[0];
        const { modelType, modelId, modelMediaType } = sample;

        const newMediaItems = mediaItemsToAdd.map(mediaItem => ({
            id: mediaItem.id,
            mode: ConstructionPlanModelMode.New
        }));

        this.findAndUpdateMediaCollection(newSite, modelType, modelId, modelMediaType, (mediaCollection, isSingular) => {
            if (isSingular) {
                return newMediaItems[0];
            }

            return [
                ...mediaCollection,
                ...newMediaItems
            ]
        });

        return newSite;
    }

    findAndUpdateMediaItem(site, changedMediaItem) {
        const { modelType, modelId, modelMediaType } = changedMediaItem;

        const updateItem = {
            id: changedMediaItem.id,
            mode: changedMediaItem.mode
        };

        if (changedMediaItem.mode !== ConstructionPlanModelMode.New) {
            updateItem.mode = ConstructionPlanModelMode.Edit;
            updateItem.name = changedMediaItem.name;
        }

        const newSite = { ...site };

        this.findAndUpdateMediaCollection(newSite, modelType, modelId, modelMediaType, (mediaCollection, isSingular) => {

            if (isSingular) {
                return updateItem;
            }

            return mediaCollection.map(media => {
                if (media.id === updateItem.id) {
                    return updateItem;
                }
                return media;
            });
        });

        return newSite;
    }

    findAndRemoveMediaItem(site, mediaItemToRemove) {
        const { modelType, modelId, modelMediaType } = mediaItemToRemove;

        const newSite = { ...site };

        this.findAndUpdateMediaCollection(newSite, modelType, modelId, modelMediaType, (mediaCollection, isSingular) => {

            if (isSingular) {
                return null;
            }

            const newCollection = [
                ...mediaCollection
            ];

            return newCollection.filter(media => {
                return media.id !== mediaItemToRemove.id;
            });
        });

        return newSite;
    }

    findAndUpdateMediaCollection(site, modelType, modelId, modelMediaType, updateCallback) {

        const collectionKey = getCollectionKey(modelMediaType);
        if (!collectionKey) {
            console.error(`Can't find collection key for media type ${modelMediaType}`);
            return site;
        }

        const isSingular = !isMultiUploadAllowed(modelMediaType);

        this.findAndUpdateModel(site, modelType, modelId, (model) => {
            const collection = model.data[collectionKey] || (isSingular ? null : []);
            model.data[collectionKey] = updateCallback(collection, isSingular);
        });
    }

    findAndUpdateModel(site, searchedModelType, searchModelId, updateCallback) {
        walkConstructionPlanSite(site, null, (modelType, model) => {
            if (modelType === searchedModelType && model.id === searchModelId) {
                updateCallback(model);
            }
        });
    }

    onSubmit() {
        this.props.onChange(this.state.site);
    }

    onClose() {
        if (this.state.pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Änderungen verloren')) {
            this.props.onClose();
        }
    }

    render() {
        const { showUploadModal } = this.state;
        const { referenceOptions } = this.props;

        return (
            <Modal
                title="Medien"
                subtitle="Fügen Sie hier Dokumente an, die zu Ihrer Baustelle, den Gerüsten oder Gerüstabschnitten gehören."
                collapseHeader
                classes="construction-plan-media-modal"
                footer={
                    <ModalFormFooter
                        onSubmit={this.onSubmit}
                        onClose={this.onClose}
                        submitLabel="Speichern"
                    />
                }
            >
                <MediaUploadButton
                    onClick={this.openUploadModal}
                />

                {this.renderMediaGroups()}

                {showUploadModal &&
                <MediaFormModal
                    onUploaded={this.addMedia}
                    onClose={this.closeUploadModal}
                    modelReferenceOptions={referenceOptions}
                    isConstructionPlan
                />
                }
            </Modal>
        );
    }

    renderMediaGroups() {
        const { mediaGroups } = this.state;
        if (!mediaGroups) {
            return null;
        }

        return mediaGroups.map(mediaGroup => this.renderMediaGroup(mediaGroup));
    }

    renderMediaGroup(mediaGroup) {
        const { type, id, mode, name, media } = mediaGroup;

        return (
            <ConstructionPlanFormGroup
                key={id}
                wrapperClass={`media-group media-group--${type}`}
                dashedBorder={type !== ModelType.Site}
                title={
                    <ConstructionPlanFormGroupTitle
                        type={type}
                    >
                        {name}
                    </ConstructionPlanFormGroupTitle>
                }
            >
                {mode === ConstructionPlanModelMode.Reference &&
                <ConstructionPlanFormModelReferenceHint/>}
                {this.renderMediaGroupCollections(media, id, mode)}
            </ConstructionPlanFormGroup>
        )
    }

    renderMediaGroupCollections(modelMedia, parentId, parentMode) {

        if (!modelMedia) {
            return null;
        }

        return Object.keys(modelMedia).map(collectionKey => {
            const collection = modelMedia[collectionKey];
            return (
                <DataRow label={getMediaCollectionName(collectionKey)} key={collectionKey}>
                    <ConstructionPlanFormMediaList
                        media={collection}
                        parentMode={parentMode}
                        onChange={(changedMedia) => this.updateMediaItem(changedMedia)}
                        onDelete={(media) => this.removeMediaItem(media)}
                    />
                </DataRow>
            )
        });
    }
}

ConstructionPlanMediaFormModal.propTypes = {
    site: ConstructionPlanFormSitePropType.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    referenceData: PropTypes.object,
    resolvedMedia: PropTypes.object,
    referenceOptions: PropTypes.array,
};

export default ConstructionPlanMediaFormModal;
