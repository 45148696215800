import moment from 'moment';
import { toast } from 'react-toastify';
import { BillingTypes } from '../../../Components/Account/BillingDetailsForm';
import history from '../../../history';
import { formatExpirationMonth } from '../../../utils';
import callApi, { mutate, query } from '../../action';
import { isAccountOwner, subscriptionHasExpired } from '../../helper';
import { queries } from '../../queries';
import * as types from '../../types';

export function fetchLoadSpinner(isLoading) {
    return {
        type: types.FETCH_LOAD_SPINNER,
        payload: isLoading
    };
}

// Login
export function login() {
    return {
        type: types.LOGIN
    };
}

export function loginSuccess({ access_token, expires_in }) {
    const payload = {
        access_token,
        expiration_time: (expires_in * 1000) + moment()
            .valueOf()
    };

    return {
        type: types.LOGIN_SUCCESS,
        payload
    };
}

export const mainSubscriptionHasExpired = () => ({
    type: types.MAIN_SUBSCRIPTION_HAS_EXPIRED
});

export const hideExpirationModal = () => ({
    type: types.HIDE_EXPIRATION_MODAL
});

// Logout
export function logout() {
    return {
        type: types.LOGOUT
    };
}

export function fetchUserSuccess(data) {
    return {
        type: types.FETCH_USER_SUCCESS,
        payload: data.user
    };
}

export function fetchUserFailure(err) {
    return {
        type: types.FETCH_USER_FAILURE,
        error: err
    };
}

// Register
export function register() {
    return {
        type: types.REGISTER
    };
}

export function registerSuccess() {
    return {
        type: types.REGISTER_SUCCESS
    };
}

// verify
export function verify() {
    return {
        type: types.VERIFY
    };
}

export function verifySuccess() {
    return {
        type: types.VERIFY_SUCCESS
    };
}

/**
 *
 * @returns {{type: string}}
 */
export const refreshTokenRequesting = () => ({
    type: types.REFRESH_TOKEN_REQUESTING
});

/**
 *
 * @param data: access_token, expires_in
 * @returns {{type: string, payload: {expires_in: number}}}
 */
export const refreshTokenSuccess = ({ access_token, expires_in }) => {
    const payload = {
        access_token,
        expiration_time: (expires_in * 1000) + moment()
            .valueOf()
    };

    return {
        type: types.REFRESH_TOKEN_SUCCESS,
        payload
    };
};

/**
 *
 * @param error
 * @returns {{type: string, payload: *}}
 */
export const refreshTokenFailure = error => ({
    type: types.REFRESH_TOKEN_FAILURE,
    payload: error
});

export const fetchAndUpdateUser = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const queryString = queries.fetchUser;

        return query(queryString, {
            token
        })
            .then((user) => {
                dispatch(fetchUserSuccess(user));
                return user;
            })
            .catch((error) => {
                dispatch(fetchUserFailure(error));
            });
    }
);

export const fetchAccountDetails = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const queryString = 'FetchUser{user{email,id,phone,firstName,lastName,email,type,account{type,company{id,name,phone, email, addressLine1,addressLine2,addressZip,addressCity }  }}}';

        return query(queryString, {
            token
        })
            .then(accountDetails => accountDetails.user);
    }
);

export function updateUser(userId, userData) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi(`v1/users/${userId}`, {
            method: 'PUT',
            token,
            body: userData
        })
            .then(response => (response.json()))
            .then(result => (result));
    };
}

export function deleteUser(userId) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi(`v1/users/${userId}`, {
            method: 'DELETE',
            token
        })
            .then(() => (Promise.resolve()));
    };
}

export function createUser(userData) {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        return callApi('v1/users', {
            method: 'POST',
            token,
            body: userData
        })
            .then(response => (response.json()))
            .then(result => result);
    };
}


export const registerUser = userData => (
    (dispatch) => {
        dispatch(register());
        return callApi('v1/auth/sign-up', {
            method: 'POST',
            body: userData,
            includeCredentials: true
        })
            .then(response => response.json())
            .then((result) => {
                dispatch(registerSuccess());
                return result;
            });
    }
);

export const updateMyself = userData => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        dispatch(register());

        return callApi('v1/me', {
            method: 'PUT',
            token,
            body: userData
        })
            .then(response => response.json())
            .then(result => (result));
    }
);

export const verifyUser = token => (
    (dispatch) => {
        dispatch(verify());
        return callApi(`v1/auth/sign-up/verify/${token}`, {
            method: 'POST',
            includeCredentials: true
        })
            .then(response => response.json())
            .then(data => data);
    }
);

/**
 *
 * @param credentials: email & password from loginPage
 * @returns {Function} sends request with users email and password,
 * if success => saves access token to the services store
 */
export function loginUser(credentials, redirectTo) {
    return (dispatch, getState) => {
        dispatch(login());

        return callApi('v1/auth/login', {
            method: 'POST',
            body: credentials,
            includeCredentials: true
        })

            .then(response => response.json())
            .then((result) => {
                const token = result.access_token;
                const queryString = queries.fetchUser;

                return query(queryString, {
                    token
                })
                    .then((user) => {
                        dispatch(fetchUserSuccess(user));
                        dispatch(loginSuccess(result));

                        if (subscriptionHasExpired(getState) && isAccountOwner(getState())) {
                            dispatch(mainSubscriptionHasExpired());
                        }

                        if (redirectTo) {
                            history.push(redirectTo.pathname);
                        }
                    });
            });
    };
}

/**
 *
 * @param credentials: user email
 * * @returns {Function} sends credentials to the server, if success => goes to the login page, else => stays on the page, show error
 *
 */
export function forgotPassword(credentials) {
    return (dispatch) => {
        dispatch(fetchLoadSpinner(true));

        return callApi('v1/auth/forgotpassword', {
            method: 'POST',
            body: credentials
        })
            .then(response => response.json())
            .then(() => {
                history.push('/login');
                toast.success('Wir haben Ihnen eine E-Mail mit Instruktionen zum Zurücksetzen Ihres Passworts geschickt', { autoClose: 2500 });
            });
    };
}


/**
 * @returns {Function} post request
 * if success => set store to initial state
 * if error => get error
 */
export function logoutUser() {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        callApi('v1/auth/logout', {
            method: 'POST',
            token
        })
            .catch();

        dispatch(logout());
        history.push('/login');
    };
}

/**
 *
 * @param data: token from url and new password
 * @returns {Function} sends data to the server, if success => goes to the login page, else => stays on the page
 */
export function resetPassword(data) {

    return () => callApi('v1/auth/resetpassword', {
        method: 'POST',
        body: data
    })
        .then(response => response.json())
        .then(result => result);
}


export function acceptInvitation(data) {

    return () => callApi('v1/auth/resetpassword', {
        method: 'POST',
        body: data
    })
        .then(response => response.json())
        .then(result => result);
}

/**
 *
 * @param sourceId
 * @returns {function(*, *): (Promise<any> | Promise | * | Promise<T>)}
 */
export const addBillingType = (sourceId) => {
    const args = {
        sourceId: sourceId ? `id:"${sourceId}",` : ''
    };

    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const queryString = `{ 
            addBillingType(${args.sourceId} isDefaultSource: true) {
                ... on Invoice {type}
                ... on Card {
                    id
                }
                ... on SepaDebit {
                    id, 
                    type, 
                    sepaDebit { 
                        bankCode, 
                        country, 
                        last4, 
                        mandateUrl, 
                        mandateReference
                    }
                }
            }
        }`;

        return mutate(queryString, [], {
            token
        });
    };
};

/**
 * @returns {function(*, *): *}
 */
export const getBillingData = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `{
            billingType {
                ... on Invoice {
                    type
                }
                ... on Card {
                    id,
                    type,
                    card {
                        brand,
                        last4,
                        expMonth,
                        expYear
                    }
                    owner {
                        name
                    }
                }
                ... on SepaDebit {
                    id, 
                    type, 
                    sepaDebit { 
                        bankCode, 
                        country, 
                        last4,
                        mandateUrl, 
                        mandateReference
                    }
                    owner {
                        name
                    }
                } 
            }
        }`;
        return query(queryString, { token })
            .then(data => data.billingType);
    }
);

export const isBillingTypeInvoice = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `{
            billingType {
                ... on Invoice {
                    type
                }
            }
        }`;
        return query(queryString, { token })
            .then((data) => {
                if (!data) {
                    return false;
                }

                if (!data.billingType) {
                    return false;
                }

                return data.billingType.type === 'invoice';
            });
    }
);

export const updateDebit = (oldSourceId, newSourceId) => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `{updateSepaDebit(id:"${newSourceId}",oldId:"${oldSourceId}", isDefaultSource: true){ id }}`;

        return mutate(queryString, [], {
            token
        });
    }
);

export const acceptTerms = version => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const query = 'AcceptTerms($version: String!) {terms: acceptTerms(version: $version)}';

        const params = {
            version
        };

        return mutate(query, params, { token });
    }
);

export const acceptADV = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const query = 'AcceptADV{acceptADV}';

        return mutate(query, null, { token });
    }
);

export const markNotificationAsRead = (id) => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const query = 'MarkNotificationAsRead($id: String!){markNotificationAsRead(id: $id)}';

        const params = {
            id
        };

        return mutate(query, params, { token });
    }
);

export const createPaymentMethodSecret = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const mutationString = 'CreatePaymentMethodSecret{createPaymentMethodSecret}';

        return mutate(mutationString, null, { token })
            .then(data => data.createPaymentMethodSecret);
    }
);

export const addPaymentMethod = (paymentMethodId) => {
    return (dispatch, getState) => {
        const token = getState().currentUser.auth_token;
        const params = {
            id: paymentMethodId,
            isDefaultSource: true
        };
        const mutationString = `AddPaymentMethod($id: String!, $isDefaultSource: Boolean!){ 
            addPaymentMethod(id: $id, isDefaultSource: $isDefaultSource) {
                    ...on Card {
                        id
                    }
            }
        }`;

        return mutate(mutationString, params, {
            token
        })
            .then(data => data.addPaymentMethod);
    };
};

export const updatePaymentMethodCard = (cardId, expMonth, expYear) => (

    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const mutationQuery = `UpdatePaymentMethodCard($id: String!, $isDefaultSource: Boolean, $expMonth: Int, $expYear: Int){updatePaymentMethodCard(id: $id, isDefaultSource: $isDefaultSource, expMonth: $expMonth, expYear: $expYear){ id }}`;
        const params = {
            id: cardId,
            isDefaultSource: true,
            expMonth: formatExpirationMonth(expMonth),
            expYear
        };

        return mutate(mutationQuery, params, {
            token
        })
            .then(data => data.updatePaymentMethodCard);
    }
);

export const getPaymentMethod = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `{
            paymentMethod {
                ... on Card {
                    id,
                    type,
                    card {
                        brand,
                        last4,
                        expMonth,
                        expYear
                    }
                    owner {
                        name
                    }
                }
            }
        }`;
        return query(queryString, { token })
            .then(data => data.paymentMethod);
    }
);

export const hasLegacyCreditCardAsBilling = () => (
    (dispatch, getState) => {
        const token = getState().currentUser.auth_token;

        const queryString = `{
            billingType {
                ... on Card {
                    id,
                    type
                }
            }
        }`;

        return query(queryString, { token })
            .then((data) => {

                if (!data) {
                    return false;
                }

                if (!data.billingType) {
                    return false;
                }

                return data.billingType.type === BillingTypes.Card && !!data.billingType.id;
            });
    }
);
