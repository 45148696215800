import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { getPurposeLabel } from '../../../../../Sections/labels';
import { Purpose } from '../../../../../Sections/properties';
import asSyncChoiceField from '../../../asSyncChoiceField';
import SyncChoiceDiffOptionRows from '../../../Base/Input/SyncChoiceDiffOptionRows';
import SyncChoiceIdenticalRow from '../../../Base/Input/SyncChoiceIdenticalRow';
import SyncInputGroup from '../../../Base/Input/SyncInputGroup';
import { isObjectIdenticalValue } from '../../../equals';
import { getSyncFormModelData } from '../../../form';
import { validateListChoiceComplete } from '../../../validation';
import { isConfigItemSelected } from '../../form';
import {
    getDiffProtectivePurposeOptionRows,
    getIdenticalProtectivePurposeOptionRows
} from '../functions';

const validateProtectivePurpose = (value, planValue, liveValue, allValues, meta) => {
    const formModelData = getSyncFormModelData(allValues, meta.name);
    const { purposeGroups, protectivePurposes } = formModelData;

    const hasSelectedOptions = isConfigItemSelected(protectivePurposes);
    const isProtectiveScaffolding = !!purposeGroups?.protective_scaffolding;

    // Some protective options have been chosen but the protective group choice is not selected
    if(!isProtectiveScaffolding && hasSelectedOptions) {
        return 'Bitte wählen Sie den Verwendungszweck “Schutzgerüst” aus.';
    } else if(isProtectiveScaffolding && !hasSelectedOptions) {
        return 'Bitte wählen Sie eine Schutzgerüst-Option aus.';
    }

    return validateListChoiceComplete()(value, planValue, liveValue, allValues, meta, isProtectiveScaffolding);
};

const ProtectivePurposeSyncChoice = ({ planValue, liveValue, value: currentFormValue, onChange, showIdentical }) => {

    const onSelect = (selectedValue) => {
        const newValue = {
            ...currentFormValue,
            ...selectedValue
        };

        onChange(newValue);
    };

    const isSelected = (option) => {
        if (!currentFormValue || !option) {
            return false;
        }

        const optionKey = Object.keys(option)[0];
        return optionKey in currentFormValue && option[optionKey] === currentFormValue[optionKey];
    };

    const renderProtectivePurposeLabel = (value) => {
        if (!value) {
            return '-';
        }

        if (value[Purpose.SafetyCatchScaffolding]) {
            return getPurposeLabel(Purpose.SafetyCatchScaffolding);
        } else if (value[Purpose.RoofSafetyCatchScaffolding]) {
            return getPurposeLabel(Purpose.RoofSafetyCatchScaffolding);
        } else if (value[Purpose.OtherProtectiveScaffolding]) {
            return `Andere: ${value[Purpose.OtherProtectiveScaffolding]}`;
        } else {
            return '-';
        }
    };

    const renderIdenticalRows = () => {

        if (_.isEmpty(planValue) && _.isEmpty(liveValue)) {
            return (
                <SyncChoiceIdenticalRow
                    key="work-purpose"
                    leftValue={'-'}
                    rightValue={'-'}
                    renderValue={a => a}
                    showIdentical={showIdentical}
                />
            );
        }

        const identicalOptionRows = getIdenticalProtectivePurposeOptionRows(planValue, liveValue);
        if (_.isEmpty(identicalOptionRows)) {
            return null;
        }

        return identicalOptionRows.map(optionRow => (
            <SyncChoiceIdenticalRow
                key={optionRow.key}
                leftValue={optionRow.left}
                rightValue={optionRow.right}
                renderValue={renderProtectivePurposeLabel}
                showIdentical={showIdentical}
            />
        ));
    };

    const isIdentical = isObjectIdenticalValue(planValue, liveValue);
    if (isIdentical && !showIdentical) {
        return null;
    }

    return (
        <SyncInputGroup
            label="Schutzgerüst"
            subLevel
        >
            {showIdentical && renderIdenticalRows()}

            <SyncChoiceDiffOptionRows
                planValue={planValue}
                liveValue={liveValue}
                getOptionRows={getDiffProtectivePurposeOptionRows}
                renderValue={renderProtectivePurposeLabel}
                onSelect={onSelect}
                isSelected={isSelected}
            />
        </SyncInputGroup>
    );
};

ProtectivePurposeSyncChoice.propTypes = {
    planValue: PropTypes.object,
    liveValue: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    showIdentical: PropTypes.bool
};

ProtectivePurposeSyncChoice.defaultProps = {
    planValue: null,
    liveValue: null,
    value: null,
    onChange: () => false
};

export default asSyncChoiceField(ProtectivePurposeSyncChoice, {
    allowNull: true,
    validate: validateProtectivePurpose
});
