import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../Common/Icon';
import { getPublicStatusLabel, getStatusLabel } from './labels';

const getSectionStatusIcon = (status) => {
    switch (status) {
        case 'constructed':
        case 'open':
            return <Icon name="stateok" />;
        case 'closed':
        case 'under_maintenance':
        case 'being_altered':
        case 'under_construction':
            return <Icon name="stateclosed" />;
        case 'dismantled':
            return <Icon name="statedismantled" />;
        case 'unknown':
        default:
            return null;
    }
}

const SectionStatusBadge = ({ section: { status, publicStatus, managed, isThirdParty }, internalOnly, showIcon, small }) => {

    const statusLabel = internalOnly || (managed && !isThirdParty) ? getStatusLabel(status) : getPublicStatusLabel(publicStatus);

    const classNames = [
        'section-status-badge',
        `section-status-badge--${status || publicStatus}`
    ]

    let icon = null;
    if(showIcon) {
        icon = getSectionStatusIcon(status || publicStatus);
    }
    if(icon) {
        classNames.push('section-status-badge--icon')
    }

    if(!small) {
        classNames.push('section-status-badge--big');
    }

    return (
        <span
            className={classNames.join(' ')}
        >
            {icon &&
                <span className="section-status-badge__icon">
                    {icon}
                </span>
            }
            {statusLabel}
        </span>
    );
};

SectionStatusBadge.propTypes = {
    section: PropTypes.shape({
        status: PropTypes.string,
        publicStatus: PropTypes.string,
        managed: PropTypes.bool,
        isThirdParty: PropTypes.bool
    }).isRequired,
    internalOnly: PropTypes.bool,
    showIcon: PropTypes.bool,
    small: PropTypes.bool
};

SectionStatusBadge.defaultProps = {
    internalOnly: false,
    showIcon: false,
    small: false
}

export default SectionStatusBadge;
