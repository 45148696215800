import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError, showTempError } from '../../../redux/modules/error/action';
import { addNewSite, fetchSiteFormData, updateSite } from '../../../redux/modules/site/action';
import { BadRequestError } from '../../../Services/ApiError';
import ErrorService from '../../../Services/ErrorService';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import ResourceNotFoundModal from '../../Common/Modal/ResourceNotFoundModal';
import { FormMode, FormModePropType } from '../../Forms/properties';
import LoadingSpinner from '../../Page/LoadingSpinner';
import SiteFormContent from './SiteFormContent';
import { getAllInputs, getFormValues, getSubmitPayload, validate } from './utils';

const focusOnError = createDecorator(getAllInputs);

const initialFormValues = {
    name: '',
    invitees: [],
    attachments: []
};

class SiteFormModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            mode: this.props.mode,
            siteId: this.props.id,
            site: null,
            formValues: {
                ...initialFormValues
            },
            isInitializing: this.props.mode === FormMode.Edit,
            isUploading: false,
            isSaving: false
        };

        this.initializeForm = this.initializeForm.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
        this.onUploading = this.onUploading.bind(this);
    }

    componentWillMount() {
        const { mode, siteId } = this.state;

        if (mode === FormMode.Create) {
            return;
        }

        this.props.actions.fetchSiteFormData(siteId)
            .then((siteData) => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.initializeForm(siteData);
            });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onUploading(isUploading) {
        this.setState({
            isUploading
        });
    }

    handleFormSubmissionError(error) {

        if (error instanceof BadRequestError) {
            return ErrorService.handleBadRequestError(error, 'site')
                .then(errorMessages => (showTempError(Object.values(errorMessages)[0])));
        }

        showApiError(error);

    }

    initializeForm(site) {
        if (!site) {
            this.setState({
                site: null,
                formValues: {},
                isInitializing: false
            });
            return;
        }

        const formValues = getFormValues(site);

        this.setState({
            site,
            formValues,
            isInitializing: false
        });
    }

    onSubmit(values) {

        const payload = getSubmitPayload(values);

        this.setState({ isSaving: true });

        if (this.state.mode === FormMode.Edit) {
            const { siteId } = this.state;
            return this.props.actions.updateSite(siteId, payload)
                .then((site) => {
                    this.setState({
                        isSaving: false
                    });
                    toast.success('Änderungen wurden gespeichert', { autoClose: 2500 });

                    return site.id;
                })
                .then((id) => {
                    if (id && typeof this.props.onUpdated === 'function') {
                        this.props.onUpdated(id);
                    }
                })
                .catch((error) => {
                    this.handleFormSubmissionError(error);
                })
                .finally(() => {

                    if (this.loadingGotCancelled) {
                        return;
                    }

                    this.setState({
                        isSaving: false
                    });
                });
        }
        return this.props.actions.addNewSite(payload)
            .then((site) => {
                this.setState({
                    isSaving: false
                });
                toast.success('Baustelle wurde angelegt', { autoClose: 2500 });

                return site;

            })
            .then((site) => {
                if (site.id && typeof this.props.onCreated === 'function') {
                    this.props.onCreated(site.id, values.createScaffolding);
                }

            })
            .catch((error) => {
                this.handleFormSubmissionError(error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isSaving: false
                });
            });
    }

    renderButtonText() {
        const { mode, isSaving } = this.state;

        if (mode === FormMode.Edit) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }

        return isSaving ? 'Baustelle wird gespeichert ...' : 'Baustelle speichern';
    }

    onClose(pristine) {
        if (pristine) {
            this.props.onClose();
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            this.props.onClose();
        }
    }

    render() {
        const {
            mode,
            site,
            formValues,
            isSaving,
            isInitializing,
            isUploading
        } = this.state;

        if (mode === FormMode.Edit && !isInitializing && !site) {
            return (
                <ResourceNotFoundModal
                    title="Baustelle nicht gefunden"
                    onClose={this.props.onClose}
                />
            );
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                decorators={[focusOnError]}
                initialValues={formValues}
                keepDirtyOnReinitialize
                validate={validate}
                mutators={{
                    setAttachments: ([newAttachments], state, utils) => {
                        utils.changeValue(state, 'attachments', () => newAttachments);
                    }
                }}
                render={({ values, handleSubmit, pristine, form: { mutators }, submitError }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            title={mode === FormMode.Create ? 'Baustelle anlegen' : 'Baustelle bearbeiten'}
                            id="site-modal"
                            footer={
                                <ModalFormFooter
                                    onSubmit={handleSubmit}
                                    isSubmitting={isSaving}
                                    onClose={() => this.onClose(pristine)}
                                    submitLabel={this.renderButtonText()}
                                    submitDisabled={isSaving || isInitializing || isUploading}
                                />
                            }
                            loading={isInitializing}
                            loadingLabel="Lade Baustellendaten"
                        >

                            {submitError && <div className="form-error">{submitError}</div>}
                            <SiteFormContent
                                mode={mode}
                                values={values}
                                attachmentsMutator={mutators.setAttachments}
                                onUploading={this.onUploading}
                            />
                        </Modal>
                    </form>
                )}
            />
        );
    }

}

SiteFormModal.propTypes = {
    mode: FormModePropType.isRequired,
    id: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func
};

SiteFormModal.defaultProps = {
    mode: FormMode.Create,
    id: null,
    onCreated: null,
    onUpdated: null,
    onClose: () => false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        addNewSite,
        fetchSiteFormData,
        updateSite
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(SiteFormModal);
