import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import { bindActionCreators } from 'redux';
import ArchivedBadge from '../../../Components/Common/Badge/ArchivedBadge';
import Permission from '../../../permissions';
import { showApiError, showTempError } from '../../../redux/modules/error/action';
import { deleteSingleSite } from '../../../redux/modules/site/action';
import { fetchSites } from '../../../redux/modules/sites/action';
import { LockedByException } from '../../../Services/ApiError';
import DateService from '../../../Services/DateService';
import ErrorService from '../../../Services/ErrorService';
import { UserType } from '../../../user';
import { AddButton } from '../../Common/Button/AddButton';
import DeleteModal from '../../Common/Modal/DeleteModal';
import EmptyTableHint from '../../Common/EmptyTableHint';
import IfUser from '../../Common/IfUser';
import InvitedIndicator from '../../Common/InvitedIndicator';
import NotAvailablePlaceholder from '../../Common/NotAvailablePlaceholder';
import Pagination from '../../Common/Pagination';
import PaginationTotal from '../../Common/PaginationTotal';
import { SitePageLink } from '../../Common/ResourcePageLink';
import ThirdPartyIndicator from '../../Common/ThirdPartyIndicator';
import styles from '../../Forms/Select/styles';
import TableHiddenActions from '../../Layout/TableHiddenActions';
import LoadingSpinner from '../../Page/LoadingSpinner';
import PageTitle from '../../Page/PageTitle';
import SiteFormModal from '../Form/SiteFormModal';
import SitesTableHeader from '../Overview/SitesTableHeader';
import {ModalTypes} from '../../Common/AddResourceDropDown';

export const SitesListViewOption = {
    ShowActiveSites: 'show_active_sites',
    ShowArchivedSites: 'show_archived_sites'
};

const urlPropsQueryConfig = {
    archived: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'archived'
    },
    page: {
        type: UrlQueryParamTypes.number,
        updateType: 'replaceIn',
        queryParam: 'page'
    },
    sortBy: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'sortBy'
    },
    sortAsc: {
        type: UrlQueryParamTypes.string,
        updateType: 'replaceIn',
        queryParam: 'sortAsc'
    }
};

export const SitesListViewSelectOptions = [
    {
        label: 'Aktive Baustellen',
        value: SitesListViewOption.ShowActiveSites
    },
    {
        label: 'Archivierte Baustellen',
        value: SitesListViewOption.ShowArchivedSites
    }
];

const initialSortFieldActiveSites = {
    field: 'name',
    direction: '+'
};

const initialSortFieldArchivedSites = {
    field: 'archivedAt',
    direction: '-'
};

const getInitialState = (props) => {

    return {
        isFetchingListItems: false,
        listItems: [],
        listItemsInTotal: 0,
        sortListBy: props.archived ? initialSortFieldArchivedSites : initialSortFieldActiveSites,
        listItemsPerPage: 25,
        currentListPage: 1,
        deleteModalIsOpen: false,
        isDeletingListItem: false,
        listItemToDelete: null,
        sitesListViewOption: SitesListViewOption.ShowActiveSites,
        showAddModal: false
    };

};

const getInitialSortListState = (sortAsc, sortBy, archived) => {

    const defaultSortBy = archived ? initialSortFieldArchivedSites.field : initialSortFieldActiveSites.field;
    const defaultSortDirection = archived ? initialSortFieldArchivedSites.direction : initialSortFieldActiveSites.direction;

    return {
        field: sortBy || defaultSortBy,
        direction: sortAsc !== undefined && sortAsc !== 'false' ? '+' : defaultSortDirection
    };
};

class SitesList extends Component {

    constructor(props) {
        super(props);

        this.loadingGotCancelled = false;

        this.state = getInitialState(props);

        this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
        this.deleteSite = this.deleteSite.bind(this);
        this.updateList = this.updateList.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.setCurrentListPage = this.setCurrentListPage.bind(this);
        this.onSiteCreated = this.onSiteCreated.bind(this);
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    getFetchParams() {
        const {
            sortListBy, currentListPage, listItemsPerPage
        } = this.state;

        return {
            sortListBy,
            listItemsPerPage,
            currentListPage
        };
    }

    updateList() {

        const { actions: { fetchSites } } = this.props;
        const { sitesListViewOption } = this.state;

        const fetchParams = this.getFetchParams();

        const archived = sitesListViewOption === SitesListViewOption.ShowArchivedSites;

        this.setState({
            isFetchingListItems: true
        });

        fetchSites(fetchParams, archived)
            .then((listData) => {

                if (this.loadingGotCancelled) {
                    return;
                }
                const { data: listItems, total: listItemsInTotal } = listData;

                this.setState({
                    listItemsInTotal,
                    listItems
                });

            })
            .catch(error => {
                showApiError(error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isFetchingListItems: false
                });
            });
    }

    resetQueryParameters() {

        const { onChangeUrlQueryParams } = this.props;

        onChangeUrlQueryParams({
            sortBy: null,
            sortAsc: null,
            page: null
        });
    }


    initializeList() {
        const {
            sortAsc, sortBy, archived, page
        } = this.props;

        this.setState({
            sortListBy: getInitialSortListState(sortAsc, sortBy, archived),
            currentListPage: page || 1,
            sitesListViewOption: archived ? SitesListViewOption.ShowArchivedSites : SitesListViewOption.ShowActiveSites
        }, () => this.updateList());

    }

    resetList(archived) {
        this.resetQueryParameters();
        this.setState({
            currentListPage: 1,
            sortListBy: getInitialSortListState(undefined, undefined, archived)
        }, () => this.updateList());
    }

    componentDidMount() {
        this.initializeList();
    }

    onSiteCreated(siteId, createScaffoldingNext) {
        this.setState({ showAddModal: false });
        const routeState = {
            siteId
        };
        if (createScaffoldingNext) {
            routeState.openModal = {
                type: ModalTypes.Scaffolding
            };
        }

        this.props.history.push(`/sites/${siteId}`, routeState);
    }

    onSortChange(updatedSortByField, updatedSortDirection) {

        const { sortListBy: { field, direction } } = this.state;

        if (field !== updatedSortByField) {
            this.props.onChangeSortBy(updatedSortByField);
            this.setState(prevState => ({
                sortListBy: {
                    field: updatedSortByField,
                    direction: prevState.sortListBy.direction
                }
            }), () => this.updateList());
        }

        if (direction !== updatedSortDirection) {
            this.props.onChangeSortAsc(updatedSortDirection === '-' ? false : true);
            this.setState(prevState => ({
                sortListBy: {
                    field: prevState.sortListBy.field,
                    direction: updatedSortDirection
                }
            }), () => this.updateList());
        }
    }

    deleteSite() {
        const { listItemToDelete } = this.state;

        if (!listItemToDelete) {
            return;
        }

        const {
            actions: { deleteSingleSite }
        } = this.props;

        this.setState({
            isDeletingListItem: true
        });

        deleteSingleSite(listItemToDelete.id)
            .then(() => {
                this.setState({
                    isDeletingListItem: false
                });
                this.toggleDeleteModal();
                toast.success('Baustelle wurde gelöscht', { autoClose: 2500 });
                this.updateList();
            })
            .catch((error) => {
                this.setState({
                    isDeletingListItem: false
                });

                if (error instanceof LockedByException) {
                    return ErrorService.handleLockedByError(error, 'siteLocked')
                        .then(({ error }) => (showTempError(error)));
                }
                return showApiError(error);
            });
    }

    toggleDeleteModal(site = null) {
        if (site) {
            this.setState({
                deleteModalIsOpen: true,
                listItemToDelete: site
            });
            return;
        }

        this.setState({
            deleteModalIsOpen: false,
            listItemToDelete: null
        });
    }

    renderEmptyTableText() {

        const { archived } = this.props;

        if (archived) {
            return (
                <Fragment>
                    <p>
                        <strong>
                            Sie haben bisher noch keine Baustellen archiviert.
                        </strong>
                    </p>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <IfUser
                    can={['create-sites']}
                    ifNot={
                        <Fragment>
                            <p>
                                <strong> Sie haben bisher noch keine Baustellen
                                    abonniert.
                                </strong>
                            </p>
                            <p>Abonnieren Sie Baustellen, indem Sie den QR-Code an den Gerüsten vor
                                Ort einscannen. Alternativ können Sie sich auch vom Gerüstersteller
                                zu Baustellen einladen lassen.
                            </p>
                            <p>
                                Wenn Sie vom Gerüstersteller zu Baustellen eingeladen werden, hat
                                dieser die Möglichkeit, Ihnen Berechtigungen zum Prüfen und zum
                                Senden von Sperranfragen zu erteilen. Das Abonnieren einer Baustelle
                                ohne Einladung durch den Gerüstersteller (Scan oder Eingabe der
                                Short-ID in der App) ermöglicht Ihnen lediglich den Lesezugriff.
                            </p>
                            <p>
                                Wenn Sie Baustellen und Gerüste selbst zur Prüfung anlegen möchten,
                                führen Sie bitte ein Upgrade auf Scaffeye BASIC durch.
                            </p>
                        </Fragment>
                    }
                >
                    <p>
                        <strong> Sie haben bisher noch keine Baustellen
                            erstellt.
                        </strong>
                    </p>
                    <p>Beginnen Sie mit der Verwaltung Ihrer Baustellen, indem Sie die Baustellen
                        über den grünen &quot;Erstellen&quot; Button am oberen rechten Rand der
                        Seite
                        anlegen.
                    </p>
                </IfUser>
            </Fragment>
        );
    }

    renderListRows() {

        const { listItems, isFetchingListItems, sitesListViewOption } = this.state;
        const archived = sitesListViewOption === SitesListViewOption.ShowArchivedSites;

        const numTableColumns = archived ? 3 : 2;

        if (isFetchingListItems) {
            return (
                <tr>
                    <td colSpan={numTableColumns}><LoadingSpinner label="Lade Baustellen ..."
                                                                  block
                    /></td>
                </tr>
            );
        }

        if (!listItems.length && !isFetchingListItems) {
            return (
                <EmptyTableHint
                    numColumns={numTableColumns}
                    title="Keine Baustellen vorhanden"
                >
                    {this.renderEmptyTableText()}
                </EmptyTableHint>
            );
        }

        return listItems.map(listItem => {

            const {
                isThirdParty,
                isInvited,
                id,
                name,
                archivedAt
            } = listItem;

            let sub = null;

            if (isThirdParty && !isInvited) {
                sub = <ThirdPartyIndicator size="small" />;
            } else if (isThirdParty && isInvited) {
                sub = <InvitedIndicator size="small" />;
            }

            return (
                <tr key={id}>
                    <td className="name-column">
                        <SitePageLink id={id} className="name-column__link">
                            {name}
                            {archived && <ArchivedBadge />}
                        </SitePageLink>
                        {sub &&
                        <div className="name-column__sub">
                            {sub}
                        </div>
                        }
                        <TableHiddenActions>
                            <SitePageLink id={id}>
                                Details
                            </SitePageLink>
                            <IfUser can={['delete-sites']} and={!isThirdParty}>
                                <span
                                    onClick={() => this.toggleDeleteModal({
                                        id,
                                        name
                                    })}
                                >
                                    Entfernen
                                </span>
                            </IfUser>
                        </TableHiddenActions>
                    </td>

                    <td>
                        {listItem.address ?
                            <Fragment>
                                <div>{listItem.address.line1 ||
                                <NotAvailablePlaceholder />}</div>
                                <div>
                                    {(listItem.address.zip || listItem.address.city) ? `${listItem.address.zip} ${listItem.address.city}`
                                        :
                                        <NotAvailablePlaceholder />
                                    }
                                </div>
                            </Fragment> : 'n.a.'}
                    </td>
                    {archived && <td>{DateService.getReadableDateWithMonthName(archivedAt)}
                    </td>}
                </tr>
            );
        });
    }

    setCurrentListPage(listPageNumber) {


        const { onChangePage } = this.props;

        onChangePage(listPageNumber);

        this.setState({
            currentListPage: listPageNumber
        }, () => this.updateList());
    }

    onSitesListViewOptionChange(viewOptionSelection) {

        if (!viewOptionSelection) {
            return;
        }

        const { value: sitesListViewOption } = viewOptionSelection;
        const { onChangeArchived } = this.props;

        onChangeArchived(sitesListViewOption === SitesListViewOption.ShowArchivedSites ? true : null);

        this.setState({
            sitesListViewOption
        }, () => {
            this.resetList(sitesListViewOption === SitesListViewOption.ShowArchivedSites);
        });

    }

    render() {

        const {
            currentListPage,
            sitesListViewOption,
            deleteModalIsOpen,
            isDeletingListItem,
            listItemToDelete,
            listItemsInTotal,
            listItemsPerPage,
            isFetchingListItems,
            sortListBy: { field, direction },
            showAddModal
        } = this.state;

        const archived = sitesListViewOption === SitesListViewOption.ShowArchivedSites;

        const selectedViewOption = sitesListViewOption === SitesListViewOption.ShowActiveSites ? SitesListViewSelectOptions[0] : SitesListViewSelectOptions[1];

        return (
            <Fragment>

                <PageTitle
                    title="Baustellen"
                >
                    <AddButton
                        label="Neue Baustelle"
                        neededPermissions={Permission.CreateSites}
                        onClick={() => this.setState({ showAddModal: true })}
                    />
                </PageTitle>

                <PaginationTotal total={listItemsInTotal} standalone />
                <div className="jobs-table">

                    <IfUser
                        isOneOfType={[UserType.ProAdministrator, UserType.ProStandard]}
                    >
                        <div className="filterbar">
                            <div className="filterbar__heading">Ansicht:</div>
                            <div className="filterbar__autocomplete">
                                <Select
                                    className="sites-list-view-toggle"
                                    classNamePrefix="react-select"
                                    options={SitesListViewSelectOptions}
                                    value={selectedViewOption}
                                    styles={{ ...styles }}
                                    onChange={(selection) => this.onSitesListViewOptionChange(selection)}
                                    isDisabled={isFetchingListItems}
                                />
                            </div>
                        </div>
                    </IfUser>
                    <div className="content-table standalone">
                        <table cellPadding="0" cellSpacing="0" className="hover stack">
                            <SitesTableHeader
                                onChange={this.onSortChange}
                                sort={{
                                    field,
                                    direction
                                }}
                                archived={archived}
                            />
                            <tbody>
                            {this.renderListRows()}
                            </tbody>
                        </table>

                        {isFetchingListItems ?
                            <div />
                            :
                            <Pagination
                                total={listItemsInTotal}
                                amountPerPage={listItemsPerPage}
                                setPage={this.setCurrentListPage}
                                currentPage={currentListPage}
                            />
                        }
                    </div>
                </div>

                {showAddModal &&
                <SiteFormModal
                    onCreated={this.onSiteCreated}
                    onClose={() => this.setState({ showAddModal: false })}
                />
                }

                {deleteModalIsOpen &&
                <DeleteModal
                    modalTitle="Baustelle löschen"
                    deleteEntity={this.deleteSite}
                    closeModal={this.toggleDeleteModal}
                    isDeletingEntity={isDeletingListItem}
                >
                    <p>Sie sind im Begriff, die aktive Baustelle {' '}
                        <Link
                            to={`/sites/${listItemToDelete.id}`}
                        >
                            {listItemToDelete.name}
                        </Link> zu löschen.
                        <strong> Alle dazugehörigen Gerüste und Gerüstabschnitte werden damit
                            ebenfalls
                            geschlossen und gelöscht
                        </strong>
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </DeleteModal>
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchSites,
        deleteSingleSite
    }, dispatch)
});

export default withRouter(addUrlProps(({ urlPropsQueryConfig }))(connect(null, mapDispatchToProps)(SitesList)));


