import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DateService from '../../Services/DateService';
import Breadcrumb from '../Navigation/Breadcrumbs/Breadcrumb';
import { BreadcrumbLink } from '../Navigation/Breadcrumbs/BreadcrumbLink';

const ConstructionDiaryEntryBreadcrumb = ({ diaryEntry, collapse }) => {

  const query = diaryEntry && diaryEntry.site && diaryEntry.site.archived ? 'archived=true' : '';

    return (
        <Breadcrumb collapse={collapse}>
            <Fragment>
                <BreadcrumbLink
                    label={'Baustellen'}
                    path="/sites"
                    icon="scaffold"
                    query={query}
                />
                <BreadcrumbLink
                    label={diaryEntry ? diaryEntry.site.name : 'Lade Baustelle ...'}
                    path={diaryEntry ? `/sites/${diaryEntry.site.id}` : null}
                />
                <BreadcrumbLink
                    label={diaryEntry ? `Bautagebuch-Eintrag vom ${DateService.getReadableDateTime(diaryEntry.created)}` : 'Lade Bautagebuch-Eintrag ...'}
                    path={diaryEntry ? `/diary/${diaryEntry.id}` : null}
                />
            </Fragment>
        </Breadcrumb>
    );
};

export default ConstructionDiaryEntryBreadcrumb;

ConstructionDiaryEntryBreadcrumb.propTypes = {
    diaryEntry: PropTypes.object,
    collapse: PropTypes.bool
};

ConstructionDiaryEntryBreadcrumb.defaultProps = {
    diaryEntry: null,
    collapse: false
};
