import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ResourceNotFoundErrorPage from './Pages/Errors/ResourceNotFoundErrorPage';
import AcceptInvitationPage from './Pages/Private/AcceptInvitationPage';
import AccountProfilePage from './Pages/Private/AccountProfilePage';
import AddUserPage from './Pages/Private/AddUserPage';
import CompanyEditPage from './Pages/Private/BaseDataEditPage';
import AppChangeLogPage from './Pages/Private/AppChangeLogPage';
import ConstructionDiaryEntriesPage from './Pages/Private/ConstructionDiaryEntriesPage';
import ConstructionDiaryEntryPage from './Pages/Private/ConstructionDiaryEntryPage';
import CustomerDetailPage from './Pages/Private/CustomerDetailPage';
import CustomersListPage from './Pages/Private/CustomersListPage';
import DashboardPage from './Pages/Private/DashboardPage';
import DocumentsPage from './Pages/Private/DocumentsPage';
import EditBillingAddressPage from './Pages/Private/EditBillingAddressPage';
import EditBillingDetailsPage from './Pages/Private/EditBillingDetailsPage';
import EditUserPage from './Pages/Private/EditUserPage';
import HelpPage from './Pages/Private/HelpPage';
import InspectionHistoryPage from './Pages/Private/InspectionHistoryPage';
import JobDetailPage from './Pages/Private/JobManagement/JobDetailPage';
import JobsPage from './Pages/Private/JobManagement/JobsPage';
import RequestDetailPage from './Pages/Private/JobManagement/RequestDetailPage';
import RequestsPage from './Pages/Private/JobManagement/RequestsPage';
import LogoutPage from './Pages/Private/LogoutPage';
import ManageSubscriptionPage from './Pages/Private/ManageSubscriptionPage';
import MiscellaneousPage from './Pages/Private/MiscellaneousPage';
import UserProfileEditPage from './Pages/Private/PersonalDataEditPage';
import ScaffoldingDetailPage from './Pages/Private/ScaffoldingDetailPage';
import SectionDetailPage from './Pages/Private/SectionDetailPage';
import SiteDetailPage from './Pages/Private/SiteDetailPage';
import SitesPage from './Pages/Private/SitesPage';
import SubscriptionDetailPage from './Pages/Private/SubscriptionDetailPage';
import UserManagementPage from './Pages/Private/UserManagementPage';
import UserProfilePage from './Pages/Private/UserPage';
import ForgotPassword from './Pages/Public/ForgotPasswordPage';
import LoginPage from './Pages/Public/LoginPage';
import NewUserInvitationPage from './Pages/Public/NewUserInvitationPage';
import RegistrationPage from './Pages/Public/RegistrationPage';
import ResetPassword from './Pages/Public/ResetPasswordPage';
import VerifyTokenPage from './Pages/Public/VerifyTokenPage';

const App = () => (
    <React.Fragment>
        <Switch>
            {/* Public area */}
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/registration" component={RegistrationPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/invitation" component={NewUserInvitationPage} />

            {/* Protected area */}
            <Route exact path="/" component={DashboardPage} />

            {/* Requests */}
            <Route exact path="/requests" component={RequestsPage} />
            <Route exact path="/requests/:id" component={RequestDetailPage} />

            {/* Jobs */}
            <Route exact path="/jobs" component={JobsPage} />
            <Route exact path="/jobs/:id" component={JobDetailPage} />

            {/* Construction Diary  */}
            <Route exact path="/diary" component={ConstructionDiaryEntriesPage} />
            <Route exact path="/diary/:id" component={ConstructionDiaryEntryPage} />

            {/* Sites */}
            <Route exact path="/sites" component={SitesPage} />
            <Route exact path="/sites/:id" component={SiteDetailPage} />


            {/* Scaffoldings / Sections / Inspections */}
            <Route exact path="/scaffoldings" render={() => <Redirect to="/sites" />} />
            <Route exact path="/scaffoldings/:id" component={ScaffoldingDetailPage} />

            <Route exact path="/sections" render={() => <Redirect to="/sites" />} />
            <Route exact path="/sections/:id" component={SectionDetailPage} />
            <Route
                exact
                path="/sections/:id/inspections"
                component={InspectionHistoryPage}
            />

            {/* Documents */}
            <Route exact path="/documents" component={DocumentsPage} />

            {/* Customers */}
            <Route exact path="/customers" component={CustomersListPage} />
            <Route exact path="/customers/:id" component={CustomerDetailPage} />

            {/* Account / Subscription / User Management */}
            <Route exact path="/me" component={UserProfilePage} />
            <Route
                exact
                path="/me/edit"
                component={UserProfileEditPage}
            />

            <Route
                exact
                path="/account"
                render={() => <Redirect to="/account/profile" />}
            />
            <Route exact path="/account/profile" component={AccountProfilePage} />
            <Route exact path="/account/profile/company/edit" component={CompanyEditPage} />
            <Route exact path="/account/users" component={UserManagementPage} />
            <Route exact path="/account/users/add" component={AddUserPage} />
            <Route exact path="/account/users/:id/edit" component={EditUserPage} />
            <Route exact path="/account/subscription" component={ManageSubscriptionPage} />
            <Route
                exact
                path="/account/subscription/details"
                component={SubscriptionDetailPage}
            />
            <Route
                exact
                path="/account/subscription/edit/billingaddress"
                component={EditBillingAddressPage}
            />

            <Route
                exact
                path="/account/subscription/edit/billingdetails"
                component={EditBillingDetailsPage}
            />

            {/* Changelog */}
            <Route exact path="/changelog" component={AppChangeLogPage} />

            {/* Misc */}
            <Route exact path="/account/misc" component={MiscellaneousPage} />

            {/* Protected area */}
            <Route exact path="/invitations/:id/accept" component={AcceptInvitationPage} />

            {/* Help + Support */}
            <Route exact path="/help" component={HelpPage} />

            <Route exact path="/sign-up/verify" component={VerifyTokenPage} />

            {/* Redirects */}
            <Route exact path="/user" render={() => <Redirect to="/me" />} />
            <Route exact path="/user/profile/personaldata/edit"
                   render={() => <Redirect to="/me/edit" />}
            />
            <Route exact path="/account/usermanagement"
                   render={() => <Redirect to="/account/users" />}
            />
            <Route exact path="/account/usermanagement/add"
                   render={() => <Redirect to="/account/users/add" />}
            />
            <Route exact path="/account/usermanagement/:id/edit" render={({ match }) => <Redirect
                to={`/account/users/${match.params.id}/edit`}
            />}
            />

            {/* 404 */}
            <Route component={ResourceNotFoundErrorPage} />
        </Switch>

        <ToastContainer
            position="top-right"
            autoClose={4000}
        />
    </React.Fragment>
);

export default App;
