import React from 'react';
import Icon from '../../../Common/Icon';

const MediaSectionActions = ({ onAdd, onEdit }) => (
    <div className="constructionPlannerSection__actions">
        <button
            className="btn btn--add-action btn--icon"
            onClick={onAdd}
        >
            <span className="btn__icon">
                <Icon name="add" />
            </span>
        </button>
        <button
            className="btn btn--edit-action btn--icon"
            onClick={onEdit}
        >
            <span className="btn__icon">
                <Icon name="edit" />
            </span>
        </button>
    </div>
);

export default MediaSectionActions;
