import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import IfUser from '../../Common/IfUser';
import ConstructionPlanReferenceList
    from '../../ConstructionPlan/Form/Common/ConstructionPlanReferenceList';
import { ConstructionPlanModelModePropType } from '../../ConstructionPlan/Form/propTypes';
import ConstructionPlanSiteScaffoldingItem
    from '../../ConstructionPlan/Form/Site/Modal/ConstructionPlanSiteScaffoldingItem';
import { ConstructionPlanModelMode } from '../../ConstructionPlan/properties';
import Checkbox from '../../Forms/Checkbox';
import Input from '../../Forms/Input';
import InputGroup from '../../Forms/InputGroup';
import InputHint from '../../Forms/InputHint';
import { FormMode, FormModePropType } from '../../Forms/properties';
import TextArea from '../../Forms/Textarea';
import Section from '../../Layout/Section';
import SiteInviteesModal from '../SiteInviteesModal';
import SiteMediaFormPart from './SiteMediaFormPart';

function renderGeneralInformation({ readOnly }) {
    return (
        <Fragment>
            <InputGroup
                label="Bezeichnung der Baustelle*"
            >
                <Input
                    type="text"
                    name="name"
                    placeholder='z.B. "Rathaus Köln"'
                    readOnly={readOnly}
                />
            </InputGroup>
            <InputGroup
                label="Adresszeile 1*"
            >
                <Input
                    type="text"
                    name="line1"
                    readOnly={readOnly}
                    placeholder='z.B. "Schulstraße 23"'
                />
            </InputGroup>
            <InputGroup
                label="Adresszeile 2"
            >
                <Input
                    type="text"
                    name="line2"
                    readOnly={readOnly}
                    placeholder='z.B. "Innenhof, 2. OG"'
                />
            </InputGroup>
            <InputGroup
                label="Postleitzahl, Ort*"
                horizontal
            >
                <div className="input-grp__field--zip">
                    <Input
                        type="text"
                        name="zip"
                        placeholder='z.B. "50667"'
                        readOnly={readOnly}
                    />
                </div>
                <div className="input-grp__field--city">
                    <Input
                        type="text"
                        name="city"
                        placeholder='z.B. "Köln"'
                        readOnly={readOnly}
                    />
                </div>
            </InputGroup>
            <InputGroup
                label="Notizen"
            >
                <TextArea
                    name="description"
                    readOnly={readOnly}
                    placeholder="Hier können Sie zusätzliche Daten zur Baustelle hinterlegen"
                />
            </InputGroup>
        </Fragment>
    );
}

function renderInvitations({ values, isConstructionPlan, isArchived }) {

    if (isConstructionPlan) {
        return (
            <Fragment>
                <InputGroup
                    label="Eingeladene Nutzer"
                    style={{
                        position: 'relative',
                        zIndex: 4
                    }}
                >
                    <InputHint showIcon>
                        {isArchived ? <Fragment>
                                Die Einladung von Nutzern zu archivierten Baustellen ist nicht
                                möglich.
                            </Fragment>
                            :
                            <Fragment>
                                Sobald aus der Baustellenplanung mittels "Planung anwenden" eine
                                Live-Baustelle erzeugt wurde, können externe Nutzer (Nutzer die ihrem
                                Scaffeye-Unternehmens-Konto nicht angehören) darauf eingeladen
                                werden.
                                Diese bekommen dadurch Zugriff auf den öffentlichen Teil der
                                daraus erzeugten Baustelle, Gerüst und Gerüstabschnitte.
                                Zusätzlich können Sie bei Bedarf einzelnen Nutzern das Recht zur
                                Prüfung und die Berechtigung zum Verfassen einer Sperranfrage geben.
                            </Fragment>
                        }
                    </InputHint>
                </InputGroup>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <IfUser can={['create-site-invitation']}>
                <InputGroup
                    label="Eingeladene Nutzer"
                >
                    <SiteInviteesModal
                        name="invitees"
                        currentUserEmail={values.currentUserEmail}
                    />
                    <InputHint showIcon>
                        Hier können Sie externe Nutzer (Nutzer die ihrem Scaffeye-Unternehmens-Konto
                        nicht angehören) zur Baustelle einladen.
                    </InputHint>
                </InputGroup>
            </IfUser>
        </Fragment>
    );
}

function renderConstructionPlanScaffoldings({ referenceData, constructionPlanMode, isArchived }) {
    if (constructionPlanMode === ConstructionPlanModelMode.New) {
        return (<InputGroup
            label="Zugehörige Gerüste"
            style={{
                position: 'relative',
                zIndex: 4
            }}
        >
            <InputHint showIcon>
                Gerüste können nach dem Abspeichern im Baustellenplaner erstellt werden.
            </InputHint>
        </InputGroup>);

    }

    const scaffoldings = (referenceData && referenceData.scaffoldings) ? referenceData.scaffoldings : [];

    return (
        <InputGroup
            label="Zugehörige Gerüste"
            style={{
                position: 'relative',
                zIndex: 4
            }}
        >
            {constructionPlanMode !== ConstructionPlanModelMode.New &&
            <ConstructionPlanReferenceList
                data={scaffoldings}
            >
                {
                    (scaffolding) => (
                        <ConstructionPlanSiteScaffoldingItem
                            key={scaffolding.id}
                            data={scaffolding}
                        />
                    )
                }
            </ConstructionPlanReferenceList>
            }
            {!isArchived &&
            <InputHint showIcon>
                Gerüste können nach dem Abspeichern im Baustellenplaner eingesehen, hinzugefügt und
                angepasst werden.
            </InputHint>
            }
        </InputGroup>
    );
}

function renderDefaultScaffoldings({ mode: formMode }) {

    if (formMode === FormMode.Create) {
        return (
            <Section title="Gerüste" collapsible isCollapsed>
                <InputGroup
                    label="Gerüsterstellung"
                >
                    <Checkbox
                        name="createScaffolding"
                        label="Gerüst nach Anlegen der Baustelle erstellen"
                    />
                    <p>
                        Wählen Sie dieses Feld aus, wenn Sie ein neues Gerüst
                        nach
                        Anlegen der Baustelle erstellen möchten.
                    </p>
                </InputGroup>
            </Section>
        );
    }
    return null;
}

const SiteFormContent = (props) => {

    const { values, attachmentsMutator, readOnly, isConstructionPlan } = props;

    const wrapperClasses = ['form-content'];

    if (readOnly) {
        wrapperClasses.push('form-content--disabled');
    }

    return (
        <React.Fragment>
            <Section title="Allgemeine Angaben*" collapsible>
                <div className={wrapperClasses.join(' ')}>
                    {renderGeneralInformation(props)}
                </div>
                {renderInvitations(props)}
                {isConstructionPlan && renderConstructionPlanScaffoldings(props)}
            </Section>

            {!isConstructionPlan && renderDefaultScaffoldings(props)}

            <Section
                title="Dokumente"
                collapsible
                isCollapsed={!readOnly}
                style={{
                    position: 'relative',
                    zIndex: 4
                }}
            >
                <SiteMediaFormPart
                    attachments={values.attachments}
                    attachmentsMutator={attachmentsMutator}
                    onUploading={props.onUploading}
                    isConstructionPlan={isConstructionPlan}
                    readOnly={readOnly}
                />
            </Section>
        </React.Fragment>
    );
};

SiteFormContent.propTypes = {
    values: PropTypes.object.isRequired,
    attachmentsMutator: PropTypes.func.isRequired,
    onUploading: PropTypes.func.isRequired,
    mode: FormModePropType.isRequired,
    readOnly: PropTypes.bool,
    referenceData: PropTypes.object,
    isConstructionPlan: PropTypes.bool,
    constructionPlanMode: ConstructionPlanModelModePropType
};

SiteFormContent.defaultProps = {
    readOnly: false,
    referenceData: null,
    isConstructionPlan: false,
    constructionPlanMode: undefined
};

export default SiteFormContent;
