import PropTypes from 'prop-types';
import React from 'react';
import PaginationTotal from "./PaginationTotal";

class Pagination extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalItems: this.props.total,
            totalPages: Math.ceil(this.props.total / this.props.amountPerPage),
            page: 1
        };
        this.goToTheFirstPage = this.goToTheFirstPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.goToTheLastPage = this.goToTheLastPage.bind(this);
        this.emitPageChange = this.emitPageChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            ...this.state,
            totalItems: nextProps.total,
            totalPages: Math.ceil(nextProps.total / nextProps.amountPerPage)
        });
    }

    goToTheFirstPage() {
        this.props.setPage(1);
        this.emitPageChange();
    }

    nextPage() {
        if (this.props.currentPage !== this.state.totalPages) {
            this.props.setPage(this.props.currentPage + 1);
            this.emitPageChange();
        }
    }

    previousPage() {
        if (this.props.currentPage !== 1) {
            this.props.setPage(Math.max(1, this.props.currentPage - 1));
            this.emitPageChange();
        }
    }

    goToTheLastPage() {
        this.props.setPage(this.state.totalPages);
        this.emitPageChange();
    }

    emitPageChange() {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange();
        }
    }

    render() {
        const { currentPage } = this.props;
        const { totalItems, totalPages } = this.state;
        const disabled_first = currentPage === 1 ? 'disabled' : '';
        const disabled_last = currentPage === totalPages ? 'disabled' : '';
        const classes_first = `${disabled_first} quo`;
        const classes_last = `${disabled_last} quo`;

        return (
            <div className="pagination">
                <PaginationTotal total={totalItems} />
                {totalPages > 0 &&
                <React.Fragment>
                    <span
                        className={classes_first}
                        onClick={this.goToTheFirstPage}
                    >&laquo;
                    </span>
                    <span
                        className={classes_first}
                        onClick={this.previousPage}
                    >&lsaquo;
                    </span>
                    <span className="pagination__txt">
                        Seite {currentPage} von {totalPages}
                    </span>
                    <span
                        className={classes_last}
                        onClick={this.nextPage}
                    >&rsaquo;
                    </span>
                    <span
                        className={classes_last}
                        onClick={this.goToTheLastPage}
                    >&raquo;
                    </span>
                </React.Fragment>
                }
            </div>
        );
    }

}

export default Pagination;

Pagination.propTypes = {
    amountPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    onChange: PropTypes.func,
    setPage: PropTypes.func,
    total: PropTypes.number
};

Pagination.defaultProps = {
    amountPerPage: 10,
    currentPage: 1,
    onChange: () => false,
    setPage: () => false,
    total: 20
};
