import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import {
    addCustomerContact,
    fetchCustomerContactFormData,
    updateCustomerContact
} from '../../../redux/modules/customer/action';
import { showApiError } from '../../../redux/modules/error/action';
import { validateEmail } from '../../../utils';
import Modal from '../../Common/Modal/Modal';
import ModalFormFooter from '../../Common/ModalFormFooter';
import ResourceNotFoundModal from '../../Common/Modal/ResourceNotFoundModal';
import ContactPersonDTO from '../../DTOs/ContactPersonDTO';
import { FormMode, FormModePropType } from '../../Forms/properties';
import LoadingSpinner from '../../Page/LoadingSpinner';
import {
    CustomerContactFormContent,
    MODE_AS_MAIN_CONTACT,
    MODE_AS_STANDARD_CONTACT
} from './CustomerContactFormContent';

export const MODAL_CONTEXT_CUSTOMER_CREATION = 'modal_context_customer_creation';
export const MODAL_CONTEXT_STANDALONE = 'modal_context_standalone';
export const MODAL_CONTEXT_JOB_REQUEST_CREATION = 'modal_context_job_request_creation';

const focusOnError = createDecorator();

const getInitialState = (props) => ({
    mode: props.mode,
    contactId: props.id,
    customerContact: null,
    initialFormValues: {},
    isSaving: false,
    isInitializing: false
});

class CustomerContactFormModal extends Component {
    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = getInitialState(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.onClose = this.onClose.bind(this);
        this.renderButtonText = this.renderButtonText.bind(this);
    }

    sendData(payload) {

        const { mode } = this.props;

        if (mode === FormMode.Create) {
            return this.createResource(payload)
                .then((id) => ({
                    id,
                    message: 'Ansprechpartner wurde angelegt'
                }));

        }
        if (mode === FormMode.Edit) {
            return this.updateResource(payload)
                .then(() => ({
                    message: 'Änderungen wurden gespeichert'
                }));
        }

        return Promise.reject();
    }

    getSubmitPayload(values) {
        if (!values) {
            return null;
        }

        const {
            contactPersonFirstName,
            contactPersonLastName,
            contactPersonPosition,
            isMainContact,
            contactPersonEmail,
            contactPersonPhoneNumber,
            contactPersonMobilePhoneNumber
        } = values;

        const contactPersonDTO = new ContactPersonDTO();

        contactPersonDTO.firstName = contactPersonFirstName;
        contactPersonDTO.lastName = contactPersonLastName;
        contactPersonDTO.phone = contactPersonPhoneNumber || null;
        contactPersonDTO.position = contactPersonPosition || null;
        contactPersonDTO.isMainContact = isMainContact || false;
        contactPersonDTO.email = contactPersonEmail || null;
        contactPersonDTO.mobile = contactPersonMobilePhoneNumber || null;

        return contactPersonDTO;

    }

    // Will be set in the render method
    handleSubmit = () => false;

    onSubmit(values) {

        const payload = this.getSubmitPayload(values);

        if (this.loadingGotCancelled) {
            return;
        }

        this.setState({ isSaving: true });

        this.sendData(payload)
            .then(data => {
                this.handleSuccessfulSubmission(data);
            })
            .catch(error => {
                this.handleFailedSubmission(error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({ isSaving: false });
            });
    }

    handleFailedSubmission(error) {
        showApiError(error);
    }

    handleSuccessfulSubmission(data) {
        const { id, message } = data;

        toast.success(message, { autoClose: 2500 });

        const { onCreated } = this.props;

        if (typeof onCreated === 'function') {
            onCreated(id);
        }
    }

    createResource(payload) {
        const { actions: { addCustomerContact }, customerId } = this.props;
        return addCustomerContact(customerId, payload);
    }

    updateResource(payload) {
        const { actions: { updateCustomerContact } } = this.props;
        const { contactId } = this.state;
        return updateCustomerContact(contactId, payload);
    }

    componentWillMount() {
        const { mode } = this.state;


        if (mode === FormMode.Create) {
            return;
        }

        this.initializeForm();

    }

    initializeForm() {

        const { actions: { fetchCustomerContactFormData } } = this.props;
        const { contactId } = this.state;

        this.setState({
            isInitializing: true
        });

        fetchCustomerContactFormData(contactId)
            .then(customerContact => {
                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    customerContact
                });

                this.setInitialFormValues(customerContact);
            })
            .catch(error => {
                showApiError(error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }
                this.setState({
                    isInitializing: false
                });
            });
    }

    setInitialFormValues(requestData) {

        const {
            id,
            firstName,
            lastName,
            isMainContact,
            email,
            position,
            phone,
            mobile
        } = requestData;

        const initialFormValues = {
            id,
            contactPersonFirstName: firstName,
            contactPersonLastName: lastName,
            isMainContact,
            contactPersonEmail: email,
            contactPersonPosition: position,
            contactPersonPhoneNumber: phone,
            contactPersonMobilePhoneNumber: mobile
        };

        this.setState({
            initialFormValues
        });
    }

    renderButtonText() {
        const { mode, isSaving } = this.state;
        if (mode === FormMode.Edit) {
            return isSaving ? 'Änderungen werden gespeichert ...' : 'Änderungen speichern';
        }
        return isSaving ? 'Ansprechpartner wird angelegt ...' : 'Ansprechpartner anlegen';
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    onClose(pristine) {

        const { onClose } = this.props;

        if (pristine) {
            onClose(true);
            return;
        }

        if (window.confirm('Möchten Sie das Formular wirklich schließen? Dadurch gehen sämtliche Eingaben verloren')) {
            onClose(true);
        }
    }

    validate(values) {

        const errors = {};

        if (!values.contactPersonFirstName) {
            errors.contactPersonFirstName = 'Bitte geben Sie einen Vornamen ein';
        }

        if (!values.contactPersonLastName) {
            errors.contactPersonLastName = 'Bitte geben Sie einen Nachnamen ein';
        }

        if (values.contactPersonEmail && !validateEmail(values.contactPersonEmail)) {
            errors.contactPersonEmail = 'Bitte geben Sie eine gültige E-Mail Adresse ein';
        }

        return errors;

    }

    render() {
        const {
            mode,
            initialFormValues,
            isSaving,
            isInitializing,
            customerContact
        } = this.state;


        const { onClose, modalContext } = this.props;

        const isMainContact = customerContact && customerContact.isMainContact;

        if (mode === FormMode.Edit && !isInitializing && !customerContact) {
            return (
                <ResourceNotFoundModal
                    title="Ansprechpartner nicht gefunden"
                    onClose={onClose}
                />
            );
        }

        return (
            <Modal
                title={mode === FormMode.Create ? 'Ansprechpartner anlegen' : 'Ansprechpartner bearbeiten'}
                id="customer-modal"
                footer={
                    <ModalFormFooter
                        onSubmit={(e) => this.handleSubmit(e)}
                        isSubmitting={isSaving}
                        onClose={() => this.onClose(this.pristine)}
                        submitLabel={this.renderButtonText()}
                        submitDisabled={isInitializing}
                    />
                }
            >
                {isInitializing &&
                <LoadingSpinner
                    block
                    label="Lade Ansprechpartner"
                    backgroundColor="white"
                />
                }

                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    initialValues={initialFormValues}
                    decorators={[focusOnError]}
                    render={({ handleSubmit, values, pristine }) => {
                        this.handleSubmit = handleSubmit;
                        this.pristine = pristine;
                        return (
                            <CustomerContactFormContent
                                mode={modalContext === MODAL_CONTEXT_CUSTOMER_CREATION ? MODE_AS_MAIN_CONTACT : MODE_AS_STANDARD_CONTACT}
                                isMainContact={mode === 'edit' && isMainContact}
                            />
                        );
                    }}
                />
            </Modal>
        );
    }
}

CustomerContactFormModal.propTypes = {
    modalContext: PropTypes.oneOf([MODAL_CONTEXT_CUSTOMER_CREATION, MODAL_CONTEXT_STANDALONE, MODAL_CONTEXT_JOB_REQUEST_CREATION]).isRequired,
    mode: FormModePropType,
    id: PropTypes.string,
    onCreated: PropTypes.func,
    onUpdated: PropTypes.func,
    onClose: PropTypes.func
};

CustomerContactFormModal.defaultProps = {
    mode: 'create',
    id: null,
    customerId: null,
    onCreated: null,
    onUpdated: null,
    onClose: () => false
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchCustomerContactFormData,
        addCustomerContact,
        updateCustomerContact
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(CustomerContactFormModal);
