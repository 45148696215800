import PropTypes from 'prop-types';
import React from 'react';

const ConstructionPlanSyncModelSummary = ({ title, children, isNew }) => {
    return (
        <div className={`sync-summary__model ${isNew ? 'sync-summary__model--new-ressource' : ''}`}>
            <h3 className="sync-summary__model-ttl">
                {title} {isNew ? <span className="new-badge">Neu</span> : null}
            </h3>
            <div className="sync-summary__model-bd">
                {children}
            </div>
        </div>
    );
};

ConstructionPlanSyncModelSummary.propTypes = {
    title: PropTypes.string.isRequired,
    isNew: PropTypes.bool,
    isIgnored: PropTypes.bool,
};

export default ConstructionPlanSyncModelSummary;
