import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { showApiError, showTempError } from '../../redux/modules/error/action';
import {
    archiveSite,
    fetchSiteArchivationData,
    unarchiveSite
} from '../../redux/modules/site/action';
import GraphQLError, { GraphQlErrorCodes } from '../../Services/GraphQLError';
import { SectionStatus } from '../Sections/properties';
import Modal from '../Common/Modal/Modal';

const VALID_CAPTURE_CONFIRMATION_TEXT = 'archivieren';

export const ArchiveActions = {
    Archived: 'archived',
    Reactivated: 'reactivated'
};

const sanitizeCaptureInput = (inputValue) => {
    if (!inputValue || typeof inputValue !== 'string') {
        return;
    }

    return inputValue.trim()
        .toLowerCase();
};

const siteHasActiveSections = (site) => {

    // active site in this context:
    // => site has at least 1 section w/ status !== dismantled ("Abgebaut") && status !== draft ("In Planung") && status !== unknown
    const { scaffoldings } = site;

    if (!scaffoldings || !scaffoldings.length) {
        return false;
    }

    return scaffoldings.some(scaffolding => {

        const { sections } = scaffolding;

        if (!sections || !sections.length) {
            return false;
        }

        return sections.some(section => (section.status !== SectionStatus.Dismantled && section.status !== SectionStatus.Draft && section.status !== SectionStatus.Unknown));

    });

};

class ArchiveConfirmationModal extends Component {

    constructor(props) {
        super(props);
        this.loadingGotCancelled = false;
        this.state = {
            currentPage: 1,
            isProcessing: false,
            isInitializing: false,
            isInitialized: false,
            isArchived: props.isArchived,
            hasActiveSections: false,
            confirmationCapture: '',
            archivationBlockedDueToActiveSections: false
        };
    }

    componentWillMount() {

        this.fetchSiteActivationState();

    }

    fetchSiteActivationState() {
        const { siteId, actions: { fetchSiteArchivationData } } = this.props;

        this.setState({
            isInitializing: true
        });

        fetchSiteArchivationData(siteId)
            .then(site => {

                if (this.loadingGotCancelled) {
                    return;
                }

                const { archived: isArchived } = site;

                this.setState({
                    site,
                    isArchived,
                    hasActiveSections: siteHasActiveSections(site),
                    isInitialized: true
                });
            })
            .catch(error => {
                showApiError(error);
            })
            .finally(() => {

                if (this.loadingGotCancelled) {
                    return;
                }

                this.setState({
                    isInitializing: false
                });
            });
    }


    setCurrentPage(page) {
        this.setState({
            currentPage: page
        });
    }

    setIsProcessing(isProcessing) {
        this.setState({
            isProcessing
        });
    }

    onConfirmationCaptureChange({ target: { value: captureInput } }) {

        this.setState((prevState) => {
            return {
                confirmationCapture: captureInput,
                captureConfirmationError: sanitizeCaptureInput(captureInput) === VALID_CAPTURE_CONFIRMATION_TEXT ? '' : prevState.captureConfirmationError
            };
        });
    }

    componentWillUnmount() {
        this.loadingGotCancelled = true;
    }

    renderModalContent() {

        const { currentPage, hasActiveSections, confirmationCapture, captureConfirmationError } = this.state;
        const { onClose } = this.props;

        const { isArchived, site } = this.state;
        const siteName =  site && site.name;

        // site is archived
        if (isArchived) {
            return (
                <Fragment>
                    <p>
                        <strong style={{ fontWeight: 600 }}>ACHTUNG:</strong> Sie sind im Begriff,
                        die Baustelle
                        <span
                            onClick={onClose}
                            className="btn--link-neutral"
                        >
                                        {' '} {siteName}
                                                </span> wieder zu reaktivieren.
                    </p>
                    <p>Möchten Sie fortfahren?</p>
                </Fragment>
            );
        }

        // site is active and has active sections
        if (hasActiveSections) {

            return (
                <Fragment>

                    <p>
                        ACHTUNG: Sie sind im Begriff, die Baustelle
                        <span
                            onClick={onClose}
                            className="btn--link-neutral"
                        >
                                 {' '} {siteName}
                            </span> zu archivieren.
                    </p>
                    <p>
                        <strong style={{ fontWeight: 600 }}> Diese Baustelle enthält aktive
                            Gerüstabschnitte. Alle aktiven
                            Gerüstabschnitte werden dadurch in den Status “Unbekannt” versetzt. Danach
                            steht Ihnen die Baustelle und alle darunterliegenden Gerüste und
                            Gerüstabschnitte nicht mehr zur Interaktion zur Verfügung.
                        </strong>
                    </p>
                    <p>Möchten Sie wirklich fortfahren?</p>

                    {
                        currentPage === 2 &&
                        <div className="input-capture">
                            <h4 className="input-capture__label">Archivierung bestätigen</h4>
                            <p className="input-capture__description">Bitte geben Sie “ARCHIVIEREN”
                                im unteren Feld ein.</p>

                            <input className="input-capture__description" type="text"
                                   value={confirmationCapture}
                                   onChange={(event) => this.onConfirmationCaptureChange(event)}
                            />
                            {
                                captureConfirmationError &&
                                <div className="input-capture__error">
                                    {captureConfirmationError}
                                </div>
                            }
                        </div>
                    }
                </Fragment>
            );
        }

        // site is active and does not have active sections
        return (
            <Fragment>
                <p>
                    Sie sind im Begriff, die Baustelle
                    <span
                        onClick={onClose}
                        className="btn--link-neutral"
                    >
                                 {' '} {siteName}
                            </span> zu archivieren.
                </p>
                <p>
                    <strong style={{ fontWeight: 600 }}>Die Baustelle und alle darunterliegenden
                        Gerüste und Gerüstabschnitte
                        stehen Ihnen und den Gerüstnutzern danach nicht mehr zur Interaktion zur
                        Verfügung.
                    </strong>
                </p>
                <p>Möchten Sie fortfahren?</p>

            </Fragment>
        );
    }

    showConfirmationSuccessMessage() {

        const { isArchived, site: { name: siteName } } = this.state;

        const message = isArchived ?
            `Baustelle: "${siteName}" wurde aktiviert.`
            :
            `Baustelle: "${siteName}" wurde archiviert.`;

        toast.success(message, { autoClose: 2500 });

    }

    handleArchivationConfirmation() {
        const { currentPage, isProcessing, hasActiveSections, confirmationCapture } = this.state;
        const { siteId, onConfirmationSuccess, actions: { archiveSite } } = this.props;
        const forceArchivation = hasActiveSections;

        if (isProcessing) {
            return;
        }

        this.setState({
            captureConfirmationError: ''
        });

        if (hasActiveSections && currentPage === 1) {
            this.setCurrentPage(2);
            return;
        }

        if (hasActiveSections && currentPage === 2) {

            if (sanitizeCaptureInput(confirmationCapture) !== VALID_CAPTURE_CONFIRMATION_TEXT) {
                this.setState({
                    captureConfirmationError: 'Bitte geben Sie "ARCHIVIEREN" in das Textfeld ein, um die Archivierung der Baustelle zu bestätigen'
                });
                return;
            }
        }

        this.setIsProcessing(true);

        archiveSite(siteId, forceArchivation)
            .then(() => {

                if (this.loadingGotCancelled) {
                    return;
                }
                this.showConfirmationSuccessMessage();
                onConfirmationSuccess(ArchiveActions.Archived);
            })
            .catch((error) => {
                this.handleError(error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setIsProcessing(false);
            });
    }

    handleBlockedByActiveSectionError() {

        this.setState({
            archivationBlockedDueToActiveSections: true,
            isInitialized: false,
            currentPage: 1,
            confirmationCapture: ''
        }, () => this.fetchSiteActivationState());
    }

    handleError(error) {

        if (error instanceof GraphQLError) {

            if (error.is(GraphQlErrorCodes.SiteArchiveBlockedBySection)) {
                this.handleBlockedByActiveSectionError();
            }

            else if (error.is(GraphQlErrorCodes.SiteAlreadyArchived)) {
                showTempError('Die Baustelle wurde bereits archiviert.');
            }

            else if (error.is(GraphQlErrorCodes.SiteAlreadyActive)) {
                showTempError('Die Baustelle wurde bereits reaktiviert.');
            }

            else {
                showApiError(error);
            }
        } else {
            showApiError(error);
        }
    }

    handleReactivationConfirmation() {

        const { siteId, onConfirmationSuccess, actions: { unarchiveSite } } = this.props;

        this.setIsProcessing(true);

        unarchiveSite(siteId)
            .then(() => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.showConfirmationSuccessMessage();
                onConfirmationSuccess(ArchiveActions.Reactivated);
            })
            .catch((error) => {
                this.handleError(error);
            })
            .finally(() => {
                if (this.loadingGotCancelled) {
                    return;
                }
                this.setIsProcessing(false);
            });
    }

    handleConfirmationClick() {

        const { isArchived, isProcessing } = this.state;

        if (isProcessing) {
            return;
        }

        if (!isArchived) {
            this.handleArchivationConfirmation();
        }

        else {
            this.handleReactivationConfirmation();
        }

    }

    renderConfirmButtonText() {

        const { isArchived, isProcessing, hasActiveSections, currentPage } = this.state;

        let defaultButtonText = hasActiveSections ? 'Baustelle archivieren' : 'Fortfahren und archivieren';

        if (hasActiveSections && currentPage === 1) {
            defaultButtonText = 'Ja, fortfahren';
        }

        let processingStateMessage = 'Wird archiviert...';

        if (isArchived) {
            processingStateMessage = 'Wird reaktiviert...';
            defaultButtonText = 'Ja, fortfahren und reaktivieren';
        }

        return isProcessing ? processingStateMessage : defaultButtonText;

    }

    renderModalTitle() {
        const { isArchived } = this.state;

        return isArchived ? 'Baustelle reaktivieren' : 'Baustelle archivieren';
    }

    renderModalFooter() {

        const { onClose } = this.props;
        const { isProcessing, isInitialized, currentPage, isArchived, hasActiveSections } = this.state;

        const submitButtonClasses = ['btn'];

        if (!isArchived && hasActiveSections) {

            if (currentPage === 1) {
                submitButtonClasses.push('btn--link-neutral');
            } else {
                submitButtonClasses.push('btn--primary-green btn--save');
            }
        } else {
            submitButtonClasses.push('btn--primary-green btn--save');
        }

        return (
            <div className="btn-group">
                <button
                    className="btn btn--backward-action"
                    onClick={onClose}
                >
                    Abbrechen
                </button>
                {
                    isInitialized && <button
                        type="submit"
                        className={submitButtonClasses.join(' ')}
                        disabled={isProcessing}
                        style={{ float: 'right' }}
                        onClick={() => this.handleConfirmationClick()}
                    >
                        {this.renderConfirmButtonText()}
                    </button>
                }
            </div>
        );
    }

    render() {

        const { classes, onClose } = this.props;
        const { archivationBlockedDueToActiveSections, isInitializing } = this.state;

        return (
            <Modal
                id="modal-archive"
                title={this.renderModalTitle()}
                type="confirmation"
                classes={classes}
                onBackdropClick={() => onClose()}
                footer={this.renderModalFooter()}
                loading={isInitializing}
                loadingLabel="Prüfe Baustelle..."
            >
                {
                    archivationBlockedDueToActiveSections && <div className="warning-banner">
                        Die Baustelle konnte nicht archiviert werden, da zwischenzeitlich
                        darunterliegende Gerüstabschnitte in einen aktiven Status versetzt wurden. Um
                        die Baustelle dennoch zu archivieren, folgen Sie bitte den folgenden
                        Anweisungen.
                    </div>
                }
                {this.renderModalContent()}
            </Modal>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        archiveSite,
        unarchiveSite,
        fetchSiteArchivationData
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ArchiveConfirmationModal);
