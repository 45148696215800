import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { fetchScaffoldingsSelectOptions } from '../../redux/modules/scaffoldings/action';
import OptionGroupSelect from '../Forms/OptionGroupSelect';
import Select from '../Forms/Select';

class ScaffoldingSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            managed: props.managed,
            siteId: props.siteId,
            choices: [],
            isFetching: false,
            hasFetched: false
        };

        this.onChange = this.onChange.bind(this);
        this.updateOnFetching = this.updateOnFetching.bind(this);
        this.getScaffoldingChoices = this.getScaffoldingChoices.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {};

        if (prevState.siteId !== nextProps.siteId) {
            newState = {
                ...newState,
                siteId: nextProps.siteId,
                choices: [],
                isFetching: false,
                hasFetched: false
            };
        }

        if (prevState.managed !== nextProps.managed) {
            newState = {
                ...newState,
                managed: nextProps.managed
            };
        }

        return newState;
    }

    componentDidMount() {
        this.loadOptions();
    }

    componentDidUpdate() {
        this.loadOptions();
    }

    getScaffoldingChoices() {
        const { choices, managed } = this.state;
        const { excludeScaffoldingIds } = this.props;

        if(!choices || choices.length === 0) {
            return [];
        }

        const filteredChoices = choices
            .filter(choice => {

                // Exclude by id
                if (excludeScaffoldingIds && excludeScaffoldingIds.indexOf(choice.value) >= 0) {
                    return false;
                }

                // Exclude by managed flag
                if (managed !== 'both') {
                    return managed === choice.managed;
                }

                // Otherwise include
                return true;
            });

        const options = _.orderBy(filteredChoices, 'label', 'asc');

        if (managed !== 'both') {
            return options;
        }

        // In case both managed and unmanaged scaffoldings should be shown we need to group all scaffoldings
        const groupedOptions = [
            {
                label: 'Eigene Gerüste',
                value: []
            }, {
                label: 'Fremde Gerüste',
                value: []
        }];

        options.forEach(option => {
            groupedOptions[option.managed ? 0 : 1].value.push(option);
        });

        return groupedOptions;
    }

    loadOptions() {
        if (this.state.hasFetched || this.state.isFetching || !this.props.siteId) {
            return;
        }

        this.setState({
            isFetching: true
        }, this.updateOnFetching);

        const managed = this.props.excludeUnmanagedScaffoldings ? true : null;
        this.props.actions.fetchScaffoldingsSelectOptions(this.props.siteId, false, managed)
            .then((_scaffoldings) => {
                const scaffoldings = _scaffoldings || [];
                const choices = scaffoldings.map((scaffolding) => {
                    return {
                        value: scaffolding.id,
                        label: scaffolding.name,
                        managed: scaffolding.managed
                    };
                });
                this.setState({
                    choices
                });
            })
            .catch(() => {
                toast.error('Gerüste konnten nicht geladen werden', { autoClose: 4000 });
            })
            .then(() => {
                this.setState({
                    isFetching: false,
                    hasFetched: true
                }, this.updateOnFetching);
            });
    }

    updateOnFetching() {
        const { onFetching } = this.props;
        if (typeof onFetching === 'function') {
            onFetching(this.state.isFetching);
        }
    }

    onChange(scaffoldingId) {
        const { onChange } = this.props;
        if (typeof onChange !== 'function') {
            return;
        }

        const scaffolding = this.state.choices.find(choice => choice.value === scaffoldingId);
        onChange(scaffoldingId, scaffolding);
    }

    render() {
        const {
            siteId,
            isFetching
        } = this.state;

        if (!siteId) {
            return (
                <Select
                    name={this.props.name}
                    placeholder="Gerüst auswählen"
                    disabled
                    readOnly={this.props.readOnly}
                />
            );
        }

        const className = this.props.excludeManagedScaffoldings ? 'excludeManagedScaffoldings' : '';

        return (
            <OptionGroupSelect
                name={this.props.name}
                placeholder={isFetching ? 'Lade Gerüste ...' : 'Gerüst wählen'}
                options={this.getScaffoldingChoices()}
                disabled={isFetching}
                onChange={this.onChange}
                className={className}
            />
        );
    }

}

ScaffoldingSelect.propTypes = {
    managed: PropTypes.oneOf([true, false, 'both']),
    name: PropTypes.string.isRequired,
    onFetching: PropTypes.func,
    onChange: PropTypes.func,
    excludeScaffoldingIds: PropTypes.array
};

ScaffoldingSelect.defaultProps = {
    managed: 'both',
    onFetching: null,
    onChange: null,
    excludeScaffoldingIds: []
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        fetchScaffoldingsSelectOptions
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(ScaffoldingSelect);
