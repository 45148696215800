import React from 'react';


const FilterBar = ({ children }) => {

    return (
        <div className="list-filterbar">
            {children}
        </div>
    );
};

export default FilterBar;
