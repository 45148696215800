import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DateService from '../../../Services/DateService';
import DeletedUserPlaceholder from '../../Common/DeletedUserPlaceholder';
import PrimaryColumn from '../../Common/PrimaryColumn';
import { DiaryEntryLink } from '../../Common/ResourcePageLink';
import ConstructionDiaryEntryCategory from '../ConstructionDiaryEntryCategory';

const showEntryReference = (entry) => {
    const { scaffoldingSelection, sectionSelection } = entry;

    if ( scaffoldingSelection === 'all' ) {
        return 'Gesamte Baustelle';
    }

    if ( scaffoldingSelection === 'single' && sectionSelection === 'all' ) {
        return '1 Gerüst';
    }

    return '1 Gerüstabschnitt';
};

export const ConstructionDiaryTableRow = ({ data: entry }) => {

    const { id, created, category, createdBy, site: { name: siteName } } = entry;

    return (
        <Fragment>
            <tr key={id}>
                <PrimaryColumn
                    link={
                        <DiaryEntryLink
                            id={id}
                        >
                            {DateService.getReadableDate(created)}
                        </DiaryEntryLink>}
                >
                    <DiaryEntryLink id={id}>
                        Details
                    </DiaryEntryLink>
                </PrimaryColumn>

                <td>
                    <ConstructionDiaryEntryCategory category={category}/>
                </td>

                <td>
                    {siteName}
                </td>

                <td>
                    {showEntryReference(entry)}
                </td>

                <td>
                    <span>
                        {createdBy
                            ? `${createdBy.firstName} ${createdBy.lastName}`
                            : <DeletedUserPlaceholder/>
                        }
                    </span>
                </td>
            </tr>
        </Fragment>
    );
};


ConstructionDiaryTableRow.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func
};

ConstructionDiaryTableRow.defaultProps = {
    onChange: () => false
};
