import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { autocompleteCustomer } from '../../../redux/modules/customer/action';
import AutocompleteSelect from './AutocompleteSelect';

const CustomerAutocomplete = (props) => {
    const { onChange, label, initialValue, actions: { autocompleteCustomer } } = props;

    const initialOption = initialValue ? {
        label: initialValue,
        value: initialValue
    } : null;

    return (
        <AutocompleteSelect
            getValues={autocompleteCustomer}
            onSelectionChange={onChange}
            label={label}
            loadingMessage={() => 'Lade Kunden ...'}
            placeholder="Kunde wählen"
            initialOption={initialOption}
        />
    );
};

CustomerAutocomplete.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    label: PropTypes.string
};

CustomerAutocomplete.defaultProps = {
    initialValue: null,
    label: 'Kunde'
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        autocompleteCustomer
    }, dispatch)
});

export default connect(null, mapDispatchToProps)(CustomerAutocomplete);
